<template>
  <div
    v-if="promoLoaded"
    class="landingPromo"
    :style="`background-color:${promo.secondary_color}`"
  >
    <BidgelyEleDialog
      v-if="!mainClient.isOnlyFV"
      :show-dialog="showDialog"
      @BidgelyEleDialogClose="showDialog = false"
    ></BidgelyEleDialog>
    <v-row class="landingPromo__header">
      <v-col>
        <button type="button" class="back" @click="onBackClick()">
          <v-icon color="white" size="30">mdi-chevron-left</v-icon>
          <h5>Torna all'homepage</h5>
        </button>
        <div class="titlePromo">{{ promo.media_title }}</div>
      </v-col>
    </v-row>
    <v-row class="landingPromo__body">
      <div class="content">
        <div class="desc">
          <div class="text" v-html="promo.media_description"></div>
          <ButtonsPromo
            :promo="promo"
            :openFootprint="openFootprint"
            :openBeyondEnergy="openBeyondEnergy"
          />
        </div>
        <div class="media">
          <VideoPlayer
            :videoUrl="promo.media_file"
            :previewUrl="promo.media_preview_image"
            :title="promo.media_title"
            :id="promo.id"
          />
        </div>
      </div>
    </v-row>
    <CfpDialog
      :showFootprint="showFootprint"
      :cfpUrl="cfpUrl"
      :resetPropsCfp="resetPropsCfp"
    />
    <!-- <v-dialog v-model="showFootprint" :max-width="cfpSize.width" :persistent="true">
      <div class="cfp-dialog" :class="{'cfp-big': cfpSize.isBig, 'cfp-small': !cfpSize.isBig}">
        <div v-if="!cfpLoaded">
          <v-progress-circular color="white" :size="50" indeterminate></v-progress-circular>
        </div>
        <iframe id="cfp" @load="iframeUrlChanged" :src="cfpUrl" frameborder="0" v-show="cfpLoaded"></iframe>
      </div>
    </v-dialog> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { ENV_CONFIGS } from '@/js/configs'

import ButtonsPromo from '@/components/promo/ButtonsPromo'
import VideoPlayer from '@/components/videoPlayer'
import CfpDialog from '@/components/cfp/CfpDialog'
import BidgelyEleDialog from '@/components/bidgely/BidgelyEleDialog'

export default {
  name: 'LandingPromo',
  components: {
    ButtonsPromo,
    VideoPlayer,
    CfpDialog,
    BidgelyEleDialog
  },
  data() {
    return {
      showFootprint: false,
      cfpUrl: '',
      showDialog: false
      // cfpSize: {
      //   width: 375,
      //   isBig: false
      // },
      // cfpUrl: '',
      // cfpLoaded: false
    }
  },
  computed: {
    ...mapGetters('account', ['isMultipleMainClients', 'promos', 'mainClient']),
    ...mapGetters('session', ['token']),
    promoLoaded() {
      return this.promos !== null
    },
    promo() {
      let id = '/'.concat(this.$route.params.id)
      return this.promos.filter((promo) => promo.id === id)[0]
    }
  },
  methods: {
    ...mapActions('account', ['getMainClient', 'getPromos']),
    ...mapActions('analytics', ['trackTapEvent']),
    openBeyondEnergy() {
      this.showDialog = true
    },
    openFootprint() {
      this.cfpUrl = `${ENV_CONFIGS.CFP_BASE_URL}/?clientCode=${this.mainClient.clientOwnerCode}&clientType=${this.mainClient.clientOwnerType}&name=${this.mainClient.firstName}&surname=${this.mainClient.lastName}&hasLightContracts=true&token=${this.token}&isWeb=true&accountType=${this.accountProperties.type}`
      if (this.isMultipleMainClients) {
        // Workaround to show the bonifica-alert also when openinig CFP. (redirect to loyalty which makes same checks since we have no route for CFP, which is open inside dialog in home)
        this.$router.push({ name: 'loyaltyPage' })
      } else {
        this.showFootprint = true
      }
    },
    onBackClick() {
      this.$router.push({ name: 'home' })
    },
    resetPropsCfp() {
      this.showFootprint = false
      this.cfpUrl = ''
    }
    // iframeUrlChanged () {
    //   this.cfpLoaded = true
    // },
    // onResize () {
    //   this.cfpSize.isBig = window.innerWidth >= 1600
    //   this.cfpSize.width = window.innerWidth < 1600 ? 375 : 500
    // },
    // closeFootprint () {
    //   this.showFootprint = false
    //   this.cfpUrl = ''
    //   this.cfpLoaded = false
    // },
    // handleMessage: function (event) {
    //   if (event.data === 'cfp-close') {
    //     this.closeFootprint()
    //   } else if (event.data === 'goToLoyalty') {
    //     this.closeFootprint()
    //     this.$router.push({ name: 'loyaltyPage' })
    //   }
    // }
  },
  beforeMount() {
    this.getMainClient().then(() => {
      this.getPromos()
    })
  }
}
</script>
<style lang="scss" scoped>
.landingPromo {
  &__header {
    padding: 40px calc((100% - 1140px) / 2) 0;
    color: white;
    position: relative;
    z-index: 100;
    align-items: center;
    .titlePromo {
      font-size: 60px;
      font-weight: bold;
      line-height: 70px;
      max-width: 913px;
      margin-bottom: 40px;
    }
    .back {
      display: flex;
      align-items: center;
      color: white;
      font-size: 20px;
      margin-right: 12px;
      margin-bottom: 5px;
      letter-spacing: 0.4px;

      .v-icon {
        width: 15px;
        margin-right: 14px;
      }

      &:focus {
        outline: none;
      }
    }
  }
  &__body {
    position: relative;
    width: 100%;
    z-index: 101;
    background: white;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding-bottom: 100px;
    padding-top: 60px;
    .content {
      width: 1140px;
      margin: 0 auto;
      display: flex;
      align-content: center;
      .desc {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-right: 88px;
        .text {
          font-size: 20px;
          line-height: 1.3;
          margin-bottom: 48px;
        }
      }
      .media {
        width: 50%;
      }
    }
  }
}
</style>
<style lang="scss">
.landingPromo {
  .theme--light.v-btn {
    width: 360px;
    color: white;
  }
}
</style>
