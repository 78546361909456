var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"supplyCard supplyCard__container",class:_vm.card.type[0] === _vm.ServiceCardType.RAISE
      ? 'raise-card'
      : _vm.card.type[0] === _vm.ServiceCardType.CUSTOM
      ? 'custom-card'
      : ''},[(_vm.card.type[0] !== _vm.ServiceCardType.RAISE)?_c('div',{staticClass:"supplyCard__card-img",style:(`backgroundImage: url('${_vm.resolve_img_url(_vm.card.card_image)}')`)}):_vm._e(),(_vm.card.type[0] === _vm.ServiceCardType.CUSTOM)?_c('div',{staticClass:"supplyCard__container-choosed-for-you"},[_c('p',{staticClass:"CaptionS color--blue"},[_vm._v("Scelto per te")])]):_vm._e(),_c('div',{staticClass:"supplyCard__inside-info"},[_c('h5',{staticClass:"BodyM-Strong mb-3"},[_vm._v(" "+_vm._s(_vm.getRealName(_vm.card))+" ")]),_c('div',{staticClass:"BodyM",domProps:{"innerHTML":_vm._s(_vm.card.card_body)}}),(_vm.card.cta_type !== 'WEB_LINK')?[_c('div',{staticClass:"ButtonS",on:{"click":function($event){return _vm.getDestinationLink(_vm.card)}}},[_vm._v(" "+_vm._s(_vm.card.cta_label)+" ")])]:[_c('a',{staticClass:"ButtonS",attrs:{"href":_vm.card.cta_url,"target":"_blank"},on:{"click":function($event){return _vm.trackTapEvent({
            name: 'customOffers_card_tap',
            params: {
              section_type: _vm.$route.params.type,
              card_title: _vm.card.card_title
            }
          })}}},[_vm._v(_vm._s(_vm.card.cta_label))])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }