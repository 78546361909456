<template>
  <div>
    <div v-for="(business, index) in filteredSupplies" :key="index">
      <div class="supplyOwner">
        <img
          v-if="business.clientOwnerType === 'RES'"
          src="../../assets/ic-residenziale_blue.svg"
          width="24"
        />
        <img
          v-if="business.clientOwnerType === 'BUS'"
          src="../../assets/icons/business.svg"
          width="24"
        />
        <div class="BodyL-Strong ml-4 mt-1">
          {{ business.businessName }}
        </div>
      </div>

      <div
        v-for="(service, k) in business.services"
        :key="service.type + k"
        @click="selectAddress(service, business.businessName)"
      >
        <div class="supplyAddress">
          <div class="supplySection">
            <img
              v-if="service.type === 'gas'"
              src="../../assets/icons/ic-fire-blu.svg"
              alt=""
              width="24"
            />
            <img
              v-if="service.type === 'ele'"
              src="../../assets/icons/ic-light-blu.svg"
              alt=""
              width="24"
            />
            <img
              v-if="service.type === 'fotovoltaico'"
              src="../../assets/icons/ic-fotovoltaico-blu.svg"
              alt=""
              width="24"
            />
            <div class="ml-4">
              <div class="BodyL">
                {{ service.street }} {{ service.zipCode }}
                {{ service.city }}
              </div>
              <!-- TODO aggiungere lo stato per gli impianti fotovoltaico -->
              <div
                class="mt-1 mb-1"
                v-if="service.type === 'ele' || service.type === 'gas'"
              >
                <div
                  v-if="service.paymentStatus === 'Regolari'"
                  class="CaptionS status status__regular"
                >
                  REGOLARE
                </div>
                <div
                  v-if="service.paymentStatus === 'Non regolari'"
                  class="CaptionS status status__notRegular"
                >
                  PAGAMENTI DA CONTROLLARE
                </div>
              </div>
              <div class="mt-1 mb-1" v-else>
                <div
                  v-if="
                    service.installationStatus === OVERALL_STATUS.NO_ANOMALY ||
                    service.installationStatus ===
                      OVERALL_STATUS.VALIDATION_ERROR
                  "
                  class="CaptionS status status__regular"
                >
                  REGOLARE
                </div>
                <div
                  v-else-if="
                    service.installationStatus === OVERALL_STATUS.ALARM
                  "
                  class="CaptionS status status__notRegular"
                >
                  NON FUNZIONANTE
                </div>
                <!-- per stati NOT_COMMUNICATING e UNKNOWN -->
                <div v-else class="CaptionS status status__notRegular">
                  OFFLINE
                </div>
              </div>
            </div>
          </div>

          <img src="@/assets/icons/chevron_rx_light.svg" width="16" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OVERALL_STATUS } from '@/js/constants'

export default {
  name: 'selectAddress',
  props: {
    filteredSupplies: { type: Array, default: () => [] },
    serviceName: { type: String }
  },
  data() {
    return {
      OVERALL_STATUS: OVERALL_STATUS
    }
  },
  methods: {
    selectAddress(service, businessName) {
      this.$emit('selectAddress', service, businessName)
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  margin-top: 10px;
  &__regular {
    color: #12256a;
  }
  &__notRegular {
    color: #d93273;
  }
}
</style>
