<template>
  <div class="flow">
    <FiberHeader />
    <div class="flow__content std-side-padding topRadius pt-8">
      <div class="flow__back mb-6" @click="prevStep">
        <h2>{{ stepTitle[this.step] }}</h2>
      </div>
      <Offert v-if="step === 0" @nextStep="nextStep"></Offert>
      <SelectRequest v-if="step === 1" @nextStep="nextStep"></SelectRequest>
    </div>
  </div>
</template>
<script>
import FiberHeader from './fiber_bus/FiberHeader.vue'
import Offert from './fiber_bus/Offert.vue'
import SelectRequest from './fiber_bus/SelectRequest.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'fiberFlowBus',
  data() {
    return {
      step: 0,
      historyStep: [{ step: 0, trackEventName: '' }],
      stepTitle: ['Scopri il piano', 'Seleziona richiesta']
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('upsellingFiber', ['updateFiberCtx']),
    nextStep(trackEventName) {
      this.step++
      this.historyStep.push({ step: this.step, trackEventName: trackEventName })
      const addressCovered =
        this.$store.getters['upsellingFiber/addressCovered']
      this.trackTapEvent({
        name: trackEventName,
        params: {
          client_selected: addressCovered
            ? addressCovered['clientOwnerCode'] || ''
            : 'not_selected'
        }
      })
      this.updateFiberCtx({ upselling_step_name: this.stepTitle[this.step] })
    },
    prevStep() {
      this.historyStep.pop(1)
      if (this.historyStep.length === 0) {
        this.$router.push({ name: 'fiberAddress' })
        return false
      }
      this.editMode = false
      this.step = this.historyStep[this.historyStep.length - 1].step
      const addressCovered =
        this.$store.getters['upsellingFiber/addressCovered']
      this.trackTapEvent({
        name: this.historyStep[this.historyStep.length - 1].trackEventName,
        params: {
          client_selected: addressCovered
            ? addressCovered['clientOwnerCode'] || ''
            : 'not_selected'
        }
      })
      this.updateFiberCtx({ upselling_step_name: this.stepTitle[this.step] })
    }
  },
  components: {
    FiberHeader,
    Offert,
    SelectRequest
  }
}
</script>
<style lang="scss" scoped>
.flow {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  &__content {
    margin-top: -30px !important;
    background: white;
  }
  &__back {
    display: flex;
    align-items: center;
    width: fit-content;
    h2 {
      font-weight: 500;
      color: black;
      cursor: pointer;
    }
    &::before {
      content: url('../../assets/ic-chevron-sx-blue.svg');
      width: 24px;
      margin-right: 16px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
</style>
