import Consts from '@/js/constants'

const getSupplyTrackingParams = (supply) => {
  const status = {
    [Consts.SUPPLY_STATUS.ACTIVE]: 'active',
    [Consts.SUPPLY_STATUS.CLOSED]: 'stopped',
    [Consts.SUPPLY_STATUS.REJECTED]: 'canceled',
    [Consts.SUPPLY_STATUS.ACTIVATING]: 'activating'
  }
  if (!supply) return {}
  return {
    supply_type: supply.serviceType.toLowerCase(),
    pr_code: supply.supplyCode,
    pod_pdr: supply.pod || supply.pdr,
    client_code: supply.clientOwnerCode,
    supply_status: status[supply.supplyState],
    product_type: supply.isDigital ? 'digital' : 'no_digital',
    product_name: Consts.PRODUCTS[supply.product],
    offer_type: supply.isPlacet ? 'placet' : 'no_placet',
    client_type: supply.clientOwnerType.toLowerCase()
  }
}

const getSupplyTrackingChartParams = (supply, fraction) => {
  return {
    ...getSupplyTrackingParams(supply),
    fraction: fraction
  }
}

export { getSupplyTrackingChartParams, getSupplyTrackingParams }
