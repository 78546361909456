<template>
  <div class="requestBusSent">
    <FiberHeader />
    <div class="requestBusSent__content std-side-padding topRadius pt-8">
      <h2 class="mb-8">Richiesta inviata</h2>
      <h4 v-if="$route.params.type === 'bill'">
        La tua bolletta è stata caricata correttamente.
      </h4>
      <h4 v-else>Sarà nostra cura contattarti al più presto.</h4>
      <h5>Un nostro operatore ti contatterà il prima possibile.</h5>
      <v-btn
        color="primary"
        x-large
        width="269"
        class="v-btn--shadowed requestBusSent__btn"
        @click="$router.push({ name: 'home' })"
        >Torna all'homepage</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import FiberHeader from '../fiber_bus/FiberHeader.vue'
export default {
  name: 'requestBusSent',
  components: {
    FiberHeader
  },
  beforeMount() {
    this.trackTapEvent({
      name: 'upsellingv2_ClickToCallTYP_open',
      params: {
        client_selected:
          this.$store.getters['upsellingFiber/addressCovered']
            .clientOwnerCode || ''
      }
    })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>
<style lang="scss" scoped>
.requestBusSent {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  &__content {
    margin-top: -30px !important;
    background: white;
  }
  h2 {
    font-weight: 500;
    color: black;
    cursor: pointer;
  }
  h4 {
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  h5 {
    line-height: 1.4;
    letter-spacing: 0.5px;
    color: black;
    width: 653px;
  }
  &__btn {
    margin-top: 40px;
    margin-bottom: 80px;
  }
}
</style>
