<template>
  <div class="mt-3 margin">
    <v-row>
      <v-col>
        <div class="faq">
          <div class="BodyL-Strong mb-3 mt-6">Cos’è il Parental control?</div>

          <div class="BodyL mb-2">
            Proteggi i tuoi cari: controlla l'accesso online. Attiva o disattiva
            il Parental Control facilmente e garantisci una navigazione sicura
            per la tua famiglia. Un internet protetto per tutti.
          </div>
          <div class="BodyL">
            Per maggiori informazioni visita
            <span
              @click.stop="goToParentalControl"
              class="ButtonM-Underline setupLink"
              >www.sorgenia.it/parental-control-fibra</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FaqParentalControl',
  methods: {
    goToParentalControl() {
      window.open('https://www.sorgenia.it/parental-control-fibra')
    }
  }
}
</script>

<style scoped>
.faq {
  width: 751px;
}
.setupLink {
  cursor: pointer;
  color: #12256a;
}
.margin {
  margin-bottom: 330px;
}
</style>
