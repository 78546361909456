<template>
  <div class="reward content">
    <v-row class="reward__description">
      <v-col cols="7">
        <slot name="text" />
      </v-col>
      <v-col cols="5">
        <v-card
          class="v-card--rounded greyContent"
          outlined
          width="360"
          :height="flow === 'survey' ? 287 : 190"
        >
          <v-col v-if="flow === 'survey'" align="center">
            <h4 class="mt-3 mb-4">Hai guadagnato</h4>
            <v-img
              class="mb-4"
              width="90"
              height="90"
              src="../../../assets/greeners/svg/greeners.svg"
            />
            <h1 class="mb-2">{{ coins }}</h1>
            <h4 class="font-weight-bold">Green Coins</h4>
          </v-col>
          <v-row class="pt-6 pl-7 pr-0" v-if="flow === 'video'" align="center">
            <v-col cols="7">
              <h4 class="mb-1">Hai guadagnato</h4>
              <h1 class="mb-1">{{ coins }}</h1>
              <h4 class="font-weight-bold">Green Coins</h4>
            </v-col>
            <v-col class="pl-5" cols="5" align-self="end">
              <v-img
                class="mb-4"
                width="90"
                height="90"
                src="../../../assets/greeners/svg/greeners.svg"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7">
        <v-btn
          class="v-btn--shadowed btn"
          x-large
          :width="btnSettings.width"
          @click="
            $router.push({ name: goTo(mission.first_app_section_section) })
          "
        >
          {{ mission.first_cta_label }}
        </v-btn>
      </v-col>
      <v-col cols="5" :align="btnSettings.align">
        <v-btn
          class="v-btn--shadowed btn"
          color="brightGreen"
          x-large
          :width="btnSettings.width"
          @click="
            $router.push({ name: goTo(mission.second_app_section_section) })
          "
        >
          {{ mission.second_cta_label }}
        </v-btn>
      </v-col>
    </v-row>
    <v-footer color="white" height="50" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { APP_SECTION_MAPPING } from '@/js/constants'

export default {
  name: 'RewardContent',
  data() {
    return {
      APP_SECTION_MAPPING: APP_SECTION_MAPPING
    }
  },
  props: {
    coins: { type: Number, default: 0 },
    flow: { type: String, default: 'survey' },
    mission: { type: Object }
  },
  beforeMount() {
    this.trackTapEvent({
      name: 'greeners_missionCompleted_view',
      params: {
        mission_id: this.mission.id,
        mission_title: this.mission.title
      }
    })
  },
  computed: {
    btnSettings() {
      if (this.flow === 'survey') {
        return { width: 360, align: 'start' }
      }
      if (this.flow === 'video') {
        return { width: 265, align: 'center' }
      }
      return { width: 360, align: 'start' }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    goTo(id) {
      var destination = this.APP_SECTION_MAPPING[id]
      // var destination;
      // switch (id) {
      //   case '/loyalty-diary-0':
      //   destination = 'loyaltyPage'
      //   break
      //   case '/loyalty-catalog-0':
      //   destination = 'catalogue'
      //   break
      //   default:
      //   destination = 'loyaltyPage'
      // }

      this.trackTapEvent({
        name: 'greeners_missionThankYouPageCTA_tap',
        params: { app_section: destination }
      })

      return destination
    }
  }
}
</script>

<style lang="scss" scoped>
.reward {
  width: 100%;
  h1 {
    font-weight: bold;
    height: 52px;
  }
  h4 {
    font-weight: 500;
    font-size: 20px;
  }
  &__description {
    margin-top: 50px;
    margin-bottom: 40px;
  }
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(
      .v-btn--outlined
    ) {
    background-color: #fff;
  }
  .btn {
    letter-spacing: normal;
    font-weight: bold;
  }
  .greyContent {
    background-color: #f8f9fc;
    border-color: #f8f9fc;
    border-radius: 40px !important;
  }
}
</style>
