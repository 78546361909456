<template>
  <div class="successSubscribed">
    <div class="successSubscribed__header">
      <div class="successSubscribed__title">
        Digitale al servizio delle persone
      </div>
    </div>
    <div class="std-side-padding topRadius pt-10">
      <div class="successSubscribed__content">
        <h2 class="mb-8">Sottoscrizione conclusa con successo</h2>
        <!-- <h3 v-if="connectionType === 'FTTC' && activeLine && !isFromTelecom"></h3> -->
        <h3
          v-if="
            isFromTelecom ||
            (!activeLine && provider === 'FC') ||
            (!activeLine && withModem)
          "
          class="mb-8"
        >
          A breve ti contatterà un operatore per fissare il giorno
          dell’installazione Fibra
        </h3>
        <div class="successSubscribed__box">
          <!-- <h5>
            Data prevista di attivazione:
            {{ $filters.formatDateSplitJoin(dac) }}
          </h5> -->
          <h5 v-if="speedUpProcess">
            Data prevista di attivazione:
            {{ getAnticipatedActivationDate() }}
          </h5>
          <h5 v-else>
            Data prevista di attivazione:
            {{ getStandardActivationDate() }}
          </h5>
        </div>
        <div class="successSubscribed__title-small">Cosa Faremo noi?</div>
        <div v-if="addressCovered.fiberType === 'OF'" class="desc">
          Ti invieremo una email di riepilogo e ti avviseremo quando la Fibra
          sarà attiva .
        </div>
        <div v-else-if="activeLine && !isFromTelecom && withModem" class="desc">
          Ti invieremo una email di riepilogo e ti avviseremo quando la Fibra
          sarà attiva.<br /><br />
          <span
            >Ti spediremo il modem Sorgenia che riceverai entro la data di
            attivazione.</span
          >
        </div>
        <div
          v-else-if="activeLine && !isFromTelecom && !withModem"
          class="desc"
        >
          Noi ti invieremo una email di riepilogo e contatteremo il tuo
          precedente fornitore per la chiusura del contratto.
        </div>
        <div v-else-if="activeLine && isFromTelecom" class="desc">
          Ti invieremo una email di riepilogo e ti avviseremo quando la Fibra
          sarà attiva.<br /><br />
          <span v-if="withModem"
            >Stiamo preparando il tuo modem Sorgenia che riceverai entro la data
            di attivazione.</span
          >
        </div>
        <div v-else-if="!activeLine" class="desc">
          Ti invieremo una email di riepilogo e ti avviseremo quando la Fibra
          sarà attiva.
        </div>
        <div class="successSubscribed__title-small">Cosa Farai tu?</div>
        <div v-if="addressCovered.fiberType === 'OF'" class="desc">
          Tu potrai controllare lo stato di avanzamento dell’attivazione nella
          pagina dell’utenza Fibra.
        </div>
        <div
          v-else-if="activeLine && !isFromTelecom && withModem"
          class="desc box"
        >
          Tu potrai monitorare lo stato di avanzamento della spedizione del
          modem seguendo il link che troverai nella sezione dell'utenza fibra.
        </div>
        <div
          v-else-if="activeLine && !isFromTelecom && !withModem"
          class="desc box"
        >
          Tu potrai controllare lo stato di avanzamento dell’attivazione nella
          pagina dell’utenza Fibra.
        </div>
        <div v-else-if="activeLine && isFromTelecom" class="desc box">
          Se hai già una linea attiva ricordati di disdire il precedente
          contratto appena la connessione Sorgenia sarà attivata.<br />Puoi
          farlo quando vuoi perché non è vincolante ai fini della nostra
          attivazione.<br />
        </div>
        <div v-else-if="!activeLine" class="desc box">
          <span v-if="withModem"
            >Tu potrai monitorare lo stato di avanzamento della spedizione del
            modem seguendo il link che troverai nella sezione dell'utenza
            fiber.</span
          >
          <span v-else
            >Tu potrai controllare lo stato di avanzamento dell’attivazione
            nella pagina dell’utenza Fibra.</span
          >
        </div>
        <v-btn
          color="primary"
          x-large
          width="269"
          class="v-btn--shadowed mb-10"
          @click="
            trackTapEvent({
              name: 'upsellingv2_thankyoupage_back',
              params: {
                client_selected:
                  $store.getters['upsellingFiber/addressCovered']
                    .clientOwnerCode || ''
              }
            }),
              $router.push('home')
          "
          >Torna all'homepage</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'successSubscribed',
  computed: {
    ...mapGetters('offers', ['upsellingInfo']),
    ...mapGetters('upsellingFiber', [
      'connectionType',
      'provider',
      'isFromTelecom',
      'activeLine',
      'client',
      'speedUpProcess',
      'addressCovered',
      'migrationCode',
      'withModem',
      'getActivationDataFiber'
    ])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    getStandardActivationDate() {
      return this.getActivationDataFiber.attivazione
    },
    getAnticipatedActivationDate() {
      return this.getActivationDataFiber.attivazioneAnticipata
    }
  },
  beforeMount() {
    this.trackTapEvent({
      name: 'upsellingv2_thankyoupage_open',
      params: {
        client_selected:
          this.$store.getters['upsellingFiber/addressCovered']
            .clientOwnerCode || ''
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.successSubscribed {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  &__header {
    width: 100%;
    height: 400px;
    background-image: url('../../../assets/img-promo-fibra-2021.png');
    background-position: center;
    background-size: cover;
  }
  &__title {
    font-size: 40px;
    font-family: 'Roboto';
    width: 85%;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding-top: 7%;
    padding-left: 51%;
    line-height: normal;
  }
  &__title-small {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  .topRadius {
    margin-top: -30px !important;
    background: white;
  }
  &__content {
    width: 701px;
    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 130%;
      letter-spacing: 1.2px;
    }
    h3 {
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0.5px;
    }
  }
  &__box {
    width: 652px;
    height: 56px;
    margin-bottom: 32px;
    padding: 0 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    display: flex;
    align-items: center;
    h5 {
      font-size: 16px;
      color: black;
      letter-spacing: 0.5px;
      font-weight: 400;
    }
    cursor: pointer;
    &::before {
      margin-right: 16px;
      content: url('../../../assets/ic-calendar-blue.svg');
      width: 18px;
      margin-top: 5px;
    }
  }
  .desc {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.5px;
    color: black;
    margin-bottom: 48px;
    &.box {
      font-weight: 400;
      padding: 24px;
      border-radius: 16px;
      background-color: #f8f9fc;
    }
    &.alert {
      background-color: #ffd9b7;
    }
  }
}
</style>
