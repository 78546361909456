<template>
  <page
    title="Motivo mancata compilazione"
    subTitle="Indica il motivo per cui non puoi compilare i dati catastali"
    page-name="Dati identificativi"
    longBreadcrumbs="Dati catastali"
    longBreadcrumbsAction="cadastralAction"
  >
    <TcVars />
    <div class="cadastralNoData mt-6">
      <v-radio-group v-model="reason">
        <v-radio value="1" class="mb-8">
          <template v-slot:label>
            <div class="reason">Immobile non accatastato</div>
          </template>
        </v-radio>
        <v-radio value="2" class="mb-8">
          <template v-slot:label>
            <div class="reason">Immobile non accatastabile</div>
          </template>
        </v-radio>
        <v-radio value="5" class="mb-8">
          <template v-slot:label>
            <div class="reason">
              Fornitura del complesso condominiale e/o parti comuni
            </div>
          </template>
        </v-radio>
        <v-radio value="6" class="mb-9">
          <template v-slot:label>
            <div class="reason">
              Fornitura temporanea o per usi pubblici (esclusa dall'obbligo di
              invio dati)
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <div class="">
        <v-btn
          width="156"
          height="54"
          color="primary"
          class="mr-6"
          :disabled="!reason"
          @click="dialogConferm = true"
          >Continua</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="dialogConferm" width="555">
      <div class="dialogCatastral">
        <div class="d-flex justify-space-between align-start mb-5">
          <div class="dialogCatastral__title">Confermi le informazioni?</div>
          <img
            src="../../../assets/icons/close-blu.svg"
            @click="dialogConferm = false"
          />
        </div>
        <div class="dialogCatastral__desc">
          Se confermi le informazioni non potrai tornare indietro per
          modificarle.
        </div>
        <div class="d-flex justify-center align-center">
          <v-btn
            width="229"
            height="54"
            color="primary"
            class="mr-2"
            @click="request"
            >Conferma e invia</v-btn
          >
          <v-btn
            width="229"
            height="54"
            color="primary"
            text
            @click="
              trackTapEvent({
                name: 'cadastral_cancelData_tap',
                params: paramsTrack
              }),
                (dialogConferm = false)
            "
            >Annulla</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <div class="contentLoader" v-if="loader">
      <v-progress-circular
        class="loader"
        :size="100"
        indeterminate
      ></v-progress-circular>
    </div>
  </page>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Template from './Template'
export default {
  name: 'cadastralNoData',
  components: {
    page: Template,
    TcVars
  },
  data() {
    return {
      reason: null,
      dialogConferm: false,
      loader: false
    }
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail', 'cadastralData']),
    paramsTrack() {
      const supplyCode = this.$route.params.supplyCode
      return {
        supply_type:
          this.supplyDetail &&
          this.supplyDetail[supplyCode].serviceType.toLowerCase(),
        pr_code: this.$route.params.supplyCode,
        client_code:
          this.supplyDetail &&
          this.supplyDetail[this.$route.params.supplyCode].clientOwnerCode,
        client_type:
          this.supplyDetail &&
          this.supplyDetail[supplyCode].clientOwnerType.toLowerCase()
      }
    }
  },
  methods: {
    ...mapMutations('supply', ['setCadastralData']),
    ...mapActions('supply', ['sendCadastralData', 'getCadastralData']),
    ...mapActions('analytics', ['trackTapEvent']),
    request() {
      this.dialogConferm = false
      this.loader = true
      this.cadastralData.motivazioneDatiNonCompilati = this.reason
      this.setCadastralData(this.cadastralData)

      this.trackTapEvent({
        name: 'cadastral_confirmData_tap',
        params: this.paramsTrack
      })

      this.getCadastralData({
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      }).then((resp) => {
        if (!resp.ricezioneDatiCatastali) {
          this.sendCadastralData().then((resp) => {
            if (resp) {
              this.loader = false
              this.$router.push({
                name: 'cadastralSuccess',
                params: { sendRequest: true }
              })
            } else {
              this.loader = false
            }
          })
        } else {
          this.loader = false
          this.$router.push({ name: 'cadastralSuccess' })
        }
      })
    }
  },
  mounted() {
    this.trackTapEvent({
      name: 'cadastral_cadastralDataUnavailable_tap',
      params: this.paramsTrack
    })
  }
}
</script>
<style lang="scss" scoped>
.cadastralNoData {
  width: 752px;
  &__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 1.3px;
  }
  &__subTitle {
    font-size: 16px;
    color: black;
    line-height: 1.4;
    letter-spacing: 1px;
  }
}
.contentLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.48);
  opacity: 0.6;
}
.v-dialog__content {
  .dialogCatastral {
    background: white;
    padding: 40px;
    img {
      cursor: pointer;
    }
    &__title {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 1px;
    }
    &__desc {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
  }
}
</style>
<style lang="scss">
.cadastralNoData {
  label.v-label.theme--light {
    top: 0px;
    transition: none;
    transform: none;
    .reason {
      font-size: 16px;
      color: #2c313a;
      line-height: 1.4;
      letter-spacing: 1px;
      margin-left: 8px;
      max-width: 417px;
    }
  }
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
