var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.promo.cta_action_type === 'APP_SECTION' &&
    _vm.promo.cta &&
    _vm.APP_SECTION_MAPPING[_vm.promo.app_section_section] === 'speedTest'
  )?_c('SpeedTest',{attrs:{"isAbstract":_vm.isAbstract}},[_c('v-btn',{staticClass:"v-btn--shadowed",attrs:{"color":_vm.promo.secondary_color,"x-large":""},on:{"click":function($event){return _vm.trackTapEvent({
        name: 'vetrina_promo_tap',
        params: { promo_id: _vm.promo.id, promo_title: _vm.promo.title }
      })}}},[_vm._v(" "+_vm._s(_vm.promo.cta)+" ")])],1):(
    _vm.promo.cta_action_type === 'APP_SECTION' &&
    _vm.promo.cta &&
    _vm.APP_SECTION_MAPPING[_vm.promo.app_section_section] === 'carbonFootprint'
  )?_c('v-btn',{staticClass:"v-btn--shadowed",attrs:{"color":_vm.promo.secondary_color,"x-large":""},on:{"click":function($event){return _vm.openFootprint()}}},[_vm._v(" "+_vm._s(_vm.promo.cta)+" ")]):(
    _vm.promo.cta_action_type === 'APP_SECTION' &&
    _vm.promo.cta &&
    _vm.APP_SECTION_MAPPING[_vm.promo.app_section_section] === 'beyondEnergy'
  )?_c('v-btn',{staticClass:"v-btn--shadowed",attrs:{"color":_vm.promo.secondary_color,"x-large":""},on:{"click":function($event){return _vm.openBeyondEnergy()}}},[_vm._v(" "+_vm._s(_vm.promo.cta)+" ")]):(_vm.promo.cta_action_type === 'APP_SECTION' && _vm.promo.cta)?_c('router-link',{attrs:{"to":{
    name: _vm.APP_SECTION_MAPPING[_vm.promo.app_section_section],
    params: { from: 'homepage-promo' }
  }},on:{"click":function($event){return _vm.trackTapEvent({
      name: 'vetrina_promo_tap',
      params: { promo_id: _vm.promo.id, promo_title: _vm.promo.title }
    })}}},[_c('v-btn',{staticClass:"v-btn--shadowed",attrs:{"color":_vm.promo.secondary_color,"x-large":""}},[_vm._v(" "+_vm._s(_vm.promo.cta)+" ")])],1):(_vm.promo.cta_action_type === 'WEB_LINK' && _vm.promo.cta)?_c('a',{attrs:{"href":_vm.promo.url,"target":"_blank"}},[_c('v-btn',{staticClass:"v-btn--shadowed",attrs:{"color":_vm.promo.secondary_color,"x-large":""},on:{"click":function($event){return _vm.trackTapEvent({
        name: 'vetrina_promo_tap',
        params: { promo_id: _vm.promo.id, promo_title: _vm.promo.title }
      })}}},[_vm._v(" "+_vm._s(_vm.promo.cta)+" ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }