import { render, staticRenderFns } from "./warning2.vue?vue&type=template&id=edaf1ea0&scoped=true"
import script from "./warning2.vue?vue&type=script&lang=js"
export * from "./warning2.vue?vue&type=script&lang=js"
import style0 from "./warning2.vue?vue&type=style&index=0&id=edaf1ea0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edaf1ea0",
  null
  
)

export default component.exports