<template>
  <div class="login">
    <TcVars />
    <div class="login__content">
      <img
        src="../../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <div class="login__box scrollable">
        <template v-if="registrationOK">
          <div class="login__title">Verifica la tua email</div>
          <div class="login__subTitle">
            è stata inviata un’email all’indirizzo inserito precedentemente con
            un link per l’accesso.
          </div>
        </template>
        <template v-if="!registrationOK">
          <div class="login__title">Account non creato</div>
          <div class="login__subTitle">
            Si è verificato un problema. Ritenta oppure contatta il supporto.
          </div>
        </template>
        <div class="d-flex justify-center mt-8">
          <MYSButton
            button-class="primaryInvertedButton auth__btn"
            width="154"
            @buttonClick="goToLogin"
            button-text="Torna al login"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MYSButton from '../../components/global/MYSButton.vue'
import router from '../../router/router'
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'RegistrationConfirmation',
  props: {
    registrationOK: Boolean
  },
  components: {
    TcVars,
    MYSButton
  },
  methods: {
    goToLogin() {
      router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/authentication/auth.scss';
</style>
