<template>
  <div>
    <div class="faq">
      <div class="page mb-10">
        <v-row class="std-side-padding">
          <v-col>
            <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
              <template v-slot:divider>
                <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>

        <FormTitle />
        <FormSystem
          :subtitle="subtitle"
          :selectedAddress="selectedAddress"
          :nome="nome"
          :cognome="cognome"
          :filteredSupplies="
            $route.params.serviceName === 'Luce e Gas'
              ? filteredSuppliesGasEle
              : filteredSuppliesFotovoltaico
          "
          :serviceName="$route.params.serviceName"
          :tipologiaServizio="tipologiaServizio"
          @setOpenSelectModal="setOpenSelectModal"
          :name="name"
          :address="address"
        />

        <FormData
          :tipologiaServizioProp="$route.params.serviceName"
          :validateAddress="validateAddress"
          :categories="categories"
          :load="load"
          @onSubmit="onSubmit"
        />
      </div>
      <FooterSupport :serviceName="$route.params.serviceName" />
      <!-- <v-dialog
        v-model="showModal"
        persistent
        max-width="558"
        overlay-opacity="0.6"
        content-class="customDialog"
      >
        <DialogResponse
          :response="response"
          :responseMessage="responseMessage"
          :messageDialogA="messageDialogA"
          :messageDialogB="messageDialogB"
          :messageDialogC="messageDialogC"
          :messageButton="messageButton"
          @goFAQ="goFAQ"
          @goHomeOrGoForm="goHomeOrGoForm"
          @setShowModal="setShowModal"
        />
      </v-dialog> -->
      <ServiceUnavailableDialog
        :serviceUnavailableDialogOpen.sync="showModal"
        :imageResponse="response"
        :title="titleServiceUnavailableDialog"
        :body="bodyServiceUnavailableDialog"
        :bodyStrong="bodyStrongServiceUnavailableDialog"
        :cta1="cta1ServiceUnavailableDialog"
        :cta2="cta2"
        @goFAQ="goFAQ"
        @goHomeOrGoForm="goHomeOrGoForm"
      />
      <v-dialog
        v-model="openSelectModal"
        persistent
        max-width="558"
        overlay-opacity="0.6"
        content-class="customDialog"
      >
        <div class="selectDialogContainer">
          <DialogHeader
            @setOpenSelectModal="setOpenSelectModal"
            :impiantoOrUtenza="impiantoOrUtenza"
          />
          <SelectAddress
            :filteredSupplies="
              $route.params.serviceName === 'Luce e Gas'
                ? filteredSuppliesGasEle
                : filteredSuppliesFotovoltaico
            "
            :serviceName="$route.params.serviceName"
            @selectAddress="selectAddress"
          />
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {
  requestCategories,
  requestCategoriesPhotovoltaic
} from '../../js/constants'
import { mapActions, mapGetters } from 'vuex'
import FooterSupport from '../../components/support/Footer.vue'
import { required, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { capitalizeFirstLetter } from '../../js/utils'
import SelectAddress from '../../components/support/SelectAddress.vue'
import Constants from '@/js/constants'
import FormTitle from '../../components/support/FormTitle.vue'
import FormSystem from '../../components/support/FormSystem.vue'
import DialogHeader from '../../components/support/DialogHeader.vue'
import FormData from '@/components/support/FormData.vue'
import ServiceUnavailableDialog from '../../components/global/ServiceUnavailableDialog.vue'

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'sendRequestComponent',
  components: {
    FooterSupport,
    SelectAddress,
    FormTitle,
    FormSystem,
    DialogHeader,
    // DialogResponse,
    FormData,
    ServiceUnavailableDialog
  },
  data() {
    return {
      load: false,
      isNavigating: false,
      openSelectModal: false,
      showModal: false,
      nome: null,
      cognome: null,
      selectedAddress: null,
      usernameOrEmail: null,
      IDACU: null,
      IDsito: null,
      IDdevice: null,
      telefono: null,
      tipologiaServizio: this.$route.params.serviceName,
      tipologiaRichiesta: null,
      dettagliRichiesta: null,
      messaggio: null,
      validateAddress: false,
      puntoDiFornitura: null,
      codiceCliente: null,
      supplyType: null,
      product: null,
      clientOwnerType: null,
      categories:
        this.$route.params.serviceName === 'Fotovoltaico'
          ? requestCategoriesPhotovoltaic
          : requestCategories,
      response: null,
      SERVICE_TYPE: Constants.SERVICE_TYPE,
      SUPPLY_STATUS: Constants.SUPPLY_STATUS,
      cta2: 'Vai alle FAQ'
    }
  },
  beforeMount() {
    if (!this.supplies) {
      this.$router.push({
        name: 'SupportPage'
      })
    }
  },

  mounted() {
    if (
      this.$route.params.serviceName === 'Fotovoltaico' &&
      Object.keys(this.filteredSuppliesFotovoltaico).length === 1
    ) {
      if (this.filteredSuppliesFotovoltaico[0].services.length === 1) {
        this.validateAddress = true
      }
    }
    if (
      this.$route.params.serviceName === 'Luce e Gas' &&
      Object.keys(this.filteredSuppliesGasEle).length === 1
    ) {
      if (this.filteredSuppliesGasEle[0].services.length === 1) {
        this.validateAddress = true
      }
    }
    this.telefono =
      this.clientDetails.phone === ''
        ? this.clientDetails.mobilePhone
        : this.clientDetails.phone
    this.usernameOrEmail = this.username

    window.addEventListener('popstate', this.handleBackButton)
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton)
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  computed: {
    ...mapGetters('account', ['accountClients', 'mainClient', 'clientDetails']),
    ...mapGetters('supply', ['supplies', 'supplyCode']),
    ...mapGetters('session', ['username']),
    ...mapGetters('fotovoltaico', ['getInstallationData']),
    clients() {
      return this.accountClients
    },
    breadcrumbsItems() {
      let text =
        this.$route.params.serviceName === 'Fotovoltaico'
          ? 'Fotovoltaico'
          : this.$route.params.serviceName === 'Luce e Gas'
          ? 'Luce e gas'
          : 'Comunità energetiche'
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        {
          to: {
            name: 'Faq',
            params: {
              serviceName: this.$route.params.serviceName,
              categoryName: this.$route.params.categoryName,
              subCategories: this.$route.params.subCategories,
              media: this.$route.params.media
            }
          },
          text: text
        },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Invio richiesta'
        }
      ]

      return res
    },
    subtitle() {
      if (this.$route.params.serviceName === 'Fotovoltaico') {
        return 'Il mio impianto'
      } else return 'Utenza'
    },
    impiantoOrUtenza() {
      if (this.$route.params.serviceName === 'Fotovoltaico') {
        return 'impianto'
      } else return 'utenza'
    },
    name() {
      if (this.$route.params.serviceName === 'Fotovoltaico') {
        return this.defineName(this.filteredSuppliesFotovoltaico)
      } else if (this.$route.params.serviceName === 'Luce e Gas') {
        return this.defineName(this.filteredSuppliesGasEle)
      } else return ''
    },
    address() {
      if (this.$route.params.serviceName === 'Fotovoltaico') {
        return this.defineAddress(
          this.filteredSuppliesFotovoltaico[0].services[0]
        )
      } else if (this.$route.params.serviceName === 'Luce e Gas') {
        return this.defineAddress(this.filteredSuppliesGasEle[0].services[0])
      } else return ''
    },
    titleServiceUnavailableDialog() {
      if (this.response === 'ok') {
        return 'Richiesta inviata'
      } else {
        if (this.response === 'KO-nonInviata') {
          return 'Richiesta non inviata'
        } else return 'Riprova più tardi'
      }
    },
    bodyServiceUnavailableDialog() {
      if (this.response === 'ok') {
        return 'La richiesta è stata inviata correttamente,'
      } else {
        if (this.response === 'KO-nonInviata') {
          return 'La richiesta non è stata inviata correttamente.'
        } else
          return 'Siamo spiacenti ma per un errore temporaneo non riusciamo a farti proseguire'
      }
    },
    bodyStrongServiceUnavailableDialog() {
      if (this.response === 'ok') {
        return 'riceverai riscontro dai nostri consulenti via e-mail il prima possibile.'
      } else {
        if (this.response === 'KO-nonInviata') {
          return ' Inviala di nuovo o vai alle FAQ.'
        } else return ''
      }
    },
    // messageDialogC() {
    //   if (this.response === 'ok') {
    //     return 'dai nostri consulenti via e-mail il prima possibile.'
    //   } else {
    //     if (this.response === 'KO-nonInviata') {
    //       return 'o vai alle FAQ.'
    //     } else return ''
    //   }
    // },
    cta1ServiceUnavailableDialog() {
      if (this.response === 'ok') {
        return `Torna all'homepage`
      } else {
        if (this.response === 'KO-nonInviata') {
          return 'Invia di nuovo'
        } else return 'Indietro'
      }
    },
    filteredSuppliesGasEle() {
      return Object.entries(
        this.supplies
          .filter(
            (oggetto) =>
              (oggetto.serviceType === this.SERVICE_TYPE.GAS ||
                oggetto.serviceType === this.SERVICE_TYPE.ELE) &&
              oggetto.supplyState !== this.SUPPLY_STATUS.STOPPED &&
              oggetto.supplyState !== this.SUPPLY_STATUS.REJECTED &&
              oggetto.supplyState !== this.SUPPLY_STATUS.ACTIVATING
          )
          .reduce((groupedSupplies, oggetto) => {
            const key = oggetto.businessName
            if (!groupedSupplies[key]) {
              groupedSupplies[key] = {
                clientOwnerType: oggetto.clientOwnerType,
                services: []
              }
            }

            const type =
              oggetto.serviceType === this.SERVICE_TYPE.GAS ? 'gas' : 'ele'
            groupedSupplies[key].services.push(
              this.createServiceObject(oggetto, type)
            )

            return groupedSupplies
          }, {})
      ).map(([key, value]) => ({
        businessName: this.capitalizeNames(key),
        clientOwnerType: value.clientOwnerType,
        services: value.services
      }))
    },
    filteredSuppliesFotovoltaico() {
      return Object.entries(
        this.supplies
          .filter(
            (oggetto) =>
              oggetto.serviceType === this.SERVICE_TYPE.PHOTOVOLTAIC &&
              oggetto.supplyState !== this.SUPPLY_STATUS.REJECTED
          )
          .reduce((groupedSupplies, oggetto) => {
            const key = oggetto.businessName
            if (!groupedSupplies[key]) {
              groupedSupplies[key] = {
                clientOwnerType: oggetto.clientOwnerType,
                services: []
              }
            }

            groupedSupplies[key].services.push(
              this.createServiceObject(oggetto, 'fotovoltaico')
            )

            return groupedSupplies
          }, {})
      ).map(([key, value]) => ({
        businessName: this.capitalizeNames(key),
        clientOwnerType: value.clientOwnerType,
        services: value.services
      }))
    }
  },
  methods: {
    ...mapActions('support', ['createRequest', 'callMeBackUtente']),
    ...mapActions('analytics', ['trackTapEvent']),

    selectAddress(service, businessName) {
      const [firstName, lastName] = businessName.split(' ')
      this.nome = service.firstName || firstName
      this.cognome = service.lastName || lastName
      this.selectedAddress =
        service.street + ', ' + service.zipCode + ' ' + service.city
      this.puntoDiFornitura = service.supplyCode
      this.codiceCliente = service.clientOwnerCode
      this.openSelectModal = false
      this.validateAddress = true
      this.product = service.type
      this.clientOwnerType = service.clientOwnerType
      this.IDsito = service.siteId || ''
      this.IDACU = service.acuId
    },
    async onSubmit(dataForm) {
      this.load = true
      if (this.$route.params.serviceName === 'Luce e Gas') {
        const [firstName, lastName] = this.name.split(' ')
        const body = {
          cognome: lastName || this.cognome,
          email: this.clientDetails.contactMail,
          nome: firstName || this.nome,
          codiceCliente: this.codiceCliente || this.supplies[0].clientOwnerCode,
          login: this.usernameOrEmail,
          product: this.product || this.supplies[0].serviceType,
          indirizzoDiFornitura: this.address || this.selectedAddress,
          puntoDiFornitura:
            this.puntoDiFornitura || this.supplies[0].supplyCode,
          fieldsCommodity: {
            commodity: 'richiesteInformative',
            tipoCommodity: this.categories.mapping[dataForm.dettagliRichiesta],
            note: dataForm.messaggio
          }
        }
        this.trackTapEvent({
          name: 'support_infoRequestSend_tap',
          params: {
            supply_type: this.translateSupplyType(
              this.product || this.supplies[0].serviceType
            ),
            client_code: this.codiceCliente || this.supplies[0].clientOwnerCode,
            client_type:
              this.clientOwnerType || this.supplies[0].clientOwnerType
          }
        })
        try {
          const res = await this.createRequest(body)
          if (res.status === 'OK') {
            this.response = 'ok'
            this.showModal = true
          } else if (res.status === 'KO') {
            this.response = 'KO-nonInviata'
            this.showModal = true
          } else {
            this.response = 'KO-500'
            this.showModal = true
          }
        } catch (err) {
          console.error(err)
          this.response = 'KO-500'
          this.showModal = true
        }
      } else {
        const [firstName, lastName] = this.name.split(' ')
        const body = {
          nome: firstName || this.nome,
          cognome: lastName || this.cognome,
          tipoRichiesta: 'PHOTOVOLTAIC_ASSISTANCE', //'PHOTOVOLTAIC_ASSISTANCE', //per ora non funziona ancora PHOTOVOLTAIC_ASSISTANCE
          mailLogin: this.usernameOrEmail,
          telefono: this.telefono,
          canaleProvenienza: 'MYS',
          photovoltaicAssistanceData: {
            acuId:
              this.IDACU ||
              this.filteredSuppliesFotovoltaico[0].services[0].siteId,
            siteId:
              this.IDsito ||
              this.filteredSuppliesFotovoltaico[0].services[0].siteId,
            requestCategory:
              this.categories.mapping[dataForm.tipologiaRichiesta],
            requestDetails: this.categories.mapping[dataForm.dettagliRichiesta],
            message: dataForm.messaggio
          }
        }
        try {
          const res = await this.callMeBackUtente(body)
          if (res.status === 'OK') {
            this.response = 'ok'
            this.showModal = true
          } else if (res.status === 'KO') {
            this.response = 'KO-nonInviata'
            this.showModal = true
          } else {
            this.response = 'KO-500'
            this.showModal = true
          }
        } catch (err) {
          console.error(err)
          this.response = 'KO-500'
          this.showModal = true
        }
      }
      this.load = false
    },
    translateSupplyType(type) {
      const translationTable = {
        luce: 'light',
        gas: 'gas'
      }

      return translationTable[type.toLowerCase()]
    },
    goHomeOrGoForm() {
      if (this.response === 'ok') {
        this.$router.push({ name: 'home' })
      } else this.showModal = false
    },
    goFAQ() {
      this.$router.push({
        name: 'Faq',
        params: {
          serviceName: this.$route.params.serviceName,
          categoryName: this.$route.params.categoryName,
          subCategories: this.$route.params.subCategories,
          media: this.$route.params.media
        }
      })
    },
    defineAddress(array) {
      return array
        ? array.street.substring(0, 1).toUpperCase() +
            array.street.substring(1, array.street.length).toLowerCase() +
            ', ' +
            array.city +
            ' ' +
            array.zipCode
        : ''
    },
    defineName(array) {
      if (array) {
        if (array[0].services[0].firstName !== '') {
          return (
            capitalizeFirstLetter(array[0].services[0].firstName) +
            ' ' +
            capitalizeFirstLetter(array[0].services[0].lastName)
          )
        } else {
          const [firstName, lastName] = array[0].businessName.split(' ')
          return (
            capitalizeFirstLetter(firstName) +
            ' ' +
            capitalizeFirstLetter(lastName)
          )
        }
      } else return ''
    },
    setOpenSelectModal() {
      this.openSelectModal = !this.openSelectModal
    },

    handleBackButton(event) {
      if (event.type === 'popstate' && !this.isNavigating) {
        event.preventDefault()
        this.isNavigating = true
        this.$router.back()
      }
    },
    handleBeforeUnload(event) {
      if (!this.isNavigating) {
        this.isNavigating = true
        event.preventDefault()
        event.returnValue = ''
      }
    },
    setShowModal() {
      this.showModal = false
    },
    createServiceObject(oggetto, type) {
      return {
        type,
        city: capitalizeFirstLetter(oggetto.city),
        street: capitalizeFirstLetter(oggetto.street),
        zipCode: oggetto.zipCode,
        supplyCode: oggetto.supplyCode,
        clientOwnerCode: oggetto.clientOwnerCode,
        clientOwnerType: oggetto.clientOwnerType,
        paymentStatus: oggetto.paymentStatus,
        firstName: capitalizeFirstLetter(oggetto.firstName),
        lastName: capitalizeFirstLetter(oggetto.lastName),
        siteId: oggetto.photovoltaicData
          ? oggetto.photovoltaicData.siteId
          : null,
        acuId: oggetto.acuId || null,
        installationStatus: oggetto.photovoltaicData
          ? oggetto.photovoltaicData.installationStatus
          : null
      }
    },
    capitalizeNames(name) {
      const [firstName, lastName] = name.split(' ')
      return (
        capitalizeFirstLetter(firstName) + ' ' + capitalizeFirstLetter(lastName)
      )
    }
    // messageDialog() {
    //   if (this.response === 'ok') {
    //     this.titleServiceError = 'Richiesta inviata'
    //     this.bodyServiceError = 'La richiesta è stata inviata correttamente, '
    //     this.strongBodyServiceError =
    //       'riceverai riscontro dai nostri consulenti via e-mail il prima possibile.'
    //     this.cta1 = `Torna all'homepage`
    //   }
    //   if (this.response === 'KO-nonInviata') {
    //     this.titleServiceError = 'Richiesta non inviata'
    //     this.bodyServiceError =
    //       'La richiesta non è stata inviata correttamente.'
    //     this.strongBodyServiceError = ' Inviala di nuovo o vai alle FAQ.'
    //     this.cta1 = 'Invia di nuovo'
    //   }
    //   if (this.response === 'KO-500') {
    //     this.titleServiceError = 'Riprova più tardi'
    //     this.bodyServiceError =
    //       'Siamo spiacenti ma per un errore temporaneo non riusciamo a farti proseguire'
    //     this.cta1 = 'Indietro'
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.selectDialogContainer {
  padding: 40px;
  .headerSelect {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    img {
      cursor: pointer;
    }
  }
}

.supplyOwner {
  display: flex;
  padding-left: 16px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.supplyAddress {
  height: 72px;
  display: flex;
  justify-content: space-between;
  border: 1px solid $neutri-neutral-30;
  border-radius: 20px;
  padding: 16px;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.supplySection {
  display: flex;
  align-items: center;
}
.select {
  cursor: pointer;
  color: $srg-blue;
}
.v-textarea {
  border-radius: 8px;
}
.custoMLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1.2px;
  color: #6e7689;
}
</style>

<style lang="scss">
.customArea {
  .v-messages__message {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    &::before {
      content: url('../../assets/icons/InfoSupport.svg');
      margin-right: 5px;
    }
  }
}

.faq {
  .v-text-field {
    padding-top: 1px;
    margin-top: 4px;
  }
}

.customDialog {
  border-radius: 20px;
  background-color: white;
}
</style>
