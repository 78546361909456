<template>
  <div class="login">
    <TcVars />
    <div class="login__content">
      <img
        src="../../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <div class="login__box">
        <div class="login__title HeadingsSubtitleXXL">Registrati</div>
        <div class="login__subTitle BodyL-Strong">
          Scegli come entrare in Sorgenia.
        </div>

        <div class="d-flex flex-column justify-space-between">
          <div
            class="choise_card d-flex flex-row justify-center align-center"
            @click="
              $router.push({
                name: 'RegistrationUserData',
                params: { clientType: 'withContract' }
              })
            "
          >
            <div class="d-flex flex-column align-start justify-space-between">
              <div class="choise_card__header CaptionM">
                HO UN CONTRATTO LUCE O GAS
              </div>
              <div class="choise_card__subtitle BodyM">
                Vai alla registrazione con il tuo codice cliente
              </div>
            </div>
            <img
              class="choise_card__icon"
              src="../../assets/icons/chevron_rx.svg"
            />
          </div>

          <div
            class="choise_card d-flex flex-row justify-center align-center"
            @click="
              $router.push({
                name: 'RegistrationUserData',
                params: { clientType: 'withOutContract' }
              })
            "
          >
            <div class="d-flex flex-column align-start justify-space-between">
              <div class="choise_card__header CaptionM">
                NON HO UN CONTRATTO
              </div>
              <div class="choise_card__subtitle BodyM">
                Vai alla registrazione e inserisci i tuoi dati
              </div>
            </div>
            <img
              class="choise_card__icon"
              src="../../assets/icons/chevron_rx.svg"
            />
          </div>
        </div>
        <div class="footer BodyL">
          Hai già le credenziali MySorgenia o MyNextMove?
          <router-link :to="{ name: 'login' }" class="BodyL-Strong"
            >Vai al Login</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'RegistrationChoise',
  data() {
    return {}
  },
  components: {
    TcVars
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../../styles/authentication/auth.scss';
.choise_card {
  cursor: pointer;
  border: solid 1px #dfe4ef;
  border-radius: 20px;
  padding: 18px 21px;
  margin-bottom: 20px;
  width: -webkit-fill-available;
  &__icon {
    margin: auto !important;
    margin-right: 5px !important;
  }
  &__header {
    color: $brand-blue;
  }
  &__subtitle {
    margin-top: 6px;
  }
}
.footer {
  margin: 32px -6px 0px;
}
</style>
