<template>
  <ExtensibleButtonGroup
    v-model="activeTab"
    :per-row="2"
    :content="true"
    :hideContentOnBlur="false"
  >
    <ExtensibleButton
      v-for="(type, i) in types"
      :key="i"
      :name="type.name"
      :label="type.label"
    >
      <v-row>
        <v-col>
          <div class="d-flex align-center">
            <h4 class="mr-3">
              Inserisci le informazioni necessarie per procedere alla richiesta
              di {{ type.text }} potenza
            </h4>
            <img src="../../assets/icons/info.svg" @click="dialogHelp = true" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-6">
          <h5>Scegli la tensione</h5>
          <v-select
            v-model="selectedType"
            :items="actions.types"
            placeholder="Seleziona"
            persistent-placeholder
            dense
          />
        </v-col>
        <v-col class="col-6">
          <h5>Scegli la potenza</h5>
          <v-select
            v-model="selectedPower"
            :items="power[type.name]"
            placeholder="Seleziona"
            persistent-placeholder
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-6">
          <h4>Messaggio</h4>
          <v-textarea
            v-model="message"
            rows="3"
            solo
            name="message"
            label="Scrivi quello di cui hai bisogno"
          />
        </v-col>
        <v-col class="col-6">
          <h5>
            Inserisci il numero sul quale vuoi essere ricontattato per la
            gestione di questa richiesta
          </h5>
          <v-text-field
            ref="phone"
            @input="filterPhone"
            :value="phone"
            placeholder="Numero di telefono"
            persistent-placeholder
          />
        </v-col>
      </v-row>
      <DialogContextualHelp
        v-model="dialogHelp"
        :clientCode="$route.params.clientOwnerCode"
        :idContextualHelp="
          activeTab === 'aumentoPotenza'
            ? 'help-increase-power'
            : 'help-decrease-power'
        "
      />
    </ExtensibleButton>
  </ExtensibleButtonGroup>
</template>
<script>
import { mapGetters } from 'vuex'
import { actions } from '@/js/constants'
import DialogContextualHelp from '@/components/contextualHelp/Dialog'

export default {
  name: 'PowerChange',
  data() {
    return {
      activeTab: '',
      selectedType: '',
      selectedPower: '',
      phone: '',
      message: '',
      types: [
        { label: 'Aumento potenza', text: 'aumento', name: 'aumentoPotenza' },
        {
          label: 'Diminuzione potenza',
          text: 'diminuzione',
          name: 'diminuzionePotenza'
        }
      ],
      dialogHelp: false
    }
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail']),
    power() {
      const power = this.supplyDetail
        ? this.supplyDetail[this.$route.params.supplyCode].power
        : null
      if (power) {
        const index = this.actions.items.indexOf(Number(power))
        const arr = [...this.actions.items]
        const max = arr.splice(index + 1)
        const min = arr.splice(0, index)
        return { aumentoPotenza: max, diminuzionePotenza: min }
      }
      return {
        aumentoPotenza: actions.power,
        diminuzionePotenza: actions.power
      }
    },
    actions() {
      return actions.power
    },
    data() {
      if (
        this.phone !== '' &&
        this.phone !== undefined &&
        this.message !== '' &&
        this.selectedType !== '' &&
        this.selectedPower !== ''
      ) {
        return {
          telefono: this.phone[0],
          tensione: this.selectedType,
          potenza: this.selectedPower,
          note: this.message,
          pod: this.$route.params.supplyCode,
          commodity: this.activeTab
        }
      }
      return null
    }
  },
  watch: {
    data(value) {
      this.$emit('update:data', value)
    }
  },
  methods: {
    filterPhone(val) {
      if (val) {
        this.phone = val.match(/[0-9]+/) || this.$refs.phone[0].reset()
      } else {
        this.phone = ''
      }
    }
  },
  components: {
    DialogContextualHelp
  }
}
</script>
<style lang="scss" scoped></style>
