<template>
  <div>
    <div class="text-center" v-if="load">
      <v-progress-circular :size="100" indeterminate color="#12256A" />
    </div>
    <div v-else>
      <VueApexCharts :options="chartOptions" :series="series" height="350" />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  components: { VueApexCharts },
  props: {
    series: { type: Array, required: true },
    categories: { type: Array, required: true },
    columnWidth: { type: Number, required: true },
    load: { type: Boolean },
    currentOptionList: { String },
    chartLabelY: { String }
  },
  data: () => {
    return {
      chartOptions: {
        legend: {
          show: false
        },
        stacked: true,
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          tooltip: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: 50
          }
        },
        grid: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return value.toFixed(0) + ' '
            }
          }
        },
        xaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          categories: [
            'Gen',
            'Feb',
            'Mar',
            'Apr',
            'Mag',
            'Giu',
            'Lug',
            'Ago',
            'Set',
            'Ott',
            'Nov',
            'Dic'
          ],
          labels: {
            style: {
              colors: '#12256A',
              fontSize: '12px',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 700
            }
          }
        }
      }
    }
  },
  created() {
    if (this.currentOptionList === 'Giorno') {
      this.chartOptions.plotOptions.bar.columnWidth = 15
      this.chartOptions.xaxis.categories = [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ]
    }
  },
  created() {
    switch (this.chartLabelY) {
      case 'consumo':
        this.chartOptions.yaxis = {
          ...this.chartOptions.yaxis,
          labels: {
            formatter: (value) => {
              return value.toFixed(0) + ' kWh'
            }
          }
        }
        break
      case 'produzione':
        this.chartOptions.yaxis = {
          ...this.chartOptions.yaxis,
          labels: {
            formatter: (value) => {
              return value.toFixed(0) + ' kWh'
            }
          }
        }
        break
      case 'impatto':
        this.chartOptions.yaxis = {
          ...this.chartOptions.yaxis,
          labels: {
            formatter: (value) => {
              return value.toFixed(0) + ' t'
            }
          }
        }
        break
      case 'risparmio':
        this.chartOptions.yaxis = {
          ...this.chartOptions.yaxis,
          labels: {
            formatter: (value) => {
              return value.toFixed(0) + ' €'
            }
          }
        }
        break
      default:
        break
    }
  },
  computed: {
    ...mapGetters('fotovoltaico', [
      'getSavingDataChart',
      'getConsumptionDataChart',
      'getProductionDataTemp',
      'getEnviromentallImpanct'
    ])
  },
  methods: {
    makeBG(elem, i) {
      var svgns = 'http://www.w3.org/2000/svg'
      var bounds = elem.getBBox()
      var bg = document.createElementNS(svgns, 'rect')
      var style = getComputedStyle(elem)
      var padding_top = parseInt(style['padding-top'])
      var padding_left = parseInt(style['padding-left'])
      var padding_right = parseInt(style['padding-right'])
      var padding_bottom = parseInt(style['padding-bottom'])
      if (this.columnWidth !== 15) {
        padding_top *= 2
        padding_left *= 4
        padding_right *= 4
        padding_bottom *= 2
      }
      bg.setAttribute('x', bounds.x - padding_left)
      bg.setAttribute('y', bounds.y - padding_top)
      bg.setAttribute('width', bounds.width + padding_left + padding_right)
      bg.setAttribute('height', bounds.height + padding_top + padding_bottom)
      bg.setAttribute('fill', style['background-color'])
      bg.setAttribute('rx', style['border-radius'])
      bg.setAttribute('stroke-width', style['border-top-width'])
      bg.setAttribute('stroke', style['border-top-color'])
      if (elem.hasAttribute('transform')) {
        bg.setAttribute('transform', elem.getAttribute('transform'))
      }
      elem.parentNode.insertBefore(bg, elem)
    },
    showFirstLastValueY(element, i, labelY) {
      element.classList.add('d-none')
      if (i === 0 || i === labelY.length - 1) {
        element.classList.remove('d-none')
      }
    },
    disabledZeroValuesLabel(element, i, dataChart) {
      if (dataChart && (!dataChart[i] || dataChart[i] === 0)) {
        element.classList.add('disabled-label')
      } else {
        element.classList.remove('disabled-label')
      }
    },

    useStoreFromChart(value) {
      switch (value) {
        case 'consumo':
          return this.getConsumptionDataChart?.series[0].data
        case 'produzione':
          break
        case 'impatto':
          return this.getEnviromentallImpanct?.data?.values?.series[0].data
        case 'risparmio':
          return this.getSavingDataChart?.series[0].data
        default:
          break
      }
    }
  },
  mounted() {
    const labelX = document.getElementsByClassName('apexcharts-xaxis-label')
    const labelY = document.getElementsByClassName('apexcharts-yaxis-label')
    let dataChart = this.useStoreFromChart(this.chartLabelY)
    labelY.forEach((element, i, labelY) => {
      this.showFirstLastValueY(element, i, labelY)
    })
    labelX.forEach((element, i) => {
      this.makeBG(element, i)
      this.disabledZeroValuesLabel(element, i, dataChart)
    })
  },
  updated() {
    const labelX = document.getElementsByClassName('apexcharts-xaxis-label')
    const labelY = document.getElementsByClassName('apexcharts-yaxis-label')
    let dataChart = this.useStoreFromChart(this.chartLabelY)
    labelY.forEach((element, i, labelY) => {
      this.showFirstLastValueY(element, i, labelY)
    })
    labelX.forEach((element, i) => {
      this.makeBG(element, i)
      this.disabledZeroValuesLabel(element, i, dataChart)
    })
  },
  watch: {
    categories: function (newVal) {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: { ...this.chartOptions.xaxis, categories: [...newVal] },
        plotOptions: {
          ...this.chartOptions.plotOptions,
          bar: {
            ...this.chartOptions.plotOptions.bar,
            columnWidth: this.columnWidth
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.apexcharts-xaxis-label {
  background: transparent;
  border-radius: 12px;
  border: 1px solid #dfe4ef;
  padding: 4px;
}

.disabled-label {
  tspan {
    fill: $neutri-neutral-40;
  }
}
</style>
