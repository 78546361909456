<template>
  <div class="dashboard-header-status">
    <div class="dashboard-header-status-left">
      <div>
        <h2 class="HeadingL">Stato dell'impianto</h2>
      </div>
      <div class="dashboard-sync">
        <div>
          <img class="mr-2" :src="icon" />
        </div>
        <div>
          <div>
            <strong class="BodyM-Strong copySync"
              >Aggiornamento al {{ parseDate }}</strong
            >
          </div>
          <div class="BodyM">{{ subheader }}</div>
        </div>
      </div>
    </div>
    <div v-if="isMulti">
      <DashboardChangeAddress></DashboardChangeAddress>
    </div>
  </div>
</template>
<script>
import Clock from '@assets/profile/clock.svg'
import Offline from '@assets/fotovoltaico/offline.svg'
import Error from '@assets/fotovoltaico/error.svg'
import DashboardChangeAddress from './DashboardChangeAddress.vue'
import { OVERALL_STATUS } from '@/js/constants.js'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    lastUpdate: { type: String },
    connectionStart: { type: String },
    overallStatus: { type: String, required: true },
    isMulti: { type: Boolean }
  },
  components: {
    DashboardChangeAddress
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('fotovoltaico', [
      'getInstallationData',
      'getFlagEnterByDeeplink',
      'getPhotovoltaicData'
    ]),
    icon() {
      if (this.overallStatus === OVERALL_STATUS.NOT_COMMUNICATING) {
        return Offline
      }
      if (this.overallStatus === OVERALL_STATUS.ALARM) {
        return Error
      }
      return Clock
    },

    parseDate() {
      let dataOra
      if (this.lastUpdate) {
        dataOra = new Date(this.lastUpdate).toLocaleString({
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      } else {
        dataOra = new Date(this.connectionStart).toLocaleString({
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      }
      dataOra = dataOra.slice(0, -3)
      return dataOra
    },

    subheader() {
      if (
        this.overallStatus === OVERALL_STATUS.NOT_COMMUNICATING ||
        this.overallStatus === OVERALL_STATUS.UNKNOWN
      ) {
        return 'Aggiornamento dati non disponibile'
      }
      return ''
    }
  },
  methods: {
    ...mapActions('fotovoltaico', ['retrievePhotovoltaicData'])
  }
}
</script>
<style lang="scss" scoped>
.dashboard-header-status {
  display: flex;
  justify-content: space-between;

  .dashboard-header-status-left {
    display: flex;

    h2 {
      font-weight: 500;
      letter-spacing: 0.8px;
      line-height: 52px;
    }
    .dashboard-sync {
      margin-left: 32px;
      display: flex;
      align-items: center;
      img {
        width: 24px;
      }
    }
    .copySync {
      display: flex;
      align-items: center;
    }
  }
}
</style>
