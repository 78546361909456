import { axiosACN } from '@/js/axiosInstances.js'
import { getPromiseSingletone } from '@/js/service/promiseStatus'
import PaymentStatus from '../../js/service/alerts/payment-status'
import AbnormalConsuption from '../../js/service/alerts/abnormal-consuption'
import SupplyAlerts from '../../js/service/alerts/supply-alerts'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    alert: null,
    flagAlert: false,
    flagAlertConsumi: false,
    resetAlertCalled: false
  },
  getters: {
    alert(state) {
      return state.alert
    },
    flagAlert(state) {
      return state.flagAlert
    },
    flagAlertConsumi(state) {
      return state.flagAlertConsumi
    }
  },
  mutations: {
    resetState(state) {
      delete window.retrievePaymentStatusV2Promise
      delete window.retrieveAbnormalConsumptionPromise
    },
    setAlert(state, alert) {
      state.alert = alert
    },
    setFlagAlert(state, flagAlert) {
      state.flagAlert = flagAlert
    },
    setFlagAlertConsumi(state, flagAlertConsumi) {
      state.flagAlertConsumi = flagAlertConsumi
    }
  },
  actions: {
    async initSuppliesAlerts({ commit, dispatch, rootGetters }) {
      let clients = await dispatch('account/getMainClient', false, {
        root: true
      })
      if (!clients) return Promise.resolve([])
      let clientsIds = !clients.isOnlyFV
        ? Object.keys(rootGetters['account/accountClients'])
        : []
      let acuIdList = Object.keys(rootGetters['account/accountAcuIdList'])
      if (
        !window.retrievePaymentStatusV2Promise ||
        !getPromiseSingletone(window.retrievePaymentStatusV2Promise)
      ) {
        window.retrievePaymentStatusV2Promise =
          PaymentStatus.retrivePaymentStatus(
            clientsIds,
            acuIdList,
            rootGetters['session/token']
          )
      }
      let paymentsAlert = await window.retrievePaymentStatusV2Promise

      if (
        (!window.retrieveAbnormalConsumptionPromise ||
          !getPromiseSingletone(window.retrieveAbnormalConsumptionPromise)) &&
        !clients.isOnlyFV
      ) {
        window.retrieveAbnormalConsumptionPromise =
          AbnormalConsuption.retrieveAbnormalConsumption(
            clientsIds,
            rootGetters['session/token']
          )
      }
      let dataForFv = {
        data: {
          listaClienti: []
        }
      }

      let consumptionsAlert = !clients.isOnlyFV
        ? await window.retrieveAbnormalConsumptionPromise
        : dataForFv

      let timelineComm = await dispatch('tracking/getTimelineComm', false, {
        root: true
      })
      let timelineFiber = await dispatch('tracking/getTimeline', false, {
        root: true
      })
      commit(
        'setFlagAlert',
        paymentsAlert.data.clientList.find(
          (alert) => !!alert.suppliesList.flagAlert
        ) || false
      )
      commit(
        'setFlagAlertConsumi',
        consumptionsAlert.data.listaClienti.find(
          (alert) => !!alert.listaForniture.flagAlert
        ) || false
      )
      let alerts = PaymentStatus.getPaymentStatusClientList(paymentsAlert)
      // let alerts = [
      //   {
      //     ele: {
      //       flagAlert: false,
      //       points: []
      //     },
      //     gas: {
      //       flagAlert: false,
      //       points: []
      //     },
      //     fiber: {
      //       points: []
      //     },
      //     photovoltaic: {
      //       photovoltaicStatus: 'ALARM',
      //       points: [
      //         {
      //           supplyType: 'PHOTOVOLTAIC',
      //           photovoltaicData: {
      //             siteId: '100000400100000',
      //             installationStatus: 'NOT_COMUNICATING',
      //             notWorkingDeviceList: [],
      //             notWorkingDeviceCount: 1
      //           },
      //           planList: []
      //         },
      //         {
      //           supplyType: 'PHOTOVOLTAIC',
      //           photovoltaicData: {
      //             siteId: '100000410200000',
      //             installationStatus: 'NOT_COMUNICATING',
      //             notWorkingDeviceList: [],
      //             notWorkingDeviceCount: 1
      //           },
      //           planList: []
      //         }
      //       ]
      //     }
      //   }
      // ]
      let consumptions =
        PaymentStatus.getPaymentStatusConsumptionsAlert(consumptionsAlert)

      let timelineAlerts = {
        ele: {
          inAttivationList:
            timelineComm.array.filter((item) => item.servizio === 'Ele') || []
        },
        gas: {
          inAttivationList:
            timelineComm.array.filter((item) => item.servizio === 'Gas') || []
        },
        fiber: {
          inAttivationList: timelineFiber.timelineFibra || []
        }
        // per fotovoltaico, il servizio retrievePaymentStatus, non ritorna nessuna informazione sullo stato della fornitura (ATTIVO o IN ATTIVAZIONE)
        // nemmeno il servizio timelineCommodity. L'unico modo è prelevare l'informazione dalla retrieveHomePageData
      }
      let accountProperties = rootGetters['account/accountProperties']

      return new Promise((resolve, reject) => {
        let dataAlerts = SupplyAlerts.getAlerts(
          alerts,
          consumptions,
          timelineAlerts,
          accountProperties
        )
        commit('setAlert', dataAlerts)
        resolve(dataAlerts)
      })
    },
    async resetFlagAlert({ state, commit, dispatch, rootGetters }) {
      let clients = await dispatch('account/getMainClient', false, {
        root: true
      })
      if (state.flagAlertConsumi && !state.resetAlertCalled) {
        if (!clients) return Promise.resolve([])

        let clientsIds = Object.keys(rootGetters['account/accountClients'])
        try {
          axiosACN
            .post(
              'bollette/sendAlertConsumption',
              {
                listaClienti: clientsIds
              },
              {
                headers: {
                  Authorization: 'Bearer ' + rootGetters['session/token']
                }
              }
            )
            .then(() => {
              state.resetAlertCalled = true
            })
        } catch (err) {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        }
      }
    }
  }
}
