<template>
  <div class="activation">
    <div class="activation__box">
      <div class="title">Che cosa è l’attivazione anticipata?</div>
      <h5 class="subTitle">Diritto di ripensamento</h5>
      <h5 class="desc">
        Selezionando questa opzione richiedi di avviare anticipatamente la
        procedura per l’attivazione della tua fornitura prima del termine per
        l’esercizio del diritto di ripensamento, secondo quanto previsto
        dall’art. 5 delle Condizioni Generali di Contratto. Ricordati che potrai
        comunque esercitare il tuo diritto di ripensamento entro i termini
        previsti per legge (14 giorni dalla conclusione del contratto) ma,
        qualora Sorgenia avesse già avviato la fornitura e/o sostenuto dei costi
        per il suo avvio, sarai tenuto a corrisponderli ai sensi del Contratto.
      </h5>
    </div>
    <h5 class="subTitle">Hai bisogno di anticipare l’attivazione?</h5>
    <div
      v-if="getActivationDataFiber.attivazione"
      class="activation__btn"
      @click="setAfterthought(false)"
    >
      <h5>ATTIVA IL {{ getActivationDataFiber.attivazione }}</h5>
      <h5>Attivazione Standard</h5>
    </div>
    <div
      v-if="getActivationDataFiber.attivazioneAnticipata"
      class="activation__btn"
      @click="setAfterthought(true)"
    >
      <h5>ATTIVA IL {{ getActivationDataFiber.attivazioneAnticipata }}</h5>
      <h5>Attivazione Anticipata</h5>
    </div>
  </div>
</template>
<script>
import { get } from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'activationTimes',
  data() {
    return {
      dacAfterthought: '',
      dacNoAfterthought: ''
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', [
      'client',
      'speedUpProcess',
      'addressCovered',
      'migrationCode',
      'connectionType',
      'getActivationDataFiber'
    ])
  },
  beforeMount() {
    let body = {
      via: this.addressCovered.street,
      civicoEgon: this.addressCovered.egonStreetNumber
    }
    if (this.migrationCode.checked == true) {
      body.migrationCode = this.migrationCode.code
    }
    if (this.addressCovered.egonStreetNumberLetter) {
      body.esponente = this.addressCovered.egonStreetNumberLetter
    }
    this.retrieveActivationDataFiber(body)
  },
  methods: {
    ...mapActions('upsellingFiber', ['retrieveActivationDataFiber']),
    ...mapMutations('upsellingFiber', ['setSpeedUpProcess']),
    ...mapActions('analytics', ['trackTapEvent']),
    setAfterthought(val) {
      if (!val) {
        this.trackTapEvent({
          name: 'upsellingv2_keepafterthought_toggle',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else {
        this.trackTapEvent({
          name: 'upsellingv2_renounceafterthought_toggle',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      }
      this.setSpeedUpProcess(val)
      this.$emit('nextStep', 'upsellingv2_identitydocument_open')
    }
  }
}
</script>
<style lang="scss" scoped>
.activation {
  max-width: 652px;
  color: black;
  &__info {
    font-weight: 400;
    margin-bottom: 24px;
  }
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .subTitle {
    font-weight: 500;
    margin-bottom: 16px;
  }
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 88px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
    h5 {
      color: black;
      &:first-child {
        font-weight: 500;
      }
    }
  }
}
</style>
