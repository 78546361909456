<template>
  <div
    :class="[
      'loyalty',
      headerSize === 'full' ? 'loyalty--' + headerSize : '',
      { 'loyalty-greeners-header': sectionLoyalty }
    ]"
  >
    <v-row
      :class="[
        'header-main',
        headerSize ? 'header-main--' + headerSize : '',
        { 'header-main--with-bg-img': headerWithBgImg }
      ]"
    >
      <slot name="header" />
    </v-row>
    <div
      :class="[
        sectionLoyalty
          ? 'loyalty__content-wrap loyalty-greeners'
          : 'loyalty__content-wrap'
      ]"
      v-show="$slots.default"
    >
      <v-row :class="{ loyalty__content: !sectionLoyalty }">
        <slot />
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'loyaltyPage',
  props: {
    headerSize: { type: String },
    headerWithBgImg: { type: Boolean, default: false },
    sectionLoyalty: { type: Boolean, default: false }
  },
  beforeMount() {
    this.getMainClient().then((resp) => {
      if (this.mainClientLoyaltySelected) {
        resp = this.mainClientLoyaltySelected
      }
      this.getDetailForClient({ clientOwnerCode: resp.clientOwnerCode })
    })
  },
  computed: {
    ...mapGetters('account', ['mainClientLoyaltySelected'])
  },
  methods: {
    ...mapActions('account', ['getMainClient', 'getDetailForClient'])
  }
}
</script>

<style lang="scss" scoped>
.loyalty {
  background-color: $srg-green;
  h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.8px;
    color: black;
  }

  .loyalty-greeners {
    padding-bottom: 0;
  }

  &.loyalty-greeners-header {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 500px;
      background: linear-gradient(
        90deg,
        #97d700 49.58%,
        rgba(196, 196, 196, 0) 100%
      );
      left: 0;
      top: 0;
      position: absolute;
      z-index: 2;
    }
    &:after {
      position: absolute;
      content: '';
      top: 0;
      width: 100%;
      background-image: url('../../assets/greeners/header-greeners.png');
      height: 450px;
      background-repeat: no-repeat;
      background-position-x: right;
      z-index: 0;
    }
  }

  &--full {
    height: 100%;
  }

  .breadcrumbsGreeners {
    position: absolute;
    top: 20px;
    padding-left: 12px;
  }
  .header {
    position: relative;
    &-info__title {
      margin-bottom: 80px;
    }

    &__icon {
      margin-left: -30px;
    }

    &-main {
      padding: 28px calc((100% - 1140px) / 2) 0;
      height: 420px;
      position: relative;
      z-index: 100;
      &--full {
        height: 100%;

        .img-man {
          background-image: url('../../assets/img_jumpman_HR.png');
          background-size: 725px;
          margin-left: -80px;
        }

        .img-girl {
          background-image: url('../../assets/img-girl-typ-badflow-yellow.png');
          background-size: 725px;
          margin-left: -80px;
          background-size: contain;
        }
      }

      &--small {
        height: auto;
        align-items: center;
        padding-top: 27px;
        padding-bottom: 39px;
        transition: height 1s;

        .img-man {
          background-image: url('../../assets/img_jumpman_HR.png');
          background-size: 725px;
          margin-left: -50px;
        }
        .img-girl {
          background-image: url('../../assets/greeners/greeners_girl_typ.png');
          background-size: 554px;
          margin-left: -50px;
          height: 276px;
          background-position-x: right;

          &-reward {
            background-size: 341px 326px;
          }
        }
      }

      &--mission {
        height: 270px;
        transition: height 1s;

        .img-man {
          background-image: url('../../assets/img_jumpman_HR.png');
          background-size: 725px;
          margin-left: -50px;
        }
        .img-girl {
          background-image: url('../../assets/greeners/greeners_girl_typ.png');
          background-size: 554px;
          margin-left: -50px;
          background-position-x: right;
          background-size: contain;
          margin-right: 80px;
        }
      }

      .content {
        margin-top: 100px;
        position: relative;

        &__logo {
          width: 290px;
          margin-bottom: 24px;
        }

        &__title {
          font-size: 32px;
          line-height: 1.3;
          font-weight: 500;
          letter-spacing: 0.8px;
          color: $srg-blue;
        }
      }

      .img {
        position: relative;
        width: 752px;
        left: -180px;
        top: -40px;
      }
      &--with-bg-img {
        padding-bottom: 0;
        min-height: 276px;
      }
    }
  }
  &__header {
    &-back {
      display: flex;
      align-items: center;
      color: $srg-blue;
      font-size: 16px;
      margin-right: 12px;
      margin-bottom: 17px;
      letter-spacing: 1px;
      .v-icon {
      }
      &:focus {
        outline: none;
      }
    }
  }

  &__content {
    &-wrap {
      position: relative;
      width: 100%;
      z-index: 101;
      background: white;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      padding-bottom: 100px;
    }
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .loyalty {
    .functionality {
      &__body {
        padding: 60px 40px;
      }
      &__header {
        padding: 40px;
        margin-bottom: 0;
      }
    }
    .functionality__image {
      &.level-1,
      &.level-3 {
        width: 370px;
        height: 265px;
        background-size: contain;
      }
    }
  }
}
@media (min-width: 1501px) {
  .loyalty {
    &.loyalty-greeners-header {
      &:before {
        content: '';
        background: linear-gradient(
          90deg,
          #97d700 52.18%,
          rgba(196, 196, 196, 0) 100%
        );
      }
    }
  }
}
</style>
