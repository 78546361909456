export const ServiceSectionMappingType = {
  checkup_energetico: {
    url: 'consumptionProfile',
    param: { tab: 'house' },
    component: ''
  },
  profile_survey_home: { url: 'consumptionProfile', param: { tab: 'house' } },
  upselling: { url: 'ownerSupplies', param: { scroll: true } },
  upsellingLuce: { url: 'eleOffering', param: '' },
  upsellingGas: { url: 'gasOffering', param: '' },
  upsellingFibra: { url: 'fiberOffering', param: '' },
  profile_survey_photovoltaic: {
    url: 'consumptionProfile',
    param: { tab: 'photovoltaic' }
  },
  photovoltaic_simulation_page: {
    url: 'DYNAMIC_COMPONENT',
    param: null,
    component: 'photovoltaic/Simulation.vue'
  },
  profile_survey_mobility: {
    url: 'consumptionProfile',
    param: { tab: 'mobility' }
  }
}
