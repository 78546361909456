<template>
  <div class="speak mt-8">
    <div class="speak__box no-arrow">
      <h5>
        Chiamaci gratis al numero {{ landLineData }} (da fisso) oppure
        {{ mobile }} (da mobile).
      </h5>
    </div>
    <div class="speak__box mb-12" @click="gotoNext(true)">
      <h5>Richiamami</h5>
    </div>
    <div class="question">
      <strong
        >Hai già un’utenza
        {{ commodityType === 'ele' ? 'Luce' : 'Gas' }}?</strong
      >
    </div>
    <div class="speak__box" @click="gotoNext(false)">
      <h5>Carica la tua bolletta</h5>
    </div>
  </div>
</template>
<script>
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'speakWithComm',
  data() {
    return {
      landLineData: {},
      mobile: {}
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['commodityType'])
  },
  methods: {
    ...mapActions('interactions', ['getChannelInfo']),
    gotoNext(val) {
      this.$emit('setCallMeBack', val)
      this.$emit(
        'nextStep',
        val ? 'upsellingcom_callmeback_open' : 'upsellingcom_uploadbill_open'
      )
    }
  },
  created() {
    this.$emit('setCallMeBack', false)
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area: consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
    })
      .then((resp) => {
        this.landLineData = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.MOBILE,
      area: consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
    })
      .then((resp) => {
        this.mobile = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  }
}
</script>
<style lang="scss" scoped>
.speak {
  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 652px;
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    cursor: pointer;
    color: black;
    h5 {
      max-width: 600px;
    }
    &::after {
      content: url('../../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
    &.no-arrow {
      cursor: initial;
      &::after {
        content: '';
      }
    }
  }
  .question {
    font-size: 20px;
    margin-bottom: 24px;
  }
}
</style>
