<template>
  <div class="activation">
    <h5 class="subTitle">Hai bisogno di anticipare l’attivazione?</h5>
    <div class="activation__btn" @click="next(false)">
      <h5>ATTIVAZIONE STANDARD</h5>
      <h5>Utenza attiva dal {{ afterthought }} senza interruzioni</h5>
    </div>
    <div class="activation__btn" @click="next(true)">
      <h5>ATTIVAZIONE ANTICIPATA</h5>
      <h5>
        Utenza attiva dal {{ noAfterthought }} senza interruzioni, avvio lavori
        pre ripensamento
      </h5>
    </div>
    <div class="activation__box">
      <div class="title">Che cosa è l’attivazione anticipata?</div>
      <h5 class="subTitle">Diritto di ripensamento</h5>
      <h5 class="desc">
        Quando sottoscrivi un contratto fuori dai locali commerciali del
        soggetto dal quale acquisti un bene o un servizio, hai 14 giorni di
        tempo per cambiare idea.<br />
        Le ragioni possono essere diverse, e non serve dare motivazione.<br /><br />
        Se ti avvali del diritto di ripensamento, il recesso dal contratto è
        valido a prescindere dal motivo che c’è dietro, senza costi.<br /><br />
        Se scegli di anticipare l’attivazione e di rinunciare al diritto di
        ripensamento, potrai in ogni caso annullare l’attivazione, ma ti
        verranno addebitati, qualora ci fossero, i costi dell’attivazione fino a
        quel momento.
      </h5>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'activationTimes',
  data() {
    return {
      afterthought: '',
      noAfterthought: ''
    }
  },
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetailRoot']),
    ...mapActions('analytics', ['trackTapEvent']),
    getActivationDate() {
      const computeMonthScad = (refDate, yearScad = 0) => {
        // let batchDate = new Date(refDate.getFullYear(), refDate.getMonth() + 1, 10).getTime()
        // let noAfterthoughtDate = batchDate - (14 * 24 * 60 * 60 * 1000)

        let month = null
        let year = yearScad

        if (refDate.getDate() <= 10) {
          month = refDate.getMonth() + 1
        } else {
          month = refDate.getMonth() + 2
        }

        // const currentDate = new Date()
        // const limitDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 10)

        // let month = null

        // if (refDate < limitDate.getTime()) {
        //   month = currentDate.getMonth() + 1
        // } else {
        //   month = currentDate.getMonth() + 2
        // }
        if (month > 11) {
          year = yearScad + 1
          month = month - 11 - 1
        }
        let retDate = new Date(year, month, 1)
        return (
          '01.' +
          (retDate.getMonth() + 1 < 10
            ? '0' + (retDate.getMonth() + 1)
            : retDate.getMonth() + 1) +
          '.' +
          year
        )
      }
      let now = new Date()
      // let monthDate = new Date(now.getFullYear(), now.getMonth(), 1)
      this.noAfterthought = computeMonthScad(now, now.getFullYear())
      this.afterthought = computeMonthScad(
        new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000),
        now.getFullYear()
      )
    },
    next(type) {
      this.setSupplyDetailRoot({
        anticipateActivation: type,
        dac: type ? this.noAfterthought : this.afterthought
      })
      this.$emit(
        'goToStep',
        this.$store.getters['upsellingComm/commodityType'] === 'ele' ? 22 : 20,
        'upsellingcom_verifydata_open'
      )
      let name = type ? 'anticipa' : 'standard'
      this.trackTapEvent({
        name: `upsellingcom_${name}_tap`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  },
  created() {
    this.getActivationDate()
  }
}
</script>
<style lang="scss" scoped>
.activation {
  max-width: 652px;
  color: black;
  &__info {
    font-weight: 400;
    margin-bottom: 24px;
  }
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .subTitle {
    font-weight: 500;
    margin-bottom: 16px;
  }
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    min-height: 88px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 22px 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
    h5 {
      color: black;
      max-width: 80%;
      &:first-child {
        font-weight: 500;
      }
    }
  }
}
</style>
