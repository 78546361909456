<template>
  <div>
    <TabLayout v-if="client && currentSlide">
      <div class="know mt-12 w-100">
        <v-row no-gutters>
          <h2 class="mb-6 w-100">
            <strong>{{ currentSlide.title }}</strong>
          </h2>
          <h5 class="mb-12" v-html="currentSlide.tab_subtitle"></h5>
        </v-row>
        <v-row no-gutters class="mb-12">
          <v-col
            md="3"
            sm="3"
            cols="12"
            v-for="(paragraph, i) in currentSlide.paragraphs"
            :key="i"
          >
            <div class="info-card">
              <!-- <v-img class="mb-6" contain :src="isSmallDevice ? require('@assets/welcome/' + paragraph.image_mobile || paragraph.image) : require('@assets/welcome/' + paragraph.image)"/> -->
              <v-img
                class="mb-6"
                contain
                :src="
                  isSmallDevice
                    ? cdn + paragraph.image_mobile || paragraph.image
                    : cdn + paragraph.image
                "
              />
              <h4 class="mb-4">
                <strong>{{ paragraph.title }}</strong>
              </h4>
              <h5 v-html="paragraph.body"></h5>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="reverseBtn">
          <v-col>
            <v-btn
              @click="
                $router.push(back()),
                  trackTapEvent({
                    name: 'welcomeKit_continue_tap',
                    params: {
                      section_title: prevSlideTitle,
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
              "
              x-large
              color="white"
              class="v-btn--shadowed mb-8 mx-auto"
              >{{ prevSlideTitle }}</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              @click="
                $router.push(next()),
                  trackTapEvent({
                    name: 'welcomeKit_continue_tap',
                    params: {
                      section_title: nextSlideTitle,
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
              "
              x-large
              color="brightGreen"
              v-show="hasNextSlide"
              class="v-btn--shadowed mb-8 mx-auto"
              >{{ nextSlideTitle }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col
          md="6"
          :offset="isSmallDevice ? 0 : currentSlide.show_feedback == 1 ? 0 : 3"
          v-if="currentSlide.show_tutorial == 1 && tutorial"
        >
          <div>
            <InstrumentItem
              title="Tutorial"
              icon="tutorial.svg"
              text="Abbiamo creato una serie di guide navigabili per migliorare la tua esperienza in MySorgenia!"
              link="Vai ai Tutorial"
              @click="
                trackTapEvent({
                  name: 'welcomeKit_tutorial_tap',
                  params: {
                    client_code: client.clientOwnerCode,
                    client_type: client.clientOwnerType,
                    wk_type: client.type
                  },
                  toNative: isMobile
                })
              "
              :routeName="isMobile ? 'publicTutorial' : 'tutorial'"
            />
          </div>
        </v-col>
        <v-col
          md="6"
          :offset="
            isSmallDevice
              ? 0
              : currentSlide.show_tutorial == 1 && tutorial
              ? 0
              : 3
          "
          v-if="currentSlide.show_feedback == 1"
        >
          <div>
            <InstrumentItem
              title="Feedback"
              icon="feedback.svg"
              text="La tua opinione è un prezioso contributo. Dedicaci 3 minuti  rispondendo a poche domande!"
              link="Dicci la tua"
              @click="
                trackTapEvent({
                  name: 'welcomeKit_feeback_tap',
                  params: {
                    client_code: client.clientOwnerCode,
                    client_type: client.clientOwnerType,
                    wk_type: client.type
                  },
                  toNative: isMobile
                })
              "
              :routeName="isMobile ? 'publicFeedback' : 'feedback'"
            />
          </div>
        </v-col>
      </v-row>
    </TabLayout>
    <TutorialOverlay
      :overlay.sync="overlay"
      v-if="currentSlide && currentSlide.show_feedback_overlay == 1 && overlay"
      :isMobile="isMobile"
    />
  </div>
</template>
<script>
import { ENV_CONFIGS } from '@/js/configs'
import {
  getKitSlideRouteName,
  isOnMobileDeviceGetPlatform
} from '@/js/utils.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'standardWelcomeKit',
  data() {
    return {
      cdn: ENV_CONFIGS.CMS_CDN_BASE_URL,
      currentSlideId: this.$route.params.id,
      isMobile: isOnMobileDeviceGetPlatform(),
      isSmallDevice: window.innerWidth <= 480,
      isBigDevice: window.innerWidth > 480 && window.innerWidth <= 1024,
      isDesktop: window.innerWidth > 1024,
      overlay: false
    }
  },
  components: {
    TabLayout: () => import('@/views/welcome/TabLayout'),
    InstrumentItem: () => import('@/components/support/InstrumentItem'),
    TutorialOverlay: () => import('@/views/welcome/TutorialOverlay')
  },
  computed: {
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['wkSlidesForUser', 'welcomekit', 'tutorial']),
    currentIndex() {
      var slides = this.wkSlidesForUser || []
      return slides.findIndex((slide) => slide.id === this.currentSlideId)
    },
    currentSlide() {
      var slides = this.wkSlidesForUser || []
      return slides[this.currentIndex]
    },
    nextSlideTitle() {
      let slides = this.wkSlidesForUser || []
      if (this.currentIndex === slides.length - 1) return ''
      let nextSlide = slides[this.currentIndex + 1]

      return nextSlide.carousel_title
    },
    hasNextSlide() {
      return this.currentIndex < this.wkSlidesForUser.length - 1
    },
    prevSlideTitle() {
      if (this.currentIndex === 0) return this.welcomekit.title

      let slides = this.wkSlidesForUser || []
      let prevSlide = slides[this.currentIndex - 1]

      return prevSlide.carousel_title
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentSlideId = id
    },
    client: function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$store.dispatch('welcomekit/getWelcomekitByUserProfile', {
          client: this.client
        })
        this.$store.dispatch('welcomekit/getTutorialByUserProfile', {
          client: this.client
        })
      }
    }
  },
  methods: {
    ...mapActions('feedback', ['loadSurvey', 'loadUndoneSurvey']),
    ...mapActions('analytics', ['trackTapEvent']),
    back() {
      if (this.currentIndex === 0)
        return { name: this.isMobile ? 'publicWelcomekit' : 'welcomekit' }

      let slides = this.wkSlidesForUser || []
      let prevSlide = slides[this.currentIndex - 1]

      if (prevSlide.template_name === 'standardWelcomeKit') {
        return {
          name: getKitSlideRouteName(prevSlide.template_name, this.isMobile),
          params: { id: prevSlide.id }
        }
      } else {
        return {
          name: getKitSlideRouteName(prevSlide.template_name, this.isMobile)
        }
      }
    },
    next() {
      let slides = this.wkSlidesForUser || []
      if (this.currentIndex === slides.length - 1) return ''
      let nextSlide = slides[this.currentIndex + 1]

      if (nextSlide.template_name === 'standardWelcomeKit') {
        return {
          name: getKitSlideRouteName(nextSlide.template_name, this.isMobile),
          params: { id: nextSlide.id }
        }
      } else {
        return {
          name: getKitSlideRouteName(nextSlide.template_name, this.isMobile)
        }
      }
    }
  },
  created() {
    // this is not required to be here, since we load again in the pages with links to feedback,
    // but it is here to pre-load this data in hte standard flow (and improve UX)
    this.loadSurvey().then((res) => {
      this.loadUndoneSurvey().then((undoneSurveys) => {
        this.overlay = undoneSurveys.length > 0
      })
    })
  }
}
</script>
<style lang="scss" scoped></style>
