<template>
  <div class="login">
    <TcVars />
    <div class="login__content">
      <img
        src="../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <div class="login__box">
        <template>
          <div class="login__title HeadingsSubtitleXXL">
            Benvenuto in Sorgenia
          </div>
          <div class="BodyL text-center mb-8">
            <b
              >È quasi tutto pronto, tra qualche ora potrai accedere alla tua
              area clienti.</b
            >
            Per permetterti di avere un’esperienza migliore stiamo preparando la
            tua area clienti, ti chiediamo ancora un po’ di pazienza e di
            tornare più tardi.
          </div>
        </template>
        <div class="ButtonM text-center" @click="goBack">Torna indietro</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'AccountNotReady',
  mounted() {
    this.trackTapEvent({ name: 'login_account_not_ready' })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    goBack() {
      this.$router.go(-1)
    }
  },
  components: {
    TcVars
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/authentication/auth.scss';
.ButtonM {
  color: $srg-blue;
  cursor: pointer;
  margin-top: 40px;
}
.login__box {
  padding-bottom: 22px;
  width: 555px;
  height: 293px;
}
</style>
