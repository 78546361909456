var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ dreamService: _vm.isMobile }},[(_vm.isMobile)?_c('v-row',{staticClass:"dreamService__header"},[_c('v-col',{attrs:{"cols":"11"}},[_c('button',{staticClass:"dreamService__header-back",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'publicDreamServiceDone' })}}},[_c('v-icon',{attrs:{"color":"white","size":"30"}},[_vm._v("mdi-chevron-left")])],1)]),_c('v-col',{staticClass:"flex-grow-0 pl-0",attrs:{"cols":"1"}},[_c('v-img',{staticClass:"close",attrs:{"contain":"","width":"16","src":require("@assets/welcome/ic-close-white-big.png")},on:{"click":function($event){return _vm.$router.push({ name: 'publicDreamServiceDone' })}}})],1)],1):_vm._e(),(_vm.loaded)?_c('div',{class:`std-side-padding pb-10 ${
      _vm.isMobile ? 'dreamService__content-wrap' : ''
    }`},[(!_vm.isSmallDevice)?_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pb-0",attrs:{"items":_vm.breadcrumbsItems,"divider":">"},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/chevron_rx_light.svg"),"width":"24"}})]},proxy:true}],null,false,3772294745)})],1)],1):_vm._e(),_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('div',{staticClass:"col-xs-12 col-md-8"},[_c('h1',{staticClass:"support__title font-weight-bold"},[_vm._v("Avanzamento Richieste")])]),(!_vm.isSmallDevice)?_c('v-col',{staticClass:"filter",attrs:{"cols":"2"}},[_c('span',[_vm._v("Filtra per")])]):_vm._e(),_c('div',{staticClass:"btn-filter"},[_c('div',{staticClass:"filter-command pl-5"},[_c('div',{class:{
              'filter-item-active': _vm.filter === 'Ele',
              'filter-item': true
            },on:{"click":function($event){_vm.filter === 'Ele' ? (_vm.filter = '') : (_vm.filter = 'Ele')}}},[(_vm.filter !== 'Ele')?_c('img',{attrs:{"src":require('@assets/icons/ic-light-blu.svg'),"height":"48","width":"48"}}):_vm._e(),(_vm.filter === 'Ele')?_c('img',{attrs:{"src":require('@assets/icons/ic-light-white.svg'),"height":"48","width":"48"}}):_vm._e()])]),_c('div',{staticClass:"filter-command pl-0 pr-0"},[_c('div',{class:{
              'filter-item-active': _vm.filter === 'Gas',
              'filter-item': true
            },on:{"click":function($event){_vm.filter === 'Gas' ? (_vm.filter = '') : (_vm.filter = 'Gas')}}},[(_vm.filter !== 'Gas')?_c('img',{attrs:{"src":require('@assets/icons/ic-fire-blu.svg'),"height":"48","width":"48"}}):_vm._e(),(_vm.filter === 'Gas')?_c('img',{attrs:{"src":require('@assets/icons/ic-fire-white.svg'),"height":"48","width":"48"}}):_vm._e()])])])],1),_c('v-row',_vm._l((_vm.requests),function(req,i){return _c('v-col',{key:i,staticClass:"relativeBox",attrs:{"cols":_vm.isSmallDevice ? 12 : 6}},[_c('div',{class:`support__activeRequestBox ${
            req.chiuso === 'N' ? 'active' : 'closed'
          }`},[_c('div',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12 pb-0"}},[(req.chiuso === 'Y')?_c('span',{staticClass:"support__activeRequestBox-status pb-0"},[_vm._v("Chiuso")]):_c('span',{staticClass:"support__activeRequestBox-status-active pb-0"},[_vm._v("In Lavorazione / Aggiornato il "+_vm._s(req.lastUpdate))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11 pt-0 pb-0"}},[_c('div',{staticClass:"support__activeRequestBox-title pt-0"},[_vm._v(" "+_vm._s(req.categoria)+" ")])]),_c('v-col',{attrs:{"cols":"1 pt-0 pb-0"}},[(req.servizio === 'Ele')?_c('img',{staticClass:"mx-n5",attrs:{"src":require('@assets/icons/ic-light-blu.svg'),"height":"32","width":"32"}}):_vm._e(),(req.servizio === 'Gas')?_c('img',{staticClass:"mx-n5",attrs:{"src":require('@assets/icons/ic-fire-blu.svg'),"height":"32","width":"32"}}):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12 pt-0"}},[_c('span',{staticClass:"address"},[_vm._v(_vm._s(req.indirizzo))])])],1),_c('v-row',[_c('v-col',{staticClass:"limitedTo11Cols",attrs:{"cols":"auto"}},[_c('span',{staticClass:"client"},[_vm._v(_vm._s(_vm.currentClient.clientOwnerType === 'RES' ? _vm.currentClient.firstName + ' ' + _vm.currentClient.lastName : _vm.currentClient.businessName))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('img',{staticClass:"flex-grow-0 ml-0 mb-1",attrs:{"src":_vm.currentClient.clientOwnerType === 'RES'
                      ? require('../../assets/ic_owner-res.png')
                      : require('../../assets/ic_owner-business.png'),"width":"20","height":"20"}})])],1),_c('v-col',{attrs:{"cols":"12 pt-0 pb-0"}},[_c('span',{staticClass:"clientData"},[_vm._v("codice cliente: "+_vm._s(_vm.currentClient.clientOwnerCode))])]),_c('v-row',{staticClass:"mb-md-5"},[_c('div',{staticClass:"pt-0 pb-0 col-8 col-xs-12"},[_c('span',{staticClass:"clientData"},[_vm._v("data richiesta: "+_vm._s(req.requestDate))])]),(req.msgToRead > 0 && !_vm.isMobile)?_c('v-col',{staticClass:"pt-0 pb-0 col-4 unreadMsg"},[_c('span',[_vm._v("Messaggi non letti "),_c('span',{staticClass:"notify"},[_vm._v(_vm._s(req.msgToRead))])])]):_vm._e()],1),(req.msgToRead > 0 && _vm.isMobile)?_c('v-row',{staticClass:"mb-4"},[_c('v-col',{staticClass:"pt-0 mt-2 pb-0 unreadMsg",attrs:{"cols":"12"}},[_c('span',[_vm._v("Messaggi non letti "),_c('span',{staticClass:"notify"},[_vm._v(_vm._s(req.msgToRead))])])])],1):_vm._e()],1),_c('div',{},[_c('v-row',{staticClass:"bottomText",attrs:{"justify":"center"}},[_c('h4',{class:`support__activeRequestBox-btn ${
                  req.chiuso === 'N' ? 'btn-active' : 'btn-closed'
                }`,on:{"click":function($event){return _vm.openDreamServiceDetail(req.idNotifica)}}},[_vm._v(" Visualizza avanzamento ")])])],1)])])}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }