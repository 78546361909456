<template>
  <v-dialog
    v-model="dialogOpen"
    persistent
    max-width="558"
    overlay-opacity="0.6"
    content-class="customDialog"
  >
    <div class="customDialog">
      <div class="d-flex align-center justify-space-between mb-10">
        <div class="SubheadingL" v-html="titleModal" />
        <img
          class="close"
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          @click="$emit('update:customDialogOpen', false)"
        />
      </div>
      <img
        v-if="showImage"
        :src="require(`@/assets/upselling/${imageModal}.svg`)"
        class="mb-6 image"
      />
      <div class="BodyL body" v-html="bodyModal" />
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'CustomDialog',
  props: {
    customDialogOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String
    },
    body: {
      type: String
    },
    image: {
      type: String,
      default: 'Image'
    },
    showImage: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    dialogOpen: {
      get() {
        return this.customDialogOpen
      },
      set(val) {
        this.$emit('update:customDialogOpen', val)
      }
    },
    imageModal() {
      return this.image !== null && this.image ? this.image : 'Image'
    },
    titleModal() {
      return this.title !== null ? this.title : ''
    },
    bodyModal() {
      return this.body !== null ? this.body : ``
    }
  }
}
</script>

<style lang="scss" scoped>
.customDialog {
  background: white;
  border-radius: 20px !important;
  padding: 40px;
  .close {
    cursor: pointer;
  }
  .image {
    width: 478px;
  }
  .body {
    width: 478px;
  }
}
</style>
