<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="7" class="img-header d-flex justify-content-center">
        <img
          class="img-header__success"
          src="@/assets/greeners/greeners_girl_typ.png"
        />
      </v-col>
      <v-col v-if="mission" class="col-5 pa-0" align-self="center">
        <h1 class="" v-html="mission.end_page_title" />
      </v-col>
    </template>
    <v-row class="mt-5">
      <v-col cols="6">
        <v-row>
          <v-col class="pb-0 success-title">Complimenti!</v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col v-if="mission">
            <p class="font-weight-regular" v-html="mission.end_page_text" />
            <!-- <p><strong>Condividi questa missione con i tuoi amici e mostra cosa vuol dire essere un vero Greener!</strong></p> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col class="offset-2">
        <div>
          <div class="bg-grey">
            <v-row class="bg-grey__title pt-3 pl-3">Hai guadagnato</v-row>
            <v-row>
              <v-col class="coins mt-3 pt-0">
                {{
                  missionValue(mission.wekit_action_id, mission.wekit_event_id)
                }}
                <div>Green Coins</div>
              </v-col>
              <v-col class="pt-0">
                <v-img
                  class="ma-0 ml-auto"
                  height="90"
                  src="@/assets/greeners/svg/greeners.svg"
                  width="90"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="achievement align-center">
                <Achievement :horizontal="true" class="mx-auto" />
              </v-col>
            </v-row>
          </div>
          <router-link
            :to="{ name: goTo(mission.first_app_section_section) }"
            class="gifts-content__item__link mt-10 d-block text-center"
          >
            {{ mission.first_cta_label }}
          </router-link>
        </div>
      </v-col>
    </v-row>
    <!-- <v-col> -->
    <!-- <social-sharing url='https://www.sorgenia.it/'
                        >
          <network network="facebook" >
            <button data-v-70ccbbf5=""
                    type="button"
                    class="v-btn--shadowed normal v-btn v-btn--contained theme--light v-size--x-large white"
                    style="width: 360px;"
            >
              <span class="v-btn__content">
                <i data-v-70ccbbf5="" aria-hidden="true" class="v-icon notranslate mr-2 ml-n2 mdi mdi-facebook theme--light"/>
                <p data-v-70ccbbf5="" class="font-weight-bold mb-0">Facebook</p>
              </span>
            </button>
          </network>
        </social-sharing> -->
    <!-- </v-col> -->
  </layout>
</template>

<script>
import { APP_SECTION_MAPPING } from '@/js/constants'
import { mapActions, mapGetters } from 'vuex'
import Layout from './Layout'

export default {
  name: 'SuccessUploadPage',
  data() {
    return {
      APP_SECTION_MAPPING: APP_SECTION_MAPPING
    }
  },
  components: {
    Layout,
    Achievement: () =>
      import(
        /* webpackChunkName: "components/loyalty/achivment" */ '@/views/loyalty/components/achievement'
      )
  },
  beforeMount() {
    this.getWekitMap()
    this.getPhoto()
  },
  computed: {
    ...mapGetters('loyalty', ['photoData', 'wekitMap']),
    mission() {
      return this.photoData.filter(
        (photo) => photo.id === this.$route.params.id
      )[0]
    }
  },
  methods: {
    ...mapActions('loyalty', ['getPhoto', 'getWekitMap']),
    ...mapActions('analytics', ['trackTapEvent']),
    missionValue(actionId, eventId) {
      let targetMission = this.wekitMap.filter(
        (mission) =>
          mission.action_id === actionId && mission.event_id === eventId
      )
      if (targetMission.length) {
        this.trackTapEvent({
          name: 'greeners_missionCompleted_view',
          params: {
            mission_id: targetMission[0].id,
            mission_title: targetMission[0].title
          }
        })
        return targetMission[0].points
      } else return 0
    },
    goTo(id) {
      var destination = this.APP_SECTION_MAPPING[id]
      // switch (id) {
      //   case '/loyalty-diary-0':
      //   destination = 'loyaltyPage'
      //   break
      //   case '/loyalty-catalog-0':
      //   destination = 'catalogue'
      //   break
      //   default:
      //   destination = 'loyaltyPage'
      // }

      this.trackTapEvent({
        name: 'greeners_missionThankYouPageCTA_tap',
        params: { app_section: destination }
      })

      return destination
    }
  }
}
</script>

<style lang="scss" scoped>
.img-header {
  height: 260px;
  &__success {
    position: absolute;
    bottom: -90px;
  }
}
.success-title {
  font-size: 42px;
  font-weight: bold;
}
p {
  font-size: 20px;
  width: 100%;
}
.bg-grey {
  border-radius: 40px;
  background-color: #f6f6f6;
  padding: 20px 25px;
  max-width: 359px;
  width: 100%;
  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .coins {
    font-size: 60px;
    font-weight: bold;
    line-height: 70px;
    min-width: 150px;
    div {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
a {
  color: $srg-blue !important;
  font-size: 20px;
  font-weight: bold;
}
</style>
