<template>
  <div>
    <TcVars />
    <v-row class="std-side-padding">
      <v-col>
        <h1 class="self-reading__title">Leggi contatore</h1>
      </v-col>
    </v-row>

    <v-row class="last-reading">
      <v-col>
        <v-row class="std-side-padding">
          <v-col>
            <p>
              Inserisci di seguito solo i numeri che si trovano prima della
              virgola (caselle a sfondo nero).
            </p>
            <v-row no-gutters="">
              <v-col sm="4">
                <v-text-field
                  type="number"
                  label="Lettura"
                  class="auth__input"
                  v-model.trim="readings.f1"
                  :max-length="details.clientOwnerType === 'RES' ? 7 : 9"
                  @blur="v$.readings.f1.$touch()"
                  :error-messages="errorsF1"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="std-side-padding" no-gutters>
      <v-col class="pt-10">
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-btn
              color="primary"
              large
              width="250px"
              class="v-btn--shadowed"
              :rounded="true"
              @click="sendReadings"
              :loading="sending"
              :disabled="v$.readings.$invalid"
            >
              Invia
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import { getRequiredValidationError } from '@/js/utils'
import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: ['details', 'lastReading', 'isAcea', 'enteredReadings'],
  name: 'selfReadingLight_Base',
  data() {
    return {
      sending: false,
      readings: {
        f1: this.enteredReadings ? this.enteredReadings.f1 : '',
        f2: '',
        f3: ''
      }
    }
  },
  components: {
    TcVars
  },
  computed: {
    errorsF1() {
      return getRequiredValidationError(this.v$.readings, 'f1')
    }
  },
  methods: {
    ...mapActions('supply', ['getDetail', 'selfReading']),
    ...mapActions('analytics', ['trackTapEvent']),
    sendReadings() {
      this.trackTapEvent({
        name: 'selfReading_send_tap',
        params: getSupplyTrackingParams(this.details)
      })
      this.$emit('send-values', this.readings)
      this.sending = true
    }
  },
  validations: {
    readings: {
      f1: { required }
    }
  }
}
</script>
