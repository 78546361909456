<template>
  <div>
    <div class="page">
      <v-row class="std-side-padding">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <transition name="fade">
        <div v-if="!loaded">
          <v-row>
            <v-col
              ><img src="@assets/fotovoltaico/placeholder.svg" width="100%"
            /></v-col>
          </v-row>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="loaded">
          <v-row>
            <v-col class="pb-0" cols="12">
              <MonitorTitleVue
                title="Il mio impatto sul pianeta"
                :abstract="textLabelTime"
              ></MonitorTitleVue>
            </v-col>
          </v-row>
        </div>
      </transition>
    </div>
    <div class="title-section">
      <div class="page">
        <v-row>
          <v-col cols="12">
            <MonitorValueVue
              title="Emissioni C02 evitate"
              :value="totalCo2EmissionSavings"
              :icon="true"
              :trees="totalTreesCountSavings"
            >
            </MonitorValueVue>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="page">
      <v-row>
        <v-col cols="12">
          <MonitorHeaderVue
            :currentOptionList="currentOptionList"
            :load="load"
            :currentEnd="body.endDate"
            :currentStart="body.startDate"
            :installationConnectionStartDate="installationConnectionStartDate"
            @change-current="changeCurrent"
            @change-unit="changeUnit"
          ></MonitorHeaderVue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MonitorChartVue
            :series="series"
            :categories="categories"
            :columnWidth="columnWidth"
            :load="load"
            chartLabelY="impatto"
          ></MonitorChartVue>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import MonitorHeaderVue from '../../../components/fotovoltaico/monitor/MonitorHeader.vue'
import MonitorTitleVue from '../../../components/fotovoltaico/monitor/MonitorTitle.vue'
import MonitorValueVue from '../../../components/fotovoltaico/monitor/MonitorValue.vue'
import MonitorChartVue from '../../../components/fotovoltaico/monitor/MonitorChart.vue'
import functionsUtilityFV from '@/js/fotovoltaico/functions'

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  components: {
    MonitorTitleVue,
    MonitorValueVue,
    MonitorHeaderVue,
    MonitorChartVue
  },
  mounted() {
    this.trackTapEvent({
      name: 'monitoring_historical_data_viewed',
      params: {
        category: 'impact'
      }
    })
    this.loadImpact()
  },
  beforeMount() {
    if (!this.addressSelected.photovoltaicData && !this.$route.params.siteId) {
      this.$router.push({ name: 'home' })
    }
  },
  data() {
    return {
      load: false,
      trees: '',
      loaded: true,
      value: 0,
      type: 'Dall inizio',
      textLabelTime: 'Dati aggiornati ogni 24 ore.',
      current: new Date(),
      body: {
        acuId: '', // retrieveclientaccount
        siteId: '', // retrievehomepage,
        granularity: 'Dall inizio',
        startDate: new Date(
          new Date().getFullYear(),
          0,
          1,
          0,
          0,
          0
        ).toISOString(),
        endDate: new Date(
          new Date().getFullYear(),
          11,
          31,
          23,
          59,
          59
        ).toISOString()
      }
    }
  },
  computed: {
    ...mapState('fotovoltaico', ['enviromentallImpanct', 'addressSelected']),
    ...mapGetters('fotovoltaico', [
      'getInstallationData',
      'getEnviromentallImpactControl'
    ]),
    ...mapGetters('account', ['mainClient']),
    breadcrumbsItems() {
      this.setFlagEnterByDeeplink(false)
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        {
          to: {
            name: 'dashboardFotovoltaico',
            params: {
              acuId: this.$route.params.acuId,
              siteId: this.$route.params.siteId
            }
          },
          text: 'Flussi energetici'
        },
        { to: { name: 'fotovoltaicoImpatto' }, text: 'Impatto ambientale' }
      ]

      return res
    },
    series() {
      return this.impactResponse.data && this.impactResponse.data.values
        ? this.impactResponse.data.values.series
        : []
    },
    categories() {
      return this.impactResponse.data && this.impactResponse.data.categories
        ? this.impactResponse.data.categories
        : []
    },
    currentOptionList() {
      const date = new Date(this.current)
      const dateStart = new Date(this.installationConnectionStartDate || '')
      switch (this.body.granularity) {
        case 'Giorno':
          return functionsUtilityFV.formatDayMonth(date)
        case 'Settimana':
          const startOfWeek = functionsUtilityFV.getStartOfWeek(date)
          const endOfWeek = functionsUtilityFV.getEndOfWeek(date)

          return `${functionsUtilityFV.formatDayMonth(
            startOfWeek,
            true
          )} - ${functionsUtilityFV.formatDayMonth(endOfWeek, true)}`

        case 'Mese':
          return functionsUtilityFV.formatMonth(date)
        case 'Anno':
          return functionsUtilityFV.formatYear(date)
        case 'Dall inizio':
          return `${functionsUtilityFV.formatFromActivation(
            dateStart
          )} - ${functionsUtilityFV.formatFromActivation(new Date())}`
        default:
          return functionsUtilityFV.formatDayMonth(date)
      }
    },
    columnWidth() {
      return this.impactResponse.data && this.impactResponse.data.columnWidth
        ? this.impactResponse.data.columnWidth
        : 0
    },
    emmissionSavingSum() {
      return this.impactResponse.data &&
        this.impactResponse.data.emmissionSavingSum
        ? this.impactResponse.data.emmissionSavingSum
        : 0
    },
    // treeCountSavingsSum() {
    //   return this.impactResponse.data &&
    //     this.impactResponse.data.treeCountSavingsSum
    //     ? this.impactResponse.data.treeCountSavingsSum
    //     : 0
    // },
    totalTreesCountSavings() {
      return this.impactResponse.data &&
        this.getEnviromentallImpactControl &&
        this.getEnviromentallImpactControl?.treeCountSavings[0]?.value
        ? this.getEnviromentallImpactControl?.treeCountSavings[0]?.value
        : 0
    },
    totalCo2EmissionSavings() {
      let total =
        this.impactResponse.data &&
        this.getEnviromentallImpactControl &&
        this.getEnviromentallImpactControl?.co2EmissionSavings[0]?.value
          ? this.getEnviromentallImpactControl?.co2EmissionSavings[0]?.value
          : 0
      return (
        total.toLocaleString('it-IT', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + ' t '
      )
    },
    // formattedValue() {
    //   return (
    //     this.emmissionSavingSum.toLocaleString('it-IT', {
    //       minimumFractionDigits: 2,
    //       maximumFractionDigits: 2
    //     }) + ' t '
    //   )
    // },
    installationConnectionStartDate() {
      return this.getInstallationData?.siteProfile
        ?.installationConnectionStartDate
    },
    getAcudId() {
      return this.getInstallationData.acuId
    },
    getSiteId() {
      return this.getInstallationData.siteProfile.siteId
    },
    impactResponse() {
      return this.enviromentallImpanct
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('fotovoltaico', [
      'retrieveEnvironmentalImpactKPIData',
      'photovoltaicInstallationDetails',
      'retrieveDashboardData',
      'retrieveEnvironmentalImpactKPIControl'
    ]),
    ...mapMutations('fotovoltaico', ['setFlagEnterByDeeplink']),
    ...mapActions('supply', ['getSupplies']),

    async changeCurrent(current, amount) {
      this.load = true
      functionsUtilityFV.updateDates(this.body.granularity, amount, this.body)
      this.current = this.body.endDate
      await this.retrieveEnvironmentalImpactKPIData(this.body)
      switch (this.body.granularity) {
        case 'Giorno':
          let bodyGran = { ...this.body, granularity: 'DAILY' }
          await this.retrieveEnvironmentalImpactKPIControl(bodyGran)
          break

        case 'Settimana':
          let bodyWeek = { ...this.body, granularity: 'WEEKLY' }
          await this.retrieveEnvironmentalImpactKPIControl(bodyWeek)
          break

        case 'Mese':
          let bodyMonth = { ...this.body, granularity: 'MONTHLY' }
          await this.retrieveEnvironmentalImpactKPIControl(bodyMonth)
          break

        case 'Anno':
          let bodyAnnual = { ...this.body, granularity: 'ANNUAL' }
          await this.retrieveEnvironmentalImpactKPIControl(bodyAnnual)
          break

        case 'Dall inizio':
          let bodyFrom = { ...this.body, granularity: 'ANNUAL' }
          await this.retrieveEnvironmentalImpactKPIControl(bodyFrom)
          break

        default:
          break
      }
      this.load = false
    },
    async changeUnit(unit) {
      this.load = true
      const today = new Date()
      switch (unit) {
        case 'Giorno':
          this.textLabelTime = 'Dati aggiornati ogni 15 minuti.'
          this.body.startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            1,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            23,
            59,
            59
          ).toISOString()
          let bodyDay = { ...this.body, granularity: 'DAILY' }
          this.retrieveEnvironmentalImpactKPIControl(bodyDay)
          break
        case 'Settimana':
          this.textLabelTime = 'Dati aggiornati ogni 60 minuti.'
          const dayOfWeek = today.getDay()
          const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1
          const firstDayOfWeek = new Date(today)
          firstDayOfWeek.setDate(today.getDate() - daysToSubtract)

          this.body.startDate = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate(),
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate() + 6,
            23,
            59,
            59
          ).toISOString()
          let bodyWeek = { ...this.body, granularity: 'WEEKLY' }
          this.retrieveEnvironmentalImpactKPIControl(bodyWeek)
          break
        case 'Mese':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0,
            23,
            59,
            59
          ).toISOString()
          let bodyMonth = { ...this.body, granularity: 'MONTHLY' }
          this.retrieveEnvironmentalImpactKPIControl(bodyMonth)
          break
        case 'Anno':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
          let bodyAnnual = { ...this.body, granularity: 'ANNUAL' }
          this.retrieveEnvironmentalImpactKPIControl(bodyAnnual)
          break
        case 'Dall inizio':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
          let bodyFromActivation = {
            ...this.body,
            granularity: 'FROM_ACTIVATION'
          }
          this.retrieveEnvironmentalImpactKPIControl(bodyFromActivation)
          break
      }
      this.body.granularity = unit
      await this.retrieveEnvironmentalImpactKPIData(this.body)
      this.current = this.body.endDate
      this.load = false
    },
    async loadImpact() {
      this.load = true
      await this.getSupplies()
      if (
        this.$route.params.acuId !== this.mainClient?.acuId ||
        !this.mainClient?.acuId
      ) {
        this.$router.push({ name: 'home' })
      } else {
        const siteId = this.$route.params.siteId
        const acuId = this.$route.params.acuId
        const granularityDaily = 'DAILY'
        const granularityFromStart = 'FROM_ACTIVATION'
        const startDateFromStart =
          this.addressSelected?.photovoltaicData
            ?.installationConnectionStartDate
        let endDate = new Date().toISOString().slice(0, 19) + 'Z'
        const today = new Date()
        const startDateDaily =
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          )
            .toISOString()
            .slice(0, 19) + 'Z'
        const body = {
          siteId,
          acuId,
          granularityDaily,
          granularityFromStart,
          startDateFromStart,
          endDate,
          startDateDaily
        }

        await this.photovoltaicInstallationDetails(body)
        await this.retrieveDashboardData(body)
        this.body.acuId = this.$route.params.acuId
        this.body.siteId = this.$route.params.siteId
        const newBody = {
          ...this.body,
          startDate: new Date(
            new Date().getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString(),
          endDate: new Date(
            new Date().getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
        }
        let bodyStart = { ...newBody, granularity: 'FROM_ACTIVATION' }
        await this.retrieveEnvironmentalImpactKPIControl(bodyStart)
        await this.retrieveEnvironmentalImpactKPIData(newBody)
      }
      this.load = false
    }
  }
}
</script>
<style lang="scss" scoped>
.title-section {
  margin-top: 40px;
  background-color: #eaeef5;
  padding: 8px;
}
</style>
