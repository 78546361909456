import { axiosCMS } from '@/js/axiosInstances.js'
import axios from 'axios'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  actions: {
    getLoginInfoDocUrl() {
      return axiosCMS
        .get('/DocumentFile/informativa-app?_format=hal_json')
        .then((resp) => {
          return axiosCMS.defaults.baseURL + resp.data[0].document
        })
    },
    getRegistrationTermAndCondDocUrl() {
      return axiosCMS
        .get('/DocumentFile/termini-e-condizioni?_format=hal_json')
        .then((resp) => {
          return axiosCMS.defaults.baseURL + resp.data[0].document
        })
    },
    getAllDocs() {
      return axiosCMS
        .get('/seyj/DocumentFile-list.json?_format=hal_json')
        .then((resp) => {
          return resp.data
        })
    },
    getMGMPdf() {
      return axiosCMS
        .get('/seyj/DocumentFile-list.json/mgm?_format=hal_json')
        .then((res) => {
          return axiosCMS.defaults.baseURL + res.data[0].document
        })
    },
    getDocsByCategory(ctx, category) {
      return axiosCMS
        .get(`/seyj/DocumentFile-list.json/${category}?_format=hal_json`)
        .then((resp) => {
          return resp.data
        })
    },
    getDocInfoConsents() {
      return axiosCMS
        .get(
          '/seyj/DocumentFile-list.json/informative-cliente?_format=hal_json'
        )
        .then((res) => {
          return res.data
        })
    },
    getDocUpsellingUnfairTerms({ dispatch, commit, rootGetters }) {
      return axios.get(
        'https://casa.sorgenia.it/content/dam/Casa/api/v1/getClausoleVessatorie.json'
      )
    },
    geTextPage() {
      return axiosCMS
        .get(`/seyj/Text-page-list.json?_format=hal_json`)
        .then((resp) => {
          return resp.data
        })
    }
  }
}
