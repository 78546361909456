<template>
  <layout header-size="small" class="catalogue">
    <template v-slot:header>
      <v-col cols="6">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
        <div class="HeadingsTitleXXL">Catalogo</div>
        <div class="blackCoins">
          <div class="blackCoins__img">
            <img
              v-if="memberStatus.statusGreeners !== 'Pending'"
              :src="icons.coinsBlack"
              class="catalogue__coins-img"
              alt="icon coins"
            />
          </div>
          <div class="blackCoins__count">
            <span
              v-if="memberStatus.statusGreeners !== 'Pending'"
              class="HeadingsDisplayXL"
              v-text="(memberBalance.user_balance || 0).toLocaleString('it-IT')"
            />
          </div>
        </div>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5">
        <v-img
          contain
          width="393"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div v-if="loader" style="width: 100%">
      <v-progress-circular
        class="loader fixed-loader"
        color="#12256A"
        :size="50"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="catalogue__content">
      <div class="catalogue__coins">
        <h2 class="HeadingL">Scegli il tuo premio ed usa i Green Coins.</h2>
        <!-- <img
          v-if="memberStatus.statusGreeners !== 'Pending'"
          :src="icons.coins"
          class="catalogue__coins-img"
          alt="icon coins"
        /> 
        <span
          v-if="memberStatus.statusGreeners !== 'Pending'"
          class="HeadingsDisplayXL"
          v-text="(memberBalance.user_balance || 0).toLocaleString('it-IT')"
        />-->
      </div>
      <v-row class="catalogue__items">
        <v-col
          cols="4"
          :key="i"
          v-for="(card, i) in catalogList"
          :class="[
            'mb-4',
            {
              'card-disabled':
                memberBalance.user_balance < card.attributes.price ||
                memberStatus.statusGreeners === 'Pending'
            }
          ]"
          class="mb-4"
        >
          <card
            :id="card.attributes.prizeoption_id"
            :imgSrc="
              card.attributes.images.data.find(({ label }) => label === 'cover')
                .image_small || card.attributes.images.data[0].image_small
            "
            :label="card.attributes.title"
            :price="card.attributes.price"
            @openModalPending="dialogPendingUser = true"
          />
        </v-col>
      </v-row>
      <v-footer color="white" height="50" />
    </div>
    <BalanceUserPending
      v-model="dialogPendingUser"
      :dialog="dialogPendingUser"
      @closeModal="dialogPendingUser = false"
      type-modal="catalog"
    />
  </layout>
</template>

<script>
import Layout from './Layout'
import Card from './components/Card'
import { mapActions, mapGetters } from 'vuex'
import IconCoins from '@assets/greeners/svg/greeners.svg'
import IconCoinsBlack from '@assets/greeners/svg/greeners_black.svg'
import BalanceUserPending from '../../components/greeners/BalanceUserPending.vue'

export default {
  name: 'CataloguePrice',
  data() {
    return {
      loader: true,
      dialogPendingUser: false
    }
  },
  components: {
    Layout,
    card: Card,
    BalanceUserPending: BalanceUserPending
  },
  computed: {
    ...mapGetters('loyalty', ['memberBalance', 'catalogList', 'memberStatus']),
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    icons: () => ({
      coins: IconCoins,
      coinsBlack: IconCoinsBlack
    }),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        { to: { name: 'loyaltyPage' }, text: 'Diario Greeners', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Catalogo' }
      ]

      return res
    }
  },
  beforeMount() {
    this.getMainClient().then((res) => {
      if (this.mainClientLoyaltySelected) {
        res = this.mainClientLoyaltySelected
      }
      this.getDetailForClient({ clientOwnerCode: res.clientOwnerCode }).then(
        () => this.initCatalog()
      )
    })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('loyalty', ['getBalance', 'getCatalog']),
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    initCatalog() {
      this.getBalance({
        ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
      })
      this.getCatalog({
        catalog_name:
          this.mainClientLoyaltySelected.clientOwnerType === 'RES'
            ? 'COLLECTION'
            : 'COLLECTION-BS',
        codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
      }).then(() => {
        this.loader = false
      })
    }
  }
}
</script>

<style lang="scss">
.loyalty.catalogue {
  .header-main {
    padding-bottom: 35px;
  }
  .card {
    width: 100%;
  }
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

<style lang="scss">
.blackCoins {
  display: flex;
  margin-top: 20px;
}

.blackCoins__count {
  font-size: 60px;
  font-weight: 600;
  line-height: 0.8;
  margin-bottom: 8px;
}

.blackCoins__img {
  width: 72px;
  height: 72px;
  margin-left: 0;
  margin-right: 24px;
}
.catalogue {
  &__header {
    min-height: 260px;
  }

  &__coins {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
    padding: 0 12px;

    &-title {
      font-size: 32px;
      font-weight: 600;
    }
    &-img {
      width: 72px;
      height: 72px;
      margin-left: auto;
      margin-right: 24px;
    }
    &-count {
      font-size: 60px;
      font-weight: 600;
      line-height: 0.8;
      margin-right: 50px;
    }
  }

  &__content {
    padding-top: 61px;
    width: 100%;
  }

  &__items {
    margin: 0 -8px;
  }

  &__items &__item {
    margin: 0 15px;
  }

  .v-card:not(.v-sheet--tile):not(
      .v-card--shaped
    ).v-card--rounded.v-card--link:before {
    border-radius: 20px;
    background-color: white;
  }
}
</style>
