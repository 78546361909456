<template>
  <div
    class="supplyCard supplyCard__container"
    :class="
      card.type[0] === ServiceCardType.RAISE
        ? 'raise-card'
        : card.type[0] === ServiceCardType.CUSTOM
        ? 'custom-card'
        : ''
    "
  >
    <div
      class="supplyCard__card-img"
      v-if="card.type[0] !== ServiceCardType.RAISE"
      :style="`backgroundImage: url('${resolve_img_url(card.card_image)}')`"
    ></div>
    <div
      class="supplyCard__container-choosed-for-you"
      v-if="card.type[0] === ServiceCardType.CUSTOM"
    >
      <p class="CaptionS color--blue">Scelto per te</p>
    </div>
    <div class="supplyCard__inside-info">
      <h5 class="BodyM-Strong mb-3">
        {{ getRealName(card) }}
      </h5>
      <div class="BodyM" v-html="card.card_body"></div>
      <template v-if="card.cta_type !== 'WEB_LINK'">
        <div class="ButtonS" @click="getDestinationLink(card)">
          {{ card.cta_label }}
        </div>
      </template>
      <template v-else>
        <a
          :href="card.cta_url"
          class="ButtonS"
          target="_blank"
          @click="
            trackTapEvent({
              name: 'customOffers_card_tap',
              params: {
                section_type: $route.params.type,
                card_title: card.card_title
              }
            })
          "
          >{{ card.cta_label }}</a
        >
      </template>
    </div>
  </div>
</template>

<script>
import { ServiceCardType } from '../../../js/type/service_card.type'
import { ServiceSectionHelper } from '../../../js/service/service_section_card_helper'
import { axiosCMS } from '../../../js/axiosInstances'
import { mapActions } from 'vuex'
import { eventBus } from '../../../main'

export default {
  name: 'SupplyCard',
  computed: {
    ServiceCardType() {
      return ServiceCardType
    }
  },
  props: {
    card: { type: Array[Object] },
    client: { type: Object }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    getDestinationLink(card) {
      this.trackTapEvent({
        name: 'customOffers_card_tap',
        params: {
          section_type: this.$route.params.type,
          card_title: card.card_title
        }
      })
      let destination = ServiceSectionHelper.getSectionUrl(card.cta_section)
      if (destination.params && destination.params?.component) {
        eventBus.$emit('send_dynamin_component', destination)
      } else {
        this.$router.push(ServiceSectionHelper.getSectionUrl(card.cta_section))
      }
    },
    resolve_img_url: function (path) {
      return axiosCMS.defaults.baseURL + path
    },
    getRealName(card) {
      let name = card.card_title
      if (card.type[0] === ServiceCardType.RAISE) {
        name =
          this.client.firstName.toLowerCase() +
          ' ' +
          this.client.lastName.toLowerCase()
        if (this.client.clientOwnerType !== 'RES') {
          name = this.client.businessName.toLowerCase()
        }
      }
      return name
    }
  }
}
</script>

<style scoped lang="scss">
.supplyCard {
  max-width: 362px;
  //width: 362px;

  &__inside-info {
    padding: 16px;
    gap: 16px;
  }

  &__card-img {
    height: 110px;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__container-choosed-for-you {
    max-width: 133px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 24px rgba(44, 53, 66, 0.06),
      0px 1px 8px rgba(44, 53, 66, 0.08), 0px 2px 4px rgba(44, 53, 66, 0.08);
    padding: 7px 8px;
    gap: 4px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-bottom: 0;
      text-transform: uppercase;

      &:before {
        display: inline-block;
        content: '';
        vertical-align: text-bottom;
        margin-right: 4px;
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../../assets/profile/forYou.svg');
      }
    }
  }

  &__container {
    border: 1px solid $neutri-neutral-30;
    border-radius: 8px;
    margin-bottom: 20px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .ButtonS {
      position: relative;
      cursor: pointer;

      &::after {
        content: '';
        display: inline-block;
        vertical-align: text-bottom;
        margin-left: 13px;
        width: 8px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../../assets/ic_chevron_dx_blue.svg');
      }
    }

    &.raise-card {
      .supplyCard__card-img {
        display: none;
      }

      h5 {
        position: relative;
        text-transform: capitalize;

        &::before {
          content: '';
          display: inline-block;
          vertical-align: text-bottom;
          margin-right: 10px;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url('../../../assets/profile/home.svg');
        }
      }
    }

    &.custom-card {
      .supplyCard__container-choosed-for-you {
        position: absolute;
        right: 15px;
        top: 8px;
      }
    }
  }
}
</style>
