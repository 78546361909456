<template>
  <layout>
    <v-row class="slider">
      <carousel
        :perPage="1"
        :navigationEnabled="true"
        :navigation-next-label="nextLabel"
        :navigation-prev-label="prevLabel"
        paginationColor="#FFF"
        paginationActiveColor="#12256A"
        :paginationPadding="5"
        class="carousel"
        :value="0"
        easing="ease-in"
        :speed="750"
        @page-change="pageChange"
      >
        <slide
          v-for="(item, i) in actions"
          :key="item.id"
          :class="
            isActive && i === 0 ? 'VueCarousel-slide-active' : '' + 'slide'
          "
        >
          <v-col>
            <div class="block">
              <div class="block__item">
                <card :id="item.id" />
              </div>
            </div>
          </v-col>
        </slide>
      </carousel>
    </v-row>
    <!-- <v-row class="desc pt-10" justify="center">
      <span>Cliccando su Entra in squadra accetti il
        <a :href="linkRegolamento" target="_blank" download
            @click="trackTapEvent({name:'greeners_enrollmentRules_tap', params: {clientCode: mainClientLoyaltySelected.clientOwnerCode, clientType: mainClientLoyaltySelected.clientOwnerType}})">
        regolamento dell’iniziativa</a> e del
        <a :href="linkConcorso" target="_blank" download
            @click="trackTapEvent({name:'greeners_enrollmentContest_tap', params: {clientCode: mainClientLoyaltySelected.clientOwnerCode, clientType: mainClientLoyaltySelected.clientOwnerType}})">
        concorso</a></span>
    </v-row> -->
    <v-row class="desc pt-10" justify="center">
      <v-btn
        class="v-btn--shadowed mb-8 white"
        x-large
        width="343"
        height="54"
        @click="join"
      >
        <strong>Vai al diario</strong>
      </v-btn>
    </v-row>
  </layout>
</template>

<script>
import Layout from './LandingLayout'
import Card from './BecomeGreenerCard'
import { Carousel, Slide } from 'vue-carousel'
import { mapActions, mapGetters } from 'vuex'
import GenErr from '@/js/genericErrors'
import { axiosAdvice } from '@/js/axiosInstances.js'
import { ENV_CONFIGS } from '@/js/configs.js'

export default {
  name: 'BecomeGreenerPage',
  components: {
    Layout,
    Carousel,
    Slide,
    Card
  },
  data: () => ({
    actions: [
      { id: '1', title: 'greencoins' },
      { id: '2', title: 'missions' },
      { id: '3', title: 'levels' }
      // { id: '4', title: 'cfp' },
      // { id: '5', title: 'determinazione' }
    ],
    value: 0,
    isActive: true,
    showAccountDataDialog: false,
    linkRegolamento:
      'https://sorgenia.s3-eu-west-1.amazonaws.com/public/seconda_edizione/regolamento_greeners.pdf',
    linkConcorso:
      'https://sorgenia.s3-eu-west-1.amazonaws.com/public/seconda_edizione/regolamento_concorso_premi.pdf',
    nextLabel: '<div class="icon next"/>',
    prevLabel: '<div class="icon prev"/>'
  }),
  mounted() {
    this.getMainClient().then((res) => {
      if (this.mainClientLoyaltySelected) {
        res = this.mainClientLoyaltySelected
      } else {
        this.$router.push({ name: 'introPage' })
      }

      axiosAdvice.defaults.baseURL =
        res.clientOwnerType === 'BUS'
          ? ENV_CONFIGS.WEKIT_BASE.replace('residential', 'business')
          : ENV_CONFIGS.WEKIT_BASE.replace('business', 'residential')

      this.getMemberStatus({ ext_uuid: res.clientOwnerCode }).then((resp) => {
        if (resp.user_registered === null) {
          GenErr.showErrorMessage()
          this.$router.push({ name: 'home' })
        }
      })
    })
  },
  computed: {
    ...mapGetters('account', ['mainClient', 'mainClientLoyaltySelected']),
    ...mapGetters('loyalty', ['memberStatus'])
  },
  methods: {
    pageChange(val) {
      let card = this.actions[val]
      this.isActive = false
      this.trackTapEvent({
        name: 'greeners_enrollmentCard_scroll',
        params: {
          client_code: this.mainClientLoyaltySelected.clientOwnerCode,
          client_type: this.mainClientLoyaltySelected.clientOwnerType,
          card_title: card.title
        }
      })
    },
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('loyalty', ['getMemberStatus']),
    ...mapActions('account', ['getMainClient']),
    join() {
      this.$router.push({ name: 'loyaltyPage' })
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  overflow-x: hidden;
}
.carousel {
  width: 100%;
  overflow-x: hidden;
  .block {
    &__item {
      width: 750px;
      transform: scaleY(0.8) scaleX(0.8);
      margin: auto;
      display: flex;
      justify-content: center;
      transition: transform 750ms 250ms;
    }
  }
  .VueCarousel-slide {
    display: flex;
    align-items: center;
  }
  .VueCarousel-slide.slide {
    transform: translate(50%, 0);
    transition: transform 750ms ease-in;
  }
}
.desc {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  a {
    color: #000000;
  }
}

.missing-data-dialog {
  background-color: white;
  padding: 30px;
  img {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.slider {
  .VueCarousel-slide-active {
    & ~ .VueCarousel-slide.slide {
      transform: translate(-50%, 0);
    }
    transform: translate(0, 0) !important;
    .block {
      &__item {
        transform: scaleY(1);
      }
    }
  }
  .VueCarousel-navigation {
    position: relative;
    width: 654px;
    margin: 0 auto;
  }
  .VueCarousel-navigation-next {
    top: 30px !important;
    right: -30px !important;
  }
  .VueCarousel-navigation-prev {
    top: 30px !important;
    left: -40px !important;
  }
  .VueCarousel-navigation-next,
  .VueCarousel-navigation-prev {
    background: $srg-blue !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    &:focus {
      outline: none !important;
    }
    .icon {
      &.next {
        content: url('../../../assets/icons/chevron-dx-white.svg');
      }
      &.prev {
        content: url('../../../assets/icons/chevron-sx-white.svg');
      }
    }
    &.VueCarousel-navigation--disabled {
      display: block !important;
      opacity: 1 !important;
      background-color: #f8f9fc !important;
      .icon {
        &.next {
          fill: #b7bfd2;
          content: url('../../../assets/icons/chevron-dx-grey.svg');
        }
        &.prev {
          fill: #b7bfd2;
          content: url('../../../assets/icons/chevron-sx-grey.svg');
        }
      }
    }
  }
}
</style>
