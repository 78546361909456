<template>
  <div class="std-side-padding pb-10">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h1 class="support__title font-weight-bold">Stato richieste</h1>
      </v-col>
    </v-row>
    <v-row v-if="hasDreamService">
      <v-col>
        <div class="support__activeRequestBox">
          <h5 class="support__activeRequestBox-title">
            Segui in tempo reale lo stato delle tue nuove richieste
            <span
              v-if="clientNotifications > 0"
              class="support__activeRequestBox-notify"
              >{{ clientNotifications }}</span
            >
          </h5>
          <h4
            class="support__activeRequestBox-btn"
            @click="openDreamServiceList()"
          >
            Visualizza Richieste
          </h4>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="statuses.length === 0 && !hasDreamService">
      <v-col cols="6">
        <h4>Non sono presenti richieste</h4>
      </v-col>
    </v-row>
    <v-row v-if="statuses.length === 0 && hasDreamService">
      <v-col cols="6">
        <h4>Non sono presenti altre richieste</h4>
      </v-col>
    </v-row>
    <div v-for="item in statuses" :key="item.status">
      <v-row>
        <v-col>
          <h2 class="font-weight-bold-title">
            {{ item.sottotipologiaInterazione }}
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Codice cliente</th>
                  <th class="text-left">Data</th>
                  <th class="text-left">Stato</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-bold" v-text="item.codiceCliente"></td>
                  <td
                    class="font-weight-bold"
                    v-text="item.created.split('-').reverse().join('.')"
                  ></td>
                  <td
                    class="title font-weight-bold"
                    :class="
                      item.esitoInterazione === 'Chiusa'
                        ? 'black--text'
                        : 'purple--text'
                    "
                    v-text="item.esitoInterazione.toUpperCase()"
                  ></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { checkMobile } from '@/js/utils.js'

export default {
  name: 'statusesComponent',
  async beforeMount() {
    await this.setStatuses(this.$route.params.clientCode)
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      selectItems: ['In Lavorazione', 'Chiusa'],
      isMobile: checkMobile()
    }
  },
  computed: {
    ...mapGetters('interactions', ['statuses']),
    ...mapGetters('account', ['accountClients']),
    ...mapGetters('session', ['username', 'token', 'username']),
    currentClientCode() {
      return this.$route.params.clientCode
    },
    hasDreamService() {
      let clientCode = this.currentClientCode
      return this.clients[clientCode].hasDreamService
    },
    clientNotifications() {
      let clientCode = this.currentClientCode
      if (clientCode) {
        return this.clients[clientCode].notifications
      } else return 0
    },
    clients() {
      return this.accountClients
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Stato richieste'
        }
      ]

      return res
    }
  },
  methods: {
    onCalendarClick: function () {
      this.isTouched = true
    },
    openDreamServiceList() {
      let destination = this.isMobile
        ? 'publicDreamServiceList'
        : 'DreamServiceList'
      this.trackTapEvent('dreamService_requestProgress_tap')
      this.$router.push({
        name: destination,
        params: { clientCode: this.currentClientCode }
      })
    },
    ...mapActions('interactions', ['setStatuses']),
    ...mapActions('account', ['getMainClient']),
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>
<style scoped lang="scss">
.support {
  &__activeRequestBox {
    width: 730px;
    height: 150px;
    border-radius: 40px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
    background: linear-gradient(180deg, white 65%, #12256a 35%);
    text-align: center;
    &-notify {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $srg-green;
      color: white;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      display: inline-block;
      position: relative;
      bottom: 10px;
      padding-top: 1px;
    }
    &-title {
      font-size: 16px;
      position: relative;
      top: 40px;
    }
    &-btn {
      font-size: 18px;
      position: relative;
      top: 85px;
      color: white;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.font-weight-bold {
  &-title {
    font-size: 24px;
    font-weight: bold;
  }
}
.v-btn {
  color: $srg-lightgray;
  padding: 0 10px !important;
  span {
    color: $srg-blue;
    letter-spacing: normal;
  }
}
.statuses {
  th,
  td {
    border: 0 !important;
    padding-left: 0 !important;
  }
}
.theme--light.v-btn--active::before {
  opacity: 0;
}
th.text-left,
td,
th {
  height: auto;
  min-width: 140px;
  border: 0px !important;
}
th.text-left,
td {
  border: 0 !important;
  min-width: 200px;
}
td.font-weight-bold:not(:last-child) {
  width: 1px;
}
th,
td {
  padding: 0;
}
.v-data-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
}
thead > tr.d-flex {
  height: 20px;
}
.support__sub-title {
  margin-bottom: 30px;
}
</style>
