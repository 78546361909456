<template>
  <div class="consumption mb-12">
    <TcVars />
    <v-tabs v-model="tab" id="tabProfile" icons-and-text grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab id="house" href="#house">
        <div
          :class="`status ${stateHouse.name} ${stateHouse.type} ${stateHouse.active}`"
        >
          <span
            v-if="stateHouse.number > 1 && stateHouse.name !== 'notification'"
            class="OtherCounter"
            >{{ stateHouse.number }}</span
          >
        </div>
      </v-tab>
      <v-tab id="photovoltaic" href="#photovoltaic">
        <div
          :class="`status ${statePhotovoltaic.name} ${statePhotovoltaic.type} ${statePhotovoltaic.active}`"
        >
          <span
            v-if="
              statePhotovoltaic.number > 1 &&
              statePhotovoltaic.name !== 'notification'
            "
            class="OtherCounter"
            >{{ statePhotovoltaic.number }}</span
          >
        </div>
      </v-tab>
      <v-tab id="mobility" href="#mobility">
        <div
          :class="`status ${stateMobility.name} ${stateMobility.type} ${stateMobility.active}`"
        >
          <span v-if="stateMobility.number > 1" class="OtherCounter">{{
            stateMobility.number
          }}</span>
        </div>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :eager="true" value="house">
        <House />
      </v-tab-item>
      <v-tab-item :eager="true" value="photovoltaic">
        <Photovoltaic />
      </v-tab-item>
      <v-tab-item :eager="true" value="mobility">
        <Mobility />
      </v-tab-item>
    </v-tabs-items>

    <WatingCheckupEnergetico :dialogCheckup.sync="dialog" />
  </div>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import { eventBus } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import WatingCheckupEnergetico from '../../components/checkup_energetico/WatingCheckupEnergetico.vue'
import { AlertService } from '../../js/service/alertService'
import House from './consumption/House.vue'
import Mobility from './consumption/Mobility.vue'
import Photovoltaic from './consumption/Photovoltaic.vue'

export default {
  name: 'consumptionProfile',
  data() {
    return {
      tab: 'house',
      dialog: false,
      refreshComputedStatus: 1
    }
  },
  created() {
    if (this.$route.params.value) {
      this.tab = this.$route.params.value.tab
    }
    // switching the selected tab
    eventBus.$on('onboarding:profile', (valueTab) => {
      this.tab = valueTab
    })
    eventBus.$on('statusProfile:forceUpdate', () => {
      this.refreshComputedStatus++
    })
    // redirect to first tab when closed dialog onBoarding on profile
    eventBus.$on('profile:onboardingClosed', () => {
      this.tab = 'house'
    })
  },
  components: {
    House,
    Photovoltaic,
    Mobility,
    WatingCheckupEnergetico,
    TcVars
  },
  watch: {
    async userProfile(user) {
      try {
        await this.getRetrieveAddress(user.clientOwnerCode)
        await this.getRetrieveHomeSurvey(user.clientOwnerCode)
        await this.getRetrievePhotovoltaicSurvey(user.clientOwnerCode)
        await this.getRetrieveMobilitySurvey(user.clientOwnerCode)
      } catch (err) {
        this.alertMethod()
      }
    },
    tab(val) {
      this.trackTapEvent({
        name: 'profile2_tab_tap',
        params: { tab_name: val, client_code: this.userProfile.clientOwnerCode }
      })
    }
  },
  computed: {
    ...mapGetters('profile', [
      'userProfile',
      'house',
      'photovoltaic',
      'mobility'
    ]),
    stateHouse() {
      if (this.refreshComputedStatus < 0 || this.house.length === 0)
        return false
      let newSurvey = this.house.findIndex((h) => !h.saved) >= 0
      let saved = this.house.findIndex((h) => h.saved && !h.completed) >= 0
      return {
        name:
          newSurvey && this.tab !== 'house'
            ? 'notification'
            : newSurvey
            ? 'saved'
            : saved
            ? 'saved'
            : this.house.length > 0
            ? 'completed'
            : 'none',
        type: this.house.length > 1 ? 'multi' : 'mono',
        number: this.house.length,
        active: this.tab === 'house' ? 'active' : ''
      }
    },
    statePhotovoltaic() {
      if (this.refreshComputedStatus < 0 || this.photovoltaic.length === 0)
        return false
      let newSurvey = this.photovoltaic.findIndex((h) => !h.saved) >= 0
      let saved =
        this.photovoltaic.findIndex((h) => h.saved && !h.completed) >= 0
      return {
        name:
          newSurvey && this.tab !== 'photovoltaic'
            ? 'notification'
            : newSurvey
            ? 'saved'
            : saved
            ? 'saved'
            : this.photovoltaic.length > 0
            ? 'completed'
            : 'none',
        type: this.photovoltaic.length > 1 ? 'multi' : 'mono',
        number: this.photovoltaic.length,
        active: this.tab === 'photovoltaic' ? 'active' : ''
      }
    },
    stateMobility() {
      if (this.refreshComputedStatus < 0) return false
      let newSurvey = !this.mobility.saved
      let saved = this.mobility.saved && !this.mobility.completed
      return {
        name:
          newSurvey || saved
            ? 'saved'
            : !!this.mobility.wallbox || !!this.mobility.interesse
            ? 'completed'
            : 'none',
        type: 'mono',
        number: 0,
        active: this.tab === 'mobility' ? 'active' : ''
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('profile', [
      'getRetrieveHomeSurvey',
      'getRetrievePhotovoltaicSurvey',
      'getRetrieveMobilitySurvey',
      'getRetrieveAddress'
    ]),
    alertMethod() {
      AlertService(
        'Servizio non disponibile',
        'Al momento non è possibile caricare i questionari. Ti invitiamo a riprovare.',
        false,
        'Chiudi',
        '#'
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.consumption {
  max-width: 815px;
}
</style>
<style lang="scss">
#tabProfile {
  .v-tabs-slider-wrapper {
    height: 15px !important;
    bottom: 0px;
  }
  .v-tabs-slider {
    background: none;
    background-image: url('../../assets/tabs-slider.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  a.v-tab:not(.v-tab--active) {
    border-bottom: 3.5px solid #b7bfd2;
    margin-bottom: 11px;
  }
  a.v-tab {
    margin-bottom: 11px;
  }
  #house::after {
    content: url('../../assets/profile/house.svg');
  }
  #house.v-tab.v-tab--active::after {
    content: url('../../assets/profile/house-active.svg');
  }
  #photovoltaic::after {
    content: url('../../assets/profile/photovoltaic.svg');
  }
  #photovoltaic.v-tab.v-tab--active::after {
    content: url('../../assets/profile/photovoltaic-active.svg');
  }
  #mobility::after {
    content: url('../../assets/profile/mobility.svg');
  }
  #mobility.v-tab.v-tab--active::after {
    content: url('../../assets/profile/mobility-active.svg');
  }

  .status {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: calc(50% + 10px);
    top: 8px;
    color: white;
    line-height: 0px;
    &.completed {
      background: $feedback-success;
      &.mono::after {
        content: url('../../assets/profile/completed.svg');
      }
    }
    &.saved {
      background: $neutri-neutral-30;
      &.active {
        color: $srg-blue;
      }
      &.mono {
        display: none;
      }
    }
    &.notification {
      width: 12px;
      height: 12px;
      background: $secondari-magenta-100;
    }
  }
}
</style>
