<template>
  <router-view></router-view>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'supportTemplate',
  beforeMount() {
    this.init()
  },
  methods: {
    ...mapActions('support', ['init'])
  }
}
</script>
