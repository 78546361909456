<template>
  <v-row>
    <v-col cols="4" class="custom-input-field">
      <v-text-field
        tabindex="1"
        class="auth__input mb-10"
        clearable
        v-model="name"
        label="Nome*"
        placeholder="Inserisci il tuo nome"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
      <v-text-field
        tabindex="1"
        class="auth__input mb-10"
        clearable
        v-model="address"
        label="Indirizzo*"
        placeholder="Inserisci il tuo indirizzo"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
      <v-text-field
        tabindex="1"
        class="auth__input mb-10"
        clearable
        ref="CAP"
        @input="postalValidator($event)"
        v-model.trim="CAP"
        label="CAP*"
        placeholder="Inserisci il CAP"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
      <v-text-field
        tabindex="1"
        class="auth__input"
        clearable
        ref="mobile"
        @input="mobileValidator($event)"
        v-model.trim="mobile"
        label="Cellulare*"
        placeholder="Inserisci il tuo numero di telefono"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
    </v-col>
    <v-col cols="4" class="custom-input-field">
      <v-text-field
        tabindex="1"
        class="auth__input mb-10"
        clearable
        v-model="surname"
        label="Cognome*"
        placeholder="Inserisci il tuo cognome"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
      <v-text-field
        tabindex="1"
        class="auth__input mb-10"
        clearable
        v-model="streetNumber"
        label="Numero civico*"
        placeholder="Inserisci il numero civico"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
      <v-text-field
        tabindex="1"
        class="auth__input mb-10"
        clearable
        v-model="province"
        label="Provincia*"
        placeholder="Inserisci la provincia"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
    </v-col>
    <v-col class="custom-input-field">
      <v-text-field
        :error-messages="emailErrors"
        @blur="v$.email.$touch()"
        v-model.trim="email"
        label="Email*"
        tabindex="1"
        class="auth__input mb-10"
        clearable
        placeholder="Inserisci la tua email"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
      <v-autocomplete
        v-model="city"
        class="mb-10"
        placeholder="Inserisci la citta"
        :items="cities"
        label="Città*"
        persistent-placeholder
      >
        <template v-slot:append>
          <v-icon color="primary">mdi-chevron-down</v-icon>
        </template>
      </v-autocomplete>
      <v-text-field
        tabindex="1"
        class="auth__input"
        clearable
        v-model="country"
        label="Nazione*"
        placeholder="Inserisci la nazione"
        color="#6e7689"
        validate-on-blur
        persistent-placeholder
      />
    </v-col>
  </v-row>
</template>

<script>
import { CITIES } from '@/js/constants'
import { email } from '@vuelidate/validators'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'ContactForm',
  props: {
    catalog: { type: String },
    prizeId: { type: String }
  },
  data: () => ({
    cities: CITIES,
    name: '',
    city: '',
    mobile: '',
    surname: '',
    CAP: '',
    email: '',
    address: '',
    streetNumber: '',
    province: '',
    country: '',
    username: '',
    errorMessage: ''
  }),
  computed: {
    isFormFilled() {
      return !(
        this.name &&
        this.city &&
        this.mobile &&
        this.surname &&
        this.CAP &&
        this.email &&
        this.v$.email.email &&
        this.address &&
        this.streetNumber &&
        this.country &&
        this.province
      )
    },
    formData() {
      return {
        catalog_name: this.catalog,
        prizeoption_id: this.prizeId,
        shipping_receiver_name: this.name + ' ' + this.surname,
        shipping_email: this.email,
        shipping_phone: this.mobile[0],
        shipping_city: this.city,
        shipping_zip_code: this.CAP[0],
        shipping_street: this.address,
        shipping_street_number: this.streetNumber,
        shipping_district: this.province,
        shipping_country: this.country
      }
    },
    emailErrors: function () {
      let errArray = []
      if (!this.v$.email.$dirty) return errArray

      this.v$.email.email.$invalid === true &&
        errArray.push('inputs must be a valid email')
      return errArray
    }
  },
  watch: {
    isFormFilled(value) {
      this.$emit('update:isFormFilled', value)
    },
    formData(value) {
      this.$emit('update:formData', value)
    }
  },
  methods: {
    mobileValidator(val) {
      if (val) {
        this.mobile = val.match(/[0-9]+/) || this.$refs.mobile.reset()
      } else {
        this.mobile = ''
      }
    },
    postalValidator(val) {
      if (val) {
        this.CAP = val.match(/[0-9]+/) || this.$refs.CAP.reset()
      } else {
        this.CAP = ''
      }
    }
  },
  validations: {
    email: { email }
  }
}
</script>

<style lang="scss" scoped></style>
