<template>
  <div class="link" @click="goToLoyalty">
    <LayoutCard :registered="initiative.registered" :loaded="loaded">
      <template v-slot:bkg>
        <div
          :class="`bkg ${
            initiative.registered ||
            IDENTITY_STATUS_ERROR ||
            IDENTITY_BALANCE_ERROR ||
            IDENTITY_LEVEL_ERROR
              ? 'full'
              : ''
          }`"
        />
      </template>
      <template v-slot:text>
        <div
          class="text"
          v-if="
            IDENTITY_STATUS_ERROR ||
            IDENTITY_BALANCE_ERROR ||
            IDENTITY_LEVEL_ERROR
          "
        >
          <div class="title big">
            <div class="t-white HeadingL-Upper mb-12">GREENERS</div>
            <div class="t-white HeadingL-Upper pt-1">IN CARICAMENTO</div>
          </div>
        </div>
        <div
          class="text justify-end"
          v-else-if="memberStatus.statusGreeners === 'Pending'"
        >
          <div class="title big">
            <div class="t-white HeadingL-Upper">BENVENUTO IN</div>
            <div class="t-white HeadingL-Upper">GREENERS</div>
          </div>
        </div>
        <div class="text justify-end" v-else-if="!initiative.registered">
          <div class="title big">
            <div class="t-white">COMMUNITY</div>
            <div class="t-green">GREENERS</div>
          </div>
        </div>
        <div class="text justify-space-between" v-else>
          <div class="title big">
            <div
              v-if="memberStatus.status_category === 'UNAWARE'"
              class="t-white HeadingL-Upper"
            >
              BENVENUTO IN GREENERS!
            </div>
            <div v-else class="t-white HeadingL-Upper">GREENERS</div>
          </div>
          <div class="d-flex align-center">
            <img v-if="getLevelInfo" :src="getLevelInfo" width="40" />
            <div class="desc">
              <h5>{{ initiative.memberBalance }}</h5>
              <div class="BodyL">I tuoi Green Coins</div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:button>
        <div class="btn-card" v-if="!loaded" />
        <div
          class="btn-card"
          v-else-if="
            IDENTITY_STATUS_ERROR ||
            IDENTITY_BALANCE_ERROR ||
            IDENTITY_LEVEL_ERROR
          "
        >
          <div class="btn-text">VAI AI CONTENUTI</div>
          <img src="../../../assets/icons/chevron_rx.svg" width="24px" />
        </div>
        <div
          class="btn-card"
          v-else-if="memberStatus.statusGreeners === 'Pending'"
        >
          <div class="btn-text">SCOPRI ORA</div>
          <img src="../../../assets/icons/chevron_rx.svg" width="24px" />
        </div>
        <div class="btn-card" v-else-if="!initiative.registered">
          <div class="btn-text">UNISCITI</div>
          <img src="../../../assets/icons/active.svg" width="24px" />
        </div>
        <div
          class="btn-card white"
          v-else-if="memberStatus.status_category === 'UNAWARE'"
        >
          <div class="btn-text color--blue">PASSA ALL' AZIONE</div>
          <img src="../../../assets/icons/chevron_rx.svg" width="24px" />
        </div>
        <div class="btn-card" v-else>
          <div class="btn-text">VAI AI CONTENUTI</div>
          <img src="../../../assets/icons/chevron_rx.svg" width="24px" />
        </div>
      </template>
    </LayoutCard>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LayoutCard from './LayoutCard'
import { accessGreeners } from '../../../js/greeners/accessGreeners'
import { eventBus } from '@/main'
export default {
  name: 'greenersCard',
  components: {
    LayoutCard
  },
  props: {
    loaded: { type: Boolean, default: false },
    initiative: {
      type: Object,
      default() {
        return { registerd: false, hasMissions: false }
      }
    }
  },
  computed: {
    ...mapGetters('loyalty', [
      'memberStatus',
      'IDENTITY_STATUS_ERROR',
      'IDENTITY_BALANCE_ERROR',
      'IDENTITY_LEVEL_ERROR',
      'TRANSACTION_LIST_ERROR',
      'NEW_MEMBER_STATUS_ERROR'
    ]),
    ...mapGetters('account', [
      'mainClientLoyaltySelected',
      'mainClientLoyaltyRes',
      'mainClientLoyaltyBus',
      'oldestLoyaltyRes',
      'oldestLoyaltyBus'
    ]),
    ...mapGetters('supply', ['supplies']),
    getLevelInfo() {
      if (!this.initiative.level) return false
      switch (this.initiative.level.level_name) {
        case 'LEVEL0':
          return require('@/assets/greeners/new_greeners_icon.svg')
        case 'LEVEL1':
          return require('@/assets/icons/card/follower.svg')
        case 'LEVEL2':
          return require('@/assets/icons/card/friend.svg')
        case 'LEVEL3':
          return require('@/assets/icons/card/influencer.svg')
        case 'LEVEL4':
          return require('@/assets/icons/card/ambassador.svg')
        default:
          return require('@/assets/greeners/new_greeners_icon.svg')
      }
    }
  },
  methods: {
    ...mapActions('loyalty', [
      'newTransaction',
      'getTransactionsListNewEdition',
      'greenerRegistration'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('loyalty', ['setMainClientLoyaltySelected']),
    async checkRegisteredEdition() {
      let transaction = await this.getTransactionsListNewEdition({
        ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
      })
      let edition = transaction.wekit_data.filter(
        (t) => t.action === '31' || t.action === '32'
      )
      if (edition.length === 0 || edition.length > 1) {
        this.isOldRegistrationLoyalty = false
      } else if (edition.length === 1) {
        this.isOldRegistrationLoyalty = edition[0].action === '31'
      }
    },
    async goToLoyalty() {
      let resp = await accessGreeners()
      if (resp === 'multiClients') {
        eventBus.$emit('greenersSelectClient', 'prova')
        return false
      }
      if (this.NEW_MEMBER_STATUS_ERROR) {
        this.$router.push({
          name: 'home',
          params: { typeError: 'IDENTITY_STATUS_ERROR' }
        })
      }
      this.$router.push({ name: 'loyaltyPage' }).catch(() => {})
    },
    missionType(missionType) {
      switch (missionType) {
        case 'SURVEY':
          return 'survey'
        case 'VIDEO':
          return 'videoPage'
        case 'FOTO':
          return 'uploadPhoto'
        case 'QUIZ':
          return 'quiz'
        default:
          return 'loyaltyPage'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bkg {
  background-image: url('../../../assets/greeners/greeners_new_home_join.png');
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(0deg, #142254 0%, rgba(0, 0, 0, 0) 72.48%);
  }

  background-size: cover;
  &.full {
    background-image: url('../../../assets/greeners/greeners_new_home.png');
    background-position: top;
    &::before {
      background: linear-gradient(
        0deg,
        #188538 -19.13%,
        rgba(0, 0, 0, 0) 157.72%
      );
    }
  }
}
.link {
  cursor: pointer;
}
</style>
