<template>
  <div class="errorMessage">
    <h2>Sei cliente Sorgenia?</h2>
    <h5>Controlla di aver scritto bene il tuo Codice Fiscale o Partita IVA.</h5>
    <h2>Non sei cliente Sorgenia?</h2>
    <h5>Chiama l’Assistenza al numero <strong>800294333</strong></h5>
    <v-btn
      width="459"
      height="54"
      class="white--text auth__btn"
      color="primary"
      @click="$emit('errorGoBack')"
    >
      Torna al codice fiscale/p.iva
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'fiscalCodeError'
}
</script>
<style lang="scss" scoped>
.errorMessage {
  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 1.2px;
    margin-bottom: 16px;
    color: $srg-blue;
  }
  h5 {
    color: black;
    letter-spacing: 0.5px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 32px;
  }
}
</style>
