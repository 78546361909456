<template>
  <div>
    <div class="dashboard-monitor-autosufficienza">
      <div class="mb-2">
        <span class="HeadingsTitleM">Autosufficienza</span>
        <img
          class="ml-2 mr-2 link"
          src="../../../assets/icons/info.svg"
          style="color: #12256a"
          height="18px"
          @click="dialog = !dialog"
        />
      </div>
      <div>
        <span class="HeadingsTitleM"
          >{{ value.toFixed(2).replace('.', ',') }} %
        </span>
      </div>
    </div>
    <div>
      <v-progress-linear
        :value="value"
        :height="6"
        color="#0F9C38"
        background-color="#EAEEF5"
        rounded
      ></v-progress-linear>
    </div>
    <v-dialog v-model="dialog" max-width="558">
      <v-card class="dialog-card">
        <v-card-title class="dialog-title">
          <div class="HeadingsTitleM">Autosufficienza</div>
          <img
            class="link"
            @click="dialog = false"
            src="@/assets/icons/ic-close.svg"
        /></v-card-title>
        <v-card-text class="dialog-text"
          >Indica quanto sei in grado di renderti autonomo dalla rete elettrica
          nazionale per far fronte ai consumi della tua abitazione, grazie
          all'energia autoconsumata istantaneamente dall'impianto o accumulata
          in batteria.</v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Number, required: true }
  },
  data() {
    return { dialog: false }
  }
}
</script>
<style lang="scss" scoped>
.v-progress-linear {
  position: relative;
  top: 0px;
}
.dashboard-monitor-autosufficienza {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.dialog-card {
  padding: 16px;
}
.dialog-title {
  display: flex;
  justify-content: space-between;
  font-size: 22px !important;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 26.4px;
}

.dialog-text {
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 22.4px;
}
</style>
