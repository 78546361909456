import { render, staticRenderFns } from "./Request.vue?vue&type=template&id=18adb31c&scoped=true"
import script from "./Request.vue?vue&type=script&lang=js"
export * from "./Request.vue?vue&type=script&lang=js"
import style0 from "../../styles/upselling/_main.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./Request.vue?vue&type=style&index=1&id=18adb31c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18adb31c",
  null
  
)

export default component.exports