<template>
  <div>
    <svg
      width="375"
      height="456"
      viewBox="0 0 375 456"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- linea produzione -->
      <path
        d="M256 112H212V118.5H256V112Z"
        fill="#12256A"
        v-if="showProduzione"
      ></path>
      <!-- pallino produzione -->
      <circle
        cx="25"
        cy="25"
        r="25"
        transform="matrix(-1 0 0 1 258 90)"
        fill="url(#paint0_radial_2764_102879)"
        v-if="showProduzione && productionAnimation === 'IN'"
      >
        <animateMotion
          v-if="productionAnimation === 'IN'"
          path="M -25.903 0.66 L 23.777 0.052"
          calcMode="linear"
          begin="0s"
          :dur="duration"
          fill="freeze"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
        ></animateMotion>
      </circle>
      <!-- linea batteria -->
      <path
        d="M208.24 259.5C238.558 279.712 269.411 299.091 300.21 318.59L300.16 318.66C305.2 322.5 308.35 328.24 308.91 334.57H309V365H302.59V336.57C302.59 331.01 299.79 325.91 295.1 322.94C264.971 303.861 234 284 205 265L208.24 259.5Z"
        fill="#12256A"
      ></path>
      <!-- pallino batteria -->
      // TODO
      <circle
        cx="25"
        cy="25"
        r="25"
        transform="matrix(-1 0 0 1 282 270)"
        fill="url(#paint1_radial_2764_102879)"
        v-if="batteryAnimation !== 'NONE' && showBatteria"
      >
        <animateMotion
          v-if="batteryAnimation == 'IN'"
          path="M -50.317 -32.537 C 66.595 46.027 47.196 11.102 48.467 75.84"
          calcMode="linear"
          :dur="duration"
          fill="freeze"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
        ></animateMotion>
        <animateMotion
          v-if="batteryAnimation == 'OUT'"
          path="M -50.317 -32.537 C 66.595 46.027 47.196 11.102 48.467 75.84"
          calcMode="linear"
          :dur="duration"
          fill="freeze"
          repeatCount="indefinite"
        ></animateMotion>
      </circle>
      <circle
        cx="25"
        cy="25"
        r="25"
        transform="matrix(-1 0 0 1 282 270)"
        fill="url(#paint1_radial_2764_102879)"
        v-if="consumptionAnimation === 'OUT' && !showBatteria"
      >
        <animateMotion
          v-if="consumptionAnimation === 'OUT'"
          path="M -50.317 -32.537 C 66.595 46.027 47.196 11.102 48.467 75.84"
          calcMode="linear"
          :dur="duration"
          fill="freeze"
          repeatCount="indefinite"
        ></animateMotion>
      </circle>
      <!-- grafico batteria -->
      <template v-if="showBatteriaStilo && showBatteria">
        <g style="mix-blend-mode: overlay" opacity="0.5">
          <path
            d="M300.224 277.495V318.843C304.847 318.604 310.047 317.282 313.625 314.3V272.952C311.767 274.493 309.48 275.597 307.066 276.322C304.818 277.008 302.452 277.376 300.224 277.485V277.495Z"
            fill="white"
          ></path>
        </g>
        <path
          d="M311.406 268.824C304.094 272.999 292.404 272.88 285.288 268.555C281.789 266.428 280.059 263.684 280.059 260.94L280 312.06C280 314.794 281.73 317.548 285.229 319.676C292.336 324 304.036 324.12 311.347 319.944C315.062 317.827 316.938 315.003 316.938 312.17L316.997 261.049C316.997 263.883 315.12 266.706 311.396 268.824H311.406Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M311.777 254.322C304.671 249.997 292.971 249.878 285.66 254.054C278.348 258.229 278.182 265.119 285.288 269.443C292.394 273.768 304.095 273.887 311.406 269.712C318.717 265.536 318.884 258.647 311.777 254.322ZM306.831 266.928C302.12 269.622 294.574 269.543 289.99 266.759C285.405 263.976 285.513 259.532 290.224 256.837C294.936 254.143 302.482 254.223 307.066 257.006C311.65 259.79 311.543 264.234 306.831 266.928Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M306.841 264.833C302.13 267.527 294.584 267.447 289.999 264.664C287.741 263.292 286.627 261.522 286.627 259.753V261.84C286.617 263.61 287.732 265.38 289.989 266.752C294.574 269.535 302.11 269.615 306.831 266.921C309.226 265.549 310.438 263.729 310.438 261.91V259.822C310.438 261.651 309.236 263.471 306.831 264.833H306.841Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M307.076 254.916C302.492 252.132 294.955 252.053 290.234 254.747C285.523 257.441 285.415 261.875 290 264.669C294.584 267.452 302.12 267.532 306.841 264.838C311.553 262.143 311.66 257.709 307.076 254.916ZM302.032 261.915C300.048 263.048 296.881 263.018 294.955 261.845C293.03 260.672 293.069 258.803 295.053 257.67C297.037 256.536 300.204 256.566 302.13 257.739C304.056 258.912 304.016 260.781 302.032 261.915Z"
          fill="#6E7689"
        ></path>
        <path
          d="M302.032 259.82C300.048 260.953 296.881 260.923 294.955 259.75C294.007 259.174 293.538 258.428 293.538 257.682V259.77C293.538 260.516 293.997 261.261 294.946 261.838C296.871 263.011 300.048 263.041 302.022 261.907C303.029 261.331 303.538 260.565 303.538 259.8V257.712C303.538 258.478 303.029 259.243 302.022 259.82H302.032Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M302.13 255.653C300.204 254.48 297.027 254.45 295.053 255.583C293.078 256.717 293.03 258.586 294.955 259.759C296.881 260.932 300.058 260.962 302.032 259.828C304.006 258.695 304.055 256.826 302.13 255.653Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M300.224 277.495V318.843C304.847 318.604 310.047 317.282 313.625 314.3V272.952C311.767 274.493 309.48 275.597 307.066 276.322C304.818 277.008 302.452 277.376 300.224 277.485V277.495Z"
          fill="#6E7689"
        ></path>

        <!-- 100% -->
        <path
          d="M300.224 281.634C304.671 281.335 309.754 279.834 313.625 276.792V274.466C313.351 274.406 313.048 274.466 312.745 274.694C309.07 277.558 304.915 278.512 300.224 278.85V281.634Z"
          :fill="calcColor(100)"
        ></path>
        <!-- 90% -->
        <path
          d="M311.015 279.892C306.264 282.765 302.296 282.467 300.224 282.825V285.628C305.697 285.261 311.504 282.875 313.625 280.836V278.46C312.872 278.381 311.973 279.325 311.025 279.892H311.015Z"
          :fill="calcColor(90)"
        ></path>
        <!-- 80% -->
        <path
          d="M313.625 284.78V282.454C313.351 282.394 313.048 282.454 312.745 282.683C307.359 286.878 302.579 286.411 300.224 286.818V289.622C304.671 289.324 309.754 287.822 313.625 284.78Z"
          :fill="calcColor(80)"
        ></path>
        <!-- 70% -->
        <path
          d="M313.625 288.843V286.457C312.872 286.377 311.973 287.321 311.025 287.888C309.949 288.544 308.395 289.25 306.753 289.737C303.674 290.682 301.533 290.672 300.224 290.831V293.615C305.453 293.257 311.445 290.891 313.625 288.843Z"
          :fill="calcColor(70)"
        ></path>
        <!-- 60% -->
        <path
          d="M312.745 290.679C309.197 293.442 304.476 294.695 300.224 294.844V297.618C304.867 297.31 309.822 295.769 313.625 292.776V290.45C313.351 290.39 313.048 290.45 312.745 290.679Z"
          :fill="calcColor(60)"
        ></path>
        <!-- 50% -->
        <path
          d="M313.625 294.419C312.96 294.339 312.198 295.184 311.025 295.88C307.731 297.869 303.879 298.564 300.233 298.823V301.607C305.639 301.239 311.494 298.863 313.634 296.825V294.409L313.625 294.419Z"
          :fill="calcColor(50)"
        ></path>
        <!-- 40% -->
        <path
          d="M302.491 305.34C306.577 304.713 310.389 303.332 313.625 300.776V298.45C313.331 298.39 313.019 298.45 312.745 298.669C311.191 299.882 309.431 300.926 306.753 301.721C303.655 302.675 301.524 302.665 300.224 302.824V305.598C300.918 305.608 301.729 305.449 302.491 305.33V305.34Z"
          :fill="calcColor(40)"
        ></path>
        <!-- 30% -->
        <path
          d="M312.745 302.669C309.079 305.522 304.876 306.497 300.224 306.825V309.608C300.439 309.589 301.289 309.519 302.491 309.33C306.47 308.714 310.331 307.362 313.625 304.767V302.43C313.351 302.371 313.048 302.43 312.745 302.659V302.669Z"
          :fill="calcColor(30)"
        ></path>
        <!-- 20% -->
        <path
          d="M312.745 306.667C311.161 307.9 309.353 308.944 306.753 309.719C303.684 310.664 301.533 310.664 300.224 310.833V313.596C304.71 313.298 309.773 311.787 313.625 308.755V306.438C313.331 306.379 313.019 306.438 312.745 306.657V306.667Z"
          :fill="calcColor(20)"
        ></path>
        <!-- 10% -->
        <path
          d="M313.625 312.761V310.434C313.351 310.375 313.048 310.434 312.745 310.663C311.161 311.896 309.353 312.94 306.753 313.715C303.674 314.66 301.533 314.65 300.224 314.809V317.592C304.74 317.274 309.764 315.783 313.625 312.751V312.761Z"
          :fill="calcColor(10)"
        ></path>
        <path
          d="M312.764 273.588V313.793C309.402 316.596 304.622 317.908 300.224 318.266V318.843C304.847 318.604 310.047 317.282 313.625 314.3V272.952C313.351 273.181 313.058 273.38 312.764 273.588Z"
          fill="#6E7689"
        ></path>

        <!-- batteria stilo val -->
        <text
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            white-space-collapse: preserve;
            text-wrap: nowrap;
          "
          x="282.016"
          y="245.876"
        >
          {{ carica }}
        </text>

        <!-- batteria stilo % -->
        <text
          style="
            white-space: pre;
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 16px;
          "
          :x="carica >= 100 ? '320.626' : carica >= 10 ? '305.626' : '295.626'"
          y="245.876"
        >
          %
        </text>
      </template>
      <!-- linea rete -->
      <path
        v-if="showRete"
        d="M175.76 259.5C145.442 279.712 114.589 299.091 83.79 318.59L83.84 318.66C78.8 322.5 75.65 328.24 75.09 334.57H75V365H81.41V336.57C81.41 331.01 84.21 325.91 88.9 322.94C119.029 303.861 150 284 179 265L175.76 259.5Z"
        fill="#12256A"
      ></path>
      <!-- pallino rete -->
      <circle
        cx="25"
        cy="25"
        r="25"
        transform="matrix(-1 0 0 1 152 270)"
        fill="url(#paint2_radial_2764_102879)"
        v-if="showRete && networkAnimation !== 'NONE'"
      >
        <animateMotion
          v-if="networkAnimation == 'IN'"
          path="M 48.139 -31.1 C -64.803 45.26 -47.829 11.899 -48.484 74.819"
          calcMode="linear"
          :dur="duration"
          fill="freeze"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
        ></animateMotion>
        <animateMotion
          v-if="networkAnimation == 'OUT'"
          path="M 48.139 -31.1 C -64.803 45.26 -47.829 11.899 -48.484 74.819"
          calcMode="linear"
          :dur="duration"
          fill="freeze"
          repeatCount="indefinite"
        ></animateMotion>
      </circle>

      <!-- linea consumo-->
      <path
        d="M195.5 272H189V377H195.5V272Z"
        fill="#12256A"
        v-if="showConsumo && showBatteria"
      ></path>
      <!-- pallino consumo -->
      <circle
        cx="25"
        cy="25"
        r="25"
        transform="matrix(-1 0 0 1 217 326)"
        fill="url(#paint3_radial_2764_102879)"
        v-if="consumptionAnimation === 'OUT' && showBatteria"
      >
        <animateMotion
          v-if="consumptionAnimation === 'OUT'"
          path="M 0.689 -81.583 L -0.832 23.956"
          calcMode="linear"
          :dur="duration"
          fill="freeze"
          repeatCount="indefinite"
        ></animateMotion>
      </circle>
      <!-- grafico traliccio -->
      <template v-if="showRete">
        <path
          d="M80.8723 321L76.3589 318.123L76.359 18.8749L80.8724 21.752L80.8723 321Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M80.8726 321L85.386 318.123V31.8749L80.8726 34.752V321Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M85.3857 19.8771L80.8723 22.7542L76.3589 19.8771L80.8723 17L85.3857 19.8771Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M114.926 66.931L43 25.8983L44.9095 24.8122L116.835 65.8449L114.926 66.931Z"
          fill="#6E7689"
        ></path>
        <path
          d="M114.926 69.2073L43 28.1556V25.8786L114.926 66.9304V69.2073Z"
          fill="#6E7689"
        ></path>
        <path
          d="M116.835 65.8434V68.1299L114.926 69.2065V66.9295L116.835 65.8434Z"
          fill="#6E7689"
        ></path>
        <path
          d="M49.1429 27.0309C48.0049 27.0309 47.0791 27.5453 47.0791 28.1836C47.0791 28.822 48.0049 29.3364 49.1429 29.3364C50.2809 29.3364 51.2068 28.822 51.2068 28.1836C51.2068 27.5453 50.2809 27.0309 49.1429 27.0309Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M51.2071 24.9645H47.0698V28.1846H51.2071V24.9645Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M49.1429 23.812C48.0049 23.812 47.0791 24.3264 47.0791 24.9647C47.0791 25.603 48.0049 26.1175 49.1429 26.1175C50.2809 26.1175 51.2068 25.603 51.2068 24.9647C51.2068 24.3264 50.2809 23.812 49.1429 23.812Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M54.0712 29.7889C52.9332 29.7889 52.0073 30.3033 52.0073 30.9416C52.0073 31.58 52.9332 32.0944 54.0712 32.0944C55.2092 32.0944 56.135 31.58 56.135 30.9416C56.135 30.3033 55.2092 29.7889 54.0712 29.7889Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M56.1354 27.7225H51.998V30.9426H56.1354V27.7225Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M54.0712 26.5701C52.9332 26.5701 52.0073 27.0846 52.0073 27.7229C52.0073 28.3612 52.9332 28.8757 54.0712 28.8757C55.2092 28.8757 56.135 28.3612 56.135 27.7229C56.135 27.0846 55.2092 26.5701 54.0712 26.5701Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M106.255 59.5153C105.117 59.5153 104.191 60.0298 104.191 60.6681C104.191 61.3064 105.117 61.8208 106.255 61.8208C107.393 61.8208 108.319 61.3064 108.319 60.6681C108.319 60.0298 107.393 59.5153 106.255 59.5153Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M108.319 57.4489H104.182V60.669H108.319V57.4489Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M106.255 56.2966C105.117 56.2966 104.191 56.811 104.191 57.4493C104.191 58.0876 105.117 58.6021 106.255 58.6021C107.393 58.6021 108.319 58.0876 108.319 57.4493C108.319 56.811 107.393 56.2966 106.255 56.2966Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M111.184 62.2693C110.046 62.2693 109.12 62.7838 109.12 63.4221C109.12 64.0604 110.046 64.5749 111.184 64.5749C112.322 64.5749 113.248 64.0604 113.248 63.4221C113.248 62.7838 112.322 62.2693 111.184 62.2693Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M113.248 60.1873H109.11V63.4074H113.248V60.1873Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M111.184 59.0506C110.046 59.0506 109.12 59.5651 109.12 60.2034C109.12 60.8417 110.046 61.3561 111.184 61.3561C112.322 61.3561 113.248 60.8417 113.248 60.2034C113.248 59.5651 112.322 59.0506 111.184 59.0506Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M77.5938 46.789C77.5938 47.1796 77.8349 47.6369 78.1338 47.8084C78.4328 47.9798 78.6739 47.7988 78.6739 47.4082C78.6739 47.0176 78.4328 46.5603 78.1338 46.3888C77.8349 46.2173 77.5938 46.3984 77.5938 46.789Z"
          fill="white"
        ></path>
        <path
          d="M78.1338 40.754C78.1338 41.1446 78.3749 41.6019 78.6739 41.7734C78.9728 41.9449 79.2139 41.7639 79.2139 41.3732C79.2139 40.9826 78.9728 40.5253 78.6739 40.3539C78.3749 40.1824 78.1338 40.3634 78.1338 40.754Z"
          fill="black"
        ></path>
        <path
          d="M78.3456 40.3047L77.7959 40.6191L78.4806 42.0958L79.0593 41.7718L78.3456 40.3047Z"
          fill="black"
        ></path>
        <path
          d="M77.5933 41.0626C77.5933 41.4532 77.8344 41.9105 78.1333 42.082C78.4323 42.2535 78.6734 42.0724 78.6734 41.6818C78.6734 41.2912 78.4323 40.8339 78.1333 40.6625C77.8344 40.491 77.5933 40.672 77.5933 41.0626Z"
          fill="black"
        ></path>
        <path
          d="M78.1338 37.7891C78.1338 38.1798 78.3749 38.637 78.6739 38.8085C78.9728 38.98 79.2139 38.799 79.2139 38.4084C79.2139 38.0178 78.9728 37.5605 78.6739 37.389C78.3749 37.2175 78.1338 37.3985 78.1338 37.7891Z"
          fill="black"
        ></path>
        <path
          d="M78.3456 37.3516L77.7959 37.666L78.4806 39.1427L79.0593 38.8188L78.3456 37.3516Z"
          fill="black"
        ></path>
        <path
          d="M77.5933 38.0977C77.5933 38.4883 77.8344 38.9456 78.1333 39.1171C78.4323 39.2886 78.6734 39.1076 78.6734 38.717C78.6734 38.3264 78.4323 37.8691 78.1333 37.6976C77.8344 37.5261 77.5933 37.7071 77.5933 38.0977Z"
          fill="black"
        ></path>
        <path
          d="M114.926 90.2087L43 49.1759L44.9095 48.0898L116.835 89.1226L114.926 90.2087Z"
          fill="#6E7689"
        ></path>
        <path
          d="M114.926 92.485L43 51.4332V49.1562L114.926 90.208V92.485Z"
          fill="#6E7689"
        ></path>
        <path
          d="M116.835 89.1212V91.4076L114.926 92.4842V90.2072L116.835 89.1212Z"
          fill="#6E7689"
        ></path>
        <path
          d="M49.1429 50.3008C48.0049 50.3008 47.0791 50.8152 47.0791 51.4535C47.0791 52.0919 48.0049 52.6063 49.1429 52.6063C50.2809 52.6063 51.2068 52.0919 51.2068 51.4535C51.2068 50.8152 50.2809 50.3008 49.1429 50.3008Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M51.2071 48.2344H47.0698V51.4545H51.2071V48.2344Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M49.1429 47.082C48.0049 47.082 47.0791 47.5965 47.0791 48.2348C47.0791 48.8731 48.0049 49.3876 49.1429 49.3876C50.2809 49.3876 51.2068 48.8731 51.2068 48.2348C51.2068 47.5965 50.2809 47.082 49.1429 47.082Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M54.0712 53.0664C52.9332 53.0664 52.0073 53.5809 52.0073 54.2192C52.0073 54.8575 52.9332 55.3719 54.0712 55.3719C55.2092 55.3719 56.135 54.8575 56.135 54.2192C56.135 53.5809 55.2092 53.0664 54.0712 53.0664Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M56.1354 50.9844H51.998V54.2045H56.1354V50.9844Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M54.0712 49.8477C52.9332 49.8477 52.0073 50.3621 52.0073 51.0004C52.0073 51.6387 52.9332 52.1532 54.0712 52.1532C55.2092 52.1532 56.135 51.6387 56.135 51.0004C56.135 50.3621 55.2092 49.8477 54.0712 49.8477Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M106.255 82.7852C105.117 82.7852 104.191 83.2996 104.191 83.9379C104.191 84.5762 105.117 85.0907 106.255 85.0907C107.393 85.0907 108.319 84.5762 108.319 83.9379C108.319 83.2996 107.393 82.7852 106.255 82.7852Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M108.319 80.7188H104.182V83.9389H108.319V80.7188Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M106.255 79.5664C105.117 79.5664 104.191 80.0809 104.191 80.7192C104.191 81.3575 105.117 81.8719 106.255 81.8719C107.393 81.8719 108.319 81.3575 108.319 80.7192C108.319 80.0809 107.393 79.5664 106.255 79.5664Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M111.184 85.5391C110.046 85.5391 109.12 86.0535 109.12 86.6918C109.12 87.3301 110.046 87.8446 111.184 87.8446C112.322 87.8446 113.248 87.3301 113.248 86.6918C113.248 86.0535 112.322 85.5391 111.184 85.5391Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M113.248 83.4609H109.11V86.6811H113.248V83.4609Z"
          fill="#B7BFD2"
        ></path>
        <path
          d="M111.183 82.3242C110.045 82.3242 109.12 82.8387 109.12 83.477C109.12 84.1153 110.045 84.6297 111.183 84.6297C112.321 84.6297 113.247 84.1153 113.247 83.477C113.247 82.8387 112.321 82.3242 111.183 82.3242Z"
          fill="#DFE4EF"
        ></path>
        <path
          d="M77.5933 70.0666C77.5933 70.4572 77.8344 70.9145 78.1333 71.086C78.4323 71.2575 78.6734 71.0765 78.6734 70.6859C78.6734 70.2953 78.4323 69.838 78.1333 69.6665C77.8344 69.495 77.5933 69.676 77.5933 70.0666Z"
          fill="white"
        ></path>
        <path
          d="M78.1333 64.0313C78.1333 64.4219 78.3744 64.8792 78.6734 65.0507C78.9723 65.2222 79.2134 65.0412 79.2134 64.6506C79.2134 64.26 78.9723 63.8027 78.6734 63.6312C78.3744 63.4597 78.1333 63.6407 78.1333 64.0313Z"
          fill="black"
        ></path>
        <path
          d="M78.3456 63.5938L77.7959 63.9082L78.4806 65.3849L79.0593 65.061L78.3456 63.5938Z"
          fill="black"
        ></path>
        <path
          d="M77.5933 64.3438C77.5933 64.7344 77.8344 65.1917 78.1333 65.3632C78.4323 65.5347 78.6734 65.3537 78.6734 64.9631C78.6734 64.5725 78.4323 64.1152 78.1333 63.9437C77.8344 63.7722 77.5933 63.9532 77.5933 64.3438Z"
          fill="black"
        ></path>
        <path
          d="M78.1338 61.0782C78.1338 61.4688 78.3749 61.9261 78.6739 62.0976C78.9728 62.2691 79.2139 62.0881 79.2139 61.6975C79.2139 61.3069 78.9728 60.8496 78.6739 60.6781C78.3749 60.5066 78.1338 60.6876 78.1338 61.0782Z"
          fill="black"
        ></path>
        <path
          d="M78.3456 60.6407L77.7959 60.9551L78.4806 62.4222L79.0593 62.1078L78.3456 60.6407Z"
          fill="black"
        ></path>
        <path
          d="M77.5933 61.3868C77.5933 61.7774 77.8344 62.2347 78.1333 62.4062C78.4323 62.5777 78.6734 62.3967 78.6734 62.0061C78.6734 61.6155 78.4323 61.1582 78.1333 60.9867C77.8344 60.8152 77.5933 60.9962 77.5933 61.3868Z"
          fill="black"
        ></path>
        <path
          d="M78.1338 50.3399C78.1338 50.7305 78.3749 51.1878 78.6739 51.3593C78.9728 51.5307 79.2139 51.3497 79.2139 50.9591C79.2139 50.5685 78.9728 50.1112 78.6739 49.9397C78.3749 49.7682 78.1338 49.9493 78.1338 50.3399Z"
          fill="black"
        ></path>
        <path
          d="M78.3456 49.8906L77.7959 50.205L78.4806 51.6816L79.0593 51.3577L78.3456 49.8906Z"
          fill="black"
        ></path>
        <path
          d="M77.5933 50.6485C77.5933 51.0391 77.8344 51.4964 78.1333 51.6678C78.4323 51.8393 78.6734 51.6583 78.6734 51.2677C78.6734 50.8771 78.4323 50.4198 78.1333 50.2483C77.8344 50.0768 77.5933 50.2579 77.5933 50.6485Z"
          fill="black"
        ></path>
        <path
          d="M78.1338 27.7149C78.1338 28.1055 78.3749 28.5628 78.6739 28.7343C78.9728 28.9058 79.2139 28.7248 79.2139 28.3342C79.2139 27.9436 78.9728 27.4863 78.6739 27.3148C78.3749 27.1433 78.1338 27.3243 78.1338 27.7149Z"
          fill="black"
        ></path>
        <path
          d="M78.3456 27.2774L77.7959 27.5918L78.4806 29.0685L79.0593 28.7446L78.3456 27.2774Z"
          fill="black"
        ></path>
        <path
          d="M77.5933 28.0274C77.5933 28.418 77.8344 28.8753 78.1333 29.0468C78.4323 29.2183 78.6734 29.0373 78.6734 28.6467C78.6734 28.2561 78.4323 27.7988 78.1333 27.6273C77.8344 27.4558 77.5933 27.6368 77.5933 28.0274Z"
          fill="black"
        ></path>
        <path
          d="M104.201 58.5421C100.681 56.6558 92.368 51.5969 86.3501 42.9845C79.1749 32.724 78.5962 28.8274 78.5866 28.7893L78.5287 28.332L78.2105 28.675C78.2105 28.675 74.3239 32.7049 68.2192 33.2765C59.7806 34.0482 55.624 30.9234 55.5855 30.8853L55.3926 31.2854C55.5565 31.4188 59.636 34.515 68.2674 33.7053C73.5717 33.2098 77.2171 30.1993 78.249 29.2562C78.5576 30.4851 79.9657 34.6008 86.0029 43.2322C92.1847 52.0733 100.778 57.2179 104.221 59.0375V58.5421H104.201Z"
          fill="black"
        ></path>
        <path
          d="M55.6235 30.9958C55.6235 31.1006 55.5561 31.2244 55.4792 31.272C55.4023 31.3197 55.335 31.272 55.335 31.1577C55.335 31.0434 55.4023 30.9291 55.4792 30.8814C55.5561 30.8338 55.6235 30.8814 55.6235 30.9958Z"
          fill="black"
        ></path>
        <path
          d="M104.202 81.6749C100.682 79.7886 92.3685 74.7297 86.3505 66.1173C79.1754 55.8568 78.5967 51.9602 78.5871 51.9221L78.5292 51.4648L78.2109 51.8078C78.2109 51.8078 74.3244 55.8377 68.2197 56.4094C59.7811 57.181 55.6245 54.0467 55.5859 54.0181L55.3931 54.4182C55.557 54.5516 59.6365 57.6479 68.2679 56.8381C73.5721 56.3427 77.2176 53.3321 78.2495 52.389C78.5581 53.6179 79.9662 57.7336 86.0034 66.3651C92.1852 75.2061 100.778 80.3507 104.221 82.1703V81.6749H104.202Z"
          fill="black"
        ></path>
        <path
          d="M55.6235 54.1286C55.6235 54.2334 55.5561 54.3572 55.4792 54.4048C55.4023 54.4525 55.335 54.4049 55.335 54.2905C55.335 54.1762 55.4023 54.0619 55.4792 54.0142C55.5561 53.9666 55.6235 54.0142 55.6235 54.1286Z"
          fill="black"
        ></path>
      </template>
      <!-- grafico casa -->
      <template>
        <path
          d="M192.091 278.99L104 229.877L192.091 186.543L279 230.455L192.091 278.99Z"
          fill="white"
          stroke="#F8F9FC"
        ></path>
        <path
          d="M191.374 272.626V222.298L119.222 180.863V231.191L191.374 272.626Z"
          fill="#EAEEF5"
        ></path>
        <path
          d="M227.455 149.258L263.526 180.866V193.735L262.741 231.865L245.772 241.39L191.374 272.629V222.301L227.455 149.258Z"
          fill="#DFE4EF"
        ></path>
        <g clip-path="url(#clip0_2764_102879)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M240.187 191.441V244.52L223.557 254.104V201.064L240.187 191.441Z"
            fill="white"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M234.882 196.428L237.054 195.164V242.742L225.215 249.604V247.339V202.016L234.882 196.428Z"
            fill="#F8F9FC"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M238.529 194.32V243.56L237.055 242.743V195.166L238.529 194.32Z"
            fill="#B7BFD2"
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M225.215 251.227L238.47 243.559L237.054 242.742L225.215 249.604V251.227Z"
            fill="#DFE4EF"
          ></path>
        </g>
        <path
          d="M223.642 147.215L189.21 216.383L119.804 176.047L154.227 106.879L223.642 147.215Z"
          fill="#B7BFD2"
        ></path>
        <g clip-path="url(#clip1_2764_102879)">
          <path
            d="M215.812 114.426L178.779 185.139L104.645 144.746L141.689 74L215.812 114.426Z"
            fill="#EAEEF5"
          ></path>
          <path
            d="M210.555 115.923L143.374 79.2891L109.901 143.257L177.082 179.857L210.555 115.923Z"
            fill="#12256A"
          ></path>
          <path
            d="M194.853 106.811L159.837 174.855L160.596 175.203L195.611 107.159L194.853 106.811Z"
            fill="#EAEEF5"
          ></path>
          <path
            d="M177.954 94.7839L142.733 163.227L143.492 163.575L178.712 95.1319L177.954 94.7839Z"
            fill="#EAEEF5"
          ></path>
          <path
            d="M159.845 85.1379L124.64 153.551L125.398 153.899L160.603 85.4859L159.845 85.1379Z"
            fill="#EAEEF5"
          ></path>
          <path
            d="M123.104 109.998L122.683 110.688L195.016 150.176L195.437 149.487L123.104 109.998Z"
            fill="#EAEEF5"
          ></path>
          <path
            d="M178.779 185.142L181.589 186.027L218.832 116.077L215.812 114.43"
            fill="#DFE4EF"
          ></path>
          <path
            opacity="0.1"
            d="M143.375 79.2891L177.082 179.857L210.555 115.923L143.375 79.2891Z"
            fill="white"
          ></path>
        </g>
      </template>
      <!-- area produzione -->
      <template v-if="showProduzione">
        <rect
          x="251.5"
          y="76.3281"
          width="108"
          height="77"
          rx="15.5"
          fill="white"
        ></rect>
        <rect
          x="251.5"
          y="76.3281"
          width="108"
          height="77"
          rx="15.5"
          stroke="#DFE4EF"
        ></rect>

        <!-- prod val -->
        <text
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            white-space-collapse: preserve;
            text-wrap: nowrap;
          "
          :x="parseFloat(produzione) >= 10 ? '261.52' : '271.52'"
          y="111.861"
        >
          {{ produzione }}
        </text>

        <!-- kW produzione -->
        <text
          style="
            white-space: pre;
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 16px;
          "
          x="315.392"
          y="111.861"
        >
          kW
        </text>

        <!-- label produzione -->
        <text
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-weight: 500;
            white-space: pre;
            font-size: 14px;
          "
          x="267.0"
          y="134.113"
        >
          {{ labelProduzione }}
        </text>
      </template>
      <!-- area rete -->
      <template v-if="showRete">
        <rect
          x="16.5"
          y="362.328"
          width="108"
          height="77"
          rx="15.5"
          fill="white"
        ></rect>
        <rect
          x="16.5"
          y="362.328"
          width="108"
          height="77"
          rx="15.5"
          stroke="#DFE4EF"
        ></rect>

        <!-- rete val-->
        <text
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            white-space: pre;
            transform-box: fill-box;
            transform-origin: 46.1351% 65.384%;
          "
          :x="parseFloat(rete) >= 10 ? '26.883' : '36.883'"
          y="397.277"
        >
          {{ rete }}
        </text>

        <!-- kW Rete -->
        <text
          style="
            white-space: pre;
            fill: rgb(51, 51, 51);
            font-family: Arial, sans-serif;
            font-size: 16px;
          "
          x="82.695"
          y="397.277"
        >
          kW
        </text>

        <!-- label rete -->
        <text
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            white-space: pre;
          "
          x="55.671"
          y="420.392"
        >
          {{ labelRete }}
        </text>
      </template>
      <!-- area consumo -->
      <template v-if="showConsumo && showBatteria">
        <rect
          x="133.5"
          y="362.328"
          width="108"
          height="77"
          rx="15.5"
          fill="white"
        ></rect>
        <rect
          x="133.5"
          y="362.328"
          width="108"
          height="77"
          rx="15.5"
          stroke="#DFE4EF"
        ></rect>

        <!-- consumo val-->
        <text
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            white-space-collapse: preserve;
            text-wrap: nowrap;
          "
          :x="parseFloat(consumo) >= 10 ? '143.79' : '153.79'"
          y="397.277"
        >
          {{ consumo }}
        </text>

        <!-- kW consumo -->
        <text
          style="
            white-space: pre;
            fill: rgb(51, 51, 51);
            font-family: Arial, sans-serif;
            font-size: 16px;
          "
          x="198.777"
          y="397.277"
        >
          kW
        </text>

        <!-- label consumo-->
        <text
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            white-space: pre;
          "
          x="157.593"
          y="420.392"
        >
          {{ labelConsumo }}
        </text>
      </template>
      <!-- area batteria -->
      <template>
        <rect
          x="250.5"
          y="362.328"
          width="108"
          height="77"
          rx="15.5"
          fill="white"
        ></rect>
        <rect
          x="250.5"
          y="362.328"
          width="108"
          height="77"
          rx="15.5"
          stroke="#DFE4EF"
        ></rect>
        <!-- batteria val-->
        <text
          v-if="showBatteria"
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            white-space-collapse: preserve;
            text-wrap: nowrap;
          "
          :x="parseFloat(batteria) >= 10 ? '260.233' : '270.233'"
          y="397.277"
        >
          {{ batteria }}
        </text>
        <text
          v-else
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            white-space-collapse: preserve;
            text-wrap: nowrap;
          "
          :x="parseFloat(consumo) >= 10 ? '260.233' : '270.233'"
          y="397.277"
        >
          {{ consumo }}
        </text>
        <!-- kW batteria-->
        <text
          style="
            white-space: pre;
            fill: rgb(51, 51, 51);
            font-family: Arial, sans-serif;
            font-size: 16px;
          "
          x="315.852"
          y="397.277"
        >
          kW
        </text>
        <!-- label batteria-->
        <text
          style="
            fill: rgb(51, 51, 51);
            font-family: Roboto;
            font-weight: 500;
            white-space: pre;
            font-size: 14px;
          "
          x="278.076"
          y="420.392"
        >
          {{ showBatteria == true ? labelBatteria : labelConsumo }}
        </text>
      </template>
      <defs>
        <radialGradient
          id="paint0_radial_2764_102879"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25 25) rotate(90) scale(18.75)"
        >
          <stop offset="0.125" stop-color="white"></stop>
          <stop offset="0.2448" :stop-color="color"></stop>
          <stop offset="0.6823" stop-color="white" stop-opacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_2764_102879"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25 25) rotate(90) scale(18.75)"
        >
          <stop offset="0.125" stop-color="white"></stop>
          <stop offset="0.2448" :stop-color="color"></stop>
          <stop offset="0.6823" stop-color="white" stop-opacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint2_radial_2764_102879"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25 25) rotate(90) scale(18.75)"
        >
          <stop offset="0.125" stop-color="white"></stop>
          <stop offset="0.2448" :stop-color="color"></stop>
          <stop offset="0.6823" stop-color="white" stop-opacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint3_radial_2764_102879"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25 25) rotate(90) scale(18.75)"
        >
          <stop offset="0.125" stop-color="white"></stop>
          <stop offset="0.2448" :stop-color="color"></stop>
          <stop offset="0.6823" stop-color="white" stop-opacity="0"></stop>
        </radialGradient>
        <clipPath id="clip0_2764_102879">
          <rect
            width="16.6297"
            height="62.6623"
            fill="white"
            transform="translate(223.557 191.441)"
          ></rect>
        </clipPath>
        <clipPath id="clip1_2764_102879">
          <rect
            width="114.188"
            height="112.024"
            fill="white"
            transform="translate(104.645 74)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    produzione: { type: String, required: true },
    labelProduzione: { type: String, default: 'Produzione' },
    carica: { type: String, required: true },
    rete: { type: String, required: true },
    labelRete: { type: String, default: 'Rete' },
    consumo: { type: String, required: true },
    labelConsumo: { type: String, default: 'Consumo' },
    batteria: { type: String, required: true },
    labelBatteria: { type: String, default: 'Batteria' },
    duration: { type: String, default: '2s' },
    color: { type: String, default: '#97D700' },
    showRete: { type: Boolean, default: true },
    showConsumo: { type: Boolean, default: true },
    showBatteria: { type: Boolean, default: true },
    showBatteriaStilo: { type: Boolean, default: true },
    showProduzione: { type: Boolean, default: true },

    batteryAnimation: {
      type: String,
      validator(value) {
        const valid = ['IN', 'OUT', 'NONE'].includes(value)
        if (!valid) {
          console.error(
            'Invalid prop "batteryAnimation", available values are "IN","OUT", "NONE".'
          )
        }
        return valid
      }
    },
    networkAnimation: {
      type: String,
      validator(value) {
        const valid = ['IN', 'OUT', 'NONE'].includes(value)
        if (!valid) {
          console.error(
            'Invalid prop "networkAnimation", available values are "IN","OUT", "NONE".'
          )
        }
        return valid
      }
    },
    productionAnimation: {
      type: String,
      validator(value) {
        const valid = ['IN', 'NONE'].includes(value)
        if (!valid) {
          console.error(
            'Invalid prop "productionAnimation", available values are "IN", "NONE".'
          )
        }
        return valid
      }
    },
    consumptionAnimation: {
      type: String,
      validator(value) {
        const valid = ['OUT', 'NONE'].includes(value)
        if (!valid) {
          console.error(
            'Invalid prop "consumptionAnimation", available values are "OUT", "NONE".'
          )
        }
        return valid
      }
    }
  },
  methods: {
    calcColor(perc) {
      const caricaHalfRound = Math.round(this.carica / 10) * 10
      return perc <= caricaHalfRound ? '#12256A' : 'white'
    }
  }
}
</script>

<style lang="scss" scoped></style>
