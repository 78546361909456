<template>
  <div class="successPage bkg--blue">
    <v-row class="successPage__content">
      <v-col :class="`col-5 img-${status}`"></v-col>
      <v-col class="col-7 info-block" v-if="status === 'OK'">
        <h1 class="info-block__title">
          La richiesta di pagamento è andata a buon fine.
        </h1>
        <div v-if="$route.params.isMultiPayment" class="info-block__text mb-12">
          Lo stato
          {{
            $route.params.instalment ? 'dei piani di rientro' : 'delle bollette'
          }}
          rimarrà "In Verifica" per il tempo tecnico necessario a ricevere
          l'effettivo incasso.<br /><br />
          Troverai lo stato del pagamento aggiornato nella sezione
          <router-link :to="{ name: 'ownerSupplies' }">utenze</router-link>.
        </div>
        <div v-else class="info-block__text mb-12">
          Lo stato
          <span v-if="$route.params.instalment"> del piano di rientro </span>
          <span v-else> della bolletta {{ $route.params.idFattura }} </span>
          rimarrà "In Verifica" per il tempo tecnico necessario a rivedere
          l'effettivo incasso.<br /><br />
          <span v-if="$route.params.instalment">
            Troverai lo stato del pagamento aggiornato nella sezione
          </span>
          <span v-else>
            Troverai lo stato del pagamento aggiornato nella sezione Utenze
            oppure sulla
          </span>

          <router-link
            v-if="$route.params.instalment"
            :to="{
              name: 'ownerSupplies'
            }"
            >utenze</router-link
          >
          <router-link
            v-else
            :to="{
              name: 'bills',
              params: { idFattura: $route.params.idFattura }
            }"
            >tua bolletta</router-link
          >.
        </div>
        <v-btn
          @click="$router.push({ name: 'home' })"
          class="info-block__button v-btn--shadowed last"
          width="355"
          height="54"
          >Torna alla Home Page</v-btn
        >
      </v-col>

      <v-col class="col-7 info-block" v-if="status === 'KO'">
        <h1 class="info-block__title">
          Il pagamento non è andato a buon fine.
        </h1>
        <div v-if="$route.params.isMultiPayment" class="info-block__text mb-12">
          <span v-if="$route.params.instalment"
            >Il pagamento delle rate dei piani di rientro non è riuscito.
            <br /><br />
            Puoi tornare indietro e riprovare di nuovo
          </span>
          <span v-else>
            Purtroppo il pagamento delle bollette non è riuscito.<br /><br />
            Ti invitiamo a riprovare utilizzando un altro metodo di pagamento
            tra quelli disponibili.
          </span>
        </div>
        <div v-else class="info-block__text mb-12">
          <span v-if="$route.params.instalment"
            >Il pagamento delle rate del piano di rientro non è riuscito.
            <br /><br />
            Puoi tornare indietro e riprovare di nuovo
          </span>
          <span v-else>
            Purtroppo il pagamento della bolletta
            {{ $route.params.idFattura }} non è riuscito.<br /><br />
            Ti invitiamo a riprovare utilizzando un altro metodo di pagamento
            tra quelli disponibili.
          </span>
        </div>
        <v-btn
          @click="goBack"
          color="brightGreen"
          class="info-block__button v-btn--shadowed"
          x-large
          width="355"
          height="54"
          >Riprova di nuovo</v-btn
        >
        <v-btn
          @click="$router.push({ name: 'home' })"
          color="white"
          class="info-block__button v-btn--shadowed last"
          width="355"
          height="54"
          >Torna alla Home Page</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'supplyPaymentResult',
  data() {
    return {}
  },
  mounted() {
    this.trackTapEvent('payment_completed')
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),

    goBack() {
      if (this.$route.params.from === 'paymentSummary') {
        this.$router.push({
          name: 'paymentSummary',
          params: { clientCode: this.$route.params.clientOwnerCode }
        })
      } else {
        this.$router.push({
          name: this.$route.params.from,
          params: {
            clientOwnerCode: this.$route.params.clientOwnerCode,
            supplyCode: this.$route.params.supplyCode
          }
        })
      }
    }
  },
  computed: {
    status() {
      return this.$route.params.status
    }
  }
}
</script>

<style lang="scss" scoped>
.successPage {
  height: 100%;
  h1 {
    color: white;
  }
  &__content {
    height: 100%;
    .img-OK {
      background-image: url('../../assets/img_typ_girl.png');
      background-size: cover;
      background-position: center;
    }
    .img-KO {
      background-image: url('../../assets/img_typ_girl-ko.png');
      background-size: cover;
      background-position: center;
    }
    .info-block {
      color: white;
      padding: 0 calc((58% - 551px) / 2);
      &__title {
        padding: 80px 0 40px 0;
        font-size: 40px;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 0.8px;
        max-width: 551px;
      }
      &__text {
        line-height: 1.4;
        letter-spacing: 1px;
        font-size: 20px;
        max-width: 551px;
        a {
          color: white;
          font-weight: bold;
          text-decoration: underline;
        }
      }
      &__button {
        color: $srg-blue;
        margin-top: 24px;
        display: block;
        &.last {
          margin-bottom: 100px;
        }
        &.v-btn--outlined {
          color: white;
        }
      }
    }
  }
}
</style>
