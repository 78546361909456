<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <button
          type="button"
          class="loyalty__header-back mb-2"
          @click="$router.push({ name: 'loyaltyPage' })"
        >
          <v-icon color="#12256A" size="30">mdi-chevron-left</v-icon>
          <span>Torna al Diario</span>
        </button>
        <h1 class="white--text">Ritira i tuoi premi</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div class="promotion__content">
      <v-row class="mb-2">
        <v-col class="promotion__content__header">
          <h2 class="mt-12 mb-3">Nuovo livello guadagnato!</h2>
          <p>Scopri il premio a te dedicato:</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(card, i) in statusCards" :key="i" cols="6" class="mb-5">
          <v-card
            light
            :class="
              card.status > 1 ? `promotion__item--${statuses[card.status]}` : ''
            "
            class="v-card--rounded promotion__item"
            @click="cardClickHandler(i)"
          >
            <p class="promotion__item__ribbon">Già ritirato!</p>
            <img :src="card.imgSrc" class="promotion__item__image" />
            <h3 class="promotion__item__header" v-text="card.title" />
            <v-divider class="promotion__item__divider" />
            <p class="promotion__item__text">Richiedi premio</p>
          </v-card>
        </v-col>
      </v-row>
      <v-footer color="white" height="90" />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Layout from '../Layout'

export default {
  name: 'PromotionPage',
  components: {
    layout: Layout
  },
  data: () => ({
    statuses: ['', '', 'active', 'received'],
    statusCards: [
      {
        title: 'Premio Germoglio',
        imgSrc: require('../../../assets/greeners/svg/follower.svg'),
        status: null,
        catalogLevel: null
      },
      {
        title: 'Premio Piantina',
        imgSrc: require('../../../assets/greeners/svg/friend.svg'),
        status: null,
        catalogLevel: null
      },
      {
        title: 'Premio Arbusto',
        imgSrc: require('../../../assets/greeners/svg/influencer.svg'),
        status: null,
        catalogLevel: null
      },
      {
        title: 'Premio Quercia',
        imgSrc: require('../../../assets/greeners/svg/ambassador.svg'),
        status: null,
        catalogLevel: null
      }
    ]
  }),
  computed: {
    ...mapGetters('loyalty', ['availableAwards', 'catalogList']),
    ...mapGetters('account', ['mainClientLoyaltySelected']),
    userLevel() {
      return Object.keys(this.availableAwards).reduce((res, el) => {
        if (el.indexOf('lev') > -1 && parseInt(this.availableAwards[el]) > 1) {
          res = el.replace('lev', '')
        }
        return res
      }, 0)
    }
    // availableAwards () {
    //   return { 'lev1': '002', 'lev2': '002', 'welcome_gift': '001', 'lev3': '002', 'lev4': '001', 'welcome_gift_counter': 0.0, 'iw': '001', 'welcome_iw': '002' }
    // }
  },
  methods: {
    ...mapActions('loyalty', ['getUuid', 'getCatalog', 'getAvailableAwards']),
    cardClickHandler(i) {
      if (!this.catalogList[0].attributes.is_required_shipping) {
        this.$router.push({
          name: 'giftEmailShipping',
          params: {
            catalog:
              this.mainClientLoyaltySelected.clientOwnerType === 'RES'
                ? 'LEVEL'
                : 'LEVEL-BS',
            id: this.statusCards[i].catalogLevel.attributes.prizeoption_id
          }
        })
      } else {
        this.$router.push({
          name: 'giftDetailsPage',
          params: {
            catalog:
              this.mainClientLoyaltySelected.clientOwnerType === 'RES'
                ? 'LEVEL'
                : 'LEVEL-BS',
            id: this.statusCards[i].catalogLevel.attributes.prizeoption_id,
            item: this.statusCards[i].catalogLevel
          }
        })
      }
    }
  },
  beforeMount() {
    this.getAvailableAwards().then(() => {
      this.statusCards.forEach((el, i) => {
        el.status = parseInt(this.availableAwards[`lev${i + 1}`])
        if (el.status === 2) {
          let count = i
          this.getCatalog({
            catalog_name:
              this.mainClientLoyaltySelected.clientOwnerType === 'RES'
                ? 'LEVEL'
                : 'LEVEL-BS',
            level_order: count + 1,
            codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
          }).then(() => {
            this.statusCards[count].catalogLevel = this.catalogList[0]
          })
        }
      })
    })
    this.getCatalog({
      catalog_name:
        this.mainClientLoyaltySelected.clientOwnerType === 'RES'
          ? 'LEVEL'
          : 'LEVEL-BS',
      codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
    })
  }
}
</script>

<style lang="scss" scoped>
.promotion__content {
  width: 100%;
  &__header {
    font-size: 20px;
    line-height: 1.5;
  }
}
.promotion__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  pointer-events: none;
  overflow: hidden;
  box-shadow: none;

  &__ribbon {
    display: none;
    position: absolute;
    background: #ff3838;
    right: 0;
    color: white;
    font-size: 18px;
    padding: 10px 80px 6px;
    letter-spacing: 0.5px;
    transform: rotate(45deg) translate(78px, -38px);
  }

  &__image {
    height: 91px;
    width: auto;
    margin-top: 33px;
    margin-bottom: 18px;
  }

  &__header {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__divider {
    margin-top: auto;
    width: 100%;
  }

  &__text {
    font-size: 18px;
    font-weight: bold;
    margin: 14px 0 18px;
    color: #b3b3b3;
  }

  &--active {
    background: white;
    box-shadow: 0 0 20px 2px rgba(63, 63, 63, 0.16) !important;
    pointer-events: auto;
  }

  &--active &__text {
    color: #12256a;
  }

  &--received {
    .promotion__item {
      &__ribbon {
        display: block;
      }

      &__image {
        margin-top: 48px;
        margin-bottom: 27px;
      }
      &__header {
        margin-top: auto;
        margin-bottom: 52px;
      }
      &__divider {
        display: none;
      }
      &__text {
        display: none;
      }
    }
  }
}

/*      .theme--light.v-card.v-card--outlined {
        border: none;
      }*/
</style>
