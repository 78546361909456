<template>
  <div>
    <v-row>
      <v-col>
        <div class="HeadingL">Compila il form</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <div class="custoMLabel">TIPOLOGIA SERVIZIO</div>
        <v-text-field
          v-model.trim="tipologiaServizio"
          @blur="v$.tipologiaServizio.$touch()"
          readonly
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        ><div class="custoMLabel">TIPOLOGIA RICHIESTA</div>
        <v-select
          v-model.trim="tipologiaRichiesta"
          :items="categories.categories"
          @blur="v$.tipologiaRichiesta.$touch()"
          placeholder="Scegli la tipologia"
          :error-messages="tipologiaRichiestaError"
        />
      </v-col>
      <v-col
        ><div class="custoMLabel">DETTAGLI RICHIESTA</div>
        <v-select
          :disabled="!tipologiaRichiesta || tipologiaRichiesta === 'Altro'"
          v-model.trim="dettagliRichiesta"
          :items="categories.typology[tipologiaRichiesta]"
          @blur="v$.dettagliRichiesta.$touch()"
          item-text="Seleziona la Richiesta"
          :placeholder="
            tipologiaRichiesta === 'Altro'
              ? 'Inserisci i dettagli nel messaggio'
              : 'Dettagli Richiesta'
          "
          persistent-placeholder
          :error-messages="dettagliRichiestaError"
      /></v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="customArea">
          <div class="custoMLabel mb-2">MESSAGGIO</div>
          <div>
            <v-textarea
              v-model.trim="messaggio"
              rows="4"
              outlined
              auto-grow
              no-resize
              single-line
              name="message"
              placeholder="Scrivi un messaggio"
              messages="Max 350 caratteri"
              @blur="v$.messaggio.$touch()"
              :error-messages="messaggioError"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center" v-if="load">
          <v-progress-circular :size="100" indeterminate color="#12256A" />
        </div>
        <v-btn
          v-if="!load"
          color="primary"
          x-large
          width="221"
          class="v-btn--shadowed"
          :disabled="validateRequest()"
          @click="onSubmit"
          >Invia richiesta</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'FormData',
  data() {
    return {
      tipologiaServizio: this.tipologiaServizioProp,
      tipologiaRichiesta: null,
      dettagliRichiesta: null,
      messaggio: null
    }
  },
  props: {
    tipologiaServizioProp: { type: String },
    validateAddress: { type: Boolean },
    categories: { type: Object },
    load: { type: Boolean }
  },
  watch: {
    tipologiaRichiesta: {
      immediate: true,
      handler(newTipologiaRichiesta) {
        if (newTipologiaRichiesta === 'Altro') {
          this.dettagliRichiesta = 'Campo Libero'
        }
      }
    }
  },
  computed: {
    tipologiaRichiestaError() {
      let errArray = []
      if (!this.v$.tipologiaRichiesta.$dirty) return errArray

      this.v$.tipologiaRichiesta.$invalid === true &&
        errArray.push(' La Tipologia di richiesta è obbligatoria')

      return errArray
    },
    dettagliRichiestaError() {
      let errArray = []
      if (!this.v$.dettagliRichiesta.$dirty) return errArray

      this.v$.dettagliRichiesta.$invalid === true &&
        errArray.push(' Il dettaglio della richista è obbligatorio')

      return errArray
    },
    messaggioError() {
      let errArray = []
      if (!this.v$.messaggio.$dirty) return errArray
      this.v$.messaggio.maxLength.$invalid === true &&
        errArray.push('Il messaggio può avere massimo 350 caratteri')
      this.v$.messaggio.$invalid === true &&
        errArray.push('Il messaggio è obbligatorio')

      return errArray
    }
  },
  methods: {
    onSubmit() {
      const dataForm = {
        tipologiaServizio: this.tipologiaServizio,
        tipologiaRichiesta: this.tipologiaRichiesta,
        dettagliRichiesta: this.dettagliRichiesta,
        messaggio: this.messaggio
      }
      this.$emit('onSubmit', dataForm)
    },
    validateRequest() {
      return this.v$.$invalid || !this.validateAddress
    }
  },
  validations: {
    tipologiaRichiesta: { required },
    dettagliRichiesta: { required },
    messaggio: { required, maxLength: maxLength(375) }
  }
}
</script>

<style lang="scss" scoped>
.custoMLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1.2px;
  color: #6e7689;
}
</style>
