<template>
  <v-dialog
    v-if="type === 'dialog'"
    max-width="1140"
    :value="value"
    @input="$emit('input')"
  >
    <slot />
  </v-dialog>
  <div v-else-if="type === 'block'">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PaymentTemplate',
  props: {
    type: {
      type: String,
      default: 'dialog'
    },
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
