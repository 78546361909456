var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.promo &&
    _vm.promo.cta_action_type === 'APP_SECTION' &&
    _vm.promo.cta &&
    _vm.APP_SECTION_MAPPING[_vm.promo.app_section_section] === 'speedTest'
  )?_c('SpeedTest',{attrs:{"isAbstract":_vm.promo.abstract !== ''}},[_vm._t("contentPromo")],2):(
    _vm.promo &&
    _vm.promo.cta_action_type === 'APP_SECTION' &&
    _vm.promo.cta &&
    _vm.APP_SECTION_MAPPING[_vm.promo.app_section_section] === 'carbonFootprint'
  )?_c('div',{staticClass:"handCursor",on:{"click":function($event){return _vm.openFootprint()}}},[_vm._t("contentPromo")],2):(_vm.promo && _vm.promo.cta && _vm.hasLandingPage(_vm.promo))?_c('router-link',{attrs:{"to":{ name: 'promo', params: { id: _vm.promo.id.substring(1) } }}},[_vm._t("contentPromo")],2):(_vm.promo && _vm.promo.cta_action_type === 'APP_SECTION' && _vm.promo.cta)?_c('router-link',{attrs:{"to":{
    name: _vm.APP_SECTION_MAPPING[_vm.promo.app_section_section],
    params: { from: 'homepage-promo' }
  }}},[_vm._t("contentPromo")],2):(
    _vm.promo && _vm.promo.app_section_section.toLowerCase() === 'welcomekit'
  )?_c('router-link',{attrs:{"to":{
    name: _vm.APP_SECTION_MAPPING[_vm.promo.app_section_section.toLowerCase()],
    params: { from: 'homepage-promo' }
  }}},[_vm._t("contentPromo")],2):(_vm.promo && _vm.promo.cta_action_type === 'WEB_LINK')?_c('a',{attrs:{"href":_vm.promo.url,"target":"_blank"}},[_vm._t("contentPromo")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }