var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('TcVars'),_c('div',{staticClass:"login__content"},[_c('img',{attrs:{"src":require("../../assets/icons/my-sorgenia-logo.svg"),"width":"203","height":"56px"}}),_c('div',{staticClass:"login__box"},[_c('div',{staticClass:"login__title HeadingsSubtitleXXL"},[_vm._v("Registrati")]),_c('div',{staticClass:"login__subTitle BodyL-Strong"},[_vm._v(" Scegli come entrare in Sorgenia. ")]),_c('div',{staticClass:"d-flex flex-column justify-space-between"},[_c('div',{staticClass:"choise_card d-flex flex-row justify-center align-center",on:{"click":function($event){return _vm.$router.push({
              name: 'RegistrationUserData',
              params: { clientType: 'withContract' }
            })}}},[_vm._m(0),_c('img',{staticClass:"choise_card__icon",attrs:{"src":require("../../assets/icons/chevron_rx.svg")}})]),_c('div',{staticClass:"choise_card d-flex flex-row justify-center align-center",on:{"click":function($event){return _vm.$router.push({
              name: 'RegistrationUserData',
              params: { clientType: 'withOutContract' }
            })}}},[_vm._m(1),_c('img',{staticClass:"choise_card__icon",attrs:{"src":require("../../assets/icons/chevron_rx.svg")}})])]),_c('div',{staticClass:"footer BodyL"},[_vm._v(" Hai già le credenziali MySorgenia o MyNextMove? "),_c('router-link',{staticClass:"BodyL-Strong",attrs:{"to":{ name: 'login' }}},[_vm._v("Vai al Login")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-start justify-space-between"},[_c('div',{staticClass:"choise_card__header CaptionM"},[_vm._v(" HO UN CONTRATTO LUCE O GAS ")]),_c('div',{staticClass:"choise_card__subtitle BodyM"},[_vm._v(" Vai alla registrazione con il tuo codice cliente ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-start justify-space-between"},[_c('div',{staticClass:"choise_card__header CaptionM"},[_vm._v(" NON HO UN CONTRATTO ")]),_c('div',{staticClass:"choise_card__subtitle BodyM"},[_vm._v(" Vai alla registrazione e inserisci i tuoi dati ")])])
}]

export { render, staticRenderFns }