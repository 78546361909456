<template>
  <layout>
    <template v-slot:header>
      <div class="breadcrumbsGreeners">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </div>
      <v-col cols="6 my-auto">
        <v-img
          contain
          width="233"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
        <h3 class="content__title">Come funziona</h3>
      </v-col>
      <v-col cols="6">
        <img class="img" src="@/assets/greeners/diario-header.png" />
      </v-col>
    </template>
    <div class="functionality">
      <v-row class="functionality__header">
        <v-col>
          <v-row class="box" no-gutters>
            <v-col>
              <v-img
                src="@/assets/greeners/svg/greeners.svg"
                width="90"
                height="90"
                class="mr-8"
              />
            </v-col>
            <v-col cols="6" align-self="center">
              <h3 class="font-weight-bold">
                Guadagna Green Coins<br />
                e richiedi i premi del catalogo.
              </h3>
            </v-col>
            <v-spacer />
            <v-col cols="4" align="right" align-self="center">
              <v-btn
                color="white"
                height="54"
                class="btn v-btn--shadowed"
                width="225"
                :to="'catalogue'"
              >
                Sfoglia i premi
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2 class="mb-4"><strong>Accumula Green Coins</strong></h2>
          <h3 class="mb-4">
            Ci sono diversi modi per accumulare Green Coins e accedere ai premi:
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1">
          <v-img
            width="64"
            height="64"
            src="@/assets/greeners/svg/mission-logo.svg"
          />
        </v-col>
        <v-col align-self="center" cols="11">
          <p class="mt-2">
            Ogni mese trovi nuove <strong>missioni</strong> sul tuo diario
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1">
          <v-img
            width="64"
            height="64"
            src="@/assets/greeners/svg/mission-contract.svg"
          />
        </v-col>
        <v-col align-self="center" cols="11">
          <p class="mt-4">
            Ogni mese guadagni dei Green Coins per ogni
            <strong>utenza attiva</strong>
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col cols="1">
          <v-img
            width="64"
            height="64"
            src="@/assets/greeners/svg/mission-cfp.svg"
          />
        </v-col>
        <v-col align-self="center" cols="11">
          <p class="mt-4">
            <strong>Misura il tuo impatto</strong>: la prima volta che fai il
            test guadagni punti
          </p>
        </v-col>
      </v-row>
      <missions class="mb-8" />
      <v-row>
        <v-col class="gradation">
          <h2 class="mb-4"><strong>Sali di livello</strong></h2>
          <h3 class="mb-4">
            Ogni <strong>8 missioni</strong> passi al livello successivo e
            sblocchi nuovi premi
          </h3>
        </v-col>
      </v-row>
      <v-row class="donators">
        <v-col class="pa-0">
          <v-img
            class="donators__img"
            src="@/assets/greeners/landing-page-levels.svg"
          ></v-img>
        </v-col>
      </v-row>
      <!-- <v-row class="carbon">
        <v-col>
          <h2 class="mb-7">Facci vedere chi sei!</h2>
          <v-card class="v-card--rounded shadowed" width="1140" height="140">
            <v-row>
              <v-col cols="2" align-self="center" class="cloud" >
                <img src="@/assets/ic_cfp.png" alt="carbon" class="carbon__img"/>
                <p class="footprint">Carbon<br/>Footprint</p>
              </v-col>
              <v-col cols="7" class="carbon__reward" justify-self="end">
                <h3>
                  Calcola il tuo <strong>Carbon Footprint</strong>, sali subito di Livello<br/>
                  e guadagna <strong>1.500 Green Coins!</strong>
                </h3>
              </v-col>
              <v-col cols="3" align-self="center" class="pa-0 mt-8" align="center">
                <v-btn color="brightGreen" width="285" depressed class="btn white--text">
                  Guadagna subito un Livello!
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row class="mb-4">
        <v-col align="center">
          <v-btn
            color="white"
            class="v-btn--shadowed"
            height="54"
            width="343"
            @click="
              trackTapEvent({
                name: 'greeners_howWorksContinue_tap',
                params: {
                  clientCode: mainClientLoyaltySelected.clientOwnerCode,
                  clientType: mainClientLoyaltySelected.clientOwnerType
                }
              }),
                $router.push({ name: 'loyaltyPage' })
            "
          >
            Vai al Diario
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-footer color="white" height="90"/> -->
    </div>
  </layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Layout from '../Layout'
import Missions from './Missions'

export default {
  name: 'HowItWorksPage',
  components: {
    Layout,
    Missions
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  },
  computed: {
    ...mapGetters('account', ['clientDetails', 'mainClientLoyaltySelected']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        { to: { name: 'loyaltyPage' }, text: 'Diario Greeners', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Come funziona' }
      ]

      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.functionality {
  width: 100%;
  padding-top: 42px;
  h3 {
    line-height: 32px;
    font-weight: 500;
    letter-spacing: 0.48px;
  }

  p {
    font-size: 20px;
    color: black;
    line-height: 30px;
    letter-spacing: 1px;
  }

  &__header {
    margin-bottom: 44px;
    .box {
      padding: 24px;
      border-radius: 24px;
      background: #f8f9fc;
    }
  }

  .btn {
    text-transform: none;
  }

  .shadowed {
    box-shadow: 0 0 20px 2px rgba(63, 63, 63, 0.16);
  }

  .gradation {
    z-index: 1000;
  }

  .donators {
    margin-bottom: 120px;

    &__img {
      margin-top: 5px;
      margin-left: 30px;
      z-index: 10;
      width: 720px;
    }
  }

  .carbon {
    margin-bottom: 84px;

    &__img {
      position: absolute;
      top: -25px;
      left: -23px;
      height: 54px;
    }

    &__reward {
      padding: 0;
      margin: 42px 0 0 -80px;
    }

    .footprint {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
    }

    .cloud {
      position: relative;
      margin: 56px 0 0 60px;
      padding: 0;
    }
  }
}
</style>
