<template>
  <div class="controls">
    <MonitorControl
      v-for="control in controls"
      :key="control.title"
      :title="control.title"
      :icon="control.icon"
      :value="control.value"
      :percentage="control.percentage"
      :load="control.load"
      @change-control="change"
      :loaded="load"
    ></MonitorControl>
  </div>
</template>

<script>
import MonitorControl from './MonitorControl.vue'

export default {
  components: { MonitorControl },
  props: {
    controls: { type: Array, required: true },
    load: { type: Boolean }
  },

  methods: {
    change({ title, switchRadio }) {
      this.$emit('change-control', { title, switchRadio })
    }
  }
}
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
}
</style>
