<template>
  <div>
    <v-row class="mb-8 mt-8">
      <v-col>
        <div class="HeadingL mb-3">{{ subtitle }}</div>
        <div class="d-flex" v-if="selectedAddress !== null">
          <div class="BodyL-Strong mr-2">{{ nome }} {{ cognome }}</div>
          <div class="BodyL">{{ selectedAddress }}</div>
        </div>
        <div v-else>
          <SelectSystem
            :filteredSupplies="filteredSupplies"
            :serviceName="serviceName"
            :tipologiaServizio="tipologiaServizio"
            @setOpenSelectModal="setOpenSelectModal"
            :name="name"
            :address="address"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectSystem from './SelectSystem.vue'

export default {
  name: 'FormSystem',
  components: {
    SelectSystem
  },
  props: {
    subtitle: { type: String },
    selectedAddress: { type: String },
    nome: { type: String },
    cognome: { type: String },
    filteredSupplies: { type: Array, default: () => [] },
    serviceName: { type: String },
    tipologiaServizio: { type: String },
    name: { type: String },
    address: { type: String }
  },
  methods: {
    setOpenSelectModal() {
      this.$emit('setOpenSelectModal')
    }
  }
}
</script>
