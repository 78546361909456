<template>
  <div class="info-card" v-if="card">
    <v-img
      class="mb-6"
      contain
      :src="
        isSmallDevice ? cdn + card.image_mobile || card.image : cdn + card.image
      "
    />
    <div class="card-info">
      <h3 class="number">{{ card.title }}</h3>
      <h5 class="ml-5" v-html="card.body" />
    </div>
  </div>
</template>

<script>
import { ENV_CONFIGS } from '@/js/configs'

export default {
  name: 'TutorialCard',
  data() {
    return {
      cdn: ENV_CONFIGS.CMS_CDN_BASE_URL,
      isSmallDevice: window.innerWidth <= 480
    }
  },
  props: {
    card: {
      image: { type: String, default: '' },
      body: { type: String, default: '' },
      title: { type: Number, default: 0 }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-card {
  max-width: 475px;
}
.card-info {
  display: flex;
  flex-direction: row;
}
.number {
  min-width: 34px;
  height: 34px;
  border-radius: 34px;
  background-color: $srg-green;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 3px;
}
@media (min-width: 481px) and (max-width: 1024px) {
  .info-card {
    max-width: 50% !important;
  }
}
</style>
