<template>
  <div>
    <TcVars />
    <v-row class="std-side-padding">
      <v-col>
        <h1 class="self-reading__title">Verifica autolettura</h1>
      </v-col>
    </v-row>

    <v-row class="last-reading" v-if="details.serviceType === 'Ele'">
      <v-col>
        <v-row class="std-side-padding">
          <v-col>
            <p class="last-reading__text mt-8">
              Lascia il tuo numero di telefono e sarai contattato da un nostro
              operatore in caso di necessità.
            </p>
            <v-row class="mt-8" no-gutters>
              <v-col sm="6">
                <v-text-field
                  type="number"
                  label="Numero di telefono"
                  class="auth__input"
                  v-model.trim="phone"
                  @blur="v$.phone.$touch()"
                  :error-messages="errorsPhone"
                  @input="filterPhone"
                  ref="phone"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="last-reading" v-if="details.serviceType === 'Gas'">
      <v-col>
        <v-row class="std-side-padding">
          <v-col sm="5">
            <h2 class="last-reading__source">
              <strong>Carica la foto del tuo contatore</strong>
            </h2>
            <p class="mt-3">
              Per offrirti un servizio migliore, ti inviamo a fornirci la foto
              del tuo contatore.
            </p>

            <v-row no-gutters class="mt-10" v-show="files.length === 0">
              <v-col>
                <v-btn
                  height="66"
                  class="v-btn--shadowed upload font-weight-regular"
                  color="white"
                  width="100%"
                  raised
                  x-large
                  @click="openFileDialog"
                >
                  Carica foto
                </v-btn>
              </v-col>
            </v-row>
            <v-file-input
              id="file-upload"
              style="display: none"
              @change="onFileChange($event)"
              v-model="file"
            />
            <v-row no-gutters class="mt-10" v-show="files.length > 0">
              <v-col>
                <v-card
                  v-for="(item, i) in files"
                  :key="i"
                  width="100%"
                  height="66px"
                  style="border-radius: 20px"
                >
                  <v-row class="pt-2">
                    <v-col class="ml-4" cols="1"
                      ><v-icon>mdi-file-image</v-icon></v-col
                    >
                    <v-col cols="9">{{ item.name }}</v-col>
                    <v-col class="ml-3" cols="1"
                      ><v-icon color="primary" @click="deleteFile(i)"
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="5" offset-sm="2">
            <h2 class="last-reading__source">
              <strong>Dove posso chiamarti?</strong>
            </h2>
            <p class="mt-3">
              Lascia il tuo numero di telefono e sarai contattato da un nostro
              operatore in caso di necessità.
            </p>
            <v-row class="mt-10" no-gutters>
              <v-col sm="10">
                <v-text-field
                  type="number"
                  label="Numero di telefono"
                  class="auth__input"
                  v-model.trim="phone"
                  @blur="v$.phone.$touch()"
                  :error-messages="errorsPhone"
                  @input="filterPhone"
                  ref="phone"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col class="text-center">
        <v-btn
          color="primary"
          large
          width="250px"
          class="v-btn--shadowed"
          :rounded="true"
          @click="sendInteraction"
          :loading="sending"
          :disabled="
            v$.phone.$invalid ||
            (details.serviceType === 'Gas' && files.length === 0)
          "
        >
          Prosegui
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import { getRequiredValidationError } from '@/js/utils'
import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import router from '../../../../router/router'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: ['enteredReadings', 'details'],
  name: 'selfReadingLight_Warning2',
  data() {
    return {
      file: [],
      files: [],
      phone: '',
      sending: false,
      accountDetails: {}
    }
  },
  components: {
    TcVars
  },
  computed: {
    errorsPhone() {
      return getRequiredValidationError(this.v$, 'phone')
    }
  },
  beforeMount() {
    this.getDetailForClient(this.details).then((res) => {
      this.accountDetails = res
      this.phone = res.mobilePhone || res.phone || '0000000000'
    })
  },
  methods: {
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('interactions', ['createInteraction', 'sendSelfReadingFile']),
    ...mapActions('analytics', ['trackTapEvent']),
    onFileChange(evt) {
      this.files.push(evt)
    },
    deleteFile(index) {
      this.files.splice(index, 1)
      this.file = null
    },
    openFileDialog() {
      this.trackTapEvent({
        name: 'selfReading_takePhoto_tap',
        params: getSupplyTrackingParams(this.details)
      })
      document.getElementById('file-upload').click()
    },

    sendInteraction() {
      this.sending = true

      let params = {
        cognome: this.accountDetails.surname,
        email: this.accountDetails.contactMail,
        nome: this.accountDetails.name,
        codiceCliente: this.accountDetails.clientCode,
        login: this.$store.getters['session/username'],
        puntoDiFornitura: this.details.supplyCode,
        sottoTipologia: 'autolettura_invalidata_reale',
        fieldsCommodity: {
          commodity: 'autolettura' + this.details.serviceType,
          telefono: this.phone.toString(),
          autoVal1: this.enteredReadings.f1 || '',
          autoVal2: this.enteredReadings.f2 || '',
          autoVal3: this.enteredReadings.f3 || '',
          codiceErrore: this.$route.params.errorCode,
          descrizioneErrore: this.$route.params.errorDescription
        }
      }

      if (this.details.serviceType === 'Gas') {
        let input = {
          codiceCliente: this.accountDetails.clientCode,
          codicePR: this.details.supplyCode,
          pdr: this.details.pdr,
          email: this.accountDetails.contactMail
        }
        let formData = new FormData()
        formData.append('file', this.files[0])
        formData.append('input', JSON.stringify(input))

        this.sendSelfReadingFile(formData)
      }

      this.createInteraction(params)
        .then((resp) => {
          router.push({
            name: 'selfReadingLight_Thankyou',
            params: { from: 'contactme', status: 'ok' }
          })
        })
        .catch((error) => {
          console.er(error)
          router.push({
            name: 'selfReadingLight_Thankyou',
            params: { from: 'contactme', status: 'ko' }
          })
        })
    },
    filterPhone(val) {
      if (val) {
        this.phone =
          val.substr(0, 12).match(/[0-9]+/) || this.$refs.phone.reset()
      } else {
        this.phone = ''
      }
    }
  },
  validations: {
    phone: { required }
  }
}
</script>
