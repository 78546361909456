<template>
  <div class="controls">
    <div>
      <div class="control">
        <div :class="icon"></div>
        <div class="control-title">{{ title }}</div>
      </div>
      <div>
        <span class="value"
          >{{
            value.toLocaleString('it-IT', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }}
          {{ changeUnit }} </span
        ><span class="percentage">{{ formatPercentage }} %</span>
      </div>
    </div>
    <div>
      <div>
        <v-switch
          v-model="switchRadio"
          flat
          inset
          class="mt-0 pt-0"
          @change="switchOnOff"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
    value: { type: Number, required: true },
    percentage: { type: Number, required: true },
    load: { type: Boolean },
    loaded: { type: Boolean }
  },
  data() {
    return {
      switchRadio: true
    }
  },
  watch: {
    load(load) {
      this.switchRadio = true
    },
    loaded(loaded) {
      this.switchRadio = true
    }
  },
  computed: {
    changeUnit() {
      let unit = 'kWh'
      if (this.$route.name === 'fotovoltaicoRisparmio') {
        unit = '€'
      }
      return unit
    },
    formatPercentage() {
      if (this.percentage !== 0) {
        return this.percentage.toFixed(2).replace('.', ',')
      }
      return '0,00'
    }
  },
  methods: {
    switchOnOff() {
      this.$emit('change-control', {
        title: this.title,
        switchRadio: this.switchRadio
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.controls {
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
}
.control {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.control-title {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 22.4px;
}
.icon-green-light {
  background-color: #97d700;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-right: 8px;
}

.icon-green {
  background-color: #0f9c38;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-right: 8px;
}

.icon-green-blue {
  background-color: #1197ad;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-right: 8px;
}

.value {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 22.4px;
  padding-right: 4px;
}
.percentage {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 22.4px;
  padding-left: 4px;
  border-left: solid 1px #dfe4ef;
}
</style>
