<template>
  <div class="entry-page">
    <div class="entry-page__content std-side-padding">
      <v-card
        class="card"
        @click="
          trackTapEvent({
            name: 'customOffers_section_tap',
            params: { sectionType: 'home' }
          }),
            $router.push({ name: 'serviceSection', params: { type: 'casa' } })
        "
      >
        <div class="icon-content">
          <img src="@/assets/service-profile/home.png" width="40" />
        </div>
        <div class="body-content">
          <span class="HeadingsTitleL">Casa</span>
          <p class="BodyL">
            Luce, gas e fiber: scopri le soluzioni Sorgenia per la tua
            abitazione.
          </p>
        </div>
      </v-card>
      <v-card
        class="card"
        @click="
          trackTapEvent({
            name: 'customOffers_section_tap',
            params: { sectionType: 'photovoltaic' }
          }),
            $router.push({
              name: 'serviceSection',
              params: { type: 'fotovoltaico' }
            })
        "
      >
        <div class="icon-content">
          <img src="@/assets/service-profile/fotovoltaico.png" width="40" />
        </div>
        <div class="body-content">
          <span class="HeadingsTitleL">Fotovoltaico</span>
          <p class="BodyL">
            Trasforma la luce del sole in energia per la tua casa: scopri le
            soluzioni Sorgenia per il tuo impianto.
          </p>
        </div>
      </v-card>
      <v-card
        class="card"
        @click="
          trackTapEvent({
            name: 'customOffers_section_tap',
            params: { sectionType: 'mobility' }
          }),
            $router.push({
              name: 'serviceSection',
              params: { type: 'mobility' }
            })
        "
      >
        <div class="icon-content">
          <img src="@/assets/service-profile/mobility.png" width="40" />
        </div>
        <div class="body-content">
          <span class="HeadingsTitleL">Auto</span>
          <p class="BodyL">
            MyNextMove è l'app di Sorgenia dedicata alla mobilità elettrica e
            alla ricarica della tua auto. Scopri i vantaggi a te dedicati!
          </p>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EntryPage',

  data() {
    return {
      to: false
    }
  },
  computed: {
    ...mapGetters('account', ['mainClient', 'accountClients'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>

<style lang="scss" scoped>
.entry-page {
  flex: auto;
  &__content {
    flex: 1;
    background: $base-light;
    display: flex;
    gap: 16px;
    flex-direction: row;
    padding-top: 48px;
    padding-bottom: 48px;
    flex-wrap: nowrap;
    height: 100%;

    .card {
      background: $base-white;
      border-radius: 20px;
      box-shadow: 0px 2px 24px rgba(44, 53, 66, 0.06),
        0px 1px 8px rgba(44, 53, 66, 0.08), 0px 2px 4px rgba(44, 53, 66, 0.08);
      height: 158px;
      width: 364px;
      padding: 16px;
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .icon-content {
        margin: 0;
      }

      .body-content {
        margin: 2px 13px 2px 16px;

        .BodyL {
          margin-top: 4px;
          line-height: 22.4px;
        }
      }
    }
  }
}
</style>
