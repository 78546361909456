import { render, staticRenderFns } from "./MenuNew.vue?vue&type=template&id=4baa30c4&scoped=true"
import script from "./MenuNew.vue?vue&type=script&lang=js"
export * from "./MenuNew.vue?vue&type=script&lang=js"
import style0 from "./MenuNew.vue?vue&type=style&index=0&id=4baa30c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4baa30c4",
  null
  
)

export default component.exports