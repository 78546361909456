<template>
  <div>
    <div
      v-if="!type"
      class="item item__1"
      :class="{ border: index !== length - 1 }"
      @click="btnSupportClicked"
    >
      <div class="ButtonL">{{ data.name }}</div>
      <div>
        <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
      </div>
    </div>
    <div
      v-else-if="type !== 'tutorial'"
      class="service-button std-panel std-panel--shadowed text-center px-0 pt-8 pb-4"
      :class="typeClass"
      @click="buttonClicked"
    >
      <div class="help-img">
        <v-img
          v-if="getProperty[data.id].filename"
          :src="
            getProperty[data.id] && getImgUrl(getProperty[data.id].filename)
          "
          height="74"
          contain
        />
      </div>
      <div class="help-text">
        <h3 class="service-button__title">{{ data.name }}</h3>
      </div>
    </div>
    <v-row
      align="center"
      v-if="type === 'tutorial'"
      class="service-button std-panel std-panel--shadowed text-center service-button--tutorial"
    >
      <v-col md="6">
        <p class="service-button__title">
          Lorem ipsum color sit amet consectetuer
        </p>
      </v-col>
      <v-col md="6">
        <v-img
          v-if="data.filename"
          :src="getImgUrl(data.filename)"
          height="74"
          contain
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { categoryIds, supportIds } from '@/js/constants'

export default {
  name: 'ServiceButton',
  props: {
    data: { type: Object, default: () => ({}) },
    type: {
      type: String,
      default: null,
      validator: (value) => [null, 'request', 'tutorial'].includes(value)
    },
    index: { type: Number },
    length: { type: Number }
  },
  data() {
    return {
      properties: ''
    }
  },
  created() {
    this.properties = this.getProperty
  },
  mounted() {
    // console.log('------', this.data.name)
  },
  computed: {
    serviceName() {
      return this.data.name
    },
    typeClass() {
      return this.type
        ? `service-button--${this.type}`
        : 'service-button--supply'
      // return `service-button--${this.type}`
    },
    ...mapGetters('support', ['categoryProperties', 'supportProperties']),
    getProperty() {
      return this.type ? this.supportProperties : this.categoryProperties
    },
    mediaChildren() {
      let mediaChildren = []
      if (this.data.children) {
        this.data.children.forEach((item) => {
          if (item.media && item.media.length) {
            mediaChildren.push(...item.media)
          }
        })
      }
      return mediaChildren
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    getImgUrl(filename) {
      let images = require.context('@/assets/icons/', false, /\.svg$/)
      return images('./' + filename)
    },
    btnSupportClicked() {
      this.$router.push({
        name: this.data.id === '21' ? 'IdentificationCode' : 'Faq',
        params: {
          subCategories: this.data.children,
          categoryName: this.data.name,
          serviceName: this.serviceName,
          media: this.mediaChildren
        }
      })
      this.checkTrackEvent(this.data)
    },
    buttonClicked() {
      this.$router.push({
        name: this.getProperty[this.data.id].route,
        params: {
          subCategories: this.data.children,
          categoryName: this.data.name,
          media: this.mediaChildren
        }
      })
      this.checkTrackEvent(this.data)
    },
    checkTrackEvent(item) {
      const ids = this.type ? supportIds : categoryIds
      switch (item.id) {
        case ids.request:
          this.trackTapEvent('support_requestInfo_tap')
          break
        case ids.contacts:
          this.trackTapEvent('support_contactUs_tap')
          break
        case ids.pdr:
          this.trackTapEvent('support_PODPDR_tap')
          break
        default:
          this.trackTapEvent({
            name: 'support_category_tap',
            params: { category_id: item.id, category_title: item.name }
          })
          break
      }
    }
  }
}
</script>

<style lang="scss">
.service-button {
  height: 100%;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0px !important;
  &--supply {
    height: 187px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .service-button__title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.8px;
      color: black;
    }
  }
  .help-img {
    height: 100px;
    border-bottom: 1px solid #e8e8e8;
  }
  .help-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 40px;
    h3 {
      margin: 0;
      padding: 0;
      letter-spacing: 1.2px;
      font-size: 18px;
      color: $srg-blue;
      font-weight: bold;
    }
  }
  &--request {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  &--request &__title {
    color: #12256a;
    font-size: 18px;
  }

  &--tutorial {
    padding-bottom: 0;
    padding-top: 0;
    background: #97d707;
  }

  &--tutorial-wrap {
    text-align: left;
    padding-left: 22px;
  }

  &--tutorial-wrap p {
    margin-bottom: 5px !important;
  }

  &--tutorial-footer {
    padding-top: 10px;
    color: #12256a;
    border-top: 1px solid #e8e8e8;
    margin-top: 0 !important;

    &--sm {
      font-size: 18px !important;
      padding-bottom: 10px;
    }
  }

  &--tutorial-png {
    width: 131px;
    height: 94px;
    float: right;
    margin-top: 10px;
  }

  &--tutorial &__title {
    font-size: 24px;
    color: #ffffff;
    font-weight: bold;
  }
}

.tutorial-panel {
  padding: 5px !important;
}
</style>
