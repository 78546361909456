<template>
  <div class="dataInput">
    <h4 class="mb-2">I tuoi dati</h4>
    <h5 class="mb-9">
      Conferma i seguenti dati per procedere con la richiesta di cambio offerta
    </h5>
    <div class="dataInput__content">
      <div></div>
      <v-text-field
        name="clientDetails"
        disabled
        class="mb-6"
        :label="dataSummary.clientDetailsSummaryTitle"
        v-model="dataSummary.clientDetailsSummaryValue"
        readonly
      />
      <v-text-field
        name="address"
        disabled
        class="mb-6"
        :label="dataSummary.addressSummaryTitle"
        v-model="nameAddress"
        readonly
      />
      <v-text-field
        name="podPdr"
        disabled
        class="mb-6"
        :label="dataSummary.podPdrSummaryTitle"
        v-model="dataSummary.podPdrSummaryValue"
        readonly
      />
      <v-text-field
        name="fiscalCodeVATNum"
        disabled
        class="mb-6"
        :label="dataSummary.fiscalCodeVATNumTitle"
        v-model="dataSummary.fiscalCodeVATNumValue"
        readonly
      />
      <v-text-field
        name="mdp"
        disabled
        class="mb-6"
        :label="dataSummary.mdpTitle"
        v-model="dataSummary.mdpValue"
        readonly
      />
    </div>
    <v-btn color="primary" width="156" height="54" @click="next"
      >Conferma</v-btn
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'dataOffer',
  data() {
    return {
      paymentMethod: '',
      client: null
    }
  },

  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('offers', [
      'changeProduct',
      'upsellingInfo',
      'getParamsChangeProduct'
    ]),
    dataSummary() {
      return this.changeProduct.dataSummary
    },
    nameAddress() {
      let str = this.dataSummary.addressSummaryValue
      return str
        .split(/(\s+|,)/)
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        })
        .join('')
    },
    supply() {
      return this.supplies.filter(
        (s) => s.supplyCode === this.getParamsChangeProduct.prCode
      )[0]
    }
  },
  methods: {
    ...mapActions('payment', ['getMethods']),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    next() {
      let params = {
        client_code: this.getParamsChangeProduct.clientCode,
        client_type: this.supply.clientOwnerType.toLowerCase(),
        pr_code: this.changeProduct.podPdrSummaryValue,
        supply_type: this.changeProduct.contractType
      }
      this.trackTapEvent({
        name: 'changeOffer_confirmData_tap',
        params: params
      })
      this.$emit('nextStep')
    }
  }
}
</script>
<style lang="scss" scoped>
.dataInput {
  width: 652px;
  &__content {
    max-width: 360px;
  }
  h4 {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 1px;
  }
  h5 {
    color: black;
    line-height: 1.4;
    letter-spacing: 1px;
  }
}
</style>
<style lang="scss">
.dataInput {
  label.v-label.theme--light {
    top: -25px;
    transition: none;
    transform: none;
  }
  .theme--light.v-input input {
    color: black !important;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .v-text-field .v-label {
    color: #6e7689 !important;
  }
}
</style>
