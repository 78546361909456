<template>
  <div>
    <v-dialog v-model="modalOpen" :max-width="checkupSize.width" persistent>
      <div class="checkup-dialog">
        <iframe
          id="checkupDialogIframe"
          :width="375"
          :height="812"
          :src="checkupUrl"
          frameborder="0"
        ></iframe>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import TcVarService from '../../js/service/tcVarService'
import { mapActions } from 'vuex'

export default {
  name: 'CheckupDialog',
  props: ['showCheckup', 'checkupUrl', 'user'],
  data() {
    return {
      checkupSize: {
        width: 375
      },
      cfpLoaded: false,
      clientDetail: {},
      tcV: TcVarService
    }
  },
  watch: {
    user(val) {
      this.getDetailForClient(val).then((res) => {
        this.clientDetail = res
      })
    }
  },
  beforeMount() {
    this.tcV = new TcVarService()
    this.getDetailForClient(this.user).then((res) => {
      this.clientDetail = res
      window.addEventListener('message', this.hendleEvent)
    })
  },
  beforeUnmount() {
    window.removeEventListener('message', this.hendleEvent)
  },
  computed: {
    modalOpen: {
      get() {
        return this.showCheckup
      },
      set(val) {
        this.$emit('update:showCheckup', val)
      }
    }
  },
  methods: {
    ...mapActions('checkup', ['sendSurvey']),
    ...mapActions('checkupAnalytics', ['checkupTrackTapEvent']),
    ...mapActions('analytics', ['trackTapEvent']),

    ...mapActions('account', ['getDetailForClient']),
    hendleEvent(event) {
      let message = event.data.postMessageEvent
      switch (message) {
        case 'checkup-close': {
          this.$emit('update:showCheckup', false)
          break
        }
        case 'checkup-trackTapEvent': {
          this.tcV.tcTrackTapEvent({
            event_name: event.data.params.name,
            client_code: this.clientDetail.clientCode,
            ...event.data.params.params
          })
          break
        }
        case 'sendCheckupSurvey': {
          // event.data.params.jsonInput = JSON.stringify(event.data.params.jsonInput)
          let resquest = {
            nome: event.data.params.name,
            email: event.data.params.email,
            jsonInput: JSON.stringify(event.data.params.jsonInput),
            codiceCliente: this.clientDetail.clientCode,
            codiceProspect: '',
            hasMarketingConsent: event.data.params.hasMarketingConsent
          }
          this.sendSurvey(resquest).then((res) => {
            document
              .getElementById('checkupDialogIframe')
              .contentWindow.dispatchEvent(
                new CustomEvent('checkupSent', { detail: res.data })
              )
          })
          break
        }
        case 'clientDataRequestReady': {
          document
            .getElementById('checkupDialogIframe')
            .contentWindow.dispatchEvent(
              new CustomEvent('checkupClientData', {
                detail: {
                  firstname: this.clientDetail.name,
                  lastname: this.clientDetail.surname,
                  email: this.clientDetail.contactMail
                }
              })
            )
          break
        }
      }
    }
  }
}
</script>
<style lang="scss">
.v-dialog:has(> .checkup-dialog) {
  border-radius: 0px !important;
}
</style>
