<template>
  <Layout header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <button
          type="button"
          class="loyalty__header-back"
          @click="
            trackTapEvent({
              name: 'greeners_mission_close',
              params: {
                client_code: mainClientLoyaltySelected.clientOwnerCode,
                client_type: mainClientLoyaltySelected.clientOwnerType,
                mission_id: mission.id
              }
            }),
              $router.push({ name: 'loyaltyPage' })
          "
        >
          <v-icon color="#12256A" size="20">mdi-chevron-left</v-icon>
          <span>Torna al Diario</span>
        </button>
        <h1 v-text="mission && mission.welcome_page_title" />
      </v-col>
      <v-spacer />
      <v-col cols="5" class="pl-0">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div class="loyalty-mission mb-10">
      <v-row no-gutters>
        <v-col cols="6" class="loyalty-mission__content">
          <v-row>
            <v-col class="loyalty-mission__title">
              <img :src="IconVideo" width="35" />
              <p>
                VIDEO - SCADE IL
                <span class="date" v-text="expirationDate" />
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="loyalty-mission__text">
              <div v-html="mission && mission.welcome_page_text"></div>
            </v-col>
            <v-col>
              <MissionCoins :coins="missionReward" :steps="1" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="loyalty-mission__player">
          <VideoPlayer
            v-if="mission"
            class="mb-12"
            :videoUrl="mission.video"
            @ended="isEnableToContinue = true"
            :plyrSeekDisable="true"
          />
          <v-btn
            color="brightGreen"
            x-large
            :outlined="!isEnableToContinue"
            :disabled="!isEnableToContinue"
            class="v-btn--shadowed confirm"
            @click="onSubmit"
          >
            <span v-text="mission.welcome_page_button_label" />
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </Layout>
</template>

<script>
import IconVideo from '@/assets/greeners/svg/mission-video.svg'
import VideoPlayer from '@/components/videoPlayer'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'VideoPage',
  components: {
    VideoPlayer,
    Layout: () =>
      import(
        /* webpackChunkName: "components/loyalty/layout" */ '@/views/loyalty/Layout'
      ),
    MissionCoins: () =>
      import(
        /* webpackChunkName: "components/loyalty/missionCoins" */ '@/views/loyalty/components/mission/missionCoins'
      )
  },
  data: () => ({ IconVideo, isEnableToContinue: false }),
  beforeCreate() {
    if (!this.$route.params.id) {
      this.$router.push({ name: 'loyaltyPage' })
    }
  },
  beforeMount() {
    this.getWekitMap()
    this.getVideo()
  },
  computed: {
    ...mapGetters('loyalty', ['videoData', 'wekitMap']),
    ...mapGetters('account', ['clientDetails', 'mainClientLoyaltySelected']),
    mission() {
      return this.videoData.filter(
        (video) => video.id === this.$route.params.id
      )[0]
    },
    missionReward() {
      return this.missionValue(
        this.mission.wekit_action_id,
        this.mission.wekit_event_id
      )
    },
    expirationDate() {
      if (this.mission && this.mission.end_date) {
        return new Date(this.mission.end_date).toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      }
      return '---'
    }
  },
  methods: {
    ...mapActions({
      getVideo: 'loyalty/getVideo',
      getWekitMap: 'loyalty/getWekitMap'
    }),
    ...mapActions('analytics', ['trackTapEvent']),
    missionValue(actionId, eventId) {
      let targetMission = this.wekitMap.filter(
        (mission) =>
          mission.action_id === actionId && mission.event_id === eventId
      )
      if (targetMission.length) {
        return targetMission[0].points
      } else return 0
    },
    onSubmit() {
      this.trackTapEvent('greeners_mission_start_tap')
      this.$router.push({
        name: 'videoFeedbackPage',
        params: { mission: this.mission, coins: this.missionReward }
      })
    }
  }
}
</script>

<style lang="scss" src="@/views/loyalty/components/mission/_index.scss" />
