export default class NewError {
  #error = {}
  get response() {
    return this.#error.response
  }
  get status() {
    return this.#error.response.status
  }
  get data() {
    return this.#error.response.data
  }
  get message() {
    return this.#error.response.message
  }

  constructor(error) {
    this.#error = error
  }
}
