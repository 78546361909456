<template>
  <!--
    USAGE Example:
      <v-btn id="informativaLogin" @click="showPdf = true">OPEN</v-btn>
      <pdf-viewer maxWidth="60%" height="600px" attachTo="informativaLogin" :showDialog="showPdf" @closing-pdfViewer="showPdf = false" url="http://www.africau.edu/images/default/sample.pdf"></pdf-viewer>
  -->

  <v-dialog
    v-model="dialogVisible"
    :max-width="maxWidth || '50%'"
    eager
    :internal-activator="true"
    :attach="attachTo"
    class="pdf-viewer"
    :style="styles || ''"
  >
    <iframe
      id="chatbot-sales"
      ref="chatbot-sales"
      :src="url"
      frameborder="0"
      :height="height || '500px'"
    >
    </iframe>
  </v-dialog>
</template>
<script>
import GenErr from '@/js/genericErrors'
import { mapGetters } from 'vuex'

export default {
  name: 'chatbotSales',
  props: [
    'showDialog',
    'url',
    'attachTo',
    'maxWidth',
    'height',
    'styles',
    'isCommodity'
  ],
  computed: {
    ...mapGetters('upsellingFiber', ['fiberCtx']),
    ...mapGetters('account', ['clientDetails']),
    ...mapGetters('upsellingComm', [
      'commodityType',
      'address',
      'getUseTypeGas',
      'client',
      'upsellingSessionId',
      'flowType',
      'typeRequest',
      'supplyDetail',
      'currentStep',
      'paymentMethod',
      'mgmDetail',
      'estimationType',
      'validationErrors'
    ]),
    dialogVisible: {
      get() {
        return this.showDialog
      },
      set() {
        this.$emit('closing-chatbot-sales')
      }
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        window.addEventListener('message', this.handleMessage)
        document.getElementById('chatbot-sales').src += '' // workaround to reload iframe
      } else {
        window.removeEventListener('message', this.handleMessage)
        this.$emit('reset-client-selected-sales')
      }
    }
  },
  methods: {
    clientRole(role) {
      switch (role) {
        case '1':
          return 'main_client'
        case '2':
          return 'second_client'
        default:
          return 'undefined'
      }
    },
    safeParse(str) {
      try {
        return JSON.parse(str)
      } catch {
        return str
      }
    },
    createJsonData() {
      if (this.isCommodity) {
        return JSON.stringify({
          type: 'jwt',
          data: {
            access_token: localStorage.getItem('token') || '',
            client_code: this.address.clientOwnerCode || '',
            username: localStorage.getItem('username') || '',
            refresh_token: localStorage.getItem('refreshToken') || '',
            client_role: this.clientRole(this.clientDetails.mainRole) || '',
            session_id: this.upsellingSessionId || '',
            step_name:
              this.currentStep.subTitle !== ''
                ? this.currentStep.subTitle
                : this.currentStep.title || '',
            address: this.address.address || '',
            client_firstname: this.clientDetails.name || '',
            client_lastname: this.clientDetails.surname || '',
            contract_code: this.supplyDetail.contractCode || '',
            pod:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.pod || ''
                : '',
            pdr:
              this.commodityType === 'gas'
                ? this.supplyDetail.gas.pdr || ''
                : '',
            supply_type: this.commodityType || '',
            activation_type: this.typeRequest || '',
            selected_offer: this.supplyDetail.selectedOffer || '',
            current_provider: this.supplyDetail?.oldProvider?.SOCIETA || '',
            activation_acceleration:
              this.supplyDetail.anticipateActivation !== null
                ? this.supplyDetail.anticipateActivation
                  ? 'anticipated'
                  : 'standard'
                : '',
            friend_code: this.mgmDetail?.promoCode || '',
            remi:
              this.commodityType === 'gas'
                ? this.supplyDetail.gas.remi || ''
                : '',
            is_owner:
              this.supplyDetail.isOwner !== null
                ? !!this.supplyDetail.isOwner
                : '',
            estimation:
              this.estimationType === 'nobill' ? 'qualitative' : 'quantitative',
            is_resident:
              this.supplyDetail.isResident !== null
                ? !!this.supplyDetail.isResident
                : '',
            counter_power:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.power || ''
                : '',
            yearly_consumption:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.consumption || ''
                : this.supplyDetail.gas.consumption || '',
            avg_bill_cost:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.avgBillCost || ''
                : this.supplyDetail.gas.avgBillCost || '',
            ownership: this.supplyDetail.ownership || '',
            usage_type:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.isRes
                  ? 'DO'
                  : 'AU'
                : '',
            payment_method: this.paymentMethod
              ? this.paymentMethod.methodType || ''
              : '',
            tension:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.tension || ''
                : '',
            is_supply_disconnectable:
              this.supplyDetail.isUnpluggable !== null
                ? !!this.supplyDetail.isUnpluggable
                : '',
            power_info:
              this.typeRequest === 'CO'
                ? this.supplyDetail.ele.powerChanged !== null
                  ? this.supplyDetail.ele.powerChanged
                    ? 'VP'
                    : 'PI'
                  : ''
                : '',
            water_usage: this.commodityType === 'gas' ? this.getUseTypeGas : '',
            house_usage:
              this.supplyDetail.isPrimaryHome !== null
                ? this.supplyDetail.isPrimaryHome
                  ? 'P'
                  : 'S'
                : '',
            house_size:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.rooms || ''
                : this.supplyDetail.gas.rooms || '',
            water_heating_type:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.waterHeatintMode || ''
                : this.supplyDetail.gas.waterHeatintMode || '',
            heating_type:
              this.commodityType === 'ele'
                ? this.supplyDetail.ele.homeHeatingMode || ''
                : this.supplyDetail.gas.homeHeatingMode || '',
            validation_errors: this.validationErrors.join(', ')
          }
        })
      } else {
        return JSON.stringify({
          type: 'jwt',
          data: {
            client_code: this.fiberCtx.client_code,
            role: this.clientRole(this.fiberCtx.role),
            token: localStorage.getItem('token'),
            refresh_token: localStorage.getItem('refreshToken'),
            username: localStorage.getItem('username'),
            client_firstname: this.fiberCtx.client_firstname,
            client_lastname: this.fiberCtx.client_lastname,
            supply_address: this.fiberCtx.supply_address,
            upselling_session_id: this.fiberCtx.upselling_session_id,
            upselling_step_name: this.fiberCtx.upselling_step_name,
            is_migration: this.fiberCtx.is_migration,
            migration_code: this.fiberCtx.migration_code,
            has_selected_vas: this.fiberCtx.has_selected_vas
          }
        })
      }
    },
    handleMessage: function (event) {
      var data =
        typeof event.data === 'string' ? this.safeParse(event.data) : event.data
      if (data.type === 'onLoad' || data.type === 'webpackOk') {
        let iframe = document.getElementById('chatbot-sales').contentWindow
        let json = this.createJsonData()
        iframe.postMessage(json, '*')
      } else if (data.type === 'chatClosed') {
        window.removeEventListener('message', this.handleMessage)
        this.$emit('closing-chatbot-sales')
        this.$emit('reset-client-selected-sales')
      } else if (data.type === 'jwtLoaded') {
        this.loaded = true
      } else if (data.type === 'error') {
        GenErr.showErrorMessage(data.data.message)
      }
    }
  }
}
</script>
<style lang="scss">
.header .v-dialog,
.header .chat-dialog {
  background: $srg-green !important;
}

.v-overlay--active {
  z-index: 201 !important;
}
</style>
