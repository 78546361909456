<template>
  <div class="estimate">
    <div class="estimate__box" @click="goToEnterDataManually">
      <h5 class="mb-1">
        <strong>{{
          commodityType === 'ele'
            ? 'INSERISCI I DATI MANUALMENTE'
            : 'INSERISCO I DATI'
        }}</strong>
      </h5>
      <h5>Vorrei caricare in autonomia i dati della mia bolletta</h5>
    </div>
    <div class="estimate__box" @click="goToUploadBill">
      <h5 class="mb-1">
        <strong>{{
          commodityType === 'ele' ? 'CARICA LA BOLLETTA' : 'CARICO LA BOLLETTA'
        }}</strong>
      </h5>
      <h5>Vorrei caricare la mia bolletta per risparmiare tempo</h5>
    </div>
    <!-- <div class="estimate__box" @click="goToNoBill">
      <h5 class="mb-1"><strong>{{commodityType === 'ele' ? 'NON HO UNA BOLLETTA' : 'RISPONDO ALLE DOMANDE'}}</strong></h5>
      <h5>Vorrei rispondere alle domande per avere una stima dei miei consumi</h5>
    </div> -->
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'discoverEstimate',
  computed: {
    ...mapGetters('upsellingComm', ['commodityType'])
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'setEstimationType',
      'resetSupplyDetail'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    goToEnterDataManually() {
      this.resetSupplyDetail()
      this.$emit('setFlowType', 'enterDataManually')
      this.setEstimationType('manual')
      this.$emit('nextStep', 'upsellingcom_isresident_open')
      this.trackTapEvent({
        name: 'upsellingcom_entermanually_tap',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    },
    goToUploadBill() {
      this.resetSupplyDetail()
      this.$emit('setFlowType', 'uploadBill')
      this.setEstimationType('bill')
      const address = this.$store.getters['upsellingComm/address']
      if (this.commodityType === 'gas') {
        this.$emit('goToStep', 14, 'upsellingcom_askhelp_open')
        this.trackTapEvent({
          name: 'upsellingcom_uploadbill_tap',
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: address
              ? address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      } else {
        this.$emit('goToStep', 16, 'upsellingcom_askhelp_open')
        this.trackTapEvent({
          name: 'upsellingcom_uploadbill_tap',
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: address
              ? address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      }
    },
    goToNoBill() {
      this.resetSupplyDetail()
      this.$emit('setFlowType', 'noBill')
      this.setEstimationType('noBill')
      this.$emit('goToStep', 6, 'upsellingcom_isresident_open')
      this.trackTapEvent({
        name: 'upsellingcom_nobill_tap',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  },
  created() {
    // this.resetSupplyDetail()
  }
}
</script>
<style lang="scss" scoped>
.estimate {
  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 652px;
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    cursor: pointer;
    h5 {
      max-width: 600px;
    }
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
}
</style>
