<template>
  <div v-if="isClientMulti">
    <v-btn
      x-large
      depressed
      color="white"
      class="change-btn"
      text
      @click="suppliesDialog = !suppliesDialog"
      >{{ fullStreet }}
      <v-icon class="ml-4" color="primary">mdi-chevron-down</v-icon></v-btn
    >
    <SuppliesDialog
      v-model="suppliesDialog"
      :typeComm="suppliesDialogType"
      :typeTrigger="suppliesDialogTrigger"
    />
  </div>
</template>

<script>
import SuppliesDialog from '@components/supplies/SuppliesDialog.vue'
import { mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      suppliesDialog: false,
      suppliesDialogType: 'photovoltaic', // TODO check value
      suppliesDialogTrigger: 'dashboardFV' // TODO check value
    }
  },
  components: {
    SuppliesDialog
  },
  watch: {
    address() {
      this.suppliesDialog = false
    }
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapState('fotovoltaico', ['addressSelected']),
    isClientMulti() {
      // logiche recupero utente e controllo se multi indirizzo multi impianto
      return this.supplies.filter((curr) => curr.photovoltaicData).length
    },
    fullStreet() {
      return this.addressSelected.street + ', ' + this.addressSelected.city
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}
.change-btn {
  background-color: white !important;
  color: #12256a !important;
  border: 1px solid #dfe4ef;
}

.dialog-card {
  padding: 16px;
}
.dialog-title {
  display: flex;
  justify-content: space-between;
  font-size: 22px !important;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 26.4px;
}
</style>
