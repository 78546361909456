<template>
  <div class="punDetails">
    <div class="successCase" v-if="punCopies.successData">
      <div class="punDetails__content">
        <div class="punDetails__title">
          <div class="HeadingS-strong">{{ punCopies.title }}</div>
          <img
            @click="openPunModal"
            class="ml-2 infoCta"
            src="@/assets/icons/info.svg"
          />
        </div>
        <div class="punDetails__dateBox">
          <div class="mr-5">
            <div class="punDetails__day mt-4">
              <div class="LabelL">{{ todayValues.dayTitle }}</div>
              <div class="BodyL ml-2">{{ todayValues.dayValue }}</div>
            </div>
            <div v-if="tomorrowValues" class="punDetails__day mt-5">
              <div class="LabelL">{{ tomorrowValues.dayTitle }}</div>
              <div class="BodyL ml-2">{{ tomorrowValues.dayValue }}</div>
            </div>
          </div>
          <div>
            <div class="punDetails__date__punHours">
              <div
                v-for="(gap, i) in todayValues.gaps"
                :key="i"
                class="BodyM punDetails__date__punHours__hour mt-3"
                v-html="gap"
              ></div>
            </div>
            <div v-if="tomorrowValues" class="punDetails__date__punHours">
              <div
                v-for="(gap, i) in tomorrowValues.gaps"
                :key="i"
                class="BodyM punDetails__date__punHours__hour mt-3"
                v-html="gap"
              ></div>
            </div>
          </div>
        </div>

        <div class="BodyM mt-3">{{ punCopies.successData.updateDate }}</div>
      </div>
      <div @click="toPunDetails" class="punDetails__cta ButtonL">
        {{ punCopies.successData.ctaTitle }}
      </div>
    </div>
    <div class="errorCase" v-if="punCopies.errorData">
      <div class="errorCase__content">
        <div class="errorCase__title">
          <div class="HeadingS-strong">{{ punCopies.title }}</div>
          <img
            @click="openPunModal"
            class="ml-2 infoCta"
            src="@/assets/icons/info.svg"
          />
        </div>
        <div class="errorCase__warning">
          <img src="@/assets/pun/warning.svg" />
          <div class="errorCase__warning__text">
            {{ punCopies.errorData.errorCopy }}
          </div>
          <div @click="toGME" class="errorCase__warning__cta">
            <div class="ButtonM">
              {{ punCopies.errorData.ctaGME }}
            </div>
            <img src="@/assets/icons/arrow-up-dx.svg" />
          </div>
        </div>
      </div>
    </div>
    <CustomDialog
      :customDialogOpen.sync="customDialogOpen"
      :body="bodyModal"
      :title="titleModal"
      :showImage="showImage"
    />
  </div>
</template>

<script>
import CustomDialog from '../upselling/gas&light/CustomDialog.vue'

export default {
  name: 'PunCard',
  components: {
    CustomDialog
  },
  data() {
    return {
      customDialogOpen: false,
      bodyModal: null,
      titleModal: null,
      showImage: false
    }
  },
  props: {
    punCopies: {
      type: Object
    }
  },
  computed: {
    todayValues() {
      return this.punCopies.successData.values.find(
        (value) => value.dayTitle === 'OGGI'
      )
    },
    tomorrowValues() {
      return this.punCopies.successData.values.find(
        (value) => value.dayTitle === 'DOMANI'
      )
    },
    fixBodyModal() {
      const htmlString = this.punCopies.modalText

      const firstParagraphEndIndex = htmlString.indexOf('</p>')

      if (firstParagraphEndIndex === -1) {
        return htmlString
      }

      return (
        htmlString.slice(0, firstParagraphEndIndex + 4) +
        '<br>' +
        htmlString.slice(firstParagraphEndIndex + 4)
      )
    }
  },
  methods: {
    toPunDetails() {
      this.$router.push({
        name: 'punDetail'
      })
    },
    openPunModal() {
      this.customDialogOpen = true
      this.bodyModal = this.fixBodyModal
      this.titleModal = this.punCopies.modalTitle
      this.showImage = false
    },
    toGME() {
      window.open(this.punCopies.errorData.linkGME, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';

.punDetails {
  background-color: #f8f9fc;
  width: 1146px;
  padding: 16px 24px 16px 24px;
  border-radius: 24px;
  margin: auto;
  display: flex;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__title {
    display: flex;
    align-items: center;
  }

  &__date {
    display: flex;
    align-items: center;

    &__punHours {
      display: flex;
      &__hour {
        padding: 7px 10px 6px 10px;
        border-radius: 34px;
        border: 1px solid #5eb83d;
        background-color: #edf7e9;
        margin-left: 5px;
      }
    }
  }
  &__dateBox {
    display: flex;
    margin-top: 8px;
  }
  &__day {
    display: flex;
  }
  &__cta {
    margin-right: 18px;
    margin-left: auto;
    color: $srg-blue;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    cursor: pointer;
  }
}
.infoCta {
  cursor: pointer;
}
.successCase {
  display: flex;
  width: 100%;
}
.errorCase {
  display: flex;
  width: 100%;

  &__title {
    display: flex;
    align-items: center;
  }

  &__content {
    margin-bottom: 12px;
  }

  &__warning {
    display: flex;
    align-items: center;
    background-color: #e7f5f7;
    margin-top: 16px;
    width: 1098px;
    height: 69px;
    padding: 16px;
    border-radius: 16px;

    &__text {
      margin-left: 12px;
    }

    &__cta {
      margin-left: auto;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-left: 8px;
        margin-bottom: 3px;
        width: 18px;
      }
    }
  }
}
.ButtonM {
  color: $srg-blue;
}
.LabelL {
  line-height: 1.5;
}
.BodyL {
  line-height: 1.5;
}
</style>
