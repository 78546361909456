<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="750">
    <div class="help">
      <img
        class="close"
        @click="closeDialog"
        src="../../assets/ic_chevron_top_black.png"
      />
      <h2
        v-if="contextualHelpData && contextualHelpData.title"
        v-html="contextualHelpData.title"
      ></h2>
      <div v-if="contextualHelpData && contextualHelpData.image" class="image">
        <img :src="CmsUrl + contextualHelpData.image" />
      </div>
      <div
        v-if="contextualHelpData && contextualHelpData.text"
        class="text"
        v-html="contextualHelpData.text"
      />
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { ENV_CONFIGS } from '@/js/configs.js'
export default {
  name: 'dialogContextualHelp',
  props: ['value', 'idContextualHelp', 'clientCode'],
  watch: {
    value(val) {
      if (val) {
        this.getContextualHelpById()
      }
    }
  },
  data() {
    return {
      contextualHelpData: null,
      CmsUrl: ENV_CONFIGS.CMS_CDN_BASE_URL
    }
  },
  computed: {
    ...mapGetters('account', ['accountClients'])
  },
  methods: {
    ...mapActions('contextualHelp', ['getContextualHelp']),
    ...mapActions('analytics', ['trackTapEvent']),
    getContextualHelpById() {
      this.getContextualHelp(this.idContextualHelp).then((resp) => {
        this.contextualHelpData = resp
        let clientType =
          this.clientCode &&
          this.accountClients &&
          this.accountClients[this.clientCode]
            ? this.accountClients[this.clientCode].clientOwnerType
            : 'not_selected'
        this.trackTapEvent({
          name: 'supply_contextualhelp_tap',
          params: {
            help_id: this.idContextualHelp,
            client_code: this.clientCode || 'not_selected',
            client_type: clientType
          }
        })
      })
    },
    closeDialog() {
      this.$emit('input')
    }
  }
}
</script>
<style lang="scss" scoped>
.v-dialog__content {
  .help {
    background: white;
    padding: 32px 48px 48px 48px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
    .image {
      margin-bottom: 20px;
      border-radius: 40px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .text {
      font-size: 20px;
      line-height: 1.3;
    }
  }
}
</style>
