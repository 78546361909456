<template>
  <div class="std-side-padding" v-if="!accountClients">Loading ...</div>
  <div v-else class="std-side-padding">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="support__title mb-8">Contattaci</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <h2 class="support__sub-title">Seleziona profilo</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <p class="support__title-desc">
          Per poterti aiutare al meglio, indica quale profilo ha bisogno di
          supporto.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-7">
        <ExtensibleButtonGroup
          v-model="activeContract"
          :per-row="3"
          :content="false"
        >
          <ExtensibleButton
            v-for="n in accountClients"
            :key="n.businessName"
            :name="n.clientOwnerCode"
            :label="n.businessName"
          />
        </ExtensibleButtonGroup>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="support__sub-title">Contattaci</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <p class="support__title-desc">
          Scegli il canale che preferisci per ricevere assistenza subito:
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card
          outlined
          :to="'/private/support/contacts'"
          :disabled="!activeContract || !chatOpen"
          height="225"
          class="v-card--rounded"
          :class="{ 'std-panel--shadowed': activeContract && chatOpen }"
          @click="openChat"
        >
          <v-list-item-content style="height: 100%">
            <v-icon
              class="pt-5"
              :class="{ active: activeContract && chatOpen }"
              size="90"
              >mdi-comment-outline</v-icon
            >
            <v-divider class="mt-auto" />
            <v-list-item-title
              :class="{ active: activeContract && chatOpen }"
              class="text-center font-weight-bold mt-3"
              >Chat</v-list-item-title
            >
          </v-list-item-content>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="routerName !== 'Fiber'">
        <v-card
          outlined
          :to="'/private/support/contacts'"
          :disabled="true"
          height="225"
          class="v-card--rounded"
        >
          <v-list-item-content style="height: 100%">
            <v-icon class="pt-5" size="90">mdi-whatsapp</v-icon>
            <v-divider class="mt-auto" />
            <v-list-item-title class="text-center font-weight-bold mt-3"
              >Whatsapp {{ this.watsUpOpen ? watsUp : '' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-card>
      </v-col>
    </v-row>
    <v-footer color="white" class="mt-8">
      <v-row>
        <v-col class="py-0">
          <h2 class="support__title">Oppure contattaci qui:</h2>
        </v-col>
      </v-row>
      <v-row v-if="email && mobile">
        <v-col>
          <div>
            <p>Email</p>
            <p>
              <a
                target="_blank"
                :href="'mailto:' + email"
                @click="openContactLinks('email', email)"
                >{{ email }}</a
              >
            </p>
            <p
              v-if="
                accountProperties.hasFiberActivating ||
                accountProperties.hasFiberActive
              "
            >
              <a
                target="_blank"
                :href="'mailto:' + fiberEmail"
                @click="openContactLinks('email', fiberEmail)"
                >{{ fiberEmail }}</a
              >
            </p>
          </div>
        </v-col>
        <v-col>
          <p>Numero verde</p>
          <p>
            <a
              target="_blank"
              :href="'tel:' + staticPhone"
              @click="openContactLinks('landlinePhone', staticPhone)"
            >
              {{ staticPhone.replace(/800/g, '800.') }} </a
            ><span> (da fisso)</span>
          </p>
          <p>
            <a
              target="_blank"
              :href="'tel:' + mobile"
              @click="openContactLinks('mobilePhone', mobile)"
            >
              {{ mobile }}</a
            ><span> (da mobile*)</span>
            <span class="support__infoFromMobile">
              * il costo della chiamata è variabile in funzione dell'operatore
              telefonico
            </span>
          </p>
          <template
            v-if="
              accountProperties.hasFiberActivating ||
              accountProperties.hasFiberActive
            "
          >
            <p class="mb-0"><span>lun-ven 8.00 - 20.00 per luce e gas</span></p>
            <p class="mb-0"><span>lun-ven 8.00 - 21.00 per fibra</span></p>
            <p class="mb-0">
              <span
                >sab 8.00 - 15.00 per luce, gas e fibra esclusi festivi
                nazionali</span
              >
            </p>
          </template>
          <template v-else>
            <p class="mb-0"><span>lun - ven 8.00 - 20.00</span></p>
            <p class="mb-0">
              <span>sab 8.00 - 15.00 esclusi festivi nazionali</span>
            </p>
          </template>
        </v-col>
        <v-col>
          <p>Social</p>
          <v-row>
            <a
              target="_blank"
              href="https://www.facebook.com/sorgenia"
              @click="openContactLinks('facebook')"
            >
              <v-icon class="social-button" size="64px">mdi-facebook</v-icon>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/sorgenia"
              @click="openContactLinks('twitter')"
            >
              <v-icon class="social-button" size="64px">mdi-twitter</v-icon>
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer color="white" height="80" />
  </div>
</template>

<script>
import ExtensibleButtonGroup from '../../components/extensibleButton/ExtensibleButtonGroup'
import ExtensibleButton from '../../components/extensibleButton/ExtensibleButton'
import { mapActions, mapGetters } from 'vuex'
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { eventBus } from '@/main'

export default {
  name: 'ContactsComponent',
  components: {
    ExtensibleButtonGroup: ExtensibleButtonGroup,
    ExtensibleButton: ExtensibleButton
  },
  mounted() {
    this.activeContract = this.$route.params.user || ''
  },
  beforeMount() {
    this.getClientsForAccount()
    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.CHAT,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.chatOpen = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.staticPhone = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.WHATSAPP,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.watsUpOpen = resp
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.EMAIL,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.email = resp.contact
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.MOBILE,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.mobile = resp.contact
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.EMAIL,
      area: consts.CALLCENTER_AREAS.SUPPORT_FIBER
    })
      .then((resp) => {
        this.fiberEmail = resp.contact
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.WHATSAPP,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.watsUp = resp.contact
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
  },
  data: () => ({
    activeContract: '',
    email: '',
    fiberEmail: '',
    mobile: '',
    staticPhone: '',
    watsUp: '',
    chatOpen: true,
    watsUpOpen: true
  }),
  computed: {
    ...mapGetters('account', ['accountClients']),
    ...mapGetters('account', ['accountProperties']),
    routerName() {
      return this.$route.params.breadcrumbs
        ? this.$route.params.breadcrumbs[0].text
        : ''
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Contattaci' }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('account', ['getClientsForAccount']),
    ...mapActions('interactions', ['isChannelOpen']),
    ...mapActions('interactions', ['getChannelInfo']),
    ...mapActions('analytics', ['trackTapEvent']),
    openChat() {
      const accountClient = this.accountClients[this.activeContract]
      this.trackTapEvent({
        name: 'support_chatbot_tap',
        params: {
          client_code: accountClient.clientOwnerCode,
          client_type: accountClient.clientOwnerType?.toLowerCase()
        }
      })
      eventBus.$emit('chat:open', {
        from: 'support',
        client: this.accountClients[this.activeContract]
      })
    },
    openContactLinks(linkName, param) {
      let eventName = ''
      let sendParam = false

      switch (linkName) {
        case 'facebook':
          eventName = 'support_contactsFacebook_tap'
          break
        case 'twitter':
          eventName = 'support_contactsTwitter_tap'
          break
        case 'email':
          eventName = 'support_contactsEmail_tap'
          sendParam = true
          break
        case 'landlinePhone':
          eventName = 'support_contactsLandlineNumber_tap'
          sendParam = true
          break
        case 'mobilePhone':
          eventName = 'support_contactsMobileNumber_tap'
          sendParam = true
          break
      }
      if (sendParam) {
        this.trackTapEvent({ name: eventName, params: { value: param } })
      } else {
        this.trackTapEvent(eventName)
      }
    }
  }
}
</script>
<style lang="scss" src="../../styles/support/_main.scss" />
<style lang="scss" scoped>
.social-button {
  border-radius: 50%;
  border: 4px solid $srg-blue;
  color: $srg-blue;
  padding: 10px;
  margin-right: 30px;
}
.v-card.std-panel--shadowed {
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12);
}
.v-footer {
  margin-top: 100px;
  border-color: transparent;
  display: block;
  p {
    font-weight: bold;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  a {
    color: $srg-blue;
    cursor: pointer;
  }
  a:visited {
    color: $srg-blue;
  }
  span {
    font-weight: 400;
  }
}
.active {
  color: $srg-blue;
}
</style>
