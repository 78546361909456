var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.client && _vm.currentSlide)?_c('TabLayout',[_c('div',{staticClass:"know mt-12 w-100"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('h2',{staticClass:"mb-6 w-100"},[_c('strong',[_vm._v(_vm._s(_vm.currentSlide.title))])]),_c('h5',{staticClass:"mb-12",domProps:{"innerHTML":_vm._s(_vm.currentSlide.tab_subtitle)}})]),_c('v-row',{staticClass:"mb-12",attrs:{"no-gutters":""}},_vm._l((_vm.currentSlide.paragraphs),function(paragraph,i){return _c('v-col',{key:i,attrs:{"md":"3","sm":"3","cols":"12"}},[_c('div',{staticClass:"info-card"},[_c('v-img',{staticClass:"mb-6",attrs:{"contain":"","src":_vm.isSmallDevice
                  ? _vm.cdn + paragraph.image_mobile || paragraph.image
                  : _vm.cdn + paragraph.image}}),_c('h4',{staticClass:"mb-4"},[_c('strong',[_vm._v(_vm._s(paragraph.title))])]),_c('h5',{domProps:{"innerHTML":_vm._s(paragraph.body)}})],1)])}),1),_c('v-row',{staticClass:"reverseBtn",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"v-btn--shadowed mb-8 mx-auto",attrs:{"x-large":"","color":"white"},on:{"click":function($event){_vm.$router.push(_vm.back()),
                _vm.trackTapEvent({
                  name: 'welcomeKit_continue_tap',
                  params: {
                    section_title: _vm.prevSlideTitle,
                    client_code: _vm.client.clientOwnerCode,
                    client_type: _vm.client.clientOwnerType,
                    wk_type: _vm.client.type
                  },
                  toNative: _vm.isMobile
                })}}},[_vm._v(_vm._s(_vm.prevSlideTitle))])],1),_c('v-col',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNextSlide),expression:"hasNextSlide"}],staticClass:"v-btn--shadowed mb-8 mx-auto",attrs:{"x-large":"","color":"brightGreen"},on:{"click":function($event){_vm.$router.push(_vm.next()),
                _vm.trackTapEvent({
                  name: 'welcomeKit_continue_tap',
                  params: {
                    section_title: _vm.nextSlideTitle,
                    client_code: _vm.client.clientOwnerCode,
                    client_type: _vm.client.clientOwnerType,
                    wk_type: _vm.client.type
                  },
                  toNative: _vm.isMobile
                })}}},[_vm._v(_vm._s(_vm.nextSlideTitle))])],1)],1)],1),_c('v-row',[(_vm.currentSlide.show_tutorial == 1 && _vm.tutorial)?_c('v-col',{attrs:{"md":"6","offset":_vm.isSmallDevice ? 0 : _vm.currentSlide.show_feedback == 1 ? 0 : 3}},[_c('div',[_c('InstrumentItem',{attrs:{"title":"Tutorial","icon":"tutorial.svg","text":"Abbiamo creato una serie di guide navigabili per migliorare la tua esperienza in MySorgenia!","link":"Vai ai Tutorial","routeName":_vm.isMobile ? 'publicTutorial' : 'tutorial'},on:{"click":function($event){return _vm.trackTapEvent({
                name: 'welcomeKit_tutorial_tap',
                params: {
                  client_code: _vm.client.clientOwnerCode,
                  client_type: _vm.client.clientOwnerType,
                  wk_type: _vm.client.type
                },
                toNative: _vm.isMobile
              })}}})],1)]):_vm._e(),(_vm.currentSlide.show_feedback == 1)?_c('v-col',{attrs:{"md":"6","offset":_vm.isSmallDevice
            ? 0
            : _vm.currentSlide.show_tutorial == 1 && _vm.tutorial
            ? 0
            : 3}},[_c('div',[_c('InstrumentItem',{attrs:{"title":"Feedback","icon":"feedback.svg","text":"La tua opinione è un prezioso contributo. Dedicaci 3 minuti  rispondendo a poche domande!","link":"Dicci la tua","routeName":_vm.isMobile ? 'publicFeedback' : 'feedback'},on:{"click":function($event){return _vm.trackTapEvent({
                name: 'welcomeKit_feeback_tap',
                params: {
                  client_code: _vm.client.clientOwnerCode,
                  client_type: _vm.client.clientOwnerType,
                  wk_type: _vm.client.type
                },
                toNative: _vm.isMobile
              })}}})],1)]):_vm._e()],1)],1):_vm._e(),(_vm.currentSlide && _vm.currentSlide.show_feedback_overlay == 1 && _vm.overlay)?_c('TutorialOverlay',{attrs:{"overlay":_vm.overlay,"isMobile":_vm.isMobile},on:{"update:overlay":function($event){_vm.overlay=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }