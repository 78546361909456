<template>
  <div class="procedure mt-8">
    <div class="procedure__box" @click="gotoNext">
      <h5 class="mb-1"><strong>CAMBIO FORNITORE</strong></h5>
      <h5>Ho già una connessione e voglio cambiare fornitore in autonomia</h5>
    </div>
    <div class="procedure__box" @click="gotoStepActivation">
      <h5 class="mb-1"><strong>NUOVA CONNESSIONE</strong></h5>
      <h5>Voglio attivare una nuova connessione in autonomia</h5>
    </div>
    <div class="procedure__box" @click="gotoStepUpload">
      <h5 class="mb-1"><strong>SUPPORTO</strong></h5>
      <h5>Voglio caricare la bolletta e farmi richiamare da un operatore</h5>
    </div>
    <v-row class="mt-6 stickyRow" no-gutters v-if="withGoogleWiFi">
      <v-col>
        <div class="infobox">
          <span
            >{{ withGoogleWiFi }} Fritz Repeater
            {{ withGoogleWiFi === 1 ? 'aggiunto' : 'aggiunti' }}</span
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'procedureComponent',
  computed: {
    ...mapGetters('upsellingFiber', ['withGoogleWiFi'])
  },
  methods: {
    ...mapMutations('upsellingFiber', [
      'setMigrationCode',
      'updateFiberCtx',
      'setActiveLine',
      'setIsFromTelecom',
      'setIsFromFastweb'
    ]),
    gotoNext() {
      this.$emit('setFlowType', 'changeSupply')
      this.$emit('nextStep', 'upsellingv2_migrationcode_open')
    },
    gotoStepActivation() {
      this.setMigrationCode({ checked: false, code: '', isOF: '' })
      this.updateFiberCtx({ is_migration: false, migration_code: null })
      this.setActiveLine(false)
      this.setIsFromTelecom(false)
      this.setIsFromFastweb(false)
      this.$emit('setFlowType', 'newConnection')
      this.$emit('goToStep', 7, 'upsellingv2_activationtimes_open')
    },
    gotoStepUpload() {
      this.updateFiberCtx({ is_migration: false, migration_code: null })
      this.$emit('setFlowType', 'support')
      this.$emit('goToStep', 12, 'upsellingv2_uploadbill_open')
    }
  }
}
</script>
<style lang="scss" scoped>
.stickyRow {
  position: fixed;
  bottom: 20px;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
  }
  100% {
    opacity: 0;
  }
}

.infobox {
  background-color: #606060;
  color: white;
  width: 300px;
  display: flex;
  padding: 15px 20px 15px 10px;
  border-radius: 5px;
  height: 50px;
  font-size: 14px;
  -webkit-animation: fadeOut 4s;
  animation: fadeOut 4s;
  animation-fill-mode: forwards;
}

.procedure {
  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 652px;
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    cursor: pointer;
    h5 {
      max-width: 600px;
    }
    &::after {
      content: url('../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
}
</style>
