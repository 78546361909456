<template>
  <div class="sel-speed">
    <FiberHeader />
    <div
      class="sel-speed__content std-side-padding topRadius pt-8"
      v-if="!isNaN(discoutedPriceMin) && !isNaN(discoutedPriceMax)"
    >
      <div
        @click="$router.push({ name: 'fiberAddress' })"
        class="sel-speed__back mb-10"
      >
        <div class="HeadingsTitleXL">
          Di quale velocità Fibra hai bisogno per la tua casa?
        </div>
      </div>

      <div class="box-address" @click="selectSpeed(1000)">
        <div class="price">
          <div class="BodyM price__title">Fibra 1 Giga FTTH</div>
          <div class="HeadingsTitleS">
            {{ $filters.replaceDecimalPoint(discoutedPriceMin) }}
            {{ upsellingInfo.price_currency || '' }}/{{
              upsellingInfo.price_period || ''
            }}
          </div>
          <div class="BodyM price__desc">
            Consigliata per lo studio e il lavoro
          </div>
        </div>
      </div>
      <div class="box-address" @click="selectSpeed(2500)">
        <div class="price">
          <div class="BodyM price__title">Fibra 2,5 Giga FTTH</div>
          <div class="HeadingsTitleS">
            {{ $filters.replaceDecimalPoint(discoutedPriceMax) }}
            {{ upsellingInfo.price_currency || '' }}/{{
              upsellingInfo.price_period || ''
            }}
          </div>
          <div class="BodyM price__desc">
            Consigliata per streaming e gaming
          </div>
        </div>
      </div>
      <div class="btnSpeed">
        <div class="ButtonL" @click="dialogSpeed = true">
          Come scegliere la velocità
        </div>
      </div>
      <v-dialog max-width="910" v-model="dialogSpeed">
        <div class="dialogSpeed">
          <img
            class="dialogSpeed__close"
            src="../../assets/icons/ic-close.svg"
            @click="dialogSpeed = false"
          />
          <div class="HeadingsSubtitleL mb-10">Come scegliere la velocità</div>
          <div v-html="textPage" />
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FiberHeader from './fiber_bus/FiberHeader.vue'

export default {
  name: 'speedChoice',
  data() {
    return {
      monoMin: {},
      dualMin: {},
      monoMax: {},
      dualMax: {},
      dialogSpeed: false,
      textPage: null
    }
  },
  components: {
    FiberHeader
  },
  computed: {
    ...mapGetters('upsellingFiber', [
      'client',
      'connectionType',
      'addressCovered'
    ]),
    ...mapGetters('offers', ['upsellingInfo']),
    discoutedPriceMin() {
      return this.client.isDUAL
        ? (
            this.dualMin.price -
            this.dualMin.discount -
            this.dualMin.scontoScalino
          ).toFixed(2)
        : (
            this.monoMin.price -
            this.monoMin.discount -
            this.monoMin.scontoScalino
          ).toFixed(2)
    },
    discoutedPriceMax() {
      return this.client.isDUAL
        ? (
            this.dualMax.price -
            this.dualMax.discount -
            this.dualMax.scontoScalino
          ).toFixed(2)
        : (
            this.monoMax.price -
            this.monoMax.discount -
            this.monoMax.scontoScalino
          ).toFixed(2)
    }
  },
  methods: {
    ...mapActions('offers', ['getOffers', 'getUpselling']),
    ...mapActions('documents', ['geTextPage']),
    ...mapMutations('upsellingFiber', ['setAddressCovered']),
    ...mapActions('analytics', ['trackTapEvent']),
    selectSpeed(val) {
      this.trackTapEvent({
        name: 'upsellingv2_priceList_tap',
        params: {
          client_code: this.client.clientOwnerCode || 'not_selected',
          bandwidth: val
        }
      })
      this.addressCovered.speedVal = val
      this.setAddressCovered(this.addressCovered)
      this.$router.push({ name: 'fiberFlow' })
    }
  },
  async beforeMount() {
    await this.getUpselling({
      current_client_type: this.client.clientType,
      new_client_type: this.client.clientType,
      utility: 'fibra'
    })
    this.textPage = await this.geTextPage().then((resp) => {
      return (
        resp.find((t) => t.id === 'upselling_fiber_how_to_choose_band').corpo ||
        ''
      )
    })
    if (this.client.isDUAL) {
      this.dualMax = await this.getOffers({ type: 'APP_DUAL', speed: 2500 })
      this.dualMin = await this.getOffers({ type: 'APP_DUAL', speed: 1000 })
    } else {
      this.monoMax = await this.getOffers({ type: 'APP_MONO', speed: 2500 })
      this.monoMin = await this.getOffers({ type: 'APP_MONO', speed: 1000 })
    }
  }
}
</script>
<style lang="scss" scoped>
.sel-speed {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  &__content {
    margin-top: -30px !important;
    background: white;
  }
  &__back {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    &::before {
      content: url('../../assets/ic-chevron-sx-black.svg');
      margin-right: 15px;
      margin-top: 10px;
    }
  }
  .box-address {
    width: 555px;
    margin: 24px 0;
    padding: 16px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    letter-spacing: 0.5px;
    &::after {
      content: url('../../assets/icons/chevron_rx.svg');
      width: 16px;
    }
    .price {
      &__title {
        color: $neutri-neutral-60;
        margin-bottom: 4px;
      }
      &__desc {
        color: $neutri-neutral-50;
        margin-top: 4px;
      }
    }
  }
  .btnSpeed {
    width: 555px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    color: $srg-blue;
    cursor: pointer;
    margin-bottom: 80px;
  }
}
.dialogSpeed {
  background: white;
  padding: 40px;
  position: relative;
  &__close {
    position: absolute;
    top: 45px;
    right: 45px;
    cursor: pointer;
  }
}
</style>
