<template>
  <layout header-size="small" class="coins">
    <template v-slot:header>
      <v-col cols="6">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
        <h1>Ritira i tuoi premi</h1>
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div class="page">
      <div class="mt-15">
        <div class="HeadingL">Ecco i premi che hai accumulato</div>
      </div>
      <div class="mt-10 cards">
        <div v-if="awardCounter > 0" class="card-item">
          <v-card
            class="v-card--rounded gifts-content__item"
            :to="{ name: 'welcomeGift' }"
            @click.capture="
              trackTapEvent({
                name: 'greeners_prizesReqPrize_tap',
                params: {
                  client_code: mainClientLoyaltySelected.clientOwnerCode,
                  client_type: mainClientLoyaltySelected.clientOwnerType,
                  card_type: 'FIDELITY'
                }
              })
            "
          >
            <div class="loyalty-rewards">
              <div class="loyalty-rewards-content">
                <div class="HeadingsSubtitleM color--blue mb-3">
                  {{ awardCounter > 1 ? 'Premi' : 'Premio' }} Fedeltà
                </div>
                <div class="BodyM item-description">
                  <span v-if="awardCounter > 1">
                    Hai {{ awardCounter }} premi che ti aspettano!
                  </span>
                  <span v-else> Hai un premio che ti aspetta! </span>
                </div>
              </div>
              <div class="loyalty-rewards-image">
                <img
                  src="@assets/greeners/fiocco.svg"
                  class="loyalty-rewards-image sticky"
                />
              </div>
            </div>
            <v-divider class="gifts-content__item__divider" />
            <router-link
              :to="{ name: 'welcomeGift' }"
              class="gifts-content__item__link"
            >
              {{ awardCounter > 1 ? 'Richiedili' : 'Richiedilo' }}
            </router-link>
          </v-card>
        </div>
        <div
          v-if="
            availableAwards.welcome_iw === '002' || availableAwards.iw === '002'
          "
          class="card-item"
        >
          <v-card
            class="v-card--rounded gifts-content__item"
            :to="{ name: 'iwGift' }"
            @click.capture="
              trackTapEvent({
                name: 'greeners_prizesReqPrize_tap',
                params: {
                  client_code: mainClientLoyaltySelected.clientOwnerCode,
                  client_type: mainClientLoyaltySelected.clientOwnerType,
                  card_type: 'IW'
                }
              })
            "
          >
            <div class="loyalty-rewards">
              <div class="loyalty-rewards-content">
                <div class="HeadingsSubtitleM color--blue mb-3">Hai vinto!</div>
                <div class="BodyM item-description">
                  <span> Non aspettare, richiedi subito i tuoi premi! </span>
                </div>
              </div>
              <div class="loyalty-rewards-image">
                <img
                  src="@assets/greeners/festa.svg"
                  class="loyalty-rewards-image sticky"
                />
              </div>
            </div>
            <v-divider class="gifts-content__item__divider" />
            <router-link
              :to="{ name: 'iwGift' }"
              class="gifts-content__item__link"
            >
              Richiedi il tuo premio
            </router-link>
          </v-card>
        </div>
        <div v-if="isUserHasLevel" class="card-item">
          <v-card
            class="v-card--rounded greeners-card"
            :to="{ name: 'promote' }"
            @click.capture="
              trackTapEvent({
                name: 'greeners_prizesReqPrize_tap',
                params: {
                  client_code: mainClientLoyaltySelected.clientOwnerCode,
                  client_type: mainClientLoyaltySelected.clientOwnerType,
                  card_type: 'LEVEL'
                }
              })
            "
          >
            <img
              src="@assets/greeners/doublecoin.png"
              class="gifts-content__item__img gifts-content__item__img--center mb-3"
            />
            <div>
              <div>
                <div class="greeners-text">
                  Hai superato il livello! <br />
                  Richiedi adesso il tuo premio!
                </div>
              </div>
            </div>
            <v-divider class="gifts-content__item__divider" />
            <router-link
              :to="{ name: 'promote' }"
              class="gifts-content__item__link mt-2"
            >
              Richiedi premio
            </router-link>
          </v-card>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Layout from './../Layout'

export default {
  name: 'GiftsMainPage',
  components: {
    Layout
  },
  data: () => ({
    levelsDescr: [
      '',
      'Beginner',
      'Follower',
      'Friend',
      'Influencer',
      'Ambassador'
    ],
    gifts: [
      {
        name: 'Variazione potenza',
        filename: 'ph_nastro_green.png',
        route: 'powerAction'
      },
      {
        name: 'Cambia metodo di pagamento',
        filename: 'ph_nastro_green.png',
        route: 'paymentAction'
      },
      {
        name: 'Gestione contatore',
        filename: 'ph_nastro_green.png',
        route: 'counterAction'
      }
    ]
  }),
  computed: {
    ...mapGetters('loyalty', [
      'availableAwards',
      'catalogList',
      'awardCounter'
    ]),
    ...mapGetters('account', ['mainClientLoyaltySelected']),
    isUserHasLevel() {
      return Object.keys(this.availableAwards).some(
        (el) => el.startsWith('lev') && this.availableAwards[el] === '002'
      )
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        { to: { name: 'loyaltyPage' }, text: 'Diario Greeners', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Ritira premi' }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('loyalty', [
      'getAvailableAwards',
      'getCatalog',
      'requesteddAwardCounter'
    ]),
    ...mapActions('analytics', ['trackTapEvent'])
  },
  beforeMount() {
    this.getAvailableAwards()
    this.requesteddAwardCounter()
  },
  watch: {
    availableAwards(value) {
      if (value.welcome_iw === '002' || value.iw === '002')
        this.getCatalog({
          catalog_name: 'IW-WL',
          codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.gifts-content {
  padding: 42px 0;
  &__title {
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 359px;
    height: 176px;
    padding: 21px 11px 18px;
    box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12);

    &__title {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.08;
      margin-bottom: 18px;
    }

    &__text {
      font-size: 16px;
      line-height: 1.5;
      padding-right: 8px;
      margin-bottom: 20px;
    }

    &__img {
      width: 109px;
      height: auto;

      &--sticky {
        position: absolute;
        top: 0;
        right: 0;
      }

      &--center {
        height: 91px;
        width: auto;
        margin: 3px auto 6px;
      }
    }

    &__divider {
      margin-top: auto;
      margin: -20px -11px -8px;
      max-width: calc(100% + 22px);
      width: calc(100% + 22px);
      padding: 0 0 21px 0;
    }

    &__link {
      font-weight: bold;
      font-size: 18px;
      align-self: center;
    }
  }
}
.loyalty-rewards {
  display: flex;
}

.sticky {
  position: absolute;
  top: 0;
  right: 0;
}

.loyalty-rewards-divider {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 20px 0;
}

.loyalty-rewards-link {
  display: block;
  text-align: center;
  margin: 20px 0;
  font-size: 1rem; /* Esempio di dimensione del testo */
  color: #007bff; /* Esempio di colore del link */
  text-decoration: none;
}

.loyalty-rewards-link:hover {
  text-decoration: underline;
}
.item-description {
  margin-bottom: 68px;
  width: 200px;
}
.loyalty-rewards-content {
  height: 120px;
}
.greeners-card {
  display: flex;
  flex-direction: column;
  width: 359px;
  height: 259px;
  padding: 21px 11px 18px;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12);
}
.cards {
  width: 1140px;
  display: flex;
}
.card-item {
  margin-right: 32px;
}
.greeners-text {
  font-size: 16px;
  line-height: 1.5;
  padding-right: 8px;
  margin-bottom: 20px;
  text-align: center;
  margin-bottom: 36px;
}
</style>
