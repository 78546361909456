import { axiosCMS, axiosACN } from '@/js/axiosInstances.js'
import { requestCategories } from '@/js/constants'
import { isHoliday } from '@/js/utils'

const filterStatuses = (array) => {
  return array.filter((item) =>
    requestCategories.mappingArr.some(
      (el) => el === item.sottotipologiaInterazione
    )
  )
}

const getStatuses = (array) => {
  const arr = filterStatuses(array)
  return arr.map((item) => {
    const temp = item.sottotipologiaInterazione.split('-')
    item.sottotipologiaInterazione = temp[temp.length - 1]
    item.created = item.created.split(' ')[0]
    return item
  })
}

// che if channel is open based on current the day/time and service descriptor
const checkOpeningTimes = (channelObj) => {
  const objTimesToMinutes = (obj, timeField) => {
    const hours = parseInt(obj[timeField].split(':')[0])
    const minutes = parseInt(obj[timeField].split(':')[1])

    return hours * 60 + minutes
  }

  let openingTimeMinutes = null
  let closingTimeMinutes = null

  const currentDay = new Date().getDay()
  const currentHours = new Date().getHours()
  const currentMinutes = new Date().getMinutes()
  const currentTimeMinutes = currentHours * 60 + currentMinutes

  if (channelObj.enabled === '0') {
    return false
  }

  if (channelObj.active_on_holidays === '0' && isHoliday()) {
    return false
  }

  if (currentDay >= 1 && currentDay <= 5) {
    openingTimeMinutes = objTimesToMinutes(channelObj, 'monday_friday_start')
    closingTimeMinutes = objTimesToMinutes(channelObj, 'monday_friday_end')

    return (
      currentTimeMinutes >= openingTimeMinutes &&
      currentTimeMinutes <= closingTimeMinutes
    )
  }

  if (currentDay === 6) {
    openingTimeMinutes = objTimesToMinutes(channelObj, 'saturday_start')
    closingTimeMinutes = objTimesToMinutes(channelObj, 'saturday_end')

    return (
      currentTimeMinutes >= openingTimeMinutes &&
      currentTimeMinutes <= closingTimeMinutes
    )
  }

  if (currentDay === 0) {
    openingTimeMinutes = objTimesToMinutes(channelObj, 'sunday_start')
    closingTimeMinutes = objTimesToMinutes(channelObj, 'sunday_end')

    return (
      currentTimeMinutes >= openingTimeMinutes &&
      currentTimeMinutes <= closingTimeMinutes
    )
  }

  return false
}

const formatDate = () => {
  function pad(s) {
    return s < 10 ? '0' + s : s
  }
  var d = new Date()
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
}

const retrieveCallMeBack = (commit, getters, callmeData) => {
  let commodity =
    callmeData.commodity === 'fiber' ? 'fibra' : callmeData.commodity
  return axiosACN
    .post(
      '/commodity/callMeBack',
      {
        email: callmeData.email || 'test@email.com',
        codiceCliente: callmeData.clientCode,
        telefono: callmeData.phone,
        commodity: commodity,
        giorno: formatDate(),
        oraPeriodo: callmeData.times,
        notaAperta: callmeData.notes || '',
        ragioneSociale: callmeData.ragioneSociale || undefined,
        partitaIva: callmeData.partitaIva || undefined,
        nominativoReferenteContrattuale:
          callmeData.nominativoReferenteContrattuale || undefined,
        commodityAttive: callmeData.commodityAttive || undefined,
        mdp: callmeData.mdp || undefined,
        digital: callmeData.digital,
        requestType: callmeData.requestType || undefined
      },
      {
        headers: {
          Authorization: 'Bearer ' + getters['session/token']
        }
      }
    )
    .then((res) => {
      if (res.data.errorDescription) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }

      commit('POST_CALLMEBACK', {
        status: res.data.status === 'OK'
      })
      return res.data
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const retrieveCallCenterTimes = (commit) => {
  return axiosCMS
    .get('/callcentertimes-list')
    .then((res) => {
      commit('setCallCenterTimes', {
        times: res.data
      })

      return res.data
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    statusCallMe: false,
    callCenterTimes: null,
    statuses: []
  },
  getters: {
    statuses: (state) => state.statuses,
    callCenterTimes: (state) => state.callCenterTimes
  },
  mutations: {
    resetState(state) {
      state.statusCallMe = false
      state.callCenterTimes = null
      state.statuses = []
    },
    POST_CALLMEBACK(state, statusCallMe) {
      state.statusCallMe = statusCallMe
    },
    setCallCenterTimes(state, { times }) {
      state.callCenterTimes = times
    },
    setStatuses(state, statuses) {
      state.statuses = getStatuses(statuses)
    }
  },
  actions: {
    createInteraction({ rootGetters }, body) {
      return axiosACN
        .post('/commodity/creazioneInterazione', body, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          // Manage business logic errors (coming with status 200 OK + errorcode)
          if (resp.data.status === 'KO' || resp.data.errorCode) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }

          return resp.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    callMeBack({ state, commit, rootGetters }, callmeData) {
      if (state.supplies) {
        return Promise.resolve(state.status)
      } else {
        return retrieveCallMeBack(commit, rootGetters, callmeData)
      }
    },
    sendFile({ state, commit, rootGetters }, formData) {
      return axiosACN
        .post('/bollette/upsellingleadfoto', formData, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token'],
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if (
            res.data.status === 'KO' ||
            res.data.errorCode ||
            res.data.errorDescription
          ) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }

          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    sendSelfReadingFile({ state, commit, rootGetters }, formData) {
      return axiosACN
        .post('/commodity/invioFotoAutoletturaGas', formData, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.errorCode) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }

          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    getCallCenterTimes({ state, commit }) {
      if (state.callCenterTimes) {
        return Promise.resolve(state.callCenterTimes)
      } else {
        return retrieveCallCenterTimes(commit)
      }
    },

    setStatuses({ state, commit, rootGetters }, id) {
      return axiosACN
        .post(
          '/commodity/retrieveInterazioni',
          {
            codiceCliente: id
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          commit('setStatuses', res.data.retrieveInterazioniList)
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },

    isChannelOpen({ dispatch }, { channel, area }) {
      return dispatch('getCallCenterTimes').then((callCenterTimes) => {
        let chatObj = callCenterTimes.filter((item) => {
          return item.channel === channel && item.area === area
        })

        if (chatObj.length !== 1) {
          console.error(
            `SRG-ERR: Call center channel object not found for area '${area}' and channel '${channel}'`
          )
          throw Error('SERVER_ERROR')
        }

        chatObj = chatObj[0]

        return checkOpeningTimes(chatObj)
      })
    },

    getChannelInfo({ dispatch }, { channel, area }) {
      return dispatch('getCallCenterTimes').then((callCenterTimes) => {
        let channelObj = callCenterTimes.filter((item) => {
          return item.channel === channel && item.area === area
        })

        if (channelObj.length !== 1) {
          console.error(
            `SRG-ERR: Call center channel object not found for area '${area}' and channel '${channel}'`
          )
          throw Error('SERVER_ERROR')
        }

        channelObj = channelObj[0]

        return channelObj
      })
    },

    upsellingLeadFoto({ rootGetters }, data) {
      return axiosACN
        .post('/bollette/upsellingleadfoto', data, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token'],
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((resp) => {
          return resp.data.status === 'OK'
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },

    callMeBackUtente({ rootGetters }, callmeData) {
      return axiosACN
        .post('/commodity/callMeBackUtente', callmeData, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.errorDescription) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }

          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    }
  }
}
