<template>
  <v-dialog overlay-opacity="0.6" v-model="modal" max-width="910">
    <template v-slot:activator="{ on }">
      <button
        class="dialog__open"
        v-on="on"
        @click="
          trackTapEvent({
            name: 'greeners_diaryLevel_tap',
            params: {
              client_code: mainClientLoyaltySelected.clientOwnerCode,
              client_type: mainClientLoyaltySelected.clientOwnerType
            }
          })
        "
      >
        A che punto sei del percorso?
      </button>
    </template>
    <v-card class="livelli-dialog" v-if="!viewGuide">
      <div class="livelli-dialog__content">
        <h3>A che punto sei del percorso</h3>
        <img @click="modal = false" src="@/assets/ic_chevron_top_black.png" />
      </div>
      <p>
        Ogni 8 missioni completate, sali di livello e ricevi un premio in Green
        Coins di valore crescente per un totale di 2000 Green Coins extra
        ricevuti!
      </p>
      <div class="contentImgLevel">
        <img
          class="imgLevel"
          :src="`${require('@/assets/greeners/levels/' +
            clientLevel.level_name?.toLowerCase() +
            '.svg')}`"
        />
        <div :class="`contentLevel ${clientLevel.level_name?.toLowerCase()}`">
          <div
            v-if="
              clientLevel.level_name === 'LEVEL0' ||
              clientLevel.level_name === 'LEVEL4'
            "
            class="d-flex justify-center flex-column align-center mb-2"
          >
            <h5 class="levelText">{{ levelText }}</h5>
            <h5 class="step">{{ clientLevel.level_step + 1 }}/8</h5>
          </div>
          <StatusIcon
            withProgressionBar
            width="140"
            :client-level="clientLevel || {}"
          />
          <div
            v-if="
              clientLevel.level_name === 'LEVEL1' ||
              clientLevel.level_name === 'LEVEL2' ||
              clientLevel.level_name === 'LEVEL3'
            "
            class="d-flex justify-center flex-column align-center mt-2"
          >
            <h5 class="levelText">{{ levelText }}</h5>
            <h5 class="step">{{ clientLevel.level_step + 1 }}/8</h5>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center mt-8">
        <v-btn
          @click="viewGuide = true"
          class="white lowerCase"
          width="227"
          height="54"
          >Guida ai livelli</v-btn
        >
      </div>
      <!-- <ScaleLivelli class="livelli-dialog__img"/> -->
    </v-card>
    <v-card class="livelli-dialog" v-else>
      <div class="livelli-dialog__content">
        <div class="textBack">Guida ai livelli</div>
        <img @click="modal = false" src="@/assets/ic_chevron_top_black.png" />
      </div>
      <LevelGuides />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LevelGuides from '../LevelGuides.vue'
import StatusIcon from '../statusIcon.vue'

export default {
  name: 'indexScaleLevelli',
  components: {
    StatusIcon,
    LevelGuides
  },
  watch: {
    modal(val) {
      if (val) this.viewGuide = false
    }
  },
  data: () => ({
    modal: false,
    viewGuide: false
  }),
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  },
  computed: {
    ...mapGetters('account', ['clientDetails', 'mainClientLoyaltySelected']),
    ...mapGetters('loyalty', ['clientLevel']),
    levelText() {
      switch (this.clientLevel.level_name) {
        case 'LEVEL0':
          return 'SEME'
        case 'LEVEL1':
          return 'GERMOGLIO'
        case 'LEVEL2':
          return 'PIANTINA'
        case 'LEVEL3':
          return 'ARBUSTO'
        case 'LEVEL4':
          return 'QUERCIA'
        default:
          return 'SEME'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.livelli-dialog {
  padding: 40px;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
    h3 {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.2;
    }
    img {
      cursor: pointer;
    }
  }
  p {
    font-size: 20px;
    color: black;
    line-height: 1.4;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 36px;
  }
}
.dialog__open {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 1.2px;
  color: $srg-blue;
}
.textBack {
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 500;
  color: black;
  display: flex;
  align-items: center;
}
.contentImgLevel {
  position: relative;
  .contentLevel {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.level0 {
      top: 50px;
      left: 82px;
    }
    &.level1 {
      bottom: 8px;
      left: 193px;
    }
    &.level2 {
      top: 70px;
      right: 297px;
    }
    &.level3 {
      bottom: 10px;
      right: 104px;
    }
    &.level4 {
      right: 6px;
      top: 90px;
    }
    .levelText {
      color: $srg-blue;
      font-weight: bold;
      letter-spacing: 0.8px;
      line-height: 1.2;
      font-size: 19px;
    }
    .step {
      color: #2c313a;
      font-weight: bold;
      letter-spacing: 0.8px;
      line-height: 1.2;
      font-size: 19px;
    }
  }
  .imgLevel {
    width: 100%;
  }
}
</style>
