<template>
  <v-card
    light
    outlined
    class="v-card--rounded card"
    @click="openModalForPending"
  >
    <div class="card__content">
      <div v-if="isPending" class="card__locked-price">
        <img src="../../../assets/greeners/svg/locked.svg" alt="locked_price" />
      </div>
      <div
        :style="{ 'background-image': `url(${imgSrc})` }"
        class="card__img"
      />
      <h3 class="card__title BodyXL mb-6" v-text="label" />
      <div class="card__price">
        <img
          src="../../../assets/greeners/svg/noCoins.svg"
          class="mr-2"
          height="32"
          width="32"
          v-if="isPending || memberBalance.user_balance < price"
        />
        <img :src="icons.coins" width="32" height="32" class="mr-2" v-else />
        <p
          class="card__price__text BodyXL-Strong"
          v-text="price.toLocaleString('it-IT')"
        />
      </div>
      <div class="card__text-cta" v-if="isPending">
        <p class="CaptionM">
          {{
            isPending || memberBalance.user_balance < price
              ? 'SCOPRI'
              : 'RICHIEDI'
          }}
        </p>
        <img src="../../../assets/icons/chevron_rx.svg" width="24px" />
      </div>
    </div>
  </v-card>
</template>

<script>
import IconCoins from '@assets/greeners/svg/greeners.svg'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CardCatalogue',
  props: {
    imgSrc: { type: String, default: '' },
    label: { type: String, default: '' },
    id: { type: String, required: true },
    price: { type: Number, default: null }
  },
  computed: {
    ...mapGetters('loyalty', ['memberBalance', 'memberStatus']),
    icons: () => ({
      coins: IconCoins
    }),
    isPending() {
      if (this.memberStatus.statusGreeners === 'Pending') {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    openModalForPending() {
      this.trackTapEvent({
        name: 'greeners_catalogItem_tap',
        params: {
          prize_id: this.id,
          prize_title: this.label
        }
      })
      if (this.isPending) {
        this.dialogPendingUser = true
        this.$emit('openModalPending')
      } else {
        this.$router.push({
          name: 'catalogueDetails',
          params: { id: this.id }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-disabled {
  .card__locked-price {
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    background-color: $neutri-neutral-60;
    img {
      margin-top: 4px;
      margin-left: 7px;
    }
  }
  .card__text-cta {
    background-color: $neutri-neutral-20;
  }
  .card__price__text {
    color: $neutri-neutral-40;
  }
}
.v-card {
  height: 100%;

  h3 {
    font-size: 24px;
    font-weight: bold;
  }
}

.card {
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__wrap {
    padding: 0;
    margin-bottom: 48px;
  }

  &__img {
    height: 258px;
    width: 100%;
    background-size: cover;
    background-position: 50%;
    border-bottom: 1px solid rgba(#979797, 0.5);
    margin-bottom: 16px;
  }

  &__text-cta {
    border-top: 1px solid $neutri-neutral-20;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin-bottom: 0;
      color: #12256a;
    }
  }

  &__title {
    padding: 0 16px;
    margin-bottom: 6px;
  }

  &__price {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    padding: 0 16px;
    margin-top: auto;
    margin-bottom: 24px;

    &__text {
      margin: 0;
    }
  }
}
</style>
