var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-radio-group',{class:[
    'extensible',
    `extensible--${_vm.size}`,
    _vm.radio ? 'extensible--with-radio' : 'extensible--wo-radio'
  ],attrs:{"value":_vm.model,"perRow":_vm.perRow,"margin":_vm.margin,"size":_vm.size,"multiple":_vm.multiple},on:{"addedButton":_vm.handlerRefreshItems,"change":_vm.handlerRadioChanged,"changeActiveContent":_vm.changeActiveContent,"toggleShowContent":_vm.handlerToggleShowContent}},[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasActive),expression:"hasActive"}],staticClass:"extensible__background",attrs:{"height":_vm.svg.height + 2,"viewBox":`-1 -1 ${_vm.svg.width + 2 + _vm.margin * 2} ${_vm.svg.height + 2}`,"width":_vm.svg.width + _vm.margin * 2},domProps:{"innerHTML":_vm._s(_vm.svg.body)}}),_c('div',{class:[
      'extensible__rows',
      {
        'extensible__rows--hideContent':
          !_vm.showContent || !_vm.content || !_vm.items[_vm.model]
      }
    ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }