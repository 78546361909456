<template>
  <div class="topRadius">
    <TcVars />
    <v-container class="auth">
      <v-row no-gutters>
        <v-col md="6" offset-md="6">
          <v-row no-gutters>
            <v-col>
              <h1 class="auth__title">Informatva e consensi</h1>
              <p class="auth__subtitle--mini">
                Registrati qui per accedere all’Area Clienti Web e all’app
                Sorgenia inserendo i tuoi dati.
              </p>
              <h4 class="auth__subtitle--mini">Informativa e consensi</h4>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <h5>Informativa e privacy</h5>
              <v-checkbox
                v-model="legal"
                label="Ho preso visione"
                true-value="yes"
                false-value="no"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <h5 class="color--blue">
                Consenso per finalità marketing, indagini di mercato e altri
                servizi da parte di Sorgenia
              </h5>
              <v-radio-group
                v-model="mktFromSorgenia"
                :error-messages="marketingErrors"
                row
              >
                <v-radio label="Acconsento" value="yes"></v-radio>
                <v-radio label="Non acconsento" value="no"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <h5 class="color--blue">
                Consenso per identificare abitudini e propensioni al consumo
              </h5>
              <v-radio-group
                v-model="mktFromCustom"
                :error-messages="marketingErrors"
                row
              >
                <v-radio label="Acconsento" value="yes"></v-radio>
                <v-radio label="Non acconsento" value="no"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <h5 class="color--blue">
                Consenso per finalità marketing, indagini di mercato e altri
                servizi da parte di soggetti terzi
              </h5>
              <v-radio-group
                v-model="mktFromTerzi"
                :error-messages="marketingErrors"
                row
              >
                <v-radio label="Acconsento" value="yes"></v-radio>
                <v-radio label="Non acconsento" value="no"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <MYSButton
                button-class="primaryButton"
                width="260"
                @buttonClick="setPrivacy(privacyFlags), goNext()"
                :disabled="v$.$invalid"
                button-text="Conferma e procedi"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import { required } from '@vuelidate/validators'
import { mapActions, mapMutations } from 'vuex'
import { getRequiredValidationError } from '../../js/utils.js'
import router from '../../router/router'

const mustBeYes = (value) => {
  return value === 'yes'
}

import { useVuelidate } from '@vuelidate/core'
import MYSButton from '../../components/global/MYSButton.vue'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'RegistrationPrivacy',
  data() {
    return {
      mktFromSorgenia: 'no',
      mktFromTerzi: 'no',
      mktFromCustom: 'no',
      legal: 'no'
    }
  },
  components: {
    TcVars,
    MYSButton
  },
  computed: {
    privacyFlags() {
      return {
        legal: this.legal,
        mktFromSorgenia: this.mktFromSorgenia,
        mktFromCustom: this.mktFromCustom,
        mktFromTerzi: this.mktFromTerzi
      }
    },
    marketingErrors() {
      let errArray = []

      if (!this.v$.mktFromSorgenia.$dirty) return errArray

      errArray.push(
        ...getRequiredValidationError(
          this.v$,
          'mktFromSorgenia',
          "L'email è obbligatoria"
        )
      )
      this.v$.mktFromSorgenia.mustBeYes === false &&
        errArray.push('Il consenso è obbligatorio')

      return ['errrrr'] // errArray
    }
  },
  methods: {
    ...mapMutations('selfRegistration', {
      setPrivacy: 'setPrivacy'
    }),
    ...mapActions('selfRegistration', {
      register: 'register'
    }),
    async goNext() {
      let registrationOK = await this.register()
      router.push({
        name: 'RegistrationConfirmation',
        params: { registrationOK }
      })
    }
  },
  created() {
    this.mktFromSorgenia =
      this.$store.getters['selfRegistration/mktFromSorgenia'] ||
      this.mktFromSorgenia
    this.mktFromTerzi =
      this.$store.getters['selfRegistration/mktFromTerzi'] || this.mktFromTerzi
    this.mktFromCustom =
      this.$store.getters['selfRegistration/mktFromCustom'] ||
      this.mktFromCustom
    this.legal = this.$store.getters['selfRegistration/legal'] || this.legal
  },
  validations: {
    mktFromSorgenia: { required, mustBeYes },
    mktFromTerzi: { required, mustBeYes },
    mktFromCustom: { required, mustBeYes },
    legal: { required, mustBeYes }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/authentication/auth.scss';
</style>
