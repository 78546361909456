<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="440">
    <v-row>
      <v-col class="help-dialog">
        <img @click="closeDialog" src="../../assets/ic_chevron_top_black.png" />
        <h2>Hai bisogno di aiuto?</h2>
        <div class="help-dialog__text">
          <p v-if="landline.show">
            Chiamaci gratis al numero
            <span class="help-dialog__num">{{ landline.contact }}</span>
          </p>
          <p v-if="fromPage === 'commodity'">
            <span class="help-dialog__num"> (da fisso)</span> oppure
            <span class="help-dialog__num"
              >{{ mobile.contact }} (da mobile)</span
            >
          </p>
          <p v-if="chat.show">
            . In alternativa contatta in
            <span @click="openChat" class="help-dialog__chat">chat</span> un
            nostro operatore
          </p>
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { eventBus } from '@/main'

export default {
  name: 'helpComponent',
  props: ['value', 'landlineOpen', 'chatOpen', 'fromPage'],
  data() {
    return {
      landline: {
        contact: '',
        show: this.landlineOpen
      },
      mobile: {
        contact: ''
      },
      chat: {
        contact: '',
        show: false
      }
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails'])
  },
  methods: {
    ...mapActions('interactions', ['isChannelOpen']),
    ...mapActions('interactions', ['getChannelInfo']),
    closeDialog() {
      this.$emit('input')
    },
    openChat() {
      eventBus.$emit('chat:open', { from: 'Help', client: this.clientDetails })
    }
  },
  beforeMount() {
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area:
        this.fromPage === 'fiber'
          ? consts.CALLCENTER_AREAS.UPSELLING_FIBER
          : consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
    })
      .then((resp) => {
        this.landline.contact = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })

    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.CHAT,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.chat.contact = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })

    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.MOBILE,
      area: consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
    })
      .then((resp) => {
        this.mobile.contact = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })

    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.CHAT,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.chat.show = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  }
}
</script>
<style lang="scss" scoped>
.v-dialog__content {
  .help-dialog {
    background: white;
    padding: 75px 48px 50px 48px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    &__text {
      font-size: 22px;
      font-weight: 300;
      line-height: 30px;
      p {
        display: inline;
      }
      p::first-letter {
        text-transform: uppercase;
      }
      &--small {
        font-size: 20px;
      }
    }
    &__num {
      font-weight: bold;
    }
    &__chat {
      color: $srg-blue;
      font-weight: bold;
      cursor: pointer;
    }
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
  }
}
</style>
