<template>
  <div>
    <div class="text-center" v-if="!loadBills">
      <v-progress-circular :size="80" indeterminate color="#12256A" />
    </div>
    <template v-else-if="filteredAll.length == 0">
      <v-row class="mt-10">
        <v-col class="bills__list">
          <v-row align="center">
            <v-col class="empty_text std-panel std-panel--empty">
              <div class="text-center pt-5 pb-5" v-html="billingFrequency" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else v-for="(item, i) in filteredAll.slice(0, 3)">
      <PdrItem
        v-if="item.planStartDate"
        :pdrItem="item"
        :supply="detail"
        @openModal="openModal"
        :key="i"
      />
      <BillItem v-else :bills="item" :supply="detail" :key="i" />
    </template>
    <DialogPagaRate
      v-if="Object.keys(pdrSelected).length > 0"
      v-model="dialogPayRatePdr"
      source="Dettaglio Utenza"
      :pdrAttivo="pdrSelected"
      :clientDetails="clientDetails"
      :intestatario="getIntestatario()"
      :dialogKey="pdrSelected.planId"
    ></DialogPagaRate>
  </div>
</template>
<script>
import PdrItem from '../../components/pdr/PdrItem.vue'
import BillItem from '../../components/bills/BillItem.vue'
import DialogPagaRate from '../pdr/DialogPagaRate.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'listPdrBills',
  props: {
    filteredAll: { type: Array, default: () => [] },
    detail: { type: Object, default: () => {} },
    loadBills: { type: Boolean }
  },
  components: {
    PdrItem,
    BillItem,
    DialogPagaRate
  },
  data() {
    return {
      dialogPayRatePdr: false,
      pdrSelected: {}
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails']),
    billingFrequency() {
      if (this.detail.billingFrequency === 'bimestrale') {
        return 'Qui troverai le tue bollette <strong>bimestrali</strong> a partire dal <strong>terzo mese di fornitura</strong>'
      } else {
        return 'Qui troverai le tue bollette <strong>mensili</strong> a partire dal <strong>secondo mese di fornitura</strong>'
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    getIntestatario() {
      let intestatario = ''
      if (this.clientDetails.clientType === 'BUS') {
        intestatario = this.clientDetails.businessName
      } else {
        intestatario =
          this.clientDetails.name + ' ' + this.clientDetails.surname
      }
      return intestatario
    },
    openModal(pdr) {
      this.trackTapEvent({
        name: 'supplyDetail_PDRpayment_tap'
      })
      this.pdrSelected = pdr
      this.dialogPayRatePdr = true
    }
  }
}
</script>
<style lang="scss" scoped>
.empty_text {
  background-color: #fff;
  font-size: 20px;
}
</style>
