var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"std-side-padding upSelling details"},[_c('v-row',[_c('v-col',{staticClass:"col-7 details-desc"},[(_vm.type === 'gas')?_c('h2',{staticClass:"details-desc__title"},[_vm._v("Gas!")]):_vm._e(),(_vm.type === 'Ele')?_c('h2',{staticClass:"details-desc__title"},[_vm._v("Luce!")]):_vm._e(),_c('div',{staticClass:"details-desc__list col-2 offset-10"},[(!_vm.isReady)?_c('v-progress-circular',{attrs:{"size":50,"indeterminate":""}}):_vm._e()],1),(_vm.data.conditions && _vm.isReady)?_c('div',{staticClass:"details-desc__list",domProps:{"innerHTML":_vm._s(_vm.data.conditions)}}):_vm._e()]),_c('v-col',{staticClass:"col-5 details-promo"},[(_vm.data.promo_label && _vm.data.promo_description)?_c('div',{staticClass:"details-promo__circle d-flex flex-column justify-center align-center"},[_c('span',[_vm._v(_vm._s(_vm.data.promo_label))]),_c('h3',[_vm._v(_vm._s(_vm.data.promo_description))])]):_vm._e()]),_c('v-row',{staticClass:"upSelling__button-block"},[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"v-btn--shadowed",attrs:{"x-large":"","color":"primary"},on:{"click":function($event){_vm.$emit('nextStep'),
              _vm.trackTapEvent({
                name: 'commodityUpsell_offer_tap',
                params: {
                  client_code: _vm.requestingClient.clientCode,
                  client_type: _vm.requestingClient.clientType
                }
              })}}},[_vm._v(" Prosegui ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }