<template>
  <div>
    <div class="levelItem">
      <img src="../../../assets/greeners/svg/beginner.svg" />
      <div class="ml-4">
        <h5>Seme</h5>
        <div class="text">
          Immergiti nelle basi della sostenibilità e scopri come le tue scelte
          quotidiane possono fare la differenza per il nostro Pianeta.
        </div>
      </div>
    </div>
    <div class="levelItem">
      <img src="../../../assets/greeners/svg/follower.svg" />
      <div class="ml-4">
        <h5>Germoglio</h5>
        <div class="text">
          Il tuo impegno sboccia come un tenero germoglio. La tua crescita
          riflette il tuo impegno sempre maggiore verso uno stile di vita più
          sostenibile.
        </div>
      </div>
    </div>
    <div class="levelItem">
      <img src="../../../assets/greeners/svg/friend.svg" />
      <div class="ml-4">
        <h5>Piantina</h5>
        <div class="text">
          Come una piantina in crescita, la tua influenza positiva si espande.
          Sei ora parte integrante del cambiamento grazie alle tue scelte ed
          azioni quotidiane.
        </div>
      </div>
    </div>
    <div class="levelItem">
      <img src="../../../assets/greeners/svg/influencer.svg" />
      <div class="ml-4">
        <h5>Arbusto</h5>
        <div class="text">
          Sei un robusto arbusto, radicato nella tua missione sostenibile. Il
          tuo supporto ai progetti di tutela ambientale e sociale è determinante
          per aumentare l'impatto positivo della community.
        </div>
      </div>
    </div>
    <div class="levelItem">
      <img src="../../../assets/greeners/svg/ambassador.svg" />
      <div class="ml-4">
        <h5>Quercia</h5>
        <div class="text">
          Come una quercia, influenzi positivamente l'intero ecosistema ed
          orienti il comportamento degli altri per rendere l'impatto della
          community rilevanti per il nostro Pianeta e le persone.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'levelGuides'
}
</script>
<style lang="scss" scoped>
.levelItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0px;
  }
  img {
    width: 80px;
    flex-shrink: 0;
  }
  .text {
    font-size: 20px;
    line-height: 1.4;
    color: black;
    letter-spacing: 1px;
  }
  h5 {
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: $srg-blue;
    margin-bottom: 8px;
  }
}
</style>
