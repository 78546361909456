<template>
  <div class="disconnectable">
    <div class="disconnectable__btn" @click="next(true)">
      <h5>Si, è disalimentabile</h5>
    </div>
    <div class="disconnectable__btn mb-10" @click="unpluggableDialog = true">
      <h5>No, non è disalimentabile</h5>
    </div>
    <div class="disconnectable__box">
      <div class="title">Cosa si intende per disalimentabile</div>
      <h5 class="desc">
        Tipologia particolare del contatore. Se selezioni NO, ad esempio, vuol
        dire che al tuo contatore sono collegate delle macchine salvavita. In
        questo caso, ti verrà richiesto di fornire la documentazione che attesti
        questa particolare condizione.
      </h5>
    </div>
    <v-dialog max-width="750" v-model="unpluggableDialog" ref="dialog">
      <div class="dialog">
        <img
          @click="unpluggableDialog = false"
          src="../../../../assets/ic_chevron_top_black.png"
        />
        <h2>Utenza non disalimentabile</h2>
        <div class="description mb-8">
          Selezionando “No” dichiari di essere in possesso di
          <strong
            >apparecchiature medico terapeutiche necessarie per l'esistenza in
            vita.</strong
          >
        </div>
        <div class="d-flex align-center btn-content justify-center">
          <MYSButton
            buttonClass="primaryInvertedButtonL mr-2"
            buttonText="Torna indietro"
            @buttonClick="unpluggableDialog = false"
          />
          <MYSButton
            buttonClass="primaryButtonL ml-2"
            buttonText="Continua"
            @buttonClick="next(false)"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
export default {
  name: 'disconnectableComponent',
  components: {
    MYSButton
  },
  data() {
    return {
      unpluggableDialog: false
    }
  },
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetailRoot']),
    ...mapActions('analytics', ['trackTapEvent']),
    next(type) {
      this.setSupplyDetailRoot({ isUnpluggable: type })
      this.$emit('nextStep', 'upsellingcom_titolarity_open')
      let name = type ? 'detachable' : 'undetachable'
      this.trackTapEvent({
        name: `upsellingcom_${name}_open`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.disconnectable {
  max-width: 652px;
  color: black;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 66px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
.dialog {
  background: white;
  padding: 55px 32px 45px 32px;
  border-radius: 40px;
  position: relative;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .description {
    font-size: 16px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
  }
  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    &.preview {
      position: initial;
    }
  }
  .btn-content {
    .back {
      font-size: 16px;
      letter-spacing: normal;
    }
  }
}
</style>
