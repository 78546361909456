<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <h6 class="back mb-7" @click="$emit('backToStep')">
        <strong>Indietro</strong>
      </h6>
    </div>
    <h3 class="mb-8 text-center">
      Vuoi salvare le tue risposte e riprendere il questionario in seguito?
    </h3>
    <div class="d-flex justify-space-between align-center flex-column">
      <v-btn
        @click="saveAndExit"
        class="primary v-btn--shadowed mb-4"
        width="269"
        height="54"
        >Sì, salva le risposte</v-btn
      >
      <v-btn @click="exit" color="#12256a" text width="269" height="54"
        >No, non salvare le risposte</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex/dist/vuex.esm.browser'
export default {
  name: 'exitComponent',
  props: ['backToStep', 'type'],
  computed: {
    ...mapGetters('profile', ['userProfile'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    saveAndExit() {
      this.trackTapEvent({
        name: 'profile2_exitbs_tap',
        params: {
          survey_type: this.type,
          action_name: 'save',
          client_code: this.userProfile.clientOwnerCode
        }
      })
      this.$emit('saveSurvey')
      this.$emit('update:dialog', false)
    },
    exit() {
      this.trackTapEvent({
        name: 'profile2_exitbs_tap',
        params: {
          survey_type: this.type,
          action_name: 'dont_save',
          client_code: this.userProfile.clientOwnerCode
        }
      })
      this.$emit('update:dialog', false)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './styleSurvey.scss';
</style>
