import { render, staticRenderFns } from "./DialogPagaPdr.vue?vue&type=template&id=6e488303&scoped=true"
import script from "./DialogPagaPdr.vue?vue&type=script&lang=js"
export * from "./DialogPagaPdr.vue?vue&type=script&lang=js"
import style0 from "./DialogPagaPdr.vue?vue&type=style&index=0&id=6e488303&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e488303",
  null
  
)

export default component.exports