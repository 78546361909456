<template>
  <div class="std-side-padding upSelling">
    <v-row :class="type !== 'Fiber' ? 'mt-12' : ''" v-if="!loading">
      <v-col>
        <h2 class="upSelling__title">Seleziona profilo</h2>
        <p v-if="onlyClientsOfType === 'RES'">
          L’offerta fibra è al momento riservata ai soli clienti residenziali
        </p>
      </v-col>
    </v-row>
    <v-row v-if="loading || !accountClients"> Loading </v-row>
    <v-row v-else>
      <v-col>
        <ExtensibleButtonGroup
          v-model="activeClient"
          :per-row="type !== 'Fiber' ? 3 : 2"
          :content="true"
          :hide-content-on-blur="false"
        >
          <ExtensibleButton
            v-for="n in clients"
            :key="n.clientOwnerCode"
            :name="n.clientOwnerCode"
            :label="$filters.clientName(n)"
          />
          <ExtensibleButton
            name="newClient"
            label-color="#12256A;"
            label="Aggiungi nuovo intestatario"
            v-if="newClientAllowed"
          >
            <v-row>
              <v-col>
                <h2 class="upSelling__title">Tipologia intestatario</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ExtensibleButtonGroup
                  v-model="selectedType"
                  :per-row="2"
                  :content="false"
                  :hide-content-on-blur="false"
                >
                  <ExtensibleButton
                    v-for="type in types"
                    :key="type.value"
                    :name="type.value"
                    :label="type.name"
                  />
                </ExtensibleButtonGroup>
                <v-row justify="center">
                  <v-btn
                    class="v-btn--shadowed"
                    x-large
                    color="primary"
                    :disabled="!selectedType"
                    @click="$emit('nextStep', { newClient: selectedType })"
                    >Scopri la tua offerta</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </ExtensibleButton>
        </ExtensibleButtonGroup>
      </v-col>
    </v-row>
    <v-row class="upSelling__button-block" justify="center" v-if="!loading">
      <v-btn
        v-if="activeClient !== 'newClient'"
        class="v-btn--shadowed"
        x-large
        color="primary"
        :disabled="!activeClient"
        @click="$emit('nextStep', { client: activeClient })"
        >Scopri la tua offerta</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters } from 'vuex'
import ExtensibleButton from '../../components/extensibleButton/ExtensibleButton'
import ExtensibleButtonGroup from '../../components/extensibleButton/ExtensibleButtonGroup'

export default {
  name: 'ClientsComponent',
  components: {
    ExtensibleButtonGroup: ExtensibleButtonGroup,
    ExtensibleButton: ExtensibleButton
  },
  props: {
    type: { type: String },
    newClientAllowed: { type: Boolean, default: true },
    onlyClientsOfType: { type: String }
  },
  data: () => ({
    activeClient: undefined,
    selectedType: undefined,
    loading: true,
    types: [
      { name: 'Residenziale', value: 'RES' },
      { name: 'Business', value: 'BUS' }
    ]
  }),
  beforeMount() {
    this.getClientsForAccount()
      .then((resp) => {
        if (resp) {
          this.clientsHaveSupplies()
        }
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  mounted() {
    this.trackTapEvent({ name: 'commodityUpsell_selectClient_view' })
    if (this.type !== 'Fiber') {
      history.pushState({ step: 'client' }, null, null)
      this.loading = false
    } else {
      this.loading = true
      if (this.clients && this.clients.length > 1) {
        history.pushState({ step: 'client' }, null, null)
        this.loading = false
      } else {
        this.activeClient = this.clients[0].clientOwnerCode
        this.$emit('nextStep', { client: this.activeClient })
      }
    }
  },
  computed: {
    clients() {
      // if (this.type === this.supplyType) return []
      if (this.type === 'Fiber') {
        if (
          !this.$store.getters['account/accountProperties'] ||
          !this.$store.getters['account/accountProperties']
            .fiberEligibleSupplies
        )
          return

        let clientsEligibleFiber = {}
        const fiberEligibleSupplies =
          this.$store.getters['account/accountProperties'].fiberEligibleSupplies
        fiberEligibleSupplies.forEach((supply) => {
          if (supply.clientOwnerType === 'RES' && supply.fiberCoverageStatus) {
            clientsEligibleFiber[supply.clientOwnerCode] = supply
          }
        })
        return Object.values(clientsEligibleFiber)
      }

      // if (this.onlyClientsOfType && this.clientsWithSupplies) {
      //   let clientsArr = Object.values(this.clientsWithSupplies)
      //   return clientsArr.filter((client) => client.clientOwnerType === this.onlyClientsOfType)
      // }

      return this.clientsWithSupplies
    },
    ...mapGetters('account', ['accountClients']),
    ...mapGetters('supply', ['supplies', 'clientsWithSupplies', 'supplyType'])
  },
  methods: {
    ...mapActions('account', ['getClientsForAccount']),
    ...mapActions('supply', ['clientsHaveSupplies']),
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>
