<template>
  <layout>
    <v-row>
      <carousel
        :perPage="1"
        paginationColor="#CFCFCF"
        paginationActiveColor="#FFFFFF"
        :paginationPadding="5"
        class="carousel"
        :value="0"
        easing="ease-in"
        :speed="750"
        @page-change="pageChange"
      >
        <slide
          v-for="(item, i) in actions"
          :key="item.id"
          :class="
            isActive && i === 0 ? 'VueCarousel-slide-active' : '' + 'slide'
          "
        >
          <v-col>
            <div class="block">
              <div class="block__item">
                <div class="card v-card v-card--rounded pl-10">
                  <v-row class="mt-7">
                    <v-col class="pb-0">
                      <h1 class="font-weight-bold mb-2">{{ item.title }}</h1>
                      <h3 class="font-weight-bold mb-2">{{ item.subTitle }}</h3>
                    </v-col>
                  </v-row>
                  <v-row v-for="image in item.items" :key="image.filename">
                    <v-col cols="2">
                      <img
                        width="69"
                        height="69"
                        :src="require('@/assets/' + image.filename)"
                      />
                    </v-col>
                    <v-col
                      align-self="center"
                      cols="10"
                      class="pl-0 card__content"
                      ><div v-html="image.textHtml"></div
                    ></v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </slide>
        <slide class="slide">
          <v-col>
            <div class="block">
              <div class="block__item">
                <div class="card v-card v-card--rounded pl-10">
                  <v-row class="mt-7">
                    <v-col class="pb-0">
                      <h1 class="font-weight-bold mb-2">
                        I 5 Livelli Greener!
                      </h1>
                      <h3 class="font-weight-bold mb-2">
                        Ti basta completare 4 Missioni per passare al Livello
                        successivo. Ad ogni Livello ti aspetta un premio.
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row class="mb-8">
                    <v-col cols="11" class="pa-0">
                      <v-img src="@/assets/greeners/scale-livelli.png" />
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </slide>
        <slide class="slide">
          <v-col>
            <div class="block">
              <div class="block__item">
                <div class="card v-card v-card--rounded pl-9 carbon">
                  <v-row class="mt-7">
                    <v-col align-self="center" class="cloud">
                      <img
                        width="107"
                        height="103"
                        src="@/assets/ic_cfp.png"
                        alt="carbon"
                        class="carbon__img"
                      />
                      <p class="footprint">Carbon<br />Footprint</p>
                    </v-col>
                  </v-row>
                  <v-row class="carbon__info">
                    <v-col cols="5" class="pb-0 pl-0">
                      <h2 class="font-weight-bold mb-2 carbon__info__title">
                        Facci vedere chi sei!
                      </h2>
                      <p class="mb-2 carbon__info__descr">
                        Calcola il tuo <strong>Carbon Footprint!</strong><br />
                        Sali <strong>subito</strong> di Livello e guadagna
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="carbon__coins">
                    <v-col cols="3" class="pl-0 pr-0">
                      <h2 class="font-weight-bold carbon__coins__value">
                        1.500
                      </h2>
                      <h3 class="font-weight-bold carbon__coins__text">
                        Green Coins
                      </h3>
                    </v-col>
                    <v-col class="pl-0 pt-4" cols="2">
                      <v-img
                        width="48"
                        :src="require('@/assets/greeners/svg/greeners.svg')"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </slide>
      </carousel>
    </v-row>
    <v-row class="desc pt-10" justify="center">
      <v-btn
        class="v-btn--shadowed"
        x-large
        width="360"
        @click="
          trackTapEvent({
            name: 'greeners_howWorksContinue_tap',
            params: {
              clientCode: mainClientLoyaltySelected.clientOwnerCode,
              clientType: mainClientLoyaltySelected.clientOwnerType
            }
          }),
            $router.push({ name: 'joinTeamPage' })
        "
      >
        Vai al diario
      </v-btn>
    </v-row>
  </layout>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import { mapActions, mapGetters } from 'vuex'
import Layout from './../landing/LandingLayout'

// TODO That was a test page, so maybe delete it
export default {
  name: 'BecomeGreenerPage1',
  components: {
    Layout,
    Carousel,
    Slide
  },
  data: () => ({
    actions: [
      {
        title: 'I green coins',
        subTitle:
          'Stare insieme ti farà guadagnare Green Coins, mese dopo mese!',
        items: [
          {
            filename: 'greeners/svg/greeners.svg',
            textHtml:
              '<p class="mt-2">Guadagna <strong>Green Coins</strong> e richiedi i premi del catalogo.</p>'
          },
          {
            filename: 'greeners/ic-contract@2x.png',
            textHtml:
              '<p class="mt-2">Ogni mese, ottieni <strong>Green Coins</strong> grazie ai tuoi contratti attivi.</p>'
          },
          {
            filename: 'greeners/ic-gas-contract@2x.png',
            textHtml:
              '<p class="mt-2">Per gli utenti GAS, altri Green Coins per ogni autolettura.</p>'
          }
        ]
      },
      {
        title: 'Le Missioni',
        subTitle: 'Ogni mese una nuova sfida.',
        items: [
          {
            filename: 'greeners/ic-survey@2x.png',
            textHtml:
              '<h4 class="font-weight-bold" >Dicci la tua</h4><p class="mt-2">Rispondi a semplici domande per <strong>condividere</strong> con noi <br> quello che pensi</p>'
          },
          {
            filename: 'greeners/ic-video@2x.png',
            textHtml:
              '<h4 class="font-weight-bold">Guarda i video</h4><p class="mt-2"><strong>Guarda</strong>, impara e vota i contenuti dei nostri contenuti.</p>'
          },
          {
            filename: 'greeners/ic-share@2x.png',
            textHtml:
              '<h4 class="font-weight-bold">Condividi i tuoi momenti</h4><p class="mt-2">Carica le foto dei tuoi momenti durante le iniziative <br> dei <strong>Greeners</strong>.</p>'
          }
        ]
      }
    ],
    value: 0,
    isActive: true
  }),
  computed: {
    ...mapGetters('account', ['clientDetails', 'mainClientLoyaltySelected'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    pageChange() {
      this.isActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel {
  width: 100%;
  .block {
    &__item {
      width: 40%;
      height: 490px;
      transform: scaleY(0.8);
      margin: auto;
      display: flex;
      justify-content: center;
      transition: transform 750ms 250ms;
    }
    .card {
      width: 100%;
      background: white;
      overflow: hidden;
      &__content {
        color: #000000;
        font-size: 18px;
        line-height: 22px;
      }
    }
    .carbon {
      &__info {
        margin-left: 50px;
        &__title {
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
        }
        &__descr {
          font-size: 16px;
          line-height: 22px;
        }
      }
      &__coins {
        margin-left: 50px;
        &__value {
          font-size: 52px;
          line-height: 55px;
        }
        &__text {
          font-size: 24px;
          line-height: 18px;
        }
      }
      &__img {
        position: absolute;
        top: -55px;
        left: -40px;
      }
      .footprint {
        font-weight: 500;
        font-size: 36px;
        line-height: 38px;
      }
      .cloud {
        position: relative;
        margin: 65px 0 0 50px;
        padding: 0;
      }
    }
  }
  .VueCarousel-slide.slide {
    transform: translate(50%, 0);
    transition: transform 750ms ease-in;
  }

  .VueCarousel-slide-active {
    & ~ .VueCarousel-slide.slide {
      transform: translate(-50%, 0);
    }
    transform: translate(0, 0) !important;
    .block {
      &__item {
        transform: scaleY(1);
      }
    }
  }
}
.desc {
  width: 100%;
  color: #ffffff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  a {
    color: #000000;
  }
}
</style>
