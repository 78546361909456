/* eslint-disable no-dupe-class-members */
import store from '@/store/index'
import tcWrapper from 'vue-tag-commander'
import { ENV_CONFIGS } from '../configs'
// import { eventBus } from '../../main'

export default class TcVarService {
  #props = null

  constructor(props) {
    this.#props = props
    this.#init()
  }

  async tcTrackTapEvent(event = {}) {
    if (!ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
      let trackData = Object.assign(
        store.getters['analytics/userProps'],
        this.#props
      )
      trackData = this.#toLowerCase(trackData)
      window.tc_wrapper.setTcVars(trackData)
      window.tc_wrapper.reloadAllContainers()
      // eventBus.$emit('tcVarReady')
      if (event.event_name && window.tC) {
        window.tC.event.genericEvent(this, event)
      }

      if (window.tC) {
        window.tC.event.virtualPage()
      }
    }
  }

  #init() {
    if (!ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
      if (!window.tc_wrapper) {
        window.tc_wrapper = tcWrapper.getInstance()
        if (ENV_CONFIGS.ENV === 'pro') {
          window.tc_wrapper.setDebug(false)
          window.tc_wrapper.addContainer(
            'container_body',
            'https://cdn.tagcommander.com/3119/tc_Sorgenia_mysorgenia.js',
            'body'
          )
        } else {
          window.tc_wrapper.setDebug(true)
          window.tc_wrapper.addContainer(
            'container_body',
            'https://cdn.tagcommander.com/3119/uat/tc_Sorgenia_mysorgenia.js',
            'body'
          )
        }
      }
    }
  }

  #toLowerCase(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && !!obj[key]) {
        obj[key] = this.#toLowerCase(obj[key])
      } else if (typeof obj[key] === 'string') {
        obj[key] = obj[key].toLowerCase().trim()
      }
    }) // Tag Commander requires all trimmed and lowercased
    return obj
  }
}
