<template>
  <div class="pt-10" v-if="loaded">
    <v-dialog max-width="660" v-model="speedUpDialog">
      <div class="summarySpeedUpDialog">
        <img
          @click="speedUpDialog = false"
          src="@/assets/ic_chevron_top_black.png"
        />
        <div class="summarySpeedUpDialog__title">Diritto di ripensamento</div>
        <div class="summarySpeedUpDialog__subtitle">
          Selezionando questa opzione richiedi di avviare anticipatamente la
          procedura per l’attivazione della tua fornitura prima del termine per
          l’esercizio del diritto di ripensamento, secondo quanto previsto
          dall’art. 5 delle Condizioni Generali di Contratto. Ricordati che
          potrai comunque esercitare il tuo diritto di ripensamento entro i
          termini previsti per legge (14 giorni dalla conclusione del contratto)
          ma, qualora Sorgenia avesse già avviato la fornitura e/o sostenuto dei
          costi per il suo avvio, sarai tenuto a corrisponderli ai sensi del
          Contratto.
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="activationDialog" max-width="775">
      <div class="dialog-modal">
        <h2 class="dialog-modal__title" v-if="!isAnticipata">
          La tua richiesta di anticipare l’attivazione è stata ricevuta, grazie!
        </h2>
        <h2 class="dialog-modal__title" v-if="isAnticipata">
          La tua richiesta di anticipare l’attivazione è già in lavorazione.
        </h2>
        <h4 class="dialog-modal__text pt-6">
          A breve aggiorneremo la pagina con la nuova data prevista
        </h4>
        <div class="dialog-modal__continue d-flex justify-center">
          <v-btn @click="goHome" color="primary" large class="v-btn--shadowed">
            OK
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="contactsDialog.show" max-width="555">
      <div class="dialog-modal">
        <h2 class="dialog-modal__title_small">
          Stai per annullare l’appuntamento!
        </h2>
        <h4 class="dialog-modal__text pt-6">
          Nei prossimi giorni ti contatterà un operatore per organizzare un
          nuovo appuntamento.
        </h4>
        <h4 class="dialog-modal__text pt-6">
          <strong>Sei sicuro di voler annullare l’appuntamento?</strong>
        </h4>
        <div class="dialog-modal__continue d-flex justify-space-between">
          <v-btn
            color="white"
            width="220"
            x-large
            class="v-btn--shadowed"
            @click="contactsDialog.show = false"
          >
            Annulla
          </v-btn>
          <v-btn
            @click="nonDisponsibileButtonClick"
            width="220"
            color="primary"
            large
            class="v-btn--shadowed"
          >
            Conferma
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <v-row no-gutters class="std-side-padding">
      <v-col>
        <v-row>
          <v-col class="d-flex">
            <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
              <template v-slot:divider>
                <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-10">
            <h1 class="font-weight-bold">Attivazione fibra</h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3 class="font-weight-medium">{{ title }}</h3>
          </v-col>
        </v-row>

        <v-row v-if="subTitle">
          <v-col>
            <h4 class="font-weight-medium" v-html="subTitle"></h4>
          </v-col>
        </v-row>

        <!-- <v-row v-if="fiberAfterEleDisclamer">
          <v-col>
            <h4>Attenzione! L'attivazione della fibra arriverà solo a seguito dell'attivazione dell'utenze luce</h4>
          </v-col>
        </v-row> -->

        <template v-if="state === 1">
          <v-row>
            <v-col>
              <div class="std-panel std-panel--shadowed">
                <v-row no-gutters>
                  <v-col class="ml-4">
                    <DateBlock :date-text="dateBlockText" />
                  </v-col>
                  <v-col class="mr-4" v-if="canAnticipate">
                    <v-col>
                      <h5 class="font-weight-bold">Vuoi anticiparla?</h5>
                      <h5 class="font-weight-light">
                        Selezionando questa opzione richiedi di avviare
                        anticipatamente la procedura per l’attivazione della tua
                        fornitura…
                        <a
                          @click="speedUpDialog = true"
                          class="scopri-di-piu font-weight-bold"
                          >Scopri di più</a
                        >
                      </h5>
                    </v-col>
                  </v-col>
                </v-row>
                <div
                  class="anticipa_button d-flex mt-5 font-weight-bold"
                  v-if="canAnticipate"
                >
                  <a v-if="!sendingAnticipationRequest" @click="anticipaClick"
                    >Anticipa l'attivazione</a
                  >
                  <v-progress-circular
                    v-if="sendingAnticipationRequest"
                    :size="40"
                    indeterminate
                    style="margin: auto"
                  ></v-progress-circular>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>

        <!-- mostrare questo bottone se isCancellable è true -->
        <template v-if="state >= 2 && state <= 4">
          <DateBlock
            :dateText="dateBlockText"
            :descriptionText="dateBlockDescriptionText"
            :showButton="showNonDisponsibileButton"
            :disabled="disabledNonDisponibileButton"
            :onClick="showDialogNonDisponsibile"
          />
        </template>
      </v-col>
    </v-row>

    <TrackingPanel
      class="mt-5"
      :address="address"
      :state="state"
      :stateHeaders="stateHeaders"
      :stateDates="stateDates"
      :isSwitchIn="timeline.tipoRichiesta === 'Switch in Fibra'"
      :hasShipping="hasShipping"
    />
  </div>
</template>

<script>
import router from '@/router/router'

import TrackingPanel from '../../components/tracking/TrackingPanel'
import DateBlock from '../../components/tracking/DateBlock'
import GenErr from '@/js/genericErrors'
import { mapActions } from 'vuex'
import {
  formatDate,
  formatDateItaly,
  formatTrackingTimelineDate
} from '../../js/utils'
import { getSupplyTrackingParams } from '@/js/trackingUtils'

export default {
  name: 'TrackingFiber',
  data() {
    return {
      hasShipping: false,
      fiberAfterEleDisclamer: false,
      loaded: false,
      state: 1,
      stateHeaders: [
        'Sottoscrizione',
        'Avvio processo di attivazione',
        'Allacciamento',
        'Fibra attivata'
      ],
      stateDates: ['', '', '', ''],
      activationDialog: false,
      speedUpDialog: false,
      sendingAnticipationRequest: false,
      contactsDialog: {
        show: false
      },
      clientDetail: {},
      timeline: {
        codiceClienteIntestatario: '',
        codiceProposta: '',
        codicePunto: '',
        dataPresaCarico: '',
        dataPresuntaAttivazione: '',
        dataSottoscrizione: '',
        dataAttivazione: '',
        dataAppuntamento: '',
        oraAppuntamento: '',
        idStato: '0',
        stato: '',
        annullamento: ''
      },
      supplyData: {}
    }
  },
  methods: {
    ...mapActions('tracking', [
      'getTimeline',
      'getTimelineComm',
      'getTimelineMock',
      'nonDisponsibile',
      'getEarlyActivation'
    ]),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('supply', ['getSupplies', 'getDetail']),
    ...mapActions('interactions', ['getChannelInfo']),
    ...mapActions('analytics', ['trackTapEvent']),
    goHome() {
      router.push({ name: 'home' })
    },
    anticipaClick() {
      this.trackTapEvent({
        name: 'supplyTracking_refuseWithdrawal_tap',
        params: getSupplyTrackingParams(this.supplyData)
      })

      if (!this.isAnticipata) {
        this.sendingAnticipationRequest = true
        this.getEarlyActivation({
          clientDetail: this.clientDetail,
          timeline: this.timeline,
          date: formatDate()
        })
          .then((res) => {
            this.activationDialog = res
            this.sendingAnticipationRequest = false
          })
          .catch((error) => {
            GenErr.handleGenericError(error && error.message)
            this.sendingAnticipationRequest = false
          })
      } else {
        this.activationDialog = true
      }
    },
    nonDisponsibileButtonClick() {
      this.trackTapEvent({
        name: 'supplyTracking_cancelAppoint_tap',
        params: getSupplyTrackingParams(this.supplyData)
      })

      this.nonDisponsibile({
        clientDetail: this.clientDetail,
        timeline: this.timeline,
        date: formatDateItaly()
      }).then(() => {
        let appuntamentoNonDisponibile = JSON.parse(
          localStorage.getItem('appuntamentoNonDisponibile') || null
        )
        appuntamentoNonDisponibile = appuntamentoNonDisponibile || {}
        appuntamentoNonDisponibile[this.timeline.codiceProposta] =
          this.timeline.dataAppuntamento
        localStorage.setItem(
          'appuntamentoNonDisponibile',
          JSON.stringify(appuntamentoNonDisponibile)
        )
      })

      this.contactsDialog.show = false
      this.timeline.annullamento = true
    },
    showDialogNonDisponsibile() {
      this.contactsDialog.show = true
    }
  },
  async beforeMount() {
    const identifyState = (timeline) => {
      if (timeline) {
        this.timeline = timeline
        this.stateDates = [
          formatTrackingTimelineDate(this.timeline.dataSottoscrizione),
          formatTrackingTimelineDate(this.timeline.dataPresaCarico),
          formatTrackingTimelineDate(this.timeline.dataAttivazione),
          formatTrackingTimelineDate(this.timeline.dataAttivazione)
        ]

        switch (timeline.step) {
          case 'subscribed': {
            this.state = 1
            break
          }
          case 'startOfWork': {
            this.state = 2
            break
          }
          case 'workInProgress': {
            this.state = 3
            break
          }
          case 'activated': {
            this.state = 4
            break
          }
          default: {
            this.state = 1
            break
          }
        }

        if (
          timeline.tipoRichiesta === 'Switch in Fibra' ||
          this.supplyData.fiberType === 'FC'
        ) {
          // this.connectionType === 'FTTC' &&
          switch (timeline.shippingStatus) {
            case '' || undefined:
              if (this.state === 4) {
                this.stateHeaders.splice(2, 1, 'Consegnato')
              } else {
                this.stateHeaders.splice(2, 1, 'Modem in spedizione') // da capire se il default è 'Modem in spedizione' o 'Spedizione Pronta'
              }
              break
            case 'Spedizione pronta':
              if (timeline.trackingLink) {
                this.stateHeaders.splice(
                  2,
                  1,
                  'Spedizione pronta <br> <a target="_blank" href="' +
                    timeline.trackingLink +
                    '">Monitora spedizione</a>'
                )
              } else {
                if (this.state === 4) {
                  this.stateHeaders.splice(2, 1, 'Consegnato')
                } else {
                  this.stateHeaders.splice(2, 1, 'Spedizione pronta')
                }
              }
              this.hasShipping = true
              break
            case 'Consegnato':
              if (timeline.trackingLink) {
                this.stateHeaders.splice(
                  2,
                  1,
                  'Consegnato <br> <a target="_blank" href="' +
                    timeline.trackingLink +
                    '">Monitora spedizione</a>'
                )
              } else {
                this.stateHeaders.splice(2, 1, 'Consegnato')
              }
              this.hasShipping = true
              break
            default:
              if (timeline.trackingLink) {
                this.stateHeaders.splice(
                  2,
                  1,
                  'Modem in spedizione <br> <a target="_blank" href="' +
                    timeline.trackingLink +
                    '">Monitora spedizione</a>'
                )
              } else {
                if (this.state === 4) {
                  this.stateHeaders.splice(2, 1, 'Consegnato')
                } else {
                  this.stateHeaders.splice(2, 1, 'Modem in spedizione')
                }
              }
              this.hasShipping = true
              break
          }
        }
      }
    }

    let data
    if (this.$route.params.supplyCode.startsWith('mock')) {
      // Dev stuff
      data = await this.getTimelineMock()
      let found = data.find((v) => {
        return v.codicePunto === this.$route.params.supplyCode
      })
      identifyState(found)
      this.fiberAfterEleDisclamer = found.activatingCommodityOnSameAddress
      this.loaded = true
    } else {
      this.getTimeline().then((data) => {
        const timelines = data.timelineFibra
        if (timelines.length > 0) {
          let fiber = timelines.find((v) => {
            return v.codicePunto === this.$route.params.supplyCode
          })
          this.getSupplies().then((supplyList) => {
            if (supplyList.length > 0) {
              this.supplyData = supplyList.find((v) => {
                return v.supplyCode === fiber.codicePunto
              })
              this.loaded = true
            } else {
              this.loaded = true
            }
          })
          identifyState(fiber)
          this.fiberAfterEleDisclamer =
            !!this.timeline.activatingCommodityOnSameAddress
        } else {
          this.loaded = true
        }
      })
    }

    this.getDetailForClient({
      clientOwnerCode: this.$route.params.clientOwnerCode
    }).then((resp) => {
      this.clientDetail = resp
    })
  },
  computed: {
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Stato attivazione'
        }
      ]

      return res
    },
    connectionType() {
      let connectionType = 'FTTH'
      if (
        this.timeline.connectionType === 'VDSL' ||
        this.timeline.connectionType === 'EVDSL'
      ) {
        connectionType = 'FTTC'
      }
      return connectionType
    },
    isAnticipata() {
      let anticipationRequests = JSON.parse(
        localStorage.getItem('anticipationRequests')
      )
      return (
        anticipationRequests &&
        anticipationRequests[this.timeline.codiceProposta]
      )
    },
    canAnticipate() {
      let dataSottoscriz = new Date(this.timeline.dataSottoscrizione)
      let dataScadRipensamento = new Date(
        dataSottoscriz.getTime() + 14 * 24 * 60 * 60 * 1000
      )

      if (!this.timeline) return false

      if (new Date() > dataScadRipensamento) return false

      return true
    },
    dateBlockText() {
      let text = ''
      if (
        this.connectionType === 'FTTC' &&
        this.timeline.tipoRichiesta === 'Switch in Fibra'
      ) {
        text =
          'Data prevista di attivazione: ' +
          formatTrackingTimelineDate(this.timeline.dataPresuntaAttivazione)
      } else if (this.disabledNonDisponibileButton && this.state <= 2) {
        text = 'Appuntamento annullato!'
      } else if (this.state <= 2) {
        if (this.isAnticipata) {
          text =
            'Data di attivazione anticipata: ' +
            formatTrackingTimelineDate(this.timeline.dataPresuntaAttivazione)
        } else if (this.disabledNonDisponibileButton) {
          text = 'Appuntamento annullato!'
        } else if (
          this.timeline.dataAppuntamento &&
          this.timeline.oraAppuntamento
        ) {
          text =
            'Data di appuntamento concordata: ' +
            formatTrackingTimelineDate(this.timeline.dataAppuntamento) +
            (this.timeline.oraAppuntamento !== '00:00'
              ? ' dalle ' + this.timeline.oraAppuntamento
              : '')
        } else {
          text =
            'Data prevista di attivazione: ' +
            formatTrackingTimelineDate(this.timeline.dataPresuntaAttivazione)
        }
      } else {
        text =
          'Data prevista di attivazione: ' +
          formatTrackingTimelineDate(this.timeline.dataPresuntaAttivazione)
      }
      return text
    },
    dateBlockDescriptionText() {
      if (this.state === 2) {
        if (this.disabledNonDisponibileButton) {
          return 'Nei prossimi giorni ti contatterà un operatore per organizzare un nuovo appuntamento.'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    showNonDisponsibileButton() {
      return !!this.timeline.isCancellable
    },
    disabledNonDisponibileButton() {
      let appuntamentoNonDisponibile =
        JSON.parse(localStorage.getItem('appuntamentoNonDisponibile')) || null
      if (
        appuntamentoNonDisponibile &&
        appuntamentoNonDisponibile[this.timeline.codiceProposta]
      ) {
        return (
          !!this.timeline.annullamento ||
          appuntamentoNonDisponibile[this.timeline.codiceProposta] ===
            this.timeline.dataAppuntamento
        )
      }
      return !!this.timeline.annullamento
    },
    title() {
      if (this.state === 1) {
        return 'Non devi fare nulla, pensiamo a tutto noi!'
      } else if (this.state === 2) {
        if (this.timeline.oraAppuntamento) {
          let currentDate = new Date()
          let date = new Date(this.timeline.dataAppuntamento)
          if (
            currentDate.getFullYear() === date.getFullYear() &&
            currentDate.getMonth() === date.getMonth() &&
            currentDate.getDate() === date.getDate()
          ) {
            return 'Il tecnico sarà da te oggi'
          } else {
            return 'Appuntamento per attivazione'
          }
        } else {
          return 'Stiamo arrivando!'
        }
      } else if (this.state === 3) {
        return 'Stiamo arrivando!'
      } else {
        return 'Perfetto! Ora la nostra fibra è attiva.'
      }
    },
    subTitle() {
      if (this.state === 1) {
        if (this.fiberAfterEleDisclamer) {
          return "Attenzione! L'attivazione della fibra arriverà solo a seguito dell'attivazione dell'utenze luce"
        } else {
          return 'A breve un nostro operatore ti contatterà chiedendoti la tua disponibilità per l’allacciamento della fibra.'
        }
      } else if (this.state === 2) {
        if (!this.timeline.oraAppuntamento) {
          return 'A breve un nostro operatore ti contatterà chiedendoti la tua disponibilità per l’allacciamento della fibra.'
        } else {
          if (!this.disabledNonDisponibileButton) {
            if (this.hasShipping) {
              return 'Stiamo preparando il tuo modem Sorgenia che riceverai direttamente a casa entro la data di attivazione.<br>Appena il modem sarà pronto riceverai qui sotto il link per monitarere la spedizione'
            } else {
              return 'Il giorno stesso la tua Fibra sarà attiva. Manca poco!'
            }
          } else {
            return ''
          }
        }
      } else if (this.state === 3) {
        let currentDate = new Date()
        let date = new Date(this.timeline.dataAppuntamento)
        if (
          currentDate.getFullYear() === date.getFullYear() &&
          currentDate.getMonth() === date.getMonth() &&
          currentDate.getDate() === date.getDate()
        ) {
          return 'Ricordati di aprirgli :)'
        } else {
          if (this.timeline.shippingStatus !== 'Consegnato') {
            return 'Stiamo preparando il tuo modem Sorgenia che riceverai direttamente a casa entro la data di attivazione.<br>Appena il modem sarà pronto riceverai qui sotto il link per monitarere la spedizione'
          } else {
            return ''
          }
        }
      } else {
        if (this.timeline.tipoRichiesta === 'Switch in Fibra') {
          return 'Non dimenticare di disdire eventuali altri contratti Internet.'
        } else {
          return ''
        }
      }
    },
    address() {
      return this.supplyData.street + ', ' + this.supplyData.city
    }
  },
  components: {
    TrackingPanel,
    DateBlock
  }
}
</script>

<style lang="scss" scoped>
.dialog-modal {
  background: white;
  border-radius: 40px;
  padding: 48px 48px 48px 48px;

  &__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 1.1em !important;
  }

  &__title_small {
    font-weight: bold;
    font-size: $title-font-size;
  }

  &__text {
    font-size: 20px;
  }

  &__continue {
    font-weight: bold;
    font-size: $std-font-size;
    margin-top: 24px;

    button {
      width: 264px;
    }
  }
}

.std-panel {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.anticipa_button {
  border-top: 1px solid $srg-lightgray;
  height: 70px;

  a {
    margin: auto;
    color: $srg-blue;
    font-size: 18px;
  }
}

.scopri-di-piu {
  color: $srg-blue;
  font-size: 16px;
}
</style>
