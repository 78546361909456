<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <button
          type="button"
          class="loyalty__header-back mb-2"
          @click="
            trackTapEvent({
              name: 'greeners_rulestBack_tap',
              params: {
                clientCode: mainClientLoyaltySelected.clientOwnerCode,
                clientType: mainClientLoyaltySelected.clientOwnerType
              }
            }),
              $router.push({ name: 'loyaltyPage' })
          "
        >
          <v-icon size="30">mdi-chevron-left</v-icon>
          <span>Torna al Diario</span>
        </button>
        <h1>Regolamento</h1>
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <v-row v-if="!isCancel" class="mt-11">
      <v-col cols="4">
        <a
          :href="linkRegolamento"
          target="_blank"
          download
          @click="
            trackTapEvent({
              name: 'greeners_rulesRules_tap',
              params: {
                clientCode: mainClientLoyaltySelected.clientOwnerCode,
                clientType: mainClientLoyaltySelected.clientOwnerType
              }
            })
          "
        >
          <div class="downloading">
            <v-row>
              <v-col cols="2" class="pr-0 mt-2">
                <v-img width="24" src="../../../assets/icons/ic_bill.svg" />
              </v-col>
              <v-col cols="8" class="pl-0 mt-2" align-self="center"
                ><h4>Regolamento</h4></v-col
              >
              <v-col cols="2" class="mt-2"
                ><v-img
                  width="20"
                  src="../../../assets/ic_download_small_blue.svg"
              /></v-col>
            </v-row>
          </div>
        </a>
      </v-col>
      <v-col cols="4">
        <a
          :href="linkConcorso"
          target="_blank"
          download
          @click="
            trackTapEvent({
              name: 'greeners_rulesContest_tap',
              params: {
                clientCode: mainClientLoyaltySelected.clientOwnerCode,
                clientType: mainClientLoyaltySelected.clientOwnerType
              }
            })
          "
        >
          <div class="downloading">
            <v-row>
              <v-col cols="2" class="pr-0 mt-2">
                <v-img width="24" src="../../../assets/icons/ic_bill.svg" />
              </v-col>
              <v-col cols="8" class="pl-0 mt-2" align-self="center"
                ><h4>Concorso</h4></v-col
              >
              <v-col cols="2" class="mt-2"
                ><v-img
                  width="20"
                  src="../../../assets/ic_download_small_blue.svg"
              /></v-col>
            </v-row>
          </div>
        </a>
      </v-col>
      <v-col
        cols="4"
        v-if="
          memberStatus.statusGreeners !== 'Pending' &&
          memberStatus.statusGreeners !== 'Nessuno'
        "
      >
        <v-btn
          class="v-btn--shadowed normal mb-4"
          color="white"
          height="66"
          width="360"
          @click="confirmDialog = true"
        >
          Disiscriviti
        </v-btn>
      </v-col>
    </v-row>
    <div v-else>
      <v-row class="mt-12">
        <v-col class="pb-0">
          <h2>Perchè vuoi abbandonare il programma Greeners?</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ExtensibleButtonGroup v-model="selectedProgram" :per-row="3"
            ><ExtensibleButton
              v-for="(item, i) in items"
              :key="i"
              :name="item.name"
              :label="item.label"
            />
          </ExtensibleButtonGroup>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
          class="v-btn--shadowed mb-4"
          color="brightGreen"
          :disabled="!selectedProgram"
          @click="describe"
          x-large
          width="265"
        >
          Conferma Disiscrizione
        </v-btn>
      </v-row>
    </div>
    <v-dialog overlay-opacity="0.6" v-model="confirmDialog" max-width="660">
      <v-card class="rules-dialog">
        <v-row>
          <v-col class="pb-0" align="right">
            <img
              class="rules-dialog__img"
              @click="confirmDialog = false"
              src="@/assets/ic_chevron_top_black.png"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <h2 class="rules-dialog__title">
              Sei sicuro di volerti disiscrivere dal programma Greeners?
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="rules-dialog__text">
              Avrai comunque la possibilità di ritrovare tutti i tuoi punti e
              redimere i premi non ancora richiesti se ti iscrivi nuovamente
              entro 6 mesi dalla disiscrizione.
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col cols="6">
            <v-btn
              class="v-btn--shadowed normal"
              color="white"
              x-large
              width="265"
              @click="
                ;(isCancel = true),
                  (confirmDialog = false),
                  trackTapEvent({
                    name: 'greeners_rulesRevokeSubscrip_tap',
                    params: {
                      clientCode: mainClientLoyaltySelected.clientOwnerCode,
                      clientType: mainClientLoyaltySelected.clientOwnerType
                    }
                  })
              "
            >
              Disiscriviti
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              class="v-btn--shadowed"
              color="brightGreen"
              x-large
              width="265"
              @click="
                trackTapEvent({
                  name: 'greeners_revokeSubscripCancel_tap',
                  params: {
                    clientCode: mainClientLoyaltySelected.clientOwnerCode,
                    clientType: mainClientLoyaltySelected.clientOwnerType
                  }
                }),
                  $router.push({ name: 'loyaltyPage' })
              "
            >
              Annulla
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Layout from './../Layout'
import { ENV_CONFIGS } from '@/js/configs'

export default {
  name: 'RulesPage',
  components: {
    Layout
  },
  data: () => ({
    selectedProgram: '',
    isCancel: false,
    confirmDialog: false,
    linkRegolamento: ENV_CONFIGS.DYNAMIC_LINKS.regolamentoGreeners,
    linkConcorso: ENV_CONFIGS.DYNAMIC_LINKS.concorsoGreeners,
    items: [
      { label: 'È troppo complicato', name: 'È troppo complicato' },
      { label: 'Non mi piacciono i premi', name: 'Non mi piacciono i premi' },
      {
        label: 'Non mi piacciono le Missioni',
        name: 'Non mi piacciono le Missioni'
      },
      {
        label: 'Ricevo troppe comunicazioni',
        name: 'Ricevo troppe comunicazioni'
      },
      { label: 'Non ho tempo', name: 'Non ho tempo' },
      { label: 'Altro', name: 'Altro' }
    ]
  }),
  computed: {
    ...mapGetters('account', ['clientDetails', 'mainClientLoyaltySelected']),
    ...mapGetters('loyalty', ['memberStatus'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('loyalty', ['revokeMember', 'setLoyaltyStatus']),
    ...mapActions('account', ['refreshMainClient']),
    ...mapMutations('account', ['setMainClientLoyaltySelected']),
    describe() {
      this.trackTapEvent({
        name: 'greeners_revokeSubscripReasonConfirm_tap',
        params: {
          clientCode: this.mainClientLoyaltySelected.clientOwnerCode,
          clientType: this.mainClientLoyaltySelected.clientOwnerType
        }
      })
      this.revokeMember({
        ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode,
        response: this.selectedProgram
      }).then((resp) => {
        // this.setLoyaltyStatus({ codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode, status_category: 'UNAWARE' })
        this.setMainClientLoyaltySelected(null)
        this.refreshMainClient()
        if (resp) this.$router.push({ name: 'rulesSuccess' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rules-dialog {
  padding: 0px 48px 20px 48px;

  &__img {
    padding-top: 30px;
  }

  &__title {
    font-size: 32px;
    line-height: 44px;
    font-weight: bold;
  }

  &__text {
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
  }
}

.downloading {
  border: 1px solid #cfcfcf;
  border-radius: 20px;
  height: 66px;
  width: 360px;

  h4 {
    font-weight: 500;
  }
}

.normal {
  letter-spacing: normal;
}
</style>
