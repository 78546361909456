import { render, staticRenderFns } from "./KoDialog.vue?vue&type=template&id=4d72765e&scoped=true"
import script from "./KoDialog.vue?vue&type=script&lang=js"
export * from "./KoDialog.vue?vue&type=script&lang=js"
import style0 from "./KoDialog.vue?vue&type=style&index=0&id=4d72765e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d72765e",
  null
  
)

export default component.exports