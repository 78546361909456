<template>
  <div class="video-player">
    <vue-plyr ref="player" :options="playerOptions">
      <video
        :src="videoFullUrl"
        :poster="previewFullUrl"
        v-on:play="trackPlay"
      />
    </vue-plyr>
  </div>
</template>

<script>
import { ENV_CONFIGS } from '@/js/configs.js'
import { mapActions } from 'vuex'

export default {
  name: 'indexComponent',
  props: {
    videoUrl: { type: String, default: '' },
    previewUrl: { type: String, default: null },
    autoplay: { type: Boolean, default: false },
    plyrSeekDisable: { type: Boolean, default: false },
    title: { type: String, require: false },
    id: { type: String, require: false }
  },
  data: () => ({
    tries: 0
  }),
  computed: {
    videoFullUrl() {
      return ENV_CONFIGS.CMS_CDN_BASE_URL + this.videoUrl
    },
    previewFullUrl() {
      if (!this.previewUrl) return null
      return ENV_CONFIGS.CMS_CDN_BASE_URL + this.previewUrl
    },
    player() {
      return this.$refs.player && this.$refs.player.player
    },
    playerOptions() {
      return {
        controls: ['play', 'progress', 'current-time', 'fullscreen', 'volume'] // ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    trackPlay() {
      if (this.id && this.title) {
        this.trackTapEvent({
          name: 'promo_video_start',
          params: { id: this.id, title: this.title }
        })
      }
    },
    play() {
      // this.$el.querySelector('.plyr__progress__container input').setAttribute('disabled', 'disabled')
      this.player.play().catch(() => {
        if (this.tries < 100) {
          this.tries++
          this.play()
        }
      })
    },
    close() {
      this.player.stop()
    }
  },
  mounted() {
    this.$watch(
      () => this.$refs.player.player,
      function (value) {
        // this.$refs.player.player.on('ready', this.play)
        this.$refs.player.player.on('ended', () => this.$emit('ended'))
      },
      {
        immediate: true
      }
    )
    if (this.autoplay) {
      this.play()
    }
    if (this.plyrSeekDisable) {
      this.player.on('play', () =>
        this.$el
          .querySelector('.plyr__progress__container input')
          .setAttribute('disabled', 'disabled')
      )
    }
  }
}
</script>

<style lang="scss">
$videoMainColor: $srg-green;
.video-player {
  position: relative;
  //border-radius: 40px;
  overflow: hidden;

  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded='true'] {
    box-shadow: 0 0 0 5px rgba(white, 0.3);
    background: #fff;
    color: $videoMainColor;
  }

  .plyr {
    .plyr__control--overlaid {
      width: 82px;
      height: 82px;
      // background: white;
      background: transparent;

      &:hover,
      &:focus {
        opacity: 0.8;
        background: white;
      }

      svg {
        color: inherit;
        width: 40px;
        height: 40px;
        transform: translate(7px, 0px);
      }
    }

    .plyr__controls {
      background: #55564f;
      padding: 7px 13px 7px 13px;
      left: 26px;
      right: 26px;
      bottom: 21px;
      border-radius: 16px;

      .plyr__progress__container {
        padding-left: 8.5px;

        .plyr__progress__buffer {
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
        }
      }

      .plyr__progress input[type='range'] {
        &::-webkit-slider-runnable-track {
          color: $videoMainColor;
        }

        &::-ms-track {
          color: $videoMainColor;
        }

        &::-moz-range-track {
          color: $videoMainColor;
        }
      }

      .plyr__progress {
        .plyr--full-ui input {
          color: $videoMainColor;
        }
      }

      .plyr__time {
        font-size: 11px;
        letter-spacing: 1.5px;
        font-weight: 400;
      }
    }

    .plyr video {
      background: white;
      max-height: 650px;
    }

    .plyr:fullscreen video {
      max-height: 100%;
    }
  }
}
</style>
