import { ENV_CONFIGS } from '@/js/configs.js'
import store from '@/store'
import axios from 'axios'
import router from '../router/router'

// Axios custom instance for API communication with the Accenture services
let axiosACN = axios.create()

axiosACN.defaults.baseURL = ENV_CONFIGS.ACN_BASE_URL

axiosACN.defaults.headers.common['Accept'] = 'application/json, text/plain, */*'
axiosACN.defaults.headers.common['Content-Type'] = 'application/json'
axiosACN.defaults.headers.common['Ocp-Apim-Subscription-Key'] =
  ENV_CONFIGS.ACN_SUB_KEY

const AUTH_HTTP_ERROR_CODES = [401, 403, 'ERR_NETWORK'] // aggiunto ERR_NETWORK per gestire le risposte senza Access-Control-Allow-Origin

/**
 * Refresh token interceptor. If getting an error related to authentication, try to refresh the token and re-run the failed API call.
 * If refreshToken fails, the session is lost and the user must be logged out.
 */
axiosACN.interceptors.response.use(
  async (response) => {
    // Restituisce una risposta di successo al servizio chiamante
    return response
  },
  async (error) => {
    // Restituisce eventuali errori diversi dall'autenticazione al servizio chiamante
    const status = error.response ? error.response.status : 'ERR_NETWORK' // default ERR_NETWORK in modo da riprovare refresh
    // Controlla se l'errore richiede un'autenticazione e se la chiamata non è già una richiesta di refresh token
    if (
      !AUTH_HTTP_ERROR_CODES.includes(status) ||
      ['login', 'superlogin'].includes(router.history.current.name)
    ) {
      return error.response
    }

    // Evita di tentare nuovamente il refresh token se la chiamata fallita è già una richiesta di refresh token
    if (error.config.url !== '/sorgenia/V5/refreshtoken') {
      try {
        const newAccessToken = await store.dispatch('session/refreshToken') // se refresh va a buon fine ...

        // ... Aggiorna l'header della richiesta originale con il nuovo token
        error.config.headers['Authorization'] = 'Bearer ' + newAccessToken
        error.config.headers['Ocp-Apim-Subscription-Key'] =
          ENV_CONFIGS.ACN_SUB_KEY
        error.config.headers['token_type'] = 'bearer'

        // Riprova la richiesta originale con il token aggiornato
        return axios.request(error.config)
      } catch (refreshError) {
        // altrimenti gestisci il caso in cui il refresh token fallisce (es. password scaduta)
        console.error(router.currentRoute)
        let options = { reason: 'SESSION_EXPIRED' }
        if (router.currentRoute.query.forwardURL) {
          options.forwardURL = router.currentRoute.query.forwardURL
        }
        store.dispatch('session/logout', options)
        throw Error('SESSION_EXPIRED')
      }
    }
  }
)

// Axios custom instance for API communication with the CMS
let axiosCMS = axios.create()
axiosCMS.defaults.baseURL = ENV_CONFIGS.CMS_BASE_URL
axiosCMS.defaults.headers.common['Content-Type'] = 'application/json'
axiosCMS.defaults.headers.common['Authorization'] = ENV_CONFIGS.CMS_BASIC_TOKEN

let axiosAdvice = axios.create()
axiosAdvice.defaults.baseURL = ENV_CONFIGS.WEKIT_BASE
axiosAdvice.defaults.headers.common['Content-Type'] = 'application/json'
axiosAdvice.defaults.headers.common['Ocp-Apim-Subscription-Key'] =
  ENV_CONFIGS.ACN_SUB_KEY

axiosAdvice.interceptors.request.use((config) => {
  config.params = config.params ? config.params : {}
  config.params.source_id = '1'
  return config
})

// Axios custom instance for API communication with services on Sorgenia servers
let axiosSorgenia = axios.create()
axiosSorgenia.defaults.baseURL = ENV_CONFIGS.SORGENIA_SERVICES_BASE_URL
axiosSorgenia.defaults.headers.common['Content-Type'] = 'application/json'

export { axiosACN, axiosAdvice, axiosCMS, axiosSorgenia }
