<template>
  <v-form ref="form" lazy-validation>
    <div class="shipping">
      <div class="address-owner">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              append-icon=""
              return-object
              autocomplete="off"
              item-text="comune"
              item-value="comuneEgon"
              label="Città"
              @change="onCitySelected"
              :error-messages="searchCityErrorMsg"
              :items="items.city"
              :loading="isLoading.city"
              :search-input.sync="searchCity"
              :readonly="!enableAddressShipping()"
              v-model="addressShippingData.city"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              append-icon=""
              return-object
              item-text="provincia"
              item-value="provinciaEgon"
              autocomplete="off"
              label="Provincia"
              :items="items.province"
              :readonly="!enableAddressShipping()"
              v-model="addressShippingData.province"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-autocomplete
              append-icon=""
              return-object
              @change="onAddressSelected"
              autocomplete="off"
              item-text="via"
              item-value="viaEgon"
              :error-messages="searchAddressErrorMsg"
              :items="items.address"
              :loading="isLoading.address"
              :search-input.sync="searchAddress"
              label="Indirizzo"
              :readonly="!enableAddressShipping()"
              v-model="addressShippingData.address"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              append-icon=""
              return-object
              @change="onCivicSelected"
              autocomplete="off"
              item-text="fullCivico"
              item-value="civicoEgon"
              :error-messages="searchCivicNumberErrorMsg"
              :items="items.civicNumber"
              :loading="isLoading.civicNumber"
              :search-input.sync="searchCivicNumber"
              label="Civico"
              :readonly="!enableAddressShipping()"
              v-model="addressShippingData.civicNumber"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-col cols="12">
          <v-text-field
            v-model.trim="addressShippingData.interphone"
            label="C/O, Citofono o Altro"
            class="auth__input mb-8"
            :error-messages="interphoneErrorMsg"
            @input="onInterphoneChange"
            @keypress="InterphonePreventWhiteSpace"
          ></v-text-field>
        </v-col>
      </div>

      <v-btn
        v-if="!btnclass"
        :color="btncolor"
        x-large
        width="260"
        class="v-btn--shadowed"
        :disabled="!validForm"
        @click="addInfo"
        >{{ btnText }}</v-btn
      >

      <div
        v-if="!!btnclass"
        :class="`${btnclass} ${!validForm ? 'disabled' : ''} `"
        @click="addInfo"
      >
        {{ btnText }}
      </div>

      <v-row class="mt-6 stickyRow" no-gutters v-if="paymentMethod && !noToast">
        <v-col>
          <div class="infobox">
            <span
              >Hai scelto {{ paymentMethod.iban ? 'il CC' : 'la CCR' }}
              {{
                paymentMethod.iban
                  ? paymentMethod.iban.slice(-4)
                  : paymentMethod.creditCardNumber.slice(-4)
              }}</span
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
import { clone, find, isEqual } from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'shippingAddress',
  data: function () {
    return {
      storedShippingData: {
        city: null,
        province: null,
        address: null,
        civicNumber: null,
        interphone: null,
        atContact: null
      },
      addressShippingData: {
        city: null,
        province: null,
        address: null,
        civicNumber: null,
        interphone: null,
        atContact: null
      },
      interphoneErrorMsg: [],
      atContactErrorMsg: [],
      searchCity: null,
      searchCityErrorMsg: [],
      searchProvince: null,
      searchAddress: null,
      searchAddressErrorMsg: [],
      searchCivicNumber: null,
      searchCivicNumberErrorMsg: [],
      isLoading: {
        city: false,
        province: false,
        address: false,
        civicNumber: false
      },
      items: { address: [], civicNumber: [], province: [], city: [] },
      timer: null,
      isChanged: false,
      validForm: false,
      btnclass: false,
      btncolor: 'primary'
    }
  },
  props: {
    btnText: {
      default: 'Continua',
      type: String
    },
    goNext: {
      default: true
    },
    noToast: {
      default: false,
      type: Boolean
    }
  },
  async mounted() {
    const addressCovered = this.$store.getters['upsellingFiber/addressCovered']
    this.trackTapEvent({
      name: 'upsellingv2_ChangeModemShipping_open',
      params: {
        client_selected: addressCovered
          ? addressCovered['clientOwnerCode'] || ''
          : 'not_selected'
      }
    })
    if (this.goNext !== true) {
      this.btnclass = 'btn-custom'
      this.btncolor = 'primary-inverted'
    }
    let cit, add, civic
    let shipComune
    let shipCap
    let shipVia
    let shipCivico
    let shipViaEgon
    let fullCivico = false
    if (this.addressShipping) {
      //  Questa fix potrebbe risultare utile nel caso in cui il campo cap fosse vuoto perché in quel caso va in errore getEgonVia
      // if (this.addressShipping.address.cap === '') {
      //   this.addressShipping.address.cap = this.addressShipping.city.cap
      // }
      shipComune = this.addressShipping.city.comune
      shipCap = this.addressShipping.address.cap
      shipVia = this.addressShipping.address.via
      shipCivico = this.addressShipping.civicNumber.civico
      shipViaEgon = this.addressShipping.address.viaEgon
      fullCivico = this.addressShipping.civicNumber.fullCivico

      this.addressShippingData.interphone =
        this.addressShipping.interphone || ''
      // this.addressShippingData.atContact = this.addressShipping.atContact || ''
      this.isChanged = this.addressShipping.isChanged
    } else {
      if (!this.enableAddressShipping()) {
        shipComune = this.addressCovered.city
        shipCap = this.addressCovered.zipCode
        shipVia = this.addressCovered.street.split(',')[0]
        shipCivico = this.addressCovered.streetNumber
        shipViaEgon = this.addressCovered.egonStreet
      }
      this.addressShippingData.interphone = this.addressCovered.interno || ''
    }
    cit = shipComune ? await this.getEgonComune(shipComune) : []
    let Realcit = shipCap ? this.getRealEgonCity(cit, shipCap) : []
    civic =
      !!shipCivico && !!shipViaEgon
        ? await this.getEgonCivico({
            civico: shipCivico,
            viaEgon: shipViaEgon
          })
        : []
    add =
      !!shipVia && !!Realcit.comuneEgon // prima era con or e se una delle due era undefined la chiamata veniva fatta lo steeso e andava in errore, ad esempio se this.addressShipping.address.cap è vuoto
        ? await this.getEgonVia({
            comuneEgon: Realcit.comuneEgon,
            via: shipVia
          })
        : []
    if (fullCivico) {
      civic = civic.filter((item) => {
        let finded = item.esponente
          ? item.civico + '/' + item.esponente
          : item.civico
        return fullCivico === finded
      })
    }
    this.items.city = cit
    this.items.province = cit
    this.items.address =
      add.length >= 0
        ? add
        : [{ via: shipVia, cap: shipCap, viaEgon: shipViaEgon }]
    this.items.civicNumber = civic

    if (!this.enableAddressShipping()) {
      this.addressShippingData.city = this.items.city[0]
      this.addressShippingData.province = this.items.province[0]
      this.addressShippingData.address = this.items.address[0]
      this.addressShippingData.civicNumber = this.items.civicNumber[0]
    }

    this.chkRequiredField(this.addressShippingData.interphone, 'interphone')
    this.storedShippingData = clone(this.addressShippingData)
  },
  watch: {
    async searchCity(comune) {
      if (this.enableAddressShipping()) {
        this.chkRequiredField(comune, 'searchCity')
        if (!!comune && comune.length > 2) {
          this.items.city = this.enableAddressShipping()
            ? await this.autocomplete(this.getEgonComune, comune)
            : comune
        } else {
          this.items.city = []
        }
      }
    },
    async searchAddress(via) {
      if (this.enableAddressShipping()) {
        this.chkRequiredField(via, 'searchAddress')
        if (!!via && via.length > 2) {
          this.items.address = await this.autocomplete(this.getEgonVia, {
            comuneEgon: this.addressShippingData.city.comuneEgon,
            via: via
          })
        } else {
          this.items.address = []
        }
      }
    },
    async searchCivicNumber(fullCivico) {
      if (this.enableAddressShipping()) {
        this.chkRequiredField(fullCivico, 'searchCivicNumber')
        if (fullCivico) {
          const noEsponente = fullCivico.split('/')
          this.validForm = false
          this.items.civicNumber = await this.autocomplete(this.getEgonCivico, {
            civico: noEsponente[0],
            viaEgon: this.addressShippingData.address.viaEgon
          })
          if (
            !this.items.civicNumber.find((item) =>
              item.fullCivico.includes(fullCivico)
            )
          ) {
            this.searchCivicNumberErrorMsg.push(
              'Il numero civico non è presente in elenco!'
            )
            this.isValidForm()
          } else {
            this.isValidForm()
          }
        } else {
          this.items.civicNumber = []
        }
      }
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', [
      'addressShipping',
      'addressCovered',
      'paymentMethod',
      'connectionType',
      'migrationCode',
      'isFromTelecom',
      'provider'
    ])
  },
  methods: {
    ...mapMutations('upsellingFiber', [
      'setAddressShipping',
      'setAddressCovered'
    ]),
    ...mapActions('egon', ['getEgonComune', 'getEgonVia', 'getEgonCivico']),
    ...mapActions('analytics', ['trackTapEvent']),

    enableAddressShipping() {
      return (
        (this.connectionType === 'FTTC' && this.migrationCode.checked) ||
        this.provider === 'FC'
      )
    },
    getRealEgonCity(cityArray, zipcode) {
      const ret = find(
        cityArray,
        (item) => item.cap.substring(0, 2) === zipcode.substring(0, 2)
      )
      return ret || []
    },
    chkRequiredField(val, field) {
      this[field + 'ErrorMsg'] = val ? [] : ['Campo obbligatorio']
      this.isValidForm()
    },
    onCitySelected(city) {
      this.chkRequiredField(city, 'searchCity')
      this.items.province = [city]
      this.isChanged = this.isAddressChanged()
      if (city !== this.addressShippingData.city.city) {
        this.addressShippingData.address = null
        this.addressShippingData.civicNumber = null
      }
    },
    onAddressSelected(add) {
      this.chkRequiredField(add, 'searchAddress')
      this.isChanged = this.isAddressChanged()
      if (add !== this.addressShippingData.address.via) {
        this.addressShippingData.civicNumber = null
        this.items.civicNumber = null
      }
    },
    onCivicSelected(civic) {
      this.chkRequiredField(civic, 'searchCivicNumber')
      this.isChanged = this.isAddressChanged()
    },
    InterphonePreventWhiteSpace(key) {
      if (key.keyCode === 32 && !this.addressShippingData.interphone) {
        key.preventDefault()
      }
    },
    onInterphoneChange(val) {
      this.chkRequiredField(val.trim(), 'interphone')
    },
    async addInfo() {
      if (!this.validForm) {
        return
      }
      // this.addressShippingData.address.fullVia =  await EgonService.getStreetDetailFromId(this.addressShippingData.civicNumber.civicoEgon)
      this.addressShippingData.address.fullVia =
        this.addressShippingData.address.via
      this.addressShippingData.address.fullVia +=
        ', ' +
        this.addressShippingData.civicNumber.civico +
        (this.addressShippingData.civicNumber.esponente
          ? '/' + this.addressShippingData.civicNumber.esponente
          : '')
      this.setAddressCovered({
        ...this.addressCovered,
        interno: this.addressShippingData.interphone
      })
      this.setAddressShipping({
        ...this.addressShippingData,
        isChanged: this.isChanged
      })
      if (this.goNext === true) {
        this.$emit('nextStep', 'upsellingv2_consent_open')
      } else {
        this.$emit('openAccordion', 'shipping')
      }
    },
    autocomplete(callback) {
      return new Promise((resolve, reject) => {
        let ret = ''
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(async () => {
          ret = await callback(arguments[1])
          resolve(ret)
        }, 500)
      })
    },
    isValidForm() {
      this.validForm =
        !this.searchCityErrorMsg.length &&
        !!this.addressShippingData.city &&
        !this.searchCivicNumberErrorMsg.length &&
        !this.interphoneErrorMsg.length &&
        !!this.addressShippingData.city &&
        !!this.addressShippingData.address &&
        !!this.addressShippingData.civicNumber &&
        !!this.addressShippingData.interphone
    },
    isAddressChanged() {
      return !isEqual(this.addressShippingData, this.storedShippingData)
    }
  }
}
</script>
<style lang="scss" scoped>
.stickyRow {
  position: fixed;
  bottom: 20px;
}

.btn-classic {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.0892857143em;
  width: 260px;
  height: 55px !important;
  padding: 18px;
  color: white;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  background-color: #12256a !important;
  border-color: #12256a !important;
  cursor: pointer;

  &:hover {
    background-color: #193494 !important;
    border-color: #193494 !important;
  }

  &.disabled {
    background-color: #f8f9fc !important;
    border-color: #f8f9fc !important;
    color: #b7bfd2 !important;
    cursor: default;
  }
}

.btn-custom {
  width: 242px;
  text-align: center;
  padding: 18px;
  color: black;
  border: 1px solid #ccd0e1;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
  }
  100% {
    opacity: 0;
  }
}

.infobox {
  background-color: #606060;
  color: white;
  width: 300px;
  display: flex;
  padding: 15px 20px 15px 10px;
  border-radius: 5px;
  height: 50px;
  font-size: 14px;
  -webkit-animation: fadeOut 4s;
  animation: fadeOut 4s;
  animation-fill-mode: forwards;
}

.shipping {
  max-width: 652px;

  &__info {
    font-weight: 500;
  }

  .clientInfo {
    margin-bottom: 30px;

    .address {
      font-weight: 500;
    }

    &.RES::before {
      content: url('../../../assets/ic-residenziale_black.svg');
      width: 24px;
      margin-right: 16px;
    }

    &.BUS::before {
      content: url('../../../assets/ic-residenziale_black.svg');
      width: 24px;
      margin-right: 16px;
    }
  }
}
</style>
