<template>
  <layout header-size="full">
    <template v-slot:header>
      <v-col cols="7" class="img-girl pa-0" />
      <v-col class="mt-12" cols="5">
        <h1 class="black--text mb-8">
          La tua <br />
          disiscrizione è <br />
          confermata<br />
        </h1>
        <v-btn
          class="v-btn--shadowed btn"
          x-large
          :width="265"
          @click="$router.push({ name: 'home' })"
        >
          Torna all'homepage
        </v-btn>
      </v-col>
    </template>
  </layout>
</template>

<script>
import Layout from '../Layout'

export default {
  name: 'CoinsSuccessPage',
  components: {
    layout: Layout
  }
}
</script>

<style scoped></style>
