import { ServiceSectionMappingType } from '../type/service_card_section_mapping.type'
import { APP_SECTION_MAPPING } from '../constants'

export class ServiceSectionHelper {
  static getSectionUrl(appSection) {
    let result = ''
    if (APP_SECTION_MAPPING[appSection]) {
      result = { name: APP_SECTION_MAPPING[appSection] }
    } else {
      result = {
        name: ServiceSectionMappingType[appSection].url,
        params: {
          value: ServiceSectionMappingType[appSection].param,
          component:
            ServiceSectionMappingType[appSection].url === 'DYNAMIC_COMPONENT'
              ? ServiceSectionMappingType[appSection].component
              : null
        }
      }
    }
    return result
  }
}
