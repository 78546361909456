<template>
  <div class="survey">
    <v-row class="survey__header" v-if="currentSurvey">
      <v-col md="6" sm="6" cols="1" class="flex-grow-0 pr-0">
        <button
          type="button"
          class="survey__header-back"
          @click="onBackClick()"
        >
          <v-icon color="white" size="30">mdi-chevron-left</v-icon>
          <h5 v-if="!isMobile && !isTablet">
            Torna a {{ stripHtml(currentSurvey.inner_title) }}
          </h5>
        </button>
        <h2 v-if="!isMobile">{{ currentSurvey.title }}</h2>
      </v-col>

      <v-col
        md="6"
        sm="6"
        cols="10"
        class="flex-grow-1 pr-0 pl-0"
        align="center"
      >
        <div class="survey__title" v-html="currentSurvey.inner_title"></div>
      </v-col>

      <v-col cols="1" class="flex-grow-0 pl-0" v-if="isMobile || isTablet">
        <v-img
          @click="$router.push({ name: 'publicWelcomekitDone' })"
          class="close"
          contain
          width="16"
          src="@assets/welcome/ic-close-white-big.png"
        />
      </v-col>
    </v-row>

    <div class="survey__content-wrap" v-if="!isDone">
      <v-col>
        <v-row class="survey__content">
          <v-progress-linear
            :value="progress"
            background-color="grey lighten-3"
            color="purple"
          ></v-progress-linear>
        </v-row>
        <v-row class="survey__content">
          <feedback-question
            v-if="currentQuestion"
            :question="currentQuestion"
            :on-answer-changed="updateAnswers"
            :on-comment-changed="onCommentChanged"
            :on-slider-changed="onSliderChanged"
          ></feedback-question>
        </v-row>
        <v-row class="survey__content">
          <feedback-progress-buttons
            :on-next-click="onNextClick"
            :on-prev-click="onPrevClick"
            :is-last="
              currentSurvey.questions &&
              currentQuestionNum === currentSurvey.questions.length
            "
            :is-column="isMobile"
            :is-next-enabled="isNextButtonEnabled"
          ></feedback-progress-buttons>
        </v-row>
      </v-col>
    </div>

    <div class="survey__content-wrap" v-if="isDone">
      <div class="survey__content survey__content__done">
        <v-img
          class="ma-auto"
          src="@assets/feedback/ic-smile-active-green-1.png"
          width="96"
        />
        <h1 class="text-center font-weight-bold mt-10">
          {{ isMobile ? 'Grazie' : 'Grazie per la tua opinione!' }}
        </h1>
        <h3
          class="text-center font-weight-bold mt-4"
          v-if="wkLinks && wkLinks.length > 0"
        >
          Vuoi approfondire gli argomenti appena trattati? Eccoli:
        </h3>
        <div>
          <div class="mt-10">
            <div
              class="ma-auto d-flex text-center flex-row shadowed px-6 ma-5 mb-5"
              v-for="(link, i) in wkLinks"
              :key="i"
              :class="[isMobile ? 'w-90' : 'w-30']"
              @click="$router.push(to(link.id, link.template_name))"
            >
              <span class="my-auto w-100">{{ link.title }}</span>
              <i class="survey__is-done-btn-icon my-auto ml-5"></i>
            </div>
          </div>
        </div>
        <router-link
          :to="{ name: isMobile ? 'publicWelcomekitDone' : 'home' }"
          class="text-md-center"
          ><h4 class="font-weight-medium" style="text-align: center">
            {{ isMobile ? 'Torne' : 'Vai' }} alla Homepage
          </h4></router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import FeedbackQuestion from '../../components/feedback/FeedbackQuestion'
import { checkMobile, stripHtml, getKitSlideRouteName } from '@/js/utils.js'
import FeedbackProgressButtons from '../../components/feedback/FeedbackProgressButtons'
import {
  CHECKBOX,
  INTERVAL,
  TEXT,
  DROPDOWN,
  RATING,
  RANKING
} from '../../js/constants'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'tabLayout',
  components: { FeedbackProgressButtons, FeedbackQuestion },
  data() {
    return {
      isMobile: checkMobile(),
      isTablet: window.innerWidth > 480 && window.innerWidth <= 1024,
      currentSurvey: {},
      currentQuestionNum: 1,
      currentQuestion: null,
      comment: '',
      progress: 0,
      isNextButtonEnabled: false,
      isDone: false,
      links: []
    }
  },
  methods: {
    ...mapActions('feedback', [
      'saveResults',
      'loadSurvey',
      'loadUndoneSurvey'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    onCommentChanged(text) {
      this.currentSurvey.questions[this.currentQuestionNum - 1].comment_value =
        text
    },
    onSliderChanged(id, val, field) {
      this.currentSurvey.questions[this.currentQuestionNum - 1].answers.find(
        (aw) => aw.id === id
      )[field] = val
    },
    stripHtml(input) {
      return stripHtml(input)
    },
    to(name, template) {
      if (
        template === 'standardWelcomeKit' ||
        template === 'standardTutorial'
      ) {
        return {
          name: getKitSlideRouteName(template, this.isMobile),
          params: { id: name }
        }
      } else {
        return { name: getKitSlideRouteName(template, this.isMobile) }
      }
    },
    buildLinks() {
      let wkLinks = this.currentSurvey.tutorial_sections
        .map((item) => {
          item.title = this.tutorialSlidesForUser.find(
            (section) => section.id === item.id
          ).carousel_title
          return item
        })
        .concat(
          this.currentSurvey.welcome_kit_sections.map((item) => {
            item.title = this.wkSlidesForUser.find(
              (section) => section.id === item.id
            ).carousel_title
            return item
          })
        )

      this.links = wkLinks
    },
    updateProgress() {
      const total = this.currentSurvey.questions.length
      this.progress = (this.currentQuestionNum / total) * 100

      this.currentQuestion =
        this.currentSurvey.questions[this.currentQuestionNum - 1]
      this.isNextButtonEnabled = false
      this.updateAnswers()
    },
    onNextClick() {
      if (this.currentQuestionNum === this.currentSurvey.questions.length) {
        this.trackTapEvent({
          name: 'wkFeedback_send_tap',
          params: {
            survey_id: this.$route.params.id,
            client_code: this.client.clientOwnerCode,
            client_type: this.client.clientOwnerType,
            wk_type: this.client.type
          },
          toNative: this.isMobile
        })

        this.isDone = true
        this.saveResults()
      } else {
        this.currentQuestionNum = this.currentQuestionNum + 1
        this.updateProgress()
      }
    },
    onPrevClick() {
      this.currentQuestionNum = this.currentQuestionNum - 1
      if (this.currentQuestionNum === 0) {
        this.onBackClick()
      } else {
        this.updateProgress()
      }
    },
    onBackClick() {
      this.isMobile || this.isTablet
        ? this.$router.go(-1)
        : this.$router.push({ name: 'feedback' })
    },
    updateAnswers() {
      this.trackTapEvent({
        name: 'feedback_answers_select',
        toNative: this.isMobile
      })
      if (this.currentQuestion.type === INTERVAL) {
        this.isNextButtonEnabled = true
      } else if (this.currentQuestion.type === CHECKBOX) {
        this.isNextButtonEnabled =
          this.currentQuestion.answers.filter((v) => v.selected !== null)
            .length > 0
      } else if (this.currentQuestion.type === TEXT) {
        this.isNextButtonEnabled = true
      } else if (this.currentQuestion.type === DROPDOWN) {
        this.isNextButtonEnabled =
          this.currentQuestion.answers.filter((v) => v.selected !== null)
            .length > 0
      } else if (this.currentQuestion.type === RATING) {
        this.isNextButtonEnabled =
          !this.currentQuestion.answers.filter((v) => v.rating_value === null)
            .length > 0
      } else if (this.currentQuestion.type === RANKING) {
        this.isNextButtonEnabled = true
      } else {
        const selection = this.currentQuestion.answers.filter((v) => v.selected)
        if (selection.length > 0) {
          this.isNextButtonEnabled = true
        }
      }
    }
  },
  computed: {
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['tutorialSlidesForUser', 'wkSlidesForUser']),
    perRow() {
      return this.isMobile
        ? 1
        : this.wkLinks.length < 3
        ? this.wkLinks.length
        : 3
    },
    wkLinks() {
      return this.links || []
    }
  },
  created() {
    let id = this.$route.params.id

    this.loadSurvey().then((cmsSurveys) => {
      this.currentSurvey = cmsSurveys.find((v) => v.id === id)

      this.loadUndoneSurvey().then((undoneSurveys) => {
        this.currentSurvey.questions.forEach((v1) => {
          v1['comment_value'] = ''
          v1.answers.forEach((v2) => {
            v2['selected'] = null
            v2['slider_value'] = null
            v2['rating_value'] = null
            v2['disabled'] = false
          })
        })
        this.updateProgress()
        this.buildLinks()
      })
    })
  }
}
</script>
<style lang="scss">
.survey__title {
  color: white;
  span {
    color: white;
  }
  p {
    color: white;
  }
}
</style>
<style lang="scss" scoped>
@import url('../../styles/slider.scss');

.survey__is-done-btn-icon {
  display: block;
  background: url('../../assets/feedback/ic-chevron-dx-blue.png');
  width: 16px;
  height: 16px;
}

.survey__is-done-btn {
  justify-content: space-between;
}

.survey {
  background-color: $srg-blue;

  &__header {
    height: 238px;
    color: white;
    padding: 28px calc((100% - 1140px) / 2) 0;
    position: relative;
    z-index: 100;
    align-items: center;

    h1,
    .survey__title {
      font-size: 60px;
      font-weight: bold;
      line-height: 70px;

      span {
        color: white;
      }
      p {
        color: white;
      }
    }

    h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 48px;
    }

    h3 {
      font-size: 20px;
    }

    &-back {
      display: flex;
      align-items: center;
      color: white;
      font-size: 20px;
      margin-right: 12px;
      margin-bottom: 5px;
      letter-spacing: 0.4px;

      .v-icon {
        width: 15px;
        margin-right: 14px;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__content {
    h2 {
      font-size: 32px;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 20px;
    }

    h5 {
      font-size: 16px;
    }

    &-wrap {
      position: relative;
      width: 100%;
      z-index: 101;
      background: white;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      padding-bottom: 100px;
      padding-top: 60px;
    }

    &__done {
      max-width: unset;
    }

    max-width: 1140px;
    margin: 0 auto;
    position: relative;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .survey {
    letter-spacing: 0;
    overflow: hidden;
    position: relative;

    &__header {
      height: 165px;
      padding: 0;
      align-items: flex-start;
      padding-top: 48px;

      h1,
      .survey__title {
        font-size: 34px;
        line-height: 40px;

        span {
          color: white;
        }
        p {
          color: white;
        }
      }

      h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      h3 {
        font-size: 16px;
        line-height: 22px;
      }

      .close {
        cursor: pointer;
      }
    }

    &__content {
      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 20px;
        line-height: 24px;
      }

      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 16px;
      }

      &-wrap {
        padding: 32px 15px 48px 15px;
      }

      &__done {
        max-width: unset;
      }
    }

    &__header-back {
      margin: 0px;
    }
  }
  .mb-6 {
    margin-bottom: 16px !important;
  }
  .mb-12 {
    margin-bottom: 24px !important;
  }
  .mt-12 {
    margin-top: 24px !important;
  }
  .reverseBtn {
    flex-direction: column-reverse;
    text-align: center;

    .col:last-child {
      text-align: center;
    }

    button {
      max-width: 90%;
      letter-spacing: 0px;
    }
  }
}

.w-100 {
  width: 100%;
}

button.v-btn.v-btn--shadowed.white {
  color: black !important;
}

@media (min-width: 481px) and (max-width: 1024px) {
  .survey {
    .survey__title {
      font-size: 50px;
      line-height: 55px;
    }

    overflow: hidden;
    position: relative;

    &__header {
      &-back {
        position: absolute;
        top: 30px;
        left: 30px;
      }

      .close {
        position: absolute;
        top: 30px;
        right: 30px;
      }
    }

    &__content {
      &-wrap {
        padding-left: 30px;
        padding-right: 30px;
      }

      &__done {
        max-width: unset;
      }
    }
  }
}

.tutorial-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrapper--btm {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.survey__content-wrap-btn {
  max-width: 1180px;
  margin: 0 auto;
}

.shadowed {
  box-shadow: 0 0 20px 2px rgba(63, 63, 63, 0.16);
  border-radius: 20px;
  height: 66px;
  cursor: pointer;
}

.w-30 {
  width: 30%;
}

.w-90 {
  width: 90%;
}
</style>
