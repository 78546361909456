export const Installation = {
  YES_WHIT_SORGENIA: 'Sì, ho già un pannello installato con Sorgenia',
  YES_WHIT_ANOTHER: 'Sì, ho un pannello installato con altro fornitore',
  NOT_YET: 'Non ancora, ma vorrei saperne di più',
  CANT_PUT: 'Non potrei metterlo'
}

export const Interest = {
  BATTERY: 'Batteria di accumulazione',
  HEAT_PUMP: 'Pompa di calore',
  EXPANSION: "Potenziamento dell'impianto",
  NOT_INTERESTED: 'No, non sono interessato'
}

export const Consulting = {
  RECALL: 'Sì, voglio lasciare un mio recapito',
  NOT_INTERESTED: 'No, non mi interessa'
}
