<template>
  <div class="account" v-if="status === 'success'">
    <TcVars />
    <v-row class="std-side-padding-profile">
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row class="std-side-padding-profile">
      <v-col class="account__registry">
        <v-row class="justify-space-between align-center">
          <div class="registry__title">
            <h1>Il mio profilo</h1>
          </div>
          <div class="registry__btn">
            <v-btn
              :rounded="true"
              @click="logout"
              class="primary--text v-btn--shadowed"
              x-large
              width="167"
              color="white"
              >Log-out
            </v-btn>
          </div>
        </v-row>
        <!-- <v-row class="registry__subtitle" no-gutters>
          <h2>Anagrafica Profilo</h2>
        </v-row> -->
        <v-row class="registry__infoTitle mb-12" no-gutters align="center">
          <v-col class="col-1 mr-4">
            <div class="profile">
              <div class="account__photo">
                <v-img
                  class="mainPhoto"
                  :src="
                    accountPhoto
                      ? accountPhotoSrc
                      : require('@/assets/icons/avatar.svg')
                  "
                  @click="photoPickDialog = true"
                >
                </v-img>
                <div @click="photoPickDialog = true" class="photoPicker">
                  <v-icon size="16" color="white">mdi-camera-outline</v-icon>
                </div>
              </div>
            </div>
          </v-col>
          <v-col lass="col-4">
            <div class="bordered ml-2 mr-8">
              <div class="labelName mb-2">IDENTIFICATIVO ACCOUNT</div>
              <div class="BodyL pb-2 colorblack">
                {{
                  (this.$store.state.account.mainClient &&
                    this.$store.state.account.mainClient.firstName) ||
                  this.$store.state.account.mainClient.businessName
                }}
              </div>
            </div>
          </v-col>
          <v-col class="col-4">
            <div class="bordered mr-8">
              <div class="labelName mb-2">NOME UTENTE</div>
              <div class="BodyL pb-2 colorblack">
                {{ username }}
              </div>
            </div>
          </v-col>
          <v-col class="col-3">
            <div class="labelName mb-1">PASSWORD</div>
            <div
              class="BodyL-Underline modifica"
              @click="
                ;(pwdDialog = true), trackTapEvent('account_password_tap')
              "
            >
              Modifica
            </div>
          </v-col>
        </v-row>

        <v-dialog
          persistent
          overlay-opacity="0.6"
          v-model="photoPickDialog"
          max-width="555"
        >
          <v-card
            class="photo-dialog"
            :style="`min-height: ${photoSrc ? '755px' : '280px'}`"
          >
            <v-row>
              <v-col align="right">
                <img
                  class="dialog__img"
                  @click="photoPickDialog = false"
                  src="@/assets/ic_chevron_top_black.png"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pl-11 mb-2">
                <h2>
                  {{
                    !photoSrc
                      ? 'Modifica foto profilo'
                      : 'Trascina per riposizionare'
                  }}
                </h2>
                <v-file-input
                  id="photo-upload"
                  style="display: none"
                  v-model="photo"
                />
              </v-col>
            </v-row>
            <v-row v-if="photoSrc">
              <v-col align="center" class="pt-0 mb-2">
                <clipper-fixed
                  :area="90"
                  :src="photoSrc"
                  ref="clipper"
                  class="clipper"
                  :grid="false"
                  :round="true"
                ></clipper-fixed>
              </v-col>
            </v-row>
            <v-row v-if="!photoSrc" class="mb-4">
              <v-col class="pl-0" align="center">
                <v-btn
                  color="white"
                  class="v-btn--shadowed btnlabel prymary--text"
                  height="54"
                  min-width="460"
                  @click="handleClick"
                >
                  <h5>Carica dal computer</h5>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else class="mb-4">
              <v-col class="pl-7" align="center">
                <v-btn
                  color="white"
                  class="v-btn--shadowed normal"
                  x-large
                  min-width="210"
                  @click="abortPhoto"
                >
                  Annulla
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  class="v-btn--shadowed normal"
                  x-large
                  min-width="210"
                  @click="uploadPhoto"
                >
                  Salva
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-expansion-panels v-model="openAccordion">
          <v-expansion-panel
            class="account__panel mb-8"
            @click="selectedClient = mainClient"
          >
            <v-expansion-panel-header
              class="d-flex align-center"
              @hook:mounted="onAccountLoaded(mainClient.clientOwnerCode)"
              :id="mainClient.clientOwnerCode"
              @click="openPanel = !openPanel"
            >
              <template v-slot:actions>
                <v-img v-if="openPanel" :src="IconChevronUp" />
                <v-img v-if="!openPanel" :src="IconChevronDown" />
              </template>
              <div class="d-flex align-center">
                <div
                  :class="`icon ${mainClient.clientOwnerType.toLowerCase()}`"
                ></div>
                <div class="text">
                  <div class="BodyXL-Strong">
                    {{
                      mainClient.clientOwnerType === 'RES'
                        ? mainClient.firstName + ' ' + mainClient.lastName
                        : $filters.capitalize(mainClient.businessName)
                    }}
                  </div>
                  <div class="BodyL mt-2">
                    Codice cliente: {{ mainClient.clientOwnerCode }}
                  </div>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <AccountDetail
                :key="keyAfterthoughtButton"
                :client="mainClient"
                :clientDetail="clientDetail"
                :infoConsents="infoConsents"
                :numContractsFound="numContractsFound"
                :contracts="contracts"
                :notDigitalContracts="notDigitalContracts"
                v-bind:contractsChangedProp="contractsChanged"
                :showAfterthoughtButton="showAfterthoughtButton"
                :disableRadio="sendingEdit"
                @openDocInfoConsents="openDocInfoConsents"
                @getContractFileUrl="getContractFileUrl"
                @getUrlForFile="getUrlForFile"
                @openDialogAfterthought="openDialogAfterthought"
                @editingContent="editingContent"
                @submitEdit="submitEdit"
                @detailForClient="detailForClient"
                @consentChanged="consentChanged"
                @getDocumentFileUrl="getDocumentFileUrl"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <h2
            class="mt-4 mb-8"
            style="width: 100%"
            v-if="clientsForAccount.length > 0"
          >
            <strong>Profili associati</strong>
          </h2>
          <v-expansion-panel
            v-for="(client, i) in clientsForAccount"
            :key="i"
            class="account__panel mb-8"
            @click="selectedClient = client"
          >
            <v-expansion-panel-header
              class="d-flex align-center"
              @hook:mounted="onAccountLoaded(client.clientOwnerCode)"
              :id="client.clientOwnerCode"
            >
              <template v-slot:actions>
                <v-img :src="IconChevronDown" />
              </template>
              <div class="d-flex align-center">
                <div
                  :class="`icon ${client.clientOwnerType.toLowerCase()}`"
                ></div>
                <div class="text">
                  <div class="name">
                    {{
                      client.clientOwnerType === 'RES'
                        ? client.firstName + ' ' + client.lastName
                        : $filters.capitalize(client.businessName)
                    }}
                  </div>
                  <div class="code">
                    Codice cliente: {{ client.clientOwnerCode }}
                  </div>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <AccountDetail
                :key="keyAfterthoughtButton"
                :client="client"
                :clientDetail="clientDetail"
                :infoConsents="infoConsents"
                :numContractsFound="numContractsFound"
                :contracts="contracts"
                :notDigitalContracts="notDigitalContracts"
                v-bind:contractsChangedProp="contractsChanged"
                :showAfterthoughtButton="showAfterthoughtButton"
                :disableRadio="sendingEdit"
                @openDocInfoConsents="openDocInfoConsents"
                @getContractFileUrl="getContractFileUrl"
                @openDialogAfterthought="openDialogAfterthought"
                @editingContent="editingContent"
                @submitEdit="submitEdit"
                @consentChanged="consentChanged"
                @getDocumentFileUrl="getDocumentFileUrl"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-dialog v-model="pwdChangedDialog" max-width="490">
      <div class="ripensamento-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="pwdChangedDialog = false"
        />
        <div>
          <div class="ripensamento-modal__title">Password modificata!</div>
          <div class="ripensamento-modal__text">
            La tua password è stata modificata. Non dimenticarlo al prossimo
            accesso!
          </div>
          <div class="ripensamento-modal__continue full-bt-group">
            <v-btn
              class="bt-modal--primary"
              text
              @click="pwdChangedDialog = false"
              >Torna all'account</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="pwdDialog" max-width="576">
      <div class="ripensamento-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="pwdDialog = false"
        />
        <change-pwd :bus="bus" modalMode @closeModal="passwordChanged" />
      </div>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="490">
      <div class="ripensamento-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="editDialog = false"
        />
        <div class="ripensamento-modal__title">
          Inserisci {{ editedContent.name }}
        </div>
        <div class="ripensamento-modal__accept" align="center">
          <v-row>
            <v-col>
              <v-text-field
                v-model.lazy="editedContent.value"
                name="editedContent.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="ripensamento-modal__continue full-bt-group">
          <v-btn
            class="bt-modal--primary"
            text
            @click="submitEdit(editedContent)"
            :loading="sendingEdit"
            >Conferma
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="editAddressDialog" max-width="490">
      <div class="ripensamento-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="editAddressDialog = false"
        />
        <div class="ripensamento-modal__title">Inserisci indirizzo</div>
        <div class="ripensamento-modal__accept" align="center">
          <v-row>
            <v-col>
              <v-text-field
                label="Via"
                v-model="editedContent.data.indirizzoResidenza"
                name="editedContent.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-text-field
                label="CAP"
                v-model="editedContent.data.capResidenza"
                name="editedContent.name"
              ></v-text-field>
            </v-col>
            <v-col cols="7">
              <v-text-field
                label="Comune"
                v-model="editedContent.data.comuneResidenza"
                name="editedContent.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="ripensamento-modal__continue full-bt-group">
          <v-btn
            class="bt-modal--primary"
            text
            @click="submitEdit(editedContent)"
            :loading="sendingEdit"
            >Conferma
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="dialogAfterthought" max-width="576">
      <div class="ripensamento-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="dialogAfterthought = false"
        />
        <div>
          <div class="ripensamento-modal__title">Ripensamento</div>
          <div class="ripensamento-modal__text">
            Questa sezione ti permette di esercitare il diritto di ripensamento
            entro i 14 giorni successivi alla data di perfezionamento del
            Contratto Sorgenia.
          </div>
          <div class="ripensamento-modal__text">
            Codice contratto<br /><strong>{{
              selectedContractType === 'commodity'
                ? selectedContract.cdPropostaContratto ||
                  selectedContract.codProposta
                : selectedContract.cdPropostaContrattoFibra
            }}</strong>
          </div>
          <div class="ripensamento-modal__continue full-bt-group">
            <v-btn
              class="bt-modal--primary"
              text
              @click="
                $router.push({
                  name: 'afterthought',
                  params: {
                    clientDetail: clientDetail,
                    selectedContract: selectedContract,
                    selectedContractType: selectedContractType
                  }
                })
              "
            >
              Prosegui
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-progress-circular
      v-if="showContractsLoader"
      class="contracts-loader"
      :size="50"
      indeterminate
    ></v-progress-circular>

    <v-progress-circular
      v-if="showContractsLoader"
      class="contracts-loader"
      :size="50"
      indeterminate
    ></v-progress-circular>

    <pdf-viewer
      :showDialog="showInfoConsents"
      :url="infoConsentsUrl"
      @closing-pdfViewer="showInfoConsents = false"
      attachTo=".account"
      height="600px"
      maxWidth="60%"
    />
    <pdf-viewer
      :showDialog="dialogContractPdf && !cancelDowloadPdf"
      :url="contractDataUrl"
      @closing-pdfViewer="dialogContractPdf = false"
      :attachTo="'body'"
      height="600px"
    />
    <Download
      :dialogDownload="dialogDownload"
      @closeModal="cancelDowload"
    ></Download>

    <PhysiCallDialog
      :physicallObj="physiCall"
      :triggerElement.sync="triggerElement"
    ></PhysiCallDialog>

    <FeedbackDialog
      :physicallObj="physiCall"
      :triggerElement.sync="triggerElement"
    />
  </div>
</template>
<script>
import Download from '@/components/download/Download.vue'
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import TcVars from '@/components/tcTracker/TcVars'
import consts from '@/js/constants'
import { b64ToBlob } from '@/js/utils'
import ChangePwd from '@/views/ChangePwd'
import { scroller } from 'vue-scrollto/src/scrollTo'
import { clipperFixed } from 'vuejs-clipper'
import AccountDetail from './AccountDetail.vue'

import { mapActions, mapGetters, mapState } from 'vuex'
// import router from '@/router/router'
import { axiosACN, axiosCMS } from '@/js/axiosInstances.js'
import GenErr from '@/js/genericErrors'

import { AlertService } from '@/js/service/alertService'
import { eventBus } from '@/main.js'
import IconChevronDown from '@assets/icons/chevron.svg'

import PhysiCall from '@/js/service/physiCall'
import PhysiCallDialog from '@/components/physicall/PhysiCallDialog.vue'

import IconChevronUp from '@assets/icons/Chevron_Up.svg'
import _ from 'lodash'

import router from '../../router/router'
import FeedbackDialog from '../../components/physicall/FeedbackDialog.vue'

const KEYNAMES_MAPPING = {
  telefonoFisso: 'phone',
  telefonoCellulare: 'mobilePhone',
  mailContatto: 'email'
}

export default {
  name: 'accountComponent',
  components: {
    ChangePwd,
    pdfViewer: PdfViewer,
    clipperFixed,
    Download,
    AccountDetail,
    TcVars,
    PhysiCallDialog,
    FeedbackDialog
  },
  props: {
    src: [String, Object]
  },
  beforeMount() {
    if (!this.clientDetails) {
      this.getMainClient().then((resp) => {
        this.selectedClient = resp
        this.detailForClient()
      })
    }
    this.getAccountPhoto()
  },
  data() {
    return {
      openPanel: false,
      IconChevronDown,
      IconChevronUp,
      photo: [],
      photoPickDialog: false,
      photoSrc: null,
      showInfoConsents: false,
      dialogContractPdf: false,
      infoConsentsUrl: '',
      selectedClient: null,
      clientsPerRow: 2,
      clientsForAccount: [],
      clientDetail: {},
      pwdDialog: false,
      pwdChangedDialog: false,
      editedContent: {
        data: {
          indirizzoResidenza: '',
          capResidenza: '',
          comuneResidenza: ''
        }
      },
      editDialog: false,
      editAddressDialog: false,
      contracts: [],
      notDigitalContracts: [],
      contractsChanged: {},
      numContractsFound: null,
      contractDataUrl: '',
      sendingEdit: false,
      bus: eventBus,
      infoConsents: null,
      showAfterthoughtButton: {},
      keyAfterthoughtButton: 0,
      showContractsLoader: false,
      dialogAfterthought: false,
      selectedContract: {},
      selectedContractType: '',
      dialogDownload: false,
      cancelDowloadPdf: false,
      consts: consts,
      disableRadio: false,
      physiCall: null,
      triggerElement: null,
      openAccordion: null
    }
  },
  watch: {
    photo(val) {
      this.photoSrc = URL.createObjectURL(val)
    },
    openAccordion(val) {
      if (this.physiCall) {
        this.physiCall.resetAll()
      }
      if (val >= 0) {
        this.physiCall = new PhysiCall(120000, 120000, 2)
        this.physiCall.setJourneySection(this.consts.JOURNEY.TIMER_DOCUMENTI)
      }
    },
    selectedClient: {
      handler: function (val) {
        if (!val) return
        this.contractsChanged = {}
        this.detailForClient()
      },
      deep: true
    },
    pwdDialog(val) {
      if (val) {
        this.bus.$emit('resetForm')
      }
    },
    contracts: function () {
      this.$nextTick(function () {
        if (Object.keys(this.contracts).length > 0) {
          if (this.$route.params.showContract) {
            const firstScrollTo = scroller()
            firstScrollTo('#' + this.$route.params.showContract)
            this.$route.params.showContract = false
          }
        }
      })
    }
  },
  created() {
    this.getSupplies()
    this.getClientsForAccount()
      .then((resp) => {
        let arr = []
        for (const key in resp) {
          const value = resp[key]
          if (
            value.clientOwnerType === 'RES' &&
            value.clientOwnerCode !== this.mainClient.clientOwnerCode
          )
            arr.push(value)
          // if (value.clientOwnerType === 'RES') arr.push(value)
        }
        for (const key in resp) {
          const value = resp[key]
          if (
            value.clientOwnerType === 'BUS' &&
            value.clientOwnerCode !== this.mainClient.clientOwnerCode
          )
            arr.push(value)
          // if (value.clientOwnerType === 'BUS') arr.push(value)
        }
        this.clientsForAccount = arr
      })
      .catch((error) => {
        GenErr.showGenericErrorAlert(error && error.message)
      })
    this.getDocInfoConsents().then((doc) => {
      this.infoConsents = doc.reduce((map, obj) => {
        map[obj.id] = {
          name: obj.name,
          document: axiosCMS.defaults.baseURL + obj.document
        }
        return map
      }, {})
    })
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapState('session', ['isFeedbackShowing']),
    status() {
      if (this.mainClient && this.infoConsents && this.clientDetails) {
        return 'success'
      } else {
        return 'wait'
      }
    },
    accountPhotoSrc() {
      return URL.createObjectURL(this.accountPhoto)
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'account' }, text: 'Profilo' }
      ]

      return res
    },
    ...mapGetters('account', [
      'clientDetails',
      'accountPhoto',
      'accountClients',
      'mainClient'
    ]),
    ...mapGetters('session', ['username'])
  },
  methods: {
    ...mapActions('account', [
      'getClientsForAccount',
      'getDetailForClient',
      'getMainClient',
      'getContractsForClient',
      'updatePhoto',
      'getAccountPhoto',
      'getRetrieveRipensamento',
      'getNotDigitalContracts',
      'getChangedProductContract'
    ]),
    ...mapActions('documents', [
      'getPdfBase64',
      'getDocsByCategory',
      'getDocInfoConsents'
    ]),
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('analytics', ['trackTapEvent']),
    detailForClient() {
      this.getDetailForClient(this.selectedClient).then((resp) => {
        this.clientDetail = resp

        this.trackTapEvent({
          name: 'account_client_tap',
          params: {
            client_code: this.clientDetails.clientCode,
            client_type: this.clientDetails.clientType
          }
        })

        this.getContractsForClient(this.selectedClient).then((resp) => {
          this.numContractsFound =
            resp.contractDocumentsList && resp.contractDocumentsList.length
          const commodityContracts = _.groupBy(
            resp.contractDocumentsList.filter((contr) => {
              return !!contr.cdPropostaContratto
            }),
            'cdPropostaContratto'
          )
          const fiberOnlyContracts = _.groupBy(
            resp.contractDocumentsList.filter((contr) => {
              return (
                !contr.cdPropostaContratto && contr.cdPropostaContrattoFibra
              )
            }),
            'cdPropostaContrattoFibra'
          )

          this.contracts = Object.assign(commodityContracts, fiberOnlyContracts)

          for (let c in this.contracts) {
            let contract = this.contracts[c][0]
            // let formatPropostaContratto = key.split('/')[0]
            if (contract.cdPropostaContratto && !contract.isChangedProduct) {
              this.showAfterthoughtButton[contract.cdPropostaContratto] = false
              this.checkAfterthought(
                contract.cdPropostaContratto,
                contract.dataFirmaContr
              )
            }
            if (
              contract.cdPropostaContrattoFibra &&
              !contract.isChangedProduct
            ) {
              const cdPropostaContrattoFibra = contract.cdPropostaContrattoFibra
              this.showAfterthoughtButton[cdPropostaContrattoFibra] = false
              this.checkAfterthought(
                contract.cdPropostaContrattoFibra,
                contract.dataFirmaContr
              )
            }
          }
        })
        this.getNotDigitalContracts(this.selectedClient).then((resp) => {
          this.notDigitalContracts = _.groupBy(
            resp.contractDocumentsList,
            'codProposta'
          )
          if (this.selectedClient.clientOwnerType === 'RES') {
            for (let c in this.notDigitalContracts) {
              let contract = this.notDigitalContracts[c][0]
              this.showAfterthoughtButton[contract.codProposta] = false
              const dataCreazione = contract.dataCreazione
                .split('/')
                .reverse()
                .join('-')
              this.checkAfterthought(contract.codProposta, dataCreazione)
            }
          }
        })
        this.getChangedProductContract(
          this.selectedClient.clientOwnerCode
        ).then((listaContratti) => {
          if (listaContratti.length > 0) {
            let contractsChanged = {}
            listaContratti.forEach((c) => {
              let supply = this.supplies.find(
                (s) => s.supplyCode === c.codicePunto
              )
              if (c.linkCondizioniContratto) {
                const contract = {
                  docType: 'pdf',
                  isChangedProduct: true,
                  url: c.linkCondizioniContratto,
                  descFile: 'Condizioni generali di contratto',
                  commodityType: supply.serviceType.toLowerCase(),
                  cdPropostaContratto: c.codiceProposta
                }
                if (contractsChanged[c.codiceProposta]) {
                  contractsChanged[c.codiceProposta].push(contract)
                } else {
                  contractsChanged[c.codiceProposta] = [contract]
                }
              }
              if (c.linkModuloAdesione) {
                const model = {
                  docType: 'pdf',
                  isChangedProduct: true,
                  url: c.linkModuloAdesione,
                  descFile: 'Modulo di Adesione'
                }
                if (contractsChanged[c.codiceProposta]) {
                  contractsChanged[c.codiceProposta].push(model)
                } else {
                  contractsChanged[c.codiceProposta] = [model]
                }
              }
            })
            this.contractsChanged = contractsChanged
          }
        })
      })
    },
    onAccountLoaded(clientOwnerCode) {
      if (
        Object.keys(this.accountClients).length === 1 &&
        !this.$route.params.clientOwnerCode
      ) {
        document.getElementById(clientOwnerCode).click()
      } else if (this.$route.params.clientOwnerCode) {
        if (clientOwnerCode === this.$route.params.clientOwnerCode) {
          document.getElementById(clientOwnerCode).click()
          this.$nextTick(() => {
            this.$nextTick(() => {
              let firstScrollTo = scroller()
              if (this.$route.params.showConsentsClient) {
                firstScrollTo('#checkarea')
              }
            })
          })
        }
      }
    },
    handleClick() {
      this.trackTapEvent('account_loadPhotoGallery_tap')
      document.getElementById('photo-upload').click()
    },
    abortPhoto() {
      this.photoSrc = null
    },
    clippImage() {
      const type = 'image/jpg'
      const quality = 1
      let clippedImg = this.$refs.clipper
        .clip({ maxWPixel: 800 })
        .toDataURL(type, quality)
      return {
        b64Data: clippedImg,
        mimeType: clippedImg.split(';')[0].split(':')[1]
      }
    },
    async uploadPhoto() {
      this.trackTapEvent('account_confirmPhoto_tap')
      let data = new FormData()
      const blob = await b64ToBlob(
        this.clippImage().b64Data,
        'fotoProfilo',
        this.clippImage().mimeType
      )
      data.append('fotoProfilo', blob)
      this.updatePhoto(data).then(() => {
        this.photoPickDialog = false
        this.abortPhoto()
      })
    },
    logout() {
      this.trackTapEvent('account_logout_tap')
      this.$store.dispatch('session/logout')
      router.push({ name: 'login' })
    },
    async checkAfterthought(contractId, contractSignatureDate) {
      const isDateExpired = () => {
        return (
          new Date().getTime() - new Date(contractSignatureDate).getTime() >
          14 * 24 * 60 * 60 * 1000
        )
      }

      let afterthoughtRequests =
        JSON.parse(localStorage.getItem('afterthoughtRequests')) || null

      let requestAlreadyPresent = false

      if (afterthoughtRequests && afterthoughtRequests[contractId]) {
        requestAlreadyPresent = true
      } else {
        let resp = await this.getRetrieveRipensamento(contractId)

        requestAlreadyPresent = !!resp?.flagRipensamento
      }

      this.showAfterthoughtButton[contractId] =
        !requestAlreadyPresent && !isDateExpired()
      this.keyAfterthoughtButton = this.keyAfterthoughtButton + 1
      this.$forceUpdate()
    },
    async submitEdit(editedContent) {
      let paramActionName = ''
      switch (this.editedContent.nameKey) {
        case 'telefonoFisso':
          paramActionName = 'phone_home'
          break
        case 'telefonoCellulare':
          paramActionName = 'phone_mobile'
          break
        case 'mailContatto':
          paramActionName = 'email'
          break
        case 'addressContatto':
          paramActionName = 'address'
          break
      }
      this.trackTapEvent({
        name: 'account_clientContactModifyConfirm_tap',
        params: {
          client_code: this.clientDetails.clientCode,
          client_type: this.clientDetails.clientType,
          contact_type: paramActionName || 'consent'
        }
      })

      try {
        this.sendingEdit = true
        let params = {
          codiceCliente: this.clientDetail.clientCode,
          street: this.clientDetail.residentialAddress,
          zipCode: this.clientDetail.residentialCAP,
          city: this.clientDetail.residentialCity,
          provincial: this.clientDetail.residentialProvince,
          nation: this.clientDetail.residentialNation,
          phone: this.clientDetail.phone,
          mobilePhone: this.clientDetail.mobilePhone,
          email: this.clientDetail.contactMail,
          thirdParties: !this.clientDetail.thirdPartConsentFlag,
          marketDenial: !this.clientDetail.marketingConsentFlag,
          profilazione: !this.clientDetail.profilingConsentFlag,
          channel: 'Web'
        }

        if (editedContent) {
          if (paramActionName === 'address') {
            params['street'] = editedContent.data.indirizzoResidenza
            params['zipCode'] = editedContent.data.capResidenza
            params['city'] = editedContent.data.comuneResidenza
          } else {
            params[KEYNAMES_MAPPING[editedContent.nameKey]] =
              editedContent.value
          }
        }

        const paramsToBeNulledIfEmpty = ['phone', 'mobilePhone']

        for (let p in params) {
          if (paramsToBeNulledIfEmpty.includes(p)) {
            params[p] = params[p] === '' ? null : params[p]
          }
        }

        await this.$store.dispatch('account/editcontent', params)

        this.editDialog = false
        this.editAddressDialog = false

        // Update the view locally without reloading the store data
        if (paramActionName === 'address') {
          _.extend(this.clientDetail, editedContent.data)
        } else {
          this.clientDetail[editedContent.nameKey] = editedContent.value
        }
        this.sendingEdit = false
      } catch (error) {
        // We do not expect any business logic error, so show an alert with generic error
        GenErr.handleGenericError(error && error.message)
        this.sendingEdit = false
      }
    },
    consentChanged({ name, value }) {
      this.clientDetail[name] = value
    },
    editingContent(field) {
      // this.editDialog = true
      // let fieldChoice = ''
      this.editedContent.name = field
      this.editedContent.codiceCliente = this.selectedClient.clientOwnerCode
      let paramActionName = ''
      if (!this.clientDetail.contactMail) {
        AlertService(
          'Modifica dei dati personali',
          "Per poter procedere con la modifica dei tuoi dati è necessario inserire anche la tua email di contatto. Puoi richiedere l’aggiunta di questa email e la modifica degli altri dati contattando l'assistenza.",
          'alert',
          'Contatta l’assistenza',
          'Contacts',
          false,
          false,
          { user: this.selectedClient.clientOwnerCode },
          false
        )
      } else {
        switch (field) {
          case 'Telefono Fisso':
            this.editedContent.value = this.clientDetail.phone
            this.editedContent.nameKey = 'telefonoFisso'
            paramActionName = 'phone_home'
            this.editDialog = true
            break
          case 'Telefono Cellulare':
            this.editedContent.value = this.clientDetail.mobilePhone
            this.editedContent.nameKey = 'telefonoCellulare'
            paramActionName = 'phone_mobile'
            this.editDialog = true
            break
          case 'Email di Contatto':
            this.editedContent.value = this.clientDetail.contactMail
            this.editedContent.nameKey = 'mailContatto'
            paramActionName = 'email'
            this.editDialog = true
            break
          case 'Indirizzo di residenza':
            this.editedContent.nameKey = 'addressContatto'
            this.editedContent.data =
              // this.clientDetail
              {
                indirizzoResidenza: this.clientDetail.residentialAddress,
                capResidenza:
                  this.clientDetail.residentialCAP || this.clientDetail.zipCode,
                comuneResidenza: this.clientDetail.residentialCity
              }
            paramActionName = 'address'
            this.editAddressDialog = true
            break
        }
      }

      this.trackTapEvent({
        name: 'account_clientContactModify_tap',
        params: {
          client_code: this.clientDetails.clientCode,
          client_type: this.clientDetails.clientType,
          contact_type: paramActionName
        }
      })
    },
    getUrlForFile(event, url, id) {
      this.triggerElement = event
      let isPhysicallShowing = this.physiCall.checkCountClick(id)
      this.physiCall.setJourneyDoc(this.consts.JOURNEY.DOWNLOAD_DOCUMENTI)
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        this.triggerElement = event
        const decriptedUrl = atob(url)
        window.open(decriptedUrl, '_blank').focus()
      }
    },
    getContractFileUrl(event, contract, isDigital = true) {
      // TODO: remove the host part when in prod. needed for localhost only

      this.triggerElement = event
      let isPhysicallShowing = this.physiCall.checkCountClick(contract.id)
      this.physiCall.setJourneyDoc(this.consts.JOURNEY.DOWNLOAD_DOCUMENTI)
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        this.dialogDownload = true
        this.cancelDowloadPdf = false
        const host = axiosACN.defaults.baseURL

        this.trackTapEvent({
          name: 'account_clientContract_tap',
          params: {
            client_code: this.clientDetails.clientCode,
            client_type: this.clientDetails.clientType,
            doc_name: contract.descFile
          }
        })
        let url = ''
        if (contract.isChangedProduct) {
          url = contract.url
        } else if (isDigital) {
          url =
            host +
            '/sorgeniadataclient/retrievePDFContract?iddocumenti=' +
            contract.id +
            '&codiceCliente=' +
            this.clientDetails.clientCode
        } else {
          url = contract.url

          this.trackTapEvent({
            name: 'account_clientContract_tap',
            params: {
              client_code: this.clientDetails.codiceCliente,
              client_type: this.clientDetails.tipoClienteIntestatario,
              doc_name: contract.descFile
            }
          })
          let url = ''
          if (contract.isChangedProduct) {
            url = contract.url
          } else if (isDigital) {
            url =
              host +
              '/sorgeniadataclient/retrievePDFContract?iddocumenti=' +
              contract.id +
              '&codiceCliente=' +
              this.clientDetails.codiceCliente
          } else {
            url = contract.url

            this.contractDataUrl = url // plain url string, NO base64data
            this.dialogContractPdf = true
            this.dialogDownload = false

            return
          }
        }
        this.showContractsLoader = true
        axiosACN({
          url: url,
          method: 'GET',
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + this.$store.getters['session/token']
          }
        }).then((resp) => {
          let self = this

          var reader = new FileReader()
          reader.readAsDataURL(resp.data)
          reader.onloadend = async function () {
            var base64data = reader.result

            const blob = await b64ToBlob(
              base64data,
              'documento',
              'application/pdf'
            )
            const blobUrl = URL.createObjectURL(blob)

            self.showContractsLoader = false
            self.contractDataUrl = blobUrl // base64data
            self.dialogContractPdf = true
            self.dialogDownload = false
          }
        })
      }
    },
    getDocumentFileUrl(event, file) {
      this.triggerElement = event
      let isPhysicallShowing = this.physiCall.checkCountClick(file.id, true)
      this.physiCall.setJourneyDoc(this.consts.JOURNEY.DOWNLOAD_DOCUMENTI)
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        const host = axiosCMS.defaults.baseURL
        const url = host + file.document
        window.open(url, '_blank')
      }
    },
    // getDocumentFileUrl(fileId) {
    //   const host = axiosCMS.defaults.baseURL
    //   const url = host + fileId
    //   return url
    // },
    passwordChanged() {
      this.pwdDialog = false
      this.pwdChangedDialog = true
    },
    openDocInfoConsents(id) {
      this.trackTapEvent('account_clientInfoConsentsPrivacyDoc_tap')
      // switch (id) {
      //   case 'informativa-privacy-cliente':
      //     this.trackTapEvent('account_infoMarketingSorgenia_tap')
      //     break
      //   case 'marketing':
      //     this.trackTapEvent('account_infoMarketingSorgenia_tap')
      //     break
      //   case 'third-party':
      //     this.trackTapEvent('account_infoMarketingThird_tap')
      //     break
      //   case 'profiling':
      //     this.trackTapEvent('account_infoProfiling_tap')
      //     break
      // }
      this.infoConsentsUrl = this.infoConsents[id].document
      this.showInfoConsents = true
    },
    openDialogAfterthought(contract, type) {
      this.trackTapEvent({
        name: 'account_contractsRethinking_tap',
        params: {
          client_code: this.clientDetail.clientCode,
          client_type: this.clientDetail.clientType?.toLowerCase(),
          supply_type: type.toLowerCase()
        }
      })
      this.dialogAfterthought = true
      this.selectedContract = contract
      this.selectedContractType =
        type.toLowerCase() === 'fiber' ? 'fiber' : 'commodity'
    },
    cancelDowload() {
      this.cancelDowloadPdf = true
      this.dialogDownload = false
      this.showContractsLoader = false
    }
  }
}
</script>
<style scoped>
.text {
  color: black;
}
.std-side-padding-profile {
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}
</style>
<style lang="scss">
.bordered {
  border-bottom: 1px solid #b7bfd2;
}
.modifica {
  color: #12256a;
  cursor: pointer;
}

.colorblack {
  color: black;
}
.account {
  .v-expansion-panel {
    .v-expansion-panel-header__icon {
      svg {
        transition: transform 0.25s ease;
        transform: rotate(0deg);
      }
    }
  }

  .v-expansion-panel--active {
    .v-expansion-panel-header__icon {
      svg {
        transition: transform 0.25s ease;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
<style lang="scss">
.consensi {
  .v-input__control > .v-input__slot > .v-input--radio-group__input {
    flex-direction: row !important;

    .v-radio:not(:last-child):not(:only-child) {
      margin-bottom: 0;
    }
  }
}

.v-tabs-slider-wrapper {
  height: 3px !important;
}
</style>
<style lang="scss" scoped>
.contracts-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.photo-dialog {
  min-height: 280px;

  h2 {
    font-size: 32px;
    font-weight: bold;
    line-height: 44px;
  }

  p {
    font-size: 20px;
    color: black;
  }

  .dialog__img {
    cursor: pointer;
    margin-top: 20px;
    margin-right: 20px;
  }

  .btnlabel {
    h5 {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.2px;
      text-align: left;
    }
  }

  .normal {
    letter-spacing: normal;
  }

  .clipper {
    max-width: 465px;
    min-width: 150px;
  }
}

.account {
  margin-top: 40px;

  &__panel {
    padding: 24px 24px 27px 24px;
    border: 1px solid #dfe4ef;
    border-radius: 8px !important;

    &.v-expansion-panel:not(:first-child)::after {
      border-top: none;
    }

    .icon {
      width: 16px;
      height: auto;
      margin-right: 24px;

      &.bus::after {
        content: url(~@/assets/icons/ic-business.svg);
      }

      &.res::after {
        content: url(~@/assets/icons/ic-owner-res.svg);
      }
    }

    .name {
      color: black !important;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: 1.3px;
    }

    .code {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.4;
      letter-spacing: 1px;
      color: black;
    }
  }

  &__photo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: relative;

    .mainPhoto {
      border-radius: 50%;
      border: 1px solid #eee;
    }

    .photoPicker {
      width: 32px;
      height: 32px;
      background-color: $srg-blue;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .account__registry {
    margin-bottom: 30px;

    .registry__title {
      h1 {
        font-weight: 500;
        letter-spacing: 0.8px;
      }
    }

    .registry__btn {
      button {
        width: 200px;
      }
    }

    .registry__subtitle {
      margin-top: 50px;
      letter-spacing: 1.2px;

      h2 {
        font-weight: bold;
      }
    }

    .registry__infoTitle {
      margin-top: 50px;

      .labelName {
        font-family: Roboto;
        color: #6e7689;
        font-size: 14px;
        letter-spacing: 1.2px;
        font-weight: 700;
        line-height: 16.8px;
      }

      h5 {
        font-weight: bold;
        letter-spacing: 1px;
      }
    }

    .clients__button {
      h3 {
        font-weight: bold;
      }

      h4 {
        margin-top: 7px;

        span {
          font-weight: bold;
        }
      }
    }
  }

  &__custom-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__custom-h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    max-width: 95%;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding-right: 35px;

    &:after {
      content: url(~@/assets/icons/ic-owner-res-black.svg);
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0px;
      right: -11px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 10%
      );
      z-index: 9;
    }

    &.icon-bus:after {
      content: no-close-quote;
      background: url(~@/assets/icons/business_black.svg);
      background-size: auto 100%;
      background-repeat: no-repeat;
      height: 16px;
      right: -5px;
      width: 16px;
    }
  }

  &__custom-h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #000000;
    padding: 7px 0;
  }

  .v-input--selection-controls.v-input {
    margin-top: 0;

    > .v-input__slot {
      height: auto;

      > .v-input--radio-group__input {
        flex-direction: row;

        .v-radio:not(:last-child):not(:only-child) {
          margin-bottom: 0;
        }
      }
    }
  }

  .download-section {
    .contentTab__data--custom {
      &:nth-child(even) {
        padding-top: 0 !important;
      }

      &:last-child {
        padding-bottom: 30px;
      }
    }
  }

  a.download-button > span {
    word-break: break-all;
    white-space: normal;
  }

  .ripensamento-modal {
    &__title {
      margin-bottom: 20px;
    }
  }

  .v-tab {
    border-bottom: 4px solid #ccc;
    text-transform: initial;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .v-tab:not(.v-tab--active) {
    color: #b7bfd2 !important;
  }

  .associate {
    .btn {
      height: 54px;
      width: 191px;
      border: 1px solid #ccc;
      border-radius: 20px;
      cursor: pointer;
      color: $srg-blue;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.2px;
    }
  }
}
</style>
