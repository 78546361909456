import { render, staticRenderFns } from "./Contacts.vue?vue&type=template&id=3a9e07d9&scoped=true"
import script from "./Contacts.vue?vue&type=script&lang=js"
export * from "./Contacts.vue?vue&type=script&lang=js"
import style0 from "../../styles/support/_main.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./Contacts.vue?vue&type=style&index=1&id=3a9e07d9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9e07d9",
  null
  
)

export default component.exports