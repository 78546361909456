<template>
  <div class="welcome">
    <div class="welcome std-side-padding" v-if="loaded && client && welcomekit">
      <v-row class="welcome__header">
        <v-col md="6" cols="12" sm="6" class="align-self-center">
          <v-breadcrumbs
            :items="breadcrumbsItems"
            divider=">"
            class="pb-0"
            v-if="!isMobile"
          >
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
          <h1 class="welcome__title" v-html="welcomekit.inner_title"></h1>
          <h2 class="welcome__subtitle" v-html="welcomekit.intro_text"></h2>
          <h3 class="welcome__title" v-html="welcomekit.body"></h3>
        </v-col>
        <v-col
          cols="12"
          v-if="isMobile && isSmallDevice"
          class="d-flex justify-center align-center"
        >
          <img :src="require('@assets/welcome/icChevronDownWhiteBig.png')" />
        </v-col>
        <v-col md="6" cols="12" sm="6" class="welcome__img">
          <!-- <img :src="isSmallDevice? require('@assets/welcome/' + welcomekit.inner_mobile_image) : require('@assets/welcome/' + welcomekit.inner_image)"/> -->
          <img
            :src="
              isSmallDevice
                ? cdn +
                  (welcomekit.inner_image_mobile || welcomekit.inner_image)
                : cdn + welcomekit.inner_image
            "
          />
          <v-img
            v-if="isMobile"
            @click="$router.push('/welcome-kit/done')"
            class="close"
            contain
            width="16"
            src="@assets/welcome/ic-close-white-big.png"
          />
        </v-col>
      </v-row>
      <v-row class="welcome__carousel">
        <WelcomeCarousel
          name="welcome"
          :slides="slides"
          :is-mobile="isMobile"
          :type="client.type"
        />
      </v-row>
    </div>
    <div
      class="welcome__empty d-flex justify-center"
      v-else-if="client && loaded && welcomekit === null"
    >
      <div
        v-if="isMobile"
        class="closeMobile d-flex align-center justify-center"
      >
        <h5 class="welcome__empty--title">
          <strong>Il valore di Sorgenia</strong>
        </h5>
        <img
          src="../../assets/icons/close-blu.svg"
          width="24"
          @click="$router.push('/welcome-kit/done')"
        />
      </div>
      <div class="welcome__alert">
        <div class="d-flex justify-space-between align-center mb-5">
          <h3>Ops! {{ welcomekit }}</h3>
          <img
            v-if="!isMobile"
            src="../../assets/icons/close-blu.svg"
            width="24"
            @click="$router.push({ name: 'home' })"
          />
        </div>
        <h5 class="mb-8">
          Al momento non sono presenti contenuti per il tuo profilo
        </h5>
        <div class="d-flex justify-center">
          <v-btn
            @click="
              isMobile
                ? $router.push('/welcome-kit/done')
                : $router.push({ name: 'home' })
            "
            width="271"
            height="54"
            color="primary"
            class="v-btn--shadowed btn-alert"
          >
            Torna alla homepage
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else>
      <v-row class="std-side-padding pa-12" align="center">
        <v-col class="text-center">
          <v-progress-circular :size="50" indeterminate />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { ENV_CONFIGS } from '@/js/configs'
import { checkMobile, chunkArray } from '@/js/utils.js'
import WelcomeCarousel from '../../components/welcomeTutorial/WelcomeCarousel'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'welcomeKit',
  components: {
    WelcomeCarousel
  },
  data() {
    return {
      cdn: ENV_CONFIGS.CMS_CDN_BASE_URL,
      isMobile: checkMobile(),
      isSmallDevice: window.innerWidth <= 480,
      isBigDevice: window.innerWidth > 480 && window.innerWidth <= 1024,
      isDesktop: window.innerWidth > 1024,
      loaded: false
    }
  },
  methods: {
    ...mapActions('feedback', ['loadSurvey', 'loadUndoneSurvey']),
    ...mapActions('supply', ['getSupplies'])
  },
  computed: {
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['welcomekit', 'wkSlidesForUser']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'welcome-kit' }, text: 'Valore di Sorgenia' }
      ]

      return res
    },
    slides() {
      let allSlides = this.wkSlidesForUser || []

      let slidePerPage = this.isSmallDevice ? 1 : 2
      let chunkedSlides = chunkArray(allSlides, slidePerPage)
      let slides = []

      chunkedSlides.forEach((pageSlides) => {
        slides.push({ slide: [...pageSlides] })
      })

      return slides
    }
  },
  async created() {
    await this.loadSurvey()
    await this.loadUndoneSurvey()
  },
  async mounted() {
    try {
      await this.$store.dispatch('welcomekit/getWelcomekitByUserProfile', {
        client: this.client
      })
      await this.$store.dispatch('welcomekit/getTutorialByUserProfile', {
        client: this.client
      })
      await this.$store.dispatch('welcomekit/getSurveyByUserProfile', {
        client: this.client
      })
    } catch (err) {
      console.error(err)
    } finally {
      this.loaded = true
    }
  }
}
</script>
<style lang="scss" scoped>
.warning-title {
  font-size: 28px;
  font-weight: 600;
}
.rounded-card {
  border-radius: 20px !important;
}
.welcome {
  color: black;
  // padding: 28px calc((100% - 1140px) / 2) 0;
  position: relative;
  z-index: 100;
  background-color: $srg-green;
  height: 100%;
  &__empty {
    padding-top: 82px;
    background-image: url('../../assets/welcome/wk-bkg.png');
    background-size: cover;
    height: 100%;
  }
  &__alert {
    width: 555px;
    height: 266px;
    border-radius: 20px;
    padding: 40px;
    background-color: white;
    h3 {
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 1px;
    }
    h5 {
      padding: 0 12px;
      color: black;
      letter-spacing: 1px;
    }
    img {
      cursor: pointer;
    }
  }
  &__header {
    position: relative;
    h1 {
      font-size: 60px;
      font-weight: bold;
      line-height: 70px;
      margin-bottom: 48px;
      span {
        color: black;
      }
    }
    h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 48px;
    }
    h3 {
      font-size: 20px;
    }
    .close {
      position: absolute;
      top: 40px;
      right: 20px;
      cursor: pointer;
    }
  }
  &__img {
    img {
      width: 555px;
    }
  }
  &__carousel {
    h3,
    h6 {
      color: black;
    }
    h3 {
      line-height: 26px;
    }
    h6 {
      font-size: 14px;
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .welcome {
    overflow: hidden;
    position: relative;
    &__empty {
      padding-top: 70px;
      background-image: initial;
      background-color: white;
      .closeMobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
        padding-right: 20px;
        background-color: $srg-green;
        img {
          position: absolute;
          right: 10px;
        }
      }
    }
    &__alert {
      width: 100%;
      height: 100%;
      border-radius: initial;
      padding: 24px 16px;
      background-color: white;
      h5 {
        padding: 0px;
      }
      .btn-alert {
        width: 100% !important;
      }
    }
    .col-sm-12 {
      padding-left: 32px;
      padding-right: 32px;
    }
    &__header {
      h1 {
        width: 250px;
        font-size: 39px;
        line-height: 45px;
        margin-bottom: 19px;
        margin-top: 90vw;
        span {
          color: black;
        }
      }
      h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      h3 {
        font-size: 16px;
        line-height: 22px;
      }
      .close {
        position: absolute;
        top: 50px;
        right: 18px;
        cursor: pointer;
      }
    }
    &__img {
      img {
        position: absolute;
        top: -40px;
        right: -5px;
        width: 90vw;
        max-width: 555px;
      }
    }
    .v-card {
      height: 386px;
    }
  }
}
@media (min-width: 481px) and (max-width: 1024px) {
  .welcome {
    &__header {
      h1 {
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 24px;
        margin-bottom: 30px;
      }
      h3 {
        font-size: 18px;
      }
    }
    overflow: hidden;
    position: relative;
    padding: 28px 40px;
    &__img {
      img {
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.welcome__title {
  span {
    color: black;
  }
}

.welcome {
  .v-window__next,
  .v-window__prev {
    top: 35%;
  }
  .v-btn--icon.v-size--default {
    height: 62px !important;
    width: 62px !important;
    background: rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  html {
    min-width: initial !important;
    max-width: 100vw !important;
  }
  .v-carousel__controls {
    background: initial;
    .v-btn--icon.v-size--small {
      height: 16px;
      width: 16px;
      margin: 0 3px;
      opacity: 0.5;
      &.v-item--active {
        opacity: 1;
      }
    }
    .v-btn:before {
      background-color: initial;
    }
    .mdi-minus::before {
      font-size: 25px;
      font-weight: bold;
    }
  }
}
@media (min-width: 481px) and (max-width: 1024px) {
  html {
    min-width: initial !important;
    max-width: 100vw !important;
  }
}
</style>
