<template>
  <page
    title="Seleziona nuovo metodo di pagamento"
    page-name="Metodo di pagamento"
    @addOption="optionDialog"
    :loading="loading"
    :columnTitle="true"
    :disabled="disabled"
  >
    <PaymentMethod
      v-if="selectedClient"
      :client="selectedClient"
      :cancelOption="cancelOption"
      :paymentOptionData.sync="data"
      @openConfirmDialog="choosePaymentDialog"
      @resetOption="cancelOption = false"
    />
    <v-dialog
      persistent
      overlay-opacity="0.6"
      :value="confirmDialog"
      max-width="576"
    >
      <v-card
        class="option-dialog"
        :style="`min-height: ${newOptionDialog ? '447px' : '360px'}`"
      >
        <v-row>
          <v-col class="pl-11 mt-8 mb-2">
            <h2 v-html="newOptionDialog ? newConfirmText : optionConfirmText" />
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col class="pl-7" align="center">
            <v-btn
              color="white"
              class="v-btn--shadowed normal"
              x-large
              min-width="224"
              @click="abortDialog"
            >
              Annulla
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-if="newOptionDialog"
              color="primary"
              class="v-btn--shadowed normal"
              x-large
              min-width="224"
              @click="$router.push({ name: 'newPaymentMethod' })"
            >
              Conferma
            </v-btn>
            <v-btn
              v-else
              color="primary"
              class="v-btn--shadowed normal"
              x-large
              min-width="224"
              @click="submitOptionChange"
            >
              Conferma
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </page>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PaymentMethod from './PaymentMethod'
import Template from './Template'

export default {
  name: 'PaymentAction',
  props: {},
  components: {
    PaymentMethod,
    page: Template
  },
  data: () => ({
    selectedClient: null,
    details: null,
    data: null,
    loading: false,
    paymentDialog: false,
    newOptionDialog: false,
    confirmDialog: false,
    newConfirmText:
      'Stai procedendo alla registrazionde di un nuovo metodo di pagamento da associare a questa utenza.<br> Sei sicuro di voler proseguire?',
    optionConfirmText:
      'Stai modificando il metodo di pagamento associato a questa utenza.<br>Sei sicuro di voler proseguire?',
    cancelOption: false
  }),
  beforeMount() {
    this.getDetailForClient({
      clientOwnerCode: this.$route.params.clientOwnerCode
    }).then((accountDetails) => {
      this.getClientsForAccount().then((res) => {
        let account = Object.values(res)
        account = account.filter(
          (client) =>
            this.$route.params.clientOwnerCode === client.clientOwnerCode
        )[0]

        const clientWithDetails = {
          ...accountDetails,
          ...account
        }
        this.setClient(clientWithDetails)
        this.selectedClient = this.$store.getters['upsellingFiber/client']
      })
    })
  },

  computed: {
    ...mapGetters('account', ['clientDetails']),
    disabled() {
      return !this.data
    }
  },
  methods: {
    tranformExpirationDateFormat(dateStr) {
      // format IN = MM/AAAA
      // format OUT =  AAMM
      return dateStr.split('/')[1].substring(2) + dateStr.split('/')[0]
    },
    submitOptionChange() {
      if (this.data) {
        let body
        if (this.data.creditCardCircuit) {
          body = {
            xaliasPan: this.data.aliasPan,
            bankIBAN: '',
            circuit:
              this.data.creditCardCircuit.toLowerCase() === 'visa'
                ? '01'
                : '02',
            creditCardCode: this.data.creditCardNumber.replace(/\*/g, ''),
            creditCardHolder: this.data.businessName, // creditCardOwner also have that field but its empty
            expireDateCC: this.tranformExpirationDateFormat(
              this.data.creditCardExpirationDate
            ),
            firstName: this.data.businessName.split(' ')[0],
            fiscalCodeCust: '',
            fiscalCodeHolder: '',
            interactionSubtypology: '',
            lastName: this.data.businessName.split(' ')[1],
            login: this.$store.state.session.username,
            mcsExternalId: this.clientDetails.clientCode,
            nameAlias: this.data.businessName,
            note: '',
            codicePunto: this.$route.params.supplyCode,
            signatureDate: '',
            swiftCode: '',
            tipology: '',
            crecurr: this.data.crecurr
          }
          this.loading = true
          this.addMdPCreditCard(body)
            .then(() => {
              this.loading = false
              this.$store.commit(
                'supply/resetSupplyDetailByCode',
                this.$route.params.supplyCode
              )
              this.resetState()
              this.$router.push({
                name: 'promSuccess',
                params: { type: 'payment' }
              })
            })
            .catch((error) => {
              GenErr.handleGenericError(error && error.message)
              GenErr.showErrorMessage(
                "Si è verificato un errore durante l'operazione."
              )
              this.loading = false
            })
        } else {
          body = {
            mcsExternalId: this.clientDetails.clientCode,
            codicePunto: this.$route.params.supplyCode,
            login: this.$store.state.session.username,
            bankIBAN: this.data.iban,
            nameAlias: this.data.businessName,
            fiscalCodeHolder: this.data.ibanSubscriberFiscalCode,
            firstName: this.data.ibanSubscriberFirstName,
            lastName: this.data.ibanSubscriberLastName,
            fiscalCodeCust: this.data.fiscalCodeVAT,
            codiceCliente: this.clientDetails.clientCode
          }
          this.loading = true
          this.ibanRequest(body)
            .then(() => {
              this.loading = false
              this.$store.commit(
                'supply/resetSupplyDetailByCode',
                this.$route.params.supplyCode
              )
              this.resetState()
              this.$router.push({
                name: 'promSuccess',
                params: { type: 'payment' }
              })
            })
            .catch((error) => {
              this.confirmDialog = false
              this.loading = false

              if (error.message === 'SAME_CASE_IN_PROGRESS') {
                GenErr.showErrorMessage(
                  "E' in corso un'operazione di modifica del metodo di pagamento per questa fornitura. Attenderne il completamento per richiedere nuove modifiche. Grazie!"
                )
              } else {
                GenErr.handleGenericError(error && error.message)
                GenErr.showErrorMessage(
                  "Si è verificato un errore durante l'operazione."
                )
              }
            })
        }
      }
    },
    ...mapActions('account', [
      'getMainClient',
      'getClientsForAccount',
      'getDetailForClient'
    ]),
    ...mapActions('payment', ['ibanRequest', 'addMdPCreditCard']),
    ...mapMutations('upsellingFiber', ['setClient']),
    ...mapMutations('supply', ['resetState']),
    optionDialog() {
      this.confirmDialog = true
      this.newOptionDialog = true
    },
    choosePaymentDialog() {
      this.confirmDialog = true
      this.paymentDialog = true
    },
    abortDialog() {
      this.confirmDialog = false
      this.newOptionDialog = false
      if (this.paymentDialog) {
        this.cancelOption = true
      }
      this.paymentDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.option-dialog {
  min-height: 360px;
  h2 {
    font-size: 32px;
    font-weight: bold;
    line-height: 44px;
  }

  p {
    font-size: 20px;
    color: black;
  }
  .normal {
    font-weight: bold;
    letter-spacing: normal;
  }
}
</style>
