<template>
  <div class="cfp">
    <div class="header-cfp">
      <div class="header-cfp-top">
        <div class="impact">
          <img src="@assets/fotovoltaico/trees.svg" class="mr-2" />
          <span class="title">impatto ambientale</span>
        </div>
        <div class="thanks">
          Grazie al tuo impianto fotovoltaico hai avuto questo impatto
          ambientale.
        </div>
      </div>
      <div class="right">
        <div class="weigth">
          {{ formatUndefined }}
        </div>
        <div class="emissions">Emissioni complessive evitate</div>
      </div>
    </div>
    <div class="cfp-link" @click="handleClick">
      <span>Monitora l'impatto ambientale</span>
      <img src="@assets/checkup-energetico/chevron-rx-blue.svg" class="ml-2" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cfp: { type: String, required: true },
    handleClick: { type: Function, required: true }
  },
  computed: {
    formatUndefined() {
      let format = this.cfp.split(' ')
      if (format[0] == undefined || format[0] === 'undefined') {
        return '0 t'
      } else {
        return parseFloat(format[0]).toFixed(2).replace('.', ',') + ' t'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cfp {
  background-color: #eaeef5;
  border: solid 1px #dfe4ef;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 48px;
}
.header-cfp {
  display: flex;
  justify-content: space-between;
}
.header-cfp-top {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.impact {
  display: flex;
  align-items: center;
  gap: 8px;
}

.title {
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 1.2px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.thanks {
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 22.4px;
}
.right {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.weigth {
  font-size: 24px !important;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 28.8px;
}
.emissions {
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 18.2px;
  color: #6e7689;
}
.cfp-link {
  font-size: 18px !important;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 25.2px;
  color: #6e7689;
  cursor: pointer;
  color: #12256a;
  display: flex;
  align-items: center;
}
</style>
