import { axiosACN } from '@/js/axiosInstances.js'
import axios from 'axios'
import { OVERALL_STATUS } from '@/js/constants.js'
import functionsUtilityFV from '@/js/fotovoltaico/functions'
import { state } from '@/js/fotovoltaico/objects.js'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  actions: {
    photovoltaicInstallationDetails({ rootGetters, commit }, body) {
      return axiosACN
        .post(`/photovoltaic/photovoltaicInstallationDetails`, body, {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        })
        .then((response) => {
          commit('setInstallation', response.data)
        })
        .catch(() => {})
    },

    async retrieveProductionKPI({ rootGetters, commit }, body) {
      let granularity = body.granularity
      let newGranularity = functionsUtilityFV.mappingGranularity(
        body.granularity
      )
      const newBody = {
        ...body,
        startDate: body.startDate.replace(/\.\d{3}Z$/, 'Z'),
        endDate: body.endDate.replace(/\.\d{3}Z$/, 'Z'),
        granularity: newGranularity
      }
      try {
        const response = await axiosACN.post(
          `/photovoltaic/retrieveProductionKPI`,
          newBody,
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )

        let energyProduction = response.data.stats?.energyProduction || []
        let energyToBattery = response.data.stats?.energyToBattery || []
        let energyToBatteryPercentage =
          response.data.stats?.energyToBatteryPercentage || []
        let selfConsumptionPercentage =
          response.data.stats?.selfConsumptionPercentage || []
        let energyToNetwork = response.data.stats?.energyToNetwork || []
        let energyToNetworkPercentage =
          response.data.stats?.energyToNetworkPercentage || []
        let selfConsumption = response.data.stats?.selfConsumption || []

        //calcolo valori totali per granularità e medie

        let energyProductionSum = functionsUtilityFV.sumValues(energyProduction)
        let selfConsumptionSum = functionsUtilityFV.sumValues(selfConsumption)
        let energyToNetworkSum = functionsUtilityFV.sumValues(energyToNetwork)
        let energyToBatterySum = functionsUtilityFV.sumValues(energyToBattery)

        let energyToNetworkPercentageSum = functionsUtilityFV.sumValues(
          energyToNetworkPercentage
        )

        let selfConsumptionPercentageSum = functionsUtilityFV.sumValues(
          selfConsumptionPercentage
        )

        let energyToBatteryPercentageSum = functionsUtilityFV.sumValues(
          energyToBatteryPercentage
        )

        let selfConsumptionPercentageAverage =
          selfConsumptionPercentageSum / selfConsumptionPercentage.length

        let energyToNetworkPercentageAverage =
          energyToNetworkPercentageSum / energyToNetworkPercentage.length

        let energyToBatteryPercentageAverage =
          energyToBatteryPercentageSum / energyToBatteryPercentage.length

        let categorie = []
        let larghezzaColonne = 15

        let valuesSelfConsumption = functionsUtilityFV.defineArrayValues(
          granularity,
          selfConsumption
        )
        let valuesEnergyToBattery = functionsUtilityFV.defineArrayValues(
          granularity,
          energyToBattery
        )
        let valuesEnergyToNetwork = functionsUtilityFV.defineArrayValues(
          granularity,
          energyToNetwork
        )

        categorie = functionsUtilityFV.defineCategories(granularity)
        larghezzaColonne = functionsUtilityFV.defineColumnWidth(granularity)

        let date = new Date()
          .toLocaleDateString('it-IT', {
            day: 'numeric',
            month: 'long'
          })
          .toString()
        let newResponse = {
          data: {
            ...response.data.stats,
            energyProductionSum: parseFloat(energyProductionSum.toFixed(2)),
            selfConsumptionSum: parseFloat(selfConsumptionSum.toFixed(2)),
            energyToNetworkSum: parseFloat(energyToNetworkSum.toFixed(2)),
            energyToBatterySum: parseFloat(energyToBatterySum.toFixed(2)),

            selfConsumptionPercentageAverage: parseFloat(
              selfConsumptionPercentageAverage.toFixed(2)
            ),
            energyToNetworkPercentageAverage: parseFloat(
              energyToNetworkPercentageAverage.toFixed(2)
            ),
            energyToBatteryPercentageAverage: parseFloat(
              energyToBatteryPercentageAverage.toFixed(2)
            ),

            granularity: newGranularity,
            columnWidth: larghezzaColonne,
            currentOptionList: date,
            categories: [...categorie],
            values: {
              series: [
                {
                  name: 'Energia caricata in batteria',
                  color: '#0F9C38',
                  data: valuesEnergyToBattery
                },
                {
                  name: 'Energia immessa in rete',
                  color: '#1197AD',
                  data: valuesEnergyToNetwork
                },
                {
                  name: 'Energia autoconsumata',
                  color: '#97D700',
                  data: valuesSelfConsumption
                }
              ]
            }
          }
        }
        commit('setProductionData', newResponse)
        commit('setProductionDataTemp', newResponse.data.values)
      } catch (err) {
        console.error(err)
      }
    },

    async retrieveProductionKPIControl({ rootGetters, commit }, body) {
      const newBody = {
        ...body,
        startDate: body.startDate.replace(/\.\d{3}Z$/, 'Z'),
        endDate: body.endDate.replace(/\.\d{3}Z$/, 'Z'),
        granularity: body.granularity
      }
      try {
        const response = await axiosACN.post(
          `/photovoltaic/retrieveProductionKPI`,
          newBody,
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        const {
          energyProduction = [],
          energyToBattery = [],
          energyToBatteryPercentage = [],
          selfConsumptionPercentage = [],
          energyToNetwork = [],
          energyToNetworkPercentage = [],
          selfConsumption = []
        } = response.data.stats || {}

        let totalProduction = energyProduction[0].value

        const newResp = {
          totalProduction,
          energyToBattery,
          energyToBatteryPercentage,
          selfConsumptionPercentage,
          energyToNetwork,
          energyToNetworkPercentage,
          selfConsumption
        }

        commit('setProductionControl', newResp)
      } catch (err) {
        console.error(err)
      }
    },

    retrieveDashboardData({ rootGetters, commit }, body) {
      const bodyDaily = {
        acuId: body.acuId,
        siteId: body.siteId,
        granularity: body.granularityDaily,
        endDate: body.endDate,
        startDate: body.startDateDaily
      }
      const bodyFromActivation = {
        acuId: body.acuId,
        siteId: body.siteId,
        granularity: body.granularityFromStart
        // endDate: body.endDate,
        // startDate: body.startDateFromStart
      }

      const dashProm = axiosACN.post(`/photovoltaic/retrieveSummaryKPI`, body, {
        headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
      })
      const prodProm = axiosACN.post(
        `/photovoltaic/retrieveProductionKPI`,
        bodyDaily,
        {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        }
      )
      const consProm = axiosACN.post(
        `/photovoltaic/retrieveConsumptionKPI`,
        bodyDaily,
        {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        }
      )
      const savingProm = axiosACN.post(
        `/photovoltaic/retrieveSavingsKPI`,
        bodyFromActivation,
        {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        }
      )

      const envProm = axiosACN.post(
        `/photovoltaic/retrieveEnvironmentalImpactKPI`,
        bodyFromActivation,
        {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        }
      )

      return Promise.all([dashProm, prodProm, consProm, savingProm, envProm])
        .then((values) => {
          commit('setDashboard', {
            dashboard: values[0],
            chartProduzione: values[1],
            chartConsumo: values[2],
            chartRisparmio: values[3],
            chartEnv: values[4]
          })
        })
        .catch((error) => {
          commit('setDashboard', {
            dashboard: [],
            chartProduzione: [],
            chartConsumo: [],
            chartRisparmio: [],
            chartEnv: []
          })
        })
    },

    async retrieveEnvironmentalImpactKPIData({ rootGetters, commit }, body) {
      let granularity = body.granularity
      let newGranularity = functionsUtilityFV.mappingGranularity(
        body.granularity
      )
      const newBody = {
        ...body,
        startDate: body.startDate.replace(/\.\d{3}Z$/, 'Z'),
        endDate: body.endDate.replace(/\.\d{3}Z$/, 'Z'),
        granularity: newGranularity
      }
      try {
        const response = await axiosACN.post(
          `/photovoltaic/retrieveEnvironmentalImpactKPI`,
          newBody,
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        let co2EmissionSavings = response.data.stats?.co2EmissionSavings || []
        let treeCountSavings = response.data.stats?.treeCountSavings || []
        let valuesCo2EmissionSavings = []

        let emmissionSavingSum =
          functionsUtilityFV.sumValues(co2EmissionSavings)
        let treeCountSavingsSum = functionsUtilityFV.sumValues(treeCountSavings)

        let categorie = []
        let larghezzaColonne = 15

        valuesCo2EmissionSavings = functionsUtilityFV.defineArrayValues(
          granularity,
          co2EmissionSavings
        )
        larghezzaColonne = functionsUtilityFV.defineColumnWidth(granularity)
        categorie = functionsUtilityFV.defineCategories(granularity)

        let date = new Date()
          .toLocaleDateString('it-IT', {
            day: 'numeric',
            month: 'long'
          })
          .toString()
        let newResponse = {
          data: {
            ...response.data.stats,
            emmissionSavingSum: parseFloat(emmissionSavingSum.toFixed(2)),
            treeCountSavingsSum: parseFloat(treeCountSavingsSum.toFixed(2)),
            granularity: newGranularity,
            columnWidth: larghezzaColonne,
            currentOptionList: date,
            categories: [...categorie],
            values: {
              series: [
                {
                  name: 'Emmissioni evitate',
                  color: '#97D700',
                  data: valuesCo2EmissionSavings
                }
              ]
            }
          }
        }
        commit('setEnviromentallImpact', newResponse)
      } catch (err) {
        console.error(err)
      }
    },

    async retrieveEnvironmentalImpactKPIControl({ rootGetters, commit }, body) {
      const newBody = {
        ...body,
        startDate: body.startDate.replace(/\.\d{3}Z$/, 'Z'),
        endDate: body.endDate.replace(/\.\d{3}Z$/, 'Z'),
        granularity: body.granularity
      }
      try {
        const response = await axiosACN.post(
          `/photovoltaic/retrieveEnvironmentalImpactKPI`,
          newBody,
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        const { co2EmissionSavings = [], treeCountSavings = [] } =
          response.data.stats || {}

        const newResp = {
          co2EmissionSavings,
          treeCountSavings
        }

        commit('setEnviromentallImpactControl', newResp)
      } catch (err) {
        console.error(err)
      }
    },

    async retrieveMonitoringSavingKPIData({ rootGetters, commit }, body) {
      let granularity = body.granularity
      let newGranularity = functionsUtilityFV.mappingGranularity(
        body.granularity
      )
      const newBody = {
        ...body,
        granularity: newGranularity,
        startDate: body.startDate.replace(/\.\d{3}Z$/, 'Z'),
        endDate: body.endDate.replace(/\.\d{3}Z$/, 'Z')
      }
      try {
        const response = await axiosACN.post(
          '/photovoltaic/retrieveSavingsKPI',
          newBody,
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        let billSaving = response.data.stats?.billSavings || []
        let totalSavings = response.data.stats?.totalSavings || []
        let gseIncentives = response.data.stats?.gseIncentives || []
        let billSavingPercentage =
          response.data.stats?.billSavingsPercentage || []
        let gseIncentivesPercentage =
          response.data.stats?.gseIncentivesPercentage || []
        let valuesBillSaving = []
        let valuesGseIncentives = []

        let billSavingSum = functionsUtilityFV.sumValues(billSaving)
        let totalSavingsSum = functionsUtilityFV.sumValues(totalSavings)
        let gseIncentivesSum = functionsUtilityFV.sumValues(gseIncentives)
        let billSavingPercentageSum =
          functionsUtilityFV.sumValues(billSavingPercentage)
        let gseIncentivesPercentageSum = functionsUtilityFV.sumValues(
          gseIncentivesPercentage
        )

        let categorie = []
        let larghezzaColonne = 15

        valuesBillSaving = functionsUtilityFV.defineArrayValues(
          granularity,
          billSaving
        )
        valuesGseIncentives = functionsUtilityFV.defineArrayValues(
          granularity,
          gseIncentives
        )
        categorie = functionsUtilityFV.defineCategories(granularity)
        larghezzaColonne = functionsUtilityFV.defineColumnWidth(granularity)

        let date = new Date()
          .toLocaleDateString('it-IT', {
            day: 'numeric',
            month: 'long'
          })
          .toString()
        let newResponse = {
          data: {
            ...response.data.stats,
            billSavingSum: parseFloat(billSavingSum.toFixed(2)),
            gseIncentivesSum: parseFloat(gseIncentivesSum.toFixed(2)),
            gseIncentivesSum: parseFloat(gseIncentivesSum.toFixed(2)),
            totalSavingsSum: totalSavingsSum,
            billSavingPercentageSum: parseFloat(
              billSavingPercentageSum.toFixed(2)
            ),
            gseIncentivesPercentageSum: parseFloat(
              gseIncentivesPercentageSum.toFixed(2)
            ),
            granularity: newGranularity,
            columnWidth: larghezzaColonne,
            currentOptionList: date,
            categories: [...categorie],
            values: {
              series: [
                {
                  name: 'Risparmio in bolletta',
                  color: '#1197AD',
                  data: valuesBillSaving
                },
                {
                  name: 'Incentivi GSE',
                  color: '#97D700',
                  data: valuesGseIncentives
                }
              ]
            }
          }
        }
        commit('setMonitoringSaving', newResponse)
        commit('setDataChart', newResponse.data.values)
      } catch (err) {
        console.error(err)
      }
    },

    async retrieveMonitoringSavingKPIControl({ rootGetters, commit }, body) {
      const newBody = {
        ...body,
        startDate: body.startDate.replace(/\.\d{3}Z$/, 'Z'),
        endDate: body.endDate.replace(/\.\d{3}Z$/, 'Z'),
        granularity: body.granularity
      }
      try {
        const response = await axiosACN.post(
          `/photovoltaic/retrieveSavingsKPI`,
          newBody,
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        const {
          billSavings = [],
          totalSavings = [],
          gseIncentives = [],
          billSavingsPercentage = [],
          gseIncentivesPercentage = [],
          valuesBillSaving = [],
          valuesGseIncentives = []
        } = response.data.stats || {}

        const newResp = {
          billSavings,
          totalSavings,
          gseIncentives,
          billSavingsPercentage,
          gseIncentivesPercentage,
          valuesBillSaving,
          valuesGseIncentives
        }

        commit('setSavingControl', newResp)
      } catch (err) {
        console.error(err)
      }
    },

    async retrieveMonitoringConsumptionKPIData({ rootGetters, commit }, body) {
      let granularity = body.granularity

      let newGranularity = functionsUtilityFV.mappingGranularity(
        body.granularity
      )
      const newBody = {
        ...body,
        granularity: newGranularity,
        startDate: body.startDate.replace(/\.\d{3}Z$/, 'Z'),
        // startDate: '2023-12-18T23:00:00Z',
        endDate: body.endDate.replace(/\.\d{3}Z$/, 'Z')
      }
      try {
        const response = await axiosACN.post(
          '/photovoltaic/retrieveConsumptionKPI',
          newBody,
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        let energyConsumption = response.data.stats?.energyConsumption || []
        let energyFromBattery = response.data.stats?.energyFromBattery || []
        let energyFromBatteryPercentage =
          response.data.stats?.energyFromBatteryPercentage || []
        let selfConsumption = response.data.stats?.selfConsumption || []
        let selfConsumptionPercentage =
          response.data.stats?.selfConsumptionPercentage || []
        let energyFromNetwork = response.data.stats?.energyFromNetwork || []
        let energyFromNetworkPercentage =
          response.data.stats?.energyFromNetworkPercentage || []
        let selfSufficiencyPercentage =
          response.data.stats?.selfSufficiencyPercentage || []

        let valuesEnergyFromBattery = []
        let valuesSelfConsumption = []
        let valuesEnergyFromNetwork = []

        let energyConsumptionSum =
          functionsUtilityFV.sumValues(energyConsumption)
        let energyFromBatterySum =
          functionsUtilityFV.sumValues(energyFromBattery)
        let energyFromBatteryPercentageSum = functionsUtilityFV.sumValues(
          energyFromBatteryPercentage
        )
        let selfConsumptionSum = functionsUtilityFV.sumValues(selfConsumption)
        let selfConsumptionPercentageSum = functionsUtilityFV.sumValues(
          selfConsumptionPercentage
        )
        let energyFromNetworkSum =
          functionsUtilityFV.sumValues(energyFromNetwork)
        let energyFromNetworkPercentageSum = functionsUtilityFV.sumValues(
          energyFromNetworkPercentage
        )
        let selfSufficiencyPercentageSum = functionsUtilityFV.sumValues(
          selfSufficiencyPercentage
        )

        let categorie = []
        let larghezzaColonne = 15

        valuesEnergyFromBattery = functionsUtilityFV.defineArrayValues(
          granularity,
          energyFromBattery
        )

        valuesSelfConsumption = functionsUtilityFV.defineArrayValues(
          granularity,
          selfConsumption
        )
        valuesEnergyFromNetwork = functionsUtilityFV.defineArrayValues(
          granularity,
          energyFromNetwork
        )

        larghezzaColonne = functionsUtilityFV.defineColumnWidth(granularity)
        categorie = functionsUtilityFV.defineCategories(granularity)

        let date = new Date()
          .toLocaleDateString('it-IT', {
            day: 'numeric',
            month: 'long'
          })
          .toString()
        let newResponse = {
          data: {
            ...response.data.stats,
            energyConsumptionSum: parseFloat(energyConsumptionSum.toFixed(2)),
            energyFromBatterySum: parseFloat(energyFromBatterySum.toFixed(2)),
            energyFromBatteryPercentageSum: parseFloat(
              energyFromBatteryPercentageSum.toFixed(2)
            ),
            selfConsumptionSum: parseFloat(selfConsumptionSum.toFixed(2)),
            selfConsumptionPercentageSum: parseFloat(
              selfConsumptionPercentageSum.toFixed(2)
            ),
            energyFromNetworkSum: parseFloat(energyFromNetworkSum.toFixed(2)),
            energyFromNetworkPercentageSum: parseFloat(
              energyFromNetworkPercentageSum.toFixed(2)
            ),
            selfSufficiencyPercentageSum: parseFloat(
              selfSufficiencyPercentageSum.toFixed(2)
            ),

            granularity: newGranularity,
            columnWidth: larghezzaColonne,
            currentOptionList: date,
            categories: [...categorie],
            values: {
              series: [
                {
                  name: 'Energia autoconsumata',
                  color: '#97D700',
                  data: valuesSelfConsumption
                },

                {
                  name: 'Energia prelevata dalla rete',
                  color: '#1197AD',
                  data: valuesEnergyFromNetwork
                },
                {
                  name: 'Energia scaricata dalla batteria',
                  color: '#0F9C38',
                  data: valuesEnergyFromBattery
                }
              ]
            }
          }
        }
        commit('setConsumptionChart', newResponse || [])
        commit('setConsumptionDataChart', newResponse?.data?.values || [])
      } catch (err) {
        console.error(err)
      }
    },

    async retrieveMonitoringConsumptionKPIControl(
      { rootGetters, commit },
      body
    ) {
      const newBody = {
        ...body,
        startDate: body.startDate.replace(/\.\d{3}Z$/, 'Z'),
        endDate: body.endDate.replace(/\.\d{3}Z$/, 'Z'),
        granularity: body.granularity
      }
      try {
        const response = await axiosACN.post(
          `/photovoltaic/retrieveConsumptionKPI`,
          newBody,
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        const {
          energyConsumption = [],
          energyFromBattery = [],
          energyFromBatteryPercentage = [],
          selfConsumption = [],
          selfConsumptionPercentage = [],
          energyFromNetwork = [],
          energyFromNetworkPercentage = [],
          selfSufficiencyPercentage = []
        } = response.data.stats || {}

        const newResp = {
          energyConsumption,
          energyFromBattery,
          energyFromBatteryPercentage,
          selfConsumption,
          selfConsumptionPercentage,
          energyFromNetwork,
          energyFromNetworkPercentage,
          selfSufficiencyPercentage
        }

        commit('setConsumptionControl', newResp)
      } catch (err) {
        console.error(err)
      }
    },

    setAddressSelected({ commit }, payload) {
      commit('setAddressSelected', payload)
    },

    async retrievePhotovoltaicData({ rootGetters, commit }, body) {
      const response = await axiosACN.post(
        '/sorgeniadataclient/V6/retrievehomepagedata',
        {
          username: rootGetters['session/username']
        },
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        }
      )
      const filteredPoints = response.data.points.filter((point) => {
        return (
          point.supplyType === 'PHOTOVOLTAIC' &&
          point.activationState === 'ACTIVE'
        )
      })
      commit('setPhotovoltaicData', filteredPoints)
    }
  },
  mutations: {
    setDashboard(
      state,
      { dashboard, chartProduzione, chartConsumo, chartRisparmio, chartEnv }
    ) {
      let summary = dashboard.data
      let production = chartProduzione.data
      let consumption = chartConsumo.data
      let saving = chartRisparmio.data
      let enviroment = chartEnv.data

      let powerToNetwork = summary?.instantProductionStats?.powerToNetwork
        ? summary?.instantProductionStats?.powerToNetwork
        : 0
      let powerFromNetwork = summary?.instantConsumptionStats?.powerFromNetwork
        ? summary?.instantConsumptionStats?.powerFromNetwork
        : 0

      let powerToBattery = summary?.instantProductionStats?.powerToBattery
        ? summary?.instantProductionStats?.powerToBattery
        : 0
      let powerFromBattery = summary?.instantConsumptionStats?.powerFromBattery
        ? summary?.instantConsumptionStats?.powerFromBattery
        : 0

      summary?.instantConsumptionStats?.powerFromNetwork
      state.dashboard = {
        loaded: true,
        lastUpdate: summary?.lastUpdate || '',
        overallStatus:
          OVERALL_STATUS[state.installation.siteProfile.installationStatus] ||
          OVERALL_STATUS.UNKNOWN, //  NO_ANOMALY, ALARM, VALIDATION_ERROR, NOT_COMMUNICATING, UNKNOWN
        produzione: {
          ...state.dashboard.produzione,
          value: functionsUtilityFV.formatUndefinedValue(
            summary?.instantProductionStats?.productionTotal
          ), // '1,24', // K02
          items: [
            {
              ...state.dashboard.produzione.items[0],
              value: functionsUtilityFV.formatUndefinedValue(
                summary?.instantConsumptionStats?.powerSelfConsumption,
                'kW'
              ) //'0,40kW', // K06
            },
            {
              ...state.dashboard.produzione.items[1],
              value: functionsUtilityFV.formatUndefinedValue(
                summary?.instantProductionStats?.powerToNetwork,
                'kW'
              ) //'0,40kW', // K05
            },
            {
              ...state.dashboard.produzione.items[2],
              value: functionsUtilityFV.formatUndefinedValue(
                summary?.instantProductionStats?.powerToBattery,
                'kW'
              ) // '0,40kW', // K03
            }
          ]
        },
        consumo: {
          ...state.dashboard.consumo,
          value: functionsUtilityFV.formatUndefinedValue(
            summary?.instantConsumptionStats?.consumptionTotal
          ), // '0,40', // K01
          items: [
            {
              ...state.dashboard.consumo.items[0],
              value: functionsUtilityFV.formatUndefinedValue(
                summary?.instantConsumptionStats?.powerSelfConsumption,
                'kW'
              ) //'0,40kW', // K06
            },
            {
              ...state.dashboard.consumo.items[1],
              value: functionsUtilityFV.formatUndefinedValue(
                summary?.instantConsumptionStats?.powerFromNetwork,
                'kW'
              ) //'0,40kW', // K07
            },
            {
              ...state.dashboard.consumo.items[2],
              value: functionsUtilityFV.formatUndefinedValue(
                summary?.instantConsumptionStats?.powerFromBattery,
                'kW'
              ) //'0,40kW', // K04
            }
          ]
        },
        batteria: {
          carica: summary?.batteryStats?.totalChargePercentage
            ? Math.floor(summary?.batteryStats?.totalChargePercentage).toFixed(
                0
              )
            : '0', // '90', // K08
          value:
            powerFromBattery > 0
              ? functionsUtilityFV.formatUndefinedValue(powerFromBattery, '')
              : functionsUtilityFV.formatUndefinedValue(powerToBattery, '')
        },
        rete: {
          // to do parse with 0 and use math.floor, create new utils
          value:
            powerFromNetwork > 0
              ? functionsUtilityFV.formatUndefinedValue(powerFromNetwork, '')
              : functionsUtilityFV.formatUndefinedValue(powerToNetwork, '')
        },
        autosufficienzaValue: summary?.selfSufficiencyStats
          ?.latestAveragePercentage
          ? summary?.selfSufficiencyStats?.latestAveragePercentage
          : 0, // '45', // K35

        chartProduzione: {
          ...state.dashboard.chartProduzione,
          apexTotal: production?.stats?.energyProduction[0].value + ' kWh', // '12,27 kWh', // K17
          stats: [
            {
              ...state.dashboard.chartProduzione.stats[0],
              percentage:
                production?.stats?.selfConsumptionPercentage[0].value + '%', //'61,56%', // K26
              value: functionsUtilityFV.formatUndefinedValue(
                production?.stats?.selfConsumption[0].value,
                'kWh'
              ) //'7,55kW' // K24
            },
            {
              ...state.dashboard.chartProduzione.stats[1],
              percentage:
                production?.stats?.energyToNetworkPercentage[0].value + '%', //'1,63%', // K28
              value: functionsUtilityFV.formatUndefinedValue(
                production?.stats?.energyToNetwork[0].value,
                'kWh'
              ) //'0,20kW' // K27
            },
            {
              ...state.dashboard.chartProduzione.stats[2],
              percentage:
                production?.stats?.energyToBatteryPercentage[0].value + '%', //'36,81%', // K19
              value: functionsUtilityFV.formatUndefinedValue(
                production?.stats?.energyToBattery[0].value,
                'kWh'
              ) //'4,52kW' // K18
            }
          ]
        },
        chartConsumo: {
          ...state.dashboard.chartConsumo,
          apexTotal: consumption?.stats?.energyConsumption[0].value + ' kWh', // '25,26 kWh', // K16
          stats: [
            {
              ...state.dashboard.chartConsumo.stats[0],
              percentage:
                consumption?.stats?.selfConsumptionPercentage[0].value + '%', //'32,00%', // K25
              value: functionsUtilityFV.formatUndefinedValue(
                consumption?.stats?.selfConsumption[0].value,
                'kWh'
              ) //'7,55kW' // K24
            },
            {
              ...state.dashboard.chartConsumo.stats[1],
              percentage:
                consumption?.stats?.energyFromNetworkPercentage[0].value + '%', //'64,00%', // K30
              value: functionsUtilityFV.formatUndefinedValue(
                consumption?.stats?.energyFromNetwork[0].value,
                'kWh'
              ) //'16,38kW' // K29
            },
            {
              ...state.dashboard.chartConsumo.stats[2],
              percentage:
                consumption?.stats?.energyFromBatteryPercentage[0].value + '%', //'4,00%', // K21
              value: functionsUtilityFV.formatUndefinedValue(
                consumption?.stats?.energyFromBattery[0].value,
                'kWh'
              ) // '1,33kW' // K20
            }
          ]
        },
        chartRisparmio: {
          ...state.dashboard.chartRisparmio,
          apexTotal: saving?.stats?.totalSavings[0].value + ' €', // '2.660,24 €', // K43
          stats: [
            {
              ...state.dashboard.chartRisparmio.stats[0],
              percentage: saving?.stats?.gseIncentivesPercentage[0].value + '%', //'6,00%', // K41
              value: functionsUtilityFV.formatUndefinedValue(
                saving?.stats?.gseIncentives[0].value,
                '€'
              )
              //'186,21 €' // K38
            },
            {
              ...state.dashboard.chartRisparmio.stats[1],
              percentage: saving?.stats?.billSavingsPercentage[0].value + '%', //'94,00%', // K42
              value: functionsUtilityFV.formatUndefinedValue(
                saving?.stats?.billSavings[0].value,
                '€'
              ) //'2.454,03 €' // K40
            }
          ]
        },
        cfp: enviroment?.stats?.co2EmissionSavings[0].value + ' t', // '3,14 t' // K32
        inFromOutBattery: functionsUtilityFV.calcDirectionBattery(
          summary?.instantProductionStats?.powerToBattery,
          summary?.instantConsumptionStats?.powerFromBattery
        ), // IN, OUT or NONE
        inFromOutNet: functionsUtilityFV.calcDirectionNet(
          summary?.instantProductionStats?.powerToNetwork,
          summary?.instantConsumptionStats?.powerFromNetwork
        ), // IN, OUT or NONE
        inFromOutProduction: functionsUtilityFV.calcDirectionProd(
          summary?.instantProductionStats?.productionTotal
        ), // IN or NONE
        inFromOutConsumation: functionsUtilityFV.calcDirectionCons(
          summary?.instantConsumptionStats?.consumptionTotal
        ), // OUT or NONE
        animationDirections: summary.animationDirections
      }
    },
    setInstallation(state, installation) {
      state.installation = { ...installation }
    },
    setProductionDataTemp(state, payload) {
      state.productionDataTemp = {
        ...state.productionDataTemp,
        ...payload
      }
    },
    setProductionData(state, payload) {
      state.productionData = {
        ...state.productionData,
        ...payload
      }
    },
    setProductionControl(state, payload) {
      state.productionControl = {
        ...state.productionControl,
        ...payload
      }
    },
    setSavingControl(state, payload) {
      state.savingControl = {
        ...state.savingControl,
        ...payload
      }
    },
    setEnviromentallImpactControl(state, payload) {
      state.enviromentallImpactControl = {
        ...state.enviromentallImpactControl,
        ...payload
      }
    },
    setConsumptionControl(state, payload) {
      state.consumptionControl = {
        ...state.consumptionControl,
        ...payload
      }
    },
    setEnviromentallImpact(state, payload) {
      state.enviromentallImpanct = {
        ...state.enviromentallImpanct,
        ...payload
      }
    },
    setMonitoringSaving(state, payload) {
      state.monitoringSavingChart = {
        ...state.monitoringSavingChart,
        ...payload
      }
    },
    setConsumptionChart(state, payload) {
      state.monitoringConsumptionChart = {
        ...state.monitoringConsumptionChart,
        ...payload
      }
    },
    setConsumptionDataChart(state, payload) {
      state.monitoringConsumptionChart.dataChart = {
        ...state.monitoringConsumptionChart.dataChart,
        ...payload
      }
    },
    setDataChart(state, payload) {
      state.monitoringSavingChart.dataChart = {
        ...state.monitoringSavingChart.dataChart,
        ...payload
      }
    },
    setAddressSelected(state, payload) {
      state.addressSelected = { ...payload }
    },
    setPhotovoltaicData(state, payload) {
      state.photovoltaicData = payload
    },
    setFlagEnterByDeeplink(state, payload) {
      state.flagEnterByDeeplink = payload
    },
    setAddressSelectedDashboard(state, payload) {
      state.addressSelected = payload
    }
  },
  getters: {
    getSavingChart(state) {
      return state.monitoringSavingChart
    },
    getConsumptionChart(state) {
      return state.monitoringConsumptionChart
    },
    getConsumptionDataChart(state) {
      return state.monitoringConsumptionChart.dataChart
    },
    getSavingDataChart(state) {
      return state.monitoringSavingChart.dataChart
    },
    getInstallationData(state) {
      return state.installation
    },
    getEnviromentallImpanct(state) {
      return state.enviromentallImpanct
    },
    getProductionData(state) {
      return state.productionData
    },
    getProductionDataTemp(state) {
      return state.productionDataTemp
    },
    getProductionControl(state) {
      return state.productionControl
    },
    getConsumptionControl(state) {
      return state.consumptionControl
    },
    getSavingControl(state) {
      return state.savingControl
    },
    getEnviromentallImpactControl(state) {
      return state.enviromentallImpactControl
    },
    getPhotovoltaicData(state) {
      return state.photovoltaicData
    },
    getFlagEnterByDeeplink(state) {
      return state.flagEnterByDeeplink
    }
  }
}
