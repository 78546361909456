<template>
  <div class="login">
    <TcVars />
    <div class="login__content">
      <img
        src="../../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <div class="login__box scrollable">
        <div class="registration__title HeadingsTitleXXL">Registrati</div>
        <div class="registration__subTitle BodyL">
          Registrati qui per accedere alla tua Area Personale MySorgenia da app
          e web.
        </div>
        <div class="login__auth">
          <v-text-field
            v-if="withClientCode"
            v-model.trim="code"
            @blur="v$.code.$touch()"
            label="CODICE CLIENTE*"
            clearable
            class="auth__input"
            :error-messages="codeErrors"
          ></v-text-field>
          <v-text-field
            v-model.trim="name"
            @blur="v$.name.$touch()"
            label="NOME*"
            placeholder="Es: Mario"
            persistent-placeholder
            clearable
            class="auth__input"
            :error-messages="nameErrors"
          ></v-text-field>
          <v-text-field
            v-model.trim="surname"
            @blur="v$.surname.$touch()"
            label="COGNOME*"
            placeholder="Es: Rossi"
            persistent-placeholder
            clearable
            class="auth__input"
            :error-messages="surnameErrors"
          ></v-text-field>
          <v-text-field
            v-model.trim="cfpi"
            @blur="v$.cfpi.$touch()"
            :label="
              withClientCode ? 'CODICE FISCALE/PARTITA IVA*' : 'CODICE FISCALE*'
            "
            placeholder="Es: FVNFRN88E49H501L"
            persistent-placeholder
            clearable
            class="auth__input"
            :error-messages="cfpiErrors"
          ></v-text-field>
          <div v-if="showCcCfpiError && withClientCode" class="login_error">
            <span class="red--text"
              >Il codice cliente o il codice fiscale non risultano associati a
              nessun cliente. Controlla i dati inseriti.</span
            >
          </div>
          <v-text-field
            v-model.trim="email"
            @blur="v$.email.$touch()"
            label="EMAIL*"
            placeholder="Es: mariorossi@email.com"
            persistent-placeholder
            clearable
            class="auth__input"
            :error-messages="emailErrors"
          ></v-text-field>
          <v-text-field
            v-if="!withClientCode"
            v-model.trim="phone"
            @blur="v$.phone.$touch()"
            label="NUMERO DI TELEFONO*"
            placeholder="Il tuo numero di telefono"
            persistent-placeholder
            clearable
            class="auth__input"
            :error-messages="phoneErrors"
          ></v-text-field>
          <v-text-field
            v-model.lazy="password"
            @blur="v$.password.$touch()"
            @input="checkPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="PASSWORD*"
            placeholder="Inserisci la password"
            persistent-placeholder
            :error-messages="passwordErrors"
            counter
            class="auth__input"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            v-model.lazy="confirmPassword"
            @blur="v$.confirmPassword.$touch()"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            name="cofirmPassword"
            label="CONFERMA PASSWORD*"
            placeholder="Inserisci la password"
            persistent-placeholder
            :error-messages="confirmPasswordErrors"
            counter
            class="auth__input"
            @click:append="showConfirmPassword = !showConfirmPassword"
          ></v-text-field>
          <!-- <div v-if="showAlreadyRegisteredError" class="login__error">
            <span class="red--text">L'indirizzo email inserito risulta già essere registrato sui nostri sistemi.</span>
          </div> -->
        </div>
        <div class="auth__reg-password mod-password">
          <h5>La password deve avere:</h5>
          <div class="BodyL">
            <span :class="formatPsw.eightChr ? 'ok' : 'ko'"></span>almeno 8
            caratteri
          </div>
          <div class="BodyL">
            <span :class="formatPsw.lowercase ? 'ok' : 'ko'"></span>almeno 1
            carattere minuscolo
          </div>
          <div class="BodyL">
            <span :class="formatPsw.uppercase ? 'ok' : 'ko'"></span>almeno 1
            carattere maiuscolo
          </div>
          <div class="BodyL">
            <span :class="formatPsw.number ? 'ok' : 'ko'"></span>almeno 1 numero
          </div>
        </div>

        <div class="login__check mb-6">
          <h5>Consensi e permessi</h5>
          <div
            @click="privacyConsent = !privacyConsent"
            :class="`checkbox ${
              privacyConsent ? 'selected' : ''
            }  align-center `"
          >
            <div class="black--text ml-3 BodyL">
              Accetto i
              <span
                @click.stop="getDoc('termsAndConditions')"
                id="loginInfoPdfTrigger"
                class="color--blue ButtonM-Underline"
              >
                Termini e condizioni*</span
              >
              di utilizzo e dichiaro di aver preso visione dell’<span
                @click.stop="getDoc('informativePrivacyWeb')"
                id="loginInfoPdfTrigger"
                class="color--blue ButtonM-Underline"
                >Informativa sulla Privacy*</span
              >
            </div>
          </div>

          <div
            @click="greenersConsent = !greenersConsent"
            :class="`checkbox ${
              greenersConsent ? 'selected' : ''
            }  align-center mt-7 `"
          >
            <div class="black--text ml-3 BodyL">
              Dichiaro di aver preso visione del
              <span
                @click.stop="getDoc('regolamentoGreeners')"
                id="loginInfoPdfTrigger"
                class="color--blue ButtonM-Underline"
                >Regolamento Greeners*</span
              >
              e dell’<span
                @click.stop="getDoc('informativaGreeners')"
                id="loginInfoPdfTrigger"
                class="color--blue ButtonM-Underline"
                >Informativa Greeners*</span
              >
            </div>
          </div>

          <div v-if="!withClientCode">
            <div
              @click="marketingConsent = !marketingConsent"
              :class="`checkbox ${
                marketingConsent ? 'selected' : ''
              }  align-center  mt-7 `"
            >
              <div class="black--text ml-3 BodyL">
                Consenso per
                <span
                  @click.stop="goToDocument('informativePrivacyWeb')"
                  id="loginInfoPdfTrigger"
                  class="color--blue ButtonM-Underline"
                >
                  ricevere da Sorgenia offerte vantaggiose, informazioni sulle
                  sue iniziative e far sentire la tua voce tramite brevi
                  questionari</span
                ><span class="black--text BodyL"> [art. 2 lett. e]</span>
              </div>
            </div>

            <div
              @click="thirdConsent = !thirdConsent"
              :class="`checkbox ${
                thirdConsent ? 'selected' : ''
              }  align-center  mt-7 `"
            >
              <div class="black--text ml-3 BodyL">
                Consenso per
                <span
                  @click.stop="goToDocument('informativePrivacyWeb')"
                  id="loginInfoPdfTrigger"
                  class="color--blue ButtonM-Underline"
                  >analizzare le tue abitudini di consumo al fine di offrirti
                  prodotti e servizi personalizzati</span
                >
                <span class="black--text BodyL"> [art. 2 lett. f]</span>
              </div>
            </div>

            <div
              @click="profilingConsent = !profilingConsent"
              :class="`checkbox ${
                profilingConsent ? 'selected' : ''
              }  align-center  mt-7 `"
            >
              <div class="black--text ml-3 BodyL">
                Consenso per
                <span
                  @click.stop="goToDocument('informativePrivacyWeb')"
                  id="loginInfoPdfTrigger"
                  class="color--blue ButtonM-Underline"
                  >ricevere da soggetti terzi/società partner di Sorgenia
                  offerte vantaggiose</span
                >
                <span class="black--text BodyL"> [art. 2 lett. g]</span>
              </div>
            </div>
          </div>
        </div>

        <div class="login__btn">
          <MYSButton
            button-class="primaryButtonL"
            :disabled="privacyValid()"
            @buttonClick="goNext"
            button-text="Registrati"
          />
          <MYSButton
            button-class="primaryInvertedButtonL ButtonL"
            @buttonClick="$router.push({ name: 'login' })"
            button-text="Torna al login"
          />
        </div>
      </div>
    </div>

    <v-dialog
      persistent
      content-class="code-error-dialog"
      v-model="showCodeError"
      max-width="558"
    >
      <div class="code-error-modal__content">
        <div class="code-error-modal__modal-close">
          <img
            src="@/assets/ic_chevron_top_black.png"
            alt="close"
            @click="showCodeError = false"
          />
        </div>
        <div class="code-error-modal__title HeadingsSubtitleXL">
          Codice Cliente non Valido
        </div>
        <div class="code-error-modal__body BodyXL">
          Il Codice Cliente Tutele Graduali non è valido per<br />
          accedre all'area clienti MySorgenia.<br />
          Inserisci un Codice Cliente del mercato libero.
        </div>
        <div class="code-error-modal__action">
          <MYSButton
            button-class="primaryButtonL"
            @buttonClick="showCodeError = false"
            button-text="Torna alla Registrazione"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import GenErr from '@/js/genericErrors'
import { validateCFPI } from '@/js/utils'
import { getRequiredValidationError } from '@/js/utils.js'
import router from '@/router/router'
import {
  alphaNum,
  email,
  minLength,
  required,
  requiredIf,
  requiredUnless,
  sameAs
} from '@vuelidate/validators'
import { mapActions, mapMutations } from 'vuex'
import { ENV_CONFIGS } from '../../js/configs'

import { useVuelidate } from '@vuelidate/core'
import MYSButton from '../../components/global/MYSButton.vue'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'RegistrationUserData',
  data() {
    return {
      code: '',
      cfpi: '',
      name: '',
      surname: '',
      email: '',
      phone: null,
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      errorMessage: '',
      formatPsw: {
        eightChr: false,
        lowercase: false,
        uppercase: false,
        oneChr: false
      },

      showAlreadyRegisteredError: false,
      showToBeConfirmedEmail: false,
      showCcCfpiError: false,

      privacyConsent: false,
      greenersConsent: false,
      marketingConsent: false,
      thirdConsent: false,
      profilingConsent: false,
      infoDoc: '',
      termDoc: '',
      showCodeError: false,
      withClientCode: false
    }
  },
  components: {
    TcVars,
    MYSButton
  },
  computed: {
    userData() {
      return {
        code: this.code,
        name: this.name,
        surname: this.surname,
        cfpi: this.cfpi,
        email: this.email,
        phone: this.phone,
        password: this.password,
        mktFromSorgenia: this.marketingConsent,
        mktFromTerzi: this.thirdConsent,
        mktFromCustom: this.profilingConsent,
        legal: this.privacyConsent
      }
    },
    codeErrors() {
      let errArray = []

      if (!this.v$.code.$dirty) return errArray

      this.v$.code.required.$invalid === true &&
        errArray.push('Il codice cliente è obbligatorio')
      this.v$.code.alphaNum === false &&
        errArray.push('Sono consentiti solo caratteri alfanumerici')

      return errArray
    },
    phoneErrors() {
      let errArray = []
      if (!this.v$.phone.$dirty) return errArray

      this.v$.phone.required.$invalid === true &&
        errArray.push('Il numero di telefono è obbligatorio')
      this.v$.phone.pattern.$invalid === true &&
        errArray.push('Sono consentiti solo caratteri numerici')

      return errArray
    },
    cfpiErrors() {
      let errArray = []

      if (!this.v$.cfpi.$dirty) return errArray

      this.v$.cfpi.required.$invalid === true &&
        errArray.push('Il codice fiscale / partita iva è obbligatorio')
      this.v$.cfpi.validateCFPI === false &&
        errArray.push('I dati inseriti non sono corretti. Verificali e riprova')
      this.showCcCfpiError &&
        !this.withClientCode &&
        errArray.push('Il codice fiscale è errato.')
      this.setShowCcCfpiError(false)

      return errArray
    },
    nameErrors() {
      return getRequiredValidationError(
        this.v$,
        'name',
        'Il nome è obbligatorio'
      )
    },
    surnameErrors() {
      return getRequiredValidationError(
        this.v$,
        'surname',
        'Il cognome è obbligatorio'
      )
    },
    emailErrors() {
      let errArray = []

      if (!this.v$.email.$dirty) return errArray

      this.v$.email.email.$invalid === true &&
        errArray.push('Formato email non valido')
      this.v$.email.sameAsEmail === false &&
        errArray.push("L'email è obbligatoria")
      this.showAlreadyRegisteredError && errArray.push('Email già esistente')
      this.showToBeConfirmedEmail && errArray.push('Email da confermare')

      return errArray
    },
    passwordErrors: function () {
      let errArray = []

      if (!this.v$.password.$dirty) return errArray

      this.v$.password.minLength.$invalid === true &&
        errArray.push('La password non soddisfa i requisiti')
      this.v$.password.pattern.$invalid === true &&
        errArray.push('La password non soddisfa i requisiti')
      this.v$.password.required.$invalid === true &&
        errArray.push('La password è obbligatoria')

      return errArray
    },

    confirmPasswordErrors: function () {
      let errArray = []

      if (!this.v$.confirmPassword.$dirty) return errArray

      this.v$.confirmPassword.sameAsPassword.$invalid === true &&
        errArray.push('Le password non coincidono')

      return errArray
    }
  },
  watch: {
    cfpi(newVal) {
      this.cfpi = newVal.toUpperCase()
    }
  },
  methods: {
    ...mapMutations('selfRegistration', {
      setUserData: 'setUserData',
      setPrivacy: 'setPrivacy'
    }),
    ...mapMutations('selfRegistration', {
      resetState: 'resetState'
    }),
    ...mapActions('selfRegistration', {
      register: 'register',
      registerCustomer: 'registerCustomer'
    }),
    ...mapActions('documents', {
      getLoginInfoDocUrl: 'getLoginInfoDocUrl', // simple binding from `store.session.actions.login()` to `this.loginService()`
      getRegistrationTermAndCondDocUrl: 'getRegistrationTermAndCondDocUrl'
    }),
    privacyValid() {
      return this.v$.$invalid || !this.privacyConsent || !this.greenersConsent
    },
    getDoc(idDoc) {
      window.open(ENV_CONFIGS.DYNAMIC_LINKS[idDoc])
    },
    setShowCcCfpiError(newValue) {
      this.showCcCfpiError = newValue
    },
    async goNext() {
      if (this.code.length === 15 && this.code.match(/^[0-9]+$/)) {
        this.showCodeError = true
        return
      }
      try {
        let registrationOK
        this.setUserData(this.userData)
        if (this.withClientCode) {
          registrationOK = await this.register()
        } else {
          this.setPrivacy(this.userData)
          registrationOK = await this.registerCustomer()
        }
        this.showCcCfpiError = false
        router.push({
          name: 'RegistrationConfirmation',
          params: { registrationOK }
        })
      } catch (error) {
        switch (error.message) {
          case 'CC_CFPI_ERROR':
            this.showCcCfpiError = true
            window.scrollTo(0, 0)
            this.v$.cfpi.$touch()
            break
          case 'ALREADY_REGISTERED':
            this.showAlreadyRegisteredError = true
            window.scrollTo(0, 0)
            this.v$.email.$touch()
            break
          case 'TO_BE_CONFIRMED':
            this.showToBeConfirmedEmail = true
            window.scrollTo(0, 0)
            this.v$.email.$touch()
            break
          default:
            // We do not expect any business logic error, so show an alert with generic error
            GenErr.showErrorMessage()
            break
        }
      }
    },
    checkPassword() {
      this.formatPsw = {
        eightChr: this.password.length >= 8,
        lowercase: /[a-z]/.test(this.password),
        uppercase: /[A-Z]/.test(this.password),
        number: /[0-9]/.test(this.password)
      }
    },
    goToDocument(id) {
      switch (id) {
        case 'termsAndConditions':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.termsAndConditions)
          break
        case 'informativePrivacyWeb':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.informativePrivacyWeb)
          break
        case 'generalClientsInformative':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.generalClientsInformative)
          break
        case 'optionalConsents':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.optionalConsents)
          break
        case 'sorgeniaSiteDocuments':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.sorgeniaSiteDocuments)
          break
      }
    }
  },
  beforeUnmount() {
    this.resetState()
  },
  async created() {
    this.withClientCode = this.$route.params.clientType === 'withContract'
    this.code = this.$store.getters['selfRegistration/code']
    this.cfpi = this.$store.getters['selfRegistration/cfpi']
    this.name = this.$store.getters['selfRegistration/name']
    this.surname = this.$store.getters['selfRegistration/surname']
    this.email = this.$store.getters['selfRegistration/email']
    this.phone = this.$store.getters['selfRegistration/phone']

    this.infoDoc = await this.getLoginInfoDocUrl()
    this.termDoc = await this.getRegistrationTermAndCondDocUrl()
  },
  validations() {
    return {
      code: {
        required: requiredIf(this.withClientCode),
        alphaNum
      },
      phone: {
        required: requiredUnless(this.withClientCode),
        pattern: (value) => {
          return value ? /^(\+)?\d+$/.test(value) : true
        }
      },
      cfpi: { required, validateCFPI },
      name: { required },
      surname: { required },
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
        pattern: (value) => {
          // Pattern: Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
          const patt = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
          return patt.test(value)
        }
      },
      confirmPassword: {
        sameAsPassword: sameAs(this.password)
      }
      // birthDate: { required },
      // genre: { required },
      // birthPlace: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/authentication/auth.scss';

.login {
  background-position: right;
}

.registration {
  &__title {
    color: #12256a;
    text-align: center;
    margin-bottom: 16px;
  }
  &__subTitle {
    text-align: center;
    margin-bottom: 42px;
    &.alert {
      background: #eaeef5;
      border-radius: 8px;
      font-size: 12px;
      line-height: 1.3;
      letter-spacing: 1px;
      color: #2c313a;
      text-align: initial;
      padding: 16px;
      &::before {
        content: url('~@/assets/icons/info.svg');
        margin-right: 8px;
      }
    }
  }
}

.code-error-modal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &__modal-close {
    align-self: end;
    cursor: pointer;
  }

  &__title {
    color: $base-black;
    text-align: center;
  }

  &__body {
    text-align: center;
  }

  &__action {
    .bt-modal--primary {
      background-color: $brand-blue;
      color: $base-white;
      padding: 14.5px 48px;
      width: 310px;
      height: 54px;
    }
  }
}
</style>

<style lang="scss">
.code-error-dialog {
  border-radius: 32px;
  background-color: $base-white;
  padding: 40px;
}

.login {
  label.v-label.theme--light {
    top: -25px;
    transition: none;
    transform: none;
  }

  &__check {
    label.v-label.theme--light {
      top: 0px;
      transition: none;
      transform: none;
    }

    .black--text {
      letter-spacing: 0.5px;
    }
  }

  .v-input--selection-controls__input {
    transform: scale(1.3);
  }

  .mdi-eye-off.theme--light,
  .mdi-close.theme--light {
    color: $srg-blue;
  }

  .auth__reg-password {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 30px;
    height: 146px;
    justify-content: space-between;

    &.mod-password {
      .ko {
        display: none;
      }
    }

    h5 {
      font-size: 18px;
      letter-spacing: 0.5px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .li {
      display: flex;
      align-items: center;
      //font-size: 16px;
      //text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-bottom: 8px;
      color: black;
      //letter-spacing: 1px;
    }
    .ok {
      display: inline-block;
      background-image: url('../../assets/ic_check_active_green.png');
      background-size: contain;
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }

    .ko {
      display: inline-block;
      background-image: url('../../assets/icons/ic-close-blue.svg');
      background-size: contain;
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
  }

  .scrollable {
    height: 70%;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.35) transparent;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

<style>
.v-text-field .v-input__slot::before {
  border-color: #b7bfd2 !important;
}
</style>
