import { eventBus } from '@/main'
import { ImageType } from '../type/aler_error.type'

function AlertService(
  title = false,
  message = false,
  imgType = ImageType.ERROR,
  btnTextRight = false,
  btnActionRight = false,
  btnTextLeft = false,
  btnActionLeft = false,
  btnActionRightParams = false,
  persistent = true
) {
  eventBus.$emit('generic:error', {
    title,
    message,
    imgType,
    btnTextLeft,
    btnActionLeft,
    btnTextRight,
    btnActionRight,
    btnActionRightParams,
    persistent
  })
}
function AlertServiceGasSardegna(
  title = true,
  message = false,
  imgType = ImageType.ERROR,
  btnTextRight = false,
  btnActionRight = false,
  btnTextLeft = false,
  btnActionLeft = false,
  btnActionRightParams = false,
  persistent = true
) {
  eventBus.$emit('generic:error', {
    title,
    message,
    imgType,
    btnTextLeft,
    btnActionLeft,
    btnTextRight,
    btnActionRight,
    btnActionRightParams,
    persistent
  })
}

export { AlertService, AlertServiceGasSardegna }
