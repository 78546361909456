<template>
  <div class="std-side-padding request-sent">
    <div class="d-flex justify-center">
      <div class="image">
        <img src="../../assets/img-girl-2-typ-okflow@2x.png" width="100%" />
      </div>
      <div class="text">
        <h1><strong>Grazie! La tua richiesta è stata inviata</strong></h1>
        <h3 class="mt-5">Sarà nostra cura contattarti al più presto.</h3>
        <v-btn
          color="white"
          x-large
          width="100%"
          :rounded="true"
          class="v-btn--shadowed mt-10 torna"
          @click="$router.push({ name: 'home' })"
        >
          Torna alla homepage
        </v-btn>
        <v-btn
          color="white"
          text
          class="mt-6 scopri"
          @click="$router.push({ name: 'fiberOffering' })"
        >
          Scopri la tua offerta
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fiberRequestSent'
}
</script>
<style lang="scss" scoped>
.request-sent {
  background-color: $srg-blue;
  height: 100vh;
  .image {
    width: 555px;
  }
  .text {
    margin-top: 120px;
    width: 365px;
    color: white;
    h1 {
      line-height: 1.14;
    }
    h3 {
      font-weight: 500;
    }
    .torna {
      letter-spacing: 0.5px;
    }
    .scopri {
      display: block;
      margin: 0 auto;
      letter-spacing: 0.5px;
    }
  }
}
</style>
