<template>
  <div class="fiber__header">
    <div class="fiber__title">
      pochi passi per scoprire l’offerta fibra di sorgenia
    </div>
  </div>
</template>
<script>
export default {
  name: 'fiberHeader'
}
</script>
<style lang="scss" scoped>
.fiber {
  &__header {
    width: 100%;
    height: 400px;
    background-image: url('../../../assets/upselling_fibra_res_bus.png');
    background-size: cover;
  }
  &__title {
    position: absolute;
    right: 210px;
    top: 70px;
    font-size: 40px;
    font-family: 'Roboto';
    width: 495px;
    letter-spacing: 1.6px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    line-height: normal;
  }
}
</style>
