<template>
  <page
    title="Invia i dati catastali"
    subTitle="Abbiamo bisogno dei tuoi dati catastali per comunicare all'Agenzia delle Entrate la registrazione dell'utenza."
    page-name="Dati catastali"
  >
    <TcVars />
    <div class="cadastral mt-6">
      <div class="cadastral__title mb-7">Dati del dichiarante</div>
      <v-row no-gutters class="mb-10">
        <v-col cols="6" class="pr-4">
          <v-text-field
            tabindex="1"
            :error-messages="firstNameErrors"
            @blur="v$.firstName.$touch()"
            class="auth__input"
            label="NOME"
            placeholder="Nome"
            persistent-placeholder
            color="#6e7689"
            v-model="firstName"
          />
        </v-col>
        <v-col cols="6" class="pl-4">
          <v-text-field
            tabindex="1"
            :error-messages="fiscalCodeErrors"
            @blur="v$.fiscalCode.$touch()"
            class="auth__input"
            label="CODICE FISCALE"
            placeholder="Codice Fiscale"
            persistent-placeholder
            color="#6e7689"
            v-model="fiscalCode"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-5">
        <v-col cols="6" class="pr-4">
          <v-text-field
            tabindex="1"
            :error-messages="lastNameErrors"
            @blur="v$.lastName.$touch()"
            class="auth__input"
            label="COGNOME"
            placeholder="Cognome"
            color="#6e7689"
            persistent-placeholder
            v-model="lastName"
          />
        </v-col>
        <v-col cols="6" class="pl-4">
          <v-select
            v-model="typeOwner"
            :items="[
              { val: '1', label: 'Proprietario' },
              { val: '2', label: 'Usufruttuario' },
              { val: '3', label: 'Titolare di altro diritto sull’immobile' },
              {
                val: '4',
                label:
                  'Rappresentante legale o volontario di uno degli aventi titolo sopra indicati'
              }
            ]"
            item-text="label"
            item-value="val"
            name="typeOwner"
            :error-messages="typeOwnerErrors"
            placeholder="Seleziona"
            persistent-placeholder
            class="mb-3"
            label="IN QUALITÀ DI"
          >
            <template #item="{ item }">
              <span class="itemTypeOwner">
                {{ item.label }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <div class="cadastral__title mb-7">Dati dell’immobile</div>
      <v-row no-gutters class="mb-10">
        <v-col cols="6" class="pr-4">
          <v-text-field
            tabindex="1"
            :error-messages="addressErrors"
            @blur="v$.address.$touch()"
            class="auth__input"
            label="INDIRIZZO"
            color="#6e7689"
            v-model="address"
          />
        </v-col>
        <v-col cols="6" class="pl-4">
          <v-text-field
            tabindex="1"
            :error-messages="comuneCatastaleErrors"
            @blur="v$.comuneCatastale.$touch()"
            class="auth__input"
            label="COMUNE CATASTALE"
            color="#6e7689"
            v-model="comuneCatastale"
            placeholder="es. Milano"
            persistent-placeholder
          >
            <template v-slot:append-outer>
              <div class="iconInfo comuneCatastale">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('comuneCatastale')"
                />
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-10">
        <v-col cols="6" class="pr-4">
          <v-text-field
            tabindex="1"
            :error-messages="comuneAmmErrors"
            @blur="v$.comuneAmm.$touch()"
            class="auth__input"
            label="COMUNE AMMINISTRATIVO"
            color="#6e7689"
            v-model="comuneAmm"
          >
            <template v-slot:append-outer>
              <div class="iconInfo comuneAmm">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('comuneAmm')"
                />
              </div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" class="pl-4">
          <v-text-field
            tabindex="1"
            :error-messages="codCatastaleErrors"
            @blur="v$.codCatastale.$touch()"
            class="auth__input"
            label="CODICE CATASTALE"
            placeholder="Es. Y111"
            persistent-placeholder
            color="#6e7689"
            v-model="codCatastale"
          >
            <template v-slot:append-outer>
              <div class="iconInfo codCatastale">
                <img
                  class="ml-1"
                  src="../../../assets/icons/info.svg"
                  width="16"
                  @click="setDialogInfo('codCatastale')"
                />
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row
        class="cadastral__why"
        @click="
          setDialogInfo('cadastralWhy'),
            trackTapEvent({
              name: 'cadastral_whySendData_tap',
              params: paramsTrack
            })
        "
        >Perché inviare i dati catastali?</v-row
      >
      <div class="mb-10 pb-10">
        <v-btn
          width="156"
          height="54"
          color="primary"
          :disabled="disabled"
          @click="request"
          >Continua</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="dialogAlert" width="555" persistent>
      <div class="dialogCatastral">
        <div class="dialogCatastral__title mb-5">Informativa</div>
        <div class="dialogCatastral__desc">
          Prima di procedere con la compilazione dei dati catastali comunichiamo
          che in caso di mancato invio o di inesattezza dei dati è possibile
          incorrere in una sanzione amministrativa (da 103€ a 2.065€, ai sensi
          dell’art. 13 del D.P.R. 29/9/1973 n. 605 e successive modifiche); e
          che in ogni caso Sorgenia è tenuta a segnalare tale situazione
          all’Agenzia delle Entrate, per controlli fiscali a carico del cliente
          stesso.
        </div>
        <div class="d-flex justify-space-between align-center">
          <v-btn
            width="230"
            height="54"
            color="primary"
            @click="setCadastralDisplayed"
            >Continua</v-btn
          >
          <v-btn
            width="230"
            height="54"
            text
            color="primary"
            @click="
              trackTapEvent({
                name: 'cadastral_cancelInfo_tap',
                params: paramsTrack
              }),
                $router.go(-1)
            "
            >Torna indietro</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogInfo.show" width="555">
      <div class="dialogCatastral">
        <div class="d-flex justify-space-between align-start mb-5">
          <div class="dialogCatastral__title" v-html="dialogInfo.title" />
          <img
            src="../../../assets/icons/close-blu.svg"
            @click="dialogInfo.show = false"
          />
        </div>
        <div class="dialogCatastral__desc" v-html="dialogInfo.desc" />
        <div class="d-flex justify-center align-center">
          <v-btn
            width="156"
            height="54"
            color="primary"
            @click="dialogInfo.show = false"
            >Ho capito</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </page>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import { capitalize, validateCF } from '@/js/utils'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Template from './Template'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'cadastralUser',
  data: () => ({
    firstName: null,
    lastName: null,
    fiscalCode: '',
    typeOwner: null,
    address: null,
    comuneCatastale: null,
    comuneAmm: null,
    codCatastale: null,
    dialogAlert: false,
    dialogInfo: {
      show: false,
      title: '',
      desc: ''
    },
    clientDetails: null
  }),
  components: {
    page: Template,
    TcVars
  },
  watch: {
    fiscalCode(val) {
      if (val) this.fiscalCode = val.toUpperCase()
    }
  },
  created() {
    this.getDetailForClient({
      clientOwnerCode: this.$route.params.clientOwnerCode
    }).then((resp) => {
      this.clientDetails = resp
      this.firstName = capitalize(this.cadastralData.nomeSottoscrittore)
      this.lastName = capitalize(this.cadastralData.cognomeSottoscrittore)
      this.fiscalCode = this.cadastralData.codiceFiscaleSottoscrittore
      this.address = capitalize(
        this.cadastralData.indirizzoSottoscrittore || resp.residentialAddress
      )
      this.comuneAmm = capitalize(
        this.cadastralData.comuneAmministrativoSottoscrittore ||
          resp.residentialCity
      )
      this.typeOwner = this.cadastralData.qualificaSottoscrittore || null
      this.codCatastale = this.cadastralData.codiceComuneSottoscrittore || null
      this.comuneCatastale =
        this.cadastralData.comuneCatastaleSottoscrittore || null
      this.dialogAlert =
        !this.supplyDetail[this.$route.params.supplyCode].cadastral.displayed
    })
  },
  mounted() {
    this.trackTapEvent({
      name: 'cadastral_clientDataInsert_tap',
      params: this.paramsTrack
    })
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail', 'cadastralData']),
    paramsTrack() {
      const supplyCode = this.$route.params.supplyCode
      return {
        supply_type:
          this.supplyDetail &&
          this.supplyDetail[supplyCode].serviceType.toLowerCase(),
        pr_code: this.$route.params.supplyCode,
        client_code:
          this.supplyDetail &&
          this.supplyDetail[this.$route.params.supplyCode].clientOwnerCode,
        client_type:
          this.supplyDetail &&
          this.supplyDetail[supplyCode].clientOwnerType.toLowerCase()
      }
    },
    disabled() {
      return this.v$.$invalid
    },
    firstNameErrors() {
      let errArray = []
      if (!this.v$.firstName.$dirty) return errArray
      this.v$.firstName.required.$invalid === true &&
        errArray.push('Il nome è obbligatorio')
      return errArray
    },
    lastNameErrors() {
      let errArray = []
      if (!this.v$.lastName.$dirty) return errArray
      this.v$.lastName.required.$invalid === true &&
        errArray.push('Il cognome è obbligatorio')
      return errArray
    },
    fiscalCodeErrors() {
      let errArray = []
      if (!this.v$.fiscalCode.$dirty) return errArray
      this.v$.fiscalCode.required.$invalid === true &&
        errArray.push('Il codice fiscale è obbligatorio')
      this.v$.fiscalCode.validateCF === false &&
        errArray.push('Formato codice fiscale non valido')
      return errArray
    },
    typeOwnerErrors() {
      let errArray = []
      if (!this.v$.typeOwner.$dirty) return errArray
      this.v$.typeOwner.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')
      return errArray
    },
    addressErrors() {
      let errArray = []
      if (!this.v$.address.$dirty) return errArray
      this.v$.address.required.$invalid === true &&
        errArray.push('I’indirizzo è obbligatorio')
      return errArray
    },
    comuneAmmErrors() {
      let errArray = []
      if (!this.v$.comuneAmm.$dirty) return errArray
      this.v$.comuneAmm.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')
      return errArray
    },
    comuneCatastaleErrors() {
      let errArray = []
      if (!this.v$.comuneCatastale.$dirty) return errArray
      this.v$.comuneCatastale.isValidCity === false &&
        errArray.push('Formato comune catastale non valido')
      return errArray
    },
    codCatastaleErrors() {
      let errArray = []
      if (!this.v$.codCatastale.$dirty) return errArray
      this.v$.codCatastale.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')
      this.v$.codCatastale.isValideCode === false &&
        errArray.push('Il codice è formato da 1 lettera e 3 numeri')
      return errArray
    }
  },
  methods: {
    ...mapMutations('supply', ['setCadastralData', 'cadastraAlertDisplayed']),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    request() {
      this.cadastralData.codiceCliente = this.clientDetails.clientCode
      this.cadastralData.codicePunto = this.$route.params.supplyCode
      this.cadastralData.nomeIntestatario = this.clientDetails.businessName
      this.cadastralData.indirizzoIntestatario = capitalize(
        this.clientDetails.residentialAddress
      )
      this.cadastralData.codiceFiscaleIntestatario =
        this.clientDetails.clientType !== 'BUS'
          ? this.clientDetails.fiscalCode
          : ''
      this.cadastralData.pIvaIntestatario =
        this.clientDetails.clientType === 'BUS'
          ? this.clientDetails.VATNumber
          : ''
      this.cadastralData.commodity =
        this.supplyDetail[this.$route.params.supplyCode].serviceType === 'Ele'
          ? 'E'
          : 'G'
      this.cadastralData.podPdr =
        this.supplyDetail[this.$route.params.supplyCode].pdr ||
        this.supplyDetail[this.$route.params.supplyCode].pod
      this.cadastralData.nomeSottoscrittore =
        this.firstName || this.clientDetails.businessName
      this.cadastralData.cognomeSottoscrittore =
        this.lastName || this.clientDetails.businessName
      this.cadastralData.codiceFiscaleSottoscrittore = this.fiscalCode
      this.cadastralData.qualificaSottoscrittore = this.typeOwner
      this.cadastralData.comuneAmministrativoSottoscrittore = this.comuneAmm
      this.cadastralData.comuneCatastaleSottoscrittore =
        this.comuneCatastale || ''
      this.cadastralData.codiceComuneSottoscrittore = this.codCatastale
      this.cadastralData.indirizzoSottoscrittore = this.address
      this.setCadastralData(this.cadastralData)

      this.$router.push({ name: 'cadastralData' })
    },
    setDialogInfo(type) {
      if (type === 'cadastralWhy') {
        this.dialogInfo.title = 'Perché inviare i dati catastali?'
        this.dialogInfo.desc =
          'Secondo la legge finanziaria del 2005 (Legge 311 del 30.12.2004 - art. 1 comma 333) e dai successivi provvedimenti dell’Agenzia delle Entrate, tutte le società di vendita di gas ed energia elettrica, devono chiedere ai propri clienti di comunicare i dati catastali relativi agli immobili in cui è attiva la fornitura.'
      } else if (type === 'comuneCatastale') {
        this.dialogInfo.title = 'Cos’è il comune catastale?'
        this.dialogInfo.desc =
          'È il territorio delimitato dai confini catastali: in genere coincide con il comune amministrativo.'
      } else if (type === 'comuneAmm') {
        this.dialogInfo.title = 'Cos’è il comune amministrativo?'
        this.dialogInfo.desc =
          "È il comune in cui si trova l'immobile per il quale stai attivando la fornitura."
      } else if (type === 'codCatastale') {
        this.dialogInfo.title = 'Dove trovo il codice catastale?'
        this.dialogInfo.desc =
          'Puoi trovare il codice del tuo comune all’indirizzo <a class="linkInfoDialog" target="_blank" href="http://www.comuni-italiani.it/alfa">www.comuni-italiani.it/alfa</a>'
      }

      if (type !== 'cadastralWhy') {
        this.trackTapEvent({
          name: 'cadastral_info_tap',
          params: { ...this.paramsTrack, info_data: type }
        })
      }

      this.dialogInfo.show = true
    },
    setCadastralDisplayed() {
      this.cadastraAlertDisplayed(this.$route.params.supplyCode)
      this.trackTapEvent({
        name: 'cadastral_acceptInfo_tap',
        params: this.paramsTrack
      })
      this.dialogAlert = false
    }
  },
  validations: {
    firstName: { required },
    lastName: { required },
    fiscalCode: { required, validateCF },
    typeOwner: { required },
    address: { required },
    comuneAmm: { required },
    comuneCatastale: {
      isValidCity: function (city) {
        const regex =
          /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/
        return regex.test(city)
      }
    },
    codCatastale: {
      required,
      isValideCode: function (code) {
        const regex = /^[a-zA-Z]{1}[0-9]{3}$/
        return regex.test(code)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cadastral {
  width: 752px;
  &__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 1.3px;
  }
  &__why {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 1.2px;
    color: $srg-blue;
    margin-bottom: 32px;
    cursor: pointer;
  }
}
.v-dialog__content {
  .dialogCatastral {
    background: white;
    padding: 40px;
    img {
      cursor: pointer;
    }
    &__title {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 1px;
    }
    &__desc {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
  }
}
</style>
<style lang="scss">
.v-select__selection--comma {
  color: $srg-blue !important;
}
.v-menu__content {
  box-shadow: 0 2px 4px 0 rgba(44, 53, 66, 0.08),
    0 4px 8px 0 rgba(44, 53, 66, 0.08), 0 12px 24px 0 rgba(44, 53, 66, 0.06);
  .v-list.v-select-list {
    .v-list-item {
      border-bottom: 1px solid #eaeef5;
      margin: 0 20px;
      padding: 16px 0;
      line-height: 1.4;
      letter-spacing: 1px;
      font-size: 16px;
      min-height: initial;
      .itemTypeOwner {
        color: $srg-blue;
      }
      &:hover::before,
      &--active::before {
        opacity: 0;
      }
      &:hover,
      &--active {
        .itemTypeOwner {
          color: #193494;
          font-weight: bold;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.cadastral {
  label.v-label.theme--light {
    top: -25px;
    transition: none;
    transform: none;
  }
  .theme--light.v-input input {
    color: $srg-blue !important;
    font-family: Roboto;
    font-size: 16px !important;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  .theme--light.v-input.error--text input {
    color: #cc0000 !important;
  }
  .v-messages.theme--light.error--text {
    color: #cc0000 !important;
  }
  .v-text-field .v-label {
    color: #6e7689 !important;
  }
  .iconInfo {
    position: absolute;
    top: -12px;
    cursor: pointer;
    &.comuneCatastale {
      left: 162px;
    }
    &.comuneAmm {
      left: 209px;
    }
    &.codCatastale {
      left: 151px;
    }
  }
}
.linkInfoDialog {
  color: black !important;
  text-decoration: underline;
}
</style>
