<template>
  <div class="successUpload">
    <div :class="`successUpload__header ${commodityType}`">
      <div class="successUpload__title">
        {{
          `Next Energy ${
            commodityType === 'ele' ? 'Luce' : 'Gas'
          }: digitale e vantaggioso`
        }}
      </div>
    </div>
    <div class="successUpload__content std-side-padding topRadius pt-10">
      <h2 class="mb-4">Bolletta ricevuta!</h2>
      <h3 class="mb-4">La tua bolletta è stata caricata correttamente.</h3>
      <div class="desc mb-10">
        Un nostro operatore ti contatterà il prima possibile per continuare
        l’attivazione della fornitura
        {{ commodityType === 'ele' ? 'Luce' : 'Gas' }} via chiamata.
      </div>
      <MYSButton
        buttonClass="primaryButton mb-10"
        width="262"
        @buttonClick="
          trackTapEvent({
            name: 'upsellingcom_thankyoupagecallmeback_back',
            params: {
              type: commodityType,
              client_selected: client.clientOwnerCode || ''
            }
          }),
            $router.push({ name: 'home' })
        "
        buttonText="Torna all'homepage"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
export default {
  name: 'successUploadComm',
  components: {
    MYSButton
  },
  beforeMount() {
    this.trackTapEvent({
      name: 'upsellingcom_thankyoupagecallmeback_open',
      params: {
        type: this.$store.getters['upsellingComm/commodityType'],
        client_selected: this.client.clientOwnerCode || ''
      }
    })
  },
  computed: {
    ...mapGetters('upsellingComm', ['commodityType', 'client'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>
<style lang="scss" scoped>
.successUpload {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  &__header {
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: cover;
    &.ele {
      background-image: url('../../../../assets/img-promo-luce-2022.png');
    }
    &.gas {
      background-image: url('../../../../assets/img-promo-gas-2022.png');
    }
  }
  &__title {
    font-size: 40px;
    font-family: 'Roboto';
    width: 85%;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding-top: 7%;
    padding-left: 51%;
    line-height: normal;
  }
  &__content {
    margin-top: -30px !important;
    background: white;
  }
  h2,
  h3 {
    font-weight: 500;
  }
  .desc {
    color: black;
    max-width: 653px;
    font-size: 20px;
    line-height: 1.3;
  }
  .v-btn {
    letter-spacing: normal;
  }
}
</style>
