<template>
  <v-dialog :value="dialog" max-width="555" persistent>
    <div class="dialog-pending-balance">
      <div class="header-dialog-user-pending">
        <p class="HeadingsSubtitleL mb-10">
          {{
            typeModal === 'saldo'
              ? 'Saldo Green Coins'
              : typeModal === 'greeners'
              ? 'Codice amico Greeners'
              : typeModal === 'catalog'
              ? 'Premi per te'
              : 'Bonus per te'
          }}
        </p>
        <img
          class="close"
          @click="$emit('closeModal')"
          src="../../assets/ic_chevron_top_black.png"
        />
      </div>
      <div class="body-dialog-user-pending">
        <div
          class="image-dialog-pending mb-8"
          v-if="typeModal !== 'saldo' && typeModal !== 'catalog'"
          :class="[
            typeModal === 'bonus' ? 'bonus' : '',
            typeModal === 'greeners' ? 'greeners' : ''
          ]"
        ></div>
        <template v-if="typeModal === 'saldo'">
          <p class="bodyL mb-5">
            Stiamo attivando la tua registrazione: a breve sarai un Greener! Al
            termine, potrai visualizzare qui i tuoi Green Coins accumulati con
            le Missioni.
          </p>
          <p>
            Nel frattempo, inizia ad esplorare Greeners: completando la tua
            prima missione otterrai 150 Green Coins di benvenuto.
          </p>
        </template>
        <template v-if="typeModal === 'bonus'">
          <p class="bodyL mb-5">
            Stiamo attivando la tua registrazione: a breve sarai un Greener! Al
            termine potrai inserire qui il codice che hai ricevuto per ottenere
            vantaggi. Se sei stato invitato da un amico a unirti alla community,
            inserendo qui il codice riceverai i Green Coins di benvenuto.
          </p>
        </template>
        <template v-if="typeModal === 'greeners'">
          <p class="bodyL mb-5">
            Stiamo attivando la tua registrazione: a breve sarai un Greener! Al
            termine potrai invitare i tuoi amici a unirsi alla community!
            Riceverai 250 Green Coins per ogni amico che accetta il tuo invito!
          </p>
        </template>
        <template v-if="typeModal === 'catalog'">
          <p class="bodyL mb-6">
            Stiamo attivando la tua registrazione: a breve sarai un Greener! Al
            termine potrai utilizzare i tuoi Green Coins per richiedere i premi
            del catalogo pensati per te.
          </p>
          <p class="bodyL">
            Nel frattempo, esplora Greeners: completando la tua prima missione
            otterrai 150 Green Coins di benvenuto.
          </p>
        </template>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'BalanceUserPending',
  props: {
    dialog: { type: Boolean, default: false },
    typeModal: { type: String }
  }
}
</script>

<style lang="scss" scoped>
.dialog-pending-balance {
  background: white;
  padding: 40px;
  position: relative;

  .image-dialog-pending {
    width: 476px;
    height: 168px;
    background-size: cover;
    border-radius: 8px;

    &.bonus {
      background-image: url('../../assets/greeners/header-greeners-new.png');
    }
    &.greeners {
      background-image: url('../../assets/greeners/greeners_pending.png');
    }
  }

  .close {
    position: absolute;
    cursor: pointer;
    right: 45px;
    top: 45px;
  }
}
</style>
