<template>
  <div class="sel-address">
    <FiberHeader />
    <div class="sel-address__content std-side-padding topRadius pt-8">
      <div
        @click="$router.push({ name: 'ownerSupplies' })"
        class="sel-address__back"
      >
        <h2>Seleziona indirizzo</h2>
      </div>
      <div class="subTitle">
        Al momento la Fibra è disponibile per i seguenti indirizzi
      </div>
      <div
        class="mt-10"
        v-for="(client, i) in Object.keys(clients) || []"
        :key="i"
      >
        <div :class="`name ${clients[client][0].clientOwnerType}`">
          {{ client }}
        </div>
        <div
          class="box-address"
          v-for="(supply, i) in clients[client]"
          :key="i"
          @click="selectAddress(supply)"
        >
          <h5>{{ supply.addressComplete }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FiberHeader from './fiber_bus/FiberHeader.vue'
import { capitalize } from '@/js/utils'
import GenErr from '@/js/genericErrors'
import { uuidv4 } from '@/js/utils.js'

export default {
  name: 'selectAddressResBus',
  computed: {
    ...mapGetters('account', ['accountProperties']),
    clients() {
      if (
        !this.accountProperties ||
        !this.accountProperties.fiberEligibleSupplies
      )
        return {}
      let clientsGroup = {}

      this.accountProperties.fiberEligibleSupplies.forEach((s) => {
        if (!s.egonStreet) return false
        const addressId = (sup) =>
          `${sup.egonRegion || ''}_${sup.county}_${sup.city}_${
            sup.egonLocality
          }_${sup.zipcode}_${sup.egonStreet}_${sup.egonStreetNumber}_${
            sup.egonStreetNumberLetter
          }`.toLowerCase()
        let name = ''
        if (s.clientOwnerType === 'RES') {
          name = s.firstName + ' ' + s.lastName
        } else {
          name = s.businessName
        }
        if (
          clientsGroup[name] &&
          clientsGroup[name].find((sup) => addressId(sup) === addressId(s))
        ) {
          return false
        }
        clientsGroup[name] = [
          ...(clientsGroup[name] || ''),
          { ...s, addressComplete: capitalize(s.street + ', ' + s.city) }
        ]
      })
      return clientsGroup
    }
  },
  beforeMount() {
    this.getSupplies()
    this.trackTapEvent({
      name: 'upsellingv2_selectaddress_open',
      params: { client_selected: 'not_selected' }
    })
    this.resetFiberCtx()
    this.resetFiberState()
    this.resetState()
    this.updateFiberCtx({
      upselling_session_id: uuidv4(),
      upselling_step_name: 'Seleziona indirizzo'
    })
  },
  methods: {
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('account', ['getClientsForAccount', 'getDetailForClient']),
    ...mapMutations('upsellingFiber', [
      'setAddressCovered',
      'setClient',
      'setProvider',
      'setConnectionType',
      'updateFiberCtx',
      'resetFiberCtx',
      'resetFiberState'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('offers', ['resetState']),
    async setClientSelected(supply) {
      await this.getClientsForAccount()
        .then(async (resp) => {
          resp = Object.values(resp)
          resp = resp.filter(
            (client) => supply.clientOwnerCode === client.clientOwnerCode
          )[0]
          await this.getDetailForClient(resp).then((clientDetails) => {
            const clientWithDetails = {
              ...resp,
              ...clientDetails
            }
            this.setClient(clientWithDetails)
            this.updateFiberCtx({
              client_code: clientWithDetails.clientOwnerCode,
              role: clientWithDetails.mainRole,
              client_firstname:
                clientWithDetails.name || clientWithDetails.businessName,
              client_lastname:
                clientWithDetails.surname || clientWithDetails.businessName,
              supply_address: supply.addressComplete
            })
            resolve()
          })
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    },
    async selectAddress(supply) {
      let connectionType = 'FTTH'
      if (supply.typology === 'VDSL' || supply.typology === 'EVDSL') {
        connectionType = 'FTTC'
      }

      this.setAddressCovered(supply)
      await this.setClientSelected(supply)
      this.setProvider(supply.fiberType)
      this.setConnectionType(connectionType)

      if (supply.clientOwnerType === 'BUS') {
        this.$router.push({ name: 'fiberFlowBus' })
      } else {
        this.$router.push({ name: 'fiberFlow' })
      }
    }
  },
  components: {
    FiberHeader
  }
}
</script>
<style lang="scss" scoped>
.sel-address {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  &__content {
    margin-top: -30px !important;
    background: white;
  }
  &__back {
    display: flex;
    align-items: center;
    width: fit-content;
    h2 {
      font-weight: 500;
      color: black;
      cursor: pointer;
    }
    &::before {
      content: url('../../assets/ic-chevron-sx-blue.svg');
      width: 24px;
      margin-right: 16px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  .subTitle {
    font-size: 16px;
    margin-top: 32px;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    &.RES::after {
      content: url('../../assets/ic-residenziale_black-small.svg');
      width: 16px;
      margin-left: 8px;
    }
    &.BUS::after {
      content: url('../../assets/ic-business_black-small.svg');
      width: 16px;
      margin-left: 8px;
    }
  }
  .box-address {
    width: 555px;
    height: 56px;
    margin: 24px 0;
    padding: 0 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    letter-spacing: 0.5px;
    &::after {
      content: url('../../assets/icons/chevron_rx.svg');
      width: 16px;
    }
  }
}
</style>
<style lang="scss">
.st-dialog {
  background: white;
  padding: 55px 45px 45px 55px;
  border-radius: 40px;
  position: relative;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    &.preview {
      position: initial;
    }
  }
}
</style>
