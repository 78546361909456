<template>
  <div class="dashboard-monitor-stat">
    <div class="mb-4">
      <img :src="icon" width="40" />
    </div>
    <div>
      <h2 class="HeadingL">{{ title }}</h2>
    </div>
    <div class="HeadingsTitleXL">
      {{ value }}<small class="BodyL"> kW</small>
    </div>

    <div v-for="(item, index) in newItems" :key="item.title">
      <DashboardMonitorStatItemVue
        :title="item.title"
        :value="item.value"
        :iconName="item.iconName"
      ></DashboardMonitorStatItemVue>
      <hr v-if="index !== items.length - 1" />
    </div>
  </div>
</template>
<script>
import FotovoltaicoSvg from '@/assets/fotovoltaico/fotovoltaico.svg'
import CasaSvg from '@assets/fotovoltaico/casa.svg'
import DashboardMonitorStatItemVue from '../../../components/fotovoltaico/dashboard/DashboardMonitorStatItem.vue'

const mapSvg = {
  fotovoltaico: FotovoltaicoSvg,
  casa: CasaSvg
}

export default {
  props: {
    title: { type: String, required: true },
    value: { type: String, required: true },
    iconName: { type: String, required: true },
    items: { type: Array, required: true },
    showBatteria: { type: Boolean, required: true }
  },
  components: { DashboardMonitorStatItemVue },
  data() {
    return {
      newItems: 0
    }
  },
  mounted() {
    this.newItems = this.items
    // se non abbiamo la batteria, nascondo ultimi valori che rappresentano le informazioni sui dati
    // relative alla batteria, come "immesso in batteria" o "prelevato dalla batteria"
    if (this.showBatteria !== true) {
      this.newItems.splice(-1)
    }
  },
  computed: {
    icon() {
      return mapSvg[this.iconName]
    }
  }
}
</script>
<style lang="scss" scoped>
.dashboard-monitor-stat {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px 16px 16px 16px;
}
hr {
  border: 1px solid #eaeef5;
}
</style>
