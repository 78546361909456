<template>
  <div>
    <div class="myshop">
      <router-link
        :to="{ name: 'home' }"
        class="std-side-padding d-flex align-center"
        ><img :src="require('../../assets/ic-chevron-sx-blue.svg')" />Torna
        all’homepage</router-link
      >
    </div>
    <div>
      <v-row v-if="!loaded" class="d-flex justify-center pa-10">
        <v-progress-circular :size="50" indeterminate></v-progress-circular>
      </v-row>
      <v-row>
        <iframe
          id="ecommerce"
          v-show="loaded"
          frameborder="0"
          :src="myshopUrl"
        ></iframe>
      </v-row>
    </div>
  </div>
</template>
<script>
import GenErr from '@/js/genericErrors'
import { ENV_CONFIGS } from '@/js/configs.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MyShop',
  data() {
    return {
      myshopUrl: ENV_CONFIGS.MYSHOP_ENDPOINT,
      loaded: false
    }
  },
  computed: {
    ...mapGetters('session', ['username', 'token']),
    clientCode() {
      return this.$route.params.code || this.$route.params.clientCode
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    safeParse: function (str) {
      try {
        return JSON.parse(str)
      } catch {
        return str
      }
    },
    handleMessageMyShop: function (event) {
      var data =
        typeof event.data === 'string' ? this.safeParse(event.data) : event.data
      if (data.type === 'onLoad' || data.type === 'webpackOk') {
        let iframe = document.getElementById('ecommerce').contentWindow
        let json = JSON.stringify({
          type: 'jwt',
          data: {
            clientCode: this.clientCode,
            token: this.token,
            username: this.username
          }
        })
        iframe.postMessage(json, '*')
      } else if (data.type === 'jwtLoaded') {
        this.loaded = true
      } else if (data.type === 'error') {
        GenErr.showErrorMessage(data.data.message)
      }
    }
  },
  mounted() {
    this.trackTapEvent('home_myShop_tap')
    window.addEventListener('message', this.handleMessageMyShop)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleMessageMyShop)
  }
}
</script>
<style lang="scss" scoped>
#ecommerce {
  width: 100%;
  height: calc(100vh - 67px);
  margin: 0px;
  padding: 0px;
}

.myshop {
  width: 100%;
  height: 60px;
  background: #97d707;
  a {
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    color: $srg-blue;
    text-decoration: none;
    img {
      margin-right: 8px;
    }
  }
}
</style>
