<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="555">
    <div class="dialogClient">
      <img @click="closeDialog" src="../../assets/ic_chevron_top_black.png" />
      <div class="d-flex align-center justify-center" v-if="!loaded">
        <v-progress-circular
          class="my-3"
          :size="80"
          indeterminate
          color="#12256A"
        />
      </div>
      <div v-else>
        <div class="mb-10 HeadingsSubtitleL">
          {{ labelsCase === 'noReg' ? 'Aggiungi' : 'Seleziona' }} profilo
        </div>
        <div v-if="labelsCase === 'noReg'" class="desc BodyL mb-8">
          Seleziona il profilo con cui ti vuoi iscrivere. Al termine
          dell'iscrizione potrai aggiungere un altro profilo.
        </div>
        <div class="mb-8" v-for="(client, i) in loyaltyClients" :key="i">
          <div
            v-if="i === 1 && labelsCase === 'oneReg'"
            class="mb-2 HeadingsSubtitleL"
          >
            Aggiungi profilo
          </div>
          <p v-if="i === 1 && labelsCase === 'oneReg'" class="BodyL mb-6">
            Selezionando il profilo sottostante entri a far parte di Greeners
            anche con questo profilo. Qual è il vantaggio? Potrai unire i Green
            Coins accumulati con entrambi i profili!
          </p>
          <div class="box" @click="selectClient(client)">
            <div :class="`client ${client.clientOwnerType}`">
              <h5>
                {{
                  client.clientOwnerType === 'RES'
                    ? client.firstName + ' ' + client.lastName
                    : client.businessName
                }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'selectClientDialog',
  props: ['value'],
  data() {
    return {
      loyaltyClients: [],
      labelsCase: '',
      loaded: false
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.init()
      } else {
        this.loyaltyClients = []
      }
    }
  },
  computed: {
    ...mapGetters('account', [
      'mainClientLoyaltyRes',
      'mainClientLoyaltyBus',
      'oldestLoyaltyRes',
      'oldestLoyaltyBus',
      'mainClientLoyaltySelected'
    ])
  },
  methods: {
    ...mapActions('loyalty', ['checkMemberStatus']),
    ...mapMutations('account', ['setMainClientLoyaltySelected']),
    init() {
      this.loaded = false
      if (this.mainClientLoyaltyRes) {
        this.loyaltyClients.push(this.mainClientLoyaltyRes)
      } else if (this.oldestLoyaltyRes) {
        let label = this.oldestLoyaltyRes
        if (this.oldestLoyaltyRes.isMultiResClient) {
          label.firstName = 'Profilo'
          label.lastName = 'Residenziale'
        }
        this.loyaltyClients.push(label)
      }
      if (this.mainClientLoyaltyBus) {
        this.loyaltyClients.push(this.mainClientLoyaltyBus)
      } else if (this.oldestLoyaltyBus) {
        let label = this.oldestLoyaltyBus
        if (this.oldestLoyaltyBus.isMultiBusClient) {
          label.businessName = 'Profilo Business'
        }
        this.loyaltyClients.push(label)
      }

      if (this.loyaltyClients.length > 1) {
        let promises = []
        this.loyaltyClients.forEach((client) => {
          promises.push(
            new Promise((resolve, reject) => {
              this.checkMemberStatus({ ext_uuid: client.clientOwnerCode }).then(
                (resp) => {
                  client.user_registered = resp.user_registered
                  client.status_category = resp.status_category
                  resolve(client)
                }
              )
            }).catch(() => {
              return { status: 'error' }
            })
          )
        })
        Promise.all(promises, this).then((value) => {
          if (value.find((s) => s.status === 'error')) {
          } else {
            this.initLabel()
          }
        })
      }
    },
    initLabel() {
      this.loyaltyClients.sort((a) => {
        if (a.user_registered) {
          return -1
        } else {
          return 1
        }
      })
      const lcase = this.loyaltyClients.filter((c) => c.user_registered)
      if (lcase.length > 1) {
        this.labelsCase = 'allReg'
      } else if (lcase.length === 0) {
        this.labelsCase = 'noReg'
      } else {
        this.labelsCase = 'oneReg'
      }
      this.loaded = true
    },
    closeDialog() {
      this.$emit('input')
    },
    selectClient(client) {
      this.closeDialog()
      this.setMainClientLoyaltySelected(client)
      this.$router.push({ name: 'loyaltyPage' })

      // if (client.user_registered && (client.status_category === 'AWARE' || client.status_category === 'FREEWILL')) {
      //   this.$router.push({ name: 'loyaltyPage' })
      // } else {
      //   this.$router.push({ name: 'introPage' })
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-dialog__content {
  .dialogClient {
    color: black;
    background: white;
    padding: 40px;
    border-radius: 40px;
    position: relative;
    .small {
      padding: 55px 110px 55px 55px;
    }
    h4 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 24px;
      letter-spacing: 1px;
      color: black;
    }
    .box {
      width: 100%;
      height: 57px;
      border-radius: 20px;
      border: 1px solid #dfe4ef;
      padding: 0 18px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        border-color: #12256a;
      }
      .client {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        h5 {
          font-weight: 700;
          font-size: 18px;
          letter-spacing: 0.8px;
          color: $srg-blue;
        }
        &.RES::before {
          width: 20px;
          content: url('../../assets/icons/ic-owner-res.svg');
          margin-right: 16px;
        }
        &.BUS::before {
          width: 20px;
          content: url('../../assets/icons/ic-business.svg');
          margin-right: 16px;
        }
      }
      &::after {
        content: url('../../assets/icons/chevron_rx.svg');
      }
    }
    .titleAlert {
      font-weight: 500;
      margin-bottom: 8px;
    }
    img {
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;
      &.preview {
        position: initial;
      }
    }
  }
}
</style>
