<template>
  <div class="promo">
    <transition name="fade">
      <div v-if="true" class="d-flex justify-space-around">
        <Placeholder type="promo" v-show="!loaded" />
        <Placeholder type="promo" v-show="!loaded" />
        <Placeholder type="promo" v-show="!loaded" />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="true">
        <div
          v-if="loaded && promoFilter.length <= 3"
          class="VueCarousel d-flex flex-row justify-content-start"
        >
          <div
            v-for="(promo, i) in promoFilter"
            :key="i"
            class="promo-element"
            :class="{
              'std-side-padding': promoFilter.length == 1 && mainClient.isOnlyFV
            }"
          >
            <PromoRouting :promo="promo">
              <template v-slot:contentPromo>
                <div
                  class="item"
                  :class="{
                    'item only-one-promo':
                      promoFilter.length == 1 && mainClient.isOnlyFV
                  }"
                  :style="`background-image: url('${
                    promo.id === 'welcomekit'
                      ? promo.background_image
                      : cdn + promo.background_image
                  }');`"
                  @click="
                    trackTapEvent({
                      name: 'vetrina_promo_tap',
                      params: { promo_id: promo.id, promo_title: promo.title }
                    })
                  "
                >
                  <div class="item__title" v-html="promo.title" />
                </div>
              </template>
            </PromoRouting>
          </div>
        </div>
        <carousel
          v-if="loaded && promoFilter.length > 3"
          :perPage="3"
          :paginationEnabled="true"
          paginationColor="#b7bfd2"
          paginationActiveColor="#12256A"
          :paginationPadding="5"
        >
          <slide v-for="(promo, i) in promoFilter" :key="i">
            <PromoRouting :promo="promo">
              <template v-slot:contentPromo>
                <div
                  class="item"
                  :style="`background-image: url('${
                    promo.id === 'welcomekit'
                      ? promo.background_image
                      : cdn + promo.background_image
                  }');`"
                  @click="
                    trackTapEvent({
                      name: 'vetrina_promo_tap',
                      params: { promo_id: promo.id, promo_title: promo.title }
                    })
                  "
                >
                  <div class="item__title" v-html="promo.title" />
                </div>
              </template>
            </PromoRouting>
          </slide>
        </carousel>
      </div>
    </transition>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
import { mapActions, mapGetters } from 'vuex'
import Placeholder from '@components/global/Placeholder.vue'
import PromoRouting from './PromoRouting.vue'

import { ENV_CONFIGS } from '@/js/configs'

export default {
  name: 'promoComponent',
  components: {
    Placeholder,
    Carousel,
    Slide,
    PromoRouting
  },
  props: {
    loaded: { type: Boolean, default: false }
  },
  data() {
    return {
      cdn: ENV_CONFIGS.CMS_CDN_BASE_URL
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  },
  computed: {
    ...mapGetters('account', ['accountProperties', 'promos', 'mainClient']),
    ...mapGetters('loyalty', ['memberStatus']),
    ...mapGetters('supply', ['supplies', 'isNotAllPlacet']),
    ...mapGetters('welcomekit', ['welcomekit']),
    ...mapGetters('session', ['token']),
    promoFilter() {
      if (!this.promos) return []

      let isClientDigital = this.accountProperties.hasAtLeast1Digital
      let isClientPlacet = !this.isNotAllPlacet
      let canBuyGas =
        this.accountProperties.hasLightActivating ||
        this.accountProperties.hasLightActive
      let canBuyElectricity =
        this.accountProperties.hasGasActivating ||
        this.accountProperties.hasGasActive
      // TO DO POST SVILUPPO CMS
      // let canBuyPhotovoltaic =
      //   this.accountProperties.hasPhotovoltaicActivating ||
      //   this.accountProperties.hasPhotovoltaicActive
      let canBuyFiber = this.accountProperties.isFiberEligible
      let isLoyaltyEnrollment = this.memberStatus.user_registered
      let isLoyaltyEligibility = this.accountProperties.isLoyaltyEligible

      let eligibilities = []
      if (canBuyElectricity) {
        eligibilities.push('ELE')
      }
      if (canBuyGas) {
        eligibilities.push('GAS')
      }
      if (canBuyFiber) {
        eligibilities.push('FIBRA')
      }
      // TO DO POST SVILUPPO CMS
      // if (canBuyPhotovoltaic) {
      //   eligibilities.push('PHOTOVOLTAIC')
      // }
      let clientTypeStr = this.accountProperties.type
      let suffix = isClientPlacet
        ? 'placet'
        : isClientDigital
        ? 'digital'
        : 'nondigital'

      let clientTypes =
        clientTypeStr === 'MIXED'
          ? ['RES-' + suffix, 'BUS-' + suffix]
          : [clientTypeStr + '-' + suffix]

      // Channel 'APP' Filter
      let filteredPromoList = this.promos.filter((item) =>
        item.channel.includes('WEB')
      )

      // Client Types Filter
      filteredPromoList = filteredPromoList.filter((item) => {
        for (let type of clientTypes) {
          if (item.client_type.includes(type)) return true
        }
        return false
      })
      let filteredPromoListChannel = filteredPromoList

      // Eligibilities Filter
      filteredPromoList = filteredPromoList.filter((item) => {
        let ret = false
        eligibilities.forEach((el) => {
          if (item.upselling_eligibility.includes(el)) {
            ret = true
          }
        })
        return ret
      })

      /// Date Filter
      filteredPromoList = filteredPromoList.filter((item) => {
        let now = new Date()
        let startDate = new Date(item.start_date.split('/').reverse().join('-'))
        let endDate = new Date(item.end_date.split('/').reverse().join('-'))
        return startDate <= now && now <= endDate
      })

      // Loyalty Eligibility Filter
      filteredPromoList = filteredPromoList.filter((item) => {
        return (
          (isLoyaltyEligibility && item.loyalty_eligibility === 'Y') ||
          item.loyalty_eligibility === 'NA' ||
          (!isLoyaltyEligibility && item.cfp_done === 'N')
        )
      })

      // Loyalty Enrollment Filter
      filteredPromoList = filteredPromoList.filter((item) => {
        if (
          (isLoyaltyEnrollment && item.loyalty_enrollment === 'Y') ||
          item.loyalty_enrollment === 'NA' ||
          (!isLoyaltyEnrollment && item.loyalty_enrollment === 'N')
        ) {
          this.$store.commit(
            'account/setAccountPropLoyalty',
            isLoyaltyEnrollment
          )
          return true
        }
      })

      // check solo FV
      if (this.mainClient.isOnlyFV && filteredPromoList.length == 0) {
        filteredPromoListChannel = filteredPromoListChannel.find(
          (item) => item.media_type === 'VIDEO'
        )
        filteredPromoList.push(filteredPromoListChannel)
      }

      return filteredPromoList
    },
    lenghtPromos() {
      return this.promoFilter.length > 3 ? 3 : this.promoFilter.length
    }
  }
}
</script>
<style lang="scss" scoped>
.promo {
  .VueCarousel {
    .promo-element {
      width: 100%;
      margin: 0 3px;
    }

    .only-one-promo {
      width: 70%;
    }
    .VueCarousel-slide {
      position: relative;
      margin: 0 3px;
    }
    .item {
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      &__title {
        width: 100%;
        white-space: pre-wrap;
        padding: 32px 40px;
        color: white;
        font-size: 24px;
        font-weight: bold;
        z-index: 1;
        letter-spacing: 0.4px;
        text-transform: uppercase;
      }
    }
  }
}
</style>
<style lang="scss">
.homeNew {
  &__carousel {
    .VueCarousel-dot-container {
      margin-top: 0px !important;
      margin-bottom: 12px !important;
    }
  }
}
</style>
