import { render, staticRenderFns } from "./LoyaltyPage.vue?vue&type=template&id=30a10492&scoped=true"
import script from "./LoyaltyPage.vue?vue&type=script&lang=js"
export * from "./LoyaltyPage.vue?vue&type=script&lang=js"
import style0 from "./LoyaltyPage.vue?vue&type=style&index=0&id=30a10492&prod&lang=scss&scoped=true"
import style1 from "./LoyaltyPage.vue?vue&type=style&index=1&id=30a10492&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a10492",
  null
  
)

export default component.exports