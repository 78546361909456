<template>
  <div class="summary">
    <div class="d-flex justify-space-between align-center summary__total-price">
      <div class="summary__total">Totale mensile</div>
      <div class="">
        <h2>
          {{
            $filters.replaceDecimalPoint(
              offers.price - offers.discount - offers.scontoScalino
            )
          }}
          {{ upsellingInfo.price_currency || '' }}
        </h2>
        <h5 v-if="offers.isScalino" class="infoText">
          Per
          {{ offers.durataScontoScalino }}
          mesi
        </h5>
      </div>
    </div>
    <div class="d-flex justify-space-between align-center summary__discounted">
      <h5>Prezzo scontato</h5>
      <h3>
        {{
          $filters.replaceDecimalPoint(
            offers.price - offers.discount - offers.scontoScalino
          )
        }}
        {{ upsellingInfo.price_currency || '' }}
      </h3>
    </div>
    <div
      class="d-flex justify-space-between align-center summary__fullPrice mb-8"
    >
      <h5>{{ upsellingInfo.name }}</h5>
      <h5 class="reducedPrice">
        {{
          $filters.replaceDecimalPoint(
            offers.price - (offers.isScalino ? offers.discount : 0)
          )
        }}
        {{ upsellingInfo.price_currency || '' }}
      </h5>
    </div>
    <div class="d-flex justify-space-between align-center summary__modem mb-8">
      <h5>Modem</h5>
      <h5>{{ withModem ? 'Incluso' : 'No' }}</h5>
    </div>
    <div class="d-flex justify-space-between summary__repeater mb-10">
      <div class="content">
        <h5>Ripetitore</h5>
        <v-select
          v-model="quantity"
          :items="[0, 1, 2, 3]"
          filled
          width="100"
          background-color="white"
          :single-line="true"
          @change="setQuantity"
        >
          <template v-slot:selection="data">
            <h5 class="mr-2">Q.tà</h5>
            {{ data.item }}
          </template>
        </v-select>
      </div>
      <h5>
        {{
          quantity === 0
            ? 'No'
            : `${(quantity * parseFloat(repeater.price))
                .toFixed(2)
                .replace('.', ',')} ${upsellingInfo.price_currency || ''}`
        }}
      </h5>
    </div>
    <div
      v-if="quantity > 0"
      class="d-flex justify-end summary__repeater mb-10 mt-n13"
    >
      <span>{{ repeater.price_description }}</span>
    </div>
    <div
      class="d-flex justify-space-between align-center summary__activation mb-8"
    >
      <h5>Attivazione</h5>
      <h5>Inclusa</h5>
    </div>
    <div class="d-flex justify-space-between align-center summary__speed mb-8">
      <h5>Velocità massima</h5>
      <h5>{{ speed }}</h5>
    </div>
    <div
      class="mt-5 summary__activationDelay"
      v-if="
        connectionType === 'FTTH' &&
        (provider === 'FW' || provider === 'FC') &&
        isFromFastweb
      "
    >
      <p><strong>Modifiche alla Data di Attivazione della tua linea</strong></p>
      <p>
        Ti informiamo che la tipologia di connessione attiva ora presso il tuo
        indirizzo al momento non ci permette di portare a termina una migrazione
        automatica. Per questo motivo, la data di attivazione della tua nuova
        linea Sorgenia potrebbe subire ritardi.
      </p>
      <p>
        Ma non preoccuparti:
        <strong
          >la tua attuale linea rimane attiva e potrai continuare ad
          utilizzarla</strong
        >
        fino al completamento della migrazione.
      </p>
    </div>

    <div class="d-flex flex-column">
      <div v-if="upsellingInfo.enable_voucher_fiber === '1'">
        <v-checkbox
          class="BodyL voucherFibra"
          v-model="flagVoucherFibra"
          @change="setFlagVoucherFibra(flagVoucherFibra)"
        >
          <template v-slot:label>
            <span class="label">Richiedi il voucher Famiglie</span></template
          >
        </v-checkbox>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SummaryFiber',
  data() {
    return {
      flagVoucherFibra: false,
      quantity: null,
      repeater: {}
    }
  },
  watch: {
    flagVoucherFibra(newVal) {
      this.trackTapEvent({
        name: 'upsellingv2_voucher_tap',
        params: { checked: newVal }
      })
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', [
      'getFlagVoucherFibra',
      'client',
      'withModem',
      'withGoogleWiFi',
      'connectionType',
      'isFromFastweb',
      'provider'
    ]),
    ...mapGetters('offers', ['upsellingInfo', 'offers']),
    speed() {
      if (parseInt(this.offers.speedOffer) < 1000) {
        return `${this.offers.speedOffer} Mega`.replace('.', ',')
      } else {
        return `${parseInt(this.offers.speedOffer) / 1000} Giga`.replace(
          '.',
          ','
        )
      }
    }
  },
  beforeMount() {
    this.quantity = this.withGoogleWiFi
    this.flagVoucherFibra = this.getFlagVoucherFibra
    this.getRepeaterDescriptionCMS('CUSTOMER').then((repeater) => {
      this.repeater = repeater
    })
  },
  methods: {
    ...mapActions('upsellingFiber', ['getRepeaterDescriptionCMS']),
    ...mapMutations('upsellingFiber', [
      'setWithGoogleWiFi',
      'updateFiberCtx',
      'setFlagVoucherFibra'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    setQuantity() {
      this.setWithGoogleWiFi(this.quantity)
      if (this.quantity === 0) {
        this.updateFiberCtx({ has_selected_vas: false })
        this.trackTapEvent({
          name: 'upsellingv2_skiprepeater_tap',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else {
        this.updateFiberCtx({ has_selected_vas: true })
        this.trackTapEvent({
          name: 'upsellingv2_getrepeater_confirm',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.voucherFibra {
  .label {
    color: black;
  }
}

.infoText {
  color: #2c313a;
  text-align: right;
  letter-spacing: 0.5px;
}

.reducedPrice {
  color: #2c313a;
  text-align: right;
  text-decoration: line-through;
}

.summary {
  max-width: 652px;
  color: black;

  &__total {
    font-size: 20px;
    font-weight: 500;
  }

  &__total-price {
    padding-bottom: 12px;
    border-bottom: 1px solid #ccd0e1;
    margin-bottom: 24px;

    h2 {
      font-weight: 500;
    }
  }

  &__discounted {
    h5,
    h3 {
      font-weight: 500;
    }
  }

  &__modem {
    h5 {
      font-weight: 500;
    }
  }

  &__repeater {
    h5 {
      font-weight: 500;
    }

    .content {
      height: 40px;
      width: 90px;
    }
  }

  &__activation {
    h5 {
      font-weight: 500;
    }
  }

  &__speed {
    h5 {
      font-weight: 500;
    }
  }

  &__activationDelay {
    border-top: 1px solid #ccd0e1;
    padding-top: 30px;
    margin-bottom: 40px;

    p {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss">
.summary {
  .v-input__control {
    .v-input__slot {
      .v-input__append-inner {
        .v-input__icon--append {
          .v-icon {
            margin-top: 15px;
            margin-left: -15px;
          }
        }
      }
    }
  }

  .v-text-field > .v-input__control > .v-input__slot {
    display: flex;
    align-items: baseline;
    padding: 0 !important;
    color: black !important;
  }

  .v-text-field--full-width .v-input__prepend-outer,
  .v-text-field--full-width .v-input__prepend-inner,
  .v-text-field--full-width .v-input__append-inner,
  .v-text-field--full-width .v-input__append-outer,
  .v-text-field--enclosed .v-input__prepend-outer,
  .v-text-field--enclosed .v-input__prepend-inner,
  .v-text-field--enclosed .v-input__append-inner,
  .v-text-field--enclosed .v-input__append-outer {
    margin-top: 0px;
  }

  .v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-top: 4px;
    color: black !important;
  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none;
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
}
</style>
