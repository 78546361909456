<template>
  <div class="flex">
    <div>
      <span class="title">{{ title }} </span>
      <span v-if="icon" class="icon">
        <img
          class="mr-1 link"
          src="../../../assets/icons/info.svg"
          style="color: #12256a"
          height="18px"
          @click="dialogCFP = !dialogCFP"
        />
        <v-dialog v-model="dialogCFP" max-width="558">
          <v-card class="dialog-card">
            <v-card-title class="dialog-title">
              <div>Emissioni CO2 evitate</div>
              <img
                class="link"
                @click="dialogCFP = false"
                src="@/assets/icons/ic-close.svg"
            /></v-card-title>
            <v-card-text class="dialog-text"
              >Si stima che un albero assorba ogni anno una media di 15 kg di
              CO2 e che per ogni kWh di energia prodotta dal fotovoltaico venga
              evitata l'emissione di 0,46 kg di anidride carbonica.</v-card-text
            >
          </v-card>
        </v-dialog></span
      >
      <span class="value">{{ value }} </span>
      <span class="subTitle"> {{ subTitle }} </span>
      <span class="trees" v-if="trees">
        <img
          class="ml-2 mr-1"
          src="../../../assets/fotovoltaico/trees.svg"
          style="color: #12256a"
          height="18px"
        />
        CO2 equivalente a quella catturata in un anno da
        {{
          trees.toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        }}
        Alberi
      </span>
    </div>
    <div v-if="question" class="question link" @click="dialog = !dialog">
      Come calcoliamo il tuo risparmio?
      <v-dialog v-model="dialog" max-width="558">
        <v-card class="dialog-card">
          <v-card-title class="dialog-title">
            <div>Come calcoliamo il risparmio</div>
            <img
              class="link"
              @click="dialog = false"
              src="@/assets/icons/ic-close.svg"
          /></v-card-title>
          <v-card-text class="BodyL pl-0 pr-0 pb-0 mt-10"
            >La quota parte dei benefici economici determinata dal risparmio in
            bolletta viene stimata attribuendo un valore economico alla quantità
            di energia che viene autoconsumata, prendendo come riferimento il
            Prezzo Zonale Orario (PO).</v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    value: { type: String, required: true },
    subTitle: { type: String, required: false },
    question: { type: Boolean, required: false, default: false },
    icon: { type: Boolean, required: false, default: false },
    trees: { type: Number, required: false }
  },
  data() {
    return {
      dialog: false,
      dialogCFP: false
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.value {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-left: 3px;
}

.subTitle {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 22.4px;
}

.question {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 25.2px;
  color: #12256a;
}

.BodyL {
  color: #000 !important;
}
.v-dialog {
  border-radius: 20px;
}
.dialog-card {
  padding: 40px;
  border-radius: 0 0 20px 20px;
}
.dialog-title {
  display: flex;
  justify-content: space-between;
  font-size: 22px !important;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 26.4px;
  padding: 0 !important;
}

.trees {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 22.4px;
}
</style>
