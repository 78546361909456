<template>
  <v-dialog :value="showDialog" max-width="1140" :persistent="true">
    <div class="paymentDialog" v-show="!showSiaFrame">
      <img @click="closeDialog" src="@/assets/ic_chevron_top_black.png" />
      <div class="paymentDialog__title">Pagamento con carta di credito</div>
      <div class="paymentDialog__subtitle">
        Per velocizzare il tuo pagamento, puoi utilizzare la carta di credito o
        debito già utilizzata in precedenza.<br />
        Seleziona "Nuova carta di credito" se desideri cambiare.
      </div>
      <v-row no-gutters class="subscription__radio">
        <v-col v-if="loading" align-self="center" class="text-center pa-10">
          <v-progress-circular :size="50" indeterminate></v-progress-circular>
        </v-col>
        <v-col v-if="!loading">
          <ExtensibleButtonGroup
            v-model="paymentMethod"
            :per-row="2"
            groupName="paymentMethod"
            size="md"
            @extensible-btn-group:touched="
              handleExtButtonTouched && handleExtButtonTouched($event)
            "
          >
            <ExtensibleButton
              :name="meth"
              v-for="(meth, i) in methodsList"
              :key="i"
            >
              <template v-slot:label>
                <div class="d-flex flex-column align-start">
                  <div class="typeMdp">Carta di credito</div>
                  <div>
                    {{ $filters.formatCreditCardTail(meth.creditCardNumber) }}
                  </div>
                </div>
              </template>
            </ExtensibleButton>

            <ExtensibleButton name="newCreditCard" class="d-flex">
              <template v-slot:label>
                <div class="color--blue">Nuova carta di credito</div>
              </template>
              <v-row no-gutters>
                <v-col>
                  <br />
                  <h5
                    @click="rememberCard = !rememberCard"
                    :class="`radio-sameHolder pl-3 d-flex ${
                      rememberCard ? 'selected' : ''
                    }`"
                  >
                    <v-icon class="mr-2" color="#12256A">{{
                      rememberCard
                        ? 'mdi-checkbox-intermediate'
                        : 'mdi-checkbox-blank-outline'
                    }}</v-icon>
                    Ricorda questa carta per i miei prossimi pagamenti
                  </h5>
                </v-col>
              </v-row>
            </ExtensibleButton>
          </ExtensibleButtonGroup>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center">
        <v-btn
          color="primary"
          x-large
          class="v-btn--shadowed"
          :rounded="true"
          :loading="paymentLoading"
          :disabled="!paymentMethodValid"
          @click="confirmPaymentMethod"
        >
          Conferma
        </v-btn>
      </v-row>
    </div>

    <div class="siaDialog" v-show="showSiaFrame">
      <iframe
        id="fiberSiaCC"
        v-if="siaUrl"
        @load="iframeUrlChanged"
        :src="siaUrl"
        frameborder="0"
      ></iframe>
    </div>
  </v-dialog>
</template>

<script>
// import { required, alphaNum } from '@vuelidate/validators'
// import { getRequiredValidationError, validateCF } from '@/js/utils'
import Consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import router from '@/router/router'

import { mapActions, mapMutations } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: ['bill', 'showDialog'],
  name: 'payWithCardDialog',
  data() {
    return {
      paymentMethod: null,
      paymentData: null,
      paymentMethodValid: false,
      paymentLoading: false,

      rememberCard: false,
      showSiaFrame: false,
      siaUrl: '',

      loading: true,
      methodsList: [],
      PAYMENT_METHODS: Consts.PAYMENT_METHODS
    }
  },
  methods: {
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('payment', ['getCardsList', 'getSiaUrlCCOS', 'payCCOSAlias']),
    ...mapMutations('supply', ['resetState']),
    confirmPaymentMethod(ccParams) {
      if (this.paymentMethod === 'newCreditCard') {
        this.getDetailForClient({
          clientOwnerCode: this.bill.codiceCliente
        }).then((resp) => {
          this.getSiaUrlCCOS({
            clientCode: this.bill.codiceCliente,
            email: resp.contactMail,
            billId: this.bill.idFattura,
            prCode: this.bill.prCode,
            rememberCard: this.rememberCard
          })
            .then((url) => {
              this.siaUrl = url
              this.showSiaFrame = true
            })
            .catch((err) => {
              console.error(err)
              GenErr.showErrorMessage(
                'Si è verificato un errore e non è stato possibile inviare la tua richiesta. Ti preghiamo di riprovare.'
              )
            })
        })
      } else {
        this.getDetailForClient({
          clientOwnerCode: this.bill.codiceCliente
        }).then((resp) => {
          this.paymentLoading = true

          this.payCCOSAlias({
            clientCode: this.bill.codiceCliente,
            email: resp.contactMail,
            billId: this.bill.idFattura,
            prCode: this.bill.prCode,
            aliasPan: this.paymentMethod.aliasPan,
            aliasExpirationdate: this.paymentMethod.creditCardExpirationDate
          })
            .then((paymentSucceded) => {
              if (paymentSucceded.status === 'OK') {
                this.siaUrl = window.atob(paymentSucceded.urlRedirect)
                this.showSiaFrame = true
                // router.push({ name: 'supplyPayment', params: { status: 'OK' } })
              } else {
                router.push({ name: 'supplyPayment', params: { status: 'KO' } })
              }
              this.paymentLoading = false
            })
            .catch((err) => {
              console.error(err)
              this.paymentLoading = false
              router.push({ name: 'supplyPayment', params: { status: 'KO' } })
            })
        })
      }
    },
    closeDialog() {
      this.$emit('close-dialog')
    },
    iframeUrlChanged(event) {
      try {
        const loc = event.target.contentWindow.location.href
        if (loc.indexOf('sia/back.html') > -1) {
          this.siaUrl = ''
          this.showSiaFrame = false
          this.paymentMethod = null
        } else if (loc.indexOf('sia/done.html') > -1) {
          this.siaUrl = ''
          this.showSiaFrame = false

          this.$store.commit(
            'supply/resetSupplyDetailByCode',
            this.$route.params.supplyCode
          )
          this.resetState()
          router.push({ name: 'supplyPayment', params: { status: 'OK' } })
        }
      } catch {
        // do nothing. it means we have no access to the iframe data because it is crossdomain
      }
    },

    checkPaymentMethodValid() {
      this.paymentMethodValid =
        this.paymentMethod === 'newCreditCard' || this.paymentMethod?.aliasPan
    }
  },
  mounted() {
    this.handleExtButtonTouched = (params) => {
      this.checkPaymentMethodValid()
    }
  },
  watch: {
    bill() {
      // Get the client code. depending on the page where the component is used bill's fields have translated/untranslated names
      const clientCode = this.bill.codiceCliente || this.bill.clientOwnerCode

      // Get the existing payment methods list
      if (this.bill && clientCode) {
        this.getCardsList({ clientCode: clientCode }).then((methods) => {
          // Filter cards for CCOS
          let list = methods.filter((meth) => {
            let monthExp = meth.creditCardExpirationDate.split('/')[0]
            let yearExp = meth.creditCardExpirationDate.split('/')[1]
            let today = new Date()
            let cardExp = new Date()
            cardExp.setFullYear(yearExp, monthExp, 1)
            return meth.aliasPan && meth.creditCardNumber && today < cardExp
          })

          this.methodsList = list
          this.loading = false
        })
      } else {
        this.methodsList = []
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.paymentDialog {
  background: white;
  padding: 48px;
  position: relative;
  &__title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &__subtitle {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
  .typeMdp {
    font-size: $std-font-size--small;
  }
}

.siaDialog {
  height: 80vh;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
