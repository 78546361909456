<template>
  <div class="infoConsent">
    <h5 class="BodyL mb-6 mt-6">
      Ti invitiamo a prendere visione dei documenti qui riportati per poter
      proseguire
    </h5>
    <h5
      @click="setCheckBox('contractCondition')"
      :class="`checkbox ${contractCondition ? 'selected' : ''} mb-4`"
    >
      <div class="BodyL">
        Accetto le
        <span
          @click.stop="openPdf('contract-conditions')"
          class="BodyL-Underline underline brand-blu"
          >Condizioni di contratto*</span
        >
        e ho preso visione dell'
        <span
          @click.stop="openPdf('infoPrivacy')"
          class="BodyL-Underline underline brand-blu"
          >Informativa generale clienti*</span
        >
        e
        <span
          @click.stop="openPdf('infoSIC')"
          class="BodyL-Underline underline brand-blu"
          >Informativa SIC*</span
        >
      </div>
    </h5>
    <h5
      @click="setCheckBox('clauses')"
      :class="`checkbox ${clauses ? 'selected' : ''} mb-6`"
    >
      <div class="BodyL">
        Ho preso visione e accetto le
        <span
          @click.stop="openPdf('clauses')"
          class="BodyL-Underline underline brand-blu"
          >Clausole di sottoscrizione specifica*</span
        >
      </div>
    </h5>
    <!--    <h5 @click="setCheckBox('infoPrivacy')" :class="`checkbox ${infoPrivacy?'selected':''} underline`">-->
    <!--      <span @click.stop="openPdf('infoPrivacy')">Informativa privacy clienti</span>-->
    <!--    </h5>-->
    <hr class="divider" />
    <h5
      @click="setAllcheckBox"
      :class="`BodyL checkbox d-flex ${accept ? 'selected' : ''}`"
    >
      Ho preso visione di tutti i documenti
    </h5>
    <v-btn
      color="primary"
      x-large
      width="260"
      class="v-btn--shadowed"
      :disabled="!contractCondition || !clauses"
      @click="addInfoConsent"
      >Continua</v-btn
    >
    <PdfViewer
      :showDialog="showPdf"
      :url="pdfDataUrl"
      @closing-pdfViewer="showPdf = false"
      height="600px"
      :attachTo="'body'"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import { ENV_CONFIGS } from '@/js/configs'

export default {
  name: 'infoConsent',
  data() {
    return {
      migrationCode: '',
      accept: false,
      contractCondition: false,
      clauses: false,
      infoPrivacy: false,
      showPdf: false,
      pdfDataUrl: '',
      pdfDocs: {}
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['addressCovered', 'infoConsents']),
    ...mapGetters('offers', ['documentFiber'])
  },
  mounted() {
    let migrationCodeStored =
      this.$store.getters['upsellingFiber/migrationCode'] &&
      this.$store.getters['upsellingFiber/migrationCode'].code
    this.migrationCode = migrationCodeStored || ''
    this.getDocsByCategory('info-consensi').then((docs) => {
      // this.pdfDocs.clauses = docs.find((item) => item.id === 'presa-visione-clausole-vessatorie')
      this.pdfDocs.infoPrivacy = docs.find(
        (item) => item.id === 'informativa-privacy'
      )
    })

    this.getDocumentFiber().then((resp) => {
      this.pdfDocs.conditions = {}
      this.pdfDocs.conditions.document = resp
    })

    this.getDocUpsellingUnfairTerms().then(({ data }) => {
      this.pdfDocs.clauses = data.fiber
    })

    if (this.infoConsents) {
      this.setAllcheckBox()
    }
  },
  methods: {
    ...mapActions('documents', [
      'getDocsByCategory',
      'getDocUpsellingUnfairTerms'
    ]),
    ...mapActions('offers', ['getDocumentFiber']),
    ...mapActions('analytics', ['trackTapEvent']),
    setCheckBox(checkbox) {
      this.$data[checkbox] = !this.$data[checkbox]
      if (this.contractCondition && this.clauses) {
        this.accept = true
        this.$store.commit('upsellingFiber/setInfoConsents', true)
        this.trackTapEvent({
          name: 'upsellingv2_consent_checked',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else {
        this.accept = false
        this.$store.commit('upsellingFiber/setInfoConsents', false)
      }
    },
    setAllcheckBox() {
      this.accept = !this.accept
      if (this.accept) {
        this.contractCondition = true
        this.clauses = true
        this.infoPrivacy = true
        this.$store.commit('upsellingFiber/setInfoConsents', true)
        this.trackTapEvent({
          name: 'upsellingv2_consent_checked',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else {
        this.contractCondition = false
        this.clauses = false
        this.infoPrivacy = false
        this.$store.commit('upsellingFiber/setInfoConsents', false)
      }
    },
    openPdf(doc) {
      switch (doc) {
        case 'contract-conditions':
          this.pdfDataUrl = this.documentFiber
          this.showPdf = true
          this.trackTapEvent({
            name: 'upsellingv2_getpdfcontractconditions',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
          break
        case 'clauses':
          window.open(this.pdfDocs.clauses)
          this.trackTapEvent({
            name: 'upsellingv2_getpdfclauses',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
          break
        case 'infoPrivacy':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.generalClientsInformative)
          // pdfUrl = axiosCMS.defaults.baseURL + this.pdfDocs.infoPrivacy.document
          // getBase64PublicDoc(pdfUrl).then((base64Data) => {
          //   this.pdfDataUrl = base64Data
          //   this.showPdf = true
          // })
          this.trackTapEvent({
            name: 'upsellingv2_getpdfinfoPrivacy',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
          break
        case 'infoSIC':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.informativaSIC)
          // this.trackTapEvent({
          //   name: 'upsellingv2_getpdfinfoSIC',
          //   params: { client_selected: this.$store.getters['upsellingFiber/addressCovered'].clientOwnerCode || '' }
          // })
          break
      }
    },
    addInfoConsent() {
      this.$emit('goToStep', 13, 'upsellingv2_ConfirmData_view')
    }
  },
  components: {
    PdfViewer
  }
}
</script>
<style lang="scss" scoped>
.infoConsent {
  max-width: 652px;
  color: $base-black;

  &__info {
    font-weight: 500;
  }

  padding-top: 20px;

  .divider {
    margin: 15px 0;
    color: $neutri-neutral-20;
  }

  .checkbox {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    cursor: pointer;
    //font-weight: normal;

    .underline {
      text-decoration: underline;
    }

    .brand-blu {
      color: $brand-blue;
    }

    &.selected {
      &:after {
        content: url('../../../assets/icons/checkbox_active.svg');
      }
    }

    &:after {
      content: url('../../../assets/icons/checkbox.svg');
    }

    &.allConsent {
      border-top: 1px solid #ccd0e1;
      height: 100px;
      font-weight: 500;
      margin-bottom: 32px;
    }

    a {
      color: black;
      text-decoration: underline;
    }
  }
}
</style>
