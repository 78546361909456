var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.view === 'list' && this.canSaveAddress)?[_c('div',{staticClass:"d-flex justify-space-between align-center mb-6"},[_c('h3',[_vm._v("La mia casa")]),_c('img',{staticClass:"close",attrs:{"src":require("../../../assets/icons/ic-close.svg")},on:{"click":_vm.close}})]),_c('h5',{staticClass:"mb-6"},[_vm._v(" Scegli l'indirizzo della tua casa o inseriscine uno nuovo ")]),_vm._l((_vm.addressSaved),function(address,i){return _c('div',{key:i,class:`dialogSurvey__box address mb-4 ${
        _vm.selected === address.egonIndirizzo ? 'selected' : ''
      }`,on:{"click":function($event){return _vm.setAddress(address)}}},[_c('h5',[_vm._v(_vm._s(address.indirizzo))])])}),_c('div',{staticClass:"d-flex align-center justify-center mt-8"},[_c('h5',{staticClass:"addAddress",on:{"click":_vm.changeView}},[_vm._v("Aggiungi nuovo indirizzo")])])]:[_c('div',{staticClass:"d-flex justify-space-between align-center mb-12"},[_c('h3',{staticClass:"back",on:{"click":function($event){_vm.$emit('update:view', 'list')
          _vm.$emit('setStartIndex')}}},[_vm._v(" Aggiungi nuovo indirizzo ")]),_c('img',{staticClass:"close",attrs:{"src":require("../../../assets/icons/ic-close.svg")},on:{"click":_vm.close}})]),_c('vuetify-google-autocomplete',{ref:"address",attrs:{"id":"map","classname":"auth__input","placeholder":"Inserisci il tuo indirizzo","country":"it","label":"nuovo indirizzo","value":_vm.addressObject,"error-messages":_vm.addressErrors},on:{"placechanged":_vm.getAddressData,"blur":function($event){return _vm.v$.newAddress.$touch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/profile/search.svg")}})]},proxy:true}]),model:{value:(_vm.newAddress),callback:function ($$v) {_vm.newAddress=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"newAddress"}}),(this.canSaveAddress)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"primary v-btn--shadowed",attrs:{"width":"152","height":"54","disabled":!_vm.newAddress || _vm.addressErrors.length > 0},on:{"click":function($event){_vm.getEgonAndSave()
          _vm.$emit('update:dialog', false)
          _vm.$emit('setStartIndex')}}},[_vm._v("Aggiungi")])],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"primary v-btn--shadowed",attrs:{"height":"54","disabled":!_vm.newAddress || _vm.addressErrors.length > 0},on:{"click":function($event){_vm.getEgonAndSave()
          _vm.$emit('update:dialog', false)
          _vm.$emit('setStartIndex')}}},[_vm._v("Aggiungi e prosegui")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }