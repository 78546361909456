<template>
  <div class="common__content">
    <v-row class="mb-10">
      <v-col class="pl-0" cols="6">
        <slot name="text" />
        <v-row class="mb-10" v-if="instruction">
          <h4 class="font-weight-bold">{{ instruction }}</h4>
        </v-row>
        <v-row :class="{ notAllowed: isFormDisabled }">
          <v-col class="pa-0 align-self-center" cols="2">
            <v-img
              v-if="!isFormDisabled"
              class="ma-0"
              height="72"
              src="@/assets/greeners/svg/greeners.svg"
              width="72"
            />
            <v-img
              v-else
              class="ma-0"
              height="72"
              src="@/assets/greeners/svg/noCoins.svg"
              width="72"
            />
          </v-col>
          <v-col class="coins pl-0" cols="6">
            <h1 class="HeadingsDisplayXL">{{ coins.toLocaleString() }}</h1>
            <h3 class="ButtonXL">Green Coins</h3>
          </v-col>
          <v-col cols="4" class="achievement pr-3" v-if="achievement">
            <Achievement class="ml-auto" />
          </v-col>
        </v-row>
        <v-row class="mt-10" v-if="!isButtonSeparated">
          <v-col :align="alignButton" class="pl-0" justify="center">
            <v-btn
              @click="$emit('buttonClick')"
              class="v-btn--shadowed mb-4"
              color="brightGreen"
              width="265"
              x-large
              :disabled="isFormDisabled"
            >
              {{ btnLabel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <VideoPlayer v-if="video" />
        <v-img
          v-else
          :height="imageSize.height"
          :src="imageSrc"
          :width="imageSize.width"
          class="img--rounded"
        />
      </v-col>
    </v-row>
    <slot />
    <v-row v-if="isFormDisabled">
      <v-col class="d-flex justify-center">
        <p class="BodyS mb-0">
          Continua ad accumulare Green Coins per richiedere il premio
        </p>
      </v-col>
    </v-row>
    <v-row v-if="isButtonSeparated">
      <v-col :align="alignButton" justify="center">
        <v-btn
          @click="$emit('buttonClick')"
          class="v-btn--shadowed mb-4"
          color="primary"
          width="358"
          x-large
          :disabled="isFormDisabled"
        >
          {{ btnLabel }}
        </v-btn>
      </v-col>
    </v-row>
    <v-footer color="white" height="50" />
  </div>
</template>

<script>
import VideoPlayer from '@/components/videoPlayer'

export default {
  name: 'CommonContent',
  components: {
    VideoPlayer,
    Achievement: () =>
      import(
        /* webpackChunkName: "components/loyalty/achivment" */ '@/views/loyalty/components/achievement'
      )
  },
  props: {
    instruction: { type: String, default: '' },
    picSize: { type: String, default: 'small' },
    coins: { type: Number || String, default: '0' },
    btnLabel: { type: String, default: 'btnText' },
    imageSrc: { type: String, default: '' },
    isButtonSeparated: { type: Boolean, default: true },
    isFormDisabled: { type: Boolean },
    video: { type: Boolean },
    achievement: { type: Boolean, default: false }
  },
  computed: {
    imageSize() {
      if (this.picSize === 'small') {
        return { width: 553, height: 386 }
      } else {
        return { width: 555, height: 552 }
      }
    },
    alignButton() {
      if (this.isButtonSeparated) {
        return 'center'
      }
      return 'start'
    }
  }
}
</script>

<style lang="scss" scoped>
/* .coins {
    h1 {
      line-height: 35px;
      font-weight: bold;
    }
  }*/
.notAllowed {
  h1,
  h3 {
    color: $neutri-neutral-40;
  }
}

h4 {
  font-size: 20px;
  color: black;
  line-height: 1.4;
  letter-spacing: 1px;
}

.common {
  &__header {
    min-height: 260px;
  }

  &__coins {
    margin-bottom: 105px;
  }

  &__content {
    padding-top: 64px;
    width: 100%;
    background: white;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
  }
}

.img {
  &--rounded {
    border-radius: 37px;
  }
}

.achievement {
  padding-right: 0;
}
</style>
