<template>
  <page
    title="Gestione tensione"
    page-name="Gestione tensione"
    @submit="request"
    id-contextual-help="help-manage-tension"
    :disabled="disabled"
  >
    <TcVars />
    <voltage-change :data.sync="data" />
  </page>
</template>

<script>
import VoltageChange from '@/components/actions/VoltageChange'
import TcVars from '@/components/tcTracker/TcVars'
import { mapActions, mapGetters } from 'vuex'
import Template from './Template'

export default {
  name: 'VoltageAction',
  props: {},
  components: {
    page: Template,
    VoltageChange,
    TcVars
  },
  data: () => ({
    details: null,
    data: null
  }),
  computed: {
    ...mapGetters('account', ['clientDetails']),
    disabled() {
      return !this.data
    }
  },
  methods: {
    request() {
      const body = {
        cognome: this.clientDetails.surname,
        email: this.clientDetails.contactMail,
        nome: this.clientDetails.name,
        codiceCliente: this.clientDetails.clientCode,
        login: this.$store.state.session.username,
        puntoDiFornitura: this.$route.params.supplyCode,
        fieldsCommodity: {
          ...this.data,
          email: this.clientDetails.contactMail
        }
      }
      const type = this.data.tensione ? 'voltage2' : 'voltage1'
      this.createRequest(body).then((res) => {
        this.trackTapEvent('supply_changeManageTension_view')
        if (res.status === 'OK')
          this.$router.push({ name: 'promSuccess', params: { type } })
      })
    },
    ...mapActions('support', ['createRequest']),
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>

<style lang="scss" scoped></style>
