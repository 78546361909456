<template>
  <layout header-size="small" class="greenersFriendCode">
    <TcVars />
    <div class="greenersFriendCode__loader" v-if="loading">
      <v-progress-circular :size="100" indeterminate color="#12256A" />
    </div>
    <template v-slot:header>
      <v-col cols="6">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
        <h1>Inserisci il Codice!</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div class="greenersFriendCode__content">
      <div class="greenersFriendCode__box-image">
        <v-img
          contain
          class="b-radius"
          width="360"
          src="../../../assets/greeners/community.png"
        />
        <div class="container-info">
          <p class="HeadingL-Upper pl-5 text mb-0">
            Il tuo codice AMICO GREENERS
          </p>
          <p class="HeadingL-Upper pl-5 code">
            {{ codeFriendGreeners || '....loading' }}
          </p>
        </div>
      </div>
      <div class="greenersFriendCode__box-content">
        <p class="HeadingsTitleXL mb-2">Fai crescere la community!</p>
        <p class="BodyL mb-8">
          Invita i tuoi amici ad unirsi alla community condividendo il tuo
          codice, entrambi riceverete un premio! Ogni volta che un amico
          inserirà il tuo codice nella sezione Bonus Per Te di Greeners, gli
          verranno accreditati 1.000 Green Coins e tu ne riceverai 250.
        </p>
        <div class="greenersFriendCode__box-coin mb-12">
          <p class="BodyL-Strong">Per ogni amico ottieni</p>
          <div class="greenersFriendCode__coin">
            <v-img
              contain
              width="48"
              height="48"
              src="@/assets/greeners/svg/coins.svg"
            />
            <div class="greenersFriendCode__coin-text">
              <p class="HeadingL-Upper mb-1">250</p>
              <p class="BodyL">Green Coins</p>
            </div>
          </div>
        </div>
        <div class="greenersFriendCode__send-friend">
          <v-row>
            <v-col cols="6" class="pl-0 custom-input-field">
              <v-text-field
                tabindex="1"
                :disabled="!codeFriendGreeners"
                class="auth__input"
                width="343px"
                clearable
                label="INVIALO AI TUOI AMICI VIA MAIL"
                placeholder="Inserisci email"
                persistent-placeholder
                color="#6e7689"
                @blur="v$.email.$touch()"
                :error-messages="emailErrors"
                v-model.trim="email"
              />
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                color="primary"
                x-large
                width="258"
                :disabled="v$.$invalid"
                @click="shareMessage('mail')"
              >
                Invita amici
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row class="greenersFriendCode__social">
          <v-col cols="12" class="pl-0">
            <p class="BodyL-Strong">Condividilo su Whatsapp o Facebook</p>
            <div class="container-social">
              <v-btn
                x-large
                :disabled="!codeFriendGreeners"
                class="white v-btn--shadowed mr-4"
                color="#12256A"
                :rounded="true"
                target="_blank"
                @click="shareMessage('whatsapp')"
              >
                <v-img
                  contain
                  width="24"
                  src="@/assets/icons/social/whatsapp.svg"
                />
                <p class="ButtonL mb-0">Whatsapp</p>
              </v-btn>
              <div>
                <v-btn
                  :disabled="!codeFriendGreeners"
                  height="58px"
                  width="186px"
                  class="white v-btn--shadowed mr-4 text-center"
                  color="#12256A"
                  :rounded="true"
                  @click="shareMessage('facebook')"
                >
                  <img
                    src="@/assets/icons/social/facebook-new.svg"
                    width="24px"
                  />
                  <p class="ButtonL mb-0 ml-2">Facebook</p>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12 pl-0">
            <p class="BodyL-Strong">
              Oppure scarica l'app MySorgenia per altre modalità di
              condivisione!
            </p>
            <div class="container-social">
              <v-btn
                x-large
                :disabled="!codeFriendGreeners"
                class="white v-btn--shadowed mr-8"
                color="#12256A"
                target="_blank"
                @click="shareMessage('android')"
                :rounded="true"
              >
                <v-img
                  contain
                  width="130"
                  src="@/assets/greeners/googlePlay.png"
                />
              </v-btn>
              <v-btn
                x-large
                :disabled="!codeFriendGreeners"
                class="white v-btn--shadowed"
                color="#12256A"
                :rounded="true"
                target="_blank"
                @click="shareMessage('ios')"
              >
                <v-img
                  contain
                  width="130"
                  src="@/assets/greeners/appleStore.png"
                />
              </v-btn>
            </div>
          </v-col>
          <v-row>
            <v-col> </v-col>
          </v-row>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialogFriendCode" max-width="560">
      <div class="greeners-code-friend-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="dialogFriendCode = false"
        />
        <div class="greeners-code-friend-modal__image-container">
          <img
            src="../../../assets/greeners/svg/code-redemption-error-used.svg"
            width="140"
          />
        </div>
        <div class="greeners-code-friend-modal__body mb-6">
          <p class="HeadingsSubtitleXL">Servizio non disponibile</p>
          <p class="BodyXL">
            Al momento non è possibile condividere il tuo codice amico Greeners.
            Ti invitiamo a riprovare.
          </p>
        </div>
        <div class="greeners-code-redemption-modal__footer">
          <v-btn
            color="white"
            class="v-btn--shadowed"
            height="54"
            width="231"
            @click="goBackHome()"
          >
            Torna al diario
          </v-btn>
          <v-btn
            color="primary"
            class="v-btn--shadowed"
            height="54"
            width="231"
            @click="retryService()"
          >
            Riprova
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </layout>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import { email, required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { AlertService } from '../../../js/service/alertService'
import Layout from '../Layout'
import sharingNetworks from './networks.json'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'GreenersFriendCode',
  components: {
    layout: Layout,
    TcVars
  },
  data() {
    return {
      overriddenNetworks: sharingNetworks,
      email: '',
      loading: false,
      errorMessage: '',
      dialogFriendCode: false,
      url: 'https://www.sorgenia.it/',
      fb: 'https://www.facebook.com/sharer/sharer.php?u',
      linkStoreAndroid:
        'https://play.google.com/store/apps/details?id=my.sorgenia',
      linkStoreIos: 'https://apps.apple.com/it/app/mysorgenia/id593806212',
      linkLandingExternalPage:
        'https://www.sorgenia.it/partnership-unisciti-alla-community-greeners',
      subjectMail: 'Entra anche tu nella community Greeners',
      textWhatsapp: 'https://wa.me/?text',
      textMessageEmail: ''
    }
  },
  async beforeMount() {
    try {
      await this.getMainClient()
      await this.showFriendCode()
    } catch (err) {
      if (err.status === 404) {
        this.generateCode()
      } else {
        this.alertMethod()
      }
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails']),
    ...mapGetters('loyalty', ['codeFriendGreeners']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        { to: { name: 'loyaltyPage' }, text: 'Diario Greeners', exact: true },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Codice amico Greeners'
        }
      ]

      return res
    },
    emailErrors() {
      let errArray = []

      if (!this.v$.email.$dirty) return errArray

      this.v$.email.email.$invalid === true &&
        errArray.push('Formato email non valido')
      this.errorMessage !== '' && errArray.push('')
      return errArray
    }
  },
  methods: {
    ...mapActions('loyalty', ['showFriendCode', 'generateFriendCode']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('loyalty', ['setCodeFriendGreeners']),
    ...mapActions('account', ['getMainClient']),
    shareMessage(method) {
      this.trackTapEvent('greeners_shareGGG_tap')
      this.textMessageEmail = `Ciao, entra anche tu nella community Greeners e facciamo insieme la differenza per il Pianeta! %0A %0AAccetta il mio invito, bastano pochi passi: %0D - Scarica l'app MySorgenia %0D - registrati gratuitamente e diventa subito un Greener, non è necessario essere un cliente Sorgenia! %0D - inserisci questo codice ${this.codeFriendGreeners} nella sezione Greeners "Bonus per te" e ricevi 1.000 Green Coins di benvenuto %0D Scopri come funziona Greeners, ti aspetto nella community! %0D`
      switch (method) {
        case 'ios': {
          window.open(`${this.linkStoreIos}`)
          break
        }
        case 'android': {
          window.open(`${this.linkStoreAndroid}`)
          break
        }
        case 'whatsapp': {
          window.open(
            `${this.textWhatsapp}=${this.textMessageEmail}${this.linkLandingExternalPage}`
          )
          break
        }
        case 'mail': {
          window.location.href = `mailto:${this.email}?subject=${this.subjectMail}&body=${this.textMessageEmail} ${this.linkLandingExternalPage}`
          break
        }
        case 'facebook': {
          window.open(
            `${this.fb}=${this.linkLandingExternalPage}&title=${this.textMessageEmail}&hashtag=%23MySorgenia#_=_`
          )
          break
        }
        default: {
          break
        }
      }
    },
    goBackHome() {
      this.$router.push({ name: 'loyaltyPage' })
    },
    alertMethod() {
      this.trackTapEvent('greeners_error_view')
      AlertService(
        'Riprova più tardi',
        'Siamo spiacenti ma per un errore temponaneo non è possibile condividere il tuo codice amico Greeners. Ti invitiamo a riprovare.',
        'alert',
        'Torna al diario'
      )
    },
    async generateCode() {
      try {
        await this.generateFriendCode()
      } catch (err) {
        this.alertMethod()
      }
    }
  },
  validations: {
    email: { required, email }
  }
}
</script>

<style lang="scss" scoped>
.facebookButton {
  margin-left: 182px;
}

.b-radius {
  border-radius: 16px;
}
.greenersFriendCode {
  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-sharing {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 186px;
    height: 54px;
    color: #12256a;
    position: relative;
  }
  .ButtonM {
    color: #12256a;
  }

  &__content {
    display: flex;
    margin-top: 64px;
  }

  &__box-image {
    max-width: 360px;
    width: 100%;
    height: 226px;
    position: relative;

    .container-info {
      position: absolute;
      top: 136px;
    }

    .code {
      color: #97d700;
    }

    .text {
      text-transform: uppercase;
      color: white;
    }
  }

  &__box-content {
    padding-left: 47px;
  }

  &__coin {
    position: relative;
    padding-left: 71px;

    .v-image {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__send-friend {
    display: flex;
  }

  &__social {
    .container-social {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .v-image {
      margin-right: 10px;
    }
  }
}
</style>
