var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"successUpload"},[_c('div',{class:`successUpload__header ${_vm.commodityType}`},[_c('div',{staticClass:"successUpload__title"},[_vm._v(" "+_vm._s(`Next Energy ${ _vm.commodityType === 'ele' ? 'Luce' : 'Gas' }: digitale e vantaggioso`)+" ")])]),_c('div',{staticClass:"successUpload__content std-side-padding topRadius pt-10"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Richiesta inviata!")]),_c('h3',{staticClass:"mb-4"},[_vm._v("Sarà nostra cura contattarti al più presto")]),_c('div',{staticClass:"desc mb-10"},[_vm._v(" Un nostro operatore ti contatterà il prima possibile per continuare l’attivazione "+_vm._s(_vm.commodityType === 'ele' ? 'della Luce' : 'del Gas')+" con il suo supporto telefonico. ")]),_c('v-btn',{staticClass:"v-btn--shadowed mb-10",attrs:{"color":"primary","x-large":"","width":"262"},on:{"click":function($event){_vm.trackTapEvent({
          name: 'upsellingcom_thankyoupagecallmeback_back',
          params: {
            type: _vm.commodityType,
            client_selected: _vm.client.clientOwnerCode || ''
          }
        }),
          _vm.$router.push({ name: 'home' })}}},[_vm._v("Torna all'homepage")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }