<template>
  <div
    class="answer_box"
    :style="`width: calc( 100% / ${perRow} - ${margin * 2}px)`"
  >
    <div class="star-container">
      <img
        v-for="i in starts"
        :key="i"
        @click="onStarClicked(i + 1)"
        :src="getStarSrc(rating > i)"
        class="star"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackRating',
  data() {
    return {
      rating: 0
    }
  },
  props: {
    answer: { type: Object },
    onAnswerChanged: { type: Function },
    onSliderChanged: { type: Function }
  },
  inject: ['options'],
  computed: {
    perRow() {
      return this.options.perRow
    },
    margin() {
      return this.options.margin
    },
    starts() {
      return this.answer.scale_values
        .trim()
        .split(',')
        .map((s, index) => index)
    }
  },
  methods: {
    onStarClicked(i) {
      let scaleValues = this.answer.scale_values.trim().split(',')
      this.rating = i
      this.onSliderChanged(this.answer.id, scaleValues[i - 1], 'rating_value')
      this.onAnswerChanged()
    },
    getStarSrc(on) {
      return on
        ? require('../../assets/rate/star_on.png')
        : require('../../assets/rate/star_off.png')
    }
  },
  created() {
    let scaleValues = this.answer.scale_values.trim().split(',')
    if (this.answer.rating_value !== null) {
      this.rating = scaleValues.indexOf(this.answer.rating_value)
    }
  }
}
</script>

<style lang="scss" scoped>
.answer_box {
  margin-bottom: 40px;
}
.star-container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 24px;
  display: flex;
}
.star {
  width: 55px;
  height: 55px;
  margin-right: 30px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .star-container {
    margin-left: initial;
    margin-right: initial;
    justify-content: space-around;
  }
  .star {
    width: 45px;
    height: 45px;
    margin-right: initial;
  }
}
</style>
