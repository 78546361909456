<template>
  <!--
    USAGE Example:
      <v-btn id="informativaLogin" @click="showPdf = true">OPEN</v-btn>
      <pdf-viewer maxWidth="60%" height="600px" attachTo="informativaLogin" :showDialog="showPdf" @closing-pdfViewer="showPdf = false" url="http://www.africau.edu/images/default/sample.pdf"></pdf-viewer>
  -->

  <v-dialog
    v-model="dialogVisible"
    :max-width="maxWidth || '50%'"
    :internal-activator="true"
    :attach="attachTo"
    class="pdf-viewer"
    :style="styles || ''"
  >
    <object v-if="showDialog" :data="url" :height="height || '500px'">
      Visualizza il documento sul sito: <a :href="url">{{ url }}</a>
    </object>
  </v-dialog>
</template>
<script>
export default {
  name: 'pdfViewer',
  props: ['showDialog', 'url', 'attachTo', 'maxWidth', 'height', 'styles'],
  computed: {
    dialogVisible: {
      get() {
        return this.showDialog
      },
      set() {
        this.$emit('closing-pdfViewer')
      }
    }
  }
}
</script>
