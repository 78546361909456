<template>
  <div>
    <div
      class="ButtonL select"
      v-if="
        Object.keys(filteredSupplies).length > 1 ||
        (Object.keys(filteredSupplies).length === 1 &&
          filteredSupplies[Object.keys(filteredSupplies)[0]].services.length >
            1)
      "
      @click="openSelectModal"
    >
      Seleziona
      <span v-if="serviceName === 'Luce e Gas'" class="ButtonL select">
        utenza
      </span>
      <span v-if="serviceName === 'Fotovoltaico'" class="ButtonL select">
        impianto
      </span>
    </div>
    <div class="d-flex" v-else-if="Object.keys(filteredSupplies).length === 1">
      <div class="BodyL-Strong mr-2">{{ name }}</div>
      <div class="BodyL">{{ address }}</div>
    </div>
    <div v-else>
      <div class="BodyL-Strong mr-2">Siamo spiacenti</div>
      <div class="BodyL">
        non sono presenti utenze per il servizio
        {{ tipologiaServizio }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'selectSystem',
  props: {
    filteredSupplies: { type: Array, default: () => [] },
    serviceName: { type: String },
    tipologiaServizio: { type: String },
    name: { type: String },
    address: { type: String }
  },
  methods: {
    openSelectModal() {
      this.$emit('setOpenSelectModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  cursor: pointer;
  color: $srg-blue;
}
</style>
