var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{staticClass:"catalogue",attrs:{"header-size":"small"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":"6"}},[_c('v-breadcrumbs',{staticClass:"pb-0",attrs:{"items":_vm.breadcrumbsItems,"divider":">"},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/chevron_rx_light.svg"),"width":"24"}})]},proxy:true}])}),_c('div',{staticClass:"HeadingsTitleXXL"},[_vm._v("Catalogo")]),_c('div',{staticClass:"blackCoins"},[_c('div',{staticClass:"blackCoins__img"},[(_vm.memberStatus.statusGreeners !== 'Pending')?_c('img',{staticClass:"catalogue__coins-img",attrs:{"src":_vm.icons.coinsBlack,"alt":"icon coins"}}):_vm._e()]),_c('div',{staticClass:"blackCoins__count"},[(_vm.memberStatus.statusGreeners !== 'Pending')?_c('span',{staticClass:"HeadingsDisplayXL",domProps:{"textContent":_vm._s((_vm.memberBalance.user_balance || 0).toLocaleString('it-IT'))}}):_vm._e()])])],1),_c('v-spacer',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"5"}},[_c('v-img',{attrs:{"contain":"","width":"393","src":require("@/assets/greeners/svg/logoGreeners_new.svg")}})],1)]},proxy:true}])},[(_vm.loader)?_c('div',{staticStyle:{"width":"100%"}},[_c('v-progress-circular',{staticClass:"loader fixed-loader",attrs:{"color":"#12256A","size":50,"indeterminate":""}})],1):_c('div',{staticClass:"catalogue__content"},[_c('div',{staticClass:"catalogue__coins"},[_c('h2',{staticClass:"HeadingL"},[_vm._v("Scegli il tuo premio ed usa i Green Coins.")])]),_c('v-row',{staticClass:"catalogue__items"},_vm._l((_vm.catalogList),function(card,i){return _c('v-col',{key:i,staticClass:"mb-4",class:[
          'mb-4',
          {
            'card-disabled':
              _vm.memberBalance.user_balance < card.attributes.price ||
              _vm.memberStatus.statusGreeners === 'Pending'
          }
        ],attrs:{"cols":"4"}},[_c('card',{attrs:{"id":card.attributes.prizeoption_id,"imgSrc":card.attributes.images.data.find(({ label }) => label === 'cover')
              .image_small || card.attributes.images.data[0].image_small,"label":card.attributes.title,"price":card.attributes.price},on:{"openModalPending":function($event){_vm.dialogPendingUser = true}}})],1)}),1),_c('v-footer',{attrs:{"color":"white","height":"50"}})],1),_c('BalanceUserPending',{attrs:{"dialog":_vm.dialogPendingUser,"type-modal":"catalog"},on:{"closeModal":function($event){_vm.dialogPendingUser = false}},model:{value:(_vm.dialogPendingUser),callback:function ($$v) {_vm.dialogPendingUser=$$v},expression:"dialogPendingUser"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }