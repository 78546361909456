<template>
  <div v-if="status === 'success'" class="supply-detail topRadius mt-12">
    <TcVars />
    <DialogFilterDate
      :showDialogDate.sync="dialogDate"
      :pickerStartProp.sync="pickerStart"
      :pickerEndProp.sync="pickerEnd"
      @refreshTotal="refreshTotal"
      @openPicker="openPicker"
    />
    <DialogFilterState
      :showDialogState.sync="dialogState"
      :filterSelStateProp.sync="filterSelState[tabBollettePdrArchive]"
      :tab="tabBollettePdrArchive"
      :bills="bills"
      :pickerStart="pickerStart"
      :pickerEnd="pickerEnd"
      @refreshTotal="refreshTotal"
    />
    <DialogPagaPdr
      v-if="dialogPagaPdr"
      v-model="dialogPagaPdr"
      :clientDetails="filteredClientDetails"
      :pdrDetail="filterPdrToPay"
    />

    <v-row v-if="!loaded" class="std-side-padding pa-12" align="center">
      <v-col class="text-center">
        <v-progress-circular :size="50" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <template v-if="loaded">
      <v-row class="std-side-padding">
        <v-col class="user">
          <UserDetail></UserDetail>
        </v-col>
      </v-row>
      <template v-if="getInfoPiani.item && getInfoPiani.item.length === 0">
        <v-row class="std-side-padding">
          <div
            class="btn-filter mr-4"
            @click="(event) => openFilterDate(event)"
          >
            Periodo
          </div>
          <div
            class="btn-filter payment ml-4"
            @click="(event) => openFilterState(event)"
          >
            Stato Pagamenti
          </div>
        </v-row>
        <v-row class="std-side-padding">
          <div class="totalPay__filtered">
            <h5
              v-if="
                filterSelDate.length === 0 && showStateSelected.length === 0
              "
            >
              Stai visualizzando i dati degli ultimi 2 anni
            </h5>
            <h5 v-else class="mb-4 w-100">filtri attivi</h5>
            <span v-if="filterSelDate.length" @click="resetPicker">{{
              filterSelDate
            }}</span>
            <span
              v-for="(item, i) in showStateSelected"
              :key="i"
              @click="resetState(item.id)"
              >{{ item.text }}</span
            >
          </div>
        </v-row>
        <template v-if="loaded">
          <div class="std-side-padding">
            <v-row class="totalPay__result">
              <v-col
                :cols="itemTotalPaymentStatus.length / 2 > 2 ? '4' : '6'"
                class="pl-7 pr-7"
                :class="{ 'offset-5': item.hasToPay }"
                v-for="(item, i) in itemTotalPaymentStatus"
                :key="i"
              >
                <div
                  class="totalPay__item"
                  :class="{
                    highlighted: item.highlight,
                    pay: item.showPayButton,
                    skipped: item.skipSpace
                  }"
                >
                  <div
                    class="field"
                    v-if="!item.showPayButton && !item.skipSpace"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="total"
                    v-if="!item.showPayButton && !item.skipSpace"
                  >
                    € {{ item.total }}
                  </div>
                  <div v-if="item.showPayButton">
                    <v-btn
                      @click="recapBillsToPay"
                      class="paymentButton"
                      v-if="item.hasToPay"
                      x-large
                      width="174"
                      >Paga Ora</v-btn
                    >
                  </div>
                </div>
              </v-col>
              <div class="totalPay__info col-12">
                <span
                  >Le bollette In Pagamento e In Verifica potrebbero non
                  rientrare all’interno della Sintesi visualizzata.</span
                >
              </div>
            </v-row>
            <v-row>
              <v-col class="bills__list py-0">
                <BillsList
                  :bills="filterBills"
                  :supply="detail"
                  :maxToShow="filterBills.length"
                ></BillsList>
              </v-col>
            </v-row>
          </div>
        </template>

        <template v-else>
          <v-row class="std-side-padding pa-12 loader" align="center">
            <v-col class="text-center">
              <v-progress-circular :size="100" indeterminate color="#12256A" />
            </v-col>
          </v-row>
        </template>
      </template>

      <template v-else>
        <v-row class="std-side-padding">
          <v-col class="totalPay">
            <v-row class="totalPay__title">
              Riepilogo e archivio pagamenti
            </v-row>
            <v-row class="totalPay__subtitle">
              Qui trovi la sintesi dei pagamenti e lo storico delle bollette
              fino a 2 anni per la fornitura selezionata.
            </v-row>

            <v-tabs
              v-model="tabBollettePdrArchive"
              id="tabBollettePdr"
              icons-and-text
              grow
            >
              <v-tabs-slider></v-tabs-slider>
              <!-- TO DO ANALYTICS -->

              <v-tab id="bill" href="#bill">
                <div>
                  <span
                    class="BodyXL-Strong text-capitalize d-flex align-center"
                  >
                    Bollette
                    <span v-if="alertBill" class="notification"></span>
                  </span>
                </div>
              </v-tab>
              <!-- TO DO ANALYTICS -->
              <v-tab id="pdr" href="#pdr">
                <span class="BodyXL-Strong text-none d-flex align-center">
                  Piani di rientro
                  <span v-if="alertPdr" class="notification"></span>
                </span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabBollettePdrArchive" class="pt-6">
              <v-tab-item :eager="true" value="bill">
                <v-row>
                  <div
                    class="btn-filter mr-4"
                    @click="(event) => openFilterDate(event)"
                  >
                    Periodo
                  </div>
                  <div
                    class="btn-filter payment ml-4"
                    @click="(event) => openFilterState(event)"
                  >
                    Stato Pagamenti
                  </div>
                </v-row>
                <v-row>
                  <div class="totalPay__filtered">
                    <h5
                      v-if="
                        filterSelDate.length === 0 &&
                        showStateSelected.length === 0
                      "
                    >
                      Stai visualizzando i dati degli ultimi 2 anni
                    </h5>
                    <h5 v-else class="mb-4 w-100">filtri attivi</h5>
                    <span v-if="filterSelDate.length" @click="resetPicker">{{
                      filterSelDate
                    }}</span>
                    <span
                      v-for="(item, i) in showStateSelected"
                      :key="i"
                      @click="resetState(item.id)"
                      >{{ item.text }}</span
                    >
                  </div>
                </v-row>
                <template v-if="loaded">
                  <v-row class="totalPay__result">
                    <v-col
                      :cols="itemTotalPaymentStatus.length / 2 > 2 ? '4' : '6'"
                      class="pl-7 pr-7"
                      :class="{ 'offset-5': item.hasToPay }"
                      v-for="(item, i) in itemTotalPaymentStatus"
                      :key="i"
                    >
                      <div
                        class="totalPay__item"
                        :class="{
                          highlighted: item.highlight,
                          pay: item.showPayButton,
                          skipped: item.skipSpace
                        }"
                      >
                        <div
                          class="field"
                          v-if="!item.showPayButton && !item.skipSpace"
                        >
                          {{ item.title }}
                        </div>
                        <div
                          class="total"
                          v-if="!item.showPayButton && !item.skipSpace"
                        >
                          € {{ item.total }}
                        </div>
                        <div v-if="item.showPayButton">
                          <v-btn
                            @click="recapBillsToPay"
                            class="paymentButton"
                            v-if="item.hasToPay"
                            x-large
                            width="174"
                            >Paga Ora</v-btn
                          >
                        </div>
                      </div>
                    </v-col>
                    <div class="totalPay__info col-12">
                      <span
                        >Le bollette In Pagamento e In Verifica potrebbero non
                        rientrare all’interno della Sintesi visualizzata.</span
                      >
                    </div>
                  </v-row>
                </template>

                <template v-else>
                  <v-row class="std-side-padding pa-12 loader" align="center">
                    <v-col class="text-center">
                      <v-progress-circular
                        :size="100"
                        indeterminate
                        color="#12256A"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-tab-item>
              <v-tab-item :eager="true" value="pdr">
                <v-row>
                  <div
                    class="btn-filter mr-4"
                    @click="(event) => openFilterDate(event)"
                  >
                    Periodo
                  </div>
                  <div
                    class="btn-filter payment ml-4"
                    @click="(event) => openFilterState(event)"
                  >
                    Stato Pagamenti
                  </div>
                </v-row>
                <v-row>
                  <div class="totalPay__filtered">
                    <h5
                      v-if="
                        filterSelDatePdr.length === 0 &&
                        showStateSelectedPdr.length === 0
                      "
                    >
                      Stai visualizzando i dati degli ultimi 2 anni
                      {{ filterSelDatePdr }}
                    </h5>
                    <h5 v-else class="mb-4 w-100">filtri attivi</h5>
                    <span v-if="filterSelDatePdr.length" @click="resetPicker">{{
                      filterSelDatePdr
                    }}</span>
                    <span
                      v-for="(item, i) in showStateSelectedPdr"
                      :key="i"
                      @click="resetState(item.id)"
                      >{{ item.text }}</span
                    >
                  </div>
                </v-row>
                <!-- FILTRI -->
                <template v-if="loaded">
                  <v-row class="totalPay__result">
                    <v-col
                      :cols="
                        itemTotalPaymentStatusPdr.length / 2 > 2 ? '4' : '6'
                      "
                      class="pl-7 pr-7"
                      v-for="(item, i) in itemTotalPaymentStatusPdr"
                      :key="i"
                    >
                      <div
                        class="totalPay__item"
                        :class="{
                          highlighted: item.highlight,
                          pay: item.showPayButton,
                          skipped: item.skipSpace
                        }"
                      >
                        <div
                          class="field"
                          v-if="!item.showPayButton && !item.skipSpace"
                        >
                          {{ item.title }}
                        </div>
                        <div
                          class="total"
                          v-if="!item.showPayButton && !item.skipSpace"
                        >
                          € {{ item.total }}
                        </div>
                        <div v-if="item.showPayButton">
                          <v-btn
                            @click="recapPdrTopay"
                            class="paymentButton"
                            :disabled="!item.hasToPay"
                            x-large
                            >Paga Ora</v-btn
                          >
                        </div>
                      </div>
                    </v-col>
                    <div class="totalPay__info pb-3">
                      <span
                        >I piani di rientro In Pagamento e In Verifica
                        potrebbero non rientrare all’interno della Sintesi
                        visualizzata.</span
                      >
                    </div>
                  </v-row>
                </template>
                <template v-else>
                  <v-row class="std-side-padding pa-12 loader" align="center">
                    <v-col class="text-center">
                      <v-progress-circular
                        :size="100"
                        indeterminate
                        color="#12256A"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>

        <v-row class="std-side-padding" v-if="tabBollettePdrArchive === 'bill'">
          <v-col class="bills py-0">
            <v-row else>
              <v-col class="bills__list py-0">
                <BillsList
                  :bills="filterBills"
                  :supply="detail"
                  :maxToShow="filterBills.length"
                ></BillsList>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="std-side-padding" v-if="tabBollettePdrArchive === 'pdr'">
          <v-col class="bills py-0">
            <v-row v-if="!getInfoPiani.item || getInfoPiani.item.length === 0">
              <v-col class="bills__list">
                <v-row align="center">
                  <v-col class="std-panel std-panel--empty">
                    <h6 class="text-center pt-5 pb-5">
                      Ops! Non sono presenti piani di rientro per questa
                      selezione
                    </h6>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row else>
              <v-col class="bills__list py-0">
                <PdrList
                  :pdrList="filterPdr"
                  :supply="detail"
                  source="Riepilogo e Archivio Pagamenti"
                >
                </PdrList>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <v-dialog v-model="openBillsToPayDialog" :max-width="500">
        <div class="billsRecapDialog">
          <div class="d-flex align-center justify-space-between mb-8">
            <div class="HeadingsSubtitleL">Bollette da pagare</div>
            <img
              @click="openBillsToPayDialog = false"
              src="@/assets/ic_chevron_top_black.png"
            />
          </div>
          <v-row no-gutters>
            <v-col>
              <v-list two-line class="scrollableList">
                <div
                  class="d-flex align-center justify-space-between billsRecapDialog__title"
                >
                  <div class="BodyL-Strong">{{ detail.address }}</div>
                  <div :class="`small-icon-${detail.serviceType}`"></div>
                </div>
                <div
                  v-for="(bill, i) in billsToPayMassive"
                  :key="bill.idFattura"
                >
                  <v-list-item class="pr-4 pl-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        <div
                          :id="bill.idFattura"
                          @click="billSelectionChange(bill, i)"
                          :class="`checkbox d-flex align-center ${
                            bill.confirmPay ? 'selected' : ''
                          }`"
                        >
                          <span class="BodyL"
                            >Bolletta: {{ bill.dataInizioCompetenza }}</span
                          >
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span
                        ><strong
                          >€
                          {{
                            formatTotal(
                              bill.statoFattura !== 'Parziale'
                                ? bill.importo
                                : bill.importoParzialeDaPagare
                            )
                          }}</strong
                        ></span
                      >
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-list>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="10">
              <v-btn
                @click="goToSupplyPayment"
                v-if="!noBillToPaySelected"
                class="paymentButton"
                x-large
                >Paga € {{ formatTotal(totalToPayMassive) }}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-dialog>
      <PhysiCallDialog
        :physicallObj="physiCall"
        :triggerElement.sync="triggerElement"
      ></PhysiCallDialog>

      <FeedbackDialog
        :physicallObj="physiCall"
        :triggerElement.sync="triggerElement"
      />
    </template>
  </div>
  <div v-else-if="status === 'error'"></div>
  <div v-else></div>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import constants from '@/js/constants'
import GenErr from '@/js/genericErrors'
import {
  dynamicSort,
  dynamicSortCresc,
  formatdateMonthYear
} from '@/js/utils.js'
import { mapActions, mapGetters, mapState } from 'vuex'
import BillsList from '../../../components/bills/BIllsList'
import PdrList from '../../../components/pdr/PdrList'
import UserDetail from '../../../components/user/UserDetail'
import DialogFilterState from './DialogFilterState.vue'
import DialogFilterDate from './DialogFilterDate.vue'
import DialogPagaPdr from '../../pdr/DialogPagaPdr.vue'

import PhysiCall from '../../../js/service/physiCall'
import PhysiCallDialog from '../../../components/physicall/PhysiCallDialog.vue'
import FeedbackDialog from '../../../components/physicall/FeedbackDialog.vue'

export default {
  name: 'billsArchive',
  data() {
    return {
      detail: {},
      bills: [],
      tabBollettePdrArchive: 'bill',
      loaded: false,
      dialogDate: false,
      filterBills: [],
      filterPdr: [],
      pickerStart: this.initPickerStart(),
      pickerEnd: this.initPickerEnd(),
      dialogState: false,
      filterSelDate: '',
      filterSelDatePdr: '',
      filterSelState: {
        bill: [
          {
            id: 'inScadenza',
            text: 'In scadenza',
            checked: false,
            code: [],
            visible: false,
            disabled: true
          },
          {
            id: 'inPagamento',
            text: 'In pagamento',
            checked: false,
            code: ['1', '2'],
            visible: true,
            disabled: true
          },
          {
            id: 'scadute',
            text: 'Scadute',
            checked: false,
            code: ['3'],
            visible: true,
            disabled: true
          },
          {
            id: 'Payed',
            text: 'Pagata',
            checked: false,
            code: ['4'],
            visible: true,
            disabled: true
          },
          {
            id: 'parzPagate',
            text: 'Pagata parzialmente',
            checked: false,
            code: ['3'],
            visible: true,
            disabled: true
          },
          {
            id: 'sospesa',
            text: 'Sospesa',
            checked: false,
            code: ['5'],
            visible: true,
            disabled: true
          },
          {
            id: 'inVerifica',
            text: 'In verifica',
            checked: false,
            code: ['6'],
            visible: true,
            disabled: true
          },
          {
            id: 'rateizzazione',
            text: 'Rateizzazione in corso',
            checked: false,
            code: ['7'],
            visible: true,
            disabled: true
          }
        ],
        pdr: [
          {
            id: 'inCorso',
            text: 'In corso',
            checked: false,
            code: ['1'],
            visible: true,
            disabled: true
          },
          {
            id: 'rataScaduta',
            text: 'Rata scaduta',
            checked: false,
            code: ['2'],
            visible: true,
            disabled: true
          },
          {
            id: 'chiuso',
            text: 'Chiuso',
            checked: false,
            code: ['3'],
            visible: true,
            disabled: true
          },
          {
            id: 'annullato',
            text: 'Annullato',
            checked: false,
            code: ['4'],
            visible: true,
            disabled: true
          }
        ]
      },
      showStateSelected: [],
      showStateSelectedPdr: [],
      recurrentPayment: {
        bill: ['CCR', 'SDD', 'DPAY'],
        pdr: ['CCR', 'SDD', 'DPAY']
      },
      openBillsToPayDialog: false,
      billsToPayMassive: [],
      totalToPayMassive: 0,
      noBillToPaySelected: false,
      physiCall: null,
      triggerElement: null,
      dialogPagaPdr: false
    }
  },
  watch: {
    dialogDate(val) {
      if (val) {
        this.trackTapEvent('billArchive_timeFilter_tap')
      }
    },
    dialogState(val) {
      if (val) {
        this.trackTapEvent('billArchive_stateFilter_tap')
      }
    },
    tabBollettePdrArchive() {
      this.physiCall.resetAll()
      // this.physiCall.restartTimerSection()
    }
  },

  methods: {
    ...mapActions('bill', [
      'getComputedBillStatus',
      'computeShowPaymentButton'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('payment', ['getPdrInfo']),
    openFilterState(event) {
      this.triggerElement = event
      this.physiCall.setJourneyDoc(
        constants.JOURNEY.SEZIONE_ARCHIVIO_BOLLETTE_PDR
      )
      let isPhysicallShowing = this.physiCall.checkCountClick('filterStatus')
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        this.dialogState = true
      }
    },

    openFilterDate(event) {
      this.triggerElement = event
      this.physiCall.setJourneyDoc(
        constants.JOURNEY.SEZIONE_ARCHIVIO_BOLLETTE_PDR
      )
      let isPhysicallShowing = this.physiCall.checkCountClick('filterPeriod')
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        this.dialogDate = true
      }
    },

    recapPdrTopay() {
      this.trackTapEvent({
        name: 'payment_started',
        params: {
          service_type:
            this.supplyDetail[
              this.$route.params.supplyCode
            ].serviceType.toLowerCase(),
          component: 'cta',
          payment_item: 'pdr',
          screen_name: 'MySorgenia - Riepilogo e Archivio Pagamenti'
        }
      })
      this.dialogPagaPdr = true
    },

    recapBillsToPay() {
      this.trackTapEvent({
        name: 'supplyDetail_BillsPayNow_tap'
      })
      this.filterBills.forEach((bill) => {
        bill.confirmPay = true
      })
      if (this.isRecurrent()) {
        // is recurrent
        this.billsToPayMassive = this.filterBills.filter(
          (bill) =>
            bill.idStato === '3' &&
            bill.importo >= 0 &&
            !(bill.computedStatus === '6') &&
            !bill.flagPagamentoRata
        )
      } else {
        this.billsToPayMassive = this.filterBills.filter(
          (bill) =>
            (bill.idStato === '3' || bill.idStato === '1') &&
            bill.importo >= 0 &&
            !(bill.computedStatus === '6') &&
            !bill.flagPagamentoRata
        )
      }
      this.totalToPayMassive = this.billsToPayMassive.reduce((sum, bill) => {
        if (bill.statoFattura === 'Parziale') {
          return sum + parseFloat(bill.importoParzialeDaPagare)
        } else {
          return sum + parseFloat(bill.importo)
        }
      }, 0)
      this.noBillToPaySelected = false
      this.openBillsToPayDialog = true
    },
    billSelectionChange(bill, i) {
      this.billsToPayMassive[i].confirmPay = !bill.confirmPay
      this.noBillToPaySelected =
        this.billsToPayMassive.filter((bill) => bill.confirmPay).length === 0
      this.totalToPayMassive = this.billsToPayMassive
        .filter((bill) => bill.confirmPay)
        .reduce(
          (sum, bill) =>
            sum +
            (bill.statoFattura !== 'Parziale'
              ? parseFloat(bill.importo)
              : parseFloat(bill.importoParzialeDaPagare)),
          0
        )
    },
    goToSupplyPayment() {
      let confirmedBillToPay = this.billsToPayMassive.filter(
        (bill) => bill.confirmPay
      )
      let idFattura =
        confirmedBillToPay.length === 1 ? confirmedBillToPay[0].idFattura : ''
      let isMultiPayment = confirmedBillToPay.length > 1
      this.$router.push({
        name: 'paySupplyBills',
        params: {
          bills: confirmedBillToPay,
          isMultiPayment: isMultiPayment,
          idFattura: idFattura,
          from: 'billsArchive'
        }
      })
    },
    initPickerStart() {
      let date = new Date()
      let dateStartDefault = `${date.getFullYear() - 2}-${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-01`

      return { date: dateStartDefault, show: false }
    },
    formatdate(value) {
      return formatdateMonthYear(value)
    },
    initPickerEnd() {
      let date = new Date()
      let dateEndDefault = `${date.getFullYear() + 3}-${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate()}`

      return { date: dateEndDefault, show: false }
    },
    openPicker(type) {
      if (type === 'start') {
        this.pickerStart.show = true
      } else {
        this.pickerEnd.show = true
      }
    },
    refreshTotal(type) {
      if (type === 'date') {
        let dateStart = this.pickerStart.date.split('-')
        let dateEnd = this.pickerEnd.date.split('-')
        this.dialogDate = false
        this.filterSelDate = `${dateStart[1]}/${dateStart[0]} - ${dateEnd[1]}/${dateEnd[0]}`
        this.filterSelDatePdr = `${dateStart[1]}/${dateStart[0]} - ${dateEnd[1]}/${dateEnd[0]}`
      } else {
        this.dialogState = false
        let showState = this.filterSelState[this.tabBollettePdrArchive].filter(
          (fil) => fil.checked
        )
        if (this.tabBollettePdrArchive === 'bill') {
          this.showStateSelected = showState
        } else {
          this.showStateSelectedPdr = showState
        }
      }
      this.filterBills = this.getFilterBills()
      this.filterPdr = this.getFilterPdr()
    },
    resetPicker() {
      let date = new Date()
      let dateEndDefault = `${date.getFullYear() + 3}-${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate()}`
      let dateStartDefault = new Date(date.setFullYear(date.getFullYear() - 2))
      dateStartDefault = `${dateStartDefault.getFullYear()}-${
        dateStartDefault.getMonth() + 1 < 10
          ? '0' + (dateStartDefault.getMonth() + 1)
          : dateStartDefault.getMonth() + 1
      }-01`
      this.pickerStart = { date: dateStartDefault, show: false }
      this.pickerEnd = { date: dateEndDefault, show: false }
      this.filterSelDate = ''
      this.filterSelDatePdr = ''
      this.filterBills = this.getFilterBills()
      this.filterPdr = this.getFilterPdr()
    },
    resetState(id) {
      this.showStateSelected = this.showStateSelected.filter(
        (fil) => fil.id !== id
      )
      this.showStateSelectedPdr = this.showStateSelectedPdr.filter(
        (fil) => fil.id !== id
      )
      this.filterSelState[this.tabBollettePdrArchive] = this.filterSelState[
        this.tabBollettePdrArchive
      ].map((fil) => {
        if (fil.id === id) fil.checked = false
        return fil
      })
      if (this.tabBollettePdrArchive === 'bill') {
        this.filterBills = this.getFilterBills()
      } else {
        this.filterPdr = this.getFilterPdr()
      }
    },
    isRecurrent() {
      if (this.detail.mdp.length === 0) return false
      return this.recurrentPayment[this.tabBollettePdrArchive].find(
        (pay) => pay === this.detail.mdp[0].mdP
      )
    },
    getFilterBills() {
      if (this.bills.length === 0) return []
      let startDate = new Date(this.pickerStart.date).setHours(0, 0, 0, 0)
      let endDate =
        new Date(this.pickerEnd.date).setHours(0, 0, 0, 0) ||
        new Date().setHours(0, 0, 0, 0)
      return this.bills
        .filter((bill) => {
          let dataEmiss = new Date(
            bill.dataEmissione.split('/').reverse().join('-')
          ).setHours(0, 0, 0, 0)
          bill.sortableDate = new Date(
            bill.dataInizioCompetenza.split('/').reverse().join('-')
          ).setHours(0, 0, 0, 0)
          if (dataEmiss >= startDate && dataEmiss <= endDate) {
            if (this.showStateSelected.length > 0) {
              if (
                this.showStateSelected.find((state) =>
                  state.code.includes(bill.computedStatus)
                )
              ) {
                if (bill.computedStatus === '3') {
                  if (
                    this.showStateSelected.find(
                      (state) => state.id === 'parzPagate'
                    ) &&
                    this.showStateSelected.find(
                      (state) => state.id === 'scadute'
                    )
                  ) {
                    return bill
                  } else {
                    if (
                      this.showStateSelected.find(
                        (state) => state.id === 'parzPagate'
                      )
                    ) {
                      if (bill.statoFattura !== 'Parziale') {
                        return false
                      }
                      return bill
                    } else {
                      if (bill.statoFattura === 'Parziale') {
                        return false
                      }
                      return bill
                    }
                  }
                } else {
                  return bill
                }
              }
            } else {
              return bill
            }
          }
        })
        .sort(dynamicSort('-sortableDate'))
    },

    getFilterPdr() {
      if (this.getInfoPiani.item.length === 0) return []
      let startDate = new Date(this.pickerStart.date).setHours(0, 0, 0, 0)
      let endDate =
        new Date(this.pickerEnd.date).setHours(0, 0, 0, 0) ||
        new Date().setHours(0, 0, 0, 0)
      return this.getInfoPiani.item
        .filter((pdr) => {
          let dataInizio = new Date(
            pdr.planStartDate.split('/').reverse().join('-')
          ).setHours(0, 0, 0, 0)
          pdr.sortableDate = new Date(
            pdr.planStartDate.split('/').reverse().join('-')
          ).setHours(0, 0, 0, 0)
          if (dataInizio >= startDate && dataInizio <= endDate) {
            if (this.showStateSelectedPdr.length > 0) {
              if (
                this.showStateSelectedPdr.find((state) =>
                  state.code.includes(pdr.computedStatus)
                )
              ) {
                return pdr
              }
            } else {
              return pdr
            }
          }
        })
        .sort(dynamicSort('-sortableDate'))
    },
    formatTotal: function (value) {
      if (value) {
        return Number(value).toLocaleString('it-IT', {
          minimumFractionDigits: 2
        })
      } else {
        return '0'
      }
    }
  },
  async created() {
    this.physiCall = new PhysiCall(null, null, 3)
    // this.physiCall.setJourneySection(constants.JOURNEY.TIMER_PAGAMENTI)
    if (this.$route.params.valueTab === 'bollette') {
      this.tabBollettePdrArchive = 'bill'
    } else {
      this.tabBollettePdrArchive = 'pdr'
    }
    await this.$store
      .dispatch('supply/getDetail', {
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      })
      .then(async (detail) => {
        let currentSupply = false
        this.bills = await Promise.all(
          detail.bills.map(async (bill) => {
            await this.computeShowPaymentButton(bill).then((shouldShow) => {
              bill.showPaymentButton = shouldShow && bill.importo > 0
              this.$forceUpdate()
            })
            await this.getComputedBillStatus(bill).then((status) => {
              bill.computedStatus = status
              this.$forceUpdate()
            })
            await this.$store
              .dispatch('supply/getSupplies')
              .then((supplies) => {
                currentSupply = supplies.filter(
                  (item) => item.supplyCode === this.$route.params.supplyCode
                )[0]
              })
            return bill
          })
        )
        this.detail = detail
        this.loaded = true
        if (this.$route.params.expiredBillsFilter) {
          this.filterSelState.bill.map((f) => {
            if (f.code.includes('3') || f.id === 'parzPagate') f.checked = true
          })
        }

        if (this.$route.params.expiredPdrFilter) {
          this.filterSelState.pdr.map((f) => {
            if (f.code.includes('2') && !f.disabled) f.checked = true
          })
        }

        if (!this.isRecurrent())
          this.filterSelState.bill[1].text = 'In Scadenza'
        this.trackTapEvent({
          name: 'billDetail_summaryBills_tap',
          params: {
            supply_type: detail.serviceType,
            pr_code: detail.supplyCode,
            client_code: detail.clientOwnerCode,
            supply_status: detail.supplyState,
            product_type: currentSupply.isDigital
              ? 'digital'
              : 'no_digital' || null,
            product_name: constants.PRODUCTS[detail.product] || detail.product,
            offer_type: currentSupply.isPlacet ? 'placet' : 'no_placet' || null,
            client_type: detail.clientOwnerType
          }
        })
        this.trackTapEvent({
          name: 'service_payment_summary_viewed',
          params: {
            service_type: detail.serviceType.toLowerCase()
          }
        })
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    await this.getPdrInfo({
      clientCode: this.$route.params.clientOwnerCode,
      prCode: this.$route.params.supplyCode
    }).then(() => {
      this.getFilterPdr()
    })

    this.refreshTotal('status')
    if (!this.$route.params.expiredBillsFilter) {
      this.refreshTotal('date')
    } else {
      this.resetPicker()
    }
  },
  computed: {
    ...mapGetters('payment', ['getInfoPiani']),
    ...mapGetters('account', ['filteredClientDetails']),
    ...mapGetters('supply', ['supplyDetail']),
    ...mapState('session', ['isFeedbackShowing']),

    alertBill() {
      if (this.bills) {
        return (
          this.bills.filter((bill) => bill.computedStatus === '3').length > 0
        )
      } else {
        return false
      }
    },
    alertPdr() {
      if (this.getInfoPiani?.item) {
        return (
          this.getInfoPiani.item.filter(
            (pdr) => pdr.computedStatus === '2' && !pdr.checkPaymentInstalment
          ).length > 0
        )
      } else {
        return false
      }
    },
    status() {
      return this.$store.state.supply.status
    },
    itemTotalPaymentStatus() {
      let total = 0
      let paid = 0
      let expire = 0
      let toPay = 0
      let verifying = 0
      let notPay = 0
      let rating = 0

      this.filterBills.forEach((bill) => {
        total += parseFloat(bill.importo)
        if (bill.idStato === '4') {
          paid += parseFloat(bill.importo)
        }
        if (bill.idStato === '3') {
          if (bill.statoFattura === 'Parziale') {
            expire += parseFloat(bill.importoParzialeDaPagare)
            paid += parseFloat(bill.importoParzialePagato)
          } else {
            expire += parseFloat(bill.importo)
          }
        }
        if (
          (bill.idStato === '3' || bill.idStato === '1') &&
          bill.importo >= 0
        ) {
          if (!bill.flagPagamentoRata) {
            if (bill.statoFattura === 'Parziale') {
              if (bill.importoParzialeDaPagare >= 0) {
                toPay += parseFloat(bill.importoParzialeDaPagare)
              }
            } else {
              toPay += parseFloat(bill.importo)
            }
          }
        }
        if (bill.computedStatus === '6') {
          if (bill.statoFattura === 'Parziale') {
            verifying += parseFloat(bill.importoParzialeDaPagare)
          } else {
            verifying += parseFloat(bill.importo)
          }
        }
        if (bill.idStato === '1') {
          notPay += parseFloat(bill.importo)
        }
        if (bill.flagPagamentoRata) {
          rating += parseFloat(bill.importo)
        }
      })

      if (this.isRecurrent()) {
        // is recurrent
        return [
          { title: 'Totale emesso', total: this.formatTotal(total) },
          { title: 'Pagate', total: this.formatTotal(paid) },
          {
            title: 'Da pagare',
            total: this.formatTotal(toPay - verifying - notPay),
            highlight: toPay - verifying - notPay > 0
          },
          { title: 'Scadute', total: this.formatTotal(expire) },
          { title: 'Rateizzazione in corso', total: this.formatTotal(rating) },
          { showPayButton: true, hasToPay: toPay - verifying - notPay > 0 }
        ]
      } else {
        return [
          { title: 'Totale emesso', total: this.formatTotal(total) },
          { title: 'Pagate', total: this.formatTotal(paid) },
          {
            title: 'Da pagare',
            total: this.formatTotal(toPay - verifying),
            highlight: toPay - verifying > 0
          },
          { title: 'In scadenza', total: this.formatTotal(notPay) },
          { title: 'Scadute', total: this.formatTotal(expire) },
          // TO DO DA VERIFICARE CON IL FILTRO SULLO STATO
          { title: 'Rateizzazione in corso', total: this.formatTotal(rating) },
          { showPayButton: true, hasToPay: toPay - verifying > 0 }
        ]
      }
    },

    itemTotalPaymentStatusPdr() {
      let totaleEmesso = 0
      let ratePagate = 0
      let rateInScadenza = 0
      let rateDaPagare = 0
      let rateScadute = 0
      let hasToPay = false
      this.filterPdr.forEach((pdr) => {
        pdr.instalments.forEach((element) => {
          let toDay = new Date()
          totaleEmesso += parseFloat(element.amount)
          if (!element.checkPaymentInstalment) {
            if (element.instalmentStatus === constants.STATUS_PDR.EXPIRED) {
              rateScadute += parseFloat(element.residualInstalmentAmount)
            }
            if (element.instalmentStatus === constants.STATUS_PDR.NOT_PAYED) {
              let dateSplit = element.instalmentDueDate.split('/')
              let dateEnd = new Date(
                `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`
              )
              if (toDay.setDate(toDay.getDate() + 5) >= dateEnd.getTime()) {
                rateInScadenza += parseFloat(element.residualInstalmentAmount)
              }
            } else if (
              element.instalmentStatus === constants.STATUS_PDR.PAYED
            ) {
              ratePagate += parseFloat(element.amount)
            }
            rateDaPagare += parseFloat(element.residualInstalmentAmount)
          } else {
            ratePagate += parseFloat(element.amount)
          }
        })
        hasToPay = hasToPay || pdr.isPossibleToPay
      })
      return [
        {
          title: 'Totale emesso',
          total: this.formatTotal(totaleEmesso)
        },
        { title: 'Rate pagate', total: this.formatTotal(ratePagate) },
        {
          title: 'Rate da pagare',
          total: this.formatTotal(rateDaPagare)
        },
        {
          title: 'Rate in Scadenza',
          total: this.formatTotal(rateInScadenza)
        },
        {
          title: 'Rate scadute',
          total: this.formatTotal(rateScadute)
        },
        {
          showPayButton: true,
          hasToPay: hasToPay && rateScadute + rateDaPagare > 0
        }
      ]
    },
    filterPdrToPay() {
      return this.filterPdr.filter((pdr) => {
        if (pdr.planStatus === constants.STATUS_PDR.ACTIVE) {
          pdr.instalments = pdr.instalments.filter(
            (instalment) =>
              instalment.instalmentStatus !== constants.STATUS_PDR.PAYED &&
              !instalment.checkPaymentInstalment
          )
          return pdr
        }
      })
    }
  },
  components: {
    UserDetail,
    PdrList,
    BillsList,
    TcVars,
    PhysiCallDialog,
    DialogFilterState,
    DialogFilterDate,
    DialogPagaPdr,
    FeedbackDialog
  }
}
</script>
<style lang="scss">
.customCheck {
  .v-icon.v-icon {
    font-size: 30px;
  }
  .v-label {
    font-size: 14px;
    color: black;
  }
}
</style>
<style lang="scss" scoped>
@import '../../../styles/dialog_pay_bills/dialogPayBills.scss';

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #b7bfd2;
  border-bottom: 3px solid #b7bfd2;
}

.notification {
  width: 10px;
  background: #e84182;
  height: 10px;
  display: inline-block;
  margin-left: 8px;
  border-radius: 50%;
}
.scrollableList {
  height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar-button {
    display: block;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($srg-lightgray, 0.7);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $srg-lightgray;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}

.bills {
  .bills__title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .bills__archive,
  .action__archive {
    text-align: right;
    .bills__btn {
      height: 50px;
      width: 245px;
    }
  }
}
.btn-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 177px;
  height: 56px;
  border-radius: 20px;
  border: solid 1.1px #dbdbdb;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: $srg-blue;
  cursor: pointer;
  &:before {
    content: url('../../../assets/icons/calendar.svg');
    width: 18px;
    margin-right: 16px;
    padding-top: 5px;
  }
  &:after {
    content: url('../../../assets/icons/chevron.svg');
    width: 18px;
    margin-left: 8px;
    padding-top: 5px;
  }
  &.payment {
    width: 232px;
    &:before {
      content: '';
      margin: 0;
    }
  }
  &.big {
    width: 310px;
    height: 66px;
    padding-left: 26px;
    justify-content: start;
    &:before {
      width: 26px;
    }
    &:after {
      content: '';
    }
  }
}
.totalPay {
  &__title {
    font-size: 40px;
    letter-spacing: 0.8px;
    line-height: 48px;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 24px;
  }
  &__subtitle {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 32px;
    color: #000;
    letter-spacing: 1px;
  }
  &__filtered {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 24px;
    padding: 24px;
    border-radius: 20px;
    background: #f8f9fc;
    width: 100%;
    h5 {
      font-size: 12px;
      color: #6e7689;
      letter-spacing: 1px;
      font-weight: bold;
      text-transform: uppercase;
      &.w-100 {
        width: 100%;
      }
    }
    span {
      display: flex;
      align-items: center;
      margin-right: 35px;
      font-size: 14px;
      font-weight: 500;
      color: $srg-blue;
      cursor: pointer;
      &:before {
        margin-right: 11px;
        content: url('../../../assets/icons/close-blu.svg');
        fill: $srg-blue !important;
        margin-top: 5px;
      }
    }
  }
  &__result {
    width: 100%;
    // height: 247px;
    margin-top: 32px;
    border-radius: 20px;
    background-color: #f8f9fc;
    // .col:last-of-type {
    //   font-weight: bold;
    // }
  }
  &__info span {
    font-size: 14px;
    color: black;
    padding-left: 34px;
    letter-spacing: 1px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 2px solid $srg-lightgray;
    &.pay {
      border-bottom: none;
    }
    &.skipped {
      border-bottom: none;
    }
    .field {
      font-size: 16px;
      color: #000;
      letter-spacing: 1px;
    }
    .total {
      font-size: 24px;
      font-weight: bold;
    }
    &.highlighted {
      .field {
        font-weight: bold;
        color: black;
      }
      .total {
        color: black;
      }
    }
    .paymentButton {
      color: white;
      background-color: $srg-blue !important;
      height: 50px;
      width: 320px;
    }
  }
}

.limitedTo11Cols {
  width: calc(100% / 12 * 11);
}

.small-icon-Ele {
  background-image: url('../../../assets/icons/ic-light-blu.svg');
  background-size: 24px;
  margin-left: 20px;
  width: 24px;
  height: 24px;
}

.small-icon-Gas {
  background-image: url('../../../assets/icons/ic-fire-blu.svg');
  background-size: 24px;
  margin-left: 20px;
  width: 24px;
  height: 24px;
}
</style>

<style lang="scss">
.v-picker.v-card {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v-picker__title.primary {
  display: none;
}
.v-picker__body.theme--light {
  background: #f6f6f6 !important;
  border-radius: 20px !important;
}
</style>
