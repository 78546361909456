<template>
  <div>
    <TabLayout v-if="client">
      <div class="know mt-12 w-100">
        <v-row no-gutters v-if="!isMobile">
          <h2 class="mb-12 mt-4 w-100">
            <strong>{{ currentSlide.title }}</strong>
          </h2>
          <h5 class="mb-12" v-html="currentSlide.tab_subtitle"></h5>
        </v-row>
        <v-row no-gutters v-if="isMobile">
          <h2 class="w-100" style="line-height: 1">
            <strong>{{ currentSlide.carousel_title }}</strong>
          </h2>
          <h3 class="mb-12 mt-4 w-100">
            <strong>{{ currentSlide.title }}</strong>
          </h3>
        </v-row>
        <div class="tutorial-row mb-12" v-for="(r, i) in cards" :key="i">
          <TutorialCard v-for="(card, k) in r" :card="card" :key="k" />
        </div>
        <v-row>
          <v-col
            md="6"
            :offset="isSmallDevice ? 0 : 3"
            v-if="currentSlide.show_feedback == 1"
          >
            <div>
              <InstrumentItem
                title="Feedback"
                icon="feedback.svg"
                text="La tua opinione è un prezioso contributo. Dedicaci 3 minuti  rispondendo a poche domande!"
                link="Dicci la tua"
                @click="
                  trackTapEvent({
                    name: 'wkTutorial_feeback_tap',
                    params: {
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
                "
                :routeName="isMobile ? 'publicFeedback' : 'feedback'"
              />
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="reverseBtn">
          <v-col>
            <v-btn
              @click="
                $router.push(back()),
                  trackTapEvent({
                    name: 'wkTutorial_continue_tap',
                    params: {
                      section_title: prevSlideTitle,
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
              "
              x-large
              color="white"
              class="v-btn--shadowed mb-8 mx-auto"
              >{{ prevSlideTitle }}</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              style="color: white"
              @click="
                $router.push(next()),
                  trackTapEvent({
                    name: 'wkTutorial_continue_tap',
                    params: {
                      section_title: nextSlideTitle,
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
              "
              x-large
              class="v-btn--shadowed mb-8 mx-auto button--blue"
              v-show="hasNextSlide"
              >{{ nextSlideTitle }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </TabLayout>
    <TutorialOverlay
      :overlay.sync="overlay"
      v-if="currentSlide && currentSlide.show_feedback_overlay == 1 && overlay"
      :isMobile="isMobile"
    />
  </div>
</template>
<script>
import {
  chunkArray,
  getKitSlideRouteName,
  isOnMobileDeviceGetPlatform
} from '@/js/utils.js'
import { mapActions, mapGetters } from 'vuex'
import TutorialCard from './TutorialCard'

export default {
  name: 'standardTutorial',
  data() {
    return {
      currentSlideId: this.$route.params.id,
      header: 'Aggiornare i dati del profilo',
      title: 'Tieni aggiornato e completo il tuo profilo!',
      isMobile: isOnMobileDeviceGetPlatform(),
      isSmallDevice: window.innerWidth <= 480,
      isBigDevice: window.innerWidth > 480 && window.innerWidth <= 1024,
      isDesktop: window.innerWidth > 1024,
      overlay: false
    }
  },
  methods: {
    ...mapActions('feedback', ['loadSurvey', 'loadUndoneSurvey']),
    ...mapActions('analytics', ['trackTapEvent']),
    back() {
      if (this.currentIndex === 0)
        return { name: this.isMobile ? 'publicTutorial' : 'tutorial' }

      let slides = this.tutorialSlidesForUser || []
      let prevSlide = slides[this.currentIndex - 1]

      if (prevSlide.template_name === 'standardTutorial') {
        return {
          name: getKitSlideRouteName(prevSlide.template_name, this.isMobile),
          params: { id: prevSlide.id }
        }
      } else {
        return {
          name: getKitSlideRouteName(prevSlide.template_name, this.isMobile)
        }
      }
    },
    next() {
      let slides = this.tutorialSlidesForUser || []
      if (this.currentIndex === slides.length - 1) return ''
      let nextSlide = slides[this.currentIndex + 1]

      if (nextSlide.template_name === 'standardTutorial') {
        return {
          name: getKitSlideRouteName(nextSlide.template_name, this.isMobile),
          params: { id: nextSlide.id }
        }
      } else {
        return {
          name: getKitSlideRouteName(nextSlide.template_name, this.isMobile)
        }
      }
    }
  },
  computed: {
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['tutorialSlidesForUser', 'tutorial']),
    currentIndex() {
      var slides = this.tutorialSlidesForUser || []
      return slides.findIndex((slide) => slide.id === this.currentSlideId)
    },
    currentSlide() {
      var slides = this.tutorialSlidesForUser || []
      return slides[this.currentIndex]
    },
    nextSlideTitle() {
      let slides = this.tutorialSlidesForUser || []
      if (this.currentIndex === slides.length - 1) return ''
      let nextSlide = slides[this.currentIndex + 1]

      return nextSlide.carousel_title
    },
    hasNextSlide() {
      return this.currentIndex < this.tutorialSlidesForUser.length - 1
    },
    prevSlideTitle() {
      if (this.currentIndex === 0) return this.tutorial.title

      let slides = this.tutorialSlidesForUser || []
      let prevSlide = slides[this.currentIndex - 1]

      return prevSlide.carousel_title
    },
    cardsInfo() {
      return this.currentSlide ? this.currentSlide.paragraphs : []
    },
    cards() {
      let cardPerPage = this.isSmallDevice ? 1 : 2
      let chunkedSlides = chunkArray(this.cardsInfo, cardPerPage)
      let chunked = []

      chunkedSlides.forEach((pageSlides) => {
        chunked.push([...pageSlides])
      })

      return chunked
    }
  },
  created() {
    this.loadSurvey().then((res) => {
      this.loadUndoneSurvey().then((undoneSurveys) => {
        this.overlay = undoneSurveys.length > 0
      })
    })
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentSlideId = id
    },
    client: function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$store.dispatch('welcomekit/getTutorialByUserProfile', {
          client: this.client
        })
      }
    }
  },
  components: {
    TutorialCard,
    TabLayout: () => import('@/views/tutorial/TabLayout'),
    InstrumentItem: () => import('@/components/support/InstrumentItem'),
    TutorialOverlay: () => import('@/views/welcome/TutorialOverlay')
  }
}
</script>
<style lang="scss" scoped>
.button--blue {
  background-color: $srg-blue !important;
}
</style>
