<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="1140">
    <v-row>
      <v-col class="help-dialog">
        <img @click="closeDialog" src="../../assets/ic_chevron_top_black.png" />
        <h2>
          {{
            reserveChat
              ? 'La tua richiesta è stata inviata'
              : 'Pensiamo tutto a noi'
          }}
        </h2>
        <div v-if="!reserveChat" class="help-dialog__text--small">
          Il nostro Servizio è aperto dal Lunedì al Venerdì dalle
          <span class="help-dialog__num">{{
            chatData.monday_friday_start
          }}</span>
          alle
          <span class="help-dialog__num">{{ chatData.monday_friday_end }}</span>
          e il Sabato dalle
          <span class="help-dialog__num">{{ chatData.saturday_start }}</span>
          alle <span class="help-dialog__num">{{ chatData.saturday_end }}</span
          >. Un nostro operatore ti contatterà al più presto.
          <v-row no-gutters>
            <v-col class="col-4">
              <v-text-field
                class="help__input"
                type="number"
                v-model="phone"
                :error-messages="phoneErrors"
                placeholder="numero di telefono"
                persistent-placeholder
                @blur="v$.phone.$touch()"
                label="Dove possiamo chiamarti?"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <ExtensibleButtonGroup
                v-model="times"
                :per-row="2"
                groupName="times"
                size="md"
                :content="false"
              >
                <ExtensibleButton
                  name="indifferente"
                  label="Indifferente"
                ></ExtensibleButton>
                <ExtensibleButton
                  name="mattina"
                  label="Mattina"
                ></ExtensibleButton>
                <ExtensibleButton
                  name="pomeriggio"
                  label="Pomeriggio"
                ></ExtensibleButton>
                <ExtensibleButton name="sera" label="Sera"></ExtensibleButton>
              </ExtensibleButtonGroup>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <v-btn
                x-large
                class="white--text auth__btn v-btn--shadowed"
                color="#12256A"
                :rounded="true"
                :disabled="v$.$invalid"
                @click="reserve"
              >
                Prenota
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else class="help-dialog__text--small">
          Verrai ricontattato nella fascia oraria da te indicata dal numero
          <span class="help-dialog__num">{{ callBackNum.contact }}</span
          >.
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'

import ExtensibleButton from '../../components/extensibleButton/ExtensibleButton'
import ExtensibleButtonGroup from '../../components/extensibleButton/ExtensibleButtonGroup'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'callMeBackDialog',
  props: ['value', 'fromPage', 'requestingClient'],
  data() {
    return {
      chatData: {},
      callBackNum: {},
      clientCode: null,
      clientType: null,
      times: null,
      phone: '',
      reserveChat: false
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails']),
    phoneErrors() {
      let errArray = []

      if (!this.v$.phone.$dirty) return errArray

      this.v$.phone.minLength.$invalid === true &&
        errArray.push('Il numero di telefono deve essere di almeno 6 cifre')
      this.v$.phone.maxLength.$invalid === true &&
        errArray.push('Il numero di telefono può avere al massimo 12 cifre')
      this.v$.phone.required.$invalid === true &&
        errArray.push('Il Numero di telefono è obbligatorio')

      return errArray
    }
  },
  watch: {
    clientDetails(clientDetails) {
      this.phone = (
        clientDetails.mobilePhone ||
        clientDetails.phone ||
        ''
      ).replace(/\s/g, '')
    }
  },

  methods: {
    ...mapActions('account', ['getMainClient']),
    ...mapActions('interactions', { callMeBack: 'callMeBack' }),
    ...mapActions('interactions', ['getChannelInfo']),
    ...mapActions('analytics', ['trackTapEvent']),
    reserve() {
      if (this.fromPage === 'fiber') {
        this.trackTapEvent({
          name: 'fiberUpsell_bookContact_tap',
          params: { client_code: this.requestingClient.clientCode }
        })
      } else {
        this.trackTapEvent({
          name: 'commodityUpsell_bookContact_tap',
          params: {
            client_code: this.requestingClient.clientCode,
            client_type: this.requestingClient.clientType
          }
        })
      }

      let chatData = {
        clientCode: this.clientCode,
        commodity: this.fromPage,
        phone: this.phone,
        times: this.times,
        email: '',
        notes: this.setNotes()
      }
      this.callMeBack(chatData)
        .then((resp) => {
          this.reserveChat = resp
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    },
    closeDialog() {
      this.$emit('input')
      this.reserveChat = false
    },
    setNotes() {
      if (this.fromPage === 'fiber') {
        return 'Fibra - Info per Upselling'
      }
      if (this.fromPage === 'luce') {
        return 'Luce - Info per Upselling'
      } else {
        return 'Gas - Info per Upselling'
      }
    }
  },
  created() {
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area:
        this.fromPage !== 'fiber'
          ? consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
          : consts.CALLCENTER_AREAS.UPSELLING_FIBER
    })
      .then((resp) => {
        this.chatData = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })

    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.CALLBACK,
      area:
        this.fromPage !== 'fiber'
          ? consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
          : consts.CALLCENTER_AREAS.UPSELLING_FIBER
    })
      .then((resp) => {
        this.callBackNum = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })

    this.getMainClient(this.$store.getters['session/username'])
      .then((resp) => {
        this.clientCode = resp.clientOwnerCode
        this.clientType = resp.clientOwnerType
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  validations: {
    times: { required },
    phone: { required, minLength: minLength(6), maxLength: maxLength(12) }
  },
  components: {
    ExtensibleButtonGroup,
    ExtensibleButton
  }
}
</script>
<style lang="scss" scoped>
.v-dialog__content {
  .help-dialog {
    background: white;
    padding: 75px 48px 50px 48px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    &__text {
      font-size: 22px;
      font-weight: 300;
      line-height: 30px;
      &--small {
        font-size: 20px;
      }
    }
    &__num {
      font-weight: bold;
    }
    &__chat {
      color: $srg-blue;
      font-weight: bold;
      cursor: pointer;
    }
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
    .help__input {
      margin-top: 30px;
      .v-label {
        color: black;
      }
    }
    button {
      width: 264px;
    }
  }
}
</style>
