<template>
  <page step="offer">
    <template v-slot:header>
      <h1 class="ml-12 font-weight-bold">Next Energy<br />Gas!</h1>
    </template>
    <div class="std-side-padding upSelling">
      <v-row class="mt-12 pt-4">
        <v-col>
          <h2>Seleziona richiesta</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mb-2">
          <ExtensibleButtonGroup
            v-model="activeContract"
            :per-row="3"
            :content="isOptionExtended"
            :hideContentOnBlur="false"
            @change="test"
          >
            <ExtensibleButton
              name="newSupplier"
              label="Cambia il tuo attual efornitore"
            >
              <v-row>
                <v-col><h4>Carica la tua bolletta</h4></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    height="66"
                    class="v-btn--shadowed mb-2 upload font-weight-regular"
                    color="white"
                    width="530"
                    raised
                    x-large
                    @click="openFileDialog"
                  >
                    Importa documento
                  </v-btn>
                </v-col>
              </v-row>
              <v-file-input
                id="file-upload"
                style="display: none"
                @change="onFileChange($event)"
                v-model="file"
              />
              <v-row>
                <v-col>
                  <v-card
                    class="v-card--rounded mb-6"
                    width="530"
                    height="66"
                    v-for="(item, i) in files"
                    :key="i"
                    outlined
                  >
                    <v-row class="pt-2">
                      <v-col class="ml-4" cols="1"
                        ><v-icon>mdi-file-image</v-icon></v-col
                      >
                      <v-col cols="9">{{ item.name }}</v-col>
                      <v-col class="ml-3" cols="1"
                        ><v-icon color="primary" @click="deleteFile(i)"
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </ExtensibleButton>
            <ExtensibleButton
              name="deactivation"
              label="Riattiva contatore non più attivo"
            >
              <v-container class="pl-7">
                <v-row>
                  <v-col><h3 class="mb-2">Pensiamo a tutto noi</h3></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Un nostro operatore ti contatterà al più presto per
                      completare il processo. Il nostro Customer Care è aperto
                      dal lunedì al venerdì dalle 9.00 alle 21.00 e il sabato e
                      la domenica dalle 9.00 alle 18.00.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <h5>Dove posso chiamarti?</h5>
                    <v-text-field
                      v-model="phone"
                      ref="phone"
                      @input="filterPhone"
                      placeholder="numero di telefono"
                      persistent-placeholder
                    />
                  </v-col>
                  <v-col cols="8">
                    <h5>Quando preferisci essere contattato?</h5>
                    <ExtensibleButtonGroup
                      v-model="callTime"
                      :per-row="2"
                      :content="false"
                      :hideContentOnBlur="false"
                    >
                      <ExtensibleButton
                        v-for="button in partOfDay"
                        :key="button"
                        :name="button"
                        :label="button"
                      />
                    </ExtensibleButtonGroup>
                  </v-col>
                </v-row>
              </v-container>
            </ExtensibleButton>
            <ExtensibleButton
              name="newTracker"
              label="Richiedi un nuovo contatore"
            >
              <v-container class="pl-7">
                <v-row>
                  <v-col><h3 class="mb-2">Pensiamo a tutto noi</h3></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>
                      Un nostro operatore ti contatterà al più presto per
                      completare il processo. Il nostro Customer Care è aperto
                      dal lunedì al venerdì dalle 9.00 alle 21.00 e il sabato e
                      la domenica dalle 9.00 alle 18.00.
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <h5>Dove posso chiamarti?</h5>
                    <v-text-field
                      v-model="phoneNew"
                      ref="phoneNew"
                      @input="filterPhoneNew"
                      placeholder="numero di telefono"
                      persistent-placeholder
                    />
                  </v-col>
                  <v-col cols="8">
                    <h5>Quando preferisci essere contattato?</h5>
                    <ExtensibleButtonGroup
                      v-model="callTime"
                      :per-row="2"
                      :content="false"
                      :hideContentOnBlur="false"
                    >
                      <ExtensibleButton
                        v-for="button in partOfDay"
                        :key="button"
                        :name="button"
                        :label="button"
                      />
                    </ExtensibleButtonGroup>
                  </v-col>
                </v-row>
              </v-container>
            </ExtensibleButton>
          </ExtensibleButtonGroup>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="3">
          <v-btn
            @click="buttonClick"
            class="v-btn--shadowed"
            x-large
            color="primary"
            width="260"
            :outlined="isSubmitButtonDisabled"
            :disabled="isSubmitButtonDisabled"
            >Prosegui</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <v-footer class="topRadius mt-12" height="70">
      <v-row>
        <v-col align="center" justify="center">
          <p class="ma-0">
            Hai bisogno di aiuto?
            <span class="pl-2">Contattaci senza impegno</span>
          </p>
        </v-col>
      </v-row>
    </v-footer>
  </page>
</template>

<script>
import { maxLength } from '@vuelidate/validators'
import ExtensibleButton from '../../../components/extensibleButton/ExtensibleButton'
import ExtensibleButtonGroup from '../../../components/extensibleButton/ExtensibleButtonGroup'
import Template from '../Template'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'NewTracker',
  components: {
    ExtensibleButtonGroup: ExtensibleButtonGroup,
    ExtensibleButton: ExtensibleButton,
    page: Template
  },
  data: () => ({
    activeContract: '',
    isOptionExtended: false,
    file: [],
    files: [],
    activeTab: '',
    callTime: '',
    phone: '',
    phoneNew: '',
    partOfDay: ['Indifferente', 'Mattina', 'Pomeriggio', 'Sera']
  }),
  computed: {
    formModel() {
      if (this.activeTab === 'newSupplier') {
        return this.files.length > 0 ? this.files : null
      }
      if (this.activeTab === 'deactivation') {
        return this.phone && this.callTime
          ? { phone: this.phone, time: this.callTime, function: this.activeTab }
          : null
      }
      if (this.activeTab === 'newTracker') {
        return this.phoneNew && this.callTime
          ? {
              phone: this.phoneNew,
              time: this.callTime,
              function: this.activeTab
            }
          : null
      }
      return null
    },
    isSubmitButtonDisabled() {
      if (!this.activeContract && !this.isOptionExtended && !this.formModel) {
        return true
      }
      if (this.activeContract && this.isOptionExtended && !this.formModel) {
        return true
      } else return false
    },
    errorMaxLenght() {
      let errArray = []
      this.v$.phone.maxLength.$invalid === true &&
        errArray.push('Numero massimo di cifre raggiunto.')

      return errArray
    },
    errorNewMaxLenght() {
      let errArray = []
      this.v$.phoneNew.maxLength.$invalid === true &&
        errArray.push('Numero massimo di cifre raggiunto.')

      return errArray
    }
  },
  methods: {
    buttonClick() {
      if (!this.isOptionExtended) {
        this.isOptionExtended = true
      }
    },
    test(value) {
      this.activeTab = value
    },
    openFileDialog() {
      document.getElementById('file-upload').click()
    },
    onFileChange(evt) {
      this.files.push(evt)
    },
    deleteFile(index) {
      this.files.splice(index, 1)
    },
    filterPhone(val) {
      if (val) {
        this.phone =
          val.substr(0, 12).match(/[0-9]+/) || this.$refs.phone.reset()
      } else {
        this.phone = ''
      }
    },
    filterPhoneNew(val) {
      if (val) {
        this.phone =
          val.substr(0, 12).match(/[0-9]+/) || this.$refs.phoneNew.reset()
      } else {
        this.phone = ''
      }
    }
  },
  validations: {
    phone: {
      maxLength: maxLength(12)
    },
    phoneNew: {
      maxLength: maxLength(12)
    }
  }
}
</script>
<style lang="scss" src="../../../styles/upselling/_main.scss" />
<style lang="scss" scoped>
.upSelling {
  min-height: 654px;
  width: 100%;
}
span {
  color: $srg-blue;
  font-weight: bold;
}
.offering {
  h1 {
    color: white;
  }
}
.upload {
  font-size: 16px;
  letter-spacing: initial;
  justify-content: start;
  color: black !important;
}
.v-card--rounded {
  border-radius: 20px !important;
}
</style>
