var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link",on:{"click":_vm.goToLoyalty}},[_c('LayoutCard',{attrs:{"registered":_vm.initiative.registered,"loaded":_vm.loaded},scopedSlots:_vm._u([{key:"bkg",fn:function(){return [_c('div',{class:`bkg ${
          _vm.initiative.registered ||
          _vm.IDENTITY_STATUS_ERROR ||
          _vm.IDENTITY_BALANCE_ERROR ||
          _vm.IDENTITY_LEVEL_ERROR
            ? 'full'
            : ''
        }`})]},proxy:true},{key:"text",fn:function(){return [(
          _vm.IDENTITY_STATUS_ERROR ||
          _vm.IDENTITY_BALANCE_ERROR ||
          _vm.IDENTITY_LEVEL_ERROR
        )?_c('div',{staticClass:"text"},[_c('div',{staticClass:"title big"},[_c('div',{staticClass:"t-white HeadingL-Upper mb-12"},[_vm._v("GREENERS")]),_c('div',{staticClass:"t-white HeadingL-Upper pt-1"},[_vm._v("IN CARICAMENTO")])])]):(_vm.memberStatus.statusGreeners === 'Pending')?_c('div',{staticClass:"text justify-end"},[_c('div',{staticClass:"title big"},[_c('div',{staticClass:"t-white HeadingL-Upper"},[_vm._v("BENVENUTO IN")]),_c('div',{staticClass:"t-white HeadingL-Upper"},[_vm._v("GREENERS")])])]):(!_vm.initiative.registered)?_c('div',{staticClass:"text justify-end"},[_c('div',{staticClass:"title big"},[_c('div',{staticClass:"t-white"},[_vm._v("COMMUNITY")]),_c('div',{staticClass:"t-green"},[_vm._v("GREENERS")])])]):_c('div',{staticClass:"text justify-space-between"},[_c('div',{staticClass:"title big"},[(_vm.memberStatus.status_category === 'UNAWARE')?_c('div',{staticClass:"t-white HeadingL-Upper"},[_vm._v(" BENVENUTO IN GREENERS! ")]):_c('div',{staticClass:"t-white HeadingL-Upper"},[_vm._v("GREENERS")])]),_c('div',{staticClass:"d-flex align-center"},[(_vm.getLevelInfo)?_c('img',{attrs:{"src":_vm.getLevelInfo,"width":"40"}}):_vm._e(),_c('div',{staticClass:"desc"},[_c('h5',[_vm._v(_vm._s(_vm.initiative.memberBalance))]),_c('div',{staticClass:"BodyL"},[_vm._v("I tuoi Green Coins")])])])])]},proxy:true},{key:"button",fn:function(){return [(!_vm.loaded)?_c('div',{staticClass:"btn-card"}):(
          _vm.IDENTITY_STATUS_ERROR ||
          _vm.IDENTITY_BALANCE_ERROR ||
          _vm.IDENTITY_LEVEL_ERROR
        )?_c('div',{staticClass:"btn-card"},[_c('div',{staticClass:"btn-text"},[_vm._v("VAI AI CONTENUTI")]),_c('img',{attrs:{"src":require("../../../assets/icons/chevron_rx.svg"),"width":"24px"}})]):(_vm.memberStatus.statusGreeners === 'Pending')?_c('div',{staticClass:"btn-card"},[_c('div',{staticClass:"btn-text"},[_vm._v("SCOPRI ORA")]),_c('img',{attrs:{"src":require("../../../assets/icons/chevron_rx.svg"),"width":"24px"}})]):(!_vm.initiative.registered)?_c('div',{staticClass:"btn-card"},[_c('div',{staticClass:"btn-text"},[_vm._v("UNISCITI")]),_c('img',{attrs:{"src":require("../../../assets/icons/active.svg"),"width":"24px"}})]):(_vm.memberStatus.status_category === 'UNAWARE')?_c('div',{staticClass:"btn-card white"},[_c('div',{staticClass:"btn-text color--blue"},[_vm._v("PASSA ALL' AZIONE")]),_c('img',{attrs:{"src":require("../../../assets/icons/chevron_rx.svg"),"width":"24px"}})]):_c('div',{staticClass:"btn-card"},[_c('div',{staticClass:"btn-text"},[_vm._v("VAI AI CONTENUTI")]),_c('img',{attrs:{"src":require("../../../assets/icons/chevron_rx.svg"),"width":"24px"}})])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }