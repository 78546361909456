<template>
  <v-radio-group
    :class="[
      'extensible',
      `extensible--${size}`,
      radio ? 'extensible--with-radio' : 'extensible--wo-radio'
    ]"
    :value="model"
    :perRow="perRow"
    :margin="margin"
    :size="size"
    :multiple="multiple"
    @addedButton="handlerRefreshItems"
    @change="handlerRadioChanged"
    @changeActiveContent="changeActiveContent"
    @toggleShowContent="handlerToggleShowContent"
  >
    <svg
      v-show="hasActive"
      :height="svg.height + 2"
      :viewBox="`-1 -1 ${svg.width + 2 + margin * 2} ${svg.height + 2}`"
      :width="svg.width + margin * 2"
      class="extensible__background"
      v-html="svg.body"
    />
    <div
      :class="[
        'extensible__rows',
        {
          'extensible__rows--hideContent':
            !showContent || !content || !items[model]
        }
      ]"
    >
      <slot />
    </div>
  </v-radio-group>
</template>
<script>
export default {
  name: 'extensibleButtonGroup',
  props: {
    model: { type: [String, Number, Object, Array], default: '' },
    perRow: { type: [Number, String], default: 3 },
    size: { type: String, default: 'md' },
    radio: { type: Boolean, default: true },
    content: { type: Boolean, default: true },
    hideContentOnBlur: { type: Boolean, default: true },
    bgColor: { type: String, default: 'white' },
    multiple: { type: Boolean, default: false }
  },
  model: {
    prop: 'model',
    event: 'updateModel'
  },
  data: () => ({
    items: {},
    itemsArr: [],
    activeContent: null,
    showContent: true,
    svg: {
      width: 0,
      height: 0,
      el: null,
      baseEl: null,
      content: null,
      button: null,
      body: ''
    },
    requestID: null
  }),
  provide() {
    let options = {}
    Object.defineProperty(options, 'perRow', {
      enumerable: true,
      get: () => this.perRow
    })
    Object.defineProperty(options, 'size', {
      enumerable: true,
      get: () => this.size
    })
    Object.defineProperty(options, 'margin', {
      enumerable: true,
      get: () => this.margin
    })
    Object.defineProperty(options, 'perent', {
      enumerable: true,
      get: () => this.$el
    })
    Object.defineProperty(options, 'model', {
      enumerable: true,
      get: () => this.model
    })
    return { options }
  },
  computed: {
    radius() {
      const r = {
        md: 20,
        lg: 40
      }
      return r[this.size] || 20
    },
    margin() {
      const m = {
        md: 10,
        lg: 30
      }
      return m[this.size] || 10
    },
    hasActive() {
      return !!this.model
    }
  },
  watch: {
    showContent(value) {
      this.$emit('showContentChanged', value)
    }
  },
  created() {
    this.handlerRefreshItems()
  },
  mounted() {
    this.svg = {
      width: 0,
      height: 0,
      el: null,
      baseEl: null,
      content: null,
      button: null
    }
    this.svg.el = this.$el.querySelector('.extensible__background')
    this.svg.baseEl = this.$el
    this.handlerRadioChanged(this.model)
    this.showContent = false
    this.requestID = window.requestAnimationFrame(this.updateSvg)
    this.$nextTick(() => {
      if (this.hideContentOnBlur) {
        window.addEventListener('click', this.handlerCloseOnBlur, true)
        window.addEventListener('touchstart', this.handlerCloseOnBlur)
      }
    })
  },
  beforeUnmount() {
    window.cancelAnimationFrame(this.requestID)
    if (this.hideContentOnBlur) {
      window.removeEventListener('click', this.handlerCloseOnBlur)
      window.removeEventListener('touchstart', this.handlerCloseOnBlur)
    }
  },
  methods: {
    changeActiveContent(content) {
      this.activeContent = content
      this.showContent = false
    },
    handlerRefreshItems(options) {
      if (options) {
        this.$set(this.items, options.value, options.hasContent)
        this.itemsArr.push(options.buttonInstance)
      }
    },
    handlerRadioChanged(data) {
      if (data) {
        this.$emit('updateModel', data)
      }
      this.$emit('change', data)
    },
    handlerCloseOnBlur(e) {
      // if (!(this.$el.contains(e.target) || this.$el === e.target)) {
      //   this.showContent = false
      // }
      if (!(this.$el.contains(e.target) || this.$el === e.target)) {
        if (this.showContent) {
          this.raiseTouchedEvent()
        }
        this.showContent = false
      }
    },
    updateSvg() {
      if (this.svg.baseEl) {
        this.svg.button = this.svg.baseEl.querySelector(
          '.extensible__btn.active'
        )
        this.svg.content = this.svg.baseEl.querySelector(
          '.extensible__content.active'
        )
        this.svg.height = this.svg.baseEl.clientHeight
        this.svg.width = this.svg.baseEl.clientWidth

        if (this.svg.button) this.makeSvg()
      }
      this.requestID = window.requestAnimationFrame(this.updateSvg)
    },
    makeSvg() {
      const bp = this.buttonPosition()
      const r = this.radius
      let res = `<path fill="${this.bgColor}" stroke="currentColor" stroke-width="1" d="M`
      if (this.svg.button && this.hasActive) {
        const {
          offsetLeft: x,
          offsetTop: y,
          clientWidth: width,
          clientHeight: height
        } = this.svg.button
        let start = {}
        let finish = {}
        start.x = x + width + r
        start.y = finish.y = y + height + this.margin * 2
        finish.x = x - (bp === 'l' ? 0 : r)
        if (this.activeContent && this.content && this.showContent) {
          res += `${x - (bp === 'l' ? 0 : r)},${y + height + this.margin * 2}`
          res += `${bp === 'l' ? `l0,${-r}` : `q${r},${0} ${r},${-r}`}`
          res += `l0,${-(height - r * 2 + this.margin * 2)}`
          res += `${this.getTurn({ x: 1, y: -1, r })}`
          res += `l${width - r * 2},0`
          res += `${this.getTurn({ x: 1, y: 1, r })}`
          res += `l0,${height - r * 2 + this.margin * 2}`
          res += `${bp === 'r' ? `l0,${r}` : `q${0},${r} ${r},${r}`}`
          res += `${this.makeSvgBody(start, finish)}`
        } else {
          res += `${x},${y + height - r}
            l0,${-(height - r * 2)}
            ${this.getTurn({ x: 1, y: -1, r })}
            l${width - r * 2},0
            ${this.getTurn({ x: 1, y: 1, r })}
            l0,${height - r * 2}
            ${this.getTurn({ x: -1, y: 1, r })}
            l${-(width - r * 2)},0
            ${this.getTurn({ x: -1, y: -1, r })}`
        }
      }
      res += '" />'
      this.$set(this.svg, 'body', res)
    },
    makeSvgBody(start, finish) {
      const { clientWidth: width, clientHeight: height } = this.svg.content
      const bp = this.buttonPosition()
      const r = this.radius
      return `
        ${bp === 'r' ? '' : `l${width - start.x - r - this.margin},0`}
        ${bp !== 'r' ? this.getTurn({ x: 1, y: 1, r }) : ''}
        l0,${height - (bp === 'r' ? r : r * 2) - this.margin * 2}
        ${this.getTurn({ x: -1, y: 1, r })}
        l${-(width - r * 2 - this.margin * 2)},0
        ${this.getTurn({ x: -1, y: -1, r })}
        l0,${-(height - (bp === 'l' ? r : r * 2) - this.margin * 2)}
        ${bp !== 'l' ? this.getTurn({ x: 1, y: -1, r }) : ''}
        ${bp !== 'l' ? `L${finish.x},${finish.y}` : ''}
      `
    },
    getTurn: ({ x, y, r }) =>
      `q${!(x !== y) ? x * r : 0},${x !== y ? y * r : 0} ${x * r},${y * r}`,
    buttonPosition() {
      if (this.svg.button) {
        let num = this.svg.button.style.order
        switch (num % this.perRow) {
          case 0:
            return 'l'
          case this.perRow - 1:
            return 'r'
          default:
            return 'c'
        }
      }
    },
    handlerToggleShowContent(data) {
      this.showContent = !(
        this.model === data.name &&
        data.active &&
        this.showContent
      )
      this.raiseTouchedEvent()
    },
    toggleContentVisibility(buttonInstance) {
      // Using a very short timeout to avoid the handlerCloseOnBlur ovverriding this toggle action
      setTimeout(() => {
        this.handlerToggleShowContent({
          name: buttonInstance.name,
          active: buttonInstance.isActive
        })
      }, 10)
    },
    /*    handlerShowContent (e) {
          const relatedContent = e.currentTarget.parentElement.getElementsByClassName('extensible__content active')[0] || {}

          // Modify showContent only if the clicked button has any content
          if (e.currentTarget.classList.contains('active') &&
            (relatedContent.childElementCount && relatedContent.innerHTML !== '')) {
            this.showContent = !this.showContent
          }

          // Throw a button buttongroup-touched event
          this.raiseTouchedEvent()
        } */

    /* toggleContentVisibility (buttonInstance) {
      // Using a very short timeout to avoid the handlerCloseOnBlur ovverriding this toggle action
      setTimeout(() => {
        if (buttonInstance && buttonInstance.$el && buttonInstance.$el.classList.contains('active')) {
          this.showContent = !this.showContent
        }
      }, 10)

      // Throw a button buttongroup-touched event
      this.raiseTouchedEvent()
    },
     */

    raiseTouchedEvent() {
      // The simplest way to check if all buttons are closed, it's checking if a specific class is applied.
      // But class application might happen slightly after event is raised, so setting a short timeout for the class being applied.
      // TODO: Evaluate improvements
      this.$nextTick(() => {
        // const activeButtons = this.items && this.items.filter((item) => item && item.componentInstance && item.componentInstance.isActive)
        // const activeButton = activeButtons && activeButtons.length > 0 ? activeButtons[0].componentInstance : {}

        // const extRows = this.$el.getElementsByClassName('extensible__rows')[0] || {}
        // const allClosed = extRows.classList ? extRows.classList.contains('extensible__rows--hideContent') : false

        const activeButtons =
          this.itemsArr && this.itemsArr.filter((item) => item?.isActive)
        const activeButton =
          activeButtons && activeButtons.length > 0 ? activeButtons[0] : {}

        const extRows =
          this.$el.getElementsByClassName('extensible__rows')[0] || {}
        const allClosed = extRows.classList
          ? extRows.classList.contains('extensible__rows--hideContent')
          : false

        this.$emit('extensible-btn-group:touched', {
          allClosed: allClosed,
          selected: activeButton
        })
      })
    }
  }
}
</script>
<style lang="scss" src="./_extensibleButton.scss"></style>
