<template>
  <div class="feedback-question">
    <span
      v-if="question.header_text.trim().length > 0"
      class="feedback-question__header"
      >{{ question.header_text }}</span
    >
    <div>
      <span class="feedback-question__title">{{ question.title }}</span>
    </div>
    <div
      v-if="question.subtitle.trim().length > 0"
      class="feedback-question__subtitle"
    >
      {{ question.subtitle }}
    </div>

    <v-row>
      <v-col v-if="question.type === 'RADIO'">
        <ExtensibleButtonGroup :per-row="perRow" :model="radioModel">
          <feedback-radio-button
            v-for="answer in question.answers"
            :key="answer.id"
            :answer="answer"
            :selected="radioModel === answer.value"
            :on-click="
              () => {
                clickRadio(answer)
              }
            "
          ></feedback-radio-button>
        </ExtensibleButtonGroup>
        <v-row
          class="comment_area mt-6"
          v-if="showComment || showCommentHeader"
        >
          <v-col cols="12">
            <textarea
              :value="question.comment_value"
              @input="onCommentChanged($event.target.value)"
              style="
                resize: none;
                border-style: none;
                border-color: transparent;
              "
              :placeholder="question.comment_placeholder"
              persistent-placeholder
            >
            </textarea>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="question.type === 'CHECKBOX'">
        <ExtensibleButtonGroup :per-row="perRow">
          <feedback-checkbox
            v-for="answer in question.answers"
            :key="answer.id"
            :answer="answer"
            :is-max="isMax"
            :on-click="
              () => {
                clickCheckbox(answer)
              }
            "
          ></feedback-checkbox>
        </ExtensibleButtonGroup>
        <v-row
          class="comment_area mt-6"
          v-if="showComment || showCommentHeader"
        >
          <v-col cols="12">
            <textarea
              :value="question.comment_value"
              @input="onCommentChanged($event.target.value)"
              style="
                resize: none;
                border-style: none;
                border-color: transparent;
              "
              :placeholder="question.comment_placeholder"
              persistent-placeholder
            >
            </textarea>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="question.type === 'INTERVAL'">
        <ExtensibleButtonGroup :per-row="perRow">
          <feedback-slider
            v-for="answer in question.answers"
            :key="answer.id"
            :answer="answer"
            :onSliderChanged="onSliderChanged"
          ></feedback-slider>
        </ExtensibleButtonGroup>
        <v-row class="comment_area mt-6" v-if="showCommentHeader">
          <v-col cols="12">
            <textarea
              :value="question.comment_value"
              @input="onCommentChanged($event.target.value)"
              style="
                resize: none;
                border-style: none;
                border-color: transparent;
              "
              :placeholder="question.comment_placeholder"
              persistent-placeholder
            >
            </textarea>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="question.type === 'RATING'">
        <ExtensibleButtonGroup :per-row="perRow">
          <FeedbackRating
            v-for="answer in question.answers"
            :key="answer.id"
            :answer="answer"
            :onAnswerChanged="onAnswerChanged"
          ></FeedbackRating>
        </ExtensibleButtonGroup>
        <v-row class="comment_area mt-6" v-if="showCommentHeader">
          <v-col cols="12">
            <textarea
              :value="question.comment_value"
              @input="onCommentChanged($event.target.value)"
              style="
                resize: none;
                border-style: none;
                border-color: transparent;
              "
              :placeholder="question.comment_placeholder"
              persistent-placeholder
            >
            </textarea>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="question.type === 'DROPDOWN'">
        <ExtensibleButtonGroup :per-row="perRow">
          <FeedbackDropdown
            :answers="question.answers"
            :on-change="changeDropdown"
          ></FeedbackDropdown>
        </ExtensibleButtonGroup>
        <v-row class="comment_area mt-6" v-if="showCommentHeader">
          <v-col cols="12">
            <textarea
              :value="question.comment_value"
              @input="onCommentChanged($event.target.value)"
              style="
                resize: none;
                border-style: none;
                border-color: transparent;
              "
              :placeholder="question.comment_placeholder"
              persistent-placeholder
            >
            </textarea>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="question.type === 'RANKING'">
        <ExtensibleButtonGroup :per-row="perRow">
          <draggable :value="question.answers" v-bind="optionDrag">
            <transition-group class="d-flex flex-wrap w-100">
              <FeedbackRanking
                v-for="answer in question.answers"
                :key="answer.id"
                :answer="answer"
                :onSliderChanged="onSliderChanged"
              ></FeedbackRanking>
            </transition-group>
          </draggable>
        </ExtensibleButtonGroup>
        <v-row class="comment_area mt-6" v-if="showCommentHeader">
          <v-col cols="12">
            <textarea
              :value="question.comment_value"
              @input="onCommentChanged($event.target.value)"
              style="
                resize: none;
                border-style: none;
                border-color: transparent;
              "
              :placeholder="question.comment_placeholder"
              persistent-placeholder
            >
            </textarea>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="question.type === 'TEXT'">
        <FeedbackTextarea
          :question="question"
          :onAnswerChanged="onAnswerChanged"
          :text="question.comment_placeholder"
          :onCommentChanged="onCommentChanged"
        ></FeedbackTextarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FeedbackCheckbox from './FeedbackCheckbox'
import FeedbackSlider from './FeedbackSlider'
import FeedbackRating from './FeedbackRating'
import FeedbackRanking from './FeedbackRanking'
import FeedbackDropdown from './FeedbackDropdown'
import FeedbackRadioButton from './FeedbackRadioButton'
import FeedbackTextarea from './FeedbackTextarea'
import { checkMobile } from '../../js/utils'
import {
  CHECKBOX,
  RADIO,
  INTERVAL,
  DROPDOWN,
  RATING,
  RANKING
} from '../../js/constants'

import draggable from 'vuedraggable'

export default {
  name: 'FeedbackQuestion',
  components: {
    FeedbackCheckbox,
    FeedbackRadioButton,
    FeedbackSlider,
    FeedbackTextarea,
    FeedbackDropdown,
    FeedbackRating,
    FeedbackRanking,
    draggable
  },
  props: {
    question: { type: Object },
    onAnswerChanged: { type: Function },
    onCommentChanged: { type: Function },
    onSliderChanged: { type: Function }
  },
  data() {
    return {
      isMobile: checkMobile(),
      showComment: false,
      showCommentHeader: false,
      groupKey: 0,
      currentAnswer: {},
      radioModel: '',
      isMax: false
    }
  },
  methods: {
    clickRadio(answer) {
      this.question.answers.forEach((v) => {
        v.selected = v.id === answer.id
      })
      this.showComment = answer.comment
      this.radioModel = answer.value
      this.onAnswerChanged()
    },
    clickCheckbox(answer) {
      this.isMax =
        this.question.max_answers > 0 &&
        this.question.answers.filter((v) => v.selected).length ===
          this.question.max_answers
      this.showComment =
        this.question.answers.filter((v) => {
          return v.selected && v.comment
        }).length > 0
      this.onAnswerChanged()
    },
    changeDropdown(answer) {
      this.question.answers.forEach((v) => {
        v.selected = v.id === answer.id
      })
      this.onAnswerChanged()
    }
  },
  computed: {
    perRow() {
      return this.isMobile ? 1 : 2
    },
    optionDrag() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  updated() {
    if (this.question.type === RADIO) {
      const curAnswer = this.question.answers.find((v) => {
        return v.selected
      })
      this.showComment = curAnswer && curAnswer.comment
      this.radioModel = curAnswer ? curAnswer.value : ''
      this.showCommentHeader = this.question.comment
    }
    if (this.question.type === CHECKBOX) {
      this.isMax =
        this.question.max_answers > 0 &&
        this.question.answers.filter((v) => v.selected !== null).length ===
          this.question.max_answers
      this.showComment =
        this.question.answers.filter((v) => {
          return v.selected && v.comment
        }).length > 0
      this.showCommentHeader = this.question.comment
    }
    if (this.question.type === INTERVAL) {
      this.showCommentHeader = this.question.comment
    }
    if (this.question.type === DROPDOWN) {
      this.showCommentHeader = this.question.comment
    }
    if (this.question.type === RATING) {
      this.showCommentHeader = this.question.comment
    }
    if (this.question.type === RANKING) {
      this.showCommentHeader = this.question.comment
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input__slot {
  display: block !important;
}

.feedback-question {
  padding: 28px calc((100% - 1140px) / 2) 0;
  position: relative;
  width: 100%;
  height: 100%;

  &__title {
    font-size: 24px;
    font-weight: bold;
  }

  &__header {
    display: block;
    font-size: 14px;
    background: #f6f6f6;
    width: 100%;
    padding: 24px;
    border-radius: 20px;
    margin-bottom: 48px;
  }

  &__subtitle {
    margin-top: 24px;
  }
}

.slider-wrap {
  display: grid;
}

.comment_area {
  border-radius: 27px;
  border: 1px solid lightgrey;
  height: 100px;
  padding: 0px 10px;

  textarea {
    width: 100%;
    height: 100%;
  }
}

.extensible__rows div {
  width: 100%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .feedback-question {
    &__title {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
