const nativeLogEvent = (name, params) => {
  if (!name) {
    return
  }

  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params))
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.firebase
  ) {
    // Call iOS interface
    var message = {
      command: 'logEvent',
      name: name,
      parameters: params
    }
    window.webkit.messageHandlers.firebase.postMessage(message)
  } else {
    // No Android or iOS interface found
    console.error('No native APIs found.')
  }
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    checkupTrackTapEvent(ctx, event) {
      let eventName, params
      const isWeb = ctx.rootState.checkup.isWeb

      if (typeof event === 'string') {
        eventName = event
        if (eventName !== 'Checkup Energetico') {
          params = {
            survey_type: ctx.rootState.checkup.metodologiaCalcolo
          }
        }
      } else if (typeof event === 'object') {
        eventName = event.name
        params = event.params || {}
      } else {
        return Error('Invalid parameters')
      }

      if (isWeb) {
        parent.postMessage(
          {
            postMessageEvent: 'checkup-trackTapEvent',
            params: { name: eventName, params }
          },
          '*'
        )
      } else {
        nativeLogEvent(eventName, params)
      }
    }
  }
}
