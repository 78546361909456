import { axiosACN } from '../../axiosInstances'

export default class AbnormalConsuption {
  static retrieveAbnormalConsumption(clientsIds, token) {
    try {
      return axiosACN
        .post(
          '/bollette/V2/retrieveAbnormalConsumption ',
          {
            listaClienti: clientsIds
          },
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
        .then((res) => {
          return res
        })
    } catch (err) {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    }
  }
}
