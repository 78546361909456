const breadcrums = {
  computed: {
    breadcrumbsItems() {
      let res = []
      if (this.$route.meta && this.$route.meta.breadcrumbs)
        res = [...res, ...this.$route.meta.breadcrumbs]
      if (this.$route.params.breadcrumbs)
        res = [...res, ...this.$route.params.breadcrumbs]
      return res
    }
  }
}

export default breadcrums
