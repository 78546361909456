import { axiosCMS } from '@/js/axiosInstances.js'

const getContextualHelpById = (listCH, id) => {
  return listCH.find((ch) => ch.id === id)
}

export default {
  namespaced: true,
  state: {
    contextualHelp: null
  },
  getters: {
    contextualHelp(state) {
      return state.contextualHelp
    }
  },
  mutations: {
    setContextualHelp(state, contextualHelp) {
      state.contextualHelp = contextualHelp
    }
  },
  actions: {
    getContextualHelp({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        if (state.contextualHelp) {
          resolve(getContextualHelpById(state.contextualHelp, id))
        } else {
          axiosCMS
            .get('/aiuti-contestuali')
            .then((resp) => {
              commit('setContextualHelp', resp.data)
              resolve(getContextualHelpById(resp.data, id))
            })
            .catch((err) => {
              console.error(err)
            })
        }
      })
    }
  }
}
