<template>
  <div class="stepFlow">
    <div class="step">
      <div class="label-step complete">OFFERTA</div>
      <div :class="`circle ${customClass}`">
        <span>1</span>
      </div>
      <v-progress-linear
        :value="value"
        color="#5EB83D"
        background-color="transparent"
      ></v-progress-linear>
    </div>
    <div class="step">
      <div class="label-step">DATI</div>
      <div class="circle">
        <span>2</span>
      </div>
      <v-progress-linear
        :value="0"
        color="#5EB83D"
        background-color="transparent"
      ></v-progress-linear>
    </div>
    <div class="step">
      <div class="label-step">CONFERMA</div>
      <div class="circle">
        <span>3</span>
      </div>
      <v-progress-linear
        :value="0"
        color="#5EB83D"
        background-color="transparent"
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style></style>
