<template>
  <div class="pdr-archive">
    <div>
      <v-row
        align="center"
        v-for="(pdr, i) in pdrList"
        :key="i"
        @click="
          goToPdrDetails(pdr, $event),
            trackTapEvent({
              name: 'supplyDetail_PDRCard_tap'
            })
        "
      >
        <v-col
          class="std-panel std-panel--shadowed pa-0"
          :class="{
            notRegular:
              countScadutaInstalments(pdr) > 0 && !checkInVerifica(pdr)
          }"
        >
          <v-row
            no-gutters
            class="bills__iteams_container"
            :class="
              pdr.planStatus === Consts.STATUS_PDR.CANCELED
                ? 'pdr-cancelled'
                : ''
            "
            :disabled="pdr.planStatus === Consts.STATUS_PDR.CANCELED"
          >
            <v-col class="bills__iteams col-md-9 px-8 py-8">
              <v-row no-gutters class="bills__item-bold mb-6">
                <v-col>
                  <h3 class="HeadingsTitleL">Piano di rientro</h3>
                </v-col>
              </v-row>
              <v-row no-gutters class="bills__item mb-2">
                <v-col class="col-md-3">
                  <h5 class="BodyM">Data inizio</h5>
                </v-col>
                <v-col class="col-md-3">
                  <h5 class="BodyM">Data fine</h5>
                </v-col>
                <v-col class="col-md-3">
                  <h5 class="BodyM">Stato pagamento</h5>
                </v-col>
                <v-col class="col-md-3">
                  <h5 class="BodyM">Importo</h5>
                </v-col>
              </v-row>
              <v-row no-gutters class="bills__item">
                <v-col class="col-md-3">
                  <h5 class="BodyL-Strong">
                    {{ $filters.formatDateSplitJoin(pdr.planStartDate) }}
                  </h5>
                </v-col>
                <v-col class="col-md-3">
                  <h5 class="BodyL-Strong">
                    {{ $filters.formatDateSplitJoin(pdr.planEndDate) }}
                  </h5>
                </v-col>
                <v-col class="col-md-3">
                  <h5
                    class="BodyL-Strong color--purple"
                    v-if="
                      countScadutaInstalments(pdr) > 0 && !checkInVerifica(pdr)
                    "
                  >
                    {{
                      countScadutaInstalments(pdr) > 1
                        ? countScadutaInstalments(pdr) + ' RATE SCADUTE'
                        : countScadutaInstalments(pdr) + ' RATA SCADUTA'
                    }}
                  </h5>
                  <h5
                    v-else-if="pdr.planStatus === Consts.STATUS_PDR.ACTIVE"
                    class="BodyL-Strong status"
                  >
                    IN CORSO
                  </h5>
                  <h5 v-else class="BodyL-Strong status">
                    {{ Consts.TRANSLATE_STATUS_PDR[pdr.planStatus] }}
                  </h5>
                </v-col>
                <v-col class="col-md-3">
                  <h5 class="HeadingsTitleL-Upper">
                    €
                    {{ countAmountInstalments(pdr) }}
                  </h5>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="bills__link col-md-3 d-flex justify-center align-center flex-column"
            >
              <a
                target="_blank"
                class="d-flex align-center justify-start"
                @click="goToPdrDetails(pdr)"
              >
                <h5 class="ButtonL color--blue">Dettaglio piano di rientro</h5>
              </a>
              <v-btn
                v-if="pdr.planStatus === Consts.STATUS_PDR.ACTIVE"
                class="mt-10 resize-btn"
                color="primary"
                x-large
                text
                @click.stop.prevent="
                  openModal(pdr),
                    trackTapEvent({
                      name: 'payment_started',
                      params: {
                        service_type: supply.serviceType.toLowerCase(),
                        component: 'card',
                        payment_item: 'pdr',
                        screen_name:
                          'MySorgenia - Riepilogo e Archivio Pagamenti'
                      }
                    })
                "
                :disabled="!pdr.isPossibleToPay"
              >
                <h5 class="ButtonL">Paga rate</h5>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <DialogPagaRate
      v-if="Object.keys(pdrSelected).length > 0"
      v-model="dialogPayRatePdr"
      :source="source"
      :pdrAttivo="pdrSelected"
      :clientDetails="clientDetails"
      :intestatario="getIntestatario()"
      :dialogKey="pdrSelected.planId"
    ></DialogPagaRate>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DialogPagaRate from '../../views/pdr/DialogPagaRate'
import Consts from '@/js/constants'
import { getTotal } from '../../js/utils'

export default {
  name: 'pdrList',
  props: {
    source: String,
    supply: Object,
    pdrList: Array
  },
  data() {
    return {
      Consts: Consts,
      dialogPayRatePdr: false,
      detail: {},
      getTotal: getTotal,
      pdrSelected: {}
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),

    ...mapActions('bill', [
      'checkBillPaid',
      'getComputedBillStatus',
      'computeShowPaymentButton'
    ]),
    ...mapActions('payment', 'getPdrInfo'),
    ...mapActions('analytics', ['trackTapEvent']),

    countAmountInstalments(data) {
      let somma = data.instalments.reduce((sum, instalment) => {
        return sum + (isNaN(instalment.amount) ? 0 : instalment.amount)
      }, 0)
      return this.getTotal(somma)
    },

    countScadutaInstalments(data) {
      if (data && data.instalments) {
        const scadutaInstalments = data.instalments.filter(
          (instalment) =>
            instalment.instalmentStatus === Consts.STATUS_PDR.EXPIRED
        )
        return scadutaInstalments.length
      }

      return 0
    },

    checkInVerifica(data) {
      return data.instalments.find((rata) => rata.checkPaymentInstalment)
    },

    getIntestatario() {
      let intestatario = ''
      if (this.clientDetails.clientType === 'BUS') {
        intestatario = this.clientDetails.businessName
      } else {
        intestatario =
          this.clientDetails.name + ' ' + this.clientDetails.surname
      }
      return intestatario
    },

    openModal(pdr) {
      this.trackTapEvent({
        name: 'supplyDetail_PDRpayment_tap'
      })
      this.pdrSelected = pdr
      this.dialogPayRatePdr = true
    },
    goToPdrDetails(details, event) {
      this.trackTapEvent({
        name: 'supplyDetail_PDRCard_tap'
      })
      if (details.planStatus !== Consts.STATUS_PDR.CANCELED) {
        this.$router.push({
          name: 'pdrDetail',
          params: {
            clientOwnerCode: this.supply.clientOwnerCode,
            supplyCode: this.supply.supplyCode,
            planId: details.planId
          }
        })
      }
    }
  },
  components: {
    DialogPagaRate
  }
}
</script>
<style lang="scss" scoped>
.std-panel {
  cursor: pointer;
  &.notRegular {
    border: 1px solid $srg-purple;
  }
}
.BodyL-Strong.status {
  text-transform: uppercase;
}
.resize-btn {
  height: auto !important;
  &:hover,
  &:focus &:active {
    &::before {
      opacity: 0;
    }
  }
}
.pdr-cancelled {
  background: $neutri-neutral-10;
  .BodyL-Strong.status {
    color: $neutri-neutral-50;
    //$neutri-neutral-40
  }
  .ButtonL {
    color: $neutri-neutral-40;
    cursor: not-allowed;
  }
}
</style>
