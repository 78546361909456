<template>
  <div>
    <TcVars />
    <v-dialog v-model="dialog.show" max-width="650">
      <v-row>
        <v-col class="self-dialog">
          <img
            @click="dialog.show = false"
            src="../../../../assets/ic_chevron_top_black.png"
          />
          <h2>Dove trovo i numeri da inserire?</h2>
          <h5 v-html="dialog.text" />
          <img class="contatore" :src="require('@/assets/' + dialog.img)" />
        </v-col>
      </v-row>
    </v-dialog>
    <v-row class="std-side-padding">
      <v-col>
        <h1 class="self-reading__title">Autolettura</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row class="std-side-padding mb-12">
          <v-col>
            <h3 class="self-reading__subtitle">Ultima lettura disponibile</h3>
            <span class="last-reading__source"
              >{{ lastReading.descrizioneFonte }}
              <span v-if="validDate(lastReading.dataLettura)"
                >-
                {{
                  $filters.formatDateWithSeparator(lastReading.dataLettura)
                }}</span
              ></span
            >
            <template v-if="details.serviceType === 'Ele'">
              <span class="last-reading__value ml-12"
                >F1: <strong>{{ lastReading.lettura1 }}</strong></span
              >
              <span class="last-reading__value ml-9"
                >F2: <strong>{{ lastReading.lettura2 }}</strong></span
              >
              <span class="last-reading__value ml-9"
                >F3: <strong>{{ lastReading.lettura3 }}</strong></span
              >
            </template>
            <template v-if="details.serviceType === 'Gas'">
              <span class="last-reading__value ml-12"
                >Gas: <strong>{{ lastReading.lettura1 }}</strong></span
              >
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="std-side-padding" no-gutters>
      <v-col>
        <v-row>
          <v-col class="d-flex justify-lg-space-between align-center mb-6">
            <h1 class="self-reading__subtitle">Inserisci i numeri</h1>
            <div @click="populateDialog()" class="self-reading__question">
              Dove trovo i numeri da inserire?
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <template v-if="details.serviceType === 'Ele'">
      <v-row class="std-side-padding last-reading">
        <v-col>
          <v-row>
            <v-col class="d-flex justify-lg-space-between align-center">
              <v-text-field
                type="number"
                label="F1"
                class="auth__input"
                v-model.trim="readings.f1"
                @blur="v$.readings.f1.$touch()"
                :error-messages="errorsF1"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                label="F2"
                class="auth__input"
                v-model.trim="readings.f2"
                @blur="v$.readings.f2.$touch()"
                :error-messages="errorsF2"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                label="F3"
                class="auth__input"
                v-model.trim="readings.f3"
                @blur="v$.readings.f3.$touch()"
                :error-messages="errorsF3"
              ></v-text-field>
            </v-col>
            <v-col class="text-center offset-3">
              <v-btn
                color="primary"
                large
                width="250px"
                class="v-btn--shadowed"
                :rounded="true"
                @click="sendReadings"
                :loading="sending"
                :disabled="v$.readings.$invalid"
              >
                Invia
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template v-if="details.serviceType === 'Gas'">
      <v-row class="std-side-padding last-reading">
        <v-col>
          <v-row class="d-flex justify-lg-space-between align-center">
            <div>
              <h4>
                <strong>Inserisci le cifre che precedono la virgola</strong>
              </h4>
              <v-text-field
                type="number"
                v-model.trim="readingsGas.f1"
                :max-length="details.clientOwnerType === 'RES' ? 7 : 9"
                @blur="v$.readingsGas.f1.$touch()"
                :error-messages="errorsF1"
              ></v-text-field>
            </div>
            <div>
              <v-btn
                color="primary"
                large
                width="250px"
                class="v-btn--shadowed"
                :rounded="true"
                @click="sendReadings"
                :loading="sending"
                :disabled="v$.readingsGas.$invalid"
              >
                Invia
              </v-btn>
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <div class="std-side-padding mt-8 infoPhoto">
          In alternativa, scatta una fotografia al tuo contatore ed inviacela
          tramite App MySorgenia
        </div>
      </v-row>
    </template>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import { getRequiredValidationError, validDate } from '@/js/utils'
import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import { mapMutations } from 'vuex/dist/vuex.esm.browser'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: ['details', 'lastReading', 'isAcea', 'enteredReadings'],
  name: 'selfReadingLight_Base',
  data() {
    return {
      sending: false,
      readings: {
        f1: this.enteredReadings ? this.enteredReadings.f1 : '',
        f2: this.enteredReadings ? this.enteredReadings.f2 : '',
        f3: this.enteredReadings ? this.enteredReadings.f3 : ''
      },
      readingsGas: {
        f1: this.enteredReadings ? this.enteredReadings.f1 : ''
      },
      validDate: validDate,
      getSupplyTrackingParams,
      dialog: {
        show: false,
        text: '',
        img: 'contatoreluce-web.png'
      }
    }
  },
  components: {
    TcVars
  },
  computed: {
    errorsF1() {
      return getRequiredValidationError(this.v$.readings, 'f1')
    },
    errorsF2() {
      return getRequiredValidationError(this.v$.readings, 'f2')
    },
    errorsF3() {
      return getRequiredValidationError(this.v$.readings, 'f3')
    }
  },
  methods: {
    ...mapActions('supply', ['getDetail', 'selfReading']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('alerts', ['resetState']),
    sendReadings() {
      this.trackTapEvent({
        name: 'selfReading_send_tap',
        params: getSupplyTrackingParams(this.details)
      })
      this.$emit(
        'send-values',
        this.details.serviceType === 'Ele' ? this.readings : this.readingsGas
      )
      this.resetState()
      this.sending = true
    },
    populateDialog() {
      let text = ''
      if (this.details.serviceType === 'Ele') {
        text =
          '<strong>Premi il pulsante</strong> sul tuo contatore luce per visualizzare i numeri relativi ai consumi nelle fasce <strong>F1</strong>, <strong>F2</strong> ed <strong>F3</strong>. Inserisci i numeri nei rispettivi campi e premi invia per comunicare la lettura.'
        if (this.isAcea) {
          text =
            '<strong>Premi il pulsante</strong> sul tuo contatore luce per visualizzare i numeri relativi ai consumi nelle fasce <strong>F1</strong>, <strong>F2</strong>, <strong>F3</strong> ed <strong>F4</strong>. Ricordati di <strong>sommare</strong> le fasce <strong>F3</strong> ed <strong>F4</strong> ed inserirle nel campo <strong>F3</strong>. Inserisci i numeri nei rispettivi campi e premi invia per comunicare la lettura.'
        }
      } else {
        text =
          'Visualizza sullo schermo del tuo contatore gas i <strong>numeri relativi ai consumi</strong>. Inserisci solo le cifre che si trovano <strong>prima della virgola</strong> (caselle a sfondo nero) e premi invia per comunicare la lettura.'
      }
      this.dialog = {
        show: true,
        text: text,
        img:
          this.details.serviceType === 'Ele'
            ? 'contatoreluce-web.png'
            : 'contatoregas-web.png'
      }
    }
  },
  validations: {
    readings: {
      f1: { required },
      f2: { required },
      f3: { required }
    },
    readingsGas: {
      f1: { required }
    }
  }
}
</script>
<style lang="scss" scoped>
.infoPhoto {
  font-size: 20px;
}
.v-dialog__content {
  .self-dialog {
    background: white;
    padding: 60px 43px 32px 48px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    h4 {
      span {
        font-weight: bold;
      }
    }
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      &.contatore {
        margin-top: 24px;
        position: initial;
      }
    }
  }
  .self-reading-dialog {
    p {
      font-size: 20px;
      color: black;
    }

    .dialog__img {
      cursor: pointer;
      margin-top: 20px;
      margin-right: 20px;
    }
  }
}
</style>
