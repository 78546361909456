<template>
  <layout header-size="small" headerWithBgImg>
    <template v-slot:header>
      <v-col cols="6" class="img-girl pa-0 img-girl-reward" />
      <v-col cols="5" class="pl-10 d-flex align-center">
        <h1 class="pt-2">Grazie per aver<br />partecipato!</h1>
      </v-col>
    </template>
    <div class="reward">
      <v-row>
        <v-spacer />
        <v-col cols="6">
          <h1 class="reward__title" v-text="mission.end_page_title" />
          <div v-html="mission.end_page_text" />
        </v-col>
        <v-col cols="5">
          <MissionReward :steps="1" :coins="coins" />
        </v-col>
      </v-row>
      <v-row class="reward__actions">
        <v-spacer />
        <v-col cols="6">
          <v-btn
            x-large
            class="v-btn--shadowed"
            @click="
              $router.push({ name: goTo(mission.first_app_section_section) })
            "
          >
            {{ mission.first_cta_label }}
          </v-btn>
        </v-col>
        <v-col cols="5" class="text-right">
          <v-btn
            x-large
            color="brightGreen"
            class="v-btn--shadowed"
            @click="
              $router.push({ name: goTo(mission.second_app_section_section) })
            "
          >
            {{ mission.second_cta_label }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'
import { APP_SECTION_MAPPING } from '@/js/constants'

export default {
  name: 'videoRewardPage',
  components: {
    Layout: () =>
      import(
        /* webpackChunkName: "components/loyalty/layout" */ '@/views/loyalty/Layout'
      ),
    MissionReward: () =>
      import(
        /* webpackChunkName: "components/loyalty/missionReward" */ '@/views/loyalty/components/mission/missionReward'
      )
  },
  data: () => ({
    mission: {},
    coins: 0,
    APP_SECTION_MAPPING: APP_SECTION_MAPPING
  }),
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    goTo(id) {
      var destination = this.APP_SECTION_MAPPING[id]
      // var destination
      // switch (id) {
      //   case '/loyalty-diary-0':
      //     destination = 'loyaltyPage'
      //     break
      //   case '/loyalty-catalog-0':
      //     destination = 'catalogue'
      //     break
      //   default:
      //     destination = 'loyaltyPage'
      // }

      this.trackTapEvent({
        name: 'greeners_missionThankYouPageCTA_tap',
        params: { app_section: destination }
      })

      return destination
    }
  },
  created() {
    if (!this.$route.params.mission) {
      this.$router.push({ name: 'loyaltyPage' })
    }
  },
  beforeMount() {
    this.mission = this.$route.params.mission
    this.coins = Number(this.$route.params.coins) || 0
  }
}
</script>
