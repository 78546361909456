<template>
  <v-dialog v-model="dialog" max-width="550" :persistent="true">
    <div class="bidgelyDialog">
      <img @click="closeDialog" src="@/assets/ic_chevron_top_black.png" />
      <template v-if="!loader">
        <template v-if="clients.length > 0">
          <div class="bidgelyDialog__title">Seleziona indirizzo</div>
          <v-row
            v-for="(client, i) in clients"
            :key="i"
            class="bidgelyDialog__client"
          >
            <v-col>
              <div>
                <span
                  class="bidgelyDialog__account-header"
                  :class="{
                    'icon-res': client.clientOwnerType === 'RES',
                    'icon-bus': client.clientOwnerType !== 'RES'
                  }"
                >
                  {{
                    client.clientOwnerType === 'RES'
                      ? client.firstName + ' ' + client.lastName
                      : $filters.capitalize(client.businessName)
                  }}
                </span>
              </div>
              <v-row
                v-for="supply in client.clientSupply"
                class="bidgelyDialog__account-supply"
                @click="checkBidgely(client, supply)"
                :key="supply.supplyCode"
              >
                <span class="bidgelyDialog__account-supply-icon-ref">
                  {{ $filters.capitalize(supply.street) }},
                  {{ $filters.capitalize(supply.city) }}
                </span>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div class="bidgelyDialog__title">Nessuna fornitura luce</div>
        </template>
      </template>
      <template v-else>
        <div class="bidgelyDialog__title">
          {{ loaderDetail }}
        </div>
        <div class="bidgelyDialog__loader">
          <v-progress-circular :size="100" indeterminate color="#12256A" />
        </div>
      </template>
    </div>
  </v-dialog>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters } from 'vuex'
import CheckBidgelyVisibility from '../../js/service/checkBidgelyVisibility'
import Constants from '@/js/constants'

export default {
  props: ['showDialog'],
  name: 'BidgelyEleDialog',
  data() {
    return {
      dialog: false,
      loader: true,
      loaderDetail: 'Caricamento dati ...',
      inAction: false,
      clients: [],
      SERVICE_TYPE: Constants.SERVICE_TYPE
    }
  },
  computed: {
    ...mapGetters('account', ['accountClients'])
  },
  watch: {
    showDialog(value) {
      this.dialog = value
      if (
        value &&
        this.clients.length === 1 &&
        this.clients[0].clientSupply.length === 1
      ) {
        this.checkBidgely(this.clients[0], this.clients[0].clientSupply[0])
      }
    }
  },
  methods: {
    ...mapActions('account', ['getClientsForAccount', 'getDetailForClient']),
    ...mapActions('supply', ['getSupplies', 'getDetail']),
    async checkBidgely(client, supply) {
      if (this.inAction) {
        return
      }
      try {
        this.inAction = true
        this.loader = true
        const detail = await this.getDetailForClient({
          clientOwnerCode: client.clientOwnerCode
        })
        if (Object.keys(this.accountClients).length === 1) {
          client.mainRole = '1'
        }
        const supplyDetail = await this.getDetail({
          clientOwnerCode: client.clientOwnerCode,
          supplyCode: supply.supplyCode
        })
        const checkBidgelyVisibility = new CheckBidgelyVisibility(
          detail,
          supplyDetail
        )
        if (
          checkBidgelyVisibility.isBidgely(
            supplyDetail.activationDate,
            client.mainRole
          )
        ) {
          this.closeDialog()
          this.$router.push({
            name: 'supplyBidgely',
            params: {
              clientOwnerCode: client.clientOwnerCode,
              supplyCode: supplyDetail.supplyCode
            }
          })
        } else {
          this.closeDialog()
          this.$router.push({
            name: 'supplyDetail',
            params: {
              clientOwnerCode: client.clientOwnerCode,
              supplyCode: supplyDetail.supplyCode
            }
          })
        }
        this.inAction = false
      } catch (error) {
        GenErr.showErrorMessage()
        GenErr.handleGenericError(error && error.message)
      }
    },
    closeDialog() {
      this.loader = false
      this.inAction = false
      this.dialog = false
      this.$emit('BidgelyEleDialogClose')
    }
  },
  async mounted() {
    this.loader = true
    const supply = await this.getSupplies()
    this.getClientsForAccount().then((res) => {
      for (const value in res) {
        const clientSupply = supply.filter(
          (item) =>
            item.clientOwnerCode === value &&
            item.serviceType === this.SERVICE_TYPE.ELE &&
            item.clientOwnerType !== 'BUS'
        )
        if (clientSupply.length > 0) {
          const client = { ...res[value], clientSupply: clientSupply }
          // if (client.clientOwnerType === 'BUS') {
          //   this.clients.push(client)
          // }
          if (client.clientOwnerType === 'RES') {
            this.clients.push(client)
          }
        }
      }
      this.loader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.bidgelyDialog {
  background: white;
  padding: 48px;
  position: relative;

  &__title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  &__account {
    &-header {
      font-size: 16px;
      font-weight: bold;
      line-height: 34px;
      max-width: 95%;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding-right: 35px;

      &:after {
        content: url('~@/assets/icons/ic-owner-res-black.svg');
        display: block;
        width: 34px;
        height: 34px;
        position: absolute;
        top: -5px;
        right: -11px;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 10%
        );
        z-index: 9;
      }

      &.icon-bus:after {
        content: no-close-quote;
        background: url('~@/assets/icons/business_black.svg') no-repeat;
        background-size: auto 100%;
        height: 24px;
        right: -5px;
      }
    }

    &-supply {
      border: solid 1px lightgray;
      border-radius: 15px;
      padding-left: 50px;
      margin: 20px 0;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 60px;
      position: relative;
      cursor: pointer;

      &-icon-ref {
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:after {
          content: url('~@/assets/ic_chevron_dx_black.svg');
          display: block;
          width: 34px;
          height: 36px;
          position: absolute;
          right: -5px;
          top: 3px;
          z-index: 9;
        }

        &:before {
          content: '';
          background-image: url('~@/assets/icons/ic-light-blu.svg');
          background-size: contain;
          display: block;
          width: 34px;
          height: 34px;
          position: absolute;
          right: 382px;
          top: 12px;
          z-index: 9;
        }
      }
    }
  }

  &__loader {
    background: white;
    padding: 48px;
    text-align: center;
  }
}
</style>
