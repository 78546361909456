var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',{attrs:{"header-size":"small"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":"6"}},[_c('button',{staticClass:"loyalty__header-back",attrs:{"type":"button"},on:{"click":function($event){_vm.trackTapEvent({
            name: 'greeners_mission_close',
            params: {
              client_code: _vm.mainClientLoyaltySelected.clientOwnerCode,
              client_type: _vm.mainClientLoyaltySelected.clientOwnerType,
              mission_id: _vm.mission.id
            }
          }),
            _vm.$router.push({ name: 'loyaltyPage' })}}},[_c('v-icon',{attrs:{"color":"#12256A","size":"20"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v("Torna al Diario")])],1),_c('h1',{domProps:{"textContent":_vm._s(_vm.mission && _vm.mission.welcome_page_title)}})]),_c('v-spacer'),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"5"}},[_c('v-img',{attrs:{"contain":"","width":"290","src":require("@/assets/greeners/svg/logoGreeners_new.svg")}})],1)]},proxy:true}])},[_c('div',{staticClass:"loyalty-mission mb-10"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"loyalty-mission__content",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"loyalty-mission__title"},[_c('img',{attrs:{"src":_vm.IconVideo,"width":"35"}}),_c('p',[_vm._v(" VIDEO - SCADE IL "),_c('span',{staticClass:"date",domProps:{"textContent":_vm._s(_vm.expirationDate)}})])])],1),_c('v-row',[_c('v-col',{staticClass:"loyalty-mission__text",attrs:{"cols":"12"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.mission && _vm.mission.welcome_page_text)}})]),_c('v-col',[_c('MissionCoins',{attrs:{"coins":_vm.missionReward,"steps":1}})],1)],1)],1),_c('v-col',{staticClass:"loyalty-mission__player",attrs:{"cols":"6"}},[(_vm.mission)?_c('VideoPlayer',{staticClass:"mb-12",attrs:{"videoUrl":_vm.mission.video,"plyrSeekDisable":true},on:{"ended":function($event){_vm.isEnableToContinue = true}}}):_vm._e(),_c('v-btn',{staticClass:"v-btn--shadowed confirm",attrs:{"color":"brightGreen","x-large":"","outlined":!_vm.isEnableToContinue,"disabled":!_vm.isEnableToContinue},on:{"click":_vm.onSubmit}},[_c('span',{domProps:{"textContent":_vm._s(_vm.mission.welcome_page_button_label)}})])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }