<template>
  <div class="tutorial" v-if="tutorial">
    <v-row v-if="!isMobile">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbsItems"
          divider=">"
          class="pb-0 itemsWhite"
        >
          <template v-slot:divider>
            <img src="@/assets/icons/chevron-dx-white.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row class="tutorial__header">
      <v-col md="6" cols="12" sm="6" class="align-self-center">
        <h1 class="tutorial__title" v-html="tutorial.inner_title"></h1>
        <h2 class="tutorial__subtitle" v-html="tutorial.intro_text"></h2>
      </v-col>
      <v-col
        cols="12"
        v-if="isSmallDevice"
        class="d-flex justify-center align-center"
      >
        <img :src="require('@assets/welcome/icChevronDownWhiteBig.png')" />
      </v-col>
      <v-col md="6" cols="12" sm="6" class="tutorial__img">
        <img
          :src="
            isSmallDevice
              ? cdn + tutorial.inner_image_mobile || tutorial.inner_image
              : cdn + tutorial.inner_image
          "
        />
      </v-col>
    </v-row>
    <v-row class="tutorial__carousel">
      <WelcomeCarousel name="tutorial" :slides="slides" :is-mobile="isMobile" />
    </v-row>
  </div>
</template>
<script>
import { chunkArray, checkMobile } from '@/js/utils.js'
import WelcomeCarousel from '../../components/welcomeTutorial/WelcomeCarousel'
import { mapGetters, mapActions } from 'vuex'
import { ENV_CONFIGS } from '@/js/configs'

export default {
  name: 'tutorialComponent',
  components: {
    WelcomeCarousel
  },
  data() {
    return {
      cdn: ENV_CONFIGS.CMS_CDN_BASE_URL,
      isMobile: checkMobile(),
      isSmallDevice: window.innerWidth <= 480,
      isBigDevice: window.innerWidth > 480 && window.innerWidth <= 1024,
      isDesktop: window.innerWidth > 1024
    }
  },
  computed: {
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['tutorial', 'tutorialSlidesForUser']),
    slides() {
      let allSlides = this.tutorialSlidesForUser || []

      let slidePerPage = this.isSmallDevice ? 1 : 2
      let chunkedSlides = chunkArray(allSlides, slidePerPage)
      let slides = []

      chunkedSlides.forEach((pageSlides) => {
        slides.push({ slide: [...pageSlides] })
      })

      return slides
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Tutorial' }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('feedback', ['loadSurvey', 'loadUndoneSurvey'])
  },
  async created() {
    await this.loadSurvey()
    await this.loadUndoneSurvey()
    await this.$store.dispatch('welcomekit/getTutorialByUserProfile', {
      client: this.client
    })
  },
  watch: {
    client: function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$store.dispatch('welcomekit/getTutorialByUserProfile', {
          client: this.client
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tutorial {
  color: white;
  padding: 28px calc((100% - 1140px) / 2) 0;
  position: relative;
  z-index: 100;
  background-color: $srg-blue;
  height: 100%;
  &__header {
    position: relative;
    margin-bottom: 71px;
    h1 {
      font-size: 60px;
      font-weight: bold;
      line-height: 70px;
      margin-bottom: 48px;
      span {
        color: black;
      }
    }
    h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 48px;
      /*max-width: 242px;*/
    }
    h3 {
      font-size: 20px;
    }
    .close {
      position: absolute;
      top: 40px;
      right: 20px;
      cursor: pointer;
    }
  }
  &__img {
    img {
      width: 490px;
      /*border-radius: 50%;*/
    }
  }
  &__carousel {
    h3,
    h6 {
      color: black;
    }
    h3 {
      line-height: 26px;
    }
    h6 {
      font-size: 14px;
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .tutorial {
    overflow: hidden;
    position: relative;
    .col-sm-12 {
      padding-left: 32px;
      padding-right: 32px;
    }
    &__header {
      h1 {
        width: 250px;
        font-size: 39px;
        line-height: 45px;
        margin-bottom: 19px;
        margin-top: 90vw;
        p {
          color: white !important;
        }
      }
      h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      h3 {
        font-size: 16px;
        line-height: 22px;
      }
      .close {
        position: absolute;
        top: 50px;
        right: 18px;
        cursor: pointer;
      }
    }
    &__img {
      img {
        position: absolute;
        top: -40px;
        right: -5px;
        width: 90vw;
        max-width: 555px;
      }
    }
    .v-card {
      height: 386px;
    }
  }
}
@media (min-width: 481px) and (max-width: 1024px) {
  .tutorial {
    &__header {
      h1 {
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 24px;
        margin-bottom: 30px;
      }
      h3 {
        font-size: 18px;
      }
    }
    overflow: hidden;
    position: relative;
    padding: 28px 40px;
    &__img {
      img {
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.tutorial__header {
  span {
    color: white;
  }
  p {
    color: white;
  }
}

.tutorial {
  .v-window__next,
  .v-window__prev {
    top: 35%;
  }
  .v-btn--icon.v-size--default {
    height: 62px !important;
    width: 62px !important;
    background: rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  html {
    min-width: initial !important;
    max-width: 100vw !important;
  }
  .v-carousel__controls {
    background: initial;
    .v-btn--icon.v-size--small {
      height: 16px;
      width: 16px;
      margin: 0 3px;
      opacity: 0.5;
      &.v-item--active {
        opacity: 1;
      }
    }
    .v-btn:before {
      background-color: initial;
    }
    .mdi-minus::before {
      font-size: 25px;
      font-weight: bold;
    }
  }
}
@media (min-width: 481px) and (max-width: 1024px) {
  html {
    min-width: initial !important;
    max-width: 100vw !important;
  }
}
</style>
