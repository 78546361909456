import { ENERGY_DIRECTION } from '@/js/constants.js'

// Calcola il totale dei valori
const sumValues = (obj) => {
  return obj.reduce((sum, item) => sum + item.value, 0)
}

//calcola la media delle percentuali
const averagePercentage = (sum, length) => {
  sum / length
}
//Definisce le categorie in base alla granularità
const defineCategories = (granularity) => {
  let categorie = []
  switch (granularity) {
    case 'Giorno':
      categorie = [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ]
      break
    case 'Settimana':
      categorie = [
        'Lunedì',
        'Martedì',
        'Mercoledì',
        'Giovedì',
        'Venerdì',
        'Sabato',
        'Domenica'
      ]
      break
    case 'Mese':
      categorie = Array.from({ length: 31 }, (_, i) => i + 1)
      break
    case 'Anno':
      categorie = [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
      ]
      break
    case 'Dall inizio':
      categorie = [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
      ]
      break
    default:
      break
  }
  return categorie
}
//Definisce la larghezza delle colonne in base alla granularità
const defineColumnWidth = (granularity) => {
  let larghezzaColonne = 0
  switch (granularity) {
    case 'Giorno':
      larghezzaColonne = 15
      break
    case 'Settimana':
      larghezzaColonne = 76
      break
    case 'Mese':
      larghezzaColonne = 15
      break
    case 'Anno':
      larghezzaColonne = 51
      break
    case 'Dall inizio':
      larghezzaColonne = 51
      break
    default:
      break
  }
  return larghezzaColonne
}

//Metodo che trasla le colonne nel grafico in caso di richiesta con startDate antecedente all'attivazione
const defineArrayValues = (granularity, values) => {
  switch (granularity) {
    case 'Giorno':
      const hourlyCo2EmissionSavings = Array.from({ length: 24 }, () => 0)
      values.forEach((item) => {
        const hour = new Date(item.date).getHours() // Ottieni il numero dell ora (0-23)
        hourlyCo2EmissionSavings[hour] = item.value
      })
      return hourlyCo2EmissionSavings
    case 'Settimana':
      const weeklyCo2EmissionSavings = Array.from({ length: 7 }, () => 0)
      values.forEach((item) => {
        const dayOfWeek = new Date(item.date).getDay() // Ottieni il numero del giorno della settimana (0-6)
        weeklyCo2EmissionSavings[dayOfWeek === 0 ? 6 : dayOfWeek - 1] =
          item.value
      })
      return weeklyCo2EmissionSavings
    case 'Mese':
      const dailyCo2EmissionSavings = Array.from({ length: 31 }, () => 0)
      values.forEach((item) => {
        const day = new Date(item.date).getDate() // Ottieni il numero del giorno (1-31)
        dailyCo2EmissionSavings[day - 1] = item.value
      })
      return dailyCo2EmissionSavings
    case 'Anno':
      const monthlyCo2EmissionSavingsForAnnual = Array.from(
        { length: 12 },
        () => 0
      )
      values.forEach((item) => {
        const monthForAnnual = new Date(item.date).getMonth() // Ottieni il numero del mese (0-11)
        monthlyCo2EmissionSavingsForAnnual[monthForAnnual] = item.value
      })
      return monthlyCo2EmissionSavingsForAnnual
    case 'Dall inizio':
      const monthlyCo2EmissionSavings = Array.from({ length: 12 }, () => 0)
      values.forEach((item) => {
        const month = new Date(item.date).getMonth() // Ottieni il numero del mese (0-11)
        monthlyCo2EmissionSavings[month] = item.value
      })
      return monthlyCo2EmissionSavings
    default:
      return []
  }
}

//Mappa la granulurità per inviare i nomi corretti al backend
const mappingGranularity = (granularity) => {
  switch (granularity) {
    case 'Giorno':
      return 'HOURLY'
    case 'Mese':
      return 'DAILY'
    case 'Anno':
      return 'MONTHLY'
    case 'Settimana':
      return 'DAILY'
    case 'Dall inizio':
      return 'MONTHLY'
    default:
      return granularity
  }
}

const calcDirectionNet = (K5, K7) => {
  if (K5 == 0 && K7 == 0) {
    return ENERGY_DIRECTION.NONE
  }
  if (K5 > 0) {
    return ENERGY_DIRECTION.OUT
  }
  if (K7 > 0) {
    return ENERGY_DIRECTION.IN
  }
}

const calcDirectionCons = (K1) => {
  if (K1 > 0) {
    return ENERGY_DIRECTION.OUT
  }
  if (K1 == 0) {
    return ENERGY_DIRECTION.NONE
  }
}

const calcDirectionProd = (K2) => {
  if (K2 > 0) {
    return ENERGY_DIRECTION.IN
  }
  if (K2 == 0) {
    return ENERGY_DIRECTION.NONE
  }
}

const calcDirectionBattery = (K3, K4) => {
  if (K3 == 0 && K4 == 0) {
    return ENERGY_DIRECTION.NONE
  }
  if (K3 > 0) {
    return ENERGY_DIRECTION.OUT
  }
  if (K4 > 0) {
    return ENERGY_DIRECTION.IN
  }
}
// cambia la granularita nell'invio dei kpi del grafico FV
const updateByUnit = (unit, amount, body) => {
  body.startDate = addTime(body.startDate, unit, amount)
  body.endDate = addTime(body.endDate, unit, amount, true)
}
// si sposta tra le date in base al tipo di granulirità,
// granularity = HOURLY , si sposta di giorno in giorno
// granularity == MONTHLY si sposta di mese in mese
const addTime = (dateString, unit, amount, end = false) => {
  const date = new Date(dateString)

  switch (unit) {
    case 'days':
      date.setDate(date.getDate() + amount)
      break
    case 'weeks':
      date.setDate(date.getDate() + amount * 7)
      break
    case 'months':
      var firstDay = new Date(
        date.getFullYear(),
        date.getMonth() + amount,
        1,
        0,
        0,
        0
      ).toISOString()
      var lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + amount + 1,
        0,
        23,
        59,
        59
      ).toISOString()
      if (end) {
        return lastDay
      } else return firstDay
      break
    case 'years':
      date.setFullYear(date.getFullYear() + amount)
      break
  }

  return date.toISOString()
}
//aggiorna la granulirtà in base alla data
const updateDates = (unit, amount, body) => {
  switch (unit) {
    case 'Giorno':
      updateByUnit('days', amount, body)
      break
    case 'Settimana':
      updateByUnit('weeks', amount, body)
      break
    case 'Mese':
      updateByUnit('months', amount, body)
      break
    case 'Anno':
      updateByUnit('years', amount, body)
      break
    case 'Dall inizio':
      updateByUnit('years', amount, body)
    default:
      // In caso di granularità sconosciuta, non fare nulla
      break
  }
}
const formatDayMonth = (date, abbreviateMonth = false) => {
  const month = abbreviateMonth
    ? getAbbreviatedMonthName(date.getMonth())
    : getMonthName(date.getMonth())
  return `${date.getDate()} ${month}`
}
const formatMonth = (date) => {
  return getMonthName(date.getMonth())
}
const formatYear = (date) => {
  return date.getFullYear().toString()
}
const formatFromActivation = (date) => {
  const month = getAbbreviatedMonthName(date.getMonth())
  return `${month} ${date.getDate()}`
}
const getStartOfWeek = (date) => {
  const dayOfWeek = date.getDay()
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) // adjust when day is Sunday
  return new Date(date.setDate(diff))
}
const getEndOfWeek = (date) => {
  const startOfWeek = getStartOfWeek(date)
  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setDate(startOfWeek.getDate() + 6)
  return endOfWeek
}
const getMonthName = (monthIndex) => {
  const monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ]
  return monthNames[monthIndex]
}
const getAbbreviatedMonthName = (monthIndex) => {
  const monthNames = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic'
  ]
  return monthNames[monthIndex]
}

const formatUndefinedValue = (value, symbol) => {
  symbol = symbol || ''
  if (
    value == undefined ||
    value === 'undefined' ||
    value == NaN ||
    value === '0'
  ) {
    return `0,00 ${symbol}`
  } else {
    value = value.toFixed(2)
    value = value.replace('.', ',')
    return `${value} ${symbol}`
  }
}
export default {
  formatUndefinedValue,
  updateByUnit,
  addTime,
  updateDates,
  formatDayMonth,
  getMonthName,
  getEndOfWeek,
  getStartOfWeek,
  formatFromActivation,
  getAbbreviatedMonthName,
  formatYear,
  formatMonth,
  sumValues,
  averagePercentage,
  defineCategories,
  defineColumnWidth,
  defineArrayValues,
  mappingGranularity,
  calcDirectionNet,
  calcDirectionCons,
  calcDirectionProd,
  calcDirectionBattery
}
