var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quotation"},[_c('div',{staticClass:"d-flex align-start"},[(!_vm.loaded)?_c('div',{staticClass:"skeletonUpselling"},[_c('transition',{attrs:{"name":"fade"}},[_c('Placeholder',{attrs:{"type":"upsellingPreventivo"}})],1),_c('transition',{attrs:{"name":"fade"}},[_c('Placeholder',{attrs:{"type":"upsellingPreventivoNoBg"}})],1)],1):_vm._e(),_c('transition',{staticClass:"fade"},[(_vm.loaded)?_c('div',[_c('div',{staticClass:"priceHeaderContainer mb-10"},[_c('div',{staticClass:"priceHeader"},[_c('div',{staticClass:"priceHeader__title"},[(_vm.product.priceQuoteText)?_c('div',{staticClass:"priceHeader__tip LabelS"},[_vm._v(" "+_vm._s(_vm.product.priceQuoteText)+" ")]):_vm._e(),_c('div',{staticClass:"priceHeader__offertName"},[_c('div',{staticClass:"HeadingL"},[_vm._v(" "+_vm._s(_vm.product.productTitle)+" ")]),_c('img',{attrs:{"contain":"","src":_vm.commodityType === 'ele'
                      ? require('../../../../assets/icons/ic-light-blu.svg')
                      : require('../../../../assets/icons/ic-fire-blu.svg'),"width":"32","height":"32"}})])]),_c('div',{staticClass:"priceHeader__price"},[_c('div',{staticClass:"priceHeader__price__text"},[_c('div',{staticClass:"LabelM"},[_vm._v(_vm._s(_vm.i4c.monthlyExpensesTitle))]),_c('div',{staticClass:"BodyM"},[_vm._v(" "+_vm._s(_vm.product.priceDetail)+" ")])]),_c('div',{staticClass:"priceHeader__price__number"},[_c('div',{staticClass:"SubheadingXXL"},[_vm._v(_vm._s(_vm.splitPrice.parteIntera))]),_c('div',{staticClass:"BodyL-Strong mt-4"},[_vm._v(" ,"+_vm._s(_vm.splitPrice.parteDecimale)+" ")])])])]),_c('div',{staticClass:"ButtonM howCalculated ml-1",on:{"click":function($event){return _vm.openHowCalculatedDetail()}}},[_vm._v(" "+_vm._s(_vm.product.quantitativeModalTitle)+" ")])]),_c('div',{staticClass:"benefitsContainer ml-1"},[(_vm.benefits.benefitList)?_c('div',{staticClass:"BodyL-Strong mb-5"},[_vm._v(" "+_vm._s(_vm.benefits.benefitTitle)+" ")]):_vm._e(),_c('div',{staticClass:"mb-7"},_vm._l((_vm.benefits.benefitList),function(benefit,i){return _c('div',{key:i,staticClass:"benefits mb-4"},[(
                  (_vm.commodityType === 'ele' && benefit.electricityFlag) ||
                  (_vm.commodityType === 'gas' && benefit.gasFlag)
                )?_c('div',{staticClass:"benefit mb-3"},[_c('div',{staticClass:"benefit__icon"},[_c('img',{attrs:{"src":_vm.loadIconClass(benefit.benefitIconClass),"width":"24"}})]),_c('div',{staticClass:"BodyL",domProps:{"innerHTML":_vm._s(benefit.title)}}),_c('div',{staticClass:"benefit__info"},[(benefit.deepDiveModalFlag)?_c('img',{staticClass:"imgInfo",attrs:{"src":require("@/assets/upselling/icons/icon-info.svg"),"width":"24"},on:{"click":function($event){return _vm.openCustomDialog(
                        benefit.imageModal,
                        benefit.modalText,
                        benefit.modalTitle
                      )}}}):_vm._e()])]):_vm._e()])}),0),_c('div',{staticClass:"ButtonM contractConditions mb-7"},[_vm._v(" "+_vm._s(_vm.catalogList.contractTermsText)+" "),_c('img',{staticClass:"ml-2",attrs:{"src":require("@/assets/upselling/icons/icon-download.svg"),"width":"24"},on:{"click":function($event){return _vm.openDoc()}}})]),_c('div',{staticClass:"LabelM"},[_vm._v(_vm._s(_vm.catalogList.endDateValidityFinalText))])])]):_vm._e()])],1),(!_vm.emptyState)?_c('div',{staticClass:"cta"},[_c('MYSButton',{attrs:{"disabled":!_vm.loaded,"buttonText":"Inizia la sottoscrizione","width":"260","buttonClass":"primaryButton primaryButtonL"},on:{"buttonClick":function($event){return _vm.next()}}}),(!_vm.emptyState)?_c('div',[_c('h5',{staticClass:"callMe",on:{"click":_vm.goToSpeakWith}},[_vm._v("Parla con un consulente")])]):_vm._e()],1):_vm._e(),_c('PdfViewer',{attrs:{"showDialog":_vm.dialogTermCond,"url":_vm.contractDataUrl,"attachTo":'body',"height":"600px"},on:{"closing-pdfViewer":function($event){_vm.dialogTermCond = false}}}),_c('CustomDialog',{attrs:{"customDialogOpen":_vm.customDialogOpen,"body":_vm.bodyModal,"title":_vm.titleModal,"image":_vm.imageModal,"showImage":_vm.showImage},on:{"update:customDialogOpen":function($event){_vm.customDialogOpen=$event},"update:custom-dialog-open":function($event){_vm.customDialogOpen=$event}}}),_c('ServiceUnavailableDialog',{attrs:{"serviceUnavailableDialogOpen":_vm.serviceUnavailableDialogOpen,"imageResponse":_vm.imageServiceError,"title":_vm.titleServiceError,"body":_vm.bodyServiceError},on:{"update:serviceUnavailableDialogOpen":function($event){_vm.serviceUnavailableDialogOpen=$event},"update:service-unavailable-dialog-open":function($event){_vm.serviceUnavailableDialogOpen=$event},"retry":_vm.loadOfferPreview,"returnToHome":_vm.returnToHome}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }