import { createDateFromACNFormat } from '../../utils'
import { OVERALL_STATUS } from '@/js/constants'

export default class SupplyAlerts {
  static getAlerts(alerts, consumptions, timelineAlerts, accountProperties) {
    const installationStatus = [
      OVERALL_STATUS.ALARM,
      OVERALL_STATUS.NOT_COMMUNICATING,
      OVERALL_STATUS.UNKNOWN
    ]

    const listNotWorking = [
      OVERALL_STATUS.ALARM,
      OVERALL_STATUS.VALIDATION_ERROR,
      OVERALL_STATUS.UNKNOWN
    ]

    return {
      ele: {
        isNotRegularPayment: alerts
          .map((item) => item.ele.paymentStatuses)
          .includes('NOT_REGULAR'),
        isInVerify: alerts
          .map((item) => item.ele.paymentStatuses)
          .includes('VERIFYING'),
        lastBillTimestamp: Math.max(
          ...alerts.map((item) => createDateFromACNFormat(item.ele.billsDates))
        ),
        isEstimate: alerts
          .map((item) => item.ele.tipoConsumo)
          .includes('ESTIMATED'),
        points: alerts.map((item) => item.ele.points).flat(),
        hasAbnormalConsumption:
          consumptions
            .map((item) => item.ele.consumptionStatuses)
            .includes('Consumo superiore alla media') &&
          accountProperties.type !== 'BUS',
        isUnavailable: consumptions
          .map((item) => item.ele.consumptionStatuses)
          .includes('Non disponibile'),
        consumptionPoints: consumptions.map((item) => item.ele.points).flat(),
        hasSupplyInAttivation: timelineAlerts.ele.inAttivationList.length > 0,
        timelinePoints: timelineAlerts.ele.inAttivationList
      },
      gas: {
        isNotRegularPayment: alerts
          .map((item) => item.gas.paymentStatuses)
          .includes('NOT_REGULAR'),
        isInVerify: alerts
          .map((item) => item.gas.paymentStatuses)
          .includes('VERIFYING'),
        lastBillTimestamp: Math.max(
          ...alerts.map((item) => createDateFromACNFormat(item.gas.billsDates))
        ),
        isEstimate: alerts
          .map((item) => item.gas.tipoConsumo)
          .includes('ESTIMATED'),
        points: alerts.map((item) => item.gas.points).flat(),
        hasAbnormalConsumption: false, // consumptions.map(item => item.gas.consumptionStatuses).includes('Consumo superiore alla media'),
        isUnavailable:
          alerts
            .map((item) => item.gas.billsDates)
            .filter((item) => item !== null).length === 0,
        consumptionPoints: consumptions.map((item) => item.gas.points).flat(),
        hasSupplyInAttivation: timelineAlerts.gas.inAttivationList.length > 0,
        timelinePoints: timelineAlerts.gas.inAttivationList
      },
      fiber: {
        isNotRegularPayment: alerts
          .map((item) => item.fiber.paymentStatuses)
          .includes('NOT_REGULAR'),
        isInVerify: alerts
          .map((item) => item.fiber.paymentStatuses)
          .includes('VERIFYING'),
        lastBillTimestamp: Math.max(
          ...alerts.map((item) =>
            createDateFromACNFormat(item.fiber.billsDates)
          )
        ),
        points: alerts.map((item) => item.fiber.points).flat(),
        hasSupplyInAttivation: timelineAlerts.fiber.inAttivationList.length > 0,
        timelinePoints: timelineAlerts.fiber.inAttivationList
      },
      photovoltaic: {
        isNotRegularPhotovoltaic: alerts
          .map((item) => item.photovoltaic.photovoltaicStatus)
          .some((status) => installationStatus.includes(status)),
        isNotWorking: alerts
          .map((item) => item.photovoltaic.photovoltaicStatus)
          .some((status) => listNotWorking.includes(status)),
        isOffline: alerts
          .map((item) => item.photovoltaic.photovoltaicStatus)
          .includes(OVERALL_STATUS.NOT_COMMUNICATING),
        irregularPhotovoltaic: alerts
          .map((item) => item.photovoltaic.points)
          .flat()
          .filter((point) =>
            installationStatus.includes(
              point.photovoltaicData.installationStatus
            )
          ),
        points: alerts.map((item) => item.photovoltaic.points).flat()
      }
    }
  }
}
