<template>
  <div class="data">
    <v-row>
      <v-col>
        <v-text-field
          v-if="typeRequest !== 'PR'"
          v-model.lazy="v$.pod.$model"
          type="text"
          name="pod"
          :error-messages="podErrors"
          placeholder="es. IT001E012554345"
          persistent-placeholder
          class="mb-3"
          label="Codice POD"
        >
          <template v-slot:append-outer>
            <div class="iconInfoEle pod">
              <img
                class="ml-1"
                src="../../../../assets/icons/info.svg"
                width="24"
                @click="openDetail('pod')"
              />
            </div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="power == 'O' ? 6 : 12">
        <v-select
          v-model="v$.power.$model"
          :items="[
            { val: '0.5', label: '0.5Kw' },
            { val: '1', label: '1Kw' },
            { val: '1.5', label: '1.5Kw' },
            { val: '2', label: '2Kw' },
            { val: '2.5', label: '2.5Kw' },
            { val: '3', label: '3Kw' },
            { val: '3.5', label: '3.5Kw' },
            { val: '4', label: '4Kw' },
            { val: '4.5', label: '4.5Kw' },
            { val: '5', label: '5Kw' },
            { val: '5.5', label: '5.5Kw' },
            { val: '6', label: '6Kw' },
            { val: '6', label: '6Kw' },
            { val: 'O', label: 'Altro' }
          ]"
          item-text="label"
          item-value="val"
          name="power"
          :error-messages="powerErrors"
          placeholder="es. 3kw"
          persistent-placeholder
          class="mb-3"
          label="Potenza contatore"
        >
          <template v-slot:append-outer>
            <div class="iconInfoEle power">
              <img
                class="ml-1"
                src="../../../../assets/icons/info.svg"
                width="24"
                @click="openDetail('power')"
              />
            </div>
          </template>
          <template #item="{ item }">
            <span :class="{ 'item-bold': item.val == 'O' }">
              {{ item.label }}
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="6" v-if="power == 'O'">
        <v-text-field
          v-model="v$.alternativePower.$model"
          type="number"
          name="alternativePower"
          :error-messages="alternativePowerErrors"
          placeholder="Valore"
          persistent-placeholder
          class="mb-3"
          label=""
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model.lazy="v$.consumption.$model"
          type="number"
          name="consumption"
          :error-messages="consumptionErrors"
          placeholder="es. 1200kWh"
          persistent-placeholder
          class="mb-3"
          label="Consumi annui"
        >
          <template v-slot:append-outer>
            <div class="iconInfoEle consumption">
              <img
                class="ml-1"
                src="../../../../assets/icons/info.svg"
                width="24"
                @click="openDetail('consumption')"
              />
            </div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model.lazy="v$.avgBillCost.$model"
          type="number"
          name="avgBillCost"
          :error-messages="avgBillCostErrors"
          placeholder="es. 0.30"
          persistent-placeholder
          class="mb-3"
          label="Costo medio bolletta (Eur/kWh)"
        >
          <template v-slot:append-outer>
            <div class="iconInfoEle avgBillCost">
              <img
                class="ml-1"
                src="../../../../assets/icons/info.svg"
                width="24"
                @click="openDetail('avgBillCost')"
              />
            </div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-if="typeRequest !== 'CA'"
          v-model.lazy="v$.tension.$model"
          :items="[
            { val: 'MO', label: 'BT Monofase' },
            { val: 'TR', label: 'BT Trifase' }
          ]"
          item-text="label"
          item-value="val"
          name="tension"
          :error-messages="tensionErrors"
          placeholder="es. BT Monofase"
          persistent-placeholder
          class="mb-6"
          label="Tensione"
        >
          <template v-slot:append-outer>
            <div class="iconInfoEle tension">
              <img
                class="ml-1"
                src="../../../../assets/icons/info.svg"
                width="24"
                @click="openDetail('tension')"
              />
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <MYSButton
      width="260"
      :buttonText="'Continua'"
      :buttonClass="'primaryButton'"
      :disabled="isInvalid"
      @buttonClick="next"
    />
    <v-dialog max-width="652" v-model="dialogDetail.show" ref="dialog">
      <v-row>
        <v-col class="dialog-detail">
          <img
            @click="dialogDetail.show = false"
            src="../../../../assets/ic_chevron_top_black.png"
          />
          <h2 v-html="dialogDetail.title" />
          <div class="description" v-html="dialogDetail.desc"></div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import { between, maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'supplierDataInputEle',
  components: {
    MYSButton
  },
  data() {
    return {
      pod: null,
      power: null,
      alternativePower: '',
      consumption: null,
      avgBillCost: null,
      tension: null,
      dialogDetail: {
        show: false,
        title: '',
        desc: ''
      }
    }
  },
  watch: {
    'v$.pod.$error': function (val) {
      val
        ? this.setValidationErrors('POD_INVALID')
        : this.deleteValidationError('POD_INVALID')
    },
    'v$.power.$error': function (val) {
      val
        ? this.setValidationErrors('POWER_INVALID')
        : this.deleteValidationError('POWER_INVALID')
    },
    'v$.alternativePower.$error': function (val) {
      val
        ? this.setValidationErrors('OTHER_POWER_INVALID')
        : this.deleteValidationError('OTHER_POWER_INVALID')
    },
    'v$.consumption.$error': function (val) {
      val
        ? this.setValidationErrors('CONSUMPTION_INVALID')
        : this.deleteValidationError('CONSUMPTION_INVALID')
    },
    'v$.avgBillCost.$error': function (val) {
      val
        ? this.setValidationErrors('AVG_BILL_COST_INVALID')
        : this.deleteValidationError('AVG_BILL_COST_INVALID')
    },
    'v$.tension.$error': function (val) {
      val
        ? this.setValidationErrors('TENSION_INVALID')
        : this.deleteValidationError('TENSION_INVALID')
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['flowType', 'typeRequest']),
    podErrors: function () {
      let errArray = []

      if (this.typeRequest === 'PR') return []

      if (!this.v$.pod.$dirty) return errArray
      this.v$.pod.maxLength.$invalid === true &&
        errArray.push('Il campo POD non è valido')
      this.v$.pod.minLength.$invalid === true &&
        errArray.push('Il campo POD non è valido')
      this.v$.pod.pattern.$invalid === true &&
        errArray.push('Il campo POD non è valido')
      this.v$.pod.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')
      this.v$.pod.checkPod.$invalid === true &&
        errArray.push('Il campo POD non è valido')

      return errArray
    },
    powerErrors: function () {
      let errArray = []
      if (this.power !== 'O') {
        this.setAlternativePower('')
      }
      if (!this.v$.power.$dirty) return errArray
      this.v$.power.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')

      return errArray
    },
    alternativePowerErrors: function () {
      let errArray = []

      if (!this.v$.alternativePower.$dirty) return errArray

      this.v$.alternativePower.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')
      this.v$.alternativePower.between.$invalid === true &&
        errArray.push('il valore deve essere inferiore o uguale a 100')

      return errArray
    },
    consumptionErrors: function () {
      let errArray = []

      if (!this.v$.consumption.$dirty) return errArray
      this.v$.consumption.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')

      return errArray
    },
    avgBillCostErrors: function () {
      let errArray = []

      if (!this.v$.avgBillCost.$dirty) return errArray
      this.v$.avgBillCost.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')

      return errArray
    },
    tensionErrors: function () {
      let errArray = []

      if (this.typeRequest === 'CA') return []

      if (!this.v$.tension.$dirty) return errArray
      this.v$.tension.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')

      return errArray
    },
    isInvalid() {
      var commonValid =
        this.v$.avgBillCost.$invalid ||
        this.v$.consumption.$invalid ||
        this.v$.power.$invalid ||
        (this.power === 'O' ? this.v$.alternativePower.$invalid : false)
      var elseValid = this.v$.pod.$invalid || this.v$.tension.$invalid
      if (this.typeRequest === 'PR') {
        elseValid = this.v$.tension.$invalid
      } else if (this.typeRequest === 'CA') {
        elseValid = this.v$.pod.$invalid
      }
      return commonValid || elseValid
    }
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'setSupplyDetail',
      'setValidationErrors',
      'deleteValidationError'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('upsellingComm', ['checkPOD']),
    ...mapGetters('upsellingComm', ['supplyDetail']),
    next() {
      this.setSupplyDetail({
        pod: this.pod,
        power: this.power,
        alternativePower: parseFloat(this.alternativePower),
        consumption: parseInt(this.consumption),
        avgBillCost: this.avgBillCost,
        tension: this.tension
      })
      if (this.flowType === 'migration') {
        this.$emit('goToStep', 12, 'upsellingcom_titolarity_open')
      } else if (this.flowType === 'reactivation') {
        this.$emit('nextStep', 'upsellingcom_activationtype_open')
      } else if (this.flowType === 'newConnection') {
        this.$emit('goToStep', 11, 'upsellingcom_candisconnect_open')
      }
    },
    setAlternativePower(newValue) {
      this.alternativePower = newValue
    },
    openDetail(type) {
      const text = {
        pod: {
          title: 'POD',
          desc: "Il codice POD (Point of Delivery) identifica la tua fornitura ed è necessario per sottoscrivere il contratto. Inizia con 'IT', è composto da 14 o 15 caratteri, a seconda del fornitore, e si trova nella tua bolletta dell'energia elettrica."
        },
        power: {
          title: 'Potenza contatore',
          desc: 'La potenza del contatore si misura in chilowatt (kW) ed è indicata nella prima o nella seconda pagina della bolletta, all\'interno della sezione “Tipologia di contratto” oppure nei “Dati di fornitura".'
        },
        consumption: {
          title: 'Guida alla stima consumi',
          desc: "Inserisci qui il dato di consumo elettrico annuo letto sulla tua ultima bolletta. Normalmente questa informazione è inserita nella seconda pagina della tua bolletta. <strong>La famiglia media italiana consuma circa 2.700 kWh all'anno.</strong>"
        },
        avgBillCost: {
          title: 'Costo medio bolletta',
          desc: 'Trovi questo valore nella tua bolletta alla voce "costo medio della bolletta", sulla bolletta elettrica. Normalmente questa informazione è presente nella seconda pagina della tua bolletta. La famiglia media italiana ha un costo medio unitario totale della bolletta dell\'energia elettrica pari a circa 0,3 €/kWh.'
        },
        tension: {
          title: 'Tensione',
          desc: 'È il valore della tensione del tuo contatore che trovi in fattura, se hai 3kW nel tuo impianto elettrico di casa seleziona Bassa Tensione Monofase, la Bassa Tensione Trifase solitamente viene utilizzata da potenze superiori ai 6kW. <strong>La maggior parte delle case ha un contatore BT Monofase con tensione pari a 3kW.</strong>'
        }
      }
      this.dialogDetail = {
        show: true,
        title: text[type].title,
        desc: text[type].desc
      }
      this.trackTapEvent({
        name: `upsellingcom_info${type}_tap`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  },
  created() {
    let { ele } = this.supplyDetail()
    this.pod = ele.pod
    this.power = ele.power
    this.alternativePower = ele.alternativePower
    this.consumption = ele.consumption
    this.avgBillCost = ele.avgBillCost
    this.tension = ele.tension
  },
  validations() {
    var ret = {
      pod: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(15),
        checkPod: (value, vm) => {
          if (value) return vm.checkPOD(value)
          return null
        },
        pattern: (value) => {
          const patt = /^[Ii]{1}[Tt]{1}[0-9]{3}[Ee]{1}[A-Za-z0-9]{8,9}$/
          return patt.test(value)
        }
      },
      power: { required },
      consumption: { required },
      avgBillCost: { required },
      tension: { required }
    }
    if (this.power === 'O') {
      ret.alternativePower = {
        required,
        between: between(6, 100)
      }
    }
    return ret
  }
}
</script>
<style lang="scss">
.v-text-field {
  position: relative;
}

.iconInfoEle {
  position: absolute;
  top: -6px;

  &.pod {
    left: 64px;
  }

  &.power {
    left: 99px;
  }

  &.consumption {
    left: 80px;
  }

  &.avgBillCost {
    left: 168px;
  }

  &.tension {
    left: 50px;
  }
}
</style>
<style lang="scss" scoped>
.dialog-detail,
.dialog-offer {
  background: white;
  padding: 55px 32px 45px 32px;
  border-radius: 40px;
  position: relative;

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .description {
    font-size: 16px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
  }

  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    &.preview {
      position: initial;
    }
  }
}

.data {
  max-width: 652px;
  color: black;
}

.item-bold {
  font-weight: bold;
}
</style>
