<template>
  <v-dialog :value="dialog" @input="$emit('input')" max-width="555">
    <v-row>
      <v-col class="owner-dialog">
        <img
          @click="$emit('closeModal')"
          src="../../assets/ic_chevron_top_black.png"
        />
        <h2>Seleziona profilo</h2>
        <div
          :class="`${client.disabled ? 'disabled' : ''} owner`"
          v-for="(client, i) in clients"
          :key="i"
          @click="!client.disabled ? gotoDetail(client) : false"
        >
          <h5>
            {{
              client.clientOwnerType === 'RES'
                ? client.firstName + ' ' + client.lastName
                : client.businessName
            }}
          </h5>
          <v-img
            class="flex-grow-0 ml-2 mb-1"
            :src="
              client.clientOwnerType === 'RES'
                ? require('../../assets/icons/ic-owner-res.svg')
                : require('../../assets/icons/ic-business.svg')
            "
            width="20"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Constants from '@/js/constants'
export default {
  name: 'CheckPaymentStatus',
  props: {
    dialog: { type: Boolean, default: false }
  },
  data() {
    return {
      clients: {},
      SUPPLY_STATUS: Constants.SUPPLY_STATUS
    }
  },
  computed: {
    ...mapGetters('supply', ['clientsWithSupplies']),
    ...mapGetters('account', ['accountClients'])
  },
  methods: {
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('analytics', ['trackTapEvent']),
    gotoDetail(client) {
      this.trackTapEvent({
        name: 'summaryBill_chooseProfile_tap',
        params: {
          client_code: client.clientOwnerCode,
          client_type: client.clientOwnerType
        }
      })
      this.$emit('closeModal')
      this.$router
        .push({
          name: 'paymentSummary',
          params: { clientCode: client.clientOwnerCode }
        })
        .catch((err) => {})
    }
  },
  watch: {
    dialog(val) {
      let clients = {}
      this.getSupplies().then((supplies) => {
        for (let cl in this.accountClients) {
          for (let sup in supplies) {
            if (
              supplies[sup].clientOwnerCode === cl &&
              supplies[sup].supplyState !== this.SUPPLY_STATUS.ACTIVATING
            ) {
              clients[cl] = this.accountClients[cl]
              clients[cl].disabled = false
            } else {
              if (!clients[cl]) {
                clients[cl] = this.accountClients[cl]
                clients[cl].disabled = true
              }
            }
          }
        }
        this.clients = Object.values(clients).sort(function (a, b) {
          if (a.disabled) return 1
          if (!a.disabled) return -1
          return 0
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-dialog__content {
  .owner-dialog {
    background: white;
    padding: 48px 58px 24px 48px;
    border-radius: 40px;
    position: relative;
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    .owner {
      display: flex;
      align-items: center;
      width: 458px;
      height: 66px;
      border-radius: 20px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff;
      padding: 0 32px;
      margin-bottom: 24px;
      cursor: pointer;
      &.disabled {
        cursor: initial;
        h5 {
          opacity: 0.3;
        }
      }
    }
  }
}
</style>
