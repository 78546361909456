<template>
  <div class="page">
    <v-row class="std-side-padding">
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CallMeBack
          :phone.sync="phone"
          :time.sync="time"
          :clientCode="clientCode"
          :email="email"
          supplyType="fiber"
          :submit="submit"
          :loading.sync="loading"
          fromPage="fiber"
          :notes="notes"
          @nextStep="sendSubmit"
        />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="3">
        <v-btn
          @click="buttonClick"
          class="v-btn--shadowed"
          x-large
          color="primary"
          width="260"
          :disabled="isSubmitButtonDisabled"
          :loading="loading"
          >Prosegui</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { capitalize } from '@/js/utils'
import { mapGetters } from 'vuex'
import CallMeBack from '../../components/callMeBack/CallMeBack'

export default {
  name: 'FiberAction',
  props: {},
  components: {
    CallMeBack
  },
  data() {
    return {
      phone: '',
      time: '',
      submit: false,
      loading: false
    }
  },
  beforeCreate() {
    if (!this.$store.state.supply.supplyDetail) {
      this.$store
        .dispatch('supply/getDetail', {
          clientOwnerCode: this.$route.params.clientOwnerCode,
          supplyCode: this.$route.params.supplyCode
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    }
    if (!this.$store.state.account.clientDetails) {
      this.$store
        .dispatch('account/getDetailForClient', {
          clientOwnerCode: this.$route.params.clientOwnerCode
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    }
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail']),
    ...mapGetters('account', ['clientDetails']),
    breadcrumbsItems() {
      let address = this.supplyDetail
        ? this.supplyDetail[this.$route.params.supplyCode].address
        : ''
      address = capitalize(address.toLowerCase())
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'supplyDetail' }, text: address, exact: true },
        { to: { name: 'fiberAction' }, text: 'Assistenza Fibra' }
      ]
      return res
    },
    clientCode() {
      return this.$route.params.clientOwnerCode
    },
    notes() {
      let n = ''
      switch (this.$route.params.actionType) {
        case 'lineIssues':
          n = 'Fibra – Assistenza per malfunzionamento linea'
          break
        case 'modemIssues':
          n = 'Fibra – Assistenza su modem'
          break
        default:
          n = 'Error'
      }
      return n
    },
    isSubmitButtonDisabled() {
      if (this.phone !== '' && this.time !== '') {
        return false
      } else return true
    },
    email() {
      return this.clientDetails ? this.clientDetails.contactMail : ''
    }
  },
  methods: {
    buttonClick() {
      this.submit = true
    },
    sendSubmit() {
      this.$router.push({ name: 'promSuccess', params: { type: 'fiber' } })
    }
  }
}
</script>

<style lang="scss" scoped></style>
