<template>
  <ExtensibleButtonGroup
    v-model="activeTab"
    :per-row="2"
    :content="true"
    :hideContentOnBlur="false"
  >
    <ExtensibleButton
      v-for="(type, i) in types"
      :key="i"
      :name="type.name"
      :label="type.label"
    >
      <v-row>
        <v-col>
          <div class="d-flex align-center">
            <h4 class="mr-3">
              Procedi alla richiesta di {{ type.text }} tensione
            </h4>
            <img
              class="bottoneInformativa"
              src="../../assets/icons/info.svg"
              @click="dialogHelp = true"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="activeTab === 'cambioTensione'" class="col-6">
          <h5>Scegli la tensione richiesta</h5>
          <v-select
            v-model="selectedType"
            :items="actions.types"
            placeholder="Seleziona"
            persistent-placeholder
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-6">
          <h5>Messaggio</h5>
          <v-textarea
            v-model="message"
            rows="3"
            solo
            name="message"
            label="Scrivi quello di cui hai bisogno"
          />
        </v-col>
        <v-col class="col-6">
          <h5>
            Inserisci il numero sul quale vuoi essere ricontattato per la
            gestione di questa richiesta
          </h5>
          <v-text-field
            ref="phone"
            @input="filterPhone"
            v-model="phone"
            placeholder="Numero di telefono"
            persistent-placeholder
          />
        </v-col>
      </v-row>
      <DialogContextualHelp
        v-model="dialogHelp"
        :clientCode="$route.params.clientOwnerCode"
        :idContextualHelp="
          activeTab === 'cambioTensione'
            ? 'help-change-tension'
            : 'help-check-tension'
        "
      />
    </ExtensibleButton>
  </ExtensibleButtonGroup>
</template>
<script>
import { actions } from '@/js/constants'
import DialogContextualHelp from '@/components/contextualHelp/Dialog'

export default {
  name: 'CounterMovement',
  props: {},
  data() {
    return {
      activeTab: '',
      selectedType: '',
      phone: '',
      message: '',
      types: [
        {
          label: 'Verifica tensione',
          text: 'verifica',
          name: 'verificaTensione'
        },
        { label: 'Cambio tensione', text: 'cambio', name: 'cambioTensione' }
      ],
      dialogHelp: false
    }
  },
  computed: {
    actions() {
      return actions.voltage
    },
    data() {
      if (
        this.phone !== '' &&
        this.phone !== undefined &&
        this.message !== '' &&
        this.selectedType !== '' &&
        this.activeTab === 'cambioTensione'
      ) {
        return {
          telefono: this.phone[0],
          tensione: this.selectedType,
          note: this.message,
          pod: this.$route.params.supplyCode,
          commodity: this.activeTab
        }
      } else if (
        this.phone !== '' &&
        this.phone !== undefined &&
        this.message !== '' &&
        this.activeTab === 'verificaTensione'
      ) {
        return {
          telefono: this.phone[0],
          note: this.message,
          pod: this.$route.params.supplyCode,
          commodity: this.activeTab
        }
      }
      return null
    }
  },
  watch: {
    data(value) {
      this.$emit('update:data', value)
    }
  },
  methods: {
    filterPhone(val) {
      if (val) {
        this.phone = val.match(/[0-9]+/) || this.$refs.phone[0].reset()
      } else {
        this.phone = ''
      }
    }
  },
  components: {
    DialogContextualHelp
  }
}
</script>
<style lang="scss" scoped>
.bottoneInformativa {
  cursor: pointer;
}
</style>
