var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"header-size":"small"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"align-self":"center","cols":"6"}},[_c('h1',{staticClass:"white--text"},[_vm._v("Ritira i tuoi premi")])]),_c('v-spacer',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"align-self":"center","cols":"5"}},[_c('v-img',{attrs:{"contain":"","width":"290","src":require("@/assets/greeners/svg/logoGreeners_new.svg")}})],1)]},proxy:true}])},[(_vm.loader)?_c('div',[_c('v-progress-circular',{staticClass:"loader",attrs:{"size":50,"indeterminate":""}})],1):_c('div',{staticClass:"reward__content"},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"HeadingL"},[_vm._v("Premio Fedeltà")])])],1),_c('v-row',{staticClass:"mb-10"},_vm._l((_vm.catalogList),function(item,index){return _c('v-col',{key:item.id,staticClass:"mt-4",attrs:{"cols":"3"}},[_c('v-card',{staticClass:"gift-item",attrs:{"to":{
            name: 'giftDetailsPage',
            params: {
              catalog: 'WL-GIFT',
              id: item.attributes.prizeoption_id,
              item: item
            }
          },"light":"","outlined":""}},[_c('v-img',{staticClass:"gift-item__img",attrs:{"src":_vm.itemImages[index],"contain":""}}),_c('h3',{staticClass:"gift-item__title",domProps:{"textContent":_vm._s(item.attributes.title)}})],1)],1)}),1),_c('v-footer',{attrs:{"color":"white","height":"90"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }