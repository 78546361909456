<template>
  <div class="use">
    <div class="use__btn" @click="next(true)">
      <h5>Uso abituale</h5>
    </div>
    <div class="use__btn mb-10" @click="next(false)">
      <h5>Uso occasionale</h5>
    </div>
    <div class="use__box">
      <div class="title">Perchè te lo chiediamo?</div>
      <h5 class="desc">
        Insieme agli altri dati che ci fornirai, la frequenza con cui sei in
        casa ci aiuta stabilire il tuo profilo di consumo.
      </h5>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'useType',
  computed: {
    ...mapGetters('upsellingComm', [
      'estimationType',
      'commodityType',
      'flowType'
    ])
  },
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetailRoot']),
    ...mapActions('analytics', ['trackTapEvent']),
    next(isPrimaryHome) {
      this.setSupplyDetailRoot({ isPrimaryHome: isPrimaryHome })
      if (this.estimationType === 'noBill') {
        if (this.commodityType === 'gas') {
          this.$emit('goToStep', 15, 'upsellingcom_homesize_open')
        } else {
          this.$emit('goToStep', 17, 'upsellingcom_heathome_open')
        }
      } else if (
        this.flowType === 'reactivation' ||
        this.flowType === 'newConnection'
      ) {
        this.$emit('goToStep', 9, 'upsellingcom_supplydetail_open')
      } else {
        this.$emit('nextStep', 'upsellingcom_currentprovider_open')
      }
      this.trackTapEvent({
        name: isPrimaryHome
          ? 'upsellingcom_isprimaryhome_tap'
          : 'upsellingcom_isnotprimaryhome_tap',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.use {
  max-width: 652px;
  color: black;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 66px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
</style>
