import { axiosACN, axiosCMS } from '@/js/axiosInstances.js'
import mkd from 'markdown-it'
import iterator from 'markdown-it-for-inline'
import { filterWelcomekitByUserProfile } from '../../js/wkFilter'

const getUserProfileCluster = (data) => {
  if (window.wkPromise) return window.wkPromise
  else {
    window.wkPromise = new Promise((resolve, reject) => {
      axiosACN
        .post(
          '/sorgeniadataclient/retrieveClusterSurvey',
          {
            codiceCliente: data.clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + data.token
            }
          }
        )
        .then((resp) => {
          if (resp.data.status === 'KO') {
            reject(resp.data)
          } else {
            resolve(resp)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
    return window.wkPromise
  }
}

const configureParser = () => {
  try {
    var mdParser = mkd({
      html: true,
      linkify: false,
      typographer: false
    }).use(iterator, 'url_new_win', 'link_open', function (tokens, idx) {
      var aIndex = tokens[idx].attrIndex('target')

      if (aIndex < 0) {
        tokens[idx].attrPush(['target', '_blank'])
      } else {
        tokens[idx].attrs[aIndex][1] = '_blank'
      }
    })

    return mdParser
  } catch (e) {
    console.error(e)
  }
}

const resolveMarkDowns = (sections) => {
  var mdParser = configureParser()
  for (let section of sections) {
    for (let paragraph of section.paragraphs) {
      paragraph.body = mdParser.render(
        paragraph.body.replace('<p>', '').replace('</p>', '')
      )
    }
  }
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    wkSlidesForUser: null,
    tutorialSlidesForUser: null,
    welcomekit: undefined,
    tutorial: null,
    survey: null
  },
  getters: {
    wkSlidesForUser: (state) => state.wkSlidesForUser,
    tutorialSlidesForUser: (state) => state.tutorialSlidesForUser,
    tutorial: (state) => state.tutorial,
    welcomekit: (state) => state.welcomekit,
    survey: (state) => state.survey
  },
  mutations: {
    setwkSlidesForUser(state, slides) {
      state.wkSlidesForUser = slides
    },
    setTutorialSlidesForUser(state, slides) {
      state.tutorialSlidesForUser = slides
    },
    setWelcomekit(state, welcomekit) {
      state.welcomekit = welcomekit
    },
    setTutorial(state, tutorial) {
      state.tutorial = tutorial
    },
    setSurvey(state, survey) {
      state.survey = survey
    },
    resetState(state) {
      state.tutorialSlidesForUser = null
      state.wkSlidesForUser = null
      state.welcomekit = undefined
      state.turorial = null
      state.survey = null
    }
  },
  actions: {
    getSurveyByUserProfile({ state, commit, rootGetters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (!data.client) resolve(state)
        else if (state.survey == null && !data.client.isOnlyFV) {
          getUserProfileCluster({
            clientOwnerCode: data.client.clientOwnerCode,
            token: data.client.token,
            getters: rootGetters,
            dispatch: dispatch
          })
            .then((userData) => {
              userData.data.userType = data.client.clientOwnerType
              userData.data.greenersSubscribed =
                rootGetters['loyalty/memberStatus'].user_registered
              // fetch('https://dev.sorgenia.it/mock-wk.json')
              axiosCMS.get('/feedback-v2').then((resp) => {
                // resp.json().then(kits => {
                let surveys = resp.data
                if (surveys.length > 0) {
                  let survey = filterWelcomekitByUserProfile(
                    surveys,
                    userData,
                    'survey'
                  )
                  if (survey === null) {
                    commit('setSurvey', null)
                    resolve(state)
                  } else {
                    commit('setSurvey', survey)
                    resolve(state)
                  }
                } else {
                  commit('setSurvey', null)
                  resolve(state)
                }
              })
            })
            .catch((err) => {
              console.error(err)
              commit('setSurvey', null)
              reject(err)
            })
          // })
        } else {
          commit('setSurvey', state.survey)
          resolve(state)
        }
      })
    },
    getTutorialByUserProfile({ state, commit, rootGetters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (!data.client) resolve(state)
        else if (
          state.tutorial == null ||
          state.tutorialSlidesForUser == null
        ) {
          getUserProfileCluster({
            clientOwnerCode: data.client.clientOwnerCode,
            token: data.client.token,
            getters: rootGetters,
            dispatch: dispatch
          })
            .then((userData) => {
              userData.data.userType = data.client.clientOwnerType
              userData.data.greenersSubscribed =
                rootGetters['loyalty/memberStatus'].user_registered
              // fetch('https://dev.sorgenia.it/mock-wk.json')
              axiosCMS.get('/tutorial-v2').then((resp) => {
                // resp.json().then(kits => {
                let tutorials = resp.data
                if (tutorials.length > 0) {
                  let tutorial = filterWelcomekitByUserProfile(
                    tutorials,
                    userData,
                    'tutorial'
                  )
                  if (tutorial === null) {
                    commit('setTutorialSlidesForUser', [])
                    commit('setTutorial', null)
                    resolve(state)
                  } else {
                    resolveMarkDowns(tutorial.sections)
                    let slides = tutorial.sections || []
                    commit('setTutorialSlidesForUser', slides)
                    commit('setTutorial', tutorial)
                    resolve(state)
                  }
                } else {
                  commit('setTutorialSlidesForUser', [])
                  commit('setTutorial', null)
                  resolve(state)
                }
              })
            })
            .catch((err) => {
              console.error(err)
              commit('setTutorialSlidesForUser', [])
              commit('setTutorial', null)
              reject(err)
            })
          // })
        } else {
          commit('setTutorialSlidesForUser', state.tutorialSlidesForUser)
          commit('setTutorial', state.tutorial)
          resolve(state)
        }
      })
    },
    getWelcomekitByUserProfile({ state, commit, rootGetters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (!data.client) resolve(state)
        else if (
          (state.welcomekit == null || state.wkSlidesForUser == null) &&
          !data.client.isOnlyFV
        ) {
          getUserProfileCluster({
            clientOwnerCode: data.client.clientOwnerCode,
            token: data.client.token,
            getters: rootGetters,
            dispatch: dispatch
          })
            .then((userData) => {
              userData.data.userType = data.client.clientOwnerType
              userData.data.greenersSubscribed =
                rootGetters['loyalty/memberStatus'].user_registered
              // fetch('https://dev.sorgenia.it/mock-wk.json')
              axiosCMS.get('/welcome-kit-v2').then(async (resp) => {
                // resp.json().then(kits => {
                let welcomekits = await resp.data
                if (welcomekits.length > 0) {
                  let welcomekit = filterWelcomekitByUserProfile(
                    welcomekits,
                    userData,
                    'welcomekit'
                  )
                  if (welcomekit === null) {
                    commit('setwkSlidesForUser', [])
                    commit('setWelcomekit', null)
                    data.client.type = 'none'
                    resolve(state)
                  } else {
                    resolveMarkDowns(welcomekit.sections)
                    let slides = welcomekit.sections || []
                    commit('setwkSlidesForUser', slides)
                    commit('setWelcomekit', welcomekit)
                    data.client.type = welcomekit.id
                    resolve(state)
                  }
                } else {
                  commit('setwkSlidesForUser', [])
                  commit('setWelcomekit', null)
                  data.client.type = 'none'
                  resolve(state)
                }
              })
            })
            .catch((err) => {
              console.error(err)
              commit('setwkSlidesForUser', [])
              commit('setWelcomekit', null)
              data.client.type = 'none'
              reject(err)
            })
          // })
        } else {
          commit('setwkSlidesForUser', state.wkSlidesForUser)
          commit('setWelcomekit', state.welcomekit)
          data.client.type = state.welcomekit.id
          resolve(state)
        }
      })
    }
  }
}
