<template>
  <layout header-size="small">
    <v-dialog overlay-opacity="0.6" v-model="confirmDialog" max-width="660">
      <v-card class="rules-dialog">
        <v-row>
          <v-col class="pb-0" align="right">
            <img
              class="rules-dialog__img"
              @click="confirmDialog = false"
              src="@/assets/ic_chevron_top_black.png"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <h2 class="rules-dialog__title">
              Sei sicuro di voler interrompere la Missione?
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="rules-dialog__text">
              Non perdere l'occasione di accumulare Green Coins e procedere
              verso un aumento di Livello.
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col cols="6">
            <v-btn
              class="v-btn--shadowed normal"
              color="white"
              x-large
              width="265"
              @click="$router.push({ name: 'loyaltyPage' })"
            >
              Interrompi
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              class="v-btn--shadowed"
              color="brightGreen"
              x-large
              width="265"
              @click="confirmDialog = false"
            >
              Completa Missione
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <template v-slot:header>
      <v-file-input id="file-upload" style="display: none" v-model="file" />
      <v-col cols="6" align-self="center">
        <button
          type="button"
          class="loyalty__header-back mb-2"
          @click="goDiario"
        >
          <v-icon color="#12256A" size="20">mdi-chevron-left</v-icon>
          <span>Torna al Diario</span>
        </button>
        <h1>{{ mission.welcome_page_title }}</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5" align-self="center">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <common-content
      v-if="step === 'info'"
      :coins="missionReward"
      :btnLabel="mission.welcome_page_button_label"
      :imageSrc="CmsUrl + mission.welcome_page_image"
      instruction="Condividi i tuoi momenti."
      picSize="large"
      :isButtonSeparated="false"
      @buttonClick="handleClick"
      :achievement="true"
    >
      <template v-slot:text>
        <v-row class="mb-6" no-gutters="">
          <v-col cols="1">
            <v-img
              class="header__icon"
              width="35"
              :src="require('@/assets/greeners/svg/mission-photo.svg')"
            />
          </v-col>
          <v-col class="pl-0 pr-0 align-self-end"
            ><h5>
              <strong>FOTO - SCADE IL {{ expirationDate }}</strong>
            </h5></v-col
          >
        </v-row>
        <h4 class="mb-6" v-html="mission.welcome_page_text" />
      </template>
    </common-content>
    <template v-if="step === 'upload'">
      <v-col cols="12" class="pt-8">
        <div class="d-inline-flex align-end ml-2 mt-8">
          <v-img
            class="mr-2"
            width="35"
            :src="require('@/assets/greeners/svg/mission-photo.svg')"
          />
          <h5>
            <strong>FOTO - SCADE IL {{ expirationDate }}</strong>
          </h5>
        </div>
        <v-row class="mg-0">
          <v-col class="pb-0 mb-9 mt-3">
            <h2>Foto caricata</h2>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters class="mb-0 mt-0">
          <v-col sm="6" class="pt-0 pb-0 img-upload">
            <v-img :src="src" />
          </v-col>
          <v-col sm="6" class="align-self-start">
            <v-row>
              <v-col align="right">
                <v-btn
                  color="white"
                  class="v-btn--shadowed black--text mb-6"
                  x-large
                  width="360"
                  @click="handleClick()"
                  >Sostituisci foto</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col align="right">
                <v-btn
                  @click="sendRequest"
                  class="v-btn--shadowed mb-4"
                  color="brightGreen"
                  x-large
                  width="360"
                >
                  Conferma e prosegui
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </layout>
</template>

<script>
import { ENV_CONFIGS } from '@/js/configs.js'
import CommonContent from './CommonContent'
import Layout from './Layout'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UploadPhoto',
  components: {
    Layout,
    commonContent: CommonContent
  },
  data() {
    return {
      CmsUrl: ENV_CONFIGS.CMS_CDN_BASE_URL,
      step: 'info',
      file: [],
      src: '',
      title: '',
      message: '',
      confirmDialog: false
    }
  },
  beforeMount() {
    this.getWekitMap()
    this.getPhoto()
    this.getMainClient().then((resp) => {
      if (this.mainClientLoyaltySelected) {
        resp = this.mainClientLoyaltySelected
      }
      this.getDetailForClient({ clientOwnerCode: resp.clientOwnerCode }).then(
        (res) => {
          this.getClientLevel({
            ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
          })
        }
      )
    })
  },
  computed: {
    ...mapGetters('loyalty', ['photoData', 'wekitMap', 'clientLevel']),
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    mission() {
      return this.photoData.filter(
        (photo) => photo.id === this.$route.params.id
      )[0]
    },
    missionReward() {
      return this.missionValue(
        this.mission.wekit_action_id,
        this.mission.wekit_event_id
      )
    },
    disabled() {
      if (this.src !== '' && this.title !== '' && this.message !== '')
        return false
      return true
    },
    expirationDate() {
      if (this.mission && this.mission.end_date) {
        return new Date(this.mission.end_date).toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      }
      return '---'
    }
  },
  watch: {
    file(val) {
      let imageURL = URL.createObjectURL(val)
      this.src = imageURL
      this.step = 'upload'
    }
  },
  methods: {
    ...mapActions({
      getPhoto: 'loyalty/getPhoto',
      getWekitMap: 'loyalty/getWekitMap'
    }),
    ...mapActions('loyalty', ['newTransaction', 'getClientLevel']),
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    missionValue(actionId, eventId) {
      let targetMission = this.wekitMap.filter(
        (mission) =>
          mission.action_id === actionId && mission.event_id === eventId
      )
      if (targetMission.length) {
        return targetMission[0].points
      } else return 0
    },
    handleClick() {
      this.trackTapEvent('greeners_mission_start_tap')
      document.getElementById('file-upload').click()
    },
    deleteFile(index) {
      this.files.splice(index, 1)
    },
    goDiario() {
      this.trackTapEvent({
        name: 'greeners_mission_close',
        params: {
          client_code: this.mainClientLoyaltySelected.clientOwnerCode,
          client_type: this.mainClientLoyaltySelected.clientOwnerType,
          mission_id: this.mission.id
        }
      })
      if (this.step === 'upload') {
        this.confirmDialog = true
      } else {
        this.$router.push({ name: 'loyaltyPage' })
      }
    },
    sendRequest() {
      const body = {
        event: this.mission.wekit_event_id,
        action: this.mission.wekit_action_id,
        detail1: this.mission.id,
        detail2: this.clientLevel.level_name,
        detail3: this.mission.title,
        extra: {}
      }
      this.newTransaction(body).then((res) => {
        if (res)
          this.$router.push({
            name: 'successUpload',
            params: { id: this.$route.params.id }
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.img-upload {
  .v-responsive {
    border-radius: 30px 30px 30px 30px;
  }
}
.rules-dialog {
  padding: 0px 48px 20px 48px;

  &__img {
    padding-top: 30px;
  }

  &__title {
    font-size: 32px;
    line-height: 44px;
    font-weight: bold;
  }

  &__text {
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
  }
}
</style>
