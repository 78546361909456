<template>
  <div class="repeater">
    <div class="BodyL repeater__intro">
      Il ripetitore assicura una navigazione stabile anche quando si connettono
      molti dispositivi
    </div>

    <div class="repeater__box">
      <div class="text">
        <div class="title-orange">
          <span class="CaptionS">{{ repeaterDescription.included }}</span>
        </div>
        <span class="BodyXL-Strong">Ripetitore</span>
        <span class="BodyL-Strong">{{ repeaterDescription.name }}</span>
        <div class="BodyS">
          {{ repeaterDescription.price }}€
          {{ repeaterDescription.price_description }}
        </div>
      </div>
      <div class="img-content">
        <img :src="repeaterDescription.repeater_image" />
      </div>
    </div>

    <div class="d-flFex align-center mb-6">
      <span class="HeadingsSubtitleM">{{
        repeaterDescription.description_title
      }}</span>
      <img
        @click="dialogDescRep = true"
        class="ml-2 pointer"
        src="../../../assets/icons/info.svg"
        width="16"
      />
    </div>
    <div
      class="repeater__description BodyL"
      v-html="repeaterDescription.description_body"
    ></div>
    <div class="addRep BodyL-Strong">Vuoi aggiungere il ripetitore?</div>
    <div class="repeater__btn">
      <v-btn
        color="#12256A"
        x-large
        width="288"
        height="54"
        class="v-btn--shadowed ButtonM"
        @click="dialogRep = true"
        >Sì, voglio aggiungerlo</v-btn
      >
      <v-btn
        text
        x-large
        color="#12256A"
        class="ButtonL"
        width="300"
        @click="goToProcedureNoRepeater"
        >Non voglio ricevere il ripetitore</v-btn
      >
    </div>
    <v-row class="mt-6 stickyRow" no-gutters v-if="!withModem">
      <v-col>
        <div class="infobox"><span>Non hai aggiunto il modem</span></div>
      </v-col>
    </v-row>
    <v-dialog max-width="555" v-model="dialogRep">
      <div class="rep-dialog">
        <v-row>
          <v-col class="rep-dialog_title-content">
            <span class="HeadingsSubtitleL"
              >Quanti ripetitori vuoi aggiungere?</span
            >
            <img
              class="pointer"
              width="16"
              @click="dialogRep = false"
              src="../../../assets/ic_chevron_top_black.png"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="BodyL">
              Scegli il numero in base alla dimensione della tua casa per
              estendere la copertura.<br />1 - 50/100 mq, 2 - da 100 mq, 3 - da
              150 mq
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-space-between mt-4">
              <div class="select">
                <v-select
                  v-model="quantity"
                  :items="[1, 2, 3]"
                  filled
                  width="260"
                  class="mr-2"
                  background-color="white"
                  label="Quantità"
                ></v-select>
              </div>
              <v-btn
                color="primary"
                x-large
                width="260"
                class="v-btn--shadowed ml-2"
                @click="addRepeater"
                >Conferma</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <v-dialog max-width="555" v-model="dialogDescRep">
      <div class="rep-dialog">
        <v-row>
          <v-col class="rep-dialog_title-content">
            <span class="HeadingsSubtitleL">{{
              repeaterDescription.info_title
            }}</span>
            <img
              class="pointer"
              width="16"
              @click="dialogDescRep = false"
              src="../../../assets/ic_chevron_top_black.png"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="BodyL" v-html="repeaterDescription.info_body"></div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'repeaterComponent',
  data() {
    return {
      quantity: 1,
      dialogRep: false,
      dialogDescRep: false,
      repeaterDescription: {}
    }
  },
  mounted() {
    if (this.withGoogleWiFi !== null) {
      this.quantity = this.withGoogleWiFi > 0 ? this.withGoogleWiFi : 1
    }
    this.getRepeaterDescriptionCMS('CUSTOMER').then((repeater) => {
      repeater.price = repeater.price.replace('.', ',')
      this.repeaterDescription = repeater
    })
  },
  watch: {
    dialogRep(val) {
      if (val) {
        this.trackTapEvent({
          name: 'upsellingv2_getrepeater_tap',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else {
        this.trackTapEvent({
          name: 'upsellingv2_getrepeater_close',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      }
    },
    dialogDescRep() {
      this.trackTapEvent({
        name: 'upsellingv2_repeaterinfo_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['withGoogleWiFi', 'withModem'])
  },
  methods: {
    ...mapMutations('upsellingFiber', ['setWithGoogleWiFi', 'updateFiberCtx']),
    ...mapActions('upsellingFiber', ['getRepeaterDescriptionCMS']),
    ...mapActions('analytics', ['trackTapEvent']),
    goToProcedureNoRepeater() {
      this.setWithGoogleWiFi(0)
      this.trackTapEvent({
        name: 'upsellingv2_skiprepeater_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      this.updateFiberCtx({ has_selected_vas: false })
      this.$emit('nextStep', 'upsellingv2_howtoprocedure_open')
    },
    addRepeater() {
      this.setWithGoogleWiFi(this.quantity)
      this.updateFiberCtx({ has_selected_vas: true })
      this.trackTapEvent({
        name: 'upsellingv2_getrepeater_confirm',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      this.$emit('nextStep', 'upsellingv2_howtoprocedure_open')
    }
  }
}
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.stickyRow {
  position: fixed;
  bottom: 20px;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
  }
  100% {
    opacity: 0;
  }
}

.infobox {
  background-color: #606060;
  color: white;
  width: 300px;
  display: flex;
  padding: 15px 20px 15px 10px;
  border-radius: 5px;
  height: 50px;
  font-size: 14px;
  -webkit-animation: fadeOut 4s;
  animation: fadeOut 4s;
  animation-fill-mode: forwards;
}

.repeater {
  &__intro {
    width: 652px;
    height: 44px;
    letter-spacing: 0.5px;
    //font-size: 20px;
    margin-bottom: 24px;
    //max-width: 652px;
    color: $base-black;
  }

  &__box {
    //border: solid 1px black;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 652px;
    height: 152px;
    padding: 16px;
    margin-bottom: 48px;
    border-radius: 16px;
    background-color: #f8f9fc;

    .title-orange {
      width: 104px;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: $neutri-neutral-30;
      //margin-bottom: 25px;
      span {
        flex: none;
        order: 0;
        flex-grow: 0;
        color: $neutri-neutral-60;
      }
    }

    .img-content {
      display: contents;

      img {
        display: block;
        //max-width:230px;
        max-height: 152px;
        //width: auto;
        //height: auto;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  h3 {
    font-weight: 500;
  }

  &__description {
    max-width: 652px;
    margin-bottom: 48px;
  }

  .addRep {
    //font-size: 20px;
    //font-weight: 500;
    margin-bottom: 32px;
  }

  &__btn {
    display: flex;
    align-items: center;

    .ButtonM {
      color: $base-white;
    }

    button {
      letter-spacing: 0;
      margin-right: 10px;
    }
  }
}
</style>
<style lang="scss">
.rep-dialog {
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  padding: 40px;
  //gap: 16px;

  background: white;
  //padding: 55px 40px 40px 40px;
  border-radius: 20px;
  //position: relative;
  &_title-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  .BodyL ul li {
    position: relative;
    &::marker {
      content: none;
    }
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url('../../../assets/icons/ic-check.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -20px;
      top: -8px;
    }
  }

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .description {
    font-size: 18px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
  }

  //img {
  //  position: absolute;
  //  top: 30px;
  //  right: 30px;
  //  cursor: pointer;

  //&.preview {
  //  position: initial;
  //}
  //}

  .select {
    width: 288px;
  }

  .features {
    max-width: 652px;

    .title {
      font-weight: 500;
      margin-bottom: 16px;
    }

    .item {
      color: black;
      display: flex;
      margin-bottom: 16px;
      margin-left: 40px;
      position: relative;

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('../../../assets/icons/ic-check.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -35px;
        top: -5px;
      }
    }
  }
}
</style>
