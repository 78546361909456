<template>
  <v-expansion-panel
    v-if="isFiberEligible || hasFiberSupplies"
    :readonly="triggerStatus.status === 'empty'"
    :class="`panel ${triggerStatus.status}`"
    @click="openUpsellingFibra(triggerStatus.status)"
  >
    <AccordionTemplate :triggerStatus="triggerStatus" type="fiber">
      <template v-slot:noActive>
        <div class="d-flex flex-column justify-space-between">
          <div
            :class="`supply__btn ${triggerStatus.status} d-flex align-center justify-space-between`"
            @click="
              $emit('openSuppliesDialog', 'fiber', triggersDisabled.status)
            "
          >
            <div class="action">
              <div class="type">{{ triggersDisabled.cta }}</div>
              <div class="desc" v-html="triggersDisabled.desc" />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:timeline>
        <div class="d-flex flex-column justify-space-between">
          <div
            :class="`supply__btn ${triggerStatus.status} d-flex align-center justify-space-between`"
            @click="
              $emit('openSuppliesDialog', 'fiber', triggersTimeline.status)
            "
          >
            <div class="action">
              <div class="type">{{ triggersTimeline.cta }}</div>
              <div class="desc" v-html="triggersTimeline.desc" />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:active>
        <div class="d-flex flex-column justify-space-between">
          <div
            :class="`supply__btn ${triggersPayment.status} d-flex align-center justify-space-between`"
            @click="$emit('openSuppliesDialog', 'fiber', 'payment')"
          >
            <div class="action">
              <div class="type">{{ triggersPayment.cta }}</div>
              <div class="desc" v-html="triggersPayment.desc" />
            </div>
          </div>
          <div
            class="supply__btn regular d-flex align-center justify-space-between"
            @click="$emit('openSuppliesDialog', 'fiber', 'consumption')"
          >
            <div class="action">
              <div class="type">CONSUMI ILLIMITATI</div>
              <div class="desc">Naviga con fibra ultraveloce</div>
            </div>
          </div>
        </div>
        <!--
        il myShop è disabilitato dal CMS quindi questo div non viene mai mostrato
        -->
        <div class="supply__advice" v-if="showMyShop" @click="openMyShop">
          <div class="action">
            <div class="type">MyShop Sorgenia</div>
            <div class="desc">
              Negozio di prodotti intelligenti e sostenibili
            </div>
            <div class="btn">Acquista ripetitore WI-FI</div>
          </div>
        </div>
        <div class="supply__parental" @click="goToParentalControl">
          <div class="action">
            <div class="type">Loro navigano, tu rilassati</div>
            <div class="desc">
              È in arrivo il Parental Control, per garantire una navigazione
              sicura a tutta la famiglia in modo facile e veloce.
            </div>
            <div class="btn">Scopri il Parental Control</div>
          </div>
        </div>
      </template>
    </AccordionTemplate>
  </v-expansion-panel>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AccordionTemplate from './Template.vue'
import { eventBus } from '@/main'
import Constants from '@/js/constants'
export default {
  name: 'accordionFiber',
  props: [
    'triggerStatus',
    'triggersDisabled',
    'triggersTimeline',
    'triggersPayment',
    'triggersConsumption'
  ],
  components: {
    AccordionTemplate
  },
  data() {
    return {
      SERVICE_TYPE: Constants.SERVICE_TYPE
    }
  },
  computed: {
    ...mapGetters('account', ['accountProperties']),
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('myshop', ['myShopEnabled']),
    showMyShop() {
      return this.myShopEnabled
    },
    isFiberEligible() {
      return this.accountProperties.isFiberEligible
    },
    hasFiberSupplies() {
      return (
        this.supplies.filter(
          (sup) => sup.serviceType === this.SERVICE_TYPE.FIBER
        ).length > 0
      )
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    openUpsellingFibra(status) {
      let statusDecode = {
        regular: 'regular',
        irregular: 'not_regular',
        timeline: 'activating',
        noActive: 'inactive'
      }
      if (status === 'empty') {
        this.trackTapEvent({
          name: 'home_upselling_tap',
          params: {
            supply_type: 'fiber',
            has_fiber:
              this.accountProperties.hasFiberActive ||
              this.accountProperties.hasFiberActivating
          }
        })
        this.$router.push({ name: 'fiberOffering' })
      } else {
        this.trackTapEvent({
          name: 'home_accordion_tap',
          params: { supply_type: 'fiber', status: statusDecode[status] }
        })
      }
    },
    openMyShop() {
      this.trackTapEvent({
        name: 'home_alertCTA_tap',
        params: { supply_type: 'fiber', cta_title: 'Acquista ripetitore WI-FI' }
      })
      eventBus.$emit('myshop:open')
    },
    goToParentalControl() {
      this.$router.push({ name: 'ownerSupplies' })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './accordionStyle.scss';
</style>
