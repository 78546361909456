<template>
  <div class="videoPayerSupport d-flex flex-wrap">
    <div class="videoContainer" v-for="(m, key) in media" :key="key">
      <div
        class="thumb"
        :style="{ backgroundImage: `url('${CmsUrl + m.preview_image}')` }"
        @click="setVideoUrl(m)"
      ></div>
      <div class="tag">{{ m.media_type }}</div>
      <div class="title">{{ m.title }}</div>
    </div>
    <v-dialog
      v-model="dialogVideo"
      max-width="725"
      max-height="408"
      @close="close"
      content-class="customdialog"
    >
      <VideoPlayer
        :videoUrl="videoUrl"
        @ended="dialogVideo = false"
        ref="videoPlayer"
        :autoplay="true"
      />
    </v-dialog>
  </div>
</template>
<script>
import VideoPlayer from '@/components/videoPlayer'
import { ENV_CONFIGS } from '@/js/configs.js'
import { mapActions } from 'vuex'

export default {
  name: 'VideoPlayerSupport',
  props: {
    media: { type: Array }
  },
  data() {
    return {
      dialogVideo: false,
      CmsUrl: ENV_CONFIGS.CMS_CDN_BASE_URL,
      videoUrl: ''
    }
  },
  watch: {
    dialogVideo(val) {
      if (val) {
      }
      val || this.close()
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    setVideoUrl(media) {
      this.videoUrl = media.media_file
      this.dialogVideo = true
      this.trackTapEvent({
        name: 'support_mediaContentPlay_tap',
        params: { support_content_id: media.id }
      })
      // this.$refs.videoPlayer.play()
    },
    close() {
      this.$refs.videoPlayer.close()
    }
  },
  components: {
    VideoPlayer
  }
}
</script>
<style lang="scss" scoped>
.videoPayerSupport {
  margin-bottom: 100px;
  .videoContainer {
    max-width: 350px;
    margin-right: 30px;
  }
  .thumb {
    width: 350px;
    height: 202px;
    border-radius: 20px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 30px;
    &::after {
      content: url('../../assets/play-button-big-white.png');
      width: 64px;
      position: absolute;
      top: calc(50% - 42px);
      left: calc(50% - 42px);
      cursor: pointer;
    }
  }
  .tag {
    font-size: 12px;
    color: #d93273;
    margin-bottom: 5px;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
  }
}
</style>

<style>
.customdialog {
  border-radius: 0px !important;
}
</style>
