<template>
  <layout header-size="full">
    <template v-slot:header>
      <v-col cols="7" :class="`${success ? 'img-man' : 'img-girl'} pa-0`" />
      <v-col v-if="!coinsBack" class="content-margin" cols="5">
        <h1 class="black--text mb-8">
          {{
            success ? 'Complimenti\n hai vinto!' : 'Purtroppo non\n hai vinto!'
          }}
        </h1>
        <v-img
          v-if="success && rewardData"
          contain
          class="card__image"
          height="235"
          width="350"
          :src="rewardData.images.data[0].image_small"
        />
        <h3 class="font-weight-bold black--text mb-10">
          {{
            success
              ? rewardData && rewardData.title
              : '…ma un Greener non si arrende al primo\n ostacolo!'
          }}
        </h3>
        <v-btn
          class="v-btn--shadowed btn normal"
          x-large
          :width="265"
          @click="
            success
              ? $router.push({
                  name: rewardData.is_required_shipping
                    ? 'giftShipping'
                    : 'giftEmailShipping',
                  params: {
                    catalog: !coinsBack
                      ? 'IW'
                      : mainClientLoyaltySelected.clientOwnerType === 'RES'
                      ? 'COLLECTION'
                      : 'COLLECTION-BS',
                    id: rewardData.prizeoption_id
                  }
                })
              : $router.push({ name: 'loyaltyPage' })
          "
        >
          {{ success ? 'Si voglio il premio' : 'Vai al Diario' }}
        </v-btn>
      </v-col>

      <v-col v-if="coinsBack" class="content-margin" cols="5">
        <v-img
          v-if="success"
          class="card__image"
          height="103"
          width="103"
          src="@/assets/ic_greencoins_large_white.png"
        />
        <h1 class="black--text mb-10">
          {{
            success
              ? `Hai ottenuto\n ${coinsReward}\nGreen Coins`
              : 'Il codice\n sembra essere\n non valido o\n già utilizzato!'
          }}
        </h1>
        <v-btn
          v-if="!success"
          class="v-btn--shadowed btn normal mb-9"
          x-large
          :width="265"
          @click="$router.push({ name: 'giftCode' })"
        >
          Riprova
        </v-btn>
        <v-btn
          class="v-btn--shadowed btn normal"
          :class="success ? '' : 'black--text'"
          :outlined="!success"
          x-large
          :width="265"
          @click="$router.push({ name: 'loyaltyPage' })"
        >
          Vai al Diario
        </v-btn>
      </v-col>
    </template>
  </layout>
</template>

<script>
import Layout from '../Layout'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CodeResultPage',
  components: {
    layout: Layout
  },
  beforeMount() {
    if (this.success) {
      this.getMainClient().then((resp) => {
        if (this.mainClientLoyaltySelected) {
          resp = this.mainClientLoyaltySelected
        }
        this.getDetailForClient({ clientOwnerCode: resp.clientOwnerCode }).then(
          (res) => {
            this.getReward().then((res) => {
              this.rewardData = res
            })
          }
        )
      })
    }
  },
  data: () => ({
    rewardData: null
  }),
  computed: {
    ...mapGetters('loyalty', ['catalogList']),
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    coinsBack() {
      return this.$route.params.coinsBack !== 'false'
    },
    success() {
      return this.$route.params.success === 'true'
    },
    coinsReward() {
      return this.$route.params.coins
    }
  },
  methods: {
    ...mapActions('loyalty', ['getReward']),
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    navigate() {
      if (this.success && !this.coinsBack) {
        this.$router.push({
          name: 'giftEmailShipping',
          params: { id: this.rewardData.prizeoption_id }
        })
      } else if (this.success && this.coinsBack) {
        this.$router.push({ name: 'loyaltyPage' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-margin {
  margin-top: 105px;
  h3 {
    font-size: 24px;
  }
  .card__image {
    border-radius: 16px;
    margin-bottom: 36px;
  }
  .normal {
    letter-spacing: normal;
    font-weight: bold;
  }
}
</style>
