<template>
  <div class="login">
    <TcVars />
    <div class="login__content">
      <img
        src="../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <div class="login__box scrollable">
        <template>
          <div class="login__title HeadingsSubtitleXXL">
            Ti diamo il benvenuto
          </div>
          <div class="BodyL">
            Inquadra il QR code e accedi all’app per unirti alla comunity
            Greeners e avere il mondo Sorgenia a portata di mano
          </div>
        </template>
        <div class="d-flex justify-center mt-4">
          <img
            src="../assets/login/QRCodeProspect.png"
            width="158"
            class="mb-0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'LoginProspectQRCode',
  mounted() {
    this.trackTapEvent({ name: 'qr_code_prospect' })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  },
  components: {
    TcVars
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/authentication/auth.scss';
</style>
