<template>
  <div class="offer" v-if="type">
    <div class="d-flex align-center mb-6">
      <div :class="`offer__icon ${type}`"></div>
      <div class="offer__text">
        <div class="HeadingL" v-html="item[type].title" />
      </div>
    </div>
    <div class="offer__desc BodyL-Strong mb-2">
      <strong v-html="item[type].subTitle" />
    </div>
    <div class="offer__desc BodyM mb-8" v-html="item[type].desc" />
    <div class="mt-auto btn-custom">
      <v-btn
        class="primary--text v-btn--shadowed"
        x-large
        @click="trackTapEvent(item[type].sonda)"
        :href="
          clientDetails.tgTurnedMLFlag == true
            ? item[type].linkTutele
            : item[type].link
        "
        target="_blank"
        >{{ item[type].cta }}</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'offerCardItem',
  props: {
    type: { type: String }
  },
  computed: {
    ...mapGetters('account', ['clientDetails'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  },
  data() {
    return {
      item: {
        fotovoltaico: {
          title: 'Fotovoltaico',
          subTitle: 'Una nuova energia per la tua casa',
          desc: 'Scopri i benefici dell’autoproduzione di parte dell’energia che consumi e migliora la classe energetica della tua casa',
          descSmall: '<strong>Una nuova energia per la tua casa</strong>',
          cta: 'Configura il tuo impianto',
          linkTutele: 'https://www.sorgenia.it/verifica-copertura-fotovoltaico',
          link: 'https://www.sorgenia.it/verifica-copertura-fotovoltaico',
          linkTutele: 'https://www.sorgenia.it/verifica-copertura-fotovoltaico',
          sonda: 'home_crossselling_fotovoltaico'
        },
        business: {
          title: 'Per il tuo business',
          subTitle: 'Dai energia alla tua attività',
          desc: 'Scegli la soluzione su misura per le tue necessità e libera risorse da reinvestire nel business che conta di più per te',
          descSmall: '<strong>Dai energia alla tua attività</strong>',
          cta: 'Vai all’offerta',
          linkTutele: 'https://www.sorgenia.it/offerte-luce-e-gas-business',
          link: 'https://www.sorgenia.it/offerte-luce-e-gas-business',
          linkTutele: 'https://www.sorgenia.it/offerte-luce-e-gas-business',
          sonda: 'home_crossselling_business'
        },
        casa: {
          title: 'Per la tua casa',
          subTitle: 'Tutta la forza dell’energia verde',
          desc: 'Scegli la soluzione su misura per le tue necessità e libera risorse da reinvestire nell’energia della tua casa',
          descSmall: '<strong>Tutta la forza dell’energia verde</strong>',
          cta: 'Vai all’offerta',
          link: 'https://www.sorgenia.it/offerte-luce-e-gas-casa',
          linkTutele:
            'https://casa.sorgenia.it/content/sorgenia-fe/residential/home.html?campaign_code=upselling_tg_res_app',
          sonda: 'home_crossselling_casa'
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.offer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 275px;
  background: $neutri-neutral-20;
  border-radius: 15px;

  &.small {
    min-height: auto;
  }
  padding: 24px;
  &__icon {
    width: 32px;
    height: 32px;
    background-size: contain;
    margin-right: 16px;
    &.fotovoltaico {
      background-image: url('../../../assets/icons/fotovoltaico.svg');
    }
    &.casa {
      background-image: url('../../../assets/icons/ic-owner-res.svg');
    }
    &.business {
      background-image: url('../../../assets/icons/business.svg');
    }
  }
  &__desc {
    color: black;
  }
  &__price {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: black;
    letter-spacing: 0.6;
    span {
      font-size: 14px;
      font-weight: 100;
    }
  }
  .btn-custom {
    border: 1px solid #dfe4ef !important;
    background-color: white;
    font-weight: bold;
    width: 312px;
    cursor: pointer;
    text-transform: initial;
    font-size: 18px !important;
    letter-spacing: 1.2px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #f7faff !important;
      opacity: 1;
    }

    a {
      border: none !important;
      background: transparent;
      border-radius: 0;
      outline: none;
      box-shadow: none !important;
      &:hover {
        &:before {
          opacity: 0;
        }
      }

      &:hover {
        background: transparent !important;
      }
    }
  }
  .v-btn {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: initial;
    font-size: 18px;
    letter-spacing: 1.2px;
    &::after {
      content: url('../../../assets/icons/active_small.svg');
      box-shadow: initial;
      margin-left: 8px;
      margin-top: 6px;
    }
  }
}
</style>
<style lang="scss">
.offer {
  .v-btn {
    span {
      flex: none !important;
    }
  }
}
</style>
