var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"successUpload"},[_vm._m(0),_c('div',{staticClass:"successUpload__content std-side-padding topRadius pt-10"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Richiesta inviata!")]),_c('h3',{staticClass:"mb-4"},[_vm._v("Sarà nostra cura contattarti al più presto")]),_c('div',{staticClass:"desc mb-10"},[_vm._v(" Un nostro operatore ti contatterà il prima possibile per continuare l’attivazione della Fibra con il suo supporto telefonico. ")]),_c('v-btn',{staticClass:"v-btn--shadowed",attrs:{"color":"primary","x-large":"","width":"262"},on:{"click":function($event){_vm.trackTapEvent({
          name: 'upsellingv2_thankyoupagecallmeback_back',
          params: {
            client_selected:
              _vm.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        }),
          _vm.$router.push({ name: 'home' })}}},[_vm._v("Torna all'homepage")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"successUpload__header"},[_c('div',{staticClass:"successUpload__title"},[_vm._v("Digitale al servizio delle persone")])])
}]

export { render, staticRenderFns }