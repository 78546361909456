<template>
  <div
    class="answer_box"
    :style="`width: calc( 100% / ${perRow} - ${margin * 2}px)`"
  >
    <div class="answer_text">{{ answer.label }}</div>
    <v-slider
      v-model="value"
      :min="min"
      :max="max"
      :step="step"
      color="#4fd0ff"
      track-color="#4fd0ff"
      track-fill-color="#fff"
      @change="onChanged()"
      thumb-label="always"
    >
      <template v-slot:thumb-label>
        <div class="talkbubble px-2">
          <span class="answer_text">{{ valueTexts[value] }}</span>
        </div>
      </template>
    </v-slider>
  </div>
</template>

<script>
export default {
  name: 'FeedbackSlider',
  data() {
    return {
      value: 0,
      valueTexts: [],
      min: 0,
      max: 5,
      step: 1
    }
  },
  props: {
    answer: { type: Object },
    onSliderChanged: { type: Function }
  },
  inject: ['options'],
  computed: {
    perRow() {
      return this.options.perRow
    },
    margin() {
      return this.options.margin
    }
  },
  methods: {
    onChanged() {
      this.onSliderChanged(
        this.answer.id,
        this.valueTexts[this.value],
        'slider_value'
      )
    }
  },
  created() {
    this.valueTexts = this.answer.scale_values.trim().split(',')
    this.max = this.valueTexts.length - 1

    if (this.answer.slider_value === null) {
      this.onChanged()
    } else {
      this.value = this.valueTexts.indexOf(this.answer.slider_value)
    }
  }
}
</script>

<style lang="scss" scoped>
.answer_box {
  min-height: 230px;
  margin: 10px;
  display: flex;
  flex-direction: column;

  background: #f6f6f6;
  padding: 9px 64px 23px 64px;
  border-radius: 20px;
  max-height: 225px;
}

.talkbubble {
  min-width: 50px;
  height: 32px;
  background: white;
  position: relative;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  transform: translateY(-60%);
  text-align: center;
  padding-top: 2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14),
    0 10px 35px 0 rgba(219, 219, 219, 0.3);

  &:before {
    content: '';
    position: absolute;
    top: 30px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 11px solid white;
    transform: translateX(-50%);
  }
}

.answer_text {
  color: black;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 32px;
  font-size: 18px;
  height: 40%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .answer_box {
    max-height: initial;
  }
  .answer_text {
    height: initial;
    font-size: 16px;
  }
  .answer_box {
    padding: 17px 14px 18px 18px;
  }
}
</style>
<style lang="scss">
.v-slider__thumb-label-container > div {
  border: unset !important;
}
</style>
