<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters>
          <v-col class="text-center">
            <template v-if="!loadingMethods && !loadingClientPM">
              <v-row>
                <v-col
                  v-if="!methodsList.length"
                  class="no_result std-panel mt-0"
                >
                  <p class="font-weight-medium">
                    Nessun metodo di pagamento disponibile. Per favore, aggiungi
                    un nuovo metodo di pagamento.
                  </p>
                </v-col>
                <v-col
                  v-if="methodsList.length && !filteredMethodList.length"
                  class="no_result std-panel mt-0"
                >
                  <p class="font-weight-medium">
                    Non sono presenti metodi di pagamento
                  </p>
                </v-col>
              </v-row>
              <ExtensibleButtonGroup
                v-if="filteredMethodList.length"
                v-model="paymentMethod"
                :per-row="3"
                groupName="paymentMethod"
                size="md"
                :content="false"
              >
                <ExtensibleButton
                  :name="meth"
                  v-for="(meth, i) in filteredMethodList"
                  :key="i"
                >
                  <template
                    v-slot:label
                    v-if="meth.methodType === PAYMENT_METHODS.CREDIT_CARD"
                  >
                    <div class="typeMdp">Carta di credito</div>
                    <div class="codeMdp">{{ meth.creditCardNumber }}</div>
                  </template>
                  <template v-slot:label v-else>
                    <div class="typeMdp">Addebito in conto corrente</div>
                    <div class="codeMdp">{{ meth.iban }}</div>
                  </template>
                </ExtensibleButton>
              </ExtensibleButtonGroup>
            </template>
            <v-progress-circular :size="50" indeterminate v-else />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'

import Consts from '@/js/constants'
import { eventBus } from '@/main'

export default {
  name: 'paymentMethod',
  props: ['client', 'cancelOption'],
  data() {
    return {
      loadingClientPM: true,
      loadingMethods: true,
      methodsList: [],
      PAYMENT_METHODS: Consts.PAYMENT_METHODS
    }
  },
  computed: {
    ...mapGetters({ supplyDetail: 'supply/supplyDetail' }),
    paymentMethod: {
      get() {
        return this.$store.getters['upsellingFiber/paymentMethod']
      },
      set(newVal) {
        this.setPaymentMethod(newVal)
        if (newVal) this.$emit('openConfirmDialog')
      }
    },
    filteredMethodList() {
      if (
        this.methodsList.length &&
        this.supplyDetail &&
        this.supplyDetail[this.$route.params.supplyCode] &&
        this.supplyDetail[this.$route.params.supplyCode]['mdp']
      ) {
        let activeMethod =
          this.supplyDetail[this.$route.params.supplyCode]['mdp'][0] || {}
        return this.methodsList.filter((meth) => {
          if (meth.iban) {
            return meth.iban !== activeMethod.iban
          } else if (meth.creditCardNumber) {
            return (
              meth.creditCardNumber !== activeMethod.numeroCarta &&
              new Date() <
                new Date(
                  ...meth.creditCardExpirationDate
                    .split('/')
                    .reverse()
                    .concat('0')
                )
            )
          }
          return false
        })
      } else {
        return this.methodsList
      }
    }
  },
  watch: {
    cancelOption() {
      if (this.cancelOption === true) {
        this.paymentMethod = null
        this.$emit('resetOption')
      }
    },
    paymentMethod(value) {
      this.$emit('update:paymentOptionData', value)
    },
    supplyDetail: {
      handler: function (val) {
        if (val[this.$route.params.supplyCode]['mdp'][0])
          this.loadingClientPM = false
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // TODO maybe we should have a common flow for all 3 commodities. OR we should create separate one for gas& light. OR shouldn't
    ...mapMutations('upsellingFiber', ['setPaymentMethod']),
    ...mapActions('payment', ['getMethods']),
    validatationHandler() {
      const result = !!this.paymentMethod
      eventBus.$emit('upsell-fiber:validation-completed', {
        component: 'paymentMethod',
        isValid: result
      })
      return result
    }
  },
  created() {
    eventBus.$on('upsell-fiber:validate-inputs', this.validatationHandler)
  },
  beforeUnmount() {
    eventBus.$off('upsell-fiber:validate-inputs', this.validatationHandler)
  },
  beforeMount() {
    if (this.client) {
      this.getMethods({ clientCode: this.client.clientOwnerCode }).then(
        (methods) => {
          this.methodsList = methods.filter(
            (meth) =>
              meth.methodType === this.PAYMENT_METHODS.CREDIT_CARD ||
              meth.methodType === this.PAYMENT_METHODS.BANK_ACCOUNT
          )
          this.loadingMethods = false
        }
      )
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadingClientPM = false
    }, 4000)
  }
}
</script>
<style lang="scss" scoped>
.no_result {
  background-color: #f6f6f6;
  min-height: 150px;
  display: flex;
  justify-content: center;
  p {
    align-self: center;
    margin: 0;
  }
}
</style>
