<template>
  <div>
    <div class="headerSelect">
      <div class="HeadingsSubtitleL">Seleziona l'{{ impiantoOrUtenza }}</div>
      <img
        src="../../assets/icons/ic-close.svg"
        width="16"
        @click="setOpenSelectModal()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogHeader',
  props: {
    impiantoOrUtenza: { type: String }
  },
  methods: {
    setOpenSelectModal() {
      this.$emit('setOpenSelectModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.headerSelect {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  img {
    cursor: pointer;
  }
}
</style>
