import { render, staticRenderFns } from "./Data.vue?vue&type=template&id=4788a6a9&scoped=true"
import script from "./Data.vue?vue&type=script&lang=js"
export * from "./Data.vue?vue&type=script&lang=js"
import style0 from "./Data.vue?vue&type=style&index=0&id=4788a6a9&prod&lang=scss&scoped=true"
import style1 from "./Data.vue?vue&type=style&index=1&id=4788a6a9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4788a6a9",
  null
  
)

export default component.exports