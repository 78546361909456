import { axiosCMS } from '@/js/axiosInstances.js'
import { ENV_CONFIGS } from '@/js/configs.js'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    notices: [],
    noticeSkipped: false
  },
  getters: {
    notices(state) {
      return state.notices
    },
    noticeSkipped(state) {
      return state.noticeSkipped
    }
  },
  mutations: {
    setNotices(state, notices) {
      state.notices = notices
    },
    setNoticeSkipped(state, skipped) {
      state.noticeSkipped = skipped
    }
  },
  actions: {
    getNotices({ commit }) {
      axiosCMS.get('/notice?channel=WEB').then((resp) => {
        let noticeList = []

        // NOTE: we are adding a Z to dates to tell the date is in UTC
        if (resp.data && resp.data.length && resp.data.length > 0) {
          noticeList = resp.data
          const currentTime = new Date().getTime()

          // filter out notices not active now
          noticeList = noticeList.filter((not) => {
            const start = new Date(not.start_date + 'Z').getTime()
            const end = new Date(not.end_date + 'Z').getTime()

            return currentTime > start && currentTime < end
          })

          // sort filtered notices by descending start date
          noticeList = noticeList.sort((notA, notB) => {
            const startA = new Date(notA.start_date + 'Z').getTime()
            const startB = new Date(notB.end_date + 'Z').getTime()

            return startA > startB
          })

          // Make skippable boolean
          for (let not of noticeList) {
            not.skippable = not.skippable === '1'
          }
        }
        if (ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
          console.dir(noticeList)
        } else {
          commit('setNotices', noticeList)
        }
      })
    }
  }
}
