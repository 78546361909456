import { axiosACN } from '@/js/axiosInstances.js'
import Consts from '@/js/constants'
import { getMillisFromStringDate } from '@/js/utils'

const rulesTypeBills = {
  'Fattura manuale': {
    'Canone RAI – Rimbors': 'Rimborso canone RAI',
    'Canone Rai FC': 'Canone RAI',
    'Fatt. beni/servizi': 'Beni / Servizi',
    'Fatturazione CMOR': 'Corrispettivo morosità',
    'Fatturazione Ind': 'Indennizzo',
    'Fatturazione Ind 100/2016 Fatt.Chiusura': 'Indennizzo',
    'Fatturazione Ind 463/2016 TIF': 'Indennizzo',
    'Fatturazione MDC': 'Lavori tecnici',
    'Indennizzi Del.463 – Fatture di periodo': 'Indennizzo',
    'Manual Billing': 'Altre partite'
  },
  Fattura: {
    'Fatturazione RES': {
      'Fattura ciclo ordinario ELE': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio ELE': 'Bolletta con ricalcoli',
      'Fattura ciclo ordinario GAS': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio GAS': 'Bolletta con ricalcoli'
    },
    'Fatturazione MM': {
      'Fattura ciclo ordinario ELE': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio ELE': 'Bolletta con ricalcoli',
      'Fattura ciclo ordinario GAS': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio GAS': 'Bolletta con ricalcoli'
    },
    'Fatturazione Large': {
      'Fattura ciclo ordinario ELE': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio ELE': 'Bolletta con ricalcoli',
      'Fattura ciclo ordinario GAS': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio GAS': 'Bolletta con ricalcoli'
    },
    undefined: {
      Fiber: 'Bolletta'
    }
  },
  'Fine nota': {
    'Fatturazione RES': {
      'Fattura ciclo ordinario ELE': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio ELE': 'Bolletta con ricalcoli',
      'Fattura ciclo ordinario GAS': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio GAS': 'Bolletta con ricalcoli'
    },
    'Fatturazione MM': {
      'Fattura ciclo ordinario ELE': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio ELE': 'Bolletta con ricalcoli',
      'Fattura ciclo ordinario GAS': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio GAS': 'Bolletta con ricalcoli'
    },
    'Fatturazione Large': {
      'Fattura ciclo ordinario ELE': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio ELE': 'Bolletta con ricalcoli',
      'Fattura ciclo ordinario GAS': 'Bolletta',
      'Fattura ciclo ordinario con conguaglio GAS': 'Bolletta con ricalcoli'
    }
  },
  'Nota di correzione': {
    'Fatturazione RES': {
      'Nota di corerezione ELE': 'Ricalcoli',
      'Fattura ultima ELE': 'Ricalcoli',
      'Nota di corerezione GAS': 'Ricalcoli',
      'Fattura ultima GAS': 'Ricalcoli'
    },
    'Fatturazione MM': {
      'Nota di corerezione ELE': 'Ricalcoli',
      'Fattura ultima ELE': 'Ricalcoli',
      'Nota di corerezione GAS': 'Ricalcoli',
      'Fattura ultima GAS': 'Ricalcoli'
    },
    'Fatturazione Large': {
      'Nota di corerezione ELE': 'Ricalcoli',
      'Fattura ultima ELE': 'Ricalcoli',
      'Nota di corerezione GAS': 'Ricalcoli',
      'Fattura ultima GAS': 'Ricalcoli'
    }
  },
  'Fattura ultima': {
    'Fatturazione RES': {
      'Nota di corerezione ELE': 'Ricalcoli',
      'Fattura ultima ELE': 'Ricalcoli',
      'Nota di corerezione GAS': 'Ricalcoli',
      'Fattura ultima GAS': 'Ricalcoli'
    },
    'Fatturazione MM': {
      'Nota di corerezione ELE': 'Ricalcoli',
      'Fattura ultima ELE': 'Ricalcoli',
      'Nota di corerezione GAS': 'Ricalcoli',
      'Fattura ultima GAS': 'Ricalcoli'
    },
    'Fatturazione Large': {
      'Nota di corerezione ELE': 'Ricalcoli',
      'Fattura ultima ELE': 'Ricalcoli',
      'Nota di corerezione GAS': 'Ricalcoli',
      'Fattura ultima GAS': 'Ricalcoli'
    }
  },
  'Nota di accredito': {
    'Fatturazione RES': {
      'Riaccredito bolletta ELE': 'Riaccredito bolletta',
      'Riaccredito bolletta GAS': 'Riaccredito bolletta'
    },
    'Fatturazione MM': {
      'Riaccredito bolletta ELE': 'Riaccredito bolletta',
      'Riaccredito bolletta GAS': 'Riaccredito bolletta'
    },
    'Fatturazione Large': {
      'Riaccredito bolletta ELE': 'Riaccredito bolletta',
      'Riaccredito bolletta GAS': 'Riaccredito bolletta'
    }
  }
}
const getStartDate = (val) => {
  let validDate = val.split('/')
  let date = new Date(validDate[2] + '/' + validDate[1] + '/' + validDate[0])
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) +
    '-01'
  )
}
const getEndDate = (val) => {
  // let validDate = val.split('/')
  // let date = new Date(validDate[2] + '/' + validDate[1] + '/' + validDate[0])
  // date.setMonth(date.getMonth() + 1)
  let date = new Date()
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) +
    '-01'
  )
}
async function retrieveBill(commit, rootGetters, params) {
  try {
    let billDetail = await axiosACN.post(
      '/bollette/V3/dettaglioBolletta',
      {
        codicePunto: params.supplyCode,
        codiceCliente: params.clientOwnerCode,
        idFattura: params.idFattura
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    commit('setBillDetail', {
      bill: billDetail.data.listBollette[0]
    })
    let billConsumption = await axiosACN.post(
      '/fornitura/retrieveDettaglioFornituraConsumi',
      {
        codicePunto: params.supplyCode,
        startDate: getStartDate(
          billDetail.data.listBollette[0].dataInizioCompetenza
        ),
        endDate: getEndDate(),
        username: rootGetters['session/username']
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    commit('setConsumptions', {
      idFattura: params.idFattura,
      consumptions: billConsumption.data.consumi.list
    })
    let billCost = await axiosACN.post(
      '/bollette/V2/costibolletta',
      {
        codicePunto: params.supplyCode,
        idFattura: params.idFattura,
        username: rootGetters['session/username']
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    commit('setCost', {
      idFattura: params.idFattura,
      cost: billCost.data
    })
    let endPoint =
      billDetail.data.listBollette[0].commodity === 'Gas'
        ? 'V2/verificaAutoLetturaGas'
        : 'verificaAutoLetturaEle'
    let dataSelfReading = await axiosACN.post(
      '/fornitura/' + endPoint,
      {
        codicePR: params.supplyCode,
        codiceCliente: params.clientOwnerCode,
        codiceUtenza: params.supplyCode.replace('PR', '').replace('pr', ''),
        canale: 'WEB'
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    if (dataSelfReading.data.status === 'OK') {
      commit('setSelfReading', {
        idFattura: params.idFattura,
        selfReading: dataSelfReading.data
      })
    } else {
      throw Error('UNKNOWN_ERROR')
    }
  } catch (err) {
    if (err.statusCode && err.statusCode >= 400) {
      throw Error('SERVER_ERROR')
    } else if (err.message) {
      throw err
    }
  }
}

async function retrieveBillPaid(commit, rootGetters, bill) {
  return axiosACN
    .post(
      '/bollette/isFatturaPagata',
      {
        codiceCliente: bill.codiceCliente || bill.clientOwnerCode,
        idFattura: bill.idFattura
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((billPaid) => {
      if (billPaid.status && billPaid.status >= 400) {
        throw Error('SERVER_ERROR')
      }

      // Manage business logic errors (coming with status 200 OK + errorcode)
      // if (billPaid.data.errorCode === '402') { // No bus logic errors expected
      //     throw Error('CLIENT_BILL_NOMATCH')
      // }

      // RULE IS: for any 200 OK, if we have status OK and a date, return true and date, otherwise fale and null
      const isPaid = !!(
        billPaid.data.status === 'OK' &&
        billPaid.data.dataPagamento &&
        billPaid.data.dataPagamento !== ''
      )
      const paymDate = isPaid ? billPaid.data.dataPagamento : null

      return {
        isPaid: isPaid,
        paymentDate: paymDate
      }
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

async function retrieveBillingSummary(commit, rootGetters, data) {
  return axiosACN
    .post(
      '/bollette/V4/retrieveBillingSummary',
      {
        codiceCliente: data.clientCode,
        startDate: data.startDate,
        endDate: data.endDate,
        idSintesi: data.idSintesi
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((billsSummary) => {
      return billsSummary.data
    })
}

// Servizio che permette di recuperare gli importi di sintesi per sei classi definite di rate (*),
// restituendone l’importo totale e la lista di rate appartenenti alla classe.
async function retrieveBillingSummaryPdr(commit, rootGetters, data) {
  return axiosACN
    .post(
      '/bollette/retrieveBillingSummaryInstalments',
      {
        clientCode: data.clientCode,
        startDate: data.startDate,
        endDate: data.endDate,
        summaryId: data.summaryId
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      if (data.summaryId === '5') {
        commit('setPDRPayment', res.data)
      }
      return res.data
    })
    .catch((err) => {
      // HTTP code Description
      // 500	500	Internal Server error
      // 400	400	Bad Request
      // 400	1030	codiceCliente is mandatory
      // 400	1024	startDate is mandatory
      // 400	1032	startDate not valid
      // 400	1025	endDate is mandatory
      // 400	1033	endDate not valid
      // 400	1023	summaryId is mandatory
      // 400	1026	summaryId not valid

      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    typeBills: rulesTypeBills,
    bill: null,
    pdrPayment: []
  },
  getters: {
    typeBills: (state) => state.typeBills,
    billDetail(state) {
      return state.bill
    },
    pdrDetail(state) {
      let pdrList = {}
      state.pdrPayment.forEach((data) => {
        data.instalmentsList.forEach((instalment) => {
          if (
            instalment.plan.planStatus === Consts.STATUS_PDR.ACTIVE &&
            instalment.instalmentStatus !== Consts.STATUS_PDR.PAYED
          ) {
            if (Object.keys(pdrList).includes(instalment.plan.planId)) {
              pdrList[instalment.plan.planId].instalments.push(instalment)
            } else {
              pdrList[instalment.plan.planId] = {
                ...instalment.plan,
                instalments: [instalment]
              }
            }
          }
        })
      })
      return pdrList
    }
  },
  mutations: {
    setBillDetail(state, data) {
      const billDetail = {
        idFattura: data.bill.idFattura,
        clientOwnerCode: data.bill.codiceCliente || '',
        supplyCode: data.bill.prCode || '',
        dataInizioCompetenza: data.bill.dataInizioCompetenza || '',
        dataEmissione: data.bill.dataEmissione || '',
        dataScadenza: data.bill.dataScadenza || '',
        tipoFattura: data.bill.tipoFattura || '',
        tipoOLD: data.bill.tipoOLD || '',
        gruppoFatturazione: data.bill.gruppoFatturazione || '',
        gruppoFatturazioneOLD: data.bill.gruppoFatturazioneOLD,
        commodity: data.bill.commodity || '',
        ricalcolo: data.bill.ricalcolo || '',
        importo: data.bill.importo,
        idStato: data.bill.idStato || '',
        firstname: data.bill.nome || '',
        lastname: data.bill.cognome || '',
        ragioneSociale: data.bill.ragioneSociale || '',
        street: data.bill.street || '',
        streetnumber: data.bill.streetnumber || '',
        city: data.bill.city || '',
        flagPagamentoRata: data.bill.flagPagamentoRata,
        dataPagamento: data.bill.dataPagamento,
        statoFattura: data.bill.statoFattura,
        importoParzialePagato: data.bill.importoParzialePagato,
        importoParzialeDaPagare: data.bill.importoParzialeDaPagare,
        consumptions: [],
        cost: {},
        selfReading: null,
        mdp: data.bill.mdp
      }
      state.bill = state.bill || {}

      state.bill[data.bill.idFattura] = billDetail
    },
    setConsumptions(state, data) {
      state.bill[data.idFattura].consumptions = data.consumptions.sort(
        function (a, b) {
          return a.dataCompetenzaStart.localeCompare(b.dataCompetenzaStart)
        }
      )
    },
    setPDRPayment(state, data) {
      state.pdrPayment.push(data)
    },
    setCost(state, data) {
      state.bill[data.idFattura].cost = data.cost
    },
    setSelfReading(state, data) {
      state.bill[data.idFattura].selfReading = data.selfReading
    },
    resetPDRPayment(state) {
      state.pdrPayment = []
    }
  },
  actions: {
    getBill({ state, commit, rootGetters }, params) {
      if (state.bill && state.bill[params.idFattura]) {
        return Promise.resolve(state.bill)
      } else {
        const bill = retrieveBill(commit, rootGetters, params)
        commit('setBillDetail', {
          bill: { idFattura: params.idFattura }
        })
        return bill
      }
    },

    checkBillPaid({ state, commit, rootGetters }, bill) {
      // NOT caching this data for now since it is difficult to manage the cache invalidation (with SIA flow etc)
      // if (state.bill && state.bill[bill.idFattura] && !!state.bill[bill.idFattura].isBillPaid) {
      //     return Promise.resolve({
      //         isPaid: state.bill[bill.idFattura].isBillPaid,
      //         paymentDate: state.bill[bill.idFattura].isBillPaidPaymentDate
      //     })
      // } else {

      return retrieveBillPaid(commit, rootGetters, bill).then((resp) => {
        return resp
      })
    },

    getComputedBillStatus({ dispatch }, bill) {
      const isMdpNonRecOk =
        bill.mdp === Consts.PAYMENT_METHODS.POSTALBILL ||
        bill.mdp === Consts.PAYMENT_METHODS.POSTALBILL_EXT ||
        bill.mdp === Consts.PAYMENT_METHODS.CC_ONE_SHOT ||
        bill.mdp === Consts.PAYMENT_METHODS.BANK_TRANSFER
      const isStateNonRecOk =
        bill.idStato === '1' || bill.idStato === '2' || bill.idStato === '3'

      const isMdpOk =
        bill.mdp === Consts.PAYMENT_METHODS.CREDIT_CARD ||
        bill.mdp === Consts.PAYMENT_METHODS.BANK_ACCOUNT ||
        bill.mdp === Consts.PAYMENT_METHODS.DIGITAL_PAYMENT
      const isStateOk = bill.idStato === '3'

      // const isFlagOk = bill.flagPagamentoRata === 'N'
      // const isTypeOk = bill.tipoOLD === 'Fattura' || bill.tipoOLD === 'Fattura manuale' || bill.tipoOLD === 'Nota di correzione' || bill.gruppoFatturazioneOLD === 'Fatturazione MDC'
      const areLocalConditionsOk =
        (isMdpNonRecOk && isStateNonRecOk) || (isMdpOk && isStateOk) // && isFlagOk && isTypeOk && isPaymDateOk
      if (bill.flagPagamentoRata) {
        return Promise.resolve('7')
      } else if (areLocalConditionsOk) {
        const paymDate = getMillisFromStringDate(bill.dataPagamento)
        const now = new Date().getTime()
        const sixDays = 6 * 24 * 60 * 60 * 1000

        if (paymDate) {
          if (now < paymDate + sixDays) {
            return Promise.resolve('6')
          } else {
            return bill.idStato
          }
        } else {
          // if paymDate is null, we need to check the payment status against the isFatturaPagata API
          return dispatch('checkBillPaid', bill)
            .then((resp) => {
              const svcPaymDate = getMillisFromStringDate(resp.paymentDate)

              if (resp.isPaid === true && svcPaymDate) {
                if (now < svcPaymDate + sixDays) {
                  return Promise.resolve('6')
                } else {
                  return bill.idStato
                }
              } else {
                return bill.idStato
              }
            })
            .catch((err) => {
              console.error(err)
              return bill.idStato
            })
        }
      } else {
        return Promise.resolve(bill.idStato)
      }
    },

    computeShowPaymentButton({ dispatch }, bill) {
      const now = new Date().getTime()
      const sixDays = 6 * 24 * 60 * 60 * 1000

      const isMdpOk =
        bill.mdp === Consts.PAYMENT_METHODS.POSTALBILL ||
        bill.mdp === Consts.PAYMENT_METHODS.POSTALBILL_EXT ||
        bill.mdp === Consts.PAYMENT_METHODS.CC_ONE_SHOT ||
        bill.mdp === Consts.PAYMENT_METHODS.BANK_TRANSFER ||
        ((bill.mdp === Consts.PAYMENT_METHODS.CREDIT_CARD ||
          bill.mdp === Consts.PAYMENT_METHODS.BANK_ACCOUNT ||
          bill.mdp === Consts.PAYMENT_METHODS.DIGITAL_PAYMENT) &&
          bill.idStato === '3')
      const isStateOk =
        bill.idStato === '1' || bill.idStato === '2' || bill.idStato === '3'
      const isFlagOk = !bill.flagPagamentoRata
      const isTypeOk =
        bill.tipoOLD === 'Fattura' ||
        bill.tipoOLD === 'Fattura manuale' ||
        bill.tipoOLD === 'Nota di correzione' ||
        bill.gruppoFatturazioneOLD === 'Fatturazione MDC'
      const isPaymDateOk = bill.dataPagamento === null

      const areLocalConditionsOk = isMdpOk && isStateOk && isFlagOk && isTypeOk

      // if local conditios are not met, avoid calling the api and return flase immediately
      if (areLocalConditionsOk && isPaymDateOk) {
        return dispatch('checkBillPaid', bill)
          .then((resp) => {
            const paymDate = getMillisFromStringDate(resp.dataPagamento)

            return (
              resp.isPaid === false || (paymDate && now >= paymDate + sixDays)
            )
          })
          .catch(() => {
            return true
          })
      } else if (areLocalConditionsOk && !isPaymDateOk) {
        const paymDate = getMillisFromStringDate(bill.dataPagamento)

        return Promise.resolve(paymDate && now >= paymDate + sixDays)
      } else {
        return Promise.resolve(false)
      }
    },

    getBillingSummary({ state, commit, rootGetters }, data) {
      return retrieveBillingSummary(commit, rootGetters, data).then((resp) => {
        return resp
      })
    },

    getBillingSummaryPdr({ state, commit, rootGetters }, data) {
      return retrieveBillingSummaryPdr(commit, rootGetters, data).then(
        (resp) => {
          return resp
        }
      )
    }
  }
}
