<template>
  <div class="sel-owner">
    <div class="mt-10">
      <div
        class="box-address"
        v-for="client in clients"
        :key="client.clientOwnerCode"
        @click="selectClient(client)"
        :v-if="client.clientOwnerType !== 'BUS'"
      >
        <h5 class="name">{{ client.firstName + ' ' + client.lastName }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'selectOwner',
  methods: {
    ...mapMutations('upsellingComm', ['updateAddress']),
    selectClient(client) {
      this.updateAddress({
        clientOwnerCode: client.clientOwnerCode
      })
      this.$emit('nextStep', 'upsellingcom_offert_open')
    }
  },
  computed: {
    ...mapGetters('account', ['accountClients']),
    clients() {
      return this.accountClients
    }
  }
}
</script>
<style lang="scss" scoped>
.sel-owner {
  .box-address {
    width: 650px;
    height: 65px;
    margin: 24px 0;
    padding: 0 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      width: 16px;
    }
    .name::before {
      content: url('../../../../assets/icons/ic-owner-res-black.svg');
      width: 24px;
      margin-left: 8px;
    }
  }
}
</style>
