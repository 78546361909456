var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcome"},[(_vm.loaded && _vm.client && _vm.welcomekit)?_c('div',{staticClass:"welcome std-side-padding"},[_c('v-row',{staticClass:"welcome__header"},[_c('v-col',{staticClass:"align-self-center",attrs:{"md":"6","cols":"12","sm":"6"}},[(!_vm.isMobile)?_c('v-breadcrumbs',{staticClass:"pb-0",attrs:{"items":_vm.breadcrumbsItems,"divider":">"},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/chevron_rx_light.svg"),"width":"24"}})]},proxy:true}],null,false,3772294745)}):_vm._e(),_c('h1',{staticClass:"welcome__title",domProps:{"innerHTML":_vm._s(_vm.welcomekit.inner_title)}}),_c('h2',{staticClass:"welcome__subtitle",domProps:{"innerHTML":_vm._s(_vm.welcomekit.intro_text)}}),_c('h3',{staticClass:"welcome__title",domProps:{"innerHTML":_vm._s(_vm.welcomekit.body)}})],1),(_vm.isMobile && _vm.isSmallDevice)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('img',{attrs:{"src":require('@assets/welcome/icChevronDownWhiteBig.png')}})]):_vm._e(),_c('v-col',{staticClass:"welcome__img",attrs:{"md":"6","cols":"12","sm":"6"}},[_c('img',{attrs:{"src":_vm.isSmallDevice
              ? _vm.cdn +
                (_vm.welcomekit.inner_image_mobile || _vm.welcomekit.inner_image)
              : _vm.cdn + _vm.welcomekit.inner_image}}),(_vm.isMobile)?_c('v-img',{staticClass:"close",attrs:{"contain":"","width":"16","src":require("@assets/welcome/ic-close-white-big.png")},on:{"click":function($event){return _vm.$router.push('/welcome-kit/done')}}}):_vm._e()],1)],1),_c('v-row',{staticClass:"welcome__carousel"},[_c('WelcomeCarousel',{attrs:{"name":"welcome","slides":_vm.slides,"is-mobile":_vm.isMobile,"type":_vm.client.type}})],1)],1):(_vm.client && _vm.loaded && _vm.welcomekit === null)?_c('div',{staticClass:"welcome__empty d-flex justify-center"},[(_vm.isMobile)?_c('div',{staticClass:"closeMobile d-flex align-center justify-center"},[_vm._m(0),_c('img',{attrs:{"src":require("../../assets/icons/close-blu.svg"),"width":"24"},on:{"click":function($event){return _vm.$router.push('/welcome-kit/done')}}})]):_vm._e(),_c('div',{staticClass:"welcome__alert"},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-5"},[_c('h3',[_vm._v("Ops! "+_vm._s(_vm.welcomekit))]),(!_vm.isMobile)?_c('img',{attrs:{"src":require("../../assets/icons/close-blu.svg"),"width":"24"},on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}}):_vm._e()]),_c('h5',{staticClass:"mb-8"},[_vm._v(" Al momento non sono presenti contenuti per il tuo profilo ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"v-btn--shadowed btn-alert",attrs:{"width":"271","height":"54","color":"primary"},on:{"click":function($event){_vm.isMobile
              ? _vm.$router.push('/welcome-kit/done')
              : _vm.$router.push({ name: 'home' })}}},[_vm._v(" Torna alla homepage ")])],1)])]):_c('div',[_c('v-row',{staticClass:"std-side-padding pa-12",attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":50,"indeterminate":""}})],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"welcome__empty--title"},[_c('strong',[_vm._v("Il valore di Sorgenia")])])
}]

export { render, staticRenderFns }