import { axiosCMS } from '@/js/axiosInstances.js'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    myShopEnabled: null,
    myShopAudience: null
  },
  getters: {
    myShopEnabled: (state) => state.myShopEnabled,
    myShopAudience: (state) => state.myShopAudience
  },
  mutations: {
    setMyShopEnabled(state, enabled) {
      state.myShopEnabled = enabled
    },
    setMyShopAudience(state, type) {
      state.myShopAudience = type
    }
  },
  actions: {
    getMyShopConfig({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.myShopEnabled === null || state.myShopAudience === null) {
          axiosCMS.get('/config').then((resp) => {
            let ecommerceEnabled = resp.data.filter(
              (item) => item.property_name === 'ecommerce_enabled'
            )[0].property_value
            let filter = resp.data.filter(
              (item) => item.property_name === 'ecommerce_enabled'
            )[0].filter_client_type
            commit('setMyShopEnabled', ecommerceEnabled === '1')
            commit('setMyShopAudience', filter)
            resolve()
          })
        } else {
          resolve()
        }
      })
    }
  }
}
