<template>
  <v-expansion-panel
    :readonly="triggerStatus.status === 'empty'"
    :class="`panel ${triggerStatus.status}`"
    @click="openUpsellingEle(triggerStatus.status)"
  >
    <AccordionTemplate :triggerStatus="triggerStatus" type="ele">
      <template v-slot:noActive>
        <div class="d-flex flex-column justify-space-between">
          <div
            :class="`supply__btn ${triggerStatus.status} d-flex align-center justify-space-between`"
            @click="$emit('openSuppliesDialog', 'ele', triggersDisabled.status)"
          >
            <div class="action">
              <div class="type">{{ triggersDisabled.cta }}</div>
              <div class="desc" v-html="triggersDisabled.desc" />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:timeline>
        <div class="d-flex flex-column justify-space-between">
          <div
            :class="`supply__btn ${triggerStatus.status} d-flex align-center justify-space-between`"
            @click="$emit('openSuppliesDialog', 'ele', triggersTimeline.status)"
          >
            <div class="action">
              <div class="type">{{ triggersTimeline.cta }}</div>
              <div class="desc" v-html="triggersTimeline.desc" />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:active>
        <div class="d-flex flex-column justify-space-between">
          <div
            :class="`supply__btn ${triggersPayment.status} d-flex align-center justify-space-between`"
            @click="$emit('openSuppliesDialog', 'ele', 'payment')"
          >
            <div class="action">
              <div class="type">{{ triggersPayment.cta }}</div>
              <div class="desc" v-html="triggersPayment.desc" />
            </div>
          </div>
          <div
            v-if="hasLigthSupplyRES"
            :class="`supply__btn ${triggersConsumption.status} d-flex align-center justify-space-between`"
            @click="$emit('openSuppliesDialog', 'ele', 'consumption')"
          >
            <div class="action">
              <div class="type">{{ triggersConsumption.cta }}</div>
              <div class="desc" v-html="triggersConsumption.desc" />
            </div>
          </div>
        </div>
        <div
          v-if="showBEContent"
          class="supply__advice"
          @click="openBeyondEnergy"
        >
          <div class="action">
            <div class="type">Beyond Energy</div>
            <div class="desc">
              Servizio di monitoraggio per gestire al meglio i consumi della tua
              casa
            </div>
            <div class="btn">Migliora i tuoi consumi</div>
          </div>
        </div>
      </template>
    </AccordionTemplate>
    <BidgelyEleDialog
      v-if="!mainClient.isOnlyFV"
      :show-dialog="showBEDialog"
      @BidgelyEleDialogClose="showBEDialog = false"
    ></BidgelyEleDialog>
  </v-expansion-panel>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AccordionTemplate from './Template.vue'
import BidgelyEleDialog from '@components/bidgely/BidgelyEleDialog'
import Constants from '@/js/constants'
export default {
  name: 'accordionEle',
  props: [
    'triggerStatus',
    'triggersDisabled',
    'triggersTimeline',
    'triggersPayment',
    'triggersConsumption'
  ],
  data() {
    return {
      showBEDialog: false,
      SERVICE_TYPE: Constants.SERVICE_TYPE,
      SUPPLY_STATUS: Constants.SUPPLY_STATUS
    }
  },
  components: {
    AccordionTemplate,
    BidgelyEleDialog
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('account', ['mainClient', 'filteredClientDetails']),
    hasLigthSupplyRES() {
      return (
        this.supplies.filter(
          (sup) =>
            sup.serviceType === this.SERVICE_TYPE.ELE &&
            sup.clientOwnerType === 'RES'
        ).length > 0
      )
    },
    showBEContent() {
      return (
        this.supplies.filter(
          (sup) =>
            sup.serviceType === this.SERVICE_TYPE.ELE &&
            sup.clientOwnerType === 'RES' &&
            sup.supplyState === this.SUPPLY_STATUS.ACTIVE
        ).length > 0
      )
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('alerts', ['resetFlagAlert']),
    openUpsellingEle(status) {
      let statusDecode = {
        regular: 'regular',
        irregular: 'not_regular',
        timeline: 'activating',
        noActive: 'inactive'
      }
      this.resetFlagAlert()
      if (status === 'empty') {
        if (this.filteredClientDetails.clientOwnerCode) {
          this.trackTapEvent({
            name: 'home_upselling_tap',
            params: { supply_type: 'ele' }
          })
          this.$router.push({ name: 'eleOffering' })
        } else {
          window.open(
            'https://casa.sorgenia.it/content/sorgenia-fe/residential/selectcommodity.html?campaign_code=fotovoltaico_upselling_app',
            '_blank'
          )
        }
      } else {
        this.trackTapEvent({
          name: 'home_accordion_tap',
          params: { supply_type: 'ele', status: statusDecode[status] }
        })
      }
    },
    openBeyondEnergy() {
      this.trackTapEvent({
        name: 'home_alertCTA_tap',
        params: { supply_type: 'ele', cta_title: 'Migliora i tuoi consumi' }
      })
      this.showBEDialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import './accordionStyle.scss';
</style>
