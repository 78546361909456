import { axiosACN } from '@/js/axiosInstances'

export default {
  namespaced: true,
  state: {
    user: {
      firstname: '',
      lastname: '',
      email: ''
    },
    annoCostruzione: '',
    provincia: '',
    comune: '',
    indirizzo: '',
    civico: '',
    confineSuperiore: '',
    confineInferiore: '',
    numeroPiani: 1,
    areaPavimento: '',
    numeroLatiEsterno: '',
    numeroPorteFinestre: 0,
    numeroTotaleFinestre: 0,
    numeroTotaleLucernari: 0,
    posizioneAppartamento: '',
    tipologiaInfissi: '',
    tipologiaCasa: '',
    tipologiaImpianto2: '',
    tipologiaImpianto1: '',
    tipologiaCombustibile: '',
    metodologiaCalcolo: '',
    sistemaProduzioneACS: '',
    impiantoCondizionamentoEstivo: '',
    isolamentoPareti: '',
    isolamentoCopertura: '',
    impiantiFontiRinnovabili: '',
    numeroUtentiAbituali: 0,
    elettrodomestici: '',
    consumoTermicoReale: '',
    consumoElettricoReale: '',
    proprietaTetto: '',
    numeroAppartamentiPiano: 1,
    hasMarketingConsent: false,

    token: null,
    isWeb: '',
    firebaseToken: null,
    subKey: null
  },
  getters: {
    getAnnoCostruzione: (state) => state.annoCostruzione,
    getProvincia: (state) => state.provincia,
    getComune: (state) => state.comune,
    getIndirizzo: (state) => state.indirizzo,
    getCivico: (state) => state.civico,
    getConfineSuperiore: (state) => state.confineSuperiore,
    getConfineInferiore: (state) => state.confineInferiore,
    getNumeroPiani: (state) => state.numeroPiani,
    getAreaPavimento: (state) => state.areaPavimento,
    getNumeroLatiEsterno: (state) => state.numeroLatiEsterno,
    getNumeroPorteFinestre: (state) => state.numeroPorteFinestre,
    getNumeroTotaleLucernari: (state) => state.numeroTotaleLucernari,
    getNumeroTotaleFinestre: (state) => state.numeroTotaleFinestre,
    getPosizioneAppartamento: (state) => state.posizioneAppartamento,
    getTipologiaInfissi: (state) => state.tipologiaInfissi,
    getTipologiaCasa: (state) => state.tipologiaCasa,
    getTipologiaImpianto2: (state) => state.tipologiaImpianto2,
    getTipologiaImpianto1: (state) => state.tipologiaImpianto1,
    getTipologiaCombustibile: (state) => state.tipologiaCombustibile,
    getMetodologiaCalcolo: (state) => state.metodologiaCalcolo,
    getSistemaProduzioneACS: (state) => state.sistemaProduzioneACS,
    getImpiantoCondizionamentoEstivo: (state) =>
      state.impiantoCondizionamentoEstivo,
    getIsolamentoPareti: (state) => state.isolamentoPareti,
    getIsolamentoCopertura: (state) => state.isolamentoCopertura,
    getImpiantiFontiRinnovabili: (state) => state.impiantiFontiRinnovabili,
    getNumeroUtentiAbituali: (state) => state.numeroUtentiAbituali,
    getElettrodomestici: (state) => state.elettrodomestici,
    getConsumoTermicoReale: (state) => state.consumoTermicoReale,
    getConsumoElettricoReale: (state) => state.consumoElettricoReale,
    getProprietaTetto: (state) => state.proprietaTetto,
    getNumeroAppartamentiPiano: (state) => state.numeroAppartamentiPiano,
    getToken: (state) => state.token,
    getIsWeb: (state) => state.isWeb,
    getHasMarketingConsent: (state) => state.hasMarketingConsent,
    getCheckup: (state) => {
      let ret = state
      delete ret.token
      delete ret.firebaseToken
      delete ret.subKey
      delete ret.user
      return ret
    },
    getUser: (state) => {
      return state.user
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setDataCheckup(state, payload) {
      if (!Array.isArray(payload)) {
        payload = [payload]
      }
      payload.forEach((element) => {
        state[element.key] = element.value
      })
    }
  },
  actions: {
    sendSurvey({ rootGetters, state }, survey) {
      return axiosACN
        .post('/commodity/checkUpEnergetico', survey, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.error) {
            throw Error('UNKNOWN_ERROR')
          }
          return res
        })
        .catch((err) => {
          if (
            (err.errorCode && err.errorCode === '500') ||
            (err.errorCode && err.errorCode === '400')
          ) {
            throw err
          }
        })
    },
    retrieveComuneIntellico({ rootGetters, state }, city) {
      let token = {}
      if (state.firebaseToken) {
        token = { FirebaseAuthorization: state.firebaseToken }
      } else {
        token = { Authorization: 'Bearer ' + state.token }
      }
      if (state.subKey) {
        token = {
          'Ocp-Apim-Subscription-Key': state.subKey,
          ...token
        }
      }
      return axiosACN
        .post(
          '/commodity/retrieveComuneIntellico',
          {
            comune: city
          },
          {
            headers: token
          }
        )
        .then((res) => {
          if (res.data.error) {
            throw Error('UNKNOWN_ERROR')
          }
          return res
        })
        .catch((err) => {
          if (
            (err.errorCode && err.errorCode === '500') ||
            (err.errorCode && err.errorCode === '400')
          ) {
            throw err
          }
        })
    }
  }
}
