<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col align-self="center" cols="6">
        <h1 class="white--text">Ritira i tuoi premi</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col align-self="center" cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <v-col class="gift-info pa-0 ma-0 mt-12">
      <v-row class="mb-6">
        <v-col class="pb-0" cols="6">
          <h2 class="mb-5" v-text="giftTitle"></h2>
          <p class="gift-info__desc" v-text="giftDescription"></p>
          <!-- <router-link :to="{name: 'giftsMainPage'}" class="gift-info__link">Scopri di più</router-link> -->
        </v-col>
        <v-spacer cols="1" />
        <v-col class="pb-0" cols="5">
          <v-img :src="giftImg.image_medium" class="gift-info__img" contain />
        </v-col>
      </v-row>
      <v-row justify="center" v-if="giftTexts && giftTexts.length">
        <v-expansion-panels accordion class="v-expansion-panels--prizes">
          <v-expansion-panel :key="item.id" v-for="item in giftTexts">
            <v-expansion-panel-header>
              <span v-text="item.title" />
              <template v-slot:actions>
                <div class="icon icon--expand"></div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              ><div v-html="item.description"></div
            ></v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            :disabled="!awardItem.hasOwnProperty('attributes')"
            @click="onSubmit"
            class="v-btn--shadowed"
            color="brightGreen"
            x-large
          >
            Richiedi premio
          </v-btn>
          <div class="gift-info__reg">
            <p>
              Cliccando su <b>Richiedi il premio</b> accetti il
              <a
                :href="
                  mainClientLoyaltySelected.clientOwnerType === 'RES'
                    ? linkRegolamento
                    : linkRegolamentoBus
                "
                target="_blank"
                download
                >regolamento</a
              >
              dell'operazione a premio
            </p>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Layout from '../Layout'

export default {
  name: 'GiftDetailsPage',
  props: {
    doubleGift: { type: Boolean, default: false },
    item: { type: Object, default: null }
  },
  data() {
    return {
      linkRegolamento:
        'https://sorgenia.s3-eu-west-1.amazonaws.com/public/seconda_edizione/regolamento_operazione_premi.pdf',
      linkRegolamentoBus:
        'https://sorgenia.s3-eu-west-1.amazonaws.com/public/seconda_edizione/regolamento_operazione_premi_business.pdf'
    }
  },
  components: {
    layout: Layout
  },
  computed: {
    ...mapGetters('account', ['clientDetails', 'mainClientLoyaltySelected']),
    ...mapGetters('loyalty', ['awardItem']),
    giftImg() {
      return (
        this.awardItem?.attributes?.images?.data.find(
          ({ label }) => label === 'cover'
        ) ||
        this.awardItem?.attributes?.images?.data[0] ||
        {}
      )
    },
    giftTitle() {
      return this.awardItem?.attributes?.title || ''
    },
    giftDescription() {
      return this.awardItem?.attributes?.description || ''
    },
    giftTexts() {
      return this.awardItem?.attributes?.prize_text?.data || []
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('loyalty', ['getAwardInfo', 'getCatalog']),
    ...mapMutations('loyalty', ['setAwardItem']),
    onSubmit() {
      this.trackTapEvent({
        name: 'greeners_prizesBack_tap',
        params: {
          client_code: this.mainClientLoyaltySelected.clientOwnerCode,
          client_type: this.mainClientLoyaltySelected.clientOwnerType
        }
      })
      if (this.getAwardInfo.is_required_shipping) {
        this.$router.push({
          name: 'giftShipping',
          params: {
            catalog: this.$route.params.catalog,
            type: this.getAwardInfo.type,
            id: this.$route.params.id
          }
        })
      } else {
        this.$router.push({
          name: 'giftEmailShipping',
          params: {
            catalog: this.$route.params.catalog,
            id: this.$route.params.id
          }
        })
      }
    }
  },
  beforeMount() {
    if (!this.$route.params.catalog) this.$router.go(-1)
    this.getAwardInfo({
      id: this.$route.params.id,
      catalog: this.$route.params.catalog
    })
    this.getCatalog({
      catalog_name: this.$route.params.catalog,
      level_order: this.$route.params.level,
      codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
    })
  }
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--x-large {
  padding: 0 60px;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
}

.gift-info {
  &__desc {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
  }

  &__link {
    color: $srg-green;
    font-weight: bold;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }

  &__img {
    margin: 20px;
  }

  &__reg {
    font-size: 14px;
    line-height: 22px;
    margin-top: 24px;
    width: 335px;

    a {
      color: $srg-green;
      font-weight: 600;
    }
  }
}
</style>
