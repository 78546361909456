<template>
  <div>
    <div class="page">
      <v-row class="std-side-padding">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <transition name="fade">
        <div v-if="!loaded">
          <v-row>
            <v-col
              ><img src="@assets/fotovoltaico/placeholder.svg" width="100%"
            /></v-col>
          </v-row>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="loaded">
          <v-row>
            <v-col class="pb-0" cols="12">
              <MonitorTitleVue
                title="Risparmio del mio impianto"
                :abstract="textLabelTime"
              ></MonitorTitleVue>
            </v-col>
          </v-row>
        </div>
      </transition>
    </div>
    <div class="title-section">
      <div class="page">
        <v-row>
          <v-col cols="12">
            <MonitorValueVue
              title="Risparmio e incentivi"
              :value="totalSavings"
              :sub-title="`Costo dell’impianto ${totalCost}`"
              :question="true"
            >
            </MonitorValueVue>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="page">
      <v-row>
        <v-col cols="12">
          <MonitorHeaderVue
            :currentOptionList="currentOptionList"
            @change-current="changeCurrent"
            @change-unit="changeUnit"
            :currentEnd="body.endDate"
            :currentStart="body.startDate"
            :installationConnectionStartDate="
              getInstallationData?.siteProfile?.installationConnectionStartDate
            "
          ></MonitorHeaderVue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MonitorChartVue
            :series="series"
            :categories="categories"
            :columnWidth="columnWidth"
            chartLabelY="risparmio"
            :load="load"
          ></MonitorChartVue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MonitorControlsVue
            @change-control="changeControl"
            :load="load"
            :controls="controls"
          ></MonitorControlsVue>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import MonitorHeaderVue from '../../../components/fotovoltaico/monitor/MonitorHeader.vue'
import MonitorTitleVue from '../../../components/fotovoltaico/monitor/MonitorTitle.vue'
import MonitorValueVue from '../../../components/fotovoltaico/monitor/MonitorValue.vue'
import MonitorChartVue from '../../../components/fotovoltaico/monitor/MonitorChart.vue'
import MonitorControlsVue from '../../../components/fotovoltaico/monitor/MonitorControls.vue'
import functionsUtilityFV from '@/js/fotovoltaico/functions'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

const allRules = ['Risparmio in bolletta', 'Incentivi GSE']

export default {
  components: {
    MonitorTitleVue,
    MonitorValueVue,
    MonitorHeaderVue,
    MonitorChartVue,
    MonitorControlsVue
  },
  mounted() {
    this.trackTapEvent({
      name: 'monitoring_historical_data_viewed',
      params: {
        category: 'savings'
      }
    })
    this.loadSavings()
  },
  beforeMount() {
    if (!this.addressSelected.photovoltaicData && !this.$route.params.siteId) {
      this.$router.push({ name: 'home' })
    }
  },
  watch: {
    getSavingControl() {
      this.calcolaCostoImpianto()
      this.calcolaIncentiviRisparmio()
    }
  },

  data() {
    return {
      load: false,
      costoImpianto: '',
      loaded: true,
      totalCost: '',
      textLabelTime: 'Dati aggiornati ogni 24 ore.',
      current: new Date().toISOString(),
      rules: [...allRules],
      controls: [
        {
          load: this.load,
          title: 'Incentivi GSE',
          icon: 'icon-green-light',
          value: 0,
          percentage: 0
        },
        {
          load: this.load,
          title: 'Risparmio in bolletta',
          icon: 'icon-green-blue',
          value: 0,
          percentage: 0
        }
      ],
      body: {
        acuId: '',
        siteId: '',
        granularity: 'Dall inizio',
        startDate: new Date(
          new Date().getFullYear(),
          0,
          1,
          0,
          0,
          0
        ).toISOString(),
        endDate: new Date(
          new Date().getFullYear(),
          11,
          31,
          23,
          59,
          59
        ).toISOString()
      }
    }
  },
  computed: {
    ...mapGetters('account', ['mainClient']),
    ...mapState('fotovoltaico', ['monitoringSavingChartReal']),
    ...mapGetters('fotovoltaico', [
      'getSavingChart',
      'getInstallationData',
      'getSavingDataChart',
      'getSavingControl',
      'getFlagEnterByDeeplink'
    ]),
    ...mapState('fotovoltaico', ['addressSelected']),

    breadcrumbsItems() {
      this.setFlagEnterByDeeplink(false)

      let res = [
        { to: { name: 'home' }, text: 'Home' },
        {
          to: {
            name: 'dashboardFotovoltaico',
            params: {
              acuId: this.$route.params.acuId,
              siteId: this.$route.params.siteId
            }
          },
          text: 'Flussi energetici'
        },
        { to: { name: 'fotovoltaicoRisparmio' }, text: 'Risparmio' }
      ]

      return res
    },
    series: {
      get() {
        return this.getSavingChart?.data?.values?.series || []
      },
      set(value) {
        this.getSavingChart.data.values.series = value
      }
    },
    categories() {
      return this.getSavingChart.data && this.getSavingChart.data.categories
        ? this.getSavingChart.data.categories
        : []
    },
    currentOptionList() {
      const date = new Date(this.current)
      const dateStart = new Date(this.installationConnectionStartDate || '')
      switch (this.body.granularity) {
        case 'Giorno':
          return functionsUtilityFV.formatDayMonth(date)
        case 'Settimana':
          const startOfWeek = functionsUtilityFV.getStartOfWeek(date)
          const endOfWeek = functionsUtilityFV.getEndOfWeek(date)

          return `${functionsUtilityFV.formatDayMonth(
            startOfWeek,
            true
          )} - ${functionsUtilityFV.formatDayMonth(endOfWeek, true)}`

        case 'Mese':
          return functionsUtilityFV.formatMonth(date)
        case 'Anno':
          return functionsUtilityFV.formatYear(date)
        case 'Dall inizio':
          return `${functionsUtilityFV.formatFromActivation(
            dateStart
          )} - ${functionsUtilityFV.formatFromActivation(new Date())}`
        default:
          return functionsUtilityFV.formatDayMonth(date)
      }
    },
    columnWidth() {
      return this.getSavingChart.data && this.getSavingChart.data.columnWidth
        ? this.getSavingChart.data.columnWidth
        : 0
    },
    totalSavings() {
      let total =
        this.getSavingDataChart &&
        this.getSavingControl?.totalSavings &&
        this.getSavingControl?.totalSavings[0].value
          ? this.getSavingControl?.totalSavings[0]?.value
          : '0,00 €'
      return total.toLocaleString('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    // calcolaTotaleRisparmi() {
    //   return this.getSavingChart.data &&
    //     this.getSavingChart.data.totalSavingsSum
    //     ? this.getSavingChart.data.totalSavingsSum.toLocaleString('it-IT', {
    //         style: 'currency',
    //         currency: 'EUR',
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2
    //       })
    //     : '0,00'
    // },
    installationConnectionStartDate() {
      return this.getInstallationData?.siteProfile
        ?.installationConnectionStartDate
    }
  },
  methods: {
    ...mapActions('fotovoltaico', [
      'retrieveMonitoringSavingKPIData',
      'retrieveMonitoringSavingKPIControl',
      'retrievePhotovoltaicData',
      'photovoltaicInstallationDetails',
      'retrieveDashboardData'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('fotovoltaico', ['setFlagEnterByDeeplink']),
    ...mapActions('supply', ['getSupplies']),
    ...mapMutations('fotovoltaico', ['setFlagEnterByDeeplink']),

    calcolaCostoImpianto() {
      let total = parseInt(
        this.getInstallationData?.siteProfile?.installationCost
      )
      this.totalCost = total.toLocaleString('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    calcolaIncentiviRisparmio() {
      this.controls[0].value =
        this.getSavingControl?.gseIncentives[0]?.value || 0
      this.controls[0].percentage =
        this.getSavingControl?.gseIncentivesPercentage[0]?.value || 0
      this.controls[1].value = this.getSavingControl?.billSavings[0]?.value || 0
      this.controls[1].percentage =
        this.getSavingControl?.billSavingsPercentage[0]?.value || 0
    },

    formatFromActivation(date) {
      const month = this.getAbbreviatedMonthName(date.getMonth())
      return `${month} ${date.getDate()}`
    },
    async changeCurrent(current, amount) {
      this.load = true
      functionsUtilityFV.updateDates(this.body.granularity, amount, this.body)
      this.current = this.body.endDate
      await this.retrieveMonitoringSavingKPIData(this.body)
      switch (this.body.granularity) {
        case 'Giorno':
          let bodyGran = { ...this.body, granularity: 'DAILY' }
          await this.retrieveMonitoringSavingKPIControl(bodyGran)
          break

        case 'Settimana':
          let bodyWeek = { ...this.body, granularity: 'WEEKLY' }
          await this.retrieveMonitoringSavingKPIControl(bodyWeek)
          break

        case 'Mese':
          let bodyMonth = { ...this.body, granularity: 'MONTHLY' }
          await this.retrieveMonitoringSavingKPIControl(bodyMonth)
          break

        case 'Anno':
          let bodyAnnual = { ...this.body, granularity: 'ANNUAL' }
          await this.retrieveMonitoringSavingKPIControl(bodyAnnual)
          break

        case 'Dall inizio':
          let bodyFrom = { ...this.body, granularity: 'ANNUAL' }
          await this.retrieveMonitoringSavingKPIControl(bodyFrom)
          break

        default:
          break
      }
      this.load = false
    },
    async changeUnit(unit) {
      this.load = true
      const today = new Date()
      switch (unit) {
        case 'Giorno':
          this.textLabelTime = 'Dati aggiornati ogni 15 minuti.'
          this.body.startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            23,
            59,
            59
          ).toISOString()
          let bodyDay = { ...this.body, granularity: 'DAILY' }
          this.retrieveMonitoringSavingKPIControl(bodyDay)
          break
        case 'Settimana':
          this.textLabelTime = 'Dati aggiornati ogni 60 minuti.'
          const dayOfWeek = today.getDay()
          const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1
          const firstDayOfWeek = new Date(today)
          firstDayOfWeek.setDate(today.getDate() - daysToSubtract)
          this.body.startDate = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate(),
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate() + 6,
            23,
            59,
            59
          ).toISOString()
          let newBody = { ...this.body, granularity: 'WEEKLY' }
          this.retrieveMonitoringSavingKPIControl(newBody)
          break
        case 'Mese':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            23,
            59,
            59
          ).toISOString()
          let bodyMonth = { ...this.body, granularity: 'MONTHLY' }
          this.retrieveMonitoringSavingKPIControl(bodyMonth)
          break
        case 'Anno':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
          let bodyYear = { ...this.body, granularity: 'ANNUAL' }
          this.retrieveMonitoringSavingKPIControl(bodyYear)
          break
        case 'Dall inizio':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          // this.body.startDate =
          //   this.getInstallationData.siteProfile.installationConnectionStartDate
          this.body.startDate = new Date(
            today.getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString()
          // this.body.endDate = new Date(
          //   new Date().getFullYear(),
          //   new Date().getMonth(),
          //   new Date().getDate(),
          //   23,
          //   59,
          //   59
          // ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
          let bodyFromActivation = {
            ...this.body,
            granularity: 'FROM_ACTIVATION'
          }
          this.retrieveMonitoringSavingKPIControl(bodyFromActivation)
          break
      }
      this.body.granularity = unit
      await this.retrieveMonitoringSavingKPIData(this.body)
      this.current = this.body.endDate
      this.load = false
    },

    changeControl({ title, switchRadio }) {
      let persistantData = this.getSavingDataChart?.series
      let spreadJson = this.getSavingChart.data.values?.series
      if (switchRadio) {
        this.rules = [...this.rules, title]
        let seriesToAdd = persistantData.filter((el) => el.name === title)

        if (title === 'Risparmio in bolletta') {
          this.series.splice(0, 0, seriesToAdd[0])
        } else {
          this.series.splice(1, 0, seriesToAdd[0])
        }
      } else {
        this.rules = this.rules.filter((curr) => curr !== title)
        this.series = spreadJson.filter((curr) => curr.name !== title)
      }
    },
    async loadSavings() {
      this.load = true
      await this.getSupplies()

      if (
        this.$route.params.acuId !== this.mainClient?.acuId ||
        !this.mainClient?.acuId
      ) {
        this.$router.push({ name: 'home' })
      } else {
        const siteId = this.$route.params.siteId
        const acuId = this.$route.params.acuId
        const granularityDaily = 'DAILY'
        const granularityFromStart = 'FROM_ACTIVATION'
        const startDateFromStart =
          this.addressSelected?.photovoltaicData
            ?.installationConnectionStartDate
        let endDate = new Date().toISOString().slice(0, 19) + 'Z'
        const today = new Date()
        const startDateDaily =
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          )
            .toISOString()
            .slice(0, 19) + 'Z'
        const body = {
          siteId,
          acuId,
          granularityDaily,
          granularityFromStart,
          startDateFromStart,
          endDate,
          startDateDaily
        }
        await this.photovoltaicInstallationDetails(body)
        await this.retrieveDashboardData(body)
        this.body.acuId = this.$route.params.acuId
        this.body.siteId = this.$route.params.siteId
        const newBody = {
          ...this.body,
          startDate: new Date(
            new Date().getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString(),
          endDate: new Date(
            new Date().getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
        }
        let bodyStart = { ...newBody, granularity: 'FROM_ACTIVATION' }
        await this.retrieveMonitoringSavingKPIControl(bodyStart)
        await this.retrieveMonitoringSavingKPIData(newBody)
      }
      this.load = false
    }
  }
}
</script>
<style lang="scss" scoped>
.title-section {
  margin-top: 40px;
  background-color: #eaeef5;
  padding: 8px;
}
</style>
