export const TypeAuto = {
  ELECTRIC: 'Elettrica',
  HYBRID: 'Ibrida',
  TRADITIONAL: 'Tradizionale',
  NONE: 'Nessuna'
}

export const Wallbox = {
  YES: 'Sì, già ho un wallbox installato',
  NOT_YET: 'Non ancora, ma ci sto pensando',
  CANNOT: 'Non potrei metterlo'
}

export const Interest = {
  RECALL: 'Sì, mi interessa e vorrei saperne di più',
  NOT_INTERESTED: 'No, non mi interessa la mobilità elettrica'
}
