<template>
  <div class="modem">
    <div class="modem__box">
      <div class="desc pa-8">
        <div class="include">INCLUSO</div>
        <h2>{{ upsellingInfo.addon_title }}</h2>
        <div class="price">dal valore di 129,99 €</div>
      </div>
      <img :src="CmsUrl + upsellingInfo.addon_image_web" width="360" />
    </div>
    <h3 class="modem__title mb-4">
      {{ upsellingInfo.addon_subtitle }}
      <img @click="openDialogDesc" src="../../../assets/icons/info.svg" />
    </h3>
    <div class="modem__desc" v-html="upsellingInfo.addon_description" />
    <div class="modem__btn">
      <v-btn
        color="primary"
        x-large
        width="260"
        class="v-btn--shadowed"
        @click="goToRepeater"
        >Continua</v-btn
      >
      <v-btn text x-large color="primary" width="300" @click="openDialog"
        >Non voglio ricevere il modem</v-btn
      >
    </div>
    <v-dialog max-width="750" v-model="dialogDesc">
      <v-row>
        <v-col class="modem-dialog">
          <img
            @click="dialogDesc = false"
            src="../../../assets/ic_chevron_top_black.png"
            class="abs"
          />
          <h2>Caratteristiche del modem</h2>
          <div
            class="modem__extend-desc"
            v-html="upsellingInfo.addon_extended_description"
          />
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog max-width="750" v-model="dialogNoModem">
      <v-row>
        <v-col class="modem-dialog modem__extend-desc">
          <img
            @click="closeDialog"
            src="../../../assets/ic_chevron_top_black.png"
            class="abs"
          />
          <h2>Preferisci usare il tuo modem?</h2>
          <h5 class="mb-6">
            Per garantire che la connessione alla rete Sorgenia funzioni
            correttamente, è necessario che il dispositivo che hai scelto
            supporti i protocolli di comunicazione necessari per dialogare
            correttamente con i sistemi dell’operatore.
          </h5>
          <h5 class="mb-6">
            Se desideri utilizzare il modem di tua proprietà ricordati che dovrà
            rispondere a questi requisiti per poter sfruttare al meglio la tua
            nuova rete Sorgenia.
          </h5>
          <h5>
            <p>
              <strong>Se il tuo indirizzo è coperto da fibra FTTH</strong
              ><img
                src="../../../assets/ftth_icon.png"
                class="ml-2 fixTechIcon"
                width="32"
              />
            </p>
          </h5>
          <h5>
            <p>
              Il modem alternativo dialogherà con il nostro ONT, dovrà quindi
              avere interfaccia di rete WAN di tipo Gigabit Ethernet con
              supporto del protocollo Ethernet 802.1q e supportare traffico in
              uscita con protocollo PPPoE Routed e poter impostare il tag VLAN.
            </p>
          </h5>
          <h5>
            <p>
              <strong>Se il tuo indirizzo è coperto da fibra FTTC</strong
              ><img
                src="../../../assets/fttc_icon.png"
                class="ml-2 fixTechIcon"
                width="32"
              />
            </p>
          </h5>
          <h5>
            <p>
              Il modem alternativo dialogherà direttamente con la rete Sorgenia,
              dovrà quindi avere supporto per
            </p>
          </h5>
          <ul>
            <li>
              <div class="li-title">
                Interfaccia VDSL2 (ITU-T G.993.2) fino al profilo 35b
              </div>
            </li>
            <li>
              <div class="li-title">Seamless Rate Adaptation (SRA)</div>
            </li>
            <li>
              <div class="li-title">Retrasmission G.INP (ITU-T G.998.4)</div>
            </li>
            <li>
              <div class="li-title">Vectoring (ITU-T G.993.5)</div>
            </li>
            <li>
              <div class="li-title">
                Incapsulamento PTM tagged con VLAN ID 100 e CoS 0
              </div>
            </li>
          </ul>
          <h5>
            <p>
              <strong
                >Se desideri utilizzarle un tuo modem, ricorda che i seguenti
                servizi non saranno garantiti</strong
              >
            </p>
          </h5>
          <ul>
            <li>
              <div class="li-title">Gestione avanzata Wi-Fi</div>
            </li>
            <li>
              <div class="li-title">Diagnostica self</div>
            </li>
            <li>
              <div class="li-title">DNS Sicuro</div>
            </li>
            <li>
              <div class="li-title">Diagnostica da remoto</div>
            </li>
          </ul>
          <h5>
            <p><strong>Manutenzione e assistenza da remoto</strong></p>
          </h5>
          <h5>
            <p>
              Ogni problematica relativa al tuo modem, all'installazione,
              configurazione e al suo corretto funzionamento sarà di tua
              esclusiva responsabilità o del fornitore dell'apparato. Non sarà
              in ogni caso possibile connetterci da remoto all'apparato per
              supportarti nell'attività di troubleshooting e risoluzione delle
              anomalie dovute al modem da te scelto.
            </p>
          </h5>
          <div class="d-flex mt-5">
            <v-btn
              color="primary"
              x-large
              width="300"
              class="v-btn--shadowed mr-5"
              @click="goToRepeaterNoModem"
              >Continua senza modem</v-btn
            >
            <v-btn text color="primary" x-large width="300" @click="undoDialog"
              >Torna al modem gratuito</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ENV_CONFIGS } from '@/js/configs.js'

export default {
  name: 'modemComponent',
  data() {
    return {
      CmsUrl: ENV_CONFIGS.CMS_CDN_BASE_URL,
      dialogNoModem: false,
      dialogDesc: false
    }
  },
  computed: {
    ...mapGetters('offers', ['upsellingInfo']),
    ...mapGetters('upsellingFiber', ['flagRepeater'])
  },
  methods: {
    ...mapMutations('upsellingFiber', ['setWithModem']),
    ...mapActions('analytics', ['trackTapEvent']),
    goToRepeater() {
      this.setWithModem(true)
      this.trackTapEvent({
        name: 'upsellingv2_getmodem_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      if (this.flagRepeater) {
        this.$emit('nextStep', 'upsellingv2_repeater_open')
      } else {
        this.$emit('goToStep', 4, 'upsellingv2_howtoprocedure_open')
      }
    },
    goToRepeaterNoModem() {
      this.setWithModem(false)
      this.trackTapEvent({
        name: 'upsellingv2_skipmodem_confirm',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      this.dialogNoModem = false
      if (this.flagRepeater) {
        this.$emit('nextStep', 'upsellingv2_repeater_open')
      } else {
        this.$emit('goToStep', 4, 'upsellingv2_howtoprocedure_open')
      }
    },
    openDialog() {
      this.dialogNoModem = true
      this.trackTapEvent({
        name: 'upsellingv2_skipmodem_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
    },
    closeDialog() {
      this.dialogNoModem = false
      this.trackTapEvent({
        name: 'upsellingv2_skipmodem_close',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
    },
    undoDialog() {
      this.dialogNoModem = false
      this.trackTapEvent({
        name: 'upsellingv2_skipmodem_undo',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
    },
    openDialogDesc() {
      this.dialogDesc = true
      this.trackTapEvent({
        name: 'upsellingv2_modeminfo_tap',
        params: { client_selected: 'not_selected' }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.modem {
  max-width: 652px;
  &__box {
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    background-color: #e8f8ff;
    margin-bottom: 48px;
    .include {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 82px;
      height: 27px;
      border-radius: 4px;
      background: #48bd2b;
      color: white;
      font-size: 14px;
      font-weight: 500;
    }
    h2 {
      font-weight: 500;
    }
    .price {
      font-size: 20px;
    }
    img {
      max-width: 50%;
    }
  }
  h3 {
    font-weight: 500;
    margin-bottom: 24px;
  }
  &__title {
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
      margin-left: 10px;
    }
  }
  &__desc {
    font-size: 20px;
  }
  &__extend-desc {
    font-size: 20px;
  }
  &__btn {
    margin-top: 48px;
    display: flex;
    align-items: center;
    button {
      letter-spacing: 0;
      margin-right: 10px;
    }
  }
}
.modem-dialog {
  background: white;
  padding: 55px 45px 45px 55px;
  border-radius: 40px;
  position: relative;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
    line-height: normal;
  }
  img.abs {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    &.preview {
      position: initial;
    }
  }
  .select {
    width: 260px;
  }
}
</style>
<style lang="scss">
.fixTechIcon {
  position: relative;
  top: 9px;
}
.modem__extend-desc {
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      font-weight: 300;
      margin-bottom: 24px;
      position: relative;
      margin-left: 40px;
      font-size: 16px;
      color: black;
      line-height: 1.25;
      .li-title {
        font-weight: 500;
      }
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('../../../assets/icons/ic-check.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -40px;
        top: -8px;
      }
      &:last-child {
        margin-bottom: 48px;
      }
    }
  }
}
</style>
