<template>
  <div>
    <v-row class="pt-12">
      <v-col>
        <h3>Seleziona richiesta</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-2">
        <ExtensibleButtonGroup
          v-model="activeTab"
          :per-row="3"
          :content="!this.newClient ? true : isOptionExtended"
          :hideContentOnBlur="false"
        >
          <ExtensibleButton
            :disabled="disabledForSardegna || (!newAddressActive && !address)"
            name="newSupplier"
            label="Cambia il tuo attuale fornitore"
            @click="trackRequestSelection()"
          >
            <v-row>
              <v-col><h4>Carica la tua bolletta</h4></v-col>
              <v-col><h4>Dove posso chiamarti?</h4></v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn
                  height="66"
                  class="v-btn--shadowed mb-2 upload font-weight-regular"
                  color="white"
                  width="530"
                  raised
                  x-large
                  @click="openFileDialog"
                >
                  Importa documenti
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  v-model="phone1"
                  placeholder="numero di telefono"
                  persistent-placeholder
                  :error-messages="phoneErrors"
                  clearable
                  @blur="v$.phone1.$touch()"
                />
              </v-col>
            </v-row>
            <v-file-input
              id="file-upload"
              style="display: none"
              ref="files"
              @change="onFileChange($event)"
              v-model="file"
            />
            <v-row v-for="(item, i) in files" :key="i">
              <v-col>
                <v-card
                  class="v-card--rounded mb-6"
                  width="530"
                  height="66"
                  outlined
                >
                  <v-row class="pt-2">
                    <v-col class="ml-4" cols="1"
                      ><v-icon>mdi-file-image</v-icon></v-col
                    >
                    <v-col cols="9" :class="item.error ? 'error--text' : ''">{{
                      item.name
                    }}</v-col>
                    <v-col class="ml-3" cols="1"
                      ><v-icon
                        :color="item.error ? 'error' : 'primary'"
                        @click="deleteFile(i)"
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </v-card>
              </v-col>
              <v-col v-if="item.error" class="error--text pt-8">
                <h5 v-if="item.error === 'size'">
                  La dimensione del file supera il limite massimo di 1MB
                </h5>
                <h5 v-if="item.error === 'total_size'">
                  La dimensione totale dei file supera il limite massimo di 4MB
                </h5>
              </v-col>
            </v-row>
          </ExtensibleButton>
          <ExtensibleButton
            :disabled="disabledForSardegna || (!newAddressActive && !address)"
            name="deactivation"
            label="Riattiva contatore non più attivo"
            @click="trackRequestSelection()"
          >
            <v-container class="pl-7">
              <CallMeBack
                :phone.sync="phone"
                :time.sync="callTime1"
                :clientCode="clientDetails.clientCode"
                :email="email"
                :notes="notes"
                :supplyType="supplyType"
                :submit="submit"
                fromPage="commodity"
                @nextStep="sendSubmit"
              />
            </v-container>
          </ExtensibleButton>
          <ExtensibleButton
            :disabled="disabledForSardegna || (!newAddressActive && !address)"
            name="newTracker"
            label="Richiedi un nuovo contatore"
            @click="trackRequestSelection()"
          >
            <v-container class="pl-7">
              <CallMeBack
                :phone.sync="phoneNew"
                :time.sync="callTime2"
                :clientCode="clientDetails.clientCode"
                :email="email"
                :notes="notes"
                :supplyType="supplyType"
                :submit="submit"
                fromPage="commodity"
                @nextStep="sendSubmit"
              />
            </v-container>
          </ExtensibleButton>
        </ExtensibleButtonGroup>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="3">
        <v-btn
          @click="buttonClick"
          class="v-btn--shadowed"
          x-large
          color="primary"
          width="260"
          :outlined="isSubmitButtonDisabled"
          :disabled="isSubmitButtonDisabled"
          >Prosegui</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import CallMeBack from '../../components/callMeBack/CallMeBack'
import { AlertService } from '../../js/service/alertService'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'RequestComponent',

  components: {
    CallMeBack
  },
  props: {
    importActive: { type: Boolean, default: false },
    newAddressActive: { type: Boolean, default: false },
    disabledForSardegna: { type: Boolean, default: false },
    requestingClient: { type: Object },
    newClient: { type: Boolean, default: false },
    supplyType: { type: String },
    address: { type: [Object, String] }
  },
  data: () => ({
    activeContract: '',
    activeAddress: '',
    isOptionExtended: false,
    file: [],
    files: [],
    activeTab: '',
    callTime1: '',
    callTime2: '',
    phone1: '',
    phone: '',
    phoneNew: '',
    submit: false,
    notes: ''
  }),
  beforeMount() {
    if (!this.newClient) this.isOptionExtended = true
    if (!this.clientDetails) this.getDetailForClient()
    if (this.clientDetails) {
      this.phone1 = (
        this.clientDetails.mobilePhone ||
        this.clientDetails.phone ||
        ''
      ).replace(/\s/g, '')
      this.phone = (
        this.clientDetails.mobilePhone ||
        this.clientDetails.phone ||
        ''
      ).replace(/\s/g, '')
      this.phoneNew = (
        this.clientDetails.mobilePhone ||
        this.clientDetails.phone ||
        ''
      ).replace(/\s/g, '')
    }
  },
  mounted() {
    this.trackTapEvent({
      name: 'commodityUpsell_selectRequest_view',
      params: {
        client_code: this.requestingClient.clientCode,
        client_type: this.requestingClient.clientType
      }
    })
  },
  watch: {
    address(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== '') {
        this.activeTab = ''
      }
    },
    phone(value) {
      if (value !== undefined) this.$emit('update:phone', value)
    },
    phoneNew(value) {
      if (value !== undefined) this.$emit('update:phoneNew', value)
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails']),
    formModel() {
      if (this.activeTab === 'newSupplier') {
        return this.files.length > 0 &&
          !this.containsBigFiles() &&
          !this.v$.phone1.$invalid
          ? { files: this.files, phone: this.phone1.toString() }
          : null
      }
      if (this.activeTab === 'deactivation') {
        return this.phone.toString().length >= 6 &&
          this.phone.toString().length <= 12 &&
          this.callTime1
          ? {
              phone: this.phone.toString(),
              time: this.callTime1,
              function: this.activeTab
            }
          : null
      }
      if (this.activeTab === 'newTracker') {
        return this.phoneNew.toString().length >= 6 &&
          this.phoneNew.toString().length <= 12 &&
          this.callTime2
          ? {
              phone: this.phoneNew.toString(),
              time: this.callTime2,
              function: this.activeTab
            }
          : null
      }
      return null
    },
    isSubmitButtonDisabled() {
      if (!this.activeTab && !this.isOptionExtended && !this.formModel) {
        return true
      }
      if (!this.activeTab && !this.newClient) {
        return true
      }
      if (this.activeTab && this.isOptionExtended && !this.formModel) {
        return true
      } else {
        return false
      }
    },
    email() {
      return this.clientDetails ? this.clientDetails.contactMail : ''
    },
    phoneErrors() {
      let errArray = []

      if (!this.v$.phone1.$dirty) return errArray

      this.v$.phone1.minLength.$invalid === true &&
        errArray.push('Il numero di telefono deve essere di almeno 6 cifre')
      this.v$.phone1.maxLength.$invalid === true &&
        errArray.push('Il numero di telefono può avere al massimo 12 cifre')
      this.v$.phone1.required.$invalid === true &&
        errArray.push('Il Numero di telefono è obbligatorio')

      return errArray
    }
  },
  methods: {
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('interactions', ['callMeBack', 'sendFile']),
    ...mapActions('analytics', ['trackTapEvent']),
    buttonClick() {
      if (this.supplyType === 'gas' && this.address.egonRegion === 'SAR') {
        AlertService(
          ' ',
          "Ci dispiace, l'indirizzo selezionato non è raggiunto dalla rete nazionale",
          false,
          'Chiudi',
          '#'
        )
      } else {
        if (this.activeTab !== 'newSupplier') {
          let commodity =
            this.supplyType.toUpperCase() === 'ELE' ? 'Luce' : 'Gas'
          let intestatario =
            this.clientDetails.clientType === 'RES'
              ? this.clientDetails.name + ' ' + this.clientDetails.surname
              : this.clientDetails.businessName
          let indirizzo = this.address.street + ', ' + this.address.city
          let type =
            this.activeTab === 'newTracker'
              ? 'attiva nuovo contatore'
              : 'riattiva contatore '
          this.notes =
            commodity + '; ' + type + ';' + intestatario + ';' + indirizzo
          this.trackTapEvent({
            name: 'commodityUpsell_bookContact_tap',
            params: {
              client_code: this.requestingClient.clientCode,
              client_type: this.requestingClient.clientType
            }
          })
        }
        let input = {}

        if (!this.isOptionExtended) {
          this.isOptionExtended = true
        } else if (this.isOptionExtended && this.activeTab === 'newSupplier') {
          input = {
            indirizzo: !this.newAddressActive
              ? this.address.street
              : this.clientDetails.residentialAddress,
            comune: !this.newAddressActive
              ? this.address.city
              : this.clientDetails.residentialCity,
            cap: !this.newAddressActive
              ? this.address.zipCode
              : this.clientDetails.residentialCAP,
            codiceCliente: this.clientDetails.clientCode,
            telefono: this.formModel.phone,
            email: this.clientDetails.contactMail,
            oraPeriodo: 'indifferente',
            tipoOfferta: this.supplyType.toUpperCase()
          }
          if (this.clientDetails.clientType === 'BUS') {
            input.upsellingType = 'upsellingBusiness'
            input.ragioneSociale = this.clientDetails.businessName
            input.piva = this.clientDetails.VATNumber
          } else {
            input.upsellingType = 'upselling'
            input.nome = this.clientDetails.name
            input.cognome = this.clientDetails.surname
          }

          let formData = new FormData()
          for (var i = 0; i < this.files.length; i++) {
            let file = this.files[i]
            formData.append('files', file)
          }

          formData.append('input', JSON.stringify(input))
          this.sendFiles(formData)
        } else {
          this.submit = true
        }
      }
    },
    sendSubmit(phone) {
      this.$emit('nextStep', phone)
    },
    sendFiles(data) {
      this.sendFile(data)
        .then((resp) => {
          if (resp) {
            this.$emit('nextStep')
          }
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    },
    openFileDialog() {
      this.trackTapEvent({
        name: 'commodityUpsell_uploadFile_tap',
        params: {
          client_code: this.requestingClient.clientCode,
          client_type: this.requestingClient.clientType
        }
      })

      document.getElementById('file-upload').click()
    },
    onFileChange(evt) {
      const size = this.files.reduce((a, { size }) => a + size, 0)
      if (evt.size >= 1000000) {
        evt['error'] = 'size'
      }
      if (size + evt.size >= 4000000) {
        evt['error'] = 'total_size'
      }
      this.files.push(evt)
    },
    deleteFile(index) {
      this.files.splice(index, 1)
    },
    containsBigFiles() {
      return this.files.findIndex((val) => val.error) >= 0
    },
    // filterPhone (val) {
    //   if (val) {
    //     this.phone1 =
    //   } else {
    //     this.phone1 = ''
    //   }
    // }

    trackRequestSelection() {
      switch (this.activeTab) {
        case 'newSupplier':
          this.trackTapEvent({
            name: 'commodityUpsell_changeRequest_tap',
            params: {
              client_code: this.requestingClient.clientCode,
              client_type: this.requestingClient.clientType
            }
          })
          break
        case 'deactivation':
          this.trackTapEvent({
            name: 'commodityUpsell_reactivationRequest_tap',
            params: {
              client_code: this.requestingClient.clientCode,
              client_type: this.requestingClient.clientType
            }
          })
          break
        case 'newTracker':
          this.trackTapEvent({
            name: 'commodityUpsell_trackerRequest_tap',
            params: {
              client_code: this.requestingClient.clientCode,
              client_type: this.requestingClient.clientType
            }
          })
          break
      }
    }
  },
  validations: {
    phone1: { required, minLength: minLength(6), maxLength: maxLength(12) }
  }
}
</script>

<style lang="scss" src="../../styles/upselling/_main.scss" />
<style lang="scss" scoped>
.upSelling {
  min-height: 654px;
  width: 100%;
}
span {
  color: $srg-blue;
  font-weight: bold;
}
.offering {
  h1 {
    color: white;
  }
}
.upload {
  font-size: 16px;
  letter-spacing: initial;
  justify-content: start;
  color: black !important;
}
.v-card--rounded {
  border-radius: 20px !important;
}
</style>
