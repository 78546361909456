<template>
  <div class="link" @click="$emit('redirectToCheckup')">
    <LayoutCard :loaded="loaded">
      <template v-slot:bkg>
        <div class="bkg" />
      </template>
      <template v-slot:text>
        <div class="text justify-end">
          <div class="title">
            <div class="t-white HeadingL-Upper">Rendi più efficiente</div>
            <div class="color--green HeadingL-Upper">LA TUA CASA</div>
          </div>
        </div>
      </template>
      <template v-slot:button>
        <div class="btn-card" v-if="!loaded" />
        <div class="btn-card" v-else>
          <div class="btn-text">FAI IL CHECK-UP</div>
          <img src="../../../assets/icons/chevron_rx.svg" width="24px" />
        </div>
      </template>
    </LayoutCard>
  </div>
</template>
<script>
import LayoutCard from './LayoutCard'

export default {
  name: 'checkupCard',
  components: {
    LayoutCard
  },
  props: {
    loaded: { type: Boolean, default: false }
  }
}
</script>
<style lang="scss" scoped>
.bkg {
  background-image: url('../../../assets/new-home/checkup-med-opacity.png');
  background-size: cover;
  &.full {
    background-image: url('../../../assets/new-home/checkup-med-opacity.png');
  }
}
</style>
