<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="6" align-self="center">
        <h1 class="white--text">Ritira i tuoi premi</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5" align-self="center">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div class="reward__content">
      <v-row class="mb-12">
        <v-col>
          <h2 class="mb-7">Bicicletta Pieghevole</h2>
          <p>
            Con Sorgenia rendi la tua vita quotidiana più semplice<br />
            e sostenibile!<br />
            Muoviti nella tua città in modalità “impatto zero”!<br />
          </p>
        </v-col>
        <v-col>
          <div class="bicycle"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            class="v-btn--shadowed"
            color="brightGreen"
            x-large
            width="265"
            @click="
              $router.push({
                name: 'giftShipping',
                params: { catalog: 'IW-WL', type: 'bicycle', id: '0' }
              })
            "
          >
            Ritira il premio
          </v-btn>
        </v-col>
      </v-row>
      <v-footer color="white" height="90" />
    </div>
  </layout>
</template>

<script>
import Layout from '../Layout'

export default {
  name: 'BicycleReward',
  components: {
    layout: Layout
  }
}
</script>
<style lang="scss" scoped>
.reward {
  &__content {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;
    .bicycle {
      background-image: url('../../../assets/greeners/img_bl_bicycle.png');
      background-size: 450px;
      margin-left: 35px;
      height: 400px;
    }
    p {
      font-size: 20px;
      color: black;
    }
  }
}
</style>
