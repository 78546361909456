var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.filteredSupplies),function(business,index){return _c('div',{key:index},[_c('div',{staticClass:"supplyOwner"},[(business.clientOwnerType === 'RES')?_c('img',{attrs:{"src":require("../../assets/ic-residenziale_blue.svg"),"width":"24"}}):_vm._e(),(business.clientOwnerType === 'BUS')?_c('img',{attrs:{"src":require("../../assets/icons/business.svg"),"width":"24"}}):_vm._e(),_c('div',{staticClass:"BodyL-Strong ml-4 mt-1"},[_vm._v(" "+_vm._s(business.businessName)+" ")])]),_vm._l((business.services),function(service,k){return _c('div',{key:service.type + k,on:{"click":function($event){return _vm.selectAddress(service, business.businessName)}}},[_c('div',{staticClass:"supplyAddress"},[_c('div',{staticClass:"supplySection"},[(service.type === 'gas')?_c('img',{attrs:{"src":require("../../assets/icons/ic-fire-blu.svg"),"alt":"","width":"24"}}):_vm._e(),(service.type === 'ele')?_c('img',{attrs:{"src":require("../../assets/icons/ic-light-blu.svg"),"alt":"","width":"24"}}):_vm._e(),(service.type === 'fotovoltaico')?_c('img',{attrs:{"src":require("../../assets/icons/ic-fotovoltaico-blu.svg"),"alt":"","width":"24"}}):_vm._e(),_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"BodyL"},[_vm._v(" "+_vm._s(service.street)+" "+_vm._s(service.zipCode)+" "+_vm._s(service.city)+" ")]),(service.type === 'ele' || service.type === 'gas')?_c('div',{staticClass:"mt-1 mb-1"},[(service.paymentStatus === 'Regolari')?_c('div',{staticClass:"CaptionS status status__regular"},[_vm._v(" REGOLARE ")]):_vm._e(),(service.paymentStatus === 'Non regolari')?_c('div',{staticClass:"CaptionS status status__notRegular"},[_vm._v(" PAGAMENTI DA CONTROLLARE ")]):_vm._e()]):_c('div',{staticClass:"mt-1 mb-1"},[(
                  service.installationStatus === _vm.OVERALL_STATUS.NO_ANOMALY ||
                  service.installationStatus ===
                    _vm.OVERALL_STATUS.VALIDATION_ERROR
                )?_c('div',{staticClass:"CaptionS status status__regular"},[_vm._v(" REGOLARE ")]):(
                  service.installationStatus === _vm.OVERALL_STATUS.ALARM
                )?_c('div',{staticClass:"CaptionS status status__notRegular"},[_vm._v(" NON FUNZIONANTE ")]):_c('div',{staticClass:"CaptionS status status__notRegular"},[_vm._v(" OFFLINE ")])])])]),_c('img',{attrs:{"src":require("@/assets/icons/chevron_rx_light.svg"),"width":"16"}})])])})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }