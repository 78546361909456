<template>
  <div class="request mt-2">
    <v-radio-group v-model="request" row>
      <div
        :class="`request__radioCustom ${
          request === 'change' ? 'selected' : ''
        } mr-8`"
      >
        <v-radio value="change">
          <template v-slot:label>
            <h4 class="color--blue"><strong>Cambio fornitore</strong></h4>
          </template>
        </v-radio>
      </div>
      <div
        :class="`request__radioCustom ${request === 'new' ? 'selected' : ''}`"
      >
        <v-radio value="new">
          <template v-slot:label>
            <h4 class="color--blue"><strong>Nuova connessione</strong></h4>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
    <div class="request__content" v-show="request === 'change'">
      <h3 class="mb-2">Carica la tua bolletta</h3>
      <h5 class="mb-6">
        Se fai l'upload della bolletta ora non servirà comunicare il codice di
        migrazione in seguito
      </h5>
      <div class="request__box" @click="handleClick">
        <h4>{{ file ? file.name : 'Importa documenti' }}</h4>
        <v-file-input
          id="bill-upload"
          style="display: none"
          ref="files"
          v-model="file"
          clearable
        />
      </div>
    </div>
    <div class="request__content">
      <h3 class="mb-6">Compila il form per essere ricontattato</h3>
      <div class="request__form d-flex justify-space-between mb-6">
        <v-text-field
          class="request__input mr-7"
          placeholder="Inserisci il nome"
          label="NOME REFERENTE CONTRATTUALE"
          v-model="firstName"
          :rules="[rules.required]"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          class="request__input"
          placeholder="Inserisci il cognome"
          label="COGNOME REFERENTE CONTRATTUALE"
          v-model="lastName"
          :rules="[rules.required]"
          persistent-placeholder
        ></v-text-field>
      </div>
      <div class="request__form d-flex justify-space-between mb-6">
        <v-text-field
          class="request__input mr-7"
          placeholder="Inserisci l'email"
          label="EMAIL"
          v-model="email"
          :rules="[rules.required, rules.emailError]"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          class="request__input"
          placeholder="Inserisci il numero di telefono"
          type="number"
          label="N.DI TELEFONO"
          v-model="numberPhone"
          :rules="[rules.required]"
          persistent-placeholder
        ></v-text-field>
      </div>
      <div class="request__form small mb-6">
        <v-select
          :items="['Indifferente', 'Mattina', 'Pomeriggio', 'Sera']"
          label="FASCIA ORARIA"
          v-model.trim="times"
        ></v-select>
      </div>
    </div>
    <v-btn
      color="primary"
      x-large
      width="343"
      class="v-btn--shadowed request__btn"
      :disabled="disableBtn"
      @click="upload"
      >Prosegui</v-btn
    >
  </div>
</template>
<script>
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters } from 'vuex'
import { isValidEmail } from '@/js/utils'
import Constants from '@/js/constants'
// import { paymentTypeLabel } from '@/js/constants'

export default {
  name: 'selectRequest',
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Campo obbligatorio.',
        emailError: (value) => !!isValidEmail(value) || 'Email non valida'
      },
      request: 'change',
      file: null,
      firstName: '',
      lastName: '',
      numberPhone: '',
      email: '',
      clientDetail: '',
      times: 'Indifferente',
      mdp: '',
      commodityAttive: 0
    }
  },
  created() {
    this.getDetailForClient({
      clientOwnerCode: this.client.clientOwnerCode
    }).then((client) => {
      this.clientDetail = client
      this.numberPhone = client.mobilePhone || client.phone
      this.email = client.contactMail || ''
      this.getMethods({ clientCode: client.clientCode }).then((methods) => {
        this.mdp = [...new Set(methods.map((item) => item.methodType))].join(
          ', '
        )
        this.commodityAttive = this.supplies.filter(
          (supply) =>
            supply.clientOwnerCode === client.clientCode &&
            supply.supplyState === Constants.SUPPLY_STATUS.ACTIVE
        ).length
      })
    })
  },
  computed: {
    ...mapGetters('upsellingFiber', ['client', 'addressCovered']),
    ...mapGetters('supply', ['supplies']),
    disableBtn() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.numberPhone ||
        !this.email ||
        !this.times
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions('interactions', ['upsellingLeadFoto']),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('interactions', { callMeBack: 'callMeBack' }),
    ...mapActions('payment', ['getMethods']),
    handleClick() {
      this.trackTapEvent({
        name: 'upsellingv2_importDoc_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      document.getElementById('bill-upload').click()
    },
    deleteFile() {
      this.file = null
    },
    upload() {
      this.trackTapEvent({
        name: 'upsellingv2_ClickToCallContinue_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      if (this.request === 'change' && this.file) {
        let formData = new FormData()
        let input = {
          upsellingType:
            this.addressCovered.clientOwnerType === 'RES'
              ? 'upsellingFibra'
              : 'upsellingFibraBusiness',
          indirizzo: `${this.addressCovered.street}`,
          comune: this.addressCovered.city,
          cap: this.addressCovered.zipCode,
          codiceCliente: this.client.clientCode,
          telefono: this.numberPhone,
          nome: this.addressCovered.firstName || '',
          cognome: this.addressCovered.lastName || '',
          ragioneSociale: this.addressCovered.businessName,
          piva: this.client.VATNumber,
          email: this.email,
          giorno: new Date().toLocaleString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }),
          oraPeriodo: this.times.toLowerCase(),
          tipoOfferta: 'FIBRA',
          nominativoReferenteContrattuale: `${this.firstName} ${this.lastName}`,
          nota: '',
          mdp: this.mdp,
          commodityAttive: this.commodityAttive,
          digital: this.addressCovered.isDigital
        }

        formData.append('input', JSON.stringify(input))
        formData.append('files', this.file, this.file.name)

        this.upsellingLeadFoto(formData).then((resp) => {
          if (resp)
            this.$router.push({
              name: 'fiberNewRequestBusSent',
              params: { type: 'bill' }
            })
        })
      } else {
        let landLineData = {
          email: this.email,
          clientCode: this.client.clientOwnerCode,
          phone: this.numberPhone,
          commodity: 'fiber',
          times: this.times.toLowerCase(),
          notes: '',
          ragioneSociale: this.addressCovered.businessName,
          partitaIva: this.client.VATNumber,
          nominativoReferenteContrattuale: `${this.firstName} ${this.lastName}`,
          commodityAttive: this.commodityAttive,
          mdp: this.mdp,
          digital: this.addressCovered.isDigital
        }
        this.callMeBack(landLineData)
          .then((resp) => {
            this.$router.push({
              name: 'fiberNewRequestBusSent',
              params: { type: 'callme' }
            })
          })
          .catch((error) => {
            GenErr.handleGenericError(error && error.message)
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.request {
  &__radioCustom {
    width: 540px;
    height: 57px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 20px;
    border: solid 1px #dfe4ef;
    &.selected {
      border: solid 1px $srg-blue;
    }
  }
  &__content {
    margin-top: 64px;
    h3 {
      font-weight: 500;
      letter-spacing: 0.48px;
      line-height: 1.2;
      margin-bottom: 8px;
    }
    h5 {
      line-height: 1.4;
      letter-spacing: 0.5px;
      color: black;
    }
  }
  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 362px;
    height: 54px;
    margin-bottom: 32px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    cursor: pointer;
    h4 {
      font-weight: bold;
      color: $srg-blue;
      letter-spacing: 0.8px;
    }
    &::after {
      content: url('../../../assets/icons/plus.svg');
      margin-left: 8px;
    }
  }
  &__form {
    width: 752px;
    &.small {
      width: 360px;
    }
  }
  &__btn {
    margin-bottom: 80px;
  }
}
</style>
<style lang="scss">
.request__form {
  label.v-label.theme--light {
    top: -25px;
    transition: none;
    transform: none;
  }
  .theme--light.v-input input {
    color: $srg-blue !important;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .v-text-field .v-label {
    color: #6e7689 !important;
  }
}
.v-menu__content .v-list-item .v-list-item__content {
  border-bottom: none !important;
  color: #193494;
  font-weight: 400;
}
.v-menu__content .v-list-item--active .v-list-item__content {
  font-weight: bold;
  color: $srg-blue;
}
</style>
