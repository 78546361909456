<template>
  <div class="login">
    <TcVars />
    <div class="login__content">
      <img
        src="../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <template v-if="!dialog">
        <div class="login__box">
          <div class="login__title">Benvenuto in Sorgenia</div>
          <template v-if="flagLock">
            <div class="BodyL text-center mb-10">
              <strong>Impossibile accedere in questo momento.</strong><br />
              Hai effettuato troppi tentativi di accesso e per la tua sicurezza
              non puoi andare avanti. Riprova più tardi.
            </div>
            <div class="d-flex justify-center">
              <MYSButton
                buttonText="Torna indietro"
                buttonClass="primaryButtonL ButtonL"
                @buttonClick="flagLock = !flagLock"
              />
            </div>
          </template>
          <template v-else>
            <div class="login__subTitle">
              Inserisci le tue credenziali di accesso per entrare nell’area
              personale di MySorgenia.
            </div>

            <div class="login__auth">
              <v-text-field
                tabindex="1"
                :error-messages="usernameErrors"
                @blur="v$.username.$touch()"
                class="auth__input"
                clearable
                label="NOME UTENTE"
                v-model.trim="username"
              />
              <v-text-field
                tabindex="2"
                :error-messages="passwordErrors"
                :type="showPassword ? 'text' : 'password'"
                @blur="v$.password.$touch()"
                @keyup.enter="login"
                class="auth__input"
                label="PASSWORD"
                color="#6e7689"
                name="password"
                v-model.lazy.trim="password"
              >
                <template v-slot:append>
                  <v-icon
                    @click="showPassword = !showPassword"
                    color="#12256a"
                    >{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon
                  >
                </template>
              </v-text-field>
            </div>
            <div class="login__error">
              <span class="red--text">{{ errorMessage }}</span>
            </div>
            <div class="login__help">
              <router-link
                to="retrievePassword"
                @click="trackTapEvent('login_forgotPassword_tap')"
              >
                <div class="question">Non ricordi la password?</div>
              </router-link>
              <router-link
                to="retrieveCredentials"
                @click="trackTapEvent('login_recoverCredentials_tap')"
              >
                <div class="question">Non ricordi le tue credenziali?</div>
              </router-link>
              <router-link to="superlogin" v-if="enabledAdminLogin">
                <h5 class="question">Admin</h5>
              </router-link>
            </div>
            <div class="login__btn">
              <MYSButton
                :disabled="v$.$invalid"
                @buttonClick="login"
                buttonText="Accedi"
                buttonClass="primaryButton primaryButtonL"
              />
              <MYSButton
                @buttonClick="goToRegistration"
                buttonText="Registrati"
                buttonClass="primaryInvertedButtonLBorded"
              />
            </div>
            <div class="login__btn--green">
              <MYSButton
                buttonText="Diventa cliente"
                buttonClass="srgButton srgButtonXXL shadow bold"
                @buttonClick="trackTapEvent({ name: 'auth_becomeClient_tap' })"
                href="https://www.sorgenia.it/offerte-luce-e-gas-casa"
              />
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="login__box">
          <div class="login__title mb-10">Benvenuto in Sorgenia</div>
          <div class="login__check mb-10 d-flex justify-center">
            <div
              @click="privacy = !privacy"
              :class="`checkbox ${privacy ? 'selected' : ''}`"
            >
              <div class="black--text ml-3">
                Ho preso visione
                <span
                  @click="showPdf = true"
                  id="loginInfoPdfTrigger"
                  class="color--blue"
                  >dell'informativa</span
                >
              </div>
            </div>
          </div>
          <div class="login__btn mb-0 d-flex justify-center">
            <MYSButton
              buttonText="Accetto"
              buttonClass="primaryButton primaryButtonL"
              width="156"
              @buttonClick="setPrivacy"
              :disabled="!privacy"
            />
          </div>
        </div>
      </template>
    </div>
    <pdf-viewer
      :showDialog="showPdf"
      :url="infoDoc"
      @closing-pdfViewer="showPdf = false"
      height="600px"
      maxWidth="60%"
    />
  </div>
</template>

<script>
import { ENV_CONFIGS } from '@/js/configs.js'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import PdfViewer from '../components/pdfViewer/PdfViewer.vue'
import router from '../router/router'
// import RocketAuth from '../components/authentication/Rocket'
import TcVars from '@/components/tcTracker/TcVars'
import MYSButton from '../components/global/MYSButton.vue'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'loginComponent',
  components: {
    pdfViewer: PdfViewer,
    TcVars,
    MYSButton
  },
  data() {
    return {
      showPdf: false,
      infoDoc: '',

      username: '',
      password: '',
      showPassword: false,
      dialog: false,
      privacy: false,
      errorMessage: '',

      enabledAdminLogin: ENV_CONFIGS.ENABLE_ADMIN_LOGIN,

      errorMessages: {
        WRONG_USER_PASSWORD: 'Username o password errati',
        EXPIRED_PASSWORD: 'La password è scaduta, inseriscine una nuova',
        UNKNOWN_ERROR:
          'Si è verificato un errore imprevisto durante il login. Ti chiediamo di riprovare.',
        SERVER_ERROR:
          'Si è verificato un errore imprevisto durante il login. Ti chiediamo di riprovare.',
        MAIL_NOT_CONFIRMED:
          'Conferma le tue credenziali dal link che ti abbiamo inviato via e-mail',
        MAX_RETRIES:
          'Impossibile contattare il server: la sessione verrà interrotta.\nRiprova facendo un nuovo login.'
      },
      flagLock: false,
      mailNotConfirmed: false
    }
  },
  watch: {
    username() {
      this.mailNotConfirmed = false
    }
  },
  computed: {
    ...mapGetters('session', ['getMailNotConfirmed']),
    usernameErrors: function () {
      let errArray = []

      if (!this.v$.username.$dirty) return errArray
      this.mailNotConfirmed === true &&
        errArray.push(this.errorMessages['MAIL_NOT_CONFIRMED'])

      this.v$.username.required.$invalid === true &&
        errArray.push('La username è obbligatoria')
      return errArray
    },

    passwordErrors: function () {
      let errArray = []

      if (!this.v$.password.$dirty) return errArray

      this.v$.password.required.$invalid === true &&
        errArray.push('La password è obbligatoria')

      return errArray
    }
  },
  created() {
    const reason = router.currentRoute.query['reason']

    this.errorMessage = this.errorMessages[reason] || ''

    this.getLoginInfoDocUrl().then((url) => {
      this.infoDoc = url
    })
  },
  methods: {
    ...mapActions('session', {
      loginService: 'login', // simple binding from `store.session.actions.login()` to `this.loginService()`
      updatePrivacyService: 'updatePrivacy'
    }),
    ...mapActions('analytics', ['trackTapEvent']),
    setPrivacy() {
      if (!this.privacy) {
        this.dialog = false
      } else {
        this.updatePrivacyService({
          username: this.username
        }).then((resp) => {
          if (resp) {
            const fwdURL = router.currentRoute.query['forwardURL']
            if (fwdURL) {
              router.replace(fwdURL)
            } else {
              router.replace('/private/home')
            }
          }
        })
      }
    },

    async login() {
      this.dialog = false
      this.errorMessage = ''
      // Show a loader
      try {
        await this.loginService({
          username: this.username,
          password: this.password
        }).then((resp) => {
          if (resp.prospect) {
            this.trackTapEvent({
              name: 'login_prospect_tap',
              params: { account_type: 'prospect', username: this.username }
            })
            router.push({ name: 'ProspectLogin' })
          } else if (!resp.privacyFlag) {
            this.dialog = true
          } else {
            const fwdURL = router.currentRoute.query['forwardURL']
            if (fwdURL) {
              router.replace(fwdURL)
            } else {
              router.replace('/private/home')
            }
          }
        })
      } catch (error) {
        console.error('Login error' + error)
        switch (error.message) {
          case 'EXPIRED_PASSWORD':
            // Redirect to the update-pwassord page that will then log me in (possibly with a forwardurl)
            const fwdURL = router.currentRoute.query['forwardURL']
            router.push(
              'changePassword' + (fwdURL ? '?forwardURL=' + fwdURL : '')
            )

            break
          case 'ACCOUNT_LOCKED':
            this.flagLock = true

            break
          case 'ACCOUNT_NOT_READY':
            router.push({ name: 'AccountNotReady' })
            break
          case 'MAIL_NOT_CONFIRMED':
            this.mailNotConfirmed = true
            break
          default:
            this.errorMessage = this.errorMessages[error.message]
            break
        }
      }
    },

    goToRegistration() {
      this.trackTapEvent({ name: 'auth_register_tap' })
      router.push({ name: 'RegistrationChoise' })
    },

    ...mapActions('documents', {
      getLoginInfoDocUrl: 'getLoginInfoDocUrl' // simple binding from `store.session.actions.login()` to `this.loginService()`
    })
  },
  validations: {
    username: { required },
    password: { required }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/authentication/auth.scss';
</style>

<style lang="scss">
.login {
  span.v-btn__content {
    text-transform: initial;
  }
}
.login {
  label.v-label.theme--light {
    top: -25px;
    transition: none;
    transform: none;
  }
  .theme--light.v-input input {
    color: $srg-blue;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .v-text-field .v-label {
    color: #6e7689 !important;
  }
  .checkbox {
    display: flex;
    color: black;
    cursor: pointer;
    font-weight: normal;
    &.selected {
      &:before {
        content: url(~@/assets/icons/checkbox_active.svg);
      }
    }
    &:before {
      content: url(~@/assets/icons/checkbox.svg);
    }
  }
}
.privacy-modal {
  background: white;
  border-radius: 40px;
  height: 220px;
  padding: 48px 10px 48px 48px;
  font-family: Roboto;

  .privacy-modal__title {
    font-weight: bold;
    font-size: $title-font-size;
  }

  .privacy-modal__accept {
    font-weight: 500;
    font-size: $std-font-size;
  }

  .privacy-modal__continue {
    font-weight: bold;
    font-size: $std-font-size;
    text-align: right;
  }
}
</style>

<style lang="scss">
.login {
  .error--text {
    .v-messages__message {
      display: flex;
      align-items: center;
      margin-right: 2px;
      &::before {
        content: url('../assets/icons/Error.svg');
      }
    }
    input {
      color: red !important;
    }
  }
}
</style>
