<template>
  <div class="std-side-padding upSelling">
    <div>
      <v-row class="mt-12 pt-4">
        <v-col>
          <h2 class="upSelling__title">Sottoscrizione</h2>
        </v-col>
      </v-row>
      <v-row class="pt-4">
        <v-col>
          <h3>Seleziona indirizzo</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mb-2">
          <ExtensibleButtonGroup
            v-model="activeAddress"
            :per-row="uniqueSupplyAddress.length >= 2 ? 3 : 2"
            :content="false"
            :hide-content-on-blur="false"
          >
            <ExtensibleButton
              v-for="(supply, i) in uniqueSupplyAddress"
              :key="i"
              :label="$filters.capitalize(supply.address)"
              :id="supply.supplyCode"
              :name="supply"
              :click="isRegionSardegnaDisableGas(activeAddress)"
            />
            <ExtensibleButton
              label-color="#12256A;"
              name="newAddress"
              label="Aggiungi nuovo indirizzo"
            />
          </ExtensibleButtonGroup>
        </v-col>
      </v-row>
    </div>
    <request
      :disabledForSardegna="disabledForSardegna"
      :address="activeAddress"
      :supplyType="supplyType"
      @nextStep="$emit('nextStep', $event)"
      :requestingClient="requestingClient"
      :newClient="newClient"
      :newAddressActive="newAddressActive"
    />
  </div>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { mapActions } from 'vuex'
import ExtensibleButton from '../../components/extensibleButton/ExtensibleButton'
import ExtensibleButtonGroup from '../../components/extensibleButton/ExtensibleButtonGroup'
import { AlertService } from '../../js/service/alertService'
import Request from './Request'

export default {
  name: 'OfferComponent',

  components: {
    ExtensibleButtonGroup: ExtensibleButtonGroup,
    ExtensibleButton: ExtensibleButton,
    request: Request
  },
  props: {
    newClient: { type: Boolean, default: false },
    supplyType: { type: String },
    codeClient: { type: String },
    requestingClient: { type: Object }
  },
  data: () => ({
    activeAddress: '',
    supplyList: [],
    disabledForSardegna: false
  }),
  beforeMount() {
    this.getSupplies()
      .then((resp) => {
        this.supplyList = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  computed: {
    uniqueSupplyAddress() {
      let uniqueAddres = []
      let objCompare = {}
      this.supplyList.forEach((supply) => {
        let address = supply.street + ', ' + supply.city
        if (this.codeClient) {
          if (
            objCompare[address] === undefined &&
            supply.clientOwnerCode === this.codeClient
          ) {
            supply.address = address
            uniqueAddres.push(supply)
            objCompare[address] = ''
          }
        } else {
          if (objCompare[address] === undefined) {
            supply.address = address
            uniqueAddres.push(supply)
            objCompare[address] = ''
          }
        }
      })
      return uniqueAddres
    },
    newAddressActive() {
      if (this.activeAddress === 'newAddress') return true
      else return false
    }
  },
  methods: {
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('analytics', ['trackTapEvent']),
    isRegionSardegnaDisableGas(supplySelected) {
      if (
        this.$route.name === 'gasOfferingBUS' &&
        supplySelected.egonRegion === 'SAR'
      ) {
        this.disabledForSardegna = true
        AlertService(
          '',
          "Ci dispiace, l'indirizzo selezionato non è raggiunto dalla rete nazionale",
          false,
          'Chiudi',
          '#'
        )
      } else this.disabledForSardegna = false
    }
  },
  mounted() {
    this.trackTapEvent({ name: 'commodityUpsell_selectAddress_view' })
  }
}
</script>

<style lang="scss" scoped>
.details {
  padding-top: 64px;
  &-desc {
    &__title {
      font-size: 42px;
      font-weight: bold;
      margin-bottom: 32px;
    }

    &__list >>> ul {
      background: #97d707;
      li {
        font-weight: 300;
        margin-bottom: 24px;
        position: relative;
        margin-left: 40px;

        &:before {
          width: 20px;
          content: url('../../assets/icons/ic-check.svg');
          position: absolute;
          left: -40px;
          top: 2px;
        }
      }
    }
  }

  &-promo {
    padding: 30px 0 0 60px;
    text-align: center;

    &__circle {
      width: 285px;
      height: 285px;
      border-radius: 195px;
      background-color: $srg-green;
      color: white;

      span {
        font-weight: bold;
        font-size: 36px;
        margin-bottom: 14px;
      }

      h3 {
        width: 228px;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: bold;

        span {
          margin-bottom: initial;
          font-size: 24px;
          display: block;
        }
      }

      h6 {
        margin-top: 20px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
</style>
