<template>
  <v-dialog :value="dialogDownload" max-width="576">
    <v-row>
      <v-col class="owner-dialog">
        <img @click="closeDialog" src="../../assets/ic_chevron_top_black.png" />
        <h2>Ci siamo quasi!</h2>
        <div class="subtitle">Download in corso...</div>
        <div class="progress">
          <v-progress-circular :size="100" indeterminate color="#12256A" />
        </div>
        <div class="cancel">
          <v-btn
            color="white"
            x-large
            width="265"
            class="v-btn--shadowed"
            @click="closeDialog"
            >Annulla</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
export default {
  name: 'DownloadComponent',
  props: ['dialogDownload'],
  methods: {
    closeDialog() {
      this.$emit('closeModal')
    }
  }
}
</script>
<style lang="scss" scoped>
.v-dialog__content {
  .owner-dialog {
    background: white;
    padding: 48px 58px 24px 48px;
    border-radius: 40px;
    position: relative;
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    .owner {
      display: flex;
      align-items: center;
      width: 458px;
      height: 66px;
      border-radius: 20px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff;
      padding: 0 32px;
      margin-bottom: 24px;
      cursor: pointer;
    }
  }
  .progress {
    margin: 40px auto 60px;
    text-align: center;
  }
  .cancel {
    text-align: center;
  }
}
</style>
