<template>
  <div class="login">
    <TcVars />
    <div class="login__content">
      <img
        src="../assets/icons/my-sorgenia-logo.svg"
        width="203"
        height="56px"
      />
      <div class="login__box">
        <template v-if="error">
          <FiscalCodeError @errorGoBack="errorGoBack" />
        </template>
        <template v-else-if="!requestSent">
          <div class="login__title">Recupero credenziali</div>
          <div class="login__subTitle">Inserisci i tuoi dati.</div>
          <div class="login__auth">
            <v-text-field
              v-model="cfpi"
              @blur="v$.cfpi.$touch()"
              label="CODICE FISCALE / PARTITA IVA"
              placeholder="Es: BSCTTR88M33F556B"
              persistent-placeholder
              clearable
              class="auth__input"
              :error-messages="cfpiErrors"
            ></v-text-field>
          </div>
          <div class="login__btn">
            <v-btn
              class="white--text auth__btn"
              color="primary"
              width="459"
              height="54"
              :rounded="true"
              @click="requestCredentials"
              :disabled="v$.$invalid"
            >
              Richiedi credenziali
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div class="login__subtitle">
            Controlla la tua email:<br />
            <b>{{ email }}</b>
          </div>
          <p>Ti abbiamo inviato le credenziali di accesso.</p>
          <div class="login__btn">
            <v-btn
              class="white--text auth__btn"
              color="primary"
              width="222"
              height="54"
              :rounded="true"
              @click="goToLogin"
            >
              Torna al login
            </v-btn>
          </div>
        </template>
      </div>
    </div>
  </div>
  <!-- <div class="topRadius">
    <v-container class="auth">
      <v-row no-gutters="">
        <v-col sm="6" offset-sm="6">
          <h1 class="auth__title">Recupero credenziali</h1>

          <template v-if="!requestSent">

            <h4 class="auth__subtitle">Inserisci i tuoi dati</h4>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="cfpi"
                  @blur="v$.cfpi.$touch()"
                  label="Codice fiscale / Partita iva"
                  clearable
                  :error-messages="cfpiErrors"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  class="white--text auth__btn"
                  color="#12256A"
                  :rounded="true"
                  @click="requestCredentials" :disabled="v$.$invalid"
                >
                  Richiedi credenziali
                </v-btn>
              </v-col>
            </v-row>

          </template>

          <template v-if="requestSent">
            <p class="auth__subtitle">
              Controlla la tua email:<br>
              <b>{{email}}</b>
            </p>
            <p>Ti abbiamo inviato le credenziali di accesso.</p>

            <v-btn
              class="white--text auth__btn"
              color="#12256A"
              :rounded="true"
              @click="goToLogin"
            >
              Torna al login
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div> -->
</template>
<script>
import { validateCFPI } from '@/js/utils'
import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import router from '../router/router'
// import GenErr from '@/js/genericErrors'
import TcVars from '@/components/tcTracker/TcVars'
import FiscalCodeError from '../components/errorMessages/FiscalCodeError.vue'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      cfpi: null,
      requestSent: false,
      email: '',
      error: false
    }
  },
  components: {
    FiscalCodeError,
    TcVars
  },
  computed: {
    cfpiErrors: function () {
      let errArray = []

      if (!this.v$.cfpi.$dirty) return errArray

      this.v$.cfpi.required.$invalid === true &&
        errArray.push('Il campo codice fiscale/partita iva è obbligatorio')
      this.v$.cfpi.validateCFPI === false &&
        errArray.push('Formato codice fiscale / partita iva non valido')

      return errArray
    }
  },
  methods: {
    ...mapActions('account', {
      retrieveCredentialService: 'retrieveCredentials' // simple binding from `store.session.actions.login()` to `this.loginService()`
    }),

    async requestCredentials() {
      try {
        const email = await this.retrieveCredentialService({ cfpi: this.cfpi })

        this.requestSent = true
        this.email = email
      } catch (error) {
        // if (error.message === 'CFPI_NOT_REGISTERED') {
        //   GenErr.showErrorMessage('Codice fiscale o partita IVA non validi.')
        // } else {
        //   GenErr.handleGenericError(error && error.message)
        // }
        this.error = true
      }
    },

    goToLogin() {
      router.push({ name: 'login' })
    },

    errorGoBack() {
      this.error = false
    }
  },
  watch: {
    cfpi(newVal) {
      this.cfpi = newVal.toUpperCase()
    }
  },
  validations: {
    cfpi: { required, validateCFPI }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/authentication/auth.scss';
</style>
