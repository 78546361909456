<template>
  <page
    typeCommodity="gas"
    class="gas"
    :client="clientDetail"
    :newClient="newClient"
    :newClientType="newClientType"
    :footer="!(offerStep || successStep)"
    :smallHeader="offerStep || successStep"
    :successStep="successStep"
  >
    <template v-slot:header>
      <div class="header-info" v-if="clientsStep">
        <v-img
          class="header-info__img"
          :src="require('../../assets/icons/ic-fire-white.svg')"
          height="74"
          contain
        />
        <h2 class="header-info__text">
          Pochi passi per scoprire l’offerta Gas di Sorgenia
        </h2>
      </div>
      <div
        :class="`header-info ${isReady ? '' : 'd-none'}`"
        v-else-if="infoStep && upsellingInfo"
      >
        <h2 class="header-info__name">{{ upsellingInfo.name || '' }}!</h2>
        <h2 class="header-info__desc">{{ upsellingInfo.description || '' }}</h2>
        <p class="header-info__price-header">
          {{ upsellingInfo.price_header || '' }}
        </p>
        <span class="header-info__price-currency"
          >{{ upsellingInfo.price_lower }}
          {{ upsellingInfo.price_currency || '' }}</span
        >
        <p class="header-info__price-period">
          {{ upsellingInfo.price_period || '' }}
        </p>
      </div>
      <div
        :class="`header-info ${isReady ? '' : 'd-none'}`"
        v-else-if="offerStep"
      >
        <h2 class="pt-0 header-info__text">{{ upsellingInfo.name }}</h2>
      </div>
      <div :class="`header-info ${isReady ? '' : 'd-none'}`" v-else>
        <h2 class="pt-0 header-info__text">GRAZIE DA SORGENIA</h2>
      </div>
    </template>
    <clients-block :type="'Gas'" v-if="clientsStep" @nextStep="toInfo" />
    <info-block
      :isReady="isReady"
      :type="'gas'"
      :data="upsellingInfo"
      v-if="infoStep && upsellingInfo"
      :requestingClient="requestingClient"
      @nextStep="toOffer"
    />
    <offer-step
      :supplyType="'gas'"
      :codeClient="clientDetail ? clientDetail.clientCode : ''"
      :requestingClient="requestingClient"
      :newClient="newClient"
      v-if="offerStep"
      @nextStep="toSuccess"
    />
    <success-page
      :phone="phone"
      :requestingClient="requestingClient"
      v-if="successStep"
    />
    <TcVars env_template="mysorgenia_conferma_gas" v-if="successStep" />
  </page>
</template>

<script>
import Template from './Template'
import Clients from './Clients'
import Info from './Info'
import Offer from './Offer'
import { mapActions, mapGetters } from 'vuex'
import SuccessPage from './SuccessPage'
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'GasOffering',
  props: {
    fromHome: {
      type: Object,
      default: null
    },
    align: {
      type: String,
      default: 'center'
    },
    footer: { type: Boolean, default: true }
  },
  components: {
    page: Template,
    clientsBlock: Clients,
    infoBlock: Info,
    offerStep: Offer,
    successPage: SuccessPage,
    TcVars
  },
  beforeMount() {
    if (this.fromHome) {
      this.clientsStep = false
      this.toInfo(this.fromHome)
    }
  },
  data: () => ({
    activeClient: undefined,
    clientDetail: {},
    clientsStep: true,
    infoStep: false,
    offerStep: false,
    newClient: false,
    newClientType: null,
    successStep: false,
    phone: null,
    isReady: false
  }),
  computed: {
    ...mapGetters('offers', ['upsellingInfo']),
    requestingClient() {
      return {
        clientCode: this.newClient
          ? 'new-client'
          : this.clientDetail?.clientCode || '',
        clientType: this.newClient
          ? this.newClientType
          : this.clientDetail?.clientType || ''
      }
    }
  },
  watch: {
    upsellingInfo: function (val, prec) {
      this.isReady = true
    }
  },
  methods: {
    toInfo(type) {
      if (type.client) {
        this.getDetailForClient({ clientOwnerCode: type.client }).then(
          (resp) => {
            this.clientDetail = resp
            this.getUpselling({
              current_client_type: resp.clientType,
              new_client_type: resp.clientType,
              utility: 'gas'
            })
          }
        )
      } else {
        this.newClientType = type.newClient

        this.getUpselling({
          current_client_type: 'all',
          new_client_type: type.newClient,
          utility: 'gas'
        })
        this.newClient = true
      }
      this.clientsStep = false
      this.infoStep = true
    },
    toOffer() {
      this.infoStep = false
      this.offerStep = true
    },
    toSuccess(value) {
      this.phone = value
      this.offerStep = false
      this.successStep = true
    },
    ...mapActions('offers', ['getUpselling']),
    ...mapActions('account', ['getDetailForClient'])
  }
}
</script>

<style lang="scss" scoped>
.gas {
  .header-info {
    position: relative;
    max-width: 550px;
    color: white;
    &__img {
      position: absolute;
    }
    &__text {
      padding-top: 100px;
      max-width: 400px;
      font-size: 2.4rem;
      font-weight: bold;
    }
    &__name,
    &__desc {
      font-size: 2.4rem;
      font-weight: bold;
    }
    &__price-currency {
      font-size: 5rem;
      font-weight: bold;
    }
    &__price-header,
    &__price-period {
      font-size: 2rem;
      font-weight: 500;
      margin: 0;
    }
  }
}
</style>
