var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.client)?_c('TabLayout',[_c('div',{staticClass:"know mt-12 w-100"},[(!_vm.isMobile)?_c('v-row',{attrs:{"no-gutters":""}},[_c('h2',{staticClass:"mb-12 mt-4 w-100"},[_c('strong',[_vm._v(_vm._s(_vm.currentSlide.title))])]),_c('h5',{staticClass:"mb-12",domProps:{"innerHTML":_vm._s(_vm.currentSlide.tab_subtitle)}})]):_vm._e(),(_vm.isMobile)?_c('v-row',{attrs:{"no-gutters":""}},[_c('h2',{staticClass:"w-100",staticStyle:{"line-height":"1"}},[_c('strong',[_vm._v(_vm._s(_vm.currentSlide.carousel_title))])]),_c('h3',{staticClass:"mb-12 mt-4 w-100"},[_c('strong',[_vm._v(_vm._s(_vm.currentSlide.title))])])]):_vm._e(),_vm._l((_vm.cards),function(r,i){return _c('div',{key:i,staticClass:"tutorial-row mb-12"},_vm._l((r),function(card,k){return _c('TutorialCard',{key:k,attrs:{"card":card}})}),1)}),_c('v-row',[(_vm.currentSlide.show_feedback == 1)?_c('v-col',{attrs:{"md":"6","offset":_vm.isSmallDevice ? 0 : 3}},[_c('div',[_c('InstrumentItem',{attrs:{"title":"Feedback","icon":"feedback.svg","text":"La tua opinione è un prezioso contributo. Dedicaci 3 minuti  rispondendo a poche domande!","link":"Dicci la tua","routeName":_vm.isMobile ? 'publicFeedback' : 'feedback'},on:{"click":function($event){return _vm.trackTapEvent({
                  name: 'wkTutorial_feeback_tap',
                  params: {
                    client_code: _vm.client.clientOwnerCode,
                    client_type: _vm.client.clientOwnerType,
                    wk_type: _vm.client.type
                  },
                  toNative: _vm.isMobile
                })}}})],1)]):_vm._e()],1),_c('v-row',{staticClass:"reverseBtn",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"v-btn--shadowed mb-8 mx-auto",attrs:{"x-large":"","color":"white"},on:{"click":function($event){_vm.$router.push(_vm.back()),
                _vm.trackTapEvent({
                  name: 'wkTutorial_continue_tap',
                  params: {
                    section_title: _vm.prevSlideTitle,
                    client_code: _vm.client.clientOwnerCode,
                    client_type: _vm.client.clientOwnerType,
                    wk_type: _vm.client.type
                  },
                  toNative: _vm.isMobile
                })}}},[_vm._v(_vm._s(_vm.prevSlideTitle))])],1),_c('v-col',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNextSlide),expression:"hasNextSlide"}],staticClass:"v-btn--shadowed mb-8 mx-auto button--blue",staticStyle:{"color":"white"},attrs:{"x-large":""},on:{"click":function($event){_vm.$router.push(_vm.next()),
                _vm.trackTapEvent({
                  name: 'wkTutorial_continue_tap',
                  params: {
                    section_title: _vm.nextSlideTitle,
                    client_code: _vm.client.clientOwnerCode,
                    client_type: _vm.client.clientOwnerType,
                    wk_type: _vm.client.type
                  },
                  toNative: _vm.isMobile
                })}}},[_vm._v(_vm._s(_vm.nextSlideTitle))])],1)],1)],2)]):_vm._e(),(_vm.currentSlide && _vm.currentSlide.show_feedback_overlay == 1 && _vm.overlay)?_c('TutorialOverlay',{attrs:{"overlay":_vm.overlay,"isMobile":_vm.isMobile},on:{"update:overlay":function($event){_vm.overlay=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }