<template>
  <v-dialog v-model="dialogState" max-width="650">
    <v-row>
      <v-col class="supply-dialog">
        <img
          @click="dialogState = false"
          src="../../../assets/ic_chevron_top_black.png"
        />
        <h2 v-if="tab === 'bill'">
          Scegli lo stato delle bollette che vuoi visualizzare
        </h2>
        <h2 v-else>
          Scegli lo stato dei piani di rientro che vuoi visualizzare
        </h2>
        <div v-for="(item, i) in filterSelState" :key="i">
          <h5
            v-if="item.visible"
            @click="setCheckState(item)"
            :class="`checkbox d-flex align-center ${
              item.checked ? 'selected' : ''
            } ${item.disabled ? 'disabled' : ''}`"
          >
            <span>{{ item.text }}</span>
          </h5>
        </div>
        <v-row>
          <v-col cols="6">
            <v-btn
              @click="dialogState = false"
              color="white"
              x-large
              width="260"
              >Annulla</v-btn
            >
          </v-col>
          <v-col cols="6">
            <v-btn
              @click="$emit('refreshTotal', 'state')"
              class="v-btn--shadowed"
              x-large
              color="primary"
              width="260"
              :outlined="filterSelState.length > 0 ? false : true"
              :disabled="filterSelState.length > 0 ? false : true"
              >Conferma</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'dialogFilterState',
  props: [
    'showDialogState',
    'filterSelStateProp',
    'bills',
    'tab',
    'pickerStart',
    'pickerEnd'
  ],
  watch: {
    dialogState(val) {
      if (val) this.openFilterPopup()
    }
  },
  computed: {
    ...mapGetters('payment', ['getInfoPiani']),
    dialogState: {
      get() {
        return this.showDialogState
      },
      set(val) {
        this.$emit('update:showDialogState', val)
      }
    },
    filterSelState: {
      get() {
        return this.filterSelStateProp
      },
      set(val) {
        this.$emit('update:filterSelStateProp', val)
      }
    }
  },
  methods: {
    openFilterPopup() {
      let startDate = new Date(this.pickerStart.date).setHours(0, 0, 0, 0)
      let endDate =
        new Date(this.pickerEnd.date) || new Date().setHours(0, 0, 0, 0)
      this.filterSelState.forEach((item, i) => {
        this.filterSelState[i].disabled = true
      })
      if (this.tab === 'bill') {
        this.bills.filter((bill) => {
          let dataEmiss = new Date(
            bill.dataEmissione.split('/').reverse().join('-')
          ).setHours(0, 0, 0, 0)
          if (dataEmiss >= startDate && dataEmiss <= endDate) {
            this.filterSelState.forEach((state) => {
              if (state.code.includes(bill.computedStatus)) {
                if (bill.computedStatus === '3') {
                  if (state.id === 'parzPagate') {
                    if (bill.statoFattura === 'Parziale') {
                      state.disabled = false
                    }
                  } else {
                    if (bill.statoFattura !== 'Parziale') {
                      state.disabled = false
                    }
                  }
                } else {
                  state.disabled = false
                }
              }
            })
          }
        })
      } else {
        this.getInfoPiani.item.filter((pdr) => {
          let dataEmiss = new Date(
            pdr.planStartDate.split('/').reverse().join('-')
          ).setHours(0, 0, 0, 0)
          if (dataEmiss >= startDate && dataEmiss <= endDate) {
            this.filterSelState.forEach((state) => {
              if (state.code.includes(pdr.computedStatus)) {
                state.disabled = false
              }
            })
          }
        })
      }
    },
    setCheckState(item) {
      if (!item.disabled) item.checked = !item.checked
    }
  }
}
</script>
<style lang="scss" scoped>
@import './dialogFilter.scss';
</style>
