<template>
  <div
    class="wrapper--btm"
    :class="[isColumn ? 'feedback__wrapper--btm_mobile' : '']"
  >
    <v-btn
      v-if="!isMobile"
      color="white"
      x-large
      rounded
      min-width="33%"
      class="v-btn--shadowed mt-6"
      @click="onPrevClick()"
    >
      Indietro
    </v-btn>
    <v-btn
      color="primary"
      x-large
      rounded
      min-width="33%"
      class="v-btn--shadowed mt-6"
      :disabled="!isNextEnabled"
      @click="onNextClick()"
    >
      {{ nextButtonTitle }}
    </v-btn>
    <v-btn
      v-if="isMobile"
      color="white"
      x-large
      rounded
      min-width="33%"
      class="v-btn--shadowed mt-6"
      @click="onPrevClick()"
    >
      Indietro
    </v-btn>
  </div>
</template>

<script>
import { checkMobile } from '@/js/utils.js'

export default {
  name: 'feedbackProgressButtons',
  props: {
    label: { type: String, default: null },
    color: { type: String, default: null },
    isLast: { type: Boolean, default: false },
    isNextEnabled: { type: Boolean, default: false },
    onNextClick: { type: Function },
    onPrevClick: { type: Function },
    isColumn: { type: Boolean, default: false }
  },
  data() {
    return {
      isMobile: checkMobile()
    }
  },
  methods: {},
  computed: {
    nextButtonTitle() {
      return this.isLast ? 'Invia sondaggio' : 'Continua'
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback {
  &__wrapper--btm {
    display: flex;
    justify-content: space-between;

    &_mobile {
      flex-direction: column;
    }
  }
}
</style>
