<template>
  <v-dialog v-model="dialog" width="558" persistent>
    <div class="kaleyra">
      <div class="d-flex align-center justify-space-between mb-10">
        <div class="HeadingsSubtitleL">Videochiamata in arrivo</div>
        <img
          src="../../assets/icons/close-blu.svg"
          class="close"
          @click="rejectCall"
        />
      </div>
      <div class="BodyL mb-6">
        Ho letto il testo dell’informativa fornita dal Titolare del trattamento
        ai sensi degli artt. 13 e 14 del Regolamento (UE) 2016/679, disponibile
        sul sito Sorgenia al seguente indirizzo
        <a :href="infoPrivacy" target="_blank">{{ infoPrivacy }}</a
        >, e accedendo al collegamento accetto di partecipare alla
        videochiamata/videoconferenza per accedere ad alcuni servizi e/o
        ricevere l’assistenza da parte di Sorgenia.<br />Durante il collegamento
        non effettuerò videoregistrazioni, registrazioni audio o acquisizione di
        immagini, relative al contenuto della videochiamata/videoconferenza.
      </div>
      <div
        @click="privacy = !privacy"
        :class="`checkbox ${privacy ? 'selected' : ''}`"
      >
        <div class="black--text ml-3 mb-10">
          Dichiaro di aver letto l’informativa sopra indicata e di averne letto,
          compreso ed accettato il contenuto e di voler procedere all’avvio
          della videochiamata/videoconferenza.
        </div>
      </div>
      <div class="d-flex flex-column align-center">
        <v-btn
          :disabled="!privacy"
          @click="acceptCall"
          width="251"
          height="54"
          class="primary mb-4"
          >Conferma</v-btn
        >
        <v-btn @click="rejectCall" width="251" height="54" color="white"
          >Rifiuta</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { eventBus } from '../../main'
import { ENV_CONFIGS } from '@/js/configs.js'
import { mapActions } from 'vuex'
export default {
  name: 'kaleyraDialog',
  data() {
    return {
      dialog: false,
      privacy: false,
      call: null,
      kaleyra: null,
      infoPrivacy: ENV_CONFIGS.KALEYRA_INFO_PRIVACY
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.privacy = false
      }
    }
  },
  created() {
    eventBus.$on('open:kaleyra', (call, kaleyra) => {
      this.call = call
      this.kaleyra = kaleyra
      this.dialog = true
      this.trackTapEvent({
        name: 'videocall_call_received'
      })
    })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    acceptCall() {
      this.dialog = false
      this.call.answer()
      this.kaleyra.showWidget()
      this.trackTapEvent({
        name: 'videocall_privacy_accepted'
      })
      this.trackTapEvent({
        name: 'videocall_call_accepted'
      })
    },
    rejectCall() {
      this.dialog = false
      this.call.end()
      this.kaleyra.hideWidget()
      this.trackTapEvent({
        name: 'videocall_privacy_rejected'
      })
      this.trackTapEvent({
        name: 'videocall_call_rejected'
      })
    }
  },
  beforeUnmount() {
    this.privacy = false
  }
}
</script>
<style lang="scss" scoped>
.kaleyra {
  background: white;
  padding: 40px;
  .checkbox {
    display: flex;
    color: black;
    cursor: pointer;
    font-weight: normal;
    &.selected {
      &:before {
        content: url(~@/assets/icons/checkbox_active.svg);
      }
    }
    &:before {
      content: url(~@/assets/icons/checkbox.svg);
    }
  }
  a {
    font-weight: 500;
    letter-spacing: 0.8px;
    text-decoration: underline;
    &:hover,
    &:visited {
      color: $secondari-blue-100;
    }
  }
}
</style>
