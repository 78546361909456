<template>
  <div>
    <v-row>
      <v-col cols="4">
        <div class="sideMenuSupport" v-if="parentCategories.length > 0">
          <div
            class="BodyXL-Strong item"
            v-for="category in parentCategories"
            :key="category.id"
            :class="{ activeItem: category.id === activeCategoryId }"
            @click="setActiveCategory(category.id)"
          >
            <span
              class="bordered"
              :class="{ activeBorder: category.id === activeCategoryId }"
              >{{ category.name }}</span
            >
          </div>
        </div>
        <div v-else class="ml-10">
          <div class="BodyXL">Faq non ancora presenti</div>
          <div class="BodyXL">Stiamo lavorando per voi!</div>
        </div>

        <div
          class="sendRequest"
          v-if="
            categoryName !== 'Fibra' && categoryName !== 'Comunità energetiche'
          "
        >
          <div class="HeadingsTitleS">Hai bisogno di supporto?</div>
          <div class="BodyM mt-2">
            Contatta direttamente il Servizio Clienti.
          </div>
          <div class="mt-4">
            <v-btn
              color="primary"
              x-large
              width="327"
              class="v-btn--shadowed"
              @click="sendRequest"
              >Invia richiesta</v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col cols="7" class="ml-4">
        <v-expansion-panels :accordion="true">
          <v-expansion-panel
            class="panel"
            v-for="(item, i) in activeCategoryItems"
            :key="i"
            :class="{ 'panel-top-border': i === 0 }"
          >
            <v-expansion-panel-header
              >{{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="item.body"></div>
              <div v-if="item.media && item.media.length > 0">
                <div class="titleVideo">
                  <h3>Guarda anche:</h3>
                </div>
                <VideoPlayerSupport :media="item.media" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { categoryIds } from '../../js/constants'
import VideoPlayerSupport from './VideoPlayerSupport'
import { mapActions } from 'vuex'

export default {
  name: 'FAQList',
  components: {
    // ExtensibleButtonGroup: ExtensibleButtonGroup,
    // ExtensibleButton: ExtensibleButton,
    VideoPlayerSupport: VideoPlayerSupport
  },
  props: {
    subCategories: { type: Array, default: () => [] },
    media: { type: Array, default: () => [] },
    categoryName: { type: String },
    serviceName: { type: String }
  },
  data() {
    return {
      activeTab: '',
      parentCategories: this.subCategories.filter(
        (cat) => cat.items.length > 0
      ),
      activeCategoryId: null
    }
  },
  mounted() {
    // console.log('--', this.subCategories)
  },
  created() {
    const firstCategory = this.parentCategories[0]
    if (firstCategory) {
      this.activeCategoryId = firstCategory.id
    }
  },
  computed: {
    activeCategoryItems() {
      const activeCategory = this.parentCategories.find(
        (category) => category.id === this.activeCategoryId
      )
      return activeCategory ? activeCategory.items : []
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    getCategory() {
      return this.subCategories.filter((cat) => cat.items.length > 0)
    },
    setActiveCategory(categoryId) {
      this.activeCategoryId = categoryId
    },
    sendRequest() {
      this.$router.push({
        name: 'SendRequest',
        params: {
          serviceName: this.serviceName,
          categoryName: this.categoryName,
          subCategories: this.subCategories,
          media: this.media
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sideMenuSupport {
  width: 375px;
  background-color: $neutri-neutral-10;
  padding-bottom: 24px;
  border-radius: 8px;
  gap: 16px;
}
.item {
  color: #b7bfd2;
  padding: 25px;
  cursor: pointer;
}
.bordered {
  border-bottom: 3px solid #b7bfd2;
  padding-bottom: 15px;
}
.activeItem {
  color: #12256a;
}
.activeBorder {
  border-bottom: 3px solid #12256a;
}
.panel {
  border-bottom: 1px solid $neutri-neutral-20;
  padding-bottom: 15px;
  padding-top: 15px;
}
.panel-top-border {
  border-top: 1px solid $neutri-neutral-20; /* Aggiungi il bordo superiore solo al primo elemento */
}
.sendRequest {
  border-radius: 16px;
  border: 1px solid $neutri-neutral-30;
  margin-top: 32px;
  width: 375px;
  padding: 24px;
}
</style>
