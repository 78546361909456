import {
  CHECKBOX,
  INTERVAL,
  RADIO,
  TEXT,
  DROPDOWN,
  RATING,
  RANKING
} from '../../js/constants'
import { axiosACN } from '@/js/axiosInstances'
import { formatDate, checkMobile } from '../../js/utils'
import router from '@/router/router'

const prepairResults = (survey, client) => {
  const currentDate = new Date()
  const result = {
    codiceCliente: client.clientOwnerCode,
    clientType: client.clientOwnerType,
    surveyType: survey.type,
    idSurvey: survey.id,
    channel: 'WEB', // 'WEB / APP',
    dataPubblicazione: survey.publication_date
      ? survey.publication_date.split('-').reverse().join('/')
      : '',
    dataCompilazione: formatDate(currentDate).split('/').reverse().join('/'),
    domande: []
  }
  result.domande = survey.questions.map((v) => {
    const q = {
      idDomanda: v.id,
      testoDomanda: v.title,
      tipologiaDomanda: v.type,
      commento: '',
      scala: '',
      risposta: []
    }

    if (v.type === RADIO || v.type === CHECKBOX || v.type === DROPDOWN) {
      v.answers.forEach((v2) => {
        if (v2.selected !== null && v2.selected) {
          q.risposta.push({
            id: v2.id,
            ranking: '',
            testoRisposta: v2.label
          })

          if (v.comment_value.length > 0) {
            q.commento = v.comment_value
          }
        }
      })
    } else if (v.type === INTERVAL) {
      const scala = v.answers[0].scale_values.split(',')
      q.scala = scala[scala.length - 1]
      q.risposta = v.answers.map((v2) => {
        return {
          id: v2.id,
          ranking: v2.slider_value,
          testoRisposta: v2.label
        }
      })
      if (v.comment_value.length > 0) {
        q.commento = v.comment_value
      }
    } else if (v.type === RATING) {
      const scala = v.answers[0].scale_values.split(',')
      q.scala = scala[scala.length - 1]
      q.risposta = v.answers.map((v2) => {
        return {
          id: v2.id,
          ranking: v2.rating_value,
          testoRisposta: v2.label
        }
      })
      if (v.comment_value.length > 0) {
        q.commento = v.comment_value
      }
    } else if (v.type === RANKING) {
      v.answers.forEach((v2) => {
        q.risposta.push({
          id: v2.id,
          ranking: '',
          testoRisposta: v2.label
        })

        if (v.comment_value.length > 0) {
          q.commento = v.comment_value
        }
      })
      if (v.comment_value.length > 0) {
        q.commento = v.comment_value
      }
    } else if (v.type === TEXT) {
      if (v.comment_value.length > 0) {
        q.commento = v.comment_value
      }
    }
    return q
  })
  return result
}

const getWKClient = (commit, dispatch, rootGetters) => {
  return new Promise((resolve, reject) => {
    if (checkMobile()) {
      if (router.currentRoute.query.wkType !== 'none') {
        commit('setClient', {
          clientOwnerCode: router.currentRoute.query.clientCode,
          clientOwnerType: router.currentRoute.query.clientType,
          token: router.currentRoute.query.token,
          type: router.currentRoute.query.wkType || 'none'
        })
      }

      dispatch(
        'loyalty/getMemberStatus',
        { ext_uuid: router.currentRoute.query.clientCode },
        { root: true }
      ).then(() => resolve())
    } else {
      dispatch('supply/getSupplies', {}, { root: true }).then((supplyList) => {
        if (supplyList) {
          let type = 'none'

          let welcomeKitClient = rootGetters['account/mainClient'] || null

          if (welcomeKitClient !== null) {
            commit('setClient', {
              clientOwnerCode: welcomeKitClient.clientOwnerCode,
              clientOwnerType: welcomeKitClient.clientOwnerType,
              token: rootGetters['session/token'],
              type: type
            })
          }

          resolve()
        }
      })
    }
  })
}

const retrieveUndoneSurveys = async (state, commit, dispatch, rootGetters) => {
  if (state.client === null) {
    await getWKClient(commit, dispatch, rootGetters)
  }

  return axiosACN
    .get(
      '/sorgeniadataclient/retrieveBusinessSurvey?codiceCliente=' +
        state.client.clientOwnerCode,
      {
        headers: {
          Authorization: 'Bearer ' + state.client.token
        }
      }
    )
    .then((res) => {
      if (res && state.cmsSurveys) {
        const surveyPassed = res.data.survey.map((v) => v.idSurvey)
        const filtered = state.cmsSurveys.filter((v) => {
          return surveyPassed.indexOf(v.id) === -1
        })
        commit('setUndoneSurveys', filtered)

        return filtered
      }
    })
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    cmsSurveys: null,
    undoneSurveys: null,
    client: null
  },
  getters: {
    cmsSurveys: (state) => {
      return state.cmsSurveys
    },
    client: (state) => {
      return state.client
    }
  },
  mutations: {
    setCmsSurvey(state, surveys) {
      state.cmsSurveys = surveys
    },
    setClient(state, client) {
      state.client = client
    },
    setUndoneSurveys(state, surveys) {
      state.undoneSurveys = surveys
    },
    resetState(state) {
      state.cmsSurveys = null
      state.undoneSurveys = null
      state.client = null
    }
  },
  actions: {
    loadSurvey(context) {
      return new Promise((resolve, reject) => {
        if (context.state.cmsSurveys) {
          resolve(context.state.cmsSurveys)
        } else {
          getWKClient(
            context.commit,
            context.dispatch,
            context.rootGetters
          ).then(() => {
            context
              .dispatch(
                'welcomekit/getSurveyByUserProfile',
                { client: context.state.client },
                { root: true }
              )
              .then((res) => {
                let survey = res.survey ? [res.survey] : []
                context.commit('setCmsSurvey', survey)
                resolve(survey)
              })
          })
        }
      })
    },

    saveResults({ state, commit, rootGetters }) {
      // TODO find survey by id
      const r = prepairResults(state.cmsSurveys[0], state.client)
      axiosACN.post('/sorgeniadataclient/saveBusinessSurvey', r, {
        headers: {
          Authorization: 'Bearer ' + state.client.token
        }
      })

      // reset undonesurveys so that they are loaded again from server and updated
      commit('setUndoneSurveys', null)
    },

    async loadUndoneSurvey({ state, commit, dispatch, rootGetters }) {
      if (state.undoneSurveys) {
        return Promise.resolve(state.undoneSurveys)
      } else {
        return retrieveUndoneSurveys(state, commit, dispatch, rootGetters)
      }
    }
  }
}
