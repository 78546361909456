<template>
  <div id="chart">
    <apexchart
      :type="type"
      :width="width"
      :height="height"
      :options="type === 'line' ? chartOptionsLine : chartOptionsArea"
      :series="generateSeries"
      :class="`apex--${type}`"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

const monthNames = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre'
]

export default {
  name: 'chartComponent',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    series: { type: Array, required: true },
    monthOnMonth: { type: Boolean, default: false },
    height: { type: [String, Number], default: 350 },
    width: { type: [String, Number], default: '100%' },
    commodity: { type: String, default: 'Ele' },
    maxMonths: { type: Number, default: 12 },
    lastMonthToShow: { type: [Number, String] },
    type: { type: String, default: 'line' }
  },
  data: function () {
    return {
      // series: this.$store.state.supply.supplyDetail[this.$route.params.supplyCode].consumption,
      chartOptionsLine: {
        chart: {
          id: 'testChartId',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true,
            type: 'x'
          }
        },
        fill: {
          type: 'solid'
        },
        stroke: {
          curve: 'straight',
          width: 12
        },
        colors: ['#12256A', '#97D707'],
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        legend: {
          show: false
        },
        yaxis: {
          show: false
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) =>
              new Date(value).toLocaleString('it-IT', { month: 'short' }),
            offsetY: 5,
            style: {
              fontSize: '16px',
              fontFamily: 'Roboto',
              cssClass: 'label-custom'
            }
          },
          tickPlacement: 'on',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true
          },
          crosshairs: {
            position: 'front'
          },
          tooltip: {
            enabled: true,
            offsetY: 13,
            style: {
              fontSize: 20
            }
          }
        }
      },
      chartOptionsArea: {
        dataLabels: {
          enabled: false
        },
        chart: {
          id: 'testChartId',
          type: 'area',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true,
            type: 'x'
          }
        },
        fill: {
          type: 'solid',
          opacity: 0.5
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        colors: ['#12256A'],
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          },
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        legend: {
          show: false
        },
        yaxis: {
          show: false
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: function (value, timestamp) {
              return new Date(value).toLocaleString('it-IT', { month: 'short' })
            },
            style: {
              fontSize: '16px',
              fontFamily: 'Roboto',
              cssClass: 'label-custom'
            }
          },
          tickPlacement: 'on',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true
          },
          crosshairs: {
            position: 'front'
          },
          tooltip: {
            enabled: false
          },
          position: 'top',
          offsetY: -6
        }
      },
      unit: ''
    }
  },
  computed: {
    generateSeries() {
      let generateSerie = []
      let series = []

      const lastM = this.lastMonthToShow && parseInt(this.lastMonthToShow)
      if (lastM) {
        for (let point in this.series) {
          let pointMonth =
            this.series[point].dataCompetenzaStart &&
            parseInt(this.series[point].dataCompetenzaStart.split('-')[1])
          if (pointMonth === lastM) {
            series = this.series.slice(point)
            break
          }
        }
      } else {
        series = this.series.slice()
      }

      if (series) {
        series = series.reverse()
        if (!this.monthOnMonth) {
          for (var i = this.maxMonths - 1; i >= 0; i--) {
            if (series[i]) {
              let dateStart = new Date(series[i].dataCompetenzaStart)
              dateStart =
                dateStart.getFullYear() +
                '/' +
                (dateStart.getMonth() + 1 < 10
                  ? '0' + (dateStart.getMonth() + 1)
                  : dateStart.getMonth() + 1) +
                '/' +
                (dateStart.getDate() < 10
                  ? '0' + dateStart.getDate()
                  : dateStart.getDate())

              if (generateSerie.length === 0) {
                generateSerie = [
                  {
                    name: 'year',
                    data: [{ x: dateStart, y: series[i].consumo }]
                  }
                ]
              } else {
                generateSerie[0].data.push({
                  x: dateStart,
                  y: series[i].consumo
                })
              }

              if (dateStart.split('/')[1] === '12') {
                this.setAnnotation(dateStart)
              }
              if (dateStart.split('/')[1] === '01') {
                this.setAnnotation(dateStart)
              }
            }
          }
        } else {
          for (var b = 0; b <= 23; b++) {
            if (series[b]) {
              const dateStart = series[b].dataCompetenzaStart
              const year = new Date(dateStart).getFullYear()
              const month = new Date(dateStart).toLocaleString('default', {
                month: '2-digit'
              })
              if (generateSerie.length === 0) {
                generateSerie = [
                  { name: 'year', data: [] },
                  { name: 'yearOld', data: [] }
                ]
              }
              if (b <= 11) {
                generateSerie[0].data.push({
                  y: parseInt(series[b].consumo),
                  x: year + '/' + month + '/' + '01'
                })
              } else {
                generateSerie[1].data.push({
                  y: parseInt(series[b].consumo),
                  x: year + 1 + '/' + month + '/' + '01'
                })
              }
            } else {
              if (generateSerie[0].data[b - 12]?.x) {
                generateSerie[1].data.push({
                  y: null,
                  x: generateSerie[0].data[b - 12].x
                })
              }
            }
          }
          generateSerie[0].data.reverse()
          generateSerie[1].data.reverse()
        }
      }
      return generateSerie
    }
  },
  beforeMount() {
    this.unit = this.commodity.toLowerCase() === 'ele' ? 'kWh' : 'Smc'
    this.updateChart()
  },
  methods: {
    updateChart() {
      this.type === 'line' ? this.updateLineChart() : this.updateAreaChart()
    },
    updateLineChart() {
      this.chartOptionsLine = {
        ...this.chartOptionsLine,
        ...{
          markers: {
            size: 0,
            shape: 'circle',
            colors: '#000',
            strokeColors: '#fff',
            strokeWidth: 5,
            strokeOpacity: 1,
            showNullDataPoints: false,
            hover: {
              size: 16
            }
          },
          tooltip: {
            shared: true,
            style: {
              fontSize: '14px',
              fontFamily: 'Roboto'
            },
            x: {
              show: false
            },
            y: {
              formatter: (
                value,
                { series, seriesIndex, dataPointIndex, w }
              ) => {
                if (seriesIndex === 1) {
                  if (
                    series[1][dataPointIndex] === undefined ||
                    series[1][dataPointIndex] === null
                  ) {
                    return '<div class="chart-item__hide"></div>'
                  } else {
                    return (
                      `<div class="chart-item">${this.formatOlddate(
                        w.config.series[seriesIndex].data[dataPointIndex].x
                      )}` +
                      `<div class="chart-item__circle" style="background-color: ${w.globals.colors[seriesIndex]}"></div>` +
                      `<div class="chart-item__val">${
                        this.formaValue(value)[0]
                      }` +
                      `<span class="chart-item__type">,${
                        this.formaValue(value)[1]
                      }</span>` +
                      `<span class="chart-item__type"> ${this.unit}</span>
                      </div>
                    </div>`
                    )
                  }
                } else {
                  return (
                    `<div class="chart-item">${this.formatdate(
                      w.config.series[seriesIndex].data[dataPointIndex].x
                    )}` +
                    `<div class="chart-item__circle" style="background-color: ${w.globals.colors[seriesIndex]}"></div>` +
                    `<div class="chart-item__val">${
                      this.formaValue(
                        w.config.series[seriesIndex].data[dataPointIndex].y
                      )[0]
                    }` +
                    `<span class="chart-item__type">,${
                      this.formaValue(
                        w.config.series[seriesIndex].data[dataPointIndex].y
                      )[1]
                    }</span>` +
                    `<span class="chart-item__type"> ${this.unit}</span>
                    </div>
                  </div>`
                  )
                }
              },
              title: {
                formatter: function () {
                  return ''
                }
              }
            },
            items: {
              display: 'inline-block'
            }
          }
        }
      }
    },
    updateAreaChart() {
      this.chartOptionsArea = {
        ...this.chartOptionsArea,
        ...{
          markers: {
            size: 9,
            shape: 'circle',
            colors: '#12256A',
            strokeColors: '#fff',
            strokeWidth: 3,
            strokeOpacity: 1,
            showNullDataPoints: false,
            hover: {
              size: 15
            }
          },
          tooltip: {
            style: {
              fontSize: '14px',
              fontFamily: 'Roboto'
            },
            x: {
              show: false
            },
            y: {
              formatter: (value, { w, dataPointIndex, seriesIndex }) => {
                return (
                  // `<div class="chart-item">${this.formatdate(w.config.series[seriesIndex].data[dataPointIndex].x)}` +
                  `<div class="chart-item__val">
                  <span class="chart-item__val chart-item__val--blue">${Math.floor(
                    value
                  )}<small>,${
                    (value % Math.floor(value)).toFixed(1) * 10 || 0
                  }</small></span>
                  <span class="chart-item__type chart-item__type--blue d-block">${
                    this.unit
                  }</span>
                  </div>
                `
                )
              }
            },
            items: {
              display: 'inline-block'
            }
          },
          annotations: {
            xaxis: []
          }
        }
      }
    },
    formatdate(value) {
      let date = new Date(value)
      return monthNames[date.getMonth()] + ' ' + date.getFullYear()
    },
    formatOlddate(val) {
      let date = new Date(val)
      return monthNames[date.getMonth()] + ' ' + (date.getFullYear() - 1)
    },
    formaValue(val) {
      let value = parseFloat(val).toFixed(1)
      return value.split('.')
    },
    setAnnotation(val) {
      if (!this.chartOptionsArea.annotations) {
        this.chartOptionsArea.annotations = {
          position: 'back',
          xaxis: [
            {
              x: val,
              strokeDashArray: 3,
              fillColor: '#ffffff',
              label: {
                position: 'bottom',
                borderWidth: 0,
                text: val.split('/')[0],
                orientation: 'horizontal',
                offsetY: 30,
                style: {
                  fontSize: '12px',
                  color: '#4A5155'
                }
              }
            }
          ]
        }
      } else if (this.chartOptionsArea.annotations.xaxis.length === 1) {
        this.chartOptionsArea.annotations.xaxis.push({
          x: val,
          strokeDashArray: 3,
          fillColor: '#ffffff',
          label: {
            position: 'bottom',
            borderWidth: 0,
            text: val.split('/')[0],
            orientation: 'horizontal',
            offsetY: 30,
            style: {
              fontSize: '12px',
              color: '#4A5155'
            }
          }
        })
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" src="./_chart.scss" />
