<template>
  <div class="useGas">
    <div :class="`useGas__btn ${cook ? 'active' : ''}`" @click="cook = !cook">
      <h5>Cottura</h5>
    </div>
    <div
      :class="`useGas__btn ${hotWater ? 'active' : ''}`"
      @click="hotWater = !hotWater"
    >
      <h5>Acqua calda</h5>
    </div>
    <div
      :class="`useGas__btn ${heating ? 'active' : ''}`"
      @click="heating = !heating"
    >
      <h5>Riscaldamento</h5>
    </div>
    <div class="useGas__box">
      <div class="title">Perchè te lo chiediamo</div>
      <h5 class="desc">
        Trovi questa informazione esatta sulla bolletta.<br />
        Indica per cosa utilizzi il gas (es. riscaldamento, cottura cibi, acqua
        calda, ecc.).
      </h5>
    </div>
    <MYSButton
      buttonClass="primaryButton"
      width="260"
      :disabled="!cook && !hotWater && !heating"
      @buttonClick="next()"
      buttonText="Scopri l'offerta"
    />
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
export default {
  name: 'useGas',
  components: { MYSButton },
  data() {
    return {
      cook: false,
      hotWater: false,
      heating: false
    }
  },
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetail']),
    ...mapActions('analytics', ['trackTapEvent']),
    next() {
      this.$emit('nextStep', 'upsellingcom_quote_open')
      this.setSupplyDetail({
        useToCook: this.cook,
        useToHotWater: this.hotWater,
        useToHeating: this.heating
      })
      const address = this.$store.getters['upsellingComm/address']
      if (this.cook)
        this.trackTapEvent({
          name: `upsellingcom_gasUseToCook_tap`,
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: address
              ? address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      if (this.hotWater)
        this.trackTapEvent({
          name: `upsellingcom_gasUseToHotWater_tap`,
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: address
              ? address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      if (this.useToHeating)
        this.trackTapEvent({
          name: `upsellingcom_gasUseToHeating_tap`,
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: address
              ? address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
    }
  },
  created() {
    this.cook = this.$store.getters['upsellingComm/supplyDetail'].gas.useToCook
    this.hotWater =
      this.$store.getters['upsellingComm/supplyDetail'].gas.useToHotWater
    this.heating =
      this.$store.getters['upsellingComm/supplyDetail'].gas.useToHeating
  }
}
</script>
<style lang="scss" scoped>
.useGas {
  max-width: 652px;
  color: black;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 66px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 8px);
    }
    &.active {
      border-color: #36d78a;
      &::after {
        content: url('../../../../assets/ic-check-big.svg');
        position: absolute;
        right: 25px;
        top: calc(50% - 8px);
      }
    }
  }
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
</style>
