<template>
  <div>
    <TabLayout>
      <div class="bill mt-12 w-100">
        <v-row no-gutters>
          <h2 class="mb-6">
            <strong>La bolletta con noi è più semplice</strong>
          </h2>
          <h5 class="mb-12">
            Abbiamo studiato un formato più efficace nella grafica e
            nell'organizzazione dei contenuti: vogliamo che le nostre bollette
            siano comprensibili, che non lascino dubbi.
          </h5>
        </v-row>
        <v-row no-gutters>
          <h3 class="mb-6"><strong>La bolletta in due parti</strong></h3>
        </v-row>
        <v-row no-gutters class="mb-12">
          <v-col
            md="3"
            sm="6"
            cols="12"
            :class="isMobile ? 'pb-8' : 'pr-6'"
            align="center"
          >
            <v-img class="shadow" contain src="@assets/welcome/bolletta.png" />
          </v-col>
          <v-col md="3" sm="5" offset-sm="1" cols="12" v-if="isTablet">
            <v-img contain src="@assets/welcome/banner-bolletta.png" />
            <div class="info-bill mt-4">
              Questi dati si riferiscono a una fornitura del IV trimestre 2019
              per un'azienda con 10kW di potenza impegnata e 9.000 kWh di
              consumo annuo. Fonte: Acquirente Unico Spa.
            </div>
          </v-col>
          <v-col
            md="6"
            sm="12"
            cols="12"
            :class="isMobile ? 'pb-8' : isTablet ? 'mt-8' : 'pr-4'"
          >
            <v-row :class="isTablet ? 'mb-3' : 'mb-8'">
              <v-col cols="2" md="1" sm="1">
                <span class="circle"><h4>1</h4></span>
              </v-col>
              <v-col>
                <strong>Una bolletta “sintetica”:</strong> un riepilogo di due
                pagine che permette di capire velocemente l'importo da pagare,
                la scadenza o l'indicazione della sede rifornita.
              </v-col>
            </v-row>
            <v-row :class="isTablet ? 'mb-3' : 'mb-8'">
              <v-col cols="2" md="1" sm="1">
                <span class="circle"><h4>2</h4></span>
              </v-col>
              <v-col>
                <strong>Una bolletta “di dettaglio”:</strong> alcune pagine, con
                il dettaglio di tutte le informazioni riportate nel riepilogo e
                i dati specifici dell'utenza.
              </v-col>
            </v-row>
            <v-row>
              <v-col class="">
                <h5>
                  Per rendere ancora più chiare le componenti della bolletta
                  abbiamo creato una serie di
                  <strong class="color--blue">guide navigabili</strong>
                  <strong
                    >con la spiegazione delle principali voci riportate.</strong
                  >
                </h5>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="3" sm="3" cols="12" v-if="!isTablet">
            <v-img contain src="@assets/welcome/banner-bolletta.png" />
            <div class="info-bill mt-4">
              Questi dati si riferiscono a una fornitura del IV trimestre 2019
              per un'azienda con 10kW di potenza impegnata e 9.000 kWh di
              consumo annuo. Fonte: Acquirente Unico Spa.
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-6">
          <h3><strong>Com'è composto il prezzo della bolletta?</strong></h3>
        </v-row>
        <v-row no-gutters class="mb-12">
          <v-col md="3" sm="4" cols="12" :class="isMobile ? '' : 'pr-5'">
            <div class="percent bkg--green">
              <div class="percent--big mb-4 color--blue">
                <strong>35%</strong>
              </div>
              <h4 class="mb-4 color--blue">
                <strong>Importo destinato a Sorgenia</strong>
              </h4>
              <v-row>
                <v-col class="radius">
                  <div v-if="!isMobile" class="percent--small mb-4 color--blue">
                    35%
                  </div>
                  <h4 class="mb-4"><strong>Materia Energia</strong></h4>
                  <h5>
                    È la quota destinata alle varie attività che svolgiamo per
                    fornire l'energia elettrica ai nostri clienti.
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col
            v-if="isMobile"
            md="12"
            cols="12"
            align="center"
            class="mt-6 mb-6"
          >
            <v-img contain width="64" src="@assets/welcome/phPlus.png" />
          </v-col>
          <v-col md="9" sm="9" cols="12" v-if="!isTablet">
            <div class="percent bkg--blue">
              <div class="percent--big mb-4"><strong>65%</strong></div>
              <h4 class="mb-4">
                <strong
                  >Importi fissati dalla normativa di settore e versati da
                  Sorgenia a stato e distributori</strong
                >
              </h4>
              <v-row class="justify-space-around">
                <v-col class="radius" cols="12" md="4" sm="4">
                  <div class="percent--small mb-4 border-small">18%</div>
                  <h4 class="mb-4">
                    <strong>Trasporto e gestione del contatore</strong>
                  </h4>
                  <h5>
                    Sono i costi sostenuti per trasportare e distribuire
                    l’energia elettrica sulla rete, per gestire i contatori e i
                    dati delle letture.
                  </h5>
                </v-col>
                <v-col
                  :class="`radius ${isMobile ? 'mt-5 mb-5' : ''}`"
                  cols="12"
                  md="4"
                  sm="4"
                >
                  <div class="percent--small mb-4 border-large">25%</div>
                  <h4 class="mb-4"><strong>Oneri di sistema</strong></h4>
                  <h5>
                    Sono i costi sostenuti da tutti i clienti del servizio
                    elettrico per vari scopi come per esempio il sostegno alla
                    ricerca.
                  </h5>
                </v-col>
                <v-col class="radius" cols="12" md="4" sm="4">
                  <div class="percent--small mb-4 border-medium">23%</div>
                  <h4 class="mb-4"><strong>Imposte</strong></h4>
                  <h5>
                    Sono l’imposta di consumo, che si applica alla quantità di
                    energia consumata, e l’IVA, che si applica all’importo
                    totale della bolletta.
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col md="9" sm="8" cols="12" v-else>
            <div class="percent bkg--blue">
              <div class="percent--big mb-4"><strong>65%</strong></div>
              <h5 class="mb-4">
                <strong
                  >Importi fissati dalla normativa di settore e versati da
                  Sorgenia a stato e distributori</strong
                >
              </h5>
              <v-row class="justify-space-around flex-column">
                <v-col class="radius tabletView mb-2" sm="12">
                  <v-row no-gutters>
                    <v-col cols="5" class="percent--small mb-4 border-small"
                      >18%</v-col
                    >
                    <v-col cols="7">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <h5 class="mb-4">
                            <strong>Trasporto e gestione del contatore</strong>
                          </h5>
                        </v-col>
                      </v-row>
                    </v-col>
                    <h6>
                      Sono i costi sostenuti per trasportare e distribuire
                      l’energia elettrica sulla rete, per gestire i contatori e
                      i dati delle letture.
                    </h6>
                  </v-row>
                </v-col>
                <v-col class="radius tabletView mb-2" sm="12">
                  <v-row no-gutters>
                    <v-col cols="5" class="percent--small mb-4 border-large"
                      >25%</v-col
                    >
                    <v-col cols="7">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <h5 class="mb-4">
                            <strong>Oneri di sistema</strong>
                          </h5>
                        </v-col>
                      </v-row>
                    </v-col>
                    <h6>
                      Sono i costi sostenuti da tutti i clienti del servizio
                      elettrico per vari scopi come per esempio il sostegno alla
                      ricerca.
                    </h6>
                  </v-row>
                </v-col>
                <v-col class="radius tabletView" sm="12">
                  <v-row no-gutters>
                    <v-col cols="5" class="percent--small mb-4 border-medium"
                      >25%</v-col
                    >
                    <v-col cols="7">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <h5 class="mb-4"><strong>Imposte</strong></h5>
                        </v-col>
                      </v-row>
                    </v-col>
                    <h6>
                      Sono l’imposta di consumo, che si applica alla quantità di
                      energia consumata, e l’IVA, che si applica all’importo
                      totale della bolletta.
                    </h6>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="6"
            :offset="
              isSmallDevice ? 0 : currentSlide.show_feedback == 1 ? 0 : 3
            "
            v-if="currentSlide.show_tutorial == 1 && tutorial"
          >
            <div>
              <InstrumentItem
                title="Tutorial"
                icon="tutorial.svg"
                text="Abbiamo creato una serie di guide navigabili per migliorare la tua esperienza in MySorgenia!"
                link="Vai ai Tutorial"
                @click="
                  trackTapEvent({
                    name: 'welcomeKit_tutorial_tap',
                    params: {
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType
                    },
                    toNative: isMobile
                  })
                "
                :routeName="isMobile ? 'publicTutorial' : 'tutorial'"
              />
            </div>
          </v-col>
          <v-col
            md="6"
            :offset="
              isSmallDevice
                ? 0
                : currentSlide.show_tutorial == 1 && tutorial
                ? 0
                : 3
            "
            v-if="currentSlide.show_feedback == 1"
          >
            <div>
              <InstrumentItem
                title="Feedback"
                icon="feedback.svg"
                text="La tua opinione è un prezioso contributo. Dedicaci 3 minuti  rispondendo a poche domande!"
                link="Dicci la tua"
                @click="
                  trackTapEvent({
                    name: 'welcomeKit_feeback_tap',
                    params: {
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType
                    },
                    toNative: isMobile
                  })
                "
                :routeName="isMobile ? 'publicFeedback' : 'feedback'"
              />
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="reverseBtn">
          <v-col>
            <v-btn
              @click="
                $router.push(back()),
                  trackTapEvent({
                    name: 'welcomeKit_continue_tap',
                    params: {
                      section_title: prevSlideTitle,
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
              "
              x-large
              color="white"
              class="v-btn--shadowed mb-8 mx-auto"
              >{{ prevSlideTitle }}</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              @click="
                $router.push(next()),
                  trackTapEvent({
                    name: 'welcomeKit_continue_tap',
                    params: {
                      section_title: nextSlideTitle,
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
              "
              x-large
              color="brightGreen"
              class="v-btn--shadowed mb-8 mx-auto"
              v-show="hasNextSlide"
              >{{ nextSlideTitle }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </TabLayout>
    <TutorialOverlay
      :overlay.sync="overlay"
      v-if="currentSlide.show_feedback_overlay == 1 && overlay"
      :isMobile="isMobile"
    />
  </div>
</template>

<script>
import { checkMobile, getKitSlideRouteName } from '@/js/utils.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'billSorgenia',
  data() {
    return {
      isMobile: '',
      isTablet: '',
      overlay: false,
      isSmallDevice: window.innerWidth <= 480
    }
  },
  created() {
    this.isMobile = checkMobile()
    this.isTablet = window.innerWidth > 480 && window.innerWidth <= 960

    this.loadSurvey().then((cmsSurveys) => {
      this.loadUndoneSurvey().then((undoneSurveys) => {
        this.overlay = undoneSurveys.length > 0
      })
    })
  },
  watch: {
    client: function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$store.dispatch('welcomekit/getWelcomekitByUserProfile', {
          client: this.client
        })
        this.$store.dispatch('welcomekit/getTutorialByUserProfile', {
          client: this.client
        })
      }
    }
  },
  computed: {
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['wkSlidesForUser', 'tutorial']),
    currentIndex() {
      var slides = this.wkSlidesForUser || []
      return slides.findIndex((slide) => slide.template_name === 'billSorgenia')
    },
    currentSlide() {
      var slides = this.wkSlidesForUser || []
      return slides[this.currentIndex]
    },
    nextSlideTitle() {
      let slides = this.wkSlidesForUser || []
      if (this.currentIndex === slides.length - 1) return ''
      let nextSlide = slides[this.currentIndex + 1]

      return nextSlide.carousel_title
    },
    hasNextSlide() {
      return this.currentIndex < this.wkSlidesForUser.length - 1
    },
    prevSlideTitle() {
      if (this.currentIndex === 0) return this.welcomekit.title

      let slides = this.wkSlidesForUser || []
      let prevSlide = slides[this.currentIndex - 1]

      return prevSlide.carousel_title
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('feedback', ['loadSurvey', 'loadUndoneSurvey']),
    buttonClicked() {
      this.$router.push('/private/tutorial')
    },
    back() {
      if (this.currentIndex === 0)
        return { name: this.isMobile ? 'publicWelcomekit' : 'welcomekit' }

      let slides = this.wkSlidesForUser || []
      let prevSlide = slides[this.currentIndex - 1]

      if (prevSlide.template_name === 'standardWelcomeKit') {
        return {
          name: getKitSlideRouteName(prevSlide.template_name, this.isMobile),
          params: { id: prevSlide.id }
        }
      } else {
        return {
          name: getKitSlideRouteName(prevSlide.template_name, this.isMobile)
        }
      }
    },
    next() {
      let slides = this.wkSlidesForUser || []
      if (this.currentIndex === slides.length - 1) return ''
      let nextSlide = slides[this.currentIndex + 1]

      if (nextSlide.template_name === 'standardWelcomeKit') {
        return {
          name: getKitSlideRouteName(nextSlide.template_name, this.isMobile),
          params: { id: nextSlide.id }
        }
      } else {
        return {
          name: getKitSlideRouteName(nextSlide.template_name, this.isMobile)
        }
      }
    }
  },
  components: {
    InstrumentItem: () => import('@/components/support/InstrumentItem'),
    TabLayout: () => import('@/views/welcome/TabLayout'),
    TutorialOverlay: () => import('@/views/welcome/TutorialOverlay')
  }
}
</script>
<style lang="scss" scoped>
a {
  color: $srg-green;
}
</style>
