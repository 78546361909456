<template>
  <v-row>
    <v-col md="auto">
      <div class="bg-border-green d-flex pa-5">
        <img src="@/assets/ic_calendar_green.svg" />
        <div class="d-flex flex-column px-5 justify-center">
          <span class="data-prevista-di-att">{{ dateText }}</span>
          <span v-if="descriptionText !== null">{{ descriptionText }}</span>
        </div>
        <v-btn
          :disabled="disabled"
          v-if="showButton"
          @click="onClick"
          color="primary"
          large
          class="v-btn--shadowed"
        >
          Non sono più disponibile
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DateBlock',
  props: {
    dateText: String,
    descriptionText: { type: String, default: null },
    showButton: { type: Boolean, default: false },
    onClick: { type: Function, default: null },
    disabled: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: $title-font-size--small;
  font-weight: bold;
}

.date-block {
  height: 130px;
}

.bg-border-green {
  border: 2px solid $srg-green;
  border-radius: 20px;
}

.data-prevista-di-att {
  font-size: 20px;
  font-weight: 500;
}
.v-btn:not(.v-btn--round).v-size--large {
  height: 55px;
}
button[disabled='disabled'] {
  box-shadow: initial !important;
  border: solid 1px #b4b4b4;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: white !important;
}
.theme--light.v-btn.v-btn--disabled {
  color: #b4b4b4 !important;
  font-weight: initial !important;
}
</style>
