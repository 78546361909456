<template>
  <div class="std-side-padding supportFooter">
    <div class="pr-3">
      <div class="subSection">
        <img src="../../assets/icons/emailIcon.svg" alt="" width="24px" />
        <div class="BodyM-Strong ml-2">Email</div>
      </div>
      <div class="ButtonM customButtonM mt-2">
        {{ email }}
      </div>
      <div class="subSection mt-6 mb-3">
        <img src="../../assets/icons/Call.svg" alt="" width="24px" />
        <div class="BodyM-Strong ml-2">Numero Verde</div>
      </div>
      <div class="subSection">
        <div class="ButtonM customButtonM">800.294.333</div>
        <div class="BodyM ml-3">(da fisso)</div>
      </div>
      <div class="subSection mb-1">
        <div class="ButtonM customButtonM">02.44.388.001</div>
        <div class="BodyM ml-3">(da mobile*)</div>
      </div>
      <div class="BodyS">
        * Il costo della chiamata è variabile in funzione dell’operatore
      </div>
      <div class="BodyS">telefonico</div>
    </div>
    <div class="pr-3">
      <div class="subSection">
        <img src="../../assets/icons/Time.svg" alt="" width="24px" />
        <div class="BodyM-Strong ml-2">Orari</div>
      </div>
      <div class="BodyM mt-2">{{ infoHours }}</div>
      <div class="BodyM mt-2">{{ infoHoursSaturday }}</div>
      <div class="subSection mt-5">
        <img src="../../assets/icons/Like.svg" alt="" width="24px" />
        <div class="BodyM-Strong ml-2">Social</div>
      </div>
      <div class="subSection mt-4">
        <img
          src="../../assets/icons/IconFacebook.svg"
          alt=""
          width="44px"
          class="social"
          @click="openContactLinks('facebook')"
        />
        <img
          src="../../assets/icons/IconTwitter.svg"
          alt=""
          width="44px"
          class="social"
          @click="openContactLinks('twitter')"
        />
      </div>
    </div>
    <div class="pr-3">
      <div class="chat" @click="openWhatsapp">
        <div class="subSection">
          <img
            src="../../assets/icons/WhatsappOutline.svg"
            alt=""
            width="24px"
          />
          <div class="BodyM-Strong ml-2">Whatsapp</div>
        </div>
        <img
          src="../../assets/icons/chevron_rx_light.svg"
          alt=""
          width="24px"
        />
      </div>
      <div
        v-if="
          serviceName !== 'Fotovoltaico' &&
          serviceName !== 'Comunità energetiche'
        "
        class="chat mt-3"
        @click="openChatOrSelect"
      >
        <div class="subSection">
          <img src="../../assets/icons/IconChat.svg" alt="" width="24px" />
          <div class="BodyM-Strong ml-2">Chat</div>
        </div>
        <img
          src="../../assets/icons/chevron_rx_light.svg"
          alt=""
          width="24px"
        />
      </div>
    </div>
    <v-dialog
      v-model="selectUserDialog"
      persistent
      max-width="558"
      overlay-opacity="0.6"
      content-class="customDialog"
    >
      <div class="selectDialogContainer">
        <div class="headerSelect">
          <div class="HeadingsSubtitleL mb-5">Seleziona il profilo</div>
          <img
            src="../../assets/icons/ic-close.svg"
            width="16"
            @click="selectUserDialog = false"
          />
        </div>
        <div
          v-for="(user, i) in formattedData"
          :key="i"
          @click="openChat(user), (selectUserDialog = false)"
        >
          <div class="supplyAddress">
            <div class="supplySection">
              <img src="../../assets/ic-residenziale_blue.svg" width="24" />
              <div class="BodyL-Strong ml-4 mt-1">
                {{ user.businessName }}
              </div>
            </div>
            <img src="@/assets/icons/chevron_rx_light.svg" width="16" />
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { eventBus } from '@/main'
import consts from '@/js/constants'
import { capitalizeFirstLetter } from '../../js/utils'

export default {
  name: 'footerSupport',
  props: {
    serviceName: { type: String }
  },
  data() {
    return {
      chatOpen: true,
      selectUserDialog: false,
      whatsappNumber:
        this.serviceName === 'Luce e Gas' || this.serviceName === 'Fibra'
          ? '3451516521'
          : '3477731694'
    }
  },
  mounted() {
    // console.log('-----', this.serviceName)
  },
  computed: {
    ...mapGetters('account', ['mainClient', 'accountClients']),
    ...mapGetters('interactions', ['callCenterTimes']),
    email() {
      let filteteredList = []
      switch (this.serviceName) {
        case 'Luce e Gas':
          filteteredList = this.filterCallcentertimesList('SUPPORTO', 'EMAIL')
          return filteteredList.contact
        case 'Fotovoltaico':
          filteteredList = this.filterCallcentertimesList(
            'SUPPORTO_FOTOVOLTAICO',
            'EMAIL'
          )

          return filteteredList.contact
        case 'Fibra':
          filteteredList = this.filterCallcentertimesList(
            'SUPPORTO_FIBRA',
            'EMAIL'
          )

          return filteteredList.contact
        default:
          return 'customercarefibra@sorgenia.it'
      }
    },
    infoHours() {
      let filteteredList = []
      let start = ''
      let end = ''
      switch (this.serviceName) {
        case 'Luce e Gas':
          filteteredList = this.filterCallcentertimesList(
            'UPSELLING_LUCE_GAS',
            'MOBILE'
          )
          start = filteteredList.monday_friday_start
          end = filteteredList.monday_friday_end
          return 'Lun - Ven: ' + start + ' - ' + end
        case 'Fotovoltaico':
          filteteredList = this.filterCallcentertimesList(
            'SUPPORTO_FOTOVOLTAICO',
            'WHATSAPP'
          )
          start = filteteredList.monday_friday_start
          end = filteteredList.monday_friday_end
          return 'Lun - Ven: ' + start + ' - ' + end
        // return 'Lun - Ven: 9:00 - 18:00'
        case 'Fibra':
          filteteredList = this.filterCallcentertimesList(
            'SUPPORTO_FIBRA',
            'MOBILE'
          )
          start = filteteredList.monday_friday_start
          end = filteteredList.monday_friday_end
          return 'Lun - Ven: ' + start + ' - ' + end
        default:
          return 'Lun - Ven: 8:00 - 21:00'
      }
    },
    infoHoursSaturday() {
      let filteteredList = []
      let start = ''
      let end = ''
      let holidays = ''
      switch (this.serviceName) {
        case 'Luce e Gas':
          filteteredList = this.filterCallcentertimesList(
            'UPSELLING_LUCE_GAS',
            'MOBILE'
          )

          start =
            filteteredList.saturday_start === '00:00'
              ? ''
              : 'Sab: ' + filteteredList.saturday_start + ' - '
          end =
            filteteredList.saturday_end === '00:00'
              ? ''
              : filteteredList.saturday_end + ' '
          holidays =
            filteteredList.active_on_holidays === '0'
              ? '(esclusi festivi nazionali)'
              : ''
          return start + end + holidays
        case 'Fotovoltaico':
          filteteredList = this.filterCallcentertimesList(
            'SUPPORTO_FOTOVOLTAICO',
            'WHATSAPP'
          )
          start =
            filteteredList.saturday_start === '00:00'
              ? ''
              : 'Sab: ' + filteteredList.saturday_start + ' - '
          end =
            filteteredList.saturday_end === '00:00'
              ? ''
              : filteteredList.saturday_end + ' '
          holidays =
            filteteredList.active_on_holidays === '0'
              ? '(esclusi festivi nazionali)'
              : ''
          return start + end + holidays
        case 'Fibra':
          filteteredList = this.filterCallcentertimesList(
            'SUPPORTO_FIBRA',
            'MOBILE'
          )
          start =
            filteteredList.saturday_start === '00:00'
              ? ''
              : 'Sab: ' + filteteredList.saturday_start + ' - '
          end =
            filteteredList.saturday_end === '00:00'
              ? ''
              : filteteredList.saturday_end + ' '
          holidays =
            filteteredList.active_on_holidays === '0'
              ? '(esclusi festivi nazionali)'
              : ''
          return start + end + holidays
        default:
          return 'Sab: 8:00 - 15:00 (esclusi festivi nazionali)'
      }
    },
    formattedData() {
      let dataFormatted = []
      Object.keys(this.accountClients).forEach((key) => {
        let item = this.accountClients[key]
        let [firstName, lastName] = item.businessName.split(' ')
        let formattedFirstName = capitalizeFirstLetter(firstName)
        let formattedLastName = capitalizeFirstLetter(lastName)
        item.businessName = formattedFirstName + ' ' + formattedLastName
        dataFormatted.push(item)
      })
      return dataFormatted
    }
  },
  beforeMount() {
    this.getCallCenterTimes()
    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.CHAT,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.chatOpen = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('interactions', ['isChannelOpen', 'getCallCenterTimes']),
    openContactLinks(social) {
      switch (social) {
        case 'facebook':
          window.open('https://www.facebook.com/sorgenia', '_blank')
          break
        case 'twitter':
          window.open('https://twitter.com/sorgenia', '_blank')
      }
    },
    openChatOrSelect() {
      if (Object.keys(this.accountClients).length == 1) {
        this.openChat(this.mainClient)
      } else if (Object.keys(this.accountClients).length > 1) {
        this.selectUserDialog = true
      } else return
    },

    openChat(accountClient) {
      if (this.chatOpen) {
        this.trackTapEvent({
          name: 'support_chatbot_tap',
          params: {
            client_code: accountClient.clientOwnerCode,
            client_type: accountClient.clientOwnerType?.toLowerCase()
          }
        })
        eventBus.$emit('chat:open', {
          from: 'support',
          client: accountClient
        })
      } else return
    },
    openWhatsapp() {
      window.open(`https://wa.me/${this.whatsappNumber}`)
    },
    filterCallcentertimesList(area, channel) {
      let filteteredList = this.callCenterTimes.find((item) => {
        return item.area === area && item.channel === channel
      })
      return filteteredList
    }
  }
}
</script>
<style lang="scss">
.supportFooter {
  width: 100%;
  background: #dfe4ef;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 48px;
  padding-top: 48px;
}

.subSection {
  display: flex;
  align-items: center;
}

.customButtonM {
  font-size: 16px !important;
  color: $srg-blue;
  padding-top: 8px;
  padding-bottom: 8px;
}
.social {
  margin-right: 10px;
  cursor: pointer;
}
.chat {
  display: flex;
  justify-content: space-between;
  width: 361px;
  height: 56px;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #dfe4ef;
  background-color: #ffffff;
  cursor: pointer;
}

.customDialog {
  border-radius: 20px;
  background-color: white;
}

.selectDialogContainer {
  padding: 40px;
  .headerSelect {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    img {
      cursor: pointer;
    }
  }
}
.supplySection {
  display: flex;
  align-items: center;
}

.supplyAddress {
  height: 56px;
  display: flex;
  justify-content: space-between;
  border: 1px solid $neutri-neutral-30;
  border-radius: 20px;
  padding: 16px;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}
.supplyOwner {
  display: flex;
  padding-left: 16px;
  margin-bottom: 24px;
  margin-top: 24px;
}
</style>
