<template>
  <div class="migration mt-8">
    <v-dialog max-width="555" v-model="infoDialogMigration">
      <v-row>
        <v-col class="moredetailsdialog">
          <img
            @click="infoDialogMigration = false"
            src="../../../assets/ic_chevron_top_black.png"
          />
          <div>
            <h3 class="HeadingsSubtitleL">Dove lo trovo e a cosa serve</h3>
          </div>
          <div class="BodyL mt-8">
            Il codice migrazione è un codice alfanumerico che identifica
            univocamente la tua attuale fornitura internet. Puoi trovarlo sulla
            bolletta del tuo attuale fornitore.
          </div>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- dialog more details -->
    <v-dialog max-width="555" v-model="moreDetailsDialog">
      <v-row>
        <v-col class="moredetailsdialog">
          <img
            width="26"
            @click="moreDetailsDialog = false"
            src="../../../assets/ic_chevron_top_black.png"
          />
          <div>
            <h3
              v-if="moreDetailsCase === 'case1'"
              class="HeadingsSubtitleM mt-1"
            >
              Da connessione misto rame a fibra ottica
            </h3>
            <h3 v-if="moreDetailsCase === 'case2'" class="HeadingsSubtitleL">
              Nuova connessione
            </h3>
            <h3 v-if="moreDetailsCase === 'case3'" class="HeadingsSubtitleL">
              Connessione TIM
            </h3>
          </div>
          <div class="mt-10">
            <h4 v-if="moreDetailsCase === 'case1'" class="BodyL">
              Il codice migrazione che hai inserito indica che la tua attuale
              connessione è in misto rame, mentre il tuo indirizzo è coperto
              dalla connettività in fibra ottica!
            </h4>
            <h4 v-if="moreDetailsCase === 'case2'" class="BodyL">
              Il codice migrazione inserito non supporta la migrazione
              auotomatica.
            </h4>
            <h4 v-if="moreDetailsCase === 'case3'" class="BodyL">
              Il codice migrazione inserito indica che la tua attuale connesione
              è gestita da TIM.
            </h4>
          </div>
          <div class="mt-6">
            <h4 v-if="moreDetailsCase === 'case1'" class="BodyL">
              La fibra ottica è una tecnologia più avanzata alla tua attuale
              connessione, poiché l'intera rete di collegamento, dalla centrale
              fino alla tua abitazione, sarà in fibra ottica. Questo permette di
              ottenere prestazioni ottimali grazie a una rete più efficiente e
              con una stabilità di connessione superiore. <br />
              In questi casi dovremmo procedere alla realizzazione di una nuova
              linea e quindi non viene supportata la migrazione.
            </h4>
            <h4 v-if="moreDetailsCase === 'case3'" class="BodyL">
              In questi casi dovremmo procedere alla realizzazione di una nuova
              linea e quindi non viene supportata la migrazione.
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-dialog>

    <div class="migration__input">
      <div class="d-flex mt-5">
        <h5 class="CaptionM migrationMessage" style="text-transform: uppercase">
          Inserisci il codice di migrazione
        </h5>
        <img
          @click="openInfoDialogMigration"
          class="ml-3 cliccable"
          src="../../../assets/icons/info.svg"
          width="16"
        />
      </div>
      <v-text-field
        color="success"
        :error-messages="migrationCodeErrors"
        @input="v$.migrationCode.$touch(), validateInput"
        v-model.trim="migrationCode"
        :success-messages="
          v$.migrationCode.$invalid
            ? null
            : 'Il codice di migrazione inserito è corretto'
        "
        placeholder="es. JJ100000101001"
      >
      </v-text-field>
    </div>
    <v-row v-if="vProgressCircular">
      <v-col class="text-center">
        <v-progress-circular :size="50" indeterminate />
      </v-col>
    </v-row>
    <template
      v-if="
        isValidMigrationCode &&
        validForChangeSupplier &&
        !isFromTelecom &&
        res != undefined
      "
    >
      <div class="migration__box">
        <div class="d-flex">
          <img class="mr-2" src="../../../assets/icons/info.svg" width="24" />
          <h5 class="BodyM mt-1">
            Bene! il precedente fornitore supporta la migrazione automatica.
          </h5>
        </div>
      </div>
      <div class="mt-8">
        <h5 class="BodyL-Strong">Chi deve disattivare il contratto?</h5>
        <h5 class="BodyM mt-4">
          Ci occuperemo noi di disattivare il vecchio contratto di connessione.
        </h5>
      </div>
      <div class="migration__btn">
        <v-btn
          color="primary"
          x-large
          width="260"
          class="v-btn--shadowed"
          @click="goToActiovation"
          :disabled="v$.$invalid"
          >Continua</v-btn
        >
      </div>
    </template>
    <template
      v-if="
        (isValidMigrationCode && !validForChangeSupplier && res != undefined) ||
        (isValidMigrationCode && isFromTelecom && res != undefined)
      "
    >
      <div class="migration__box">
        <div class="d-flex">
          <img class="mr-2" src="../../../assets/icons/info.svg" width="24" />
          <h5 class="mt-1 BodyM">
            La tua richiesta verrà gestita come nuova connessione.
          </h5>
        </div>
        <h5
          class="ml-8 mt-3 ButtonS color--blue cliccable"
          @click="openMoreDetailsDialog"
        >
          Maggiori dettagli
        </h5>
      </div>
      <div class="migration__boxInvalidCode">
        <h5 class="mb-3 BodyL-Strong">Cosa cambia?</h5>
        <ul>
          <li>
            <h5 class="mb-4 BodyM">
              Sarai contattato per fissare un appuntamento per l’intervento del
              tecnico che installerà la nuova fibra. Andrai alla velocità della
              luce!
            </h5>
          </li>
          <li>
            <h5 class="BodyM">
              Dovrai procedere in autonomia alla disattivazione del contratto
              con il precedente fornitore
            </h5>
          </li>
        </ul>
      </div>
      <div class="migration__btn">
        <v-btn
          color="primary"
          x-large
          width="212"
          class="v-btn--shadowed"
          @click="gotoStepActivation"
          :disabled="v$.$invalid"
          >OK, ho capito</v-btn
        >
      </div>
    </template>
  </div>
</template>
<script>
import { isFastwebCode, isTelecomCode } from '@/js/utils.js'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'migrationCode',
  data() {
    return {
      migrationCode: '',
      showInfo: true,
      infoDialogMigration: false,
      validForChangeSupplier: '',
      moreDetailsDialog: false,
      moreDetailsCase: '', // case1 case2 o case3
      res: undefined,
      codiceCOS: '',
      vProgressCircular: '',
      isValidMigrationCode: null
    }
  },
  watch: {
    migrationCode(newVal) {
      this.v$.migrationCode.isValidMigrationCode.$response.then((result) => {
        this.isValidMigrationCode = result
      })
      this.migrationCode = newVal.toUpperCase().replace(' ', '')
      this.v$.migrationCode.$model = newVal.toUpperCase().replace(' ', '')
      if (this.migrationCode) {
        this.validateInput()
      }
    }
  },
  beforeMount() {
    let migrationCodeStored =
      this.$store.getters['upsellingFiber/migrationCode'] &&
      this.$store.getters['upsellingFiber/migrationCode'].code
    this.migrationCode = migrationCodeStored || ''
  },
  computed: {
    ...mapGetters('upsellingFiber', [
      'isFromTelecom',
      'connectionType',
      'provider',
      'addressCovered'
    ]),
    migrationCodeErrors: function () {
      let errArray = []
      if (!this.v$.migrationCode.$dirty) return errArray

      this.v$.migrationCode.required.$invalid === true &&
        errArray.push('Il codice migrazione è obbligatorio')
      !this.isValidMigrationCode &&
        errArray.push('Codice errato o non riconosciuto')
      return errArray
    }
  },
  methods: {
    ...mapActions('upsellingFiber', ['getCheckMigrazione']),
    ...mapMutations('upsellingFiber', [
      'setMigrationCode',
      'setActiveLine',
      'setIsFromTelecom',
      'setIsFromFastweb',
      'updateFiberCtx'
    ]),

    async validateInput() {
      this.vProgressCircular = true
      this.codiceCOS =
        this.migrationCode.slice(-4, -3) +
        this.migrationCode.slice(-3, -2) +
        this.migrationCode.slice(-2, -1)

      const params = {
        tipoTecnologia: this.addressCovered.typology || 'FTTH_GPON',
        tipoConnessione: this.addressCovered.connectionType || null,
        codiceMigrazione: this.migrationCode
      }

      this.res = await this.getCheckMigrazione(params)

      let openFiber = this.checkOpenFiber(this.migrationCode || '')

      this.setMigrationCode({
        checked: true,
        code: this.migrationCode,
        isOF: openFiber
      })

      this.vProgressCircular = false
      this.setActiveLine(true)
      let isFromTelecom = isTelecomCode(this.migrationCode || '')
      let isFromFastweb = isFastwebCode(this.migrationCode || '')
      this.setIsFromTelecom(isFromTelecom)
      this.setIsFromFastweb(isFromFastweb)
      this.updateFiberCtx({
        is_migration: true,
        migration_code: this.migrationCode
      })
      if (this.res.codiceMigrazione?.tipoRichiesta === 'CA') {
        this.validForChangeSupplier = true
      } else if (
        (this.codiceCOS === '009' ||
          this.codiceCOS === '010' ||
          this.codiceCOS === '011' ||
          this.codiceCOS === '012') &&
        (this.addressCovered.typology === 'FTTH_GPON' ||
          this.addressCovered.typology === '')
      ) {
        this.validForChangeSupplier = false
        this.moreDetailsCase = 'case1'
      } else if (
        (this.addressCovered.typology === 'FTTH_GPON' ||
          this.addressCovered.typology === '') &&
        this.provider === 'OF' // più controllo COS === FWD || FWC  potrebbe non servire in futuro
      ) {
        this.validForChangeSupplier = false
        this.moreDetailsCase = 'case2'
      } else if (this.isFromTelecom) {
        this.validForChangeSupplier = false
        this.moreDetailsCase = 'case3'
      } else this.validForChangeSupplier = false
    },
    checkOpenFiber(code) {
      let of = code.slice(-4).slice(0, 2)
      return of === 'OF'
    },
    goToActiovation() {
      this.$emit('nextStep', 'upsellingv2_phonenumber_open')
    },
    gotoStepActivation() {
      this.setMigrationCode({ checked: false, code: '', isOF: '' })
      this.updateFiberCtx({ is_migration: false, migration_code: null })
      this.setActiveLine(false)
      this.setIsFromTelecom(false)
      this.setIsFromFastweb(false)
      this.$emit('setFlowType', 'newConnection')
      this.$emit('goToStep', 7, 'upsellingv2_activationtimes_open')
    },
    openInfoDialogMigration() {
      this.infoDialogMigration = true
    },
    openMoreDetailsDialog() {
      this.moreDetailsDialog = true
    }
  },
  validations: {
    migrationCode: {
      required,
      async isValidMigrationCode(code) {
        const params = {
          tipoTecnologia: this.addressCovered.typology || 'FTTH_GPON',
          tipoConnessione: this.addressCovered.connectionType || null,
          codiceMigrazione: this.migrationCode
        }
        if (code.includes(' ')) return false
        this.codiceCOS =
          code.slice(-4, -3) + code.slice(-3, -2) + code.slice(-2, -1)

        const response = (this.res = await this.getCheckMigrazione(params))
        if (response.status === 'OK') {
          if (response.codiceMigrazione.tipoRichiesta === 'CA') {
            this.validate = true
            return true
          }
          if (response.codiceMigrazione.tipoRichiesta === 'PR') {
            if (isTelecomCode(code)) {
              return true
            }
            if (
              this.codiceCOS !== 'FWC' &&
              this.codiceCOS !== 'FWD' &&
              this.codiceCOS !== '009' &&
              this.codiceCOS !== '010' &&
              this.codiceCOS !== '011' &&
              this.codiceCOS !== '012'
            ) {
              return false
            } else {
              this.validate = true
              return true
            }
          }
        } else return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.migration {
  &__input {
    width: 652px;
  }
  &__info {
    margin-top: 32px;
    h5 {
      max-width: 652px;
    }
    img {
      margin-top: 48px;
      width: 620px;
      height: 24px;
      border: 1px solid #ccd0e1;
      border-radius: 20px;
    }
  }
  &__box {
    max-width: 652px;
    margin-top: 56px;
    padding: 16px;
    border-radius: 8px;
    background-color: $neutri-neutral-20;
  }
  &__btn {
    margin-top: 70px;
  }
  &__boxInvalidCode {
    width: 652px;
    margin-top: 32px;
    border-radius: 8px;
  }
}
.migrationMessage {
  color: $neutri-neutral-50;
}

li {
  list-style-type: disc;
  font-size: 12px;
}
.moredetailsdialog {
  background: white;
  padding: 40px;
  position: relative;

  img {
    position: absolute;
    top: 40px;
    right: 30px;
    cursor: pointer;

    &.preview {
      position: initial;
    }
  }
}
.cliccable {
  cursor: pointer;
}
</style>

<style lang="scss">
.migration {
  .success--text {
    .v-messages__message {
      display: flex;
      align-items: center;
      &::before {
        content: url('../../../assets/icons/ok-small.svg');
        margin-right: 5px;
      }
    }
    input {
      color: $feedback-success;
    }
  }
}
</style>
