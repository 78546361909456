<template>
  <div class="loyalty-landing">
    <v-row class="loyalty-landing__logo" justify="center">
      <img
        :src="require('@/assets/greeners_logo_loyalty_main.png')"
        width="231"
      />
    </v-row>
    <v-row class="loyalty-landing__content">
      <slot />
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'loyaltyPage'
}
</script>

<style lang="scss" scoped>
.loyalty-landing {
  height: 100%;
  background-color: $srg-green;
  &__logo {
    padding-top: 25px;
  }
  &__content {
    padding-top: 50px;
  }
}
</style>
