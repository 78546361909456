<template>
  <div id="chart">
    <apexchart
      :type="type"
      :width="width"
      :height="height"
      :options="chartOptionsLine"
      :series="[]"
      :class="`apex--${type}`"
      ref="chart"
    />
  </div>
</template>
<script>
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import VueApexCharts from 'vue-apexcharts'
import { mapActions } from 'vuex'

const monthNames = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre'
]

export default {
  name: 'chartNew',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    series: { type: Object, required: true },
    seriesCompare: { type: Object, default: null },
    height: { type: [String, Number], default: 350 },
    width: { type: [String, Number], default: '100%' },
    commodity: { type: String, default: 'Ele' },
    type: { type: String, default: 'line' },
    tabSelected: { type: Number, default: 0 },
    power: { type: [String, Number], default: 3 },
    isFascia: { type: Boolean, default: false },
    detail: { type: Object, default: null }
  },
  watch: {
    series(data) {
      if (data.frazione) {
        this.loadPeriodSeries(data.frazione)
      }
    },
    changeHoverPoint() {
      this.trackTapEvent({
        name: 'supplyDetail_consumptionChart_tap',
        params: getSupplyTrackingParams(this.detail)
      })
    }
  },
  data: function () {
    const dis = this
    return {
      // series: this.$store.state.supply.supplyDetail[this.$route.params.supplyCode].consumption,
      changeHoverPoint: null,
      chartOptionsLine: {
        chart: {
          id: 'testChartId',
          events: {
            scrolled: function () {
              dis.trackTapEvent({
                name: 'supplyDetail_consumptionChart_tap',
                params: getSupplyTrackingParams(this.detail)
              })
            }
          },
          toolbar: {
            autoSelected: 'pan',
            show: false,
            tools: {
              pan: true
            }
          },
          zoom: {
            enabled: true,
            type: 'x'
          }
        },
        fill: {
          type: 'solid'
        },
        stroke: {
          curve: 'straight',
          dashArray: [0, 10, 10, 10],
          colors: ['#12256A', '#255bd7', '#0091ff', '#2fbdd1'],
          width: [12, 4, 4, 4]
        },
        colors: ['#12256A', '#97D707'],
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        legend: {
          show: false
        },
        yaxis: {
          show: false
        },
        xaxis: {
          type: 'category',
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Roboto',
              cssClass: 'label-custom'
            }
          },
          tickPlacement: 'on',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true
          },
          crosshairs: {
            position: 'front'
          }
        }
      },
      unit: '',
      scala: {
        mensile: 'month',
        giornaliera: 'daily',
        oraria: 'hours',
        quartoraria: 'hours'
      },
      selectScala: ''
    }
  },
  computed: {
    generateSeries() {
      let generateSerie = []
      let series = this.series.curve
      let seriesCompare = this.seriesCompare && this.seriesCompare.curve
      if (series) {
        for (var i = 0; i < series.length; i++) {
          if (series[i]) {
            let dateStart = new Date(series[i].startDate)
            if (this.tabSelected === 0) {
              if (generateSerie.length === 0) {
                generateSerie = [
                  {
                    name: 'consumo',
                    data: [{ x: dateStart, y: series[i].curveconsumo }]
                  }
                ]
              } else {
                generateSerie[0].data.push({
                  x: dateStart,
                  y: series[i].curveconsumo
                })
              }
              if (
                ('fascia1' && 'fascia2' && 'fascia3') in series[i] &&
                !seriesCompare &&
                this.isFascia
              ) {
                ;['fascia1', 'fascia2', 'fascia3'].forEach((fascia, key) => {
                  if (
                    generateSerie[key + 1] &&
                    generateSerie[key + 1].name === fascia
                  ) {
                    generateSerie[key + 1].data.push({
                      x: dateStart,
                      y: series[i][fascia]
                    })
                  } else {
                    generateSerie.push({
                      name: fascia,
                      data: [{ x: dateStart, y: series[i][fascia] }]
                    })
                  }
                })
              }
            } else {
              if (generateSerie.length === 0) {
                generateSerie = [
                  {
                    name: 'potenza',
                    data: [{ x: dateStart, y: series[i].piccopotenza }]
                  }
                ]
                generateSerie.push({
                  name: 'picco',
                  data: [{ x: dateStart, y: this.power }]
                })
              } else {
                generateSerie[0].data.push({
                  x: dateStart,
                  y: series[i].piccopotenza
                })
                generateSerie[1].data.push({ x: dateStart, y: this.power })
              }
            }
          }
        }
      }
      if (seriesCompare) {
        let maxLength = Math.max(
          seriesCompare.length,
          generateSerie[0].data.length
        )
        for (let i = 0; i < maxLength; i++) {
          if (seriesCompare[i]) {
            let dateStart = new Date(seriesCompare[i].startDate)
            if (this.tabSelected === 0) {
              if (generateSerie[1] && generateSerie[1].name === 'compare') {
                generateSerie[1].data.push({
                  x: dateStart,
                  y: seriesCompare[i].curveconsumo
                })
              } else {
                generateSerie.push({
                  name: 'compare',
                  data: [{ x: dateStart, y: seriesCompare[i].curveconsumo }]
                })
              }
            }
          } else {
            generateSerie[1].data.push({
              x: generateSerie[0].data[i].x,
              y: null
            })
          }
          if (!generateSerie[0].data[i]) {
            let oldX = new Date(generateSerie[0].data[0].x)
            let newX = new Date(oldX.setDate(oldX.getDate() - 1))
            generateSerie[0].data.unshift({ x: newX, y: null })
          }
        }
      }
      return generateSerie
    }
  },
  beforeMount() {
    this.unit =
      this.commodity === 'Ele' ? (this.tabSelected === 0 ? 'kWh' : 'kW') : 'Smc'
    this.updateLineChart()
  },
  mounted() {
    this.loadPeriodSeries(this.series.frazione || '1')
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    loadPeriodSeries(frazione) {
      this.selectScala = frazione
      let series = this.generateSeries // todo reload service and recalculate aggregates
      this.$refs.chart.updateSeries(series)
      let maxLength = Math.max(
        this.series.curve.length,
        this.seriesCompare ? this.seriesCompare.curve.length : 0
      )
      let fromDate = maxLength
      let toDate = fromDate - 12
      this.$refs.chart.zoomX(toDate, fromDate)
      let options = {
        xaxis: {
          labels: {
            formatter: (value) => {
              switch (frazione) {
                case '1':
                  return new Date(value).toLocaleString('it-IT', {
                    month: 'short'
                  })
                case '2':
                  return new Date(value).getDate()
                case '3':
                case '4':
                  return new Date(value).toLocaleString('it-IT', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })
              }
            }
          },
          tooltip: {
            enabled: true,
            offsetY: 13,
            formatter: function (val, opts) {
              let date = opts.w.config.series[0].data[val - 1].x
              let labelX = ''
              let labelDate = ''
              switch (frazione) {
                case '1':
                  labelX = date.toLocaleString('it-IT', { month: 'short' })
                  labelDate = date.getFullYear()
                  break
                case '2':
                  labelX = date.getDate()
                  labelDate =
                    monthNames[date.getMonth()] + ' ' + date.getFullYear()
                  break
                case '3':
                case '4':
                  labelX = date.toLocaleString('it-IT', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })
                  labelDate =
                    date.getDate() +
                    ' ' +
                    monthNames[date.getMonth()] +
                    ' ' +
                    date.getFullYear()
                  break
              }
              return labelX + "<div class='labelDate'>" + labelDate + '</div>'
            },
            style: {
              fontSize: 20
            }
          }
        },
        stroke: {
          curve: 'straight',
          dashArray: this.seriesCompare ? [0, 0] : [0, 10, 10, 10],
          colors: this.seriesCompare
            ? ['#12256A', '#97D707']
            : ['#12256A', '#255bd7', '#0091ff', '#2fbdd1'],
          width: this.seriesCompare ? [12, 12] : [12, 4, 4, 4]
        }
      }
      this.$refs.chart.updateOptions(options)
    },
    updateLineChart() {
      this.chartOptionsLine = {
        ...this.chartOptionsLine,
        ...{
          markers: {
            size: 0,
            shape: 'circle',
            colors: '#000',
            strokeColors: '#fff',
            strokeWidth: 5,
            strokeOpacity: 1,
            showNullDataPoints: false,
            hover: {
              size: 16
            }
          },
          tooltip: {
            shared: true,
            style: {
              fontSize: '14px',
              fontFamily: 'Roboto'
            },
            x: {
              show: false
            },
            y: {
              formatter: (
                value,
                { series, seriesIndex, dataPointIndex, w }
              ) => {
                this.changeHoverPoint = dataPointIndex
                // if (seriesIndex === 1) {
                if (
                  series[1] &&
                  (series[seriesIndex][dataPointIndex] === undefined ||
                    series[seriesIndex][dataPointIndex] === null)
                ) {
                  return '<div class="chart-item__hide"></div>'
                } else if (w.globals.seriesNames.includes('compare')) {
                  let original = parseFloat(
                    w.config.series[seriesIndex].data[dataPointIndex].y
                  ).toFixed(2)
                  const idx = seriesIndex === 0 ? 1 : 0
                  let compare = parseFloat(
                    w.config.series[idx].data[dataPointIndex].y
                  ).toFixed(2)
                  let variation = (
                    ((original - compare) / compare) *
                    100
                  ).toFixed(1)
                  let upIcon = require('@/assets/consumption-up.svg')
                  let downIcon = require('@/assets/consumption-down.svg')
                  return (
                    `<div class="chart-item">${this.formatdate(
                      w.config.series[seriesIndex].data[dataPointIndex].x
                    )}` +
                    `<div class="chart-item__circle" style="background-color: ${w.globals.colors[seriesIndex]}"></div>` +
                    `${
                      this.series.frazione === '3' ||
                      this.series.frazione === '4'
                        ? '<div>' +
                          this.formatHours(
                            w.config.series[seriesIndex].data[dataPointIndex].x
                          ) +
                          '</div>'
                        : ''
                    }` +
                    `<div class="chart-item__val">${
                      this.formaValue(
                        w.config.series[seriesIndex].data[dataPointIndex].y
                      )[0]
                    }` +
                    `<span class="chart-item__type">,${
                      this.formaValue(
                        w.config.series[seriesIndex].data[dataPointIndex].y
                      )[1]
                    }</span>` +
                    `<span class="chart-item__type"> ${this.unit}</span>
                      </div>` +
                    (!isNaN(variation) && seriesIndex === 0
                      ? `<div class="variationBox justify-space-between d-flex align-center">VARIAZIONE CONSUMO <span class="variationLabel">` +
                        `${variation}%</span>` +
                        (variation > 0
                          ? `<img src="${upIcon}" width="24" height="24" />`
                          : `<img src="${downIcon}" width="24" height="24"/>`) +
                        `</div>
                        </div>`
                      : `</div>`)
                  )
                } else if (w.globals.seriesNames.includes('fascia1')) {
                  if (seriesIndex === 0) {
                    return (
                      `<div class="chart-item big text-center">${
                        this.series.frazione === '3' ||
                        this.series.frazione === '4'
                          ? '<span>' +
                            this.formatHours(
                              w.config.series[seriesIndex].data[dataPointIndex]
                                .x
                            ) +
                            ' - </span>'
                          : ''
                      }
                      ${this.formatdate(
                        w.config.series[seriesIndex].data[dataPointIndex].x
                      )}` +
                      `<div class="chart-item__val text-center">${
                        this.formaValue(
                          w.config.series[seriesIndex].data[dataPointIndex].y
                        )[0]
                      }` +
                      `<span class="chart-item__type">,${
                        this.formaValue(
                          w.config.series[seriesIndex].data[dataPointIndex].y
                        )[1]
                      }</span>` +
                      `<span class="chart-item__type"> ${this.unit}</span>
                        </div>
                        <div id="tooltipFascia"></div>
                      </div>`
                    )
                  } else {
                    document.getElementById('tooltipFascia').insertAdjacentHTML(
                      'beforeend',
                      `
                      <div class="fascia">F${seriesIndex}: <span style="color:${
                        w.globals.stroke.colors[seriesIndex]
                      }">${parseFloat(
                        w.config.series[seriesIndex].data[dataPointIndex].y
                      ).toFixed(1)}</span></div>
                    `
                    )
                  }
                } else if (w.globals.seriesNames.includes('potenza')) {
                  if (seriesIndex === 0) {
                    return (
                      `<div class="chart-item big text-center">${this.formatdate(
                        w.config.series[seriesIndex].data[dataPointIndex].x
                      )}` +
                      `<div class="chart-item__val text-center">${
                        this.formaValue(
                          w.config.series[seriesIndex].data[dataPointIndex].y
                        )[0]
                      }` +
                      `<span class="chart-item__type">,${
                        this.formaValue(
                          w.config.series[seriesIndex].data[dataPointIndex].y
                        )[1]
                      }</span>` +
                      `<span class="chart-item__type"> ${this.unit}</span>
                        </div>
                        <div id="tooltipPotenza"></div>
                      </div>`
                    )
                  } else {
                    document
                      .getElementById('tooltipPotenza')
                      .insertAdjacentHTML(
                        'beforeend',
                        `
                      <div class="potenza">Potenza fatturata: <span style="color:${
                        w.globals.stroke.colors[seriesIndex]
                      }">${parseFloat(this.power).toFixed(1)} kW</span></div>
                    `
                      )
                  }
                } else {
                  return (
                    `<div class="chart-item text-center">${
                      this.series.frazione === '3' ||
                      this.series.frazione === '4'
                        ? '<span>' +
                          this.formatHours(
                            w.config.series[seriesIndex].data[dataPointIndex].x
                          ) +
                          ' - </span>'
                        : ''
                    }
                      ${this.formatdate(
                        w.config.series[seriesIndex].data[dataPointIndex].x
                      )}` +
                    `<div class="chart-item__val text-center">${
                      this.formaValue(
                        w.config.series[seriesIndex].data[dataPointIndex].y
                      )[0]
                    }` +
                    `<span class="chart-item__type">,${
                      this.formaValue(
                        w.config.series[seriesIndex].data[dataPointIndex].y
                      )[1]
                    }</span>` +
                    `<span class="chart-item__type"> ${this.unit}</span>
                      </div>
                    </div>`
                  )
                }
              },
              title: {
                formatter: function () {
                  return ''
                }
              }
            },
            items: {
              display: 'inline-block'
            }
          }
        }
      }
    },
    formatHours(value) {
      let date = new Date(value)
      return date
        .toLocaleString('it-IT', { hour: '2-digit', minute: '2-digit' })
        .split(':')
        .join('.')
    },
    formatdate(value) {
      let date = new Date(value)
      switch (this.series.frazione) {
        case '1':
          return monthNames[date.getMonth()] + ' ' + date.getFullYear()
        case '2':
        case '3':
        case '4':
          return (
            date.getDate() +
            ' ' +
            monthNames[date.getMonth()] +
            ' ' +
            date.getFullYear()
          )
      }
    },
    formaValue(val) {
      let value = parseFloat(val).toFixed(1)
      return value.split('.')
    },
    setAnnotation(val) {
      if (!this.chartOptionsArea.annotations) {
        this.chartOptionsArea.annotations = {
          position: 'back',
          xaxis: [
            {
              x: val,
              strokeDashArray: 3,
              fillColor: '#ffffff',
              label: {
                position: 'bottom',
                borderWidth: 0,
                text: val.split('/')[0],
                orientation: 'horizontal',
                offsetY: 30,
                style: {
                  fontSize: '12px',
                  color: '#4A5155'
                }
              }
            }
          ]
        }
      } else if (this.chartOptionsArea.annotations.xaxis.length === 1) {
        this.chartOptionsArea.annotations.xaxis.push({
          x: val,
          strokeDashArray: 3,
          fillColor: '#ffffff',
          label: {
            position: 'bottom',
            borderWidth: 0,
            text: val.split('/')[0],
            orientation: 'horizontal',
            offsetY: 30,
            style: {
              fontSize: '12px',
              color: '#4A5155'
            }
          }
        })
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" src="./_chart.scss" />
