import { render, staticRenderFns } from "./GreenersCard.vue?vue&type=template&id=2b36ef60&scoped=true"
import script from "./GreenersCard.vue?vue&type=script&lang=js"
export * from "./GreenersCard.vue?vue&type=script&lang=js"
import style0 from "./GreenersCard.vue?vue&type=style&index=0&id=2b36ef60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b36ef60",
  null
  
)

export default component.exports