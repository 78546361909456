<template>
  <div class="self-reading mt-4">
    <TcVars
      env_template="mysorgenia_autolettura"
      :pdr="details.pdr"
      :pod="details.pod"
      :user_contract_code="cdPropostaContratto"
      :user_contract_code_fibra="cdPropostaContrattoFibra"
      v-if="details && cdPropostaContratto && cdPropostaContrattoFibra"
    />
    <v-row no-gutters v-if="details && lastReading">
      <v-col>
        <v-row class="std-side-padding">
          <v-col>
            <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
              <template v-slot:divider>
                <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>
        <router-view
          :details="details"
          :lastReading="lastReading"
          :isAcea="isAcea"
          :enteredReadings="enteredReadings"
          @send-values="sendReadings"
        >
        </router-view>
      </v-col>
    </v-row>

    <PhysiCallDialog
      :physicallObj="physiCall"
      :triggerAction="triggerActionPhysicall"
    ></PhysiCallDialog>
    <FeedbackDialog
      :physicallObj="physiCall"
      :triggerAction="triggerActionPhysicall"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import GenErr from '@/js/genericErrors'
import router from '@/router/router'
import TcVars from '@/components/tcTracker/TcVars'
import { capitalize } from '@/js/utils'
import consts from '@/js/constants'

import PhysiCall from '@/js/service/physiCall'
import PhysiCallDialog from '@components/physicall/PhysiCallDialog.vue'
import FeedbackDialog from '../../../../components/physicall/FeedbackDialog.vue'

export default {
  data() {
    return {
      details: null,
      lastReading: null,
      isAcea: false,
      status: 'ok',
      enteredReadings: null,
      cdPropostaContratto: null,
      cdPropostaContrattoFibra: null,
      physiCall: null,
      triggerActionType: null,
      responseAction: {}
    }
  },
  components: {
    TcVars,
    PhysiCallDialog,
    FeedbackDialog
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail']),
    breadcrumbsItems() {
      let res = []
      if (this.details && this.details.address) {
        let address = this.details ? this.details.address : ''
        address = capitalize(address.toLowerCase())
        if (this.$route.params.from === 'bill') {
          res = [
            { to: { name: 'home' }, text: 'Home' },
            { to: { name: 'ownerSupplies' }, text: 'Utenze', exact: true },
            {
              to: {
                name: 'supplyDetail',
                params: {
                  clientOwnerCode: this.$route.params.clientOwnerCode,
                  supplyCode: this.$route.params.supplyCode
                }
              },
              text: address,
              exact: true
            },
            {
              to: {
                name: 'bills',
                params: { idFattura: this.$route.params.idFattura }
              },
              text: 'Bolletta',
              exact: true
            },
            {
              to: { name: this.$router.currentRoute.name },
              text: 'Autolettura'
            }
          ]
        } else {
          res = [
            { to: { name: 'home' }, text: 'Home' },
            { to: { name: 'ownerSupplies' }, text: 'Utenze', exact: true },
            {
              to: {
                name: 'supplyDetail',
                params: {
                  clientOwnerCode: this.$route.params.clientOwnerCode,
                  supplyCode: this.$route.params.supplyCode
                }
              },
              text: address,
              exact: true
            },
            {
              to: { name: this.$router.currentRoute.name },
              text: 'Autolettura'
            }
          ]
        }
      }
      return res
    }
  },
  methods: {
    ...mapActions('supply', ['getDetail', 'selfReading']),
    ...mapActions('account', [
      'getContractsForClient',
      'getNotDigitalContracts'
    ]),
    ...mapMutations('alerts', ['resetState']),
    sendReadings(readings) {
      const readingValuesValid = () => {
        if (this.details.serviceType === 'Ele') {
          return (
            parseInt(this.enteredReadings.f1) >
              parseInt(this.lastReading.lettura1) &&
            parseInt(this.enteredReadings.f2) >
              parseInt(this.lastReading.lettura2) &&
            parseInt(this.enteredReadings.f3) >
              parseInt(this.lastReading.lettura3)
          )
        } else if (this.details.serviceType === 'Gas') {
          return (
            parseInt(this.enteredReadings.f1) >
            parseInt(this.lastReading.lettura1)
          )
        }
      }

      this.enteredReadings = readings

      if (!readingValuesValid() && this.status !== 'warning2') {
        this.triggerActionType = 'actionFE'
        this.manageInvalidSending()
      } else {
        this.triggerActionType = 'actionBE'
        this.selfReading({
          commodity: this.details.serviceType,
          clientOwnerCode: this.clientOwnerCode,
          supplyCode: this.supplyCode,
          readings: this.enteredReadings
        })
          .then((resp) => {
            // Check for bus logic errors: here we are handling this way because we need the details about the error returned by the service, and they cannot be sent inside the Error object
            // If all fine, go to thank you page
            this.responseAction = resp
            if (this.details.serviceType === 'Ele') {
              this.physiCall.setJourneyAction(
                consts.JOURNEY.ERRORE_AUTOLETTURA_ELE
              )
            } else {
              this.physiCall.setJourneyAction(
                consts.JOURNEY.ERRORE_AUTOLETTURA_GAS
              )
            }
            let isPhysicallShowing = this.physiCall.checkAction()
            console.log('isPhysicallShowing', isPhysicallShowing)
            if (isPhysicallShowing) {
              this.triggerActionPhysicall()
            }
          })
          .catch((error) => {
            console.error(error)
            router.push({
              name: 'selfReadingLight_Thankyou',
              params: { from: 'base', status: 'ko' }
            })
          })
      }
    },
    manageInvalidSending() {
      switch (this.status) {
        case 'ok':
          this.status = 'warning1'
          router.push({ name: 'selfReadingLight_Warning1' })
          break
        case 'warning1':
          this.status = 'warning2'
          if (this.details.serviceType === 'Ele') {
            this.physiCall.setJourneyAction(
              consts.JOURNEY.ERRORE_AUTOLETTURA_ELE
            )
          } else {
            this.physiCall.setJourneyAction(
              consts.JOURNEY.ERRORE_AUTOLETTURA_GAS
            )
          }
          let isPhysicallShowing = this.physiCall.checkAction()
          console.log('isPhysicallShowing', isPhysicallShowing)
          if (isPhysicallShowing) {
            this.triggerActionPhysicall()
          }
          break
      }
    },
    triggerActionPhysicall() {
      if (this.triggerActionType === 'actionFE') {
        router.push({ name: 'selfReadingLight_Warning2' })
      } else {
        let resp = this.responseAction
        if (resp.errorCode && resp.errorCode === 'WRONG_VALUES_CALLCENTER') {
          router.push({
            name: 'selfReadingLight_Contactme',
            params: resp.raw
          })
        } else if (resp.errorCode && resp.errorCode === 'GENERIC') {
          router.push({
            name: 'selfReadingLight_Thankyou',
            params: { from: 'base', status: 'ko' }
          })
        } else if (resp.status >= 400) {
          router.push({
            name: 'selfReadingLight_Thankyou',
            params: { from: 'base', status: 'ko' }
          })
        } else {
          // All fine
          this.resetState()

          router.push({
            name: 'selfReadingLight_Thankyou',
            params: { from: 'base', status: 'ok' }
          })
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === 'selfReadingLight_Base') {
      this.physiCall.clearTimer()
    } else {
      this.physiCall.restartTimerSection()
    }
    next()
  },
  created() {
    this.physiCall = new PhysiCall(120000, null, null)
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    this.physiCall.clearTimer()
  },
  beforeMount() {
    this.clientOwnerCode = this.$route.params.clientOwnerCode
    this.supplyCode = this.$route.params.supplyCode

    this.getDetail({
      clientOwnerCode: this.clientOwnerCode,
      supplyCode: this.supplyCode
    })
      .then((details) => {
        this.details = details
        if (this.details.serviceType === 'Ele') {
          this.physiCall.setJourneySection(
            consts.JOURNEY.TIMER_AUTOLETTURA_LUCE
          )
        } else {
          this.physiCall.setJourneySection(consts.JOURNEY.TIMER_AUTOLETTURA_GAS)
        }
        this.physiCall
        this.lastReading =
          this.details.selfReading && this.details.selfReading.ultimalettura
        this.isAcea = this.details.pod.startsWith('IT002')
        this.getContractsForClient({
          clientOwnerCode: this.$route.params.clientOwnerCode
        }).then((digitalContracts) => {
          this.getNotDigitalContracts({
            clientOwnerCode: this.$route.params.clientOwnerCode
          }).then((noDigitalContracts) => {
            digitalContracts.contractDocumentsList.forEach((contr) => {
              if (
                contr.cdPunto === details.supplyCode &&
                contr.cdPropostaContratto
              )
                this.cdPropostaContratto = contr.cdPropostaContratto
              if (
                contr.cdPunto === details.supplyCode &&
                contr.cdPropostaContrattoFibra
              )
                this.cdPropostaContrattoFibra = contr.cdPropostaContrattoFibra
            })
            noDigitalContracts.contractDocumentsList.forEach((contr) => {
              if (
                contr.cdPunto === details.supplyCode &&
                contr.cdPropostaContratto
              )
                this.cdPropostaContratto = contr.cdPropostaContratto
              if (
                contr.cdPunto === details.supplyCode &&
                contr.cdPropostaContrattoFibra
              )
                this.cdPropostaContrattoFibra = contr.cdPropostaContrattoFibra
            })
            this.cdPropostaContratto = this.cdPropostaContratto || '-'
            this.cdPropostaContrattoFibra = this.cdPropostaContrattoFibra || '-'
          })
          this.loaded = true
        })
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  }
}
</script>

<style lang="scss" scoped>
.self-reading::v-deep {
  .self-reading {
    &__title {
      font-size: rem-calc(42);
      font-weight: bold;
    }
    &__subtitle {
      font-size: rem-calc(24);
      font-weight: bold;
    }
    &__question {
      font-size: 20px;
      font-weight: bold;
      color: $srg-blue;
      cursor: pointer;
    }
  }
  .last-reading {
    background-color: $srg-lightergray;
    padding-top: 32px;
    padding-bottom: 32px;
    &__source,
    &__text {
      font-size: $title-font-size--small;
    }
    &__value {
      font-size: $title-font-size;
    }
  }

  .color-srg-blue {
    color: $srg-blue;
  }
}
</style>
