<template>
  <div class="identityDocEdit">
    <h3 class="mb-6">Modifica i dati</h3>
    <v-select
      :items="nationalityList"
      label="Nazionalità"
      v-model.trim="identityData.nationality"
      @blur="v$.identityData.nationality.$touch()"
      :error-messages="errorNationIC"
    ></v-select>
    <v-text-field
      :label="
        identityData.type === 'card'
          ? 'Numero Carta d\'identità'
          : 'Numero Passaporto'
      "
      class="auth__input"
      v-model.trim="identityData.number"
      @blur="v$.identityData.number.$touch()"
      :error-messages="errorNumberDoc"
    ></v-text-field>
    <v-menu
      v-model="fromDateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          label="Scadenza"
          readonly
          :value="$filters.formatDateIntl(identityData.deadline)"
          @blur="v$.identityData.deadline.$touch()"
          :error-messages="errorDealine"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        locale="en-in"
        v-model.trim="identityData.deadline"
        no-title
        @input="fromDateMenu = false"
      ></v-date-picker>
    </v-menu>
    <v-text-field
      :label="identityData.type === 'card' ? 'Rilasciata da' : 'Rilasciato da'"
      class="auth__input mb-6"
      v-model.trim="identityData.releasedBy"
      @blur="v$.identityData.releasedBy.$touch()"
      :error-messages="errorReleasedBy"
    ></v-text-field>
    <div
      :class="`btn-custom ${v$.identityData.$invalid ? 'disabled' : ''}`"
      @click="editIdentityDoc"
    >
      Modifica
    </div>
  </div>
</template>
<script>
import GenErr from '@/js/genericErrors'
import { validateIdentityDocument } from '@/js/utils'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'identityDocumentEdit',
  data() {
    return {
      identityData: {
        type: '',
        nationality: '',
        number: '',
        deadline: null,
        releasedBy: ''
      },
      fromDateMenu: false,
      nationalityList: [],
      docType: ''
    }
  },
  beforeMount() {
    this.getNationality()
      .then((resp) => {
        this.nationalityList = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.identityData = this.identityDocument
  },
  computed: {
    ...mapGetters('upsellingFiber', ['identityDocument']),
    errorNationIC() {
      let errArray = []

      if (!this.v$.identityData.nationality.$dirty) return errArray

      this.v$.identityData.nationality.required.$invalid === true &&
        errArray.push('Seleziona nazionalità.')
      if (this.v$.identityData.nationality.$model === 'Italia') {
        this.v$.identityData.nationality.$touch()
      }

      return errArray
    },
    errorNumberDoc() {
      let errArray = []

      if (!this.v$.identityData.number.$dirty) return errArray

      this.v$.identityData.number.required.$invalid === true &&
        (this.identityData.type === 'card'
          ? errArray.push('Inserisci un numero di carta d’identità valido.')
          : errArray.push('Inserisci un numero di passaporto valido.'))
      this.v$.identityData.number.validateIdentityDocument === false &&
        (this.identityData.type === 'card'
          ? errArray.push('Inserisci un numero di carta d’identità valido.')
          : errArray.push('Inserisci un numero di passaporto valido.'))

      return errArray
    },
    errorDealine() {
      let errArray = []
      if (!this.v$.identityData.deadline.$dirty) return errArray

      this.v$.identityData.deadline.required.$invalid === true &&
        errArray.push('Seleziona scadenza.')
      return errArray
    },
    errorReleasedBy() {
      let errArray = []
      if (!this.v$.identityData.releasedBy.$dirty) return errArray

      this.v$.identityData.releasedBy.required.$invalid === true &&
        errArray.push('Inserisci il luogo di rilascio del documento.')
      return errArray
    }
  },
  methods: {
    ...mapActions('fiber', ['getNationality']),
    ...mapMutations('upsellingFiber', ['setIdentityDocument']),
    ...mapActions('analytics', ['trackTapEvent']),
    resetDataDoc() {
      this.identityData = {
        type: '',
        nationality: '',
        number: '',
        deadline: null,
        releasedBy: ''
      }
    },
    editIdentityDoc() {
      if (!this.v$.identityData.$invalid) {
        this.trackTapEvent({
          name: 'upsellingv2_identitydocument_edit',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
        this.setIdentityDocument(this.identityData)
        this.$emit('openAccordion', 'registry')
      }
    }
  },
  validations: {
    identityData: {
      number: { required, validateIdentityDocument },
      nationality: { required },
      deadline: {},
      releasedBy: {}
    }
  }
}
</script>
<style lang="scss" scoped>
.identityDocEdit {
  padding-top: 20px;
  h3 {
    font-weight: 500;
  }
  .btn-custom {
    width: 242px;
    text-align: center;
    padding: 18px;
    color: black;
    border: 1px solid #ccd0e1;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
