import Vue from 'vue'
import Vuex from 'vuex'
import session from './modules/session'
import account from './modules/account'
import selfRegistration from './modules/selfRegistration'
import supply from './modules/supply'
import bill from './modules/bill'
import documents from './modules/documents'
import offers from './modules/offers'
import interactions from './modules/interactions'
import tracking from './modules/tracking'
import support from './modules/support'
import upsellingFiber from './modules/upsellingFiber'
import upsellingComm from './modules/upsellingComm'
import egon from './modules/egon'
import fiber from './modules/fiber'
import payment from './modules/payment'
import MGM from './modules/MGM'
import notice from './modules/notice'
import loyalty from './modules/loyalty'
import cfp from './modules/cfp'
import analytics from './modules/analytics'
import feedback from './modules/feedback'
import welcomekit from './modules/welcomekit'
import myshop from './modules/myshop'
import contextualHelp from './modules/contextualHelp'
import alerts from './modules/alerts'
import profile from './modules/profile'
import checkup from './modules/checkup'
import checkupAnalytics from './modules/checkupAnalytics'
import fotovoltaico from './modules/fotovoltaico'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    account,
    selfRegistration,
    supply,
    bill,
    documents,
    offers,
    interactions,
    support,
    egon,
    upsellingFiber,
    upsellingComm,
    fiber,
    payment,
    MGM,
    tracking,
    notice,
    loyalty,
    cfp,
    analytics,
    feedback,
    welcomekit,
    myshop,
    contextualHelp,
    alerts,
    profile,
    checkup,
    checkupAnalytics,
    fotovoltaico
  }
})
