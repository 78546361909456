import { render, staticRenderFns } from "./GiftsMainPage.vue?vue&type=template&id=baa266b6&scoped=true"
import script from "./GiftsMainPage.vue?vue&type=script&lang=js"
export * from "./GiftsMainPage.vue?vue&type=script&lang=js"
import style0 from "./GiftsMainPage.vue?vue&type=style&index=0&id=baa266b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa266b6",
  null
  
)

export default component.exports