import { AlertService } from './service/alertService'

export default {
  UNKNOWN_ERROR:
    'Si è verificato un errore imprevisto. Ti chiediamo di riprovare tra qualche minuto.',
  SERVER_ERROR:
    'Si è verificato un errore imprevisto. Ti chiediamo di riprovare tra qualche minuto.',
  MAX_RETRIES:
    'Impossibile contattare il server: la sessione verrà interrotta.\nRiprova facendo un nuovo login.',
  handleGenericError(errorType) {
    console.error(`SRG-ERR: ${errorType}`)
    // alert(this[errorType] || this['UNKNOWN_ERROR'])
  },
  showErrorMessage(
    message = 'Si è verificato un errore imprevisto. Ti chiediamo di riprovare tra qualche minuto.'
  ) {
    // alert(message)
    AlertService('Qualcosa è andato storto', message)
  }
}
