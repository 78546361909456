<template>
  <div class="successUpload">
    <div class="successUpload__header">
      <div class="successUpload__title">Digitale al servizio delle persone</div>
    </div>
    <div class="successUpload__content std-side-padding topRadius pt-10">
      <h2 class="mb-4">Richiesta inviata!</h2>
      <h3 class="mb-4">Sarà nostra cura contattarti al più presto</h3>
      <div class="desc mb-10">
        Un nostro operatore ti contatterà il prima possibile per continuare
        l’attivazione della Fibra con il suo supporto telefonico.
      </div>
      <v-btn
        color="primary"
        x-large
        width="262"
        class="v-btn--shadowed"
        @click="
          trackTapEvent({
            name: 'upsellingv2_thankyoupagecallmeback_back',
            params: {
              client_selected:
                $store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          }),
            $router.push({ name: 'home' })
        "
        >Torna all'homepage</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'successUpload',
  beforeMount() {
    this.trackTapEvent({
      name: 'upsellingv2_thankyoupagecallmeback_open',
      params: {
        client_selected:
          this.$store.getters['upsellingFiber/addressCovered']
            .clientOwnerCode || ''
      }
    })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>
<style lang="scss" scoped>
.successUpload {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  &__header {
    width: 100%;
    height: 400px;
    background-image: url('../../../assets/img-promo-fibra-2022.png');
    background-position: center;
    background-size: cover;
  }
  &__title {
    font-size: 40px;
    font-family: 'Roboto';
    width: 85%;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding-top: 7%;
    padding-left: 51%;
    line-height: normal;
  }
  &__content {
    margin-top: -30px !important;
    background: white;
  }
  h2,
  h3 {
    font-weight: 500;
  }
  .desc {
    color: black;
    max-width: 653px;
    font-size: 20px;
    line-height: 1.3;
  }
  .v-btn {
    letter-spacing: normal;
  }
}
</style>
