<template>
  <layout>
    <div class="join-team">
      <v-row class="mb-12">
        <v-col cols="7" align-self="center">
          <h1 class="mb-8" v-html="title"></h1>
          <h3>
            Adesso che sei parte della community colora l’immagine e scopri se
            hai vinto il premio di benvenuto.
          </h3>
          <div
            class="skip"
            @click="
              trackTapEvent({
                name: 'greeners_welcomeIWSkip_tap',
                params: {
                  clientCode: mainClientLoyaltySelected.clientOwnerCode,
                  clientType: mainClientLoyaltySelected.clientOwnerType
                }
              }),
                $router.back()
            "
          >
            Lo faccio dopo
          </div>
        </v-col>
        <v-col cols="5">
          <div class="cows">
            <scratch-card
              :key="renderCount"
              :cardWidth="490"
              :cardHeight="490"
              :finishPercent="53"
              :imageUrl="require('@/assets/greeners/instantwin-nocolor.svg')"
              :brushUrl="brushUrl"
              :forceReveal="colorAll"
              :onComplete="getLotteryResult"
              class="cows__img"
            >
              <v-img
                height="490"
                width="490"
                src="@/assets/greeners/instantwincolor.svg"
              />
            </scratch-card>
            <v-btn
              class="btn-colorAll primary"
              height="54"
              :width="222"
              @click="colorAll = true"
            >
              Colora immagine
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      persistent
      overlay-opacity="0.6"
      v-model="instantWinGame"
      :max-width="dialogWindowWidth"
    >
      <v-card class="instantWinGame--dialog" :style="dialogWindowHeight">
        <v-row v-if="value === 0">
          <v-col align="center" class="iw">
            <img
              width="73"
              class="instantWinGame--status"
              :src="require('@/assets/greeners/svg/ic_sad_red.svg')"
            />
            <h1 class="mb-3">Purtroppo non hai vinto!</h1>
            <h2 class="mb-6">Non temere: molte altre occasioni ti aspettano</h2>
            <v-btn
              class="v-btn--shadowed normal"
              color="brightGreen"
              x-large
              :width="360"
              @click="
                $router.back(),
                  trackTapEvent({
                    name: 'greeners_welcomeIWClose_tap',
                    params: {
                      clientCode: mainClientLoyaltySelected.clientOwnerCode,
                      clientType: mainClientLoyaltySelected.clientOwnerType
                    }
                  })
              "
            >
              Esplora Greeners
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="value === 1">
          <v-spacer />
          <v-col cols="5" class="iw">
            <img
              width="90"
              height="90"
              class="instantWinGame--status"
              :src="require('@/assets/ic_smile_green.png')"
            />
            <h1 class="mb-6">
              Complimenti<br />
              hai vinto!
            </h1>
            <h2 v-html="wonText" />
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-img :src="wonGift" />
          </v-col>
          <v-row class="mb-2 mt-10">
            <v-spacer />
            <v-col cols="5">
              <v-btn
                class="v-btn--shadowed normal"
                color="white"
                x-large
                width="265"
                @click="
                  $router.back(),
                    trackTapEvent({
                      name: 'greeners_welcomeIWSkipReq_tap',
                      params: {
                        clientCode: mainClientLoyaltySelected.clientOwnerCode,
                        clientType: mainClientLoyaltySelected.clientOwnerType
                      }
                    })
                "
              >
                Lo richiedo dopo
              </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn
                align="center"
                color="brightGreen"
                class="v-btn--shadowed btn normal"
                x-large
                :width="265"
                @click="
                  $router.push({ name: 'shippingPage' }),
                    trackTapEvent({
                      name: 'greeners_welcomeIWReqPrize_tap',
                      params: {
                        clientCode: mainClientLoyaltySelected.clientOwnerCode,
                        clientType: mainClientLoyaltySelected.clientOwnerType
                      }
                    })
                "
              >
                Si, voglio il premio
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-if="value === 3" class="pt-12 pl-9">
          <v-col cols="12">
            <h2 class="mb-4">OPS! C’è stato un errore!</h2>
            <p>Accedi al tuo Diario per poter partecipare di nuovo!</p>
          </v-col>
          <v-col cols="12" align="center" class="pt-1 ml-n4">
            <v-btn
              align="center"
              color="brightGreen"
              class="v-btn--shadowed btn normal"
              x-large
              width="265"
              @click="$router.push({ name: 'loyaltyPage' })"
            >
              Vai al Diario
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </layout>
</template>

<script>
import ScratchCard from 'vue-scratchcard'
import { mapActions, mapGetters } from 'vuex'
import BRUSH from '../../../assets/greeners/brush.png'
import Layout from './LandingLayout'

export default {
  name: 'JoinTeamPage',
  components: {
    Layout,
    ScratchCard
  },
  data: () => ({
    value: null,
    clientCode: '',
    renderCount: 0,
    brushUrl: BRUSH,
    instantWinGame: false,
    colorAll: false
  }),
  mounted() {
    this.getMainClient().then((res) => {
      if (this.mainClientLoyaltySelected) {
        res = this.mainClientLoyaltySelected
      }
      this.clientCode = res.clientOwnerCode
      this.getMemberStatus({ ext_uuid: res.clientOwnerCode }).then((resp) => {
        if (!resp.user_registered) {
          this.$router.push({ name: 'home' })
        } else if (resp.played_welcome_iw) {
          this.$router.push({ name: 'loyaltyPage' })
        }
      })
    })

    this.getCatalog({
      catalog_name: 'IW-WLif',
      codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
    })
  },
  computed: {
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    ...mapGetters('loyalty', ['isGameWon', 'catalog_iwwl', 'isWelcomeIWLater']),
    dialogWindowHeight() {
      if (this.value === 0) {
        return `height:${394}px`
      } else if (this.value === 1) {
        return `height:${612}px`
      } else if (this.value === 2) {
        return `height:${351}px`
      } else return `height:${295}px`
    },
    dialogWindowWidth() {
      if (this.value === 0 || this.value === 1) {
        return 944
      } else return 652
    },
    wonGift() {
      return this.catalog_iwwl[0].attributes.images.data[0].image_big
    },
    wonText() {
      return this.catalog_iwwl[0].attributes.description
    },
    title() {
      // return !this.$route.query.returnIW ? 'Complimenti,<br/>ora sei un Greener!' : 'Aggiungi<br/>un po’ di colore<br/>al mondo!'
      return 'Aggiungi un po’ di<br/>colore al mondo!'
    }
  },
  methods: {
    ...mapActions('loyalty', [
      'getGameResult',
      'getCatalog',
      'getMemberStatus'
    ]),
    ...mapActions('account', ['getMainClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    getLotteryResult() {
      this.getGameResult({ body: {}, id: this.clientCode })
        .then((res) => {
          this.value = this.isGameWon ? 1 : 0
          this.instantWinGame = true
        })
        .catch(() => {
          this.value = 3
          this.instantWinGame = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
// .v-dialog__content {
// margin-top: 200px;
// }
.instantWinGame {
  &--status {
    border-radius: 50%;
    margin-left: -14px;
  }
  &--dialog {
    .iw {
      margin-top: 36px;
    }
    .normal {
      letter-spacing: normal;
      font-weight: bold;
    }
    h1 {
      font-size: 40px;
      letter-spacing: 0.8px;
      font-weight: 500;
    }
    h2 {
      font-size: 32px;
      font-weight: bold;
      line-height: 44px;
      letter-spacing: 1.2;
    }
    p {
      font-size: 20px;
      color: black;
    }
  }
}
.join-team {
  width: 100%;
  padding: 0 calc((100% - 1140px) / 2) 0;
  .skip {
    font-size: 18px;
    letter-spacing: 1.2px;
    font-weight: bold;
    margin-top: 32px;
    color: $srg-blue;
    cursor: pointer;
  }
  h1 {
    font-size: 56px;
    line-height: 1.3;
    letter-spacing: 3px;
    font-weight: bold;
  }
  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.02em;
    color: black;
  }
  h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: black;
  }
  .cows {
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
    width: 460px;
    height: 460px;
    position: relative;
    &__img {
      margin-top: -20px;
      margin-left: -20px;
    }
    .btn-colorAll {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: calc(50% - 121px);
    }
  }
}
</style>
