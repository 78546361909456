<template>
  <div class="dialogContainer">
    <div class="headerModal">
      <img
        src="../../assets/icons/ic-close.svg"
        width="16"
        @click="setOpenKoDialog"
      />
    </div>
    <div class="illoImg">
      <img src="../../assets/icons/IlloNotInvied.svg" alt="" width="140" />
    </div>
    <div class="HeadingsSubtitleXL mt-5">Qualcosa è andato storto</div>
    <div class="messageDialog">
      <div class="BodyL">
        Al momento non è possibile completare l’operazione richiesta. Ti
        invitiamo a riprovare tra qualche minuto.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KoDialog',
  methods: {
    setOpenKoDialog() {
      this.$emit('setOpenKoDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialogContainer {
  text-align: center;
  padding: 40px;
  .headerModal {
    margin-bottom: 40px;
    img {
      float: right;
      cursor: pointer;
      margin-bottom: 30px;
    }
  }
  .messageDialog {
    max-width: 478px;
    margin-top: 10px;
  }
  .buttons {
    height: 124px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
