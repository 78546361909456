<template>
  <div class="goodbyePage">
    <img
      class="logoSRG mb-8"
      src="../assets/icons/my-sorgenia-logo.svg"
      width="203"
      height="56px"
    />
    <div class="goodbyeDialog">
      <img src="../assets/icons/Illo.svg" class="mb-6" />
      <div class="SubheadingXL mb-2">Richiesta inviata</div>
      <div class="BodyL mb-8">
        La tua richiesta sarà <b>elaborata entro 14 giorni lavorativi</b>. Ti
        ricordiamo che nel caso in cui ne avessi bisogno potrai contattare il
        nostro Servizio clienti.
      </div>
      <div class="containerButton">
        <v-btn
          color="white"
          x-large
          width="152"
          class="v-btn--shadowed"
          @click="toSorgenia"
          ><div class="ButtonL buttonText">Chiudi</div>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirmAccountDeleted',
  methods: {
    toSorgenia() {
      window.location.href = 'https://www.sorgenia.it/'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.logoSRG {
  margin: 0 auto;
  display: block;
}
.goodbyeDialog {
  background: white;
  border-radius: 20px !important;
  padding: 40px;
  width: 558px;
  height: 456px;
  margin: auto;
  .containerButton {
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
.goodbyeDialog img {
  margin: auto;
  display: flex;
}
.goodbyePage {
  padding: 48px 0 90px 0;
  background-image: url('~@/assets/login/bkg-login.png');
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  &::after {
    content: url('~@/assets/login/decoration-dx.svg');
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  &::before {
    content: url('~@/assets/login/decoration-sx.svg');
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
.buttonText {
  text-transform: none;
  color: $srg-blue;
}
.BodyL {
  text-align: center;
}
</style>
