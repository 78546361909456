<template>
  <div :class="{ dreamService: isMobile }">
    <v-row class="dreamService__header" v-if="isMobile">
      <v-col cols="11">
        <button
          type="button"
          class="dreamService__header-back"
          @click="$router.push({ name: 'publicDreamServiceDone' })"
        >
          <v-icon color="white" size="30">mdi-chevron-left</v-icon>
        </button>
      </v-col>
      <v-col cols="1" class="flex-grow-0 pl-0">
        <v-img
          @click="$router.push({ name: 'publicDreamServiceDone' })"
          class="close"
          contain
          width="16"
          src="@assets/welcome/ic-close-white-big.png"
        />
      </v-col>
    </v-row>
    <div
      :class="`std-side-padding pb-10 ${
        isMobile ? 'dreamService__content-wrap' : ''
      }`"
      v-if="loaded"
    >
      <v-row v-if="!isSmallDevice">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row no-gutters class="align-center">
        <div class="col-xs-12 col-md-8">
          <h1 class="support__title font-weight-bold">Avanzamento Richieste</h1>
        </div>
        <v-col v-if="!isSmallDevice" cols="2" class="filter">
          <span>Filtra per</span>
        </v-col>
        <div class="btn-filter">
          <div class="filter-command pl-5">
            <div
              @click="filter === 'Ele' ? (filter = '') : (filter = 'Ele')"
              :class="{
                'filter-item-active': filter === 'Ele',
                'filter-item': true
              }"
            >
              <img
                v-if="filter !== 'Ele'"
                :src="require('@assets/icons/ic-light-blu.svg')"
                height="48"
                width="48"
              />
              <img
                v-if="filter === 'Ele'"
                :src="require('@assets/icons/ic-light-white.svg')"
                height="48"
                width="48"
              />
            </div>
          </div>
          <div class="filter-command pl-0 pr-0">
            <div
              @click="filter === 'Gas' ? (filter = '') : (filter = 'Gas')"
              :class="{
                'filter-item-active': filter === 'Gas',
                'filter-item': true
              }"
            >
              <img
                v-if="filter !== 'Gas'"
                :src="require('@assets/icons/ic-fire-blu.svg')"
                height="48"
                width="48"
              />
              <img
                v-if="filter === 'Gas'"
                :src="require('@assets/icons/ic-fire-white.svg')"
                height="48"
                width="48"
              />
            </div>
          </div>
        </div>
      </v-row>
      <!-- Da sistemare in caso di nessuna richiesta -->
      <v-row>
        <v-col
          :cols="isSmallDevice ? 12 : 6"
          v-for="(req, i) in requests"
          :key="i"
          class="relativeBox"
        >
          <div
            :class="`support__activeRequestBox ${
              req.chiuso === 'N' ? 'active' : 'closed'
            }`"
          >
            <div class="pa-3">
              <v-row>
                <v-col cols="12 pb-0">
                  <span
                    class="support__activeRequestBox-status pb-0"
                    v-if="req.chiuso === 'Y'"
                    >Chiuso</span
                  >
                  <span
                    class="support__activeRequestBox-status-active pb-0"
                    v-else
                    >In Lavorazione / Aggiornato il {{ req.lastUpdate }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11 pt-0 pb-0">
                  <div class="support__activeRequestBox-title pt-0">
                    {{ req.categoria }}
                  </div>
                </v-col>
                <v-col cols="1 pt-0 pb-0">
                  <img
                    class="mx-n5"
                    v-if="req.servizio === 'Ele'"
                    :src="require('@assets/icons/ic-light-blu.svg')"
                    height="32"
                    width="32"
                  />
                  <img
                    class="mx-n5"
                    v-if="req.servizio === 'Gas'"
                    :src="require('@assets/icons/ic-fire-blu.svg')"
                    height="32"
                    width="32"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12 pt-0">
                  <span class="address">{{ req.indirizzo }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto" class="limitedTo11Cols">
                  <span class="client">{{
                    currentClient.clientOwnerType === 'RES'
                      ? currentClient.firstName + ' ' + currentClient.lastName
                      : currentClient.businessName
                  }}</span>
                </v-col>
                <v-col cols="1">
                  <img
                    class="flex-grow-0 ml-0 mb-1"
                    :src="
                      currentClient.clientOwnerType === 'RES'
                        ? require('../../assets/ic_owner-res.png')
                        : require('../../assets/ic_owner-business.png')
                    "
                    width="20"
                    height="20"
                  />
                </v-col>
              </v-row>
              <v-col cols="12 pt-0 pb-0">
                <span class="clientData"
                  >codice cliente: {{ currentClient.clientOwnerCode }}</span
                >
              </v-col>
              <v-row class="mb-md-5">
                <div class="pt-0 pb-0 col-8 col-xs-12">
                  <span class="clientData"
                    >data richiesta: {{ req.requestDate }}</span
                  >
                </div>
                <v-col
                  class="pt-0 pb-0 col-4 unreadMsg"
                  v-if="req.msgToRead > 0 && !isMobile"
                >
                  <span
                    >Messaggi non letti
                    <span class="notify">{{ req.msgToRead }}</span></span
                  >
                </v-col>
              </v-row>
              <v-row v-if="req.msgToRead > 0 && isMobile" class="mb-4">
                <v-col cols="12" class="pt-0 mt-2 pb-0 unreadMsg">
                  <span
                    >Messaggi non letti
                    <span class="notify">{{ req.msgToRead }}</span></span
                  >
                </v-col>
              </v-row>
            </div>
            <div class="">
              <v-row class="bottomText" justify="center">
                <h4
                  :class="`support__activeRequestBox-btn ${
                    req.chiuso === 'N' ? 'btn-active' : 'btn-closed'
                  }`"
                  @click="openDreamServiceDetail(req.idNotifica)"
                >
                  Visualizza avanzamento
                </h4>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { checkMobile, dynamicSort } from '@/js/utils.js'
import router from '@/router/router'

export default {
  name: 'DreamServiceList',
  data() {
    return {
      isSmallDevice: window.innerWidth <= 480,
      loaded: false,
      isMobile: checkMobile(),
      isTablet: window.innerWidth > 480 && window.innerWidth <= 1024,
      filter: ''
    }
  },
  computed: {
    ...mapGetters('account', ['accountClients']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        {
          to: {
            name: 'Statuses',
            params: { clientCode: this.$route.params.clientCode }
          },
          text: 'Stato richieste',
          exact: true
        },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Avanzamento richieste'
        }
      ]

      return res
    },
    currentClientCode() {
      return this.$route.params.clientCode
    },
    currentClient() {
      let clientCode = this.currentClientCode
      if (clientCode) {
        return this.clients[clientCode]
      } else {
        return null
      }
    },
    hasDreamService() {
      let clientCode = this.currentClientCode
      return this.clients[clientCode].hasDreamService
    },
    clientNotifications() {
      let clientCode = this.currentClientCode
      if (clientCode) {
        return this.clients[clientCode].notifications
      } else return 0
    },
    requests() {
      let clientCode = this.currentClientCode
      if (clientCode && this.clients[clientCode].requests) {
        this.clients[clientCode].requests?.forEach((item) => {
          let mostUpdateMessage = Math.max(
            ...item.listaMessaggi.map((msg) => Number(msg.idMessaggio))
          )
          let mostOldMessage = Math.min(
            ...item.listaMessaggi.map((msg) => Number(msg.idMessaggio))
          )
          item.lastUpdate = item.listaMessaggi.filter(
            (msg) => msg.idMessaggio === mostUpdateMessage
          )[0].data
          item.requestDate = item.listaMessaggi.filter(
            (msg) => msg.idMessaggio === mostOldMessage
          )[0].data
          item.msgToRead = item.listaMessaggi.filter(
            (msg) => msg.letto === false
          ).length
        })
        return this.clients[clientCode].requests
          .filter((req) => this.filter === '' || req.servizio === this.filter)
          .sort(dynamicSort('chiuso'))
      } else return []
    },
    clients() {
      return this.accountClients
    }
  },
  methods: {
    ...mapActions('account', ['getClientsForAccount']),
    ...mapActions('analytics', ['trackTapEvent']),
    openDreamServiceDetail(selectedRequestId) {
      this.trackTapEvent({
        name: 'dreamService_seeProgress_tap',
        params: {
          id: selectedRequestId
        },
        toNative: this.isMobile
      })
      let destination = this.isMobile
        ? 'publicDreamServiceDetail'
        : 'DreamServiceDetail'
      this.$router.push({
        name: destination,
        params: {
          clientCode: this.currentClientCode,
          requestId: selectedRequestId
        }
      })
    },
    onBackClick() {
      this.$router.go(-1)
    }
  },
  beforeMount() {
    if (this.isMobile) {
      this.$store.commit('session/setExternalAuth', {
        token: router.currentRoute.query.token,
        username: router.currentRoute.query.username
      })
    }

    this.getClientsForAccount().then((res) => {
      this.loaded = true
    })
  }
}
</script>

<style scoped lang="scss">
.filter {
  text-align: right;
  font-size: 16px;
  font-weight: 500;
}

.filter-command {
  text-align: center;
}

.filter-command div {
  cursor: pointer;
  vertical-align: middle;
}

.filter-command img {
  margin-top: 5px;
}

.filter-item {
  width: 64px;
  height: 64px;
  border-radius: 20px;
  background-color: white;
}

.filter-item-active {
  background-color: #12256a;
}

.bottomText {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  .bottomText {
    background: #12256a;
  }
}

.limitedTo11Cols {
  max-width: calc(100% / 12 * 11);
}

.closed {
  .bottomText {
    background: white;
    border-top: 1px solid rgba(204, 208, 225, 0.5);
  }
}

.btn-active {
  color: white;
}

.btn-closed {
  color: #12256a;
}

.address {
  font-size: 16px;
  text-transform: capitalize;
}

.client {
  font-weight: 500;
}

.clientData {
  font-size: 12px;
  text-transform: uppercase;
}

.unreadMsg {
  color: #97d707;
  font-size: 12px;
}

.notify {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $srg-green;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  position: relative;
  bottom: 0px;
  left: 8px;
  padding-top: 0px;
}

.relativeBox {
  position: relative;
}

.support {
  &__activeRequestBox {
    width: 550px;
    border-radius: 40px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
    text-align: left;
    overflow: hidden;

    &-btn {
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
    }
    &-status {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      &-active {
        color: #d93273;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    &-title {
      font-size: 24px;
      font-weight: bold;
    }
    &-title::first-letter {
      text-transform: uppercase;
    }
  }
}
.btn-filter {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: auto;
}
@media (min-width: 320px) and (max-width: 480px) {
  .support {
    &__title {
      font-size: 24px !important;
      text-align: center;
    }
    &__activeRequestBox {
      width: 98%;
      height: auto;
    }
    &-btn {
      font-weight: normal;
    }
  }
  .bottomText {
    width: 100%;
    bottom: 20px;
  }

  .notify {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $srg-green;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-top: 0px;
  }
  .btn-filter {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    .filter-item {
      padding: 0;
    }
  }
}

.relativeBox {
  justify-content: center;
  display: flex;
}

.dreamService {
  background-color: $srg-blue;
  &__header {
    height: 100px;
    color: white;
    padding: 28px calc((100% - 1140px) / 2) 0;
    position: relative;
    z-index: 100;
    align-items: center;
    h1,
    .wc-tab__title {
      font-size: 60px;
      font-weight: bold;
      line-height: 70px;
      span {
        color: black;
      }
    }
    &-back {
      display: flex;
      align-items: center;
      color: white;
      font-size: 20px;
      margin-right: 12px;
      margin-bottom: 5px;
      letter-spacing: 0.4px;
      .v-icon {
        width: 15px;
        margin-right: 14px;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__content {
    &-wrap {
      position: relative;
      width: 100%;
      z-index: 101;
      background: white;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-bottom: 100px;
      padding-top: 60px;
    }
  }
}
</style>
