<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col align-self="center" cols="6">
        <h1 class="white--text">Ritira i tuoi premi</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col align-self="center" cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div v-if="loader">
      <v-progress-circular
        class="loader"
        :size="50"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="reward__content">
      <v-row>
        <v-col>
          <h2 class="HeadingL">Premio Fedeltà</h2>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col
          :key="item.id"
          class="mt-4"
          cols="3"
          v-for="(item, index) in catalogList"
        >
          <v-card
            :to="{
              name: 'giftDetailsPage',
              params: {
                catalog: 'WL-GIFT',
                id: item.attributes.prizeoption_id,
                item: item
              }
            }"
            class="gift-item"
            light
            outlined
          >
            <v-img :src="itemImages[index]" class="gift-item__img" contain />
            <h3 class="gift-item__title" v-text="item.attributes.title" />
            <!-- <p
              class="gift-item__text pl-6 pr-6"
              v-if="item.attributes.description"
              v-text="item.attributes.description"
            /> -->
          </v-card>
        </v-col>
      </v-row>
      <v-footer color="white" height="90" />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Layout from '../Layout'

export default {
  name: 'WelcomeGiftPage',
  props: {
    doubleGift: { type: Boolean, default: false }
  },
  data() {
    return {
      loader: true
    }
  },
  components: {
    layout: Layout
  },
  computed: {
    ...mapGetters('loyalty', [
      'catalogList',
      'availableAwards',
      'awardCounter'
    ]),
    ...mapGetters('account', ['mainClientLoyaltySelected']),
    itemImages() {
      return (
        this.catalogList.map((el) => {
          let res = null
          if (
            el.attributes &&
            el.attributes.images &&
            el.attributes.images.data
          ) {
            let img = el.attributes.images.data.find(
              ({ label }) => label === 'cover'
            )
            res = img
              ? img.image_small
              : el.attributes.images.data[0].image_small
          }
          return res
        }) || []
      )
    }
  },
  methods: {
    ...mapMutations('loyalty', ['setCatalog']),
    ...mapActions('loyalty', ['getCatalog'])
  },
  created() {
    this.getCatalog({
      catalog_name: 'WL-GIFT',
      codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
    }).then(() => {
      this.loader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.reward {
  &__content {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;

    p {
      font-size: 20px;
      color: black;
    }
  }
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.gift-item {
  height: 100%;
  border-radius: 44px !important;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;

  &__img {
    margin: 1px 0 0;
  }

  &__title {
    border-top: 1px solid #979797;
    font-size: 24px;
    font-weight: bold;
    padding: 16px 24px 8px;
    line-height: 1.2;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: black;
    padding: 0 24px;
  }
}
</style>
