<template>
  <div class="std-side-padding">
    <TcVars />
    <div class="serviceSection mt-10">
      <template v-if="initialLoad">
        <div class="d-flex justify-center">
          <v-progress-circular :size="100" indeterminate color="#12256A" />
        </div>
      </template>
      <template v-else>
        <div class="serviceSection__header-title mb-12">
          <template
            v-if="filterRaiseCard.length > 0 || filterOtherCard.length > 0"
          >
            <h5 class="HeadingsTitleM">
              Offerte su misura e consigli per scoprire come efficientare i
              propri consumi
            </h5>
            <h5 class="BodyL mt-2">
              Scegli uno dei tuoi profili attivi ed esplora prodotti e servizi
              su misura
            </h5>
          </template>
          <template v-else>
            <h5 class="HeadingsTitleM">
              Grazie per aver risposto al questionario Fotovoltaico!
            </h5>
            <h5 class="BodyL mt-2">
              Stiamo elaborando le tue risposte per poterti offrire consigli e
              contenuti utili per te. Nel frattempo scopri i suggerimenti per la
              tua casa e la tua auto nelle rispettive sezioni, oppure se non lo
              hai ancora fatto rispondi ai questionari per ottenere soluzioni su
              misura per te!
            </h5>
          </template>
        </div>
        <div
          class="serviceSection__list-profile mb-12"
          v-if="getAccountClients > 1"
        >
          <v-btn
            v-for="(client, i) in accountClients"
            :data-id="client.clientOwnerCode"
            :key="i"
            class="ButtonS v-btn--shadowed px-4 mr-2"
            large
            color="white"
            :class="{
              active: i === activeIndexProfile,
              res: client.clientOwnerType === 'RES',
              bus: client.clientOwnerType === 'BUS'
            }"
            @click="toggleUser(client)"
          >
            <span class="icon-profile py-4">{{
              client.firstName + ' ' + client.lastName
            }}</span></v-btn
          >
        </div>
        <div class="d-flex">
          <div
            v-if="filterRaiseCard.length > 0"
            class="questionCard questionCard__container-small-card mr-8"
          >
            <SupplyCard
              v-for="(card, i) in filterRaiseCard"
              :key="i"
              :card="card"
              :client="activeClient"
            >
            </SupplyCard>
          </div>
          <div
            class="serviceSection__carousel-card"
            v-if="filterOtherCard.length > 2"
          >
            <carousel
              :perPage="2"
              :autoplay="false"
              paginationColor="#b7bfd2"
              paginationActiveColor="#12256A"
              :paginationPadding="5"
              easing="ease-in-out"
            >
              <slide v-for="(card, i) in filterOtherCard" :key="i">
                <SupplyCard :card="card" :client="activeClient"> </SupplyCard>
              </slide>
            </carousel>
          </div>
          <div class="serviceSection__no-carousel-card" v-else>
            <SupplyCard
              v-for="(card, i) in filterOtherCard"
              :key="i"
              :card="card"
              :client="activeClient"
            >
            </SupplyCard>
          </div>
        </div>
      </template>
    </div>
    <div>
      <dynamic-component
        :component="dynComp"
        :parameters="dynPrams"
        v-if="dynComp"
      ></dynamic-component>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import SupplyCard from '../SupplyCard/SupplyCard.vue'
import { Carousel, Slide } from 'vue-carousel'
import { from } from 'rxjs'
import { map, catchError, switchMap } from 'rxjs/operators'
import { AlertService } from '../../../js/service/alertService'
import { ServiceCardType } from '../../../js/type/service_card.type'
import DynamicComponent from '../../../components/dynamicComponent.vue'
import TcVars from '@/components/tcTracker/TcVars'
import { eventBus } from '../../../main'

export default {
  name: 'serviceSection',
  props: {
    sections: { type: String }
  },
  components: {
    Carousel,
    Slide,
    SupplyCard,
    DynamicComponent,
    TcVars
  },
  data() {
    return {
      activeIndexProfile: '',
      activeClient: null,
      section: this.$route.params.type.toUpperCase(),
      initialLoad: true,
      jsonResponse: [],
      dynComp: null,
      dynPrams: null
    }
  },
  async created() {
    try {
      this.activeClient = await this.getMainClient()
      this.getClientDetail(this.activeClient)
      this.activeIndexProfile = this.activeClient.clientOwnerCode
      this.getClientCards(this.activeClient.clientOwnerCode)
    } catch (err) {
      this.makeAlert(err)
    }
    eventBus.$on('send_dynamin_component', (destination) => {
      this.openDynamicComponent(destination)
    })
  },
  beforeUnmount() {
    eventBus.$off('send_dynamin_component')
  },
  computed: {
    ...mapGetters('account', ['accountClients', 'mainClient']),
    filterOtherCard() {
      return this.jsonResponse.filter(
        (card) => !card.type.includes(ServiceCardType.RAISE)
      )
    },
    filterRaiseCard() {
      return this.jsonResponse
        .filter((card) => card.type.includes(ServiceCardType.RAISE))
        .slice(0, 2)
    },
    getAccountClients() {
      return this.accountClients ? Object.keys(this.accountClients).length : 0
    }
  },
  methods: {
    ...mapActions('profile', ['getRetrieveHomeSurvey']),
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    ...mapActions('profile', [
      'sorgeniaServices',
      'getSorgeniaServiceUserInvitation'
    ]),
    async getClientDetail(profile) {
      let clietnDetail = await this.getDetailForClient({
        clientOwnerCode: profile.clientOwnerCode
      })
      this.activeClient.email = clietnDetail.contactMail || ''
      this.activeClient.phone =
        clietnDetail.mobilePhone || clietnDetail.phone || ''
    },

    openDynamicComponent(destination) {
      this.dynComp = destination.params.component || null
      this.dynPrams = this.activeClient || null
      eventBus.$emit('dynamic_component:wakeup')
    },

    async toggleUser(profile) {
      this.initialLoad = true
      this.activeIndexProfile = profile.clientOwnerCode
      this.activeClient = profile
      this.getClientDetail(profile)
      await this.getRetrieveHomeSurvey(profile.clientOwnerCode)
      this.getClientCards(profile.clientOwnerCode)
    },
    getClientCards(indexProfile) {
      from(this.getSorgeniaServiceUserInvitation(indexProfile))
        .pipe(
          switchMap((rilanci) => {
            return from(this.sorgeniaServices()).pipe(
              map((cards) => {
                let sectionCards = cards.filter((card) =>
                  card.sections.includes(this.section.toUpperCase())
                )
                sectionCards = sectionCards.filter(
                  (card) =>
                    rilanci.find((r) => r.denominazione === card.identity)
                      .flagRilancio
                )
                sectionCards.sort((c1, c2) =>
                  parseInt(c1.sort) > parseInt(c2.sort)
                    ? 1
                    : parseInt(c1.sort) < parseInt(c2.sort)
                    ? -1
                    : 0
                )
                return sectionCards
              })
            )
          }),
          catchError(() => {
            AlertService(
              'Servizio non disponibile',
              'Al momento non è possibile connettersi al server. Ti invitiamo a riprovare.',
              false,
              'Torna ai Servizi'
            )
          })
        )
        .subscribe((result) => {
          this.initialLoad = false
          this.jsonResponse = result
        })
    },
    makeAlert() {
      AlertService(
        'Servizio non disponibile',
        'Al momento non è possibile connettersi al server. Ti invitiamo a riprovare.',
        false,
        'Torna ai Servizi'
      )
    }
  }
}
</script>

<style scoped lang="scss">
.serviceSection {
  min-height: 570px;

  &__carousel-card {
    width: 100%;
  }

  &__no-carousel-card {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .supplyCard {
      width: 100%;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__list-profile {
    .ButtonS {
      border-radius: 16px;
      border: 1px solid #dfe4ef;

      &.active {
        background-color: $neutri-neutral-30 !important;
      }
    }

    .res .icon-profile {
      &::before {
        background-image: url('../../../assets/icons/ic-owner-res-black.svg');
      }
    }

    .bus .icon-profile {
      &::before {
        background-image: url('../../../assets/icons/business_black.svg');
      }
    }

    .icon-profile {
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../../assets/profile/implant.svg');
      }
    }
  }

  .questionCard__container-small-card {
    max-width: 362px;
    width: 100%;
  }

  &__small-card {
    padding: 16px;
    gap: 16px;
    border: 1px solid $neutri-neutral-30;
    border-radius: 8px;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
