<template>
  <div class="std-side-padding" v-if="!accountClients">Loading ...</div>
  <div v-else class="std-side-padding">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="support__title mb-8">Richiedi informazioni</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="support__sub-title">Seleziona profilo</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <p class="support__title-desc">
          Per poterti aiutare al meglio, indica quale profilo ha bisogno di
          supporto.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-7">
        <ExtensibleButtonGroup
          v-model="activeContract"
          :per-row="3"
          :content="false"
          :hide-content-on-blur="false"
        >
          <ExtensibleButton
            v-for="n in accountClients"
            :key="n.clientOwnerCode"
            :name="n.clientOwnerCode"
            :label="n.businessName"
          />
        </ExtensibleButtonGroup>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="support__sub-title">Scegli tipologia utenza</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <p class="support__title-desc mb-0">
          Per quale tipologia di utenza hai bisogno di informazioni?
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-2">
        <ExtensibleButtonGroup
          v-model="activeTab"
          :per-row="3"
          :content="true"
          :hide-content-on-blur="false"
        >
          <ExtensibleButton
            v-for="n in supplies"
            :key="n.title"
            :name="n.title"
            :label="n.title"
          >
            <v-row>
              <v-col>
                <h4 class="supply__title mb-6" v-text="n.instruction" />
                <v-row align="center">
                  <v-col xs="6">
                    <p class="supply__text">Indirizzo utenza</p>
                    <v-select
                      v-model="selectAddress"
                      :items="uniqueSupplyAddress"
                      item-text="address"
                      return-object
                      placeholder="Seleziona"
                      persistent-placeholder
                      dense
                    />
                  </v-col>
                  <v-col xs="6" class="pl-0 mr-2">
                    <p class="supply__text">Categoria richiesta</p>
                    <v-select
                      :disabled="!selectAddress"
                      v-model="selectedCategory"
                      :items="categories.categories"
                      placeholder="Seleziona"
                      persistent-placeholder
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                  v-if="n.title === 'Luce' || n.title === 'Gas'"
                >
                  <v-col xs="6">
                    <p class="supply__text">Tipologia richiesta</p>
                    <v-select
                      :disabled="!selectedCategory"
                      v-model="selectType"
                      :items="categories.typology[selectedCategory]"
                      placeholder="Seleziona"
                      persistent-placeholder
                      dense
                    />
                  </v-col>
                  <v-col class="col-6 pl-0 mr-2">
                    <p class="supply__text">Messaggio</p>
                    <v-textarea
                      v-model="note"
                      rows="3"
                      outlined
                      name="message"
                      label="Scrivi quello di cui hai bisogno"
                      :error-messages="noteErrors"
                      @blur="v$.note.$touch()"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </ExtensibleButton>
          <ExtensibleButton
            v-if="
              accountProperties.hasFiberActivating ||
              accountProperties.hasFiberActive
            "
            name="Fiber"
            label="Fiber"
            :per-row="3"
            :content="false"
            :hide-content-on-blur="false"
          />
        </ExtensibleButtonGroup>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex">
        <v-btn
          @click="onSubmit"
          class="v-btn--shadowed ml-auto mb-12"
          x-large
          color="primary"
          width="260"
          :loading="sending"
          :disabled="
            !activeContract ||
            !activeTab ||
            !selectedCategory ||
            !selectType ||
            !note
          "
          >Prosegui
        </v-btn>
      </v-col>
    </v-row>
    <v-footer color="white" height="200" />
    <v-dialog
      overlay-opacity="0.6"
      v-model="isModalShown"
      persistent
      max-width="598"
    >
      <v-card class="dialogWindow pa-2">
        <div class="pr-5 pl-5">
          <v-card-title class="pt-1 mt-8 mb-4"
            ><h2>La tua richiesta è stata inviata!</h2></v-card-title
          >
          <v-card-text class="mb-3"
            ><p>
              Riceverai risposta direttamente al tuo indirizzo email.
            </p></v-card-text
          >
          <v-row justify="center">
            <v-btn
              class="v-btn--shadowed"
              x-large
              color="primary"
              @click="$router.push({ name: 'home' })"
              >Torna alla homepage</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requestCategories } from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { capitalize } from '@/js/utils'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import ExtensibleButton from '../../components/extensibleButton/ExtensibleButton'
import ExtensibleButtonGroup from '../../components/extensibleButton/ExtensibleButtonGroup'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'NewRequest',
  components: {
    ExtensibleButtonGroup: ExtensibleButtonGroup,
    ExtensibleButton: ExtensibleButton
  },
  beforeMount() {
    this.getClientsForAccount()
    this.getSupplies()
      .then((resp) => {
        this.supplyList = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  data: () => ({
    isModalShown: false,
    activeContract: undefined,
    activeTab: '',
    categories: requestCategories,
    selectedCategory: '',
    selectType: '',
    selectAddress: '',
    note: '',
    sending: false,
    clientDetail: {},
    contracts: [
      { name: 'Lucia Crespi' },
      { name: 'Gaia Sr' },
      { name: 'Edicola Crespi srl' }
    ],
    supplyList: [],
    // supplies: [
    //   { title: 'Luce' },
    //   { title: 'Gas' }
    // ],
    select: {
      category: [
        'Informazioni contratto',
        'Metodo di pagamento',
        'Informazioni fatture',
        'Supporto clienti'
      ],
      type: [
        'Attivazione',
        'Offerta commerciale sottoscritta',
        'Dati anagrafici',
        'Voltura o Subentro',
        'Chiusura o Disdetta',
        'Recesso da precedente operatore'
      ]
    }
  }),
  watch: {
    activeTab(val) {
      this.trackTapEvent({
        name: 'support_infoRequestType_tap',
        params: { supply_type: val }
      })
      if (val === 'Fiber') this.$router.push({ name: 'FiberContacts' })
    },
    activeContract(val) {
      this.getDetailForClient({ clientOwnerCode: val }).then((resp) => {
        this.clientDetail = resp
      })
    }
  },
  computed: {
    ...mapGetters('account', ['accountClients', 'accountProperties']),
    supplies() {
      let sup = []
      if (
        this.accountProperties.hasGasActive ||
        this.accountProperties.hasGasActivating
      ) {
        sup.push({ title: 'Gas' })
      }
      if (
        this.accountProperties.hasLightActive ||
        this.accountProperties.hasLightActivating
      ) {
        sup.push({ title: 'Luce' })
      }
      return sup
    },
    uniqueSupplyAddress() {
      let uniqueAddres = []
      let objCompare = {}
      this.supplyList.forEach((supply) => {
        let address = supply.street
        let type = this.activeTab === 'Luce' ? 'Ele' : 'Gas'
        if (this.activeContract && this.activeTab) {
          if (
            objCompare[address] === undefined &&
            supply.clientOwnerCode === this.activeContract &&
            type === supply.serviceType
          ) {
            const item = {
              address: capitalize(address),
              supplyCode: supply.supplyCode
            }
            uniqueAddres.push(item)
            objCompare[address] = ''
          }
        }
      })
      return uniqueAddres
    },
    noteErrors: function () {
      let errArray = []

      if (!this.v$.note.$dirty) return errArray

      this.v$.note.required.$invalid === true &&
        errArray.push('Il campo note è obbligatorio')

      return errArray
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Richiedi infomazioni'
        }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('account', [
      'getClientsForAccount',
      'getDetailForClient',
      'createRequest'
    ]),
    ...mapActions('support', ['createRequest']),
    ...mapActions('analytics', ['trackTapEvent']),
    onSubmit() {
      const body = {
        cognome: this.clientDetail.surname,
        email: this.clientDetail.contactMail,
        nome: this.clientDetail.name,
        codiceCliente: this.clientDetail.clientCode,
        login: this.$store.state.session.username,
        product: this.activeTab === 'Luce' ? 'ELE' : 'GAS',
        indirizzoDiFornitura: this.selectAddress.address,
        puntoDiFornitura: this.selectAddress.supplyCode,
        fieldsCommodity: {
          commodity: 'richiesteInformative',
          tipoCommodity: this.categories.mapping[this.selectType],
          note: this.note
        }
      }
      this.sending = true

      this.trackTapEvent({
        name: 'support_infoRequestSend_tap',
        params: {
          supply_type: this.translateSupplyType(this.activeTab),
          client_code: this.clientDetail.clientCode,
          client_type: this.clientDetail.clientType
        }
      })

      this.createRequest(body)
        .then((res) => {
          if (res.status === 'OK') {
            this.isModalShown = true
          } else {
            GenErr.showErrorMessage(
              "Si è verificato un errore nell'invio della richiesta. Si prega di riprovare."
            )
            this.sending = false
          }
        })
        .catch(() => {
          GenErr.showErrorMessage(
            "Si è verificato un errore nell'invio della richiesta. Si prega di riprovare."
          )
          this.sending = false
        })
    },
    translateSupplyType(type) {
      const translationTable = {
        luce: 'light',
        gas: 'gas',
        fiber: 'fiber'
      }

      return translationTable[type.toLowerCase()]
    }
  },
  validations: {
    note: { required }
  }
}
</script>
<style lang="scss" src="../../styles/support/_main.scss" />
<style lang="scss" scoped>
.supply {
  &__text {
    font-weight: bold;
  }
  &__title {
    font-weight: bold;
  }
}
.dialogWindow {
  height: 315px;
  h2 {
    font-size: 32px;
    font-weight: bold;
    line-height: 44px;
  }
  p {
    font-size: 20px;
    color: rgb(0, 0, 0) !important;
  }
}
</style>
