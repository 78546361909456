<template>
  <payment-template :type="type" :value="value" @input="$emit('input')">
    <div
      :class="isDialog ? 'paymentDialog' : 'paymentDialog px-0 py-0'"
      v-show="!showSiaFrame"
    >
      <img
        v-if="isDialog"
        @click="closeDialog"
        src="../../../assets/ic_chevron_top_black.png"
      />
      <div v-if="isDialog" class="paymentDialog__title">
        Seleziona nuovo metodo di pagamento
      </div>
      <div class="paymentDialog__subtitle">
        Scegli il metodo di pagamento alternativo desiderato. Ricorda che
        completando con successo il cambio del metodo di pagamento, la modifica
        verrà apportata solamente all'utenza selezionata.
      </div>
      <v-row no-gutters class="subscription__radio">
        <v-col>
          <ExtensibleButtonGroup
            v-model="newPaymentMethod"
            :per-row="2"
            groupName="newPaymentMethod"
            size="md"
          >
            <!-- @extensible-btn-group:touched="handleExtButtonTouched && handleExtButtonTouched($event)" -->
            <ExtensibleButton
              name="newBankAccount"
              label="Addebito in conto corrente"
            >
              <v-row no-gutters>
                <div class="radio-title">Dati Conto corrente</div>
              </v-row>
              <v-row no-gutters>
                <div class="radio-subtitle">
                  Compilando i campi sottostanti procederai alla registrazione
                  di un nuovo conto corrente.
                </div>
              </v-row>
              <v-row no-gutters>
                <v-col class="col-6">
                  <v-text-field
                    id="newIban"
                    label="Nuovo Iban"
                    class="auth__input"
                    v-model.trim="newIban"
                    :error-messages="newIbanErrors"
                    @blur="v$.newIban.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="clientDetails.clientType === 'RES'">
                <v-col>
                  <h5
                    @click="sameHolder = !sameHolder"
                    :class="`radio-sameHolder d-flex ${
                      sameHolder ? 'selected' : ''
                    }`"
                  >
                    L'intestatario del conto corrente è lo stesso della
                    fornitura
                  </h5>
                </v-col>
              </v-row>
              <v-row v-if="!sameHolder" class="mt-8">
                <v-col :class="isDialog ? 'col-4' : 'col-6'">
                  <h5 v-if="!isDialog">Nome intestatario nuovo Iban</h5>
                  <v-text-field
                    :label="isDialog ? 'Nome intestatario nuovo iban' : 'Nome'"
                    class="auth__input"
                    v-model.trim="newIbanName"
                    :error-messages="newIbanNameErrors"
                    @blur="v$.newIbanName.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col :class="isDialog ? 'col-4' : 'col-6'">
                  <h5 v-if="!isDialog">Cognome intestatario nuovo Iban</h5>
                  <v-text-field
                    :label="
                      isDialog ? 'Cognome intestatario nuovo iban' : 'Cognome'
                    "
                    class="auth__input"
                    v-model.trim="newIbanSurname"
                    :error-messages="newIbanSurnameErrors"
                    @blur="v$.newIbanSurname.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col :class="isDialog ? 'col-4' : 'col-6'">
                  <h5 v-if="!isDialog">
                    Codice fiscale intestatario nuovo Iban
                  </h5>
                  <v-text-field
                    label="Codice fiscale"
                    class="auth__input"
                    v-model.trim="newIbanCf"
                    :error-messages="newIbanCfErrors"
                    @blur="v$.newIbanCf.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </ExtensibleButton>
            <ExtensibleButton
              name="newCreditCard"
              label="Addebito diretto carta di credito"
            >
            </ExtensibleButton>
          </ExtensibleButtonGroup>
        </v-col>
      </v-row>
      <v-row v-if="isDialog" no-gutters class="justify-center">
        <v-btn
          color="primary"
          x-large
          class="v-btn--shadowed"
          :rounded="true"
          :disabled="!paymentMethodValid"
          @click="addPaymentMethod"
        >
          Conferma
        </v-btn>
      </v-row>
    </div>

    <div class="siaDialog" v-show="showSiaFrame">
      <iframe
        id="fiberSiaCC"
        v-if="siaUrl"
        @load="iframeUrlChanged"
        :src="siaUrl"
        frameborder="0"
      ></iframe>
    </div>
  </payment-template>
</template>
<script>
import PaymentTemplate from './PaymentMethodTeamplate'

import Consts from '@/js/constants'
import { getRequiredValidationError, validateCF } from '@/js/utils'
import { alphaNum, required } from '@vuelidate/validators'

import { mapActions, mapGetters } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: ['value', 'type'],
  name: 'paymentMethodDialog',
  data() {
    return {
      newPaymentMethod: null,
      sameHolder: true,
      newIban: '',
      newIbanName: '',
      newIbanSurname: '',
      newIbanBusiness: '',
      newIbanCf: '',
      paymentData: null,

      showSiaFrame: false,
      siaUrl: '',

      PAYMENT_METHODS: Consts.PAYMENT_METHODS
    }
  },
  mounted() {
    if (this.clientDetails.clientType === 'BUS') {
      this.sameHolder = false
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails', 'getcontactMail']),
    ...mapGetters('upsellingFiber', ['client']),
    isDialog() {
      return this.type === 'dialog'
    },
    newIbanErrors() {
      let errArray = []

      if (!this.v$.newIban.$dirty) return errArray

      this.v$.newIban.required.$invalid === true &&
        errArray.push("L'IBAN è obbligatorio")
      this.v$.newIban.alphaNum === false &&
        errArray.push('Formato IBAN non valido')
      this.v$.newIban.validateIban === false && errArray.push('IBAN errato')

      return errArray
    },
    newIbanNameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanName',
        'Il nome intestatario è obbligatorio'
      )
    },
    newIbanSurnameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanSurname',
        'Il cognome intestatario è obbligatorio'
      )
    },
    newIbanCfErrors() {
      let errArray = []

      if (!this.v$.newIbanCf.$dirty) return errArray

      this.v$.newIbanCf.required.$invalid === true &&
        errArray.push('Il codice fiscale intestatario è obbligatorio')
      this.v$.newIbanCf.validateCF === false &&
        errArray.push('Formato codice fiscale non valido')

      return errArray
    },
    paymentMethodValid() {
      if (!this.newPaymentMethod) return false

      if (this.newPaymentMethod === 'newBankAccount') {
        if (this.sameHolder) {
          return !this.v$.newIban.$invalid
        } else {
          if (this.isDialog) {
            return (
              !this.v$.newIban.$invalid &&
              !this.v$.newIbanName.$invalid &&
              !this.v$.newIbanSurname.$invalid &&
              !this.v$.newIbanCf.$invalid
            )
          } else {
            return (
              !this.v$.newIban.$invalid &&
              !this.v$.newIbanName.$invalid &&
              !this.v$.newIbanCf.$invalid
            )
          }
        }
      } else {
        return false
      }
    },
    supplyClient() {
      return this.$store.getters['upsellingFiber/client']
    }
  },
  watch: {
    newIbanCf(newVal) {
      this.newIbanCf = newVal.toUpperCase()
    },
    newIban(newVal) {
      this.newIban = newVal.toUpperCase().replace(' ', '')
    },
    paymentMethodValid(val) {
      if (val && !this.isDialog) {
        this.addPaymentMethod()
      } else if (!val && !this.isDialog) {
        this.$emit('update:data', false)
      }
    },
    newPaymentMethod(val) {
      if (val === 'newCreditCard') {
        if (this.$store.getters['upsellingFiber/client']) {
          this.getDetailForClient({
            clientOwnerCode:
              this.$store.getters['upsellingFiber/client'].clientOwnerCode
          }).then((resp) => {
            this.getSiaUrl({
              email: resp.contactMail ? resp.contactMail : this.getcontactMail
            }).then((url) => {
              this.siaUrl = url
              this.showSiaFrame = true
            })
            this.trackTapEvent({
              name: 'fiberUpsell_addNewCCR_tap',
              params: { client_code: this.client.clientOwnerCode }
            })
          })
        } else {
          this.getSiaUrl({ email: this.clientDetails.contactMail }).then(
            (url) => {
              this.siaUrl = url
              this.showSiaFrame = true
            }
          )
          this.trackTapEvent({
            name: 'fiberUpsell_addNewCCR_tap',
            params: { client_code: this.client.clientOwnerCode }
          })
        }
      } else {
        this.trackTapEvent({
          name: 'fiberUpsell_addNewSDD_tap',
          params: { client_code: this.client.clientOwnerCode }
        })
      }
    }
  },
  methods: {
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('upsellingFiber', ['getSiaUrl']),
    ...mapActions('upsellingComm', ['checkIBAN']),
    ...mapActions('analytics', ['trackTapEvent']),
    addPaymentMethod(ccParams) {
      if (this.newPaymentMethod === 'newBankAccount') {
        this.paymentData = {
          type: this.PAYMENT_METHODS.BANK_ACCOUNT,
          iban: this.newIban,
          name:
            this.newIbanName ||
            (this.supplyClient
              ? this.supplyClient.firstName
              : this.clientDetails.name),
          surname:
            this.newIbanSurname ||
            (this.supplyClient
              ? this.supplyClient.lastName
              : this.clientDetails.surname),
          fiscalCode:
            this.newIbanCf ||
            (this.supplyClient
              ? this.supplyClient.fiscalCode
              : this.clientDetails.fiscalCode)
        }

        // this.newIban = ''
        // this.newIbanName = ''
        // this.newIbanSurname = ''
        // this.newIbanCf = ''
      } else if (this.newPaymentMethod === 'newCreditCard') {
        this.paymentData = {
          type: this.PAYMENT_METHODS.CREDIT_CARD,
          fiscalCode: this.supplyClient
            ? this.supplyClient.fiscalCode
            : this.clientDetails.fiscalCode,
          ...ccParams
        }
      }
      if (this.isDialog) {
        this.v$.$reset()

        this.closeDialog()
        this.newPaymentMethod = null
      }
      this.isDialog
        ? this.$emit('paymentMethodAdded', this.paymentData)
        : this.$emit('update:data', this.paymentData)
    },
    closeDialog() {
      this.$emit('input')
    },
    iframeUrlChanged(event) {
      try {
        const loc = event.target.contentWindow.location.href
        if (loc.indexOf('sia/back.html') > -1) {
          this.siaUrl = ''
          this.showSiaFrame = false
          this.newPaymentMethod = null
        } else if (loc.indexOf('sia/done.html') > -1) {
          const queryParams = loc.substring(loc.indexOf('?') + 1).split('&')
          let queryParamsObj = {}
          queryParams.forEach((qp) => {
            const parts = qp.split('=')
            queryParamsObj[parts[0]] = parts[1]
          })

          const ccParams = {
            aliasPan: queryParamsObj.PANALIAS,
            creditCardCircuit: queryParamsObj.NETWORK,
            creditCardNumber:
              '****' + '****' + '****' + queryParamsObj.PANALIASTAIL,
            creditCardExpirationDate: queryParamsObj.PANALIASEXPDATE,
            crecurr: queryParamsObj.CRECURR,
            trecurr: queryParamsObj.TRECURR
          }
          this.addPaymentMethod(ccParams)

          this.siaUrl = ''
          this.showSiaFrame = false
        }
      } catch (error) {
        console.log(
          'we have no access to the iframe data because it is crossdomain. Error:',
          error
        )
        // do nothing. it means we have no access to the iframe data because it is crossdomain
      }
    }
  },

  validations: {
    newIban: {
      required,
      alphaNum,
      validateIban: (value, vm) => {
        return vm.checkIBAN(value)
      }
    },
    newIbanName: { required },
    newIbanSurname: { required },
    newIbanCf: { required, validateCF }
  },
  components: {
    PaymentTemplate
  }
}
</script>
<style lang="scss" scoped>
.paymentDialog {
  background: white;
  padding: 48px;
  position: relative;

  &__title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 26px;
  }

  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
}

.siaDialog {
  height: 80vh;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
