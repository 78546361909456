<template>
  <v-row no-gutters class="speed-test">
    <v-col cols="12" class="mt-2 mb-5">
      <h1 class="speed-test__title mb-0">
        Esegui il test collegandoti alla rete raggiunta dalla Fibra
      </h1>
      <div class="result__desc mt-5 mb-2">
        Lo speed test evidenzia una
        {{ connectionSpeed === 'Poor' ? 'scarsa' : 'buona' }} velocita della tua
        fiber.
      </div>
    </v-col>
    <v-col cols="12">
      <div class="result">
        <div class="result__item">
          <div class="result__item-header">
            <span class="result__item-info mb-3">Rete attuale</span>
          </div>
          <div class="result__item-info">
            <div class="result__">Download</div>
            <span v-text="result.download.speed" /> Mbps
          </div>
          <div class="result__item-info mt-3">
            <div>Upload</div>
            <span v-text="result.upload.speed" /> Mbps
          </div>
        </div>
        <v-divider vertical class="my-3" />
        <div class="result__item">
          <div class="result__item-header">
            <span class="result__item-info mb-3">Fibra Sorgenia</span>
          </div>
          <div class="result__item-info">
            <div class="result__">Download</div>
            <span>79.25 Mbps</span>
          </div>
          <div class="result__item-info mt-3">
            <div>Upload</div>
            <span>35.40 Mbps</span>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12" class="result-info">
      <h2 class="speed-test__title">Cosa offre la nostra Fibra</h2>
      <ul class="list">
        <li class="list__item">Prezzo scontato se hai già 2 utenze</li>
        <li class="list__item">
          Unica APP per monitorare le bollette delle tue utenze
        </li>
      </ul>
    </v-col>
    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        x-large
        color="primary"
        class="v-btn--shadowed"
        width="250"
        @click="$emit('nextStep')"
      >
        {{ connectionSpeed === 'Poor' ? "Vai all'offerta" : "Vai all'offerta" }}
      </v-btn>
    </v-col>
    <v-col cols="12" class="d-flex justify-center">
      <router-link
        :to="{ name: 'fiberOffering', params: { speedTestDone: true } }"
      >
        <v-btn
          x-large
          color="primary"
          class="v-btn--shadowed"
          width="360"
          @click="$emit('nextStep')"
        >
          {{
            connectionSpeed === 'Poor'
              ? 'Attiva Next Fiber e vai più veloce'
              : 'Attiva Next Fiber'
          }}
        </v-btn>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import IconDownload from '@/assets/icons/ic-download.svg' // TODO change icon take it from zeplin
// import IconWifiGood from '@/assets/icons/ic-wifi-good.svg'
import IconWifiAverage from '@/assets/icons/ic-wifi-average.svg'
import IconWifiPoor from '@/assets/icons/ic-wifi-poor.svg'

export default {
  name: 'resultComponent',
  props: {
    result: { type: Object, default: () => ({}) }
  },
  data: () => ({
    quality: {
      // min speed for quality level
      Good: {
        minSpeed: 79.25
      }
      // Average: {
      //   minSpeed: 100
      // }
    }
  }),
  computed: {
    icons: () => ({
      download: IconDownload,
      wifiGood: IconWifiAverage, // IconWifiGood,
      wifiAverage: IconWifiAverage,
      wifiPoor: IconWifiPoor
    }),
    connectionSpeed() {
      const speed = this.result.download?.speed // Math.min(this.result.download?.speed, this.result.upload?.speed)
      return speed >= this.quality.Good.minSpeed ? 'Good' : 'Poor'
    }
  }
}
</script>

<!--TODO: check layout with zeplin-->
<style lang="scss" scoped>
.result {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #f6f6f6;
  border-radius: 40px;
  padding: 30px 20px;
  margin-bottom: 38px;

  &__item {
    padding-right: 20px;
    flex: 0 0 44.66%;
    margin-bottom: 13px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 17px;
      letter-spacing: 0.7px;
      margin-bottom: 4px;

      img {
        width: 26px;
        margin-top: 2px;
      }
    }

    &--upload &-header {
      img {
        transform: rotate(180deg);
      }
    }

    &-info {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;

      span {
        font-size: 24px;
        margin-right: 4px;
      }
    }
  }

  &__desc {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.1px;
    padding-left: 2px;
  }

  &-info {
    &__title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &--poor {
    .result__item-info {
      color: $srg-error;
    }
  }
}

.icon {
  &--wifi {
    width: 64px;
    margin-right: 3px;
  }
}

.list {
  list-style-image: url('~@/assets/icons/ic-check.svg');
  padding-right: 30px;
  margin-left: 6px;
  margin-bottom: 32px;

  &__item {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.1px;
    padding-left: 18px;
    margin-bottom: 18px;

    b {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: normal;
    }
  }
}
</style>
