<template>
  <v-dialog v-model="modalOpen" max-width="558" persistent>
    <div class="completed">
      <img
        class="completed__close"
        src="../../../assets/icons/ic-close.svg"
        @click="$emit('update:dialog', false)"
      />
      <img class="mb-8" src="../../../assets/profile/feedback.svg" />
      <template v-if="status.index === 'complete-house'">
        <div class="HeadingsSubtitleXL mb-2">
          Hai completato le informazioni sulla tua casa
        </div>
        <div class="BodyXL mb-8">
          Puoi trovare le offerte più adatte nella sezione
          <router-link
            class="BottonXL-Underline"
            @click="
              trackTapEvent({
                name: 'profile2_typCustomOffers_tap',
                params: {
                  survey_type: type,
                  client_code: userProfile.clientOwnerCode
                }
              })
            "
            :to="{ name: 'serviceSection', params: { type: 'casa' } }"
            >Casa dei Servizi Sorgenia</router-link
          >.<br /><br />
          Se vuoi approfondire le soluzioni di efficienza energetica completa
          anche le domande sul fotovoltaico!
        </div>
      </template>
      <template v-if="status.index === 'onboarding-house'">
        <div class="HeadingsSubtitleXL mb-2">
          1/3<br />Hai completato le informazioni sulla tua casa
        </div>
        <div class="BodyXL mb-8">
          Continua a compilare il questionario per approfondire le soluzioni di
          efficienza energetica.<br /><br />
          Vai alla sezione
          <router-link
            class="BottonXL-Underline"
            @click="
              trackTapEvent({
                name: 'profile2_typCustomOffers_tap',
                params: {
                  survey_type: type,
                  client_code: userProfile.clientOwnerCode
                }
              })
            "
            :to="{ name: 'serviceSection', params: { type: 'casa' } }"
            >Casa dei Servizi Sorgenia</router-link
          >
          per le opportunità adatte al tuo profilo!
        </div>
      </template>
      <template v-if="status.index === 'complete-photovoltaic-0'">
        <div class="HeadingsSubtitleXL mb-2">
          Hai completato le informazioni sul fotovoltaico
        </div>
        <div class="BodyXL mb-8">
          Un nostro operatore ti contatterà per una consulenza
          personalizzata.<br /><br />
          Nel frattempo approfondisci le opportunità Sorgenia per la tua
          efficienza energetica: rispondi alle domande sulla tua casa.
        </div>
      </template>
      <template v-if="status.index === 'complete-photovoltaic-1'">
        <div class="HeadingsSubtitleXL mb-2">
          Hai completato le informazioni<br />sul fotovoltaico
        </div>
        <div class="BodyXL mb-8">
          Scopri le opportunità per migliorare la tua efficienza energetica!
        </div>
      </template>
      <template v-if="status.index === 'complete-photovoltaic-2'">
        <div class="HeadingsSubtitleXL mb-2">
          Hai completato le informazioni<br />sul fotovoltaico
        </div>
        <div class="BodyXL mb-8">
          Continua a compilare il questionario: anche se non puoi configurare un
          impianto ci sono molte opportunità per migliorare l'efficienza
          energetica della tua casa!
        </div>
      </template>
      <template v-if="status.index === 'onboarding-photovoltaic'">
        <div class="HeadingsSubtitleXL mb-2">
          2/3<br />Hai completato le informazioni su casa e fotovoltaico
        </div>
        <div class="BodyXL mb-8">
          Concludi il questionario con le informazioni sulla tua auto: il
          profilo sulle tue abitudini di consumo sarà completo e i nostri
          consigli molto più accurati.<br />
          Vai alla sezione
          <router-link
            class="BottonXL-Underline"
            @click="
              trackTapEvent({
                name: 'profile2_typCustomOffers_tap',
                params: { client_code: userProfile.clientOwnerCode }
              })
            "
            :to="{ name: 'entryPage' }"
            >Casa o Fotovoltaico dei Servizi Sorgenia</router-link
          >
          per le opportunità adatte al tuo profilo!
        </div>
      </template>
      <template v-if="status.index === 'complete-mobility'">
        <div class="HeadingsSubtitleXL mb-2">
          Hai completato le informazioni sulla tua auto
        </div>
        <div class="BodyXL mb-8">
          Scopri le opportunità per migliorare la tua efficienza energetica!
        </div>
      </template>
      <template v-if="status.index === 'onboarding-mobility'">
        <div class="HeadingsSubtitleXL mb-2">
          3/3<br />Grazie per aver completato il questionario!
        </div>
        <div class="BodyXL mb-8">
          I nostri consigli sulla gestione dei consumi e sulle opportunità di
          risparmio saranno più accurati.
        </div>
      </template>
      <template v-if="status.index === 'allComplete-0'">
        <div class="HeadingsSubtitleXL mb-2">
          Le informazioni sulla casa e sul fotovoltaico sono complete!
        </div>
        <div class="BodyXL mb-8">
          Un nostro operatore ti contatterà per una consulenza
          personalizzata.<br /><br />Nel frattempo approfondisci le opportunità
          Sorgenia per la tua efficienza energetica.
        </div>
      </template>
      <template v-if="status.index === 'allComplete-1'">
        <div class="HeadingsSubtitleXL mb-2">
          Le informazioni sulla casa e sul fotovoltaico sono complete!
        </div>
        <div class="BodyXL mb-8">
          Scopri le opportunità per migliorare la tua efficienza energetica!
        </div>
      </template>
      <!-- <template v-if="status.index === 'edit-allComplete'">
        <div class="HeadingsSubtitleXL mb-2" v-html="status.title"/>
        <div class="BottonXL-Underline mb-8" v-html="status.desc"/>
      </template> -->
      <template v-if="status.index === 'edit-house'">
        <div class="HeadingsSubtitleXL mb-2">
          Hai aggiornato le informazioni sulla tua casa!
        </div>
        <div class="BodyXL mb-8">
          Ricordati di completare o aggiornare le risposte sul fotovoltaico per
          ottenere soluzioni ancora più personalizzate!
        </div>
      </template>
      <template v-if="status.index === 'edit-photovoltaic'">
        <div class="HeadingsSubtitleXL mb-2">
          Hai aggiornato le informazioni sul fotovoltaico!
        </div>
        <div class="BodyXL mb-8">
          Ricordati di completare o aggiornare le risposte sulla tua casa per
          ottenere soluzioni ancora più personalizzate!
        </div>
      </template>
      <template v-if="status.index === 'edit-mobility'">
        <div class="HeadingsSubtitleXL mb-2">
          Hai aggiornato le informazioni sulla tua auto!
        </div>
        <div class="BodyXL mb-8">
          Scopri le opportunità per te e la tua auto!
        </div>
      </template>
      <v-btn
        @click="status.btnAction"
        class="primary v-btn--shadowed mb-4 px-12"
        height="54"
        >{{ status.btnText }}</v-btn
      >
      <v-btn @click="goBack" color="#12256a" text width="269" height="54"
        >Torna al profilo</v-btn
      >
    </div>
  </v-dialog>
</template>
<script>
import { eventBus } from '@/main'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'completedComponent',
  props: ['dialog', 'type'],
  data() {
    return {
      status: {
        id: '',
        link: '',
        btnText: '',
        btnAction: ''
      }
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.setContent()
        eventBus.$emit('statusProfile:forceUpdate')
        this.trackTapEvent({
          name: `profile2_${this.type}_survey_complete_view`,
          params: { client_code: this.userProfile.clientOwnerCode }
        })
      }
    }
  },
  computed: {
    ...mapGetters('profile', [
      'houseSelected',
      'photovoltaicSelected',
      'mobility',
      'userProfile',
      'typOnboardingHouse',
      'typOnboardingPhotovoltaic',
      'typOnboardingMobility'
    ]),
    modalOpen: {
      get() {
        return this.dialog
      },
      set(val) {
        this.$emit('update:dialog', val)
      }
    }
  },
  mounted() {
    // this.setContent()
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('profile', [
      'setTypOnboardingHouse',
      'setTypOnboardingPhotovoltaic',
      'setTypOnboardingMobility'
    ]),
    setContent() {
      if (
        this.typOnboardingHouse ||
        this.typOnboardingPhotovoltaic ||
        this.typOnboardingMobility
      ) {
        if (this.type === 'house') {
          this.setTypOnboardingHouse(false)
          this.setTypOnboardingPhotovoltaic(true)
          this.status = {
            index: 'onboarding-house',
            btnText: 'Vai alle domande sul fotovoltaico',
            btnAction: () => {
              this.trackTapEvent('profile2_typNextSurvey_tap')
              this.$emit('update:dialog', false)
              eventBus.$emit('onboarding:profile', 'photovoltaic')
              eventBus.$emit('photovoltaic:changeSurvey')
            }
          }
        } else if (this.type === 'photovoltaic') {
          this.setTypOnboardingPhotovoltaic(false)
          this.setTypOnboardingMobility(true)
          this.status = {
            index: 'onboarding-photovoltaic',
            btnText: 'Vai alle domande sulla tua auto',
            btnAction: () => {
              this.trackTapEvent('profile2_typNextSurvey_tap')
              this.$emit('update:dialog', false)
              eventBus.$emit('onboarding:profile', 'mobility')
              eventBus.$emit('mobility:changeSurvey')
            }
          }
        } else if (this.type === 'mobility') {
          this.setTypOnboardingMobility(false)
          this.status = {
            index: 'onboarding-mobility',
            btnText: 'Vai ai consigli',
            btnAction: () => {
              this.trackTapEvent({
                name: 'profile2_typCustomOffers_tap',
                params: { client_code: this.userProfile.clientOwnerCode }
              })
              this.$emit('update:dialog', false)
              this.$router.push({ name: 'entryPage' })
            }
          }
        }
      } else if (
        this.photovoltaicSelected?.completed &&
        this.houseSelected?.completed &&
        (this.type === 'house' || this.type === 'photovoltaic')
      ) {
        this.status = {
          index: 'allComplete-0',
          btnText: 'Scopri le offerte per te',
          btnAction: () => {
            this.trackTapEvent({
              name: 'profile2_typCustomOffers_tap',
              params: {
                survey_type: this.type,
                client_code: this.userProfile.clientOwnerCode
              }
            })
            this.$emit('update:dialog', false)
            this.$router.push({
              name: 'serviceSection',
              params: { type: this.type === 'house' ? 'casa' : 'fotovoltaico' }
            })
          }
        }
        if (this.photovoltaicSelected.consulenza === 'No, non mi interessa') {
          this.status.index = 'allComplete-1'
        }
        if (
          (this.photovoltaicSelected.edited && this.type === 'photovoltaic') ||
          (this.houseSelected.edited && this.type === 'house')
        ) {
          this.status.index = `edit-${this.type}`
          // this.status.title = `Hai modificato le informazioni ${this.type === 'house' ? 'sulla tua casa' : 'sul fotovoltaico'}`
          this.status.btnText = `Vai alle domande ${
            this.type === 'house' ? 'sul fotovoltaico' : 'sulla tua casa'
          }`
          this.status.btnAction = async () => {
            this.trackTapEvent('profile2_typNextSurvey_tap')
            this.$emit('update:dialog', false)
            await eventBus.$emit(
              'onboarding:profile',
              this.type === 'house' ? 'photovoltaic' : 'house'
            )
            if (this.type === 'house') {
              eventBus.$emit(
                'photovoltaic:changeSurvey',
                this.photovoltaicSelected
              )
            } else {
              eventBus.$emit('house:changeSurvey', this.houseSelected)
            }
          }
          // this.status.desc = `Ora che hai aggiornato le informazioni ${this.type === 'house' ? 'sulla tua abitazione' : 'sul fotovoltaico'} riceverai offerte su misura per te e ${this.type === 'house' ? 'la tua nuova casa' : 'per il tuo impianto'}.<br/>Ricordati di completare o aggiornare le tue risposte ${this.type === 'house' ? 'sul fotovoltaico' : 'sulla tua casa'} per permetterci di offrirti soluzioni ancora più personalizzate!`
        }
      } else if (this.type === 'house') {
        if (this.houseSelected && this.houseSelected.completed) {
          this.status = {
            index: 'complete-house',
            btnText: 'Vai alle domande sul fotovoltaico',
            btnAction: async () => {
              this.trackTapEvent('profile2_typNextSurvey_tap')
              this.$emit('update:dialog', false)
              await eventBus.$emit('onboarding:profile', 'photovoltaic')
              eventBus.$emit(
                'photovoltaic:changeSurvey',
                this.photovoltaicSelected
              )
            }
          }
        }
        if (this.houseSelected.edited) {
          this.status.index = 'edit-house'
        }
      } else if (this.type === 'photovoltaic') {
        if (this.photovoltaicSelected && this.photovoltaicSelected.completed) {
          this.status = {
            index: 'complete-photovoltaic-0',
            btnText: 'Vai alle domande sulla tua casa',
            btnAction: () => {
              this.trackTapEvent('profile2_typNextSurvey_tap')
              this.$emit('update:dialog', false)
              eventBus.$emit('onboarding:profile', 'house')
              eventBus.$emit('house:changeSurvey', this.houseSelected)
            }
          }
          if (this.photovoltaicSelected.consulenza === 'No, non mi interessa') {
            this.status.index =
              this.photovoltaicSelected.possessoImpianto ===
              'Non potrei metterlo'
                ? 'complete-photovoltaic-2'
                : 'complete-photovoltaic-1'
          }
          if (this.photovoltaicSelected.edited) {
            this.status.index = 'edit-photovoltaic'
          }
        }
      } else if (this.type === 'mobility') {
        this.status = {
          index: 'complete-mobility',
          btnText: 'Scopri di più',
          btnAction: () => {
            this.trackTapEvent({
              name: 'profile2_typCustomOffers_tap',
              params: {
                survey_type: this.type,
                client_code: this.userProfile.clientOwnerCode
              }
            })
            this.$emit('update:dialog', false)
            this.$router.push({
              name: 'serviceSection',
              params: { type: 'mobility' }
            })
          }
        }
        if (this.mobility.edited) {
          this.status.index = 'edit-mobility'
        }
      }
    },
    goBack() {
      this.$emit('update:dialog', false)
      this.trackTapEvent('profile2_typBack_tap')
    }
  }
}
</script>
<style lang="scss" scoped>
.completed {
  background: white;
  padding: 88px 40px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    text-align: center;
    a {
      text-decoration: underline;
    }
  }
  &__close {
    position: absolute;
    top: 45px;
    right: 45px;
    cursor: pointer;
  }
}
</style>
