import store from '../../store'
import Constants from '@/js/constants'

function accessGreeners() {
  let memberStatus = store.getters['loyalty/memberStatus']
  let mainClientLoyaltyRes = store.getters['account/mainClientLoyaltyRes']
  let mainClientLoyaltyBus = store.getters['account/mainClientLoyaltyBus']
  let oldestLoyaltyRes = store.getters['account/oldestLoyaltyRes']
  let oldestLoyaltyBus = store.getters['account/oldestLoyaltyBus']
  let supplies = store.getters['supply/supplies']

  if (memberStatus.user_registered) {
    const body = {
      event: '2',
      action: '21',
      detail1: 'LOYALTY'
    }
    store.dispatch('loyalty/newTransaction', body)
  }
  store.dispatch('analytics/trackTapEvent', 'home_engagementGreeners_tap')
  if (
    (mainClientLoyaltyRes || oldestLoyaltyRes) &&
    (mainClientLoyaltyBus || oldestLoyaltyBus)
  ) {
    let mainRes = mainClientLoyaltyRes || oldestLoyaltyRes
    let mainBus = mainClientLoyaltyBus || oldestLoyaltyBus
    let mainResActive =
      supplies.filter(
        (sup) =>
          sup.clientOwnerCode === mainRes.clientOwnerCode &&
          (sup.supplyState === Constants.SUPPLY_STATUS.ACTIVE ||
            sup.supplyState === Constants.SUPPLY_STATUS.ACTIVATING)
      ).length > 0
    let mainBusActive =
      supplies.filter(
        (sup) =>
          sup.clientOwnerCode === mainBus.clientOwnerCode &&
          (sup.supplyState === Constants.SUPPLY_STATUS.ACTIVE ||
            sup.supplyState === Constants.SUPPLY_STATUS.ACTIVATING)
      ).length > 0
    if (mainResActive && mainBusActive) {
      return 'multiClients'
    } else {
      if (!mainResActive) {
        store.commit('account/setMainClientLoyaltySelected', mainBus)
      }
      if (!mainBusActive) {
        store.commit('account/setMainClientLoyaltySelected', mainRes)
      }
    }
  }
  return store.dispatch('loyalty/greenerRegistration')
}

export { accessGreeners }
