<template>
  <div class="ownerSupply">
    <div class="ownerSupply__btn" @click="next(true)">
      <h5>Si, sono intestatario</h5>
    </div>
    <div class="ownerSupply__btn mb-10" @click="next(false)">
      <h5>No, non sono intestatario</h5>
    </div>
    <div class="ownerSupply__box" v-if="$route.name === 'eleOffering'">
      <div class="title">Perchè te lo chiediamo</div>

      <h5 class="desc">
        Con questo campo ti è richiesto di indicarci se il contratto ad uso
        domestico che stai sottoscrivendo è destinato alla fornitura di energia
        elettrica per l'abitazione in cui hai la residenza anagrafica. Ti
        ricordiamo che la residenza anagrafica è il luogo in cui risiedi in modo
        stabile e risultante dal registro anagrafe.
      </h5>
    </div>
    <v-dialog max-width="750" v-model="dialog">
      <v-row>
        <v-col class="dialog">
          <img
            @click="dialog = false"
            src="../../../../assets/ic_chevron_top_black.png"
          />
          <h2>Intestatario attuale utenza</h2>
          <div class="description">
            Per gestire la voltura puoi proseguire la sottoscrizione
            direttamente dal sito sorgenia.it
          </div>
          <div class="d-flex justify-center">
            <MYSButton
              width="262"
              buttonClass="primaryButton"
              buttonText="Prosegui con Sorgenia"
              @buttonClick="
                dialog = false
                toSorgenia()
              "
            />
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
export default {
  name: 'ownerSupply',
  components: {
    MYSButton
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['commodityType'])
  },
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetailRoot']),
    ...mapActions('analytics', ['trackTapEvent']),
    toSorgenia() {
      window.open(
        'https://offertacasa.sorgenia.it/offertacasa/simulazione.html',
        '_blank'
      )
    },
    next(isOwner) {
      this.setSupplyDetailRoot({ isOwner: isOwner })
      const address = this.$store.getters['upsellingComm/address']
      if (!isOwner) {
        this.trackTapEvent({
          name: 'upsellingcom_isnotowner_tap',
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: address
              ? address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
        if (this.commodityType === 'ele') {
          this.dialog = true
        } else {
          this.$emit('setFlowType', 'noOwner')
          this.$emit('goToStep', 14, 'upsellingcom_homeuse_open')
        }
      } else {
        this.$emit('nextStep', 'upsellingcom_homeuse_open')
        this.trackTapEvent({
          name: 'upsellingcom_isowner_tap',
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: address
              ? address['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ownerSupply {
  max-width: 652px;
  color: black;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 66px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
.dialog {
  background: white;
  padding: 40px;
  border-radius: 40px;
  position: relative;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .description {
    font-size: 18px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
    margin-bottom: 32px;
  }
  img {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    &.preview {
      position: initial;
    }
  }
  .v-btn {
    font-size: 16px;
    letter-spacing: normal;
    text-transform: none;
  }
}
</style>
