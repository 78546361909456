<template>
  <div class="container">
    <VueApexCharts :type="type" :options="chartOptions" :series="series" />
    <div class="center">
      <div class="text">
        <div class="header-chart">{{ formattedTotal }}</div>
        <div class="subheader-chart">{{ apexData.apexLabel }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: { VueApexCharts },
  props: {
    apexData: { type: Object, required: true }
  },
  data() {
    return {
      type: 'donut'
    }
  },
  computed: {
    series() {
      return this.apexData.series
    },
    formattedTotal() {
      let dataSlice = this.apexData.apexTotal.split(' ')
      if (dataSlice[0] === 'undefined') {
        return '0 ' + dataSlice[1]
      } else {
        return (
          parseFloat(this.apexData.apexTotal).toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) +
          ' ' +
          dataSlice[1]
        )
      }
    },
    chartOptions() {
      return {
        colors: ['#97D700', '#0F9C38', '#1197AD'],
        dataLabels: { enabled: false },
        labels: this.apexData.labels,
        legend: {
          show: false
        },
        plotOptions: {
          pie: {
            donut: {
              size: '75%'
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .header-chart {
      font-size: 20px !important;
      font-weight: 600;
      letter-spacing: 0.4px;
      line-height: 24px;
    }
    .subheader-chart {
      font-size: 12px !important;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 14.4px;
      color: #6e7689;
      text-transform: uppercase;
      width: 100%;
      max-width: 150px;
    }
  }
}
</style>
