<template>
  <!--
    USAGE Example:
      <v-btn id="informativaLogin" @click="showPdf = true">OPEN</v-btn>
      <pdf-viewer maxWidth="60%" height="600px" attachTo="informativaLogin" :showDialog="showPdf" @closing-pdfViewer="showPdf = false" url="http://www.africau.edu/images/default/sample.pdf"></pdf-viewer>
  -->

  <v-dialog
    v-model="dialogVisible"
    :max-width="maxWidth || '50%'"
    eager
    :internal-activator="true"
    :attach="attachTo"
    class="pdf-viewer"
    :style="styles || ''"
  >
    <iframe
      id="chatbot"
      ref="chatbot"
      :src="url"
      frameborder="0"
      :height="height || '500px'"
    >
    </iframe>
  </v-dialog>
</template>
<script>
import GenErr from '@/js/genericErrors'

export default {
  name: 'chatbotComponent',
  props: [
    'showDialog',
    'url',
    'clientCode',
    'role',
    'process',
    'attachTo',
    'maxWidth',
    'height',
    'styles'
  ],
  computed: {
    dialogVisible: {
      get() {
        return this.showDialog
      },
      set() {
        this.$emit('closing-chatbot')
      }
    },
    clientRole() {
      switch (this.role) {
        case '1':
          return 'main_client'
        case '2':
          return 'second_client'
        default:
          return 'undefined'
      }
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        window.addEventListener('message', this.handleMessage)
        document.getElementById('chatbot').src += '' // workaround to reload iframe
      } else {
        window.removeEventListener('message', this.handleMessage)
        this.$emit('reset-client-selected')
      }
    }
  },
  methods: {
    safeParse(str) {
      try {
        return JSON.parse(str)
      } catch {
        return str
      }
    },
    handleMessage: function (event) {
      var data =
        typeof event.data === 'string' ? this.safeParse(event.data) : event.data
      if (data.type === 'onLoad' || data.type === 'webpackOk') {
        let iframe = document.getElementById('chatbot').contentWindow
        let json = JSON.stringify({
          type: 'jwt',
          data: {
            clientCode: this.clientCode,
            role: this.clientRole,
            process: this.process,
            token: localStorage.getItem('token'),
            refresh_token: localStorage.getItem('refreshToken'),
            username: localStorage.getItem('username')
          }
        })
        iframe.postMessage(json, '*')
      } else if (data.type === 'chatClosed') {
        window.removeEventListener('message', this.handleMessage)
        this.$emit('closing-chatbot')
        this.$emit('reset-client-selected')
      } else if (data.type === 'jwtLoaded') {
        this.loaded = true
      } else if (data.type === 'error') {
        GenErr.showErrorMessage(data.data.message)
      }
    }
  }
}
</script>
<style lang="scss">
.header .v-dialog,
.header .chat-dialog {
  background: $srg-green !important;
}
.v-overlay--active {
  z-index: 201 !important;
}
.v-dialog__container {
  display: unset; // to fix Vuetify issue when dialog model start with true value (workaround, if found root cause to be removed)
}
</style>
