<template>
  <div class="mt-3">
    <v-row>
      <v-col>
        <div class="infoMessage">
          <div class="mr-3">
            <img src="../../assets/checkup-energetico/info-blue.svg" alt="" />
          </div>
          <div class="BodyL">
            L'operazione può richiedere in alcuni casi fino a 4 ore.
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'InfoParentalControl'
}
</script>

<style scoped>
.infoMessage {
  width: 751px;
  height: 76px;
  display: flex;
  flex-direction: row;
  background-color: #eaeef5;
  padding: 16px;
  border-radius: 16px;
  align-items: center;
}
</style>
