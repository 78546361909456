// import { ENV_CONFIGS } from '@/js/configs.js'
import { axiosACN } from '@/js/axiosInstances.js'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getLastCfpValue({ state, commit, rootGetters }, params) {
      return axiosACN
        .post(
          '/surveycliente/V2/retrieveSurvey',
          {
            codiceCliente: params.clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.status && res.status >= 400) {
            throw Error('SERVER_ERROR')
          }

          // Manage business logic errors (coming with status 200 OK + errorcode)
          if (res.data.errorCode || res.data.status !== 'OK') {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }

          if (res.data.survey && res.data.survey.length > 0) {
            return res.data.survey[res.data.survey.length - 1].co2Anno
          } else {
            return false
          }
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    }
  }
}
