<template>
  <div>
    <v-dialog v-model="dialog" width="555" persistent>
      <div class="checkup">
        <div class="HeadingsSubtitleL mb-10">Check-up energetico</div>
        <img
          class="checkup__close"
          @click="$emit('update:dialogCheckup', false)"
          src="@/assets/icons/ic-close.svg"
        />
        <img class="mb-8" src="@/assets/fotoCheckup.png" />
        <div class="HeadingsSubtitleXL">
          Ottieni il check-up energetico della tua casa!
        </div>
        <div class="BodyL mb-8">
          Approfondisci la situazione energetica della tua casa rispondendo alle
          domande di dettaglio per ottenere suggerimenti su possibili interventi
          e azioni che ti consentiranno di efficientare i tuoi consumi.
          Proseguendo verrai indirizzato sul sito esterno sorgenia.it. Potrai
          tornare in ogni momento all'interno dell'App MySorgenia.
        </div>
        <div class="checkup__btn">
          <v-btn
            x-large
            class="primary v-btn--shadowed mr-4"
            width="231"
            height="54"
            rounded
            target="_blank"
            @click="redirectToCheckup"
          >
            {{ cta || 'Chiudi' }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <CheckupDialog
      :showCheckup.sync="showCheckup"
      :checkupUrl="checkupUrl"
      :user="userProfile"
    />
  </div>
</template>
<script>
import { ENV_CONFIGS } from '@/js/configs'
import { eventBus } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import CheckupDialog from './CheckupDialog.vue'

export default {
  name: 'watingCheckupEnergetico',
  props: ['dialogCheckup'],
  data() {
    return {
      cta: '',
      showCheckup: false,
      checkupUrl: ''
    }
  },
  components: {
    CheckupDialog
  },
  computed: {
    ...mapGetters('session', ['token']),
    ...mapGetters('profile', ['userProfile']),
    urlCheckup() {
      return `${ENV_CONFIGS.CHECKUP_BASE_URL}?token=${this.token}`
    },
    dialog: {
      get() {
        return this.dialogCheckup
      },
      set(val) {
        this.$emit('update:dialogCheckup', val)
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    redirectToCheckup() {
      this.trackTapEvent({
        name: 'profile2_start_checkup',
        params: {
          client_code: this.userProfile.clientOwnerCode
        }
      })
      this.$emit('update:dialogCheckup', false)
      // open iframe e then rediect url
      this.showCheckup = true
      this.checkupUrl = `${ENV_CONFIGS.CHECKUP_BASE_URL}?token=${this.token}`
    }
  },
  beforeMount() {
    eventBus.$on('checkup-wating:open', (cta) => {
      this.cta = cta
      this.$emit('update:dialogCheckup', true)
    })
  }
}
</script>
<style lang="scss" scoped>
.checkup {
  background: white;
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }
}
</style>
