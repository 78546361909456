<template>
  <div class="std-side-padding myFooter">
    <div class="information">
      <div class="myFooter__title">Sorgenia S.p.A</div>
      <div class="BodyM">
        Sede legale in Milano, Via Algardi 4 | Capitale sociale Euro
        150.000.000,00 i.v. | P.IVA n.12874490159 Codice<br />
        Fiscale e Iscrizione al Registro delle Imprese di Milano Monza Brianza
        Lodi n.07756640012
      </div>
    </div>
    <div class="download">
      <div class="myFooter__title">Scarica l'app mySorgenia</div>
      <div class="myFooter__apps">
        <a
          target="_blank"
          href="https://apps.apple.com/it/app/mysorgenia/id593806212/"
        >
          <img src="../../assets/button-ios.svg" width="100" />
        </a>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=my.sorgenia"
        >
          <img src="../../assets/button-android.svg" width="100" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'myFooter'
}
</script>
<style lang="scss">
.myFooter {
  width: 100%;
  height: 130px;
  background: #dfe4ef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .information {
    width: 70%;
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 7px;
    letter-spacing: 1px;
  }
  &__text {
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 1px;
  }
  &__apps {
    display: flex;
    align-items: center;
    img:first-child {
      margin-right: 10px;
    }
  }
}
</style>
