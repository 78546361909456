<template>
  <div
    class="answer_box"
    :style="`width: calc( 100% / ${perRow} - ${margin * 2}px)`"
  >
    <div class="answer_text">
      {{ answer.label }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'feedbackRanking',
  props: {
    answer: { type: Object }
  },
  inject: ['options'],
  computed: {
    perRow() {
      return this.options.perRow
    },
    margin() {
      return this.options.margin
    }
  }
}
</script>
<style lang="scss" scoped>
.answer_box {
  border-radius: 27px;
  border: 1px solid lightgrey;
  min-height: 72px;
  margin: 10px;
  padding: 15px 15px 15px 30px;
  display: flex;
  align-items: center;
  &_shadowed {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14),
      0 10px 35px 0 rgba(219, 219, 219, 0.3);
  }
}

.ghost {
  border-color: #12256a;
  .answer_text {
    font-weight: bold;
  }
}
.no-shadow {
  box-shadow: none !important;
}
.answer_text {
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}
</style>
