<template>
  <div>
    <div class="page">
      <v-row class="std-side-padding">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <transition name="fade">
        <div v-if="!loaded">
          <v-row>
            <v-col
              ><img src="@assets/fotovoltaico/placeholder.svg" width="100%"
            /></v-col>
          </v-row>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="loaded">
          <v-row>
            <v-col class="pb-0" cols="12">
              <MonitorTitleVue
                title="Consumo della mia casa"
                :abstract="textLabelTime"
              ></MonitorTitleVue>
            </v-col>
          </v-row>
        </div>
      </transition>
    </div>
    <div class="title-section">
      <div class="page">
        <v-row>
          <v-col cols="12">
            <MonitorValueVue
              title="Consumo totale"
              :value="totalConsumption + ' kWh'"
            >
            </MonitorValueVue>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="page">
      <v-row>
        <v-col cols="12">
          <MonitorHeaderVue
            :currentOptionList="currentOptionList"
            @change-current="changeCurrent"
            @change-unit="changeUnit"
            :currentEnd="body.endDate"
            :currentStart="body.startDate"
            :installationConnectionStartDate="
              getInstallationData?.siteProfile?.installationConnectionStartDate
            "
          ></MonitorHeaderVue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MonitorChartVue
            :currentOptionList="body.granularity"
            :series="series"
            :categories="categories"
            :columnWidth="columnWidth"
            chartLabelY="consumo"
            :load="load"
          ></MonitorChartVue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MonitorControlsVue
            @change-control="changeControl"
            :controls="controls"
            :load="load"
          ></MonitorControlsVue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="autosufficienza">
            <h2>Autosufficienza</h2>
            <div class="abstract">
              Indica quanto sei in grado di renderti autonomo dalla rete
              elettrica nazionale per far fronte ai consumi della tua
              abitazione, grazie all’energia autoconsumata istantaneamente
              dall’impianto o accumulata in batteria.
            </div>
            <div class="value">
              {{ autosufficienza.toFixed(2).replace('.', ',') }} %
            </div>
            <div>
              <v-progress-linear
                :value="autosufficienza"
                color="#0F9C38"
                background-color="#EAEEF5"
                rounded
              ></v-progress-linear>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import MonitorHeaderVue from '../../../components/fotovoltaico/monitor/MonitorHeader.vue'
import MonitorTitleVue from '../../../components/fotovoltaico/monitor/MonitorTitle.vue'
import MonitorValueVue from '../../../components/fotovoltaico/monitor/MonitorValue.vue'
import MonitorChartVue from '../../../components/fotovoltaico/monitor/MonitorChart.vue'
import MonitorControlsVue from '../../../components/fotovoltaico/monitor/MonitorControls.vue'
import functionsUtilityFV from '@/js/fotovoltaico/functions'

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

const allRules = [
  'Energia autoconsumata',
  'Energia prelevata dalla rete',
  'Energia scaricata dalla batteria'
]

export default {
  components: {
    MonitorTitleVue,
    MonitorValueVue,
    MonitorHeaderVue,
    MonitorChartVue,
    MonitorControlsVue
  },

  mounted() {
    this.trackTapEvent({
      name: 'monitoring_historical_data_viewed',
      params: {
        category: 'savings'
      }
    })
    this.loadConsumption()
  },

  watch: {
    getConsumptionControl() {
      this.calcolaEnergieImpianto()
    }
  },

  data() {
    return {
      load: false,
      loaded: true,
      textLabelTime: 'Dati aggiornati ogni 15 minuti.',
      current: new Date().toISOString(),
      rules: [...allRules],
      controls: [
        {
          load: this.load,
          title: 'Energia autoconsumata',
          icon: 'icon-green-light',
          value: 0,
          percentage: 0
        },
        {
          load: this.load,
          title: 'Energia prelevata dalla rete',
          icon: 'icon-green-blue',
          value: 0,
          percentage: 0
        },
        {
          load: this.load,
          title: 'Energia scaricata dalla batteria',
          icon: 'icon-green',
          value: 0,
          percentage: 0
        }
      ],
      body: {
        acuId: '',
        siteId: '',
        granularity: 'Giorno',
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0,
          0,
          0
        ).toISOString(),
        endDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          23,
          59,
          59
        ).toISOString()
      }
    }
  },
  computed: {
    ...mapGetters('account', ['mainClient']),
    ...mapGetters('fotovoltaico', [
      'getConsumptionChart',
      'getInstallationData',
      'getConsumptionDataChart',
      'getConsumptionControl'
    ]),
    breadcrumbsItems() {
      this.setFlagEnterByDeeplink(false)

      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'dashboardFotovoltaico' }, text: 'Flussi energetici' },
        { to: { name: 'fotovoltaicoConsumo' }, text: 'Consumo' }
      ]

      return res
    },
    series: {
      get() {
        return this.getConsumptionChart?.data?.values?.series || []
      },
      set(value) {
        this.getConsumptionChart.data.values.series = value
      }
    },
    categories() {
      return this.getConsumptionChart?.data &&
        this.getConsumptionChart?.data.categories
        ? this.getConsumptionChart?.data.categories
        : []
    },
    retrieveSiteId() {
      return this.getInstallationData.siteProfile.siteId
    },
    retrieveAcuId() {
      return this.getInstallationData.acuId
    },

    autosufficienza() {
      if (
        this.getConsumptionControl &&
        this.getConsumptionControl?.selfSufficiencyPercentage
      ) {
        return this.getConsumptionControl?.selfSufficiencyPercentage[0].value
      } else return 0
    },

    currentOptionList() {
      const date = new Date(this.current)
      const dateStart = new Date(this.body.startDate)
      switch (this.body.granularity) {
        case 'Giorno':
          return functionsUtilityFV.formatDayMonth(date)
        case 'Settimana':
          const startOfWeek = functionsUtilityFV.getStartOfWeek(date)
          const endOfWeek = functionsUtilityFV.getEndOfWeek(date)

          return `${functionsUtilityFV.formatDayMonth(
            startOfWeek,
            true
          )} - ${functionsUtilityFV.formatDayMonth(endOfWeek, true)}`

        case 'Mese':
          return functionsUtilityFV.formatMonth(date)
        case 'Anno':
          return functionsUtilityFV.formatYear(date)
        case 'Dall inizio':
          return `${functionsUtilityFV.formatFromActivation(
            dateStart
          )} - ${functionsUtilityFV.formatFromActivation(new Date())}`
        default:
          return functionsUtilityFV.formatDayMonth(date)
      }
    },
    columnWidth() {
      return this.getConsumptionChart?.data &&
        this.getConsumptionChart?.data.columnWidth
        ? this.getConsumptionChart?.data.columnWidth
        : 0
    },
    totalConsumption() {
      let total =
        this.getConsumptionDataChart &&
        this.getConsumptionControl?.energyConsumption &&
        this.getConsumptionControl?.energyConsumption[0].value
          ? this.getConsumptionControl?.energyConsumption[0].value
          : 0
      return total.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
    // calcolaTotaleConsumo() {
    //   let total =
    //     this.getConsumptionChart?.data &&
    //     this.getConsumptionChart?.data.energyConsumptionSum
    //       ? this.getConsumptionChart?.data.energyConsumptionSum
    //       : 0

    //   return total.toLocaleString('it-IT', {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    //   })
    // }
  },
  methods: {
    ...mapActions('fotovoltaico', [
      'retrieveMonitoringConsumptionKPIData',
      'retrieveMonitoringConsumptionKPIControl',
      'photovoltaicInstallationDetails',
      'retrieveDashboardData'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('supply', ['getSupplies']),
    ...mapMutations('fotovoltaico', ['setFlagEnterByDeeplink']),

    calcolaEnergieImpianto() {
      this.controls[0].value =
        this.getConsumptionControl?.selfConsumption[0].value
      this.controls[0].percentage =
        this.getConsumptionControl?.selfConsumptionPercentage[0].value
      this.controls[1].value =
        this.getConsumptionControl?.energyFromNetwork[0].value
      this.controls[1].percentage =
        this.getConsumptionControl?.energyFromNetworkPercentage[0].value
      this.controls[2].value =
        this.getConsumptionControl?.energyFromBattery[0].value
      this.controls[2].percentage =
        this.getConsumptionControl?.energyFromBatteryPercentage[0].value
    },

    formatFromActivation(date) {
      const month = this.getAbbreviatedMonthName(date.getMonth())
      return `${month} ${date.getDate()}`
    },
    async changeCurrent(current, amount) {
      this.load = true
      functionsUtilityFV.updateDates(this.body.granularity, amount, this.body)
      this.current = this.body.endDate
      await this.retrieveMonitoringConsumptionKPIData(this.body)
      switch (this.body.granularity) {
        case 'Giorno':
          let bodyGran = { ...this.body, granularity: 'DAILY' }
          await this.retrieveMonitoringConsumptionKPIControl(bodyGran)
          break

        case 'Settimana':
          let bodyWeek = { ...this.body, granularity: 'WEEKLY' }
          await this.retrieveMonitoringConsumptionKPIControl(bodyWeek)
          break

        case 'Mese':
          let bodyMonth = { ...this.body, granularity: 'MONTHLY' }
          await this.retrieveMonitoringConsumptionKPIControl(bodyMonth)
          break

        case 'Anno':
          let bodyAnnual = { ...this.body, granularity: 'ANNUAL' }
          await this.retrieveMonitoringConsumptionKPIControl(bodyAnnual)
          break

        case 'Dall inizio':
          let bodyFrom = { ...this.body, granularity: 'ANNUAL' }
          await this.retrieveMonitoringConsumptionKPIControl(bodyFrom)
          break

        default:
          break
      }
      this.load = false
    },

    changeControl({ title, switchRadio }) {
      let persistantData = this.getConsumptionDataChart?.series
      let spreadJson = this.getConsumptionChart?.data?.values?.series
      if (switchRadio) {
        this.rules = [...this.rules, title]
        let seriesToAdd = persistantData.filter((el) => el.name === title)

        if (title === 'Energia autoconsumata') {
          this.series.splice(0, 0, seriesToAdd[0])
        } else if (title === 'Energia prelevata dalla rete') {
          this.series.splice(1, 0, seriesToAdd[0])
        } else {
          this.series.splice(2, 0, seriesToAdd[0])
        }
      } else {
        this.rules = this.rules.filter((curr) => curr !== title)
        this.series = spreadJson.filter((curr) => curr.name !== title)
      }
    },
    async changeUnit(unit) {
      this.load = true
      const today = new Date()
      switch (unit) {
        case 'Giorno':
          this.textLabelTime = 'Dati aggiornati ogni 15 minuti.'
          this.body.startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            23,
            59,
            59
          ).toISOString()
          let bodyDay = { ...this.body, granularity: 'DAILY' }
          this.retrieveMonitoringConsumptionKPIControl(bodyDay)
          break
        case 'Settimana':
          this.textLabelTime = 'Dati aggiornati ogni 60 minuti.'
          const dayOfWeek = today.getDay()
          const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1
          const firstDayOfWeek = new Date(today)
          firstDayOfWeek.setDate(today.getDate() - daysToSubtract)

          this.body.startDate = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate(),
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate() + 6,
            23,
            59,
            59
          ).toISOString()
          let newBody = { ...this.body, granularity: 'WEEKLY' }
          this.retrieveMonitoringConsumptionKPIControl(newBody)
          break
        case 'Mese':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0,
            23,
            59,
            59
          ).toISOString()
          let bodyMonth = { ...this.body, granularity: 'MONTHLY' }
          this.retrieveMonitoringConsumptionKPIControl(bodyMonth)
          break
        case 'Anno':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
          let bodyYear = { ...this.body, granularity: 'ANNUAL' }
          this.retrieveMonitoringConsumptionKPIControl(bodyYear)
          break
        case 'Dall inizio':
          this.textLabelTime = 'Dati aggiornati ogni 15 minuti.'
          this.body.startDate = new Date(
            today.getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
          let bodyFromActivation = {
            ...this.body,
            granularity: 'FROM_ACTIVATION'
          }
          this.retrieveMonitoringConsumptionKPIControl(bodyFromActivation)
          break
      }
      this.body.granularity = unit
      await this.retrieveMonitoringConsumptionKPIData(this.body)
      this.current = this.body.endDate
      this.load = false
    },
    async loadConsumption() {
      this.load = true
      await this.getSupplies()

      if (
        this.$route.params.acuId !== this.mainClient?.acuId ||
        !this.mainClient?.acuId
      ) {
        this.$router.push({ name: 'home' })
      } else {
        const siteId = this.$route.params.siteId
        const acuId = this.$route.params.acuId
        const granularityDaily = 'DAILY'
        const granularityFromStart = 'FROM_ACTIVATION'
        const startDateFromStart =
          this.addressSelected?.photovoltaicData
            ?.installationConnectionStartDate
        let endDate = new Date().toISOString().slice(0, 19) + 'Z'
        const today = new Date()
        const startDateDaily =
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          )
            .toISOString()
            .slice(0, 19) + 'Z'
        const body = {
          siteId,
          acuId,
          granularityDaily,
          granularityFromStart,
          startDateFromStart,
          endDate,
          startDateDaily
        }
        await this.photovoltaicInstallationDetails(body)
        await this.retrieveDashboardData(body)

        this.body.siteId = this.$route.params.siteId
        this.body.acuId = this.$route.params.acuId
        await this.retrieveMonitoringConsumptionKPIData(this.body) // questa chiamata ci serve per poter valorizzare K43, risoarmio e incentivi è costo dell'impianto
        let bodyStart = { ...this.body, granularity: 'DAILY' }
        await this.retrieveMonitoringConsumptionKPIControl(bodyStart) // questa chiamata ci serve per poter valorizzare K43, risoarmio e incentivi è costo dell'impianto
      }
      this.load = false
    }
  }
}
</script>
<style lang="scss" scoped>
.v-progress-linear {
  position: relative !important;
  top: 0px;
}
.title-section {
  margin-top: 40px;
  background-color: #eaeef5;
  padding: 8px;
}
.autosufficienza {
  padding: 32px;
  background-color: #f8f9fc;
  border-radius: 24px;
  margin-bottom: 16px;

  h2 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.5;
    line-height: 28.8px;
  }
  .abstract {
    padding: 16px 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5;
    line-height: 22.4px;
    text-align: justify;
  }
  .value {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4;
    line-height: 24px;
    float: right;
  }
}
</style>
