<template>
  <layout header-size="full">
    <template v-slot:header>
      <v-col cols="7" class="img-man pa-0" />
      <v-col class="mt-12">
        <h1 class="white--text mb-8">
          Hai richiesto<br />
          correttamente<br />
          il tuo premio<br />
        </h1>
        <h3 v-if="isEmail" class="font-weight-bold white--text mb-8">
          Il tuo premio arriverà all'indirizzo email <br />
          indicato.
        </h3>
        <h3 v-else class="font-weight-bold white--text mb-8">
          Per ricevere il tuo premio entro i<br />
          tempi previsti da Regolamento segui le<br />
          indicazioni che ti invieremo per email.<br />
        </h3>
        <v-btn
          class="v-btn--shadowed btn"
          x-large
          :width="265"
          @click="$router.push({ name: labelBtn.to })"
        >
          {{ labelBtn.label }}
        </v-btn>
      </v-col>
    </template>
  </layout>
</template>

<script>
import Layout from '../Layout'

export default {
  name: 'ShipConfirmPage',
  components: {
    layout: Layout
  },
  computed: {
    isEmail() {
      return this.$route.params.type === 'email'
    },
    labelBtn() {
      if (this.$route.params.from === 'joinTeamPage') {
        return { to: 'howItWorks', label: 'Esplora Greeners' }
      } else {
        return { to: 'loyaltyPage', label: 'Vai al Diario' }
      }
    }
  }
}
</script>

<style scoped></style>
