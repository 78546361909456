<template>
  <div>
    <v-expansion-panel-header class="d-flex align-center">
      <template v-slot:actions>
        <v-img v-if="triggerStatus.status === 'empty'" :src="IconActive" />
        <v-img v-else class="v-icon" :src="IconChevronDown" />
      </template>
      <div class="d-flex align-center">
        <div :class="`supply__icon ${type}`"></div>
        <div class="supply__text">
          <div class="type">{{ triggerStatus.nSupplies }}</div>
          <div class="state">{{ triggerStatus.title }}</div>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div
        class="d-flex justify-space-between"
        v-if="triggerStatus.status === 'noActive'"
      >
        <slot name="noActive" />
      </div>
      <div
        class="d-flex justify-space-between"
        v-else-if="triggerStatus.status === 'timeline'"
      >
        <slot name="timeline" />
      </div>
      <div class="d-flex justify-space-between" v-else>
        <slot name="active" />
      </div>
    </v-expansion-panel-content>
  </div>
</template>
<script>
import IconChevronDown from '@assets/icons/chevron.svg'
import IconActive from '@assets/icons/active.svg'
export default {
  name: 'templateAccordions',
  props: {
    type: { type: String, default: 'ele' },
    triggerStatus: { type: Object, default: () => {} }
  },
  data() {
    return {
      IconChevronDown,
      IconActive
    }
  }
}
</script>
<style lang="scss" scoped>
.supply {
  &__icon {
    background-size: contain;
    width: 32px;
    height: 32px;
    margin-right: 16px;

    &.ele {
      background-image: url('../../../../assets/icons/ic-light-blu.svg');
    }

    &.gas {
      background-image: url('../../../../assets/icons/ic-fire-blu.svg');
    }

    &.fiber {
      background-image: url('../../../../assets/icons/ic-wifi-blu.svg');
    }

    &.photovoltaic {
      background-image: url('../../../../assets/icons/ic-photovoltaic-blu.svg');
    }
  }
  .panel {
    padding: 22px 48px 21px 36px;
    border: 1px solid $neutri-neutral-30;
    border-radius: 8px !important;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0px;
    }

    &.regular,
    &.timeline {
      border-left: 6px solid $srg-blue;
    }

    &.noActive {
      border-left: 6px solid #6e7689;

      .supply__icon {
        &.ele {
          background-image: url('../../../../assets/icons/ic-light-grey.svg');
        }

        &.gas {
          background-image: url('../../../../assets/icons/ic-fire-grey.svg');
        }

        &.fiber {
          background-image: url('../../../../assets/icons/ic-wifi-grey.svg');
        }
      }

      .supply__text {
        .state {
          color: #6e7689;
        }
      }
    }

    &.irregular {
      border-left: 6px solid $srg-purple;

      .supply__icon {
        &.ele {
          background-image: url('../../../../assets/icons/ic-light-red.svg');
        }

        &.gas {
          background-image: url('../../../../assets/icons/ic-fire-red.svg');
        }

        &.fiber {
          background-image: url('../../../../assets/icons/ic-wifi-red.svg');
        }

        &.photovoltaic {
          background-image: url('../../../../assets/icons/ic-photovoltaic-red.svg');
        }
      }

      .supply__text {
        .state {
          color: $srg-purple;
        }
      }
    }
  }
  &__text {
    .type {
      color: black;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 6px;
      letter-spacing: 1.3px;
    }

    .state {
      font-size: 14px;
      font-weight: bold;
      color: $srg-blue;
      letter-spacing: 1.2px;
    }
  }
}
</style>
