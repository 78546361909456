import { axiosACN } from '@/js/axiosInstances.js'

const retrieveWalletData = (commit, rootGetters, clientOwnerCode) => {
  return axiosACN
    .post(
      '/sorgeniadataclient/V2/retrieveMgmWallet',
      { codiceCliente: clientOwnerCode },
      { headers: { Authorization: 'Bearer ' + rootGetters['session/token'] } }
    )
    .then((res) => {
      if (res.data.error) {
        // No bus logic errors expected
        commit('setWalletInError')
        throw Error('UNKNOWN_ERROR')
      }
      if (res.data.status === 'KO') {
        commit('setWalletInError')
        return { error: true }
      } else {
        return { ...res.data, success: true }
      }
    })
    .catch((err) => {
      commit('setWalletInError')
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    mgmWallet: { success: false }
  },
  getters: {
    mgmWallet(state) {
      return state.mgmWallet
    }
  },
  mutations: {
    setMgmWallet(state, mgmWallet) {
      state.mgmWallet = mgmWallet
    },
    setWalletInError(state) {
      state.mgmWallet.success = false
    }
  },
  actions: {
    getMgmWallet({ state, commit, rootGetters }, params) {
      retrieveWalletData(commit, rootGetters, params).then((res) =>
        commit('setMgmWallet', res)
      )
    },
    sendPromoCode({ rootGetters }, params) {
      return axiosACN
        .post(
          '/sorgeniadataclient/sendPromoCode',
          {
            codicePromo: params.codicePromo,
            cognome: params.cognome,
            nome: params.nome,
            commodity: params.commodity,
            email: params.email,
            login: rootGetters['session/username'],
            indirizzoDiFornitura: params.indirizzoDiFornitura,
            codiceCliente: params.codiceCliente,
            segmentoCliente: params.segmentoCliente,
            codicePunto: params.codicePunto
          },
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        .then((res) => {
          // res.data = {
          //   "status": "OK",
          //   "nRate": "2",
          //   "importo": "30",
          //   "errorCode": "003",
          //   "errorDescription":""
          // }
          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    getImportiListini({ rootGetters }, segmentoCliente) {
      return axiosACN
        .post('/sorgeniadataclient/retrieveImportiListini', segmentoCliente, {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    verifyFriendCode({ rootGetters }, params) {
      return axiosACN
        .post(
          '/sorgeniadataclient/verifyFriendCode',
          {
            codiceAmico: params.codiceAmico,
            codicefiscaleCliente: params.codicefiscaleCliente,
            codiceCampagnaWeb: params.codiceCampagnaWeb,
            segmentoCliente: params.segmentoCliente
          },
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    }
  }
}
