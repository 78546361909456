var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"std-side-padding supportFooter"},[_c('div',{staticClass:"pr-3"},[_vm._m(0),_c('div',{staticClass:"ButtonM customButtonM mt-2"},[_vm._v(" "+_vm._s(_vm.email)+" ")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"BodyS"},[_vm._v(" * Il costo della chiamata è variabile in funzione dell’operatore ")]),_c('div',{staticClass:"BodyS"},[_vm._v("telefonico")])]),_c('div',{staticClass:"pr-3"},[_vm._m(4),_c('div',{staticClass:"BodyM mt-2"},[_vm._v(_vm._s(_vm.infoHours))]),_c('div',{staticClass:"BodyM mt-2"},[_vm._v(_vm._s(_vm.infoHoursSaturday))]),_vm._m(5),_c('div',{staticClass:"subSection mt-4"},[_c('img',{staticClass:"social",attrs:{"src":require("../../assets/icons/IconFacebook.svg"),"alt":"","width":"44px"},on:{"click":function($event){return _vm.openContactLinks('facebook')}}}),_c('img',{staticClass:"social",attrs:{"src":require("../../assets/icons/IconTwitter.svg"),"alt":"","width":"44px"},on:{"click":function($event){return _vm.openContactLinks('twitter')}}})])]),_c('div',{staticClass:"pr-3"},[_c('div',{staticClass:"chat",on:{"click":_vm.openWhatsapp}},[_vm._m(6),_c('img',{attrs:{"src":require("../../assets/icons/chevron_rx_light.svg"),"alt":"","width":"24px"}})]),(
        _vm.serviceName !== 'Fotovoltaico' &&
        _vm.serviceName !== 'Comunità energetiche'
      )?_c('div',{staticClass:"chat mt-3",on:{"click":_vm.openChatOrSelect}},[_vm._m(7),_c('img',{attrs:{"src":require("../../assets/icons/chevron_rx_light.svg"),"alt":"","width":"24px"}})]):_vm._e()]),_c('v-dialog',{attrs:{"persistent":"","max-width":"558","overlay-opacity":"0.6","content-class":"customDialog"},model:{value:(_vm.selectUserDialog),callback:function ($$v) {_vm.selectUserDialog=$$v},expression:"selectUserDialog"}},[_c('div',{staticClass:"selectDialogContainer"},[_c('div',{staticClass:"headerSelect"},[_c('div',{staticClass:"HeadingsSubtitleL mb-5"},[_vm._v("Seleziona il profilo")]),_c('img',{attrs:{"src":require("../../assets/icons/ic-close.svg"),"width":"16"},on:{"click":function($event){_vm.selectUserDialog = false}}})]),_vm._l((_vm.formattedData),function(user,i){return _c('div',{key:i,on:{"click":function($event){_vm.openChat(user), (_vm.selectUserDialog = false)}}},[_c('div',{staticClass:"supplyAddress"},[_c('div',{staticClass:"supplySection"},[_c('img',{attrs:{"src":require("../../assets/ic-residenziale_blue.svg"),"width":"24"}}),_c('div',{staticClass:"BodyL-Strong ml-4 mt-1"},[_vm._v(" "+_vm._s(user.businessName)+" ")])]),_c('img',{attrs:{"src":require("@/assets/icons/chevron_rx_light.svg"),"width":"16"}})])])})],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subSection"},[_c('img',{attrs:{"src":require("../../assets/icons/emailIcon.svg"),"alt":"","width":"24px"}}),_c('div',{staticClass:"BodyM-Strong ml-2"},[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subSection mt-6 mb-3"},[_c('img',{attrs:{"src":require("../../assets/icons/Call.svg"),"alt":"","width":"24px"}}),_c('div',{staticClass:"BodyM-Strong ml-2"},[_vm._v("Numero Verde")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subSection"},[_c('div',{staticClass:"ButtonM customButtonM"},[_vm._v("800.294.333")]),_c('div',{staticClass:"BodyM ml-3"},[_vm._v("(da fisso)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subSection mb-1"},[_c('div',{staticClass:"ButtonM customButtonM"},[_vm._v("02.44.388.001")]),_c('div',{staticClass:"BodyM ml-3"},[_vm._v("(da mobile*)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subSection"},[_c('img',{attrs:{"src":require("../../assets/icons/Time.svg"),"alt":"","width":"24px"}}),_c('div',{staticClass:"BodyM-Strong ml-2"},[_vm._v("Orari")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subSection mt-5"},[_c('img',{attrs:{"src":require("../../assets/icons/Like.svg"),"alt":"","width":"24px"}}),_c('div',{staticClass:"BodyM-Strong ml-2"},[_vm._v("Social")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subSection"},[_c('img',{attrs:{"src":require("../../assets/icons/WhatsappOutline.svg"),"alt":"","width":"24px"}}),_c('div',{staticClass:"BodyM-Strong ml-2"},[_vm._v("Whatsapp")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subSection"},[_c('img',{attrs:{"src":require("../../assets/icons/IconChat.svg"),"alt":"","width":"24px"}}),_c('div',{staticClass:"BodyM-Strong ml-2"},[_vm._v("Chat")])])
}]

export { render, staticRenderFns }