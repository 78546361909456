var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-page"},[_c('div',{staticClass:"entry-page__content std-side-padding"},[_c('v-card',{staticClass:"card",on:{"click":function($event){_vm.trackTapEvent({
          name: 'customOffers_section_tap',
          params: { sectionType: 'home' }
        }),
          _vm.$router.push({ name: 'serviceSection', params: { type: 'casa' } })}}},[_c('div',{staticClass:"icon-content"},[_c('img',{attrs:{"src":require("@/assets/service-profile/home.png"),"width":"40"}})]),_c('div',{staticClass:"body-content"},[_c('span',{staticClass:"HeadingsTitleL"},[_vm._v("Casa")]),_c('p',{staticClass:"BodyL"},[_vm._v(" Luce, gas e fiber: scopri le soluzioni Sorgenia per la tua abitazione. ")])])]),_c('v-card',{staticClass:"card",on:{"click":function($event){_vm.trackTapEvent({
          name: 'customOffers_section_tap',
          params: { sectionType: 'photovoltaic' }
        }),
          _vm.$router.push({
            name: 'serviceSection',
            params: { type: 'fotovoltaico' }
          })}}},[_c('div',{staticClass:"icon-content"},[_c('img',{attrs:{"src":require("@/assets/service-profile/fotovoltaico.png"),"width":"40"}})]),_c('div',{staticClass:"body-content"},[_c('span',{staticClass:"HeadingsTitleL"},[_vm._v("Fotovoltaico")]),_c('p',{staticClass:"BodyL"},[_vm._v(" Trasforma la luce del sole in energia per la tua casa: scopri le soluzioni Sorgenia per il tuo impianto. ")])])]),_c('v-card',{staticClass:"card",on:{"click":function($event){_vm.trackTapEvent({
          name: 'customOffers_section_tap',
          params: { sectionType: 'mobility' }
        }),
          _vm.$router.push({
            name: 'serviceSection',
            params: { type: 'mobility' }
          })}}},[_c('div',{staticClass:"icon-content"},[_c('img',{attrs:{"src":require("@/assets/service-profile/mobility.png"),"width":"40"}})]),_c('div',{staticClass:"body-content"},[_c('span',{staticClass:"HeadingsTitleL"},[_vm._v("Auto")]),_c('p',{staticClass:"BodyL"},[_vm._v(" MyNextMove è l'app di Sorgenia dedicata alla mobilità elettrica e alla ricarica della tua auto. Scopri i vantaggi a te dedicati! ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }