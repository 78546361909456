<template>
  <div class="data">
    <v-text-field
      v-model.lazy="avgBillCost"
      @blur="v$.avgBillCost.$touch()"
      type="number"
      name="avgBillCost"
      :error-messages="avgBillCostErrors"
      placeholder="es. 9.90"
      persistent-placeholder
      class="mb-3"
      label="Costo medio bolletta [€/SMC]"
    >
      <template v-slot:append-outer>
        <div class="iconInfo avgBillCost">
          <img
            class="ml-1"
            src="../../../../assets/icons/info.svg"
            width="24"
            @click="openDetail('avgBillCost')"
          />
        </div>
      </template>
    </v-text-field>
    <v-text-field
      v-model.lazy="consumption"
      @blur="v$.consumption.$touch()"
      type="number"
      name="consumption"
      :error-messages="consumptionErrors"
      placeholder="es. 100"
      persistent-placeholder
      class="mb-3"
      label="Consumi annui [SMC/Anno]"
    >
      <template v-slot:append-outer>
        <div class="iconInfo consumption">
          <img
            class="ml-1"
            src="../../../../assets/icons/info.svg"
            width="24"
            @click="openDetail('consumption')"
          />
        </div>
      </template>
    </v-text-field>
    <v-text-field
      v-if="typeRequest !== 'PR'"
      v-model.lazy="remi"
      @blur="v$.remi.$touch()"
      type="text"
      name="remi"
      :error-messages="remiErrors"
      placeholder="es. 34565"
      persistent-placeholder
      class="mb-3"
      label="Codice REMI"
    >
      <template v-slot:append-outer>
        <div class="iconInfo remi">
          <img
            class="ml-1"
            src="../../../../assets/icons/info.svg"
            width="24"
            @click="openDetail('remi')"
          />
        </div>
      </template>
    </v-text-field>
    <v-text-field
      v-if="typeRequest !== 'PR'"
      v-model.lazy="pdr"
      @blur="v$.pdr.$touch()"
      type="text"
      name="pdr"
      :error-messages="pdrErrors"
      placeholder="es. 5678987656789"
      persistent-placeholder
      class="mb-3"
      label="PDR"
    >
      <template v-slot:append-outer>
        <div class="iconInfo pdr">
          <img
            class="ml-1"
            src="../../../../assets/icons/info.svg"
            width="24"
            @click="openDetail('pdr')"
          />
        </div>
      </template>
    </v-text-field>
    <MYSButton
      width="260"
      :buttonText="'Continua'"
      :buttonClass="'primaryButton'"
      :disabled="isInvalid"
      @buttonClick="next"
    />
    <v-dialog max-width="652" v-model="dialogDetail.show" ref="dialog">
      <v-row>
        <v-col class="dialog-detail">
          <img
            @click="dialogDetail.show = false"
            src="../../../../assets/ic_chevron_top_black.png"
          />
          <h2 v-html="dialogDetail.title" />
          <div class="description" v-html="dialogDetail.desc"></div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import { maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

// const checkPdr = (value) => {
//   console.log('+++++++++> ', value)
//   // return this.checkPDR(value)
//   return false
// }

import { useVuelidate } from '@vuelidate/core'
import MYSButton from '@/components/global/MYSButton.vue'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'supplierDataInputGas',
  components: {
    MYSButton
  },
  data() {
    return {
      avgBillCost: null,
      consumption: null,
      remi: null,
      pdr: null,
      dialogDetail: {
        show: false,
        title: '',
        desc: ''
      },
      me: null
    }
  },
  watch: {
    'v$.pdr.$error': function (val) {
      val
        ? this.setValidationErrors('PDR_INVALID')
        : this.deleteValidationError('PDR_INVALID')
    },
    'v$.avgBillCost.$error': function (val) {
      val
        ? this.setValidationErrors('AVG_BILL_COST_INVALID')
        : this.deleteValidationError('AVG_BILL_COST_INVALID')
    },
    'v$.consumption.$error': function (val) {
      val
        ? this.setValidationErrors('CONSUMPTION_INVALID')
        : this.deleteValidationError('CONSUMPTION_INVALID')
    },
    'v$.remi.$error': function (val) {
      val
        ? this.setValidationErrors('REMI_INVALID')
        : this.deleteValidationError('REMI_INVALID')
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['flowType', 'typeRequest']),
    isInvalid() {
      if (this.typeRequest === 'PR') {
        return (
          this.avgBillCostErrors.length > 0 ||
          this.consumptionErrors.length > 0 ||
          !this.v$.avgBillCost.$dirty ||
          !this.v$.consumption.$dirty
        )
      } else {
        return (
          this.avgBillCostErrors.length > 0 ||
          this.consumptionErrors.length > 0 ||
          this.remiErrors.length > 0 ||
          this.pdrErrors.length > 0 ||
          !this.v$.avgBillCost.$dirty ||
          !this.v$.consumption.$dirty ||
          !this.v$.remi.$dirty ||
          !this.v$.pdr.$dirty
        )
      }
    },
    avgBillCostErrors: function () {
      let errArray = []

      if (!this.v$.avgBillCost.$dirty) return errArray
      this.v$.avgBillCost.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')

      return errArray
    },
    consumptionErrors: function () {
      let errArray = []

      if (!this.v$.consumption.$dirty) return errArray
      this.v$.consumption.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')

      return errArray
    },
    remiErrors: function () {
      let errArray = []

      if (this.typeRequest === 'PR') return []

      if (!this.v$.remi.$dirty) return errArray
      this.v$.remi.maxLength.$invalid === true &&
        errArray.push('Il codice non soddisfa i requisiti')
      this.v$.remi.pattern.$invalid === true &&
        errArray.push('Il codice non soddisfa i requisiti')
      this.v$.remi.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')

      return errArray
    },
    pdrErrors: function () {
      let errArray = []

      if (this.typeRequest === 'PR') return []
      if (!this.v$.pdr.$dirty) return errArray

      this.v$.pdr.minLength.$invalid === true &&
        errArray.push('Il campo PDR non è valido')
      this.v$.pdr.maxLength.$invalid === true &&
        errArray.push('Il campo PDR non è valido')
      this.v$.pdr.pattern.$invalid === true &&
        errArray.push('Il campo PDR non è valido')
      this.v$.pdr.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')
      this.v$.pdr.checkPdr.$invalid === true &&
        errArray.push('Il campo PDR non è valido')

      return errArray
    }
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'setSupplyDetail',
      'setValidationErrors',
      'deleteValidationError'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('upsellingComm', ['checkPDR']),
    next() {
      this.setSupplyDetail({
        avgBillCost: this.avgBillCost,
        consumption: parseInt(this.consumption),
        remi: this.remi,
        pdr: this.pdr
      })
      this.$emit('nextStep', 'upsellingcom_titolarity_open')
    },
    openDetail(type) {
      const text = {
        pdr: {
          title: 'PDR',
          desc: "Lo trovi in bolletta. È un codice composto da 14 caratteri: i primi 4 identificano l'impresa di distribuzione, i restanti 10 rappresentano un codice univoco stabilito dalla stessa impresa di distribuzione e riferito al PDR. Serve ad individuare il punto dove il distributore riconsegna il gas per la fornitura al cliente finale."
        },
        remi: {
          title: 'Codice REMI',
          desc: 'La cabina REMI del gas corrisponde al Punto di Consegna del gas (PdC), dove questo viene consegnato dalla società di trasporto alla società di distribuzione. Puoi trovare questa informazione sulla bolletta.'
        },
        consumption: {
          title: 'Consumi annui [SMC/Anno]',
          desc: "Inserisci qui il dato di consumo gas annuo letto sulla tua ultima bolletta. Normalmente questa informazione è inserita nella seconda pagina della tua bolletta. La famiglia media italiana consuma circa 1.400 metri cubi all'anno."
        },
        avgBillCost: {
          title: 'Costo medio bolletta [€/SMC]',
          desc: 'Trovi questo valore nella tua bolletta alla voce “costo medio della bolletta”, sulla bolletta gas. Normalmente questa informazione è presente nella seconda pagina della tua bolletta. La famiglia media italiana ha un costo medio unitario totale della bolletta del gas pari a circa 0,9 €/Smc.'
        }
      }
      this.dialogDetail = {
        show: true,
        title: text[type].title,
        desc: text[type].desc
      }
      this.trackTapEvent({
        name: `upsellingcom_info${type}_tap`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  },
  created() {
    this.avgBillCost =
      this.$store.getters['upsellingComm/supplyDetail'].gas.avgBillCost
    this.consumption =
      this.$store.getters['upsellingComm/supplyDetail'].gas.consumption
    this.remi = this.$store.getters['upsellingComm/supplyDetail'].gas.remi
    this.pdr = this.$store.getters['upsellingComm/supplyDetail'].gas.pdr
    this.me = this
  },
  validations: {
    avgBillCost: { required },
    consumption: { required },
    remi: {
      required,
      maxLength: maxLength(20),
      pattern: (value) => {
        const patt = /^[a-zA-Z0-9]*$/
        return patt.test(value)
      }
    },
    pdr: {
      required,
      minLength: minLength(14),
      maxLength: maxLength(14),
      checkPdr: (value, vm) => {
        if (value) return vm.checkPDR(value)
        return { $invalid: true }
      },
      pattern: (value) => {
        const patt = /^[0-9]*$/
        return patt.test(value)
      }
    }
  }
}
</script>
<style lang="scss">
.v-text-field {
  position: relative;
}

.iconInfo {
  position: absolute;
  top: -6px;

  &.avgBillCost {
    left: 158px;
  }

  &.consumption {
    left: 148px;
  }

  &.remi {
    left: 68px;
  }

  &.pdr {
    left: 22px;
  }
}
</style>
<style lang="scss" scoped>
.dialog-detail,
.dialog-offer {
  background: white;
  padding: 55px 32px 45px 32px;
  border-radius: 40px;
  position: relative;

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .description {
    font-size: 16px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
  }

  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    &.preview {
      position: initial;
    }
  }
}

.data {
  max-width: 652px;
  color: black;
}
</style>
