<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <h1 class="white--text">
          A chi vuoi spedire<br />
          il premio?
        </h1>
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div v-if="isFisicalShipping" class="shipping__content">
      <v-row>
        <v-col>
          <h2>Inserisci i dati per la spedizione.</h2>
        </v-col>
      </v-row>
      <contact-form
        :isFormFilled.sync="isSubmitButtonDisabled"
        :formData.sync="input"
        catalog="IW-WL"
      />
      <v-row class="mb-8">
        <v-col>
          <h4>Tutti i campi sono obbligatori.</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            :disabled="isSubmitButtonDisabled"
            class="v-btn--shadowed bold"
            color="primary"
            x-large
            width="265"
            @click="shippingConfirmHandler"
          >
            Prosegui
          </v-btn>
        </v-col>
      </v-row>
      <v-footer color="white" height="90" />
    </div>
    <div v-else class="shipping__content">
      <v-row class="mt-12">
        <v-col cols="12" class="pb-0">
          <h2 class="mb-5">Riceverai il premio a questo indirizzo email.</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <span class="mr-4">Email di contatto</span
          ><img
            width="22"
            height="20"
            @click="editDialog = true"
            src="@/assets/ic_edit_blue.svg"
          />
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col cols="12" class="pb-0">
          <strong>{{ email }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            :disabled="!email"
            class="v-btn--shadowed bold"
            color="primary"
            x-large
            width="265"
            @click="shippingConfirmHandler"
          >
            Prosegui
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="editDialog" max-width="490">
      <div class="ripensamento-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="editDialog = false"
        />
        <div class="ripensamento-modal__title">Inserisci Email di Contatto</div>
        <div class="ripensamento-modal__accept" align="center">
          <v-row>
            <v-col>
              <v-text-field
                :error-messages="emailErrors"
                @blur="v$.editEmail.$touch()"
                v-model.trim="editEmail"
                label="Email"
              />
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            :disabled="v$.editEmail.$invalid || !editEmail"
            @click="confirm"
            color="primary"
            class="v-btn--shadowed bold"
            width="222"
            height="54"
            >Conferma</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </layout>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { isValidEmail } from '@/js/utils'
import { email } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import Layout from '../Layout'
import ContactForm from './ContactForm'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'ShippingFormPage',
  components: {
    layout: Layout,
    ContactForm
  },
  data: () => ({
    isSubmitButtonDisabled: true,
    input: {},
    email: '',
    editEmail: '',
    editDialog: false
  }),
  beforeRouteEnter(to, from, next) {
    to.params.from = from.name
    next()
  },
  mounted() {
    this.getMainClient().then((res) => {
      if (this.mainClientLoyaltySelected) {
        res = this.mainClientLoyaltySelected
      }
      this.getCatalog({
        catalog_name: 'IW-WL',
        codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
      })
      this.getDetailForClient({ clientOwnerCode: res.clientOwnerCode }).then(
        (resp) => {
          this.email = resp.contactMail || 'Email'
        }
      )
    })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('loyalty', ['getCatalog', 'shippingConfirm']),
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    shippingConfirmHandler() {
      this.trackTapEvent({
        name: 'greeners_welcomeIWContinue_tap',
        params: {
          clientCode: this.mainClientLoyaltySelected.clientOwnerCode,
          clientType: this.mainClientLoyaltySelected.clientOwnerType
        }
      })
      if (!this.isFisicalShipping) {
        this.input = this.inputMail
      }
      this.input.prizeoption_id = this.catalog_iwwl[0].attributes.prizeoption_id
      this.shippingConfirm({
        input: this.input,
        id: this.mainClientLoyaltySelected.clientOwnerCode
      })
        .then((res) => {
          if (res)
            this.$router.push({
              name: 'shippingConfirm',
              params: {
                type: this.isFisicalShipping ? 'info' : 'email',
                catalog: 'IW-WL',
                from: this.$route.params.from
              },
              props: true
            })
        })
        .catch(() => {
          GenErr.showErrorMessage(
            'Si è verificato un errore e non è stato possibile inviare la tua richiesta. Ti preghiamo di riprovare.'
          )
        })
    },
    emailErrors: function () {
      let errArray = []
      if (!this.v$.editEmail.$dirty) return errArray
      this.v$.editEmail.isValidEmail === false &&
        errArray.push('inputs must be a valid email')
      return errArray
    },
    confirm() {
      this.editDialog = false
      this.email = this.editEmail
    }
  },
  computed: {
    ...mapGetters('loyalty', ['isGameWon', 'catalog_iwwl']),
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    isFisicalShipping() {
      return this.catalog_iwwl[0]
        ? !!this.catalog_iwwl[0].attributes.is_required_shipping
        : true
    },
    inputMail() {
      const {
        businessName,
        mobilePhone,
        birthPlace,
        residentialCAP,
        residentialNation,
        residentialRegion,
        residentialAddress
      } = this.clientDetails
      return {
        catalog_name: 'IW-WL',
        shipping_receiver_name: businessName,
        shipping_email: this.email,
        shipping_phone: mobilePhone,
        shipping_city: birthPlace,
        shipping_zip_code: residentialCAP,
        shipping_street: residentialAddress,
        shipping_street_number: '',
        shipping_district: residentialRegion,
        shipping_country: residentialNation
      }
    }
  },
  validations: {
    editEmail: { email, isValidEmail }
  }
}
</script>
<style lang="scss" scoped>
.shipping {
  &__content {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;
  }
  &__panels {
    margin-bottom: 36px;
  }
}
.v-expansion-panels {
  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;
}
.v-expansion-panel-header {
  h3 {
    font-size: 24px;
    font-weight: 500;
    height: 60px;
  }
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-content {
  height: 60px;
}
</style>
