var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'loyalty',
    _vm.headerSize === 'full' ? 'loyalty--' + _vm.headerSize : '',
    { 'loyalty-greeners-header': _vm.sectionLoyalty }
  ]},[_c('v-row',{class:[
      'header-main',
      _vm.headerSize ? 'header-main--' + _vm.headerSize : '',
      { 'header-main--with-bg-img': _vm.headerWithBgImg }
    ]},[_vm._t("header")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$slots.default),expression:"$slots.default"}],class:[
      _vm.sectionLoyalty
        ? 'loyalty__content-wrap loyalty-greeners'
        : 'loyalty__content-wrap'
    ]},[_c('v-row',{class:{ loyalty__content: !_vm.sectionLoyalty }},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }