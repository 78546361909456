<script>
import TcVarService from '@/js/service/tcVarService.js'

export default {
  name: 'TcVars',
  props: {
    // env_template: {
    //   type: String,
    //   default: ''
    // },
    // pdr: {
    //   type: String,
    //   default: ''
    // },
    // pod: {
    //   type: String,
    //   default: ''
    // },
    // user_contract_code: {
    //   type: String,
    //   default: ''
    // },
    // user_contract_code_fibra: {
    //   type: String,
    //   default: ''
    // }
  },
  mounted() {
    new TcVarService(this.$props).tcTrackTapEvent()
  },
  render() {
    return null
  }
}
</script>
