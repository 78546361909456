<template>
  <div class="bkg--lightgray tracker_container">
    <div class="std-side-padding">
      <h6 class="pt-6 pb-2 address">
        Fornitura per <span class="street">{{ address.toLowerCase() }}</span>
      </h6>

      <div class="stepper">
        <v-row no-gutters align="center" class="pt-5">
          <v-col md="auto" v-for="i in stateHeaders.length * 2 - 1" :key="i">
            <div
              v-if="i % 2 !== 0"
              :class="i < step * 2 ? 'tracker_active' : 'tracker_inactive'"
            />
            <hr
              v-if="i % 2 === 0"
              class="line"
              :class="i < step * 2 ? 'line__blue' : 'line__gray'"
            />
          </v-col>
        </v-row>

        <v-row no-gutters class="pt-5 tracking-labels">
          <v-col
            v-for="(s, i) in stateHeaders"
            :key="i"
            :class="`${fromPage !== 'fiber' ? 'tracking-commodity' : ''}`"
          >
            <h6
              :class="i < step ? 'heading_active' : 'heading'"
              v-html="s"
            ></h6>
          </v-col>
        </v-row>

        <v-row no-gutters class="pt-2 tracking-labels">
          <v-col
            v-for="(s, i) in stateDates"
            :key="i"
            :class="`${fromPage !== 'fiber' ? 'tracking-commodity' : ''}`"
          >
            <h6 :class="i < step ? 'heading_active' : 'heading'">{{ s }}</h6>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrackingPanel',
  props: {
    fromPage: { type: String, default: 'fiber' },
    address: { type: String, default: 'Rome' },
    label: { type: String, default: null },
    state: Number,
    stateHeaders: Array,
    stateDates: Array,
    isSwitchIn: Boolean,
    hasShipping: { type: Boolean, default: false }
  },
  computed: {
    step() {
      if (this.fromPage === 'fiber') {
        if (this.isSwitchIn) {
          if (this.hasShipping) {
            switch (this.state) {
              case 1: {
                console.log('TrakingPanel: ', 1)
                break
              }
              case 3: {
                console.log('TrakingPanel: ', 3)
                break
              }
              case 5: {
                console.log('TrakingPanel: ', 4)
                break
              }
              default: {
                console.log('TrakingPanel: ', 2)
                break
              }
            }
          } else {
            switch (this.state) {
              case 1: {
                console.log('TrakingPanel: ', 1)
                break
              }
              case 5: {
                console.log('TrakingPanel: ', 4)
                break
              }
              default: {
                console.log('TrakingPanel: ', 2)
                break
              }
            }
          }
        } else {
          switch (this.state) {
            case 1: {
              console.log('TrakingPanel: ', 1)
              break
            }
            case 5: {
              console.log('TrakingPanel: ', 4)
              break
            }
            default: {
              console.log('TrakingPanel: ', 2)
              break
            }
          }
        }
        return this.state
      } else if (this.isSwitchIn) {
        switch (this.state) {
          case 1:
            return 1
          case 2:
            return 2
          case 3:
            return 3
          case 4:
            return 4
          default:
            return 1
        }
      } else {
        switch (this.state) {
          case 1 || 2 || 3 || 5 || 6:
            return 1
          case 4:
            return 2
          default:
            return 1
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .std-side-padding {
//   width: 1470px;
// }

.tracker_container {
  height: 254px;
  width: 100%;
  margin-left: 0;
}

.tracker_active {
  height: 32px;
  width: 32px;
  background-color: $srg-blue;
  border-radius: 16px;
}

.tracker_inactive {
  height: 24px;
  width: 24px;
  margin: 4px;
  border: 3px solid #b3b3b3;
  border-radius: 12px;
}

.line {
  box-sizing: border-box;
  height: 2px;
  width: 240px;

  &__gray {
    border: 2px dashed #ccd0e1;
  }

  &__blue {
    border: 3px solid $srg-blue;
    background: #12256a;
  }
}

.address {
  font-size: 20px;
  font-weight: 500;

  .street {
    text-transform: capitalize;
  }
}

.heading {
  font-size: 20px;
  font-weight: 300;
}

.heading_active {
  font-size: 20px;
  font-weight: 500;
}

// TODO: check this. temporary style to make the component look decent.
.tracking-labels {
  max-width: 1100px;
}

.tracking-commodity {
  max-width: 274px;
  h6 {
    max-width: 150px;
  }
}
</style>
