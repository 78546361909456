<template>
  <div class="notice topRadius">
    <v-row class="auth std-side-padding">
      <v-row no-gutters align="center">
        <v-col sm="6">
          <v-img src="@/assets/ph_login_pale.gif"></v-img>
        </v-col>
        <v-col sm="6">
          <v-row>
            <v-col offset-sm="1" sm="11" align-center>
              <h1 class="auth__title" v-if="notice && notice.title">
                {{ notice.title }}
              </h1>
              <h4 class="auth__subtitle" v-if="notice && notice.message">
                {{ notice.message }}
              </h4>

              <v-row>
                <v-col sm="6" class="pl-0">
                  <v-btn
                    :color="
                      notice && notice.skippable ? 'secondary' : 'primary'
                    "
                    x-large
                    rounded
                    width="100%"
                    class="v-btn--shadowed mt-6"
                    v-if="
                      notice &&
                      notice.cta &&
                      notice.cta_action_type === 'WEB_LINK'
                    "
                    :href="notice.cta_action_url"
                    target="_blank"
                  >
                    {{ notice.cta }}
                  </v-btn>
                </v-col>

                <v-col sm="6" class="pr-0">
                  <v-btn
                    color="primary"
                    x-large
                    rounded
                    width="100%"
                    class="v-btn--shadowed mt-6"
                    v-if="notice && notice.skippable"
                    @click="skipNotice"
                  >
                    Continua
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import router from '../../router/router'

export default {
  name: 'noticeComponent',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('notice', ['notices']),
    notice() {
      if (!this.notices || this.notices.lenght === 0) {
        return {}
      } else {
        return this.notices[0]
      }
    }
  },
  methods: {
    ...mapMutations('notice', ['setNoticeSkipped']),
    skipNotice() {
      this.setNoticeSkipped(true)
      router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/authentication/auth.scss';

.topRadius {
  background: none;
  margin-top: 100px;
}

.v-btn {
  text-transform: none;
}
</style>
