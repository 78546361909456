<template>
  <v-carousel
    :continuous="false"
    :show-arrows="!isMobile"
    :show-arrows-on-hover="!isMobile"
    :hide-delimiter-background="!isMobile"
    delimiter-icon="mdi-minus"
    :height="!isMobile ? 570 : 460"
  >
    <v-carousel-item v-for="(slide, i) in slides" :key="i">
      <v-row>
        <v-col
          md="6"
          cols="12"
          sm="6"
          v-for="(card, i) in slide.slide"
          :key="i"
          @click="
            trackTapEvent({
              name:
                name === 'welcome'
                  ? 'welcomeKit_card_tap'
                  : name === 'tutorial'
                  ? 'wkTutorial_card_tap'
                  : 'feedback_card_tap',
              params: {
                card_title: card.carousel_title,
                client_code: client.clientOwnerCode,
                client_type: client.clientOwnerType,
                wk_type: client.type
              },
              toNative: isMobile
            })
          "
        >
          <v-card
            light
            class="v-card--rounded"
            elevation="10"
            @click="$router.push(to(card.id, card.template_name))"
            :min-height="!isMobile ? 474 : 378"
          >
            <!-- <v-img :src="require('@/assets/' + name + '/' + card.carousel_image)" class="white--text"/> -->
            <v-img
              :src="
                isSmallDevice
                  ? cdn + (card.carousel_image_mobile || card.carousel_image)
                  : cdn + card.carousel_image
              "
              class="white--text"
              :width="isMobile ? 351 : 546"
              :height="isMobile ? 200 : 330"
            />
            <v-row class="pl-6 pr-3">
              <h3 class="mt-8">
                <strong>{{ card.carousel_title }}</strong>
              </h3>
              <h6 class="mt-2 mb-8" v-html="card.carousel_text"></h6>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { ENV_CONFIGS } from '@/js/configs'
import { getKitSlideRouteName } from '@/js/utils.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WelcomeCarousel',
  data() {
    return {
      cdn: ENV_CONFIGS.CMS_CDN_BASE_URL,
      isSmallDevice: window.innerWidth <= 480
    }
  },
  props: {
    isMobile: { type: Boolean, default: false },
    slides: Array,
    name: { type: String, default: '' },
    routeName: { type: String, default: null },
    type: { type: String, default: 'new_massmarket' }
  },
  computed: {
    ...mapGetters('feedback', ['client'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    to(name, template) {
      if (this.routeName) {
        return { name: this.routeName, params: { id: name } }
      } else {
        if (
          template === 'standardWelcomeKit' ||
          template === 'standardTutorial'
        ) {
          return {
            name: getKitSlideRouteName(template, this.isMobile),
            params: { id: name }
          }
        } else {
          return { name: getKitSlideRouteName(template, this.isMobile) }
        }
      }
    }
  }
}
</script>

<style scoped></style>
