<template>
  <div class="callMeBack">
    <v-row>
      <v-col><h3 class="mb-2">Pensiamo a tutto noi!</h3></v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Un nostro operatore ti contatterà al più presto per completare il
          processo. Il nostro Servizio Clienti è aperto dal lunedì al venerdì
          dalle {{ lineData.monday_friday_start }} alle
          {{ lineData.monday_friday_end }} e il sabato e la domenica dalle
          {{ lineData.saturday_start }} alle {{ lineData.saturday_end }}.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <h5>Dove possiamo chiamarti?</h5>
        <v-text-field
          type="number"
          :value="phone"
          @input="emitPhone"
          placeholder="numero di telefono"
          persistent-placeholder
          :error-messages="phoneErrors"
          clearable
          @blur="v$.phone.$touch()"
        />
      </v-col>
      <v-col cols="8">
        <h5>Quando preferisci essere contattato?</h5>
        <ExtensibleButtonGroup v-model="callTime" :per-row="2" :content="false">
          <ExtensibleButton name="indifferente" label="Indifferente" />
          <ExtensibleButton name="mattina" label="Mattina" />
          <ExtensibleButton name="pomeriggio" label="Pomeriggio" />
          <ExtensibleButton name="sera" label="Sera" />
        </ExtensibleButtonGroup>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'callMeBack',
  props: [
    'supplyType',
    'clientCode',
    'email',
    'notes',
    'submit',
    'fromPage',
    'phone',
    'time'
  ],
  data() {
    return {
      callTime: '',
      lineData: {}
    }
  },
  watch: {
    callTime(value) {
      this.$emit('update:time', value)
    },
    submit(value) {
      if (value) this.reserve()
    }
  },
  computed: {
    phoneErrors() {
      let errArray = []

      if (!this.v$.phone.$dirty) return errArray

      this.v$.phone.minLength.$invalid === true &&
        errArray.push('Il numero di telefono deve essere di almeno 6 cifre')
      this.v$.phone.maxLength.$invalid === true &&
        errArray.push('Il numero di telefono può avere al massimo 12 cifre')
      this.v$.phone.required.$invalid === true &&
        errArray.push('Il Numero di telefono è obbligatorio')

      return errArray
    }
  },
  methods: {
    ...mapActions('interactions', { callMeBack: 'callMeBack' }),
    ...mapActions('interactions', ['getChannelInfo']),
    reserve() {
      this.$emit('update:loading', true)
      let chatData = {
        clientCode: this.clientCode,
        commodity:
          this.supplyType === 'Ele' ? 'luce' : this.supplyType.toLowerCase(),
        phone: this.phone,
        times: this.callTime,
        email: this.email,
        notes: this.notes || 'test',
        times: this.time,
        requestType: this.supplyType === 'fiber' ? 'assistance' : undefined
      }
      console.log('chatData', chatData)
      this.callMeBack(chatData)
        .then((res) => {
          if (res.status === 'OK') this.$emit('nextStep', this.phone)
        })
        .catch((error) => {
          GenErr.showErrorMessage(
            'Si è verificato un errore e non è stato possibile inviare la tua richiesta. Ti preghiamo di riprovare.'
          )
          GenErr.handleGenericError(error && error.message)
        })
    },
    emitPhone(val) {
      if (val) {
        this.$emit('update:phone', val)
      } else {
        this.$emit('update:phone', '')
      }
    }
  },
  created() {
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area:
        this.fromPage !== 'fiber'
          ? consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
          : consts.CALLCENTER_AREAS.UPSELLING_FIBER
    })
      .then((resp) => {
        this.lineData = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  validations: {
    phone: { required, minLength: minLength(6), maxLength: maxLength(12) },
    phoneNew: { required, minLength: minLength(6), maxLength: maxLength(12) }
  }
}
</script>
<style lang="scss" scoped>
.callMeBack {
  h3 {
    font-size: 32px;
    font-weight: bold;
  }
  h5 {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
