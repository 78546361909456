var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"header-size":"full"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{class:`${_vm.success ? 'img-man' : 'img-girl'} pa-0`,attrs:{"cols":"7"}}),(!_vm.coinsBack)?_c('v-col',{staticClass:"content-margin",attrs:{"cols":"5"}},[_c('h1',{staticClass:"black--text mb-8"},[_vm._v(" "+_vm._s(_vm.success ? 'Complimenti\n hai vinto!' : 'Purtroppo non\n hai vinto!')+" ")]),(_vm.success && _vm.rewardData)?_c('v-img',{staticClass:"card__image",attrs:{"contain":"","height":"235","width":"350","src":_vm.rewardData.images.data[0].image_small}}):_vm._e(),_c('h3',{staticClass:"font-weight-bold black--text mb-10"},[_vm._v(" "+_vm._s(_vm.success ? _vm.rewardData && _vm.rewardData.title : '…ma un Greener non si arrende al primo\n ostacolo!')+" ")]),_c('v-btn',{staticClass:"v-btn--shadowed btn normal",attrs:{"x-large":"","width":265},on:{"click":function($event){_vm.success
            ? _vm.$router.push({
                name: _vm.rewardData.is_required_shipping
                  ? 'giftShipping'
                  : 'giftEmailShipping',
                params: {
                  catalog: !_vm.coinsBack
                    ? 'IW'
                    : _vm.mainClientLoyaltySelected.clientOwnerType === 'RES'
                    ? 'COLLECTION'
                    : 'COLLECTION-BS',
                  id: _vm.rewardData.prizeoption_id
                }
              })
            : _vm.$router.push({ name: 'loyaltyPage' })}}},[_vm._v(" "+_vm._s(_vm.success ? 'Si voglio il premio' : 'Vai al Diario')+" ")])],1):_vm._e(),(_vm.coinsBack)?_c('v-col',{staticClass:"content-margin",attrs:{"cols":"5"}},[(_vm.success)?_c('v-img',{staticClass:"card__image",attrs:{"height":"103","width":"103","src":require("@/assets/ic_greencoins_large_white.png")}}):_vm._e(),_c('h1',{staticClass:"black--text mb-10"},[_vm._v(" "+_vm._s(_vm.success ? `Hai ottenuto\n ${_vm.coinsReward}\nGreen Coins` : 'Il codice\n sembra essere\n non valido o\n già utilizzato!')+" ")]),(!_vm.success)?_c('v-btn',{staticClass:"v-btn--shadowed btn normal mb-9",attrs:{"x-large":"","width":265},on:{"click":function($event){return _vm.$router.push({ name: 'giftCode' })}}},[_vm._v(" Riprova ")]):_vm._e(),_c('v-btn',{staticClass:"v-btn--shadowed btn normal",class:_vm.success ? '' : 'black--text',attrs:{"outlined":!_vm.success,"x-large":"","width":265},on:{"click":function($event){return _vm.$router.push({ name: 'loyaltyPage' })}}},[_vm._v(" Vai al Diario ")])],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }