<template>
  <v-dialog v-model="dialog.show" max-width="440">
    <v-row>
      <v-col class="iban-dialog">
        <img
          @click="dialog.show = false"
          src="../../../assets/ic_chevron_top_black.png"
        />
        <h2>Attenzione</h2>
        <h4>{{ this.dialog.text }}</h4>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
export default {
  name: 'popupErrorIban',
  data() {
    return {
      dialog: {
        show: false,
        text: ''
      }
    }
  },
  methods: {
    typeError(val) {
      this.dialog.text = val
      this.dialog.show = true
    }
  }
}
</script>
<style lang="scss" scoped>
.iban-dialog {
  background: white;
  padding: 75px 48px 50px 48px;
  border-radius: 40px;
  position: relative;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  h4 {
    font-weight: 300;
  }
  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
}
</style>
