import { axiosACN } from '@/js/axiosInstances'
import constants from '@/js/constants'

const retrieveCardsList = (commit, rootGetters, params) => {
  return axiosACN
    .post(
      '/fornitura/retrieveAlias',
      {
        codiceCliente: params.clientCode
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.errorCode) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }

      let cardsList = {}
      for (let point of res.data.list) {
        const method = {
          creditCardNumber: point.numeroCarta,
          creditCardCircuit: point.circuito,
          creditCardExpirationDate: point.dataScadenza,
          aliasPan: point.aliasPan,
          oneShotCardRegistrationDate: point.dataRegistrazioneCartaCCOS,
          crecurr: point.crecurr,
          trecurr: point.trecurr
        }

        // Here we are getting th elist of all point with related payment method.
        // Grouping methods to get the list of unique methods
        const key = [point.aliasPan, point.numeroCarta].join('_')
        cardsList[key] = method
      }

      cardsList = Object.values(cardsList)

      commit('setCardList', {
        cardsList
      })
      return cardsList
    })
}

// Servizio che permette il pagamento di più rate su
// SIA senza alias e il tracciamento dei pagamenti sul sistema di billing
const payMultiInstalmentNoAlias = (rootGetters, params) => {
  const iframeBaseUrl = window.location.origin
  return axiosACN
    .post(
      '/payment/payMultiInstalmentNoAlias',
      {
        clientCode: params.clientCode,
        email: params.email ? params.email : undefined,
        name: params.name ? params.name : undefined,
        surname: params.surname ? params.surname : undefined,
        urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
        urlDone: window.btoa(iframeBaseUrl + '/sia/done.html'),
        remember: params.remember, // Flag per salvare il pagamento
        instalments: params.instalment // Lista di oggetti rata
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.errorCode) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }
      // let mockedRes = {
      //   status: 'OK',
      //   urlRedirect:
      //     'aHR0cHM6Ly9hcmVhY2xpZW50aS1zdGFnaW5nLnNvcmdlbmlhLml0L3NpYS9iYWNrLmh0bWw='
      // }

      return res.data
    })
    .catch((err) => {
      console.error('Session error' + err)
      // HTTP Code 	Error code 	Error Description
      // 500 	500 	Internal Server error
      // 400 	400 	Bad Request
      // 400	1016	idInstalment not valued
      // 400	1017	idInvoice not valued
      // 400	1018	paymentSlipId not valued
      // 400	1019	amount not valued
      // 400	1027	urlBack not valued

      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

// Servizio che permette il pagamento di più rate su SIA con alias e il tracciamento
// dei pagamenti sul sistema di billing
const payMultiInstalmentAlias = (rootGetters, params) => {
  const iframeBaseUrl = window.location.origin

  return axiosACN
    .post(
      '/payment/payMultiInstalmentAlias',
      {
        clientCode: params.clientCode,
        email: params.email ? params.email : undefined,
        name: params.name ? params.name : undefined,
        surname: params.surname ? params.surname : undefined,
        aliasPan: params.aliasPan,
        urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
        urlDone: window.btoa(iframeBaseUrl + '/sia/done.html'),
        aliasPanExpirationDate: params.aliasPanExpirationDate.replace('/', ''),
        instalments: params.instalment // Lista di oggetti rata
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.errorCode) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }
      // let mockedRes = {
      //   status: 'OK',
      //   urlRedirect:
      //     'aHR0cHM6Ly9hcmVhY2xpZW50aS1zdGFnaW5nLnNvcmdlbmlhLml0L3NpYS9iYWNrLmh0bWw='
      // }

      return res.data
    })
    .catch((err) => {
      console.error('Session error' + err)
      // HTTP Code 	Error code 	Error Description
      // 500 	500 	Internal Server error
      // 400 	400 	Bad Request
      // 400	1016	idInstalment not valued
      // 400	1017	idInvoice not valued
      // 400	1018	paymentSlipId not valued
      // 400	1019	amount not valued
      // 400	1027	urlBack not valued

      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const payInstalmentNoAlias = (rootGetters, params) => {
  const iframeBaseUrl = window.location.origin
  return axiosACN
    .post(
      '/payment/payInstalmentNoAlias',
      {
        clientCode: params.clientCode,
        email: params.email ? params.email : undefined,
        name: params.name ? params.name : undefined,
        surname: params.surname ? params.surname : undefined,
        urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
        urlDone: window.btoa(iframeBaseUrl + '/sia/done.html'),
        remember: params.remember, // Flag per salvare il pagamento
        instalment: params.instalment // Lista di oggetti rata
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.errorCode) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }
      // let mockedRes = {
      //   status: 'OK',
      //   urlRedirect:
      //     'aHR0cHM6Ly9hcmVhY2xpZW50aS1zdGFnaW5nLnNvcmdlbmlhLml0L3NpYS9iYWNrLmh0bWw='
      // }

      return res.data
    })
    .catch((err) => {
      console.error('Session error' + err)
      // HTTP code Description
      // 500 	500 	Internal Server error
      // 400 	400 	Bad Request
      // 400	1016	idInstalment not valued
      // 400	1017	idInvoice not valued
      // 400	1018	paymentSlipId not valued
      // 400	1019	amount not valued
      // 400	1020	aliasPan cannot be empty
      // 400	1021	aliasPanDataScad cannot be empty
      // 400	1027	urlBack not valued
      // 400	1028	urlDone not valued

      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}
const payInstalmentAlias = (rootGetters, params) => {
  const iframeBaseUrl = window.location.origin
  return axiosACN
    .post(
      '/payment/payInstalmentAlias',
      {
        clientCode: params.clientCode,
        email: params.email ? params.email : undefined,
        name: params.name ? params.name : undefined,
        surname: params.surname ? params.surname : undefined,
        aliasPan: params.aliasPan,
        urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
        urlDone: window.btoa(iframeBaseUrl + '/sia/done.html'),
        aliasPanExpirationDate: params.aliasPanExpirationDate.replace('/', ''),
        instalment: params.instalment // Lista di oggetti rata
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.errorCode) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }
      // let mockedRes = {
      //   status: 'OK',
      //   urlRedirect:
      //     'aHR0cHM6Ly9hcmVhY2xpZW50aS1zdGFnaW5nLnNvcmdlbmlhLml0L3NpYS9iYWNrLmh0bWw='
      // }

      return res.data
    })
    .catch((err) => {
      console.error('Session error' + err)
      // HTTP code Description
      // 500 	500 	Internal Server error
      // 400 	400 	Bad Request
      // 400	1016	idInstalment not valued
      // 400	1017	idInvoice not valued
      // 400	1018	paymentSlipId not valued
      // 400	1019	amount not valued
      // 400	1020	aliasPan cannot be empty
      // 400	1021	aliasPanDataScad cannot be empty
      // 400	1027	urlBack not valued
      // 400	1028	urlDone not valued

      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const retrieveMethodsList = (commit, rootGetters, params) => {
  return axiosACN
    .post(
      '/fornitura/fornituraMdpCliente',
      {
        codiceCliente: params.clientCode
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.errorCode) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }

      let methods = {}
      for (let point of res.data.points) {
        const method = {
          supplyCode: point.codicePunto,
          fiscalCodeVAT: point.cfPiva,
          businessName: point.ragioneSociale,

          ibanSubscriberFiscalCode: point.cfSottoscrittore,
          ibanSubscriberFirstName: point.nomeSottoscrittore,
          ibanSubscriberLastName: point.cognomeSottoscrittore,
          iban: point.iban,

          creditCardNumber: point.numeroCarta,
          creditCardCircuit: point.circuito,
          creditCardExpirationDate: point.dataScadenza,
          creditCardOwner: point.intestatarioCCR,
          aliasPan: point.aliasPan,

          oneShotCardRegistrationDate: point.dataRegistrazioneCartaCCOS,

          methodType: point.mdP,

          crecurr: point.crecurr,
          trecurr: point.trecurr
        }

        // Here we are getting th elist of all point with related payment method.
        // Grouping methods to get the list of unique methods
        const key = [
          point.mdP,
          point.iban,
          point.aliasPan,
          point.numeroCarta
        ].join('_')
        methods[key] = method
      }

      methods = Object.values(methods)

      commit('setMethods', {
        methods
      })
      return methods
    })
}
//Servizio per la restituzione delle informazioni relative ai piani di rientro su base codice cliente + codice punto.
const retrieveInfoPDR = (commit, rootGetters, params) => {
  return axiosACN
    .post(
      '/payment/retrieveInfoPDR',
      {
        clientCode: params.clientCode,
        prCode: params.prCode
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      commit('setInfoPdr', res.data)
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.errorCode) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }
      return res.data
    })
    .catch((err) => {
      console.error('Session error' + err)
      // HTTP Error Description
      // 400 	400 	Bad Request
      // 400	1030	codiceCliente is mandatory
      // 400	1031	prCode is mandatory

      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    methods: null,
    cardsList: null,
    infoPdr: []
  },
  mutations: {
    setMethods(state, { methods }) {
      state.methods = methods
    },
    setCardList(state, { cardsList }) {
      state.cardsList = cardsList
    },
    setInfoPdr(state, infoPdr) {
      let isRecurrent = ['CCR', 'SDD', 'DPAY']
      state.infoPdr.item = infoPdr.item.map((item) => {
        if (item.planStatus === constants.STATUS_PDR.CLOSED) {
          item.computedStatus = '3'
        } else if (item.planStatus === constants.STATUS_PDR.CANCELED) {
          item.computedStatus = '4'
        } else if (item.planStatus === constants.STATUS_PDR.ACTIVE) {
          if (
            item.instalments.filter(
              (instalment) => instalment.checkPaymentInstalment
            ).length > 0
          ) {
            item.computedStatus = '1'
          } else if (
            item.instalments.filter(
              (instalment) =>
                instalment.instalmentStatus === constants.STATUS_PDR.EXPIRED
            ).length > 0
          ) {
            item.computedStatus = '2'
          } else {
            item.computedStatus = '1'
          }
        }
        if (item.titles) {
          item.isPossibleToPay = false
        } else if (isRecurrent.includes(item.planPaymentMethod)) {
          if (
            item.instalments.filter(
              (instalment) =>
                instalment.instalmentStatus === constants.STATUS_PDR.EXPIRED &&
                !instalment.checkPaymentInstalment
            ).length > 0
          ) {
            item.isPossibleToPay = true
          } else {
            item.isPossibleToPay = false
          }
        } else if (
          item.instalments.filter(
            (instalment) =>
              instalment.instalmentStatus !== constants.STATUS_PDR.PAYED &&
              !instalment.checkPaymentInstalment
          ).length > 0
        ) {
          item.isPossibleToPay = true
        } else {
          item.isPossibleToPay = false
        }
        return item
      })
    },
    resetState(state) {
      state.methods = null
    }
  },
  getters: {
    getInfoPiani(state) {
      return state.infoPdr
    }
  },
  actions: {
    getMethods({ state, commit, rootGetters }, params) {
      return retrieveMethodsList(commit, rootGetters, params)
    },
    getCardsList({ state, commit, rootGetters }, params) {
      return retrieveCardsList(commit, rootGetters, params)
    },
    getPdrInfo({ state, commit, rootGetters }, params) {
      return retrieveInfoPDR(commit, rootGetters, params)
    },
    ibanRequest({ commit, rootGetters }, body) {
      return axiosACN
        .post('/fornitura/modificaMDPIban', body, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          // Manage business logic errors (coming with status 200 OK + errorcode)
          if (resp.data.errorCode) {
            switch (resp.data.errorCode) {
              case '102':
                throw Error('INVALID_FISCAL_CODE')
              case '103':
              case '105':
                throw Error('SAME_CASE_IN_PROGRESS')
              default:
                throw Error('UNKNOWN_ERROR')
            }
          }
          return resp.data
        })
        .catch((err) => {
          console.error('Session error' + err)
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    addMdPCreditCard({ commit, rootGetters }, body) {
      return axiosACN
        .post('/fornitura/modificaMDPCdc', body, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          // Manage business logic errors (coming with status 200 OK + errorcode)
          if (resp.data.errorCode) {
            switch (resp.data.errorCode) {
              default:
                throw Error('UNKNOWN_ERROR')
            }
          }
          return resp.data
        })
        .catch((err) => {
          console.error('Session error' + err)
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    isvalidabicab({ commit, rootGetters }, body) {
      return axiosACN
        .post('/sorgeniadataclient/isvalidabicab', body, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          return resp.status === 'OK'
        })
        .catch((err) => {
          console.error('Session error' + err)
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    getSiaUrlCCOSBollettone({ rootGetters }, params) {
      const iframeBaseUrl = window.location.origin

      return axiosACN
        .post(
          '/payment/V2/BollettoneCCOSNoAlias',
          {
            codiceCliente: params.clientCode,
            email: params.email,
            ricorda: params.rememberCard,
            urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
            urlDone: window.btoa(iframeBaseUrl + '/sia/done.html'),
            bollettinos: params.billsToPay
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw Error('NO_SIA_URL_ERROR')
          }
          return window.atob(res.data.urlRedirect)
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    getSiaUrlCCOS({ rootGetters }, params) {
      // const iframeBaseUrl = 'https://sia-iframe.firebaseapp.com'
      const iframeBaseUrl = window.location.origin

      return axiosACN
        .post(
          '/payment/V2/CCOSNoAlias',
          {
            codiceCliente: params.clientCode,
            email: params.email,
            idFattura: params.billId,
            prCode: params.prCode,
            ricorda: params.rememberCard,
            urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
            urlDone: window.btoa(iframeBaseUrl + '/sia/done.html')
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw Error('NO_SIA_URL_ERROR')
          }
          return window.atob(res.data.urlRedirect)
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    payCCOSAliasBollettone({ rootGetters }, params) {
      const iframeBaseUrl = window.location.origin

      return axiosACN
        .post(
          '/payment/V2/BollettoneCCOSAlias',
          {
            codiceCliente: params.clientCode,
            email: params.email,
            urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
            urlDone: window.btoa(iframeBaseUrl + '/sia/done.html'),
            aliasPan: params.aliasPan,
            aliasPanDataScad:
              params.aliasExpirationdate &&
              params.aliasExpirationdate.replace('/', ''),
            bollettinos: params.billsToPay
          },
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw Error('UNKNOWN_ERROR')
          }

          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    payCCOSAlias({ rootGetters }, params) {
      const iframeBaseUrl = window.location.origin

      return axiosACN
        .post(
          '/payment/V2/CCOSAlias',
          {
            codiceCliente: params.clientCode,
            email: params.email,
            idFattura: params.billId,
            prCode: params.prCode,
            urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
            urlDone: window.btoa(iframeBaseUrl + '/sia/done.html'),
            aliasPan: params.aliasPan,
            aliasPanDataScad:
              params.aliasExpirationdate &&
              params.aliasExpirationdate.replace('/', '')
          },
          {
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw Error('UNKNOWN_ERROR')
          }

          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    payGenericNoAlias({ rootGetters }, params) {
      if (params.instalment && params.instalment.length > 1) {
        return payMultiInstalmentNoAlias(rootGetters, params)
      } else {
        return payInstalmentNoAlias(rootGetters, params)
      }
    },
    payGenericAlias({ rootGetters }, params) {
      if (params.instalment && params.instalment.length > 1) {
        return payMultiInstalmentAlias(rootGetters, params)
      } else {
        return payInstalmentAlias(rootGetters, params)
      }
    }
  }
}
