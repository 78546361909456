<template>
  <layout header-size="mission">
    <template v-slot:header>
      <v-col cols="6" class="img-girl img-girl-reward pa-0" />
      <v-col v-if="mission" class="col-5 pa-0" align-self="center">
        <h1 class="" v-html="mission.end_page_title" />
      </v-col>
    </template>
    <div class="content-success">
      <v-row class="pb-0 success-title">
        <v-col
          >Hai risposto correttamente a
          {{ $route.params.nAnswersCorrect }} domande su
          {{ $route.params.totalAnswers }}.</v-col
        >
      </v-row>
      <v-row v-if="mission">
        <v-col cols="7">
          <div
            class="success-desc font-weight-regular"
            v-html="mission.end_page_text"
          />
        </v-col>
        <v-col cols="5" class="earned" align="right">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex flex-column align-start">
              <div class="earned__title">Hai guadagnato</div>
              <div class="earned__value mt-3">
                {{
                  missionValue(mission.wekit_action_id, mission.wekit_event_id)
                }}
              </div>
              <div class="earned__title">Green Coins</div>
            </div>
            <div>
              <v-img
                class="mt-3"
                height="72"
                src="@/assets/greeners/svg/greeners.svg"
                width="72"
              />
            </div>
          </div>
          <Achievement :steps="1" horizontal class="mt-6 mx-auto" />
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="7">
          <v-btn
            class="v-btn--shadowed btn text-black"
            x-large
            width="360"
            @click="
              $router.push({ name: goTo(mission.first_app_section_section) })
            "
          >
            {{ mission.first_cta_label || '' }}
          </v-btn>
        </v-col>
        <v-col cols="5">
          <v-btn
            class="v-btn--shadowed btn"
            color="brightGreen"
            x-large
            width="360"
            @click="
              $router.push({ name: goTo(mission.second_app_section_section) })
            "
          >
            {{ mission.second_cta_label || '' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </layout>
</template>

<script>
import Layout from './Layout'
import { mapGetters, mapActions } from 'vuex'
import { APP_SECTION_MAPPING } from '@/js/constants'

export default {
  name: 'SuccessQuiz',
  data() {
    return {
      mission: null,
      APP_SECTION_MAPPING: APP_SECTION_MAPPING
    }
  },
  components: {
    Layout,
    Achievement: () =>
      import(
        /* webpackChunkName: "components/loyalty/achivment" */ '@/views/loyalty/components/achievement'
      )
  },
  beforeMount() {
    this.getWekitMap()
    this.getQuiz().then((res) => {
      const condition = res.filter(
        (mission) => mission.id === this.$route.params.id
      )
      if (condition.length) {
        this.mission = condition[0]
        this.trackTapEvent({
          name: 'greeners_missionCompleted_view',
          params: {
            mission_id: this.mission.id,
            mission_title: this.mission.title
          }
        })
      }
    })
  },
  computed: {
    ...mapGetters('loyalty', ['quiz', 'wekitMap'])
  },
  methods: {
    ...mapActions('loyalty', ['getQuiz', 'getWekitMap']),
    ...mapActions('analytics', ['trackTapEvent']),
    missionValue(actionId, eventId) {
      let targetMission = this.wekitMap.filter(
        (mission) =>
          mission.action_id === actionId && mission.event_id === eventId
      )
      if (targetMission.length) {
        return targetMission[0].points
      } else return 0
    },
    goTo(id) {
      var destination = this.APP_SECTION_MAPPING[id]
      // switch (id) {
      //   case '/loyalty-diary-0':
      //     destination = 'loyaltyPage'
      //     break
      //   case '/loyalty-catalog-0':
      //     destination = 'catalogue'
      //     break
      //   default:
      //     destination = 'loyaltyPage'
      // }

      this.trackTapEvent({
        name: 'greeners_missionThankYouPageCTA_tap',
        params: { app_section: destination }
      })

      return destination
    }
  }
}
</script>

<style lang="scss" scoped>
.text-black {
  color: black;
}
.img-header {
  height: 260px;
  &__success {
    position: absolute;
    bottom: -100px;
  }
}

.img-girl-reward {
  background-size: 341px 326px !important;
}
.success-title {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1.3;
  width: 555px;
}
.success-desc {
  font-size: 16px;
  color: black;
}
p {
  font-size: 20px;
  width: 100%;
}
.content-success {
  width: 100%;
  margin-top: 60px;
}
.earned {
  color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 359px;
  justify-content: space-between;
  background-color: #f6f6f6;
  border-radius: 40px;
  padding: 40px;
  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  &__value {
    font-size: 52px;
    font-weight: bold;
  }
  .coins {
    font-size: 60px;
    font-weight: bold;
    line-height: 70px;
    min-width: 150px;
    div {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
a {
  color: $srg-green !important;
  font-size: 20px;
  font-weight: bold;
}
</style>
