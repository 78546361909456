import { dynamicSort } from '@/js/utils.js'

const filterWelcomekitByUserProfile = (welcomekits, userProfile, entity) => {
  let priorities = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 13, 14, 15, 17, 18, 20, 21, 22, 23
  ]
  welcomekits.forEach((item) => {
    item.valid = true
  })
  for (var priority of priorities) {
    for (var filterCriteria in filterObjectByProperty(
      filters,
      'priority',
      priority
    )) {
      for (var welcomekit of welcomekits.filter(
        (item) => item.valid === true
      )) {
        let profileFieldValueDecoder = filters[filterCriteria].field_decode
        let accumulator = filters[filterCriteria].accumulator
        let subProperty = filters[filterCriteria].subProperty
        let profileField = subProperty
          ? subProperty + '.' + filters[filterCriteria].profile_field
          : filters[filterCriteria].profile_field
        let profileDecoded = profileFieldValueDecoder(
          subProperty
            ? userProfile.data[subProperty]
            : userProfile.data[profileField]
        )
        let profileRaw = accumulator
          ? accumulator(
              subProperty
                ? userProfile.data[subProperty]
                : userProfile.data[profileField]
            )
          : null

        if (
          !filters[filterCriteria].check(
            profileRaw || profileDecoded,
            welcomekit
          )
        ) {
          welcomekit.valid = false
        } else {
          welcomekit.valid = true
          let filterPriority = 'filter_' + priority
          const wk = filters[filterCriteria]
          welcomekit[filterPriority] = wk.calculateValuePriority(
            profileDecoded,
            welcomekit,
            priority
          )
        }
      }
    }

    if (welcomekits.filter((item) => item.valid === true).length === 0) {
      break
    }
  }

  welcomekits.forEach((item) => {
    item.weightInt = Number(item.weight)
  })
  return (
    welcomekits
      .filter((item) => item.valid)
      .sort(dynamicSort('-weightInt'))[0] || null
  )
}

export { filterWelcomekitByUserProfile }

const filters = {
  filter_active: {
    priority: 0,
    profile_field: 'prodotto',
    subProperty: 'point',
    field_decode: (products) =>
      Array.from(new Set(products.map((item) => item.stato))),
    filterDescription: 'CONTAINS',
    check: (productsState, welcomekit) => {
      return productsState.some(
        (state) => state === 'ATTIVO' || state === 'IN ATTIVAZIONE'
      )
    },
    calculateValuePriority() {
      return 0
    }
  },
  filter_channel: {
    priority: 1,
    profile_field: 'canaleAcquisizione',
    subProperty: 'point',
    filterDescription: 'CONTAINS',
    accumulator: (products) =>
      products.map((item) => {
        item.cluster = productMap[item['prodotto'].trim().toUpperCase()] || ''
        return item
      }),
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) => item['canaleAcquisizione'].toUpperCase())
        )
      ),
    check: (profileChannels, welcomekit) => {
      if (
        !welcomekit.filter_channel ||
        welcomekit.filter_channel.length === 0
      ) {
        welcomekit.accumulator = profileChannels
        return true
      } else {
        welcomekit.accumulator = profileChannels.filter((prod) =>
          welcomekit.filter_channel
            .map((item) => (item ? item.toUpperCase() : ''))
            .includes(
              prod.canaleAcquisizione
                ? prod.canaleAcquisizione.toUpperCase()
                : ''
            )
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      let priorities = welcomekit.filter_channel.map(
        (item) => channelPriority[item.toUpperCase()]
      )
      return Math.min(...priorities)
    }
  },
  filter_age_min_months: {
    priority: 2,
    profile_field: 'aging',
    field_decode: (value) => Number(value),
    filterDescription: 'GREATER THAN OR EQUAL',
    check: (profileAging, welcomekit) => {
      return welcomekit.filter_age_min_months
        ? profileAging >= welcomekit.filter_age_min_months
        : true
    },
    calculateValuePriority(profileAging, welcomekit) {
      if (
        welcomekit.filter_age_min_months === '' ||
        Number(welcomekit.filter_age_min_months) === 0
      )
        return 1
      if (welcomekit.filter_age_min_months > 36) return 13
      else return Math.ceil(welcomekit.filter_age_min_months / 3)
    }
  },
  filter_age_max_months: {
    priority: 2,
    profile_field: 'aging',
    field_decode: (value) => Number(value),
    filterDescription: 'LOWER THAN OR EQUAL',
    check: (profileAging, welcomekit) => {
      return welcomekit.filter_age_max_months
        ? profileAging <= welcomekit.filter_age_max_months
        : true
    },
    calculateValuePriority(profileAging, welcomekit) {
      if (
        welcomekit.filter_age_max_months === '' ||
        welcomekit.filter_age_max_months > 36
      )
        return 13
      else return Math.ceil(welcomekit.filter_age_max_months / 3)
    }
  },
  filter_product: {
    priority: 3,
    profile_field: 'prodotto',
    subProperty: 'point',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map(
            (item) =>
              productMap[item['prodotto']] ||
              item['prodotto'] + ' without cluster'
          )
        )
      ),
    filterDescription: 'CONTAINS',
    check: (profileProducts, welcomekit) => {
      if (
        !welcomekit.filter_product ||
        welcomekit.filter_product.length === 0
      ) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter((prod) =>
          welcomekit.filter_product
            .map((item) => (item ? item.toUpperCase() : ''))
            .includes(prod.cluster ? prod.cluster.toUpperCase() : '')
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      let priorities = welcomekit.filter_product.map(
        (item) => productClusterPriorities[item.toUpperCase()]
      )
      return Math.min(...priorities)
    }
  },
  filter_payment_method: {
    priority: 4,
    profile_field: 'mdp',
    subProperty: 'point',
    filterDescription: 'CONTAINS',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) => getPaymentType(item['mdp'].toUpperCase()))
        )
      ),
    check: (profilePayments, welcomekit) => {
      if (
        !welcomekit.filter_payment_method ||
        welcomekit.filter_payment_method.length === 0
      ) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter((prod) =>
          welcomekit.filter_payment_method
            .map((item) => (item ? item.toUpperCase() : ''))
            .includes(
              getPaymentType(prod['mdp'] ? prod['mdp'].toUpperCase() : '')
            )
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      let priorities = welcomekit.filter_payment_method.map(
        (item) => paymentPriority[item.toUpperCase()]
      )
      return Math.min(...priorities)
    }
  },
  filter_invoicing: {
    priority: 5,
    profile_field: 'fatturazioneDigital',
    subProperty: 'point',
    filterDescription: 'CONTAINS',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) => item['fatturazioneDigital'].toUpperCase())
        )
      ),
    check: (invoices, welcomekit) => {
      if (
        !welcomekit.filter_invoicing ||
        welcomekit.filter_invoicing.length === 0
      ) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter((prod) =>
          welcomekit.filter_invoicing
            .map((item) => (item ? item.toUpperCase() : ''))
            .includes(
              prod.fatturazioneDigital
                ? prod.fatturazioneDigital.toUpperCase()
                : ''
            )
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      let priorities = welcomekit.filter_invoicing.map(
        (item) => invoicePriority[item.toUpperCase()]
      )
      return Math.min(...priorities)
    }
  },
  filter_supply_threshold: {
    priority: 6,
    profile_field: 'totalePuntiAttivi',
    filterDescription: 'bigger or equal than',
    field_decode: (value) => Number(value),
    check: (profileNumberOfPoints, welcomekit) => {
      return welcomekit.filter_supply_threshold
        ? profileNumberOfPoints >= welcomekit.filter_supply_threshold
        : true
    },
    calculateValuePriority(profileValue, welcomekit) {
      if (profileValue >= welcomekit.filter_supply_threshold) return 1
      else if (profileValue < welcomekit.filter_supply_threshold) return 2
    }
  },
  filter_request_type: {
    priority: 7,
    profile_field: 'tipoRichiesta',
    subProperty: 'point',
    filterDescription: 'CONTAINS',
    field_decode: (products) =>
      Array.from(
        new Set(products.map((item) => item['tipoRichiesta'].toUpperCase()))
      ),
    check: (requestTypes, welcomekit) => {
      if (
        !welcomekit.filter_request_type ||
        welcomekit.filter_request_type.length === 0
      ) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter((prod) =>
          welcomekit.filter_request_type
            .map((item) => (item ? item.toUpperCase() : ''))
            .includes(
              prod.tipoRichiesta ? prod.tipoRichiesta.toUpperCase() : ''
            )
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      let priorities = welcomekit.filter_request_type.map(
        (item) => requestTypePriority[item.toUpperCase()]
      )
      return Math.min(...priorities)
    }
  },
  filter_user_type: {
    priority: 8,
    profile_field: 'userType',
    filterDescription: 'equal to',
    field_decode: (value) => value,
    check: (userType, welcomekit) => {
      if (!welcomekit.filter_user_type) return true
      if (welcomekit.filter_user_type === '1')
        return userType.toUpperCase() === 'MIX'
      if (welcomekit.filter_user_type === '0')
        return userType.toUpperCase() === 'BUS'

      return false
    },
    calculateValuePriority(profileValue, welcomekit) {
      if (welcomekit.filter_user_type === '1') return 1
      else return 2
    }
  },
  filter_previous_suppliers: {
    priority: 10,
    profile_field: 'fornitorePrecedente',
    subProperty: 'point',
    filterDescription: 'contains',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) =>
            item['fornitorePrecedente']
              ? item['fornitorePrecedente'].toUpperCase()
              : ''
          )
        )
      ),
    check: (oldProvider, welcomekit) => {
      if (
        !welcomekit.filter_previous_suppliers ||
        welcomekit.filter_previous_suppliers.length === 0
      ) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter((prod) =>
          welcomekit.filter_previous_suppliers
            .map((item) => (item ? item.toUpperCase() : ''))
            .includes(
              prod.fornitorePrecedente
                ? prod.fornitorePrecedente.toUpperCase()
                : ''
            )
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_client_commodities: {
    priority: 11,
    profile_field: 'commodityCliente',
    subProperty: 'point',
    filterDescription: 'contains',
    field_decode: (products) =>
      Array.from(
        new Set(products.map((item) => item['commodityCliente']?.toUpperCase()))
      ),
    check: (commodityType, welcomekit) => {
      if (
        !welcomekit.filter_client_commodities ||
        welcomekit.filter_client_commodities.length === 0
      ) {
        return true
      } else {
        if (welcomekit.filter_client_commodities.includes('DUAL')) {
          welcomekit.filter_client_commodities = ['DUAL']
        } else if (
          welcomekit.filter_client_commodities.includes('GAS') &&
          welcomekit.filter_client_commodities.includes('ELE')
        ) {
          welcomekit.filter_client_commodities = ['DUAL']
        }
        return welcomekit.accumulator
          .filter(
            (item) => (item.commodityCliente || '').toUpperCase() !== 'FIBRA'
          )
          .every(
            (prod) =>
              welcomekit.filter_client_commodities[0].toUpperCase() ===
              (prod.commodityCliente ? prod.commodityCliente.toUpperCase() : '')
          )
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_residential_credit_score: {
    priority: 13,
    profile_field: 'fasciaRatingSic',
    filterDescription: 'contains',
    field_decode: (value) => value,
    check: (ratingSic, welcomekit) => {
      if (
        !welcomekit.filter_residential_credit_score ||
        ratingSic === '' ||
        welcomekit.filter_residential_credit_score.length === 0
      ) {
        return true
      } else {
        return welcomekit.filter_residential_credit_score
          .map((item) => (item ? item.toUpperCase() : ''))
          .includes(ratingSic ? ratingSic.toUpperCase() : '')
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_business_credit_score: {
    priority: 13,
    profile_field: 'busFasciaRating',
    filterDescription: 'contains',
    field_decode: (value) => value,
    check: (rating, welcomekit) => {
      if (
        !welcomekit.filter_business_credit_score ||
        rating === '' ||
        welcomekit.filter_business_credit_score.length === 0
      ) {
        return true
      } else {
        return welcomekit.filter_business_credit_score
          .map((item) => (item ? item.toUpperCase() : ''))
          .includes(rating ? rating.toUpperCase() : '')
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_ateco_codes: {
    priority: 14,
    profile_field: 'codiceAteco',
    filterDescription: 'contains',
    subProperty: 'point',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) =>
            item['codiceAteco'] ? item['codiceAteco'].toUpperCase() : ''
          )
        )
      ),
    check: (atecoCode, welcomekit) => {
      if (
        !welcomekit.filter_ateco_codes ||
        welcomekit.filter_ateco_codes.length === 0
      ) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter((prod) =>
          welcomekit.filter_ateco_codes
            .map((item) => (item ? item.code.toUpperCase() : ''))
            .includes(prod.codiceAteco ? prod.codiceAteco.toUpperCase() : '')
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_invoicing_type: {
    priority: 15,
    profile_field: 'fasceCurve',
    filterDescription: 'contains',
    subProperty: 'point',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) =>
            item['fasceCurve'] ? item['fasceCurve'].toUpperCase() : ''
          )
        )
      ),
    check: (fasceCurve, welcomekit) => {
      if (
        !welcomekit.filter_invoicing_type ||
        welcomekit.filter_invoicing_type.length === 0
      ) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter((prod) =>
          welcomekit.filter_invoicing_type
            .map((item) => (item ? item.toUpperCase() : ''))
            .includes(prod ? prod.fasceCurve.toUpperCase() : '')
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_ownership_type: {
    priority: 17,
    profile_field: 'titolarita',
    filterDescription: 'contains',
    subProperty: 'point',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) =>
            item['titolarita'] ? item['titolarita'].toUpperCase() : ''
          )
        )
      ),
    check: (titolarity, welcomekit) => {
      if (
        !welcomekit.filter_ownership_type ||
        welcomekit.filter_ownership_type.length === 0
      ) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter((prod) =>
          welcomekit.filter_ownership_type
            .map((item) => (item ? item.toUpperCase() : ''))
            .includes(prod.titolarita ? prod.titolarita.toUpperCase() : '')
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_territory: {
    priority: 18,
    profile_field: 'capFornitura',
    filterDescription: 'equal to',
    subProperty: 'point',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) =>
            item['capFornitura'] ? item['capFornitura'].toUpperCase() : ''
          )
        )
      ),
    check: (cap, welcomekit) => {
      if (!welcomekit.filter_territory) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter(
          (item) =>
            (item.capFornitura ? item.capFornitura.toUpperCase() : '') ===
            welcomekit.filter_territory.toUpperCase()
        )
        return welcomekit.accumulator.length > 0
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_greeners_subscription: {
    priority: 20,
    profile_field: 'greenersSubscribed',
    filterDescription: 'equal to',
    field_decode: (value) => value,
    check: (greenersSubscribed, welcomekit) => {
      if (!welcomekit.filter_greeners_subscription) {
        return true
      } else {
        return greenersSubscribed === welcomekit.filter_greeners_subscription
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_test_cfp: {
    priority: 21,
    profile_field: 'testCfp',
    filterDescription: 'equal to',
    field_decode: (value) => value,
    check: (testCfp, welcomekit) => {
      if (!welcomekit.filter_test_cfp) {
        return true
      } else {
        return testCfp === (welcomekit.filter_test_cfp ? 'Y' : 'N')
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_fiber_coverage: {
    priority: 22,
    profile_field: 'coperturaFibra',
    filterDescription: 'contains',
    field_decode: (products) =>
      Array.from(
        new Set(
          products.map((item) =>
            item['coperturaFibra'] ? item['coperturaFibra'].toUpperCase() : ''
          )
        )
      ),
    subProperty: 'point',
    check: (fiberCoverage, welcomekit) => {
      if (!welcomekit.filter_fiber_coverage) {
        return true
      } else {
        welcomekit.accumulator = welcomekit.accumulator.filter(
          (prod) =>
            prod.coperturaFibra ===
            (welcomekit.filter_fiber_coverage ? 'Y' : 'N')
        )
      }
      return welcomekit.accumulator.length > 0
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_fiber_supply: {
    priority: 23,
    profile_field: 'fornituraFibra',
    filterDescription: 'equal to',
    field_decode: (value) => value,
    check: (fornituraFibra, welcomekit) => {
      if (!welcomekit.filter_fiber_supply) {
        return true
      } else {
        return fornituraFibra === (welcomekit.filter_fiber_supply ? 'Y' : 'N')
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  },
  filter_new_fiber_coverage: {
    priority: 23,
    profile_field: 'nuovaCoperturaFibra',
    filterDescription: 'less or equal',
    field_decode: (value) => value,
    check: (newFibraAge, welcomekit) => {
      if (!welcomekit.filter_new_fiber_coverage) {
        return true
      } else {
        if (!newFibraAge) return false
        return (
          Number(newFibraAge) <= Number(welcomekit.filter_new_fiber_coverage)
        )
      }
    },
    calculateValuePriority(profileValue, welcomekit) {
      return 1
    }
  }
}

const filterObjectByProperty = (filters, propName, propValue) => {
  return Object.fromEntries(
    Object.entries(filters).filter(
      ([key, value]) => value[propName] === propValue
    )
  )
}

const getPaymentType = (mdp) => {
  let isRecurrent = ['CCR', 'SDD', 'DPAY'].includes(mdp.toUpperCase())
    ? 'RECURRENT'
    : 'NON RECURRENT'
  return isRecurrent
}

const productClusterPriorities = {
  'NUOVO BUSINESS FIX': 1,
  'NUOVO BUSINESS FLEX': 2,
  'VECCHIO BUSINESS FIX': 3,
  'VECCHIO BUSINESS FLEX': 4,
  'DIGITAL RESIDENZIALE': 5,
  'DIGITAL BUSINESS': 6,
  FIBER: 7,
  'RESIDENZIALE OLD': 8,
  PLACET: 9,
  'TUTELA SIMILE': 10
}

const channelPriority = {
  'RETE FISICA': 1,
  TELESELLING: 2,
  WEB: 3,
  'WEB BUSINESS': 4,
  'TUTELA SIMILE': 5
}

const paymentPriority = {
  RECURRENT: 1,
  'NON RECURRENT': 2
}

const invoicePriority = {
  CARTACEA: 1,
  DIGITALE: 2
}

const requestTypePriority = {
  RIATTIVAZIONE: 1,
  'NUOVO ALLACCIO': 2,
  'SWITCH IN': 3,
  'NUOVO ALLACCIO FIBRA': 4,
  ALTRO: 5
}

const productMap = {
  STD_NEGFIXEBUS010318: 'Digital Business',
  STD_NEGFIXEBUS010618: 'Digital Business',
  STD_NELFIXEBUS010318: 'Digital Business',
  STD_NELFIXEBUS010618: 'Digital Business',
  RES_NEGFIX0102: 'Digital Residenziale 12',
  RES_NEGFIX0103: 'Digital Residenziale 12',
  RES_NELFIX0102: 'Digital Residenziale 12',
  RES_NELFIX0103: 'Digital Residenziale 12',
  RES_NEGFIX0101: 'Digital Residenziale 12',
  RES_NELFIX0101: 'Digital Residenziale 12',
  RES_NEGFIX4Y0101: 'Digital Residenziale 12',
  RES_NELFIX4Y0101: 'Digital Residenziale 12',
  NELFIX24: 'Digital Residenziale 24',
  NEGFIX24: 'Digital Residenziale 24',
  NEFIBR0101: 'Fiber',
  'IMPFIX+01200520': 'Nuovo Business Fix',
  'IMPFIX+02200520': 'Nuovo Business Fix',
  IMPFIX01200520: 'Nuovo Business Fix',
  IMPFIX02200520: 'Nuovo Business Fix',
  IMPGASFIX01200520: 'Nuovo Business Fix',
  IMPFLEX01200520: 'Nuovo Business Flex',
  IMPFLEX02200520: 'Nuovo Business Flex',
  RES_EL_PCT01010118_F: 'Placet',
  RES_EL_PCT01010118_V: 'Placet',
  RES_GA_PCT01010118_F: 'Placet',
  RES_GA_PCT01010118_I: 'Placet',
  STD_EL_PCT01010118_F: 'Placet',
  STD_EL_PCT01010118_V: 'Placet',
  STD_GA_PCT01010118_F: 'Placet',
  STD_GA_PCT01010118_I: 'Placet',
  '10L01': 'Residenziale Old',
  AL01: 'Residenziale Old',
  ATG01: 'Residenziale Old',
  ATG02: 'Residenziale Old',
  ATG03: 'Residenziale Old',
  ATG04: 'Residenziale Old',
  CM01: 'Residenziale Old',
  ESMA01: 'Residenziale Old',
  L24C01: 'Residenziale Old',
  L24C02: 'Residenziale Old',
  L24T01: 'Residenziale Old',
  LCA01: 'Residenziale Old',
  LCA02C: 'Residenziale Old',
  RC01EE: 'Residenziale Old',
  RCGAS01: 'Residenziale Old',
  RES_PSG010117: 'Residenziale Old',
  RES_PSL010117: 'Residenziale Old',
  SCC01NB: 'Residenziale Old',
  SDC01: 'Residenziale Old',
  SDG01: 'Residenziale Old',
  SDPC01: 'Residenziale Old',
  SGCFIX0101: 'Residenziale Old',
  SGCFIX0102: 'Residenziale Old',
  SLCFIX0101: 'Residenziale Old',
  SLCFIX0201: 'Residenziale Old',
  TVWEB3Q11: 'Residenziale Old',
  ZP01: 'Residenziale Old',
  ZP02: 'Residenziale Old',
  BUS_TS010117: 'Tutela Simile',
  RES_TS010117: 'Tutela Simile',
  '365GAS150517': 'Vecchio Business Fix',
  '365GAS150914': 'Vecchio Business Fix',
  '365GAS260916': 'Vecchio Business Fix',
  BUS_PSG010117: 'Vecchio Business Fix',
  BUS_PSL010117: 'Vecchio Business Fix',
  CL1AFIX221014: 'Vecchio Business Fix',
  CL1AFIX260916: 'Vecchio Business Fix',
  CL1AFIXDT011018: 'Vecchio Business Fix',
  CL1BFIX221014: 'Vecchio Business Fix',
  CL1BFIX260916: 'Vecchio Business Fix',
  CL1BFIXDT011018: 'Vecchio Business Fix',
  CL2AFIX221014: 'Vecchio Business Fix',
  CL2AFIX260916: 'Vecchio Business Fix',
  CL2BFIX221014: 'Vecchio Business Fix',
  CL2BFIX260916: 'Vecchio Business Fix',
  CL2BFIXDT011018: 'Vecchio Business Fix',
  CL2SMIN110113: 'Vecchio Business Fix',
  CL3FIX221014: 'Vecchio Business Fix',
  CL3FIX260916: 'Vecchio Business Fix',
  CL3FIXDT011018: 'Vecchio Business Fix',
  EP01: 'Vecchio Business Fix',
  IMFA01: 'Vecchio Business Fix',
  OL1AFIX011119: 'Vecchio Business Fix',
  OL2AFIX011119: 'Vecchio Business Fix',
  OL3FIX011119: 'Vecchio Business Fix',
  PRZFISSOM: 'Vecchio Business Fix',
  RB01: 'Vecchio Business Fix',
  SDP01: 'Vecchio Business Fix',
  SDP02: 'Vecchio Business Fix',
  SGFIX0101: 'Vecchio Business Fix',
  SGFIX01201016: 'Vecchio Business Fix',
  'SLFIX+01011017': 'Vecchio Business Fix',
  'SLFIX+05011017': 'Vecchio Business Fix',
  'SLFIX+06011017': 'Vecchio Business Fix',
  SLFIX0101: 'Vecchio Business Fix',
  SLFIX01201016: 'Vecchio Business Fix',
  SLFIX0201: 'Vecchio Business Fix',
  SLFIX0301: 'Vecchio Business Fix',
  SLFIX0401: 'Vecchio Business Fix',
  SLFIX0501: 'Vecchio Business Fix',
  SLFIX05201016: 'Vecchio Business Fix',
  SLFIX0601: 'Vecchio Business Fix',
  SLFIX06201016: 'Vecchio Business Fix',
  ST08: 'Vecchio Business Fix',
  'ST08-PA': 'Vecchio Business Fix',
  'STD_NELFIX+01180717': 'Vecchio Business Fix',
  'STD_NELFIX+05180717': 'Vecchio Business Fix',
  'STD_NELFIX+06180717': 'Vecchio Business Fix',
  '3XTE01C': 'Vecchio Business Flex',
  '3XTE02C': 'Vecchio Business Flex',
  ASGAS020713: 'Vecchio Business Flex',
  CL1AFIX310315: 'Vecchio Business Flex',
  CL1BFIX310315: 'Vecchio Business Flex',
  CL1FIX031013: 'Vecchio Business Flex',
  CL1FIX110113: 'Vecchio Business Flex',
  CL1FIX110912: 'Vecchio Business Flex',
  CL1PUN140214: 'Vecchio Business Flex',
  CL1PUN201216: 'Vecchio Business Flex',
  CL1PUNMIN191011: 'Vecchio Business Flex',
  CL2AFIX031013: 'Vecchio Business Flex',
  CL2AFIX110113: 'Vecchio Business Flex',
  cl2Afix110912: 'Vecchio Business Flex',
  CL2AFIX310315: 'Vecchio Business Flex',
  CL2AFIXDT011018: 'Vecchio Business Flex',
  CL2APUN140214: 'Vecchio Business Flex',
  CL2APUN201216: 'Vecchio Business Flex',
  CL2BFIX031013: 'Vecchio Business Flex',
  CL2BFIX110113: 'Vecchio Business Flex',
  CL2BFIX110912: 'Vecchio Business Flex',
  CL2BFIX310315: 'Vecchio Business Flex',
  CL2BPUN140214: 'Vecchio Business Flex',
  CL2BPUN201216: 'Vecchio Business Flex',
  CL2FIX010212: 'Vecchio Business Flex',
  CL3FIX010212: 'Vecchio Business Flex',
  CL3FIX031013: 'Vecchio Business Flex',
  CL3FIX110113: 'Vecchio Business Flex',
  CL3FIX110912: 'Vecchio Business Flex',
  CL3FIX310315: 'Vecchio Business Flex',
  CL3PUN010318: 'Vecchio Business Flex',
  CL3PUN140214: 'Vecchio Business Flex',
  CL3PUN150115: 'Vecchio Business Flex',
  CL3PUN191011: 'Vecchio Business Flex',
  CL3PUN201216: 'Vecchio Business Flex',
  CL3PUN260916: 'Vecchio Business Flex',
  GDS1: 'Vecchio Business Flex',
  MF01: 'Vecchio Business Flex',
  MF02: 'Vecchio Business Flex',
  MF03: 'Vecchio Business Flex',
  PGAS020713: 'Vecchio Business Flex',
  PN01: 'Vecchio Business Flex',
  PN02: 'Vecchio Business Flex',
  'PRZFISSOM-PFOR': 'Vecchio Business Flex',
  RD01: 'Vecchio Business Flex',
  RDS01: 'Vecchio Business Flex',
  RDS02: 'Vecchio Business Flex',
  RDS03NF: 'Vecchio Business Flex',
  RDS04: 'Vecchio Business Flex',
  RDS05: 'Vecchio Business Flex',
  RDS06: 'Vecchio Business Flex',
  RDSG01: 'Vecchio Business Flex',
  SCB01NB: 'Vecchio Business Flex',
  SDS01: 'Vecchio Business Flex',
  SDS02: 'Vecchio Business Flex',
  'SLFLE+01011017': 'Vecchio Business Flex',
  'SLFLE+05011017': 'Vecchio Business Flex',
  'SLFLE+06011017': 'Vecchio Business Flex',
  SLFLE0101: 'Vecchio Business Flex',
  SLFLE01201016: 'Vecchio Business Flex',
  SLFLE0201: 'Vecchio Business Flex',
  SLFLE0301: 'Vecchio Business Flex',
  SLFLE0401: 'Vecchio Business Flex',
  SLFLE0501: 'Vecchio Business Flex',
  SLFLE05201016: 'Vecchio Business Flex',
  SLFLE0601: 'Vecchio Business Flex',
  SLFLE06201016: 'Vecchio Business Flex',
  SMGAS150517: 'Vecchio Business Flex',
  SMGAS150914: 'Vecchio Business Flex',
  SMGAS260916: 'Vecchio Business Flex',
  SPR01: 'Vecchio Business Flex',
  SPR02: 'Vecchio Business Flex',
  'ST08-CT': 'Vecchio Business Flex',
  'ST08-IA': 'Vecchio Business Flex',
  STD_CCA_PERC: 'Vecchio Business Flex',
  STD_CL3_PI: 'Vecchio Business Flex',
  STD_G_GRELEASE_07: 'Vecchio Business Flex',
  STD_LARGE07_CL1_PA: 'Vecchio Business Flex',
  STD_LARGE07_CL3_CT: 'Vecchio Business Flex',
  STD_LARGE08_CL1_IA: 'Vecchio Business Flex',
  STD_LARGE08_CL3_IA: 'Vecchio Business Flex',
  'STD_NELFLEX+01010419': 'Vecchio Business Flex',
  'STD_NELFLEX+05010419': 'Vecchio Business Flex',
  TND01: 'Vecchio Business Flex',
  TND02C: 'Vecchio Business Flex',
  TND03C: 'Vecchio Business Flex',
  'TRE - STD_CL3PA_170910': 'Vecchio Business Flex',
  TVWEB1Q13: 'Vecchio Business Flex',
  VP01: 'Vecchio Business Flex',
  VP02: 'Vecchio Business Flex',
  GRELEASE_07: 'Vecchio Business Non Standard',
  NNSTD_ELE_PRTFLMNGT: 'Vecchio Business Non Standard',
  NNSTD_GAS_PRTFLMNGT: 'Vecchio Business Non Standard',
  RISPENERGETICO: 'Vecchio Business Non Standard',
  STD_ENEL_QTA: 'Vecchio Business Non Standard',
  'STD_G_KB-G_KM-0708': 'Vecchio Business Non Standard',
  STD_GRELEASE_07: 'Vecchio Business Non Standard',
  STD_PC2005: 'Vecchio Business Non Standard',
  STD_PC2005NEW: 'Vecchio Business Non Standard'
}
