<template>
  <SpeedTest
    :isAbstract="isAbstract"
    v-if="
      promo.cta_action_type === 'APP_SECTION' &&
      promo.cta &&
      APP_SECTION_MAPPING[promo.app_section_section] === 'speedTest'
    "
  >
    <v-btn
      :color="promo.secondary_color"
      x-large
      class="v-btn--shadowed"
      @click="
        trackTapEvent({
          name: 'vetrina_promo_tap',
          params: { promo_id: promo.id, promo_title: promo.title }
        })
      "
    >
      {{ promo.cta }}
    </v-btn>
  </SpeedTest>

  <v-btn
    v-else-if="
      promo.cta_action_type === 'APP_SECTION' &&
      promo.cta &&
      APP_SECTION_MAPPING[promo.app_section_section] === 'carbonFootprint'
    "
    :color="promo.secondary_color"
    x-large
    class="v-btn--shadowed"
    @click="openFootprint()"
  >
    {{ promo.cta }}
  </v-btn>

  <v-btn
    v-else-if="
      promo.cta_action_type === 'APP_SECTION' &&
      promo.cta &&
      APP_SECTION_MAPPING[promo.app_section_section] === 'beyondEnergy'
    "
    :color="promo.secondary_color"
    x-large
    class="v-btn--shadowed"
    @click="openBeyondEnergy()"
  >
    {{ promo.cta }}
  </v-btn>

  <router-link
    v-else-if="promo.cta_action_type === 'APP_SECTION' && promo.cta"
    :to="{
      name: APP_SECTION_MAPPING[promo.app_section_section],
      params: { from: 'homepage-promo' }
    }"
    @click="
      trackTapEvent({
        name: 'vetrina_promo_tap',
        params: { promo_id: promo.id, promo_title: promo.title }
      })
    "
  >
    <v-btn :color="promo.secondary_color" x-large class="v-btn--shadowed">
      {{ promo.cta }}
    </v-btn>
  </router-link>

  <a
    v-else-if="promo.cta_action_type === 'WEB_LINK' && promo.cta"
    :href="promo.url"
    target="_blank"
  >
    <v-btn
      :color="promo.secondary_color"
      x-large
      class="v-btn--shadowed"
      @click="
        trackTapEvent({
          name: 'vetrina_promo_tap',
          params: { promo_id: promo.id, promo_title: promo.title }
        })
      "
    >
      {{ promo.cta }}
    </v-btn>
  </a>
</template>
<script>
import { APP_SECTION_MAPPING } from '@/js/constants'
import { mapActions } from 'vuex'

import SpeedTest from '@/components/speedTest/'

export default {
  name: 'ButtonsPromo',
  props: {
    promo: { type: Object },
    openFootprint: { type: Function },
    openBeyondEnergy: { type: Function },
    isAbstract: { type: Boolean, default: false }
  },
  components: {
    SpeedTest
  },
  data() {
    return {
      APP_SECTION_MAPPING: APP_SECTION_MAPPING
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>
