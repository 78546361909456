import { OVERALL_STATUS } from '@/js/constants.js'

const state = {
  installation: {},
  addressSelected: {},
  dashboard: {
    loaded: false,
    lastUpdate: 'N/A',
    overallStatus: OVERALL_STATUS.NO_ANOMALY, // NO_ANOMALY, ALARM, VALIDATION_ERROR, NOT_COMMUNICATING, UNKNOWN
    produzione: {
      title: 'Produzione',
      value: 'N/A', // K02
      iconName: 'fotovoltaico',
      items: [
        {
          title: 'Autoconsumati',
          value: 'N/A', // K06
          iconName: 'fulmine'
        },
        {
          title: 'Immessi in rete',
          value: 'N/A', // K05
          iconName: 'traliccio'
        },
        {
          title: 'Caricati in batteria',
          value: 'N/A', // K03
          iconName: 'stilo'
        }
      ]
    },
    consumo: {
      title: 'Consumo',
      value: 'N/A', // K01
      iconName: 'casa',
      items: [
        {
          title: 'Autoconsumati',
          value: 'N/A', // K06
          iconName: 'fulmine'
        },
        {
          title: 'Prelevati dalla rete',
          value: 'N/A', // K07
          iconName: 'traliccio'
        },
        {
          title: 'Prelevati dalla batteria',
          value: 'N/A', // K04
          iconName: 'stilo'
        }
      ]
    },
    batteria: {
      carica: 'N/A', // K08
      value: 'N/A' // K03-K04
    },
    rete: {
      value: 'N/A' // K05-K07
    },
    autosufficienzaValue: 'N/A', // K35
    chartProduzione: {
      title: 'Produzione giornaliera',
      description:
        'Qui trovi i dati di produzione del tuo impianto e di efficienza energetica della tua casa.',
      labelButton: 'Monitora la produzione',
      apexLabel: 'Prodotti oggi',
      apexTotal: 'N/A', // K17
      stats: [
        {
          type: 'icon-green-light',
          title: 'Energia autoconsumata',
          percentage: 'N/A', // K26
          value: 'N/A' // K24
        },
        {
          type: 'icon-green',
          title: 'Energia immessa in rete',
          percentage: 'N/A', // K28
          value: 'N/A' // K27
        },
        {
          type: 'icon-green-blue',
          title: 'Energia caricata in batteria',
          percentage: 'N/A', // K19
          value: 'N/A' // K18
        }
      ]
    },
    chartConsumo: {
      title: 'Consumo giornaliero',
      description:
        'Qui trovi i dati di consumo del tuo impianto fotovoltaico e di prelievo di energia dalla batteria e dalla rete.',
      labelButton: 'Monitora il consumo',
      apexLabel: 'Consumati oggi',
      apexTotal: 'N/A', // K16
      stats: [
        {
          type: 'icon-green-light',
          title: 'Energia autoconsumata',
          percentage: 'N/A', // K25
          value: 'N/A' // K24
        },
        {
          type: 'icon-green',
          title: 'Energia prelevata dalla rete',
          percentage: 'N/A', // K30
          value: 'N/A' // K29
        },
        {
          type: 'icon-green-blue',
          title: 'Energia scaricata dalla batteria',
          percentage: 'N/A', // K21
          value: 'N/A' // K20
        }
      ]
    },
    chartRisparmio: {
      title: "Risparmio dall'inizio",
      description:
        "Qui trovi i benefici economici derivanti dall'utilizzo del tuo impianto fotovoltaico.",
      labelButton: 'Monitora il risparmio',
      apexLabel: 'Risparmio complessivo',
      apexTotal: 'N/A', // K43,
      stats: [
        {
          type: 'icon-green-light',
          title: 'Incentivi GSE',
          percentage: 'N/A', // K41
          value: 'N/A' // K38
        },
        {
          type: 'icon-green',
          title: 'Risparmio in bolletta',
          percentage: 'N/A', // K42
          value: 'N/A' // K40
        }
      ]
    },
    cfp: 'N/A', // K32,
    inFromOutBattery: 'IN', // IN, OUT or NONE
    inFromOutNet: 'IN', // IN, OUT or NONE
    inFromOutProduction: 'IN', // IN or NONE
    inFromOutConsumation: 'OUT' // OUT or NONE
  },
  productionData: {},
  productionDataTemp: {},
  enviromentallImpanct: {},
  monitoringSavingChart: {},
  monitoringConsumptionChart: {},
  monitoringSavingChart: {},
  productionControl: {},
  consumptionControl: {},
  savingControl: {},
  enviromentallImpactControl: {},
  photovoltaicData: [],
  flagEnterByDeeplink: true,
  addressSelectedDasboard: {}
}
export { state }
