var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',[_c('div',{staticClass:"join-team"},[_c('v-row',{staticClass:"mb-12"},[_c('v-col',{attrs:{"cols":"7","align-self":"center"}},[_c('h1',{staticClass:"mb-8",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('h3',[_vm._v(" Adesso che sei parte della community colora l’immagine e scopri se hai vinto il premio di benvenuto. ")]),_c('div',{staticClass:"skip",on:{"click":function($event){_vm.trackTapEvent({
              name: 'greeners_welcomeIWSkip_tap',
              params: {
                clientCode: _vm.mainClientLoyaltySelected.clientOwnerCode,
                clientType: _vm.mainClientLoyaltySelected.clientOwnerType
              }
            }),
              _vm.$router.back()}}},[_vm._v(" Lo faccio dopo ")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"cows"},[_c('scratch-card',{key:_vm.renderCount,staticClass:"cows__img",attrs:{"cardWidth":490,"cardHeight":490,"finishPercent":53,"imageUrl":require('@/assets/greeners/instantwin-nocolor.svg'),"brushUrl":_vm.brushUrl,"forceReveal":_vm.colorAll,"onComplete":_vm.getLotteryResult}},[_c('v-img',{attrs:{"height":"490","width":"490","src":require("@/assets/greeners/instantwincolor.svg")}})],1),_c('v-btn',{staticClass:"btn-colorAll primary",attrs:{"height":"54","width":222},on:{"click":function($event){_vm.colorAll = true}}},[_vm._v(" Colora immagine ")])],1)])],1)],1),_c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.6","max-width":_vm.dialogWindowWidth},model:{value:(_vm.instantWinGame),callback:function ($$v) {_vm.instantWinGame=$$v},expression:"instantWinGame"}},[_c('v-card',{staticClass:"instantWinGame--dialog",style:(_vm.dialogWindowHeight)},[(_vm.value === 0)?_c('v-row',[_c('v-col',{staticClass:"iw",attrs:{"align":"center"}},[_c('img',{staticClass:"instantWinGame--status",attrs:{"width":"73","src":require('@/assets/greeners/svg/ic_sad_red.svg')}}),_c('h1',{staticClass:"mb-3"},[_vm._v("Purtroppo non hai vinto!")]),_c('h2',{staticClass:"mb-6"},[_vm._v("Non temere: molte altre occasioni ti aspettano")]),_c('v-btn',{staticClass:"v-btn--shadowed normal",attrs:{"color":"brightGreen","x-large":"","width":360},on:{"click":function($event){_vm.$router.back(),
                _vm.trackTapEvent({
                  name: 'greeners_welcomeIWClose_tap',
                  params: {
                    clientCode: _vm.mainClientLoyaltySelected.clientOwnerCode,
                    clientType: _vm.mainClientLoyaltySelected.clientOwnerType
                  }
                })}}},[_vm._v(" Esplora Greeners ")])],1)],1):_vm._e(),(_vm.value === 1)?_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"iw",attrs:{"cols":"5"}},[_c('img',{staticClass:"instantWinGame--status",attrs:{"width":"90","height":"90","src":require('@/assets/ic_smile_green.png')}}),_c('h1',{staticClass:"mb-6"},[_vm._v(" Complimenti"),_c('br'),_vm._v(" hai vinto! ")]),_c('h2',{domProps:{"innerHTML":_vm._s(_vm.wonText)}})]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-img',{attrs:{"src":_vm.wonGift}})],1),_c('v-row',{staticClass:"mb-2 mt-10"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"v-btn--shadowed normal",attrs:{"color":"white","x-large":"","width":"265"},on:{"click":function($event){_vm.$router.back(),
                  _vm.trackTapEvent({
                    name: 'greeners_welcomeIWSkipReq_tap',
                    params: {
                      clientCode: _vm.mainClientLoyaltySelected.clientOwnerCode,
                      clientType: _vm.mainClientLoyaltySelected.clientOwnerType
                    }
                  })}}},[_vm._v(" Lo richiedo dopo ")])],1),_c('v-col',{attrs:{"cols":"6","align":"center"}},[_c('v-btn',{staticClass:"v-btn--shadowed btn normal",attrs:{"align":"center","color":"brightGreen","x-large":"","width":265},on:{"click":function($event){_vm.$router.push({ name: 'shippingPage' }),
                  _vm.trackTapEvent({
                    name: 'greeners_welcomeIWReqPrize_tap',
                    params: {
                      clientCode: _vm.mainClientLoyaltySelected.clientOwnerCode,
                      clientType: _vm.mainClientLoyaltySelected.clientOwnerType
                    }
                  })}}},[_vm._v(" Si, voglio il premio ")])],1)],1)],1):_vm._e(),(_vm.value === 3)?_c('v-row',{staticClass:"pt-12 pl-9"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-4"},[_vm._v("OPS! C’è stato un errore!")]),_c('p',[_vm._v("Accedi al tuo Diario per poter partecipare di nuovo!")])]),_c('v-col',{staticClass:"pt-1 ml-n4",attrs:{"cols":"12","align":"center"}},[_c('v-btn',{staticClass:"v-btn--shadowed btn normal",attrs:{"align":"center","color":"brightGreen","x-large":"","width":"265"},on:{"click":function($event){return _vm.$router.push({ name: 'loyaltyPage' })}}},[_vm._v(" Vai al Diario ")])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }