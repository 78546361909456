<template>
  <div class="address-owner">
    <v-row>
      <v-col cols="10">
        <v-autocomplete
          append-icon=""
          return-object
          autocomplete="off"
          item-text="comune"
          item-value="comuneEgon"
          label="Città"
          @change="onCitySelected"
          :items="items.city"
          :loading="isLoading.city"
          :search-input.sync="searchCity"
          v-model="city"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          readonly
          append-icon=""
          return-object
          item-text="provincia"
          item-value="provinciaEgon"
          autocomplete="off"
          label="Provincia"
          :items="items.province"
          v-model="city"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          append-icon=""
          return-object
          autocomplete="off"
          item-text="via"
          item-value="viaEgon"
          :items="items.address"
          :loading="isLoading.address"
          :search-input.sync="searchAddress"
          label="Indirizzo"
          v-model="address"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          append-icon=""
          return-object
          autocomplete="off"
          item-text="fullCivico"
          item-value="civicoEgon"
          :items="items.civicNumber"
          :loading="isLoading.civicNumber"
          :search-input.sync="searchCivicNumber"
          label="Civico"
          v-model="civicNumber"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <MYSButton
        v-if="isFormValid"
        width="260"
        buttonClass="primaryButton"
        @buttonClick="$emit('nextStep', 'upsellingcom_offert_open')"
        buttonText="Continua"
      />
    </v-row>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
import MYSButton from '../../../../components/global/MYSButton.vue'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'addressOwner',
  components: { MYSButton },
  data: function () {
    return {
      city: null,
      province: null,
      address: null,
      civicNumber: null,
      searchCity: null,
      searchProvince: null,
      searchAddress: null,
      searchCivicNumber: null,
      isLoading: {
        city: false,
        province: false,
        address: false,
        civicNumber: false
      },
      items: { address: [], civicNumber: [], province: [], city: [] }
    }
  },
  watch: {
    async searchCity(comune) {
      let comuni = await this.getEgonComune(comune)
      this.items.city = comuni
    },
    async searchAddress(via) {
      let vie = await this.getEgonVia({
        comuneEgon: this.city.comuneEgon,
        via: via
      })
      this.items.address = vie
    },
    async searchCivicNumber(civico) {
      let civici = await this.getEgonCivico({
        civico: civico,
        viaEgon: this.address.viaEgon
      })
      this.items.civicNumber = civici
    }
  },
  methods: {
    ...mapActions('egon', ['getEgonComune', 'getEgonVia', 'getEgonCivico']),
    onCitySelected(city) {
      this.items.province = [city]
    }
  },
  computed: {
    isFormValid() {
      return !this.v$.$invalid
    }
  },
  validations: {
    city: { required },
    province: { required },
    address: { required },
    civicNumber: { required }
  }
}
</script>
<style lang="scss" scoped>
.address-owner {
  width: 60%;
}
.blu-btn {
  background-color: $srg-blue;
}
</style>
