var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{key:_vm.dialogKey,attrs:{"max-width":500,"persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"billsRecapDialog pdrDialogRate"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-8"},[_c('div',{staticClass:"HeadingsSubtitleL"},[_vm._v(" Rate piani di rientro "+_vm._s(_vm.pdrAttivo?.planEndDate.replaceAll('/', '.'))+" ")]),_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/ic_chevron_top_black.png")},on:{"click":function($event){_vm.show = false}}})]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-list',{staticClass:"scrollableList",attrs:{"two-line":""}},[_c('div',{staticClass:"d-flex align-center mb-4"},[_c('div',{staticClass:"BodyL-Strong"},[_vm._v(_vm._s(_vm.intestatario))]),(_vm.clientDetails.clientType === 'RES')?_c('img',{staticClass:"supply__owner-icon",attrs:{"src":_vm.IconOwner,"width":"22"}}):_vm._e(),(_vm.clientDetails.clientType !== 'RES')?_c('img',{staticClass:"supply__owner-icon",attrs:{"src":_vm.IconBusiness,"width":"22"}}):_vm._e()]),_c('div',{class:`checkbox all d-flex align-center ${
              _vm.selectAll ? 'selected' : ''
            }`,on:{"click":_vm.selectAllAction}},[_c('span',{staticClass:"BodyL"},[_vm._v("Seleziona tutto")])]),_vm._l((_vm.listToPay),function(rata,index){return _c('div',{key:index},[_c('v-list-item',{staticClass:"pr-4 pl-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('div',{class:`checkbox d-flex align-center ${
                      rata?.checked ? 'selected' : ''
                    } ${rata?.disabled ? 'disabled' : ''}`,attrs:{"id":rata?.idInstalment},on:{"click":function($event){return _vm.addPerPagare(rata, index)}}},[_c('span',{staticClass:"BodyL"},[_vm._v("Rata "+_vm._s(rata?.instalmentDueDate.replaceAll('/', '.')))])]),(
                      rata?.instalmentStatus === _vm.Consts.STATUS_PDR.EXPIRED
                    )?_c('span',{staticClass:"CaptionL upper color--purple ml-4"},[_c('strong',[_vm._v(_vm._s(_vm.Consts.TRANSLATE_STATUS_PDR[ rata?.instalmentStatus ].toUpperCase()))])]):_vm._e()])],1),_c('v-list-item-action',[_c('span',{staticClass:"BodyL-Strong"},[_vm._v(" € "+_vm._s(_vm.getTotal(rata?.residualInstalmentAmount))+" ")])])],1),_c('v-divider')],1)})],2)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{staticClass:"paymentButton",attrs:{"x-large":"","disabled":_vm.totaleDaPagare <= 0},on:{"click":_vm.pay}},[_vm._v(" "+_vm._s(_vm.totaleDaPagare > 0 ? `Paga € ${_vm.getTotal(_vm.totaleDaPagare)}` : 'Paga')+" ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }