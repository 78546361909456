<template>
  <div class="offert" v-if="upsellingInfo">
    <h3 class="mb-2 HeadingsSubtitleL">
      {{ addressCovered.addressComplete }}
    </h3>
    <div class="d-flex align-center mb-7">
      <div class="BodyL">Raggiunto da connessione {{ connectionType }}</div>
      <img
        src="../../../assets/fttc.svg"
        class="ml-2"
        v-if="connectionType === 'FTTC'"
        width="32"
      />
      <img
        src="../../../assets/ftth.svg"
        class="ml-2"
        v-if="connectionType === 'FTTH'"
        width="32"
      />
      <img
        @click="openDialogConnection"
        class="ml-2"
        src="../../../assets/icons/info.svg"
        width="32"
      />
    </div>
    <div v-if="isSpeedChoice" class="offert__doubleBox">
      <div class="BodyXL-Strong">Scegli {{ upsellingInfo.name }}</div>
      <div class="BodyL color--neutri-neutral-60 mb-4">
        Seleziona l’opzione più adatta
      </div>
      <div class="boxContainer mb-16">
        <div
          :class="`boxContainer__item ${
            offerSelected.speedOffer === '1000' ? 'selected' : ''
          }`"
          @click="setOfferSelected(offerMin)"
        >
          <div class="CaptionM mb-2 color--blue">STUDIO E LAVORO</div>
          <div class="d-flex">
            <div class="CaptionS mr-8">
              <p class="color--grey mb-0">DOWNLOAD FINO A</p>
              <div class="BodyL-Strong mt-1 color--black">1 Giga</div>
            </div>
            <div class="CaptionS">
              <p class="color--grey mb-0">UPLOAD FINO A</p>
              <div class="BodyL-Strong mt-1 color--black">
                {{ addressCovered.typology === 'FTTH_GPON' ? 100 : 300 }} Mega
              </div>
            </div>
          </div>
        </div>
        <div
          :class="`boxContainer__item ${
            offerSelected.speedOffer === '2500' ? 'selected' : ''
          }`"
          @click="setOfferSelected(offerMax)"
        >
          <div class="CaptionM mb-2 color--blue">STREAMING E GAMING</div>
          <div class="d-flex">
            <div class="CaptionS mr-8">
              <p class="color--grey mb-0">DOWNLOAD FINO A</p>
              <div class="BodyL-Strong mt-1 color--black">
                {{ $filters.formatSpeed(addressCovered.speedVal) }}
              </div>
            </div>
            <div class="CaptionS">
              <p class="color--grey mb-0">UPLOAD FINO A</p>
              <div class="BodyL-Strong mt-1 color--black">
                {{ $filters.formatSpeed(getUploadSpeed(addressCovered.speed)) }}
              </div>
            </div>
          </div>
        </div>
        <template v-if="Object.keys(this.offerSelected).length > 0">
          <div class="boxContainer__details mb-1">
            <div class="CaptionM">SPESA MENSILE</div>
            <div class="BodyL line-through">
              {{ $filters.replaceDecimalPoint(notDiscoutedPrice) }}
              {{ upsellingInfo.price_currency || '' }}
            </div>
          </div>
          <div class="boxContainer__details">
            <div class="promo CaptionM">
              {{
                offerSelected.isScalino
                  ? upsellingInfo.price_step_label
                  : client.isDUAL
                  ? 'Promo per chi ha 2 utenze'
                  : 'Promo per i nostri clienti'
              }}
            </div>
            <div class="price">
              <span class="HeadingsSubtitleXXL">{{
                discoutedPrice.split('.')[0]
              }}</span>
              <span class="BodyL-Strong"
                >,{{ discoutedPrice.split('.')[1]
                }}{{ upsellingInfo.price_currency || '' }}</span
              >
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="offert__singleBox">
      <h3 v-text="`${upsellingInfo.name}` || ''" />
      <div class="d-flex justify-space-between align-baseline">
        <div class="text">
          {{
            offerSelected.isScalino
              ? upsellingInfo.price_step_label
              : client.isDUAL
              ? 'Promo per chi ha 2 utenze'
              : 'Promo per i nostri clienti'
          }}
        </div>
        <div class="price">
          <h3>
            <span>{{ $filters.replaceDecimalPoint(discoutedPrice) }}</span>
            <span
              >{{ upsellingInfo.price_currency || '' }} /
              {{ upsellingInfo.price_period || '' }}</span
            >
          </h3>
          <h5>
            <span>{{ $filters.replaceDecimalPoint(notDiscoutedPrice) }}</span>
            <span
              >{{ upsellingInfo.price_currency || '' }} /
              {{ upsellingInfo.price_period || '' }}</span
            >
          </h5>
        </div>
      </div>
    </div>

    <template v-if="Object.keys(offerSelected).length === 0">
      <div class="noDetails BodyL mb-14">
        Per visualizzare i dettagli dell’offerta fibra seleziona l’opzione più
        adatta
      </div>
    </template>
    <template v-else>
      <div class="offert__description">
        <div class="title d-flex align-center HeadingsSubtitleM">
          Dettaglio dell’offerta
        </div>
        <div class="offert__conditions">
          <div class="condition" v-for="(detail, i) in details" :key="i">
            <div
              @click="
                ;(conditionIndex = i),
                  (dialogOfferDetail = true),
                  trackTapEvent({
                    name: 'upsellingv2_offerAccordion_tap',
                    params: { offer_accordion_title: details[i].title || '' }
                  })
              "
              class="condition__title ButtonS"
            >
              {{ detail.title || '' }}
            </div>
            <!-- <div
              class="condition__plus"
            ></div> -->
          </div>
        </div>
      </div>
      <div
        v-if="documentLoaded"
        class="ButtonS contract"
        @click="openDialogTermCond"
      >
        Condizioni generali di contratto
      </div>
      <h5 v-else class="contract"><strong>Caricamento...</strong></h5>

      <div class="d-flex flex-column">
        <div v-if="upsellingInfo.enable_voucher_fiber === '1'">
          <v-checkbox class="voucherFibra" v-model="flagVoucherFibra">
            <template v-slot:label>
              <span class="label BodyL"
                >Richiedi il voucher Famiglie</span
              ></template
            >
          </v-checkbox>
        </div>
      </div>
    </template>
    <div class="offert__btn">
      <v-btn
        color="primary"
        x-large
        width="343"
        class="v-btn--shadowed"
        @click="goToModem"
        :disabled="Object.keys(this.offerSelected).length === 0"
        >Inizia la sottoscrizione</v-btn
      >
      <v-btn text x-large color="primary" width="260" @click="goToChat"
        >Parla con un consulente</v-btn
      >
    </div>
    <v-dialog max-width="652" v-model="dialogFTTH">
      <v-row>
        <v-col class="dialog-connection">
          <img
            @click="dialogFTTH = false"
            src="../../../assets/ic_chevron_top_black.png"
          />
          <h2>Cos’è la connessione FTTH</h2>
          <div class="description">
            Da oggi puoi avere con Sorgenia
            <strong>la nostra fibra ultraveloce e 100% green</strong> con la
            miglior connessione che tu abbia mai provato finora.<br /><br />
            <strong>Tecnologia FTTH, Fiber To The Home</strong>, la fibra che
            per la prima volta arriva fino a casa tua! Con velocità fino a 1000
            Mb/s in download e 100 Mb/s in upload by Open Fiber.<br /><br />
            L'intera rete di connessione dalla centrale alla tua abitazione è in
            fibra ottica, consentendo il massimo delle performance, la rete più
            efficiente e con la miglior stabilità di connessione.<br /><br />
            Grazie a questa tecnologia ora hai tutto il potenziale di internet:
            una trasmissione dati più veloce, efficiente ed affidabile,
            interamente realizzata in fibra ottica, in grado di supportare tutte
            le potenzialità delle nuove tecnologie future.
          </div>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog max-width="555" v-model="dialogOfferDetail">
      <v-row>
        <v-col class="dialog-offer" v-if="details.length > 0">
          <div class="d-flex justify-space-between align-start mb-6">
            <h3>{{ details[conditionIndex].title || '' }}</h3>
            <img
              class="close"
              @click="dialogOfferDetail = false"
              src="../../../assets/ic_chevron_top_black.png"
            />
          </div>
          <div
            class="description"
            v-html="details[conditionIndex].body || ''"
          />
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog max-width="652" v-model="dialogFTTC">
      <v-row>
        <v-col class="dialog-connection">
          <img
            @click="dialogFTTC = false"
            src="../../../assets/ic_chevron_top_black.png"
          />
          <h2>Cos’è la connessione FTTC</h2>
          <div class="description">
            Da oggi puoi avere con Sorgenia la nostra fibra con la miglior
            connessione disponibile per casa tua.<br /><br />
            <strong>Tecnologia FTTC, Fiber To The Cabinet</strong>, La fibra
            ottica va dalla centrale fino all’armadio in strada più vicino alla
            tua abitazione, sfruttando fin lì tutta la potenza della fibra
            ottica; poi la connessione viene veicolata nei cavi in rame già
            presenti, garantendo velocità fino a 10 volte superiori all’
            ADSL.<br /><br />
            La rete di connessione dalla centrale al cabinet è fibra ottica,
            consentendo il massimo delle performance, la rete più efficiente e
            con la stabilità di connessione migliorata rispetto al solo rame.<br /><br />
            Grazie a questa tecnologia sfrutti al meglio il potenziale di
            internet: una trasmissione dati più veloce, efficiente ed affidabile
            in grado di supportare meglio tutte le potenzialità delle nuove
            tecnologie future.
          </div>
        </v-col>
      </v-row>
    </v-dialog>
    <CallMeBack
      :requestingClient="client"
      v-model="dialog"
      :landlineOpen="landlineOpen"
      fromPage="fibra"
      step="offert"
    />
    <PdfViewer
      :showDialog="dialogTermCond"
      :url="contractDataUrl"
      @closing-pdfViewer="dialogTermCond = false"
      :attachTo="'body'"
      height="600px"
    />
  </div>
</template>
<script>
import CallMeBack from '@/components/callMeBack/CallMeBackDialogNew'
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { replaceDecimalPoint } from '@/js/utils'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'offertComponent',
  data() {
    return {
      // offerMono: {},
      // offerDual: {},
      offerSelected: {},
      offerMin: {},
      offerMax: {},
      dialog: false,
      flagVoucherFibra: false,
      landlineOpen: false,
      documentLoaded: false,
      contractDataUrl: '',
      dialogTermCond: false,
      dialogFTTH: false,
      dialogFTTC: false,
      dialogOfferDetail: false,
      conditionIndex: 0
    }
  },
  components: {
    CallMeBack,
    PdfViewer
  },
  watch: {
    flagVoucherFibra(newVal) {
      this.trackTapEvent({
        name: 'upsellingv2_voucher_tap',
        params: { checked: newVal }
      })
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', [
      'getFlagVoucherFibra',
      'addressCovered',
      'client',
      'connectionType'
    ]),
    ...mapGetters('account', ['filteredClientDetails']),
    ...mapGetters('offers', ['upsellingInfo', 'offers']),
    isVDSL() {
      return this.addressCovered.typology === 'VDSL'
    },
    isEVDSL() {
      return this.addressCovered.typology === 'EVDSL'
    },
    isFWGPON() {
      return this.addressCovered.typology === 'FTTH_GPON'
    },
    isSpeedChoice() {
      return this.addressCovered.speed.includes('2500')
    },
    discoutedPrice() {
      return (
        this.offerSelected.price -
        this.offerSelected.discount -
        this.offerSelected.scontoScalino
      ).toFixed(2)
    },
    notDiscoutedPrice() {
      return (
        this.offerSelected.price -
        (this.offerSelected.isScalino ? this.offerSelected.discount : 0)
      ).toFixed(2)
    },
    details() {
      if (!isNaN(this.discoutedPrice) && !isNaN(this.notDiscoutedPrice)) {
        return this.upsellingInfo.details.map((d) => {
          d.title = d.title.replace(
            // eslint-disable-next-line no-template-curly-in-string
            '${prezzo_scontato}',
            replaceDecimalPoint(this.discoutedPrice)
          )
          d.title = d.title.replace(
            // eslint-disable-next-line no-template-curly-in-string
            '${prezzo_non_scontato}',
            replaceDecimalPoint(this.notDiscoutedPrice)
          )
          d.body = d.body.replace(
            // eslint-disable-next-line no-template-curly-in-string
            '${prezzo_scontato}',
            replaceDecimalPoint(this.discoutedPrice)
          )
          d.body = d.body.replace(
            // eslint-disable-next-line no-template-curly-in-string
            '${prezzo_non_scontato}',
            replaceDecimalPoint(this.notDiscoutedPrice)
          )
          return d
        })
      } else {
        return []
      }
    }
  },
  async beforeMount() {
    this.documentLoaded = false
    let offerAlreadySelected = this.offers
    this.offerMax = await this.$store.dispatch('offers/getOffers', {
      type: this.filteredClientDetails.isDUAL ? 'APP_DUAL' : 'APP_MONO',
      speed: this.addressCovered.speedVal
    })
    if (this.isSpeedChoice) {
      this.trackTapEvent({
        name: 'upsellingv2_MultiplePriceOffer_view',
        params: {
          client_selected: this.addressCovered
            ? this.addressCovered.clientOwnerCode || ''
            : 'not_selected'
        }
      })
      this.offerMin = await this.$store.dispatch('offers/getOffers', {
        type: this.filteredClientDetails.isDUAL ? 'APP_DUAL' : 'APP_MONO',
        speed: '1000'
      })
      if (!offerAlreadySelected) {
        // Aggiungere le logiche del CMS x verificare quale o se l'offerta è stata preselezionata
      } else {
        this.offerSelected = offerAlreadySelected
      }
    } else {
      this.trackTapEvent({
        name: 'upsellingv2_SinglePriceOffer_view',
        params: {
          client_selected: this.addressCovered
            ? this.addressCovered.clientOwnerCode || ''
            : 'not_selected'
        }
      })
      this.offerSelected = this.offerMax
    }
    this.getUpselling({
      current_client_type: this.filteredClientDetails.clientOwnerType,
      new_client_type: this.filteredClientDetails.clientOwnerType,
      utility: 'fibra'
    })
    this.setOffers(this.offerSelected)
    this.setDocumentFiber()

    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area: consts.CALLCENTER_AREAS.UPSELLING_FIBER
    })
      .then((resp) => {
        this.landlineOpen = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  methods: {
    ...mapActions('offers', [
      'getUpselling',
      'resetUpsellingInfo',
      'getDocumentFiber'
    ]),
    ...mapMutations('upsellingFiber', ['setCatalogId', 'setFlagVoucherFibra']),
    ...mapActions('interactions', ['isChannelOpen']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('offers', ['setOffers']),
    setOfferSelected(offer) {
      this.documentLoaded = false
      this.offerSelected = offer
      this.setOffers(this.offerSelected)
      this.setDocumentFiber()
    },
    setDocumentFiber() {
      if (Object.keys(this.offerSelected).length > 0) {
        this.getDocumentFiber().then((document) => {
          this.contractDataUrl = document
          this.documentLoaded = true
        })
      }
    },
    goToModem() {
      this.trackTapEvent({
        name: 'upsellingv2_subscriptionStart_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      this.setFlagVoucherFibra(this.flagVoucherFibra)
      this.$emit('nextStep', 'upsellingv2_modem_open')
    },
    goToChat() {
      let pageName =
        Object.keys(this.offerSelected).length === 0
          ? 'Preventivo offerta fibra senza scelta'
          : 'Preventivo offerta fibra con scelta'
      this.$emit('goToStep', 15, 'upsellingv2_askhelp_tap', pageName)
    },
    openDialogConnection() {
      if (this.connectionType === 'FTTH') {
        this.dialogFTTH = true
      } else {
        this.dialogFTTC = true
      }
      this.trackTapEvent({
        name: 'upsellingv2_technologyinfo_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
    },
    openOfferDetail() {
      this.dialogOfferDetail = true
    },
    openDialogTermCond() {
      this.dialogTermCond = true
      this.trackTapEvent({
        name: 'upsellingv2_getpdfcontract',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
    },
    getUploadSpeed(upload) {
      if (!upload) return '-'
      return upload.split(' ')[0].split('/')[1]
    }
  }
}
</script>
<style lang="scss" scoped>
.voucherFibra {
  margin-bottom: 40px;
  .label {
    color: black;
  }
}

.offert {
  color: black;

  .subTitle {
    img {
      cursor: pointer;
    }
  }

  &__singleBox {
    width: 652px;
    padding: 32px 37px 32px 32px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-top: 24px;
    margin-bottom: 32px;

    h3 {
      font-weight: 700;
      font-size: 32px;
      letter-spacing: 1.2px;
    }

    .price {
      h5 {
        color: black;
        text-align: right;
        text-decoration: line-through;
      }
    }
  }
  .contract {
    margin-bottom: 30px;
    height: 54px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #12256a;
    &::after {
      content: url('../../../assets/ic_download_small_blue.svg');
      margin-left: 10px;
    }
  }

  &__doubleBox {
    .boxContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      background: $neutri-neutral-10;
      padding: 24px;
      width: 653px;
      border-radius: 16px;
      &__item {
        width: 288px;
        background: white;
        border: 1px solid $neutri-neutral-30;
        border-radius: 20px;
        padding: 16px;
        margin-bottom: 24px;
        cursor: pointer;
        &.selected {
          background: $neutri-neutral-20;
        }
      }
      &__details {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .line-through {
          text-decoration: line-through;
        }
        .promo {
          display: flex;
          align-items: center;
          padding-top: 14px;
          text-transform: uppercase;
        }
      }
    }
  }
  .noDetails {
    width: 653px;
    text-align: center;
    color: $neutri-neutral-50;
  }

  &__description {
    .title {
      margin-bottom: 12px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;

    button {
      letter-spacing: 0;
      margin-right: 10px;
    }
  }

  &__conditions {
    display: flex;
    flex-direction: column;
    width: 652px;
    .condition {
      height: 54px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        color: $srg-blue;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &__plus {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        cursor: pointer;

        &::after {
          content: url('../../../assets/icons/plus.svg');
          width: 24px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.dialog-connection,
.dialog-offer {
  background: white;
  padding: 55px 32px 45px 32px;
  border-radius: 40px;
  position: relative;

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    max-width: 377px;
    font-size: 22px;
    font-weight: 500;
  }

  .description {
    font-size: 16px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
  }

  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    &.preview {
      position: initial;
    }
  }

  .close {
    position: initial;
  }
}
</style>
