<template>
  <router-view></router-view>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'changeOfferIndex',
  methods: {
    ...mapActions('offers', ['getChangeProduct']),
    ...mapActions('supply', ['getSupplies'])
  }
}
</script>
