<template>
  <div
    class="answer_box"
    :style="`width: calc( 100% / ${perRow} - ${margin * 2}px)`"
  >
    <v-select
      v-model="selected"
      :items="answers"
      item-text="label"
      return-object
      @change="onChange"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'FeedbackDropdown',
  data() {
    return {
      selected: ''
    }
  },
  props: {
    answers: { type: Array },
    onChange: { type: Function }
  },
  inject: ['options'],
  computed: {
    perRow() {
      return this.options.perRow
    },
    margin() {
      return this.options.margin
    }
  },
  created() {
    this.selected = this.answers.find((v) => {
      return v.selected
    })
  }
}
</script>

<style lang="scss">
.answer_box {
  margin-bottom: 40px;
}
.v-menu__content {
  &::-webkit-scrollbar-thumb {
    background: rgba($srg-lightgray, 0.7);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $srg-lightgray;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .v-list-item {
    .v-list-item__content {
      border-bottom: 1px solid #ccd0e1;
    }
    &:last-child {
      .v-list-item__content {
        border-bottom: initial;
      }
    }
  }
}
.v-input__icon {
  .mdi-menu-down::before {
    content: url('../../assets/icons/ic-chevron-down-blue.svg');
    transform: rotate(180deg);
  }
}
</style>
