<template>
  <div class="offert" v-if="upsellingInfo">
    <h3 class="mb-4">
      <strong>{{ addressCovered.addressComplete }}</strong>
    </h3>
    <div class="offert__box d-flex justify-space-between align-center">
      <h3 v-text="`${upsellingInfo.name}` || ''" />
      <div class="price">
        <div class="BodyL text-right price__label">A partire da</div>
        <h3>
          <span>{{ upsellingInfo.price_lower }}</span>
          <span
            >{{ upsellingInfo.price_currency || '' }} /
            {{ upsellingInfo.price_period || '' }}</span
          >
        </h3>
      </div>
    </div>
    <div class="offert__description">
      <div class="detail d-flex align-center">Dettaglio dell’offerta</div>
      <div class="offert__conditions">
        <div class="condition" v-for="(detail, i) in details" :key="i">
          <div
            @click="
              ;(conditionIndex = i),
                (dialogOfferDetail = true),
                trackTapEvent({
                  name: 'upsellingv2_offerAccordion_tap',
                  params: { offer_accordion_title: details[i].title }
                })
            "
            class="condition__title ButtonS"
          >
            {{ detail.title || '' }}
          </div>
          <!-- <div class="condition__plus"></div> -->
        </div>
      </div>
    </div>
    <div class="offert__btn">
      <v-btn
        color="primary"
        x-large
        width="260"
        class="v-btn--shadowed"
        @click="goToRequest"
        >Prosegui</v-btn
      >
    </div>
    <v-dialog max-width="555" v-model="dialogOfferDetail">
      <v-row>
        <v-col class="dialog-offer">
          <div class="d-flex justify-space-between align-start mb-6">
            <h3>{{ details[conditionIndex].title }}</h3>
            <img
              class="close"
              @click="dialogOfferDetail = false"
              src="../../../assets/ic_chevron_top_black.png"
            />
          </div>
          <div class="description" v-html="details[conditionIndex].body"></div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'offertBus',
  data() {
    return {
      offerMono: {},
      offerDual: {},
      dialogOfferDetail: false,
      conditionIndex: 0
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', [
      'addressCovered',
      'client',
      'connectionType'
    ]),
    ...mapGetters('offers', ['upsellingInfo']),
    details() {
      if (this.upsellingInfo) {
        return this.upsellingInfo.details
      }
      return []
    }
  },
  async beforeMount() {
    await this.getUpselling({
      current_client_type: 'BUS',
      new_client_type: 'BUS',
      utility: 'fibra'
    })

    let promArr = []
    if (this.client.isDUAL) {
      promArr.push(
        this.$store
          .dispatch('offers/getOffers', {
            type: 'APP_DUAL',
            speed: this.addressCovered.speedVal
          })
          .then((resp) => {
            this.offerDual = resp
            return resp
          })
      )
    } else {
      promArr.push(
        this.$store
          .dispatch('offers/getOffers', {
            type: 'APP_MONO',
            speed: this.addressCovered.speedVal
          })
          .then((resp) => {
            this.offerMono = resp
            return resp
          })
      )
      promArr.push(
        this.$store
          .dispatch('offers/getOffers', {
            type: 'APP_DUAL',
            speed: this.addressCovered.speedVal
          })
          .then((resp) => {
            this.offerDual = resp
            return resp
          })
      )
    }
    Promise.all(promArr).then((respArr) => {
      this.setCatalogId(respArr[0].listPrice)
    })
  },
  methods: {
    ...mapActions('offers', ['getUpselling']),
    ...mapMutations('upsellingFiber', ['setCatalogId']),
    ...mapActions('analytics', ['trackTapEvent']),
    goToRequest() {
      this.trackTapEvent({
        name: 'upsellingv2_subscriptionStart_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      this.$emit('nextStep', 'upsellingv2_ClickToCall_open')
    },
    openOfferDetail() {
      this.dialogOfferDetail = true
    }
  }
}
</script>
<style lang="scss" scoped>
.offert {
  color: black;
  h3 {
    font-weight: 500;
    font-size: 22px;
  }
  &__box {
    width: 652px;
    padding: 32px 37px 32px 32px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-top: 24px;
    margin-bottom: 32px;
    .price {
      &__label {
        color: $neutri-neutral-60;
      }
      h3 {
        font-size: 32px;
        letter-spacing: 1.2px;
        font-weight: bold;
      }
    }
    .contract {
      text-decoration: underline;
      margin-top: 15px;
      cursor: pointer;
      &::after {
        content: url('../../../assets/ic_download_small_blue.svg');
        margin-left: 10px;
      }
    }
  }
  &__description {
    .detail {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 24px;
    }
  }
  &__btn {
    margin-top: 48px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    button {
      letter-spacing: 0;
      margin-right: 10px;
    }
  }
  &__conditions {
    display: flex;
    flex-direction: column;
    width: 652px;
    .condition {
      height: 54px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        color: $srg-blue;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &__plus {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        cursor: pointer;
        &::after {
          content: url('../../../assets/icons/plus.svg');
          width: 24px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.dialog-connection,
.dialog-offer {
  background: white;
  padding: 55px 32px 45px 32px;
  border-radius: 40px;
  position: relative;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  h3 {
    max-width: 377px;
    font-size: 22px;
    font-weight: 500;
  }
  .description {
    font-size: 16px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
  }
  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    &.preview {
      position: initial;
    }
  }
  .close {
    position: initial;
  }
}
</style>
