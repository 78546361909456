import { axiosACN } from '@/js/axiosInstances.js'
import { getPromiseSingletone } from '../../js/service/promiseStatus'

const createInteraction = (rootGetters, params) => {
  return axiosACN
    .post('/commodity/creazioneInterazione', params, {
      headers: {
        Authorization: 'Bearer ' + rootGetters['session/token']
      }
    })
    .then((res) => {
      if (res.data.errorCode) {
        throw Error('UNKNOWN_ERROR')
      }
      return res
    })
}

const getParams = (command, clientDetail, timeline, date, username) => {
  return {
    cognome: clientDetail.surname,
    email: clientDetail.contactMail,
    nome: clientDetail.name,
    codiceCliente: clientDetail.clientCode,
    login: username,
    fieldsCommodity: {
      commodity: command,
      codiceProposta: timeline.codiceProposta,
      data: date
    }
  }
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    resetState(state) {
      delete window.timelineFibraPromise
      delete window.timelineCommodityPromise
    }
  },
  actions: {
    getTimeline({ state, commit, rootGetters }) {
      if (
        !window.timelineFibraPromise ||
        !getPromiseSingletone(window.timelineFibraPromise)
      ) {
        window.timelineFibraPromise = new Promise((resolve, reject) => {
          axiosACN
            .post(
              '/sorgeniadataclient/V3/timelineFibra',
              {
                username: rootGetters['session/username']
              },
              {
                headers: {
                  Authorization: 'Bearer ' + rootGetters['session/token']
                }
              }
            )
            .then((res) => {
              if (res.data.errorCode) {
                // No bus logic errors expected
                resolve(res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              if (err.statusCode && err.statusCode >= 400) {
                throw Error('SERVER_ERROR')
              } else if (err.message) {
                throw err
              }
            })
        })
      }
      return window.timelineFibraPromise
    },
    getTimelineComm({ state, commit, rootGetters }) {
      if (
        !window.timelineCommodityPromise ||
        !getPromiseSingletone(window.timelineCommodityPromise)
      ) {
        window.timelineCommodityPromise = new Promise((resolve, reject) => {
          axiosACN
            .post(
              '/commodity/V2/timelineCommodity',
              {
                username: rootGetters['session/username']
              },
              {
                headers: {
                  Authorization: 'Bearer ' + rootGetters['session/token']
                }
              }
            )
            .then((res) => {
              if (res.data.errorCode) {
                // No bus logic errors expected
                resolve(res.data)
              }

              resolve(res.data)
            })
            .catch((err) => {
              if (err.statusCode && err.statusCode >= 400) {
                throw Error('SERVER_ERROR')
              } else if (err.message) {
                throw err
              }
            })
        })
      }
      return window.timelineCommodityPromise
    },
    getTimelineMock() {
      return [
        {
          codiceClienteIntestatario: 'mock-1',
          codiceProposta: 'mock-1',
          codicePunto: 'mock-1',
          dataPresaCarico: '',
          dataPresuntaAttivazione: '2019/10/24',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '',
          dataAppuntamento: '',
          oraAppuntamento: '',
          idStato: '12',
          step: 'S3',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codiceClienteIntestatario: 'mock-1-noant',
          codiceProposta: 'mock-1-noant',
          codicePunto: 'mock-1-noant',
          dataPresaCarico: '2019/10/20',
          dataPresuntaAttivazione: '2019/10/24',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '',
          dataAppuntamento: '',
          oraAppuntamento: '',
          idStato: '13',
          step: 'S3',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codiceClienteIntestatario: 'mock-2',
          codiceProposta: 'mock-2',
          codicePunto: 'mock-2',
          dataPresaCarico: '2019/10/30',
          dataPresuntaAttivazione: '2019/10/24',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '',
          dataAppuntamento: '',
          oraAppuntamento: '',
          idStato: '14',
          step: '',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codiceClienteIntestatario: 'mock-2-ant',
          codiceProposta: 'mock-2-ant',
          codicePunto: 'mock-2-ant',
          dataPresaCarico: '2019/10/30',
          dataPresuntaAttivazione: '2019/10/24',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '',
          dataAppuntamento: '',
          oraAppuntamento: '',
          step: '',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codiceClienteIntestatario: 'mock-2-1',
          codiceProposta: 'mock-2-1',
          codicePunto: 'mock-2-1',
          dataPresaCarico: '1900/10/30',
          dataPresuntaAttivazione: '',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '',
          dataAppuntamento: '',
          oraAppuntamento: '',
          step: '',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codicePunto: 'mock-3',
          codiceClienteIntestatario: '4585460',
          codiceProposta: '001901/WF',
          tipoRichiesta: 'NewConnectionFibra',
          dataSottoscrizione: '2020/10/17',
          dataPresaCarico: '2020/10/18',
          dataPresuntaAttivazione: '2020/11/03',
          dataAppuntamento: '2020/10/26',
          oraAppuntamento: '14:50',
          annullamento: 'N',
          dataAttivazione: '1900/01/01',
          idStato: '3',
          stato: 'Attesa attivazione',
          step: '',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codiceClienteIntestatario: 'mock-3-1',
          codiceProposta: 'mock-3-1',
          codicePunto: 'mock-3-1-noappuntamento',
          dataPresaCarico: '2019/10/30',
          dataPresuntaAttivazione: '',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '',
          dataAppuntamento: '1970/01/01',
          oraAppuntamento: '00:00',
          idStato: '13',
          step: '',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codiceClienteIntestatario: 'mock-3-norisc',
          codiceProposta: 'mock-3-norisc',
          codicePunto: 'mock-3-norisc',
          dataPresaCarico: '2019/10/30',
          dataPresuntaAttivazione: '',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '',
          dataAppuntamento: '2020/01/08',
          oraAppuntamento: '20:00',
          idStato: '13',
          step: '',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codiceClienteIntestatario: 'mock-4',
          codiceProposta: 'mock-4',
          codicePunto: 'mock-4',
          dataPresaCarico: '2019/10/30',
          dataPresuntaAttivazione: '',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '',
          dataAppuntamento: '2020/01/07',
          oraAppuntamento: '09:00',
          idStato: '13',
          step: '',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        },
        {
          codiceClienteIntestatario: 'mock-5',
          codiceProposta: 'mock-5',
          codicePunto: 'mock-5',
          dataPresaCarico: '2019/10/30',
          dataPresuntaAttivazione: '',
          dataSottoscrizione: '2019/10/20',
          dataAttivazione: '2019/12/30',
          dataAppuntamento: '2019/12/25',
          oraAppuntamento: '09:00',
          idStato: '11',
          step: '',
          isCancellable: true,
          activatingCommodityOnSameAddress: false
        }
      ]
    },
    getTimelineCommMock() {
      return []
    },
    getEarlyActivation({ state, commit, rootGetters }, params) {
      const p = getParams(
        'attivazioneAnticipata',
        params.clientDetail,
        params.timeline,
        params.date,
        rootGetters['session/username']
      )
      return createInteraction(rootGetters, p)
        .then((res) => {
          if (res.data.errorCode) {
            throw Error('UNKNOWN_ERROR')
          }
          let anticipationRequests = JSON.parse(
            localStorage.getItem('anticipationRequests')
          )
          anticipationRequests = anticipationRequests || {}
          anticipationRequests[params.timeline.codiceProposta] = true
          localStorage.setItem(
            'anticipationRequests',
            JSON.stringify(anticipationRequests)
          )
          return true
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    requestCommodityEarlyActivation({ rootGetters }, params) {
      return axiosACN
        .post('/commodity/rinunciaRipensamentoCommodity', params, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.status === 'KO') {
            throw Error('UNKNOWN_ERROR')
          }

          let anticipationRequests = JSON.parse(
            localStorage.getItem('anticipationRequests')
          )
          anticipationRequests = anticipationRequests || {}
          anticipationRequests[params.codiceProposta] = true
          localStorage.setItem(
            'anticipationRequests',
            JSON.stringify(anticipationRequests)
          )

          return res
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },

    nonDisponsibile({ state, commit, rootGetters }, params) {
      const p = getParams(
        'appuntamentoNonDisponibile',
        params.clientDetail,
        params.timeline,
        params.date,
        rootGetters['session/username']
      )
      return createInteraction(rootGetters, p)
    }
  }
}
