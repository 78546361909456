<template>
  <div class="page paybill">
    <TcVars />
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row v-show="!showSiaFrame">
      <div class="paymentPage__title">Scegli la carta che vuoi utilizzare</div>
      <div class="paymentPage__subtitle">
        Per velocizzare il tuo pagamento, puoi utilizzare la carta di credito o
        debito già utilizzata in precedenza.<br />
        Seleziona "Nuova carta di credito" se desideri cambiare.
      </div>
    </v-row>
    <v-row no-gutters class="subscription__radio" v-show="!showSiaFrame">
      <v-col v-if="loading" align-self="center" class="text-center pa-10">
        <v-progress-circular :size="50" indeterminate></v-progress-circular>
      </v-col>
      <v-col v-if="!loading">
        <ExtensibleButtonGroup
          v-model="paymentMethod"
          :per-row="2"
          groupName="paymentMethod"
          size="md"
          @extensible-btn-group:touched="
            handleExtButtonTouched && handleExtButtonTouched($event)
          "
        >
          <ExtensibleButton
            :name="meth"
            v-for="(meth, i) in methodsList"
            :key="i"
          >
            <template v-slot:label>
              <div class="d-flex flex-column align-start">
                <div class="typeMdp">Carta di credito</div>
                <div>
                  {{ $filters.formatCreditCardTail(meth.creditCardNumber) }}
                </div>
              </div>
            </template>
          </ExtensibleButton>

          <ExtensibleButton name="newCreditCard" class="d-flex">
            <template v-slot:label>
              <div class="color--blue">Nuova carta di credito</div>
            </template>
            <v-row no-gutters>
              <v-col>
                <br />
                <h5
                  @click="rememberCard = !rememberCard"
                  :class="`radio-sameHolder pl-3 d-flex pl-3 ${
                    rememberCard ? 'selected' : ''
                  }`"
                >
                  <v-icon class="mr-2" color="#12256A">{{
                    rememberCard
                      ? 'mdi-checkbox-intermediate'
                      : 'mdi-checkbox-blank-outline'
                  }}</v-icon>
                  Ricorda questa carta per i miei prossimi pagamenti
                </h5>
              </v-col>
            </v-row>
          </ExtensibleButton>
        </ExtensibleButtonGroup>
      </v-col>
    </v-row>
    <v-row no-gutters class="justify-center mb-5" v-show="!showSiaFrame">
      <v-btn
        color="primary"
        x-large
        class="v-btn--shadowed"
        :rounded="true"
        :loading="paymentLoading"
        :disabled="!paymentMethodValid"
        v-show="paymentMethod && paymentMethodValid"
        @click="confirmPaymentMethod"
      >
        Conferma
      </v-btn>
    </v-row>
    <div class="siaDialog" v-show="showSiaFrame">
      <iframe
        id="fiberSiaCC"
        v-if="siaUrl"
        @load="iframeUrlChanged"
        :src="siaUrl"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import Consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import router from '@/router/router'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'paySupplyBills',
  computed: {
    ...mapGetters('supply', ['supplyDetail']),
    breadcrumbsItems() {
      if (this.$route.params.supplyCode) {
        const address = this.supplyDetail
          ? this.supplyDetail[this.$route.params.supplyCode].address
          : ''
        let res = [
          { to: { name: 'home' }, text: 'Home' },
          { to: { name: 'supplyDetail' }, text: address, exact: true },
          { to: { name: 'paySupplyBills' }, text: 'Pagamento' }
        ]

        return res
      } else {
        let res = [
          { to: { name: 'home' }, text: 'Home' },
          {
            to: {
              name: 'paymentSummary',
              params: { clientCode: this.$route.params.clientOwnerCode }
            },
            text: 'Sintesti stato pagamenti',
            exact: true
          },
          { to: { name: 'payAllBills' }, text: 'Pagamento' }
        ]

        return res
      }
    }
  },
  data() {
    return {
      paymentMethod: null,
      paymentData: null,
      paymentMethodValid: false,
      paymentLoading: false,
      instalment: false,

      rememberCard: false,
      showSiaFrame: false,
      siaUrl: '',

      loading: true,
      methodsList: [],
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,
      bills: null,
      pdrs: null,
      billsToPay: [],
      pdrToPay: []
    }
  },
  components: {
    TcVars
  },
  methods: {
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('payment', [
      'getCardsList',
      'getSiaUrlCCOSBollettone',
      'payCCOSAliasBollettone',
      'payGenericNoAlias',
      'payGenericAlias'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('supply', ['resetState']),
    confirmPaymentMethod(ccParams) {
      if (this.paymentMethod === 'newCreditCard') {
        this.getDetailForClient({
          clientOwnerCode: this.$route.params.clientOwnerCode
        }).then((resp) => {
          if (this.bills) {
            this.getSiaUrlCCOSBollettone({
              clientCode: this.$route.params.clientOwnerCode,
              email: resp.contactMail,
              rememberCard: this.rememberCard,
              billsToPay: this.billsToPay
            })
              .then((url) => {
                this.siaUrl = url
                this.showSiaFrame = true
              })
              .catch((err) => {
                console.error(err)
                GenErr.showErrorMessage(
                  'Si è verificato un errore e non è stato possibile inviare la tua richiesta. Ti preghiamo di riprovare.'
                )
              })
          } else {
            this.payGenericNoAlias({
              clientCode: this.$route.params.clientOwnerCode,
              email: resp.contactMail,
              name: resp.name,
              surname: resp.surname,
              remember: this.rememberCard,
              instalment: this.pdrToPay
            })
              .then((url) => {
                this.siaUrl = window.atob(url.urlRedirect)
                this.instalment = true
                this.showSiaFrame = true
              })
              .catch((err) => {
                console.error(err)
                router.push({
                  name: 'pdrPaymentResult',
                  params: {
                    status: 'KO',
                    isMultiPayment: this.$route.params.isMultiPayment,
                    idFattura: this.$route.params.idFattura,
                    from: this.$route.params.from,
                    instalment: this.instalment,
                    supplyCode: this.$route.params.supplyCode
                  }
                })
              })
          }
        })
      } else {
        this.getDetailForClient({
          clientOwnerCode: this.$route.params.clientOwnerCode
        }).then((resp) => {
          this.paymentLoading = true
          if (this.bills) {
            this.payCCOSAliasBollettone({
              clientCode: this.$route.params.clientOwnerCode,
              email: resp.contactMail,
              aliasPan: this.paymentMethod.aliasPan,
              aliasExpirationdate: this.paymentMethod.creditCardExpirationDate,
              billsToPay: this.billsToPay
            })
              .then((paymentSucceded) => {
                if (paymentSucceded.status === 'OK') {
                  this.siaUrl = window.atob(paymentSucceded.urlRedirect)
                  this.showSiaFrame = true
                } else {
                  router.push({
                    name: 'supplyPayment',
                    params: {
                      status: 'KO',
                      isMultiPayment: this.$route.params.isMultiPayment,
                      idFattura: this.$route.params.idFattura,
                      from: this.$route.params.from
                    }
                  })
                }
                this.paymentLoading = false
              })
              .catch((err) => {
                console.error(err)
                this.paymentLoading = false
                router.push({
                  name: 'supplyPayment',
                  params: {
                    status: 'KO',
                    isMultiPayment: this.$route.params.isMultiPayment,
                    idFattura: this.$route.params.idFattura,
                    from: this.$route.params.from
                  }
                })
              })
          } else {
            this.payGenericAlias({
              clientCode: this.$route.params.clientOwnerCode,
              email: resp.contactMail,
              name: resp.name,
              surname: resp.surname,
              aliasPan: this.paymentMethod.aliasPan,
              aliasPanExpirationDate:
                this.paymentMethod.creditCardExpirationDate,
              remember: this.rememberCard,
              instalment: this.pdrToPay
            })
              .then((paymentSucceded) => {
                if (paymentSucceded.status === 'OK') {
                  this.siaUrl = window.atob(paymentSucceded.urlRedirect)
                  this.instalment = true
                  this.showSiaFrame = true
                } else {
                  router.push({
                    name: 'pdrPaymentResult',
                    params: {
                      status: 'KO',
                      isMultiPayment: this.$route.params.isMultiPayment,
                      idFattura: this.$route.params.idFattura,
                      from: this.$route.params.from,
                      supplyCode: this.$route.params.supplyCode
                    }
                  })
                }
                this.paymentLoading = false
              })
              .catch((err) => {
                console.error(err)
                this.instalment = true
                this.paymentLoading = false
                router.push({
                  name: 'pdrPaymentResult',
                  params: {
                    status: 'KO',
                    isMultiPayment: this.$route.params.isMultiPayment,
                    idFattura: this.$route.params.idFattura,
                    from: this.$route.params.from,
                    instalment: this.instalment,
                    supplyCode: this.$route.params.supplyCode
                  }
                })
              })
          }
        })
      }
    },
    closeDialog() {
      this.$emit('close-dialog')
    },
    iframeUrlChanged(event) {
      try {
        const loc = event.target.contentWindow.location.href
        if (loc.indexOf('sia/back.html') > -1) {
          this.siaUrl = ''
          this.showSiaFrame = false
          this.paymentMethod = null
        } else if (loc.indexOf('sia/done.html') > -1) {
          this.trackTapEvent('summaryBill_multiplePaymentDone_view')
          this.siaUrl = ''
          this.showSiaFrame = false

          if (this.bills) {
            this.bills.forEach((bill) => {
              this.$store.commit('supply/resetSupplyDetailByCode', bill.prCode)
            })
            this.resetState()
          }
          router.push({
            name: this.instalment ? 'pdrPaymentResult' : 'supplyPayment',
            params: {
              status: 'OK',
              isMultiPayment: this.$route.params.isMultiPayment,
              idFattura: this.$route.params.idFattura,
              from: this.$route.params.from,
              instalment: this.instalment,
              supplyCode: this.$route.params.supplyCode
            }
          })
        }
      } catch (err) {
        console.err(event, 'ERROR')
        // do nothing. it means we have no access to the iframe data because it is crossdomain
      }
    },

    checkPaymentMethodValid() {
      this.paymentMethodValid =
        this.paymentMethod === 'newCreditCard' || this.paymentMethod?.aliasPan
    }
  },
  mounted() {
    this.trackTapEvent('payment_method_viewed')
    this.handleExtButtonTouched = (params) => {
      this.checkPaymentMethodValid()
    }
  },
  created() {
    // Get the client code. depending on the page where the component is used bill's fields have translated/untranslated names
    this.bills = this.$route.params.bills
    this.pdrs = this.$route.params.pdrs
    const clientCode = this.$route.params.clientOwnerCode

    // Get the existing payment methods list
    if ((this.bills || this.pdrs) && clientCode) {
      this.getCardsList({ clientCode: clientCode }).then((methods) => {
        // Filter cards for CCOS
        let list = methods.filter((meth) => {
          let monthExp = meth.creditCardExpirationDate.split('/')[0]
          let yearExp = meth.creditCardExpirationDate.split('/')[1]
          let today = new Date()
          let cardExp = new Date()
          cardExp.setFullYear(yearExp, monthExp, 1)
          return meth.aliasPan && meth.creditCardNumber && today < cardExp
        })
        if (this.bills) {
          this.billsToPay = this.bills.map((item) => {
            return {
              idFattura: item.idFattura,
              codiceBollettino: item.codiceBollettino,
              importo:
                item.statoFattura === 'Parziale'
                  ? item.importoParzialeDaPagare
                  : item.importo
            }
          })
        } else {
          if (this.$route.params.isMultiPayment) {
            this.pdrToPay = this.pdrs.map((instalment) => {
              return {
                instalmentId: instalment.instalmentId,
                invoices: instalment.invoicesList.map((invoice) => {
                  return {
                    invoiceId: invoice.invoiceId,
                    paymentSlipId: invoice.paymentSlipId,
                    amount: invoice.residualAmount
                  }
                })
              }
            })
          } else {
            this.pdrToPay = {
              instalmentId: this.pdrs[0].instalmentId,
              invoices: this.pdrs[0].invoicesList.map((invoice) => {
                return {
                  invoiceId: invoice.invoiceId,
                  paymentSlipId: invoice.paymentSlipId,
                  amount: invoice.residualAmount
                }
              })
            }
          }
        }
        this.methodsList = list
        this.loading = false
      })
    } else {
      this.methodsList = []
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.paybill {
  .send-email {
    .v-btn__content {
      color: black;
      font-size: 14px;
      font-weight: normal;
      justify-content: flex-start;
    }
  }
  #send-whatsapp {
    background-color: #f6f6f6 !important;
    .v-btn__content {
      color: black;
      font-size: 15px;
      font-weight: 400;
      justify-content: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.siaDialog {
  height: 80vh;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.paybill {
  .v-tab {
    border-bottom: #e1e1e1 2px solid;
    text-transform: initial;
  }
  .v-tab:not(.v-tab--active) {
    color: black !important;
  }
}

.paybill__tabs__pay,
.paybill__tabs__send {
  margin-top: 30px;
}

.paymentPage {
  background: white;
  padding: 48px;
  position: relative;
  &__title {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  &__subtitle {
    font-size: 20px;
    font-weight: 400;
  }
}
</style>
