<template>
  <v-dialog
    v-model="dialogDate"
    :max-width="!pickerStart.show && !pickerEnd.show ? 750 : 555"
  >
    <v-row>
      <v-col class="supply-dialog">
        <img
          @click="dialogDate = false"
          src="../../../assets/ic_chevron_top_black.png"
        />
        <template v-if="!pickerStart.show && !pickerEnd.show">
          <h2>Scegli il periodo che vuoi visualizzare</h2>
          <v-row class="mb-8">
            <v-col class="col-6">
              <div class="btn-filter big" @click="$emit('openPicker', 'start')">
                Inizio periodo -
                {{ $filters.formatDateWithSeparator(pickerStart.date) }}
              </div>
            </v-col>
            <v-col class="col-6">
              <div class="btn-filter big" @click="$emit('openPicker', 'end')">
                Fine periodo -
                {{ $filters.formatDateWithSeparator(pickerEnd.date) }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-btn
                @click="dialogDate = false"
                color="white"
                x-large
                width="310"
                >Annulla</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                @click="$emit('refreshTotal', 'date')"
                class="v-btn--shadowed"
                x-large
                color="primary"
                width="310"
                :outlined="pickerStart.date && pickerEnd.date ? false : true"
                :disabled="pickerStart.date && pickerEnd.date ? false : true"
                >Conferma</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-else-if="pickerStart.show">
          <h3 class="mb-6">Scegli la data iniziale che vuoi visualizzare</h3>
          <v-row justify="center">
            <v-date-picker
              :show-current="false"
              flat
              width="350"
              v-model="pickerStart.date"
              :max="maxDate"
              :min="minDate"
              :reactive="true"
            ></v-date-picker>
          </v-row>
          <v-row class="mt-12">
            <v-col cols="6">
              <v-btn
                @click="pickerStart = { date: pickerStart.date, show: false }"
                color="white"
                x-large
                width="210"
                >Annulla</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                @click="pickerStart.show = false"
                class="v-btn--shadowed"
                x-large
                color="primary"
                width="210"
                >Prosegui</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <h3 class="mb-6">Scegli la data di fine che vuoi visualizzare</h3>
          <v-row justify="center">
            <v-date-picker
              :show-current="false"
              flat
              width="350"
              v-model="pickerEnd.date"
              :max="maxDate"
              :min="pickerStart.date"
              :reactive="true"
            ></v-date-picker>
          </v-row>
          <v-row class="mt-12">
            <v-col cols="6">
              <v-btn
                @click="pickerEnd = { date: pickerEnd.date, show: false }"
                color="white"
                x-large
                width="210"
                >Annulla</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                @click="pickerEnd.show = false"
                class="v-btn--shadowed"
                x-large
                color="primary"
                width="210"
                >Prosegui</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
export default {
  name: 'dialogFilterDate',
  props: ['showDialogDate', 'pickerStartProp', 'pickerEndProp'],
  computed: {
    dialogDate: {
      get() {
        return this.showDialogDate
      },
      set(val) {
        this.$emit('update:showDialogDate', val)
      }
    },
    pickerStart: {
      get() {
        return this.pickerStartProp
      },
      set(val) {
        this.$emit('update:pickerStartProp', val)
      }
    },
    pickerEnd: {
      get() {
        return this.pickerEndProp
      },
      set(val) {
        this.$emit('update:pickerEndProp', val)
      }
    },
    minDate() {
      let minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 2)
      )
      return `${minDate.getFullYear()}-${
        minDate.getMonth() + 1 < 10
          ? '0' + (minDate.getMonth() + 1)
          : minDate.getMonth() + 1
      }-01`
    },
    maxDate() {
      let maxDate = new Date()
      return `${maxDate.getFullYear() + 3}-${
        maxDate.getMonth() + 1 < 10
          ? '0' + (maxDate.getMonth() + 1)
          : maxDate.getMonth() + 1
      }-${maxDate.getDate()}`
    }
  }
}
</script>
<style lang="scss" scoped>
@import './dialogFilter.scss';
</style>
