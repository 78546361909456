import { axiosAdvice, axiosCMS } from '@/js/axiosInstances.js'
import { STATUS_GREENERS } from '../../js/constants'
import { ENV_CONFIGS } from '@/js/configs.js'
import NewError from '../../js/service/newError'

const errorHandler = (err) => {
  console.error(err)
}

let size = 0
let page = 1

const retrieveTransactionsList = (commit, rootGetters, params) => {
  return axiosAdvice
    .get('/transaction-list', {
      params: {
        codiceCliente: params.ext_uuid,
        perpage: 1000,
        page: page.toString()
      },
      headers: {
        Authorization: 'Bearer ' + rootGetters['session/token']
      }
    })
    .then((res) => {
      if (res.data.status === 'KO') {
        commit('setTRANSACTION_LIST_ERROR', true)
      } else {
        size = res.data.wekit_size + size
        commit('setTransactionList', res.data.wekit_data)
        commit('setTRANSACTION_LIST_ERROR', false)
        if (size <= res.data.wekit_total) {
          page = page + 1
          if (page <= res.data.wekit_total_pages) {
            return retrieveTransactionsList(commit, rootGetters, params)
          }
        }
      }
    })
    .catch((err) => {
      commit('setTRANSACTION_LIST_ERROR', true)
      errorHandler(err)
    })
}
export default {
  namespaced: true,
  state: {
    catalog: [],
    catalog_iwwl: [],
    catalog_iw: [],
    gameResult: null,
    memberStatus: {},
    memberBalance: null,
    awardId: '',
    awardItem: {},
    transactionList: [],
    clientLevel: '',
    codeFriendGreeners: '',
    availableAwards: {},
    surveys: null,
    wekitMap: [],
    welcomeIWLater: false,
    loyaltyLandingPrizes: null,
    video: [],
    photo: [],
    quiz: null,
    loyaltyNews: [],
    availableMissions: [],
    awardCounter: 0,
    AWARD_CATALOG_ERROR: false,
    IDENTITY_STATUS_ERROR: false,
    IDENTITY_BALANCE_ERROR: false,
    IDENTITY_LEVEL_ERROR: false,
    WEKITMAP_ERROR: false,
    TRANSACTION_LIST_ERROR: false,
    TRANSACTION_ERROR: false,
    REQUEST_AWARD_DIARY_ERRROR: false,
    REQUEST_AWARD_CATALOG_ERRROR: false,
    REQUEST_AWARD_GREENCOIS_ERRROR: false,
    AWARD_REDEEM_ERRROR: false,
    NEW_MEMBER_STATUS_ERROR: false
  },
  getters: {
    catalogList: (state) => state.catalog,
    catalog_iwwl: (state) => state.catalog_iwwl,
    catalog_iw: (state) => state.catalog_iw,
    isGameWon: (state) => state.gameResult,
    memberStatus: (state) => state.memberStatus,
    awardId: (state) => state.awardId,
    awardItem: (state) => state.awardItem,
    memberBalance: (state) => state.memberBalance || {},
    transactionList: (state) =>
      Array.isArray(state.transactionList) ? state.transactionList : [],
    clientLevel: (state) => state.clientLevel,
    availableAwards: (state) => state.availableAwards,
    surveys: (state) => (Array.isArray(state.surveys) ? state.surveys : []),
    wekitMap: (state) => (Array.isArray(state.wekitMap) ? state.wekitMap : []),
    isWelcomeIWLater: (state) => state.welcomeIWLater,
    loyaltyLandingPrizes: (state) => state.loyaltyLandingPrizes,
    videoData: (state) => (Array.isArray(state.video) ? state.video : []),
    photoData: (state) => (Array.isArray(state.photo) ? state.photo : []),
    quiz: (state) => (Array.isArray(state.quiz) ? state.quiz : []),
    loyaltyNews: (state) =>
      Array.isArray(state.loyaltyNews) ? state.loyaltyNews : [],
    availableMissions: (state) =>
      Array.isArray(state.availableMissions) ? state.availableMissions : [],
    awardCounter: (state) => state.awardCounter || 0,
    AWARD_CATALOG_ERROR: (state) => state.AWARD_CATALOG_ERROR,
    IDENTITY_STATUS_ERROR: (state) => state.IDENTITY_STATUS_ERROR,
    IDENTITY_BALANCE_ERROR: (state) => state.IDENTITY_BALANCE_ERROR,
    IDENTITY_LEVEL_ERROR: (state) => state.IDENTITY_LEVEL_ERROR,
    WEKITMAP_ERROR: (state) => state.WEKITMAP_ERROR,
    TRANSACTION_LIST_ERROR: (state) => state.TRANSACTION_LIST_ERROR,
    TRANSACTION_ERROR: (state) => state.TRANSACTION_ERROR,
    REQUEST_AWARD_DIARY_ERRROR: (state) => state.REQUEST_AWARD_DIARY_ERRROR,
    REQUEST_AWARD_CATALOG_ERRROR: (state) => state.REQUEST_AWARD_CATALOG_ERRROR,
    REQUEST_AWARD_GREENCOIS_ERRROR: (state) =>
      state.REQUEST_AWARD_GREENCOIS_ERRROR,
    AWARD_REDEEM_ERRROR: (state) => state.AWARD_REDEEM_ERRROR,
    NEW_MEMBER_STATUS_ERROR: (state) => state.NEW_MEMBER_STATUS_ERROR,
    codeFriendGreeners: (state) => state.codeFriendGreeners
  },
  mutations: {
    resetState(state) {
      state.transactionList = []
    },
    setCatalog(state, payload = []) {
      state.catalog = payload
    },
    setCatalogIWWL(state, payload = []) {
      state.catalog_iwwl = payload
    },
    setCatalogIW(state, payload = []) {
      state.catalog_iw = payload
    },
    setGameResult(state, payload) {
      state.gameResult = payload
    },
    setMemberStatus(state, payload) {
      state.memberStatus = payload
    },
    setMemberBalance(state, payload) {
      state.memberBalance = payload
    },
    setAwardId(state, payload) {
      state.awardId = payload
    },
    setAwardItem(state, payload) {
      state.awardItem = payload || {}
    },
    setTransactionList(state, payload) {
      state.transactionList.push(...payload)
    },
    setClientLevel(state, payload) {
      state.clientLevel = payload
    },
    setAvailableAwards(state, payload) {
      state.availableAwards = payload
    },
    setSurveys(state, payload) {
      state.surveys = payload
    },
    setQuiz(state, quiz) {
      state.quiz = quiz
    },
    setWekitMap(state, payload) {
      state.wekitMap = payload
    },
    setVideo(state, payload) {
      state.video = payload
    },
    setPhoto(state, payload) {
      state.photo = payload
    },
    setWelcomeIWLater(state, welcomeIWLater) {
      state.welcomeIWLater = welcomeIWLater
    },
    setLoyaltyLandingPrizes(state, loyaltyLandingPrizes) {
      state.loyaltyLandingPrizes = loyaltyLandingPrizes
    },
    setLoayaltyNews(state, news) {
      state.loyaltyNews = news
    },
    setAvailableMissions(state, missions) {
      state.availableMissions = missions
    },
    setAwardCounter(state, awardCounter) {
      state.awardCounter = awardCounter
    },
    setAWARD_CATALOG_ERROR(state, value) {
      state.AWARD_CATALOG_ERROR = value
    },
    setIDENTITY_STATUS_ERROR(state, value) {
      state.IDENTITY_STATUS_ERROR = value
    },
    setIDENTITY_BALANCE_ERROR(state, value) {
      state.IDENTITY_BALANCE_ERROR = value
    },
    setIDENTITY_LEVEL_ERROR(state, value) {
      state.IDENTITY_LEVEL_ERROR = value
    },
    setWEKITMAP_ERROR(state, value) {
      state.WEKITMAP_ERROR = value
    },
    setTRANSACTION_LIST_ERROR(state, value) {
      state.TRANSACTION_LIST_ERROR = value
    },
    setTRANSACTION_ERROR(state, value) {
      state.TRANSACTION_ERROR = value
    },
    setREQUEST_AWARD_DIARY_ERRROR(state, value) {
      state.REQUEST_AWARD_DIARY_ERRROR = value
    },
    setREQUEST_AWARD_CATALOG_ERRROR(state, value) {
      state.REQUEST_AWARD_CATALOG_ERRROR = value
    },
    setREQUEST_AWARD_GREENCOIS_ERRROR(state, value) {
      state.REQUEST_AWARD_GREENCOIS_ERRROR = value
    },
    setAWARD_REDEEM_ERRROR(state, value) {
      state.AWARD_REDEEM_ERRROR = value
    },
    setNEW_MEMBER_STATUS_ERROR(state, value) {
      state.NEW_MEMBER_STATUS_ERROR = value
    },
    setCodeFriendGreeners(state, payload) {
      state.codeFriendGreeners = payload
    }
  },
  actions: {
    getGameResult({ commit, rootGetters }, params) {
      return axiosAdvice
        .post(
          '/V2/play-iw-welcome',
          { codiceCliente: params.id },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.status === 200 && res.data.wekit_data[0]) {
            commit(
              'setGameResult',
              res.data.wekit_data[0] && res.data.wekit_data[0].iw_result
            )
            return res.data.wekit_data[0].iw_result
          } else {
            throw Error('SERVER_ERROR')
          }
        })
        .catch((err) => errorHandler(err))
    },
    getTransactionsList({ state, commit, rootGetters }, params) {
      if (state.transactionList && state.transactionList.length > 0) {
        return Promise.resolve(state.transactionList)
      } else {
        size = 0
        page = 1
        return retrieveTransactionsList(commit, rootGetters, params)
      }
    },
    getTransactionsListNewEdition({ state, rootGetters }, params) {
      return axiosAdvice
        .get('/transaction-list', {
          params: {
            codiceCliente: params.ext_uuid,
            event: '3'
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => errorHandler(err))
    },
    getAwardId({ commit, rootGetters }, params) {
      return axiosAdvice
        .get('/catalog', {
          params: {
            catalog_name: 'IW-WL',
            ext_uuid: params.ext_uuid
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          commit('setAwardId', res.data.wekit_data[0].attributes.prizeoption_id)
        })
        .catch((err) => errorHandler(err))
    },
    async getAwardInfo({ commit, dispatch, rootGetters, state }, params) {
      await dispatch('getUuid')
      if (!state.memberBalance)
        dispatch('getBalance', {
          ext_uuid:
            rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
        })
      return axiosAdvice
        .get('/V2/prize-detail', {
          params: {
            ...(params.catalog && { catalog_name: params.catalog }),
            prizeoption_id: params.id,
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if ([1002, 1022].includes(parseInt(res.data.wekit_code))) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          } else {
            commit('setAwardItem', res.data.wekit_data[0])
            commit('setREQUEST_AWARD_CATALOG_ERRROR', false)
          }
          return res.data.wekit_data[0]
        })
        .catch((err) => {
          commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          errorHandler(err)
          return err
        })
    },
    async getCatalog({ commit, dispatch, rootGetters }, params) {
      await dispatch('getUuid')
      return axiosAdvice
        .get('/V2/catalog', {
          params: {
            ...params
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          if (resp.data.wekit_code === 1002) {
            commit('setAWARD_CATALOG_ERROR', true)
          } else {
            if (params.catalog_name === 'IW-WL') {
              commit('setCatalogIWWL', resp.data.wekit_data)
            } else if (params.catalog_name === 'IW') {
              commit('setCatalogIW', resp.data.wekit_data)
            } else {
              commit('setCatalog', resp.data.wekit_data)
            }
            commit('setAWARD_CATALOG_ERROR', false)
          }
          return resp.data.wekit_data
        })
        .catch((err) => {
          commit('setAWARD_CATALOG_ERROR', true)
          errorHandler(err)
        })
    },
    checkMemberStatus({ rootGetters }, params) {
      const failedStatus = {
        user_registered: null,
        played_welcome_iw: null
      }

      return axiosAdvice
        .get('/user-status', {
          params: {
            codiceCliente: params.ext_uuid
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          if (resp.data.wekit_data && resp.data.wekit_data[0]) {
            return resp.data.wekit_data[0]
          } else {
            return failedStatus
          }
        })
        .catch((err) => {
          console.error('Error:' + err)
          return failedStatus
        })
    },
    getMemberStatus({ commit, rootGetters }, params) {
      const failedStatus = {
        user_registered: null,
        played_welcome_iw: null
      }

      return axiosAdvice
        .get('/user-status', {
          params: {
            codiceCliente: params.ext_uuid
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          if (resp.data.wekit_data && resp.data.wekit_data[0]) {
            commit('setMemberStatus', resp.data.wekit_data[0])
            commit('setIDENTITY_STATUS_ERROR', false)
            return resp.data.wekit_data[0]
          } else {
            commit('setMemberStatus', failedStatus)
            commit('setIDENTITY_STATUS_ERROR', true)
            return failedStatus
          }
        })
        .catch((err) => {
          console.error('Error:' + err)
          commit('setMemberStatus', failedStatus)
          commit('setIDENTITY_STATUS_ERROR', true)
          return failedStatus
        })
    },
    getBalance({ commit, rootGetters }, params) {
      return axiosAdvice
        .get('/user-balance', {
          params: {
            codiceCliente: params.ext_uuid
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          if (resp.data.status === 'KO') {
            commit('setIDENTITY_BALANCE_ERROR', true)
          } else {
            commit('setMemberBalance', resp.data.wekit_data[0])
            commit('setIDENTITY_BALANCE_ERROR', false)
          }
        })
        .catch(() => {
          commit('setIDENTITY_BALANCE_ERROR', true)
        })
    },
    async getAvailableAwards({ dispatch, commit, rootGetters }) {
      await dispatch('getUuid')
      return axiosAdvice
        .get('/award-status', {
          params: {
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          if (resp.data.wekit_code === 1002) {
            commit('setAWARD_REDEEM_ERRROR', true)
          } else {
            commit(
              'setAvailableAwards',
              resp.data.wekit_data[0].prize_to_request
            )
            commit('setAWARD_REDEEM_ERRROR', false)
          }
        })
        .catch((err) => {
          commit('setAWARD_REDEEM_ERRROR', true)
          errorHandler(err)
        })
    },
    joinTeam({ dispatch, commit, rootGetters }, body) {
      return axiosAdvice
        .post('/new-member', body, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          if (resp.data.status === 'KO') {
            commit('setNEW_MEMBER_STATUS_ERROR', true)
          } else {
            commit('setNEW_MEMBER_STATUS_ERROR', false)
          }
          return resp
        })
        .catch((err) => {
          commit('setNEW_MEMBER_STATUS_ERROR', true)
          errorHandler(err)
        })
    },
    revokeMember({ commit, rootGetters }, params) {
      return axiosAdvice
        .post(
          '/revoke-member',
          {
            reason: params.response,
            codiceCliente: params.ext_uuid
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          return res
        })
        .catch((err) => errorHandler(err))
    },
    shippingConfirm({ commit, rootGetters }, params) {
      return axiosAdvice
        .post(
          '/request-award',
          { ...params.input, codiceCliente: params.id },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.wekit_code === null) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          } else if ([8007, 8008].includes(parseInt(res.data.wekit_code))) {
            commit('setREQUEST_AWARD_GREENCOIS_ERRROR', true)
          } else if (
            [
              500, 8005, 8014, 8015, 8017, 8018, 8020, 8021, 8010, 8012
            ].includes(parseInt(res.data.wekit_code))
          ) {
            commit('setREQUEST_AWARD_DIARY_ERRROR', true)
          } else if (
            [8009, 8011, 8013, 8016, 8019, 1001, 1002, 8001].includes(
              parseInt(res.data.wekit_code)
            )
          ) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          } else {
            commit('setREQUEST_AWARD_GREENCOIS_ERRROR', false)
            commit('setREQUEST_AWARD_DIARY_ERRROR', false)
            commit('setREQUEST_AWARD_CATALOG_ERRROR', false)
          }
          return res
        })
        .catch((err) => {
          if (err.response.data.wekit_code === null) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          } else if (
            [8007, 8008].includes(parseInt(err.response.data.wekit_code))
          ) {
            commit('setREQUEST_AWARD_GREENCOIS_ERRROR', true)
          } else if (
            [
              500, 8005, 8014, 8015, 8017, 8018, 8020, 8021, 8010, 8012
            ].includes(parseInt(err.response.data.wekit_code))
          ) {
            commit('setREQUEST_AWARD_DIARY_ERRROR', true)
          } else if (
            [8009, 8011, 8013, 8016, 8019, 1001, 1002, 8001].includes(
              parseInt(err.response.data.wekit_code)
            )
          ) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          }
          errorHandler(err)
          return err
        })
    },
    async getClientLevel({ commit, rootGetters, dispatch }) {
      await dispatch('getUuid')
      return axiosAdvice
        .get('user-level', {
          params: {
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.status === 'KO') {
            commit('setIDENTITY_LEVEL_ERROR', true)
          } else {
            commit('setClientLevel', res.data.wekit_data[0])
            commit('setIDENTITY_LEVEL_ERROR', false)
          }
        })
        .catch((err) => {
          commit('setIDENTITY_LEVEL_ERROR', true)
          errorHandler(err)
        })
    },
    validateIWCode({ commit, rootGetters }, params) {
      return axiosAdvice
        .post(
          '/V2/play-code',
          {
            ...params,
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          return res.data.wekit_data[0]
        })
        .catch((err) => {
          if (!err.response.data || !err.response.data.wekit_data) {
            throw Error('SERVER_ERROR')
          }
          return err.response.data
        })
    },
    burnIWCode({ commit, rootGetters }, params) {
      return axiosAdvice
        .post(
          'burn-mgm-code',
          {
            ...params,
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    showFriendCode({ commit, rootGetters }, params) {
      return axiosAdvice
        .get('/show-mgm-code', {
          params: {
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.status === 'KO') {
            throw Error(res)
          } else {
            // success 200
            commit('setCodeFriendGreeners', res.data.wekit_data[0].mgm_code)
            return res.data.wekit_data[0]
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    generateFriendCode({ commit, rootGetters }, params) {
      return axiosAdvice
        .get('/generate-mgm-code', {
          params: {
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new Error(res)
          } else {
            // success 200
            commit('setCodeFriendGreeners', res.data.wekit_data[0].mgm_code)
            return res.data.wekit_data[0]
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    getReward({ rootGetters }) {
      return axiosAdvice
        .get('/catalog', {
          params: {
            catalog_name: 'IW',
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          return res.data.wekit_data[0].attributes
        })
        .catch((err) => errorHandler(err))
    },
    requestAward({ rootGetters, commit }, body) {
      return axiosAdvice
        .post(
          '/request-award',
          {
            ...body,
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.wekit_code === null) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          } else if ([8007, 8008].includes(parseInt(res.data.wekit_code))) {
            commit('setREQUEST_AWARD_GREENCOIS_ERRROR', true)
          } else if (
            [
              500, 8005, 8014, 8015, 8017, 8018, 8020, 8021, 8010, 8012
            ].includes(parseInt(res.data.wekit_code))
          ) {
            commit('setREQUEST_AWARD_DIARY_ERRROR', true)
          } else if (
            [8009, 8011, 8013, 8016, 8019, 1001, 1002, 8001].includes(
              parseInt(res.data.wekit_code)
            )
          ) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          } else {
            commit('setREQUEST_AWARD_GREENCOIS_ERRROR', false)
            commit('setREQUEST_AWARD_DIARY_ERRROR', false)
            commit('setREQUEST_AWARD_CATALOG_ERRROR', false)
          }
          return res
        })
        .catch((err) => {
          if (err.response.data.wekit_code === null) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          } else if (
            [8007, 8008].includes(parseInt(err.response.data.wekit_code))
          ) {
            commit('setREQUEST_AWARD_GREENCOIS_ERRROR', true)
          } else if (
            [
              500, 8005, 8014, 8015, 8017, 8018, 8020, 8021, 8010, 8012
            ].includes(parseInt(err.response.data.wekit_code))
          ) {
            commit('setREQUEST_AWARD_DIARY_ERRROR', true)
          } else if (
            [8009, 8011, 8013, 8016, 8019, 1001, 1002, 8001].includes(
              parseInt(err.response.data.wekit_code)
            )
          ) {
            commit('setREQUEST_AWARD_CATALOG_ERRROR', true)
          }
          errorHandler(err)
          return err
        })
    },
    getWekitMap({ commit, rootGetters }) {
      return axiosAdvice
        .get('/wekitmap', {
          params: {
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.status === 'KO') {
            commit('setWEKITMAP_ERROR', true)
          } else {
            commit('setWekitMap', res.data.wekit_data)
            commit('setWEKITMAP_ERROR', false)
          }
        })
        .catch((err) => {
          commit('setWEKITMAP_ERROR', true)
          errorHandler(err)
        })
    },
    newTransaction({ dispatch, rootGetters, state, commit }, body) {
      return axiosAdvice
        .post(
          '/tracking',
          {
            ...body,
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then(async (res) => {
          if (res.data.status === 'KO' && body.action !== '21') {
            commit('setTRANSACTION_ERROR', true)
          } else {
            state.transactionList = []
            await dispatch('getUuid')
            state.transactionList = []
            dispatch('getBalance', {
              ext_uuid:
                rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
            })
            commit('setTRANSACTION_ERROR', false)
          }
          return res.data
        })
        .catch((err) => {
          commit('setTRANSACTION_ERROR', true)
          errorHandler(err)
        })
    },
    newTransactionClientSelected(
      { dispatch, rootGetters, commit },
      body,
      clientSelected
    ) {
      return axiosAdvice
        .post('/tracking', body, {
          params: {
            ext_uuid:
              clientSelected ||
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then(async (res) => {
          if (res.data.status === 'KO') {
            commit('setTRANSACTION_ERROR', true)
          } else {
            await dispatch('getUuid')
            dispatch('getBalance', {
              ext_uuid:
                rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
            })
            commit('setTRANSACTION_ERROR', false)
          }
          return res.data
        })
        .catch((err) => {
          commit('setTRANSACTION_ERROR', true)
          errorHandler(err)
        })
    },
    getVideo({ state, commit }) {
      axiosCMS
        .get('/loyalty-video', {
          params: {
            client_type: STATUS_GREENERS[state.memberStatus.statusGreeners]
          }
        })
        .then((res) => {
          commit('setVideo', res.data)
        })
        .catch((err) => errorHandler(err))
    },
    getPhoto({ state, commit }) {
      axiosCMS
        .get('/loyalty-foto', {
          params: {
            client_type: STATUS_GREENERS[state.memberStatus.statusGreeners]
          }
        })
        .then((res) => {
          commit('setPhoto', res.data)
        })
        .catch((err) => errorHandler(err))
    },
    async getUuid({ dispatch, rootGetters }) {
      if (!rootGetters['account/mainClient']) {
        await dispatch('account/getMainClient', null, { root: true })
        await dispatch('account/getDetailForClient', null, { root: true })
      }
    },
    getLoyaltyLandingPrizes({ commit }) {
      return axiosCMS
        .get('/wekit-award')
        .then((res) => {
          commit('setLoyaltyLandingPrizes', res.data)
          return res.data
        })
        .catch((err) => errorHandler(err))
    },
    getSurveys({ state, commit }) {
      return axiosCMS
        .get('/loyalty-survey', {
          params: {
            client_type: STATUS_GREENERS[state.memberStatus.statusGreeners]
          }
        })
        .then((res) => {
          commit('setSurveys', res.data)
          return res.data
        })
        .catch((err) => errorHandler(err))
    },
    getQuiz({ state, commit }) {
      return axiosCMS
        .get('/mission_quiz', {
          params: {
            client_type: STATUS_GREENERS[state.memberStatus.statusGreeners]
          }
        })
        .then((res) => {
          commit('setQuiz', res.data)
          return res.data
        })
        .catch((err) => errorHandler(err))
    },
    getLoyaltyNews({ state, commit }) {
      return axiosCMS
        .get('/loyalty-news', {
          params: {
            client_type: STATUS_GREENERS[state.memberStatus.statusGreeners]
          }
        })
        .then((res) => {
          commit('setLoayaltyNews', res.data)
          return res.data
        })
        .catch((err) => errorHandler(err))
    },
    getAvailableMissions({ commit, state }, params) {
      let filterMissionByUserTypeAndCommodity = (mission) => {
        return (
          params.currentClientTypes.some(
            (item) =>
              mission.current_client_type === '' ||
              mission.current_client_type
                .toUpperCase()
                .includes(item.toUpperCase())
          ) &&
          params.currentClientCommodity.some(
            (item) =>
              mission.active_commodities === '' ||
              mission.active_commodities
                .toUpperCase()
                .includes(item.toUpperCase())
          )
        )
      }
      let filterMissionByLevels = (mission) => {
        let levelMinMission = 1
        let levelMaxMission = 5
        if (!mission.level_min) mission.level_min = levelMinMission
        if (!mission.level_max) mission.level_max = levelMaxMission
        let clientLevel = 1

        switch (state.clientLevel.level_name) {
          case 'LEVEL0':
            clientLevel = 1
            break
          case 'LEVEL1':
            clientLevel = 2
            break
          case 'LEVEL2':
            clientLevel = 3
            break
          case 'LEVEL3':
            clientLevel = 4
            break
          case 'LEVEL4':
            clientLevel = 5
            break
        }
        return (
          clientLevel >= parseInt(mission.level_min) &&
          clientLevel <= parseInt(mission.level_max)
        )
        // return true
      }

      let listOfIds = state.transactionList
        .filter((el) => el.detail1)
        .map((el) => el.detail1)

      let surveys = state.surveys.filter(
        (mission) =>
          listOfIds.indexOf(mission.id) === -1 &&
          filterMissionByUserTypeAndCommodity(mission) &&
          filterMissionByLevels(mission)
      )
      let videos = state.video.filter(
        (mission) =>
          listOfIds.indexOf(mission.id) === -1 &&
          filterMissionByUserTypeAndCommodity(mission) &&
          filterMissionByLevels(mission)
      )
      let photos = state.photo.filter(
        (mission) =>
          listOfIds.indexOf(mission.id) === -1 &&
          filterMissionByUserTypeAndCommodity(mission) &&
          filterMissionByLevels(mission)
      )
      let quiz = state.quiz.filter(
        (mission) =>
          listOfIds.indexOf(mission.id) === -1 &&
          filterMissionByUserTypeAndCommodity(mission) &&
          filterMissionByLevels(mission)
      )

      let availableMissions = surveys.concat(videos).concat(photos).concat(quiz)

      if (availableMissions.length === 0) return availableMissions

      let returnMissions = availableMissions.filter((mission) => {
        let startDate = new Date(mission.start_date)
        let endDate = new Date(mission.end_date)
        let now = new Date()
        if (startDate <= now && now <= endDate) {
          const checkNew = new Date()
          if (startDate >= checkNew.setDate(now.getDate() - 10)) {
            mission['badge'] = 'NUOVO'
          }
          const checkDeadline = endDate
          if (now >= checkDeadline.setDate(endDate.getDate() - 10)) {
            mission['badge'] = 'IN SCADENZA'
          }
          if (mission.wekit_event_id === '1500') {
            switch (mission.wekit_action_id) {
              case '1507':
                mission.second_app_section_label = 'Carbon footprint'
                break
              case '1504':
              case '1541':
              case '1542':
              case '1543':
              case '1544':
              case '1545':
                mission.second_app_section_label = 'Survey'
                break
              case '1506':
              case '1561':
              case '1562':
              case '1563':
              case '1564':
              case '1565':
                mission.second_app_section_label = 'Foto'
                break
              case '1505':
              case '1551':
              case '1552':
              case '1553':
              case '1554':
              case '1555':
                mission.second_app_section_label = 'Video'
                break
              case '1503':
              case '1531':
              case '1532':
              case '1533':
              case '1534':
              case '1535':
                mission.second_app_section_label = 'Quiz'
                break
              default:
                mission.second_app_section_label = 'Vai al catalogo'
                break
            }
          } else if (mission.wekit_event_id === '7') {
            if (mission.wekit_action_id === '140') {
              mission.second_app_section_label =
                'Iniziativa natalizia – The Garden'
            }
          }
          return mission
        }
      })
      commit('setAvailableMissions', returnMissions)
      return returnMissions
    },
    requesteddAwardCounter({ rootGetters, commit }) {
      return axiosAdvice
        .get('/requested-award-counter', {
          params: {
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode,
            catalog_name: 'WL-GIFT'
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.data.wekit_code === 1002) {
            commit('setAWARD_REDEEM_ERRROR', true)
          } else if (res.data.wekit_code === 1001) {
            commit('setAWARD_REDEEM_ERRROR', true)
          } else {
            commit('setAWARD_REDEEM_ERRROR', false)
            commit('setAWARD_REDEEM_ERRROR', false)
            commit(
              'setAwardCounter',
              res.data.wekit_data[0].prizeAvailable || 0
            )
          }
        })
        .catch((err) => {
          if (err.response.data.wekit_code === 1002) {
            commit('setAWARD_REDEEM_ERRROR', true)
          } else {
            commit('setAWARD_REDEEM_ERRROR', true)
          }
          errorHandler(err)
        })
    },
    setLoyaltyStatus({ rootGetters, commit }, params) {
      return axiosAdvice
        .post(
          '/set-status',
          {
            codiceCliente: params.codiceCliente,
            status_category: params.status_category
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.status === 200 && res.data.wekit_data[0]) {
            return res.data.wekit_data[0]
          } else {
            throw Error('SERVER_ERROR')
          }
        })
        .catch((err) => {
          errorHandler(err)
        })
    },
    async greenerRegistration({ dispatch, rootGetters, commit }) {
      try {
        axiosAdvice.defaults.baseURL =
          rootGetters['account/mainClientLoyaltySelected'].clientOwnerType ===
          'BUS'
            ? ENV_CONFIGS.WEKIT_BASE.replace('residential', 'business')
            : ENV_CONFIGS.WEKIT_BASE.replace('business', 'residential')

        let memberStatus = await dispatch('getMemberStatus', {
          ext_uuid:
            rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
        })

        if (!memberStatus.user_registered) {
          let clientDetail = await dispatch(
            'account/getDetailForClient',
            {
              clientOwnerCode:
                rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
            },
            { root: true }
          )
          let cfpValue = await dispatch(
            'cfp/getLastCfpValue',
            {
              clientOwnerCode:
                rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode
            },
            { root: true }
          )

          const {
            clientCode,
            name,
            surname,
            contactMail,
            phone,
            fiscalCode,
            birthDate,
            residentialProvince
          } = clientDetail
          let date
          if (!birthDate) {
            date = ''
          } else if (!birthDate.match(/\d{4}-\d{2}-\d{2}/g)) {
            let re = /(\d{2})\/(\d{2})\/(\d{4})/
            date = birthDate.replace(re, '$3-$2-$1')
          } else {
            date = birthDate
          }
          let body = {
            codiceCliente: clientCode,
            first_name: name,
            last_name: surname,
            email: contactMail,
            phone: phone,
            fiscal_code: fiscalCode,
            birthday: birthDate,
            province: residentialProvince,
            cfp: cfpValue || undefined
          }

          await dispatch('joinTeam', body)
        }

        if (
          memberStatus.status_category !== 'AWARE' &&
          memberStatus.status_category !== 'FREEWILL'
        ) {
          dispatch('setLoyaltyStatus', {
            codiceCliente:
              rootGetters['account/mainClientLoyaltySelected'].clientOwnerCode,
            status_category: 'AWARE'
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
