<template>
  <div class="std-side-padding">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="support__title mb-6">Richiedi informazioni</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="support__sub-title">Contattaci</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <p class="support__title-desc">
          Scegli il canale che preferisci per ricevere assistenza subito:
        </p>
      </v-col>
    </v-row>
    <v-row class="mb-12">
      <v-col xs="6">
        <v-card
          :to="'fibercontacts'"
          outlined
          :disabled="!chatOpen"
          height="225"
          class="v-card--rounded"
          :class="{ 'std-panel--shadowed': chatOpen }"
          @click="openChat"
        >
          <v-list-item-content style="height: 100%">
            <v-icon class="pt-5 active" size="90">mdi-comment-outline</v-icon>
            <v-divider class="mt-auto" />
            <v-list-item-title class="text-center font-weight-bold mt-3 active"
              >Chat</v-list-item-title
            >
          </v-list-item-content>
        </v-card>
      </v-col>
      <v-col xs="6">
        <v-card
          :to="'fibercontacts'"
          outlined
          :disabled="!whatsUpOpen"
          height="225"
          class="v-card--rounded"
          :class="{ 'std-panel--shadowed': whatsUpOpen }"
        >
          <v-list-item-content style="height: 100%">
            <v-icon class="pt-5 active" size="90">mdi-whatsapp</v-icon>
            <v-divider class="mt-auto" />
            <v-list-item-title class="text-center font-weight-bold mt-3 active"
              >Whatsapp {{ whatsUp }}
            </v-list-item-title>
          </v-list-item-content>
        </v-card>
      </v-col>
    </v-row>
    <v-footer color="white">
      <h2 class="support__title">Oppure contattaci qui</h2>
      <v-row>
        <v-col class="px-0">
          <div v-if="email && mobile">
            <p>Email</p>
            <p>
              <a :href="'mailto:' + email">{{ email }}</a>
            </p>
            <p
              v-if="
                accountProperties.hasFiberActivating ||
                accountProperties.hasFiberActive
              "
            >
              <a :href="'mailto:' + fiberEmail">{{ fiberEmail }}</a>
            </p>
          </div>
        </v-col>
        <v-col>
          <p>Numero verde</p>
          <p><span>(lun-ven 08-20 / sab 08-15)</span></p>
          <p class="mb-0">
            <a :href="'tel:' + staticPhone">{{
              staticPhone.replace(/800/g, '800.')
            }}</a
            ><span> (da fisso)</span>
          </p>
          <p>
            <a :href="'tel:' + mobile"> {{ mobile }} </a
            ><span> (da mobile)</span>
          </p>
        </v-col>
        <v-col>
          <p>Social</p>
          <v-row>
            <a href="">
              <v-icon class="social-button" size="64">mdi-facebook</v-icon>
            </a>
            <a href="">
              <v-icon class="social-button" size="64">mdi-twitter</v-icon>
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer color="white" height="80" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { eventBus } from '@/main'

export default {
  name: 'FiberContacts',
  beforeMount() {
    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.CHAT,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((res) => {
        this.chatOpen = res
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((res) => {
        this.staticPhone = res.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.WHATSAPP,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((res) => {
        this.whatsUpOpen = res
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.EMAIL,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((res) => {
        this.email = res.contact
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.MOBILE,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((res) => {
        this.mobile = res.contact
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.WHATSAPP,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.whatsUp = resp.contact
      })
      .catch((error) => {
        console.error(error)
        // GenErr.handleGenericError(error && error.message)
      })
  },
  data: () => ({
    email: '',
    fiberEmail: 'fibracare@sorgenia.it',
    mobile: '',
    staticPhone: '',
    chatOpen: true,
    whatsUpOpen: true,
    whatsUp: ''
  }),
  computed: {
    ...mapGetters('account', ['accountClients']),
    ...mapGetters('account', ['accountProperties']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Richiedi infomazioni'
        }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('account', ['getClientsForAccount']),
    ...mapActions('interactions', ['isChannelOpen']),
    ...mapActions('interactions', ['getChannelInfo']),
    openChat() {
      if (this.chatOpen) {
        eventBus.$emit('chat:open', { from: 'support' })
      }
    }
  }
}
</script>
<style lang="scss" src="../../styles/support/_main.scss" />
<style lang="scss" scoped>
.social-button {
  border-radius: 50%;
  border: 4px solid #12256a;
  color: #12256a;
  padding: 10px;
  margin-right: 30px;
}
.support__title {
  width: 100%;
}
.v-card.std-panel--shadowed {
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12);
}
.v-footer {
  margin-top: 100px;
  border-color: transparent;
  p {
    font-weight: bold;
    margin-bottom: 10px;
  }
  a {
    //color: #3cf;
    cursor: pointer;
  }
  a:visited {
    color: #3cf;
  }
  span {
    font-weight: 400;
  }
}
.active {
  color: #12256a;
}
</style>
