<template>
  <section>
    <transition name="fade">
      <div class="card" v-show="!loaded">
        <Placeholder type="card" />
        <slot name="button" />
      </div>
    </transition>
    <transition name="fade">
      <div class="card" v-show="loaded">
        <div :class="`card__content ${registered ? 'registered' : ''}`">
          <slot name="bkg" />
          <slot name="text" />
        </div>
        <slot name="button" />
      </div>
    </transition>
  </section>
</template>
<script>
import Placeholder from '@components/global/Placeholder.vue'

export default {
  name: 'layoutCard',
  components: {
    Placeholder
  },
  props: {
    registered: { type: Boolean, default: false },
    loaded: { type: Boolean, default: false }
  }
}
</script>
<style lang="scss">
.card {
  min-width: 261px;
  height: 271px;
  border-top-right-radius: 20px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
  max-width: 554px;
  &__content {
    width: 100%;
    height: 199px;
    position: relative;
    .bkg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }
    // &::after {
    //   content: '';
    //   background: linear-gradient(0deg, rgba(18,37,106,1) 0%, rgba(255,255,255,0) 100%);
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    //   height: 50%;
    //   z-index: 2;
    // }
    &.registered {
      &::after {
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(18, 37, 106, 0.8968721277573529) 10%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
    .text {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 32px 24px 32px;
      z-index: 3;
      .title {
        line-height: 26px;
        font-size: 24px;
        letter-spacing: 0.4px;
        font-weight: bold;
        &.big {
          letter-spacing: 0.4px;
          font-size: 24px;
        }
        h3 {
          font-weight: bold;
          letter-spacing: 0.4px;
        }
        .t-white {
          color: white;
        }
        .t-green {
          color: #97d700;
        }
      }
      .desc {
        color: white;
        margin-left: 10px;
        h5 {
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.8px;
        }
        .smallText {
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0.5px;
        }
      }
    }
  }
  .btn-card {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #97d700;
    padding: 0px 24px;
    .btn-text {
      color: #12256a;
      font-weight: bold;
      letter-spacing: 0.8px;
      font-size: 19px;
    }
  }
}
</style>
