<template>
  <div class="std-side-padding">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1 class="font-weight-bold">POD e PDR</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ body.bodyData }}</v-col>
    </v-row>
    <v-row>
      <span class="mdi mdi-phone"></span>
      <div>
        <v-row class="contacts__phone-nums">
          <span class="color--blue font-weight-bold">{{
            formatStaticPhone(staticPhone)
          }}</span>
          <span class="color--blue font-weight-bold ml-2"> da fisso</span>
        </v-row>
        <v-row class="contacts__phone-nums">
          <span class="color--blue font-weight-bold">{{
            formatMobile(mobile)
          }}</span>
          <span class="color--blue font-weight-bold ml-2"> da mobile</span>
        </v-row>
      </div>
    </v-row>
    <template v-if="whatsAppOpen">
      <v-row>
        <v-col>oppure su Whatsapp</v-col>
      </v-row>
      <v-row class="d-flex">
        <span class="mdi mdi-whatsapp"></span>
        <div class="d-flex">
          <v-row>
            <v-col>
              <span class="color--blue font-weight-bold">{{
                formatWhatsApp(whatsApp)
              }}</span>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </template>
    <v-col class="color--blue font-weight-bold d-flex"><span></span></v-col>
  </div>
</template>

<script>
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { mapActions } from 'vuex'

export default {
  name: 'IdentificationCode',
  data: () => ({
    body: {
      bodyData:
        "POD e PDR sono i codici alfanumerici di 14 cifre che identiﬁcano univocamente il punto ﬁsico presso il quale le tue utenze ti vengono consegnate. Nello speciﬁco il POD (Point of Delivery) è il codice alfanumerico nazionale di 14 caratteri relativo all'utenza di Energia Elettrica e non cambia anche se cambi fornitore. Il PDR (Punto di Riconsegna), invece, identiﬁca il punto ﬁsico presso il quale il Gas viene consegnato a casa tua.",
      // phoneNums: [{ phoneNum: '800.294.333', phoneType: ' da mobile' }, { phoneNum: '02.44388001', phoneType: ' da mobile' }],
      splitArr: []
    },
    whatsApp: '',
    staticPhone: '',
    mobile: '',
    whatsAppOpen: false
  }),
  computed: {
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'POD e PDR' }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('interactions', ['getChannelInfo', 'isChannelOpen']),
    splitBodyData: function () {
      this.data.splitArr = this.bodyData.split(' .')
      return this.data.splitArr
    },
    formatStaticPhone(val) {
      if (!val) return ''
      return val.toString().match(/.{3}/g).join('.')
    },
    formatMobile(val) {
      if (!val) return ''
      return val.toString().slice(0, 2) + '.' + val.toString().slice(2)
    },
    formatWhatsApp(val) {
      if (!val) return ''
      return val.toString().slice(0, 3) + '.' + val.toString().slice(3)
    }
  },
  beforeMount() {
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.WHATSAPP,
      area: consts.CALLCENTER_AREAS.POD_PDR
    })
      .then((res) => {
        this.whatsApp = res.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area: consts.CALLCENTER_AREAS.POD_PDR
    })
      .then((res) => {
        this.staticPhone = res.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.MOBILE,
      area: consts.CALLCENTER_AREAS.POD_PDR
    })
      .then((res) => {
        this.mobile = res.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.WHATSAPP,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.whatsAppOpen = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  }
}
</script>

<style lang="scss" scoped>
.mdi.mdi-phone:before {
  font-size: 50px;
  color: #12256a;
  padding-top: 10px;
}
.mdi.mdi-whatsapp {
  font-size: 50px;
  color: #12256a;
}
.contacts__phone-nums {
  margin: 20px 12px 0;
  &:last-child {
    margin-top: 5px;
  }
}
</style>

// body, whatsapp, phone numbers array
