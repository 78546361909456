var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{staticClass:"coins",attrs:{"header-size":"small"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":"6"}},[_c('v-breadcrumbs',{staticClass:"pb-0",attrs:{"items":_vm.breadcrumbsItems,"divider":">"},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/chevron_rx_light.svg"),"width":"24"}})]},proxy:true}])}),_c('h1',[_vm._v("Ritira i tuoi premi")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"5"}},[_c('v-img',{attrs:{"contain":"","width":"290","src":require("@/assets/greeners/svg/logoGreeners_new.svg")}})],1)]},proxy:true}])},[_c('div',{staticClass:"page"},[_c('div',{staticClass:"mt-15"},[_c('div',{staticClass:"HeadingL"},[_vm._v("Ecco i premi che hai accumulato")])]),_c('div',{staticClass:"mt-10 cards"},[(_vm.awardCounter > 0)?_c('div',{staticClass:"card-item"},[_c('v-card',{staticClass:"v-card--rounded gifts-content__item",attrs:{"to":{ name: 'welcomeGift' }},on:{"!click":function($event){return _vm.trackTapEvent({
              name: 'greeners_prizesReqPrize_tap',
              params: {
                client_code: _vm.mainClientLoyaltySelected.clientOwnerCode,
                client_type: _vm.mainClientLoyaltySelected.clientOwnerType,
                card_type: 'FIDELITY'
              }
            })}}},[_c('div',{staticClass:"loyalty-rewards"},[_c('div',{staticClass:"loyalty-rewards-content"},[_c('div',{staticClass:"HeadingsSubtitleM color--blue mb-3"},[_vm._v(" "+_vm._s(_vm.awardCounter > 1 ? 'Premi' : 'Premio')+" Fedeltà ")]),_c('div',{staticClass:"BodyM item-description"},[(_vm.awardCounter > 1)?_c('span',[_vm._v(" Hai "+_vm._s(_vm.awardCounter)+" premi che ti aspettano! ")]):_c('span',[_vm._v(" Hai un premio che ti aspetta! ")])])]),_c('div',{staticClass:"loyalty-rewards-image"},[_c('img',{staticClass:"loyalty-rewards-image sticky",attrs:{"src":require("@assets/greeners/fiocco.svg")}})])]),_c('v-divider',{staticClass:"gifts-content__item__divider"}),_c('router-link',{staticClass:"gifts-content__item__link",attrs:{"to":{ name: 'welcomeGift' }}},[_vm._v(" "+_vm._s(_vm.awardCounter > 1 ? 'Richiedili' : 'Richiedilo')+" ")])],1)],1):_vm._e(),(
          _vm.availableAwards.welcome_iw === '002' || _vm.availableAwards.iw === '002'
        )?_c('div',{staticClass:"card-item"},[_c('v-card',{staticClass:"v-card--rounded gifts-content__item",attrs:{"to":{ name: 'iwGift' }},on:{"!click":function($event){return _vm.trackTapEvent({
              name: 'greeners_prizesReqPrize_tap',
              params: {
                client_code: _vm.mainClientLoyaltySelected.clientOwnerCode,
                client_type: _vm.mainClientLoyaltySelected.clientOwnerType,
                card_type: 'IW'
              }
            })}}},[_c('div',{staticClass:"loyalty-rewards"},[_c('div',{staticClass:"loyalty-rewards-content"},[_c('div',{staticClass:"HeadingsSubtitleM color--blue mb-3"},[_vm._v("Hai vinto!")]),_c('div',{staticClass:"BodyM item-description"},[_c('span',[_vm._v(" Non aspettare, richiedi subito i tuoi premi! ")])])]),_c('div',{staticClass:"loyalty-rewards-image"},[_c('img',{staticClass:"loyalty-rewards-image sticky",attrs:{"src":require("@assets/greeners/festa.svg")}})])]),_c('v-divider',{staticClass:"gifts-content__item__divider"}),_c('router-link',{staticClass:"gifts-content__item__link",attrs:{"to":{ name: 'iwGift' }}},[_vm._v(" Richiedi il tuo premio ")])],1)],1):_vm._e(),(_vm.isUserHasLevel)?_c('div',{staticClass:"card-item"},[_c('v-card',{staticClass:"v-card--rounded greeners-card",attrs:{"to":{ name: 'promote' }},on:{"!click":function($event){return _vm.trackTapEvent({
              name: 'greeners_prizesReqPrize_tap',
              params: {
                client_code: _vm.mainClientLoyaltySelected.clientOwnerCode,
                client_type: _vm.mainClientLoyaltySelected.clientOwnerType,
                card_type: 'LEVEL'
              }
            })}}},[_c('img',{staticClass:"gifts-content__item__img gifts-content__item__img--center mb-3",attrs:{"src":require("@assets/greeners/doublecoin.png")}}),_c('div',[_c('div',[_c('div',{staticClass:"greeners-text"},[_vm._v(" Hai superato il livello! "),_c('br'),_vm._v(" Richiedi adesso il tuo premio! ")])])]),_c('v-divider',{staticClass:"gifts-content__item__divider"}),_c('router-link',{staticClass:"gifts-content__item__link mt-2",attrs:{"to":{ name: 'promote' }}},[_vm._v(" Richiedi premio ")])],1)],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }