<template>
  <v-dialog
    persistent
    v-model="isDialogOpen"
    max-width="760"
    content-class="std-panel std-panel--shadowed std-panel--overflow-auto"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" :class="!isAbstract ? 'd-inline-block' : 'd-block'">
        <slot v-if="$slots.default" />
        <v-btn v-else color="primary" dark> Speed test </v-btn>
      </div>
    </template>
    <v-btn icon fab x-small class="v-dialog__close" @click="handleCloseDialog">
      <img :src="icons.close" alt="close dialog" />
    </v-btn>
    <Result
      v-if="!result.isResultReady"
      :result="result"
      @newTest="startTest"
      @nextStep="$emit('nextStep')"
    />
    <Result v-else :result="result" @newTest="startTest" />
  </v-dialog>
</template>

<script>
import IconClose from '@/assets/icons/ic-close.svg'
import Result from '@/components/speedTest/result'
import { eventBus } from '@/main'
import { mapActions } from 'vuex'

const worker = new Worker('/speedtest/libndt7-worker.js')
// events groups all events
const events = {
  // open is the event emitted when the socket is opened. The
  // object bound to this event is always null.
  open: 'ndt7.open',

  // close is the event emitted when the socket is closed. The
  // object bound to this event is always null. The code SHOULD
  // always emit this event at the end of the test.
  close: 'ndt7.close',

  // error is the event emitted when the socket is closed. The
  // object bound to this event is always null.
  error: 'ndt7.error',

  // serverMeasurement is a event emitted periodically during a
  // ndt7 download. It represents a measurement performed by the
  // server and sent to us over the WebSocket channel.
  serverMeasurement: 'ndt7.measurement.server',

  // clientMeasurement is a event emitted periodically during a
  // ndt7 download. It represents a measurement performed by the client.
  clientMeasurement: 'ndt7.measurement.client',

  // selectedServer is emitted once when we've selected a server.
  selectedServer: 'ndt7.selected_server'
}

export default {
  name: 'indexComponent',
  props: {
    isAbstract: { type: Boolean, default: false }
  },
  components: {
    Result
  },
  data: () => ({
    isDialogOpen: false,
    unUsedTCP: [
      'State',
      'CAState',
      'Retransmits',
      'Probes',
      'Backoff',
      'Options',
      'LastDataSent',
      'WScale',
      'SndMSS',
      'RcvMSS',
      'Unacked',
      'Sacked',
      'LastDataSent',
      'LastDataRecv',
      'LastAckRecv',
      'PMTU',
      'RcvSsThresh',
      'RTT',
      'RTTVar',
      'SndSsThresh',
      'SndCwnd'
    ],
    result: {
      download: {
        speed: 0
      },
      upload: {
        speed: 0
      },
      inProgress: null,
      isResultReady: false
    }
  }),
  computed: {
    icons: () => ({
      close: IconClose
    })
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    handleCloseDialog() {
      this.isDialogOpen = false
    },
    testDownload() {
      this.$set(this.result, 'inProgress', 'download')
      worker.postMessage({
        key: 'download',
        value: {
          hostname: this.MaybeHostname()
        }
      })
    },
    testUpload() {
      this.$set(this.result, 'inProgress', 'upload')
      worker.postMessage({
        key: 'upload',
        value: {
          hostname: this.MaybeHostname()
        }
      })
    },
    startTest() {
      this.result.isResultReady = false
      this.resetResult()
      this.testDownload()

      worker.onmessage = (ev) => {
        const msg = ev.data
        const data = msg.value
        if (msg.key === events.clientMeasurement) {
          this.result[data.direction].speed = this.getSpeed(
            data.app_info.num_bytes,
            data.elapsed
          )
        } else if (msg.key === events.close) {
          if (this.result.inProgress === 'download') {
            this.testUpload()
          } else {
            this.result.isResultReady = true
            this.trackTapEvent({
              name: 'upsellingv2_SpeedTestResult_open',
              params: {
                client_selected:
                  this.$store.getters['upsellingFiber/addressCovered']
                    ?.clientOwnerCode || 'not_selected'
              }
            })
          }
        }
      }
    },
    getSpeed: (bytes, time) => ((8 * bytes) / time / 1000 / 1000).toFixed(2),
    resetResult() {
      this.result = {
        download: {
          BBRInfo: {},
          ConnectionInfo: {},
          TCPInfo: {},
          app_info: {},
          speed: '0',
          elapsed: 0
        },
        upload: {
          speed: '---'
        },
        inProgress: null,
        isResultReady: false
      }
    },
    MaybeHostname: () => {
      const url = new URL(window.location.href)
      return url.hash !== '' ? url.hash.substring(1) : ''
    },
    openSpeedTest() {
      this.trackTapEvent({
        name: 'upsellingv2_SpeedTest_open',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              ?.clientOwnerCode || ''
        }
      })
      this.isDialogOpen = true
    }
  },
  created: function () {
    eventBus.$on('speed-test:open', this.openSpeedTest)
  }
}
</script>

<style lang="scss">
.speed-test {
  padding: 8px 16px 16px;

  &__title {
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.04px;
  }

  .row {
    width: 100%;
  }

  .v-btn {
    letter-spacing: 0.2px;
  }
}

h1.speed-test__title {
  font-size: 33px;
  letter-spacing: -0.4px;
  line-height: 1;
}

h2.speed-test__title {
  font-size: 24px;
  letter-spacing: 0.1px;
  line-height: 1.35;
}
</style>
