export const HouseType = {
  INDIPENDENT: 'Casa indipendente',
  APARTAMENT: 'Appartamento',
  TOWNHOUSE: 'Villetta a schiera'
}

export const Ownership = {
  RENT: 'Sono in affitto',
  OWNER: 'Sono proprietario'
}
