<template>
  <div class="dynamicCompoennt">
    <component
      v-bind:is="currentComponent"
      :params="parameters"
      :wakeup="wakeup"
    />
  </div>
</template>

<script>
import { eventBus } from '../main'

export default {
  name: 'dynamicComponent',
  props: {
    component: { type: String },
    parameters: { type: Object, required: false }
  },
  data() {
    return {
      wakeup: false
    }
  },
  created() {
    eventBus.$on('dynamic_component:wakeup', () => {
      this.wakeup = !this.wakeup
    })
  },
  computed: {
    currentComponent() {
      return () => import(`@/components/${this.component}`)
    },
    currentProperties() {
      return this.parameters || {}
    }
  }
}
</script>
<style scoped></style>
