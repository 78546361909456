<template>
  <div :class="{ dreamService: isMobile }" v-if="loaded">
    <v-row class="dreamService__header" v-if="isMobile">
      <v-col cols="11">
        <button
          type="button"
          class="dreamService__header-back"
          @click="onBackClick()"
        >
          <v-icon color="white" size="30">mdi-chevron-left</v-icon>
        </button>
      </v-col>

      <v-col cols="1" class="flex-grow-0 pl-0">
        <v-img
          @click="$router.push({ name: 'publicDreamServiceDone' })"
          class="close"
          contain
          width="16"
          src="@assets/welcome/ic-close-white-big.png"
        />
      </v-col>
    </v-row>
    <div
      :class="`std-side-padding pb-10 ${
        isMobile ? 'dreamService__content-wrap' : ''
      }`"
      v-if="loaded"
    >
      <v-row v-if="!isSmallDevice">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="12" class="pb-0">
          <v-row>
            <v-col cols="auto" class="limitedTo11Cols">
              <h1 class="category">{{ currentRequest.categoria }}</h1>
            </v-col>
            <v-col cols="1">
              <img
                :class="`flex-grow-0 ${isMobile ? 'mt-n2 ml-n4' : 'mt-2 ml-3'}`"
                v-if="currentRequest.servizio === 'Ele'"
                src="@/assets/icons/ic-light-blu.svg"
                height="48"
                width="48"
              />
              <img
                :class="`flex-grow-0 ${isMobile ? 'mt-n2 ml-n4' : 'mt-2 ml-3'}`"
                v-if="currentRequest.servizio === 'Gas'"
                src="@/assets/icons/ic-fire-blu.svg"
                height="48"
                width="48"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="isMobile && currentRequest.chiuso === 'Y'">
        <v-col>
          <a
            class="d-flex justify-center evaluate white--text v-btn--shadowed v-btn v-btn--contained v-btn--rounded theme--light v-size--large primary"
            href="https://it.surveymonkey.com/r/VFLK7PT"
            target="_blank"
            @click="
              trackTapEvent({
                name: 'dreamService_rateService_tap',
                toNative: isMobile
              })
            "
          >
            Valuta il servizio
          </a>
        </v-col>
      </v-row>
      <v-row
        v-for="(msg, i) in messages"
        :key="i"
        :class="`rowDream ${
          isCompleted(msg, currentRequest) ? 'completedRow' : ''
        }`"
      >
        <v-col
          :cols="`${isMobile ? '12' : '9'}`"
          :class="`message ${i === 0 ? 'toRead' : ''} ${
            isCompleted(msg, currentRequest) ? 'last' : ''
          }`"
        >
          <v-row :class="`${isMobile ? 'pt-0' : 'pt-2'}`">
            <v-col cols="1" class="borderDream">
              <!-- <div class="timeline timeline-first2second"></div> -->
              <img
                v-if="isCompleted(msg, currentRequest)"
                :width="isMobile ? 43 : 48"
                class="timeline-icon"
                src="../../assets/dreamservice_completed.svg"
              />
              <img
                v-else
                width="28"
                class="timeline-icon"
                src="../../assets/ic-point-timeline.png"
              />
            </v-col>
            <v-col
              :cols="`${
                isMobile ? '11' : isCompleted(msg, currentRequest) ? '6' : '8'
              } pt-1`"
            >
              <span class="message-text">{{ msg.messaggio }}</span>
              <span class="message-subtext mt-2">{{ msg.operatore }}</span>
              <v-row :class="`${isMobile ? 'pt-0' : 'pt-1'} pl-0 ml-0`">
                <v-col :cols="`${isMobile ? '4' : '3'}`" class="pl-0 ml-0"
                  ><span class="message-data">{{ msg.data }}</span></v-col
                >
                <v-col :cols="`${isMobile ? '8' : '6'}`"
                  ><span class="message-channel"
                    >Tramite {{ currentRequest.canale }}</span
                  ></v-col
                >
              </v-row>
            </v-col>
            <v-col
              v-if="isCompleted(msg, currentRequest) && !isMobile"
              class="col-3 offset-1"
              align-self="center"
            >
              <a
                class="evaluate white--text v-btn--shadowed v-btn v-btn--contained v-btn--rounded theme--light v-size--large primary"
                href="https://it.surveymonkey.com/r/VFLK7PT"
                target="_blank"
              >
                Valuta il servizio
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { checkMobile, dynamicSort, objectCopy } from '@/js/utils.js'

export default {
  name: 'DreamServiceDetail',
  data() {
    return {
      isSmallDevice: window.innerWidth <= 480,
      loaded: true,
      isMobile: checkMobile(),
      isTablet: window.innerWidth > 480 && window.innerWidth <= 1024,
      filter: ''
    }
  },
  computed: {
    ...mapGetters('account', ['accountClients']),
    breadcrumbsItems() {
      let destination = this.isMobile
        ? 'publicDreamServiceList'
        : 'DreamServiceList'
      let currentCategory =
        this.currentRequest?.categoria[0].toUpperCase() +
        this.currentRequest.categoria.slice(1)
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        {
          to: {
            name: 'Statuses',
            params: { clientCode: this.$route.params.clientCode }
          },
          text: 'Stato richieste',
          exact: true
        },
        {
          to: {
            name: destination,
            params: { clientCode: this.$route.params.clientCode }
          },
          text: 'Avanzamento richieste',
          exact: true
        },
        { to: { name: this.$router.currentRoute.name }, text: currentCategory }
      ]

      return res
    },
    currentClientCode() {
      return this.$route.params.clientCode
    },
    currentRequestId() {
      return this.$route.params.requestId
    },
    currentClient() {
      let clientCode = this.currentClientCode
      if (clientCode) {
        return this.clients[clientCode]
      } else {
        return null
      }
    },
    lastMessage() {
      return this.messages[0]
    },
    currentRequest() {
      if (this.clients) {
        let clientCode = this.currentClientCode
        if (clientCode) {
          let request = this.clients[clientCode].requests.filter(
            (item) => item.idNotifica === this.currentRequestId
          )[0]
          return request
        } else return {}
      } else return {}
    },
    messages() {
      const res = this.currentRequest
      return Object.keys(res).length === 0
        ? []
        : res.listaMessaggi.sort(dynamicSort('-idMessaggio'))
    },
    clients() {
      return this.accountClients
    }
  },
  methods: {
    ...mapActions('account', [
      'readDreamServiceMessage',
      'getTotalNotifications',
      'getClientsForAccount'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    onBackClick() {
      this.$router.go(-1)
    },
    isCompleted(message, request) {
      return (
        request.chiuso === 'Y' &&
        message.idMessaggio === this.lastMessage.idMessaggio &&
        this.messages.length === 3
      )
    }
  },
  async mounted() {
    for (let msg of this.messages.filter((item) => item.letto === false)) {
      let message = {
        messageToRead: Object.assign(
          objectCopy(this.currentRequest),
          objectCopy(msg),
          { codiceCliente: this.currentClientCode }
        ),
        client: this.currentClient
      }
      delete message.messageToRead.listaMessaggi
      delete message.messageToRead.msgToRead
      delete message.messageToRead.lastUpdate
      message.messageToRead.letto = true
      await this.$store.dispatch('account/readDreamServiceMessage', message)
    }

    this.$store.commit('account/resetClients')
    await this.getClientsForAccount().then((res) =>
      this.getTotalNotifications()
    )
  }
}
</script>

<style scoped lang="scss">
.limitedTo11Cols {
  max-width: calc(100% / 12 * 11);
}

.evaluate {
  text-transform: none;
  width: 250px;
}
.rowDream.completedRow {
  position: relative;
  padding-bottom: 20px;
  &:after {
    content: none;
  }
}

.rowDream {
  position: relative;
  padding-bottom: 20px;
  &:after {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 46px;
    width: 1px;
    height: calc(50% - 3px);
    border-left: dashed 2px #cccccc;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 46px;
    width: 1px;
    height: calc(50% - 3px);
    border-left: dashed 2px #cccccc;
  }
}
.rowDream:last-of-type {
  &:before {
    content: none;
  }
}

.borderDream {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.category::first-letter {
  text-transform: capitalize;
}

.category {
  font-weight: 800;
}

.message {
  width: 893px;
  height: 174px;
  border-radius: 20px;
  border: solid 1px transparent;
}

.message-text {
  opacity: 1;
  color: black;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  white-space: pre-line;
}

.message-subtext {
  font-size: 14px;
  display: inline-block;
  white-space: pre-line;
}

.message-data {
  font-weight: bold;
}

.message-channel {
  text-transform: capitalize;
}

.timeline {
  width: 1px;
  height: calc(100% + 45px);
  border-left: dashed 2px #cccccc;
}

.timeline-icon {
  background: white;
  z-index: 1;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 1);
}

.timeline-first2second {
  position: absolute;
  bottom: 50%;
  left: 50%;
}

.toRead {
  border: solid 1px #97979710;
  background-color: #f1fbff;
  &:first-child {
    overflow: hidden;
  }
  .timeline-icon {
    background-color: #f1fbff;
    box-shadow: 0px 0px 0px 5px rgba(241, 251, 255, 1);
  }
}

.last {
  border: solid 1px #97979716;
  background-color: #97d70716;
  &:first-child {
    overflow: hidden;
  }
  .timeline-icon {
    background-color: #f3fcee;
    box-shadow: 0px 0px 0px 5px transparent;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .evaluate {
    text-transform: none;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .rowDream {
    &:after {
      left: 27px;
    }
    &:before {
      left: 27px;
    }
  }

  .category {
    font-size: 26px;
  }
  .message-text {
    font-size: 16px;
  }

  .message-data {
    font-weight: bold;
    font-size: 14px;
  }

  .message-channel {
    text-transform: capitalize;
    font-size: 14px;
  }

  .message {
    height: 100%;
    padding-bottom: 0px;
  }

  .toRead {
    padding-bottom: 0px;
  }
}

.dreamService {
  background-color: $srg-blue;
  &__header {
    height: 100px;
    color: white;
    padding: 28px calc((100% - 1140px) / 2) 0;
    position: relative;
    z-index: 100;
    align-items: center;
    h1,
    .wc-tab__title {
      font-size: 60px;
      font-weight: bold;
      line-height: 70px;
      span {
        color: black;
      }
    }
    &-back {
      display: flex;
      align-items: center;
      color: white;
      font-size: 20px;
      margin-right: 12px;
      margin-bottom: 5px;
      letter-spacing: 0.4px;
      .v-icon {
        width: 15px;
        margin-right: 14px;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__content {
    &-wrap {
      position: relative;
      width: 100%;
      min-height: 100vh;
      z-index: 101;
      background: white;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding-bottom: 100px;
      padding-top: 40px;
    }
  }
}
</style>
