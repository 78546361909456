<template>
  <div class="mt-5">
    <v-row>
      <v-col>
        <div class="headerStatus">
          <div class="status">
            <div class="BodyL">Stato Parental control</div>
            <div class="LabelM" :class="statusColor">
              {{ messageStatus }}
            </div>
          </div>
          <div>
            <v-btn
              class="v-btn--shadowed"
              x-large
              color="primary"
              width="260"
              :disabled="isDisabled"
              @click="sendParentalControlRequest()"
              >{{ requestMessage }}</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'StatusHeader',
  props: {
    messageStatus: { type: String, required: true },
    requestMessage: { type: String, required: true },
    statusColor: { type: String, required: true },
    isDisabled: { type: Boolean, required: true }
  },
  methods: {
    sendParentalControlRequest() {
      this.$emit('sendParentalControl')
    }
  }
}
</script>

<style lang="scss" scoped>
.headerStatus {
  height: 47px;
  width: 751px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.status {
  height: 47px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.activeStatusColor {
  color: #12256a;
}
.noActiveStatusColor {
  color: #d93273;
}
.pendingStatusColor {
  color: #6e7689;
}
</style>
