<template>
  <div class="chart-utils d-flex justify-space-between">
    <v-row class="d-flex flex-column">
      <div class="legenda">
        <img src="@/assets/icons/Circle-blu.png" class="MediumCircle" />
        <span class="spese-anno-corrente">spese anno corrente</span>
        <img
          src="@/assets/icons/Circle-green.png"
          v-if="tabSelected === 0 && !isFascia"
          class="MediumCircle"
        />
        <span class="spese-anno-corrente" v-if="tabSelected === 0 && !isFascia"
          >spese anno precedente</span
        >
      </div>
      <div class="d-flex align-center">
        <div
          class="btn-chart mr-4"
          @click="
            ;(dialogFilter = true),
              trackTapEvent({
                name: 'supplyDetail_chartFilter_tap',
                params: getSupplyTrackingChartParams(detail, periodSelected)
              })
          "
        >
          {{ labelPeriodSelected.cta }}
        </div>
        <div class="btn-chart download" @click="exportCSV()">
          Scarica dati di {{ tabSelected === 0 ? 'consumo' : 'potenza' }}
        </div>
      </div>
      <div class="dates-selected d-flex align-center pt-5">
        <h6
          @click="removeDateSelected('from')"
          v-if="showDateSelected && showDate"
        >
          {{ formatDateSelected(dateSelected.dateStart) }} -
          {{ formatDateSelected(dateSelected.dateEnd) }}
        </h6>
        <span v-if="showDateSelectedCompare" class="ml-3 mr-3">VS</span>
        <h6 @click="removeDateSelected('to')" v-if="showDateSelectedCompare">
          {{ formatDateSelected(dateSelectedCompare.dateStart) }} -
          {{ formatDateSelected(dateSelectedCompare.dateEnd) }}
        </h6>
        <div
          v-if="showDateSelectedCompare && consumptionVariation"
          class="variationBox justify-space-between d-flex align-center"
        >
          VARIAZIONE CONSUMO
          <span class="variationLabel">{{ consumptionVariation }}%</span>
          <img
            v-if="consumptionVariation > 0"
            src="../../assets/consumption-up.svg"
            width="24"
            height="24"
          />
          <img
            v-if="consumptionVariation < 0"
            src="../../assets/consumption-down.svg"
            width="24"
            height="24"
          />
        </div>
      </div>
    </v-row>
    <div class="d-flex flex-row justify-end align-start chart-switch">
      <ChartCompare
        v-show="tabSelected === 0 && !isFascia"
        :compareStart="pickerStart.date || minDate"
        :compareEnd="pickerEnd.date || maxDate"
        :cutOffDefault="period[periodSelected].cutOff"
        :frazione="this.period[this.periodSelected].frazione"
        :activationDate="activationDate"
        :detail="detail"
        @refreshFilters="refreshFilters"
        ref="chartCompare"
      />
    </div>

    <v-dialog
      v-model="dialogFilter"
      :max-width="!pickerStart.show && !pickerEnd.show ? 750 : 555"
    >
      <v-row>
        <v-col class="period-dialog">
          <img
            @click="dialogFilter = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <template v-if="!pickerStart.show && !pickerEnd.show">
            <h2>Scegli il periodo da visualizzare</h2>
            <div class="period-radio">
              <v-radio-group
                color="#12256A"
                row="row"
                v-model="periodSelected"
                class="row"
              >
                <v-radio
                  v-for="(type, i) in listPeriodToShow"
                  :key="i"
                  :value="type.value"
                  :label="type.cta"
                  :class="`col-${
                    listPeriodToShow.length === 3 ? '4' : '6'
                  } mr-0`"
                />
              </v-radio-group>
            </div>
            <div class="period-panel">
              <h5 class="mb-1 pl-3">
                Per una visualizzazione più dettagliata seleziona il periodo
                temporale
              </h5>
              <v-row>
                <v-col class="col-6">
                  <div
                    class="btn-chart big"
                    @click="
                      openPicker('start'),
                        trackTapEvent({
                          name: 'chartFilter_startDate_tap',
                          params: getSupplyTrackingChartParams(
                            detail,
                            periodSelected
                          )
                        })
                    "
                  >
                    {{
                      pickerStart.date
                        ? formatDateWithSeparatorPeriod(pickerStart.date)
                        : 'Data inizio'
                    }}
                  </div>
                </v-col>
                <v-col class="col-6">
                  <div
                    class="btn-chart big"
                    @click="
                      openPicker('end'),
                        trackTapEvent({
                          name: 'chartFilter_endDate_tap',
                          params: getSupplyTrackingChartParams(
                            detail,
                            periodSelected
                          )
                        })
                    "
                  >
                    {{
                      pickerEnd.date
                        ? formatDateWithSeparatorPeriod(pickerEnd.date)
                        : 'Data fine'
                    }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="d-flex justify-end">
              <v-btn @click="cancelFilter()" color="white" x-large width="220"
                >Annulla</v-btn
              >
              <v-btn
                @click="
                  filter(),
                    trackTapEvent({
                      name: 'chartFilter_confirm_tap',
                      params: getSupplyTrackingChartParams(
                        detail,
                        periodSelected
                      )
                    })
                "
                class="v-btn--shadowed ml-5"
                x-large
                color="primary"
                width="220"
                >Conferma</v-btn
              >
            </div>
          </template>
          <template v-else-if="pickerStart.show">
            <h3 class="mb-2"><strong>Scegli la data di inizio</strong></h3>
            <h5 class="mb-3">
              Seleziona in un periodo di
              {{
                periodSelected === 'monthly'
                  ? '24 mesi'
                  : period[periodSelected].cutOff + ' giorni'
              }}
            </h5>
            <v-row justify="center">
              <v-date-picker
                :show-current="false"
                flat
                width="460"
                v-model="pickerStart.date"
                :max="maxDate"
                :min="minDate"
                :type="periodSelected === 'monthly' ? 'month' : 'date'"
              ></v-date-picker>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="6">
                <v-btn
                  @click="pickerStart = { date: pickerStart.date, show: false }"
                  color="white"
                  x-large
                  width="210"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="pickerStart.show = false"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="210"
                  >Prosegui</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <h3 class="mb-2"><strong>Scegli la data di fine</strong></h3>
            <h5 class="mb-3">
              Seleziona in un periodo di
              {{
                periodSelected === 'monthly'
                  ? '24 mesi'
                  : period[periodSelected].cutOff + ' giorni'
              }}
            </h5>
            <v-row justify="center">
              <v-date-picker
                :show-current="false"
                flat
                width="460"
                v-model="pickerEnd.date"
                :max="maxDateCutoff"
                :min="minDateCutoff"
                :type="periodSelected === 'monthly' ? 'month' : 'date'"
              ></v-date-picker>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="6">
                <v-btn
                  @click="pickerEnd = { date: pickerEnd.date, show: false }"
                  color="white"
                  x-large
                  width="210"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="pickerEnd.show = false"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="210"
                  >Prosegui</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import {
  getSupplyTrackingChartParams,
  getSupplyTrackingParams
} from '@/js/trackingUtils'
import { mapActions } from 'vuex'
import ChartCompare from './ChartCompare'
export default {
  name: 'chartUtils',
  props: {
    tabSelected: { type: Number, default: 0 },
    isFascia: { type: Boolean, default: false },
    supplyType: { type: String, default: 'ele' },
    billingType: { type: String },
    activationDate: { type: String },
    detail: { type: Object, default: null },
    consumptionVariation: { type: Number, default: null },
    period: {
      type: Object,
      default: function () {
        return {
          monthly: {
            value: 'monthly',
            cta: 'Mensile',
            cutOff: 730,
            frazione: '1'
          },
          daily: {
            value: 'daily',
            cta: 'Giornaliero',
            cutOff: 60,
            frazione: '2'
          },
          hourly: {
            value: 'hourly',
            cta: 'Orario',
            cutOff: 14,
            frazione: '3'
          },
          quarterly: {
            value: 'quarterly',
            cta: 'Quartorario',
            cutOff: 7,
            frazione: '4'
          }
        }
      }
    }
  },

  data() {
    return {
      periodSelected: 'monthly',
      labelPeriodSelected: this.period['monthly'],
      dialogFilter: false,
      pickerStart: { date: '', show: false },
      pickerEnd: { date: '', show: false },
      dateSelected: null,
      dateSelectedCompare: null,
      getSupplyTrackingChartParams: getSupplyTrackingChartParams,
      getSupplyTrackingParams: getSupplyTrackingParams,
      showDate: false
    }
  },
  watch: {
    periodSelected() {
      this.trackTapEvent({
        name: 'supplyDetail_chartFilterTime_tap',
        params: getSupplyTrackingChartParams(this.detail, this.periodSelected)
      })
      this.pickerStart.date = ''
      this.pickerEnd.date = ''
    }
  },
  created() {
    this.trackTapEvent({
      name: 'supplyDetail_chartConsHeader_tap',
      params: getSupplyTrackingParams(this.detail)
    })
  },
  computed: {
    minDate() {
      let minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 2)
      )
      let _activationDate = new Date(this.activationDate)
      if (_activationDate && _activationDate > minDate) {
        minDate = _activationDate
      }
      return this.formatDatePicker(minDate)
    },
    maxDate() {
      let maxDate = new Date()
      return this.formatDatePicker(maxDate)
    },
    maxDateCutoff() {
      if (this.pickerStart.date) {
        let minDate = new Date(this.pickerStart.date)
        let maxDateCutoff = new Date(
          minDate.setDate(
            minDate.getDate() + this.period[this.periodSelected].cutOff
          )
        )
        return maxDateCutoff > new Date()
          ? this.formatDatePicker(new Date())
          : this.formatDatePicker(maxDateCutoff)
      } else {
        return this.maxDate
      }
    },
    minDateCutoff() {
      if (this.periodSelected === 'monthly') {
        let min = new Date(this.pickerStart.date)
        return this.formatDatePicker(new Date(min.setMonth(min.getMonth() + 1)))
      }
      return this.pickerStart.date
    },
    listPeriodToShow() {
      let discardPeriod = []
      if (this.supplyType === 'Gas') {
        discardPeriod = ['hourly', 'quarterly']
      } else if (this.supplyType === 'Ele') {
        if (this.tabSelected === 1) {
          discardPeriod.push('quarterly')
          if (this.periodSelected === 'quarterly') {
            this.setPeriodSelected('monthly')
            this.setLabelPeriodSelected(this.period[this.periodSelected])
          }
        }
        if (
          this.billingType === 'ELE_MONO' ||
          this.billingType === 'ELE_MULTI'
        ) {
          discardPeriod = [
            ...new Set([...discardPeriod, ...['daily', 'hourly', 'quarterly']])
          ]
        }
      }
      return Object.values(this.period).filter(
        (p) => !discardPeriod.includes(p.value)
      )
    },
    showDateSelected() {
      return this.dateSelected !== null
    },
    showDateSelectedCompare() {
      return this.dateSelectedCompare !== null && this.tabSelected === 0
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    formatDatePicker(date) {
      return `${date.getFullYear()}-${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`
    },
    openPicker(type) {
      if (type === 'start') {
        this.pickerStart.show = true
      } else {
        this.pickerEnd.show = true
      }
    },
    setPeriodSelected(newValue) {
      this.periodSelected = newValue
    },
    setLabelPeriodSelected(newValue) {
      this.labelPeriodSelected = newValue
    },
    filter() {
      this.dialogFilter = false
      this.showDate = true
      if (
        this.$refs.chartCompare &&
        this.$refs.chartCompare.$data.monthOnMonth
      ) {
        this.$refs.chartCompare.$data.monthOnMonth = false
        this.$refs.chartCompare.$data.applyCompareFilter = false
      }
      let _minDate = new Date(
        new Date().setDate(
          new Date().getDate() - this.period[this.periodSelected].cutOff
        )
      )
      if (this.pickerEnd.date !== '' && this.pickerStart.date === '') {
        _minDate = new Date(
          new Date(this.pickerEnd.date).setDate(
            new Date(this.pickerEnd.date).getDate() -
              this.period[this.periodSelected].cutOff
          )
        )
      }
      let _activationDate = new Date(this.activationDate)
      if (_activationDate > _minDate) {
        _minDate = _activationDate
      }

      let paramsDate = {
        dateStart:
          this.pickerStart.date === ''
            ? _minDate
            : new Date(this.pickerStart.date),
        dateEnd:
          this.pickerEnd.date === ''
            ? new Date(this.maxDateCutoff)
            : new Date(this.pickerEnd.date),
        frazione: this.period[this.periodSelected].frazione
      }

      this.pickerStart.date = this.formatDatePicker(paramsDate.dateStart)
      this.pickerEnd.date = this.formatDatePicker(paramsDate.dateEnd)

      this.dateSelected = {
        dateStart: paramsDate.dateStart,
        dateEnd: paramsDate.dateEnd
      }
      this.dateSelectedCompare = null

      this.labelPeriodSelected = this.period[this.periodSelected]
      let params = { paramsDate: paramsDate }
      this.$emit('getCurveConsumi', params)
    },
    cancelFilter() {
      this.trackTapEvent({
        name: 'chartFilter_cancel_tap',
        params: getSupplyTrackingChartParams(this.detail, this.periodSelected)
      })
      this.periodSelected = this.labelPeriodSelected.value
      this.dialogFilter = false
    },
    refreshFilters(data) {
      this.pickerStart.date = data.paramsDate.dateStart.date
      this.pickerEnd.date = data.paramsDate.dateEnd.date

      this.dateSelected = {
        dateStart: data.paramsDate.dateStart,
        dateEnd: data.paramsDate.dateEnd
      }
      this.dateSelectedCompare = {
        dateStart: (data.paramsCompare && data.paramsCompare.dateStart) || '',
        dateEnd: (data.paramsCompare && data.paramsCompare.dateEnd) || ''
      }

      this.$emit('getCurveConsumi', data)
    },
    removeDateSelected(type) {
      let loadDefaultRange = false
      if (
        this.$refs.chartCompare &&
        this.$refs.chartCompare.$data.monthOnMonth
      ) {
        this.$refs.chartCompare.$data.monthOnMonth = false
        this.$refs.chartCompare.$data.applyCompareFilter = false
      }
      if (this.tabSelected === 1) {
        this.dateSelectedCompare = null
      }
      if (type === 'to') {
        this.pickerStart.date = this.formatDatePicker(
          this.dateSelected.dateStart
        )
        this.pickerEnd.date = this.formatDatePicker(this.dateSelected.dateEnd)
        this.dateSelectedCompare = null
      } else {
        let _dateStart = new Date(
          new Date().setDate(
            new Date().getDate() - this.period[this.periodSelected].cutOff
          )
        )
        let _activationDate = new Date(this.activationDate)
        if (_activationDate > _dateStart) {
          _dateStart = _activationDate
        }
        let _dateEnd = new Date()
        if (this.dateSelectedCompare !== null) {
          _dateStart = this.dateSelectedCompare.dateStart
          _dateEnd = this.dateSelectedCompare.dateEnd
        } else {
          loadDefaultRange = true
        }
        this.pickerStart.date = this.formatDatePicker(_dateStart)
        this.pickerEnd.date = this.formatDatePicker(_dateEnd)
        this.dateSelected = {
          dateStart: _dateStart,
          dateEnd: _dateEnd
        }
        this.dateSelectedCompare = null
      }

      loadDefaultRange ? this.filter() : this.$parent.deleteSerieCompare(type)
      this.showDate = false
    },
    exportCSV() {
      this.$parent.export()
    },
    formatDateWithSeparatorPeriod: function (value, separator = '.') {
      function pad(s) {
        return s < 10 ? '0' + s : s
      }
      if (!value) return ''
      if (value.indexOf('T') > -1)
        value = value.substring(0, value.indexOf('T'))
      var d
      value = value.split('/').reverse().join('-') // light format must be reworked
      d = new Date(value)
      if (this.periodSelected === 'monthly') {
        return [pad(d.getMonth() + 1), d.getFullYear()].join(separator)
      } else {
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
          separator
        )
      }
    },
    formatDateSelected(date) {
      if (this.periodSelected === 'monthly') {
        return new Intl.DateTimeFormat('it', {
          month: '2-digit',
          year: 'numeric'
        }).format(date)
      } else {
        return new Intl.DateTimeFormat('it', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(date)
      }
    }
  },
  components: {
    ChartCompare
  }
}
</script>
<style lang="scss" scoped>
.chart-utils {
  width: 100%;
}
.legenda {
  width: 445px;
  height: 56px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  //gap: 16px;
  padding: 16px;
  border-radius: 20px;
  background-color: #f8f9fc;
  margin-bottom: 20px;
}
.MediumCircle {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
  margin-right: 10px;
}
.spese-anno-corrente {
  width: 171px;
  height: 14px;
  flex-grow: 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: left;
  opacity: 0.4;
  text-transform: uppercase;
}
.btn-chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 322px;
  height: 51px;
  background: white;
  border-radius: 20px;
  border: solid 1.1px #dbdbdb;
  font-size: 16px;
  font-weight: 500;
  color: $srg-blue;
  padding: 0 16px;
  cursor: pointer;
  &:before {
    content: url('../../assets/icons/calendar.svg');
    width: 18px;
    margin-right: 10px;
    padding-top: 5px;
  }
  &:after {
    content: url('../../assets/icons/ic-chevron-down-blue.svg');
    transform: rotate(180deg);
    width: 18px;
    margin-left: 20px;
  }
  &.download:after {
    content: url('../../assets/ic_download_small_blue.svg');
    width: 18px;
    margin-left: 20px;
    padding-top: 5px;
    transform: initial;
  }
  &.download:before {
    content: '';
    width: 18px;
    margin-right: 10px;
    padding-top: 5px;
  }
  &.big {
    width: 289px;
    height: 66px;
    padding-left: 26px;
    justify-content: start;
    &:before {
      content: url('../../assets/icons/calendar.svg');
      width: 32px;
      margin-right: 20px;
    }
    &:after {
      content: '';
    }
  }
}

.chart-switch {
  .chart-switch__label {
    font-size: 18px;
    font-weight: bold;
    color: $srg-blue;
    letter-spacing: 1.2px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
}

.dates-selected {
  h6 {
    cursor: pointer;
    display: flex;
    align-items: center;
    &::before {
      margin-right: 5px;
      margin-top: 4px;
      content: url('../../assets/icons/ic-close-green.svg');
      width: 14px;
    }
    &:first-child::before {
      content: url('../../assets/icons/ic-close-blue.svg');
    }
  }
}

.v-dialog__content {
  .period-dialog {
    background: white;
    padding: 48px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    h4 {
      span {
        font-weight: bold;
      }
    }
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      &.preview {
        position: initial;
      }
    }
    .period-panel {
      padding: 24px;
      border-radius: 20px;
      background-color: #f6f6f6;
      margin-bottom: 20px;
    }
  }
}

.variationBox {
  min-width: 300px;
  height: 40px;
  padding: 0px 10px 0px 15px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  background-color: #f6f6f6;
  .variationLabel {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
