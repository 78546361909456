<template>
  <v-dialog v-model="dialog" width="558" persistent="persistent">
    <div class="alert">
      <img
        class="alert__close"
        @click="closeAlert"
        src="@/assets/ic_chevron_top_black.png"
      />
      <v-img
        class="alert__img mb-8"
        src="@/assets/greeners/svg/code-redemption-success.svg"
      />
      <div class="alert__title">
        Ti contatteremo a breve, <br />
        resta nell’area clienti
      </div>
      <div class="alert__message">
        Mantieni aperta e visibile la pagina della tua area clienti per ricevere
        correttamente la videochiamata.
      </div>
      <div class="alert__btn">
        <v-btn
          x-large
          class="white--text v-btn--shadowed"
          width="231"
          height="54"
          color="#12256A"
          :rounded="true"
          @click="closeAlert"
        >
          Ho capito
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { eventBus } from '@/main'
import { map } from 'lodash'
import { mapMutations } from 'vuex'

export default {
  name: 'FeedbackDialog',
  props: ['physicallObj', 'triggerElement', 'triggerAction'],
  data() {
    return {
      dialog: false,
      eventType: ''
    }
  },
  watch: {
    dialog(val) {
      if (!val && this.eventType === 'eventClick') {
        if (this.triggerElement instanceof Object) {
          this.triggerElement.target.click()
        }
        if (typeof this.triggerAction === 'function') {
          this.triggerAction()
        }
      } else {
        this.physicallObj.clearTimer()
      }
    }
  },
  created() {
    eventBus.$on('open:feedbackDialog', (eventType) => {
      this.dialog = true
      this.eventType = eventType
    })
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    eventBus.$off('open:feedbackDialog')
  },
  beforeRouteLeave(to, from, next) {
    eventBus.$off('open:feedbackDialog')
    this.physicallObj.clearTimer()
    next()
  },
  methods: {
    ...mapMutations('session', ['setFeedbackShowing']),
    closeAlert() {
      this.dialog = false
      this.setFeedbackShowing(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  background: white;
  padding: 80px 40px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36.4px;
    text-align: center;
    letter-spacing: 1.2px;
    margin-bottom: 8px;
  }

  &__message {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #2c313a;
    margin-bottom: 32px;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
}
</style>
