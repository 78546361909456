<template>
  <div class="ownerSupplies">
    <div
      id="scrollTo-supplies"
      class="ownerSupplies__supplies std-side-padding pt-8"
    >
      <SubscriptionsList :supplyList="supplyList" />

      <v-row
        class="paymentSummary"
        v-if="filteredClientDetails.clientOwnerCode"
      >
        <v-col cols="12">
          <h1 class="supplies__title supplies__title--small">
            Riepilogo pagamenti
          </h1>
        </v-col>
        <v-col cols="12" class="paymentSummary__container">
          <h4>Verifica lo stato dei pagamenti di tutte le tue forniture</h4>
          <v-btn
            color="primary"
            x-large
            width="320"
            class="v-btn--shadowed"
            @click="openPaymentStatusSummary"
            >Riepilogo dei pagamenti</v-btn
          >
        </v-col>
      </v-row>
      <PaymentStatusSummary
        v-model="dialog"
        :dialog="dialog"
        @closeModal="dialog = false"
      />

      <Supplies class="mt-8" />
    </div>
  </div>
</template>
<script>
import Consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { getMonth, getYear } from '@/js/utils'
import { mapActions, mapGetters } from 'vuex'

import { scroller } from 'vue-scrollto/src/scrollTo'

export default {
  name: 'ownerSupplies',
  components: {
    Supplies: () =>
      import(/* webpackChunkName: "components/home/supplies" */ './supplies'),
    SubscriptionsList: () =>
      import(
        /* webpackChunkName: "components/home/SubscriptionsList" */ './SubscriptionsList'
      ),
    PaymentStatusSummary: () =>
      import(
        /* webpackChunkName: "components/home/CheckPaymentStatus" */ './PaymentStatusSummary'
      )
  },
  data() {
    return {
      supplyList: null,
      supplyListMode: 'compact',
      BILL_PAYMENT_STATUS: Consts.BILL_PAYMENT_STATUS,
      SUPPLY_STATUS: Consts.SUPPLY_STATUS,
      getMonth: getMonth,
      getYear: getYear,
      cfpValue: '',
      dialog: false,
      isFromLogin: false
    }
  },
  computed: {
    ...mapGetters({
      supplies: 'supply/supplies',
      clientDetails: 'account/clientDetails',
      memberStatus: 'loyalty/memberStatus',
      clientsWithSupplies: 'supply/clientsWithSupplies',
      accountClients: 'account/accountClients'
    }),
    ...mapGetters('account', ['filteredClientDetails']),
    mainClient() {
      return this.$store.getters['account/mainClient'] || {}
    },
    clientDetails() {
      return this.$store.getters['account/clientDetails'] || {}
    }
  },
  methods: {
    ...mapActions({
      getSupplies: 'supply/getSupplies',
      getDetail: 'supply/getDetail',
      clientsHaveSupplies: 'supply/clientsHaveSupplies',
      getMainClient: 'account/getMainClient',
      getClientsForAccount: 'account/getClientsForAccount',
      getDetailForClient: 'account/getDetailForClient'
    }),
    ...mapActions('analytics', ['trackTapEvent']),

    openPaymentStatusSummary() {
      if (Object.keys(this.accountClients).length === 1) {
        this.$router.push({
          name: 'paymentSummary',
          params: {
            clientCode: Object.values(this.accountClients)[0].clientOwnerCode
          }
        })
      } else {
        this.dialog = true
      }
    }
  },
  beforeMount() {
    this.getSupplies()
      .then((res) => {
        this.supplyList = res
      })
      .catch((error) => {
        // We do not expect any business logic error, so show an alert with generic error
        GenErr.handleGenericError(error && error.message)
      })

    this.getMainClient()

    this.getClientsForAccount()
      .then((resp) => {
        if (resp) {
          this.clientsHaveSupplies()
        }
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isFromLogin = from.path === '/login'
    })
  },
  mounted() {
    this.trackTapEvent('services_viewed')
    this.$nextTick(function () {
      if (this.$route.params?.scroll || this.$route.params.value?.scroll) {
        let scrollTo = scroller()
        setTimeout(() => {
          scrollTo('#scrollTo-addSupply')
        }, 500)
      }
    })
  }
}
</script>
<style lang="scss" src="../../styles/upselling/_main.scss"></style>
<style lang="scss" src="./_ownerSupplies.scss"></style>
<style lang="scss" scoped>
.paymentSummary {
  &__container {
    width: 100%;
    height: 119px;
    border-radius: 40px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    color: black;
    letter-spacing: 1px;
  }
  .v-btn {
    letter-spacing: 1.2px;
  }
}
</style>
