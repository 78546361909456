<template>
  <div
    class="answer_box"
    :class="[selected ? 'answer_box_shadowed' : '']"
    :style="`width: calc( 100% / ${perRow} - ${margin}px)`"
  >
    <v-radio :value="answer.value" @change="onClick">
      <template v-slot:label>
        <div class="answer_text">
          {{ answer.label }}
        </div>
      </template>
    </v-radio>
  </div>
</template>
<script>
export default {
  name: 'feedbackRadioButton',
  props: {
    answer: { type: Object },
    onClick: { type: Function },
    selected: { type: Boolean, default: false }
  },
  data() {
    return {
      isSmallDevice: window.innerWidth <= 480
    }
  },
  inject: ['options'],
  computed: {
    perRow() {
      return this.options.perRow
    },
    margin() {
      return this.isSmallDevice ? 0 : this.options.margin * 2
    }
  },
  methods: {
    onChanged() {
      this.onClick()
    }
  }
}
</script>
<style lang="scss" scoped>
.answer_box {
  border-radius: 27px;
  border: 1px solid lightgrey;
  min-height: 72px;
  margin: 10px;
  padding: 15px 15px 15px 30px;
  display: flex;

  &_shadowed {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14),
      0 10px 35px 0 rgba(219, 219, 219, 0.3);
  }
}

.no-shadow {
  box-shadow: none !important;
}
.answer_text {
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  padding-left: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .answer_text {
    font-size: 14px;
    padding-left: 2px !important;
  }
  .answer_box {
    padding-left: 15px;
    padding-right: 20px;
  }
}
</style>
