import { axiosACN } from '@/js/axiosInstances.js'

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  actions: {
    getEgonCivico({ rootGetters }, { civico, viaEgon }) {
      return axiosACN
        .get(`/commodity/civico/?civico=${civico}&via=${viaEgon}`, {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        })
        .then((response) => {
          let extendedCivici = response.data.egonResponse.map((item) => {
            let expo = item.esponente !== '' ? '/' + item.esponente : ''
            item.fullCivico = item.civico + expo
            return item
          })
          // let extendedCivici = response.data.egonResponse
          return extendedCivici
        })
        .catch(() => {})
    },
    getEgonVia({ rootGetters }, { comuneEgon, via }) {
      return axiosACN
        .get(`/commodity/via/?comune=${comuneEgon}&via=${via}`, {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        })
        .then((response) => {
          return response.data.egonResponse
        })
        .catch(() => {})
    },
    getEgonComune({ rootGetters }, comune) {
      return axiosACN
        .get(`/commodity/comune?comune=${comune}`, {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        })
        .then((response) => {
          return response.data.egonResponse
        })
        .catch(() => {})
    },
    getComune({ rootGetters }, comune) {
      return axiosACN
        .get(`/fibra/getComune?comune=${comune.toLowerCase()}`, {
          headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
        })
        .then((response) => {
          return response.data.comune
        })
        .catch(() => {})
    },
    getEgonIndirizzo({ state, rootGetters }, body) {
      return axiosACN
        .post(
          '/commodity/egonIndirizzo',
          {
            via: body.via,
            cap: body.cap,
            comune: body.comune,
            civico: body.civico
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'OK') {
            return res.data.egon[0]
          }
        })
        .catch((err) => console.log(err))
    }
  }
}
