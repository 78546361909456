var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pdrItem?.planStatus)?_c('div',{staticClass:"pdr-archive"},[_c('div',[_c('v-row',{attrs:{"align":"center"},on:{"click":function($event){_vm.goToPdrDetails(_vm.pdrItem, $event),
          _vm.trackTapEvent({
            name: 'supplyDetail_PDRCard_tap'
          })}}},[_c('v-col',{staticClass:"std-panel std-panel--shadowed pa-0",class:{
          notRegular:
            _vm.countScadutaInstalments(_vm.pdrItem) > 0 && !_vm.checkInVerifica(_vm.pdrItem)
        }},[_c('v-row',{staticClass:"bills__iteams_container",class:_vm.pdrItem.planStatus === _vm.Consts.STATUS_PDR.CANCELED
              ? 'pdr-cancelled'
              : '',attrs:{"no-gutters":"","disabled":_vm.pdrItem.planStatus === _vm.Consts.STATUS_PDR.CANCELED}},[_c('v-col',{staticClass:"bills__iteams col-md-9 px-8 py-8"},[_c('v-row',{staticClass:"bills__item-bold mb-6",attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"HeadingsTitleL"},[_vm._v("Piano di rientro")])])],1),_c('v-row',{staticClass:"bills__item mb-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Data inizio")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Data fine")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Stato pagamento")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Importo")])])],1),_c('v-row',{staticClass:"bills__item",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyL-Strong"},[_vm._v(" "+_vm._s(_vm.$filters.formatDateSplitJoin(_vm.pdrItem.planStartDate))+" ")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyL-Strong"},[_vm._v(" "+_vm._s(_vm.$filters.formatDateSplitJoin(_vm.pdrItem.planEndDate))+" ")])]),_c('v-col',{staticClass:"col-md-3"},[(
                    _vm.countScadutaInstalments(_vm.pdrItem) > 0 &&
                    !_vm.checkInVerifica(_vm.pdrItem)
                  )?_c('h5',{staticClass:"BodyL-Strong color--purple"},[_vm._v(" "+_vm._s(_vm.countScadutaInstalments(_vm.pdrItem) > 1 ? _vm.countScadutaInstalments(_vm.pdrItem) + ' RATE SCADUTE' : _vm.countScadutaInstalments(_vm.pdrItem) + ' RATA SCADUTA')+" ")]):(_vm.pdrItem.planStatus === _vm.Consts.STATUS_PDR.ACTIVE)?_c('h5',{staticClass:"BodyL-Strong status"},[_vm._v(" IN CORSO ")]):_c('h5',{staticClass:"BodyL-Strong status"},[_vm._v(" "+_vm._s(_vm.Consts.TRANSLATE_STATUS_PDR[_vm.pdrItem.planStatus])+" ")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"HeadingsTitleL-Upper"},[_vm._v(" € "+_vm._s(_vm.countAmountInstalments(_vm.pdrItem))+" ")])])],1)],1),_c('v-col',{staticClass:"bills__link col-md-3 d-flex justify-center align-center flex-column"},[_c('a',{staticClass:"d-flex align-center justify-start",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.goToPdrDetails(_vm.pdrItem)}}},[_c('h5',{staticClass:"ButtonL color--blue"},[_vm._v("Dettaglio piano di rientro")])]),(_vm.pdrItem.planStatus === _vm.Consts.STATUS_PDR.ACTIVE)?_c('v-btn',{staticClass:"mt-10 resize-btn",attrs:{"color":"primary","x-large":"","text":"","disabled":!_vm.pdrItem.isPossibleToPay},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.$emit('openModal', _vm.pdrItem),
                  _vm.trackTapEvent({
                    name: 'payment_started',
                    params: {
                      service_type: _vm.supply.serviceType.toLowerCase(),
                      component: 'card',
                      payment_item: 'pdr',
                      screen_name: 'MySorgenia - Dettaglio Utenza'
                    }
                  })}}},[_c('h5',{staticClass:"ButtonL"},[_vm._v("Paga rate")])]):_vm._e()],1)],1)],1)],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }