<template>
  <vue-slider
    v-model="sliderModel"
    :tooltip="'none'"
    :width="width"
    :process="process"
    :data="optionHint"
    :marks="true"
    :hide-label="true"
  >
    <template v-slot:dot="{ value }">
      <div class="slider-dot">
        <div class="tooltip">
          <v-col class="pl-0 pt-1" align="center">
            <h4>{{ value }}</h4>
          </v-col>
        </div>
        <div class="tooltip-handler" />
        <div class="blue-dot" />
      </div>
    </template>
  </vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'ThumbSlider',
  components: {
    VueSlider
  },
  props: {
    width: { type: String, default: '90%' },
    process: {
      type: Boolean,
      default: false
    } /* makes progress-bar single coloured */,
    options: { type: Array, default: () => [] }
  },
  data: () => ({
    sliderModel: 'pick an option'
  }),
  watch: {
    chosenAnswer(value) {
      this.$emit('update:sliderV', value)
    }
  },
  computed: {
    optionHint() {
      return this.options.map((option) => option.label)
    },
    chosenAnswer() {
      return this.options.filter(
        (option) => option.label === this.sliderModel
      )[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-dot {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.3s;
  box-shadow: 0 0 20px 2px rgba(63, 63, 63, 0.45);
  margin-left: -18px;
  margin-top: -18px;
  .blue-dot {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: #12256a;
    border-radius: 50%;
    left: 5px;
    top: 5px;
  }
  .tooltip {
    height: 60px;
    min-width: 156px;
    background-color: #f6f6f6;
    position: absolute;
    border-radius: 8px;
    bottom: 60px;
    left: -55px;
  }
  .tooltip-handler {
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid #f6f6f6;
    left: -5px;
    bottom: 47px;
  }
  h4,
  h5 {
    font-weight: bold;
  }
}
</style>
