<template>
  <div class="std-side-padding">
    <div class="HeadingsTitleXL color--blue mt-12">
      Prossime fasi per la realizzazione dell’ impianto fotovoltaico
    </div>
    <div class="HeadingsSubtitleM sutitle mt-2 mb-12">
      Fasi e tempi sono indicativi e non sono vincolanti per Sorgenia
    </div>
    <div class="trackingPhotovoltaic">
      <v-expansion-panels v-model="openPaneldx" multiple>
        <TemplatePhotovoltaicVue>
          <template v-slot:box>01</template>
          <template v-slot:title>Firma della Proposta di Contratto</template>
          <template v-slot:desc>
            I consulenti di Sorgenia ti presenteranno la Proposta di Contratto
            che dovrai compilare e firmare.</template
          >
        </TemplatePhotovoltaicVue>

        <TemplatePhotovoltaicVue>
          <template v-slot:box>02</template>
          <template v-slot:title>Integrazione documentale</template>
          <template v-slot:desc>
            Sorgenia verificherà la documentazione fornita, ove necessario ti
            chiederà un’integrazione.</template
          >
        </TemplatePhotovoltaicVue>

        <TemplatePhotovoltaicVue :info="true">
          <template v-slot:box>03</template>
          <template v-slot:title
            >Verifiche dei vincoli e della fattibilità tecnica</template
          >
          <template v-slot:duration>CIRCA 1 MESE</template>
          <template v-slot:desc>
            Sorgenia analizzerà i vincoli comunali e paesaggistici, per
            comunicarti eventuali necessità di modifiche o costi aggiuntivi.
            Verrai contattato dall’installatore per organizzare il sopralluogo e
            studiare la modalità di realizzazione degli interventi più adatti
            alla tua casa.</template
          >
          <template v-slot:extraDesc>
            Solo in caso di vincoli paesaggistici dovrai sostenere un costo
            aggiuntivo pari a 550 euro per richiedere l’autorizzazione agli enti
            competenti.
          </template>
          <template v-slot:involve>
            <img
              src="../../assets/icons/ic-owner-res.svg"
              class="mr-2"
              width="16px"
            />
            <div class="CaptionS color--blue">SOPRALLUOGO</div>
          </template>
          <template v-slot:actorsGreen>CLIENTE</template>
          <template v-slot:actorsGray1>INSTALLATORE</template>
        </TemplatePhotovoltaicVue>

        <TemplatePhotovoltaicVue :info="true">
          <template v-slot:box>04</template>
          <template v-slot:title>Perfezionamento del Contratto</template>
          <template v-slot:duration>CIRCA 7 GIORNI</template>
          <template v-slot:desc>
            Se le verifiche avranno esito positivo, Sorgenia ti invierà tramite
            mail il Contratto Perfezionato con tutti i dettagli dell’intervento
            e le eventuali modifiche necessarie. Con il contratto riceverai
            anche la prima fattura (60%) con l’importo da pagare.</template
          >
          <template v-slot:extraDesc>
            In caso di finanziamento non riceverai qui la fattura.In caso di
            acquisto di Wallbox e di selezione di “pagamento in più quote”,
            l’intero importo della Wallbox sarà dovuto alla data in cui è
            perfezionato il Contratto.
          </template>
          <template v-slot:involve>
            <img src="../../assets/icons/euro.svg" class="mr-2" />
            <div class="CaptionS color--blue">PAGAMENTO</div>
          </template>
          <template v-slot:actorsGreen>CLIENTE</template>
          <template v-slot:actorsGray1>SORGENIA</template>
        </TemplatePhotovoltaicVue>

        <TemplatePhotovoltaicVue :info="true">
          <template v-slot:box>05</template>
          <template v-slot:title>Installazione</template>
          <template v-slot:duration>CIRCA 1 MESE E MEZZO</template>
          <template v-slot:desc>
            Sorgenia predisporrà le pratiche per l’avvio dei lavori e presenterà
            la domanda di connessione al Gestore di Rete. Verrai contattato per
            fissare l’appuntamento dell’installazione. Al termine dei lavori
            verrà sottoscritto il verbale di ultimazione interventi.</template
          >
          <template v-slot:involve>
            <img
              src="../../assets/icons/ic-owner-res.svg"
              class="mr-2"
              width="16px"
            />
            <div class="CaptionS color--blue">INSTALLAZIONE</div>
          </template>
          <template v-slot:actorsGreen>CLIENTE</template>
          <template v-slot:actorsGray1>INSTALLATORE</template>
          <template v-slot:actorsGray2>GESTORE DI RETE</template>
        </TemplatePhotovoltaicVue>
      </v-expansion-panels>
      <v-expansion-panels v-model="openPanelsx" multiple>
        <TemplatePhotovoltaicVue :info="true">
          <template v-slot:box>06</template>
          <template v-slot:title>Fine lavori di installazione</template>
          <template v-slot:duration>CIRCA 7 GIORNI</template>
          <template v-slot:desc>
            Sorgenia ti confermerà tramite mail la conclusione dei lavori di
            installazione e ti invierà la seconda fattura (40%) con l’importo da
            pagare.</template
          >
          <template v-slot:extraDesc>
            Solo in caso di finanziamento riceverai qui la fattura unica.
          </template>
          <template v-slot:involve>
            <img src="../../assets/icons/euro.svg" class="mr-2" />
            <div class="CaptionS color--blue">PAGAMENTO</div>
          </template>
          <template v-slot:actorsGreen>CLIENTE</template>
          <template v-slot:actorsGray1>SORGENIA</template>
        </TemplatePhotovoltaicVue>

        <TemplatePhotovoltaicVue :info="true">
          <template v-slot:box>07</template>
          <template v-slot:title
            >Allaccio alla rete del Gestore di Rete</template
          >
          <template v-slot:duration>CIRCA 2 MESI E MEZZO</template>
          <template v-slot:desc>
            Il Gestore di Rete, da remoto o in presenza, concluderà l’allaccio
            alla rete del tuo impianto</template
          >
          <template v-slot:involve>
            <img
              src="../../assets/icons/ic-owner-res.svg"
              class="mr-2"
              width="16px"
            />
            <div class="CaptionS color--blue">ALLACCIO</div>
          </template>
          <template v-slot:actorsGreen>CLIENTE</template>
          <template v-slot:actorsGray1>GESTORE DI RETE</template>
        </TemplatePhotovoltaicVue>

        <div class="activate">
          <div class="activate__boxCounter BodyM-Strong">
            <img src="../../assets/icons/photovoltaic_active.svg" />
          </div>
          <div class="text">
            <div class="BodyM-Strong mb-2">Il tuo impianto sarà attivo!</div>
            <div class="BodyM">
              Da qui potrai iniziare a sfruttare l’energia del sole per produrre
              energia pulita in completa autonomia
            </div>
          </div>
          <img src="../../assets/photovoltaic_active_house.svg" />
        </div>

        <TemplatePhotovoltaicVue :info="true">
          <template v-slot:box>08</template>
          <template v-slot:title
            >Documentazione tecnica dei lavori eseguiti</template
          >
          <template v-slot:duration>CIRCA 2 SETTIMANE</template>
          <template v-slot:desc>
            A conclusione dei lavori, Sorgenia ti invierà la documentazione
            tecnica che certifica gli interventi eseguiti e i relativi
            verbali.</template
          >
          <template v-slot:involve>
            <img
              src="../../assets/message-center/email-to-read.svg"
              class="mr-2"
              width="16px"
            />
            <div class="CaptionS color--blue">EMAIL</div>
          </template>
          <template v-slot:actorsGreen>CLIENTE</template>
          <template v-slot:actorsGray1>SORGENIA</template>
        </TemplatePhotovoltaicVue>

        <TemplatePhotovoltaicVue :info="true">
          <template v-slot:box>09</template>
          <template v-slot:title>Servizio di Ritiro Energia</template>
          <template v-slot:duration>CIRCA 2 MESI</template>
          <template v-slot:desc>
            In circa 2 mesi verrà attivato il servizio che ti consentirà di
            raccogliere gli importi dell'immissione in rete dell'energia da te
            prodotta e non consumata.</template
          >
          <template v-slot:involve>
            <img
              src="../../assets/message-center/email-to-read.svg"
              class="mr-2"
              width="16px"
            />
            <div class="CaptionS color--blue">EMAIL</div>
          </template>
          <template v-slot:actorsGreen>CLIENTE</template>
          <template v-slot:actorsGray1>SORGENIA</template>
          <template v-slot:actorsGray2>GESTORE DI RETE</template>
          <template v-slot:actorsGray3>GSE</template>
        </TemplatePhotovoltaicVue>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import TemplatePhotovoltaicVue from './TemplatePhotovoltaic'
export default {
  data() {
    return {
      openPaneldx: [0, 1, 2, 3, 4],
      openPanelsx: [0, 1, 2, 3]
    }
  },
  components: {
    TemplatePhotovoltaicVue
  }
}
</script>
<style lang="scss" scoped>
.subtitle {
  color: $neutri-neutral-60;
}
.trackingPhotovoltaic {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .v-item-group {
    display: block;
    width: calc(50% - 15px);
    padding-left: 48px;
  }
  .activate {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid $neutri-neutral-30;
    border-radius: 8px;
    height: fit-content;
    margin-bottom: 16px;
    position: relative;
    &__boxCounter {
      background: $srg-blue;
      position: absolute;
      left: -50px;
      top: 0;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $neutri-neutral-30;
      border-radius: 8px;
    }
  }
}
</style>
