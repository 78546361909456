<template>
  <v-card
    v-if="imgSrc"
    class="v-card--rounded"
    light
    :width="cardWidth"
    :height="cardHeight"
    outlined
  >
    <div
      class="img"
      :class="underlined ? 'underlined' : null"
      :style="{
        backgroundImage: `linear-gradient(180deg, rgba(20, 34, 84, 0) 47.45%, #142254 100%), url(${imgSrc})`
      }"
    >
      <h4 class="pl-4 pb-2">
        <span>{{ title }}</span>
      </h4>
    </div>
    <div v-if="coins" class="d-flex align-center px-4 py-2">
      <img
        width="36"
        height="36"
        :src="require('@/assets/greeners/new_greeners.svg')"
      />
      <h5 class="pl-3 coins">{{ coins }} GREEN COINS</h5>
    </div>
    <div v-else class="d-flex align-center px-4 py-2">
      <h5>{{ label }}</h5>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SingleCard',
  props: {
    imgSrc: { type: String, default: '' },
    title: { type: String, default: '' },
    label: { type: String, default: '' },
    coins: { type: Number, default: null },
    imgHeight: { type: String, default: '153' },
    cardWidth: { type: String, default: '230' },
    cardHeight: { type: String, default: 'auto' },
    underlined: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  .underlined {
    border-bottom: 1px solid grey;
  }

  &--rounded {
    box-shadow: 0px 2.2439px 26.9268px rgba(44, 53, 66, 0.06),
      0px 1.12195px 8.97561px rgba(44, 53, 66, 0.08),
      0px 2.2439px 4.4878px rgba(44, 53, 66, 0.08);
    border-radius: 22.439px !important;
    overflow: hidden;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
  }

  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.8px;
    color: white;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.5px;
    color: black;

    &.coins {
      line-height: 120%;
      letter-spacing: 1.2px;
      color: $srg-blue;
      font-weight: bold;
    }
  }

  .img {
    height: 153px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //h4 {
    //  background-color: gray;
    //  opacity: .5;
    //  span {
    //    opacity: 1;
    //  }
    //}
  }
}
</style>
