<template>
  <div class="page paybill">
    <TcVars />
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbsItems"
          divider=">"
          class="pb-0 mb-0"
        />
      </v-col>
    </v-row>
    <v-tabs :grow="true">
      <v-tab>Paga ora</v-tab>
      <v-tab>Invia ricevuta</v-tab>
      <v-tab-item class="paybill__tabs__pay">
        <v-row v-show="!showSiaFrame">
          <div class="paymentPage__title">
            Scegli la carta che vuoi utilizzare
          </div>
          <div class="paymentPage__subtitle">
            Per velocizzare il tuo pagamento, puoi utilizzare la carta di
            credito o debito già utilizzata in precedenza.<br />
            Seleziona "Nuova carta di credito" se desideri cambiare.
          </div>
        </v-row>
        <v-row no-gutters class="subscription__radio" v-show="!showSiaFrame">
          <v-col v-if="loading" align-self="center" class="text-center pa-10">
            <v-progress-circular :size="50" indeterminate></v-progress-circular>
          </v-col>
          <v-col v-if="!loading">
            <ExtensibleButtonGroup
              v-model="paymentMethod"
              :per-row="2"
              groupName="paymentMethod"
              size="md"
              @extensible-btn-group:touched="
                handleExtButtonTouched && handleExtButtonTouched($event)
              "
            >
              <ExtensibleButton
                :name="meth"
                v-for="(meth, i) in methodsList"
                :key="i"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column align-start">
                    <div class="typeMdp">Carta di credito</div>
                    <div>
                      {{ $filters.formatCreditCardTail(meth.creditCardNumber) }}
                    </div>
                  </div>
                </template>
              </ExtensibleButton>

              <ExtensibleButton name="newCreditCard" class="d-flex">
                <template v-slot:label>
                  <div class="color--blue">Nuova carta di credito</div>
                </template>
                <v-row no-gutters>
                  <v-col>
                    <br />
                    <h5
                      @click="rememberCard = !rememberCard"
                      :class="`radio-sameHolder pl-3 d-flex ${
                        rememberCard ? 'selected' : ''
                      }`"
                    >
                      <v-icon class="mr-2" color="#12256A">{{
                        rememberCard
                          ? 'mdi-checkbox-intermediate'
                          : 'mdi-checkbox-blank-outline'
                      }}</v-icon>
                      Ricorda questa carta per i miei prossimi pagamenti
                    </h5>
                  </v-col>
                </v-row>
              </ExtensibleButton>
            </ExtensibleButtonGroup>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-center mb-5" v-show="!showSiaFrame">
          <v-btn
            color="primary"
            x-large
            class="v-btn--shadowed"
            :rounded="true"
            :loading="paymentLoading"
            :disabled="!paymentMethodValid"
            v-show="paymentMethod && paymentMethodValid"
            @click="confirmPaymentMethod"
          >
            Conferma
          </v-btn>
        </v-row>
        <div class="siaDialog" v-show="showSiaFrame">
          <iframe
            id="fiberSiaCC"
            v-if="siaUrl"
            @load="iframeUrlChanged"
            :src="siaUrl"
            frameborder="0"
          ></iframe>
        </div>
      </v-tab-item>
      <v-tab-item class="paybill__tabs__send">
        <v-row>
          <div class="paymentPage__title">Invia ricevuta pagamento</div>
          <div class="paymentPage__subtitle">
            Se hai già pagato la tua bolletta con bonifico o bollettino inviaci
            tramite WhatsApp o email la tua
            <strong>ricevuta di pagamento</strong> con una
            <strong>foto</strong>, indicando il
            <strong>numero identificativo della bolletta</strong> e il tuo
            <strong>codice cliente.</strong>
          </div>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="6">
            <a
              style="display: none"
              ref="openMail"
              id="openMail"
              href="mailto:edp@sorgenia.it?subject=Ricevuta Pagamento via App/web"
            ></a>
            <v-btn
              class="v-btn--shadowed send-email"
              color="white"
              block
              plain
              rounded
              @click="sendEmail"
              x-large
            >
              Invia per email
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              id="send-whatsapp"
              block
              elevation="0"
              plain
              disabled
              rounded
              x-large
            >
              Invia con WhatsApp al numero
              <strong class="ml-1">342.3393654</strong>
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import Consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import router from '@/router/router'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'payBill',
  computed: {
    ...mapGetters('supply', ['supplyDetail']),
    breadcrumbsItems() {
      const address = this.supplyDetail
        ? this.supplyDetail[this.$route.params.supplyCode].address
        : ''
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'supplyDetail' }, text: address, exact: true },
        { to: { name: 'payBill' }, text: 'Pagamento' }
      ]

      return res
    }
  },
  data() {
    return {
      paymentMethod: null,
      paymentData: null,
      paymentMethodValid: false,
      paymentLoading: false,

      rememberCard: false,
      showSiaFrame: false,
      siaUrl: '',

      loading: true,
      methodsList: [],
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,

      detail: null
    }
  },
  components: {
    TcVars
  },
  methods: {
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('payment', ['getCardsList', 'getSiaUrlCCOS', 'payCCOSAlias']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('supply', ['resetState']),
    confirmPaymentMethod(ccParams) {
      if (this.paymentMethod === 'newCreditCard') {
        this.getDetailForClient({
          clientOwnerCode: this.bill.codiceCliente
        }).then((resp) => {
          this.getSiaUrlCCOS({
            clientCode: this.bill.codiceCliente,
            email: resp.contactMail,
            billId: this.bill.idFattura,
            prCode: this.bill.prCode,
            rememberCard: this.rememberCard
          })
            .then((url) => {
              this.siaUrl = url
              this.showSiaFrame = true
            })
            .catch((err) => {
              console.error(err)
              GenErr.showErrorMessage(
                'Si è verificato un errore e non è stato possibile inviare la tua richiesta. Ti preghiamo di riprovare.'
              )
            })
        })
      } else {
        this.getDetailForClient({
          clientOwnerCode: this.bill.codiceCliente
        }).then((resp) => {
          this.paymentLoading = true

          this.payCCOSAlias({
            clientCode: this.bill.codiceCliente,
            email: resp.contactMail,
            billId: this.bill.idFattura,
            prCode: this.bill.prCode,
            aliasPan: this.paymentMethod.aliasPan,
            aliasExpirationdate: this.paymentMethod.creditCardExpirationDate
          })
            .then((paymentSucceded) => {
              if (paymentSucceded.status === 'OK') {
                this.siaUrl = window.atob(paymentSucceded.urlRedirect)
                this.showSiaFrame = true
                // router.push({ name: 'supplyPayment', params: { status: 'OK' } })
              } else {
                router.push({
                  name: 'supplyPayment',
                  params: {
                    status: 'KO',
                    isMultiPayment: this.$route.params.isMultiPayment,
                    idFattura: this.$route.params.idFattura,
                    from: this.$route.params.from
                  }
                })
              }
              this.paymentLoading = false
            })
            .catch((err) => {
              console.error(err)
              this.paymentLoading = false
              router.push({
                name: 'supplyPayment',
                params: {
                  status: 'KO',
                  isMultiPayment: this.$route.params.isMultiPayment,
                  idFattura: this.$route.params.idFattura,
                  from: this.$route.params.from
                }
              })
            })
        })
      }
    },
    closeDialog() {
      this.$emit('close-dialog')
    },
    iframeUrlChanged(event) {
      try {
        const loc = event.target.contentWindow.location.href
        if (loc.indexOf('sia/back.html') > -1) {
          this.siaUrl = ''
          this.showSiaFrame = false
          this.paymentMethod = null
        } else if (loc.indexOf('sia/done.html') > -1) {
          this.trackTapEvent('bill_payBillDone_view')
          this.siaUrl = ''
          this.showSiaFrame = false

          this.$store.commit(
            'supply/resetSupplyDetailByCode',
            this.$route.params.supplyCode
          )
          this.resetState()
          router.push({
            name: 'supplyPayment',
            params: {
              status: 'OK',
              isMultiPayment: this.$route.params.isMultiPayment,
              idFattura: this.$route.params.idFattura,
              from: this.$route.params.from
            }
          })
        }
      } catch {
        // do nothing. it means we have no access to the iframe data because it is crossdomain
      }
    },

    checkPaymentMethodValid() {
      this.paymentMethodValid =
        this.paymentMethod === 'newCreditCard' || this.paymentMethod?.aliasPan
    },
    sendEmail() {
      this.trackTapEvent({
        name: 'ackPayment_sendEmail_tap',
        params: getSupplyTrackingParams(this.detail)
      })
      this.$refs.openMail.click()
    }
  },
  mounted() {
    this.trackTapEvent('payment_method_viewed')
    this.handleExtButtonTouched = (params) => {
      this.checkPaymentMethodValid()
    }
  },
  created() {
    this.$store
      .dispatch('supply/getDetail', {
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      })
      .then((detail) => {
        this.detail = detail
      })
    // Get the client code. depending on the page where the component is used bill's fields have translated/untranslated names
    this.bill = this.$route.params.bill
    const clientCode = this.bill.codiceCliente || this.bill.clientOwnerCode

    // Get the existing payment methods list
    if (this.bill && clientCode) {
      this.getCardsList({ clientCode: clientCode }).then((methods) => {
        // Filter cards for CCOS
        let list = methods.filter((meth) => {
          let monthExp = meth.creditCardExpirationDate.split('/')[0]
          let yearExp = meth.creditCardExpirationDate.split('/')[1]
          let today = new Date()
          let cardExp = new Date()
          cardExp.setFullYear(yearExp, monthExp, 1)
          return meth.aliasPan && meth.creditCardNumber && today < cardExp
        })

        this.methodsList = list
        this.loading = false
      })
    } else {
      this.methodsList = []
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.paybill {
  .send-email {
    .v-btn__content {
      color: black;
      font-size: 14px;
      font-weight: normal;
      justify-content: flex-start;
    }
  }
  #send-whatsapp {
    background-color: #f6f6f6 !important;
    .v-btn__content {
      color: black;
      font-size: 15px;
      font-weight: 400;
      justify-content: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.siaDialog {
  height: 80vh;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.paybill {
  .v-tab {
    border-bottom: #e1e1e1 2px solid;
    text-transform: initial;
  }
  .v-tab:not(.v-tab--active) {
    color: black !important;
  }
}

.paybill__tabs__pay,
.paybill__tabs__send {
  margin-top: 30px;
}

.paymentPage {
  background: white;
  padding: 48px;
  position: relative;
  &__title {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  &__subtitle {
    font-size: 20px;
    font-weight: 400;
  }
}
</style>
