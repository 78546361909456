<template>
  <div class="bills-archive">
    <PayWithCardDialog
      :bill="billToPay"
      :showDialog="showPaymentDialog"
      @close-dialog="showPaymentDialog = false"
    ></PayWithCardDialog>
    <template v-if="billsToShow.length === 0"
      ><v-row>
        <div class="bills__empty mb-6">
          Ops! Non sono presenti bollette per questa selezione
        </div>
      </v-row></template
    >
    <template v-else>
      <v-row v-for="(bill, index) in billsToShow" :key="index" align="center">
        <v-col
          :class="`std-panel std-panel--shadowed pa-0  ${
            bill.showPaymentButton ? 'notRegular' : ''
          }`"
        >
          <v-row
            no-gutters
            class="bills__iteams_container"
            @click="
              trackTapEvent({
                name: 'supplyDetail_bill_tap',
                params: getSupplyTrackingParams(supply)
              }),
                $router.push({
                  name: 'bills',
                  params: { idFattura: bill.idFattura }
                })
            "
          >
            <v-col class="bills__iteams col-md-9 px-8 py-8">
              <v-row no-gutters class="bills__item-bold mb-5">
                <v-col>
                  <h3>{{ typeBill(bill) }}</h3>
                </v-col>
              </v-row>
              <v-row no-gutters class="bills__item">
                <v-col
                  :class="`${isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`"
                >
                  <h5 class="BodyM">Periodo</h5>
                </v-col>

                <v-col class="col-md-3" v-if="isNotExpiredBill(bill)">
                  <h5 class="BodyM">Scadenza</h5>
                </v-col>

                <v-col
                  :class="`${isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`"
                >
                  <h5 class="BodyM">Stato pagamento</h5>
                </v-col>
                <v-col
                  :class="`${isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`"
                >
                  <h5 class="BodyM">Importo</h5>
                </v-col>
              </v-row>
              <v-row no-gutters class="bills__item-bold align-baseline">
                <v-col
                  :class="`${isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`"
                >
                  <h4>{{ formatdate(bill.dataInizioCompetenza) }}</h4>
                </v-col>
                <v-col class="col-md-3" v-if="isNotExpiredBill(bill)">
                  <h4 class="bills__expired">
                    {{ $filters.formatDateSplitJoin(bill.dataScadenza) }}
                  </h4>
                </v-col>
                <v-col
                  :class="`${isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`"
                >
                  <h5
                    :class="
                      'bills__statusPay bills__statusPay--' +
                      bill.computedStatus
                    "
                  >
                    {{ labelStatusBill(bill) }}
                  </h5>
                </v-col>

                <v-col
                  :class="`${isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`"
                >
                  <h3>
                    <strong
                      >€
                      {{
                        bill.statoFattura === 'Parziale'
                          ? bill.importoParzialeDaPagare
                          : numberIta(bill.importo)
                      }}</strong
                    >
                  </h3>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="col-md-12">
                  <h5
                    class="bills__warningBank"
                    v-if="billRequiresPayDisclamer(bill)"
                  >
                    Gestione in corso dalla banca con aggiornamento in
                    {{ labelUpdatePaymentDelay(bill) }} giorni
                  </h5>
                </v-col>
              </v-row>
              <!-- <v-row no-gutters>
                <v-col class="col-md-12">
                  <h5
                    class="bills__warningPaying"
                    v-if="!!bill.flagPagamentoRata"
                  >
                    Piano di rientro in corso
                  </h5>
                </v-col>
              </v-row> -->
              <!-- <v-row no-gutters>
              <v-col class="col-md-12">
                <h5 class="bills__warningPaying" v-if="labelStatusBill(bill) === 'PAGATA PARZIALMENTE' && bill.flagPagamentoRata !== 'Y'">Per pagare questa bolletta clicca su "Paga ora" in cima alla pagina "Riepilogo e Archivio"</h5>
              </v-col>
            </v-row> -->
            </v-col>
            <v-col
              class="bills__link col-md-3"
              :class="{ 'align-self-center': !bill.showPaymentButton }"
            >
              <v-row align="center">
                <v-col>
                  <!-- <template v-if="bill.flagPagamentoRata">
                    <a
                      target="_blank"
                      class="d-flex align-center justify-center"
                      :href="goToPdrDetails(bill)"
                      @click="$event.stopPropagation()"
                    >
                      <h5 class="ButtonL color--blue">
                        Dettaglio piano di rientro
                      </h5>
                    </a>
                  </template>
                  <template v-else> -->
                  <template>
                    <a
                      target="_blank"
                      class="d-flex align-center justify-center"
                      :href="getBillPdf(bill.codiceCliente, bill.idFattura)"
                      @click="$event.stopPropagation()"
                    >
                      <h5
                        class="color--blue"
                        @click="
                          trackTapEvent({
                            name: 'supplyDetail_bill_tap',
                            params: getSupplyTrackingParams(supply)
                          })
                        "
                      >
                        Bolletta sintetica
                      </h5>
                      <img
                        class="color--blue ml-3"
                        src="@/assets/icons/medium-pdf.svg"
                        height="24"
                        width="24"
                      />
                    </a>
                  </template>
                </v-col>
              </v-row>
              <v-row
                class="bills__payment-button justify-center"
                v-if="bill.showPaymentButton"
              >
                <v-col
                  @click="openPaymentRoute($event, bill)"
                  style="cursor: pointer; text-align: center"
                >
                  Paga o invia ricevuta
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
import PayWithCardDialog from '@/components/payments/PayWithCardDialog'
import { computeBillTitle } from '@/js/constants.js'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import { formatdateMonthYear } from '@/js/utils.js'
import md5 from 'blueimp-md5'
import { mapActions } from 'vuex'
import {
  CDN_URL,
  computeBillPaymentDelay,
  computeBillStatusLabel
} from '../../js/constants'

export default {
  name: 'billsList',
  props: {
    supply: Object,
    bills: Array,
    maxToShow: Number
  },
  components: { PayWithCardDialog },
  data() {
    return {
      showPaymentDialog: false,
      billToPay: null,
      getSupplyTrackingParams: getSupplyTrackingParams
    }
  },
  computed: {
    billsToShow: {
      get() {
        return this.bills.slice(0, this.maxToShow) || []
      },
      set(newVal) {
        return newVal
      }
    }
  },
  mounted() {
    const bills = this.bills.slice(0, this.maxToShow)

    for (let b of bills) {
      this.computeShowPaymentButton(b).then((shouldShow) => {
        b.showPaymentButton = shouldShow && b.importo > 0
        this.$forceUpdate()
      })
      this.getComputedBillStatus(b).then((status) => {
        b.computedStatus = status
        this.$forceUpdate()
      })
    }

    this.billsToShow = bills
  },
  methods: {
    ...mapActions('bill', [
      'checkBillPaid',
      'getComputedBillStatus',
      'computeShowPaymentButton'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    typeBill(bill) {
      return computeBillTitle(bill)
    },
    isNotExpiredBill(bill) {
      // let splitDate = bill.dataScadenza.split('/')
      // let dataScadenza = new Date(splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0])
      // return new Date() < dataScadenza
      return true
    },
    formatdate(value) {
      return formatdateMonthYear(value)
    },
    labelStatusBill(bill) {
      let mdp = bill.mdp.toUpperCase()
      let isRecurrent = ['CCR', 'SDD', 'DPAY'].includes(mdp)
      if (isRecurrent && bill.computedStatus === '1') {
        return 'In pagamento'
      } else if (
        bill.statoFattura === 'Parziale' &&
        bill.computedStatus === '3'
      ) {
        return 'PAGATA PARZIALMENTE'
      } else {
        return computeBillStatusLabel(bill.computedStatus)
      }
    },
    billRequiresPayDisclamer(bill) {
      return this.labelUpdatePaymentDelay(bill) !== null
    },
    labelUpdatePaymentDelay(bill) {
      return computeBillPaymentDelay(bill)
    },
    getBillPdf(clientOwnerCode, idFattura) {
      let companyPrefix = 'SRG'
      let dataParams = md5(
        companyPrefix + '/' + clientOwnerCode + '/' + idFattura + '/S'
      )
      let pdfUrl = CDN_URL + '/' + dataParams

      return pdfUrl
    },
    isOldBill(dataEmissione) {
      let splitDate = dataEmissione.split('/')
      let date = new Date(
        splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0]
      )
      let oldDate = new Date('2019/07/01')
      return date < oldDate
    },

    openPaymentDialog(bill) {
      this.billToPay = bill
      this.showPaymentDialog = true
    },
    openPaymentRoute(event, bill) {
      event.stopPropagation()
      this.trackTapEvent('billList_payBill_tap')
      if (this.labelStatusBill(bill) === 'PAGATA PARZIALMENTE') {
        this.$router.push({
          name: 'paySupplyBills',
          params: {
            bills: [bill],
            isMultiPayment: false,
            idFattura: bill.idFattura,
            from: this.$route.name
          }
        })
      } else {
        this.$router.push({
          name: 'payBill',
          params: {
            bill: bill,
            isMultiPayment: false,
            idFattura: bill.idFattura,
            from: this.$route.name
          }
        })
      }
    },
    numberIta(value, decimals) {
      value = typeof value !== 'number' ? Number(value) : value
      if (typeof value !== 'number') {
        return value
      }
      var formatter = new Intl.NumberFormat('it-it', {
        minimumFractionDigits: decimals
      })
      return formatter.format(value)
    }
    // goToPdrDetails(bill) {
    //   this.trackTapEvent({
    //     name: 'supplyDetail_PDRCard_tap'
    //   })
    //   console.log(bill, 'pippo')
    //   console.log(this.supply, 'pippo')
    //   this.$router.push({
    //     name: 'pdrDetail',
    //     params: {
    //       clientOwnerCode: bill.codiceCliente,
    //       supplyCode: this.supply.supplyCode,
    //       planId: bill.planId
    //     }
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
.std-panel {
  cursor: pointer;
  &.notRegular {
    border: 1px solid $srg-purple;
  }
}

.bills__list {
  .bills__iteams {
    //border-right: 1px solid lightgray;

    &_container:hover {
      background-color: #f8f9fc;
    }

    .col {
      padding-bottom: 0px;
    }

    .bills__item-bold {
      margin-top: 6px;
      h3 {
        letter-spacing: 0.48px;
        font-weight: 500;
        line-height: 26px;
      }

      h5 {
        font-size: 14px;
      }

      h4 {
        font-size: 16px;
      }

      h5,
      h4,
      h2,
      span {
        color: black;
        font-weight: bold;
      }

      span {
        font-size: $title-font-size--small;
      }

      .col {
        padding-bottom: 0px;
      }
    }
    .bills__expired {
      letter-spacing: 1px;
    }
    .bills__statusPay {
      text-transform: uppercase;
      font-size: $title-font-size--small;

      &--1 {
        color: $srg-purple !important;
      }

      &--2 {
        color: black;
      }

      &--3 {
        color: $srg-purple !important;
      }

      &--4,
      &--NA {
        color: black;
      }

      &--5 {
        color: $srg-purple !important;
      }
      &--6 {
        color: $srg-blue !important;
      }
    }
  }

  .bills__warningPaying {
    color: $srg-purple;
    margin-top: 10px;
  }

  .bills__warningBank {
    color: black;
    margin-top: 10px;
  }

  .bills__link {
    text-align: center;

    h5 {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.2px;
    }

    .row {
      height: 50%;
    }

    .bills__payment-button {
      //border-top: 1px solid lightgray;;
      text-align: left;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.2px;
      color: $srg-blue;
    }
  }

  .bills__empty {
    width: 100%;
    height: 120px;
    border-radius: 40px;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
}
</style>
