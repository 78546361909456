<template>
  <div class="page">
    <TcVars />
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="page__title">Richiesta credito</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <iframe id="creditFrame" :src="creditUrl" frameborder="0" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'requestCredit',
  computed: {
    ...mapGetters('supply', ['supplies']),
    currentSupply() {
      return this.supplies.filter(
        (item) => item.supplyCode === this.$route.params.supplyCode
      )[0]
    },
    data: {
      creditUrl: null
    },
    components: {
      TcVars
    },
    methods: {
      ...mapActions('analytics', ['trackTapEvent'])
    },
    breadcrumbsItems() {
      const address = this.currentSupply ? this.currentSupply.street : ''
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'supplyDetail' }, text: address, exact: true },
        { to: { name: 'requestCredit' }, text: 'Richiedi credito' }
      ]

      return res
    }
  },
  beforeMount() {
    this.trackTapEvent('supply_residualCredit_tap')
    this.creditUrl = this.currentSupply.credits[0].purl
  }
}
</script>

<style lang="scss" scoped>
#creditFrame {
  width: 100%;
  height: calc(75vh - 110px);
}
.page__title {
  font-weight: bold;
}
</style>
