<template>
  <div class="dashboard-monitor-stat-item">
    <div>
      <img class="mr-2" :src="icon" />
      <span>{{ title }}</span>
    </div>
    <div class="BodyM-Strong">{{ value }}</div>
  </div>
</template>
<script>
import FulmineSvg from '@assets/fotovoltaico/fulmine.svg'
import TraliccioSvg from '@assets/fotovoltaico/traliccio.svg'
import StiloSvg from '@assets/fotovoltaico/stilo.svg'

const mapSvg = {
  fulmine: FulmineSvg,
  traliccio: TraliccioSvg,
  stilo: StiloSvg
}
export default {
  props: {
    title: { type: String, required: true },
    value: { type: String, required: true },
    iconName: { type: String, required: true }
  },
  computed: {
    icon() {
      return mapSvg[this.iconName]
    }
  }
}
</script>
<style lang="scss" scoped>
.dashboard-monitor-stat-item {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
