<template>
  <div class="bills-archive">
    <PayWithCardDialog
      :bill="billToPay"
      :showDialog="showPaymentDialog"
      @close-dialog="showPaymentDialog = false"
    ></PayWithCardDialog>

    <v-row align="center">
      <v-col
        :class="`std-panel std-panel--shadowed pa-0  ${
          billToShow.showPaymentButton ? 'notRegular' : ''
        }`"
      >
        <v-row
          no-gutters
          class="bills__iteams_container"
          @click="
            trackTapEvent({
              name: 'supplyDetail_bill_tap',
              params: getSupplyTrackingParams(supply)
            }),
              $router.push({
                name: 'bills',
                params: { idFattura: billToShow.idFattura }
              })
          "
        >
          <v-col class="bills__iteams col-md-9 px-8 py-8">
            <v-row no-gutters class="bills__item-bold mb-5">
              <v-col>
                <h3>{{ typeBill() }}</h3>
              </v-col>
            </v-row>
            <v-row no-gutters class="bills__item">
              <v-col
                :class="`${
                  isNotExpiredBill(billToShow) ? 'col-md-3' : 'col-md-4'
                }`"
              >
                <h5 class="BodyM">Periodo</h5>
              </v-col>

              <v-col class="col-md-3" v-if="isNotExpiredBill(billToShow)">
                <h5 class="BodyM">Scadenza</h5>
              </v-col>

              <v-col
                :class="`${
                  isNotExpiredBill(billToShow) ? 'col-md-3' : 'col-md-4'
                }`"
              >
                <h5 class="BodyM">Stato pagamento</h5>
              </v-col>
              <v-col
                :class="`${
                  isNotExpiredBill(billToShow) ? 'col-md-3' : 'col-md-4'
                }`"
              >
                <h5 class="BodyM">Importo</h5>
              </v-col>
            </v-row>
            <v-row no-gutters class="bills__item-bold align-baseline">
              <v-col
                :class="`${
                  isNotExpiredBill(billToShow) ? 'col-md-3' : 'col-md-4'
                }`"
              >
                <h4>{{ formatdate(billToShow.dataInizioCompetenza) }}</h4>
              </v-col>
              <v-col class="col-md-3" v-if="isNotExpiredBill(billToShow)">
                <h4 class="bills__expired">
                  {{ $filters.formatDateSplitJoin(billToShow.dataScadenza) }}
                </h4>
              </v-col>
              <v-col
                :class="`${
                  isNotExpiredBill(billToShow) ? 'col-md-3' : 'col-md-4'
                }`"
              >
                <h5
                  :class="
                    'bills__statusPay bills__statusPay--' +
                    billToShow.computedStatus
                  "
                >
                  {{ labelStatusBill(billToShow) }}
                </h5>
              </v-col>

              <v-col
                :class="`${
                  isNotExpiredBill(billToShow) ? 'col-md-3' : 'col-md-4'
                }`"
              >
                <h3>
                  <strong
                    >€
                    {{
                      billToShow.statoFattura === 'Parziale'
                        ? billToShow.importoParzialeDaPagare
                        : numberIta(billToShow.importo)
                    }}</strong
                  >
                </h3>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="col-md-12">
                <h5
                  class="bills__warningBank"
                  v-if="billRequiresPayDisclamer(billToShow)"
                >
                  Gestione in corso dalla banca con aggiornamento in
                  {{ labelUpdatePaymentDelay(billToShow) }} giorni
                </h5>
              </v-col>
            </v-row>
            <!-- <v-row no-gutters>
              <v-col class="col-md-12">
                <h5
                  class="bills__warningPaying"
                  v-if="!!billToShow.flagPagamentoRata"
                >
                  Piano di rientro in corso
                </h5>
              </v-col>
            </v-row> -->
          </v-col>
          <v-col
            class="bills__link col-md-3"
            :class="{ 'align-self-center': !billToShow.showPaymentButton }"
          >
            <v-row align="center">
              <v-col>
                <!-- <template v-if="billToShow.flagPagamentoRata">
                  <a
                    target="_blank"
                    class="d-flex align-center justify-center"
                    @click="(event) => goToPdrDetails(billToShow, event)"
                  >
                    <h5 class="ButtonL color--blue">
                      Dettaglio piano di rientro
                    </h5>
                  </a>
                </template>
                <template v-else> -->
                <template>
                  <a
                    target="_blank"
                    class="d-flex align-center justify-center"
                    :href="
                      getBillPdf(billToShow.codiceCliente, billToShow.idFattura)
                    "
                    @click="$event.stopPropagation()"
                  >
                    <h5
                      class="color--blue"
                      @click="
                        trackTapEvent({
                          name: 'supplyDetail_bill_tap',
                          params: getSupplyTrackingParams(supply)
                        })
                      "
                    >
                      Bolletta sintetica
                    </h5>
                    <img
                      class="color--blue ml-3"
                      src="@/assets/icons/medium-pdf.svg"
                      height="24"
                      width="24"
                    />
                  </a>
                </template>
              </v-col>
            </v-row>
            <v-row
              class="bills__payment-button justify-center"
              v-if="billToShow.showPaymentButton"
            >
              <v-col
                @click="openPaymentRoute($event, billToShow)"
                style="cursor: pointer; text-align: center"
              >
                Paga o invia ricevuta
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PayWithCardDialog from '@/components/payments/PayWithCardDialog'
import { computeBillTitle } from '@/js/constants.js'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import { formatdateMonthYear } from '@/js/utils.js'
import md5 from 'blueimp-md5'
import { mapActions } from 'vuex'
import {
  CDN_URL,
  computeBillPaymentDelay,
  computeBillStatusLabel
} from '../../js/constants'

export default {
  name: 'billItem',
  props: ['supply', 'bills'],
  components: { PayWithCardDialog },
  data() {
    return {
      showPaymentDialog: false,
      billToPay: null,
      getSupplyTrackingParams: getSupplyTrackingParams
    }
  },
  computed: {
    billToShow: {
      get() {
        return this.bills || {}
      },
      set(newVal) {
        return newVal
      }
    }
  },
  mounted() {
    this.computeShowPaymentButton(this.billToShow).then((shouldShow) => {
      this.billToShow.showPaymentButton =
        shouldShow && this.billToShow.importo > 0
      this.$forceUpdate()
    })
    this.getComputedBillStatus(this.billToShow).then((status) => {
      this.billToShow.computedStatus = status
      this.$forceUpdate()
    })
  },
  methods: {
    ...mapActions('bill', [
      'checkBillPaid',
      'getComputedBillStatus',
      'computeShowPaymentButton'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    typeBill() {
      return computeBillTitle(this.billToShow)
    },
    isNotExpiredBill(bill) {
      // let splitDate = bill.dataScadenza.split('/')
      // let dataScadenza = new Date(splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0])
      // return new Date() < dataScadenza
      return true
    },
    formatdate(value) {
      return formatdateMonthYear(value)
    },
    labelStatusBill(bill) {
      let mdp = bill.mdp.toUpperCase()
      let isRecurrent = ['CCR', 'SDD', 'DPAY'].includes(mdp)
      if (isRecurrent && bill.computedStatus === '1') {
        return 'In pagamento'
      } else if (
        bill.statoFattura === 'Parziale' &&
        bill.computedStatus === '3'
      ) {
        return 'PAGATA PARZIALMENTE'
      } else {
        return computeBillStatusLabel(bill.computedStatus)
      }
    },
    billRequiresPayDisclamer(bill) {
      return this.labelUpdatePaymentDelay(bill) !== null
    },
    labelUpdatePaymentDelay(bill) {
      return computeBillPaymentDelay(bill)
    },
    getBillPdf(clientOwnerCode, idFattura) {
      let companyPrefix = 'SRG'
      let dataParams = md5(
        companyPrefix + '/' + clientOwnerCode + '/' + idFattura + '/S'
      )
      let pdfUrl = CDN_URL + '/' + dataParams

      return pdfUrl
    },
    isOldBill(dataEmissione) {
      let splitDate = dataEmissione.split('/')
      let date = new Date(
        splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0]
      )
      let oldDate = new Date('2019/07/01')
      return date < oldDate
    },

    openPaymentDialog(bill) {
      this.billToPay = bill
      this.showPaymentDialog = true
    },
    openPaymentRoute(event, bill) {
      event.stopPropagation()
      this.trackTapEvent('billList_payBill_tap')
      if (this.labelStatusBill(bill) === 'PAGATA PARZIALMENTE') {
        this.$router.push({
          name: 'paySupplyBills',
          params: {
            bills: [bill],
            isMultiPayment: false,
            idFattura: bill.idFattura,
            from: this.$route.name
          }
        })
      } else {
        this.$router.push({
          name: 'payBill',
          params: {
            bill: bill,
            isMultiPayment: false,
            idFattura: bill.idFattura,
            from: this.$route.name
          }
        })
      }
    },
    numberIta(value, decimals) {
      value = typeof value !== 'number' ? Number(value) : value
      if (typeof value !== 'number') {
        return value
      }
      var formatter = new Intl.NumberFormat('it-it', {
        minimumFractionDigits: decimals
      })
      return formatter.format(value)
    }
    // goToPdrDetails(bill, event) {
    //   event.stopPropagation()
    //   this.trackTapEvent({
    //     name: 'supplyDetail_PDRCard_tap'
    //   })
    //   console.log(bill, 'pippo')
    //   console.log(this.supply, 'pippo')
    //   this.$router.push({
    //     name: 'pdrDetail',
    //     params: {
    //       clientOwnerCode: bill.codiceCliente,
    //       supplyCode: this.supply.supplyCode,
    //       planId: bill.planId
    //     }
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
.std-panel {
  cursor: pointer;
  &.notRegular {
    border: 1px solid $srg-purple;
  }
}

.bills__list {
  .bills__iteams {
    //border-right: 1px solid lightgray;

    &_container:hover {
      background-color: #f8f9fc;
    }

    .col {
      padding-bottom: 0px;
    }

    .bills__item-bold {
      margin-top: 6px;
      h3 {
        letter-spacing: 0.48px;
        font-weight: 500;
        line-height: 26px;
      }

      h5 {
        font-size: 14px;
      }

      h4 {
        font-size: 16px;
      }

      h5,
      h4,
      h2,
      span {
        color: black;
        font-weight: bold;
      }

      span {
        font-size: $title-font-size--small;
      }

      .col {
        padding-bottom: 0px;
      }
    }
    .bills__expired {
      letter-spacing: 1px;
    }
    .bills__statusPay {
      font-size: 16px !important;
      text-transform: uppercase;
      font-size: $title-font-size--small;

      &--1 {
        color: $srg-blue !important;
      }

      &--2 {
        color: black;
      }

      &--3 {
        color: $srg-purple !important;
      }

      &--4,
      &--NA {
        color: black;
      }

      &--5 {
        color: $srg-purple !important;
      }
      &--6 {
        color: $srg-blue !important;
      }
    }
  }

  .bills__warningPaying {
    color: $srg-purple;
    margin-top: 10px;
  }

  .bills__warningBank {
    color: black;
    margin-top: 10px;
  }

  .bills__link {
    text-align: center;

    h5 {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.2px;
    }

    .row {
      height: 50%;
    }

    .bills__payment-button {
      //border-top: 1px solid lightgray;;
      text-align: left;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.2px;
      color: $srg-blue;
    }
  }

  .bills__empty {
    width: 100%;
    height: 120px;
    border-radius: 40px;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
}
</style>
