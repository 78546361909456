var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bills-archive"},[_c('PayWithCardDialog',{attrs:{"bill":_vm.billToPay,"showDialog":_vm.showPaymentDialog},on:{"close-dialog":function($event){_vm.showPaymentDialog = false}}}),(_vm.billsToShow.length === 0)?[_c('v-row',[_c('div',{staticClass:"bills__empty mb-6"},[_vm._v(" Ops! Non sono presenti bollette per questa selezione ")])])]:_vm._l((_vm.billsToShow),function(bill,index){return _c('v-row',{key:index,attrs:{"align":"center"}},[_c('v-col',{class:`std-panel std-panel--shadowed pa-0  ${
          bill.showPaymentButton ? 'notRegular' : ''
        }`},[_c('v-row',{staticClass:"bills__iteams_container",attrs:{"no-gutters":""},on:{"click":function($event){_vm.trackTapEvent({
              name: 'supplyDetail_bill_tap',
              params: _vm.getSupplyTrackingParams(_vm.supply)
            }),
              _vm.$router.push({
                name: 'bills',
                params: { idFattura: bill.idFattura }
              })}}},[_c('v-col',{staticClass:"bills__iteams col-md-9 px-8 py-8"},[_c('v-row',{staticClass:"bills__item-bold mb-5",attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.typeBill(bill)))])])],1),_c('v-row',{staticClass:"bills__item",attrs:{"no-gutters":""}},[_c('v-col',{class:`${_vm.isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`},[_c('h5',{staticClass:"BodyM"},[_vm._v("Periodo")])]),(_vm.isNotExpiredBill(bill))?_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Scadenza")])]):_vm._e(),_c('v-col',{class:`${_vm.isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`},[_c('h5',{staticClass:"BodyM"},[_vm._v("Stato pagamento")])]),_c('v-col',{class:`${_vm.isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`},[_c('h5',{staticClass:"BodyM"},[_vm._v("Importo")])])],1),_c('v-row',{staticClass:"bills__item-bold align-baseline",attrs:{"no-gutters":""}},[_c('v-col',{class:`${_vm.isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`},[_c('h4',[_vm._v(_vm._s(_vm.formatdate(bill.dataInizioCompetenza)))])]),(_vm.isNotExpiredBill(bill))?_c('v-col',{staticClass:"col-md-3"},[_c('h4',{staticClass:"bills__expired"},[_vm._v(" "+_vm._s(_vm.$filters.formatDateSplitJoin(bill.dataScadenza))+" ")])]):_vm._e(),_c('v-col',{class:`${_vm.isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`},[_c('h5',{class:'bills__statusPay bills__statusPay--' +
                    bill.computedStatus},[_vm._v(" "+_vm._s(_vm.labelStatusBill(bill))+" ")])]),_c('v-col',{class:`${_vm.isNotExpiredBill(bill) ? 'col-md-3' : 'col-md-4'}`},[_c('h3',[_c('strong',[_vm._v("€ "+_vm._s(bill.statoFattura === 'Parziale' ? bill.importoParzialeDaPagare : _vm.numberIta(bill.importo)))])])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-md-12"},[(_vm.billRequiresPayDisclamer(bill))?_c('h5',{staticClass:"bills__warningBank"},[_vm._v(" Gestione in corso dalla banca con aggiornamento in "+_vm._s(_vm.labelUpdatePaymentDelay(bill))+" giorni ")]):_vm._e()])],1)],1),_c('v-col',{staticClass:"bills__link col-md-3",class:{ 'align-self-center': !bill.showPaymentButton }},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[[_c('a',{staticClass:"d-flex align-center justify-center",attrs:{"target":"_blank","href":_vm.getBillPdf(bill.codiceCliente, bill.idFattura)},on:{"click":function($event){return $event.stopPropagation()}}},[_c('h5',{staticClass:"color--blue",on:{"click":function($event){_vm.trackTapEvent({
                          name: 'supplyDetail_bill_tap',
                          params: _vm.getSupplyTrackingParams(_vm.supply)
                        })}}},[_vm._v(" Bolletta sintetica ")]),_c('img',{staticClass:"color--blue ml-3",attrs:{"src":require("@/assets/icons/medium-pdf.svg"),"height":"24","width":"24"}})])]],2)],1),(bill.showPaymentButton)?_c('v-row',{staticClass:"bills__payment-button justify-center"},[_c('v-col',{staticStyle:{"cursor":"pointer","text-align":"center"},on:{"click":function($event){return _vm.openPaymentRoute($event, bill)}}},[_vm._v(" Paga o invia ricevuta ")])],1):_vm._e()],1)],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }