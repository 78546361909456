<template>
  <div class="offer">
    <transition name="fade">
      <div class="skeleton mb-10" v-if="!loaded">
        <Placeholder type="changeProductHeader" />
        <Placeholder type="changeProductBody" />
        <Placeholder type="changeProductFooter" />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="loaded">
        <div class="HeadingL mb-2">
          {{ changeProduct.pageTitle }}
        </div>
        <div class="d-flex align-center mb-2">
          <div class="BodyXL-Strong">
            <div class="name">
              <img
                contain
                :src="
                  require(`@/assets/changeproduct/icons/${changeProduct.clientTypeIcon}.svg`)
                "
              />
              {{ changeProduct.clientNaming }}
            </div>
          </div>
        </div>
        <div class="BodyXL-Strong mb-2">
          {{ $filters.capitalize(changeProduct.address) }}
        </div>
        <div class="BodyL mb-6">
          {{ changeProduct.podPdrTitle + ': ' + changeProduct.podPdrValue }}
        </div>
        <div class="offerBox mb-5">
          <div class="LabelS offerBox__foryou mb-2">
            {{ offerData.priceQuoteText }}
          </div>
          <div class="offerBox__title mb-5">
            <div class="HeadingL">{{ offerData.productTitle }}</div>
            <img
              contain
              :src="
                changeProduct.contractType === 'ELE'
                  ? require('../../../assets/icons/ic-light-blu.svg')
                  : require('../../../assets/icons/ic-fire-blu.svg')
              "
              width="32"
              height="32"
            />
          </div>
          <div class="offerBox__body mb-5">
            <div class="offerBox__body__detail">
              <div class="LabelM mb-2">
                {{ offerData.monthlyExpensesTitle }}
              </div>
              <div class="BodyM mb-2">
                {{ offerData.priceDetail }}
              </div>
              <div class="BodyM">
                {{ offerData.noteText }}
              </div>
            </div>
            <div class="offerBox__body__price">
              <div class="SubheadingXXL">{{ splitPrice.parteIntera }}</div>
              <div class="BodyL-Strong mt-4">
                ,{{ splitPrice.parteDecimale }}
              </div>
              <div class="BodyL-Strong">*</div>
            </div>
          </div>
          <div v-if="offerData?.annualSavingsValue" class="offerBox__saving">
            <div class="offerBox__saving__detail">
              <div class="LabelM mb-2">
                {{ offerData.annualSavingsTitle }}
              </div>
              <div
                v-if="offerData.savingsModalText"
                class="BodyM howCalculated"
              >
                {{ offerData.savingsModalTitle }}
                <img
                  @click="
                    openHowCalculatedDetail(
                      offerData.savingsModalTitle,
                      offerData.savingsModalText
                    )
                  "
                  src="../../../assets/changeproduct/icons/icon-info.svg"
                  width="16"
                  height="16"
                />
              </div>
            </div>
            <div class="offerBox__saving__price BodyL-Strong">
              {{ offerData.annualSavingsValue }}
            </div>
          </div>
        </div>
        <div
          class="ButtonM howCalculated__text mb-8"
          @click="
            openHowCalculatedDetail(
              changeProduct.quantitativeModalTitle,
              changeProduct.quantitativeModalText
            )
          "
        >
          {{ changeProduct.quantitativeModalTitle }}
        </div>
        <div class="benefitsContainer ml-1">
          <div v-if="benefits.benefitList" class="BodyL-Strong mb-5">
            {{ benefits.benefitTitle }}
          </div>
          <div class="mb-7">
            <div
              v-for="(benefit, i) in benefits.benefitList"
              :key="i"
              class="benefits mb-4"
            >
              <div class="benefit mb-3">
                <div class="benefit__icon">
                  <img
                    :src="loadIconClass(benefit.benefitIconClass)"
                    width="24"
                  />
                </div>
                <div class="BodyL" v-html="benefit.title"></div>
                <div v-if="benefit?.infoIcon" class="benefit__info">
                  <img
                    :src="loadInfoIcon(benefit.infoIcon)"
                    width="24"
                    class="imgInfo"
                    @click="openCustomDialog(benefit.modalText, benefit.title)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="ButtonM contractConditions mb-7">
            {{ changeProduct.contractTermsText }}
            <img
              src="@/assets/changeproduct/icons/icon-download.svg"
              width="24"
              class="ml-2"
              @click.stop="getPdf()"
            />
          </div>
          <div class="LabelM mb-8">
            {{ changeProduct.endDateValidityFinalText }}
          </div>
        </div>
        <v-btn
          color="primary"
          width="307"
          height="54"
          @click="$emit('nextStep')"
          >Inizia la sottoscrizione</v-btn
        >
      </div>
    </transition>

    <CustomDialog
      :customDialogOpen.sync="customDialogOpen"
      :body="bodyModal"
      :title="titleModal"
      :showImage="showImage"
    />
    <ServiceUnavailableDialog
      :serviceUnavailableDialogOpen.sync="serviceUnavailableDialogOpen"
      :imageResponse="imageServiceError"
      :title="titleServiceError"
      :body="bodyServiceError"
      @retry="loadChangeOfferPreview"
      @returnToHome="returnToHome"
    />
    <pdf-viewer
      :showDialog="showPdf"
      :url="pdfDataUrl"
      @closing-pdfViewer="showPdf = false"
      :attachTo="'body'"
      height="600px"
    />
  </div>
</template>
<script>
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import { mapActions, mapGetters } from 'vuex'
import CustomDialog from '@/components/upselling/gas&light/CustomDialog'
import ServiceUnavailableDialog from '@/components/global/ServiceUnavailableDialog.vue'
import Placeholder from '@components/global/Placeholder.vue'

export default {
  name: 'offerComponent',
  data() {
    return {
      showPdf: false,
      pdfDataUrl: '',
      customDialogOpen: false,
      imageModal: null,
      bodyModal: null,
      titleModal: null,
      showImage: false,
      serviceUnavailableDialogOpen: false,
      titleServiceError: '',
      bodyServiceError: '',
      imageServiceError: '',
      loaded: false
    }
  },
  components: {
    PdfViewer,
    CustomDialog,
    ServiceUnavailableDialog,
    Placeholder
  },
  mounted() {
    this.loadChangeOfferPreview()
  },
  computed: {
    ...mapGetters('offers', [
      'changeProduct',
      'upsellingInfo',
      'getParamsChangeProduct'
    ]),
    ...mapGetters('supply', ['supplies']),

    benefits() {
      return this.changeProduct.benefits
    },
    offerData() {
      return this.changeProduct.offerData
    },
    splitPrice() {
      const [parteIntera, parteDecimale] = this.offerData.monthlyCost.split(',')
      return {
        parteIntera,
        parteDecimale
      }
    }
  },
  methods: {
    ...mapActions('offers', ['getChangeProduct']),
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('offers', ['getChangeProductOffer']),
    ...mapActions('analytics', ['trackTapEvent']),
    getPdf() {
      this.pdfDataUrl = this.changeProduct.contractConditionDoc
      this.showPdf = true
    },
    next() {
      let params = {
        client_code: this.getParamsChangeProduct.clientCode,
        client_type: 'bus',
        pr_code: this.getParamsChangeProduct.prCode,
        supply_type: this.changeProduct.contractType
      }
      this.trackTapEvent({
        name: 'changeOffer_startSubscription_tap',
        params: params
      })
      this.$emit('nextStep')
    },
    loadIconClass(iconClass) {
      try {
        return require(`@/assets/changeproduct/icons/${iconClass}.svg`)
      } catch (error) {
        return require(`@/assets/changeproduct/icons/icon-star.svg`)
      }
    },
    loadInfoIcon(infoIcon) {
      try {
        return require(`@/assets/changeproduct/icons/${infoIcon}.svg`)
      } catch (error) {
        return ''
      }
    },
    openCustomDialog(bodyModal, titleModal) {
      this.showImage = false
      this.bodyModal = bodyModal
      this.titleModal = titleModal
      this.customDialogOpen = true
    },
    openHowCalculatedDetail(title, body) {
      console.log('openHowCalculatedDetail', title, body)
      this.customDialogOpen = true
      this.showImage = false
      this.bodyModal = body
      this.titleModal = title
    },
    async loadChangeOfferPreview() {
      let supplyCode = this.getParamsChangeProduct.prCode
      try {
        await this.getChangeProduct(this.getParamsChangeProduct).then((res) => {
          if (res === 'alreadySigned') {
            this.getSupplies().then((supplies) => {
              let supply = supplies.filter(
                (s) => s.supplyCode === supplyCode
              )[0]
              this.$router.push({
                name: 'resultChangeOffer',
                params: {
                  status: 'expired',
                  address: supply.street
                }
              })
            })
          } else if (res.subscribedFlag) {
            this.$router.push({
              name: 'resultChangeOffer',
              params: {
                status: 'alreadySigned'
              }
            })
          }
        })
        this.loaded = true
      } catch (error) {
        console.log('error', error)
        this.serviceUnavailableDialogOpen = true
        this.titleServiceError = 'Servizio temporaneamente non disponibile'
        this.bodyServiceError =
          'Il servizio è temporaneamente non disponibile. Riprova ora oppure in un secondo momento.'
        this.imageServiceError = 'KO-500'
      }
    },
    returnToHome() {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
.offerBox {
  width: 652px;
  margin: 16px 0;
  padding: 24px;
  border-radius: 16px;
  background-color: #f8f9fc;
  &__foryou {
    background: $neutri-neutral-30;
    width: 113px;
    height: 28px;
    padding: 7px;
    border-radius: 8px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__price {
      display: flex;
    }
  }
  &__saving {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__detail {
      display: flex;
      flex-direction: column;
    }
  }
}
.howCalculated {
  display: flex;
  align-items: center;
  color: black;
  cursor: default;
  img {
    cursor: pointer;
    margin-left: 8px;
  }
  &__text {
    cursor: pointer;
    color: $srg-blue;
  }
}
.benefitsContainer {
  height: auto;
  .benefits {
    display: block;
    .benefit {
      height: 24px;
      display: flex;
      align-items: center;
      &__icon {
        height: 24px;
        margin-right: 12px;
      }
      &__info {
        display: flex;
        align-items: center;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
}
.divider {
  border-color: rgba(0, 0, 0, 0);
  background-color: $neutri-neutral-20;
}
.contractConditions {
  height: 37px;
  display: flex;
  align-items: center;
  color: $srg-blue;
  img {
    margin-left: 8px;
    cursor: pointer;
  }
}
.name {
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
}
.skeleton {
  width: 652px;
}
</style>
