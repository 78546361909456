<template>
  <div class="card v-card v-card--rounded">
    <div v-if="id === '1'" class="py-8 px-12">
      <div class="card__title">Accumula Green Coins</div>
      <div class="card__subtitle">
        Ci sono diversi modi per accumulare Green Coins e accedere ai premi:
      </div>
      <div class="card__list">
        <div class="card__item">
          <img src="../../../assets/greeners/svg/mission-logo.svg" />
          <div class="text">
            <div class="text__desc">
              Ogni mese trovi nuove <strong>missioni</strong> sul tuo diario
            </div>
          </div>
        </div>
        <div class="card__item">
          <img src="../../../assets/greeners/svg/mission-contract.svg" />
          <div class="text">
            <div class="text__desc">
              Ogni mese guadagni dei Green Coins per ogni
              <strong>utenza attiva</strong>
            </div>
          </div>
        </div>
        <div class="card__item">
          <img src="../../../assets/greeners/svg/mission-cfp.svg" />
          <div class="text">
            <div class="text__desc">
              <strong>Misura il tuo impatto</strong>: la prima volta che fai il
              test guadagni punti
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="id === '2'" class="py-8 px-12">
      <div class="card__title">Fai le missioni</div>
      <div class="card__subtitle">
        Ogni mese ti proponiamo diverse tipologie di missioni da completare:
      </div>
      <div class="card__list">
        <div class="card__item">
          <img src="../../../assets/greeners/svg/mission-survey.svg" />
          <div class="text">
            <div class="text__title">SURVEY</div>
            <div class="text__desc">
              Dicci la tua sulla <strong>sostenibilità</strong>
            </div>
          </div>
        </div>
        <div class="card__item">
          <img src="../../../assets/greeners/svg/mission-video.svg" />
          <div class="text">
            <div class="text__title">VIDEO</div>
            <div class="text__desc">
              Segui <strong>notizie</strong> sull’ambiente
            </div>
          </div>
        </div>
        <div class="card__item">
          <img src="../../../assets/greeners/svg/mission-photo.svg" />
          <div class="text">
            <div class="text__title">FOTO</div>
            <div class="text__desc">
              Condividi i momenti <strong>Green</strong>
            </div>
          </div>
        </div>
        <div class="card__item">
          <img src="../../../assets/greeners/svg/mission-quiz.svg" />
          <div class="text">
            <div class="text__title">QUIZ</div>
            <div class="text__desc">Mettiti alla prova</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="id === '3'" class="py-8 px-12 d-flex flex-column">
      <div class="card__title">Sali di livello</div>
      <div class="card__subtitle--small">
        Ogni <strong>8 missioni</strong> passi al livello successivo e sblocchi
        nuovi premi
      </div>
      <img
        width="515"
        class="align-self-center mt-5"
        src="../../../assets/greeners/livelli-full.png"
      />
    </div>
    <!-- <div v-else-if="id === '4'" class="py-12 px-12 custom-height custom-bkg">
      <img class="mb-6" src="../../../assets/greeners/svg/cfp.svg" />
      <div class="card__subtitle ml-12 mb-1">Facci vedere chi sei!</div>
      <div class="card__subtitle--xsmall ml-12 mb-6">Calcola il tuo <strong>Carbon Footprint!</strong></div>
      <div class="card__coins ml-12">
        <img src="../../../assets/greeners/svg/greeners.svg" />
        <div class="text">
          <div class="card__bigtext">1.500</div>
          <div class="card__subtitle">Green Coins</div>
        </div>
      </div>
    </div>
    <div v-else-if="id === '5'">
      <img src="../../../assets/greeners/determinazione.png">
      <div class="determinazione">
        <div class="card__title">Determinazione</div>
        <div class="card__subtitle--small mt-0">Essere sostenibili vuol dire compiere scelte quotidiane. Più Missioni<br/>porti a termine e più salirai di livello ottenendo altri fantastici premi!</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'becomeGreenerCard',
  props: {
    id: {
      type: String,
      default: '1'
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  .determinazione {
    padding: 20px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .card__title {
      align-self: baseline;
      padding-left: 83px;
      margin-bottom: 0;
    }
  }
  .custom-height {
    height: 488px;
  }
  .custom-bkg {
    background-image: url('../../../assets/greeners/esploragreeners.png');
    background-size: contain;
  }
  &__title {
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0.8px;
    margin-bottom: 8px;
    line-height: 1.3;
  }
  &__subtitle {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.48px;
    line-height: 1.3;
    margin-bottom: 32px;
    &--small {
      font-size: 22px;
      font-weight: 500;
    }
    &--xsmall {
      font-size: 16px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .text {
      margin-left: 32px;
      &__title {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 1.4;
      }
      &__desc {
        color: black;
        font-size: 16px;
        line-height: 1.4;
        letter-spacing: 1px;
      }
    }
    img {
      width: 56px;
    }
  }
  &__coins {
    display: flex;
    align-items: flex-start;
    img {
      margin-top: 15px;
      margin-right: 12px;
    }
  }
  &__bigtext {
    font-size: 56px;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 1.3;
  }
}
</style>
