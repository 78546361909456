import { axiosACN, axiosCMS } from '@/js/axiosInstances.js'
import { ENV_CONFIGS } from '@/js/configs.js'
import consts from '@/js/constants'
import { getPromiseSingletone } from '@/js/service/promiseStatus'
import { capitalize } from '@/js/utils'
import store from '..'

const mockMessage = {
  status: 'OK',
  listaClienti: [
    {
      codiceCliente: '5095175',
      numeroMessaggiTotale: '0',
      listaCategorie: [
        {
          greeners: '0',
          myShop: '0',
          utenzaLuce: '0',
          utenzaGas: '0',
          utenzaFibra: '0',
          vantaggiPerTe: '0',
          iniziative: '0',
          info: '0'
        }
      ],
      listaMessaggi: [
        {
          idMessaggio: '2806455',
          codiceCliente: '5095175',
          titolo: 'Attivazione Parental control',
          testoMessaggio:
            'Ciao Lucia, la tua richiesta di attivazione del Parental control è stata inviata correttamente. Per verificare lo stato di attivazione vai alla pagina dedicata.',
          dataInvio: '21/12/2023',
          dataValidita: null,
          categoria: 'Parental Control',
          testoLink: 'Verifica Parental control',
          link: 'ownerSupplies',
          tipoLink: 'APP_SECTION',
          letto: false,
          archiviato: false,
          pinned: false,
          cancellato: false
        },
        {
          idMessaggio: '2806456',
          codiceCliente: '5095175',
          titolo: 'TEST',
          testoMessaggio: 'TEST',
          dataInvio: '16/12/2023',
          dataValidita: null,
          categoria: 'TEST',
          testoLink: 'TEST',
          link: 'TEST',
          tipoLink: 'TEST',
          letto: true,
          archiviato: false,
          pinned: false,
          cancellato: false
        },
        {
          idMessaggio: '2806457',
          codiceCliente: '5095175',
          titolo: 'TEST',
          testoMessaggio: 'TEST',
          dataInvio: '16/12/2023',
          dataValidita: null,
          categoria: 'TEST',
          testoLink: 'TEST',
          link: 'TEST',
          tipoLink: 'TEST',
          letto: true,
          archiviato: false,
          pinned: false,
          cancellato: false
        },
        {
          idMessaggio: '2806458',
          codiceCliente: '5095175',
          titolo: 'TEST',
          testoMessaggio: 'TEST',
          dataInvio: '16/12/2023',
          dataValidita: null,
          categoria: 'TEST',
          testoLink: 'TEST',
          link: 'TEST',
          tipoLink: 'TEST',
          letto: true,
          archiviato: false,
          pinned: false,
          cancellato: false
        },
        {
          idMessaggio: '2806459',
          codiceCliente: '5095175',
          titolo: 'TEST',
          testoMessaggio: 'TEST',
          dataInvio: '15/12/2023',
          dataValidita: '16/12/2023',
          categoria: 'TEST',
          testoLink: 'TEST',
          link: 'TEST',
          tipoLink: 'TEST',
          letto: false,
          archiviato: false,
          pinned: false,
          cancellato: true
        },
        {
          idMessaggio: '2806464',
          codiceCliente: '5095175',
          titolo: 'TEST',
          testoMessaggio: 'TEST',
          dataInvio: '19/12/2023',
          dataValidita: '20/12/2023',
          categoria: 'TEST',
          testoLink: 'TEST',
          link: 'TEST',
          tipoLink: 'TEST',
          letto: true,
          archiviato: false,
          pinned: false,
          cancellato: false
        },
        {
          idMessaggio: '2806465',
          codiceCliente: '5095175',
          titolo: 'TEST',
          testoMessaggio: 'TEST',
          dataInvio: '19/12/2023',
          dataValidita: '20/12/2023',
          categoria: 'TEST',
          testoLink: 'TEST',
          link: 'TEST',
          tipoLink: 'TEST',
          letto: true,
          archiviato: false,
          pinned: false,
          cancellato: false
        },
        {
          idMessaggio: '2806466',
          codiceCliente: '5095175',
          titolo: 'TEST',
          testoMessaggio: 'TEST',
          dataInvio: '19/12/2023',
          dataValidita: '20/12/2023',
          categoria: 'TEST',
          testoLink: 'TEST',
          link: 'TEST',
          tipoLink: 'TEST',
          letto: true,
          archiviato: false,
          pinned: false,
          cancellato: false
        },
        {
          idMessaggio: '2806467',
          codiceCliente: '5095175',
          titolo: 'TEST',
          testoMessaggio: 'TEST',
          dataInvio: '19/12/2023',
          dataValidita: '20/12/2023',
          categoria: 'TEST',
          testoLink: 'TEST',
          link: 'TEST',
          tipoLink: 'TEST',
          letto: true,
          archiviato: false,
          pinned: false,
          cancellato: false
        }
      ]
    }
  ]
}

const checkIfDual = async (clientCode, rootGetters) => {
  const waitForSupplies = async () => {
    let supplies = rootGetters['supply/supplies']

    while (!supplies) {
      await new Promise((resolve) => setTimeout(resolve, 500))
      supplies = rootGetters['supply/supplies']
    }

    return supplies
  }

  const supplies = await waitForSupplies()

  const result = await Promise.resolve(supplies)
  return (
    result.filter(
      (sup) =>
        sup.clientOwnerCode === clientCode &&
        (sup.supplyState === consts.SUPPLY_STATUS.ACTIVE ||
          sup.supplyState === consts.SUPPLY_STATUS.ACTIVATING) &&
        sup.serviceType !== consts.SERVICE_TYPE.FIBER &&
        (sup.role === 'I' || sup.role === 'G')
    ).length > 1
  )
}

const setNotificationForClient = (rootGetters, client) => {
  if (!client.isOnlyFV) {
    return retriveNotifications(rootGetters, {
      clientCode: client.clientOwnerCode
    }).then((res) => {
      if (!res.lista || res.lista.length === 0) {
        client.hasDreamService = false
        client.notifications = 0
        client.requests = []
      } else {
        let clientData = res.lista.filter(
          (item) => item.codiceCliente === client.clientOwnerCode
        )[0]
        client.hasDreamService = !!clientData
        client.notifications = Number((clientData || {}).nuoveNotifiche || 0)
        client.requests = (clientData || {}).listaNotifiche
        if (client.requests) {
          client.requests.forEach((item) => {
            item.servizio = item.servizio === 'Luce' ? 'Ele' : item.servizio
          })
        }
      }
    })
  }
}

const retriveNotifications = (getters, data) => {
  return axiosACN
    .post(
      '/wsccp/V2/readLogbook',
      {
        codiceCliente: data.clientCode
      },
      {
        headers: {
          Authorization: 'Bearer ' + getters['session/token']
        }
      }
    )
    .then((res) => {
      if (res.data.errorDescription) {
        // No bus logic errors expected
        return Promise.resolve({})
      }

      return res.data
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        return Promise.resolve({})
      } else if (err.message) {
        return Promise.resolve({})
      }
    })
}

async function checkLoyaltyRegistration(dispatch, commit, rootGetters, data) {
  let isClientRegistred = false
  let i = 0
  do {
    let memberStatus = await dispatch(
      'loyalty/getMemberStatus',
      { ext_uuid: data[i].clientOwnerCode },
      { root: true }
    )
    isClientRegistred = memberStatus.user_registered
    data[i].user_registered = memberStatus.user_registered
    if (isClientRegistred) commit('setMainClientLoyaltySelected', data[i])
    i++
  } while (!isClientRegistred && i < data.length)
}

const retrieveClients = (dispatch, commit, rootGetters) => {
  if (
    !window.retrieveClientsPromise ||
    !getPromiseSingletone(window.retrieveClientsPromise)
  ) {
    window.retrieveClientsPromise = new Promise((resolve, reject) => {
      axiosACN
        .post(
          '/sorgeniadataclient/V4/retrieveclientaccount',
          // '/sorgeniadataclient/V3/retrieveclientaccount',
          {
            username: rootGetters['session/username']
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then(async (res) => {
          // Manage business logic errors (coming with status 200 OK + errorcode)
          if (res.data.errorCode) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }

          let oldestResClient = null
          let oldestBusClient = null
          let mainClient = null
          let mainClientsRes = []
          let mainClientsBus = []
          let clients = {}
          let acuIdList = {}
          let resClientsCounter = 0
          let busClientsCounter = 0
          let _mainClientLoyaltyRes = null
          let _mainClientLoyaltyBus = null

          commit('setIdLogin', res.data.clientList[0].loginId)
          commit('setUserIdMd5', res.data.userIdMd5)

          for (let cl of res.data.clientList) {
            let client = {
              clientOwnerCode: cl.clientCode || cl.clientCodeFV,
              clientCodeFV: cl.clientCodeFV || '',
              clientOwnerType: cl.clientType || '',
              firstName: capitalize(cl.name),
              lastName: capitalize(cl.surname),
              businessName: cl.businessName || '',
              hasDigitalPoints: !!cl.hasDigitalPoints,
              mgmCode: cl.friendCode,
              mainRole: cl.mainRole,
              prospectFlag: cl.prospectFlag,
              loginId: cl.loginId,
              acceptedClientState: cl.acceptedClientState,
              prospectCode: cl.prospectCode,
              acuId: cl.acuId,
              tgTurnedMLFlag: cl.tgTurnedMLFlag,
              isOnlyFV: !cl.clientCode
            }

            if (!cl.prospectFlag) {
              // todo change response for refactory service with true/false
              await setNotificationForClient(rootGetters, client)
            }
            if (client.clientOwnerCode) {
              clients[client.clientOwnerCode] = client
            }
            if (cl.acuId) {
              acuIdList[cl.acuId] = client
            }

            // Check for mainClient
            if (cl.mainRole === '1') {
              if (cl.clientType === 'RES') {
                mainClientsRes.push(client)
              } else if (cl.clientType === 'BUS') {
                mainClientsBus.push(client)
              }
            }

            if (cl.clientType === 'RES') {
              resClientsCounter++
              oldestResClient =
                oldestResClient &&
                parseInt(oldestResClient.clientOwnerCode) <
                  parseInt(client.clientOwnerCode)
                  ? oldestResClient
                  : client
            } else if (cl.clientType === 'BUS') {
              busClientsCounter++
              oldestBusClient =
                oldestBusClient &&
                parseInt(oldestBusClient.clientOwnerCode) <
                  parseInt(client.clientOwnerCode)
                  ? oldestBusClient
                  : client
            }
          }

          if (
            mainClientsRes.length > 1 ||
            (resClientsCounter > 1 && mainClientsRes.length === 0)
          ) {
            commit('setLoyaltyResToFix', true)
          } else {
            _mainClientLoyaltyRes =
              mainClientsRes.length === 1 ? mainClientsRes[0] : oldestResClient
            commit('setMainClientLoyaltyRes', _mainClientLoyaltyRes)
          }
          if (
            mainClientsBus.length > 1 ||
            (busClientsCounter > 1 && mainClientsBus.length === 0)
          ) {
            commit('setLoyaltyBusToFix', true)
          } else {
            _mainClientLoyaltyBus =
              mainClientsBus.length === 1 ? mainClientsBus[0] : oldestBusClient
            commit('setMainClientLoyaltyBus', _mainClientLoyaltyBus)
          }
          if (oldestResClient) {
            oldestResClient.isMultiResClient = resClientsCounter > 1
            commit('setOldestLoyaltyRes', oldestResClient)
          }
          if (oldestBusClient) {
            oldestBusClient.isMultiBussClient = busClientsCounter > 1
            commit('setOldestLoyaltyBus', oldestBusClient)
          }

          commit('setClients', { clients })
          commit('setAcuIdList', { acuIdList })

          if (mainClientsRes.length !== 1) {
            commit('setMultipleMainClients', true)
          }
          if (mainClientsRes.length === 0) {
            commit('setZeroMainClients', true)
          }

          if (oldestResClient) {
            // this means we have at least 1 RES, so mainClient must be RES (main or oldest, respectively)
            mainClient =
              mainClientsRes.length === 1 ? mainClientsRes[0] : oldestResClient
          } else {
            // this means we have 0 RES, so mainClient must be BUS (main or oldest, respectively)
            mainClient =
              mainClientsBus.length === 1 ? mainClientsBus[0] : oldestBusClient
          }
          commit('setMainClient', mainClient)
          commit('setMainClientLoyaltySelected', mainClient)

          let data = [mainClient]
          if (
            _mainClientLoyaltyRes &&
            _mainClientLoyaltyRes.clientOwnerCode !== mainClient.clientOwnerCode
          )
            data.push(_mainClientLoyaltyRes)
          if (
            _mainClientLoyaltyBus &&
            _mainClientLoyaltyBus.clientOwnerCode !== mainClient.clientOwnerCode
          )
            data.push(_mainClientLoyaltyBus)
          commit('setListMainClientLoyalty', data)
          if (!mainClient.isOnlyFV) {
            await checkLoyaltyRegistration(dispatch, commit, rootGetters, data)
          }

          // Check for account type - RES (res only) / BUS (bus only) / MIXED
          const accountType =
            oldestResClient && oldestBusClient
              ? 'MIXED'
              : oldestBusClient
              ? 'BUS'
              : 'RES'
          commit('setAccountType', accountType)

          // Used for analytics purposes. Possible values are: UNICO RES | MULTI RES | UNICO BUS | MULTI BUS | MISTO
          let detailedAccountType = ''
          if (accountType !== 'MIXED') {
            if (accountType === 'RES') {
              detailedAccountType =
                resClientsCounter > 1 ? 'MULTI RES' : 'UNICO RES'
            } else {
              detailedAccountType =
                busClientsCounter > 1 ? 'MULTI BUS' : 'UNICO BUS'
            }
          } else {
            detailedAccountType = 'MISTO'
          }

          commit('setDetailedAccountType', detailedAccountType)

          resolve({ clients, mainClient })
        })
        .catch((err) => {
          console.error(err.message)
          throw new Error('SERVER_ERROR')
        })
    })
  }
  return window.retrieveClientsPromise
}
const retrieveClientDetail = (commit, rootGetters, data) => {
  let photovoltaic =
    rootGetters['session/accountTypes'].includes('PHOTOVOLTAIC')
  return axiosACN
    .post(
      '/sorgeniadataclient/v4/retrieveclientdetails',
      // '/sorgeniadataclient/v3/retrieveclientdetails',
      {
        // codiceCliente: data.clientOwnerCode,
        clientCode:
          data.clientOwnerCode && !data.isOnlyFV
            ? data.clientOwnerCode
            : undefined,
        acuId: data.acuId && photovoltaic ? data.acuId : undefined,
        username: rootGetters['session/username']
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then(async (res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.error) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }

      const client = {
        clientOwnerCode: res.data.clientCode,
        clientCodeFV: res.data.clientCodeFV, // in attesa di rilascio servizi BE con v4
        clientOwnerType: res.data.clientType,
        firstName: res.data.name ? capitalize(res.data.name) : '',
        lastName: res.data.surname ? capitalize(res.data.surname) : '',
        businessName: res.data.businessName,
        hasDigitalPoints: res.data.hasDigitalPoints,
        mgmCode: res.data.cdAmico || '',
        tgTurnedMLFlag: res.data.tgTurnedMLFlag, // MYS-3268
        isDUAL: false
      }
      // store.dispatch(
      //   'session/setAccountDeletionRequested',
      //   res.data.accountDeletionRequested
      // )

      client.isDUAL = await checkIfDual(client.clientOwnerCode, rootGetters)
      let newResp = {
        ...res.data,
        isDUAL: client.isDUAL
      }
      commit('setcontactMail', newResp.contactMail)
      commit('setClientDetail', newResp)
      commit('setFilteredClientDetails', client)
      return mappingClientDetail(newResp)
    })
    .catch((err) => {
      // } catch (err) {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const mappingClientDetail = (clientDetails) => {
  return {
    status: clientDetails.status,
    clientCode: clientDetails.clientCode,
    clientCodeFV: clientDetails.clientCodeFV,
    clientType: clientDetails.clientType,
    name: clientDetails.name,
    surname: clientDetails.surname,
    businessName: clientDetails.businessName,
    contactMail: clientDetails.contactMail,
    gender: clientDetails.gender,
    birthDate: clientDetails.birthDate,
    birthPlace: clientDetails.birthPlace,
    fiscalCode: clientDetails.fiscalCode,
    VATNumber: clientDetails.vatNumber,
    residentialAddress: clientDetails.residentialAddress,
    residentialCAP: clientDetails.residentialCAP,
    residentialCity: clientDetails.residentialCity,
    residentialRegion: clientDetails.residentialRegion,
    residentialNation: clientDetails.residentialNation,
    residentialProvince: clientDetails.residentialProvince,
    phone: clientDetails.phone,
    mobilePhone: clientDetails.mobilePhone,
    marketingConsentFlag: clientDetails.marketingConsentFlag,
    thirdPartConsentFlag: clientDetails.thirdPartConsentFlag,
    profilingConsentFlag: clientDetails.profilingConsentFlag,
    informationNoticeFlag: clientDetails.informationNoticeFlag,
    mainRole: clientDetails.mainRole,
    hasDigitalPoints: clientDetails.hasDigitalPoints,
    loginIdtgTurnedMLFla: clientDetails.loginIdtgTurnedMLFla,
    tgTurnedMLFlag: clientDetails.tgTurnedMLFlag,
    accountDeletionRequested: clientDetails.accountDeletionRequested
  }
}

const retrieveClientContracts = (commit, rootGetters, data) => {
  return axiosACN
    .post(
      '/sorgeniadataclient/V2/contractdocuments',
      {
        codiceCliente: data.clientCode || data.clientOwnerCode
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      // Manage business logic errors (coming with status 200 OK + errorcode)
      if (res.data.error) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }
      commit('setClientContracts', res.data)
      return res.data
    })
    .catch((err) => {
      // } catch (err) {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const retrieveFriendList = (commit, rootGetters, clientOwnerCode) => {
  return axiosACN
    .post(
      '/sorgeniadataclient/V2/elencoamici',
      { codiceCliente: clientOwnerCode },
      { headers: { Authorization: 'Bearer ' + rootGetters['session/token'] } }
    )
    .then((res) => {
      if (res.data.error) {
        // No bus logic errors expected
        throw Error('UNKNOWN_ERROR')
      }
      return res.data.amiciList
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const retrivePromos = (commit) => {
  return axiosCMS
    .get('/promo')
    .then((res) => {
      commit('setPromo', res.data)

      return res.data
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const retrieveNoDigitalContracts = (commit, rootGetters, data) => {
  return axiosACN
    .post(
      '/sorgeniadataclient/V2/retrieveDocuments',
      {
        codiceCliente: data.clientCode || data.clientOwnerCode
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      if (res.data.error) {
        throw Error('UNKNOWN_ERROR')
      }
      return res.data
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const retrieveNumberMessage = (commit, rootGetters, clientList) => {
  if (clientList.length === 0) {
    return Promise.resolve(
      commit('setNumberMessage', {
        status: 'OK',
        listaClienti: [
          {
            codiceCliente: '5095175',
            numeroMessaggiDaLeggere: '1'
          }
        ]
      })
    )
  }
  return axiosACN
    .post(
      '/wsccp/getNumberMessage',
      {
        listaClienti: clientList
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      if (res.data.error) {
        throw Error('UNKNOWN_ERROR')
      }
      commit('setNumberMessage', res.data)
      return res.data
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

const retrieveStatusMessage = (commit, rootGetters, data) => {
  return axiosACN
    .post(
      '/wsccp/V2/getStatusMessage',
      {
        idMessaggio: data.idMessaggio,
        codiceCliente: data.codiceCliente,
        letto: data.letto,
        archiviato: data.archiviato,
        pinned: data.pinned,
        cancellato: data.cancellato
      },
      {
        headers: {
          Authorization: 'Bearer ' + rootGetters['session/token']
        }
      }
    )
    .then((res) => {
      if (res.data.error) {
        throw Error('UNKNOWN_ERROR')
      }

      return res.data
    })
    .catch((err) => {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    })
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    clients: null,
    acuIdList: null,
    mainClient: null,
    clientDetails: {},
    filteredClientDetails: null,
    accountProperties: {},
    accountClients: [],
    friendsList: {},
    accountPhoto: null,
    isMultipleMainClients: false,
    isZeroMainClients: false,
    promos: null,
    totalNotifications: 0,
    numberMessage: null,
    loyaltyResToFix: false,
    loyaltyBusToFix: false,
    mainClientLoyaltyRes: null,
    mainClientLoyaltyBus: null,
    oldestLoyaltyRes: null,
    oldestLoyaltyBus: null,
    mainClientLoyaltySelected: null,
    listMainClientLoyalty: [],
    accountInitiative: {},
    idLogin: '',
    messageCenter: {},
    contactMail: '',
    userIdMd5: ''
  },
  getters: {
    accountProperties(state) {
      return state.accountProperties
    },
    accountClients(state) {
      return state.clients
    },
    accountAcuIdList(state) {
      return state.acuIdList
    },
    filteredClientDetails(state) {
      return state.filteredClientDetails
    },
    clientDetails(state) {
      return state.clientDetails
    },
    mainClient(state) {
      return state.mainClient
    },
    friendsList(state) {
      return state.friendsList
    },
    accountPhoto(state) {
      return state.accountPhoto
    },
    isMultipleMainClients(state) {
      return state.isMultipleMainClients
    },
    isZeroMainClients(state) {
      return state.isZeroMainClients
    },
    promos(state) {
      return state.promos
    },
    totalNotifications(state) {
      return state.totalNotifications
    },
    numberMessage(state) {
      return state.numberMessage
    },
    loyaltyResToFix(state) {
      return state.loyaltyResToFix
    },
    loyaltyBusToFix(state) {
      return state.loyaltyBusToFix
    },
    mainClientLoyaltyRes(state) {
      return state.mainClientLoyaltyRes
    },
    mainClientLoyaltyBus(state) {
      return state.mainClientLoyaltyBus
    },
    oldestLoyaltyRes(state) {
      return state.oldestLoyaltyRes
    },
    oldestLoyaltyBus(state) {
      return state.oldestLoyaltyBus
    },
    mainClientLoyaltySelected(state) {
      return state.mainClientLoyaltySelected
    },
    listMainClientLoyalty(state) {
      return state.listMainClientLoyalty
    },
    accountInitiative(state) {
      return state.accountInitiative
    },
    idLogin(state) {
      return state.idLogin
    },
    getMessageCenter(state) {
      return state.messageCenter
    },
    getcontactMail(state) {
      return state.contactMail
    },
    getUserIdMd5(state) {
      return state.userIdMd5
    }
  },
  mutations: {
    resetState(state) {
      delete window.retrieveClientsPromise
      state.clients = null
      state.acuIdList = null
      state.mainClient = null
      state.isMultipleMainClients = false
      state.isZeroMainClients = false
      state.clientDetails = {}
      state.filteredClientDetails = null
      state.accountProperties = {}
      state.accountClients = []
      state.accountPhoto = null
      state.promos = null
      state.totalNotifications = 0
      state.numberMessage = null
      state.loyaltyResToFix = false
      state.loyaltyBusToFix = false
      state.mainClientLoyaltyRes = null
      state.mainClientLoyaltyBus = null
      state.oldestLoyaltyRes = null
      state.oldestLoyaltyBus = null
      state.mainClientLoyaltySelected = null
      state.listMainClientLoyalty = []
    },
    resetClients(state) {
      delete window.retrieveClientsPromise
      state.clients = null
      state.acuIdList = null
    },
    setClients(state, { clients }) {
      state.clients = clients
    },
    setAcuIdList(state, { acuIdList }) {
      state.acuIdList = acuIdList
    },
    setClientDetail(state, clientDetails) {
      state.clientDetails = mappingClientDetail(clientDetails)
    },
    setFilteredClientDetails(state, clientDetails) {
      state.filteredClientDetails = clientDetails
    },
    setClientContracts(state, clientContracts) {
      state.clientDetails.contractDocumentsList =
        clientContracts.contractDocumentsList
    },
    setMainClient(state, mainClient) {
      state.mainClient = mainClient
    },
    setAccountType(state, accountType) {
      state.accountProperties.type = accountType
    },
    setDetailedAccountType(state, detailedAccountType) {
      state.accountProperties.detailedType = detailedAccountType
    },
    setFriendsList(state, friendsList) {
      state.friendsList = friendsList
    },
    setAccountPhoto(state, accountPhoto) {
      state.accountPhoto = accountPhoto
    },
    setTotalNotifications(state, notifications) {
      state.totalNotifications = notifications
    },
    setAccountProperties(state, accountProperties) {
      // Using assign breaks the observer on the accountProperties field and breaks the related computed properites updates
      // Object.assign(state.accountProperties, accountProperties)
      const type = state.accountProperties.type
      const detailedType = state.accountProperties.detailedType
      if (!accountProperties.hasAtLeast1Placet && type !== 'BUS') {
        accountProperties.isLoyaltyEligible = true
      }
      state.accountProperties = accountProperties
      state.accountProperties.type = type
      state.accountProperties.detailedType = detailedType
    },
    setMultipleMainClients(state, isMultipleMainClients) {
      state.isMultipleMainClients = isMultipleMainClients
    },
    setZeroMainClients(state, isZeroMainClients) {
      state.isZeroMainClients = isZeroMainClients
    },
    setPromo(state, promos) {
      state.promos = promos
    },
    setAccountPropLoyalty(state, registered) {
      state.accountProperties.hasLoyaltyEnrollment = registered
    },
    setNumberMessage(state, numberMessage) {
      state.numberMessage = numberMessage
    },
    setLoyaltyResToFix(state, loyaltyResToFix) {
      state.loyaltyResToFix = loyaltyResToFix
    },
    setLoyaltyBusToFix(state, loyaltyBusToFix) {
      state.loyaltyBusToFix = loyaltyBusToFix
    },
    setMainClientLoyaltyRes(state, mainClientLoyaltyRes) {
      state.mainClientLoyaltyRes = mainClientLoyaltyRes
    },
    setMainClientLoyaltyBus(state, mainClientLoyaltyBus) {
      state.mainClientLoyaltyBus = mainClientLoyaltyBus
    },
    setOldestLoyaltyRes(state, oldestLoyaltyRes) {
      state.oldestLoyaltyRes = oldestLoyaltyRes
    },
    setOldestLoyaltyBus(state, oldestLoyaltyBus) {
      state.oldestLoyaltyBus = oldestLoyaltyBus
    },
    setMainClientLoyaltySelected(state, mainClientLoyaltySelected) {
      state.mainClientLoyaltySelected = mainClientLoyaltySelected
    },
    setListMainClientLoyalty(state, listMainClientLoyalty) {
      state.listMainClientLoyalty = listMainClientLoyalty
    },
    setAccountInitiative(state, initiative) {
      state.accountInitiative = initiative
    },
    setIdLogin(state, idLogin) {
      state.idLogin = idLogin
    },
    setMessageCenter(state, data) {
      state.messageCenter = data
    },
    setcontactMail(state, data) {
      state.contactMail = state.contactMail ? state.contactMail : data
    },
    setUserIdMd5(state, data) {
      state.userIdMd5 = data
    }
  },
  actions: {
    retrieveMessageCenter({ commit, rootGetters }, clientList) {
      if (clientList.length === 0) {
        return Promise.resolve(commit('setMessageCenter', mockMessage))
      }
      return axiosACN
        .post(
          '/wsccp/V2/retrieveMessageCenter',
          {
            listaClienti: clientList
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.error) {
            throw Error('UNKNOWN_ERROR')
          }
          commit('setMessageCenter', res.data)
          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    readDreamServiceMessage({ rootGetters }, data) {
      return axiosACN
        .post('/wsccp/writeLogbook', data.messageToRead, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          if (res.Status === 'KO') {
            data.messageToRead.letto = false
          }
        })
        .catch((err) => console.error(err.response.data))
    },
    updatePhoto({ dispatch, commit, rootGetters }, data) {
      data.append('username', rootGetters['session/username'])
      return axiosACN
        .post('/sorgenia/updateFotoProfilo', data, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token'],
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          // Invalidate cached acocunt photo
          commit('setAccountPhoto', null)

          dispatch('getAccountPhoto')
        })
        .catch((err) => console.error(err.response.data))
    },
    missingAccountPhoto({ state, commit, rootGetters }) {
      return axiosACN
        .post(
          `/sorgenia/V2/downloadFotoProfilo`,
          { username: rootGetters['session/username'] },
          {
            responseType: 'arraybuffer',
            headers: { Authorization: 'Bearer ' + rootGetters['session/token'] }
          }
        )
        .then((res) => {
          if (res.data.byteLength < 100) {
            let obj = JSON.parse(
              String.fromCharCode.apply(null, new Uint8Array(res.data))
            )
            let isMissing = obj?.status === 'KO'
            return {
              missingPhoto: isMissing,
              photo: isMissing ? null : res.data
            }
          } else {
            return { missingPhoto: false, photo: res.data }
          }
        })
    },
    getAccountPhoto({ dispatch, state, commit, rootGetters }) {
      dispatch('missingAccountPhoto')
        .then((check) => {
          if (!check.missingPhoto) {
            const blob = new Blob([check.photo], { type: 'image/jpg' })
            commit('setAccountPhoto', blob)
          }
        })
        .catch((err) => {
          console.error(err)

          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    async retrieveCredentials(ctx, params) {
      try {
        let res = await axiosACN.post(
          '/sorgenia/V2/recoveryusername',
          {
            cfPiva: params.cfpi,
            canaleProvenienza: 'MYS'
          },
          {
            headers: {
              Authorization: ENV_CONFIGS.BASIC_TOKEN
            }
          }
        )

        // Manage business logic errors (coming with status 200 OK + errorcode)
        if (res.data.errorCode) {
          switch (res.data.errorCode) {
            case '412':
              throw Error('CFPI_NOT_REGISTERED')
            default:
              throw Error('UNKNOWN_ERROR')
          }
        }

        return res.data.email
      } catch (err) {
        if (err.statusCode && err.statusCode >= 400) {
          throw Error('SERVER_ERROR')
        } else if (err.message) {
          throw err
        }
      }
    },

    getClientsForAccount({ dispatch, state, commit, rootGetters }) {
      if (state.clients) {
        return Promise.resolve(state.clients)
      } else {
        return retrieveClients(dispatch, commit, rootGetters).then(
          (resp) => resp.clients
        )
      }
    },
    getDetailForClient({ state, commit, rootGetters }, data) {
      if (!data) {
        return retrieveClientDetail(commit, rootGetters, {
          clientOwnerCode: !state.mainClient.isOnlyFV
            ? state.mainClient.clientOwnerCode
            : undefined,
          acuId: state.mainClient.acuId
        })
      } else {
        return retrieveClientDetail(commit, rootGetters, data)
      }
    },
    getFriendsList({ state, commit, rootGetters }, params) {
      return retrieveFriendList(commit, rootGetters, params).then((res) =>
        commit('setFriendsList', res)
      )
    },
    getContractsForClient({ state, commit, rootGetters }, data) {
      return retrieveClientContracts(commit, rootGetters, data).then(
        (resp) => resp
      )
    },
    getChangedProductContract({ state, commit, rootGetters }, clientCode) {
      return axiosACN
        .post(
          '/fornitura/retrieveChangedProductContract',
          {
            codiceCliente: clientCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw Error('UNKNOWN_ERROR')
          }
          return res.data.listaContratti
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.errorDescription) {
            throw err
          }
        })
    },
    getRetrieveRipensamento({ state, commit, rootGetters }, codiceProposta) {
      return axiosACN
        .post(
          '/commodity/V2/retrieveRipensamento',
          {
            codiceProposta: codiceProposta
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          // Manage business logic errors (coming with status 200 OK + errorcode)
          if (res.data.error) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }
          return res.data
        })
        .catch((err) => {
          // } catch (err) {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    editcontent({ state, commit, rootGetters }, data) {
      return axiosACN
        .post('/sorgeniadataclient/V2/modificaAnagraficaCl', data, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
            // 'access_token': getters.token,
            // 'token_type': 'bearer'
          }
        })
        .then((res) => {
          if (res.status && res.status >= 400) {
            throw new Error('SERVER_ERROR')
          }
        })
        .catch((err) => {
          console.error('Session error' + err)
          if (err.statusCode && err.statusCode >= 400) {
            throw new Error('SERVER_ERROR')
          } else if (err.message) {
            throw new Error(err.message)
          }
        })
    },

    getMainClient({ dispatch, state, commit, rootGetters }) {
      if (state.mainClient) {
        return Promise.resolve(state.mainClient)
      } else {
        return retrieveClients(dispatch, commit, rootGetters).then((resp) => {
          return resp.mainClient
        })
      }
    },

    refreshMainClient({ dispatch, state, commit, rootGetters }) {
      delete window.retrieveClientsPromise
      retrieveClients(dispatch, commit, rootGetters)
    },

    getPromos({ state, commit }) {
      if (state.promos) {
        return Promise.resolve(state.promos)
      } else {
        return retrivePromos(commit).then((resp) => {
          return resp
        })
      }
    },

    getNotDigitalContracts({ state, commit, rootGetters }, data) {
      return retrieveNoDigitalContracts(commit, rootGetters, data).then(
        (resp) => resp
      )
    },
    getTotalNotifications({ state, commit }) {
      if (state.clients) {
        let totalNotifications = Object.values(state.clients)
          .map((item) => item.notifications)
          .reduce((sum, x) => sum + x)
        commit('setTotalNotifications', totalNotifications)
      } else {
        commit('setTotalNotifications', 0)
      }
    },
    getNumberMessage({ state, commit, rootGetters }, data) {
      return retrieveNumberMessage(commit, rootGetters, data).then((resp) => {
        return resp
      })
    },
    getStatusMessage({ state, commit, rootGetters }, data) {
      return retrieveStatusMessage(commit, rootGetters, data).then((resp) => {
        return resp
      })
    },
    getLinKprivacyConfig() {
      return axiosCMS
        .get('/DocumentFile/informativa-app?_format=hal_json')
        .then((resp) => {
          return resp.data[0].document.toString()
        })
    },
    async getAccountInitiative({ dispatch, state, commit, rootGetters }) {
      let greeners = {
        registered: false,
        hasMissions: false
      }
      let cfp = {
        result: ''
      }
      let mgm = {
        hasFriends: false
      }
      // GREENERS
      // let currentClientCommodity = () => {
      //   let commodities = []
      //   if (
      //     state.accountProperties.hasGasActive ||
      //     state.accountProperties.hasGasActivating
      //   )
      //     commodities.push('GAS')
      //   if (
      //     state.accountProperties.hasLightActive ||
      //     state.accountProperties.hasLightActivating
      //   )
      //     commodities.push('ELE')
      //   if (
      //     state.accountProperties.hasFiberActive ||
      //     state.accountProperties.hasFiberActivating
      //   )
      //     commodities.push('FIBRA')

      //   return commodities
      // }
      if (!state.mainClient.isOnlyFV) {
        await checkLoyaltyRegistration(
          dispatch,
          commit,
          rootGetters,
          state.listMainClientLoyalty
        )
      }

      // let registered = await dispatch('loyalty/getMemberStatus', { ext_uuid: state.mainClientLoyaltySelected.clientOwnerCode }, { root: true })
      greeners.registered =
        state.mainClientLoyaltySelected.user_registered || false

      if (greeners.registered) {
        await dispatch('loyalty/getClientLevel', {}, { root: true })
        await dispatch(
          'loyalty/getBalance',
          { ext_uuid: state.mainClientLoyaltySelected.clientOwnerCode },
          { root: true }
        )
        //   let missions = await dispatch(
        //     'loyalty/getAvailableMissions',
        //     {
        //       currentClientTypes: [
        //         state.mainClientLoyaltySelected.clientOwnerType
        //       ],
        //       currentClientCommodity: currentClientCommodity()
        //     },
        //     { root: true }
        //   )
        //   greeners.hasMissions = missions.length > 0
        //   greeners.missions = missions
        greeners.memberBalance =
          rootGetters['loyalty/memberBalance'].user_balance
        greeners.level = rootGetters['loyalty/clientLevel']
      }
      // END GREENERS
      // CFP
      let resultCfp = ''
      if (!state.mainClient.isOnlyFV) {
        resultCfp = await dispatch(
          'cfp/getLastCfpValue',
          { clientOwnerCode: state.mainClient.clientOwnerCode },
          { root: true }
        )
      } else {
        resultCfp = ''
      }

      if (resultCfp) {
        cfp.result = resultCfp
      }
      // END CFP

      commit('setAccountInitiative', { greeners, cfp, mgm })
    }
  }
}
