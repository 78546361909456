<template>
  <v-dialog :max-width="500" v-model="show" persistent :key="dialogKey">
    <div class="billsRecapDialog pdrDialogRate">
      <div class="d-flex align-center justify-space-between mb-8">
        <div class="HeadingsSubtitleL">
          Rate piani di rientro
          {{ pdrAttivo?.planEndDate.replaceAll('/', '.') }}
        </div>
        <img
          @click="show = false"
          src="@/assets/ic_chevron_top_black.png"
          style="cursor: pointer"
        />
      </div>
      <v-row no-gutters>
        <v-col>
          <v-list two-line class="scrollableList">
            <div class="d-flex align-center mb-4">
              <div class="BodyL-Strong">{{ intestatario }}</div>
              <img
                :src="IconOwner"
                v-if="clientDetails.clientType === 'RES'"
                width="22"
                class="supply__owner-icon"
              />
              <img
                :src="IconBusiness"
                v-if="clientDetails.clientType !== 'RES'"
                width="22"
                class="supply__owner-icon"
              />
            </div>
            <div
              @click="selectAllAction"
              :class="`checkbox all d-flex align-center ${
                selectAll ? 'selected' : ''
              }`"
            >
              <span class="BodyL">Seleziona tutto</span>
            </div>
            <div v-for="(rata, index) in listToPay" :key="index">
              <v-list-item class="pr-4 pl-0">
                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center">
                    <div
                      :id="rata?.idInstalment"
                      @click="addPerPagare(rata, index)"
                      :class="`checkbox d-flex align-center ${
                        rata?.checked ? 'selected' : ''
                      } ${rata?.disabled ? 'disabled' : ''}`"
                    >
                      <span class="BodyL"
                        >Rata
                        {{ rata?.instalmentDueDate.replaceAll('/', '.') }}</span
                      >
                    </div>
                    <span
                      class="CaptionL upper color--purple ml-4"
                      v-if="
                        rata?.instalmentStatus === Consts.STATUS_PDR.EXPIRED
                      "
                      ><strong>{{
                        Consts.TRANSLATE_STATUS_PDR[
                          rata?.instalmentStatus
                        ].toUpperCase()
                      }}</strong></span
                    >
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <span class="BodyL-Strong">
                    € {{ getTotal(rata?.residualInstalmentAmount) }}
                  </span>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-btn
            class="paymentButton"
            x-large
            @click="pay"
            :disabled="totaleDaPagare <= 0"
          >
            {{
              totaleDaPagare > 0 ? `Paga € ${getTotal(totaleDaPagare)}` : 'Paga'
            }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import IconBusiness from '@assets/icons/business_black.svg'
import IconOwner from '@assets/icons/ic-owner-res-black.svg'
import { mapActions } from 'vuex'
import { getTotal } from '../../js/utils'

import Consts from '@/js/constants'

export default {
  name: 'dialogPagaRate',
  props: {
    source: String,
    value: Boolean,
    pdrAttivo: Object,
    clientDetails: Object,
    intestatario: String,
    dialogKey: String
  },
  data() {
    return {
      Consts: Consts,
      IconBusiness,
      getTotal: getTotal,
      IconOwner,
      totaleDaPagare: 0,
      listToPay: [],
      selectAll: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        if (!value) {
        }
        this.$emit('input', value)
      }
    }
  },

  watch: {
    pdrAttivo: {
      deep: true,
      immediate: true,
      handler(val) {
        let instalmentFiltered = val?.instalments.filter(
          (rata) => !rata.checkPaymentInstalment
        )
        let isRecurrent = ['CCR', 'SDD', 'DPAY']
        if (val?.planStatus === Consts.STATUS_PDR.ACTIVE) {
          this.listToPay = instalmentFiltered
            .map((instalment, i) => {
              if (isRecurrent.includes(val.planPaymentMethod)) {
                if (instalment.instalmentStatus === Consts.STATUS_PDR.EXPIRED) {
                  return { ...instalment, disabled: i !== 0, checked: false }
                }
              } else {
                if (instalment.instalmentStatus !== Consts.STATUS_PDR.PAYED) {
                  return { ...instalment, disabled: i !== 0, checked: false }
                }
              }
            })
            .filter((instalment) => !!instalment)
        }
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    selectAllAction() {
      this.selectAll = !this.selectAll
      this.listToPay.forEach((rata, i) => {
        rata.checked = this.selectAll
        if (i !== 0) {
          rata.disabled = !this.selectAll
        }
      })
      this.calcTotalRateSelected()
    },
    addPerPagare(rata, index) {
      this.selectAll = false
      this.listToPay[index].checked = !rata.checked
      if (this.listToPay[index].checked) {
        if (this.listToPay[index + 1]) {
          this.listToPay[index + 1].disabled =
            !this.listToPay[index + 1].disabled
        }
        if (index === this.listToPay.length - 1) this.selectAll = true
      } else {
        for (let i = index + 1; this.listToPay.length >= i; i++) {
          if (this.listToPay[i]) {
            this.listToPay[i].disabled = true
            this.listToPay[i].checked = false
          }
        }
      }
      this.calcTotalRateSelected()
    },
    calcTotalRateSelected() {
      this.totaleDaPagare = this.listToPay
        .filter((listChecked) => listChecked?.checked)
        .reduce((acc, currentValue) => {
          return (
            parseFloat(acc) + parseFloat(currentValue.residualInstalmentAmount)
          )
        }, 0)
    },
    pay() {
      this.trackTapEvent('payment_items_selected')
      let instalments = this.listToPay.filter(
        (listChecked) => listChecked?.checked
      )
      this.$router.push({
        name: 'payAllBills',
        params: {
          clientOwnerCode:
            this.clientDetails.clientOwnerCode || this.clientDetails.clientCode,
          pdrs: instalments,
          isMultiPayment: instalments.length > 1,
          from: this.$route.name,
          supplyCode: this.$route.params.supplyCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/dialog_pay_bills/dialogPayBills.scss';
// .pdrDialogRate {
//   padding: 40px 30px;

//   .billsRecapDialog__title {
//     padding-left: 15px;
//   }

//   .supply__owner-icon {
//     position: relative;
//     top: 0;
//     right: 0;
//     margin-left: 5px;
//   }
// }
</style>
