<template>
  <div class="std-side-padding upSelling">
    <v-row class="pl-9 mt-12 pt-1">
      <v-col>
        <h2>La tua richiesta è stata inviata!</h2>
      </v-col>
    </v-row>

    <v-container class="pl-12" v-if="isWithTelephone">
      <v-row class="mb-2">
        <v-col class="pa-0">
          <p>
            Verrai ricontattato nella fascia oraria da te indicata dal numero
            <span class="font-weight-bold">{{ callBackNum.contact }}</span
            >.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pl-12" v-if="!isWithTelephone">
      <v-row>
        <v-col class="pa-0">
          <p>Sarà nostra cura contattarti al più presto.</p>
        </v-col>
      </v-row>
    </v-container>

    <v-row align="center" justify="center">
      <v-col cols="3">
        <v-btn
          @click="$router.push({ name: 'home' })"
          class="v-btn--shadowed"
          x-large
          color="primary"
          width="260"
          >Torna alla homepage</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GenErr from '@/js/genericErrors'
import consts from '@/js/constants'
import { mapActions } from 'vuex'

export default {
  name: 'SuccessPage',
  props: {
    phone: { type: String, default: null },
    requestingClient: { type: Object, default: null }
  },
  data() {
    return {
      callBackNum: ''
    }
  },
  computed: {
    isWithTelephone() {
      return !!this.phone
    }
  },
  methods: {
    ...mapActions('interactions', ['getChannelInfo']),
    ...mapActions('analytics', ['trackTapEvent'])
  },
  created() {
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.CALLBACK,
      area: consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
    })
      .then((resp) => {
        this.callBackNum = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  mounted() {
    this.trackTapEvent({
      name: 'commodityUpsell_typ_view',
      params: {
        client_code: this.requestingClient.clientCode,
        client_type: this.requestingClient.clientType
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.successPage {
  h1 {
    color: white;
  }
}
</style>
