<template>
  <v-expansion-panel
    :readonly="triggerStatus.status === 'empty'"
    :class="`panel ${triggerStatus.status}`"
    @click="openUpsellingFV(triggerStatus.status)"
  >
    <AccordionTemplate :triggerStatus="triggerStatus" type="photovoltaic">
      <template v-slot:timeline>
        <div class="d-flex flex-column justify-space-between">
          <div
            :class="`supply__btn ${triggerStatus.status} d-flex align-center justify-space-between`"
            @click="$router.push({ name: 'trackingPhotovoltaic' })"
          >
            <div class="action">
              <div class="type">{{ triggersTimeline.cta }}</div>
              <div class="desc" v-html="triggersTimeline.desc" />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:active>
        <div class="d-flex flex-column justify-space-between">
          <div
            :class="`supply__btn ${triggersDevicesFV.status} d-flex align-center justify-space-between`"
            @click="$emit('openSuppliesDialog', 'photovoltaic', 'dashboardFV')"
          >
            <div class="action">
              <div class="type">{{ triggersDevicesFV.cta }}</div>
              <div class="desc" v-html="triggersDevicesFV.desc" />
            </div>
          </div>
          <div class="d-flex flex-column justify-space-between">
            <div
              class="supply__btn regular d-flex align-center justify-space-between"
              @click="
                $emit('openSuppliesDialog', 'photovoltaic', 'dashboardFV')
              "
            >
              <div class="action">
                <div class="type">FLUSSI ENERGETICI</div>
                <div class="desc">Rendimento dell’impianto</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="supply__advice"
          @click="$emit('openSuppliesDialog', 'photovoltaic', 'dashboardFV')"
        >
          <div class="action">
            <div class="type">Risparmio del tuo impianto</div>
            <div class="desc">
              Qui trovi i benefici economici derivanti dall'utilizzo del tuo
              impianto
            </div>
            <div class="btn">Monitora il tuo risparmio</div>
          </div>
        </div>
      </template>
    </AccordionTemplate>
    <UpsellingPhotovoltaic :upsellingDialogFV.sync="upsellingDialogFV" />
  </v-expansion-panel>
</template>
<script>
import AccordionTemplate from './Template.vue'
import { mapActions } from 'vuex'
import UpsellingPhotovoltaic from '@/components/fotovoltaico/upselling/UpsellingPhotovoltaic'

export default {
  name: 'accordionPhotovoltaic',
  props: [
    'triggerStatus',
    'triggersDisabled',
    'triggersTimeline',
    'triggersPayment',
    'triggersConsumption',
    'triggersDevicesFV'
  ],
  components: {
    AccordionTemplate,
    UpsellingPhotovoltaic
  },
  data() {
    return {
      upsellingDialogFV: false
    }
  },
  computed: {},
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),

    goToDashboard() {
      this.$router.push({
        name: 'dashboardFotovoltaico'
      })
    },
    openUpsellingFV(status) {
      if (status === 'empty') {
        this.trackTapEvent({
          name: 'home_upselling_tap',
          params: { supply_type: 'fotovoltaico' }
        })
        this.upsellingDialogFV = true
        // this.$emit('update:upsellingDialogFV', true)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './accordionStyle.scss';
</style>
