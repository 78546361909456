<template>
  <div class="">
    <v-row
      v-if="client.clientOwnerType === 'RES'"
      class="contentTab__data contentTab__data--custom"
      no-gutters
    >
      <v-col>
        <!-- <div class="contentTab__row contentTab__row--custom mb-8" no-gutters>
          <div class="fullName mb-4"><strong>{{ client.firstName }} {{ client.lastName }}</strong></div>
          <h5 class="clientInfo mb-4">Codice cliente: {{ client.clientOwnerCode }}</h5>
          <h5 class="clientInfo">Tipologia: <strong>Residenziale</strong></h5>
        </div> -->
        <h3 class="contentTab__title">Dati intestatario</h3>
        <v-row class="contentTab__row contentTab__row--custom" no-gutters>
          <v-col class="col-4">
            <div class="bordered mr-6">
              <div class="labelName mb-2">NOME</div>
              <div class="BodyL pb-2">{{ client.firstName }}</div>
            </div>
          </v-col>
          <v-col class="col-4">
            <div class="bordered mr-6">
              <div class="labelName mb-2">COGNOME</div>
              <div class="BodyL pb-2">
                {{ client.lastName }}
              </div>
            </div>
          </v-col>
          <v-col class="col-4">
            <div class="bordered mr-4">
              <div class="labelName mb-2">GENERE</div>
              <div class="BodyL pb-2">{{ clientDetail.gender }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row class="contentTab__row" no-gutters>
          <v-col class="col-4">
            <div class="bordered mr-6">
              <div class="labelName mb-2">DATA E COMUNE DI NASCITA</div>
              <div class="BodyL pb-2">
                {{ clientDetail.birthDate }} -
                {{ clientDetail.birthPlace }}
              </div>
            </div>
          </v-col>
          <v-col class="col-4">
            <div class="bordered mr-6">
              <div class="labelName mb-2">CODICE FISCALE</div>
              <div class="BodyL pb-2">{{ clientDetail.fiscalCode }}</div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else class="contentTab__data contentTab__data--custom" no-gutters>
      <v-col>
        <h3 class="contentTab__title">Dati intestatario</h3>
        <v-row class="contentTab__row contentTab__row--custom" no-gutters>
          <v-col class="col-4">
            <div class="bordered mr-6">
              <div class="labelName mb-2">RAGIONE SOCIALE</div>
              <div class="BodyL pb-2">
                {{ $filters.capitalize(clientDetail.businessName) }}
              </div>
            </div>
          </v-col>
          <v-col class="col-4">
            <div class="bordered mr-6">
              <div class="labelName mb-2">PARTITA IVA</div>
              <div class="BodyL pb-2">
                {{ clientDetail.VATNumber || clientDetail.fiscalCode }}
              </div>
            </div>
          </v-col>
          <v-col class="col-4">
            <div class="bordered mr-4">
              <div class="labelName mb-2">SEDE LEGALE</div>
              <div class="BodyL pb-2">
                {{ clientDetail.residentialAddress }},
                {{ clientDetail.zipCode || clientDetail.residentialCAP }}
                {{ clientDetail.residentialCity }}
              </div>
            </div>
          </v-col>
          <v-col
            v-if="
              clientDetail.VATNumber &&
              clientDetail.fiscalCode &&
              clientDetail.VATNumber !== clientDetail.fiscalCode
            "
            class="col-4"
            :class="{ 'mt-8': client.clientOwnerType === 'BUS' }"
          >
            <div class="bordered mr-6">
              <div class="labelName mb-2">CODICE FISCALE</div>
              <div class="BodyL pb-2">{{ clientDetail.fiscalCode }}</div>
            </div>
          </v-col>
        </v-row>
      </v-col></v-row
    >
    <v-row class="contentTab__data contentTab__data--custom" no-gutters>
      <v-col>
        <h3 class="HeadingL mb-7">Dati di contratto</h3>
        <v-row class="contentTab__row" no-gutters>
          <v-col class="col-4">
            <div class="bordered mr-6">
              <div class="labelName mb-2">TELEFONO FISSO</div>
              <div
                v-if="
                  filteredClientDetails.clientOwnerCode &&
                  !filteredClientDetails.clientCodeFV
                "
                class="BodyL pb-2 editableContact"
                @click="$emit('editingContent', 'Telefono Fisso')"
              >
                {{ clientDetail.phone || clientDetail.mobilePhone }}
              </div>
              <div v-else class="BodyL pb-2">
                {{
                  clientDetail.telefonoFisso || clientDetail.telefonoCellulare
                }}
              </div>
            </div>
          </v-col>
          <v-col class="col-4">
            <div class="bordered mr-6">
              <div class="labelName mb-2">TELEFONO CELLULARE</div>
              <div
                v-if="
                  filteredClientDetails.clientOwnerCode &&
                  !filteredClientDetails.clientCodeFV
                "
                class="BodyL pb-2 editableContact"
                @click="$emit('editingContent', 'Telefono Cellulare')"
              >
                {{ clientDetail.mobilePhone }}
              </div>
              <div v-else class="BodyL pb-2">
                {{ clientDetail.telefonoCellulare }}
              </div>
            </div>
          </v-col>
          <v-col class="col-4" v-if="client.clientOwnerType === 'RES'">
            <div class="bordered mr-6">
              <div class="labelName mb-2">INDIRIZZO DI RESIDENZA</div>
              <div
                v-if="
                  filteredClientDetails.clientOwnerCode &&
                  !filteredClientDetails.clientCodeFV
                "
                class="BodyL pb-2 editableContact"
                @click="$emit('editingContent', 'Indirizzo di residenza')"
              >
                {{ clientDetail.residentialAddress }},
                {{ clientDetail.residentialCity }}
              </div>
              <div v-else class="BodyL pb-2">
                {{ clientDetail.indirizzoResidenza }},
                {{ clientDetail.comuneResidenza }}
              </div>
            </div>
          </v-col>
          <v-col
            class="col-4"
            :class="{ 'mt-8': client.clientOwnerType === 'RES' }"
          >
            <div class="bordered mr-6">
              <div class="labelName mb-2">EMAIL DI CONTATTO</div>
              <div
                class="BodyL pb-2 editableContact"
                @click="mailDialog = true"
              >
                {{ clientDetail.contactMail || '' }}
              </div>
              <EditContactMailVue
                :mailDialog.sync="mailDialog"
                :user="clientDetail?.clientCode || undefined"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="contentTab__data contentTab__data--custom"
      no-gutters
      id="checkarea"
    >
      <v-col>
        <v-row>
          <v-col class="col-4 pl-0">
            <div class="mb-6 HeadingL">Informative e consensi</div>
          </v-col>
          <v-col class="col-4">
            <v-progress-circular
              indeterminate
              :size="25"
              class="color--blue"
              v-if="disableRadio"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-row class="contentTab__row mb-6" no-gutters>
          <div class="HeadingsSubtitleM">Informative</div>
        </v-row>
        <v-row class="contentTab__row" no-gutters>
          <v-col class="col-11">
            <v-row>
              <div class="BodyL space-r">Accetto i</div>
              <div
                class="ButtonM-Underline color--blue space-r"
                @click="goToDocument('termsAndConditions')"
                style="cursor: pointer"
              >
                Termini e condizioni*
              </div>
              <div class="BodyL">e prendo visione dell'</div>
              <div
                class="ButtonM-Underline color--blue space-r"
                @click="goToDocument('informativePrivacyWeb')"
                style="cursor: pointer"
              >
                Informativa sulla Privacy*
              </div>
            </v-row>
          </v-col>
          <v-col class="col-1 customSwitch">
            <v-switch
              class="mt-0"
              value
              input-value="true"
              disabled
              inset
            ></v-switch>
          </v-col>
        </v-row>

        <v-row
          class="contentTab__row mb-8"
          no-gutters
          v-if="
            filteredClientDetails.clientOwnerCode &&
            !filteredClientDetails.clientCodeFV
          "
        >
          <v-col class="col-12">
            <v-row>
              <div class="BodyL space-r">
                Ti invitiamo a prendere visione delle informative sul
                trattamento dei dati personali a
              </div>
              <div
                class="ButtonM-Underline color--blue space-r"
                @click="goToDocument('sorgeniaSiteDocuments')"
                style="cursor: pointer"
              >
                questo link.
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="contentTab__row mb-6" no-gutters>
          <span class="HeadingsSubtitleM">Consensi</span>
        </v-row>

        <v-row class="contentTab__row mb-6" no-gutters>
          <v-col class="col-11">
            <v-row class="d-flex">
              <div class="BodyL space-r">
                Consenso per
                <span
                  class="ButtonM-Underline color--blue space-r"
                  @click="goToDocument('informativePrivacyWeb')"
                >
                  ricevere da Sorgenia offerte vantaggiose, informazioni sulle
                  sue iniziative e far sentire la tua voce tramite brevi
                  questionari</span
                >
                <span class="BodyL">[art. 2 lett. e]</span>
              </div>
            </v-row>
          </v-col>
          <v-col class="col-1 mt-2 customSwitch">
            <v-switch
              class="mt-0"
              :input-value="clientDetail.marketingConsentFlag"
              @change="
                $emit('consentChanged', {
                  name: 'flagConsensoMarketing',
                  value: $event
                })
              "
              inset
            ></v-switch>
          </v-col>
        </v-row>

        <v-row class="contentTab__row mb-6" no-gutters>
          <v-col class="col-11">
            <v-row class="flex flex-row">
              <div class="BodyL space-r">
                <span class="BodyL space-r">Consenso per</span>
                <span
                  class="ButtonM-Underline color--blue space-r"
                  @click="goToDocument('informativePrivacyWeb')"
                  >analizzare le tue abitudini di consumo al fine di offrirti
                  prodotti e servizi personalizzati</span
                >
                <span class="BodyL">[art. 2 lett. f]</span>
              </div>
            </v-row>
          </v-col>
          <v-col class="col-1 customSwitch">
            <v-switch
              class="mt-0"
              :input-value="clientDetail.profilingConsentFlag"
              @change="
                $emit('consentChanged', {
                  name: 'flagConsensoProfilazione',
                  value: $event
                })
              "
              inset
            ></v-switch>
          </v-col>
        </v-row>

        <v-row class="contentTab__row mb-5" no-gutters>
          <v-col class="col-11">
            <v-row>
              <div class="BodyL space-r">
                <span class="BodyL space-r">Consenso per</span>
                <span
                  class="ButtonM-Underline color--blue space-r"
                  @click="goToDocument('informativePrivacyWeb')"
                >
                  <span class="underline"
                    >ricevere da soggetti terzi/società partner di Sorgenia
                    offerte vantaggiose</span
                  >
                </span>
                <span class="BodyL">[art. 2 lett. g]</span>
              </div>
            </v-row>
          </v-col>
          <v-col class="col-1 customSwitch">
            <v-switch
              class="mt-0"
              :input-value="clientDetail.thirdPartConsentFlag"
              @change="
                $emit('consentChanged', {
                  name: 'flagConsensoSoggettiTerzi',
                  value: $event
                })
              "
              inset
            ></v-switch>
          </v-col>
        </v-row>

        <v-row
          class="mt-4 align-center"
          v-if="
            filteredClientDetails.clientOwnerCode &&
            !filteredClientDetails.clientCodeFV
          "
        >
          <v-btn
            :rounded="true"
            class="v-btn--shadowed mr-4"
            x-large
            width="154"
            height="54"
            color="primary"
            @click="$emit('submitEdit')"
            >Aggiorna
          </v-btn>
          <v-btn
            :rounded="true"
            x-large
            color="white"
            width="154"
            height="54"
            class="primary--text v-btn--shadowed"
            @click="$emit('detailForClient')"
            >Annulla
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <section class="download-section">
      <v-row
        v-if="
          (numContractsFound && numContractsFound > 0) ||
          Object.keys(contractsChanged).length > 0 ||
          Object.keys(notDigitalContracts).length > 0
        "
        class="contentTab__data contentTab__data--custom pb-0"
        no-gutters
      >
        <v-col>
          <h3 class="contentTab__title mb-0">Contratti</h3>
        </v-col>
      </v-row>
      <div v-for="(item, i) in contractsChanged" :key="i + '_nameChanged'">
        <v-row
          class="contentTab__data contentTab__data--custom pb-0"
          no-gutters
        >
          <v-col>
            <h4 class="mb-4">
              {{ item[0].cdPropostaContratto
              }}<strong class="ml-5">{{
                labelCommodityType(item[0].commodityType)
              }}</strong>
            </h4>
          </v-col>
        </v-row>
        <v-row
          class="contentTab__row contentTab__data--custom pt-0 pb-0"
          no-gutters
        >
          <v-col
            class="col-4"
            :key="i"
            v-for="(subItem, i) in item"
            :id="subItem.cdPunto"
          >
            <a
              @click="(event) => $emit('getContractFileUrl', event, subItem)"
              :title="item.name"
              class="download-button"
            >
              <span> {{ $filters.descFile(subItem) }} </span>
            </a>
          </v-col>
        </v-row>
      </div>
      <div v-for="(item, i) in contracts" :key="i + '_name'">
        <v-row
          class="contentTab__data contentTab__data--custom pb-0"
          no-gutters
        >
          <v-col>
            <h4 class="mb-4">
              {{
                item[0].cdPropostaContratto || item[0].cdPropostaContrattoFibra
              }}<strong class="ml-5">{{
                labelCommodityType(
                  item[0].commodityType,
                  item[0].commodityTypeFibra
                )
              }}</strong>
            </h4>
          </v-col>
        </v-row>
        <v-row
          class="contentTab__row contentTab__data--custom pt-0 pb-0"
          no-gutters
        >
          <v-col
            class="col-4"
            :key="i"
            v-for="(subItem, i) in item"
            :id="subItem.cdPunto"
          >
            <a
              @click="(event) => $emit('getContractFileUrl', event, subItem)"
              :title="item.name"
              class="download-button"
            >
              <span> {{ $filters.descFile(subItem) }} </span>
            </a>
          </v-col>

          <template
            v-if="
              !checkGroupFile(item[0].cdPropostaContratto) &&
              !checkGroupFile(item[0].cdPropostaContrattoFibra)
            "
          >
            <v-col
              class="col-4"
              v-if="showAfterthoughtButton[item[0].cdPropostaContratto]"
            >
              <button
                class="download-button"
                @click="
                  $emit(
                    'openDialogAfterthought',
                    item[0],
                    item[0].commodityType
                  )
                "
              >
                Ripensamento {{ formatCommodityType(item[0].commodityType) }}
              </button>
            </v-col>
            <v-col
              class="col-4"
              v-if="showAfterthoughtButton[item[0].cdPropostaContrattoFibra]"
            >
              <button
                class="download-button"
                @click="
                  $emit(
                    'openDialogAfterthought',
                    item[0],
                    item[0].commodityTypeFibra
                  )
                "
              >
                Ripensamento
                {{ formatCommodityFiberType(item[0].commodityTypeFibra) }}
              </button>
            </v-col>
          </template>
        </v-row>
      </div>

      <div v-for="(item, i) in notDigitalContracts" :key="i + '_nameNoDigital'">
        <v-row
          class="contentTab__data contentTab__data--custom pb-0"
          no-gutters
        >
          <v-col>
            <h4 class="mb-2">
              {{ item[0].codProposta
              }}<strong class="ml-5">{{
                consts.SUPPLY_TYPES[item[0].commodityType.toLowerCase()]
              }}</strong>
            </h4>
          </v-col>
        </v-row>
        <v-row
          class="contentTab__row contentTab__data--custom pt-0 pb-0"
          no-gutters
        >
          <v-col class="col-4" :key="i" v-for="(subItem, i) in item">
            <a
              @click="
                (event) =>
                  $emit('getUrlForFile', event, subItem.url, subItem.id)
              "
              :title="item.name"
              class="download-button"
            >
              <span> {{ subItem.descrizione }} </span>
            </a>
          </v-col>
          <v-col
            class="col-4"
            v-if="showAfterthoughtButton[item[0].codProposta]"
          >
            <button
              class="download-button"
              @click="$emit('openDialogAfterthought', item[0], 'commodity')"
            >
              Ripensamento
              {{ consts.SUPPLY_TYPES[item[0].commodityType.toLowerCase()] }}
            </button>
          </v-col>
        </v-row>
      </div>

      <v-row class="contentTab__data contentTab__data--custom" no-gutters>
        <v-col>
          <h3 class="contentTab__title">Documenti utili</h3>
        </v-col>
      </v-row>
      <v-row
        class="row contentTab__data contentTab__data--custom--link pb-10"
        no-gutters
      >
        <a
          @click="
            (event) =>
              $emit('getDocumentFileUrl', event, {
                id: linkPrivacy,
                document: linkPrivacy
              })
          "
          target="_blank"
          class="color-blue"
        >
          Informativa sul trattamento dei dati personali per MySorgenia
        </a>
      </v-row>
      <v-row class="contentTab__row contentTab__data--custom pt-0" no-gutters>
        <v-col class="col-4" :key="i" v-for="(item, i) in infoDoc">
          <a
            :title="item.name"
            class="download-button"
            @click="(event) => getDocumentFileUrlTrack(event, item)"
          >
            <span> {{ item.name }} </span>
          </a>
        </v-col>
      </v-row>
      <v-row class="contentTab__row contentTab__data--custom">
        <h4 @click="dialogDeleteUser = true" class="deleteUser">
          Elimina il tuo profilo
        </h4>
      </v-row>
      <DeleteUser v-model="dialogDeleteUser" :clientDetail="clientDetail" />
    </section>
  </div>
</template>
<script>
import DeleteUser from '@/components/user/DeleteUser.vue'
import { axiosCMS } from '@/js/axiosInstances.js'
import consts from '@/js/constants'
import { mapActions, mapGetters } from 'vuex'
import EditContactMailVue from '../../components/support/EditContactMail.vue'
import { ENV_CONFIGS } from '../../js/configs'

export default {
  name: 'accountDetail',
  props: [
    'client',
    'clientDetail',
    'infoConsents',
    'numContractsFound',
    'contracts',
    'notDigitalContracts',
    'contractsChangedProp',
    'showAfterthoughtButton',
    'disableRadio'
  ],
  data() {
    return {
      linkPrivacy: '',
      infoDoc: null,
      consts: consts,
      dialogDeleteUser: false,
      mailDialog: false
    }
  },
  computed: {
    ...mapGetters('account', ['filteredClientDetails']),

    contractsChanged() {
      return this.contractsChangedProp
    }
  },
  methods: {
    ...mapActions('account', ['getLinKprivacyConfig']),
    ...mapActions('documents', ['getDocsByCategory']),
    ...mapActions('analytics', ['trackTapEvent']),
    closeDeleteUser() {
      this.dialogDeleteUser = false
    },
    goToDocument(id) {
      switch (id) {
        case 'termsAndConditions':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.termsAndConditions)
          break
        case 'informativePrivacyWeb':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.informativePrivacyWeb)
          break
        case 'generalClientsInformative':
          // this.trackTapEvent({ name: 'account_informativeLink_tap' })
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.generalClientsInformative)
          break
        case 'optionalConsents':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.optionalConsents)
          break
        case 'sorgeniaSiteDocuments':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.sorgeniaSiteDocuments)
          break
      }
    },
    checkGroupFile(contractId) {
      return (
        this.contracts[contractId] &&
        this.contracts[contractId].some(
          (item) => item.groupFile === 'Nuove condizioni di contratto'
        )
      )
    },
    formatCommodityType(commType) {
      let label = ''
      switch (commType || '') {
        case 'gas':
          label = 'Gas'
          break
        case 'ele':
          label = 'Luce'
          break
        case 'dual':
          label = 'Luce - Gas'
          break
        default:
          label = ''
          break
      }
      return label
    },
    formatCommodityFiberType(commTypeFibra) {
      let label = ''
      switch (commTypeFibra || '') {
        case 'FIBRA':
          label = 'Fiber'
          break
        default:
          label = ''
          break
      }
      return label
    },
    labelCommodityType(commType, commTypeFibra) {
      const labelComm = this.formatCommodityType(commType)
      const labelFiber = this.formatCommodityFiberType(commTypeFibra)

      return labelComm + (labelComm && labelFiber ? ', ' : '') + labelFiber
    },
    getDocumentFileUrlTrack(event, item) {
      this.$emit('getDocumentFileUrl', event, item)
      this.trackTapEvent({
        name: 'account_usefulDocs_open',
        params: {
          document_id: item.id,
          document_name: item.name
        }
      })
    }
  },
  async created() {
    this.infoDoc = await this.getDocsByCategory('documenti-utili')
    this.linkPrivacy = await this.getLinKprivacyConfig()
  },
  components: {
    DeleteUser,
    EditContactMailVue
  }
}
</script>

<style lang="scss">
#checkarea {
  i.v-icon {
    font-size: 32px;
  }
}
</style>
<style lang="scss" scoped>
.labelName {
  font-family: Roboto;
  color: #6e7689;
  font-size: 14px;
  letter-spacing: 1.2px;
  font-weight: 700;
  line-height: 16.8px;
}
.customSwitch {
  display: flex;
  justify-content: flex-end;
}

.editableContact {
  color: #12256a;
  cursor: pointer;
}

.contentTab__data--custom {
  padding: 0 0px;
  padding-top: 32px;
  padding-bottom: 32px;

  &--link {
    padding: 0 49px;
    font-weight: bold;
  }

  .space-r {
    margin-right: 5px;
  }

  .contentTab__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    margin-bottom: 32px;
    letter-spacing: 0.48px;
    color: black;
  }

  h6 {
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 4px;
    letter-spacing: 1px;
  }

  h5 {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: black;
    letter-spacing: 1px;

    &.clientInfo {
      font-weight: normal;
    }

    &.semiBold {
      font-weight: 500;
      letter-spacing: 1.2px;
    }
  }

  .ButtonM-Underline {
    cursor: pointer;
  }
  .HeadingL {
    color: black;
  }

  h4 {
    font-size: 20px;
    color: black;
    letter-spacing: 1px;
  }

  .deleteUser {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.8px;
    color: $srg-blue;
    cursor: pointer;

    &::after {
      content: url('../../assets/icons/delete.svg');
      margin-left: 11px;
    }
  }
}

.contentTab__row--custom {
  padding-bottom: 30px;
}

.fullName {
  font-size: 20px;
  letter-spacing: 1px;
}
</style>
