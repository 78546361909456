<template>
  <v-dialog v-model="dialog" width="558" persistent>
    <div class="physicall">
      <div class="d-flex justify-space-between align-center mb-10">
        <div class="physicall__title HeadingsSubtitleL">
          Possiamo fare qualcosa per te?
        </div>
        <img
          class="physicall__close"
          src="../../assets/icons/close-blu.svg"
          @click="close"
        />
      </div>
      <div class="BodyL mb-8 physicall__desc">
        Se hai bisogno di aiuto, puoi chiedere di parlare con uno dei nostri
        consulenti del Servizio Clienti. Prenota qui sotto un
        <strong>contatto immediato</strong>: ti chiameremo entro pochi minuti
        sempre qui nell’Area Clienti!
      </div>
      <div class="infoMessage">
        <img src="../../assets/icons/info.svg" width="20px" />
        <div class="BodyL">
          Riceverai una videochiamata <strong>entro pochi minuti</strong>
        </div>
      </div>
      <div class="d-flex flex-column align-center">
        <v-btn @click="reserve" width="237" height="54" class="primary mb-4"
          >Si, contattatemi</v-btn
        >
        <v-btn @click="close" width="230" height="54" color="white"
          >Non ora, grazie</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { eventBus } from '@/main'
import { mapActions, mapMutations } from 'vuex'
import { AlertService } from '../../js/service/alertService'

export default {
  name: 'physicallDialog',
  props: ['physicallObj', 'triggerElement', 'triggerAction'],
  data() {
    return {
      dialog: false,
      eventType: ''
    }
  },
  watch: {
    dialog(val) {
      if (!val && this.eventType === 'eventClick') {
        if (this.triggerElement instanceof Object) {
          this.triggerElement.target.click()
        }
        if (typeof this.triggerAction === 'function') {
          this.triggerAction()
        }
      } else {
        this.physicallObj.clearTimer()
      }
    }
  },
  created() {
    eventBus.$on('open:physicallDialog', (eventType) => {
      this.dialog = true
      this.eventType = eventType
      this.trackTapEvent({
        name: 'videocall_booking_viewed',
        params: {
          source: this.physicallObj.getTrackJourney()
        }
      })
    })
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    eventBus.$off('open:physicallDialog')
  },
  beforeRouteLeave(to, from, next) {
    eventBus.$off('open:physicallDialog')
    this.physicallObj.clearTimer()
    next()
  },
  methods: {
    ...mapActions('session', ['sendInfoKaleyra']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('session', ['setFeedbackShowing']),
    close() {
      this.dialog = false
    },
    reserve() {
      this.close()
      this.setFeedbackShowing(true)
      localStorage.setItem('videoCallBooked', true)
      this.sendInfoKaleyra(this.physicallObj.getJourney()).then((resp) => {
        if (resp.status === 'OK') {
          eventBus.$emit('open:feedbackDialog', this.eventType)
          this.physicallObj.reserve()
          this.trackTapEvent({
            name: 'videocall_call_booked',
            params: {
              source: this.physicallObj.getTrackJourney()
            }
          })
        } else {
          AlertService(
            ' Servizio non disponibile',
            'Al momento non è possibile connettersi al server. Ti invitiamo a riprovare.',
            'alert',
            'Torna ai Servizi',
            false,
            false,
            false,
            false,
            false
          )
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.physicall {
  background: white;
  padding: 40px;
  &__close {
    cursor: pointer;
  }
  &__desc {
    color: $neutri-neutral-60;
  }
  .checkbox {
    display: flex;
    color: $neutri-neutral-60;
    cursor: pointer;
    font-weight: normal;
    &.selected {
      &:before {
        content: url(~@/assets/icons/checkbox_active.svg);
      }
    }
    &:before {
      content: url(~@/assets/icons/checkbox.svg);
      margin-top: -5px;
    }
  }
  a {
    font-weight: 500;
    letter-spacing: 0.8px;
    text-decoration: underline;
    &:hover,
    &:visited {
      color: $secondari-blue-100;
    }
  }
  .infoMessage {
    display: flex;
    width: 478px;
    height: 56px;
    border-radius: 16px;
    padding: 16px;
    gap: 8px;
    background-color: $neutri-neutral-20;
    align-items: center;
    margin-bottom: 40px;
    img {
      margin-right: 2px;
    }
  }
}
</style>
