<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="945">
    <v-row>
      <v-col class="help-dialog">
        <img @click="closeDialog" src="../../assets/ic_chevron_top_black.png" />
        <h2>Vuoi parlare con un operatore?</h2>
        <div class="help-dialog__text--small">
          Scegli il canale che preferisci per ricevere assistenza subito.
        </div>
        <div v-if="landlineOpen" class="help-dialog__box">
          <div class="help-dialog__text--small mb-2">
            <strong>Chiama</strong>
          </div>
          <h5>Chiamaci gratis al numero</h5>
          <h5 class="color--blue">
            <strong>{{
              $filters.formatNumberGreen(landLineData.contact)
            }}</strong>
          </h5>
        </div>
        <div class="help-dialog__box big">
          <div class="help-dialog__text--small mb-4">
            <strong>Richiamaci</strong>
          </div>
          <v-row>
            <v-col cols="4" class="pa-0">
              <v-text-field
                class="help__input"
                type="number"
                v-model="phone"
                :error-messages="phoneErrors"
                placeholder="numero di telefono"
                persistent-placeholder
                @blur="v$.phone.$touch()"
                label="Dove possiamo chiamarti?"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="offset-2 pa-0">
              <h5><strong>Quando vuoi essere chiamato?</strong></h5>
              <h5>
                Il call center è aperto dal lun-ven
                {{ landLineData.monday_friday_start }}-{{
                  landLineData.monday_friday_end
                }}
                /<br />sab-dom {{ landLineData.saturday_start }}-{{
                  landLineData.saturday_end
                }}
              </h5>
              <div>
                <v-radio-group row="row" mandatory v-model="times">
                  <div class="help-dialog__radio mb-3">
                    <v-radio
                      value="indifferente"
                      label="Indifferente"
                    ></v-radio>
                    <v-radio value="mattina" label="Mattina"></v-radio>
                  </div>
                  <div class="help-dialog__radio">
                    <v-radio value="pomeriggio" label="Pomeriggio"></v-radio>
                    <v-radio value="sera" label="Sera"></v-radio>
                  </div>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <div class="mt-5 d-flex align-center justify-center">
            <v-btn
              x-large
              class="white--text auth__btn v-btn--shadowed"
              color="#12256A"
              :rounded="true"
              :disabled="v$.$invalid"
              @click="reserve"
            >
              Richiamami
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'CallMeBackDialogNew',
  props: ['value', 'landlineOpen', 'fromPage', 'requestingClient', 'step'],
  data() {
    return {
      landLineData: {},
      clientCode: null,
      clientType: null,
      times: null,
      phone: ''
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails']),
    phoneErrors() {
      let errArray = []

      if (!this.v$.phone.$dirty) return errArray

      this.v$.phone.minLength.$invalid === true &&
        errArray.push('Il numero di telefono deve essere di almeno 6 cifre')
      this.v$.phone.maxLength.$invalid === true &&
        errArray.push('Il numero di telefono può avere al massimo 12 cifre')
      this.v$.phone.required.$invalid === true &&
        errArray.push('Il Numero di telefono è obbligatorio')

      return errArray
    }
  },
  watch: {
    clientDetails(clientDetails) {
      this.phone = (
        clientDetails.mobilePhone ||
        clientDetails.phone ||
        ''
      ).replace(/\s/g, '')
    }
  },
  methods: {
    ...mapActions('account', ['getMainClient']),
    ...mapActions('interactions', { callMeBack: 'callMeBack' }),
    ...mapActions('interactions', ['getChannelInfo']),
    ...mapActions('analytics', ['trackTapEvent']),
    reserve() {
      if (this.fromPage === 'fiber') {
        this.trackTapEvent({
          name: 'fiberUpsell_bookContact_tap',
          params: {
            client_code: this.requestingClient.clientCode,
            funnel_page: this.step
          }
        })
      } else {
        this.trackTapEvent({
          name: 'commodityUpsell_bookContact_tap',
          params: {
            client_code: this.requestingClient.clientCode,
            client_type: this.requestingClient.clientType
          }
        })
      }

      let landLineData = {
        clientCode: this.clientCode,
        commodity: this.fromPage,
        phone: this.phone,
        times: this.times,
        email: '',
        notes: this.setNotes()
      }
      this.callMeBack(landLineData)
        .then((resp) => {
          this.$router.push({ name: 'fiberRequestSent' })
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    },
    closeDialog() {
      this.$emit('input')
    },
    setNotes() {
      if (this.fromPage === 'fiber') {
        return 'Fibra- Info per Upselling'
      }
      if (this.fromPage === 'luce') {
        return 'Luce - Info per Upselling'
      } else {
        return 'Gas - Info per Upselling'
      }
    }
  },
  created() {
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area:
        this.fromPage !== 'fiber'
          ? consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
          : consts.CALLCENTER_AREAS.UPSELLING_FIBER
    })
      .then((resp) => {
        this.landLineData = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })

    this.getMainClient(this.$store.getters['session/username'])
      .then((resp) => {
        this.clientCode = resp.clientOwnerCode
        this.clientType = resp.clientOwnerType
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  validations: {
    times: { required },
    phone: { required, minLength: minLength(6), maxLength: maxLength(12) }
  }
}
</script>
<style lang="scss">
.help__input {
  .v-label {
    transform: translateY(-30px) scale(1) !important;
    -webkit-transform: translateY(-30px) scale(1) !important;
  }
}
</style>
<style lang="scss" scoped>
.v-dialog__content {
  .help-dialog {
    background: white;
    padding: 32px 48px 48px 48px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    &__text {
      font-size: 22px;
      font-weight: 300;
      line-height: 30px;
      &--small {
        font-size: 20px;
      }
    }
    &__num {
      font-weight: bold;
    }
    &__chat {
      color: $srg-blue;
      font-weight: bold;
      cursor: pointer;
    }
    &__box {
      width: 267px;
      height: 132px;
      padding: 24px 24px 16px 24px;
      border-radius: 20px;
      background: #f6f6f6;
      margin-top: 20px;
      &.big {
        width: 100%;
        height: 350px;
      }
    }
    &__radio {
      width: 100%;
      display: flex;
      align-items: center;
      div {
        width: 50%;
      }
    }
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
    .help__input {
      margin-top: 14px;
      .v-label {
        color: black;
      }
    }
    button {
      width: 264px;
    }
  }
}
</style>
