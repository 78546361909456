<template>
  <v-dialog :value="dialog" max-width="946" :persistent="true">
    <div class="onboarding">
      <div :class="`page start ${index === 0 ? 'active' : ''}`">
        <div class="d-flex justify-center mb-8">
          <img src="../../assets/my-sorgenia-logo-white.png" width="200" />
        </div>
        <div class="desc">
          Insieme ai clienti Sorgenia come te, abbiamo progettato<br />una nuova
          esperienza per l'Area Personale MySorgenia.
        </div>
        <div class="d-flex justify-center">
          <v-btn
            class="brightGreen"
            width="164"
            height="54"
            @click="
              index =
                clientDetails?.tgTurnedMLFlag == true ? index + 1 : index + 2
            "
            >Inizia</v-btn
          >
        </div>
      </div>
      <div
        :class="`page items ${index === 1 ? 'active' : ''}`"
        v-if="clientDetails?.tgTurnedMLFlag == true"
      >
        <div class="d-flex align-center justify-space-between mb-8">
          <img src="../../assets/icons/my-sorgenia-logo.svg" width="200" />
        </div>
        <div class="main-container-onboarding">
          <div class="text small">
            <template></template>
            <h2>Una scelta che vale</h2>
            <div class="BodyXL mb-6 flex-grow-1">
              Vogliamo congratularci con te per aver scelto la sostenibilità e
              la semplicità dei nostri servizi del Mercato Libero. <br />
              Ti presentiamo i servizi che avrai a disposizione da oggi.
            </div>
            <div class="d-flex align-center justify-space-between">
              <v-btn
                @click="index = index - 1"
                color="white"
                height="54"
                width="167"
                >Indietro</v-btn
              >
              <v-btn
                @click="
                  trackTapEvent('onboarding_next_tap'), (index = index + 1)
                "
                class="brightGreen ml-4"
                width="146"
                height="54"
                >Avanti</v-btn
              >
            </div>
          </div>
          <div class="image">
            <img
              src="../../assets/onboarding/tuteleGraduate.png"
              width="490"
              class="pt-9"
            />
          </div>
        </div>
      </div>
      <div :class="`page items ${index === 2 ? 'active' : ''}`">
        <div class="d-flex align-center justify-space-between mb-8">
          <img src="../../assets/icons/my-sorgenia-logo.svg" width="200" />
          <div @click="setOnboardingStorage('salta')" class="close">Salta</div>
        </div>
        <div class="main-container-onboarding">
          <div class="text small">
            <template></template>
            <h2 v-if="clientDetails?.tgTurnedMLFlag == true">
              Le tue utenze,<br />in uno sguardo
            </h2>
            <h2 v-else>I tuoi servizi,<br />in uno sguardo</h2>
            <div
              class="BodyXL mb-6 flex-grow-1"
              v-if="clientDetails?.tgTurnedMLFlag == true"
            >
              Puoi avere tutti i vantaggi di una gestione unica delle utenze
              della tua casa e della tua attività. Monitoraggio delle utenze,
              dei pagamenti e consumi in primo piano.
            </div>
            <div class="BodyXL mb-6 flex-grow-1" v-else>
              In primo piano la gestione delle tue utenze: consumi, pagamenti e
              la possibilità di configurare il tuo impianto fotovoltaico.
            </div>
            <div class="d-flex align-center justify-space-between">
              <v-btn
                @click="
                  index =
                    clientDetails?.tgTurnedMLFlag == true
                      ? index - 1
                      : index - 2
                "
                color="white"
                height="54"
                width="167"
                >Indietro</v-btn
              >
              <v-btn
                @click="
                  trackTapEvent('onboarding_next_tap'), (index = index + 1)
                "
                class="brightGreen ml-4"
                width="146"
                height="54"
                >Avanti</v-btn
              >
            </div>
          </div>
          <div class="image">
            <img
              v-if="clientDetails?.tgTurnedMLFlag == true"
              src="../../assets/onboarding/triggers.png"
              width="490"
              class="pt-9"
            />
            <img
              v-else
              src="../../assets/onboarding/trigger_photovoltaic.svg"
              width="490"
              class="pt-9"
            />
          </div>
        </div>
      </div>
      <div :class="`page items ${index === 3 ? 'active' : ''}`">
        <div class="d-flex align-center justify-space-between mb-8">
          <img src="../../assets/icons/my-sorgenia-logo.svg" width="200" />
          <div
            @click="trackTapEvent('onboarding_skip_tap'), $emit('close')"
            class="close"
          >
            Salta
          </div>
        </div>
        <div class="main-container-onboarding">
          <div class="text">
            <h2 v-if="clientDetails?.tgTurnedMLFlag == true">
              Ora sei un Greeners
            </h2>
            <h2 v-else>Benvenuto in Greeners</h2>
            <div
              class="BodyXL mb-6 flex-grow-1"
              v-if="clientDetails?.tgTurnedMLFlag == true"
            >
              Benvenuto in Greeners, la <br />
              community di Sorgenia unita per <br />
              aiutare il pianeta!
              <br />
              Le tue azioni sono importanti<br />
              e insieme moltiplichiamo il <br />
              risultato.
            </div>
            <div class="BodyXL mb-6 flex-grow-1" v-else>
              La community di Sorgenia unita per aiutare il Pianeta! Le tue
              azioni sono importanti e insieme moltiplichiamo il risultato!
            </div>
            <div class="d-flex align-center justify-space-between">
              <v-btn
                @click="index = index - 1"
                color="white"
                height="54"
                width="167"
                >Indietro</v-btn
              >
              <v-btn
                @click="
                  trackTapEvent('onboarding_next_tap'), (index = index + 1)
                "
                class="brightGreen ml-4"
                width="146"
                height="54"
                >Avanti</v-btn
              >
            </div>
          </div>
          <div class="image">
            <img
              src="../../assets/onboarding/greeners.svg"
              width="490"
              class="pt-9"
            />
          </div>
        </div>
      </div>
      <!-- <div :class="`page items ${index === 2 ? 'active' : '' }`">
        <div class="d-flex align-center justify-space-between">
          <img src="../../assets/icons/my-sorgenia-logo.svg" width="200"/>
          <div @click="trackTapEvent('onboarding_skip_tap'); $emit('close')" class="close">Salta</div>
        </div>
        <div class="main-container-onboarding">
          <div class="text">
            <h2>Non perdere<br/>occasioni e novità</h2>
            <div class="desc mb-6">Accedi ai privilegi esclusivi del programma fedeltà. Segui notizie, eventi e iniziative dal mondo Sorgenia.</div>
            <div class="d-flex align-center justify-space-between">
              <v-btn @click="index = index - 1" color="white" height="54" width="167" v-text="`Indietro`"/>
              <v-btn @click="trackTapEvent('onboarding_next_tap'); index = index + 1" class="brightGreen ml-4" width="146" height="54" v-text="`Avanti`"/>
            </div>
          </div>
          <div class="image">
            <img src="../../assets/onboarding/cards.png" width="473" class="pt-12"/>
          </div>
        </div>
      </div> -->
      <div :class="`page items ${index === 4 ? 'active' : ''}`">
        <div class="d-flex align-center justify-space-between mb-8">
          <img src="../../assets/icons/my-sorgenia-logo.svg" width="200" />
        </div>
        <div class="main-container-onboarding">
          <div class="text small">
            <h2 v-if="clientDetails?.tgTurnedMLFlag == true">
              Tutto nelle tue <br />mani
            </h2>
            <h2 v-else>Ciò che cerchi, in<br />bella vista</h2>
            <div
              class="BodyXL mb-6 flex-grow-1"
              v-if="clientDetails?.tgTurnedMLFlag == true"
            >
              Informazioni e novità sul mondo di Sorgenia a portata di mano:
              scopri tutte le iniziative, le notizie e i servizi aggiuntivi
              legati all’energia e alla sostenibilità.
            </div>
            <div class="BodyXL mb-6 flex-grow-1" v-else>
              Abbiamo fatto ordine: con il nuovo menu arrivi alle informazioni
              di cui hai bisogno senza fatica.
            </div>
            <div class="d-flex align-center justify-space-between">
              <v-btn
                @click="index = index - 1"
                color="white"
                height="54"
                width="167"
                >Indietro</v-btn
              >
              <v-btn
                @click="setOnboardingStorage('entra')"
                class="brightGreen ml-4"
                width="146"
                height="54"
                >Entra</v-btn
              >
            </div>
          </div>
          <div class="image">
            <img
              v-if="clientDetails?.tgTurnedMLFlag == true"
              src="../../assets/onboarding/menu.png"
              width="490"
              class="pt-9"
            />
            <img
              v-else
              src="../../assets/onboarding/new_menu.png"
              width="490"
              class="pt-9"
            />
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'onboardingHome',
  props: ['dialog'],
  data() {
    return {
      index: 0
    }
  },
  watch: {
    index(val) {
      this.trackTapEvent({
        name: 'onboarding_page_show',
        params: { page_position: val }
      })
    },
    dialog(val) {
      if (val) {
        this.trackTapEvent({
          name: 'onboarding_page_show',
          params: { page_position: 0 }
        })
      }
    }
  },
  computed: {
    ...mapGetters('account', ['mainClient', 'clientDetails'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('account', ['retrieveClients']),
    closeModal() {
      this.trackTapEvent('onboarding_skip_tap')
      this.$emit('close')
    },
    setOnboardingStorage(type) {
      if (type === 'entra') this.trackTapEvent('onboarding_completed')
      let onboarding = JSON.parse(localStorage.getItem('onboarding')) || {}
      if (type === 'entra') {
        onboarding[this.mainClient.clientOwnerCode] = true
      } else {
        onboarding[this.mainClient.clientOwnerCode] = false
      }
      localStorage.setItem('onboarding', JSON.stringify(onboarding))
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.onboarding {
  position: relative;
  height: 521px;
  border-radius: 20px;
  overflow: hidden;
  background: white;

  .close {
    cursor: pointer;
  }
  .image {
    display: flex;
  }

  .main-container-onboarding {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
  }
  .page {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 48px 48px 0 48px;
    opacity: 0;
    z-index: 1;
    &.active {
      opacity: 1;
      z-index: 2;
    }
    &.start {
      background-image: url('../../assets/onboarding/start.png');
      background-size: cover;
      .desc {
        margin-top: 96px;
        margin-bottom: 48px;
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.48px;
        line-height: 1.2;
        color: white;
      }
    }
    &.items {
      //  justify-content: space-between;
      .close {
        width: 79px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1.2px;
        color: $srg-blue;
      }
      .text {
        width: 327px;
        padding-bottom: 48px;
        height: 100%;
        display: flex;
        flex-direction: column;
        &.small {
          width: 304px;
        }
        h2 {
          font-weight: bold;
          letter-spacing: 1.2px;
          line-height: 1.3;
          margin-bottom: 16px;
        }
        .desc {
          font-size: 20px;
          color: black;
          letter-spacing: 0.5px;
          line-height: 1.4;
        }
      }
    }
  }
}
</style>
