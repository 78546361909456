<template>
  <div class="supplier">
    <v-autocomplete
      :items="providerFilterd"
      item-text="SOCIETA"
      label="Attuale fornitore"
      v-model="supplier"
      @blur="v$.supplier.$touch()"
      :error-messages="errorSupplier"
      return-object
    ></v-autocomplete>
    <div class="supplier__box">
      <div class="title">Perchè te lo chiediamo</div>
      <h5 class="desc">
        Selezionando il tuo precedente fornitore possiamo indicarti dove trovare
        i dati che ti verranno richiesti per la stima all'interno della
        bolletta.<br />
        Non preoccuparti, useremo i tuoi dati solo per farti avere una stima dei
        costi.
      </h5>
    </div>
    <MYSButton
      width="260"
      buttonClass="primaryButton"
      :disabled="v$.supplier.$invalid"
      @buttonClick="next"
      buttonText="Continua"
    />
  </div>
</template>
<script>
import { PROVIDERS } from '@/js/constants'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapGetters, mapMutations } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'currentSupplier',
  components: { MYSButton },
  data() {
    return {
      providers: PROVIDERS,
      supplier: null
    }
  },
  watch: {
    'v$.supplier.$error': function (val) {
      val
        ? this.setValidationErrors('SUPPLIER_INVALID')
        : this.deleteValidationError('SUPPLIER_INVALID')
    }
  },
  computed: {
    ...mapGetters('upsellingComm', ['supplyDetail', 'commodityType']),
    errorSupplier() {
      let errArray = []

      if (!this.v$.supplier.$dirty) return errArray
      this.v$.supplier.required.$invalid === true &&
        errArray.push('Seleziona fornitore.')

      return errArray
    },
    providerFilterd() {
      return this.providers.filter((p) => {
        if (this.commodityType === 'ele') {
          return p.FORNITORE_EN === 'S'
        } else {
          return p.FORNITORE_GAS === 'S'
        }
      })
    }
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'setSupplyDetailRoot',
      'setValidationErrors',
      'deleteValidationError'
    ]),
    next() {
      this.setSupplyDetailRoot({ oldProvider: this.supplier })
      this.$emit('nextStep', 'upsellingcom_supplydetail_open')
    }
  },
  created() {
    if (this.supplyDetail.oldProvider) {
      this.supplier = this.supplyDetail.oldProvider
    }
  },
  validations: {
    supplier: { required }
  }
}
</script>
<style lang="scss" scoped>
.supplier {
  max-width: 652px;
  color: black;
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
</style>
