<template>
  <div>
    <TabLayout v-if="client && currentSlide">
      <div class="clean mt-12 w-100">
        <v-row no-gutters>
          <h2 class="mb-6" sm="6">
            <strong>La nostra energia è pulita</strong>
          </h2>
        </v-row>
        <v-row no-gutters class="mb-12">
          <v-col md="6" sm="12" cols="12">
            <h5 class="mb-6">
              Produrre energia a partire dal sole, dal vento, dall'acqua
              <strong>abbatte l’emissione nell’aria di gas inquinante.</strong>
            </h5>
            <h5 class="mb-6">
              In più, se gli impianti di generazione di energia rinnovabile sono
              installati vicino ai luoghi di consumo, c’è l’aumento
              dell’efficienza del sistema elettrico, perché il tragitto che
              l’energia deve compiere è più breve e le perditedi rete minori.
            </h5>
            <h5 :class="isSmallDevice ? 'mb-12' : 'mb-6'">
              L’energia da fonti rinnovabili per i nostri clienti business non
              avrà più un costo aggiuntivo e non sarà più un’opzione: tutti i
              nostri clienti business ora ne beneficiano
              <strong>gratuitamente.</strong>
            </h5>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <v-img
              :class="isSmallDevice ? 'mb-5' : 'pr-4'"
              contain
              src="@assets/welcome/banner-energia-pulita.png"
            />
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <v-img
              :class="isSmallDevice ? 'mb-5' : 'pl-4'"
              contain
              src="@assets/welcome/banner-2-energia-pulita-regalo.png"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-12">
          <h3 class="mb-6"><strong>Il percorso dell’energia pulita</strong></h3>
          <h5 class="mb-6">
            Il produttore di energia rinnovabile la immette nella rete di
            trasmissione. Per ogni unità (MWh) prodotta, riceve un Certificato
            di garanzia d’origine dal Gestore dei servizi energetici (GSE).
          </h5>
          <h5>
            Per garantire ai propri clienti una fornitura coperta esclusivamente
            da fonti rinnovabili, un fornitore acquista i Certificati d’origine
            necessari a coprire l’intero consumo del proprio cliente.
          </h5>
        </v-row>
        <v-row no-gutters>
          <h3 class="mb-6">
            <strong>Ecco le fasi del percorso e gli attori coinvolti</strong>
          </h3>
        </v-row>
        <v-row no-gutters class="mb-12">
          <v-col md="3" sm="3" cols="12">
            <div class="info-card">
              <v-img
                class="mb-6"
                contain
                :src="
                  isSmallDevice
                    ? require('@assets/welcome/phProduzioneMob.png')
                    : require('@assets/welcome/ph-produzione-web.png')
                "
              />
              <h4 class="mb-4"><strong>Produzione</strong></h4>
              <h5>
                L’energia rinnovabile, quindi pulita, è generata da fonti quali
                sole, vento, acqua e biomasse, certificata e immessa nella rete.
              </h5>
            </div>
          </v-col>
          <v-col md="3" sm="3" cols="12">
            <div class="info-card">
              <v-img
                class="mb-6"
                contain
                :src="
                  isSmallDevice
                    ? require('@assets/welcome/phTrasportoMob.png')
                    : require('@assets/welcome/ph-trasporto-web.png')
                "
              />
              <h4 class="mb-4"><strong>Trasporto</strong></h4>
              <h5>
                L’energia verde viene trasportata in rete fino alle porte del
                paese o della città, insieme all’altra elettricità disponibile
                in quel momento.
              </h5>
            </div>
          </v-col>
          <v-col md="3" sm="3" cols="12">
            <div class="info-card">
              <v-img
                class="mb-6"
                contain
                :src="
                  isSmallDevice
                    ? require('@assets/welcome/phDistribuzioneMob.png')
                    : require('@assets/welcome/ph-distribuzione-web.png')
                "
              />
              <h4 class="mb-4"><strong>Distribuzione</strong></h4>
              <h5>
                Il distributore locale trasporta e consegna l’energia al
                consumatore ed è il proprietario del contatore e il responsabile
                per la risoluzione dei guasti alla rete.
              </h5>
            </div>
          </v-col>
          <v-col md="3" sm="3" cols="12">
            <div class="info-card">
              <v-img
                class="mb-6"
                contain
                :src="
                  isSmallDevice
                    ? require('@assets/welcome/phFornituraMob.png')
                    : require('@assets/welcome/ph-fornitura-web.png')
                "
              />
              <h4 class="mb-4"><strong>Fornitura</strong></h4>
              <h5>
                Sorgenia garantisce la fornitura di energia rinnovabile al
                cliente “passando” i certificati di origine del produttore di
                energia verde: significa che le “quote” di energia rinnovabile
                immesse in rete non potranno più essere utilizzate e consumate
                da altri soggetti.
              </h5>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="reverseBtn">
          <v-col>
            <v-btn
              @click="
                $router.push(back()),
                  trackTapEvent({
                    name: 'welcomeKit_continue_tap',
                    params: {
                      section_title: prevSlideTitle,
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
              "
              x-large
              color="white"
              class="v-btn--shadowed mb-8 mx-auto"
              >{{ prevSlideTitle }}</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              @click="
                $router.push(next()),
                  trackTapEvent({
                    name: 'welcomeKit_continue_tap',
                    params: {
                      section_title: nextSlideTitle,
                      client_code: client.clientOwnerCode,
                      client_type: client.clientOwnerType,
                      wk_type: client.type
                    },
                    toNative: isMobile
                  })
              "
              x-large
              color="brightGreen"
              class="v-btn--shadowed mb-8 mx-auto"
              v-show="hasNextSlide"
              >{{ nextSlideTitle }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col
          md="6"
          :offset="
            isSmallDevice
              ? 0
              : currentSlide && currentSlide.show_feedback == 1
              ? 0
              : 3
          "
          v-if="currentSlide.show_tutorial == 1 && tutorial"
        >
          <div>
            <InstrumentItem
              title="Tutorial"
              icon="tutorial.svg"
              text="Abbiamo creato una serie di guide navigabili per migliorare la tua esperienza in MySorgenia!"
              link="Vai ai Tutorial"
              @click="
                trackTapEvent({
                  name: 'welcomeKit_tutorial_tap',
                  params: {
                    client_code: client.clientOwnerCode,
                    client_type: client.clientOwnerType
                  },
                  toNative: isMobile
                })
              "
              :routeName="isMobile ? 'publicTutorial' : 'tutorial'"
            />
          </div>
        </v-col>
        <v-col
          md="6"
          :offset="
            isSmallDevice
              ? 0
              : currentSlide && currentSlide.show_tutorial == 1 && tutorial
              ? 0
              : 3
          "
          v-if="currentSlide.show_feedback == 1"
        >
          <div>
            <InstrumentItem
              title="Feedback"
              icon="feedback.svg"
              text="La tua opinione è un prezioso contributo. Dedicaci 3 minuti  rispondendo a poche domande!"
              link="Dicci la tua"
              @click="
                trackTapEvent({
                  name: 'welcomeKit_feeback_tap',
                  params: {
                    client_code: client.clientOwnerCode,
                    client_type: client.clientOwnerType
                  },
                  toNative: isMobile
                })
              "
              :routeName="isMobile ? 'publicFeedback' : 'feedback'"
            />
          </div>
        </v-col>
      </v-row>
    </TabLayout>
    <TutorialOverlay
      :overlay.sync="overlay"
      v-if="currentSlide && currentSlide.show_feedback_overlay == 1 && overlay"
      :isMobile="isMobile"
    />
  </div>
</template>
<script>
import {
  getKitSlideRouteName,
  isOnMobileDeviceGetPlatform
} from '@/js/utils.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'cleanEnergyNew',
  data() {
    return {
      currentSlideId: 'cleanEnergyNew',
      isMobile: isOnMobileDeviceGetPlatform(),
      isSmallDevice: window.innerWidth <= 480,
      isBigDevice: window.innerWidth > 480 && window.innerWidth <= 1024,
      isDesktop: window.innerWidth > 1024,
      overlay: false
    }
  },
  components: {
    TabLayout: () => import('@/views/welcome/TabLayout'),
    InstrumentItem: () => import('@/components/support/InstrumentItem'),
    TutorialOverlay: () => import('@/views/welcome/TutorialOverlay')
  },
  computed: {
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['wkSlidesForUser', 'welcomekit', 'tutorial']),
    currentIndex() {
      var slides = this.wkSlidesForUser || []
      return slides.findIndex(
        (slide) => slide.template_name === 'cleanEnergyNew'
      )
    },
    currentSlide() {
      var slides = this.wkSlidesForUser || []
      return slides[this.currentIndex]
    },
    nextSlideTitle() {
      let slides = this.wkSlidesForUser || []
      if (this.currentIndex === slides.length - 1) return ''
      let nextSlide = slides[this.currentIndex + 1]

      return nextSlide.carousel_title
    },
    hasNextSlide() {
      return this.currentIndex < this.wkSlidesForUser.length - 1
    },
    prevSlideTitle() {
      if (this.currentIndex === 0) return this.welcomekit.title

      let slides = this.wkSlidesForUser || []
      let prevSlide = slides[this.currentIndex - 1]

      return prevSlide.carousel_title
    }
  },
  methods: {
    ...mapActions('feedback', ['loadSurvey', 'loadUndoneSurvey']),
    ...mapActions('analytics', ['trackTapEvent']),
    back() {
      if (this.currentIndex === 0)
        return { name: this.isMobile ? 'publicWelcomekit' : 'welcomekit' }

      let slides = this.wkSlidesForUser || []
      let prevSlide = slides[this.currentIndex - 1]

      if (prevSlide.template_name === 'standardWelcomeKit') {
        return {
          name: getKitSlideRouteName(prevSlide.template_name, this.isMobile),
          params: { id: prevSlide.id }
        }
      } else {
        return {
          name: getKitSlideRouteName(prevSlide.template_name, this.isMobile)
        }
      }
    },
    next() {
      let slides = this.wkSlidesForUser || []
      if (this.currentIndex === slides.length - 1) return ''
      let nextSlide = slides[this.currentIndex + 1]

      if (nextSlide.template_name === 'standardWelcomeKit') {
        return {
          name: getKitSlideRouteName(nextSlide.template_name, this.isMobile),
          params: { id: nextSlide.id }
        }
      } else {
        return {
          name: getKitSlideRouteName(nextSlide.template_name, this.isMobile)
        }
      }
    }
  },
  created() {
    // this is not required to be here, since we load again in the pages with links to feedback,
    // but it is here to pre-load this data in hte standard flow (and improve UX)
    this.loadSurvey().then((res) => {
      this.loadUndoneSurvey().then((undoneSurveys) => {
        this.overlay = undoneSurveys.length > 0
      })
    })
  },
  watch: {
    client: function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$store.dispatch('welcomekit/getWelcomekitByUserProfile', {
          client: this.client
        })
        this.$store.dispatch('welcomekit/getTutorialByUserProfile', {
          client: this.client
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
