<template>
  <v-dialog :value="value" @input="exit" width="555">
    <div class="dialogDelete">
      <template v-if="step === 'message'">
        <div class="d-flex align-center justify-space-between mb-6">
          <div class="dialogDelete__title">Elimina il tuo profilo</div>
          <img
            src="@/assets/icons/ic-close-blue.svg"
            alt="close"
            @click="exit"
          />
        </div>
        <div class="dialogDelete__subTitle">Ci dispiace vederti andare via</div>
        <div class="dialogDelete__text">
          Stai per eliminare il tuo account Sorgenia. Ciò comporterà la
          cancellazione dei tuoi dati e non avrai più accesso alle funzionalità
          delle <strong>app Sorgenia</strong>.<br />
          Se hai delle utenze attive o in attivazione continuerai a poterne
          usufruire al di fuori dei canali digitali.<br /><br />
          Prima di proseguire, puoi dirci perché hai deciso di eliminare
          l’account?
        </div>
        <div class="dialogDelete__message">
          <h5>MESSAGGIO</h5>
          <v-textarea
            outlined
            v-model="message"
            name="message"
            placeholder="Descrivi il motivo"
            persistent-placeholder
            color="#B7BFD2"
            height="90"
          ></v-textarea>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            color="primary"
            height="54"
            width="339"
            class="v-btn--shadowed"
            @click="step = 'confirm'"
            >Prosegui con l’eliminazione</v-btn
          >
        </div>
      </template>
      <template v-else-if="step === 'confirm'">
        <div class="d-flex align-center justify-space-between mb-6">
          <div class="dialogDelete__title">
            Vuoi davvero eliminare il tuo profilo?
          </div>
          <img
            src="@/assets/icons/ic-close-blue.svg"
            alt="close"
            @click="$emit('input')"
          />
        </div>

        <div class="dialogDelete__text">
          Proseguendo verrà inviata una <b>richiesta</b> di eliminazione che
          <b>verrà elaborata entro 14 giorni</b>. Ricorda che questa azione è
          irreversibile.
        </div>
        <div class="d-flex justify-center mb-6">
          <v-btn
            height="54"
            width="339"
            class="v-btn--shadowed redAlert"
            @click="toGoodbyePage"
            >Prosegui con l’eliminazione</v-btn
          >
        </div>
        <div class="d-flex justify-center">
          <v-btn color="white" height="54" width="267" @click="exit"
            >Annulla la richiesta</v-btn
          >
        </div>
      </template>
      <template v-else-if="step === 'success'">
        <div class="d-flex align-center justify-space-between">
          <div class="dialogDelete__title"></div>
          <img
            src="@/assets/icons/ic-close-blue.svg"
            alt="close"
            @click="$emit('input')"
          />
        </div>
        <div class="d-flex align-center justify-center mt-5">
          <img src="../../assets/feedback-mys.svg" />
        </div>
        <div class="dialogDelete__subTitle big">Richiesta inviata</div>
        <div class="dialogDelete__text big">
          La tua richiesta sarà elaborata entro 14 giorni lavorativi. Ti
          ricordiamo che nel caso in cui ne avessi bisogno potrai contattare il
          nostro Servizio clienti.
        </div>
        <div class="d-flex justify-center">
          <v-btn
            color="primary"
            height="54"
            width="231"
            class="v-btn--shadowed"
            @click="exit"
            >Torna al profilo</v-btn
          >
        </div>
      </template>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'deleteUser',
  props: ['value', 'clientDetail'],
  data() {
    return {
      step: 'message',
      message: ''
    }
  },
  computed: {
    ...mapGetters('session', ['username']),
    ...mapGetters('account', ['idLogin'])
  },
  methods: {
    ...mapActions('interactions', ['callMeBackUtente']),
    ...mapActions('session', ['accountDeleted']),
    ...mapMutations('session', ['setAccountDeletionRequested']),

    exit() {
      this.$emit('input')
      this.step = 'message'
    },
    toGoodbyePage() {
      let data = {
        clientIDprospectID: this.clientDetail.clientCode,
        canaleProvenienza: 'MYS',
        tipoRichiesta: 'cancellazioneAccount',
        nome: this.clientDetail.name,
        cognome: this.clientDetail.surname,
        mailDiContatto: this.clientDetail.contactMail,
        mailLogin: this.username,
        idLogin: this.idLogin,
        nota: this.message,
        telefono: this.clientDetail.mobilePhone,
        oggettoRichiestaAssistenza:
          'MySorgenia - Richiesta assistenza cancellazione account'
      }
      this.callMeBackUtente(data).then((res) => {
        if (res.status === 'OK') {
          this.accountDeleted()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialogDelete {
  padding: 40px;
  background: white;
  &__title {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    color: black;
  }
  &__subTitle {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: black;
    line-height: 140%;
    margin-bottom: 8px;
    &.big {
      font-size: 28px;
      line-height: 130%;
      letter-spacing: 1.2px;
      text-align: center;
      margin-top: 20px;
    }
  }
  &__text {
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #2c313a;
    line-height: 140%;
    margin-bottom: 24px;
    &.big {
      font-size: 19px;
      text-align: center;
      letter-spacing: 1.2px;
      color: black;
      margin-bottom: 32px;
    }
  }
  &__message {
    margin-bottom: 24px;
    h5 {
      font-size: 14px;
      font-weight: 700;
      color: #6e7689;
      letter-spacing: 1.2px;
      margin-bottom: 8px;
    }
  }
}
.dialogDelete img {
  cursor: pointer;
}
</style>
<style lang="scss">
.v-application--is-ltr
  .v-textarea.v-text-field--enclosed
  .v-text-field__slot
  textarea {
  color: #12256a;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 140%;
}
</style>
