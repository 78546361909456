<template>
  <div class="offering">
    <v-row
      :class="[
        'offering__header',
        { 'offering__header--small': smallHeader },
        { successStep: successStep },
        typeCommodity
      ]"
    >
      <v-col class="col-6 img"></v-col>
      <v-spacer />
      <v-col class="col-5" :align-self="align">
        <slot name="header" />
      </v-col>
    </v-row>
    <v-row class="offering__content">
      <slot />
    </v-row>
    <v-footer v-if="footer" class="offering__footer topRadius" height="96">
      <v-row>
        <v-col align="center" justify="center">
          <p class="ma-0">
            Hai bisogno di aiuto?
            <a
              @click="
                ;(dialog = true),
                  trackTapEvent({
                    name: 'commodityUpsell_contactUs_tap',
                    params: {
                      client_code: clientCode,
                      client_type: clientType,
                      contact_type: landline ? 'phone' : 'callmeback'
                    }
                  })
              "
              class="pl-2"
            >
              Contattaci senza impegno
            </a>
          </p>
        </v-col>
      </v-row>
    </v-footer>
    <Help
      v-if="landline"
      :landlineOpen="landline"
      v-model="dialog"
      fromPage="commodity"
    ></Help>
    <CallMeBack
      v-if="!landline"
      v-model="dialog"
      :requestingClient="requestingClient"
      :fromPage="typeCommodity"
    ></CallMeBack>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import CallMeBack from '../../components/callMeBack/CallMeBackDialog'
import Help from '../../components/upselling/Help'

import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'

export default {
  name: 'OfferingTemplate',
  data() {
    return {
      dialog: false,
      landline: false
    }
  },
  props: {
    successStep: {
      type: Boolean,
      default: false
    },
    smallHeader: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'center'
    },
    footer: { type: Boolean, default: true },
    typeCommodity: { type: String },
    client: { type: Object, default: null },
    newClient: { type: Boolean, default: false },
    newClientType: { type: String, default: '' }
  },
  beforeMount() {
    this.getSupplies()

    this.isChannelOpen({
      channel: consts.CALLCENTER_CHANNELS.LANDLINE,
      area: consts.CALLCENTER_AREAS.UPSELLING_COMMODITY
    })
      .then((resp) => {
        this.landline = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  computed: {
    clientCode() {
      return this.newClient ? 'new-client' : this.client.clientCode || ''
    },
    clientType() {
      return this.newClient ? this.newClientType : this.client.clientType || ''
    },
    requestingClient() {
      return { clientCode: this.clientCode, clientType: this.clientType }
    }
  },
  methods: {
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('interactions', ['isChannelOpen']),
    ...mapActions('account', ['getMainClient']),
    ...mapActions('analytics', ['trackTapEvent'])
  },
  components: {
    CallMeBack,
    Help
  }
}
</script>

<style lang="scss" scoped>
.offering {
  background-color: $srg-blue;
  &__header {
    height: 470px;
    &--small {
      height: 270px;
      transition: height 1s;
    }
    &.successStep {
      height: 395px;
      margin-bottom: -32px;
      &.luce {
        background-image: url('../../assets/success-upselling-luce-bus.png');
        background-size: 100%;
        background-position: center;
      }
      &.gas {
        background-image: url('../../assets/success-upselling-gas-bus.png');
        background-size: 100%;
        background-position: center;
      }
      .img {
        background-image: initial;
      }
    }
    .img {
      background-image: url('../../assets/jumping-woman.png');
      background-size: 700px;
      background-position-x: right;
      background-position-y: 13px;
    }
  }
  &__content {
    min-height: 500px;
    background: white;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
  &__footer {
    margin-top: -96px;
  }
}
</style>
