<template>
  <page
    title="Gestione contatore"
    page-name="Gestione contatore"
    @submit="request"
    :disabled="disabled"
    idContextualHelp="help-manage-meter"
  >
    <TcVars />
    <counter-movement :data.sync="data" idContextualHelp="help-manage-meter" />
  </page>
</template>

<script>
import CounterMovement from '@/components/actions/CounterMovement'
import TcVars from '@/components/tcTracker/TcVars'
import { mapActions, mapGetters } from 'vuex'
import Template from './Template'

export default {
  name: 'CounterAction',
  props: {},
  components: {
    page: Template,
    CounterMovement,
    TcVars
  },
  data: () => ({
    details: null,
    data: null
  }),
  computed: {
    ...mapGetters('account', ['clientDetails']),
    disabled() {
      return !this.data
    }
  },
  methods: {
    request() {
      const body = {
        cognome: this.clientDetails.surname,
        email: this.clientDetails.contactMail,
        nome: this.clientDetails.name,
        codiceCliente: this.clientDetails.clientCode,
        login: this.$store.state.session.username,
        puntoDiFornitura: this.$route.params.supplyCode,
        fieldsCommodity: {
          ...this.data,
          email: this.clientDetails.contactMail
        }
      }
      const type = this.data.spostamento ? 'counter1' : 'counter2'
      this.createRequest(body).then((res) => {
        this.trackTapEvent('supply_changeManageMeter_view')
        if (res.status === 'OK')
          this.$router.push({ name: 'promSuccess', params: { type } })
      })
    },
    ...mapActions('support', ['createRequest']),
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>

<style lang="scss" scoped></style>
