<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SelfRegistration'
  // data () {
  //   return {
  //     // these will be to be set dynamically based on a route param once we'll also have the "by-friend-code registration"
  //     registrationByFriendCode: false,
  //     registrationByCustomerCode: true
  //   }
  // }
}
</script>
