import { axiosACN } from '../../axiosInstances'
import constants from '../../constants'
import store from '../../../store'
export default class PaymentStatus {
  static retrivePaymentStatus(clientsIds, acuIdList, token) {
    let body = {}
    let commodity = store.getters['session/accountTypes'].includes('COMMODITY')
    let photovoltaic =
      store.getters['session/accountTypes'].includes('PHOTOVOLTAIC')
    if (commodity && photovoltaic) {
      body = {
        commodityClientList: clientsIds,
        acuIdList: acuIdList
      }
    } else {
      if (commodity) {
        body = {
          commodityClientList: clientsIds
        }
      } else {
        body = {
          acuIdList: acuIdList
        }
      }
    }

    try {
      return axiosACN
        .post(
          // '/bollette/V3/retrievePaymentStatus',
          '/bollette/V4/retrievePaymentStatus',
          // {
          //   // listaClienti: clientsIds
          //   commodityClientList: clientsIds.length > 0 ? clientsIds : undefined,
          //   acuIdList: acuIdList.length > 0 ? acuIdList : undefined
          // },
          body,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
        .then((res) => {
          return res
        })
    } catch (err) {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    }
  }

  static getPaymentStatusClientList(paymentsAlert) {
    return paymentsAlert.data.clientList.map((item) => {
      return {
        ele: {
          paymentStatuses: item.suppliesList.elePaymentStatus,
          billsDates: item.suppliesList.eleLastInvoiceDate,
          tipoConsumo: item.suppliesList.eleConsumptionType,
          flagAlert: !!item.suppliesList.flagAlert,
          points:
            item.suppliesList.pointList.filter(
              (item) => item.supplyType === constants.SERVICE_TYPE.ELE
            ) || []
        },
        gas: {
          paymentStatuses: item.suppliesList.gasPaymentStatus,
          billsDates: item.suppliesList.gasLastInvoiceDate || null,
          tipoConsumo: item.suppliesList.gasConsumptionType || null,
          flagAlert: !!item.suppliesList.flagAlert,
          points:
            item.suppliesList.pointList.filter(
              (item) => item.supplyType === constants.SERVICE_TYPE.GAS
            ) || []
        },
        fiber: {
          paymentStatuses: item.suppliesList.fiberPaymentStatus,
          billsDates: item.suppliesList.fiberLastInvoiceDate,
          points:
            item.suppliesList.pointList.filter(
              (item) => item.supplyType === constants.SERVICE_TYPE.FIBER
            ) || []
        },
        photovoltaic: {
          photovoltaicStatus: item.suppliesList.photovoltaicStatus,
          points:
            item.suppliesList.pointList.filter(
              (item) => item.supplyType === constants.SERVICE_TYPE.PHOTOVOLTAIC
            ) || []
        }
      }
    })
  }

  static getPaymentStatusConsumptionsAlert(consumptionsAlert) {
    return consumptionsAlert.data.listaClienti.map((item) => {
      return {
        ele: {
          consumptionStatuses: item.listaForniture.statusConsumiLuce,
          points:
            item.listaForniture.listaPunti.filter(
              (item) => item.servizio === 'Ele'
            ) || [],
          flagAlertConsumi: !!item.listaForniture.flagAlert
        },
        gas: {
          consumptionStatuses: item.listaForniture.statusConsumiGas,
          points:
            item.listaForniture.listaPunti.filter(
              (item) => item.servizio === 'Gas'
            ) || [],
          flagAlertConsumi: !!item.listaForniture.flagAlert
        }
      }
    })
  }
}
