<template>
  <div>
    <v-row>
      <v-col>
        <div class="HeadingsTitleXXL formTitle">
          Hai bisogno di contattarci?
        </div>
        <div class="BodyXL">
          Compila i campi sottostanti e invia la richiesta al Servizio Clienti.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FormTitle'
}
</script>

<style lang="scss" scoped>
.formTitle {
  margin-bottom: 8px;
}
</style>
