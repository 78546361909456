var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-carousel',{attrs:{"continuous":false,"show-arrows":!_vm.isMobile,"show-arrows-on-hover":!_vm.isMobile,"hide-delimiter-background":!_vm.isMobile,"delimiter-icon":"mdi-minus","height":!_vm.isMobile ? 570 : 460}},_vm._l((_vm.slides),function(slide,i){return _c('v-carousel-item',{key:i},[_c('v-row',_vm._l((slide.slide),function(card,i){return _c('v-col',{key:i,attrs:{"md":"6","cols":"12","sm":"6"},on:{"click":function($event){return _vm.trackTapEvent({
            name:
              _vm.name === 'welcome'
                ? 'welcomeKit_card_tap'
                : _vm.name === 'tutorial'
                ? 'wkTutorial_card_tap'
                : 'feedback_card_tap',
            params: {
              card_title: card.carousel_title,
              client_code: _vm.client.clientOwnerCode,
              client_type: _vm.client.clientOwnerType,
              wk_type: _vm.client.type
            },
            toNative: _vm.isMobile
          })}}},[_c('v-card',{staticClass:"v-card--rounded",attrs:{"light":"","elevation":"10","min-height":!_vm.isMobile ? 474 : 378},on:{"click":function($event){_vm.$router.push(_vm.to(card.id, card.template_name))}}},[_c('v-img',{staticClass:"white--text",attrs:{"src":_vm.isSmallDevice
                ? _vm.cdn + (card.carousel_image_mobile || card.carousel_image)
                : _vm.cdn + card.carousel_image,"width":_vm.isMobile ? 351 : 546,"height":_vm.isMobile ? 200 : 330}}),_c('v-row',{staticClass:"pl-6 pr-3"},[_c('h3',{staticClass:"mt-8"},[_c('strong',[_vm._v(_vm._s(card.carousel_title))])]),_c('h6',{staticClass:"mt-2 mb-8",domProps:{"innerHTML":_vm._s(card.carousel_text)}})])],1)],1)}),1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }