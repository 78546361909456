<template>
  <div class="checkData">
    <div class="boxSummary mb-8">
      <div class="HeadingsTitleM">Next Fiber!</div>
      <div class="ButtonM" @click="dialogSummaryFiber = true">
        Vedi il riepilogo offerta
      </div>
    </div>
    <div id="data" class="accordion mb-6">
      <div class="d-flex align-center justify-space-between">
        <div class="data">
          <div class="LabelM colorData mb-2">DATI FORNITURA</div>
          <div class="BodyL" v-if="migrationCode && migrationCode.checked">
            <strong>CM: {{ migrationCode.code }}</strong>
          </div>
          <div class="BodyL">
            {{ addressCovered.street }}, {{ addressCovered.city }}
          </div>
        </div>
      </div>
    </div>
    <div id="activation" class="accordion mb-6">
      <div class="d-flex align-center justify-space-between relative">
        <div class="data">
          <div class="LabelM colorData mb-2">ATTIVAZIONE</div>
          <div class="BodyL">
            {{ activeLine ? 'Cambio fornitore' : 'Nuova connessione' }}
          </div>
        </div>
      </div>
    </div>
    <div id="registry" class="accordion accordionData">
      <div class="d-flex align-center justify-space-between relative">
        <div class="data">
          <div class="BodyXL-Strong mb-2">Anagrafica</div>
          <div class="BodyL">
            {{ summarySupplyOwner.firstName }}
            {{ summarySupplyOwner.lastName }}
          </div>
        </div>
        <div class="arrow" @click="openAccordion('registry')">
          <img src="../../../assets/icons/chevron.svg" width="24" />
        </div>
      </div>
      <div class="content">
        <IdentityDocumentEdit @openAccordion="openAccordion" />
      </div>
    </div>

    <div id="payment" class="accordion accordionData">
      <div class="d-flex align-center justify-space-between relative">
        <div class="data">
          <div class="BodyXL-Strong mb-2">Metodo di pagamento</div>
          <div class="BodyL">
            {{
              paymentMethod.methodType === PAYMENT_METHODS.CREDIT_CARD
                ? 'Carta di credito ' + paymentMethod.creditCardNumber
                : 'Conto corrente ' + paymentMethod.iban
            }}
          </div>
        </div>
        <div class="arrow" @click="openAccordion('payment')">
          <img src="../../../assets/icons/chevron.svg" width="24" />
        </div>
      </div>
      <div class="content">
        <PaymentEdit
          @openAccordion="openAccordion"
          @goToEdit="$emit('goToEdit', 9, '')"
        />
      </div>
    </div>

    <div
      id="shipping"
      class="accordion accordionData"
      v-if="provider === 'FW' || provider === 'FC'"
    >
      <div class="d-flex align-center justify-space-between relative">
        <div class="data">
          <div class="BodyXL-Strong mb-2">Dati di Spedizione</div>
          <div class="BodyL">
            {{ $filters.capitalize(addressShipping.address.via) }}
            {{ addressShipping.civicNumber.fullCivico }}
            {{ $filters.capitalize(addressShipping.address.comune) }}
          </div>
        </div>
        <div class="arrow" @click="openAccordion('shipping')">
          <img src="../../../assets/icons/chevron.svg" width="24" />
        </div>
      </div>
      <div class="content">
        <ShippingAddress
          btnText="Modifica"
          go-next="false"
          @openAccordion="openAccordion"
          :noToast="true"
        />
      </div>
    </div>

    <div id="anticipate" class="accordion mb-8">
      <div class="d-flex align-center justify-space-between relative">
        <div class="data">
          <div class="BodyL-Strong mb-3 mt-3">
            Voglio anticipare l’attivazione
          </div>
        </div>
        <v-switch
          v-model="speedUpCheck"
          hide-details="true"
          class="switch"
          color="#97D707"
          inset
        ></v-switch>
      </div>
    </div>
    <div id="paperBill" :class="`accordion mb-12 ${paperBill ? 'open' : ''}`">
      <div class="mb-2 d-flex align-center justify-space-between relative">
        <div class="BodyL">
          Scelta ecologica con
          <span class="BodyL-Strong">bolletta DIGITALE</span>
        </div>
        <img class="mr-2" src="../../../assets/ic-eco-leaf.svg" />
      </div>
      <div class="d-flex align-center justify-space-between relative">
        <div class="data">
          <div class="mb-5 mt-3 BodyL">Bolletta cartacea</div>
        </div>
        <v-switch
          v-model="paperBill"
          hide-details="true"
          class="switch"
          color="#97D707"
          inset
        ></v-switch>
      </div>
      <div class="mb-1 BodyL">
        La bolletta cartacea ha un costo aggiuntivo di 2,50 € al mese
      </div>
      <div class="content">
        <div id="addressSentBill" class="sub-accordion">
          <div class="d-flex align-center justify-space-between">
            <div class="data">
              <h5>Indirizzo bolletta cartacea</h5>
              <h5 class="bold">{{ addressBillPaper }}</h5>
            </div>
            <div class="sub-arrow" @click="openAccordion('addressSentBill')">
              <img src="../../../assets/icons/chevron.svg" width="24" />
            </div>
          </div>
          <div class="sub-content">
            <h3 class="bold">Modifica Indirizzo dove ricevere la bolletta</h3>
            <ReceiveBill
              v-if="paperBill"
              @updateAddress="updateAddress"
            ></ReceiveBill>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-center justify-space-between relative">
      <div class="data">
        <div class="BodyL-Strong mb-2">Hai ricevuto un codice sconto?</div>
      </div>
    </div>
    <div>
      <h5 class="pt-3 mb-2">Inseriscilo e ricevi uno sconto in bolletta</h5>
      <div class="mt-6 mb-3 custom-input-field">
        <v-text-field
          v-model="promoCode"
          label="Codice sconto"
          placeholder="inserisci codice"
          persistent-placeholder
          @input="v$.promoCode.$touch()"
          :error-messages="verifyCodeErrors()"
        />
      </div>
    </div>

    <v-btn
      color="primary"
      x-large
      width="262"
      class="v-btn--shadowed"
      @click="confirm"
      >Conferma e concludi
    </v-btn>

    <v-dialog v-model="dialogSummaryFiber" width="555">
      <div class="dialog__summaryFiber">
        <div class="d-flex align-center justify-space-between mb-10">
          <div class="HeadingsSubtitleL">Riepilogo offerta</div>
          <img
            class="cursor-pointer"
            src="../../../assets/icons/close-blu.svg"
            @click="dialogSummaryFiber = false"
          />
        </div>
        <SummaryFiber />
      </div>
    </v-dialog>
  </div>
</template>
<script>
import IdentityDocumentEdit from '@/components/upselling/fiber/IdentityDocumentEdit'
import PaymentEdit from '@/components/upselling/fiber/PaymentEdit'
import ReceiveBill from '@/components/upselling/fiber/ReceiveBill'
import Consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { capitalize, hasCharacterValid } from '@/js/utils'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ShippingAddress from '../data/ShippingAddress'
import SummaryFiber from './SummaryFiber.vue'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'checkData',
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      addressBillPaper: '',
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,
      dialogSummaryFiber: false,
      promoCode: '',
      errorPromoCode: null
    }
  },
  watch: {
    dialogSummaryFiber(val) {
      if (val) {
        this.trackTapEvent({
          name: 'upsellingv2_OfferOverview_view',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      }
    },
    promoCode(newVal) {
      this.verifyCodeErrors()
      this.errorPromoCode = null
    }
  },
  mounted() {
    // Questa fix potrebbe risultare utile nel caso in cui il campo cap fosse vuoto perché in quel caso va in errore la sottoscrizione
    // if (this.addressShipping.address.cap === '') {
    //   this.addressShipping.address.cap = this.addressShipping.city.cap
    // }
    if (this.invoiceMethod && this.invoiceMethod.type === 'paper') {
      if (this.invoiceMethod.sameAsSupplyAddress) {
        this.addressBillPaper = capitalize(
          this.addressCovered.street + ', ' + this.addressCovered.city
        )
      } else {
        this.addressBillPaper = capitalize(
          this.invoiceMethod.address.label +
            ' ' +
            this.invoiceMethod.number.fullInfo.NRPNUMCIV.lValue +
            ', ' +
            this.invoiceMethod.city.fullInfo.DSXOBJCNL
        )
      }
    } else {
      this.addressBillPaper = capitalize(
        this.addressCovered.street + ', ' + this.addressCovered.city
      )
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', [
      'client',
      'summarySupplyOwner',
      'migrationCode',
      'addressCovered',
      'paymentMethod',
      'connectionType',
      'isFromFastweb',
      'provider',
      'invoiceMethod',
      'identityDocument',
      'activeLine',
      'addressShipping'
    ]),
    paperBill: {
      get() {
        return this.invoiceMethod ? this.invoiceMethod.type === 'paper' : false
      },
      set(val) {
        if (val) {
          this.setInvoiceMethod({
            ...this.invoiceMethod,
            type: 'paper',
            sameAsSupplyAddress:
              this.invoiceMethod &&
              typeof this.invoiceMethod.sameAsSupplyAddress === 'boolean'
                ? this.invoiceMethod.sameAsSupplyAddress
                : true
          })
          this.trackTapEvent({
            name: 'upsellingv2_nodigital_toggle',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        } else {
          this.setInvoiceMethod({
            ...this.invoiceMethod,
            type: 'digital'
          })
          this.trackTapEvent({
            name: 'upsellingv2_digital_toggle',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        }
      }
    },
    speedUpCheck: {
      get() {
        return this.$store.getters['upsellingFiber/speedUpProcess']
      },
      set(val) {
        if (val) {
          this.trackTapEvent({
            name: 'upsellingv2_renounceafterthought_toggle',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        } else {
          this.trackTapEvent({
            name: 'upsellingv2_keepafterthought_toggle',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        }
        this.setSpeedUpProcess(val)
      }
    }
  },
  methods: {
    ...mapMutations('upsellingFiber', [
      'setSpeedUpProcess',
      'setInvoiceMethod',
      'setMgmDetail',
      'subscribe'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('upsellingFiber', ['subscribe']),
    ...mapActions('MGM', ['verifyFriendCode']),
    doSubscribe() {
      this.trackTapEvent({
        name: 'upsellingv2_activate_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      if (
        this.errorPromoCode &&
        this.errorPromoCode.errorCode &&
        this.promoCode !== ''
      ) {
        return
      } else {
        this.subscribe()
          .then((resp) => {
            this.$router.push('subscribed')
          })
          .catch((error) => {
            // We do not expect any business logic error, so show an alert with generic error
            GenErr.handleGenericError(error && error.message)
            GenErr.showErrorMessage(
              'Si è verificato un errore durante la fase di sottoscrizione. Ti preghiamo di verificare i dati e riprovare. Grazie.'
            )
          })
      }
    },
    openAccordion(id) {
      document.getElementById(id).classList.toggle('open')
    },
    verifyCodeErrors() {
      let errArray = []

      if (!this.v$.promoCode.$dirty) return errArray

      this.v$.promoCode.hasCharacterValid.$invalid &&
        errArray.push('Non sono ammessi caratteri speciali')
      if (this.errorPromoCode) {
        if (this.errorPromoCode.errorCode === '001') {
          errArray.push('Codice non valido')
        } else if (this.errorPromoCode.errorCode === '002') {
          errArray.push('Listino non presente')
        }
      }
      return errArray
    },
    verify() {
      return this.verifyFriendCode({
        codiceAmico: this.promoCode,
        codicefiscaleCliente: this.client.fiscalCode,
        codiceCampagnaWeb: '',
        segmentoCliente: 'Residenziale Digital'
      })
        .then((res) => {
          this.errorPromoCode = res
          if (res.status === 'OK') {
            this.setMgmDetail({
              listinoMgm: res.listinoMgm,
              nomeCampagnaMgm: res.nomeCampagnaMgm,
              promoCode: this.promoCode
            })
            return true
          } else {
            return false
          }
        })
        .catch(() => {
          return false
        })
    },
    updateAddress() {
      this.trackTapEvent({
        name: 'upsellingv2_nodigitalbilladdress_edit',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      this.addressBillPaper = capitalize(
        this.invoiceMethod.address.label +
          ' ' +
          this.invoiceMethod.number.fullInfo.NRPNUMCIV.lValue +
          ', ' +
          this.invoiceMethod.city.fullInfo.DSXOBJCNL
      )
      this.openAccordion('addressSentBill')
    },
    async confirm() {
      let verifyCheck = true
      if (this.promoCode) {
        verifyCheck = await this.verify()
      }

      if (verifyCheck) {
        if (!this.invoiceMethod) {
          this.setInvoiceMethod({
            ...this.invoiceMethod,
            type: 'digital'
          })
        } else if (
          this.invoiceMethod.sameAsSupplyAddress &&
          this.invoiceMethod.type === 'paper'
        ) {
          let paperInvoiceSameAsSupply = {}
          paperInvoiceSameAsSupply.address = {
            label: this.addressCovered.street || '',
            value: ''
          }
          paperInvoiceSameAsSupply.number = {
            label: this.addressCovered.streetNumber || '',
            value: ''
          }
          paperInvoiceSameAsSupply.city = {
            label: this.addressCovered.city || '',
            value: ''
          }
          paperInvoiceSameAsSupply.county = {
            label: this.addressCovered.county || '',
            value: ''
          }
          paperInvoiceSameAsSupply.cap = {
            label: this.addressCovered.zipCode || '',
            value: ''
          }
          paperInvoiceSameAsSupply.among =
            this.addressCovered.firstName + ' ' + this.addressCovered.lastName
          paperInvoiceSameAsSupply.sameAsSupplyAddress = true
          paperInvoiceSameAsSupply.type = 'paper'

          this.setInvoiceMethod(paperInvoiceSameAsSupply)
        }
        // this.$emit('nextStep', 'upsellingv2_summary_open')
        this.doSubscribe()
      } else {
        this.v$.promoCode.$touch()
      }
    }
  },
  validations: {
    promoCode: { hasCharacterValid }
  },
  components: {
    ShippingAddress,
    ReceiveBill,
    IdentityDocumentEdit,
    PaymentEdit,
    SummaryFiber
  }
}
</script>
<style lang="scss" scoped>
.colorData {
  color: #6e7689;
}
.checkData {
  max-width: 652px;
  color: black;
  #mgmCode {
    border-bottom: none !important;
  }
  .boxSummary {
    width: 100%;
    height: 93px;
    background: $base-light;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 26px 16px 16px;
    color: $neutri-neutral-60;
    .ButtonM {
      cursor: pointer;
      color: #12256a;
    }
  }
}

.accordionData {
  cursor: pointer;
  padding: 24px !important;
  border: 1px solid;
  border-color: #b7bfd2;
  border-radius: 8px;
  margin-bottom: 32px !important;
  margin-top: 32px !important;
}
.accordion,
.sub-accordion {
  .relative {
    position: relative;
  }

  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid #b7bfd2;

  .arrow {
    img {
      transition: transform 0.2s ease-in;
      transform: rotate(0deg);
    }
  }

  &.open {
    .content {
      max-height: 800px;

      .bold {
        font-weight: 500;
      }
    }

    .arrow {
      img {
        transition: transform 0.2s ease-in;
        transform: rotate(180deg);
      }
    }
  }

  .switch {
    position: absolute;
    right: 0;
    margin-top: auto;
  }
}

.sub-accordion {
  border-top: 1px solid #ccd0e1;
  padding-top: 24px;
  padding-bottom: 0px;
  margin-top: 24px;
  border-bottom: none;

  &.open {
    .sub-content {
      padding-top: 20px;
      max-height: 800px;
    }

    .sub-arrow {
      img {
        transition: transform 0.2s ease-in;
        transform: rotate(180deg);
      }
    }
  }
}

.content,
.sub-content {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 0.7s;
  overflow-x: hidden;
  overflow-y: hidden;
}
.dialog__summaryFiber {
  background: white;
  padding: 40px 40px 20px;
  .cursor-pointer {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.checkData {
  .error--text {
    .v-messages__message {
      display: flex;
      align-items: center;
      &::before {
        content: url('../../../assets/icons/Error.svg');
        margin-right: 5px;
      }
    }
    input {
      color: red !important;
    }
  }
}
</style>
