<template>
  <div class="survey mt-10">
    <h5 class="mb-1">Auto</h5>
    <h4 class="mb-6">
      Tieni aggiornate le informazioni sulla tua auto: ti daremo consigli più
      accurati sulle opportunità energetiche e sugli sviluppi di MyNextMove, la
      nostra app della mobilità elettrica.
    </h4>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel class="survey__box mb-6" @click="selectMobility">
        <v-expansion-panel-header>
          <h4>La mia auto</h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            :class="`survey__item mb-4 mt-4 ${
              mobilityData.saved ? 'edit' : ''
            }`"
            @click="openSurvey"
          >
            <h6>
              Questionario 1<img
                class="ml-6 mr-1"
                src="../../../assets/profile/clock.svg"
              />1 min
            </h6>
            <h6 class="bold">Cosa guidi?</h6>
          </div>
          <div
            v-if="mobilityData.completed"
            class="survey__suggestion ButtonS mt-6"
            @click="
              $router.push({
                name: 'serviceSection',
                params: { type: 'mobility' }
              })
            "
          >
            Scopri le offerte su misura per te
          </div>
          <div
            v-else-if="mobilityData.saved"
            class="survey__suggestion ButtonS mt-6"
            @click="openSurvey"
          >
            Finisci di compilare il questionario!
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="dialog" max-width="555">
      <div class="dialogSurvey">
        <div
          v-if="step > 0"
          class="d-flex justify-space-between align-center mb-6"
        >
          <h3>La mia auto</h3>
          <img
            class="close"
            src="../../../assets/icons/ic-close.svg"
            @click="exitSurveyDialog"
          />
        </div>
        <div v-if="step === 1">
          <h5 class="mb-2">{{ step }} di 2</h5>
          <div class="question car mb-6">
            <h4>Che tipo di auto hai?</h4>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              mobilityData.tipoAuto === TypeAuto.ELECTRIC ? 'selected' : ''
            }`"
            @click="mobilityData.tipoAuto = TypeAuto.ELECTRIC"
          >
            <h6>Elettrica</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              mobilityData.tipoAuto === TypeAuto.HYBRID ? 'selected' : ''
            }`"
            @click="mobilityData.tipoAuto = TypeAuto.HYBRID"
          >
            <h6>Ibrida</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              mobilityData.tipoAuto === TypeAuto.TRADITIONAL ? 'selected' : ''
            }`"
            @click="mobilityData.tipoAuto = TypeAuto.TRADITIONAL"
          >
            <h6>Tradizionale</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              mobilityData.tipoAuto === TypeAuto.NONE ? 'selected' : ''
            }`"
            @click="mobilityData.tipoAuto = TypeAuto.NONE"
          >
            <h6>Nessuna</h6>
          </div>
        </div>
        <div v-if="step === 2 && mobilityData.tipoAuto === 'Elettrica'">
          <h5 class="mb-2">{{ step }} di 2</h5>
          <div class="question battery mb-6">
            <h4>Qual è la capienza della tua batteria?</h4>
          </div>
          <!-- <div class="slider-legend justify-space-between px-3">
            <span :class="mobility.capienzaBatteria >= 20 ? 'over' : ''">20 kWh</span>
            <span :class="mobility.capienzaBatteria >= 50 ? 'over' : ''">50 kWh</span>
            <span :class="mobility.capienzaBatteria >= 80 ? 'over' : ''">80 kWh</span>
            <span :class="mobility.capienzaBatteria >= 110 ? 'over' : ''">+110 kWh</span>
          </div> -->
          <v-slider
            v-model="mobilityData.capienzaBatteria"
            :thumb-size="30"
            thumb-color="#1197AD"
            thumb-label="always"
            track-color="#DFE4EF"
            loader-height="10"
            step="10"
            min="0"
            max="88"
            class="mb-6 batteryHouse"
          />
          <div class="question wallbox mb-6">
            <h4>Possiedi una wallbox a casa?</h4>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              mobilityData.wallbox === Wallbox.YES ? 'selected' : ''
            }`"
            @click="mobilityData.wallbox = Wallbox.YES"
          >
            <h6>Sì, già ho un wallbox installato</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              mobilityData.wallbox === Wallbox.NOT_YET ? 'selected' : ''
            }`"
            @click="mobilityData.wallbox = Wallbox.NOT_YET"
          >
            <h6>Non ancora, ma ci sto pensando</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              mobilityData.wallbox === Wallbox.CANNOT ? 'selected' : ''
            }`"
            @click="mobilityData.wallbox = Wallbox.CANNOT"
          >
            <h6>Non potrei metterlo</h6>
          </div>
        </div>
        <div v-if="step === 2 && mobilityData.tipoAuto !== TypeAuto.ELECTRIC">
          <h5 class="mb-2">{{ step }} di 2</h5>
          <div class="question key mb-6">
            <h4>Ti interessa la mobilità elettrica?</h4>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              mobilityData.interesse === Interest.RECALL ? 'selected' : ''
            }`"
            @click="mobilityData.interesse = Interest.RECALL"
          >
            <h6>Sì, mi interessa e vorrei saperne di più</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              mobilityData.interesse === Interest.NOT_INTERESTED
                ? 'selected'
                : ''
            }`"
            @click="mobilityData.interesse = Interest.NOT_INTERESTED"
          >
            <h6>No, non mi interessa la mobilità elettrica</h6>
          </div>
        </div>
        <Exit
          v-if="!Number.isInteger(step)"
          :dialog.sync="dialog"
          @backToStep="backToStep"
          @saveSurvey="saveSurvey"
          type="mobility"
        />
        <div class="d-flex justify-space-between align-center">
          <v-btn
            @click="prev"
            v-show="step > 1"
            class="white v-btn--shadowed"
            width="152"
            height="54"
            >Indietro</v-btn
          >
          <v-btn
            @click="next"
            v-show="step > 0"
            class="primary v-btn--shadowed ml-auto"
            width="152"
            height="54"
            :disabled="isDisabled"
            >{{ step === 2 ? 'Conferma' : 'Avanti' }}</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <Completed :dialog.sync="dialogCompleted" type="mobility" />
  </div>
</template>
<script>
import { eventBus } from '@/main'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { AlertService } from '../../../js/service/alertService'
import {
  Interest,
  TypeAuto,
  Wallbox
} from '../../../js/type/survey_mobility.type.js'
import Completed from './Completed.vue'
import Exit from './Exit.vue'

export default {
  name: 'mobileComponent',
  data() {
    return {
      mobilityData: {
        tipoAuto: '',
        capienzaBatteria: '',
        wallbox: '',
        interesse: ''
      },
      dialog: false,
      step: 1,
      dialogCompleted: false,
      panel: 0,
      TypeAuto: TypeAuto,
      Wallbox: Wallbox,
      Interest: Interest
    }
  },
  components: {
    Exit,
    Completed
  },
  watch: {
    dialog(val) {
      if (!val) {
        setTimeout(() => {
          this.step = 1
        }, 400)
      } else {
        if (this.step > 0) {
          this.trackTapEvent({
            name: 'profile2_openSurvey_tap',
            params: {
              survey_type: 'mobility',
              survey_name: 'Cosa guidi?',
              client_code: this.userProfile.clientOwnerCode
            }
          })
        }
      }
    },
    mobility(mobility) {
      this.mobilityData = mobility
    }
  },
  created() {
    eventBus.$on('profile:onboardingClosed', () => {
      this.panel = ''
    })
    let onboardingStorage = JSON.parse(
      localStorage.getItem('onboardingProfile')
    )
    if (
      !onboardingStorage ||
      !onboardingStorage[this.mainClient.clientOwnerCode]
    ) {
      this.panel = 0
    }
  },
  mounted() {
    eventBus.$on('mobility:changeSurvey', () => {
      this.panel = 0
      this.openSurvey()
    })
  },
  computed: {
    ...mapGetters('account', ['mainClient']),
    ...mapGetters('profile', ['mobility', 'userProfile']),
    isDisabled() {
      if (this.step === 1) {
        return !this.mobilityData.tipoAuto
      } else if (
        this.step === 2 &&
        this.mobilityData.tipoAuto === TypeAuto.ELECTRIC
      ) {
        return !this.mobilityData.capienzaBatteria || !this.mobilityData.wallbox
      } else if (
        this.step === 2 &&
        this.mobilityData.tipoAuto !== TypeAuto.ELECTRIC
      ) {
        return !this.mobilityData.interesse
      }
      return true
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('profile', ['mobilitySaveSurvey']),
    ...mapMutations('profile', ['setMobility', 'setTypOnboardingMobility']),
    prev() {
      this.step--
    },
    next() {
      if (this.step === 1 && this.mobilityData.tipoAuto === TypeAuto.ELECTRIC) {
        this.mobilityData.interesse = undefined
      } else if (
        this.step === 1 &&
        this.mobilityData.tipoAuto !== TypeAuto.ELECTRIC
      ) {
        this.mobilityData.capienzaBatteria = undefined
        this.mobilityData.wallbox = undefined
      }
      if (this.step < 2) {
        this.step++
      } else {
        this.saveSurvey()
        this.dialog = false
      }
    },
    selectMobility() {
      this.mobilityData = {
        tipoAuto: this.mobility.tipoAuto || '',
        capienzaBatteria: this.mobility.capienzaBatteria || '',
        wallbox: this.mobility.wallbox || '',
        interesse: this.mobility.interesse || '',
        saved: !!this.mobility.tipoAuto,
        completed: !!this.mobility.interesse || !!this.mobility.wallbox,
        edited: false
      }
    },
    openSurvey() {
      this.selectMobility()
      this.dialog = true
    },
    exitSurveyDialog() {
      this.setTypOnboardingMobility(false)
      this.trackTapEvent({
        name: 'profile2_surveyClose_tap',
        params: {
          survey_type: 'mobility',
          client_code: this.userProfile.clientOwnerCode
        }
      })
      if (this.step > 1) {
        this.step = this.step + 'exit'
      } else {
        this.dialog = false
      }
    },
    backToStep() {
      this.step = parseInt(this.step.replace('exit', ''))
    },
    saveSurvey() {
      this.mobilityData.saved = !!this.mobilityData.tipoAuto
      if (this.step === 2) {
        if (this.mobilityData.completed) {
          this.mobilityData.edited = true
        }
        this.mobilityData.completed = true
      }
      this.setMobility(this.mobilityData)
      this.mobilitySaveSurvey()
        .then(() => {
          if (this.step === 2) {
            this.dialogCompleted = true
          }
        })
        .catch(() => {
          AlertService(
            'Servizio non disponibile',
            'Al momento non è possibile salvare i questionari. Ti invitiamo a riprovare.',
            false,
            'Chiudi',
            '#'
          )
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './styleSurvey.scss';
</style>
