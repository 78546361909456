<template>
  <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="py-0 mb-10">
    <template v-slot:divider>
      <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
    </template>
  </v-breadcrumbs>
</template>
<script>
import { mapGetters } from 'vuex'
import { capitalize } from '@/js/utils'

export default {
  name: 'PunBreadcrumbs',
  data() {
    return {
      detail: {}
    }
  },
  beforeCreate() {
    this.$store
      .dispatch('supply/getDetail', {
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      })
      .then((detail) => {
        this.detail = detail
      })
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    breadcrumbsItems() {
      let address = this.detail ? this.detail.address : ''
      let res = []
      res = [
        { to: { name: 'home' }, text: 'Home' },
        {
          to: { name: 'ownerSupplies' },
          text: 'I tuoi Servizi'
        },
        {
          to: { name: 'ownerSupplies' },
          text: 'Servizi e Consumi',
          exact: true
        },
        { to: { name: 'supplyDetail' }, text: capitalize(address) },
        { to: { name: 'punDetail' }, text: 'Andamento PUN orario' }
      ]
      return res
    }
  }
}
</script>
