<template>
  <div class="flow std-side-padding mb-12 pt-10">
    <div class="stepFlow">
      <div class="step">
        <div class="label-step complete">OFFERTA</div>
        <div :class="`circle ${flowOffert.completed ? 'complete' : ''}`">
          <span>1</span>
        </div>
        <v-progress-linear
          :value="(100 / flowOffert.list.length) * flowOffert.index"
          color="#5eb83d"
          background-color="transparent"
        ></v-progress-linear>
      </div>
      <div class="step">
        <div :class="`label-step ${step > 3 ? 'complete' : ''}`">DATI</div>
        <div :class="`circle ${flowData.completed ? 'complete' : ''}`">
          <span>2</span>
        </div>
        <v-progress-linear
          :value="(100 / flowData.list.length) * flowData.index"
          color="#5eb83d"
          background-color="transparent"
        ></v-progress-linear>
      </div>
      <div :class="`step ${step === 12 ? 'hidden' : ''}`">
        <div :class="`label-step ${step > 12 ? 'complete' : ''}`">CONFERMA</div>
        <div :class="`circle ${flowConferm.completed ? 'complete' : ''}`">
          <span>3</span>
        </div>
        <v-progress-linear
          :value="(100 / flowConferm.list.length) * flowConferm.index"
          color="#5eb83d"
          background-color="transparent"
        ></v-progress-linear>
      </div>
    </div>
    <div class="flow__back mb-6" @click="prevStep">
      <span>{{ title }}</span>
    </div>
    <Offert
      v-if="step === 1"
      @nextStep="nextStep"
      @goToStep="goToStep"
    ></Offert>
    <Modem v-if="step === 2" @nextStep="nextStep" @goToStep="goToStep"></Modem>
    <Repeater v-if="step === 3" @nextStep="nextStep"></Repeater>
    <Procedure
      v-if="step === 4"
      @nextStep="nextStep"
      @goToStep="goToStep"
      @setFlowType="setFlowType"
    ></Procedure>
    <MigrationCode
      v-if="step === 5"
      @nextStep="nextStep"
      @goToStep="goToStep"
      @setFlowType="setFlowType"
    ></MigrationCode>
    <TelephoneNumber v-if="step === 6" @nextStep="nextStep"></TelephoneNumber>
    <Activation v-if="step === 7" @nextStep="nextStep"></Activation>
    <IdentityDocument v-if="step === 8" @nextStep="nextStep"></IdentityDocument>
    <Payment
      v-if="step === 9"
      @nextStep="nextStep"
      @prevStep="prevStep"
      :editMode="editMode"
      @goToStep="goToStep"
    ></Payment>
    <ShippingAddress v-if="step === 10" @nextStep="nextStep"></ShippingAddress>
    <InfoConsent
      v-if="step === 11"
      @nextStep="nextStep"
      @goToStep="goToStep"
    ></InfoConsent>
    <UploadBill
      v-if="step === 12"
      @nextStep="nextStep"
      @goToStep="goToStep"
      :isCallMeBack="isCallMeBack"
    ></UploadBill>
    <CheckData
      v-if="step === 13"
      @nextStep="nextStep"
      @goToEdit="goToEdit"
    ></CheckData>
    <SummaryFiber v-if="step === 14" @nextStep="nextStep"></SummaryFiber>
    <SpeakWith
      v-if="step === 15"
      @goToStep="goToStep"
      @setFlowType="setFlowType"
      @setCallMeBack="setCallMeBack"
    ></SpeakWith>
  </div>
</template>
<script>
import Offert from './offert/Offert.vue'
import Modem from './offert/Modem.vue'
import Repeater from './offert/Repeater.vue'
import Procedure from './data/Procedure.vue'
import MigrationCode from './data/MigrationCode.vue'
import TelephoneNumber from './data/TelephoneNumber.vue'
import Activation from './data/ActivationTimes.vue'
import IdentityDocument from './data/IdentityDocument.vue'
import Payment from './data/Payment.vue'
import ShippingAddress from './data/ShippingAddress.vue'
import InfoConsent from './data/InfoConsent.vue'
import UploadBill from './data/UploadBill.vue'
import SummaryFiber from './summary/SummaryFiber.vue'
import CheckData from './summary/CheckData.vue'
import SpeakWith from './offert/SpeakWith.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'fiberFlow',
  data() {
    return {
      step: 1,
      historyStep: [{ step: 1, trackEventName: '' }],
      stepTitle: [
        '',
        'Preventivo offerta',
        'Modem gratuito',
        'Estendi la tua rete',
        'Come vuoi procedere?',
        'Codice migrazione',
        'Hai un numero di telefono fisso?',
        'Tempi di attivazione',
        'Aggiungi un documento',
        'Aggiungi metodo di pagamento',
        'Indirizzo di spedizione',
        'Informazioni e consensi',
        'Carica la tua bolletta',
        'Sottoscrizione',
        'Il riepilogo della tua offerta',
        'Vuoi parlare con un operatore?'
      ],
      flowType: '',
      flowOffert: {
        list: [1, 2, 3],
        index: 1,
        completed: false
      },
      flowData: {
        list: [4, 5, 6, 7, 8, 9, 10, 11, 12],
        index: 0,
        completed: false
      },
      flowConferm: {
        list: [13, 14],
        index: 0,
        completed: false
      },
      editMode: false,
      isCallMeBack: false
    }
  },
  created() {
    this.getFlagRepeater()
  },
  computed: {
    ...mapGetters('upsellingFiber', ['addressCovered']),
    ...mapGetters('upsellingFiber', ['flagRepeater']),
    title() {
      if (this.isCallMeBack) {
        return 'Dati di contatto'
      } else {
        return this.stepTitle[this.step]
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('upsellingFiber', ['updateFiberCtx']),
    ...mapActions('upsellingFiber', ['getFlagRepeater']),
    nextStep(trackEventName) {
      this.step++
      this.historyStep.push({ step: this.step, trackEventName: trackEventName })
      this.setFlow('')
      const addressCovered =
        this.$store.getters['upsellingFiber/addressCovered']
      this.trackTapEvent({
        name: trackEventName,
        params: {
          client_selected: addressCovered
            ? addressCovered['clientOwnerCode'] || ''
            : 'not_selected'
        }
      })
      this.updateFiberCtx({ upselling_step_name: this.stepTitle[this.step] })
    },
    prevStep() {
      this.historyStep.pop(1)
      if (this.historyStep.length === 0) {
        this.$router.push({ name: 'fiberOffering' })
        return false
      }
      this.editMode = false
      this.step = this.historyStep[this.historyStep.length - 1].step
      const addressCovered =
        this.$store.getters['upsellingFiber/addressCovered']
      this.trackTapEvent({
        name: this.historyStep[this.historyStep.length - 1].trackEventName,
        params: {
          client_selected: addressCovered
            ? addressCovered['clientOwnerCode'] || ''
            : 'not_selected'
        }
      })
      this.updateFiberCtx({ upselling_step_name: this.stepTitle[this.step] })
      this.setFlow()
    },
    goToStep(step, trackEventName, pageName) {
      this.step = step
      this.historyStep.push({ step: this.step, trackEventName: trackEventName })
      this.setFlow()
      const addressCovered =
        this.$store.getters['upsellingFiber/addressCovered']
      this.trackTapEvent({
        name: trackEventName,
        params: {
          client_selected: addressCovered
            ? addressCovered['clientOwnerCode'] || ''
            : 'not_selected',
          page_name: pageName || undefined
        }
      })
      this.updateFiberCtx({ upselling_step_name: this.stepTitle[step] })
    },
    goToEdit(step, trackEventName) {
      this.step = step
      this.historyStep.push({ step: this.step, trackEventName: trackEventName })
      this.editMode = true
    },
    setFlowType(flow) {
      this.flowType = flow
      this.$forceUpdate()
    },
    setCallMeBack(val) {
      this.isCallMeBack = val
      this.flowOffert.index = this.flowOffert.list.length + 1
      this.flowOffert.completed = true
    },
    setFlow() {
      let index
      if (this.flowOffert.list.includes(this.step)) {
        this.flowData.index = 0
        this.flowData.completed = false
        index = this.flowOffert.list.indexOf(this.step) + 1
        this.flowOffert.index = index
        this.flowOffert.completed = index === this.flowOffert.list.length
      } else if (this.flowData.list.includes(this.step)) {
        this.flowConferm.index = 0
        this.flowConferm.completed = false
        if (this.step === 4) {
          this.flowData.list = [4, 5, 6, 7, 8, 9, 10, 11, 12]
        } else if (this.flowType === 'changeSupply') {
          this.flowData.list = [4, 5, 6, 7, 8, 9, 10, 11]
        } else if (this.flowType === 'newConnection') {
          this.flowData.list = [4, 7, 8, 9, 10, 11]
        } else if (this.flowType === 'support') {
          this.flowData.list = [4, 12]
        }
        index = this.flowData.list.indexOf(this.step) + 1
        this.flowData.index = index
        this.flowData.completed = index === this.flowData.list.length
      } else if (this.flowConferm.list.includes(this.step)) {
        index = this.flowConferm.list.indexOf(this.step) + 1
        this.flowConferm.index = index
        this.flowConferm.completed = index === this.flowConferm.list.length
      } else if (this.step === 15) {
        this.flowData.completed = false
        this.flowData.index = 0
        this.flowOffert.completed = false
        this.flowOffert.index = 2
      }
      if (!this.flagRepeater && this.step > 1) {
        this.flowOffert.completed = true
        this.flowOffert.index = this.flowOffert.index + 1
      }
    }
  },
  components: {
    Offert,
    Modem,
    Repeater,
    Procedure,
    MigrationCode,
    TelephoneNumber,
    Activation,
    IdentityDocument,
    Payment,
    ShippingAddress,
    InfoConsent,
    UploadBill,
    CheckData,
    SummaryFiber,
    SpeakWith
  }
}
</script>
<style lang="scss" scoped>
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.flow {
  border-top: 1px solid darkgray;
  &__back {
    margin-top: 48px;
    display: flex;
    align-items: center;
    width: fit-content;
    span {
      font-size: 22px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.8px;
      color: #12256a;
      cursor: pointer;
    }
    &::before {
      content: url('../../assets/ic-chevron-sx-blue.svg');
      width: 24px;
      margin-right: 16px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  .v-progress-linear {
    position: absolute;
    top: 15px;
    left: 0;
  }
  .stepFlow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .step {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      text-align: right;
      position: relative;
      &.hidden {
        opacity: 0;
      }
      .label-step {
        color: #ccd0e1;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
        &.complete {
          color: black;
        }
      }
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 21px;
        font-weight: 500;
        color: #ccd0e1;
        z-index: 1;
        background: white;
        border: 2px solid;
        border-color: #ccd0e1;
        transition: all 0.5s ease;
        span {
          opacity: 1;
          transition: opacity 0.2s ease;
        }
        &::after {
          content: url('../../assets/ic-check-white.svg');
          position: absolute;
          top: 2px;
          opacity: 0;
          transition: opacity 0.1s ease;
        }
        &.complete {
          animation: bounce 0.5s ease-in-out;
          animation-delay: 0.2s;
          background-color: #5eb83d;
          border-color: #5eb83d;
          color: #fff;
          position: relative;
          span {
            opacity: 0;
            transition: opacity 0.1s ease;
          }
          &::after {
            opacity: 1;
          }
        }
      }
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background: #ccd0e1;
        top: 15px;
        left: 0;
        z-index: 0;
      }
    }
  }
}
</style>
