<template>
  <div class="menu" v-if="loaded">
    <v-menu open-on-hover content-class="menu__item__support">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="menu__item"
          v-bind:class="{ active: isActive === 'home' }"
          @click="goToHome"
          v-bind="attrs"
          v-on="on"
        >
          HOME
        </div>
      </template>
    </v-menu>
    <v-menu open-on-hover content-class="menu__item__support">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="menu__item"
          v-bind:class="{ active: isActive === 'profile' }"
          @click="goToAccount"
          v-bind="attrs"
          v-on="on"
        >
          PROFILO
          <div class="menu__item__info_news" v-if="showProfileNews"></div>
        </div>
      </template>
    </v-menu>
    <v-menu
      open-on-hover
      :close-delay="closeDelay"
      offset-y
      nudge-bottom="32"
      nudge-width="100%"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="menu__item"
          v-bind:class="{ active: isActive === 'summary' }"
          v-bind="attrs"
          v-on="on"
        >
          I TUOI SERVIZI
        </div>
      </template>
      <v-list>
        <v-list-item @click="trackTap('UTENZE')">
          <router-link class="menu__link" :to="{ name: 'ownerSupplies' }"
            ><span>SERVIZI E CONSUMI</span></router-link
          >
        </v-list-item>
        <v-list-item
          @click="trackTap('RIEPILOGO PAGAMENTI')"
          v-if="filteredClientDetails?.clientOwnerCode"
        >
          <div class="menu__link" @click="openPaymentStatusSummary">
            <span>RIEPILOGO PAGAMENTI</span>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
    <template v-if="supplyList">
      <v-menu open-on-hover content-class="menu__item__support">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="showService"
            class="menu__item"
            v-bind:class="{ active: isActive === 'service' }"
            @click="$router.push({ name: 'entryPage' })"
            v-bind="attrs"
            v-on="on"
          >
            SORGENIA PER TE
          </div>
        </template>
      </v-menu>
      <v-menu
        open-on-hover
        :close-delay="closeDelay"
        offset-y
        nudge-bottom="32"
        nudge-width="100%"
        v-if="
          (showLoyalty || showCFP || showWelcomeKit) &&
          !accountProperties.isOnlyPhotovoltaic
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="menu__item"
            v-bind:class="{ active: isActive === 'awards' }"
            v-bind="attrs"
            v-on="on"
          >
            PREMI E INIZIATIVE
          </div>
        </template>
        <v-list>
          <v-list-item
            v-if="showLoyalty"
            @click="trackTap('PROGRAMMA GREENERS')"
          >
            <div class="menu__link" @click="goToLoyalty">
              <span>PROGRAMMA GREENERS</span>
            </div>
          </v-list-item>
          <v-list-item @click="trackTap('CHECKUP ENERGETICO')">
            <div class="menu__link" @click="goToCheckup">
              <span>CHECK-UP ENERGETICO</span>
            </div>
          </v-list-item>
          <v-list-item
            v-if="showCFP"
            @click="trackTap('MISURA IL TUO IMPATTO')"
          >
            <div class="menu__link" @click="openFootprint">
              <span>MISURA IL TUO IMPATTO</span>
            </div>
          </v-list-item>
          <v-list-item
            v-if="showWelcomeKit"
            @click="trackTap('IL VALORE DI SORGENIA')"
          >
            <router-link class="menu__link" :to="{ name: 'welcomekit' }"
              ><span>IL VALORE DI SORGENIA</span></router-link
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-menu open-on-hover content-class="menu__item__support">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="menu__item"
          v-bind:class="{ active: isActive === 'support' }"
          @click="goToSupport"
          v-bind="attrs"
          v-on="on"
        >
          ASSISTENZA
        </div>
      </template>
    </v-menu>
    <!-- DEBITO TECNICO: eliminare dialog -->
    <v-dialog v-model="showSelectClient" max-width="1140">
      <v-row>
        <v-col class="owner-dialog">
          <img
            @click="showSelectClient = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h2>Seleziona profilo</h2>
          <h5>
            Scegli con quale profilo vuoi accedere a
            <strong>MyShop Sorgenia</strong> e scopri i prodotti dedicati.
          </h5>
          <ExtensibleButtonGroup
            :per-row="2"
            v-model="selectedClient"
            :content="false"
            groupName="myShopclients"
          >
            <ExtensibleButton
              v-for="(client, i) in myShopclients"
              :key="i"
              :name="client"
            >
              <template v-slot:label>
                <h5>
                  {{
                    client.clientOwnerType === 'RES'
                      ? client.firstName + ' ' + client.lastName
                      : client.businessName
                  }}
                </h5>
              </template>
            </ExtensibleButton>
          </ExtensibleButtonGroup>
          <v-row>
            <v-col cols="6" :offset="4">
              <v-btn
                color="primary"
                :disabled="!selectedClient"
                large
                width="303"
                class="v-btn--shadowed"
                @click="
                  $router
                    .push({
                      name: 'myshop',
                      params: {
                        clientCode: selectedClient.clientOwnerCode,
                        token: token
                      }
                    })
                    .catch(() => {})
                "
                >Prosegui</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- DEBITO TECNICO -->
    <PaymentStatusSummary
      v-model="dialog"
      :dialog="dialog"
      @closeModal="dialog = false"
    />
    <CfpDialog
      :showFootprint="showFootprint"
      :cfpUrl="cfpUrl"
      :resetPropsCfp="resetPropsCfp"
    />
    <CheckupDialog
      v-if="mainClient"
      :showCheckup.sync="showCheckup"
      :checkupUrl="checkupUrl"
      :user="mainClient"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import { eventBus } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import PaymentStatusSummary from '../../views/supplies/PaymentStatusSummary.vue'
import CfpDialog from '@/components/cfp/CfpDialog'
import CheckupDialog from '@/components/checkup_energetico/CheckupDialog.vue'

import { ENV_CONFIGS } from '@/js/configs'
import { getMenuActive } from '../../js/service/menuHelper'

import { accessGreeners } from '../../js/greeners/accessGreeners'
import Constants from '@/js/constants'

Vue.use(VueScrollTo)

export default {
  name: 'menuNew',
  data() {
    return {
      dialog: false,
      supplyList: null,
      showSelectClient: false,
      selectedClient: null,
      cfpUrl: '',
      showFootprint: false,
      loaded: true,
      closeDelay: '200',
      isActive: 'home',
      showProfileNews: false,
      showCheckup: false,
      checkupUrl: ''
    }
  },
  components: {
    PaymentStatusSummary,
    CfpDialog,
    CheckupDialog
  },
  computed: {
    ...mapGetters('account', [
      'accountClients',
      'filteredClientDetails',
      'mainClientLoyaltyRes',
      'mainClientLoyaltyBus',
      'oldestLoyaltyRes',
      'oldestLoyaltyBus',
      'listMainClientLoyalty',
      'accountProperties',
      'isMultipleMainClients',
      'isZeroMainClients',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    ...mapGetters('myshop', ['myShopEnabled', 'myShopAudience']),
    ...mapGetters('loyalty', [
      'memberStatus',
      'transactionList',
      'NEW_MEMBER_STATUS_ERROR'
    ]),
    ...mapGetters('welcomekit', ['welcomekit']),
    ...mapGetters('feedback', ['client']),
    ...mapGetters('supply', ['isNotAllPlacet']),
    ...mapGetters('session', ['token']),
    // DEBITO TECNICO: eliminare lista clienti myShop
    myShopclients() {
      let clients = []
      let self = this
      if (this.accountClients) {
        Object.keys(this.accountClients).forEach((client) => {
          if (
            this.myShopAudience &&
            this.myShopAudience.includes(
              self.accountClients[client].clientOwnerType
            )
          ) {
            clients.push(self.accountClients[client])
          }
        })
        return clients
      } else {
        return []
      }
    },
    // DEBITO TECNICO TO CHECK, ESISTE GIA MAINCLIENT.ISONLYFV
    showService() {
      return (
        this.accountProperties.detailedType.includes('RES') &&
        !this.accountProperties.isOnlyPhotovoltaic
      )
    },
    currentClientTypes() {
      if (!this.accountProperties.type) return ''
      let isClientDigital = this.accountProperties.hasAtLeast1Digital
      let isClientPlacet = !this.isNotAllPlacet
      let clientTypeStr = this.accountProperties.type

      let suffix = isClientPlacet
        ? 'placet'
        : isClientDigital
        ? 'digital'
        : 'nondigital'

      let clientTypes =
        clientTypeStr === 'MIXED'
          ? ['RES-' + suffix, 'BUS-' + suffix]
          : [clientTypeStr + '-' + suffix]

      return clientTypes
    },
    showLoyalty() {
      if (this.mainClient.isOnlyFV) return false

      let listMain = this.listMainClientLoyalty.map((m) => m.clientOwnerCode)
      return (
        this.supplyList.filter(
          (sup) =>
            (sup.supplyState === Constants.SUPPLY_STATUS.ACTIVE ||
              sup.supplyState === Constants.SUPPLY_STATUS.ACTIVATING) &&
            listMain.includes(sup.clientOwnerCode)
        ).length > 0 && this.isNotAllPlacet
      )
    },
    showWelcomeKit() {
      return this.accountProperties.type !== 'RES'
    },
    showCFP() {
      return this.isNotAllPlacet
    }
  },
  methods: {
    // DEBITO TECNICO: eliminare riferimenti a myShop --> getMyShopConfig
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('myshop', ['getMyShopConfig']),
    ...mapActions('account', ['getClientsForAccount']),
    ...mapActions('loyalty', [
      'newTransaction',
      'getTransactionsListNewEdition'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('feedback', ['loadSurvey', 'loadUndoneSurvey']),
    trackTap(itemTitle) {
      this.trackTapEvent({
        name: 'menu_item_tap',
        params: { menu_item_title: itemTitle.toLowerCase() }
      })
    },
    getOnboardingStorage() {
      let onboardingStorage = JSON.parse(
        localStorage.getItem('onboardingProfile')
      )
      if (
        (!onboardingStorage ||
          !onboardingStorage[this.mainClient.clientOwnerCode]) &&
        this.accountProperties.detailedType.includes('RES')
      ) {
        this.showProfileNews = true
      } else {
        this.showProfileNews = false
      }
    },
    openPaymentStatusSummary() {
      if (Object.keys(this.accountClients).length === 1) {
        this.$router
          .push({
            name: 'paymentSummary',
            params: {
              clientCode: Object.values(this.accountClients)[0].clientOwnerCode
            }
          })
          .catch(() => {})
      } else {
        this.dialog = true
      }
    },
    // DEBITO TECNICO: eliminare metodo
    goToMyShop() {
      if (this.myShopclients.length > 1) {
        this.showSelectClient = true
      } else {
        // this.showSelectClient = true
        this.$router
          .push({
            name: 'myshop',
            params: {
              clientCode: this.myShopclients[0].clientOwnerCode,
              token: this.token
            }
          })
          .catch(() => {})
      }
    },
    async goToLoyalty() {
      let resp = await accessGreeners()
      if (resp === 'multiClients') {
        eventBus.$emit('greenersSelectClient', 'prova')
        return false
      }
      if (this.NEW_MEMBER_STATUS_ERROR) {
        this.$router
          .push({
            name: 'home',
            params: { typeError: 'IDENTITY_STATUS_ERROR' }
          })
          .catch(() => {})
      }
      this.$router.push({ name: 'loyaltyPage' }).catch(() => {})
    },
    openFootprint() {
      this.cfpUrl = `${ENV_CONFIGS.CFP_BASE_URL}/?clientCode=${
        this.mainClient.clientOwnerCode
      }&clientType=${this.mainClient.clientOwnerType}&name=${
        this.mainClient.firstName
      }&surname=${this.mainClient.lastName}&hasLightContracts=${
        this.accountProperties.hasLightActivating ||
        this.accountProperties.hasLightActive
      }&token=${this.token}&isWeb=true&mgmCode=${
        this.mainClient.mgmCode
      }&accountType=${this.accountProperties.type}&isWeb=true`
      if (this.isMultipleMainClients && !this.isZeroMainClients) {
        // Workaround to show the bonifica-alert also when openinig CFP. (redirect to loyalty which makes same checks since we have no route for CFP, which is open inside dialog in home)
        this.$router.push({ name: 'loyaltyPage' }).catch(() => {})
      } else {
        this.showFootprint = true
      }
    },
    resetPropsCfp() {
      this.showFootprint = false
      this.cfpUrl = ''
      if (this.$route.meta.openCfp) this.$router.push({ name: 'home' })
    },
    goToSupport() {
      this.trackTap('ASSISTENZA')
      this.trackTapEvent(this.buildTrackEventPoperties('support'))
      this.$router.push({ name: 'SupportPage' }).catch(() => {})
    },
    goToHome() {
      this.trackTap('HOME')
      this.$router.push({ name: 'home' }).catch(() => {})
    },
    goToAccount() {
      this.trackTap('PROFILO')
      this.$router.push({ name: 'account' }).catch(() => {})
    },
    goToCheckup() {
      this.checkupUrl = `${ENV_CONFIGS.CHECKUP_BASE_URL}?token=${this.token}`
      this.showCheckup = true
    },
    buildTrackEventPoperties(action) {
      const section = 'header'
      let name = ''
      switch (action) {
        case 'support':
          name = `${section}_support_tap`
          break
        case 'chat':
          name = `${section}_chat_tap`
          break
        case 'chatsales':
          name = `upsellingv2_chatsales_tap`
          break
      }

      let params = { page_name: this.$route.name }

      return { name, params }
    }
  },
  async beforeMount() {
    // DEBITO TECNICO: eliminare riferimenti a myShop --> myshop:open + getMyShopConfig + cercare nel progetto myshop:open
    eventBus.$on('footprint:open', this.openFootprint)
    eventBus.$on('myshop:open', this.goToMyShop)
    eventBus.$on('prifile:onboarding', this.getOnboardingStorage)
    eventBus.$on('checkup:open', this.goToCheckup)
    await this.getMyShopConfig()
    await this.getClientsForAccount()
    if (!this.mainClient.isOnlyFV) {
      this.supplyList = await this.getSupplies()
    }
    if (!this.mainClient.isOnlyFV) {
      this.getOnboardingStorage()
    }

    this.isActive = getMenuActive(this.$route.path)

    await this.$router.beforeResolve((to, from, next) => {
      this.isActive = getMenuActive(to.path)
      next()
    })
    if (!this.mainClient.isOnlyFV) {
      await this.loadSurvey()
    }

    if (!this.mainClient.isOnlyFV) {
      await this.loadUndoneSurvey()
    }

    if (!this.mainClient.isOnlyFV) {
      await this.$store.dispatch('welcomekit/getWelcomekitByUserProfile', {
        client: this.mainClient
      })
    }
    if (!this.mainClient.isOnlyFV) {
      await this.$store.dispatch('welcomekit/getTutorialByUserProfile', {
        client: this.mainClient
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-list-item:hover::before {
  opacity: 0;
}

.theme--light.v-list-item:hover .menu__link {
  color: #193494;
}

.menu {
  .active {
    box-shadow: 0px 2px 0px 0px #193494;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: $srg-blue;
    padding: 4px 8px 4px 8px;
    margin: 0 12px;
    cursor: pointer;

    &[aria-expanded='true'] {
      box-shadow: 0px 2px 0px 0px #193494;
    }

    &:hover {
      color: #193494;
    }

    &__support {
      display: none;
    }

    &__info_news {
      position: absolute;
      top: 2px;
      right: -12px;
      background-color: $secondari-magenta-100;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  &__link {
    color: $srg-blue;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    height: 35px;
    display: flex;
    align-items: center;
  }
}

.v-menu__content {
  overflow: initial;
  contain: initial;
  border-radius: 0px;
  padding: 10px 12px 12px 12px;
  background: #f7faff;
  box-shadow: none;
  // &::before {
  //   content: '';
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 0 10px 15px 10px;
  //   border-color: transparent transparent white transparent;
  //   position: absolute;
  //   top: -10px;
  //   left: calc(50% - 15px);
  // }
}

.v-list {
  padding: 0;
  background: #f7faff;
  min-width: 190px;
}

.v-list-item {
  min-height: 35px;
  cursor: pointer;
}

.v-dialog__content {
  .owner-dialog {
    background: white;
    padding: 48px 58px 24px 48px;
    border-radius: 40px;
    position: relative;

    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .owner {
      display: flex;
      align-items: center;
      width: 458px;
      height: 66px;
      border-radius: 20px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff;
      padding: 0 32px;
      margin-bottom: 24px;
      cursor: pointer;

      &.disabled {
        cursor: initial;

        h5 {
          opacity: 0.3;
        }
      }
    }
  }
}
</style>
