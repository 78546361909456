<template>
  <div class="telephone">
    <section v-if="!hasPhone">
      <div class="telephone__box" @click="hasPhone = true">
        <h5>Ho un numero di telefono fisso</h5>
      </div>
      <div class="telephone__box mb-12" @click="removePhone">
        <h5>Non ho un numero di telefono fisso</h5>
      </div>
      <div class="why">
        <h5 class="bold">Perchè te lo chiediamo?</h5>
        <h5>
          Il numero di telefono fisso serve per completare la migrazione. Lo
          utilizzeremo per cessare il contratto con il tuo precedente fornitore.
        </h5>
      </div>
    </section>
    <section v-else>
      <v-text-field
        id="phone"
        type="number"
        label="Inserisci il numero di telefono"
        class="auth__input"
        v-model.trim="phone"
        :error-messages="phoneErrors"
        @blur="v$.phone.$touch()"
      ></v-text-field>
      <div class="d-flex align-center justify-space-between">
        <h5>Questo è l'unico numero associato al contratto Internet</h5>
        <v-switch v-model="singleNumber" color="#97D707" inset></v-switch>
      </div>
      <v-btn
        color="primary"
        x-large
        width="260"
        class="v-btn--shadowed"
        @click="addPhone"
        :disabled="v$.$invalid"
        >Continua</v-btn
      >
      <v-btn
        text
        x-large
        color="primary"
        width="300"
        class="white ml-5"
        @click="hasPhone = false"
        >Annulla</v-btn
      >
    </section>
  </div>
</template>
<script>
import { maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'telephoneNumber',
  data() {
    return {
      phone: '',
      singleNumber: false,
      hasPhone: false
    }
  },
  beforeMount() {
    this.phone = this.migrationCode.telefonoFisso || ''
    this.singleNumber =
      this.migrationCode.flagAltriNumeri !== undefined
        ? !this.migrationCode.flagAltriNumeri
        : false
  },
  watch: {
    hasPhone(val) {
      if (val) {
        this.trackTapEvent({
          name: 'upsellingv2_hasphonenumber_tap',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else {
        this.trackTapEvent({
          name: 'upsellingv2_hasphonenumber_undo',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['migrationCode']),
    phoneErrors() {
      let errArray = []

      if (!this.v$.phone.$dirty) return errArray

      this.v$.phone.minLength.$invalid === true &&
        errArray.push('Il numero di telefono deve essere di almeno 6 cifre')
      this.v$.phone.maxLength.$invalid === true &&
        errArray.push('Il numero di telefono può avere al massimo 12 cifre')
      this.v$.phone.required.$invalid === true &&
        errArray.push('Il Numero di telefono è obbligatorio')

      return errArray
    }
  },
  methods: {
    ...mapMutations('upsellingFiber', ['setMigrationCode']),
    ...mapActions('analytics', ['trackTapEvent']),
    addPhone() {
      this.setMigrationCode({
        ...this.migrationCode,
        telefonoFisso: this.phone,
        flagAltriNumeri: !this.singleNumber
      })
      this.$emit('nextStep', 'upsellingv2_activationtimes_open')
    },
    removePhone() {
      this.setMigrationCode({
        ...this.migrationCode,
        telefonoFisso: '',
        flagAltriNumeri: false
      })
      this.trackTapEvent({
        name: 'upsellingv2_nophonenumber_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      this.$emit('nextStep', 'upsellingv2_activationtimes_open')
    }
  },
  validations: {
    phone: { required, minLength: minLength(6), maxLength: maxLength(12) }
  }
}
</script>
<style lang="scss" scoped>
.telephone {
  max-width: 652px;
  color: black;
  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 652px;
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    cursor: pointer;
    h5 {
      max-width: 600px;
    }
    &::after {
      content: url('../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
  .bold {
    font-weight: 500;
  }
}
</style>
