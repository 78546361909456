<template>
  <layout class="coins" header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
        <h1 class="nowrap">Saldo Green Coins</h1>
        <v-row class="header-info">
          <v-col class="pl-0 pt-4" cols="2">
            <v-img
              width="72"
              :src="require('@/assets/greeners/svg/greeners_black.svg')"
            />
          </v-col>
          <v-col cols="9" class="pl-4 pr-0">
            <h2 class="font-weight-bold header-info__coins HeadingsDisplayXL">
              {{ (memberBalance.user_balance || 0).toLocaleString('it-IT') }}
            </h2>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="5" class="pl-0">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <template class="coins__content">
      <v-col class="pa-0 ma-0">
        <v-row class="mt-12">
          <v-col>
            <h2 class="mb-6 HeadingsTitleXXL">
              Ecco come hai accumulato i tuoi Green Coins:
            </h2>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-progress-circular
          v-if="loaded"
          class="loader fixed-loader"
          color="#12256A"
          :size="100"
          indeterminate
        ></v-progress-circular>
        <v-row
          v-for="(item, i) in filteredTransactionList"
          :key="i"
          class="coins__content__info"
          v-else
        >
          <v-col cols="11">
            <div class="content-header">
              <span class="BodyS">{{
                item.date.split(' ')[0].split('-').reverse().join('.')
              }}</span>
              <span class="content-header__status BodyS">{{
                item.event === '1500' ? 'MISSIONE' : null
              }}</span>
            </div>
            <div class="content-info">
              <h3 class="content-info__title HeadingsSubtitleL">
                {{ transactionLabel(item) }}
              </h3>
              <div class="content-info__coins">
                <span class="BodyS mr-2">Green Coins</span>
                <span class="content-info__coins--md HeadingsTitleM-Upper"
                  >{{ item.points > 0 ? '+' : '' }}{{ item.points }}</span
                >
              </div>
            </div>
          </v-col>
          <v-col cols="1">
            <img width="48" :src="require('@/assets/' + userStatus(item))" />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-pagination
          v-if="showPagination"
          class="mt-5"
          v-model="page"
          :length="totalPages"
          :total-visible="5"
          color="#97D707"
        ></v-pagination>
        <!-- <v-row class="mt-10" justify="center">
            <v-btn
              class="v-btn--shadowed mb-4"
              color="brightGreen"
              @click="$router.push({name: 'rulesPage'})"
              x-large
              width="265"
            >
              Mostra altro
            </v-btn>
        </v-row> -->
      </v-col>
    </template>
  </layout>
</template>

<script>
import Layout from './../Layout'
import { mapActions, mapGetters } from 'vuex'
import { getTransactionLabel, chunkArray } from '@/js/utils'
import { PAGINATION_GREENERS_COINS } from '../../../js/constants'
export default {
  name: 'CoinsPage',
  components: {
    Layout
  },
  data() {
    return {
      lastLevelStatus: '',
      page: 1,
      loaded: true
    }
  },
  async mounted() {
    let res = await this.getMainClient()
    if (this.mainClientLoyaltySelected) {
      res = this.mainClientLoyaltySelected
    }
    await this.getDetailForClient({ clientOwnerCode: res.clientOwnerCode })
    await this.getBalance({
      ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
    })
    await this.getTransactionsList({
      ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
    }).then((resp) => {
      this.loaded = false
    })
  },
  computed: {
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    ...mapGetters('loyalty', ['memberBalance', 'transactionList']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        { to: { name: 'loyaltyPage' }, text: 'Diario Greeners', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Saldo coins' }
      ]

      return res
    },
    totalPages() {
      if (!this.transactionList || this.transactionList.length === 0) return 0
      return chunkArray(this.transactionList, PAGINATION_GREENERS_COINS).length
    },
    filteredTransactionList() {
      const allowedActions = [
        '11',
        '31',
        '62',
        '92',
        '51',
        '140',
        '1211',
        '1507',
        '1504',
        '1541',
        '1542',
        '1543',
        '1544',
        '1545',
        '1506',
        '1561',
        '1562',
        '1563',
        '1564',
        '1565',
        '1505',
        '1551',
        '1552',
        '1553',
        '1554',
        '1555',
        '1407',
        '1406',
        '1408',
        '1503',
        '1531',
        '1532',
        '1533',
        '1534',
        '1535'
      ]
      if (!this.transactionList || this.transactionList.length === 0) return []

      let list = this.transactionList.filter((tr) => {
        return allowedActions.includes(tr.action) && tr.points !== 0
      })
      if (list.length > 0) {
        const array = chunkArray(
          this.transactionList,
          PAGINATION_GREENERS_COINS
        )
        return array[this.page - 1]
      } else {
        return []
      }
    },
    showPagination() {
      return this.transactionList.length > PAGINATION_GREENERS_COINS
    }
  },
  methods: {
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    ...mapActions('loyalty', ['getTransactionsList', 'getBalance']),
    userStatus(transaction) {
      let level = ''
      if (transaction.detail2.indexOf('LEVEL') === 0) {
        level = transaction.detail2
      } else if (transaction.detail1.indexOf('LEVEL') === 0) {
        level = transaction.detail1
      } else if (transaction.detail3.indexOf('LEVEL') === 0) {
        level = transaction.detail3
      } else {
        level = this.lastLevelStatus || 'LEVEL0'
      }
      this.lastLevelStatus = level

      switch (level) {
        case 'LEVEL0':
          return 'greeners/svg/beginner.svg'
        case 'LEVEL1':
          return 'greeners/svg/follower.svg'
        case 'LEVEL2':
          return 'greeners/svg/friend.svg'
        case 'LEVEL3':
          return 'greeners/svg/influencer.svg'
        case 'LEVEL4':
          return 'greeners/svg/ambassador.svg'
      }
    },
    transactionLabel(transaction) {
      return getTransactionLabel(transaction)
    }
  }
}
</script>
<style lang="scss">
.coins {
  .v-pagination__item:focus,
  .v-pagination__navigation:focus {
    outline: none !important;
  }
  .v-pagination__navigation--disabled {
    display: none;
  }
  .v-pagination {
    li:first-child {
      margin-right: 45px;
      .v-pagination__navigation {
        box-shadow: initial !important;
        -webkit-box-shadow: initial !important;
        &::after {
          content: 'Precedente';
          font: 20px;
          font-weight: bold;
        }
        &::before {
          content: '';
        }
      }
    }
    li:last-child {
      margin-left: 25px;
      .v-pagination__navigation {
        box-shadow: initial !important;
        -webkit-box-shadow: initial !important;
        &::before {
          content: 'Avanti';
          font: 20px;
          font-weight: bold;
        }
        &::after {
          content: '';
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.coins {
  .header-main {
    padding-bottom: 14px;
  }
  .header-info {
    &__coins {
      margin-left: -25px;
    }
  }
  &__content {
    &__title {
      h2 {
        margin-top: 64px;
        margin-bottom: 30px;
      }
    }
    &__info {
      .col.col-11 {
        position: relative;
      }
      .content-header {
        margin-bottom: 12px;
        &__status {
          margin-left: 8px;
          font-weight: bold;
          color: $srg-blue;
        }
      }
      .content-info {
        &__title {
          display: inline;
          font-weight: bold;
        }
        &__coins {
          float: right;
          font-weight: bold;
          display: flex;
          align-items: center;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          &--md {
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
