<template>
  <v-dialog v-model="dialog" width="558" persistent="persistent">
    <div class="alert">
      <img
        class="alert__close"
        @click="closeAlert"
        src="@/assets/ic_chevron_top_black.png"
        v-if="!persistent"
      />
      <v-img class="alert__img mb-8" :src="alert.img" />
      <div class="alert__title">
        {{ alert.title }}
      </div>
      <div class="alert__message">
        {{ alert.message }}
      </div>
      <div class="alert__btn">
        <v-btn
          v-if="alert.btnTextL"
          x-large
          class="v-btn--no--shadowed bold mr-4"
          width="231"
          height="54"
          color="white"
          rounded
          @click="alert.btnActionL"
        >
          {{ alert.btnTextL }}
        </v-btn>
        <v-btn
          x-large
          class="white--text v-btn--shadowed"
          width="231"
          height="54"
          color="#12256A"
          :rounded="true"
          @click="alert.btnAction"
        >
          {{ alert.btnText }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import alertImg from '../../assets/alert.png'
import errorImg from '../../assets/error.png'
import succesImg from '../../assets/greeners/svg/code-redemption-success.svg'
import { eventBus } from '@/main'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'alertError',
  props: ['type', 'showAlert'],
  data() {
    return {
      genericType: '',
      alertImg: alertImg,
      errorImg: errorImg,
      succesImg: succesImg,
      title: false,
      message: false,
      imgPath: false,
      btnTextLeft: false,
      btnActionLeft: false,
      btnTextRight: false,
      btnActionRight: false,
      btnActionRightParams: false,
      showGenericError: false,
      persistent: false
    }
  },
  beforeMount() {
    eventBus.$on('generic:error', (params) => {
      this.genericType = 'GENERIC_MESSAGE'
      this.showGenericError = true
      this.persistent = params.persistent
      this.title = params.title || false
      this.message = params.message || false
      this.imgPath = this.getImg(params.imgType)
      this.btnTextLeft = params.btnTextLeft || false
      this.btnActionLeft = params.btnActionLeft || false
      this.btnTextRight = params.btnTextRight || false
      this.btnActionRight = params.btnActionRight || false
      this.btnActionRightParams = params.btnActionRightParams || false
    })
  },
  computed: {
    ...mapState('session', ['isFeedbackShowing']),
    dialog: {
      get() {
        return this.genericType === 'GENERIC_MESSAGE'
          ? this.showGenericError
          : this.showAlert
      },
      set(val) {
        this.showGenericError = false
        this.$emit('update:showAlert', val)
      }
    },
    alert() {
      const typeAlert = this.type || this.genericType
      switch (typeAlert) {
        case 'GENERIC_MESSAGE':
          return {
            img: this.imgPath,
            title: this.title || 'Servizio non disponibile',
            message:
              this.message ||
              'Al momento non è possibile connettersi al server. Ti invitiamo a riprovare.',
            btnText: this.btnTextRight || 'Vai in Home',
            btnAction: () => {
              if (!this.isFeedbackShowing) {
                this.goTo(this.btnActionRight)
              }
              this.closeAlert()
            },
            btnTextL: this.btnTextLeft,
            btnActionL: () => this.goTo(this.btnActionLeft)
          }
        case 'GENERIC_ERROR_HOME':
          return {
            img: this.alertImg,
            title: 'Riprova più tardi',
            message:
              'Per un errore temporaneo non riusciamo a farti accedere. Non preoccuparti: i tuoi dati sono al sicuro e presto potrai visualizzare questa sezione.',
            btnText: 'Torna indietro',
            btnAction: this.closeDialog
          }
        case 'GENERIC_ERROR_DIARY':
          return {
            img: this.alertImg,
            title: 'Riprova più tardi',
            message:
              'Siamo spiacenti ma per un errore temporaneo non riusciamo a farti proseguire. Non preoccuparti: i tuoi dati sono al sicuro.',
            btnText: 'Torna indietro',
            btnAction: this.closeDialog
          }
        case 'GENERIC_ERROR_MISSIONS':
          return {
            img: this.alertImg,
            title: 'Riprova più tardi',
            message:
              'Purtroppo per un errore temporaneo non è possibile completare la tua missione. ',
            btnText: 'Torna indietro',
            btnAction: this.goToloyalty
          }
        case 'GENERIC_ERROR_AWARD_DIARY':
          return {
            img: this.alertImg,
            title: 'Riprova più tardi',
            message:
              'Siamo spiacenti ma per un errore temporaneo non è possibile richiedere il premio. Non preoccuparti: i tuoi dati sono al sicuro.',
            btnText: 'Torna indietro',
            btnAction: this.goToloyalty
          }
        case 'GENERIC_ERROR_AWARD_CATALOG':
          return {
            img: this.alertImg,
            title: 'Riprova più tardi',
            message:
              'Siamo spiacenti ma per un errore temporaneo non è possibile richiedere il premio. Non preoccuparti: i tuoi dati sono al sicuro.',
            btnText: 'Torna al catalogo',
            btnAction: this.goToCatalog
          }
        case 'GENERIC_ERROR_GREENCOINS':
          return {
            img: this.alertImg,
            title: 'Green Coins non sufficienti',
            message:
              'Siamo spiacenti ma il tuo saldo Green Coins non è sufficiente per redimere questo premio.',
            btnText: 'Torna al catalogo',
            btnAction: this.goToCatalog
          }
        case 'GENERIC_ERROR_CATALOG':
          return {
            img: this.alertImg,
            title: 'Riprova più tardi',
            message:
              'Siamo spiacenti ma per errore temponaneo non è possibile accedere al catalogo.',
            btnText: 'Torna indietro',
            btnAction: this.goToloyalty
          }
        default:
          return {
            img: '',
            title: '',
            message: '',
            btnText: '',
            btnAction: ''
          }
      }
    }
  },
  methods: {
    ...mapMutations('session', ['setFeedbackShowing']),
    closeAlert() {
      this.dialog = false
      this.setFeedbackShowing(false)
    },
    getImg(imgType) {
      let ret = this.alertImg
      switch (imgType) {
        case 'eorrr': {
          ret = this.errorImg
          break
        }
        case 'alert': {
          ret = this.alertImg
          break
        }
        case 'success': {
          ret = this.succesImg
          break
        }
        default: {
          ret = this.errorImg
        }
      }
      return ret
    },
    goTo(path) {
      // const path = this.btnActionRight
      this.dialog = false
      if (this.btnActionRight !== '#') {
        if (this.btnActionRight) {
          this.$router.push({
            name: this.btnActionRight,
            params: this.btnActionRightParams
              ? this.btnActionRightParams
              : undefined
          })
        } else {
          this.$router.go(-1)
        }
      }
    },
    closeDialog() {
      this.dialog = false
    },
    goToloyalty() {
      this.dialog = false
      this.$router.push({ name: 'loyaltyPage' })
    },
    goToCatalog() {
      this.dialog = false
      this.$router.push({ name: 'catalogue' })
    }
  }
}
</script>
<style lang="scss" scoped>
.alert {
  background: white;
  padding: 80px 40px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36.4px;
    text-align: center;
    letter-spacing: 1.2px;
    margin-bottom: 8px;
  }

  &__message {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #2c313a;
    margin-bottom: 32px;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
}
</style>
