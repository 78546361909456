<template>
  <layout header-size="small" class="registered">
    <template v-slot:header>
      <v-col cols="6">
        <button
          type="button"
          class="loyalty__header-back mb-2"
          @click="$router.push({ name: 'loyaltyPage' })"
        >
          <v-icon color="white" size="30">mdi-chevron-left</v-icon>
          <span>Torna al Diario</span>
        </button>
        <h1 class="white--text">Iscritti a Greeners</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div class="registered__content" v-if="clientSelected">
      <div class="title">
        Il programma Greeners è disponibile anche per un profilo
        {{
          clientSelected.clientOwnerType === 'RES'
            ? 'Residenziale'
            : 'Business'
        }}.
      </div>
      <h3>Qual è il vantaggio?</h3>
      <div class="desc">
        Chi ha entrambi i profili può unire i Green Coins guadagnati e accedere
        al Catalogo premi con un unico borsellino.
      </div>
      <div v-if="toBeReclaimed.alert" class="alert">
        <div class="title">
          {{ toBeReclaimed.textAlert }}
        </div>
        <div class="desc">
          Contattaci al numero <span>800294333</span> (da fisso) oppure allo
          <span>0244388001</span> (da mobile). Un nostro operatore ti assisterà
          per abilitare il servizio che sarà disponibile entro le successive
          24h.
        </div>
      </div>
      <div class="d-flex align-center">
        <v-btn
          @click="goToAction"
          color="brightGreen"
          class="black--text mr-3"
          x-large
          width="264"
        >
          <v-progress-circular
            indeterminate
            :size="25"
            class="color--blue"
            v-if="showProgress"
          ></v-progress-circular>
          <div v-else>
            {{ !toBeReclaimed.alert ? 'Aggiungi profilo' : 'Torna al diario' }}
          </div>
        </v-btn>
        <v-btn
          v-if="!toBeReclaimed.alert"
          text
          color="#12256A"
          x-large
          width="264"
          @click="$router.push({ name: 'loyaltyPage' })"
        >
          Torna al diario
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="showAccountDataDialog" max-width="750">
      <v-row class="missing-data-dialog">
        <v-col>
          <img
            align="right"
            @click="showAccountDataDialog = false"
            src="@/assets/ic_chevron_top_black.png"
          />
          <h2></h2>
          <div class="text">
            <b>Ops! Manca qualche informazione nel tuo Account.</b><br />
            Compila il campo email alla voce Dati di contatto nella card del tuo
            Cliente associato. Attendi qualche minuto l’aggiornamento delle
            informazione e riprova!
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </layout>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Layout from './Layout'

export default {
  name: 'registeredComponent',
  data() {
    return {
      clientSelected: null,
      toBeReclaimed: { alert: false, text: '' },
      showProgress: false,
      showAccountDataDialog: false
    }
  },
  components: {
    Layout
  },
  computed: {
    ...mapGetters('account', [
      'accountClients',
      'loyaltyResToFix',
      'loyaltyBusToFix',
      'clientDetails',
      'mainClientLoyaltySelected'
    ])
  },
  methods: {
    ...mapMutations('account', ['setMainClientLoyaltySelected']),
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('loyalty', ['joinTeam']),
    ...mapActions('cfp', ['getLastCfpValue']),
    async goToAction() {
      if (this.toBeReclaimed.alert) {
        this.$router.push({ name: 'loyaltyPage' })
      } else {
        this.trackTapEvent('greeners_addClientDone_tap')
        this.setMainClientLoyaltySelected(this.clientSelected)
        await this.getDetailForClient({
          clientOwnerCode: this.clientSelected.clientOwnerCode
        })
        this.$router.push({ name: 'introPage' })
      }
    }
  },
  mounted() {
    this.trackTapEvent('greeners_addClient_tap')
  },
  beforeMount() {
    this.getMainClient().then(() => {
      this.clientSelected = this.accountClients[this.$route.params.id]
      if (
        this.clientSelected.clientOwnerType === 'RES' &&
        this.loyaltyResToFix
      ) {
        this.toBeReclaimed = {
          alert: true,
          textAlert:
            "OPS! L'accesso a questa sezione deve essere abilitato dal nostro Servizio Clienti"
        }
      }
      if (
        this.clientSelected.clientOwnerType === 'BUS' &&
        this.loyaltyBusToFix
      ) {
        this.toBeReclaimed = {
          alert: true,
          textAlert:
            'Per iscrivere un utente business devi contattare il Servizio Clienti'
        }
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.registered {
  &__content {
    padding-top: 52px;
    color: black;
    .title {
      margin-bottom: 48px;
    }
    h3 {
      font-weight: 500;
      margin-bottom: 16px;
    }
    .desc {
      font-size: 20px;
      line-height: 1.3;
      margin-bottom: 48px;
      span {
        font-weight: 500;
      }
    }
    .alert {
      padding-top: 48px;
      border-top: 1px solid #ccd0e1;
      .title {
        margin-bottom: 24px;
      }
    }
  }
}
.missing-data-dialog {
  background-color: white;
  padding: 30px;
  img {
    cursor: pointer;
  }
}
</style>
