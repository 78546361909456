import * as amplitude from '@amplitude/analytics-browser'
import { ENV_CONFIGS } from '../configs'
import store from '../../store/index'

export default class AmplitudeService {
  #props = null

  constructor(props) {
    this.#props = props
    this.init()
  }

  init() {
    const userIdMd5 = store.getters['account/getUserIdMd5']
    amplitude.init(ENV_CONFIGS.AMPLITUDE_API_KEY, userIdMd5, {
      serverZone: 'EU',
      includeUtm: true,
      defaultTracking: {
        sessions: true,
        pageViews: {
          trackOn: () => {
            return !window.location.pathname.includes('punDetail')
          }
        }
      }
    })
  }

  async trackEvent(event, eventProps = {}) {
    if (ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
      return
    }
    if (eventProps) {
      amplitude.track(event, eventProps)
    } else {
      amplitude.track(event)
    }
  }
}
