<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col align-self="center" cols="6">
        <button
          type="button"
          class="loyalty__header-back mb-2"
          @click="$router.push({ name: 'catalogue' })"
        >
          <v-icon size="30">mdi-chevron-left</v-icon>
          <span class="BodyL-Strong">Torna al Catalogo</span>
        </button>
        <h1 v-html="awardTitle" />
      </v-col>
      <v-spacer cols="1" />
      <v-col align-self="center" cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div style="width: 100%">
      <v-progress-circular
        v-if="loader"
        class="loader fixed-loader"
        color="#12256A"
        :size="50"
        indeterminate
      ></v-progress-circular>
    </div>
    <common-content
      :coins="awardPrice"
      :imageSrc="awardImg"
      :isFormDisabled="isFormDisabled"
      @buttonClick="requestAward"
      btnLabel="Richiedi premio"
      v-if="awardItem.id"
    >
      <template v-slot:text>
        <p
          class="BodyXL mb-8"
          v-html="awardItem.attributes && awardItem.attributes.description"
        />
      </template>
      <v-row justify="center" v-if="awardText.length">
        <v-expansion-panels class="v-expansion-panels--prizes" accordion>
          <v-expansion-panel :key="item.id" v-for="item in awardText">
            <v-expansion-panel-header>
              <span class="CaptionXL color--blue" v-text="item.title" />
              <template v-slot:actions>
                <div class="icon icon--expand icon--expand-blue"></div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              ><div v-html="item.description"
            /></v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </common-content>
  </layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Layout from './Layout'
import CommonContent from './CommonContent'

export default {
  name: 'BonusDetail',
  components: {
    Layout,
    commonContent: CommonContent
  },
  data: () => ({
    bonusItem: {},
    loader: true
  }),

  beforeMount() {
    this.setAwardItem()
    this.getAwardInfo({
      id: this.$route.params.id,
      catalog:
        this.mainClientLoyaltySelected?.clientOwnerType === 'RES'
          ? 'COLLECTION'
          : 'COLLECTION-BS'
    }).then((resp) => {
      this.loader = false
    })
  },
  computed: {
    ...mapGetters('loyalty', ['memberBalance', 'awardItem']),
    ...mapGetters('account', ['mainClientLoyaltySelected']),
    isFormDisabled() {
      return this.userBalance < this.awardPrice
    },
    userBalance() {
      return this.memberBalance.user_balance
    },
    awardPrice() {
      return this.awardItem.attributes.prizeoptions.data[0].price
    },
    awardTitle() {
      return (
        (this.awardItem.attributes && this.awardItem.attributes.title) || ''
      )
    },
    awardText() {
      return (
        (this.awardItem.attributes &&
          this.awardItem.attributes.prize_text &&
          this.awardItem.attributes.prize_text.data) ||
        []
      )
    },
    awardImg() {
      let imgFull = this.awardItem.attributes.images.data.find(
        ({ label }) => label === 'full'
      )
      return imgFull
        ? imgFull.image_medium
        : this.awardItem.attributes.images.data[0].image_medium
    }
  },
  methods: {
    ...mapMutations('loyalty', ['setAwardItem']),
    ...mapActions('loyalty', ['getAwardInfo']),
    requestAward() {
      if (this.isFormDisabled) return false
      if (this.awardItem.attributes.is_required_shipping) {
        this.$router.push({
          name: 'giftShipping',
          params: {
            catalog:
              this.mainClientLoyaltySelected?.clientOwnerType === 'RES'
                ? 'COLLECTION'
                : 'COLLECTION-BS',
            type: this.awardItem.type,
            id: this.$route.params.id
          }
        })
      } else {
        this.$router.push({
          name: 'giftEmailShipping',
          params: {
            catalog:
              this.mainClientLoyaltySelected?.clientOwnerType === 'RES'
                ? 'COLLECTION'
                : 'COLLECTION-BS',
            id: this.$route.params.id
          }
        })
      }
    }
  }
}
</script>
