<template>
  <div
    :class="[
      'extensible__btn',
      `extensible__btn--${size}`,
      'std-panel',
      isActive ? 'std-panel--tab active' : 'std-panel--shadowed'
    ]"
    :style="`order: ${index}; width: calc( 100% / ${perRow} - ${margin * 2}px)`"
    @click="handlerToggleShowContent"
  >
    <v-radio
      :label="label || name.toString()"
      :value="value || name"
      :disabled="disabled"
    >
      <template v-slot:label>
        <span
          :style="`color: ${labelColor}; font-weight: ${labelWeight}; letter-spacing: ${letterSpacing}`"
          v-if="!$slots.label"
          v-text="label"
        />
        <slot name="label" />
      </template>
    </v-radio>
    <div
      :style="`order: ${index + Number(perRow) - (index % perRow) - 1}`"
      class="extensible__content"
      :class="{ active: isActive }"
    >
      <slot v-if="isActive" />
    </div>
  </div>
</template>
<script>
/*
  USAGE Example:
    <ExtensibleButtonGroup
      v-model="model"
      :per-row="2"
      :radio="false"
      groupName="group"
      size="lg"
    >
      <ExtensibleButton :name="1">
        <template v-slot:label> some label </template>
        ..some content..
      </ExtensibleButton>
      <ExtensibleButton :name="2" label=" some label">
        ..some content..
      </ExtensibleButton>
    </ExtensibleButtonGroup>

  PROPS:
  -ExtensibleButtonGroup
    per-row: max number of button in the row. Default value 3
    size: style adjustment for different sizes (md / ld). Default value "md" // TODO adjust existing styles anf add styles for sizes sm and xl
    radio: show/hide Radio. default value true
    content: if value false component working as radio-group
    groupName: group name

  -ExtensibleButton
    name or value: value for ExtensibleButtonGroup v-model
    label: content for button, also you can use slot with name "label"
*/

export default {
  name: 'extensibleButton',
  props: {
    name: { type: [String, Number, Object], default: undefined },
    value: { type: [String, Number, Object], default: undefined },
    label: { type: String, default: null },
    labelColor: { type: [String, Number], default: null },
    labelWeight: { type: [String, Number], default: null },
    letterSpacing: { type: Number, default: null },
    radio: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({
    index: 0
  }),
  inject: ['options'],
  computed: {
    isActive() {
      return this.options.model === (this.value ? this.value : this.name)
    },
    perRow() {
      return this.options.perRow
    },
    size() {
      return this.options.size
    },
    margin() {
      return this.options.margin
    }
  },
  watch: {
    isActive(value) {
      if (value) this.$parent.$emit('changeActiveContent', this.$slots.default)
    }
  },
  mounted() {
    this.$parent.$el
      .querySelector('.extensible__rows')
      .appendChild(this.$el.querySelector('.extensible__content'))
    this.$parent.$emit('addedButton', {
      value: this.value || this.name,
      hasContent: !!this.$slots.default,
      buttonInstance: this
    })
    this.$parent.$slots.default[1].children
      .filter(({ componentInstance }) => componentInstance)
      .forEach((el, i) => {
        if (el && el.componentInstance) el.componentInstance.index = i
      })
  },
  methods: {
    handlerToggleShowContent() {
      this.$parent.$emit('toggleShowContent', {
        name: this.value || this.name,
        active: this.isActive
      })
    }
  }
}
</script>
