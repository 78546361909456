import { render, staticRenderFns } from "./MigrationCode.vue?vue&type=template&id=203fda82&scoped=true"
import script from "./MigrationCode.vue?vue&type=script&lang=js"
export * from "./MigrationCode.vue?vue&type=script&lang=js"
import style0 from "./MigrationCode.vue?vue&type=style&index=0&id=203fda82&prod&lang=scss&scoped=true"
import style1 from "./MigrationCode.vue?vue&type=style&index=1&id=203fda82&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203fda82",
  null
  
)

export default component.exports