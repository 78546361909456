<template>
  <v-expansion-panel class="trackingPanel">
    <v-expansion-panel-header class="d-flex align-center">
      <div class="trackingPanel__boxCounter BodyM-Strong">
        <slot name="box" />
      </div>
      <template v-slot:actions>
        <v-img class="v-icon" :src="IconChevronDown" />
      </template>
      <div class="BodyM">
        <strong><slot name="title" /></strong>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="$slots.duration" class="trackingPanel__duration CaptionS mb-6">
        DURATA <span><slot name="duration" /></span>
      </div>
      <div class="trackingPanel__happens CaptionS">COSA ACCADE</div>
      <div class="trackingPanel__desc BodyM mt-2">
        <slot name="desc" />
      </div>
      <div v-if="$slots.extraDesc" class="trackingPanel__extraDesc BodyM mt-2">
        <slot name="extraDesc" />
      </div>
      <div v-if="info" class="trackingPanel__info mt-4">
        <div class="item">
          <div class="CaptionS mb-2">COME TI COINVOLGE</div>
          <div class="d-flex align-center">
            <div class="involve">
              <slot name="involve" />
            </div>
          </div>
        </div>
        <div class="item CaptionS">
          <div class="mb-2">ATTORI INTERESSATI</div>
          <div class="boxContainer">
            <div class="box green">
              <slot name="actorsGreen" />
            </div>
            <div class="box gray">
              <slot name="actorsGray1" />
            </div>
            <div v-if="$slots.actorsGray2" class="box gray">
              <slot name="actorsGray2" />
            </div>
            <div v-if="$slots.actorsGray3" class="box gray">
              <slot name="actorsGray3" />
            </div>
          </div>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import IconChevronDown from '@assets/icons/chevron.svg'
export default {
  props: ['info'],
  data() {
    return {
      IconChevronDown
    }
  }
}
</script>
<style lang="scss">
.trackingPanel {
  .v-expansion-panel-header {
    height: 40px;
    color: black !important;
  }
  .v-expansion-panel-content__wrap {
    padding-top: 10px !important;
  }
}
</style>

<style lang="scss" scoped>
.trackingPanel {
  padding: 0px 8px 0px 16px;
  border: 1px solid $neutri-neutral-30;
  border-radius: 8px;
  height: fit-content;
  margin-bottom: 16px;
  position: relative;
  &__boxCounter {
    position: absolute;
    left: -66px;
    top: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $neutri-neutral-30;
    border-radius: 8px;
  }
  &__duration {
    color: $neutri-neutral-50;
    span {
      color: $neutri-neutral-60;
    }
  }
  &__happens {
    color: $neutri-neutral-50;
  }
  &__desc {
    color: $neutri-neutral-60;
  }
  &__extraDesc {
    color: $neutri-neutral-50;
  }
  &__info {
    display: flex;
    color: $neutri-neutral-50;
    .item {
      width: 50%;
      .involve {
        height: 30px;
        display: flex;
        align-items: center;
      }
      .boxContainer {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        .box {
          padding: 8px;
          align-items: flex-start;
          border-radius: 8px;
          &.green {
            color: white;
            background: $terziari-dark-green-100;
          }
          &.gray {
            color: $srg-blue;
            background: $neutri-neutral-20;
          }
        }
      }
    }
  }
}
</style>
