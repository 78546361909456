<template>
  <div class="supply">
    <transition name="fade" v-if="!loaded || isEmptyAlert">
      <section v-if="true">
        <Placeholder type="supply" />
        <Placeholder type="supply" />
        <Placeholder type="supply" />
      </section>
    </transition>
    <transition v-else name="fade">
      <v-expansion-panels v-model="openPanel">
        <component
          v-for="(type, i) in accordionsSorted"
          :key="i"
          :is="`accordion-${type}`"
          :triggerStatus="triggerStatus[type]"
          :triggersDisabled="triggersDisabled[type]"
          :triggersTimeline="triggersTimeline[type]"
          :triggersPayment="triggersPayment[type]"
          :triggersConsumption="triggersConsumption[type]"
          :triggersDevicesFV="triggersDevicesFV"
          @openSuppliesDialog="openSuppliesDialog"
        />
      </v-expansion-panels>
    </transition>
    <SuppliesDialog
      v-model="suppliesDialog"
      :typeComm="suppliesDialogType"
      :typeTrigger="suppliesDialogTrigger"
    />
    <BidgelyEleDialog
      v-if="mainClient && !mainClient?.isOnlyFV"
      :show-dialog="showBEDialog"
      @BidgelyEleDialogClose="showBEDialog = false"
    ></BidgelyEleDialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Placeholder from '@components/global/Placeholder.vue'

import BidgelyEleDialog from '@components/bidgely/BidgelyEleDialog'
import SuppliesDialog from '@components/supplies/SuppliesDialog.vue'

import Constants from '@/js/constants'
import { eventBus } from '@/main'

import { validDate } from '@/js/utils'
import IconActive from '@assets/icons/active.svg'
import IconChevronDown from '@assets/icons/chevron.svg'

import AccordionEle from './accordions/AccordionEle'
import AccordionGas from './accordions/AccordionGas'
import AccordionFiber from './accordions/AccordionFiber'
import AccordionPhotovoltaic from './accordions/AccordionPhotovoltaic'
export default {
  name: 'supplyCard',
  components: {
    Placeholder,
    SuppliesDialog,
    BidgelyEleDialog,
    AccordionEle,
    AccordionGas,
    AccordionFiber,
    AccordionPhotovoltaic
  },
  props: {
    loaded: { type: Boolean, default: false }
  },
  data() {
    return {
      IconChevronDown,
      IconActive,
      statusDecode: {
        regular: 'regular',
        irregular: 'not_regular',
        timeline: 'activating',
        noActive: 'inactive'
      },
      suppliesDialog: false,
      showBEDialog: false,
      suppliesDialogType: '',
      suppliesDialogTrigger: '',
      SUPPLY_STATUS: Constants.SUPPLY_STATUS,
      SERVICE_TYPE: Constants.SERVICE_TYPE,
      DEVICES_PHOTOVOLTAIC: Constants.DEVICES_PHOTOVOLTAIC,
      clientsForAccount: [],
      openPanel: null
    }
  },
  async beforeMount() {
    this.clientsForAccount = await this.getClientsForAccount().then((res) => {
      return Object.keys(res)
    })
  },
  updated() {
    if (this.$route.params.beyondenergyAlert) {
      if (this.showBEContent) {
        this.openPanel = 0
      }
    }
  },
  computed: {
    ...mapGetters('alerts', ['alert', 'flagAlert', 'flagAlertConsumi']),
    ...mapGetters('account', ['accountProperties', 'mainClient']),
    ...mapGetters('supply', ['supplies']),
    isEmptyAlert() {
      return Object.entries(this.alert).length === 0
    },
    accordionsSorted() {
      const priorityOrder = {
        photovoltaic: 1,
        ele: 2,
        gas: 3,
        fiber: 4
      }

      let listaAccordionHome = Object.keys(this.triggerStatus)
      if (
        this.mainClient.clientOwnerType !== 'RES' ||
        this.accountProperties.type === 'MIXED'
      ) {
        listaAccordionHome = listaAccordionHome.filter(
          (item) => item !== 'photovoltaic'
        )
      }

      //funzione d'appoggio
      const statusComparison = (a, b, status) => {
        if (
          this.triggerStatus[a]?.status === status &&
          this.triggerStatus[b]?.status !== status
        ) {
          return 1
        } else if (
          this.triggerStatus[a]?.status !== status &&
          this.triggerStatus[b]?.status === status
        ) {
          return -1
        }
        return 0
      }

      // Ordina in base allo stato e al tipo
      listaAccordionHome.sort((a, b) => {
        const orderA = priorityOrder[a] || 0
        const orderB = priorityOrder[b] || 0

        // Priorità per gli stati specifici
        const specificStatusOrder = ['empty', 'timeline', 'noActive']
        for (const status of specificStatusOrder) {
          const comparisonResult = statusComparison(a, b, status)
          if (comparisonResult !== 0) {
            return comparisonResult
          }
        }

        // Ordina in base alla priorità definita
        return orderA - orderB
      })

      return listaAccordionHome
    },
    triggerStatus() {
      const setTriggers = (alert, type, comm) => {
        if (type === 'Fiber') {
          type = 'Fibra'
        }

        let supplyDisabled = this.supplies.filter(
          (sup) =>
            (sup.supplyState === this.SUPPLY_STATUS.CLOSED ||
              sup.supplyState === this.SUPPLY_STATUS.REJECTED) &&
            sup.serviceType.toLowerCase() === comm.toLowerCase() &&
            this.clientsForAccount.includes(sup.clientOwnerCode)
        )
        let supplyByComm = this.supplies.filter(
          (sup) =>
            sup.serviceType.toLowerCase() === comm.toLowerCase() &&
            this.clientsForAccount.includes(sup.clientOwnerCode)
        )
        let countSupplies =
          supplyDisabled.length > 0 &&
          supplyDisabled.length === supplyByComm.length
            ? supplyDisabled.length
            : supplyByComm.length - supplyDisabled.length

        if (
          supplyDisabled.length > 0 &&
          supplyDisabled.length === supplyByComm.length
        ) {
          return {
            status: 'noActive',
            title: `NON ${this.singleOrpluralText(
              countSupplies,
              'ATTIVA',
              'ATTIVE'
            )}`,
            nSupplies: `${countSupplies} ${this.singleOrpluralText(
              countSupplies,
              'Utenza',
              'Utenze'
            )} ${type}`
          }
        } else if (
          alert?.timelinePoints.length > 0 &&
          supplyByComm.length > 0 &&
          supplyByComm.length ===
            this.supplies.filter(
              (sup) =>
                (sup.supplyState === this.SUPPLY_STATUS.ACTIVATING ||
                  (sup.supplyState === this.SUPPLY_STATUS.ACTIVE &&
                    this.checkActivationDate(sup.activationDate))) &&
                sup.serviceType.toLowerCase() === comm.toLowerCase()
            ).length
        ) {
          return {
            status: 'timeline',
            title: 'SEGUI L’ATTIVAZIONE',
            nSupplies: `${countSupplies} ${this.singleOrpluralText(
              countSupplies,
              'Utenza',
              'Utenze'
            )} ${type}`
          }
        } else if (alert.points.length > 0 && countSupplies > 0) {
          if (
            alert.isNotRegularPayment ||
            (alert.hasAbnormalConsumption && type === 'Luce') ||
            (alert.isEstimate && type === 'Gas')
          ) {
            if (
              alert.isNotRegularPayment &&
              ((alert.hasAbnormalConsumption && type === 'Luce') ||
                (alert.isEstimate && type === 'Gas'))
            ) {
              return {
                status: 'irregular',
                title: 'DA CONTROLLARE',
                nSupplies: `${countSupplies} ${this.singleOrpluralText(
                  countSupplies,
                  'Utenza',
                  'Utenze'
                )} ${type}`
              }
            } else if (alert.isNotRegularPayment) {
              return {
                status: 'irregular',
                title: 'PAGAMENTI DA CONTROLLARE',
                nSupplies: `${countSupplies} ${this.singleOrpluralText(
                  countSupplies,
                  'Utenza',
                  'Utenze'
                )} ${type}`
              }
            } else {
              return {
                status: this.flagAlertConsumi ? 'irregular' : 'regular',
                title: `${
                  alert.hasAbnormalConsumption
                    ? 'CONSUMI DA CONTROLLARE'
                    : 'CONSUMI DA CONTROLLARE'
                }`,
                nSupplies: `${countSupplies} ${this.singleOrpluralText(
                  countSupplies,
                  'Utenza',
                  'Utenze'
                )} ${type}`
              }
            }
          } else if (alert.isInVerify) {
            return {
              status: 'regular',
              title: 'REGOLARE',
              nSupplies: `${countSupplies} ${this.singleOrpluralText(
                countSupplies,
                'Utenza',
                'Utenze'
              )} ${type}`
            }
          } else if (!alert.isNotRegularPayment) {
            return {
              status: 'regular',
              title: 'REGOLARE',
              nSupplies: `${countSupplies} ${this.singleOrpluralText(
                countSupplies,
                'Utenza',
                'Utenze'
              )} ${type}`
            }
          }
        } else {
          return {
            status: 'empty',
            title: "SCOPRI L'OFFERTA PER TE",
            nSupplies:
              type === 'Luce'
                ? 'Accendi la Luce'
                : type === 'Gas'
                ? 'Vai a tutto Gas'
                : 'Fibra Ultraveloce'
          }
        }
      }
      const setTriggersPhotovoltaic = (alert, type, comm) => {
        let supplyByComm = this.supplies.filter(
          (sup) => sup.serviceType.toLowerCase() === comm.toLowerCase()
        )
        let countSupplies = supplyByComm.length
        let countIrregularSupplies = alert.irregularPhotovoltaic.length
        // debugger
        if (countSupplies === 0 || !countSupplies) {
          // this.upsellingDialogFV = true
          return {
            status: 'empty',
            title: "SCOPRI L'OFFERTA PER TE",
            nSupplies: `${type}`
          }
        } else if (
          supplyByComm.length ===
          this.supplies.filter(
            (sup) =>
              sup.supplyState === this.SUPPLY_STATUS.ACTIVATING &&
              sup.serviceType.toLowerCase() === comm.toLowerCase()
          ).length
        ) {
          return {
            status: 'timeline',
            title: 'SEGUI L’ATTIVAZIONE',
            nSupplies: `${countSupplies} ${this.singleOrpluralText(
              countSupplies,
              'Impianto Fotovoltaico',
              'Impianti Fotovoltaici'
            )}`
          }
        } else if (alert.points.length > 0 && countSupplies > 0) {
          if (alert.isNotRegularPhotovoltaic) {
            if (alert.isNotWorking) {
              return {
                status: 'irregular',
                title: `NON ${this.singleOrpluralText(
                  countIrregularSupplies,
                  'FUNZIONANTE',
                  'FUNZIONANTI'
                )}`,
                nSupplies: `${countSupplies} ${this.singleOrpluralText(
                  countSupplies,
                  'Impianto Fotovoltaico',
                  'Impianti Fotovoltaici'
                )}`
              }
            } else if (alert.isOffline) {
              return {
                status: 'irregular',
                title: 'OFFLINE',
                nSupplies: `${countSupplies} ${this.singleOrpluralText(
                  countSupplies,
                  'Impianto Fotovoltaico',
                  'Impianti Fotovoltaici'
                )}`
              }
            }
          } else {
            return {
              status: 'regular',
              title: `${this.singleOrpluralText(
                countSupplies,
                'REGOLARE',
                'REGOLARI'
              )}`,
              nSupplies: `${countSupplies} ${this.singleOrpluralText(
                countSupplies,
                'Impianto Fotovoltaico',
                'Impianti Fotovoltaici'
              )}`
            }
          }
        }
      }
      if (!this.alert) return null
      return {
        ele: setTriggers(this.alert.ele, 'Luce', 'ele'),
        gas: setTriggers(this.alert.gas, 'Gas', 'gas'),
        fiber: setTriggers(this.alert.fiber, 'Fiber', 'fiber'),
        photovoltaic: setTriggersPhotovoltaic(
          this.alert.photovoltaic,
          'Fotovoltaico',
          'photovoltaic'
        )
      }
    },
    triggersDevicesFV() {
      let alert = this.alert.photovoltaic
      let supplyFV = this.supplies.filter(
        (sup) => sup.serviceType === this.SERVICE_TYPE.PHOTOVOLTAIC
      )
      let countFV = supplyFV.length
      let countIrregularFV = alert.irregularPhotovoltaic.length
      let devicesIrregular = alert.irregularPhotovoltaic
        .map((point) => point.photovoltaicData.notWorkingDeviceList)
        .flat()
      if (alert.isOffline && countFV < 2) {
        return {
          status: 'irregular',
          cta: `STATO DELL'IMPIANTO`,
          desc: `Verifica la connessione`
        }
      } else if (alert.isOffline || devicesIrregular.length > 1) {
        //TODO aggiungere alert.isNotWorking
        if (countIrregularFV >= 1) {
          return {
            status: 'irregular',
            cta: `STATO DELL'IMPIANTO`,
            desc: `${countIrregularFV} ${this.singleOrpluralText(
              countIrregularFV,
              'impianto',
              'impianti'
            )} su ${countFV} da verificaree`
          }
        } else {
          if (devicesIrregular.length > 1) {
            return {
              status: 'irregular',
              cta: `STATO DELL'IMPIANTO`,
              desc: `Verifica ${devicesIrregular.length} componenti`
            }
          } else {
            return {
              status: 'irregular',
              cta: `STATO DELL'IMPIANTO`,
              desc: `Verifica ${this.DEVICES_PHOTOVOLTAIC[devicesIrregular[0]]}`
            }
          }
        }
      } else {
        return {
          status: 'regular',
          cta: `STATO DELL'IMPIANTO`,
          desc: `Funzionamento componenti`
        }
      }
    },
    triggersPayment() {
      const setTriggers = (alert, type) => {
        if (alert.points.length > 0) {
          let nExpiredBills = alert.points
            .map((m) => m.expiredInvoicesCount)
            .reduce(
              (prev, next) => prev + (typeof next === 'undefined' ? 0 : next)
            )

          if (alert.isNotRegularPayment) {
            if (
              ((alert.hasAbnormalConsumption && type === 'Luce') ||
                (alert.isEstimate && type === 'Gas')) &&
              this.flagAlertConsumi
            ) {
              return {
                status: 'irregular',
                cta: 'PAGAMENTI DA CONTROLLARE',
                desc:
                  nExpiredBills === 1
                    ? '1 bolletta scaduta'
                    : nExpiredBills + ' bollette scadute'
              }
            } else {
              return {
                status: 'irregular',
                cta:
                  nExpiredBills === 1
                    ? '1 BOLLETTA SCADUTA'
                    : nExpiredBills + ' BOLLETTE SCADUTE',
                desc: `Vai a <strong>riepilogo pagamenti</strong>`
              }
            }
          } else if (alert.isInVerify) {
            return {
              status: 'regular',
              cta: 'PAGAMENTI REGOLARI',
              desc: `<strong>${
                nExpiredBills === 1
                  ? '1 pagamento'
                  : nExpiredBills + ' pagamenti'
              } in verifica</strong>`
            }
          } else if (!alert.isNotRegularPayment) {
            return {
              status: 'regular',
              cta: `${
                alert.lastBillTimestamp > 0
                  ? 'PAGAMENTI REGOLARI'
                  : 'STATO PAGAMENTI'
              }`,
              desc:
                alert.lastBillTimestamp > 0
                  ? `Ultima bolletta <strong>${new Date(alert.lastBillTimestamp)
                      .toLocaleString('it-IT', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      })
                      .replaceAll('/', '.')}</strong>`
                  : 'Disponibile dopo la prima bolletta'
            }
          }
        } else {
          return {
            status: 'empty',
            cta: '',
            desc: ''
          }
        }
      }
      if (!this.alert) return null
      return {
        ele: setTriggers(this.alert.ele, 'Luce'),
        gas: setTriggers(this.alert.gas, 'Gas'),
        fiber: setTriggers(this.alert.fiber, 'Fiber')
      }
    },
    triggersConsumption() {
      const setTriggers = (alert, type) => {
        if (alert.consumptionPoints.length > 0) {
          if (type === 'Luce') {
            if (alert.hasAbnormalConsumption) {
              return {
                status: this.flagAlertConsumi ? 'irregular' : 'regular',
                cta: 'CONSUMI DA CONTROLLARE',
                desc: `Consumi <strong>superiori</strong> alla media`
              }
            } else if (alert.isUnavailable) {
              return {
                status: 'regular',
                cta: 'CONTROLLA STATO CONSUMI',
                desc: `Vai al  <strong>monitoraggio</strong>`
              }
            } else if (!alert.hasAbnormalConsumption) {
              return {
                status: 'regular',
                cta: 'CONSUMI STABILI',
                desc: '<strong>In media</strong> con ultimi 6 mesi'
              }
            }
          } else {
            if (alert.isUnavailable) {
              return {
                status: 'regular',
                cta: 'STATO CONSUMI',
                desc: `Disponibile dopo la prima bolletta`
              }
            } else if (alert.isEstimate) {
              return {
                status: 'irregular',
                cta: 'INVIA AUTOLETTURA',
                desc: `Ottieni consumi <strong>effettivi</strong>`
              }
            } else {
              return {
                status: 'regular',
                cta: 'CONSUMI EFFETTIVI',
                desc: `Bollette con consumi <strong>reali</strong>`
              }
            }
          }
        } else {
          return {
            status: 'empty',
            cta: 'CONTROLLA STATO CONSUMI',
            desc: `Vai al  <strong>monitoraggio</strong>`
          }
        }
      }
      if (!this.alert) return null
      return {
        ele: setTriggers(this.alert.ele, 'Luce'),
        gas: setTriggers(this.alert.gas, 'Gas')
      }
    },
    triggersDisabled() {
      if (!this.alert) return null
      const setTriggers = (alert, type) => {
        if (alert.points.length > 0) {
          return {
            status: 'noActive',
            cta: 'VERIFICA STATO',
            desc: `Hai <strong>${
              alert.points.length
            }</strong> ${this.singleOrpluralText(
              alert.points.length,
              'utenza',
              'utenze'
            )} non ${this.singleOrpluralText(
              alert.points.length,
              'attiva',
              'attive'
            )}`
          }
        } else {
          return {
            status: 'empty',
            cta: '',
            desc: ''
          }
        }
      }
      return {
        ele: setTriggers(this.alert.ele, 'Luce'),
        gas: setTriggers(this.alert.gas, 'Gas'),
        fiber: setTriggers(this.alert.fiber, 'Fiber')
      }
    },
    triggersTimeline() {
      if (!this.alert) return null
      const setTriggers = (type, comm) => {
        let suppliesTimeline = this.supplies.filter(
          (sup) =>
            (sup.supplyState === this.SUPPLY_STATUS.ACTIVATING ||
              (sup.supplyState === this.SUPPLY_STATUS.ACTIVE &&
                this.checkActivationDate(sup.activationDate))) &&
            sup.serviceType.toLowerCase() === comm.toLowerCase()
        )
        if (suppliesTimeline.length > 1) {
          return {
            status: 'timeline',
            cta: 'SOTTOSCRIZIONE AVVENUTA',
            desc: `Seleziona una delle tue utenze`
          }
        } else if (suppliesTimeline.length === 1) {
          if (!suppliesTimeline[0].activationDate) {
            if (
              suppliesTimeline[0].typeRequest === 'Nuovo Allaccio Fibra' ||
              suppliesTimeline[0].typeRequest === 'NewConnection'
            ) {
              return {
                status: 'timeline',
                cta: 'SOTTOSCRIZIONE AVVENUTA',
                desc: `Seguiremo insieme tutti i passaggi`
              }
            } else if (suppliesTimeline[0].typeRequest === 'Reactivation') {
              return {
                status: 'timeline',
                cta: 'SOTTOSCRIZIONE AVVENUTA',
                desc:
                  type === 'Luce'
                    ? 'Attiva entro 7 giorni lavorativi'
                    : 'Seguiremo insieme tutti i passaggi'
              }
            } else {
              return {
                status: 'timeline',
                cta: 'SOTTOSCRIZIONE AVVENUTA',
                desc: `Attiva entro il <strong>${new Date(
                  suppliesTimeline[0].estimateDate
                ).toLocaleString('it-IT', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                })}</strong>`
              }
            }
          } else {
            return {
              status: 'timeline',
              cta: 'UTENZA ATTIVA',
              desc: `Attiva entro il <strong>${new Date(
                suppliesTimeline[0].activationDate
              ).toLocaleString('it-IT', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              })}</strong>`
            }
          }
        } else {
          return {
            status: 'empty',
            cta: '',
            desc: ''
          }
        }
      }
      const setTriggersPhotovoltaic = (type, comm) => {
        let suppliesTimeline = this.supplies.filter(
          (sup) =>
            sup.supplyState === this.SUPPLY_STATUS.ACTIVATING &&
            sup.serviceType.toLowerCase() === comm.toLowerCase()
        )
        if (suppliesTimeline.length > 0) {
          return {
            status: 'timeline',
            cta: 'SOTTOSCRIZIONE AVVENUTA',
            desc: `Monitora lo stato di attivazione`
          }
        } else {
          return {
            status: 'empty',
            cta: '',
            desc: ''
          }
        }
      }
      return {
        ele: setTriggers('Luce', 'ele'),
        gas: setTriggers('Gas', 'gas'),
        fiber: setTriggers('Fiber', 'fiber'),
        photovoltaic: setTriggersPhotovoltaic('Fotovoltaico', 'photovoltaic')
      }
    },
    showBEContent() {
      return (
        this.supplies.filter(
          (sup) =>
            sup.serviceType === this.SERVICE_TYPE.ELE &&
            sup.clientOwnerType === 'RES' &&
            sup.supplyState === this.SUPPLY_STATUS.ACTIVE
        ).length > 0
      )
    }
  },
  methods: {
    ...mapActions('alerts', ['resetFlagAlert']),
    ...mapActions('account', ['getClientsForAccount']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('fotovoltaico', ['setAddressSelected']),
    singleOrpluralText(number, single, plural) {
      return number > 1 ? plural : single
    },
    checkActivationDate(dataAttivazione) {
      if (validDate(dataAttivazione)) {
        let checkDate = new Date(dataAttivazione)
        checkDate = checkDate.setDate(checkDate.getDate() + 5)
        return new Date().setHours(0, 0, 0, 0) <= checkDate
      } else {
        return false
      }
    },
    openSuppliesDialog(type, trigger) {
      if (trigger === 'payment') {
        this.trackTapEvent({
          name: 'home_paymentAlert_tap',
          params: {
            supply_type: type,
            status: this.alert[type].isNotRegularPayment
              ? 'not_regular'
              : 'regular'
          }
        })
      } else if (trigger === 'consumption') {
        this.trackTapEvent({
          name: 'home_consumptionAlert_tap',
          params: {
            supply_type: type,
            status: this.alert[type].hasAbnormalConsumption
              ? 'not_regular'
              : 'regular'
          }
        })
      } else if (trigger === 'dashboardFV') {
        this.trackTapEvent({
          // TO DO
        })
      }
      if (this.alert[type].points.length === 1) {
        this.getActionTrigger(type, trigger)
      } else if (
        this.alert[type].consumptionPoints &&
        this.alert[type].consumptionPoints.length === 1
      ) {
        this.getActionTrigger(type, trigger)
      } else if (
        this.supplies.filter(
          (sup) =>
            sup.supplyState === this.SUPPLY_STATUS.ACTIVATING &&
            sup.serviceType.toLowerCase() === type.toLowerCase()
        ).length === 1 &&
        type !== 'photovoltaic'
      ) {
        this.getActionTrigger(type, trigger)
      } else {
        this.suppliesDialog = true
        this.suppliesDialogType = type
        this.suppliesDialogTrigger = trigger
      }
    },
    getActionTrigger(type, trigger) {
      let supply = this.supplies.filter(
        (sup) => sup.serviceType.toLowerCase() === type.toLowerCase()
      )[0]
      if (trigger === 'payment') {
        supply = this.supplies.filter(
          (sup) =>
            sup.supplyState !== this.SUPPLY_STATUS.ACTIVATING &&
            sup.serviceType.toLowerCase() === type.toLowerCase()
        )[0]
        if (supply.hasRegularStatus) {
          if (!this.alert[type].lastBillTimestamp) {
            this.$router.push({
              name: 'supplyDetail',
              params: {
                clientOwnerCode: supply.clientOwnerCode,
                supplyCode: supply.supplyCode
              }
            })
          } else {
            this.$router.push({
              name: 'billsArchive',
              params: {
                clientOwnerCode: supply.clientOwnerCode,
                supplyCode: supply.supplyCode,
                valueTab: 'bollette'
              }
            })
          }
        } else {
          this.$router.push({
            name: 'billsArchive',
            params: {
              clientOwnerCode: supply.clientOwnerCode,
              supplyCode: supply.supplyCode,
              expiredBillsFilter: true,
              valueTab: 'bollette'
            }
          })
        }
      } else if (trigger === 'consumption') {
        supply = this.supplies.filter(
          (sup) =>
            sup.supplyState !== this.SUPPLY_STATUS.ACTIVATING &&
            sup.serviceType.toLowerCase() === type.toLowerCase()
        )[0]
        if (supply.isEstimate) {
          this.$router.push({
            path: `supply/${supply.clientOwnerCode}/${supply.supplyCode}/selfreading/base`
          })
        } else {
          this.$router.push({
            name: 'supplyDetail',
            params: {
              clientOwnerCode: supply.clientOwnerCode,
              supplyCode: supply.supplyCode
            }
          })
        }
      } else if (trigger === 'dashboardFV') {
        this.setAddressSelected(supply)
        this.$router.push({
          name: 'dashboardFotovoltaico',
          params: {
            acuId: supply.acuId,
            siteId: supply.photovoltaicData?.siteId
          }
        })
      } else if (trigger === 'noActive') {
        this.$router.push({
          name: 'supplyDetail',
          params: {
            clientOwnerCode: supply.clientOwnerCode,
            supplyCode: supply.supplyCode
          }
        })
      } else if (trigger === 'timeline') {
        if (type === 'fiber') {
          this.$router.push({
            name: 'trackingFiber',
            params: {
              clientOwnerCode: supply.clientOwnerCode,
              supplyCode: supply.supplyCode
            }
          })
        } else {
          this.$router.push({
            name: 'trackingCommodity',
            params: {
              clientOwnerCode: supply.clientOwnerCode,
              supplyCode: supply.supplyCode
            }
          })
        }
      }
    }
  }
}
</script>
<style lang="scss">
.supply {
  .v-expansion-panel {
    .v-expansion-panel-header__icon {
      svg {
        transition: transform 0.25s ease;
        transform: rotate(0deg);
      }
    }
  }

  .v-expansion-panel--active {
    .v-expansion-panel-header__icon {
      svg {
        transition: transform 0.25s ease;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.supply {
  width: 748px;
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
}
</style>
