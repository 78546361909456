<template>
  <div class="chart-compare">
    <div class="chart-switch__label d-flex align-center">
      <h5 class="mr-4">Confronta anno precedente</h5>
      <v-switch
        inset
        color="#12256A"
        v-model="monthOnMonth"
        @change="setSwitch"
      ></v-switch>
    </div>
    <div
      v-if="monthOnMonth"
      class="chart-switch__new-date"
      @click="dialogCompare = true"
    >
      <span>Seleziona nuove date</span>
    </div>
    <v-dialog
      :value="dialogCompare"
      :max-width="
        !dateStart.show &&
        !dateEnd.show &&
        !dateCustomStart.show &&
        !dateCustomEnd.show
          ? 750
          : 555
      "
    >
      <v-row>
        <v-col class="compare-dialog">
          <img
            @click="closeDialog()"
            src="../../assets/ic_chevron_top_black.png"
          />
          <template
            v-if="
              !dateStart.show &&
              !dateEnd.show &&
              !dateCustomStart.show &&
              !dateCustomEnd.show
            "
          >
            <h2>Seleziona periodo da confrontare</h2>
            <div class="compare-panel">
              <h5 class="mb-4">Seleziona date</h5>
              <v-row no-gutters>
                <v-col class="col-6">
                  <div
                    class="btn-chart big"
                    @click="
                      openPicker('start'),
                        trackTapEvent({
                          name: 'chartFilter_compareStartDate_tap',
                          params: getSupplyTrackingChartParams(detail, fraction)
                        })
                    "
                  >
                    {{ formatDateWithSeparatorFrazione(dateStart.date) }}
                  </div>
                </v-col>
                <v-col class="col-6">
                  <div
                    class="btn-chart big"
                    @click="
                      openPicker('end'),
                        trackTapEvent({
                          name: 'chartFilter_compareEndDate_tap',
                          params: getSupplyTrackingChartParams(detail, fraction)
                        })
                    "
                  >
                    {{ formatDateWithSeparatorFrazione(dateEnd.date) }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="compare-panel">
              <h5>Confronta con</h5>
              <ExtensibleButtonGroup
                v-model="activePeriod"
                groupName="groupPeriod"
                bg-color="transparent"
                :per-row="2"
                :hideContentOnBlur="false"
                ref="ExtensibleButtonGroup"
                class="mt-0"
              >
                <ExtensibleButton
                  name="prevPeriod"
                  id="prevPeriod"
                  key="0"
                  ref="prevPeriod"
                  :disabled="disableDatePrev"
                >
                  <template v-slot:label>Periodo precedente</template>
                  <v-row>
                    <v-col class="col-6">
                      <div class="btn-chart big">
                        {{ formatDateWithSeparatorFrazione(datePrevStart) }}
                      </div>
                    </v-col>
                    <v-col class="col-6">
                      <div class="btn-chart big">
                        {{ formatDateWithSeparatorFrazione(datePrevEnd) }}
                      </div>
                    </v-col>
                  </v-row>
                </ExtensibleButton>
                <ExtensibleButton
                  name="customPeriod"
                  id="customPeriod"
                  @hook:mounted="onExtensibleMounted()"
                  key="1"
                  ref="customPeriod"
                >
                  <template v-slot:label>Personalizzato</template>
                  <v-row ref="rowContent">
                    <v-col class="col-6">
                      <div
                        class="btn-chart big"
                        @click="openPicker('compareStart')"
                      >
                        {{
                          dateCustomStart.date
                            ? formatDateWithSeparatorFrazione(
                                dateCustomStart.date
                              )
                            : 'Data inizio'
                        }}
                      </div>
                    </v-col>
                    <v-col class="col-6">
                      <div
                        class="btn-chart big"
                        @click="openPicker('compareEnd')"
                      >
                        {{
                          dateCustomEnd.date
                            ? formatDateWithSeparatorFrazione(
                                dateCustomEnd.date
                              )
                            : 'Data fine'
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </ExtensibleButton>
              </ExtensibleButtonGroup>
            </div>
            <div class="d-flex justify-end">
              <v-btn @click="closeDialog()" color="white" x-large width="220"
                >Annulla</v-btn
              >
              <v-btn
                @click="
                  filter(),
                    trackTapEvent({
                      name: 'chartFilter_compareConfirm_tap',
                      params: getSupplyTrackingChartParams(detail, fraction)
                    })
                "
                class="v-btn--shadowed ml-5"
                x-large
                color="primary"
                width="220"
                :disabled="disableBtnConferma"
                >Conferma</v-btn
              >
            </div>
          </template>
          <template v-else-if="dateStart.show">
            <h3 class="mb-2"><strong>Scegli la data di inizio</strong></h3>
            <h5 class="mb-3">
              Seleziona in un periodo di
              {{ frazione === '1' ? '24 mesi' : cutOffDefault + ' giorni' }}
            </h5>
            <v-row justify="center">
              <v-date-picker
                :show-current="false"
                flat
                width="460"
                :allowed-dates="allowedDates"
                v-model="dateStart.date"
                :max="maxDate"
                :min="minDate"
                :type="frazione === '1' ? 'month' : 'date'"
              ></v-date-picker>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="6">
                <v-btn
                  @click="dateStart = { date: undoDate.start, show: false }"
                  color="white"
                  x-large
                  width="220"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="continueAndRefresh('start')"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="220"
                  >Prosegui</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-else-if="dateEnd.show">
            <h3 class="mb-2"><strong>Scegli la data di fine</strong></h3>
            <h5 class="mb-3">
              Seleziona in un periodo di
              {{ frazione === '1' ? '24 mesi' : cutOffDefault + ' giorni' }}
            </h5>
            <v-row justify="center">
              <v-date-picker
                :show-current="false"
                flat
                width="460"
                :allowed-dates="allowedDates"
                v-model="dateEnd.date"
                :max="maxDateCompare"
                :min="minDateCompare"
                :type="frazione === '1' ? 'month' : 'date'"
              ></v-date-picker>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="6">
                <v-btn
                  @click="dateEnd = { date: undoDate.end, show: false }"
                  color="white"
                  x-large
                  width="220"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="continueAndRefresh('end')"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="220"
                  >Prosegui</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-else-if="dateCustomStart.show">
            <h3 class="mb-2"><strong>Scegli la data di inizio</strong></h3>
            <h5 class="mb-3">
              Seleziona in un periodo di
              {{ frazione === '1' ? '24 mesi' : cutOffDefault + ' giorni' }}
            </h5>
            <v-row justify="center">
              <v-date-picker
                :show-current="false"
                flat
                width="460"
                :allowed-dates="allowedDatesCompared"
                v-model="dateCustomStart.date"
                :max="maxDate"
                :min="minDate"
                :type="frazione === '1' ? 'month' : 'date'"
              ></v-date-picker>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="6">
                <v-btn
                  @click="
                    dateCustomStart = {
                      date: undoDate.compareStart,
                      show: false
                    }
                  "
                  color="white"
                  x-large
                  width="220"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="undoRefresh(), (dateCustomStart.show = false)"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="220"
                  >Prosegui</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-else-if="dateCustomEnd.show">
            <h3 class="mb-2"><strong>Scegli la data di fine</strong></h3>
            <h5 class="mb-3">
              Seleziona in un periodo di
              {{ frazione === '1' ? '24 mesi' : cutOffDefault + ' giorni' }}
            </h5>
            <v-row justify="center">
              <v-date-picker
                :show-current="false"
                flat
                width="460"
                :allowed-dates="allowedDatesCompared"
                v-model="dateCustomEnd.date"
                :max="maxDateCompareWith"
                :min="minDateCompareWith"
                :type="frazione === '1' ? 'month' : 'date'"
              ></v-date-picker>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="6">
                <v-btn
                  @click="
                    dateCustomEnd = { date: undoDate.compareEnd, show: false }
                  "
                  color="white"
                  x-large
                  width="220"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="undoRefresh(), (dateCustomEnd.show = false)"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="220"
                  >Prosegui</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import ExtensibleButton from '@/components/extensibleButton/ExtensibleButton'
import ExtensibleButtonGroup from '@/components/extensibleButton/ExtensibleButtonGroup'
import { getSupplyTrackingChartParams } from '@/js/trackingUtils'
import { mapActions } from 'vuex'
export default {
  name: 'chartCompare',
  components: {
    ExtensibleButton,
    ExtensibleButtonGroup
  },
  props: [
    'compareStart',
    'compareEnd',
    'cutOffDefault',
    'frazione',
    'activationDate',
    'detail'
  ],
  data() {
    return {
      dialogCompare: false,
      applyCompareFilter: false,
      activePeriod: null,
      cutOff: 0,
      monthOnMonth: false,
      dateStart: { date: '', show: false },
      dateEnd: { date: '', show: false },
      datePrevStart: '',
      datePrevEnd: '',
      dateCustomStart: { date: '', show: false },
      dateCustomEnd: { date: '', show: false },
      dateRangeDisabled: null,
      disableDatePrev: false,
      undoDate: {
        start: '',
        end: '',
        compareStart: '',
        compareEnd: ''
      },
      getSupplyTrackingChartParams: getSupplyTrackingChartParams
    }
  },
  computed: {
    fraction() {
      switch (this.frazione) {
        case '1':
          return 'monthly'
        case '2':
          return 'daily'
        case '3':
          return 'hourly'
        case '4':
          return 'quarterly'
        default:
          return 'monthly'
      }
    },
    minDate() {
      let minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 2)
      )
      let _activationDate = new Date(this.activationDate)
      if (_activationDate && _activationDate > minDate) {
        minDate = _activationDate
      }
      return this.formatDatePicker(minDate)
    },
    maxDate() {
      let maxDate = new Date()
      return this.formatDatePicker(maxDate)
    },
    maxDateCompare() {
      let date = ''
      if (this.dateStart.date) {
        let maxDate = new Date(
          new Date(this.dateStart.date).setDate(
            new Date(this.dateStart.date).getDate() + this.cutOffDefault
          )
        )
        if (
          this.dateCustomStart.date &&
          new Date(this.dateStart.date) < new Date(this.dateCustomStart.date)
        ) {
          if (maxDate > new Date(this.dateCustomStart.date)) {
            maxDate = new Date(
              new Date(this.dateCustomStart.date).setDate(
                new Date(this.dateCustomStart.date).getDate() - 1
              )
            )
          }
        } else {
          if (maxDate > new Date()) {
            maxDate = new Date()
          }
        }
        date = this.formatDatePicker(maxDate)
      }
      return date
    },
    minDateCompare() {
      if (this.dateStart.date) {
        if (this.frazione === '1') {
          let min = new Date(this.dateStart.date)
          return this.formatDatePicker(
            new Date(min.setMonth(min.getMonth() + 1))
          )
        }
        return this.dateStart.date
      }
      return ''
    },
    maxDateCompareWith() {
      let date = ''
      if (
        this.dateCustomStart.date &&
        this.dateStart.date &&
        this.dateEnd.date
      ) {
        let maxDate = new Date(
          new Date(this.dateCustomStart.date).setDate(
            new Date(this.dateCustomStart.date).getDate() + this.cutOffDefault
          )
        )
        if (
          new Date(this.dateCustomStart.date) < new Date(this.dateStart.date)
        ) {
          if (maxDate > new Date(this.dateStart.date)) {
            maxDate = new Date(
              new Date(this.dateStart.date).setDate(
                new Date(this.dateStart.date).getDate() - 1
              )
            )
          }
        } else {
          if (maxDate > new Date()) {
            maxDate = new Date()
          }
        }
        date = this.formatDatePicker(maxDate)
      }
      return date
    },
    minDateCompareWith() {
      if (this.dateCustomStart.date) {
        if (this.frazione === '1') {
          let min = new Date(this.dateCustomStart.date)
          return this.formatDatePicker(
            new Date(min.setMonth(min.getMonth() + 1))
          )
        }
        return this.dateCustomStart.date
      }
      return ''
    },
    disableBtnConferma() {
      return (
        ((!this.dateCustomStart.date || !this.dateCustomEnd.date) &&
          this.activePeriod === 'customPeriod') ||
        !this.activePeriod
      )
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    undoRefresh() {
      this.undoDate = {
        start: this.dateStart.date,
        end: this.dateEnd.date,
        compareStart: this.dateCustomStart.date,
        compareEnd: this.dateCustomEnd.date
      }
    },
    continueAndRefresh(type) {
      let start = new Date(this.dateStart.date)
      let end = new Date(this.dateEnd.date)
      let minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 2)
      )
      let _activationDate = new Date(this.activationDate)
      if (_activationDate && _activationDate > minDate) {
        minDate = _activationDate
      }
      let difference = end.getTime() - start.getTime()
      this.cutOff = difference / (1000 * 3600 * 24)

      let _datePrevStart = new Date(
        new Date(start).setDate(start.getDate() - 1 - this.cutOff)
      )
      if (_datePrevStart < minDate) {
        _datePrevStart = minDate
      }
      let _datePrevEnd = new Date(start.setDate(start.getDate() - 1))
      if (_datePrevEnd <= minDate) {
        this.disableDatePrev = true
      } else {
        this.disableDatePrev = false
      }

      this.datePrevStart = this.formatDatePicker(_datePrevStart)
      this.datePrevEnd = this.formatDatePicker(_datePrevEnd)
      this.undoRefresh()
      switch (type) {
        case 'start':
          this.dateStart.show = false
          break
        case 'end':
          this.dateEnd.show = false
      }
    },
    onExtensibleMounted() {
      if (this.activePeriod) {
        this.$refs.ExtensibleButtonGroup.handlerRadioChanged(this.activePeriod)
        this.$nextTick(() => {
          this.$refs.ExtensibleButtonGroup.changeActiveContent(
            this.$refs.rowContent
          )
          this.$refs.customPeriod.$el.click()
        })
      }
    },
    closeDialog() {
      this.dialogCompare = false
      if (!this.applyCompareFilter) {
        this.cancelFilter()
        this.monthOnMonth = false
      }
      this.trackTapEvent({
        name: 'chartFilter_compareCancel_tap',
        params: getSupplyTrackingChartParams(this.detail, this.fraction)
      })
    },
    setSwitch() {
      if (this.monthOnMonth) {
        this.initDates()
        this.dialogCompare = true
      } else {
        this.cancelFilter()
      }
    },
    initDates() {
      this.disableDatePrev = false
      let minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 2)
      )
      let _activationDate = new Date(this.activationDate)
      if (_activationDate && _activationDate > minDate) {
        minDate = _activationDate
      }
      let _compareStart = new Date(this.compareStart)

      let difference =
        new Date(this.compareEnd).getTime() -
        new Date(this.compareStart).getTime()
      this.cutOff = difference / (1000 * 3600 * 24)

      this.dateStart.date = this.compareStart
      this.dateEnd.date = this.compareEnd

      let _datePrevStart = new Date(
        new Date(_compareStart).setDate(
          _compareStart.getDate() - 1 - this.cutOff
        )
      )
      if (_datePrevStart < minDate) {
        _datePrevStart = minDate
      }
      let _datePrevEnd = new Date(
        new Date(_compareStart).setDate(_compareStart.getDate() - 1)
      )
      if (_datePrevEnd <= minDate) {
        this.disableDatePrev = true
      }
      this.datePrevStart = this.formatDatePicker(_datePrevStart)
      this.datePrevEnd = this.formatDatePicker(_datePrevEnd)
      this.undoRefresh()
    },
    formatDatePicker(date) {
      return `${date.getFullYear()}-${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`
    },
    openPicker(type) {
      switch (type) {
        case 'start':
          this.dateStart.show = true
          break
        case 'end':
          this.dateEnd.show = true
          break
        case 'compareStart':
          this.dateCustomStart.show = true
          break
        case 'compareEnd':
          this.dateCustomEnd.show = true
          break
      }
    },
    filter() {
      this.applyCompareFilter = true
      let paramsDate = {
        dateStart: new Date(this.dateStart.date),
        dateEnd: new Date(this.dateEnd.date),
        frazione: this.frazione
      }
      let paramsCompare = {
        dateStart:
          this.activePeriod === 'prevPeriod'
            ? new Date(this.datePrevStart)
            : new Date(this.dateCustomStart.date),
        dateEnd:
          this.activePeriod === 'prevPeriod'
            ? new Date(this.datePrevEnd)
            : new Date(this.dateCustomEnd.date),
        frazione: this.frazione
      }
      this.undoRefresh()

      this.dialogCompare = false
      let params = { paramsDate: paramsDate, paramsCompare: paramsCompare }
      this.$emit('refreshFilters', params)
    },
    cancelFilter() {
      this.dialogCompare = false
      this.activePeriod = null
      this.datePrevStart = ''
      this.datePrevEnd = ''
      this.dateCustomStart = { date: '', show: false }
      this.dateCustomEnd = { date: '', show: false }
      this.applyCompareFilter = false
      this.$parent.removeDateSelected('to')
    },
    allowedDates(date) {
      if (this.dateCustomStart.date && this.dateCustomEnd.date) {
        return (
          new Date(date).getTime() <
            new Date(this.dateCustomStart.date).getTime() ||
          new Date(date).getTime() > new Date(this.dateCustomEnd.date).getTime()
        )
      } else {
        return true
      }
    },
    allowedDatesCompared(date) {
      if (this.dateStart.date && this.dateEnd.date) {
        return (
          new Date(date).getTime() < new Date(this.dateStart.date).getTime() ||
          new Date(date).getTime() > new Date(this.dateEnd.date).getTime()
        )
      } else {
        return true
      }
    },
    formatDateWithSeparatorFrazione: function (value, separator = '.') {
      function pad(s) {
        return s < 10 ? '0' + s : s
      }
      if (!value) return ''
      if (value.indexOf('T') > -1)
        value = value.substring(0, value.indexOf('T'))
      var d
      value = value.split('/').reverse().join('-') // light format must be reworked
      d = new Date(value)
      if (this.frazione === '1') {
        return [pad(d.getMonth() + 1), d.getFullYear()].join(separator)
      } else {
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
          separator
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.chart-utils {
  width: 100%;
}
.btn-chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 263px;
  height: 51px;
  border-radius: 20px;
  border: solid 1.1px #dbdbdb;
  font-size: 16px;
  font-weight: 500;
  background: white;
  color: $srg-blue;
  padding: 0 16px;
  cursor: pointer;
  &:before {
    content: url('../../assets/icons/calendar.svg');
    margin-right: 20px;
  }
  &:after {
    content: url('../../assets/icons/ic-chevron-down-blue.svg');
    transform: rotate(180deg);
    width: 18px;
    margin-left: 8px;
    padding-top: 5px;
  }
  &.download:after {
    content: url('../../assets/ic_download_small_blue.svg');
    width: 18px;
    margin-left: 8px;
    padding-top: 5px;
    transform: initial;
  }
  &.download:before {
    content: url('../../assets/ic-graphic-grey.svg');
    width: 18px;
    margin-left: 8px;
    padding-top: 5px;
  }
  &.big {
    max-width: 289px;
    height: 66px;
    padding-left: 26px;
    justify-content: start;
    &:before {
      width: 32px;
    }
    &:after {
      content: '';
    }
  }
}
.chart-switch {
  .chart-switch__label {
    h5 {
      font-size: 18px;
      font-weight: bold;
      color: $srg-blue;
    }
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .chart-switch__new-date {
    color: black;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
}

.v-dialog__content {
  .compare-dialog {
    background: white;
    padding: 48px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    h4 {
      span {
        font-weight: bold;
      }
    }
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      &.preview {
        position: initial;
      }
    }
    .compare-panel {
      padding: 24px;
      border-radius: 20px;
      background-color: #f6f6f6;
      margin-bottom: 30px;
      h5 {
        color: black;
        font-size: 18px;
      }
    }
  }
}
</style>
