var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","max-width":"910"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('button',_vm._g({staticClass:"dialog__open",on:{"click":function($event){return _vm.trackTapEvent({
          name: 'greeners_diaryLevel_tap',
          params: {
            client_code: _vm.mainClientLoyaltySelected.clientOwnerCode,
            client_type: _vm.mainClientLoyaltySelected.clientOwnerType
          }
        })}}},on),[_vm._v(" A che punto sei del percorso? ")])]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(!_vm.viewGuide)?_c('v-card',{staticClass:"livelli-dialog"},[_c('div',{staticClass:"livelli-dialog__content"},[_c('h3',[_vm._v("A che punto sei del percorso")]),_c('img',{attrs:{"src":require("@/assets/ic_chevron_top_black.png")},on:{"click":function($event){_vm.modal = false}}})]),_c('p',[_vm._v(" Ogni 8 missioni completate, sali di livello e ricevi un premio in Green Coins di valore crescente per un totale di 2000 Green Coins extra ricevuti! ")]),_c('div',{staticClass:"contentImgLevel"},[_c('img',{staticClass:"imgLevel",attrs:{"src":`${require('@/assets/greeners/levels/' +
          _vm.clientLevel.level_name?.toLowerCase() +
          '.svg')}`}}),_c('div',{class:`contentLevel ${_vm.clientLevel.level_name?.toLowerCase()}`},[(
            _vm.clientLevel.level_name === 'LEVEL0' ||
            _vm.clientLevel.level_name === 'LEVEL4'
          )?_c('div',{staticClass:"d-flex justify-center flex-column align-center mb-2"},[_c('h5',{staticClass:"levelText"},[_vm._v(_vm._s(_vm.levelText))]),_c('h5',{staticClass:"step"},[_vm._v(_vm._s(_vm.clientLevel.level_step + 1)+"/8")])]):_vm._e(),_c('StatusIcon',{attrs:{"withProgressionBar":"","width":"140","client-level":_vm.clientLevel || {}}}),(
            _vm.clientLevel.level_name === 'LEVEL1' ||
            _vm.clientLevel.level_name === 'LEVEL2' ||
            _vm.clientLevel.level_name === 'LEVEL3'
          )?_c('div',{staticClass:"d-flex justify-center flex-column align-center mt-2"},[_c('h5',{staticClass:"levelText"},[_vm._v(_vm._s(_vm.levelText))]),_c('h5',{staticClass:"step"},[_vm._v(_vm._s(_vm.clientLevel.level_step + 1)+"/8")])]):_vm._e()],1)]),_c('div',{staticClass:"d-flex justify-center mt-8"},[_c('v-btn',{staticClass:"white lowerCase",attrs:{"width":"227","height":"54"},on:{"click":function($event){_vm.viewGuide = true}}},[_vm._v("Guida ai livelli")])],1)]):_c('v-card',{staticClass:"livelli-dialog"},[_c('div',{staticClass:"livelli-dialog__content"},[_c('div',{staticClass:"textBack"},[_vm._v("Guida ai livelli")]),_c('img',{attrs:{"src":require("@/assets/ic_chevron_top_black.png")},on:{"click":function($event){_vm.modal = false}}})]),_c('LevelGuides')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }