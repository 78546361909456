<template>
  <div class="water">
    <div class="water__btn" @click="next('ELE')">
      <h5>Boiler elettrico</h5>
    </div>
    <div class="water__btn" @click="next('GAS')">
      <h5>Scaldabagno a gas</h5>
    </div>
    <div class="water__btn mb-10" @click="next('CEN')">
      <h5>Impianto centralizzato</h5>
    </div>
    <div class="water__box">
      <div class="title">Perchè te lo chiediamo</div>
      <h5 class="desc">
        Insieme agli altri dati che ci fornirai, la modalità che utilizzi per
        scaldare l'acqua ci aiuta stabilire il tuo profilo di consumo.
      </h5>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'heatWater',
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetail']),
    ...mapActions('analytics', ['trackTapEvent']),
    next(type) {
      this.setSupplyDetail({ waterHeatintMode: type })
      this.$emit('nextStep', 'upsellingcom_heathome_open')
      this.trackTapEvent({
        name: `upsellingcom_heatwater${type}_tap`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.water {
  max-width: 652px;
  color: black;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 66px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #ccd0e1;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
</style>
