<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <v-breadcrumbs
          :items="breadcrumbsItems"
          divider="<"
          class="pb-0 reverse-breadcrumbs"
        >
          <template v-slot:divider>
            <img src="@/assets/icons/chevron-sx-blue.svg" width="24" />
          </template>
        </v-breadcrumbs>
        <h1 class="HeadingsTitleXXL">Ritira i tuoi premi</h1>
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-img
          :src="require('@/assets/greeners/svg/logoGreeners_new.svg')"
          contain
          width="360"
        />
      </v-col>
    </template>
    <div class="reward__content">
      <v-row>
        <v-col class="pt-0 pb-0">
          <h2 class="HeadingL">Premio Fedeltà</h2>
        </v-col>
      </v-row>
      <v-row class="mb-10" v-if="catalog">
        <v-col
          v-for="({ attributes }, index) in catalog"
          :key="index"
          class="mt-2"
          cols="3"
        >
          <v-card
            class="gift-item"
            light
            outlined
            :disabled="!attributes.prizeoption_id"
            @click="claimPrize(attributes)"
          >
            <v-img :src="itemImages[index]" class="gift-item__img" contain />
            <h3 class="gift-item__title" v-text="attributes.title" />
          </v-card>
        </v-col>
      </v-row>
      <v-footer color="white" height="90" />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Layout from '../Layout'
export default {
  name: 'IWGiftPage',
  components: {
    layout: Layout
  },
  beforeMount() {
    this.getAvailableAwards()
    this.getCatalog({
      catalog_name: 'IW-WL',
      codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
    })
    this.getCatalog({
      catalog_name: 'IW',
      codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
    })
  },
  computed: {
    ...mapGetters('loyalty', ['availableAwards', 'catalog_iwwl', 'catalog_iw']),
    ...mapGetters('account', ['mainClientLoyaltySelected']),
    breadcrumbsItems() {
      let res = [
        {
          to: { name: 'giftsMainPage' },
          text: 'Torna al Ritira premi',
          exact: true
        },
        { to: { name: this.$router.currentRoute.name }, text: '' }
      ]

      return res
    },
    catalog() {
      let catalogIWWL = []
      let catalogIW = []
      if (this.availableAwards.iw === '002') {
        catalogIW = this.catalog_iw.flat()
      }
      if (this.availableAwards.welcome_iw === '002') {
        catalogIWWL = this.catalog_iwwl.flat()
      }
      return catalogIWWL.concat(catalogIW)
    },
    itemImages() {
      return (
        this.catalog.map((el) => {
          let res = null
          if (
            el.attributes &&
            el.attributes.images &&
            el.attributes.images.data
          ) {
            let img = el.attributes.images.data.find(
              ({ label }) => label === 'cover'
            )
            res = img
              ? img.image_small
              : el.attributes.images.data[0].image_small
          }
          return res
        }) || []
      )
    }
  },
  methods: {
    ...mapActions('loyalty', [
      'getCatalog',
      'getAwardInfo',
      'getAvailableAwards'
    ]),
    claimPrize(attributes) {
      this.getAwardInfo({
        id: attributes.prizeoption_id,
        catalog: 'IW-WL'
      }).then((res) => {
        this.$router.push({
          name: attributes.is_required_shipping
            ? 'giftShipping'
            : 'giftEmailShipping',
          params: {
            catalog: 'IW-WL',
            type: res.type,
            id: attributes.prizeoption_id
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reward {
  &__content {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;

    p {
      font-size: 20px;
      color: black;
    }
  }
}

.gift-item {
  height: 100%;
  border-radius: 44px !important;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;

  &__img {
    margin: 1px 0 0;
  }

  &__title {
    border-top: 1px solid #979797;
    font-size: 24px;
    font-weight: bold;
    padding: 12px 24px 12px;
    line-height: 1.2;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: black;
    padding: 0 24px;
  }
}
</style>
