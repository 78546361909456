var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promo"},[_c('transition',{attrs:{"name":"fade"}},[(true)?_c('div',{staticClass:"d-flex justify-space-around"},[_c('Placeholder',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loaded),expression:"!loaded"}],attrs:{"type":"promo"}}),_c('Placeholder',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loaded),expression:"!loaded"}],attrs:{"type":"promo"}}),_c('Placeholder',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loaded),expression:"!loaded"}],attrs:{"type":"promo"}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(true)?_c('div',[(_vm.loaded && _vm.promoFilter.length <= 3)?_c('div',{staticClass:"VueCarousel d-flex flex-row justify-content-start"},_vm._l((_vm.promoFilter),function(promo,i){return _c('div',{key:i,staticClass:"promo-element",class:{
            'std-side-padding': _vm.promoFilter.length == 1 && _vm.mainClient.isOnlyFV
          }},[_c('PromoRouting',{attrs:{"promo":promo},scopedSlots:_vm._u([{key:"contentPromo",fn:function(){return [_c('div',{staticClass:"item",class:{
                  'item only-one-promo':
                    _vm.promoFilter.length == 1 && _vm.mainClient.isOnlyFV
                },style:(`background-image: url('${
                  promo.id === 'welcomekit'
                    ? promo.background_image
                    : _vm.cdn + promo.background_image
                }');`),on:{"click":function($event){return _vm.trackTapEvent({
                    name: 'vetrina_promo_tap',
                    params: { promo_id: promo.id, promo_title: promo.title }
                  })}}},[_c('div',{staticClass:"item__title",domProps:{"innerHTML":_vm._s(promo.title)}})])]},proxy:true}],null,true)})],1)}),0):_vm._e(),(_vm.loaded && _vm.promoFilter.length > 3)?_c('carousel',{attrs:{"perPage":3,"paginationEnabled":true,"paginationColor":"#b7bfd2","paginationActiveColor":"#12256A","paginationPadding":5}},_vm._l((_vm.promoFilter),function(promo,i){return _c('slide',{key:i},[_c('PromoRouting',{attrs:{"promo":promo},scopedSlots:_vm._u([{key:"contentPromo",fn:function(){return [_c('div',{staticClass:"item",style:(`background-image: url('${
                  promo.id === 'welcomekit'
                    ? promo.background_image
                    : _vm.cdn + promo.background_image
                }');`),on:{"click":function($event){return _vm.trackTapEvent({
                    name: 'vetrina_promo_tap',
                    params: { promo_id: promo.id, promo_title: promo.title }
                  })}}},[_c('div',{staticClass:"item__title",domProps:{"innerHTML":_vm._s(promo.title)}})])]},proxy:true}],null,true)})],1)}),1):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }