<template>
  <div class="faq">
    <div class="page mb-10">
      <v-row class="std-side-padding">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="HeadingsTitleXXL">{{ $route.params.serviceName }}</div>
          <div class="BodyXL mt-3 mb-8">
            Qui troverai le risposte alle domande frequenti {{ article }}
            {{ $route.params.serviceName }}.
          </div>
        </v-col>
      </v-row>
      <faq-list
        :sub-categories="$route.params.subCategories"
        :media="$route.params.media"
        :categoryName="$route.params.categoryName"
        :serviceName="$route.params.serviceName"
      />
    </div>
    <FooterSupport :serviceName="$route.params.serviceName" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import FAQList from '../../components/support/FAQList'
import FooterSupport from '../../components/support/Footer.vue'

export default {
  name: 'faqComponent',
  components: {
    FaqList: FAQList,
    FooterSupport
  },
  beforeMount() {
    if (!this.$route.params.subCategories) {
      this.$router.push({
        name: 'SupportPage'
      })
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  },
  computed: {
    breadcrumbsItems() {
      let text =
        this.$route.params.categoryName === 'Fibra'
          ? 'Fibra'
          : this.$route.params.categoryName === 'Luce e Gas'
          ? 'Luce e gas'
          : this.$route.params.categoryName === 'Fotovoltaico'
          ? 'Fotovoltaico'
          : 'Comunità energetiche'
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: text }
      ]

      return res
    },
    article() {
      switch (this.$route.params.serviceName) {
        case 'Luce e Gas':
          return 'su'
        case 'Fotovoltaico':
          return 'sul'
        case 'Fiber':
          return 'sulla'
        default:
          return 'su'
      }
    }
  }
}
</script>

<style src="@/styles/support/_main.scss" lang="scss"></style>
<style lang="scss">
.faq {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .contacts-button {
    box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12) !important;
    background-color: white !important;
    color: #12256a !important;
    width: 250px;
    margin-top: 0px;
  }
}
</style>
