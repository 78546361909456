<template>
  <v-dialog :max-width="500" v-model="show">
    <div class="billsRecapDialog pdrDialogRate">
      <div class="d-flex align-center justify-space-between mb-8">
        <div class="HeadingsSubtitleL">Piani di rientro da pagare</div>
        <img
          @click="show = false"
          src="@/assets/ic_chevron_top_black.png"
          style="cursor: pointer"
        />
      </div>
      <v-row no-gutters>
        <v-col>
          <v-list two-line class="scrollableList">
            <div class="d-flex align-center mb-4">
              <div class="BodyL-Strong">{{ intestatario }}</div>
              <img
                :src="IconOwner"
                v-if="clientDetails && clientDetails.clientOwnerType === 'RES'"
                width="22"
                class="supply__owner-icon"
              />
              <img
                :src="IconBusiness"
                v-if="clientDetails && clientDetails.clientOwnerType !== 'RES'"
                width="22"
                class="supply__owner-icon"
              />
            </div>
            <div
              @click="selectAllAction"
              :class="`checkbox all d-flex align-center ${
                selectAll ? 'selected' : ''
              }`"
            >
              <span class="BodyL">Seleziona tutto</span>
            </div>
            <div v-for="(pdr, index) in pdrListTotal" :key="index">
              <v-list-item class="pr-4 pl-0">
                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center">
                    <div
                      :id="pdr.planId"
                      @click="addPerPagare(pdr, index)"
                      :class="`checkbox d-flex align-center ${
                        pdr.checked ? 'selected' : ''
                      } ${pdr.disabled ? 'disabled' : ''}`"
                    >
                      <span class="BodyL"
                        >Piano di rientro
                        {{ pdr.planEndDate.replaceAll('/', '.') }}</span
                      >
                    </div>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <span class="BodyL-Strong">
                    € {{ getTotal(pdr.total) }}
                  </span>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-btn
            class="paymentButton"
            x-large
            @click="pay"
            :disabled="totaleDaPagare <= 0"
          >
            {{
              totaleDaPagare > 0 ? `Paga € ${getTotal(totaleDaPagare)}` : 'Paga'
            }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import IconBusiness from '@assets/icons/business_black.svg'
import IconOwner from '@assets/icons/ic-owner-res-black.svg'
import { mapActions } from 'vuex'
import Consts from '@/js/constants'
import { getTotal } from '../../js/utils'
import { dynamicSortCresc } from '@/js/utils.js'

export default {
  name: 'dialogPagaPdr',
  props: {
    value: Boolean,
    clientDetails: Object,
    pdrDetail: null
  },
  data() {
    return {
      Consts: Consts,
      IconBusiness,
      IconOwner,
      getTotal: getTotal,
      totaleDaPagare: 0,
      selectAll: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    pdrListTotal() {
      let isRecurrent = ['CCR', 'SDD', 'DPAY']
      let arrayPdr = null
      arrayPdr = this.pdrDetail
      if (!Array.isArray(this.pdrDetail)) {
        arrayPdr = Object.values(this.pdrDetail)
      }
      arrayPdr.forEach((pdr, i) => {
        pdr.sortableDate = new Date(
          pdr.planEndDate.split('/').reverse().join('-')
        ).setHours(0, 0, 0, 0)
        pdr.total = pdr.instalments.reduce((sum, rata) => {
          return sum + parseFloat(rata.residualInstalmentAmount)
        }, 0)
        if (pdr.titles) {
          pdr.isPossibleToPayPDR = false
        } else if (isRecurrent.includes(pdr.planPaymentMethod)) {
          if (
            pdr.instalments.filter(
              (instalment) =>
                instalment.instalmentStatus !== Consts.STATUS_PDR.EXPIRED
            ).length > 0
          ) {
            pdr.isPossibleToPayPDR = false
          } else {
            pdr.isPossibleToPayPDR = true && pdr.total > 0
          }
        } else {
          pdr.isPossibleToPayPDR = true && pdr.total > 0
        }
        pdr.disabled = false
        pdr.checked = false
      })
      arrayPdr = arrayPdr
        .filter((pdr) => pdr.isPossibleToPayPDR)
        .sort(dynamicSortCresc('-sortableDate'))
      // if (arrayPdr.length > 0) {
      //   arrayPdr[0].disabled = false
      // }

      return arrayPdr
    },
    intestatario() {
      if (this.clientDetails?.clientOwnerType === 'BUS') {
        return this.clientDetails?.businessName
      } else {
        return (
          this.clientDetails?.firstName + ' ' + this.clientDetails?.lastName
        )
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),

    selectAllAction() {
      this.selectAll = !this.selectAll
      this.pdrListTotal.forEach((rata, i) => {
        rata.checked = this.selectAll
      })
      this.calcTotalRateSelected()
    },
    addPerPagare(pdr, index) {
      this.selectAll = false
      this.pdrListTotal[index].checked = !pdr.checked
      if (
        this.pdrListTotal.filter((pdrChecked) => pdrChecked.checked).length ===
        this.pdrListTotal.length
      )
        this.selectAll = true
      this.calcTotalRateSelected()
    },
    calcTotalRateSelected() {
      this.totaleDaPagare = 0
      this.pdrListTotal.forEach((pdr) => {
        if (pdr.checked) {
          this.totaleDaPagare = pdr.instalments.reduce((sum, rata) => {
            return sum + parseFloat(rata.residualInstalmentAmount)
          }, this.totaleDaPagare)
        }
      })
    },
    pay() {
      this.trackTapEvent('payment_items_selected')
      let instalments = this.pdrListTotal.reduce((a, pdr) => {
        return a.concat(pdr.instalments)
      }, [])
      this.$router.push({
        name: 'payAllBills',
        params: {
          clientOwnerCode:
            this.clientDetails.clientOwnerCode ||
            this.clientDetails.codiceCliente,
          pdrs: instalments,
          isMultiPayment: instalments.length > 1,
          from: this.$route.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/dialog_pay_bills/dialogPayBills.scss';
</style>
