<template>
  <layout header-size="small" class="gift-code">
    <template v-slot:header>
      <v-col cols="6">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
        <h1>Inserisci il Codice!</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div class="gift-code__content">
      <v-row>
        <v-col cols="12">
          <p class="HeadingsTitleXL">Inserisci il codice</p>
          <p class="BodyL">
            Hai ricevuto un Codice o un amico ti ha invitato ad unirti alla
            community? Inserisci qui sotto il codice ricevuto e scopri cosa hai
            ottenuto!
          </p>
        </v-col>
        <v-col cols="4" class="custom-input-field">
          <v-text-field
            tabindex="1"
            class="auth__input"
            clearable
            label="CODICE"
            placeholder="Inserisci"
            persistent-placeholder
            color="#6e7689"
            validate-on-blur
            :rules="[rules.required]"
            v-model.trim="giftCode"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left">
          <v-btn
            :disabled="isSubmitButtonDisabled"
            :outlined="isSubmitButtonDisabled"
            class="v-btn--shadowed"
            color="primary"
            x-large
            width="154"
            @click="checkCode"
          >
            <div>
              <span v-if="!loading">Vai</span>
              <v-progress-circular
                v-if="loading"
                :size="30"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-btn>
        </v-col>
      </v-row>
      <v-footer color="white" height="90" />
    </div>
    <v-dialog v-model="dialogCodeRedemption" max-width="560">
      <div class="greeners-code-redemption-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="dialogCodeRedemption = false"
        />
        <div class="greeners-code-redemption-modal__image-container">
          <img
            src="../../../assets/greeners/svg/code-redemption-success.svg"
            width="140"
          />
        </div>
        <div class="greeners-code-redemption-modal__body">
          <p class="HeadingsSubtitleXL">{{ titleDialog }}</p>

          <div
            class="greeners-code-redemption-modal__quantity-coins"
            v-if="success && !istantWin"
          >
            <img
              src="../../../assets/greeners/svg/coins-medium.svg"
              width="48"
            />
            <p class="HeadingsTitleXXL">{{ coinsAmount || 0 }}</p>
          </div>
          <p v-if="subtitleDialog || rewardData.title" class="BodyL">
            {{ rewardData.title || subtitleDialog }}
          </p>
          <v-img
            contain
            v-if="istantWin"
            class="card__image"
            height="228"
            width="290"
            :src="rewardData.images.data[0].image_small"
          />
        </div>
        <div class="greeners-code-redemption-modal__footer">
          <v-btn
            color="primary"
            class="v-btn--shadowed"
            height="54"
            width="231"
            @click="
              istantWin ? navigate(rewardData) : (dialogCodeRedemption = false)
            "
          >
            {{ istantWin ? ' Si, voglio il premio' : 'Vai al diario' }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </layout>
</template>

<script>
import Layout from '../Layout'
import { mapGetters, mapActions } from 'vuex'
import { AlertService } from '../../../js/service/alertService'

export default {
  name: 'GiftCodePage',
  components: {
    layout: Layout
  },
  data: () => ({
    giftCode: '',
    coinsBack: false,
    success: true,
    titleDialog: '',
    subtitleDialog: '',
    istantWin: false,
    rewardData: '',
    coinsAmount: 0,
    loading: false,
    dialogCodeRedemption: false,
    rules: {
      required: (value) => !!value || 'Required.'
    }
  }),
  beforeMount() {},
  computed: {
    ...mapGetters('account', ['clientDetails', 'mainClientLoyaltySelected']),
    isSubmitButtonDisabled() {
      return this.giftCode === '' || this.loading
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        { to: { name: 'loyaltyPage' }, text: 'Diario Greeners', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Bonus per te' }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('loyalty', ['validateIWCode', 'burnIWCode', 'getReward']),
    ...mapActions('analytics', ['trackTapEvent']),
    navigate(data) {
      this.$router.push({
        name: data.is_required_shipping ? 'giftShipping' : 'giftEmailShipping',
        params: {
          catalog: !this.coinsBack
            ? 'IW'
            : this.mainClientLoyaltySelected.clientOwnerType === 'RES'
            ? 'COLLECTION'
            : 'COLLECTION-BS',
          id: data.prizeoption_id
        }
      })
    },
    isCodeGreeners(code) {
      let codeSplitted = code.split('-')
      return codeSplitted.indexOf('GREENERS') > -1
    },
    checkCode() {
      this.loading = true
      if (this.isCodeGreeners(this.giftCode)) {
        // burn-mgm-code
        this.burnIWCode({ mgm_code: this.giftCode })
          .then((res) => {
            if (res.status === 200) this.dialogCodeRedemption = true
          })
          .catch((err) => {
            this.loading = false
            this.trackTapEvent('greeners_error_view')

            if (
              err.response.data.status === 'KO' &&
              err.response.data.wekit_code === 1001
            ) {
              AlertService(
                'Il codice è inesistente o errato',
                ' ',
                false,
                'Chiudi',
                '#'
              )
            } else if (
              err.response.data.status === 'KO' &&
              err.response.data.wekit_code === 1002
            ) {
              AlertService('Il codice è scaduto', ' ', false, 'Chiudi', '#')
            } else if (
              err.response.data.status === 'KO' &&
              err.response.data.wekit_code === 1003
            ) {
              AlertService(
                'Hai già utilizzato un codice amico Greeners',
                ' ',
                false,
                'Chiudi',
                '#'
              )
            } else if (err.status === 500) {
              AlertService(
                'Qualcosa è andato storto',
                "Si è verificato un errore inatteso, riprova tra qualche minuto. L'eventuale accredito punti potrebbe essere comunque avvenuto. Controlla il tuo Saldo Punti.",
                false,
                'Chiudi',
                '#'
              )
            }
          })
      } else {
        // play-code
        this.validateIWCode({ code: this.giftCode })
          .then((res) => {
            this.loading = false
            if (
              res.code_status === 'WRONG' ||
              res.code_status === 'ALREADY-USED'
            ) {
              this.trackTapEvent('greeners_error_view')
              AlertService(
                'Il codice sembra essere non valido o già utilizzato!',
                ' ',
                false,
                'Chiudi',
                '#'
              )
              this.success = false
            } else if (
              res.code_status === 'OK' &&
              res.code_category === 'IW' &&
              !!res.iw_result
            ) {
              this.getReward()
                .then((res) => {
                  this.coinsBack = false
                  this.success = true
                  this.istantWin = true
                  this.dialogCodeRedemption = true
                  this.titleDialog = 'Complimenti hai vinto'
                  this.rewardData = res
                })
                .catch(() => {
                  this.dialogCodeRedemption = false
                  this.trackTapEvent('greeners_error_view')
                  AlertService(
                    'Complimenti hai vinto!',
                    'Per richiedere il tuo premio torna al Diario e vai nella sezione “Premio da ritirare”.',
                    'success',
                    'Vai al diario',
                    'loyaltyPage'
                  )
                })
            } else if (res.code_category === 'IW' && !res.iw_result) {
              this.trackTapEvent('greeners_error_view')
              AlertService(
                'Purtroppo non hai vinto!',
                '...ma un Greener non si arrende al primo ostacolo!',
                false,
                'Chiudi',
                '#'
              )
            } else if (
              res.code_status === 'OK' &&
              (res.code_category === 'COLLECTION' ||
                res.code_category === 'COLLECTION_BUSINESS')
            ) {
              this.dialogCodeRedemption = true
              this.coinsBack = true
              this.success = true
              this.coinsAmount = res.points
              this.titleDialog = 'Hai ottenuto'
              this.subtitleDialog = 'Green Coins'
            } else {
              this.trackTapEvent('greeners_error_view')
              AlertService(
                'Qualcosa è andato storto',
                "Si è verificato un errore inatteso, riprova tra qualche minuto. L'eventuale accredito punti potrebbe essere comunque avvenuto. Controlla il tuo Saldo Punti.",
                false,
                'Chiudi',
                '#'
              )
            }
          })
          .catch(() => {
            this.trackTapEvent('greeners_error_view')
            AlertService(
              'Qualcosa è andato storto',
              "Si è verificato un errore inatteso, riprova tra qualche minuto. L'eventuale accredito punti potrebbe essere comunque avvenuto. Controlla il tuo Saldo Punti.",
              false,
              'Chiudi',
              '#'
            )
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-code {
  &__content {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;
  }
  label.v-label.theme--light {
    top: -25px;
    transition: none;
    transform: none;
  }
  .theme--light.v-input input {
    color: $srg-blue !important;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .v-text-field .v-label {
    color: #6e7689 !important;
  }
  .header-main {
    padding-bottom: 16px;
  }
}
</style>
