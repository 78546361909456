<template>
  <div class="cardOffer">
    <transition name="fade" v-if="!loaded">
      <Placeholder type="offer" />
    </transition>
    <transition name="fade" v-else>
      <template v-if="accountProperties.type === 'RES'">
        <!-- <carousel
          v-if="loaded"
          :perPage="1"
          :navigationEnabled="false"
          :paginationEnabled="true"
          :autoplay="true"
          :autoplayTimeout="5000"
          :loop="true"
          paginationColor="#b7bfd2"
          paginationActiveColor="#12256A"
          :paginationPadding="5"
          easing="ease-in-out"
          @pageChange="initialLoad = false"
        >
          <slide :class="{ 'VueCarousel-slide-active': initialLoad }">
            <OfferCardItem type="fotovoltaico" />
          </slide>
          <slide>
            <OfferCardItem type="business" />
          </slide>
        </carousel> -->
        <OfferCardItem type="business" />
      </template>
      <template v-else-if="accountProperties.type === 'BUS'">
        <OfferCardItem type="casa" />
      </template>
      <!-- <template v-else>
        <OfferCardItem type="fotovoltaico" />
      </template> -->
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Placeholder from '@components/global/Placeholder.vue'
import OfferCardItem from './OfferCardItem.vue'

export default {
  name: 'offerCard',
  components: {
    Placeholder,
    OfferCardItem
  },
  props: {
    loaded: { type: Boolean, default: false }
  },
  data() {
    return {
      initialLoad: true
    }
  },
  computed: {
    ...mapGetters('account', ['accountProperties'])
  }
}
</script>
<style lang="scss">
.cardOffer {
  .VueCarousel-wrapper {
    background: #eaeef5;
    border-radius: 15px;
  }
  .VueCarousel-inner {
    transition: transform 0s ease !important;
  }

  .VueCarousel-slide {
    opacity: 0 !important;
    flex-basis: 0;
  }

  .VueCarousel-slide-active {
    transition: all 0.8s !important;
    opacity: 1 !important;
  }
}
</style>
<style lang="scss" scoped>
.cardOffer {
  width: 360px;
}
</style>
