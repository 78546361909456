<template>
  <div class="successPage bkg--blue">
    <TcVars />
    <v-row class="successPage__content">
      <v-col :class="`col-5 img-${status}`"></v-col>
      <v-col class="col-7 info-block" v-if="loaded">
        <h1 class="info-block__title" v-html="title"></h1>
        <span class="info-block__text" v-html="text"></span>
        <v-btn
          @click="$router.push({ name: 'supplyDetail' })"
          class="info-block__button v-btn--shadowed"
          x-large
          width="260"
          >Torna alla fornitura</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import constants from '@/js/constants'
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'thankyouComponent',
  data() {
    return {
      loaded: false,
      status: ''
    }
  },
  components: {
    TcVars
  },
  methods: {
    ...mapActions('interactions', ['getChannelInfo']),
    ...mapActions('analytics', ['trackTapEvent'])
  },
  created() {
    this.getChannelInfo({
      channel: constants.CALLCENTER_CHANNELS.LANDLINE,
      area: constants.CALLCENTER_AREAS.SUPPORT
    }).then((channel) => {
      this.loaded = true
      const ccNumber = channel.contact

      const from =
        (this.$route.params && this.$route.params.from) || 'contactme'
      this.status = (this.$route.params && this.$route.params.status) || 'ko'

      if (this.status === 'ok') {
        this.trackTapEvent('selfReading_thypage_view')
      }

      const inputs = from + '--' + this.status

      switch (inputs) {
        case 'base--ok':
          this.title = 'Grazie! <br>Autolettura inviata con successo.'
          this.text = ''
          break
        case 'contactme--ok':
          this.title = 'Grazie! <br> La tua richiesta è <br> stata inviata!'
          this.text = ''
          break
        case 'base--ko':
        case 'contactme--ko':
          this.title = 'Ci dispiace'
          this.text =
            'Purtroppo non è stato possibile inviare la tua lettura. Se desideri proseguire, ti preghiamo di chiamare il nostro call center al numero ' +
            ccNumber +
            '.<br>Grazie!'
          break
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.successPage {
  height: 100%;
  h1 {
    color: white;
  }
  &__content {
    height: 100%;
    .img-ok {
      background-image: url('../../../../assets/img_typ_girl.png');
      background-size: cover;
      background-position: center;
    }
    .img-ko {
      background-image: url('../../../../assets/img_typ_girl-ko.png');
      background-size: cover;
      background-position: center;
    }
    .info-block {
      color: white;
      padding: 0 calc((58% - 551px) / 2);
      &__title {
        padding: 100px 0 30px 0;
        font-size: 2.4rem;
        font-weight: bold;
        line-height: normal;
      }
      &__text {
        margin-top: 10px;
        font-weight: bold;
        font-size: 1.4rem;
      }
      &__button {
        color: $srg-blue;
        margin-top: 20px;
        display: block;
        margin-bottom: 100px;
      }
    }
  }
}
</style>
