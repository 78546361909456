import { axiosACN, axiosCMS } from '@/js/axiosInstances'
import { capitalize } from '@/js/utils'
import NewError from '@/js/service/newError'

export default {
  namespaced: true,
  state: {
    userProfile: '',
    addressSelected: '',
    addressSaved: [],
    house: [],
    mobility: {},
    photovoltaic: [],
    typOnboardingHouse: false,
    typOnboardingPhotovoltaic: false,
    typOnboardingMobility: false,
    tempAddress: {}
  },
  getters: {
    userProfile(state) {
      return state.userProfile
    },
    addressSelected(state) {
      return state.addressSelected
    },
    addressSaved(state) {
      return state.addressSaved
    },
    house(state) {
      return state.house
    },
    houseSelected(state) {
      return state.house.filter(
        (house) => house.egonIndirizzo === state.addressSelected.egonIndirizzo
      )[0]
    },
    mobility(state) {
      return state.mobility
    },
    photovoltaic(state) {
      return state.photovoltaic
    },
    photovoltaicSelected(state) {
      return state.photovoltaic.filter(
        (photovoltaic) =>
          photovoltaic.egonIndirizzo === state.addressSelected.egonIndirizzo
      )[0]
    },
    typOnboardingHouse(state) {
      return state.typOnboardingHouse
    },
    typOnboardingPhotovoltaic(state) {
      return state.typOnboardingPhotovoltaic
    },
    typOnboardingMobility(state) {
      return state.typOnboardingMobility
    },
    getTempAddress(state) {
      return state.tempAddress
    }
  },
  mutations: {
    setUserProfile(state, userProfile) {
      state.userProfile = userProfile
    },
    setAddressSelected(state, addressSelected) {
      state.addressSelected = addressSelected
    },
    setAddressSaved(state, addressSaved) {
      state.addressSaved = addressSaved
    },
    setHouse(state, house) {
      state.house = house
    },
    setMobility(state, mobility) {
      state.mobility = mobility
    },
    setSorgeniaServices(state, cards) {
      state.cards = cards
    },
    setPhotovoltaic(state, photovoltaic) {
      state.photovoltaic = photovoltaic
    },
    setHouseValues(state, value) {
      var addressIndex = state.house.findIndex((house) => {
        return house.egonIndirizzo === state.addressSelected.egonIndirizzo
      })
      if (addressIndex < 0) {
        state.house.push({
          egonIndirizzo: '',
          indirizzo: '',
          tipoAbitazione: '',
          dimensioneCasa: 0,
          nPersone: 0,
          tipoTitolarita: '',
          saved: false,
          completed: false,
          edited: false
        })
        addressIndex = state.house.length - 1
      }
      Object.keys(value).forEach((key) => {
        state.house[addressIndex] = {
          ...state.house[addressIndex],
          [key]: value[key]
        }
      })
    },
    setPhotovoltaicValues(state, value) {
      var addressIndex = state.photovoltaic.findIndex((photovoltaic) => {
        return (
          photovoltaic.egonIndirizzo === state.addressSelected.egonIndirizzo
        )
      })
      if (addressIndex < 0) {
        state.photovoltaic.push({
          egonIndirizzo: '',
          indirizzo: '',
          possessoImpianto: '',
          interesse: '',
          consulenza: '',
          saved: false,
          completed: false,
          edited: false,
          capResidenza: '',
          comuneResidenza: '',
          provinciaResidenza: ''
        })
        addressIndex = state.photovoltaic.length - 1
      }
      Object.keys(value).forEach((key) => {
        state.photovoltaic[addressIndex] = {
          ...state.photovoltaic[addressIndex],
          [key]: value[key]
        }
      })
    },
    setTypOnboardingHouse(state, value) {
      state.typOnboardingHouse = value
    },
    setTypOnboardingPhotovoltaic(state, value) {
      state.typOnboardingPhotovoltaic = value
    },
    setTypOnboardingMobility(state, value) {
      state.typOnboardingMobility = value
    },
    setTempAddress(state, value) {
      state.tempAddress = value
    }
  },
  actions: {
    getRetrieveHomeSurvey({ commit, rootGetters }, codiceCliente) {
      return axiosACN
        .post(
          '/sorgeniadataclient/retrieveHomeSurvey',
          {
            codiceCliente: codiceCliente,
            codiceProspect: ''
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new NewError(res)
          } else if (res.data.info.length > 0) {
            res.data.info = res.data.info.map((info) => ({
              ...info,
              saved: !!info.tipoAbitazione,
              completed: !!info.tipoTitolarita,
              edited: false
            }))
          }
          commit('setHouse', res.data.info)
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    getRetrieveMobilitySurvey({ state, commit, rootGetters }, codiceCliente) {
      return axiosACN
        .post(
          '/sorgeniadataclient/retrieveMobilitySurvey',
          { codiceCliente: codiceCliente, codiceProspect: '' },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new NewError(res)
          } else {
            let mobility = {
              tipoAuto: res.data.tipoAuto || '',
              capienzaBatteria: res.data.capienzaBatteria || '',
              wallbox: res.data.wallbox || '',
              interesse: res.data.interesse || '',
              saved: !!res.data.tipoAuto,
              completed: !!res.data.interesse || !!res.data.wallbox,
              edited: false
            }
            commit('setMobility', mobility)
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    getRetrievePhotovoltaicSurvey({ commit, rootGetters }, codiceCliente) {
      return axiosACN
        .post(
          '/sorgeniadataclient/retrievePhotovoltaicSurvey',
          {
            codiceCliente: codiceCliente,
            codiceProspect: ''
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new NewError(res)
          } else if (res.data.info.length > 0) {
            res.data.info = res.data.info.map((info) => ({
              ...info,
              saved: !!info.possessoImpianto,
              completed: !!info.consulenza,
              edited: false
            }))
          }
          commit('setPhotovoltaic', res.data.info)
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    getRetrieveAddress({ commit, rootGetters }, codiceCliente) {
      return axiosACN
        .post(
          '/sorgeniadataclient/retrieveAddress',
          {
            codiceCliente: codiceCliente,
            codiceProspect: ''
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new NewError(res)
          } else {
            res.data.info.forEach((data) => {
              data.indirizzo = capitalize(data.indirizzo)
            })
            commit('setAddressSaved', res.data.info)
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    sorgeniaServices({ commit }, clientType = 'CUSTOMER') {
      return axiosCMS
        .get(`/getSorgeniaServices?client_type=${clientType}`)
        .then((res) => {
          commit(`setSorgeniaServices`, res.data)
          return res.data
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    getSorgeniaServiceUserInvitation(
      { rootGetters },
      clientCode = undefined,
      prospectCode = undefined
    ) {
      return axiosACN
        .post(
          '/sorgeniadataclient/V2/retrieveUserInvitation',
          {
            codiceCliente: clientCode,
            codiceProspect: prospectCode,
            username: rootGetters['session/username']
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'OK') {
            return res.data.rilanci
          } else {
            throw new NewError(res.data)
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    homeSaveSurvey({ state, dispatch, rootGetters }, body) {
      return axiosACN
        .post(
          '/sorgeniadataclient/homeSaveSurvey',
          {
            ...state.house.filter(
              (house) =>
                house.egonIndirizzo === state.addressSelected.egonIndirizzo
            )[0],
            codiceCliente: state.userProfile.clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new NewError(res)
          } else {
            return res.data.status === 'OK'
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    mobilitySaveSurvey({ state, dispatch, rootGetters }, body) {
      return axiosACN
        .post(
          '/sorgeniadataclient/mobilitySaveSurvey',
          {
            ...state.mobility,
            codiceCliente: state.userProfile.clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new NewError(res)
          } else {
            return res.data.status === 'OK'
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    photovoltaicSaveSurvey({ state, dispatch, rootGetters }, body) {
      return axiosACN
        .post(
          '/sorgeniadataclient/photovoltaicSaveSurvey',
          {
            ...state.photovoltaic.filter(
              (photovoltaic) =>
                photovoltaic.egonIndirizzo ===
                state.addressSelected.egonIndirizzo
            )[0],
            codiceCliente: state.userProfile.clientOwnerCode
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new NewError(res)
          } else {
            return res.data.status === 'OK'
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    },
    saveAddress({ commit, state, dispatch, rootGetters }, body) {
      return axiosACN
        .post(
          '/sorgeniadataclient/saveAddress',
          {
            codiceCliente: state.userProfile.clientOwnerCode,
            egonIndirizzo: body.egonIndirizzo,
            indirizzo: body.indirizzo,
            cap: body.cap,
            comune: body.comune,
            provincia: body.provincia
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          if (res.data.status === 'KO') {
            throw new NewError(res)
          } else {
            state.addressSaved.push({
              egonIndirizzo: body.egonIndirizzo,
              indirizzo: body.indirizzo
            })
            commit('setAddressSaved', state.addressSaved)
            return res.data.status === 'OK'
          }
        })
        .catch((err) => {
          throw new NewError(err)
        })
    }
  }
}
