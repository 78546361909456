<template>
  <Layout header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <button
          type="button"
          class="loyalty__header-back mb-2"
          @click="$router.push({ name: 'loyaltyPage' })"
        >
          <v-icon color="#12256A" size="30">mdi-chevron-left</v-icon>
          <span>Torna al Diario</span>
        </button>
        <h1 v-text="mission.welcome_page_title" />
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <div class="loyalty-mission">
      <v-row>
        <v-col cols="12" class="loyalty-mission__title">
          <img :src="IconVideo" width="32" height="32" />
          <p class="">
            VIDEO - SCADE IL <span class="date" v-text="expirationDate" />
          </p>
        </v-col>
        <v-col v-if="mission.show_evaluation" cols="6">
          <h2 class="feedback__title mb-11">Ti è piaciuto il video?</h2>
          <v-row no-gutters>
            <v-col cols="3">
              <v-btn
                fab
                width="110"
                height="110"
                :color="isLiked !== '1' ? 'white' : '#97D707'"
                class="v-btn--shadowed"
                :class="{ active: isLiked !== '1' }"
                @click="handleChangeLike($event, '1')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 88 88"
                  stroke="currentColor"
                  class="iconFace iconFace--smile"
                  :class="{ active: isLiked === '1' }"
                >
                  <g fill="none">
                    <circle cx="44" cy="44" r="40.2" stroke-width="5.3" />
                    <path
                      stroke-linecap="round"
                      stroke-width="4"
                      d="M37.288 37.288a6.706 6.706 0 00-13.412 0m40.238 0a6.706 6.706 0 00-13.413 0M27.229 50.701c0 9.26 7.506 16.766 16.766 16.766 9.26 0 16.766-7.506 16.766-16.766"
                    />
                  </g>
                </svg>
              </v-btn>
            </v-col>
            <v-col cols="3" class="pl-4">
              <v-btn
                fab
                width="110"
                height="110"
                :color="isLiked !== '0' ? 'white' : '#C00'"
                class="v-btn--shadowed"
                :class="{ active: isLiked !== '0' }"
                @click="handleChangeLike($event, '0')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 88 88"
                  stroke="currentColor"
                  class="iconFace iconFace--sad"
                  :class="{ active: isLiked === '0' }"
                >
                  <g fill="none">
                    <circle cx="44" cy="44" r="40.2" stroke-width="5.3" />
                    <path
                      stroke-linecap="round"
                      stroke-width="4"
                      d="M37.22 37.22c0-3.87-2.91-7-6.5-7s-6.5 3.13-6.5 7m39 0c0-3.87-2.91-7-6.5-7s-6.5 3.13-6.5 7m-23 30a17 17 0 1134 0"
                    />
                  </g>
                </svg>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <div
            v-for="(question, index) in mission.questions"
            :key="question.id"
          >
            <h2 class="feedback__title mb-8" v-text="question.text" />
            <ExtensibleButtonGroup
              v-model="answers[index]"
              :per-row="2"
              :content="false"
            >
              <ExtensibleButton
                v-for="answer in question.answers"
                :key="answer.id"
                :label="answer.label"
                :name="answer"
              />
            </ExtensibleButtonGroup>
          </div>
        </v-col>
      </v-row>
      <div class="text-center mt-6">
        <v-btn
          color="brightGreen"
          x-large
          :loading="inProgress"
          :outlined="!isSubmitDisabled"
          :disabled="!isSubmitDisabled || inProgress"
          class="v-btn--shadowed confirm"
          @click="onSubmit"
        >
          Conferma
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="isConfirmDialogOpen" max-width="652">
      <v-card class="confirm-dialog">
        <h2 class="confirm-dialog__title">
          Sei sicuro di voler interrompere la Missione?
        </h2>
        <p class="confirm-dialog__text">
          Non perdere l'occasione di accumulare Green Coins e procedere verso un
          aumento di livello.
        </p>
        <div class="confirm-dialog__controls">
          <v-btn x-large class="v-btn--shadowed cancel" @click="handleLeaving">
            Interrompi
          </v-btn>
          <v-btn
            x-large
            color="brightGreen"
            class="v-btn--shadowed confirm"
            @click="handleStayOnPage"
          >
            Completa Missione
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </Layout>
</template>

<script>
import IconVideo from '@/assets/greeners/svg/mission-video.svg'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'feedbackComponent',
  components: {
    Layout: () =>
      import(
        /* webpackChunkName: "components/loyalty/layout" */ '@/views/loyalty/Layout'
      )
  },
  data: () => ({
    IconVideo,
    isLiked: null,
    answers: [],
    isConfirmDialogOpen: false,
    inProgress: false,
    leavingPageName: null,
    mission: {},
    coins: 0
  }),
  computed: {
    ...mapGetters('loyalty', ['videoData', 'clientLevel']),
    isSubmitDisabled() {
      return (
        (this.isLiked !== null || !this.mission.show_evaluation) &&
        this.answers.length
      )
    },
    expirationDate() {
      if (this.mission.end_date) {
        return new Date(this.mission.end_date).toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      }
      return '---'
    }
  },
  methods: {
    ...mapActions({
      getClientLevel: 'loyalty/getClientLevel',
      newTransaction: 'loyalty/newTransaction'
    }),
    handleChangeLike(e, value) {
      e.currentTarget.blur()
      this.isLiked = value
    },
    onSubmit() {
      this.inProgress = true
      const form = {
        event: this.mission.wekit_event_id,
        action: this.mission.wekit_action_id,
        detail1: this.mission.id,
        detail2: this.clientLevel.level_name,
        detail3: this.mission.title,
        extra: {
          mission_id: this.mission.id,
          mission_name: this.mission.title,
          evaluation: this.isLiked,
          questions: this.mission.questions.map((el, index) => {
            return {
              question_id: el.id,
              question_type: el.type,
              question_value: el.text,
              answers: this.mappingFields(el.answers),
              user_answers: this.mappingFields(
                this.answers.filter((el, i) => i === index)
              )
            }
          })
        }
      }
      this.newTransaction(form)
        .then(() => {
          this.$router.push({
            name: 'videoRewardPage',
            params: { mission: this.mission, coins: this.coins }
          })
        })
        .finally(() => {
          this.inProgress = false
        })
    },
    mappingFields: (arr) =>
      arr.map(({ id, value, label }) => ({
        answer_id: id,
        answer_label: label,
        answer_value: value
      })),
    handleLeaving() {
      this.isConfirmDialogOpen = false
      this.$router.push({ name: this.leavingPageName })
    },
    handleStayOnPage() {
      this.isConfirmDialogOpen = false
      this.leavingPageName = null
    }
  },
  created() {
    if (!this.$route.params.mission) {
      this.leavingPageName = 'loyaltyPage'
      this.$router.push({ name: 'loyaltyPage' })
    }
  },
  beforeMount() {
    this.mission = this.$route.params.mission || {}
    this.coins = Number(this.$route.params.coins) || 0
  },
  mounted() {
    this.getClientLevel()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'videoRewardPage' || this.leavingPageName) {
      next()
    } else {
      this.isConfirmDialogOpen = true
      this.leavingPageName = to.name
    }
  }
}
</script>

<style src="./_feedback.scss" lang="scss" scoped />
<style
  src="@/views/loyalty/components/mission/_confirm-dialog.scss"
  lang="scss"
/>

<style lang="scss">
.iconFace {
  width: 74px;
  height: auto;
  transition: width 0.3s;

  &--smile {
    color: #97d707;
  }

  &--sad {
    color: #c00;
  }

  &.active {
    color: white;
    width: 68px;
  }
}
</style>
