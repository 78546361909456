<template>
  <div class="landing">
    <div class="d-flex justify-space-between align-center landing--header">
      <div class="bkg"></div>
      <div class="logo">
        <img width="290" src="@/assets/greeners/svg/logoGreeners_new.svg" />
        <h2 class="logo--title">La Community sostenibile di Sorgenia</h2>
      </div>
      <img class="img" src="@/assets/greeners/landing-page-header.svg" />
    </div>
    <div class="landing--intro">
      <div class="d-flex justify-space-between align-center content">
        <div
          :class="`landing--intro__text ${
            memberStatus.played_welcome_iw ? 'full mb-7' : ''
          }`"
        >
          <h4 class="mb-4">
            Greeners è la <strong>community di Sorgenia</strong> fatta di
            persone come te, che <strong>pensano all’ambiente</strong> nelle
            loro scelte quotidiane e che
            <strong>uniscono il loro impegno</strong> per aiutare insieme il
            pianeta.
          </h4>
          <h4 class="mb-9">
            <strong>Le tue azioni possono fare la differenza</strong>: per
            questo puoi essere un Greener anche se non sei un cliente Sorgenia!
          </h4>
        </div>
      </div>
      <div class="bkg-sky mt-12">
        <div class="content">
          <div class="landing--intro__text">
            <h1 class="BodyXL mb-12">Come funziona?</h1>
          </div>
          <div class="d-flex justify-space-between">
            <div class="landing--intro__step">
              <div class="d-flex align-start">
                <div class="circle mr-8">1</div>
                <div class="desc">
                  <h2>Accumula Green Coins</h2>
                  <h5 class="BodyXL">
                    Più sono le <strong>azioni green</strong> che porterai a
                    termine e<br />
                    più saranno i Green Coins accumulati.
                  </h5>
                </div>
              </div>
            </div>
            <div class="landing--intro__cards">
              <card
                class="card0"
                :imgSrc="require('../../../assets/greeners/card-fidelity.png')"
                title="SCEGLI SORGENIA PER LE TUE UTENZE"
                label="Ogni mese ricevi Green Coins per ogni tua utenza Sorgenia attiva"
              />
              <card
                class="card1"
                :imgSrc="require('../../../assets/greeners/card-missions.png')"
                title="PARTECIPA ALLE MISSIONI"
                label="Mettiti in gioco: per ogni missione superata ricevi Green Coins"
              />
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="landing--intro__step">
              <div class="d-flex align-start">
                <div class="circle mr-8">2</div>
                <div class="desc">
                  <h2>Sali di livello</h2>
                  <h5 class="BodyXL">
                    Ogni <strong>8 Missioni</strong> completate, sali di livello
                    e ricevi un premio in Green Coins di valore crescente per un
                    totale di 2000 Green Coins extra ricevuti!
                  </h5>
                </div>
              </div>
            </div>
            <div class="pos-relative">
              <img
                class="landing--intro__img-levels"
                src="../../../assets/greeners/landing-page-levels.svg"
              />
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="landing--intro__step">
              <div class="d-flex align-start">
                <div class="circle mr-8">3</div>
                <div class="desc">
                  <h2>Premi per te e per tutti</h2>
                  <h5 class="BodyXL">
                    Con i tuoi Green Coins puoi scegliere premi a
                    <strong>catalogo</strong> che fanno bene a te, all’ambiente
                    e alla comunità.
                  </h5>
                </div>
              </div>
            </div>
            <div class="landing--intro__cards" v-if="catalog.length > 0">
              <card
                v-for="(item, i) in catalog"
                :key="i"
                :class="'card' + i"
                :imgSrc="item.attributes.images.data[0].image_small"
                :title="item.attributes.title"
                :coins="item.attributes.price"
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            class="v-btn--shadowed mb-4 ButtonL"
            color="primary"
            height="54"
            width="257"
            @click="
              $router.push({ name: 'loyaltyPage' }),
                trackTapEvent({
                  name: 'greeners_diaryHowWorksBack_tap',
                  params: {
                    client_code: mainClientLoyaltySelected.clientOwnerCode,
                    client_type: mainClientLoyaltySelected.clientOwnerType
                  }
                })
            "
          >
            Torna Indietro
          </v-btn>
        </div>
        <div class="spacer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleCard from './SingleCard'
import { mapActions, mapGetters } from 'vuex'
import GenErr from '@/js/genericErrors'
import { axiosAdvice } from '@/js/axiosInstances.js'
import { ENV_CONFIGS } from '@/js/configs.js'
import Constants from '@/js/constants'

export default {
  name: 'IntroPage',
  components: {
    Card: SingleCard
  },
  data() {
    return {
      catalog: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next((self) => {
      axiosAdvice.defaults.baseURL =
        self.mainClientLoyaltySelected.clientOwnerType === 'BUS'
          ? ENV_CONFIGS.WEKIT_BASE.replace('residential', 'business')
          : ENV_CONFIGS.WEKIT_BASE.replace('business', 'residential')
      self
        .getMemberStatus({
          ext_uuid: self.mainClientLoyaltySelected.clientOwnerCode
        })
        .then((resp) => {
          if (!resp.user_registered) {
            self
              .getDetailForClient({
                clientOwnerCode: self.mainClientLoyaltySelected.clientOwnerCode
              })
              .then(async (res) => {
                await self.join()
              })
          } else if (
            resp.status_category !== 'AWARE' &&
            resp.status_category !== 'FREEWILL'
          ) {
            self.setLoyaltyStatus({
              codiceCliente: self.mainClientLoyaltySelected.clientOwnerCode,
              status_category: 'AWARE'
            })
          }
        })
    })
  },
  beforeMount() {
    if (!this.showLoyalty()) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.getMainClient().then(async (res) => {
      if (this.mainClientLoyaltySelected) {
        res = this.mainClientLoyaltySelected
      }

      axiosAdvice.defaults.baseURL =
        res.clientOwnerType === 'BUS'
          ? ENV_CONFIGS.WEKIT_BASE.replace('residential', 'business')
          : ENV_CONFIGS.WEKIT_BASE.replace('business', 'residential')

      this.getCatalog({
        codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode,
        catalog_name: 'IW-WL'
      })

      await this.getDetailForClient({
        clientOwnerCode: res.clientOwnerCode
      }).then((res) => {
        let catalogPromise = this.getCatalog({
          codiceCliente: res.clientCode,
          catalog_name:
            res.clientType === 'RES' ? 'COLLECTION' : 'COLLECTION-BS'
        })
        let prizesOrderPromise = this.getLoyaltyLandingPrizes()

        Promise.all([catalogPromise, prizesOrderPromise]).then((result) => {
          let prizesToShow = result[1]
            .sort((a, b) => parseInt(b.weight) - parseInt(a.weight))
            .slice(0, 2)
            .map((item) => item.wekit_award_id)
          this.catalog = result[0].filter((item) =>
            prizesToShow.includes(item.attributes.prizeoption_id)
          )
        })
      })
    })
  },
  computed: {
    ...mapGetters('loyalty', [
      'catalogList',
      'catalog_iwwl',
      'loyaltyLandingPrizes',
      'memberStatus',
      'NEW_MEMBER_STATUS_ERROR'
    ]),
    ...mapGetters('account', [
      'clientDetails',
      'mainClientLoyaltySelected',
      'listMainClientLoyalty'
    ]),
    ...mapGetters('supply', ['supplies', 'isNotAllPlacet']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Greeners' }
      ]

      return res
    },
    wonGift() {
      return this.catalog_iwwl[0]
        ? this.catalog_iwwl[0].attributes.images.data[0].image_small
        : ''
    },
    wonText() {
      return this.catalog_iwwl[0] ? this.catalog_iwwl[0].attributes.title : ''
    }
  },
  methods: {
    ...mapActions('loyalty', [
      'getCatalog',
      'getLoyaltyLandingPrizes',
      'getMemberStatus',
      'setLoyaltyStatus',
      'joinTeam'
    ]),
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('cfp', ['getLastCfpValue']),
    showLoyalty() {
      let listMain = this.listMainClientLoyalty.map((m) => m.clientOwnerCode)
      return (
        this.isNotAllPlacet &&
        this.supplies.filter(
          (sup) =>
            (sup.supplyState === Constants.SUPPLY_STATUS.ACTIVE ||
              sup.supplyState === Constants.SUPPLY_STATUS.ACTIVATING) &&
            listMain.includes(sup.clientOwnerCode)
        ).length > 0
      )
    },
    join() {
      const {
        clientCode,
        name,
        surname,
        contactMail,
        phone,
        mobilePhone,
        birthDate,
        residentialProvince,
        fiscalCod
      } = this.clientDetails

      this.trackTapEvent({
        name: 'greeners_enrollmentButton_tap',
        params: {
          clientCode: this.mainClientLoyaltySelected.clientOwnerCode,
          clientType: this.mainClientLoyaltySelected.clientOwnerType
        }
      })

      let date
      if (!birthDate) {
        date = ''
      } else if (!birthDate.match(/\d{4}-\d{2}-\d{2}/g)) {
        let re = /(\d{2})\/(\d{2})\/(\d{4})/
        date = birthDate.replace(re, '$3-$2-$1')
      } else date = birthDate
      let body = {
        codiceCliente: clientCode,
        first_name: name,
        last_name: surname,
        email: contactMail,
        phone: phone || mobilePhone,
        fiscal_code: fiscalCod,
        birthday: date,
        province: residentialProvince
      }

      this.getLastCfpValue({ clientOwnerCode: clientCode })
        .then((cfpValue) => {
          if (cfpValue) {
            body.cfp = cfpValue
          }
          this.joinTeam(body).then((resp) => {
            if (this.NEW_MEMBER_STATUS_ERROR) {
              this.$router.push({
                name: 'home',
                params: { typeError: 'IDENTITY_STATUS_ERROR' }
              })
            } else {
              this.getMemberStatus({
                ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
              }).then((resp) => {
                if (
                  resp.status_category !== 'AWARE' &&
                  resp.status_category !== 'FREEWILL'
                ) {
                  this.setLoyaltyStatus({
                    codiceCliente:
                      this.mainClientLoyaltySelected.clientOwnerCode,
                    status_category: 'AWARE'
                  })
                }
              })
            }
          })
        })
        .catch(() => {
          GenErr.showErrorMessage()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.spacer {
  height: 70px;
}
.landing {
  &--header {
    width: 100%;
    height: 449px;
    position: relative;
    .bkg {
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(
        90deg,
        #97d700 44.58%,
        rgba(196, 196, 196, 0) 100%
      );
    }
    .logo {
      z-index: 1;
      padding-left: calc((100% - 1140px) / 2);
      &--title {
        color: $srg-blue;
        font-weight: 500;
        width: 392px;
        letter-spacing: 0.02em;
        line-height: 120%;
        margin-top: 32px;
      }
    }
    .img {
      -webkit-mask-image: linear-gradient(
        to left,
        rgb(0 0 0),
        rgba(0, 0, 0, 0)
      );
    }
  }
  &--intro {
    width: 100%;
    background: white;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    margin-top: -40px;
    position: relative;
    .content {
      padding: 40px calc((100% - 1140px) / 2) 0px;
    }
    .bkg-sky {
      background: #f7faff;
    }
    .pos-relative {
      position: relative;
      width: 100%;
      height: 230px;
      margin-bottom: 152px;
    }
    &__text {
      &.full {
        width: 100%;
      }
    }
    &__bike {
      width: 460px;
      height: fit-content;
      box-shadow: 0px 12px 24px rgba(44, 53, 66, 0.06),
        0px 4px 8px rgba(44, 53, 66, 0.08), 0px 2px 4px rgba(44, 53, 66, 0.08);
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0 16px;
      cursor: pointer;
      h4 {
        font-weight: 500;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: 1px;
        color: $srg-blue !important;
        margin-bottom: 12px;
      }
      .bicycle {
        height: auto;
        margin-bottom: 12px;
      }
      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: black;
        letter-spacing: 0.5px;
        text-align: center;
        margin-bottom: 16px;
      }
      .divisor {
        width: 100%;
        height: 1px;
        background: #dfe4ef;
      }
      .btn {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        color: $srg-blue;
        text-align: center;
        letter-spacing: 0.8px;
        margin-top: 16px;
      }
    }
    &__step {
      .circle {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $srg-blue;
        color: white;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.8;
        border-radius: 20px;
      }
      .desc {
        width: 486px;
        h2 {
          font-weight: bold;
          color: $srg-blue;
          letter-spacing: 1.2px;
          margin-bottom: 16px;
        }
        h5 {
          color: black;
          font-size: 20px;
          line-height: 140%;
          letter-spacing: 0.5px;
        }
      }
    }
    &__img-levels {
      position: absolute;
      width: 753px;
      right: 0px;
      top: 50px;
    }
    &__cards {
      position: relative;
      margin-bottom: 112px;
      .card0 {
        z-index: 11;
      }
      .card1 {
        z-index: 10;
        position: absolute;
        right: 180px;
        top: 40px;
      }
      .theme--light.v-card.v-card--outlined {
        border: none;
      }
    }
    h1 {
      font-size: 40px;
      line-height: 1.3;
      letter-spacing: 0.02em;
      font-weight: 500;
      color: $srg-blue;
    }
    h2 {
      font-size: 32px;
    }
    h4 {
      font-size: 20px;
      letter-spacing: 1px;
      color: black;
      line-height: 140%;
      letter-spacing: 0.5px;
    }
    span {
      color: $srg-green;
      font-weight: bold;
    }
  }
}
.v-dialog__content {
  .dialogClient {
    color: black;
    background: white;
    padding: 55px 45px 45px 55px;
    border-radius: 40px;
    position: relative;
    .small {
      padding: 55px 110px 55px 55px;
    }
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    .box {
      width: 407px;
      padding: 21px 24px 21px 24px;
      border-radius: 20px;
      border: solid 1px #ccd0e1;
      cursor: pointer;
      &:hover {
        border-color: #12256a;
      }
      .client {
        h5 {
          width: 370px;
        }
        display: flex;
        align-items: center;
        &.RES::before {
          width: 20px;
          content: url('../../../assets/ic-residenziale_black.svg');
          margin-right: 16px;
        }
        &.BUS::before {
          width: 20px;
          content: url('../../../assets/ic-business_black.svg');
          margin-right: 16px;
        }
        &::after {
          content: url('../../../assets/icons/chevron_rx.svg');
        }
      }
    }
    .titleAlert {
      font-weight: 500;
      margin-bottom: 8px;
    }
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      &.preview {
        position: initial;
      }
    }
  }
}
.missing-data-dialog {
  background-color: white;
  padding: 30px;
  img {
    cursor: pointer;
  }
}
</style>
