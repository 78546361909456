<template>
  <div
    :class="`level step-${clientLevel.level_step}`"
    :style="`height: ${Width}px; width: ${Width}px;`"
  >
    <img
      :src="iconStatusLevel"
      class=""
      :width="withProgressionBar ? Width * 0.65 : Width"
      :height="withProgressionBar ? Width * 0.65 : Width"
      alt="statusIcon"
    />
  </div>
</template>

<script>
import LEVEL0 from '@/assets/greeners/svg/beginner.svg'
import LEVEL1 from '@/assets/greeners/svg/follower.svg'
import LEVEL2 from '@/assets/greeners/svg/friend.svg'
import LEVEL3 from '@/assets/greeners/svg/influencer.svg'
import LEVEL4 from '@/assets/greeners/svg/ambassador.svg'
import PLACEHOLDER from '@/assets/greeners/svg/placeholder.svg'

export default {
  name: 'statusIcon',
  props: {
    withProgressionBar: { type: Boolean, default: false },
    width: { type: String || Number, default: null },
    size: { type: String, default: 'md' },
    clientLevel: {
      type: Object || String,
      default: () => ({
        level_name: 'LEVEL0',
        level_step: 0
      })
    }
  },
  data: () => ({ LEVEL0, LEVEL1, LEVEL2, LEVEL3, LEVEL4, PLACEHOLDER }),
  computed: {
    Width() {
      if (this.width) return this.width
      switch (this.size) {
        case 'sm':
          return 100
        case 'md':
          return 121
        case 'lg':
          return 140
        default:
          return 100
      }
    },
    iconStatusLevel() {
      switch (this.clientLevel.level_name) {
        case 'LEVEL0':
          return LEVEL0
        case 'LEVEL1':
          return LEVEL1
        case 'LEVEL2':
          return LEVEL2
        case 'LEVEL3':
          return LEVEL3
        case 'LEVEL4':
          return LEVEL4
        default:
          return PLACEHOLDER
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.level {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: contain;
  background-position: center;
  &.step-0 {
    background-image: url('../../../assets/greeners/svg/step-1.svg');
  }
  &.step-1 {
    background-image: url('../../../assets/greeners/svg/step-2.svg');
  }
  &.step-2 {
    background-image: url('../../../assets/greeners/svg/step-3.svg');
  }
  &.step-3 {
    background-image: url('../../../assets/greeners/svg/step-4.svg');
  }
  &.step-4 {
    background-image: url('../../../assets/greeners/svg/step-5.svg');
  }
  &.step-5 {
    background-image: url('../../../assets/greeners/svg/step-6.svg');
  }
  &.step-6 {
    background-image: url('../../../assets/greeners/svg/step-7.svg');
  }
  &.step-7 {
    background-image: url('../../../assets/greeners/svg/step-8.svg');
  }

  &__outer {
    height: auto;

    circle {
      fill: transparent;
      stroke-width: 5px;
      stroke-linecap: round;
      stroke-dasharray: 60px, 230px;
      stroke: currentColor;
    }

    &.LEVEL0 {
      color: #0096dc;
    }

    &.LEVEL1 {
      color: #bb0178;
    }

    &.LEVEL2 {
      color: #2f2c78;
    }

    &.LEVEL3 {
      color: #f87603;
    }

    &.LEVEL4 {
      color: #c8961a;
    }

    circle:not(.active) {
      stroke: #e4e4e4;
    }
  }

  &__icon {
    position: absolute;
    margin: 1px 0 0 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
