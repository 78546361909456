<template>
  <div class="feedback">
    <v-row v-if="!isMobile">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbsItems"
          divider=">"
          class="pb-0 itemsWhite"
        >
          <template v-slot:divider>
            <img src="@/assets/icons/chevron-dx-white.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row class="feedback__header" v-if="loaded">
      <v-col md="6" cols="12" sm="6" class="align-self-center">
        <h1
          class="wkfeedback__title"
          v-if="!showNoSurvey"
          v-html="survey.inner_title"
        ></h1>
        <h1 class="wkfeedback__title" v-if="showNoSurvey">
          Feedback<br /><span>MySorgenia</span>
        </h1>
        <h2
          class="feedback__subtitle"
          v-if="!showNoSurvey"
          v-html="survey.inner_text"
        ></h2>
        <h2 class="feedback__subtitle" v-if="showNoSurvey">
          Questi sondaggi ci permettono di migliorare i nostri Servizi e creare
          nuovi strumenti utili al tuo business.<br />Grazie del tuo tempo!
        </h2>
      </v-col>
      <v-col
        cols="12"
        v-if="isMobile"
        class="d-flex justify-center align-center"
      >
        <img :src="require('@assets/welcome/icChevronDownWhiteBig.png')" />
      </v-col>
      <v-col md="6" cols="12" sm="6" class="feedback__img" v-if="survey">
        <img
          v-if="!showNoSurvey"
          :src="
            isSmallDevice
              ? cdn + survey.inner_image_mobile || survey.inner_image
              : cdn + survey.inner_image
          "
        />
        <img
          v-if="showNoSurvey"
          :src="require('@assets/feedback/ph_round_feedback.png')"
        />
      </v-col>
    </v-row>
    <v-row class="feedback__carousel">
      <div v-if="showNoSurvey">
        <div
          class="v-card--empty"
          :class="[isMobile ? 'v-card--empty__mobile' : 'v-card--empty__web']"
        >
          <h3 class="mx-auto text-center" style="color: white">
            <strong>Nessun sondaggio<br />attivo.</strong>
          </h3>
          <h6 class="mx-auto mt-6 text-center" style="color: white">
            <strong>Torna presto e lasciaci<br />il tuo feedback!</strong>
          </h6>
        </div>
      </div>

      <WelcomeCarousel
        name="feedback"
        :route-name="isMobile ? 'publicFeedback-survey' : 'feedback-survey'"
        :slides="slides"
        :is-mobile="isMobile"
      />
    </v-row>
  </div>
</template>
<script>
import { checkMobile } from '@/js/utils.js'
import WelcomeCarousel from '../../components/welcomeTutorial/WelcomeCarousel'
import { mapActions, mapGetters } from 'vuex'
import { ENV_CONFIGS } from '@/js/configs'

export default {
  name: 'feedbackComponent',
  components: {
    WelcomeCarousel
  },
  data() {
    return {
      cdn: ENV_CONFIGS.CMS_CDN_BASE_URL,
      isMobile: checkMobile(),
      isTablet: window.innerWidth > 480 && window.innerWidth <= 1024,
      isSmallDevice: window.innerWidth <= 480,
      questions: [],
      slides: [],
      showNoSurvey: false,
      loaded: false
    }
  },
  methods: {
    ...mapActions('feedback', ['loadSurvey', 'loadUndoneSurvey']),
    initSlides() {
      if (this.questions.length === 0) {
        this.showNoSurvey = true
      } else {
        if (this.isMobile) {
          this.slides = this.questions.map((v) => {
            return {
              slide: [
                {
                  id: v.id,
                  carousel_title: v.title,
                  carousel_text: v.abstract,
                  carousel_image: v.image
                }
              ]
            }
          })
        } else {
          let slideCount = this.questions.length / 2
          const res = []
          for (let s = 0; s < slideCount; s++) {
            const slide = { slide: [] }
            this.questions.slice(s * 2, s * 2 + 2).forEach((v, i) => {
              slide.slide.push({
                id: v.id,
                carousel_title: v.title,
                carousel_text_new_massmark: v.abstract,
                carousel_text: v.abstract,
                carousel_image: v.image
              })
            })
            res.push(slide)
          }
          this.slides = res
        }
      }
    }
  },
  computed: {
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['survey']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'SupportPage' }, text: 'Assistenza', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: 'Feedback' }
      ]

      return res
    }
  },
  created() {
    this.loadSurvey().then((cmsSurveys) => {
      this.loadUndoneSurvey().then((undoneSurveys) => {
        this.questions = undoneSurveys
        this.initSlides()
        this.loaded = true
      })
    })
  }
}
</script>
<style lang="scss" scoped>
.feedback {
  color: white;
  padding: 28px calc((100% - 1140px) / 2) 0;
  position: relative;
  z-index: 100;
  background-color: $srg-blue;
  height: 100%;
  &__header {
    line-height: 1.2;
    position: relative;
    margin-bottom: 71px;
    h1 {
      font-size: 60px;
      font-weight: bold;
      line-height: 70px;
      margin-bottom: 48px;
      p {
        color: white;
      }
    }
    h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 48px;
    }
    h3 {
      font-size: 20px;
    }
    .close {
      position: absolute;
      top: 40px;
      right: 20px;
      cursor: pointer;
    }
  }
  &__img {
    img {
      width: 490px;
    }
  }
  &__carousel {
    h3,
    h6 {
      color: black;
    }
    h3 {
      line-height: 26px;
    }
    h6 {
      font-size: 14px;
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .feedback {
    overflow: hidden;
    position: relative;
    .col-sm-12 {
      padding-left: 32px;
      padding-right: 32px;
    }
    &__header {
      h1 {
        width: 250px;
        font-size: 39px;
        line-height: 45px;
        margin-bottom: 19px;
        margin-top: 90vw;
        span {
          color: black;
        }
      }
      h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      h3 {
        font-size: 16px;
        line-height: 22px;
      }
      .close {
        position: absolute;
        top: 50px;
        right: 18px;
        cursor: pointer;
      }
    }
    &__img {
      img {
        position: absolute;
        top: -40px;
        right: -5px;
        width: 90vw;
        max-width: 555px;
      }
    }
    .v-card {
      height: 386px;
    }
  }
}
@media (min-width: 481px) and (max-width: 1024px) {
  .feedback {
    &__header {
      h1 {
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 24px;
        margin-bottom: 30px;
      }
      h3 {
        font-size: 18px;
      }
    }
    overflow: hidden;
    position: relative;
    padding: 28px 40px;
    &__img {
      img {
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.wkfeedback__title {
  color: white;
  p {
    color: white;
  }
}
.feedback {
  .v-window__next,
  .v-window__prev {
    top: 35%;
  }
  .v-btn--icon.v-size--default {
    height: 62px !important;
    width: 62px !important;
    background: rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  html {
    min-width: initial !important;
    max-width: 100vw !important;
  }
  .v-carousel__controls {
    background: initial;
    .v-btn--icon.v-size--small {
      height: 16px;
      width: 16px;
      margin: 0 3px;
      opacity: 0.5;
      &.v-item--active {
        opacity: 1;
      }
    }
    .v-btn:before {
      background-color: initial;
    }
    .mdi-minus::before {
      font-size: 25px;
      font-weight: bold;
    }
  }
}
@media (min-width: 481px) and (max-width: 1024px) {
  html {
    min-width: initial !important;
    max-width: 100vw !important;
  }
}

.v-card--empty {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__web {
    height: 474px;
    width: 555px;
  }

  &__mobile {
    height: 378px;
    width: 400px;
  }
}
</style>
