<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="6" align-self="center">
        <button
          type="button"
          class="loyalty__header-back"
          @click="
            trackTapEvent({
              name: 'greeners_mission_close',
              params: {
                client_code: mainClientLoyaltySelected.clientOwnerCode,
                client_type: mainClientLoyaltySelected.clientOwnerType
              }
            }),
              $router.push({ name: 'loyaltyPage' })
          "
        >
          <v-icon color="#12256A" size="20">mdi-chevron-left</v-icon>
          <span>Torna al Diario</span>
        </button>
        <!-- <v-row>
          <v-col cols="9" class="pl-0 pr-0">
            <h4 v-if="mission" class="font-weight-bold white--text mb-3">QUESTIONARIO - SCADE IL {{expirationDate}} </h4>
          </v-col>
          <v-col class="pt-2 pl-0" cols="3">
            <v-img class="header__icon" width="32" :src="require('@/assets/ic_survey_white_small.png')"/>
          </v-col>
        </v-row> -->
        <h1>{{ mission.welcome_page_title }}</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5" align-self="center">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <common-content
      v-if="content === 'survey' && mission"
      :btnLabel="mission.welcome_page_button_label"
      :coins="missionValue(mission.wekit_action_id, mission.wekit_event_id)"
      :imageSrc="CmsUrl + mission.welcome_page_image"
      picSize="large"
      :isButtonSeparated="false"
      @buttonClick="proceed"
    >
      <template v-slot:text>
        <v-row class="mb-6" no-gutters="">
          <v-col cols="1">
            <v-img
              class="header__icon"
              width="35"
              :src="require('@/assets/greeners/svg/mission-survey.svg')"
            />
          </v-col>
          <v-col v-if="mission" class="pl-0 pr-0 align-self-center"
            ><h5>
              <strong>SURVEY - SCADE IL {{ expirationDate }}</strong>
            </h5></v-col
          >
        </v-row>
        <h4 class="mb-6" v-html="mission.welcome_page_text" />
      </template>
    </common-content>

    <div
      v-if="content === 'questionnaire' && mission"
      class="question__content"
    >
      <div v-for="(question, i) in mission.questions" :key="question.id">
        <div>
          <v-row v-if="question.type === 'MULTIPLE_CHOICE'" class="mb-6">
            <v-col>
              <h2 class="mb-8" v-html="question.text" />
              <ExtensibleButtonGroup v-model="surveyAnswers[i]" :name="i">
                <ExtensibleButton
                  v-for="(button, i) in question.answers"
                  :key="button.id + i"
                  :label="button.label || button.value"
                  :name="button"
                />
              </ExtensibleButtonGroup>
            </v-col>
          </v-row>
          <v-row v-else-if="question.type === 'TEXT'" class="mb-6">
            <v-col>
              <h2 v-html="question.text" />
              <h5 class="mb-2">Messaggio</h5>
              <v-textarea
                outlined
                placeholder="Max 500 caratteri"
                v-model="surveyAnswers[i]"
                persistent-placeholder
              />
            </v-col>
          </v-row>
          <v-row v-else class="mb-8">
            <v-col>
              <h2 class="slider-desc" v-html="question.text" />
              <v-col cols="11" class="pl-0">
                <slider
                  :options="question.answers"
                  :sliderV.sync="surveyAnswers[i]"
                />
              </v-col>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row>
        <v-col class="ml-n11" align="center">
          <v-btn
            class="v-btn--shadowed"
            color="brightGreen"
            x-large
            width="265"
            @click="sendRequest"
            :disabled="isButtonDisabled"
          >
            Conferma
          </v-btn>
        </v-col>
      </v-row>
      <v-footer color="white" height="90" />
    </div>
  </layout>
</template>

<script>
import { ENV_CONFIGS } from '@/js/configs.js'
import { mapActions, mapGetters } from 'vuex'
import ThumbSlider from '../../components/thumbSlider/ThumbSlider'
import CommonContent from './CommonContent'
import Layout from './Layout'

export default {
  name: 'SurveyComponent',
  components: {
    Layout,
    CommonContent,
    slider: ThumbSlider
  },
  beforeMount() {
    this.getWekitMap()
    this.getSurveys().then((res) => {
      const condition = res.filter(
        (mission) => mission.id === this.$route.params.id
      )
      if (condition.length) {
        this.mission = condition[0]
      }
    })
    this.getMainClient().then((resp) => {
      if (this.mainClientLoyaltySelected) {
        resp = this.mainClientLoyaltySelected
      }
      this.getDetailForClient({ clientOwnerCode: resp.clientOwnerCode }).then(
        (res) => {
          this.getClientLevel({
            ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
          })
        }
      )
    })
  },
  data: () => ({
    CmsUrl: ENV_CONFIGS.CMS_CDN_BASE_URL,
    content: 'survey',
    surveyAnswers: [],
    mission: null
  }),
  computed: {
    isButtonDisabled() {
      return (
        this.surveyAnswers.filter((x) => x !== null).length <
        this.mission.questions.length
      )
    },
    pageState() {
      if (this.content === 'survey') {
        return 'survey'
      } else {
        return 'questionnaire'
      }
    },
    expirationDate() {
      if (this.mission && this.mission.end_date) {
        return new Date(this.mission.end_date)
          .toLocaleDateString('it-It', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
          .split('/')
          .join('.')
      }
      return '---'
    },
    userAnswers() {
      return this.surveyAnswers.map((answer) => {
        if (typeof answer === 'string') {
          return { answer_value: answer }
        } else {
          return {
            answer_id: answer.id,
            answer_label: answer.label,
            answer_value: answer.value
          }
        }
      })
    },
    ...mapGetters('loyalty', ['surveys', 'wekitMap', 'clientLevel']),
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ])
  },
  methods: {
    ...mapActions('loyalty', [
      'getSurveys',
      'getWekitMap',
      'newTransaction',
      'getClientLevel'
    ]),
    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    missionResearch() {
      return this.mission.questions.map((question, i) => {
        let answers = []
        if (question.answers.length) {
          answers = question.answers.map((answer) => {
            return {
              answer_id: answer.id,
              answer_label: answer.label,
              answer_value: answer.value
            }
          })
        }
        return {
          question_id: question.id,
          question_type: question.type,
          question_value: question.text.trim(),
          answers: answers,
          user_answers: [this.userAnswers[i]]
        }
      })
    },
    proceed() {
      this.trackTapEvent('greeners_mission_start_tap')
      this.content = 'questionnaire'
    },
    missionValue(actionId, eventId) {
      let targetMission = this.wekitMap.filter(
        (mission) =>
          mission.action_id === actionId && mission.event_id === eventId
      )
      if (targetMission.length) {
        return targetMission[0].points
      } else return 0
    },
    sendRequest() {
      let questions = this.missionResearch()
      const body = {
        event: this.mission.wekit_event_id,
        action: this.mission.wekit_action_id,
        detail1: this.mission.id,
        detail2: this.clientLevel.level_name,
        detail3: this.mission.title,
        extra: {
          mission_id: this.mission.id,
          mission_name: this.mission.title,
          questions: questions
        }
      }
      this.newTransaction(body).then((res) => {
        if (res)
          this.$router.push({
            name: 'surveyReward',
            params: { id: this.$route.params.id }
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  &-desc {
    margin-bottom: 120px;
  }
}
.question {
  &__content {
    padding-top: 70px;
    width: 100%;

    h4,
    h5 {
      font-weight: bold;
    }
  }
}
</style>
