<template>
  <page page-name="Dati catastali">
    <TcVars />
    <div class="cadastralSuccess">
      <img
        v-if="$route.params.sendRequest"
        src="../../../assets/icons/ok.svg"
        width="64"
        class="mb-12"
      />
      <img
        v-else
        src="../../../assets/icons/alreadySend.svg"
        width="64"
        class="mb-12"
      />
      <div class="cadastralSuccess__title mb-2">
        {{
          $route.params.sendRequest
            ? 'Dati ricevuti'
            : 'I dati catastali sono già stati inviati'
        }}
      </div>
      <div class="cadastralSuccess__subTitle">
        {{
          $route.params.sendRequest
            ? 'Grazie, la tua utenza ora è completa dei dati catastali.'
            : 'Abbiamo già ricevuto la comunicazione dei tuoi dati catastali per questa utenza. Se hai bisogno di maggiori informazioni contattaci.'
        }}
      </div>
    </div>
    <div class="mt-12 mb-10">
      <v-btn width="243" height="54" color="primary" @click="request"
        >Torna all’utenza</v-btn
      >
    </div>
  </page>
</template>
<script>
import { mapGetters } from 'vuex'
import Template from './Template'
import TcVars from '@/components/tcTracker/TcVars'
export default {
  name: 'cadastralSuccess',
  components: {
    page: Template,
    TcVars
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail']),
    alreadySend() {
      return (
        this.supplyDetail[this.$route.params.supplyCode]?.cadastral
          ?.ricezioneDatiCatastali || false
      )
    }
  },
  methods: {
    request() {
      this.$router.push({
        name: 'supplyDetail',
        params: {
          clientOwnerCode: this.$route.params.clientOwnerCode,
          supplyCode: this.$route.params.supplyCode
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cadastralSuccess {
  width: 640px;
  &__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 1px;
  }
  &__subTitle {
    font-size: 16px;
    color: black;
    line-height: 1.4;
    letter-spacing: 1px;
    &.already {
      border-bottom: 1px solid #eaeef5;
    }
  }
  &__question {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.2;
  }
  &__response {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.2;
    margin-bottom: 30px;
  }
}
</style>
