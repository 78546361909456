<template>
  <div class="redirect">
    <div class="redirect__dashboard">
      <div class="d-flex justify-center logo">
        <img src="../../assets/my-sorgenia-logo-white.png" width="231" />
      </div>
      <div class="d-flex justify-end">
        <img src="../../assets/redirect_home.png" width="254" />
      </div>
    </div>
    <div class="redirect__text">
      <div class="redirect__title">App Mysorgenia</div>
      <div class="redirect__desc">
        Per avere un'esperienza migliore nella tua area personale clicca qui
        sotto e vai all’App
      </div>
      <div class="redirect__link">
        <a
          v-if="platform === 'android'"
          href="https://play.google.com/store/apps/details?id=my.sorgenia"
        >
          <img src="@assets/button-android.svg" alt="play market" />
        </a>
        <a v-else href="https://apps.apple.com/it/app/mysorgenia/id593806212/">
          <img src="@assets/button-ios.svg" alt="app store" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
const html = document.querySelector('html')
export default {
  name: 'redirectComponent',
  data: () => ({}),
  props: {
    platform: { type: String, default: null }
  },
  beforeMount() {
    html.style.minWidth = '320px'
  },
  beforeUnmount() {
    html.style = ''
  }
}
</script>

<style lang="scss" scoped>
.redirect {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__dashboard {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: $srg-blue;
    .logo {
      flex-direction: column;
      height: 100%;
    }
  }
  &__text {
    width: 100%;
    height: 242px;
    padding: 24px 16px 47px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
  }
  &__title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 8px;
    line-height: 26px;
    letter-spacing: 1px;
  }
  &__desc {
    font-size: 16px;
    color: black;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 25px;
  }
  &__link {
    display: flex;
    justify-content: center;
  }
}
</style>
