import { axiosACN } from '@/js/axiosInstances.js'
import { capitalize, isHoliday } from '@/js/utils'
import { eventBus } from '@/main'
import Consts from '@/js/constants'
import { axiosCMS } from '../../js/axiosInstances'

let timerListener = null

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    flagRepeater: null,
    catalogId: null,
    client: null,
    timer: null,
    addressCovered: null,
    addressShipping: null,
    withGoogleWiFi: null,
    withModem: false,
    flagVoucherFibra: false,
    identityDocument: null,
    paymentMethod: null,
    invoiceMethod: null,
    speedUpProcess: null,
    infoConsents: false,
    migrationCode: null,
    connectionType: null, // FTTH FTTC
    provider: null, // OF, FW
    isFromTelecom: false,
    isFromFastweb: false,
    activeLine: false,
    mgmDetail: null,
    fiberCtx: {
      client_code: null,
      role: null,
      client_firstname: null,
      client_lastname: null,
      supply_address: null,
      upselling_session_id: null,
      upselling_step_name: null,
      is_migration: null,
      migration_code: null,
      has_selected_vas: null
    },
    repeaterDescription: {},
    activationDataFiber: {}
  },
  getters: {
    flagRepeater: (state) => state.flagRepeater,
    repeaterDescription: (state) => state.repeaterDescription,
    mgmDetail: (state) => state.mgmDetail,
    fiberCtx: (state) => state.fiberCtx,
    connectionType: (state) => state.connectionType,
    provider: (state) => state.provider,
    isFromTelecom: (state) => state.isFromTelecom,
    isFromFastweb: (state) => state.isFromFastweb,
    activeLine: (state) => state.activeLine,
    catalogId: (state) => state.catalogId,
    timer: (state) => state.timer,
    addressCovered: (state) => state.addressCovered,
    addressShipping: (state) => state.addressShipping,
    withGoogleWiFi: (state) => state.withGoogleWiFi,
    withModem: (state) => state.withModem,
    getFlagVoucherFibra: (state) => state.flagVoucherFibra,
    identityDocument: (state) => state.identityDocument || {},
    client: (state) => state.client || {},
    paymentMethod: (state) => state.paymentMethod,
    invoiceMethod: (state) => state.invoiceMethod,
    speedUpProcess: (state) => state.speedUpProcess,
    infoConsents: (state) => state.infoConsents,
    summarySupplyOwner: (state, getters) => ({
      firstName: capitalize(getters.client.firstName),
      lastName: capitalize(getters.client.lastName),
      cf: getters.client.fiscalCode,
      idType: getters.identityDocument.type,
      idNumber: getters.identityDocument.number,
      nationality: getters.identityDocument.nationality
    }),
    migrationCode(state) {
      return state.migrationCode
    },
    getActivationDataFiber: (state) => state.activationDataFiber
  },
  mutations: {
    updateFiberCtx(state, props) {
      const definedProps = (obj) =>
        Object.fromEntries(
          Object.entries(obj).filter(([k, v]) => v !== undefined)
        )
      Object.assign(state.fiberCtx, definedProps(props))
    },
    resetFiberState(state) {
      state.catalogId = null
      state.client = null
      state.timer = null
      state.addressCovered = null
      state.addressShipping = null
      state.withGoogleWiFi = null
      state.withModem = false
      state.flagVoucherFibra = false
      state.identityDocument = null
      state.paymentMethod = null
      state.invoiceMethod = null
      state.speedUpProcess = null
      state.infoConsents = false
      state.migrationCode = null
      state.connectionType = null // FTTH FTTC
      state.provider = null // OF, FW
      state.isFromTelecom = false
      state.isFromFastweb = false
      state.activeLine = false
      state.mgmDetail = false
      state.activationDataFiber = {}
    },
    resetFiberCtx(state) {
      state.fiberCtx.client_code = null
      state.fiberCtx.role = null
      state.fiberCtx.client_firstname = null
      state.fiberCtx.client_lastname = null
      state.fiberCtx.supply_address = null
      state.fiberCtx.upselling_session_id = null
      state.fiberCtx.upselling_step_name = null
      state.fiberCtx.is_migration = null
      state.fiberCtx.migration_code = null
      state.fiberCtx.has_selected_vas = null
    },
    setConnectionType(state, connectionType) {
      state.connectionType = connectionType
    },
    setProvider(state, provider) {
      state.provider = provider
    },
    setIsFromTelecom(state, isTelecom) {
      state.isFromTelecom = isTelecom
    },
    setIsFromFastweb(state, isFastweb) {
      state.isFromFastweb = isFastweb
    },
    setActiveLine(state, activeLine) {
      state.activeLine = activeLine
    },
    setCatalogId(state, catalogId) {
      state.catalogId = catalogId
    },
    setClient(state, client) {
      state.client = client
    },
    restartTimer(state, seconds) {
      state.timer = seconds
      state.timerExpired = false

      timerListener = setInterval(() => {
        if (state.timer > 0) {
          state.timer--
        } else {
          clearInterval(timerListener)

          if (state.timer) {
            eventBus.$emit('upselling-fiber:timer-expired')
          }
        }
      }, 1000)
    },
    stopTimer(state) {
      clearInterval(timerListener)
      state.timer = null
    },
    setAddressCovered(state, addressCovered) {
      state.addressCovered = addressCovered
    },
    setAddressShipping(state, addressShipping) {
      if (addressShipping.interphone) {
        state.addressCovered.interno = addressShipping.interphone
      }
      state.addressShipping = addressShipping
    },
    setWithGoogleWiFi(state, count) {
      state.withGoogleWiFi = count
    },
    setWithModem(state, modem) {
      state.withModem = modem
    },
    setFlagVoucherFibra(state, value) {
      state.flagVoucherFibra = value
    },
    setIdentityDocument(state, identityDocument) {
      state.identityDocument = identityDocument
    },
    setPaymentMethod(state, method) {
      state.paymentMethod = method
    },
    setInvoiceMethod(state, method) {
      state.invoiceMethod = method
    },
    setSpeedUpProcess(state, speedUpProcess) {
      state.speedUpProcess = speedUpProcess
    },
    setMgmDetail(state, mgmDetail) {
      state.mgmDetail = mgmDetail
    },
    setInfoConsents(state, infoConsents) {
      state.infoConsents = infoConsents
    },
    setMigrationCode(state, migrationCode) {
      state.migrationCode = migrationCode
    },
    resetState(state) {
      state.catalogId = null
      state.client = null
      state.timer = null
      state.addressCovered = null
      state.addressShipping = null
      state.withGoogleWiFi = 0
      state.identityDocument = null
      state.paymentMethod = null
      state.invoiceMethod = null
      state.speedUpProcess = null
      state.infoConsents = false
      state.migrationCode = null
    },
    setActivationDataFiber(state, activationDataFiber) {
      state.activationDataFiber = activationDataFiber
    }
  },
  actions: {
    async retrieveActivationDataFiber({ rootGetters, commit }, params) {
      const response = await axiosACN.post(
        '/fibra/retrieveActivationDateFiber',
        params,
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        }
      )
      if (response.data.date) {
        commit('setActivationDataFiber', response.data.date) //DA VEDERE SE È UN ARRAY O MENO
      } else {
        throw Error('NO_DATA_RESULT')
      }
    },
    getRepeaterDescriptionCMS({ state }, params) {
      return axiosCMS
        .get(`/getRepeaterDescription?client_type=${params}`)
        .then(({ data }) => {
          state.repeaterDescription = data[0]
          state.repeaterDescription.repeater_image =
            axiosCMS.defaults.baseURL + data[0].repeater_image
          return state.repeaterDescription
        })
    },
    async getFlagRepeater({ state }) {
      const { data } = await axiosCMS.get(`/funnel_configurations`)
      state.flagRepeater = data[0].active || false
    },
    getSiaUrl({ rootGetters }, params) {
      // const iframeBaseUrl = 'https://sia-iframe.firebaseapp.com'
      const iframeBaseUrl = window.location.origin

      return axiosACN
        .post(
          '/payment/V2/CCR',
          {
            email: params.email,
            urlBack: window.btoa(iframeBaseUrl + '/sia/back.html'),
            urlDone: window.btoa(iframeBaseUrl + '/sia/done.html')
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          return window.atob(res.data.urlRedirect)
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },
    getCheckMigrazione({ rootGetters }, params) {
      return axiosACN
        .post('/fibra/checkMigrazione', params, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    },
    async subscribe({ getters, dispatch, rootGetters }) {
      const egonComune = await dispatch(
        'egon/getEgonComune',
        getters.addressCovered.city,
        { root: true }
      )
      let params = {
        flagCondizioniContratto: getters.infoConsents,
        flagClausoleVessatorie: true,
        // 'flagNoteLegali': true,
        // 'flagPrivacy': true,
        flagConsensoMarketing: getters.client.marketingConsentFlag,
        flagConsensoSoggettiTerzi: getters.client.thirdPartConsentFlag,
        flagConsensoProfilazione: getters.client.profilingConsentFlag,
        flagRecesso: getters.speedUpProcess,
        codicePromo: '',
        codiceCampagna: '',
        fibra: {},
        cliente: {},
        datiPagamento: {}
      }

      if (getters.mgmDetail) {
        params.amico = {
          codice: getters.mgmDetail ? getters.mgmDetail.promoCode : '',
          listinoMgm: getters.listinoMgm ? getters.mgmDetail.listinoMgm : '',
          nomeCampagnaMgm: getters.mgmDetail.nomeCampagnaMgm
            ? getters.mgmDetail.nomeCampagnaMgm
            : ''
        }
      }

      params.fibra = {
        installazione: {
          citofono: getters.addressCovered.interno || 'n/d',
          flagIndirizzoFibra: !getters.addressShipping
        },
        tipoSconto: getters.client.isDUAL ? 'APP_DUAL' : 'APP_MONO',
        flagVoucherFibra: getters.getFlagVoucherFibra,
        flagLineaInternet: !!getters.migrationCode.code,
        flagLineaFissa: !!getters.migrationCode.telefonoFisso,
        flagUnicoNumero: !getters.migrationCode.flagAltriNumeri,
        flagAttivazioneAnticipata: getters.speedUpProcess,
        flagModemProprietario: !getters.withModem,
        codiceMigrazione: getters.migrationCode.code || '',
        fatturazione: {
          tipo: getters.invoiceMethod.type === 'paper' ? 'C' : 'D',
          flagIndirizzoFibra: getters.invoiceMethod.sameAsSupplyAddress,
          nominativo: getters.invoiceMethod.among
        },
        tipoTecnologia:
          getters.provider === 'OF'
            ? 'FTTH_GPON'
            : getters.addressCovered.typology,
        tipoConnessione: getters.addressCovered.connectionType,
        telefonoFisso: getters.migrationCode.telefonoFisso || '',
        indirizzo: {
          via: getters.addressCovered.street || '',
          cap: getters.addressCovered.zipCode || '',
          comune: getters.addressCovered.city || '',
          civico: getters.addressCovered.streetNumber || '',
          idEgonCivico: getters.addressCovered.egonStreetNumber || '',
          provincia: getters.addressCovered.county || '',
          regione: getters.addressCovered.egonRegion || '',
          paese: 'Italia',
          idEgonVia: getters.addressCovered.egonStreet || '',
          esponente: getters.addressCovered.egonStreetNumberLetter || '',
          idEgonComune: egonComune[0].comuneEgon
        },
        repeaterCounter: getters.withGoogleWiFi || 0,
        listino: rootGetters['offers/offers'].listPrice
      }

      params.cliente = {
        codiceCliente: getters.addressCovered.clientOwnerCode,
        codiceFiscale: getters.client.fiscalCode,
        nome: getters.addressCovered.firstName,
        cognome: getters.addressCovered.lastName,
        email: getters.client.contactMail,
        telefono:
          getters.client.mobilePhone && getters.client.mobilePhone.length === 10
            ? getters.client.mobilePhone
            : '0000000000',
        documento: {
          tipo: getters.identityDocument.type === 'card' ? 'CI' : 'PAS',
          numero: getters.identityDocument.number,
          nazione: getters.identityDocument.nationality
        }
      }

      if (
        getters.paymentMethod.methodType === Consts.PAYMENT_METHODS.BANK_ACCOUNT
      ) {
        params.datiPagamento = {
          contoCorrente: {
            flagTitolareConto:
              getters.client.fiscalCode ===
              getters.paymentMethod.ibanSubscriberFiscalCode,
            sottoscrittoreCognome:
              getters.paymentMethod.ibanSubscriberLastName || '',
            sottoscrittoreNome:
              getters.paymentMethod.ibanSubscriberFirstName || '',
            codiceFiscale: getters.paymentMethod.ibanSubscriberFiscalCode || '',
            codiceIban: getters.paymentMethod.iban || ''
          }
        }
      } else {
        const creditCardCircuit = getters.paymentMethod.creditCardCircuit
        params.datiPagamento = {
          cartaCredito: {
            alias: getters.paymentMethod.aliasPan || '',
            crecurr: getters.paymentMethod.crecurr || '',
            circuit: Object.keys(Consts.CREDIT_CARDS_CIRCUITS).includes(
              getters.paymentMethod.creditCardCircuit
            )
              ? Consts.CREDIT_CARDS_CIRCUITS[creditCardCircuit]
              : getters.paymentMethod.creditCardCircuit || '',
            creditCard:
              (getters.paymentMethod.creditCardNumber &&
                getters.paymentMethod.creditCardNumber.replace(/\*/g, '')) ||
              '',
            expiredDateCreditCard:
              getters.paymentMethod.creditCardExpirationDate &&
              // getters.paymentMethod.creditCardExpirationDate.replace(
              //   /\//g,
              //   ''
              // )
              getters.paymentMethod.creditCardExpirationDate.includes('/')
                ? getters.paymentMethod.creditCardExpirationDate
                    .split('/')[1]
                    .substring(2) +
                  getters.paymentMethod.creditCardExpirationDate.split('/')[0]
                : getters.paymentMethod.creditCardExpirationDate
                ? getters.paymentMethod.creditCardExpirationDate
                : ''
          }
        }
      }

      if (getters.connectionType === 'FTTC' && getters.migrationCode.checked) {
        params.fibra.installazione = {
          presso: getters.addressCovered.interno || '',
          flagIndirizzoFibra: !getters.addressShipping,
          indirizzoModem: {
            via: getters.addressShipping.address.fullVia || '',
            cap: getters.addressShipping.address.cap || '',
            comune: getters.addressShipping.city.comune || '',
            civico: getters.addressShipping.civicNumber.civico || '',
            idEgonCivico: getters.addressShipping.civicNumber.civicoEgon || '',
            provincia: getters.addressShipping.city.provincia || '',
            regione: getters.addressShipping.city.regione || '',
            paese: 'Italia',
            idEgonVia: getters.addressShipping.address.viaEgon || '',
            esponente: getters.addressShipping.civicNumber.esponente || '',
            idEgonComune: egonComune[0].comuneEgon
          }
        }
      }

      if (
        getters.invoiceMethod.type === 'paper' &&
        !getters.invoiceMethod.sameAsSupplyAddress
      ) {
        params.fibra.fatturazione.indirizzo = {
          via: getters.invoiceMethod.address.label,
          cap: getters.invoiceMethod.cap.label,
          comune: getters.invoiceMethod.city.fullInfo.DSXOBJCNL,
          civico: getters.invoiceMethod.number.fullInfo.NRPNUMCIV.lValue,
          idEgonCivico: String(getters.invoiceMethod.number.value || ''),
          // 'frazione': '',
          provincia: getters.invoiceMethod.city.fullInfo.DSXOBJDPT,
          regione: getters.invoiceMethod.city.fullInfo.DSXOBJREG,
          paese: 'Italia',
          // 'presso': getters.invoiceMethod.among,
          idEgonVia: String(getters.invoiceMethod.address.value || ''),
          esponente: getters.invoiceMethod.number.fullInfo.DSXESP,
          idEgonComune: egonComune[0].comuneEgon
        }
      } else if (
        getters.invoiceMethod.type === 'paper' &&
        getters.invoiceMethod.sameAsSupplyAddress
      ) {
        params.fibra.fatturazione.indirizzo = {
          via: getters.addressCovered.street,
          cap: getters.addressCovered.zipCode,
          comune: getters.addressCovered.city,
          civico: getters.addressCovered.streetNumber,
          idEgonCivico: String(getters.addressCovered.egonStreetNumber || ''),
          // 'frazione': getters.addressCovered.egonLocality,
          provincia: getters.addressCovered.county,
          regione: getters.addressCovered.egonRegion,
          paese: 'Italia',
          // 'presso': getters.invoiceMethod.among,
          idEgonVia: String(getters.addressCovered.egonStreet || ''),
          esponente: getters.addressCovered.egonStreetNumberLetter,
          idEgonComune: egonComune[0].comuneEgon
        }
      }

      if (
        params.fibra.indirizzo.idEgonCivico === '' ||
        params.fibra.indirizzo.idEgonCivico === undefined ||
        params.fibra.indirizzo.idEgonVia === '' ||
        params.fibra.indirizzo.idEgonVia === undefined
      ) {
        throw Error('UNKNOWN_ERROR')
      } else {
        return axiosACN
          .post('/fibra/V3/sottoscrizioneContratto', params, {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          })
          .then((res) => {
            // Manage business logic errors (coming with status 200 OK + errorcode)
            if (res.data.errorCode) {
              // No bus logic errors expected
              throw Error('UNKNOWN_ERROR')
            }
            return res.status
          })
          .catch((err) => {
            if (err.statusCode && err.statusCode >= 400) {
              throw Error('SERVER_ERROR')
            } else if (err.message) {
              throw err
            }
          })
      }
    }
  }
}
