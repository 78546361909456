import Support from '../../views/support/Support'
import Faq from '../../views/support/Faq'
import Contacts from '../../views/support/Contacts'
import Statuses from '../../views/support/Statuses'
import FiberContacts from '../../views/support/FiberContacts'
import NewRequest from '../../views/support/NewRequest'
import IdentificationCode from '../../views/support/IdentificationCode'
import DreamServiceList from '../../views/support/DreamServiceList'
import DreamServiceDetail from '../../views/support/DreamServiceDetail'
import SendRequest from '@/views/support/SendRequest'
const routes = [
  {
    path: '/',
    name: 'SupportPage',
    component: Support
  },
  {
    path: 'faq',
    name: 'Faq',
    component: Faq,
    props: true
  },
  {
    path: 'contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: 'statuses/:clientCode',
    name: 'Statuses',
    component: Statuses
  },
  {
    path: 'statuses/:clientCode/dreamservice',
    name: 'DreamServiceList',
    component: DreamServiceList
  },
  {
    path: 'statuses/:clientCode/dreamservice/detail/:requestId',
    name: 'DreamServiceDetail',
    component: DreamServiceDetail
  },
  {
    path: 'fibercontacts',
    name: 'FiberContacts',
    component: FiberContacts
  },
  {
    path: 'newrequest',
    name: 'NewRequest',
    component: NewRequest
  },
  {
    path: 'pdr',
    name: 'IdentificationCode',
    component: IdentificationCode
  },
  {
    path: 'sendRequest',
    name: 'SendRequest',
    component: SendRequest,
    props: true
  }
]

export default routes
