const MenuLink = [
  {
    name: 'profile',
    looking: ['account', 'profile']
  },
  {
    name: 'summary',
    looking: [
      'owner-supplies',
      'tracking/commodity',
      'supply',
      'payment-summary'
    ]
  },
  {
    name: 'service',
    looking: ['myshop', 'service-profile']
  },
  {
    name: 'awards',
    looking: ['loyalty', 'welcome-kit']
  },
  {
    name: 'support',
    looking: ['support']
  }
]

const root = 'private'

function getMenuActive(path) {
  let out = { name: '', looking: '' }
  if (path.includes(root)) {
    if (path === '/private/home') {
      out.name = 'home'
    } else {
      out = MenuLink.find((item) => {
        const val = item.looking.find((search) => path.includes(`/${search}`))
        if (val) {
          return val
        }
      })
    }
  }

  return out ? out.name : ''
}

export { getMenuActive }
