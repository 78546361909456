<template>
  <div class="punTitle">
    <div class="punTitle__title">
      <div class="HeadingsTitleXXL">{{ getInfoPUNtuale.pageTitle }}</div>
      <img
        @click="openModalPUNInfo"
        class="ml-3"
        src="@/assets/icons/info.svg"
      />
    </div>
    <div v-html="getInfoPUNtuale.pageSubtitle" class="BodyXL"></div>
    <CustomDialog
      :customDialogOpen.sync="customDialogOpen"
      :body="bodyModal"
      :title="titleModal"
      :showImage="showImage"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CustomDialog from '../upselling/gas&light/CustomDialog.vue'

export default {
  name: 'PunTitle',
  components: {
    CustomDialog
  },
  data() {
    return {
      customDialogOpen: false,
      bodyModal: null,
      titleModal: null,
      showImage: false
    }
  },
  computed: {
    ...mapGetters('supply', ['getInfoPUNtuale']),
    fixBodyModal() {
      let bodyModal = String(this.getInfoPUNtuale.modalText).replace(
        /<\/p>/g,
        '</p><br>'
      )
      return bodyModal
    }
  },

  methods: {
    openModalPUNInfo() {
      this.titleModal = this.getInfoPUNtuale.modalTitle
      this.bodyModal = this.fixBodyModal
      this.customDialogOpen = true
    }
  }
}
</script>
<style lang="scss" scoped>
.punTitle {
  margin-bottom: 38px;

  &__title {
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
    }
  }
}
</style>
