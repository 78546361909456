import { axiosACN } from '@/js/axiosInstances.js'

const sendParentalControlResponse = {
  status: 'KO'
}

export default {
  namespaced: true,
  state: {
    pricing: {},
    nationality: []
  },
  getters: {
    nationality(state) {
      return state.nationality
    }
  },
  mutations: {
    resetState(state) {
      state.pricing = {}
      state.nationality = []
    },
    setpricing({ state }, pricing) {
      state.pricing = pricing
    },
    setNationality(state, nationality) {
      state.nationality = nationality
    }
  },
  actions: {
    async getPricing({ commit, rootGetters }) {
      const contractType = 'DUAL' // This should come from the 'account' store module (ELE | GAS | DUAL)

      try {
        let pricing = await axiosACN.post(
          '/sorgenia/fibra/getlistinoFibra',
          {
            tipoFUnnel: 'FIBRA',
            tipoContratto: contractType
          },
          {
            headers: {
              access_token: rootGetters['session/token'],
              token_type: 'bearer'
            }
          }
        )

        commit('setpricing', pricing)
      } catch (err) {
        // USING the retry strategy. all errors are centrally managed
      }
    },
    async getNationality({ commit, rootGetters }) {
      try {
        let nationality = await axiosACN.get('/fibra/nazionalita', {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })

        commit('setNationality', nationality.data.nazionalita)
        return nationality.data.nazionalita
      } catch (err) {
        // USING the retry strategy. all errors are centrally managed
      }
    },

    sendParentalControlRequestMock({ rootGetters, commit }, body) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(sendParentalControlResponse)
        }, 2000)
      })
    },
    async sendParentalControlRequest({ rootGetters }, body) {
      try {
        let res = await axiosACN.post(
          '/fibra/sendParentalControlRequest',
          body,
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        return res
      } catch (err) {
        console.error('error in sendParentalControlRequest', err)
        throw err
      }
    }
  }
}
