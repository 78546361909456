<template>
  <layout>
    <!-- <TcVars env_template="mysorgenia_greenes" /> -->
    <template v-slot:header>
      <div class="breadcrumbsGreeners">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </div>
      <v-col class="col-6 content">
        <img
          class="content__logo"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
        <h3 class="content__title">
          La <strong>Community sostenibile</strong><br />di Sorgenia
        </h3>
      </v-col>
      <v-col class="col-6 contentAccount">
        <div class="avatar" @click="openDialogClientSelected">
          <img
            v-if="
              accountPhoto &&
              mainClient.clientOwnerCode ===
                mainClientLoyaltySelected.clientOwnerCode
            "
            :src="accountPhotoSrc"
            class="photo"
            alt="account photo"
          />
          <v-img class="photo" :src="IconProfileNoPhoto" v-else />
          <v-dialog v-model="selectClientDialog" max-width="555">
            <v-row>
              <v-col class="dialogClient">
                <img
                  class="close"
                  @click="selectClientDialog = false"
                  src="../../assets/ic_chevron_top_black.png"
                />
                <h4>Seleziona profilo</h4>
                <div
                  v-for="(client, i) in loyaltyClients"
                  :key="i"
                  class="mb-6"
                >
                  <h4 v-if="i === 1">{{ labelAddUser }}</h4>
                  <div class="box">
                    <div class="client" @click="setClientSelected(client)">
                      <!-- <img
                        v-if="accountPhoto && mainClient.clientOwnerCode === client.clientOwnerCode"
                        :src="accountPhotoSrc"
                        class="photo"
                        alt="account photo"
                      />
                      <IconProfileNoPhoto v-else class="photo"/> -->
                      <h5 :class="`client ${client.clientOwnerType}`">
                        {{
                          client.clientOwnerType === 'RES'
                            ? client.firstName + ' ' + client.lastName
                            : client.businessName
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-dialog>
        </div>
        <img class="img" src="@/assets/greeners/landing_greeners_new.svg" />
      </v-col>
    </template>
    <v-row class="dashboard">
      <div class="d-flex">
        <div class="dashboard__levels">
          <div class="bigTitle"><strong>Sei al livello</strong></div>
          <template v-if="IDENTITY_BALANCE_ERROR || IDENTITY_LEVEL_ERROR">
            <div class="errorMessage">IN CARICAMENTO</div>
            <div class="detailsOther" @click="openAlert">
              A che punto sei del percorso?
            </div>
          </template>
          <template v-else>
            <h5>
              <strong
                >{{ levelIcon.level }} ({{ clientLevel.level_step + 1 }} di 8
                missioni)</strong
              >
            </h5>
            <div class="detailsLevels d-flex align-center pt-4 pb-4">
              <StatusIcon
                withProgressionBar
                size="sm"
                :client-level="clientLevel || {}"
              />
              <div class="ml-4">
                <h2>
                  <strong>{{ memberBalance.user_balance || 0 }}</strong>
                </h2>
                <h6><strong>GREEN COINS</strong></h6>
              </div>
            </div>
            <div class="detailsOther"><ScaleLivelli /></div>
          </template>
          <div
            class="detailsOther"
            @click="openModalForPendingUser((typeModal = 'saldo'))"
          >
            <strong>Vedi il tuo saldo</strong>
          </div>
          <v-btn
            v-if="prizesToRetrive > 0"
            :to="{ name: 'giftsMainPage' }"
            class="white mb-6 lowerCase"
            width="290"
            height="54"
          >
            <div class="d-flex align-center">
              Premio da ritirare
              <div class="giftCounter">{{ prizesToRetrive }}</div>
            </div>
          </v-btn>
        </div>
        <div class="dashboard__messages">
          <div class="bigTitle"><strong>Messaggi per te</strong></div>
          <v-row no-gutters v-if="!isPending">
            <div class="container-progressbar" v-if="!loadedGreeners">
              <v-progress-circular
                color="#12256A"
                :size="50"
                indeterminate
              ></v-progress-circular>
            </div>

            <carousel
              v-if="loadedGreeners"
              :perPage="2"
              :navigationEnabled="true"
              :navigation-next-label="nextLabel"
              :navigation-prev-label="prevLabel"
              paginationColor="#CFCFCF"
              paginationActiveColor="#12256a"
              :paginationPadding="5"
              @page-change="pageChange"
              class="messages__carousel custom"
              id="messages__carousel"
            >
              <slide
                v-for="(item, i) in greeners"
                :key="item.id"
                class="mt-1 mb-1"
                :class="[i % 2 === 0 ? 'pari' : 'dispari']"
              >
                <div
                  :class="`messages__carousel-itemMex  ${item.class}`"
                  @click="
                    trackTapEvent({
                      name: 'greeners_diaryHighlightCard_tap',
                      params: {
                        client_code: mainClientLoyaltySelected.clientOwnerCode,
                        client_type: mainClientLoyaltySelected.clientOwnerType,
                        card_title: item.text,
                        card_id: item.id || item.to
                      }
                    }),
                      go(item)
                  "
                >
                  <img
                    :width="item.width ? item.width : null"
                    :height="item.height ? item.height : 112"
                    :src="item.filename"
                  />
                  <h4 class="text" v-html="item.text" />
                </div>
              </slide>
            </carousel>
          </v-row>
          <v-row v-else>
            <span class="BodyXL">Non ci sono elementi da visualizzare</span>
          </v-row>
        </div>
      </div>
    </v-row>
    <!-- <v-row class="coins">
      <v-col
        cols="8"
        class="pt-0 pb-0"
      >
        <v-row class="balance v-card v-card--rounded">
          <img class="balance__img" src="@/assets/greeners/svg/greeners.svg" width="82"/>
          <div class="balance__info">
            <p class="balance__info--lg" v-text="memberBalance.user_balance || 0"/>
            <h4><strong>I tuoi Green Coins</strong></h4>
          </div>
          <v-btn
            color="brightGreen"
            class="balance__btn v-btn--shadowed"
            @click="trackTapEvent({name: 'greeners_diaryPoints_tap', params: {client_code: mainClientLoyaltySelected.clientOwnerCode, client_type: mainClientLoyaltySelected.clientOwnerType}}); $router.push({name: 'coinsPage'})"
          >
            Saldo Green Coins
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="4" class="levels">
        <StatusIcon withProgressionBar size="md" :client-level="clientLevel || {}"/>
        <div class="levels__info">
          <p class="levels__info__text">Livello</p>
          <p class="levels__info__text bold" v-text="levelIcon.level"/>
          <ScaleLivelli/>
        </div>
      </v-col>
    </v-row>
    <v-row class="messages-title" v-if="greeners.length > 0">
      <v-col cols="6" class="pt-0 pb-0">
        <h2 class="sub-title">In evidenza</h2>
      </v-col>
    </v-row>
    <v-row class="messages">
      <carousel
        :perPage="3"
        :navigationEnabled="true"
        :navigation-next-label="nextLabel"
        :navigation-prev-label="prevLabel"
        paginationColor="#CFCFCF"
        paginationActiveColor="#12256a"
        :paginationPadding="5"
        class="messages__carousel"
        id="messages__carousel"
      >
        <slide v-for="item in greeners" :key="item.id" class="mt-1 mb-1">
          <v-col>
            <div :class="`messages__carousel-item v-card v-card--rounded ${item.class}`"
              @click="trackTapEvent({name: 'greeners_highlightCardCTA_tap', params: {client_code: mainClientLoyaltySelected.clientOwnerCode, client_type: mainClientLoyaltySelected.clientOwnerType, card_title: item.text, card_id: item.id || item.to }}); go(item)">
              <img :width="item.width ? item.width : null" :height="item.height ? item.height : 112"
                :src="item.filename"/>
              <h4 class="text" v-html="item.text"/>
            </div>
          </v-col>
        </slide>
      </carousel>
    </v-row> -->
    <v-dialog
      overlay-opacity="0.6"
      v-model="cardDialog"
      max-width="1140"
      v-if="cardDialogContent"
    >
      <v-card class="card-dialog">
        <v-row>
          <v-col align="right">
            <img
              class="dialog__img"
              @click="cardDialog = false"
              src="@/assets/ic_chevron_top_black.png"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h1 v-html="cardDialogContent.popupTitle"></h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div
              class="dialog__text"
              v-html="cardDialogContent.popupText"
            ></div>
          </v-col>
          <v-col v-if="cardDialogContent.popupImg">
            <img
              class="dialog__imageCard"
              :src="CmsUrl + cardDialogContent.popupImg"
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col align="center">
            <SpeedTest
              v-if="
                cardDialogContent.actionType === 'APP_SECTION' &&
                APP_SECTION_MAPPING[cardDialogContent.popupAction] ===
                  'speedTest'
              "
            >
              <v-btn
                color="brightGreen"
                x-large
                class="v-btn--shadowed"
                @click="
                  ;(cardDialog = false),
                    trackTapEvent({
                      name: 'vetrina_promo_tap',
                      params: {
                        promo_id: cardDialogContent.id,
                        promo_title: cardDialogContent.title
                      }
                    })
                "
              >
                {{ cardDialogContent.popupCta }}
              </v-btn>
            </SpeedTest>
            <v-btn
              v-else-if="
                cardDialogContent.actionType === 'APP_SECTION' &&
                APP_SECTION_MAPPING[cardDialogContent.popupAction] ===
                  'carbonFootprint'
              "
              color="brightGreen"
              x-large
              class="v-btn--shadowed"
              @click="
                ;(cardDialog = false),
                  trackTapEvent({
                    name: 'greeners_diaryHighlightCard_tap',
                    params: {
                      client_code: mainClientLoyaltySelected.clientOwnerCode,
                      client_type: mainClientLoyaltySelected.clientOwnerType,
                      card_title: cardDialogContent.popupTitle,
                      card_id: cardDialogContent.id
                    }
                  }),
                  openFootprint()
              "
            >
              {{ cardDialogContent.popupCta }}
            </v-btn>
            <v-btn
              v-else-if="
                cardDialogContent.actionType === 'APP_SECTION' &&
                APP_SECTION_MAPPING[cardDialogContent.popupAction] !== undefined
              "
              class="v-btn--shadowed"
              color="brightGreen"
              x-large
              width="265"
              @click="
                trackTapEvent({
                  name: 'greeners_diaryHighlightCard_tap',
                  params: {
                    client_code: mainClientLoyaltySelected.clientOwnerCode,
                    client_type: mainClientLoyaltySelected.clientOwnerType,
                    card_title: cardDialogContent.popupTitle,
                    card_id: cardDialogContent.id
                  }
                }),
                  $route.name ===
                  APP_SECTION_MAPPING[cardDialogContent.popupAction]
                    ? (cardDialog = false)
                    : $router.push({
                        name: APP_SECTION_MAPPING[cardDialogContent.popupAction]
                      })
              "
            >
              {{ cardDialogContent.popupCta }}
            </v-btn>
            <v-btn
              v-else-if="cardDialogContent.actionType === 'APP_SECTION'"
              color="brightGreen"
              x-large
              width="265"
              @click="
                trackTapEvent({
                  name: 'greeners_diaryHighlightCard_tap',
                  params: {
                    client_code: mainClientLoyaltySelected.clientOwnerCode,
                    client_type: mainClientLoyaltySelected.clientOwnerType,
                    card_title: cardDialogContent.popupTitle,
                    card_id: cardDialogContent.id
                  }
                }),
                  $route.name ===
                  APP_SECTION_MAPPING[cardDialogContent.popupAction]
                    ? (cardDialog = false)
                    : $router.push({
                        name:
                          cardDialogContent.popupAction === 'loyalty_catalog'
                            ? 'catalogue'
                            : 'loyaltyPage'
                      })
              "
            >
              {{ cardDialogContent.popupCta }}
            </v-btn>
            <a
              v-if="cardDialogContent.actionType === 'WEB_LINK'"
              style="text-transform: initial"
              class="v-btn--shadowed v-btn v-btn--contained theme--light v-size--x-large brightGreen"
              :href="cardDialogContent.popupUrl"
              @click="
                trackTapEvent({
                  name: 'greeners_diaryHighlightCard_tap',
                  params: {
                    client_code: mainClientLoyaltySelected.clientOwnerCode,
                    client_type: mainClientLoyaltySelected.clientOwnerType,
                    card_title: cardDialogContent.popupTitle,
                    card_id: cardDialogContent.id
                  }
                })
              "
              target="_blanck"
            >
              {{ cardDialogContent.popupCta }}
            </a>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <div class="missions-title d-flex justify-space-between align-center">
      <h2 class="sub-title">Missioni per te</h2>
      <v-btn
        v-if="availableMissions.length"
        height="54"
        width="296"
        class="white"
        @click="
          trackTapEvent({
            name: 'greeners_diaryHowWorks_tap',
            params: {
              client_code: mainClientLoyaltySelected.clientOwnerCode,
              client_type: mainClientLoyaltySelected.clientOwnerType,
              isPending: isPending
            }
          }),
            $router.push({ name: 'IntroPage' })
        "
      >
        Scopri come funziona
      </v-btn>
    </div>
    <v-dialog overlay-opacity="0.6" v-model="levelsDialog" max-width="910">
      <v-card class="levels-dialog">
        <div class="d-flex justify-space-between align-center mb-12">
          <div class="textBack" @click="levelsDialog = false">
            Guida ai livelli
          </div>
          <img
            class="imgBack"
            @click="levelsDialog = false"
            src="@/assets/ic_chevron_top_black.png"
          />
        </div>
        <LevelGuides />
      </v-card>
    </v-dialog>
    <v-row class="missions" v-if="availableMissions.length">
      <carousel
        :perPage="3"
        v-if="missionLoaded"
        :navigationEnabled="true"
        :navigation-next-label="nextLabel"
        :navigation-prev-label="prevLabel"
        paginationColor="#CFCFCF"
        paginationActiveColor="#12256a"
        :paginationPadding="5"
        class="missions__carousel"
      >
        <slide v-if="promos && !hasCfp && !isPending">
          <v-col style="height: 100%">
            <div
              class="missions__carousel-item v-card v-card--rounded"
              style="width: 352px"
              @click="openFootprint()"
            >
              <div
                class="img"
                :style="`backgroundImage: url('${require('@/assets/greeners/ph_loyalty_missioni.png')}')`"
              />
              <div class="price">
                <div class="price__coins">
                  <img
                    src="@/assets/greeners/new_greeners_icon_blue.svg"
                    class="price__img"
                  />
                  <span class="price__value">{{
                    missionValue('1507', '1500')
                  }}</span>
                </div>
                <div class="price__lvl"><span>+4</span>MISSIONI</div>
              </div>
              <div class="content">
                <div class="mission-info">
                  <span class="mission-info__name">CARBOON FOOTPRINT</span>
                </div>
                <h3>Qual è il tuo impatto sul pianeta? Misuralo</h3>
              </div>
            </div>
          </v-col>
        </slide>
        <slide
          v-for="item in availableMissions"
          :key="item.id"
          class="mt-1 mb-1"
        >
          <v-col style="height: 100%">
            <div
              class="missions__carousel-item v-card v-card--rounded"
              style="width: 352px"
              @click="
                trackTapEvent({
                  name: 'greeners_diaryMissionCard_tap',
                  params: {
                    client_code: mainClientLoyaltySelected.clientOwnerCode,
                    client_type: mainClientLoyaltySelected.clientOwnerType,
                    mission_id: item.id,
                    isPending: isPending
                  }
                }),
                  $router.push({
                    name: missionType(item.mission_type),
                    params: { id: item.id }
                  })
              "
            >
              <div
                class="img"
                :style="{
                  backgroundImage: `url('${CmsUrl + item.welcome_page_image}')`
                }"
              />
              <div class="price">
                <div class="price__coins">
                  <img
                    src="@/assets/greeners/new_greeners_icon_blue.svg"
                    class="price__img"
                  />
                  <span class="price__value">{{
                    missionValue(item.wekit_action_id, item.wekit_event_id)
                  }}</span>
                </div>
                <div class="price__lvl">
                  <span>{{ item.weight < 1500 ? '+1' : '+4' }}</span
                  >MISSIONI
                </div>
              </div>
              <div class="content">
                <div class="mission-info">
                  <span class="mission-info__name">{{
                    item.second_app_section_label
                  }}</span>
                  <v-chip
                    v-if="item.badge"
                    class="mission-info__chip"
                    :color="
                      item.badge && item.badge === 'NUOVO'
                        ? 'primary'
                        : '#d93273'
                    "
                  >
                    {{ item.badge }}
                  </v-chip>
                </div>
                <h3>{{ item.title }}</h3>
              </div>
            </div>
          </v-col>
        </slide>
      </carousel>
      <h5 class="ml-5" v-else>Loading...</h5>
    </v-row>
    <div v-else class="missions__empty mt-2">
      <span v-if="TRANSACTION_LIST_ERROR" class="mb-6"
        >Le missioni sono momentaneamente non disponibili. Torna più
        tardi.</span
      >
      <span v-else class="mb-6"
        >Complimenti, hai completato le missioni disponibili!<br />Non perderti
        i messaggi della community e tieniti pronto per le prossime
        missioni.</span
      >
      <v-btn
        height="54"
        width="296"
        class="white"
        @click="
          trackTapEvent({
            name: 'greeners_diaryHowWorks_tap',
            params: {
              client_code: mainClientLoyaltySelected.clientOwnerCode,
              client_type: mainClientLoyaltySelected.clientOwnerType
            }
          }),
            $router.push({ name: 'IntroPage' })
        "
      >
        Scopri come funziona
      </v-btn>
    </div>
    <!-- <v-row class="bonuses-title">
      <v-col cols="4" class="pt-0 pb-0">
        <h2 class="sub-title">Bonus per te</h2>
      </v-col>
      <v-col cols="4" class="pt-0 pb-0">
        <h2 class="sub-title">Ecco i premi!</h2>
      </v-col>
      <v-col cols="4" class="d-flex">
        <v-btn
          v-if="!noPrizesToRetrieve"
          :to="{name: 'giftsMainPage'}"
          class="white ml-auto"
          width="247"
          height="54"
        >
          Premi da ritirare
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row class="bonuses d-flex justify-space-between align-center">
      <div>
        <div class="bonuses__title mb-4">Catalogo premi</div>
        <div
          class="bonuses__item catalog"
          @click="openModalForPendingUser((typeModal = 'catalog'))"
        >
          <h3 class="item-info">
            vai al catalogo e scopri tutti i premi pensati per te
          </h3>
        </div>
      </div>
      <div>
        <div class="bonuses__title mb-4">Bonus per te</div>
        <div
          class="bonuses__item bonus"
          @click="openModalForPendingUser((typeModal = 'bonus'))"
        >
          <h3 class="item-info">inserisci qui il codice ricevuto</h3>
        </div>
      </div>
      <div>
        <div class="bonuses__title mb-4">Codice Amico Greeners</div>
        <div
          class="bonuses__item greeners"
          @click="openModalForPendingUser((typeModal = 'greeners'))"
        >
          <h3 class="item-info">Fai crescere la community!</h3>
        </div>
      </div>
    </v-row>
    <div class="container-links">
      <div>
        <div class="bonuses__title mb-4">Informazioni utili</div>
        <div class="links d-flex">
          <div
            class="links__item d-flex"
            @click="
              trackTapEvent({
                name: 'greeners_diaryRules_tap',
                params: {
                  client_code: mainClientLoyaltySelected.clientOwnerCode,
                  client_type: mainClientLoyaltySelected.clientOwnerType,
                  isPending: isPending
                }
              }),
                $router.push({ name: 'rulesPage' })
            "
          >
            <img class="img" src="@/assets/icons/ic_bill.svg" />
            <span class="primary--text">Regolamento</span>
            <img
              class="arrow ml-auto"
              :src="require('@/assets/icons/chevron_rx.svg')"
            />
          </div>
          <div class="links__item d-flex">
            <a
              :href="linkStatic.link"
              target="_blank"
              download
              class="links__item d-flex"
              @click="
                trackTapEvent({
                  name: 'greeners_rulesInfo_tap',
                  params: {
                    client_code: mainClientLoyaltySelected.clientOwnerCode,
                    client_type: mainClientLoyaltySelected.clientOwnerType,
                    isPending: isPending
                  }
                })
              "
            >
              <img
                class="img"
                :src="require('@/assets/icons/' + linkStatic.filename)"
              />
              <span class="primary--text">{{ linkStatic.text }}</span>
              <img
                class="arrow ml-auto"
                :src="require('@/assets/icons/chevron_rx.svg')"
              />
            </a>
          </div>
          <div
            class="links__item d-flex"
            @click="
              trackTapEvent({
                name: 'greeners_diaryLevelGuide_tap ',
                params: {
                  client_code: mainClientLoyaltySelected.clientOwnerCode,
                  client_type: mainClientLoyaltySelected.clientOwnerType,
                  isPending: isPending
                }
              }),
                (levelsDialog = true)
            "
          >
            <img class="img" src="@/assets/icons/info.svg" />
            <span class="primary--text">Guida ai livelli</span>
            <img
              class="arrow ml-auto"
              :src="require('@/assets/icons/chevron_rx.svg')"
            />
          </div>
        </div>
      </div>
    </div>
    <BalanceUserPending
      v-model="dialogPendingUser"
      :dialog="dialogPendingUser"
      @closeModal="dialogPendingUser = false"
      :type-modal="typeModal"
    />
    <CfpDialog
      :showFootprint="showFootprint"
      :cfpUrl="cfpUrl"
      :resetPropsCfp="resetPropsCfp"
    />
    <NewEditionLoyaltyDialogVue
      v-model="newEdtionGreeners"
      :dialog="newEdtionGreeners"
      @goToNewEditionLoyalty="goToNewEditionLoyalty"
    />
  </layout>
</template>

<script>
import { ENV_CONFIGS } from '@/js/configs.js'
import constants from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { Carousel, Slide } from 'vue-carousel'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Layout from './Layout'
import ScaleLivelli from './components/scaleLevelli'

import { APP_SECTION_MAPPING } from '../../js/constants'

import CfpDialog from '@/components/cfp/CfpDialog'
// import TcVars from '@/components/tcTracker/TcVars'
import NewEditionLoyaltyDialogVue from '@/components/menu/NewEditionLoyaltyDialog.vue'
import SpeedTest from '@/components/speedTest/'
import LevelGuides from './components/LevelGuides.vue'
import StatusIcon from './components/statusIcon'

import { axiosAdvice } from '@/js/axiosInstances.js'

import { dynamicSort } from '@/js/utils.js'
import { axiosCMS } from '../../js/axiosInstances'

import { eventBus } from '@/main'
import IconProfileNoPhoto from '@assets/icons/avatar.svg'
import BalanceUserPending from '../../components/greeners/BalanceUserPending.vue'
import { AlertService } from '../../js/service/alertService'

export default {
  name: 'loyaltyPage',
  components: {
    Layout,
    Carousel,
    Slide,
    StatusIcon,
    LevelGuides,
    ScaleLivelli,
    // TcVars,
    CfpDialog,
    SpeedTest,
    NewEditionLoyaltyDialogVue,
    BalanceUserPending
  },
  beforeRouteUpdate(next) {
    if (this.loyaltyResToFix && this.loyaltyBusToFix) {
      next({ name: 'home', query: { isMultiMainClients: true } })
    } else {
      next()
    }
  },
  async beforeMount() {
    await this.getSupplies()
    // mock !== for test loylty with user FFTH3
    // L7 user Pending can log in in Greeners
    // if (this.memberStatus.statusGreeners === 'Pending') {
    //   this.$router.push({ name: 'home', params: { showModalPending: true } })
    // }
    if (!this.showLoyalty()) {
      this.$router.push({ name: 'home' })
    }
    this.init()
  },
  mounted() {
    this.checkIsOldCLient()
    // this.checkRegisteredEdition()
  },
  watch: {
    selectClientDialog(val) {
      if (this.loyaltyClients.length > 1) {
        this.checkMemberStatus({
          ext_uuid: this.loyaltyClients[1].clientOwnerCode
        }).then((resp) => {
          if (!resp.user_registered) {
            this.labelAddUser = 'Aggiungi un profilo'
            this.subLabelAddUser =
              'Selezionando il profilo sottostante entri a far parte di Greeners anche con questo profilo. Qual è il vantaggio? Potrai unire i Green Coins accumulati con entrambi i profili!'
          } else {
            this.labelAddUser = ''
            this.subLabelAddUser = ''
          }
        })
      }
    },
    mainClientLoyaltySelected(val) {
      this.checkIsOldCLient()
    }
  },
  data() {
    return {
      IconProfileNoPhoto,
      CmsUrl: ENV_CONFIGS.CMS_CDN_BASE_URL,
      levelsDialog: false,
      missionLoaded: false,
      missions: [
        {
          name: 'Carbon FootPrint',
          filename: 'img_erbolario.png',
          route: 'survey'
        },
        { name: 'Video', filename: 'img_erbolario.png', route: 'videoPage' },
        {
          name: 'Carica foto',
          filename: 'img_erbolario.png',
          route: 'uploadPhoto'
        },
        {
          name: 'VidegetPromoso',
          filename: 'img_erbolario.png',
          route: 'videoPage'
        }
      ],
      linkStatic: { text: 'Informativa', filename: 'ic_bill.svg', link: null },
      nextLabel: '<div class="icon next"/>',
      prevLabel: '<div class="icon prev"/>',
      showFootprint: false,
      cfpUrl: '',
      hasCfp: true,
      cardDialogContent: null,
      cardDialog: false,
      APP_SECTION_MAPPING: APP_SECTION_MAPPING,
      SUPPLY_STATUS: constants.SUPPLY_STATUS,
      SERVICE_TYPE: constants.SERVICE_TYPE,
      selectClientDialog: false,
      labelAddUser: '',
      subLabelAddUser: '',
      haveOtherUserToAdd: false,
      newEdtionGreeners: false,
      dialogPendingUser: false,
      typeModal: '',
      loadedGreeners: false,
      isPending: ''
    }
  },
  async created() {
    try {
      let dwUrl = await axiosCMS.get(
        '/DocumentFile/informativa-greeners-sorgenia-settembre-2021?_format=hal_json'
      )
      this.linkStatic.link = ENV_CONFIGS.DYNAMIC_LINKS.informativaGreeners
      this.hasCfp = await this.getLastCfpValue({
        clientOwnerCode: this.clientDetails.clientCode
      })
    } catch (err) {
      if (err.statusCode && err.statusCode >= 400) {
        throw Error('SERVER_ERROR')
      } else if (err.message) {
        throw err
      }
    }
  },
  computed: {
    ...mapGetters('account', [
      'mainClientLoyaltyRes',
      'oldestLoyaltyRes',
      'mainClientLoyaltyBus',
      'oldestLoyaltyBus',
      'loyaltyResToFix',
      'loyaltyBusToFix',
      'mainClientLoyaltySelected',
      'accountPhoto',
      'clientDetails',
      'mainClient',
      'accountProperties',
      'promos',
      'numberMessage',
      'listMainClientLoyalty'
    ]),
    ...mapGetters('loyalty', [
      'memberBalance',
      'clientLevel',
      'memberStatus',
      'catalog_iwwl',
      'catalog_iw',
      'availableAwards',
      'transactionList',
      'surveys',
      'wekitMap',
      'videoData',
      'photoData',
      'quiz',
      'loyaltyNews',
      'awardCounter',
      'IDENTITY_BALANCE_ERROR',
      'IDENTITY_LEVEL_ERROR',
      'TRANSACTION_LIST_ERROR'
    ]),
    ...mapGetters('supply', ['supplies', 'isNotAllPlacet']),
    ...mapGetters('session', ['token']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Diario Greeners'
        }
      ]

      return res
    },
    accountPhotoSrc() {
      return URL.createObjectURL(this.accountPhoto)
    },
    loyaltyClients() {
      let clients = []
      if (
        (this.mainClientLoyaltyRes || this.oldestLoyaltyRes) &&
        (this.mainClientLoyaltyBus || this.oldestLoyaltyBus)
      ) {
        let mainRes = this.mainClientLoyaltyRes || this.oldestLoyaltyRes
        let mainBus = this.mainClientLoyaltyBus || this.oldestLoyaltyBus
        let mainResActive =
          this.supplies.filter(
            (sup) =>
              sup.clientOwnerCode === mainRes.clientOwnerCode &&
              (sup.supplyState === this.SUPPLY_STATUS.ACTIVE ||
                sup.supplyState === this.SUPPLY_STATUS.ACTIVATING)
          ).length > 0
        let mainBusActive =
          this.supplies.filter(
            (sup) =>
              sup.clientOwnerCode === mainBus.clientOwnerCode &&
              (sup.supplyState === this.SUPPLY_STATUS.ACTIVE ||
                sup.supplyState === this.SUPPLY_STATUS.ACTIVATING)
          ).length > 0
        if (mainBusActive) {
          if (this.mainClientLoyaltyRes) {
            clients.push(this.mainClientLoyaltyRes)
          } else if (this.oldestLoyaltyRes) {
            let label = this.oldestLoyaltyRes
            if (this.oldestLoyaltyRes.isMultiResClient) {
              label.firstName = 'Profilo'
              label.lastName = 'Residenziale'
            }
            clients.push(label)
          }
        }
        if (mainResActive) {
          if (this.mainClientLoyaltyBus) {
            clients.push(this.mainClientLoyaltyBus)
          } else if (this.oldestLoyaltyBus) {
            let label = this.oldestLoyaltyBus
            if (this.oldestLoyaltyBus.isMultiBussClient) {
              label.businessName = 'Profilo Business'
            }
            clients.push(label)
          }
        }
      }
      return clients.sort((a) => {
        if (
          a.clientOwnerCode === this.mainClientLoyaltySelected.clientOwnerCode
        ) {
          return -1
        } else {
          return 1
        }
      })
    },
    greeners() {
      let items = []

      if (this.catalog_iw.length) {
        if (this.availableAwards.iw === '002') {
          // priority 2
          const descr = this.catalog_iw[0].attributes.title
            ? this.catalog_iw[0].attributes.title
            : ''
          items.push({
            filename: this.catalog_iw[0].attributes.images.data[0].image_small,
            class: '',
            text: `<h4 class="font-style-card">Ritira il tuo premio:<br/><span style="color: #12256a;font-weight: bold">${descr}</span></h4>`,
            to: 'giftsMainPage'
          })
        }
      }

      if (this.catalog_iwwl.length) {
        let curDate = new Date()
        const expDate = this.catalog_iwwl[0].attributes.expiration_date
          ? this.catalog_iwwl[0].attributes.expiration_date
          : new Date('2099/01/01')
        if (
          !this.memberStatus.played_welcome_iw &&
          curDate.getTime() < expDate.getTime()
        ) {
          // priority 1
          const descr = this.catalog_iwwl[0].attributes.title
            ? this.catalog_iwwl[0].attributes.title
            : ''
          items.push({
            filename:
              this.catalog_iwwl[0].attributes.images.data[0].image_small,
            class: '',
            text: `<h4 class="font-style-card">Scopri se hai vinto:<br/><span style="color: #12256a;font-weight: bold">${descr}</span></h4>`,
            to: 'joinTeamPage'
          })
        }
        if (this.availableAwards.welcome_iw === '002') {
          // priority 2
          const descr = this.catalog_iwwl[0].attributes.title
            ? this.catalog_iwwl[0].attributes.title
            : ''
          items.push({
            filename:
              this.catalog_iwwl[0].attributes.images.data[0].image_small,
            class: '',
            text: `<h4 class="font-style-card">Ritira il tuo premio:<br/><span style="color: #12256a;font-weight: bold">${descr}</span></h4>`,
            to: 'giftsMainPage'
          })
        }
      }

      // if (this.filteredTransactionList.length) {
      //   items.push({
      //     // priority 5
      //     filename: require('@/assets/greeners/' + 'svg/greeners.svg'),
      //     height: 80,
      //     class: '',
      //     text: `<h4 class="font-style-card ml-2">Con l'ultima Missione<br/>hai ottenuto:<br/><span style="color: #12256a;font-weight: bold">${this.filteredTransactionList[0].points} Green Coins</span></h4>`,
      //     to: 'coinsPage'
      //   })
      // }

      if (this.awardCounter > 0) {
        // priority 3
        items.push({
          filename: require('@/assets/greeners/' + 'ic-awards.svg'),
          text: `<h4 class="font-style-card ml-2">Hai un premio<br/>che ti aspetta! <strong>Scoprilo</strong></h4>`,
          to: 'giftsMainPage'
          // to: 'welcomeGift'
        })
      }

      const levels = ['lev1', 'lev2', 'lev3', 'lev4']
      let level = ''
      levels.forEach((item) => {
        if (this.availableAwards[item] === '002') {
          level = item
        }
      })
      if (level !== '') {
        // priority 4
        items.push({
          filename: require('@/assets/greeners/svg/' +
            this.getLevelInfo(this.translateLevelName(level)).image),
          height: 80,
          class: '',
          text: `<h4 class="font-style-card ml-2">Hai raggiunto un<br/>nuovo livello!<br/><span style="color: #12256a;font-weight: bold">Richiedi il premio</span></h4>`,
          to: 'promote'
        })
      }
      if (this.loyaltyNews.length > 0) {
        this.loyaltyNews.forEach((news) => {
          let newsStartData = new Date(news.start_date)
          let newsEndDate = new Date(news.end_date)
          let today = new Date()
          if (
            today >= newsStartData &&
            today <= newsEndDate &&
            (news.current_client_type === '' ||
              this.currentClientTypes.some((item) =>
                news.current_client_type.includes(item.toUpperCase())
              ))
          ) {
            items.unshift({
              filename: require('@/assets/greeners/' +
                this.getCategoryImg(news.field_category || '')),
              height: 96,
              class: '',
              text: `<h4 class="ml-2 font-style-card"><strong>${news.title}</strong></h4>`,
              id: news.id,
              popupText: news.text,
              popupTitle: news.title,
              popupImg: news.image_web || news.image,
              popupCta: news.cta_label,
              popupAction: news.app_section_section,
              actionType: news.cta_action_type,
              popupUrl: news.cta_action_url,
              clientType: news.current_client_type,
              to: '',
              hasPopup: true,
              weight: Number(news.weight)
            })
          }
        })
        items.sort(dynamicSort('-weight'))
      }
      if (this.accountProperties.type === 'MIXED' && this.haveOtherUserToAdd) {
        items.unshift({
          filename: require('@/assets/greeners/svg/' + 'greeners.svg'),
          height: 96,
          class: '',
          text: `<h4 class="font-style-card ml-2">Aggiungi un altro profilo e <span style="color: #12256a;font-weight: bold">accumula Green Coins</span></h4>`,
          to: '',
          hasSelectClient: true
        })
      }

      return items
    },
    levelIcon() {
      return this.getLevelInfo(this.clientLevel.level_name)
    },
    // filteredTransactionList () {
    //   const allowedActions = ['11', '31', '62', '92', '51', '140', '1211', '1507', '1504', '1541', '1542', '1543', '1544', '1545', '1506', '1561', '1562', '1563', '1564', '1565', '1505', '1551', '1552', '1553', '1554', '1555', '1407', '1406', '1503', '1531', '1532', '1533', '1534', '1535']
    //   return this.transactionList.filter((tr) => {
    //     return allowedActions.includes(tr.action) && tr.points > 0
    //   })
    // },
    prizesToRetrive() {
      const levels = ['lev1', 'lev2', 'lev3', 'lev4']
      var levelPrizeCounter = Object.keys(this.availableAwards).filter(
        (el) => this.availableAwards[el] === '002' && levels.includes(el)
      ).length
      var welcomeIWCounter = this.availableAwards.welcome_iw === '002' ? 1 : 0
      var iWCounter = this.availableAwards.iw === '002' ? 1 : 0
      return (
        levelPrizeCounter + this.awardCounter + welcomeIWCounter + iWCounter
      )
    },
    availableMissions() {
      let filterMissionByUserTypeAndCommodity = (mission) => {
        return (
          this.currentClientTypes.some(
            (item) =>
              mission.current_client_type === '' ||
              mission.current_client_type
                .toUpperCase()
                .includes(item.toUpperCase())
          ) &&
          this.currentClientCommodity.some(
            (item) =>
              mission.active_commodities === '' ||
              mission.active_commodities
                .toUpperCase()
                .includes(item.toUpperCase())
          )
        )
      }
      let filterMissionByLevels = (mission) => {
        let levelMinMission = 1
        let levelMaxMission = 5
        if (!mission.level_min) mission.level_min = levelMinMission
        if (!mission.level_max) mission.level_max = levelMaxMission
        let clientLevel = 1
        switch (this.clientLevel.level_name) {
          case 'LEVEL0':
            clientLevel = 1
            break
          case 'LEVEL1':
            clientLevel = 2
            break
          case 'LEVEL2':
            clientLevel = 3
            break
          case 'LEVEL3':
            clientLevel = 4
            break
          case 'LEVEL4':
            clientLevel = 5
            break
        }
        return (
          clientLevel >= parseInt(mission.level_min) &&
          clientLevel <= parseInt(mission.level_max)
        )
        // return true
      }

      let listOfIds = this.transactionList
        .filter((el) => el.detail1)
        .map((el) => el.detail1)

      let surveys = this.surveys.filter(
        (mission) =>
          listOfIds.indexOf(mission.id) === -1 &&
          filterMissionByUserTypeAndCommodity(mission) &&
          filterMissionByLevels(mission)
      )
      let videos = this.videoData.filter(
        (mission) =>
          listOfIds.indexOf(mission.id) === -1 &&
          filterMissionByUserTypeAndCommodity(mission) &&
          filterMissionByLevels(mission)
      )
      let photos = this.photoData.filter(
        (mission) =>
          listOfIds.indexOf(mission.id) === -1 &&
          filterMissionByUserTypeAndCommodity(mission) &&
          filterMissionByLevels(mission)
      )
      let quiz = this.quiz.filter(
        (mission) =>
          listOfIds.indexOf(mission.id) === -1 &&
          filterMissionByUserTypeAndCommodity(mission) &&
          filterMissionByLevels(mission)
      )

      let availableMissions = surveys.concat(videos).concat(photos).concat(quiz)
      let sortedMissions = availableMissions
        .sort((a, b) => a.weight - b.weight)
        .reverse()

      if (sortedMissions.length === 0) return sortedMissions

      return sortedMissions.filter((mission) => {
        let startDate = new Date(mission.start_date)
        let endDate = new Date(mission.end_date)
        let now = new Date()
        if (startDate <= now && now <= endDate) {
          const checkNew = new Date()
          if (startDate >= checkNew.setDate(now.getDate() - 10)) {
            mission['badge'] = 'NUOVO'
          }
          const checkDeadline = endDate
          if (now >= checkDeadline.setDate(endDate.getDate() - 10)) {
            mission['badge'] = 'IN SCADENZA'
          }
          if (mission.wekit_event_id === '1500') {
            switch (mission.wekit_action_id) {
              case '1507':
                mission.second_app_section_label = 'Carbon footprint'
                break
              case '1504':
              case '1541':
              case '1542':
              case '1543':
              case '1544':
              case '1545':
                mission.second_app_section_label = 'Survey'
                break
              case '1506':
              case '1561':
              case '1562':
              case '1563':
              case '1564':
              case '1565':
                mission.second_app_section_label = 'Foto'
                break
              case '1505':
              case '1551':
              case '1552':
              case '1553':
              case '1554':
              case '1555':
                mission.second_app_section_label = 'Video'
                break
              case '1503':
              case '1531':
              case '1532':
              case '1533':
              case '1534':
              case '1535':
                mission.second_app_section_label = 'Quiz'
                break
              default:
                mission.second_app_section_label = 'Vai al catalogo'
                break
            }
          } else if (mission.wekit_event_id === '7') {
            if (mission.wekit_action_id === '140') {
              mission.second_app_section_label =
                'Iniziativa natalizia – The Garden'
            }
          }
          return mission
        }
      })
    },
    currentClientTypes() {
      return [this.mainClientLoyaltySelected?.clientOwnerType]
    },
    currentClientCommodity() {
      let commodities = []
      let selectedCode = this.mainClientLoyaltySelected?.clientOwnerCode
      if (selectedCode) {
        let hasEle =
          this.supplies.filter(
            (item) =>
              item.clientOwnerCode === selectedCode &&
              item.serviceType === this.SERVICE_TYPE.ELE &&
              (item.supplyState === (this.SUPPLY_STATUS?.ACTIVE || 'active') ||
                item.supplyState ===
                  (this.SUPPLY_STATUS?.ACTIVATING || 'activating'))
          ).length > 0
        let hasGas =
          this.supplies.filter(
            (item) =>
              item.clientOwnerCode === selectedCode &&
              item.serviceType === this.SERVICE_TYPE.GAS &&
              (item.supplyState === (this.SUPPLY_STATUS?.ACTIVE || 'active') ||
                item.supplyState ===
                  (this.SUPPLY_STATUS?.ACTIVATING || 'activating'))
          ).length > 0
        let hasFibra =
          this.supplies.filter(
            (item) =>
              item.clientOwnerCode === selectedCode &&
              item.serviceType === this.SERVICE_TYPE.FIBER &&
              (item.supplyState === (this.SUPPLY_STATUS?.ACTIVE || 'active') ||
                item.supplyState ===
                  (this.SUPPLY_STATUS?.ACTIVATING || 'activating'))
          ).length > 0
        if (hasEle) commodities.push('ELE')
        if (hasFibra) commodities.push('FIBRA')
        if (hasGas) commodities.push('GAS')
      }

      return commodities
    }
  },
  methods: {
    ...mapActions('loyalty', [
      'newTransaction',
      'getTransactionsListNewEdition',
      'getBalance',
      'getClientLevel',
      'getMemberStatus',
      'getCatalog',
      'getAvailableAwards',
      'getTransactionsList',
      'getSurveys',
      'getWekitMap',
      'getVideo',
      'getPhoto',
      'getQuiz',
      'getLoyaltyNews',
      'checkMemberStatus',
      'setLoyaltyStatus',
      'requesteddAwardCounter',
      'joinTeam'
    ]),
    ...mapActions('account', [
      'getMainClient',
      'getDetailForClient',
      'getPromos',
      'getAccountPhoto'
    ]),
    ...mapActions('cfp', ['getLastCfpValue']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('account', ['setMainClientLoyaltySelected']),
    ...mapMutations('loyalty', ['resetState']),
    ...mapActions('supply', ['getSupplies']),
    pageChange(val) {
      this.trackTapEvent({
        name: 'greeners_diaryHighlightCard_scroll',
        params: {
          client_code: this.mainClientLoyaltySelected.clientOwnerCode,
          client_type: this.mainClientLoyaltySelected.clientOwnerType
        }
      })
    },
    showLoyalty() {
      let listMain = this.listMainClientLoyalty.map((m) => m.clientOwnerCode)
      return (
        this.isNotAllPlacet &&
        this.supplies.filter(
          (sup) =>
            (sup.supplyState === (this.SUPPLY_STATUS?.ACTIVE || 'active') ||
              sup.supplyState ===
                (this.SUPPLY_STATUS?.ACTIVATING || 'activating')) &&
            listMain.includes(sup.clientOwnerCode)
        ).length > 0
      )
    },
    openAlert() {
      eventBus.$emit('greeners:error', { type: 'IDENTITY_STATUS_ERROR' })
    },
    init() {
      this.getAccountPhoto()
      this.getMainClient().then((resp) => {
        if (
          this.accountProperties.type === 'MIXED' &&
          this.loyaltyResToFix &&
          this.loyaltyBusToFix
        ) {
          this.$router.push({
            name: 'home',
            query: { isMultiMainClients: true }
          })
        } else if (
          this.accountProperties.type === 'RES' &&
          this.loyaltyResToFix
        ) {
          this.$router.push({
            name: 'home',
            query: { isMultiMainClients: true }
          })
        } else if (
          this.accountProperties.type === 'BUS' &&
          this.loyaltyBusToFix
        ) {
          this.$router.push({
            name: 'home',
            query: { isMultiMainClients: true }
          })
        }

        if (this.mainClientLoyaltySelected) {
          resp = this.mainClientLoyaltySelected
        }

        axiosAdvice.defaults.baseURL =
          resp.clientOwnerType === 'BUS'
            ? ENV_CONFIGS.WEKIT_BASE.replace('residential', 'business')
            : ENV_CONFIGS.WEKIT_BASE.replace('business', 'residential')

        this.getDetailForClient({ clientOwnerCode: resp.clientOwnerCode }).then(
          async (resp) => {
            this.requesteddAwardCounter()
            this.getSurveys()
            this.getVideo()
            this.getPhoto()
            this.getQuiz()
            this.getLoyaltyNews()
            this.getPromos()
            this.getBalance({ ext_uuid: resp.clientCode })
            this.getMemberStatus({ ext_uuid: resp.clientCode }).then((resp) => {
              // check if user is pending
              this.isPending = this.memberStatus.statusGreeners === 'Pending'
              if (resp.user_registered === null) {
                GenErr.showErrorMessage()
                this.$router.push({ name: 'home' })
              }
              if (!resp.user_registered) {
                // if user Not registered, call function to register it
                this.join()
              }
            })
            this.getClientLevel({ ext_uuid: resp.clientCode })
            await this.getCatalog({
              codiceCliente: resp.clientCode,
              catalog_name: 'IW'
            })
            await this.getCatalog({
              codiceCliente: resp.clientCode,
              catalog_name: 'IW-WL'
            })
            await this.getCatalog({
              catalog_name:
                this.mainClientLoyaltySelected.clientOwnerType === 'RES'
                  ? 'COLLECTION'
                  : 'COLLECTION-BS',
              codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode
            })
            await this.getAvailableAwards({ ext_uuid: resp.clientCode })
            await this.getTransactionsList({ ext_uuid: resp.clientCode })
            await this.getWekitMap()
            this.missionLoaded = true
            this.loadedGreeners = true
          }
        )
      })

      if (this.loyaltyClients.length > 1) {
        this.checkMemberStatus({
          ext_uuid: this.loyaltyClients[1].clientOwnerCode
        }).then((resp) => {
          if (!resp.user_registered) {
            this.haveOtherUserToAdd = true
          } else {
            this.haveOtherUserToAdd = false
          }
        })
      } else {
        this.haveOtherUserToAdd = false
      }
    },
    checkIsOldCLient() {
      this.getTransactionsListNewEdition({
        ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
      }).then((resp) => {
        let edition = resp.wekit_data.filter(
          (t) => t.action === '31' || t.action === '32'
        )
        if (edition.length === 0 || edition.length > 1) {
          this.newEdtionGreeners = false
        } else if (edition.length === 1) {
          this.newEdtionGreeners = edition[0].action === '31'
        }
      })
    },
    join() {
      this.showProgress = true
      const {
        clientCode,
        name,
        surname,
        contactMail,
        phone,
        mobilePhone,
        birthDate,
        residentialProvince,
        fiscalCode
      } = this.clientDetails

      if (!contactMail) {
        this.showAccountDataDialog = true
        return
      }

      let date
      if (!birthDate) {
        date = ''
      } else if (!birthDate.match(/\d{4}-\d{2}-\d{2}/g)) {
        let re = /(\d{2})\/(\d{2})\/(\d{4})/
        date = birthDate.replace(re, '$3-$2-$1')
      } else date = birthDate
      let body = {
        codiceCliente: clientCode,
        first_name: name,
        last_name: surname,
        email: contactMail,
        phone: phone || mobilePhone,
        fiscal_code: fiscalCode,
        birthday: date,
        province: residentialProvince,
        cfp: this.cfp
      }

      this.joinTeam(body).catch((err) => {
        console.error(err)
        this.trackTapEvent('greeners_error_view')
        AlertService(
          'Qualcosa è andato storto',
          'Si è verificato un errore inatteso, riprova tra qualche minuto. Potresti non esserti iscritto correttamente',
          false,
          'Chiudi',
          '#'
        )
      })
    },

    async goToNewEditionLoyalty() {
      this.newEdtionGreeners = false
      await this.newTransaction({ event: '3', action: '32', detail1: '' })
    },

    async setClientSelected(client) {
      this.trackTapEvent({
        name: 'greeners_chooseClient_tap',
        params: {
          client_code: this.mainClientLoyaltySelected.clientOwnerCode,
          client_type: this.mainClientLoyaltySelected.clientOwnerType
        }
      })
      this.getMemberStatus({ ext_uuid: client.clientOwnerCode }).then(
        (resp) => {
          if (resp.user_registered === null) {
            GenErr.showErrorMessage()
            this.$router.push({ name: 'home' })
          }
          if (!resp.user_registered) {
            this.selectClientDialog = false
            // if user already register and then unsubcribed re join on Greeners
            this.setMainClientLoyaltySelected(client)
            this.getDetailForClient({ clientOwnerCode: client.clientOwnerCode })
          } else {
            this.resetState()
            this.setMainClientLoyaltySelected(client)
            axiosAdvice.defaults.baseURL =
              client.clientOwnerType === 'BUS'
                ? ENV_CONFIGS.WEKIT_BASE.replace('residential', 'business')
                : ENV_CONFIGS.WEKIT_BASE.replace('business', 'residential')
            this.selectClientDialog = false
            this.init()
          }
        }
      )
    },
    openDialogClientSelected() {
      if (this.loyaltyClients.length > 1) {
        this.selectClientDialog = true
      }
    },
    missionValue(actionId, eventId) {
      const targetMission = this.wekitMap.filter(
        (mission) =>
          mission.action_id === actionId && mission.event_id === eventId
      )
      if (targetMission.length) {
        return targetMission[0].points
      } else {
        return 0
      }
    },
    go(item) {
      if (item.hasLink) {
        window.open(item.to)
      } else if (item.hasPopup) {
        this.cardDialog = true
        this.cardDialogContent = item
      } else if (item.hasSelectClient) {
        this.openDialogClientSelected()
      } else {
        this.$router.push({ name: item.to })
      }
    },
    missionType(missionType) {
      switch (missionType) {
        case 'SURVEY':
          return 'survey'
        case 'VIDEO':
          return 'videoPage'
        case 'FOTO':
          return 'uploadPhoto'
        case 'QUIZ':
          return 'quiz'
        default:
          return 'loyaltyPage'
      }
    },
    goToRouteFromItem(to) {
      if (to === 'joinTeamPage') {
        this.$store.commit('loyalty/setWelcomeIWLater', true)
        this.$router.push({ name: to, query: { returnIW: true } })
      } else {
        this.$router.push({ name: to })
      }
    },
    getLevelInfo(level) {
      switch (level) {
        case 'LEVEL0':
          return { image: 'beginner.svg', level: 'Seme' }
        case 'LEVEL1':
          return { image: 'follower.svg', level: 'Germoglio' }
        case 'LEVEL2':
          return { image: 'friend.svg', level: 'Piantina' }
        case 'LEVEL3':
          return { image: 'influencer.svg', level: 'Arbusto' }
        case 'LEVEL4':
          return { image: 'ambassador.svg', level: 'Quercia' }
        default:
          return { image: 'beginner.svg', level: 'Seme' }
      }
    },
    translateLevelName(levelShortName) {
      switch (levelShortName) {
        case 'lev1':
          return 'LEVEL1'
        case 'lev2':
          return 'LEVEL2'
        case 'lev3':
          return 'LEVEL3'
        case 'lev4':
          return 'LEVEL4'
      }
    },
    openFootprint() {
      this.trackTapEvent({
        name: 'greeners_diaryMissionCard_tap',
        params: {
          client_code: this.mainClientLoyaltySelected.clientOwnerCode,
          client_type: this.mainClientLoyaltySelected.clientOwnerType,
          mission_id: 'carbonFootprint',
          isPending: this.isPending
        }
      })
      this.cfpUrl = `${ENV_CONFIGS.CFP_BASE_URL}/?clientCode=${
        this.mainClientLoyaltySelected.clientOwnerCode
      }&clientType=${this.mainClientLoyaltySelected.clientOwnerType}&name=${
        this.mainClientLoyaltySelected.firstName
      }&surname=${this.mainClientLoyaltySelected.lastName}&hasLightContracts=${
        this.accountProperties.hasLightActivating ||
        this.accountProperties.hasLightActive
      }&token=${this.token}&accountType=${
        this.accountProperties.type
      }&isWeb=true`
      this.showFootprint = true
    },
    openModalForPendingUser(type) {
      switch (type) {
        case 'saldo':
          this.trackTapEvent({
            name: 'greeners_diaryPoints_tap',
            params: {
              client_code: this.mainClientLoyaltySelected.clientOwnerCode,
              client_type: this.mainClientLoyaltySelected.clientOwnerType,
              isPending: this.isPending
            }
          })
          this.isPending
            ? (this.dialogPendingUser = true)
            : this.$router.push({ name: 'coinsPage' })
          break
        case 'bonus':
          this.trackTapEvent({
            name: 'greeners_diaryIWCard_tap',
            params: {
              client_code: this.mainClientLoyaltySelected.clientOwnerCode,
              client_type: this.mainClientLoyaltySelected.clientOwnerType,
              isPending: this.isPending
            }
          })
          this.isPending
            ? (this.dialogPendingUser = true)
            : this.$router.push({ name: 'giftCode' })
          break
        case 'greeners':
          this.trackTapEvent({
            name: 'greeners_diaryGGG_tap',
            params: {
              client_code: this.mainClientLoyaltySelected.clientOwnerCode,
              client_type: this.mainClientLoyaltySelected.clientOwnerType,
              isPending: this.isPending
            }
          })
          this.isPending
            ? (this.dialogPendingUser = true)
            : this.$router.push({ name: 'GreenersFriendCode' })
          break
        case 'catalog':
          this.trackTapEvent({
            name: 'greeners_diaryPrizesCatalogue_tap',
            params: {
              client_code: this.mainClientLoyaltySelected.clientOwnerCode,
              client_type: this.mainClientLoyaltySelected.clientOwnerType,
              isPending: this.isPending
            }
          })
          this.$router.push({ name: 'catalogue' })
          break
        default:
          break
      }
    },
    resetPropsCfp() {
      this.showFootprint = false
      this.cfpUrl = ''
      this.getBalance({
        ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
      })
      this.getSurveys()
      this.getVideo()
      this.getPhoto()
      this.getQuiz()
      this.getPromos()
      this.getClientLevel({
        ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
      })
      this.getCatalog({
        codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode,
        catalog_name: 'IW-WL'
      })
      this.getCatalog({
        codiceCliente: this.mainClientLoyaltySelected.clientOwnerCode,
        catalog_name: 'IW'
      })
      this.getAvailableAwards({
        ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
      })
      this.getTransactionsList({
        ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
      })
      this.getWekitMap()
    },
    getCategoryImg(img) {
      switch (img) {
        case 'News':
          return 'ic-megaphone.svg'
        case 'Premialità':
          return 'ic-awards.svg'
        case 'Green Coins':
          return 'ic-coins.svg'
        case 'Comunicazioni':
          return 'ic-comunication.svg'
        case 'Tips':
          return 'ic-tips.svg'
        case 'Feedback':
          return 'ic-community.svg'
        default:
          return 'svg/greeners.svg'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.contentAccount {
  .avatar {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 48px;
    z-index: 2;
    .photo {
      border-radius: 50%;
      width: 48px;
      height: 48px;
    }
  }
  position: relative;
}

.v-dialog__content {
  .dialogClient {
    color: black;
    background: white;
    padding: 55px 45px 45px 55px;
    border-radius: 40px;
    position: relative;

    h4 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 24px;
      letter-spacing: 1px;
      color: black;
    }
    .box {
      width: 432px;
      height: 57px;
      border-radius: 20px;
      border: 1px solid #dfe4ef;
      padding: 0 24px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .client {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        .photo {
          border-radius: 50%;
          border: white 3px solid;
          width: 48px;
          height: 48px;
        }

        h5 {
          font-weight: 700;
          font-size: 18px;
          letter-spacing: 0.8px;
          color: $srg-blue;

          &.RES::before {
            width: 20px;
            content: url('../../assets/icons/ic-owner-res.svg');
            margin-right: 16px;
          }
          &.BUS::before {
            width: 20px;
            content: url('../../assets/icons/ic-business.svg');
            margin-right: 16px;
          }
        }
      }
      &::after {
        content: url('../../assets/icons/chevron_rx.svg');
      }
    }
    .divider {
      font-size: 20px;
      font-weight: 500;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #ccd0e1;
    }

    .close {
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;

      &.preview {
        position: initial;
      }
    }
  }
}

.link {
  color: $srg-green;
  font-weight: bold;
}

.text--green {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: $srg-green;
}

.sub-title {
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: $srg-blue;
}

h4 {
  font-size: 20px;
  line-height: 26px;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 1.2;
}

.dashboard {
  margin-top: 48px;
  &__levels {
    background: #f8f9fc;
    width: 360px;
    padding: 24px 24px 0 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .bigTitle {
      font-size: 28px;
      letter-spacing: 1.2px;
      color: #6e7689;
    }
    .errorMessage {
      font-size: 16px;
      font-weight: 700;
      color: #2c313a;
      line-height: 19px;
      letter-spacing: 0.8px;
      border-bottom: 1px solid #eaeef5;
      margin-bottom: 24px;
      margin-top: 4px;
      padding-bottom: 24px;
      width: 100%;
      text-align: center;
    }
    h5 {
      letter-spacing: 1.2px;
      text-transform: uppercase;
      color: black;
      letter-spacing: 1px;
    }
    .detailsLevels {
      width: 291px;
      border-bottom: 1px solid #eaeef5;
      justify-content: center;
      margin-bottom: 24px;
      h2 {
        letter-spacing: 1.2px;
      }
      h6 {
        letter-spacing: 1px;
      }
    }
    .detailsOther {
      font-size: 18px;
      color: $srg-blue;
      margin-bottom: 24px;
      letter-spacing: 1.2px;
      cursor: pointer;
    }
    .giftCounter {
      width: 24px;
      height: 24px;
      background: $srg-purple;
      color: white;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      margin-left: 8px;
    }
  }
  &__messages {
    margin-left: 30px;
    border-radius: 24px;
    width: 750px;
    .bigTitle {
      font-size: 28px;
      letter-spacing: 1.2px;
      color: $srg-blue;
      margin-bottom: 24px;
    }
  }
}
.coins {
  margin-top: 60px;
  height: 140px;
  width: 100%;

  .balance {
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(44, 53, 66, 0.08),
      0 1px 8px 0 rgba(44, 53, 66, 0.08), 0 2px 24px 0 rgba(44, 53, 66, 0.06);
    align-items: center;
    padding: 24px 32px;

    &__img {
      margin-right: 24px;
    }

    &__info {
      margin-top: 10px;
      color: #000000;
      font-weight: bold;

      h4 {
        font-size: 22px;
      }

      &--lg {
        font-size: 56px;
        font-weight: bold;
        letter-spacing: 3px;
        line-height: 38px;
        line-height: 1.2;
        margin-bottom: 0;
      }

      &--md {
        font-size: 22px;
        line-height: 22px;
      }
    }

    &__btn {
      height: 54px;
      width: 262px;
      font-size: 14px;
      margin-left: auto;
    }
  }

  .levels {
    display: flex;
    padding-left: 30px;

    &__info {
      padding: 12px 0 0 14px;

      &__text {
        color: black;
        margin: 0;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: 1px;
        &.bold {
          font-weight: bold;
        }
      }
    }
  }
}

.messages-title {
  margin-top: 60px;
}

.messages {
  width: 100%;

  &__carousel {
    width: 100%;
    &-item {
      padding: 0 22px;
      position: relative;
      box-shadow: 0 2px 4px 0 rgba(44, 53, 66, 0.08),
        0 1px 8px 0 rgba(44, 53, 66, 0.08), 0 2px 24px 0 rgba(44, 53, 66, 0.06);
      height: 140px;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        margin-right: 16px;
      }
      h4 {
        line-height: 22px;
      }
    }
    &.custom {
      .pari,
      .dispari {
        display: flex;
      }

      .pari + .dispari {
        justify-content: flex-end;
      }

      .VueCarousel-slide {
        &:last-child {
          &.pari.VueCarousel-slide-active {
            justify-content: flex-end;
          }
        }
        &:nth-last-child(2) {
          &.dispari {
            justify-content: flex-start;
          }
          &.dispari.VueCarousel-slide-active {
            justify-content: flex-end;
          }
        }
      }
    }
    &-itemMex {
      width: 360px;
      height: 200px;
      padding: 32px 24px;
      position: relative;
      background: #f7faff;
      overflow: hidden;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        margin-right: 16px;
      }
      h4 {
        line-height: 22px;
      }
    }
  }
}

.card-dialog {
  padding: 20px 40px 40px 40px;

  h1 {
    font-weight: bold;
  }

  .dialog__img {
    cursor: pointer;
    margin-top: 20px;
    margin-right: 20px;
  }

  .dialog__text {
    font-size: 20px;
    color: black;
  }

  .dialog__imageCard {
    width: 100%;
  }
}

.missions-title {
  width: 100%;
  margin-top: 50px;
  padding: 0 12px;
  margin-bottom: 10px;
}

.levels-dialog {
  padding: 40px;
  .textBack {
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 500;
    color: black;
    display: flex;
    align-items: center;
    cursor: pointer;
    &::before {
      content: url('../../assets/icons/chevron-sx-blue.svg');
      height: 23px;
      margin-right: 4px;
    }
  }
  h5 {
    font-weight: bold;
    color: $srg-blue;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
}

.missions {
  width: 100%;
  &__empty {
    width: 100%;
    font-size: 20px;
    color: #2c313a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    span {
      letter-spacing: 1px;
    }
  }
  &__carousel {
    width: 100%;

    &-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.12);
      height: 100%;

      .img {
        height: 200px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }

      .price {
        overflow-x: hidden;
        position: relative;
        display: flex;
        align-items: center;
        height: 50px;
        &__coins {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 100%;
          background: $srg-green;
        }
        &__img {
          margin-right: 8px;
        }

        &__lvl {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 100%;
          background: $srg-blue;
          color: white;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: bold;
            border: 1px solid white;
            border-radius: 11px;
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }
        }

        &__value {
          color: $srg-blue;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0.4px;
        }

        &__descr {
          align-self: stretch;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          width: 105px;
          background: #ffe03a;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          line-height: normal;
        }
      }

      .content {
        padding: 22px 24px 32px;

        .mission-info {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          text-transform: uppercase;

          &__name {
            font-size: 12px;
            font-weight: 500;
          }

          &__chip {
            padding: 2px 12px;
            font-weight: bold;
            margin-left: auto;
            cursor: pointer;
            color: white;
            font-size: 12px;
            line-height: 0;
            height: 26px;
          }
        }
      }
    }
  }
}
.bonuses {
  margin-top: 60px;
  width: 100%;
  &__title {
    font-size: 28px;
    font-weight: bold;
    color: $srg-blue;
    letter-spacing: 1.2px;
  }
  &__item {
    width: 360px;
    height: 219px;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    position: relative;
    align-items: flex-end;
    padding: 24px;
    cursor: pointer;
    overflow: hidden;

    &::before {
      background: linear-gradient(0deg, #142254 0%, rgba(0, 0, 0, 0) 72.48%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
    &.catalog {
      background-image: url('../../assets/greeners/new_catalogo_greeners.png');
    }
    &.bonus {
      background-image: url('../../assets/greeners/new_codice_ricevuto_greeners.png');
    }
    &.greeners {
      background-image: url('../../assets/greeners/codice_amico_new_greeners.png');
    }
    h3 {
      width: 280;
      font-weight: bold;
      color: white;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      position: relative;
    }
  }
}

.container-progressbar {
  display: flex;
  width: 100%;
  justify-content: center;
}

.container-links {
  width: 100%;
  margin-top: 40px;
}
.links {
  display: flex;
  justify-content: space-between;
  &__item {
    box-sizing: border-box;
    width: 343px;
    height: 57px;
    border: 1px solid #dbdbdb;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 18px;
      font-weight: bold;
      margin-left: 10px;
    }

    .img {
      margin-left: 25px;
    }

    .arrow {
      margin-right: 20px;
    }
  }
}
</style>
<style lang="scss">
.loyalty {
  .VueCarousel-navigation-button {
    top: initial !important;
    bottom: -50px;
  }
  .VueCarousel-navigation-next {
    right: 10px !important;
  }
  .VueCarousel-navigation-prev {
    left: 10px !important;
  }
  .VueCarousel-navigation-next,
  .VueCarousel-navigation-prev {
    background: $srg-blue !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    &:focus {
      outline: none !important;
    }
    .icon {
      &.next {
        content: url('../../assets/icons/chevron-dx-white.svg');
      }
      &.prev {
        content: url('../../assets/icons/chevron-sx-white.svg');
      }
    }
    &.VueCarousel-navigation--disabled {
      display: block !important;
      opacity: 1 !important;
      background-color: #f8f9fc !important;
      .icon {
        &.next {
          fill: #b7bfd2;
          content: url('../../assets/icons/chevron-dx-grey.svg');
        }
        &.prev {
          fill: #b7bfd2;
          content: url('../../assets/icons/chevron-sx-grey.svg');
        }
      }
    }
  }
  &__header-back .mdi-chevron-left {
    &::before {
      color: #12256a;
    }
  }
  .font-style-card {
    font-size: 20px;
    word-break: break-word;
    letter-spacing: 1px;
    line-height: 1.4;
    color: black;
    font-weight: 700;
  }
  .hideBycicle {
    img {
      margin-left: -60px !important;
    }
  }
}
</style>
