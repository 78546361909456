var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offering"},[_c('v-row',{class:[
      'offering__header',
      { 'offering__header--small': _vm.smallHeader },
      { successStep: _vm.successStep },
      _vm.typeCommodity
    ]},[_c('v-col',{staticClass:"col-6 img"}),_c('v-spacer'),_c('v-col',{staticClass:"col-5",attrs:{"align-self":_vm.align}},[_vm._t("header")],2)],1),_c('v-row',{staticClass:"offering__content"},[_vm._t("default")],2),(_vm.footer)?_c('v-footer',{staticClass:"offering__footer topRadius",attrs:{"height":"96"}},[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('p',{staticClass:"ma-0"},[_vm._v(" Hai bisogno di aiuto? "),_c('a',{staticClass:"pl-2",on:{"click":function($event){;(_vm.dialog = true),
                _vm.trackTapEvent({
                  name: 'commodityUpsell_contactUs_tap',
                  params: {
                    client_code: _vm.clientCode,
                    client_type: _vm.clientType,
                    contact_type: _vm.landline ? 'phone' : 'callmeback'
                  }
                })}}},[_vm._v(" Contattaci senza impegno ")])])])],1)],1):_vm._e(),(_vm.landline)?_c('Help',{attrs:{"landlineOpen":_vm.landline,"fromPage":"commodity"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e(),(!_vm.landline)?_c('CallMeBack',{attrs:{"requestingClient":_vm.requestingClient,"fromPage":_vm.typeCommodity},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }