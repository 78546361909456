<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <v-breadcrumbs
          :items="breadcrumbsItems"
          divider="<"
          class="pb-0 reverse-breadcrumbs"
        >
          <template v-slot:divider>
            <img src="@/assets/icons/chevron-sx-blue.svg" width="24" />
          </template>
        </v-breadcrumbs>
        <h1 class="HeadingsTitleXXL">Richiedi il tuo premio</h1>
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-img
          :src="require('@/assets/greeners/svg/logoGreeners_new.svg')"
          contain
          width="360"
        />
      </v-col>
    </template>
    <div class="shipping__content">
      <v-row class="mb-10">
        <v-col>
          <h2 class="HeadingL mb-2">Ritira il tuo premio</h2>
          <h4 class="BodyL">Inserisci i tuoi dati ai fini della spedizione.</h4>
        </v-col>
      </v-row>
      <contact-form
        :isFormFilled.sync="isSubmitButtonDisabled"
        :formData.sync="formData"
        :catalog="catalog"
        :prizeId="prizeId"
      />
      <v-row>
        <v-col align="left">
          <v-btn
            :disabled="isSubmitButtonDisabled"
            :outlined="isSubmitButtonDisabled"
            class="v-btn--shadowed"
            color="primary"
            x-large
            width="154"
            @click="sendRequest"
          >
            Prosegui
          </v-btn>
        </v-col>
      </v-row>
      <v-footer color="white" height="90" />
    </div>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'
import { AlertService } from '../../../js/service/alertService'
import Layout from '../Layout'
import ContactForm from '../landing/ContactForm'

export default {
  name: 'GiftShipping',
  components: {
    layout: Layout,
    ContactForm
  },
  data: () => ({
    isSubmitButtonDisabled: true,
    formData: {}
  }),
  computed: {
    catalog() {
      return this.$route.params.catalog || ''
    },
    prizeId() {
      return this.$route.params.id
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'giftCode' }, text: 'Torna ai tuoi Premi', exact: true },
        { to: { name: this.$router.currentRoute.name }, text: '' }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('loyalty', ['requestAward']),
    sendRequest() {
      this.requestAward(this.formData)
        .then((res) => {
          AlertService(
            'Hai richiesto correttamente il tuo premio',
            'Per ricevere il tuo premio entro i tempi previsti da Regolamento segui le indicazioni che ti invieremo per email.',
            'success',
            'Vai al diario',
            'loyaltyPage'
          )
        })
        .catch((err) => {
          this.trackTapEvent('greeners_error_view')
          AlertService(
            'Riprova più tardi',
            'Siamo spiacenti ma per un errore temporaneo non è possibile richiedere il premio. Non preoccuparti: i tuoi dati sono al sicuro.',
            'alert',
            'Vai al diario',
            'loyaltyPage'
          )
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.shipping {
  &__content {
    margin-top: 50px;
    margin-bottom: 40px;
    width: 100%;
  }
}
</style>
