<template>
  <v-dialog :value="dialog" @input="$emit('input')" max-width="578" persistent>
    <v-row>
      <v-col class="edition-dialog">
        <img
          id="close"
          @click="$emit('goToNewEditionLoyalty')"
          src="../../assets/ic_chevron_top_black.png"
        />
        <div class="SubheadingL div_padding">Nuova Edizione Greeners!</div>
        <div class="div_padding">
          <img
            src="../../assets/greeners/svg/family-group.svg"
            style="margin-bottom: 4%; margin-top: 5%; max-width: 100%"
          />
          <h5 class="BodyL">
            Non perdere l'occasione di premiare la tua energia positiva e di
            passare all'azione per l'ambiente con la community Greeners! Leggi
            il
            <a @click="getDoc('regolamentoGreeners')" class="ButtonM-Underline"
              >regolamento</a
            >
            nella sezione "Informazioni Utili" di Greeners e scopri tutte le
            novità di questa edizione, pensate per te!
          </h5>
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="primary"
            class="v-btn--shadowed"
            height="54"
            width="174"
            @click="$emit('goToNewEditionLoyalty')"
            >Prosegui</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
import { ENV_CONFIGS } from '../../js/configs'

export default {
  name: 'newEditionLoyaltyDialog',
  props: {
    dialog: { type: Boolean, default: false }
  },
  methods: {
    getDoc(idDoc) {
      window.open(ENV_CONFIGS.DYNAMIC_LINKS[idDoc])
    }
  }
}
</script>
<style lang="scss" scoped>
.v-dialog__content {
  .edition-dialog {
    background: white;
    padding: 48px 58px 38px 48px;
    border-radius: 40px;
    position: relative;
    img#close {
      position: absolute;
      top: 50px;
      right: 55px;
      cursor: pointer;
    }
    &__title {
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1.2;
    }
    &_desc {
      color: black;
      line-height: 1.4;
      letter-spacing: 1px;
      a {
        font-weight: 500;
        text-decoration: underline !important;
      }
    }
  }

  .div_padding {
    padding-bottom: 2%;
  }
}
</style>
