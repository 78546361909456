<template>
  <div class="upload">
    <section v-if="!isCallMeBack">
      <h5 class="mb-6">
        Invia la tua bolletta al nostro operatore che ti seguirà durante la fase
        di attivazione della Fibra
      </h5>
      <div class="upload__box">
        <h5 @click="handleClick">Carica la tua bolletta</h5>
        <v-file-input
          id="bill-upload"
          style="display: none"
          ref="files"
          v-model="currFiles"
          multiple
          clearable
          @change="inputChanged"
        />
      </div>
      <div
        v-for="(file, i) in billDocument"
        :key="i"
        class="upload__fileuploaded"
      >
        <div class="d-flex justify-space-between align-center">
          <h6 class="upload__fileuploaded__text">{{ file.name }}</h6>
          <img
            src="../../../assets/icons/ic-close-blue.svg"
            width="18"
            style="cursor: pointer"
            @click="deleteFile(i)"
          />
        </div>
      </div>
    </section>
    <section v-else>
      <h5 class="mb-6">
        Un nostro operatore ti contatterà al più presto per completare il
        processo.
      </h5>
    </section>
    <div class="mb-4">
      <div class="upload__callNum">Dove ti chiamiamo?</div>
      <v-text-field
        class="pt-0"
        v-model="numberPhone"
        type="number"
        :rules="[rules.required]"
      ></v-text-field>
    </div>
    <div class="mb-8">
      <div class="upload__pref">Hai preferenze di orario?</div>
      <h5>
        Siamo disponibili tutti i giorni dalle 9 alle 21. Il sabato e la domenca
        dalle 9 alle 18
      </h5>
      <v-select
        :items="['Indifferente', 'Mattina', 'Pomeriggio', 'Sera']"
        label=""
        v-model.trim="times"
      ></v-select>
    </div>
    <v-btn
      color="primary"
      x-large
      width="260"
      class="v-btn--shadowed"
      :disabled="disableBtn"
      @click="upload"
      >Continua</v-btn
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import GenErr from '@/js/genericErrors'

export default {
  name: 'uploadBill',
  props: ['isCallMeBack'],
  data() {
    return {
      rules: { required: (value) => !!value || 'Campo obbligatorio.' },
      billDocument: [],
      currFiles: [],
      clientDetail: null,
      numberPhone: '',
      times: 'Indifferente'
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['client']),
    disableBtn() {
      if (!this.isCallMeBack) {
        return this.billDocument.length === 0 || this.numberPhone === ''
      } else {
        return this.numberPhone === ''
      }
    }
  },
  created() {
    this.getDetailForClient({
      clientOwnerCode: this.client.clientOwnerCode
    }).then((client) => {
      this.clientDetail = client
      this.numberPhone = client.mobilePhone || client.phone
    })
  },
  methods: {
    ...mapActions('interactions', ['upsellingLeadFoto']),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('interactions', { callMeBack: 'callMeBack' }),
    handleClick() {
      this.trackTapEvent({
        name: 'upsellingv2_uploadbill_tap',
        params: {
          client_selected:
            this.$store.getters['upsellingFiber/addressCovered']
              .clientOwnerCode || ''
        }
      })
      document.getElementById('bill-upload').click()
    },
    deleteFile(i) {
      this.billDocument.splice(i, 1)
      this.currFiles.splice(i, 1)
    },
    inputChanged() {
      let size = this.billDocument.reduce((accumulator, file) => {
        return accumulator + file.size
      }, 0)
      if (size + this.currFiles[0].size < 4000 * 1024) {
        this.billDocument = [...this.currFiles, ...this.billDocument]
      } else {
        this.dialogMaxSize = true
      }
    },
    upload() {
      if (!this.isCallMeBack) {
        this.trackTapEvent({
          name: 'upsellingv2_uploadbillconfirm_tap',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
        let formData = new FormData()
        let input = {
          upsellingType: 'upsellingFibra',
          indirizzo: this.client.residentialAddress,
          comune: this.client.residentialCity,
          cap: this.client.residentialCAP,
          codiceCliente: this.client.clientCode,
          telefono: this.numberPhone,
          nome: this.client.name,
          cognome: this.client.surname,
          ragioneSociale: this.client.businessName,
          piva: this.client.VATNumber,
          email: this.client.contactMail,
          oraPeriodo: this.times.toLowerCase(),
          tipoOfferta: 'FIBRA'
        }

        formData.append('input', JSON.stringify(input))

        for (var i = 0; i < this.billDocument.length; i++) {
          let index = i
          formData.append(
            'files',
            this.billDocument[index],
            this.billDocument[index].name
          )
        }

        this.upsellingLeadFoto(formData).then((resp) => {
          if (resp) this.$router.push({ name: 'uploaded' })
        })
      } else {
        this.trackTapEvent({
          name: 'upsellingv2_callmebackconfirm_tap',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
        let landLineData = {
          clientCode: this.client.clientOwnerCode,
          commodity: 'fiber',
          phone: this.numberPhone,
          times: this.times.toLowerCase(),
          email: '',
          notes: 'Fibra - Info per Upselling',
          requestType: 'upselling'
        }
        this.callMeBack(landLineData)
          .then((resp) => {
            this.$router.push({ name: 'fiberNewRequestSent' })
          })
          .catch((error) => {
            GenErr.handleGenericError(error && error.message)
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.upload {
  max-width: 652px;
  color: black;
  &__box {
    position: relative;
    width: 652px;
    margin-bottom: 32px;
    padding: 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    cursor: pointer;
    h5 {
      max-width: 600px;
    }
    &::after {
      content: url('../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
  &__fileuploaded {
    padding: 10px 23px 10px 32px;
    background-color: transparent;
    border: solid 1.1px #dbdbdb;
    border-radius: 20px;
    margin-bottom: 20px;
    &__text {
      color: black;
      font-size: 16px;
      display: flex;
      align-items: center;
      max-width: 90%;
      overflow: hidden;
      &:before {
        content: url('../../../assets/bill-grey.svg');
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }
    }
  }
  &__callNum {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  &__pref {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
