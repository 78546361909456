<template>
  <v-dialog v-model="dialog" max-width="555" content-class="dialog_test">
    <div class="editMail pa-10">
      <div class="d-flex align-center justify-space-between mb-10">
        <div class="HeadingsSubtitleL">Email di contatto</div>
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          @click="$emit('update:mailDialog', false)"
        />
      </div>
      <div class="BodyL mb-10">
        A questo indirizzo email ricevi i premi Greeners e tutte le
        comunicazioni da parte di Sorgenia.<br /><br />
        Puoi richiedere la modifica o l’aggiunta di questa email contattando
        l'assistenza.
      </div>
      <div class="d-flex justify-center">
        <v-btn
          class="v-btn--shadowed"
          x-large
          color="primary"
          width="272"
          @click="$router.push({ name: 'Contacts', params: { user: user } })"
          >Contatta l’assistenza
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'editContactMail',
  props: ['mailDialog', 'user'],
  computed: {
    dialog: {
      get() {
        return this.mailDialog
      },
      set(val) {
        this.$emit('update:mailDialog', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.editMail {
  background-color: white;

  img {
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.v-dialog__content:has(.dialog_test) {
  z-index: 203 !important;
}
</style>
