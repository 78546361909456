import * as BandyerSDK from '@bandyer/bandyer-chat-widget'
import store from '@/store/index'
import { ENV_CONFIGS } from '@/js/configs.js'
import { eventBus } from '../../main'
import GenErr from '@/js/genericErrors'

async function initKaleyra() {
  let Client = await BandyerSDK.configure({
    appId: ENV_CONFIGS.KALEYRA_APP_ID,
    region: 'eu',
    environment: ENV_CONFIGS.KALEYRA_ENV,
    mode: 'window',
    hidden: true
  })

  window.kaleyra = await Client

  Client.on('client:access_token:is_about_to_expire', async (data) => {
    let kaleyraObj = await store.dispatch(
      'session/kaleyraAuth',
      store.getters['account/mainClient'].clientOwnerCode
    )
    Client.updateAccessToken(kaleyraObj.accessToken)
    localStorage.setItem('videoCallBooked', false)
  })
  Client.on('client:access_token:expired', async () => {
    await Client.disconnect()
    let kaleyraObj = await store.dispatch(
      'session/kaleyraAuth',
      store.getters['account/mainClient'].clientOwnerCode
    )
    try {
      await Client.connect(kaleyraObj.userId, kaleyraObj.accessToken)
      localStorage.setItem('videoCallBooked', false)
    } catch (error) {
      throw GenErr.showErrorMessage()
      localStorage.setItem('videoCallBooked', false)
    }
  })

  Client.on('call:incoming', ({ call }) => {
    Client.hideWidget()
    eventBus.$emit('open:kaleyra', call, Client)
    localStorage.setItem('videoCallBooked', false)
  })

  Client.on('call:status:changed', ({ call, status }) => {
    console.log(status)
    //TODO sarà da implementare in futuro quindi lascio commentato
    // if (status === 'ended' && call.endReason !== 'declined') {
    //   store.dispatch('analytics/trackTapEvent', {
    //     name: 'videocall_call_ended'
    //   })
    // }

    localStorage.setItem('videoCallBooked', false)
  })
}

export { initKaleyra }
