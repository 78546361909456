<template>
  <v-dialog v-model="dialog" width="558">
    <div class="upsellingDialogFV">
      <div class="d-flex align-center justify-space-between mb-10">
        <div class="SubheadingL">Fotovoltaico</div>
        <img
          class="close"
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          @click="$emit('update:upsellingDialogFV', false)"
        />
      </div>
      <img src="../../../assets/fotovoltaico/upsellingFV.svg" class="mb-6" />
      <div class="BodyL-Strong mb-10">
        Guarda lontano con l'energia del sole:
        <div class="BodyL">
          Energia pulita, prodotta in autonomia e che riduce la tua bolletta.
          <br />
          Cosa aspetti? Calcola subito quanto puoi risparmiare con un impianto
          fotovoltaico per la tua casa.
        </div>
      </div>
      <div class="d-flex align-center justify-center">
        <v-btn
          class="v-btn--shadowed"
          x-large
          color="primary"
          width="315"
          @click="openUpsellingFV"
        >
          Richiedi un preventivo
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'upsellingPhotovoltaic',
  props: ['upsellingDialogFV'],
  computed: {
    ...mapGetters('account', ['mainClient']),
    dialog: {
      get() {
        return this.upsellingDialogFV
      },
      set(val) {
        this.$emit('update:upsellingDialogFV', val)
      }
    }
  },
  methods: {
    openUpsellingFV() {
      if (this.mainClient.isOnlyFV) {
        window.open(
          'https://www.sorgenia.it/partnership-fotovoltaico-energy-corner?campaign_code=MySorgenia_listinoCB&utm_source=app&utm_medium=mysorgenia&utm_campaign=MySorgenia_listinoCBFV',
          '_blank'
        )
      } else {
        window.open(
          'https://www.sorgenia.it/partnership-fotovoltaico-energy-corner?campaign_code=MySorgenia_listinoCB&utm_source=app&utm_medium=mysorgenia&utm_campaign=MySorgenia_listinoCB',
          '_blank'
        )
      }

      // return false
    }
  }
}
</script>
<style lang="scss" scoped>
.upsellingDialogFV {
  background: white;
  padding: 40px;
  .close {
    cursor: pointer;
  }
}
</style>
