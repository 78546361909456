<template>
  <div class="mt-5">
    <v-row>
      <v-col>
        <div class="unknown">
          <img src="../../assets/icons/IconUnknoewnStatus.svg" width="24" />
          <div class="BodyL ml-2">
            Stato al momento non disponibile. Riprova ad accedere a questa
            sezione tra qualche minuto.
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'StatusUnknown',
  props: {}
}
</script>

<style lang="scss" scoped>
.unknown {
  display: flex;
  flex-direction: row;
  width: 751px;
  height: 76px;
  border-radius: 16px;
  padding: 16px;
  gap: 8px;
  background-color: #e7f5f7;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
