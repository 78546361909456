<template>
  <layout header-size="mission">
    <template v-slot:header>
      <v-col cols="6" class="img-girl pa-0" />
      <v-col v-if="mission" class="col-5 pa-0" align-self="center">
        <h1 class="" v-html="mission.end_page_title" />
      </v-col>
    </template>
    <reward-content
      :coins="missionValue(mission.wekit_action_id, mission.wekit_event_id)"
      :mission="mission"
    >
      <template v-if="mission" v-slot:text>
        <h4 class="font-weight-regular" v-html="mission.end_page_text" />
      </template>
    </reward-content>
  </layout>
</template>

<script>
import Layout from './Layout'
import RewardContent from '@/views/loyalty/components/rewardContent'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SurveyRewardPage',
  components: {
    Layout,
    rewardContent: RewardContent
  },
  beforeMount() {
    this.getWekitMap()
    this.getSurveys().then((res) => {
      const condition = res.filter(
        (mission) => mission.id === this.$route.params.id
      )
      if (condition.length) {
        this.mission = condition[0]
      }
    })
  },
  data: () => ({
    mission: null
  }),
  computed: {
    ...mapGetters('loyalty', ['surveys', 'wekitMap'])
  },
  methods: {
    ...mapActions('loyalty', ['getSurveys', 'getWekitMap']),
    missionValue(actionId, eventId) {
      let targetMission = this.wekitMap.filter(
        (mission) =>
          mission.action_id === actionId && mission.event_id === eventId
      )
      if (targetMission.length) {
        return targetMission[0].points
      } else return 0
    }
  }
}
</script>

<style lang="scss" scoped></style>
