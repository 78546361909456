async function getPromiseSingletone(promiseBag) {
  await promiseState(promiseBag).then((state) => {
    switch (state) {
      case 'fulfilled': {
        return true
      }
      case 'rejected': {
        return false
      }
      case 'pending': {
        return true
      }
      default: {
        return false
      }
    }
  })
}

function promiseState(p) {
  const t = {}
  return Promise.race([p, t]).then(
    (v) => (v === t ? 'pending' : 'fulfilled'),
    () => 'rejected'
  )
}

export { getPromiseSingletone }
