<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="555" scrollable>
    <v-card height="662">
      <div class="supplies">
        <img
          class="closeImg"
          @click="$emit('input')"
          src="@/assets/ic_chevron_top_black.png"
        />
        <div class="supplies__title SubheadingL">Seleziona indirizzo</div>
        <div
          v-for="(name, i) in Object.keys(groupedSupplies)"
          :key="i"
          class="supplies__owner"
        >
          <h5
            :class="`BodyL-Strong name ${groupedSupplies[
              name
            ][0].clientOwnerType.toLowerCase()}`"
          >
            {{ name }}
          </h5>
          <div
            v-for="(address, a) in groupedSupplies[name]"
            :key="a"
            :class="`supplies__btn ${labelTrigger(address).status}`"
            @click="ctaTrigger(address)"
          >
            <div class="supplies__content">
              <img
                cla
                :src="pathImg(labelTrigger(address).status)"
                width="25"
                class="mr-4"
              />
              <div class="text">
                <h5 class="address">
                  {{ address.street.toLowerCase() }},
                  {{ address.city.toLowerCase() }}
                </h5>
                <div :class="`LabelM type ${labelTrigger(address).status}`">
                  {{ labelTrigger(address).label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Constants from '@/js/constants'
import { OVERALL_STATUS } from '@/js/constants'
import { dynamicSort } from '@/js/utils'

export default {
  name: 'suppliesDialog',
  props: ['value', 'typeTrigger', 'typeComm'],
  data() {
    return {
      SUPPLY_STATUS: Constants.SUPPLY_STATUS
    }
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('alert', ['flagAlertConsumi']),
    groupedSupplies() {
      if (!this.supplies) return []
      let suppliesFiltered = this.supplies.filter(
        (s) => s.serviceType.toLowerCase() === this.typeComm.toLowerCase()
      )
      let result = suppliesFiltered.reduce((r, a) => {
        let name = (
          a.businessName || a.firstName + ' ' + a.lastname
        ).toLowerCase()
        r[name] = r[name] || []
        r[name].push(Object.assign(a, this.labelTrigger(a)))
        r[name].sort(dynamicSort('order'))
        return r
      }, Object.create(null))
      return result
    }
  },
  methods: {
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('fotovoltaico', ['setAddressSelected']),
    ...mapMutations('fotovoltaico', ['setFlagEnterByDeeplink']),
    pathImg(status) {
      switch (this.typeComm) {
        case 'ele':
          return require(`../../assets/icons/ic-light-${
            status === 'regular' || status === 'timeline'
              ? 'blu'
              : status === 'noActive'
              ? 'grey'
              : 'red'
          }.svg`)
        case 'gas':
          return require(`../../assets/icons/ic-fire-${
            status === 'regular' || status === 'timeline'
              ? 'blu'
              : status === 'noActive'
              ? 'grey'
              : 'red'
          }.svg`)
        case 'fiber':
          return require(`../../assets/icons/ic-wifi-${
            status === 'regular' || status === 'timeline'
              ? 'blu'
              : status === 'noActive'
              ? 'grey'
              : 'red'
          }.svg`)
        case 'photovoltaic':
          return require(`../../assets/icons/ic-photovoltaic-${
            status === 'regular' || status === 'timeline' ? 'blu' : 'red'
          }.svg`)
      }
    },
    labelTrigger(address) {
      if (address.supplyState === this.SUPPLY_STATUS.CLOSED) {
        return { status: 'noActive', label: 'CESSATA', order: 10 }
      } else if (address.supplyState === this.SUPPLY_STATUS.REJECTED) {
        return { status: 'noActive', label: 'ANNULLATA', order: 11 }
      } else if (address.supplyState === this.SUPPLY_STATUS.ACTIVATING) {
        if (this.typeComm === 'photovoltaic') {
          return { status: 'timeline', label: 'SCOPRI L’ATTIVAZIONE', order: 2 }
        } else {
          return { status: 'timeline', label: 'SEGUI L’ATTIVAZIONE', order: 5 }
        }
      } else {
        if (this.typeTrigger === 'dashboardFV') {
          return address.isRegularPhotovoltaic
            ? { status: 'regular', label: 'REGOLARE', order: 3 }
            : {
                status: 'irregular',
                label:
                  address.photovoltaicData.installationStatus ===
                  OVERALL_STATUS.NOT_COMMUNICATING
                    ? 'OFFLINE'
                    : 'NON FUNZIONANTE',
                order: 1
              }
        } else if (this.typeTrigger === 'payment') {
          return address.hasRegularStatus
            ? { status: 'regular', label: 'PAGAMENTI REGOLARI', order: 4 }
            : {
                status: 'irregular',
                label:
                  address.notRegularPayments > 1
                    ? `${address.notRegularPayments} BOLLETTE SCADUTE`
                    : `${address.notRegularPayments} BOLLETTA SCADUTA`,
                order: 1
              }
        } else if (this.typeTrigger === 'consumption') {
          if (this.typeComm === 'ele') {
            return address.hasAbnormalConsumption
              ? { status: 'regular', label: 'CONSUMI DA CONTROLLARE', order: 3 }
              : address.isUnavailable
              ? {
                  status: 'regular',
                  label: 'CONTROLLA STATO CONSUMI',
                  order: 6
                }
              : { status: 'regular', label: 'CONSUMI STABILI', order: 7 }
          } else if (this.typeComm === 'gas') {
            return address.isEstimate
              ? { status: 'irregular', label: 'INVIA AUTOLETTURA', order: 2 }
              : address.isUnavailable
              ? {
                  status: 'regular',
                  label: 'CONSUMI NON DISPONIBILI',
                  order: 8
                }
              : { status: 'regular', label: 'CONSUMI EFFETTIVI', order: 9 }
          } else {
            return { status: 'regular', label: 'CONSUMI ILLIMITATI', order: 6 }
          }
        }
      }
    },
    ctaTrigger(address) {
      if (address.supplyState === this.SUPPLY_STATUS.CLOSED) {
        this.$router.push({
          name: 'supplyDetail',
          params: {
            clientOwnerCode: address.clientOwnerCode,
            supplyCode: address.supplyCode
          }
        })
      } else if (address.supplyState === this.SUPPLY_STATUS.REJECTED) {
        this.$router.push({
          name: 'supplyDetail',
          params: {
            clientOwnerCode: address.clientOwnerCode,
            supplyCode: address.supplyCode
          }
        })
      } else if (address.supplyState === this.SUPPLY_STATUS.ACTIVATING) {
        if (this.typeComm === 'fiber') {
          this.$router.push({
            name: 'trackingFiber',
            params: {
              clientOwnerCode: address.clientOwnerCode,
              supplyCode: address.supplyCode
            }
          })
        } else if (this.typeComm === 'photovoltaic') {
          this.$router.push({
            name: 'trackingPhotovoltaic'
          })
        } else {
          this.$router.push({
            name: 'trackingCommodity',
            params: {
              clientOwnerCode: address.clientOwnerCode,
              supplyCode: address.supplyCode
            }
          })
        }
      } else {
        if (this.typeTrigger === 'payment') {
          if (this.typeComm === 'fiber' || address.isUnavailable) {
            this.$router.push({
              name: 'supplyDetail',
              params: {
                clientOwnerCode: address.clientOwnerCode,
                supplyCode: address.supplyCode
              }
            })
          } else {
            if (address.hasRegularStatus) {
              this.$router.push({
                name: 'billsArchive',
                params: {
                  clientOwnerCode: address.clientOwnerCode,
                  supplyCode: address.supplyCode,
                  valueTab: 'bollette'
                }
              })
            } else {
              this.$router.push({
                name: 'billsArchive',
                params: {
                  clientOwnerCode: address.clientOwnerCode,
                  supplyCode: address.supplyCode,
                  expiredBillsFilter: true,
                  valueTab: 'bollette'
                }
              })
            }
          }
        } else if (this.typeTrigger === 'consumption') {
          if (address.isEstimate) {
            this.$router.push({
              path: `supply/${address.clientOwnerCode}/${address.supplyCode}/selfreading/base`
            })
          } else {
            this.$router.push({
              name: 'supplyDetail',
              params: {
                clientOwnerCode: address.clientOwnerCode,
                supplyCode: address.supplyCode
              }
            })
          }
        } else if (this.typeTrigger === 'dashboardFV') {
          this.setAddressSelected(address)
          this.setFlagEnterByDeeplink(false)
          if (this.$route.name != 'dashboardFotovoltaico') {
            this.setFlagEnterByDeeplink(false)
            this.$router.push({
              name: 'dashboardFotovoltaico',
              params: {
                acuId: address.acuId,
                siteId: address.photovoltaicData.siteId
              }
            })
          }
          this.$router.replace({
            name: 'dashboardFotovoltaico',
            params: {
              acuId: address.acuId,
              siteId: address.photovoltaicData.siteId
            }
          })
        }
      }
      // if (this.typeTrigger === 'payment') {
      //   if (this.typeComm === 'fiber') {
      //     this.$router.push({ name: 'supplyDetail', params: { clientOwnerCode: address.clientOwnerCode, supplyCode: address.supplyCode } })
      //   } else {
      //     if (address.hasRegularStatus) {
      //       this.$router.push({ name: 'billsArchive', params: { clientOwnerCode: address.clientOwnerCode, supplyCode: address.supplyCode } })
      //     } else {
      //       this.$router.push({ name: 'billsArchive', params: { clientOwnerCode: address.clientOwnerCode, supplyCode: address.supplyCode, expiredBillsFilter: true } })
      //     }
      //   }
      // } else if (this.typeTrigger === 'consumption') {
      //   if (address.isEstimate) {
      //     this.$router.push({ path: `supply/${address.clientOwnerCode}/${address.supplyCode}/selfreading/base` })
      //   } else {
      //     this.$router.push({ name: 'supplyDetail', params: { clientOwnerCode: address.clientOwnerCode, supplyCode: address.supplyCode } })
      //   }
      // } else if (this.typeTrigger === 'noActive') {
      //   this.$router.push({ name: 'supplyDetail', params: { clientOwnerCode: address.clientOwnerCode, supplyCode: address.supplyCode } })
      // } else if (this.typeTrigger === 'timeline') {
      //   if (this.typeComm === 'fiber') {
      //     this.$router.push({ name: 'trackingFiber', params: { clientOwnerCode: address.clientOwnerCode, supplyCode: address.supplyCode } })
      //   } else {
      //     this.$router.push({ name: 'trackingCommodity', params: { clientOwnerCode: address.clientOwnerCode, supplyCode: address.supplyCode } })
      //   }
      // }
    }
  },
  created() {
    this.getSupplies()
  }
}
</script>
<style lang="scss" scoped>
.supplies {
  background: white;
  background-size: 100%;
  padding: 40px 40px 47px 40px;
  &__title {
    color: black;
    margin-bottom: 24px;
  }
  .closeImg {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
  &__owner {
    .name {
      text-transform: capitalize;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
    }
    .res {
      &::before {
        content: url('../../assets/icons/ic-owner-res.svg');
        margin-right: 8px;
      }
    }
    .bus {
      &::before {
        content: url('../../assets/icons/business.svg');
        margin-right: 8px;
      }
    }
  }
  &__btn {
    width: 475px;
    height: 74px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 20px;
    margin-bottom: 23px;
    cursor: pointer;
    border: 1px solid #dfe4ef;
    &.irregular {
      border: 1px solid $srg-purple;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .text {
      flex-grow: 1;
    }
    h5 {
      color: black;
      letter-spacing: 1px;
      text-transform: capitalize;
      width: 370px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .type {
      margin-top: 5px;
      &.regular,
      &.timeline {
        color: $srg-blue;
      }
      &.irregular {
        color: $srg-purple;
      }
      &.noActive {
        color: #6e7689;
      }
    }
    &::after {
      content: url('../../assets/icons/chevron_rx.svg');
    }
  }
}
</style>
