<template>
  <div
    class="answer_box"
    :class="[selected ? 'answer_box_shadowed' : '']"
    :style="`width: calc( 100% / ${perRow} - ${margin * 2}px)`"
  >
    <v-checkbox
      :value="answer.value"
      :disabled="isMax && answer.selected === null"
      @change="onChanged($event)"
      :key="componentKey"
    >
      <template v-slot:label>
        <div class="answer_text">
          {{ answer.label }}
        </div>
      </template>
    </v-checkbox>
  </div>
</template>
<script>
export default {
  name: 'feedbackCheckbox',
  props: {
    answer: { type: Object },
    onClick: { type: Function },
    isMax: { type: Boolean, default: false }
  },
  watch: {
    answer() {
      this.componentKey += 1
    }
  },
  data: () => ({
    selected: false,
    componentKey: 0
  }),
  inject: ['options'],
  computed: {
    perRow() {
      return this.options.perRow
    },
    margin() {
      return this.options.margin
    }
  },
  methods: {
    onChanged(event) {
      this.selected = this.answer.selected !== null
      this.onClick()
    }
  },
  beforeMount() {
    this.selected = this.answer.selected !== null
  }
}
</script>
<style lang="scss" scoped>
.answer_box {
  border-radius: 27px;
  border: 1px solid lightgrey;
  margin: 10px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;

  &_shadowed {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14),
      0 10px 35px 0 rgba(219, 219, 219, 0.3);
  }
}

.answer_text {
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  padding-left: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .answer_text {
    font-size: 14px;
    padding-left: 2px !important;
  }
  .answer_box {
    padding-left: 15px;
    padding-right: 20px;
  }
}
</style>
