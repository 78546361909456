var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{class:{ dreamService: _vm.isMobile }},[(_vm.isMobile)?_c('v-row',{staticClass:"dreamService__header"},[_c('v-col',{attrs:{"cols":"11"}},[_c('button',{staticClass:"dreamService__header-back",attrs:{"type":"button"},on:{"click":function($event){return _vm.onBackClick()}}},[_c('v-icon',{attrs:{"color":"white","size":"30"}},[_vm._v("mdi-chevron-left")])],1)]),_c('v-col',{staticClass:"flex-grow-0 pl-0",attrs:{"cols":"1"}},[_c('v-img',{staticClass:"close",attrs:{"contain":"","width":"16","src":require("@assets/welcome/ic-close-white-big.png")},on:{"click":function($event){return _vm.$router.push({ name: 'publicDreamServiceDone' })}}})],1)],1):_vm._e(),(_vm.loaded)?_c('div',{class:`std-side-padding pb-10 ${
      _vm.isMobile ? 'dreamService__content-wrap' : ''
    }`},[(!_vm.isSmallDevice)?_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pb-0",attrs:{"items":_vm.breadcrumbsItems,"divider":">"},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/chevron_rx_light.svg"),"width":"24"}})]},proxy:true}],null,false,3772294745)})],1)],1):_vm._e(),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"limitedTo11Cols",attrs:{"cols":"auto"}},[_c('h1',{staticClass:"category"},[_vm._v(_vm._s(_vm.currentRequest.categoria))])]),_c('v-col',{attrs:{"cols":"1"}},[(_vm.currentRequest.servizio === 'Ele')?_c('img',{class:`flex-grow-0 ${_vm.isMobile ? 'mt-n2 ml-n4' : 'mt-2 ml-3'}`,attrs:{"src":require("@/assets/icons/ic-light-blu.svg"),"height":"48","width":"48"}}):_vm._e(),(_vm.currentRequest.servizio === 'Gas')?_c('img',{class:`flex-grow-0 ${_vm.isMobile ? 'mt-n2 ml-n4' : 'mt-2 ml-3'}`,attrs:{"src":require("@/assets/icons/ic-fire-blu.svg"),"height":"48","width":"48"}}):_vm._e()])],1)],1)],1),(_vm.isMobile && _vm.currentRequest.chiuso === 'Y')?_c('v-row',[_c('v-col',[_c('a',{staticClass:"d-flex justify-center evaluate white--text v-btn--shadowed v-btn v-btn--contained v-btn--rounded theme--light v-size--large primary",attrs:{"href":"https://it.surveymonkey.com/r/VFLK7PT","target":"_blank"},on:{"click":function($event){return _vm.trackTapEvent({
              name: 'dreamService_rateService_tap',
              toNative: _vm.isMobile
            })}}},[_vm._v(" Valuta il servizio ")])])],1):_vm._e(),_vm._l((_vm.messages),function(msg,i){return _c('v-row',{key:i,class:`rowDream ${
        _vm.isCompleted(msg, _vm.currentRequest) ? 'completedRow' : ''
      }`},[_c('v-col',{class:`message ${i === 0 ? 'toRead' : ''} ${
          _vm.isCompleted(msg, _vm.currentRequest) ? 'last' : ''
        }`,attrs:{"cols":`${_vm.isMobile ? '12' : '9'}`}},[_c('v-row',{class:`${_vm.isMobile ? 'pt-0' : 'pt-2'}`},[_c('v-col',{staticClass:"borderDream",attrs:{"cols":"1"}},[(_vm.isCompleted(msg, _vm.currentRequest))?_c('img',{staticClass:"timeline-icon",attrs:{"width":_vm.isMobile ? 43 : 48,"src":require("../../assets/dreamservice_completed.svg")}}):_c('img',{staticClass:"timeline-icon",attrs:{"width":"28","src":require("../../assets/ic-point-timeline.png")}})]),_c('v-col',{attrs:{"cols":`${
              _vm.isMobile ? '11' : _vm.isCompleted(msg, _vm.currentRequest) ? '6' : '8'
            } pt-1`}},[_c('span',{staticClass:"message-text"},[_vm._v(_vm._s(msg.messaggio))]),_c('span',{staticClass:"message-subtext mt-2"},[_vm._v(_vm._s(msg.operatore))]),_c('v-row',{class:`${_vm.isMobile ? 'pt-0' : 'pt-1'} pl-0 ml-0`},[_c('v-col',{staticClass:"pl-0 ml-0",attrs:{"cols":`${_vm.isMobile ? '4' : '3'}`}},[_c('span',{staticClass:"message-data"},[_vm._v(_vm._s(msg.data))])]),_c('v-col',{attrs:{"cols":`${_vm.isMobile ? '8' : '6'}`}},[_c('span',{staticClass:"message-channel"},[_vm._v("Tramite "+_vm._s(_vm.currentRequest.canale))])])],1)],1),(_vm.isCompleted(msg, _vm.currentRequest) && !_vm.isMobile)?_c('v-col',{staticClass:"col-3 offset-1",attrs:{"align-self":"center"}},[_c('a',{staticClass:"evaluate white--text v-btn--shadowed v-btn v-btn--contained v-btn--rounded theme--light v-size--large primary",attrs:{"href":"https://it.surveymonkey.com/r/VFLK7PT","target":"_blank"}},[_vm._v(" Valuta il servizio ")])]):_vm._e()],1)],1)],1)})],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }