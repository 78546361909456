<template>
  <div class="receiveBill">
    <div class="">
      <v-text-field
        label="Presso"
        class="auth__input"
        v-model="paperInvoiceCustom.among"
        @change="updateStoredPaperInvoiceMethod"
      ></v-text-field>
    </div>
    <div class="">
      <v-autocomplete
        v-model="paperInvoiceCustom.city"
        :items="items.city"
        :loading="isLoading.city"
        :search-input.sync="searchCity"
        @blur="v$.paperInvoiceCustom.city.$touch()"
        :error-messages="cityErrors"
        color="#12256A"
        item-text="label"
        item-value="value"
        label="Comune"
        :no-data-text="noDataText"
        return-object
        autocomplete="none"
      ></v-autocomplete>
    </div>
    <div class="">
      <v-autocomplete
        v-model="paperInvoiceCustom.cap"
        :items="items.cap"
        :loading="isLoading.cap"
        :search-input.sync="searchCap"
        :disabled="!paperInvoiceCustom.city.value"
        @blur="v$.paperInvoiceCustom.cap.$touch()"
        :error-messages="capErrors"
        color="#12256A"
        item-text="label"
        item-value="value"
        label="Cap"
        :no-data-text="noDataText"
        return-object
        autocomplete="none"
      ></v-autocomplete>
    </div>
    <v-row no-gutters class="mb-6">
      <v-col cols="8">
        <v-autocomplete
          v-model="paperInvoiceCustom.address"
          :items="items.address"
          :loading="isLoading.address"
          :search-input.sync="searchAddress"
          :disabled="
            !paperInvoiceCustom.city.value ||
            !paperInvoiceCustom.cap.value ||
            !isCapValid
          "
          @blur="v$.paperInvoiceCustom.address.$touch()"
          :error-messages="addressErrors"
          color="#12256A"
          item-text="label"
          item-value="value"
          label="Indirizzo"
          :no-data-text="noDataText"
          return-object
          class="pr-6"
          autocomplete="none"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="paperInvoiceCustom.number"
          :items="items.number"
          :loading="isLoading.number"
          :search-input.sync="searchNumber"
          :disabled="
            !paperInvoiceCustom.city.value ||
            !paperInvoiceCustom.cap.value ||
            !paperInvoiceCustom.address.value
          "
          @blur="v$.paperInvoiceCustom.number.$touch()"
          :error-messages="numberErrors"
          color="#12256A"
          item-text="label"
          item-value="value"
          label="n° civico"
          :no-data-text="noDataText"
          return-object
          autocomplete="none"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <div class="btn-custom" @click="editAddress">Modifica</div>
  </div>
</template>
<script>
import { ENV_CONFIGS } from '@/js/configs.js'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapGetters, mapMutations } from 'vuex'

const EGON_BASEURL = ENV_CONFIGS.EGON_BASEURL
const EGON_USER = ENV_CONFIGS.EGON_USER
const EGON_PASSWORD = ENV_CONFIGS.EGON_PASSWORD

const EGON_MAPPING = {
  address: {
    serviceType: 'LstStr',
    searchField: 'DSXST1',
    additionalSearchFileds: [
      { key: 'CDPOBJ', value: 'city.value' },
      { key: 'CDXZIP', value: 'cap.value' }
    ],
    rootProperty: 'STR_AREA_OUT',
    listProperty: 'STR',
    labelProperty: 'DSXOBJSTR',
    valueProperty: 'CDPOBJSTR'
  },
  number: {
    serviceType: 'LstCiv',
    searchField: 'NRPNUMCIV',
    additionalSearchFileds: [{ key: 'CDPOBJ', value: 'address.value' }],
    searchMinChars: 1,
    rootProperty: 'CIV_AREA_OUT',
    listProperty: 'CIV',
    labelProperty: 'NRPNUMCIV',
    additionalLabelProperty: 'DSXESP',
    valueProperty: 'CDPOBJCIV'
  },
  city: {
    serviceType: 'LstCnl',
    searchField: 'DSXCNL',
    rootProperty: 'CNL_AREA_OUT',
    listProperty: 'CNL',
    labelProperty: 'DSXOBJCNL',
    additionalLabelProperty: 'DSXOBJDPT',
    valueProperty: 'CDPOBJCNL'
  },
  cap: {
    serviceType: 'LstZip',
    searchField: 'CDXZIP',
    rootProperty: 'ZIP_AREA_OUT',
    listProperty: 'ZIP',
    labelProperty: 'CDXZIP',
    valueProperty: 'CDXZIP'
  }
}
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'receiveBill',
  data() {
    return {
      digitalInvoice: { type: 'digital' },
      paperInvoice: null,
      paperInvoiceCustom: {
        type: 'paper',
        address: {},
        number: {},
        city: {},
        cap: {},
        among: ''
      },
      searchAddress: '',
      searchNumber: '',
      searchAmong: '',
      searchCity: '',
      searchCap: '',
      isLoading: {
        address: false,
        number: false,
        among: false,
        city: false,
        cap: false
      },
      items: { address: [], number: [], among: [], city: [], cap: [] },
      capList: [],
      noDataText: 'Inizia digitare per visualizzare i risultati'
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['invoiceMethod']),
    isCapValid() {
      if (!this.paperInvoiceCustom.cap || !this.paperInvoiceCustom.cap.value)
        return false

      for (let item of this.capList) {
        if (
          item.fullInfo[EGON_MAPPING.city.valueProperty]['lValue'] ===
          this.paperInvoiceCustom.city.value
        )
          return true
      }

      return false
    },
    capErrors() {
      let errArray = []

      // if (!this.v$.paperInvoiceCustom.cap.$dirty) return errArray
      if (!this.paperInvoiceCustom.cap || !this.paperInvoiceCustom.cap.value)
        return errArray

      this.isCapValid === false &&
        errArray.push('Il Cap non corrisponde con il Comune inserito')

      return errArray
    },

    cityErrors() {
      let errArray = []

      if (!this.v$.paperInvoiceCustom.city.$dirty) return errArray

      this.v$.paperInvoiceCustom.city.required.$invalid === true &&
        errArray.push('La citta è obbligatoria')

      return errArray
    },
    addressErrors() {
      let errArray = []

      if (!this.v$.paperInvoiceCustom.address.$dirty) return errArray

      this.v$.paperInvoiceCustom.address.required.$invalid === true &&
        errArray.push("L'indirizzo è obbligatorio")

      return errArray
    },
    numberErrors() {
      let errArray = []

      if (!this.v$.paperInvoiceCustom.number.$dirty) return errArray

      this.v$.paperInvoiceCustom.number.required.$invalid === true &&
        errArray.push('Il numero civico è obbligatorio')

      return errArray
    }
  },
  methods: {
    ...mapMutations('upsellingFiber', ['setInvoiceMethod']),
    editAddress() {
      if (this.validatationHandler()) {
        this.paperInvoiceCustom.sameAsSupplyAddress = false
        this.setInvoiceMethod(this.paperInvoiceCustom)
        this.$emit('updateAddress')
      }
    },
    updateStoredPaperInvoiceMethod() {
      this.setInvoiceMethod(this.paperInvoiceCustom)
    },
    updateSelect(searchValue, fieldName) {
      const mapping = EGON_MAPPING[fieldName]
      const minChars = mapping.searchMinChars || 3

      // If the user has not entered at least minChars chars, do not call api
      if (!searchValue || searchValue.length < minChars)
        return Promise.reject(new Error())

      // Items have already been loaded
      // if (this.items[fieldName].length > 0) return
      if (
        this.paperInvoiceCustom[fieldName] &&
        this.paperInvoiceCustom[fieldName].label === searchValue
      )
        return Promise.reject(new Error())

      // Items have already been requested
      if (this.isLoading[fieldName]) return Promise.reject(new Error())

      this.isLoading[fieldName] = true

      let url = `${EGON_BASEURL}?CDXISO=ITA&USER=${EGON_USER}&PASW=${EGON_PASSWORD}&LST=${mapping.serviceType}&${mapping.searchField}=${searchValue}`

      if (mapping.additionalSearchFileds) {
        for (let field of mapping.additionalSearchFileds) {
          if (field && field.key && field.value) {
            const fieldValues = field.value.split('.')
            const val = this.paperInvoiceCustom[fieldValues[0]][fieldValues[1]]
            url += `&${field.key}=${val}`
          }
        }
      }

      // Lazily load input items
      return fetch(url)
        .then((res) => res.json())
        .then((res) => {
          let result = []
          res[mapping.rootProperty][mapping.listProperty].forEach((entry) => {
            if (!entry) return false // Promise.reject(new Error())

            try {
              result.push({
                label:
                  (entry[mapping.labelProperty]['lValue'] ||
                    entry[mapping.labelProperty]) +
                  (mapping.additionalLabelProperty &&
                  entry[mapping.additionalLabelProperty]
                    ? ' (' + entry[mapping.additionalLabelProperty] + ')'
                    : ''),
                value:
                  entry[mapping.valueProperty]['lValue'] ||
                  entry[mapping.valueProperty],
                fullInfo: entry
              })
            } catch {
              // unexpected data in resp. console.log it and go on
              console.error('Unexpected EGON search result')
              console.error(entry)
            }
          })

          // if no items found fot this search, leave the only item that was selected before the new search (so that the previously selected option doesnt get deleted)
          result =
            result.length > 0 ? result : [this.paperInvoiceCustom[fieldName]]
          this.items[fieldName] = result
          return result
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => (this.isLoading[fieldName] = false))
    },

    validatationHandler() {
      // Check if invoiceMethod has a value
      let result = false
      if (this.invoiceMethod) {
        if (this.invoiceMethod.type === this.digitalInvoice.type) {
          result = true
        } else if (this.invoiceMethod.type === this.paperInvoiceCustom.type) {
          const paperI = this.paperInvoiceCustom
          // validate all required fields have a value
          result = !!(
            paperI.city.value &&
            paperI.cap.value &&
            paperI.address.value &&
            paperI.number.value
          )
          if (!result) {
            // Touch all paper invoice fields, so that they show the red validation messages
            this.v$.paperInvoiceCustom.city.$touch()
            this.v$.paperInvoiceCustom.cap.$touch()
            this.v$.paperInvoiceCustom.address.$touch()
            this.v$.paperInvoiceCustom.number.$touch()
          }
        }
      }
      return result
    }
  },
  watch: {
    'paperInvoiceCustom.city.value'(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.items.city = [this.paperInvoiceCustom.city]
      }
    },
    'paperInvoiceCustom.cap.value'(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.items.cap = [this.paperInvoiceCustom.cap]
      }
    },
    'paperInvoiceCustom.address.value'(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.items.address = [this.paperInvoiceCustom.address]
      }
    },
    'paperInvoiceCustom.number.value'(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.items.number = [this.paperInvoiceCustom.number]
      }
    },

    searchCity(searchValue) {
      this.updateSelect(searchValue, 'city')
        .then(() => {
          this.paperInvoiceCustom.cap = {}
          this.paperInvoiceCustom.address = {}
          this.paperInvoiceCustom.number = {}

          this.items.cap = []
          this.items.address = []
          this.items.number = []
        })
        .catch(() => {
          // do nothing
        })
    },
    searchCap(searchValue) {
      this.updateSelect(searchValue, 'cap')
        .then((list) => {
          this.capList = list

          this.paperInvoiceCustom.address = {}
          this.paperInvoiceCustom.number = {}

          this.items.address = []
          this.items.number = []
        })
        .catch(() => {
          // do nothing
        })
    },
    searchAddress(searchValue) {
      this.updateSelect(searchValue, 'address')
        .then(() => {
          this.paperInvoiceCustom.number = {}

          this.items.number = []
        })
        .catch(() => {
          // do nothing
        })
    },
    searchNumber(searchValue) {
      this.updateSelect(searchValue, 'number').catch(() => {
        // do nothing
      })
    }
  },
  validations: {
    paperInvoiceCustom: {
      city: { required },
      cap: { required },
      address: { required },
      number: { required }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-custom {
  width: 242px;
  text-align: center;
  padding: 18px;
  color: black;
  border: 1px solid #ccd0e1;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
