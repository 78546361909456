<template>
  <div class="identityDoc">
    <h5 class="identityDoc__info mb-6">
      Associa il tuo documento di attivazione
    </h5>
    <template v-if="!identityData.show">
      <div class="identityDoc__btn" @click="setDataDoc('card')">
        <h5>Carta d’identità</h5>
      </div>
      <div class="identityDoc__btn mb-10" @click="setDataDoc('passport')">
        <h5>Passaporto</h5>
      </div>
      <h5 class="identityDoc__info">Perchè serve il documento?</h5>
      <h5 class="identityDoc__desc">
        Verificare la tua identità è un obbligo di legge.<br />Per procedere
        all’attivazione dell'offerta dovrai fornire i dati del tuo documento
        d'identità (in ottemperanza al DL n. 144 del 27/07/2005).
      </h5>
    </template>
    <template v-else>
      <v-select
        :items="nationalityList"
        label="Nazionalità"
        v-model.trim="identityData.nationality"
        @blur="v$.identityData.nationality.$touch()"
        :error-messages="errorNationIC"
      ></v-select>
      <v-text-field
        :label="
          identityData.type === 'card'
            ? 'Numero Carta d\'identità'
            : 'Numero Passaporto'
        "
        class="auth__input"
        v-model.trim="identityData.number"
        @blur="v$.identityData.number.$touch()"
        :error-messages="errorNumberDoc"
      ></v-text-field>
      <v-menu
        v-model="fromDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="Scadenza"
            readonly
            :value="$filters.formatDateIntl(identityData.deadline)"
            @blur="v$.identityData.deadline.$touch()"
            :error-messages="errorDealine"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          locale="en-in"
          v-model.trim="identityData.deadline"
          no-title
          @input="fromDateMenu = false"
        ></v-date-picker>
      </v-menu>
      <v-text-field
        :label="
          identityData.type === 'card' ? 'Rilasciata da' : 'Rilasciato da'
        "
        class="auth__input mb-6"
        v-model.trim="identityData.releasedBy"
        @blur="v$.identityData.releasedBy.$touch()"
        :error-messages="errorReleasedBy"
      ></v-text-field>
      <v-btn
        color="primary"
        x-large
        width="260"
        class="v-btn--shadowed"
        :disabled="v$.identityData.$invalid"
        @click="addIdentityDoc"
        >Aggiungi</v-btn
      >
      <v-btn
        text
        x-large
        color="primary"
        class="white ml-5"
        width="300"
        @click="resetDataDoc"
        >Annulla</v-btn
      >
    </template>
    <v-row class="mt-6 stickyRow" no-gutters v-if="!docType">
      <v-col>
        <div class="infobox">
          <span>{{
            !speedUpProcess
              ? "Non hai anticipato l'attivazione"
              : "Hai anticipato l'attivazione"
          }}</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import GenErr from '@/js/genericErrors'
import { validateIdentityDocument } from '@/js/utils'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'identityDoc',
  data() {
    return {
      identityData: {
        type: '',
        show: false,
        nationality: '',
        number: '',
        deadline: null,
        releasedBy: ''
      },
      fromDateMenu: false,
      nationalityList: [],
      docType: ''
    }
  },
  beforeMount() {
    this.getNationality()
      .then((resp) => {
        this.nationalityList = resp
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  created() {
    if (this.identityDocument !== null) {
      // this.identityData = { ...this.identityDocument, show: true }
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['identityDocument', 'speedUpProcess']),
    errorNationIC() {
      let errArray = []

      if (!this.v$.identityData.nationality.$dirty) return errArray

      this.v$.identityData.nationality.required.$invalid === true &&
        errArray.push('Seleziona nazionalità.')
      if (this.v$.identityData.nationality.$model === 'Italia') {
        this.v$.identityData.nationality.$touch()
      }

      return errArray
    },
    errorNumberDoc() {
      let errArray = []
      if (!this.v$.identityData.number.$dirty) return errArray

      this.v$.identityData.number.required.$invalid === true &&
        (this.identityData.type === 'card'
          ? errArray.push('Inserisci un numero di carta d’identità valido.')
          : errArray.push('Inserisci un numero di passaporto valido.'))
      this.v$.identityData.number.validateIdentityDocument.$invalid &&
        (this.identityData.type === 'card'
          ? errArray.push('Inserisci un numero di carta d’identità valido.')
          : errArray.push('Inserisci un numero di passaporto valido.'))

      return errArray
    },
    errorDealine() {
      let errArray = []
      if (!this.v$.identityData.deadline.$dirty) return errArray

      this.v$.identityData.deadline.$invalid === true &&
        errArray.push('Seleziona scadenza.')
      return errArray
    },
    errorReleasedBy() {
      let errArray = []
      if (!this.v$.identityData.releasedBy.$dirty) return errArray
      this.v$.identityData.releasedBy.$invalid === true &&
        errArray.push('Inserisci il luogo di rilascio del documento.')
      return errArray
    }
  },
  methods: {
    ...mapActions('fiber', ['getNationality']),
    ...mapMutations('upsellingFiber', ['setIdentityDocument']),
    ...mapActions('analytics', ['trackTapEvent']),
    setDataDoc(type) {
      if (
        this.$store.getters['upsellingFiber/identityDocument'] !== null &&
        this.$store.getters['upsellingFiber/identityDocument'].type === type
      ) {
        this.identityData = { ...this.identityDocument, show: true }
      } else {
        this.identityData.show = true
        this.identityData.type = type
      }
      if (type === 'card') {
        this.trackTapEvent({
          name: 'upsellingv2_identitycard_tap',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else {
        this.trackTapEvent({
          name: 'upsellingv2_passport_tap',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      }
      this.docType = type
    },
    resetDataDoc() {
      this.identityData = {
        type: '',
        show: false,
        nationality: '',
        number: '',
        deadline: null,
        releasedBy: ''
      }
      if (this.docType === 'card') {
        this.trackTapEvent({
          name: 'upsellingv2_identitycard_undo',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else {
        this.trackTapEvent({
          name: 'upsellingv2_passport_undo',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      }
      this.docType = ''
    },
    addIdentityDoc() {
      this.setIdentityDocument(this.identityData)
      this.$emit('nextStep', 'upsellingv2_paymentmehtod_open')
    }
  },
  validations: {
    identityData: {
      number: { required, validateIdentityDocument },
      nationality: { required },
      deadline: {},
      releasedBy: {}
    }
  }
}
</script>
<style lang="scss" scoped>
.stickyRow {
  position: fixed;
  bottom: 20px;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
  }
  100% {
    opacity: 0;
  }
}

.infobox {
  background-color: #606060;
  color: white;
  width: 300px;
  display: flex;
  padding: 15px 20px 15px 10px;
  border-radius: 5px;
  height: 50px;
  font-size: 14px;
  -webkit-animation: fadeOut 4s;
  animation: fadeOut 4s;
  animation-fill-mode: forwards;
}

.identityDoc {
  max-width: 652px;
  color: black;
  &__info {
    font-weight: 500;
  }
  &__desc {
    line-height: 1.25;
  }
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 66px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
}
</style>
