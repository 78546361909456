var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing"},[_vm._m(0),_c('div',{staticClass:"landing--intro"},[_c('div',{staticClass:"d-flex justify-space-between align-center content"},[_c('div',{class:`landing--intro__text ${
          _vm.memberStatus.played_welcome_iw ? 'full mb-7' : ''
        }`},[_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"bkg-sky mt-12"},[_c('div',{staticClass:"content"},[_vm._m(3),_c('div',{staticClass:"d-flex justify-space-between"},[_vm._m(4),_c('div',{staticClass:"landing--intro__cards"},[_c('card',{staticClass:"card0",attrs:{"imgSrc":require('../../../assets/greeners/card-fidelity.png'),"title":"SCEGLI SORGENIA PER LE TUE UTENZE","label":"Ogni mese ricevi Green Coins per ogni tua utenza Sorgenia attiva"}}),_c('card',{staticClass:"card1",attrs:{"imgSrc":require('../../../assets/greeners/card-missions.png'),"title":"PARTECIPA ALLE MISSIONI","label":"Mettiti in gioco: per ogni missione superata ricevi Green Coins"}})],1)]),_vm._m(5),_c('div',{staticClass:"d-flex justify-space-between"},[_vm._m(6),(_vm.catalog.length > 0)?_c('div',{staticClass:"landing--intro__cards"},_vm._l((_vm.catalog),function(item,i){return _c('card',{key:i,class:'card' + i,attrs:{"imgSrc":item.attributes.images.data[0].image_small,"title":item.attributes.title,"coins":item.attributes.price}})}),1):_vm._e()])]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"v-btn--shadowed mb-4 ButtonL",attrs:{"color":"primary","height":"54","width":"257"},on:{"click":function($event){_vm.$router.push({ name: 'loyaltyPage' }),
              _vm.trackTapEvent({
                name: 'greeners_diaryHowWorksBack_tap',
                params: {
                  client_code: _vm.mainClientLoyaltySelected.clientOwnerCode,
                  client_type: _vm.mainClientLoyaltySelected.clientOwnerType
                }
              })}}},[_vm._v(" Torna Indietro ")])],1),_c('div',{staticClass:"spacer"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-space-between align-center landing--header"},[_c('div',{staticClass:"bkg"}),_c('div',{staticClass:"logo"},[_c('img',{attrs:{"width":"290","src":require("@/assets/greeners/svg/logoGreeners_new.svg")}}),_c('h2',{staticClass:"logo--title"},[_vm._v("La Community sostenibile di Sorgenia")])]),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/greeners/landing-page-header.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"mb-4"},[_vm._v(" Greeners è la "),_c('strong',[_vm._v("community di Sorgenia")]),_vm._v(" fatta di persone come te, che "),_c('strong',[_vm._v("pensano all’ambiente")]),_vm._v(" nelle loro scelte quotidiane e che "),_c('strong',[_vm._v("uniscono il loro impegno")]),_vm._v(" per aiutare insieme il pianeta. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"mb-9"},[_c('strong',[_vm._v("Le tue azioni possono fare la differenza")]),_vm._v(": per questo puoi essere un Greener anche se non sei un cliente Sorgenia! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing--intro__text"},[_c('h1',{staticClass:"BodyXL mb-12"},[_vm._v("Come funziona?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing--intro__step"},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"circle mr-8"},[_vm._v("1")]),_c('div',{staticClass:"desc"},[_c('h2',[_vm._v("Accumula Green Coins")]),_c('h5',{staticClass:"BodyXL"},[_vm._v(" Più sono le "),_c('strong',[_vm._v("azioni green")]),_vm._v(" che porterai a termine e"),_c('br'),_vm._v(" più saranno i Green Coins accumulati. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"landing--intro__step"},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"circle mr-8"},[_vm._v("2")]),_c('div',{staticClass:"desc"},[_c('h2',[_vm._v("Sali di livello")]),_c('h5',{staticClass:"BodyXL"},[_vm._v(" Ogni "),_c('strong',[_vm._v("8 Missioni")]),_vm._v(" completate, sali di livello e ricevi un premio in Green Coins di valore crescente per un totale di 2000 Green Coins extra ricevuti! ")])])])]),_c('div',{staticClass:"pos-relative"},[_c('img',{staticClass:"landing--intro__img-levels",attrs:{"src":require("../../../assets/greeners/landing-page-levels.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing--intro__step"},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"circle mr-8"},[_vm._v("3")]),_c('div',{staticClass:"desc"},[_c('h2',[_vm._v("Premi per te e per tutti")]),_c('h5',{staticClass:"BodyXL"},[_vm._v(" Con i tuoi Green Coins puoi scegliere premi a "),_c('strong',[_vm._v("catalogo")]),_vm._v(" che fanno bene a te, all’ambiente e alla comunità. ")])])])])
}]

export { render, staticRenderFns }