<template>
  <layout header-size="small" class="quiz" v-if="mission">
    <template v-slot:header>
      <v-col cols="6">
        <button
          type="button"
          class="loyalty__header-back mb-2"
          @click="
            trackTapEvent({
              name: 'greeners_mission_close',
              params: {
                client_code: mainClientLoyaltySelected.clientOwnerCode,
                client_type: mainClientLoyaltySelected.clientOwnerType,
                mission_id: mission.id
              }
            }),
              $router.push({ name: 'loyaltyPage' })
          "
        >
          <v-icon color="#12256A" size="20">mdi-chevron-left</v-icon>
          <span>Torna al Diario</span>
        </button>
        <h1>{{ mission.welcome_page_title }}</h1>
      </v-col>
      <v-spacer cols="1" />
      <v-col cols="5">
        <v-img
          contain
          width="290"
          src="@/assets/greeners/svg/logoGreeners_new.svg"
        />
      </v-col>
    </template>
    <common-content
      v-if="step === 'info'"
      :coins="missionReward"
      :btnLabel="mission.welcome_page_button_label"
      :imageSrc="CmsUrl + mission.welcome_page_image"
      instruction="Mettiti alla prova!"
      picSize="large"
      :isButtonSeparated="false"
      @buttonClick="proceed"
      :achievement="true"
    >
      <template v-slot:text>
        <v-row class="mb-6" no-gutters="">
          <v-col cols="1">
            <v-img
              class="header__icon"
              width="35"
              :src="require('@/assets/greeners/svg/mission-quiz.svg')"
            />
          </v-col>
          <v-col class="pl-0 pr-0 align-self-center"
            ><h6>QUIZ - SCADE IL {{ expirationDate }}</h6></v-col
          >
        </v-row>
        <h4 class="mb-6" v-html="mission.welcome_page_text" />
      </template>
    </common-content>
    <div v-else class="content-quiz">
      <v-row class="mt-8">
        <v-col class="d-flex align-center">
          <img src="../../assets/greeners/svg/mission-quiz.svg" width="35" />
          <div class="name ml-2">QUIZ - scade il {{ expirationDate }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-progress-linear
            :value="progressValue"
            color="#97D707"
            background-color="#dbdbdb"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>{{ mission.questions[numberQuestion - 1].text }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div
            class="question"
            v-for="(response, i) in questionView"
            :key="i"
            @click="setCorrectQuestion(response, i)"
            :class="response.class"
          >
            <div class="circle">
              {{ isCorrectQuestion ? '' : alphabetId[i] }}
            </div>
            <div class="response">{{ response.label }}</div>
          </div>
        </v-col>
        <v-col cols="6">
          <div
            v-if="isCorrectQuestion && isCorrectQuestion.value"
            class="explication ml-8"
          >
            <h2>Risposta {{ explication }}</h2>
            <div class="detail">
              {{ mission.questions[numberQuestion - 1].explication }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isCorrectQuestion" class="mt-12">
        <v-col cols="12" align="center">
          <v-btn
            class="v-btn--shadowed"
            :rounded="true"
            @click="next()"
            large
            width="270"
            color="#97D707"
          >
            {{ btnLabel }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </layout>
</template>
<script>
import { ENV_CONFIGS } from '@/js/configs.js'
import Layout from './Layout'
import CommonContent from './CommonContent'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'quizComponent',
  data() {
    return {
      CmsUrl: ENV_CONFIGS.CMS_CDN_BASE_URL,
      mission: null,
      step: 'info',
      numberQuestion: 1,
      isCorrectQuestion: null,
      savedAnswers: [],
      answerCorrect: 0,
      btnLabel: 'Prossima domanda'
    }
  },
  components: {
    Layout,
    CommonContent
  },
  beforeMount() {
    this.getWekitMap()
    this.getQuiz().then((res) => {
      const condition = res.filter((m) => m.id === this.$route.params.id)
      if (condition.length) {
        this.mission = condition[0]
      }
    })
    this.getMainClient().then((resp) => {
      if (this.mainClientLoyaltySelected) {
        resp = this.mainClientLoyaltySelected
      }
      this.getDetailForClient({ clientOwnerCode: resp.clientOwnerCode }).then(
        (res) => {
          this.getClientLevel({
            ext_uuid: this.mainClientLoyaltySelected.clientOwnerCode
          })
        }
      )
    })
  },
  computed: {
    ...mapGetters('loyalty', ['wekitMap', 'quiz', 'clientLevel']),

    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    progressValue() {
      return (this.numberQuestion * 100) / this.mission.questions.length
    },
    questionView() {
      return this.mission.questions[this.numberQuestion - 1].answers.map(
        (r, i) => {
          if (this.isCorrectQuestion && this.isCorrectQuestion.value) {
            if (r.isCorrect) {
              r.class = 'correct'
            } else {
              r.class = ''
            }
          }
          if (this.isCorrectQuestion && !this.isCorrectQuestion.value) {
            if (r.isCorrect) {
              r.class = 'correct opacity'
            } else if (this.isCorrectQuestion.id === i) {
              r.class = 'wrong'
            } else {
              r.class = 'wrong border'
            }
          }
          return r
        }
      )
    },
    missionReward() {
      if (!this.mission) return null
      return this.missionValue(
        this.mission.wekit_action_id,
        this.mission.wekit_event_id
      )
    },
    explication() {
      if (this.isCorrectQuestion && !this.isCorrectQuestion.value) return ''
      if (this.isCorrectQuestion && this.isCorrectQuestion.value)
        return 'corretta'
      else return 'sbagliata!'
    },
    expirationDate() {
      if (this.mission && this.mission.end_date) {
        return new Date(this.mission.end_date).toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      }
      return '---'
    },
    alphabetId() {
      const alpha = Array.from(Array(26)).map((e, i) => i + 65)
      return alpha.map((x) => String.fromCharCode(x))
    }
  },
  methods: {
    ...mapActions('loyalty', [
      'getWekitMap',
      'getQuiz',
      'getClientLevel',
      'newTransaction'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),

    ...mapActions('account', ['getMainClient', 'getDetailForClient']),
    saveAnswer(response) {
      this.answerCorrect =
        this.answerCorrect + (this.isCorrectQuestion.value ? 1 : 0)
      let answers = this.mission.questions[this.numberQuestion - 1].answers.map(
        (answer) => {
          return {
            answer_id: answer.id,
            answer_label: answer.label,
            answer_value: answer.value
          }
        }
      )
      let question = {
        question_id: this.mission.questions[this.numberQuestion - 1].id,
        question_type: this.mission.questions[this.numberQuestion - 1].type,
        question_value:
          this.mission.questions[this.numberQuestion - 1].text.trim(),
        answers: answers,
        user_answers: [
          {
            answer_id: response.id,
            answer_label: response.label,
            answer_value: response.value,
            correct: this.isCorrectQuestion.value === true
          }
        ]
      }

      this.savedAnswers.push(question)
    },
    setCorrectQuestion(response, index) {
      if (this.numberQuestion >= this.mission.questions.length) {
        this.btnLabel = 'Vai al risultato'
      }
      if (!this.isCorrectQuestion) {
        this.isCorrectQuestion = response.isCorrect
          ? { value: true, id: index }
          : { value: false, id: index }
      }
      this.saveAnswer(response)
    },
    next() {
      if (this.numberQuestion >= this.mission.questions.length) {
        const body = {
          event: this.mission.wekit_event_id,
          action: this.mission.wekit_action_id,
          detail1: this.mission.id,
          detail2: this.clientLevel.level_name,
          detail3: this.mission.title,
          extra: {
            mission_id: this.mission.id,
            mission_name: this.mission.title,
            questions: this.savedAnswers,
            score: this.answerCorrect + '/' + this.mission.questions.length
          }
        }
        this.newTransaction(body).then((res) => {
          if (res)
            this.$router.push({
              name: 'successQuiz',
              params: {
                nAnswersCorrect: this.answerCorrect,
                totalAnswers: this.mission.questions.length,
                id: this.$route.params.id
              }
            })
        })
      } else {
        this.numberQuestion++
        this.isCorrectQuestion = null
      }
    },
    missionValue(actionId, eventId) {
      let targetMission = this.wekitMap.filter(
        (mission) =>
          mission.action_id === actionId && mission.event_id === eventId
      )
      if (targetMission.length) {
        return targetMission[0].points
      } else return 0
    },
    proceed() {
      this.trackTapEvent('greeners_mission_start_tap')
      this.step = 'quiz'
    }
  }
}
</script>
<style lang="scss" scoped>
.content-quiz {
  width: 100%;
  .name {
    font-size: 14px;
    font-weight: normal;
  }
}
.explication {
  .detail {
    margin-top: 16px;
    line-height: 2.12;
    font-size: 20px;
    color: black;
  }
}
.question {
  width: 509px;
  height: 64px;
  padding: 5px 39px 5px 24px;
  border-radius: 27px;
  border: solid 1.1px #dbdbdb;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  color: black;
  .circle {
    width: 26px;
    height: 26px;
    margin-right: 24px;
    border-radius: 13px;
    border: solid 1px #ccd0e1;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.correct {
    background-color: #97d707;
    border-color: #97d707;
    font-weight: bold;
    color: white;
    font-size: 20px;
    .circle {
      border: solid 1px #97d707;
      background-image: url('../../assets/greeners/ic-radiobutton-selected-ok.svg');
      background-position: center;
      background-size: contain;
    }
    &.opacity {
      background-color: #97d707;
      opacity: 0.4;
    }
  }
  &.wrong {
    background-color: #ff3838;
    border-color: #ff3838;
    font-weight: bold;
    color: white;
    font-size: 20px;
    .circle {
      border: solid 1px #ff3838;
      background-image: url('../../assets/greeners/ic-radiobutton-selected-ko.png');
      background-position: center;
      background-size: contain;
    }
    &.border {
      border: solid 1px #ccd0e1;
      background-color: white;
      color: black;
      font-size: 16px;
      font-weight: 100;
    }
  }
}
</style>
