var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"survey mt-10"},[_c('h5',{staticClass:"mb-1"},[_vm._v("Auto")]),_c('h4',{staticClass:"mb-6"},[_vm._v(" Tieni aggiornate le informazioni sulla tua auto: ti daremo consigli più accurati sulle opportunità energetiche e sugli sviluppi di MyNextMove, la nostra app della mobilità elettrica. ")]),_c('v-expansion-panels',{model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{staticClass:"survey__box mb-6",on:{"click":_vm.selectMobility}},[_c('v-expansion-panel-header',[_c('h4',[_vm._v("La mia auto")])]),_c('v-expansion-panel-content',[_c('div',{class:`survey__item mb-4 mt-4 ${
            _vm.mobilityData.saved ? 'edit' : ''
          }`,on:{"click":_vm.openSurvey}},[_c('h6',[_vm._v(" Questionario 1"),_c('img',{staticClass:"ml-6 mr-1",attrs:{"src":require("../../../assets/profile/clock.svg")}}),_vm._v("1 min ")]),_c('h6',{staticClass:"bold"},[_vm._v("Cosa guidi?")])]),(_vm.mobilityData.completed)?_c('div',{staticClass:"survey__suggestion ButtonS mt-6",on:{"click":function($event){return _vm.$router.push({
              name: 'serviceSection',
              params: { type: 'mobility' }
            })}}},[_vm._v(" Scopri le offerte su misura per te ")]):(_vm.mobilityData.saved)?_c('div',{staticClass:"survey__suggestion ButtonS mt-6",on:{"click":_vm.openSurvey}},[_vm._v(" Finisci di compilare il questionario! ")]):_vm._e()])],1)],1),_c('v-dialog',{attrs:{"max-width":"555"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialogSurvey"},[(_vm.step > 0)?_c('div',{staticClass:"d-flex justify-space-between align-center mb-6"},[_c('h3',[_vm._v("La mia auto")]),_c('img',{staticClass:"close",attrs:{"src":require("../../../assets/icons/ic-close.svg")},on:{"click":_vm.exitSurveyDialog}})]):_vm._e(),(_vm.step === 1)?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.step)+" di 2")]),_c('div',{staticClass:"question car mb-6"},[_c('h4',[_vm._v("Che tipo di auto hai?")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.mobilityData.tipoAuto === _vm.TypeAuto.ELECTRIC ? 'selected' : ''
          }`,on:{"click":function($event){_vm.mobilityData.tipoAuto = _vm.TypeAuto.ELECTRIC}}},[_c('h6',[_vm._v("Elettrica")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.mobilityData.tipoAuto === _vm.TypeAuto.HYBRID ? 'selected' : ''
          }`,on:{"click":function($event){_vm.mobilityData.tipoAuto = _vm.TypeAuto.HYBRID}}},[_c('h6',[_vm._v("Ibrida")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.mobilityData.tipoAuto === _vm.TypeAuto.TRADITIONAL ? 'selected' : ''
          }`,on:{"click":function($event){_vm.mobilityData.tipoAuto = _vm.TypeAuto.TRADITIONAL}}},[_c('h6',[_vm._v("Tradizionale")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.mobilityData.tipoAuto === _vm.TypeAuto.NONE ? 'selected' : ''
          }`,on:{"click":function($event){_vm.mobilityData.tipoAuto = _vm.TypeAuto.NONE}}},[_c('h6',[_vm._v("Nessuna")])])]):_vm._e(),(_vm.step === 2 && _vm.mobilityData.tipoAuto === 'Elettrica')?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.step)+" di 2")]),_c('div',{staticClass:"question battery mb-6"},[_c('h4',[_vm._v("Qual è la capienza della tua batteria?")])]),_c('v-slider',{staticClass:"mb-6 batteryHouse",attrs:{"thumb-size":30,"thumb-color":"#1197AD","thumb-label":"always","track-color":"#DFE4EF","loader-height":"10","step":"10","min":"0","max":"88"},model:{value:(_vm.mobilityData.capienzaBatteria),callback:function ($$v) {_vm.$set(_vm.mobilityData, "capienzaBatteria", $$v)},expression:"mobilityData.capienzaBatteria"}}),_c('div',{staticClass:"question wallbox mb-6"},[_c('h4',[_vm._v("Possiedi una wallbox a casa?")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.mobilityData.wallbox === _vm.Wallbox.YES ? 'selected' : ''
          }`,on:{"click":function($event){_vm.mobilityData.wallbox = _vm.Wallbox.YES}}},[_c('h6',[_vm._v("Sì, già ho un wallbox installato")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.mobilityData.wallbox === _vm.Wallbox.NOT_YET ? 'selected' : ''
          }`,on:{"click":function($event){_vm.mobilityData.wallbox = _vm.Wallbox.NOT_YET}}},[_c('h6',[_vm._v("Non ancora, ma ci sto pensando")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.mobilityData.wallbox === _vm.Wallbox.CANNOT ? 'selected' : ''
          }`,on:{"click":function($event){_vm.mobilityData.wallbox = _vm.Wallbox.CANNOT}}},[_c('h6',[_vm._v("Non potrei metterlo")])])],1):_vm._e(),(_vm.step === 2 && _vm.mobilityData.tipoAuto !== _vm.TypeAuto.ELECTRIC)?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.step)+" di 2")]),_c('div',{staticClass:"question key mb-6"},[_c('h4',[_vm._v("Ti interessa la mobilità elettrica?")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.mobilityData.interesse === _vm.Interest.RECALL ? 'selected' : ''
          }`,on:{"click":function($event){_vm.mobilityData.interesse = _vm.Interest.RECALL}}},[_c('h6',[_vm._v("Sì, mi interessa e vorrei saperne di più")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.mobilityData.interesse === _vm.Interest.NOT_INTERESTED
              ? 'selected'
              : ''
          }`,on:{"click":function($event){_vm.mobilityData.interesse = _vm.Interest.NOT_INTERESTED}}},[_c('h6',[_vm._v("No, non mi interessa la mobilità elettrica")])])]):_vm._e(),(!Number.isInteger(_vm.step))?_c('Exit',{attrs:{"dialog":_vm.dialog,"type":"mobility"},on:{"update:dialog":function($event){_vm.dialog=$event},"backToStep":_vm.backToStep,"saveSurvey":_vm.saveSurvey}}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.step > 1),expression:"step > 1"}],staticClass:"white v-btn--shadowed",attrs:{"width":"152","height":"54"},on:{"click":_vm.prev}},[_vm._v("Indietro")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.step > 0),expression:"step > 0"}],staticClass:"primary v-btn--shadowed ml-auto",attrs:{"width":"152","height":"54","disabled":_vm.isDisabled},on:{"click":_vm.next}},[_vm._v(_vm._s(_vm.step === 2 ? 'Conferma' : 'Avanti'))])],1)],1)]),_c('Completed',{attrs:{"dialog":_vm.dialogCompleted,"type":"mobility"},on:{"update:dialog":function($event){_vm.dialogCompleted=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }