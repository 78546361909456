<template>
  <div class="documents">
    <TcVars />
    <template
      v-if="
        Object.keys(contractsChanged).length > 0 ||
        Object.keys(contracts).length > 0 ||
        Object.keys(notDigitalContracts).length > 0
      "
    >
      <div class="documents__container-title HeadingsTitleM">
        <span>I tuoi contratti</span>
      </div>
      <div
        v-for="(contr, i) in Object.keys(contractsChanged)"
        :key="`${i}_nameChanged`"
      >
        <div class="documents__list-contracts">
          <div
            class="documents__contract"
            v-if="contractsChanged[contr].length"
          >
            <div class="documents__contract-info">
              <h5 class="CaptionL">
                {{
                  labelCommodityType(contractsChanged[contr][0].commodityType)
                }}
              </h5>
              <h5 class="BodyL">
                {{ contractsChanged[contr][0].cdPropostaContratto }}
              </h5>
            </div>
            <div class="documents__contract-item">
              <div
                v-for="document in contractsChanged[contr]"
                :key="document.id"
                class="documents__contract-item-action"
              >
                <span
                  class="documents__contract-file color--blue"
                  @click="(event) => getContractFileUrl(event, document)"
                  >{{ $filters.descFile(document) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(contr, i) in Object.keys(contracts)" :key="`${i}__name`">
        <div class="documents__list-contracts">
          <div class="documents__contract" v-if="contracts[contr].length">
            <div class="documents__contract-info">
              <h5 class="CaptionL">
                {{
                  labelCommodityType(
                    contracts[contr][0].commodityType,
                    contracts[contr][0].commodityTypeFibra
                  )
                }}
              </h5>
              <h5 class="BodyL">
                {{
                  contracts[contr][0].cdPropostaContratto ||
                  contracts[contr][0].cdPropostaContrattoFibra
                }}
              </h5>
            </div>
            <div class="documents__contract-item">
              <div
                v-for="document in contracts[contr]"
                :key="document.id"
                class="documents__contract-item-action"
              >
                <span
                  class="documents__contract-file color--blue"
                  @click="(event) => getContractFileUrl(event, document)"
                  >{{ $filters.descFile(document) }}</span
                >
              </div>
            </div>
            <div
              v-if="
                !checkGroupFile(contracts[contr][0].cdPropostaContratto) &&
                !checkGroupFile(contracts[contr][0].cdPropostaContrattoFibra)
              "
              class="d-flex align-center"
            >
              <div
                v-if="
                  showAfterthoughtButton[
                    contracts[contr][0].cdPropostaContratto
                  ]
                "
                class="documents__afterthought-item mt-2 mr-6"
                @click="
                  openDialogAfterthought(
                    contracts[contr][0],
                    contracts[contr][0].commodityType
                  )
                "
              >
                <div class="ButtonS color--blue">
                  Ripensamento
                  {{ formatCommodityType(contracts[contr][0].commodityType) }}
                </div>
              </div>
              <div
                v-if="
                  contracts[contr][0] &&
                  showAfterthoughtButton[
                    contracts[contr][0].cdPropostaContrattoFibra
                  ]
                "
                class="documents__afterthought-item mt-2 mr-6"
                @click="
                  openDialogAfterthought(
                    contracts[contr][0],
                    contracts[contr][0].commodityTypeFibra
                  )
                "
              >
                <div class="ButtonS color--blue">
                  Ripensamento
                  {{
                    formatCommodityFiberType(
                      contracts[contr][0].commodityTypeFibra
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(contr, i) in Object.keys(notDigitalContracts)"
        :key="`${i}__nameNoDigital`"
      >
        <div class="documents__list-contracts">
          <div
            class="documents__contract"
            v-if="notDigitalContracts[contr].length"
          >
            <div class="documents__contract-info">
              <h5 class="CaptionL">
                {{
                  consts.SUPPLY_TYPES[
                    notDigitalContracts[contr][0].commodityType.toLowerCase()
                  ]
                }}
              </h5>
              <h5 class="BodyL">
                {{ notDigitalContracts[contr][0].codProposta }}
              </h5>
            </div>
            <div class="documents__contract-item">
              <div
                v-for="(doc, k) in notDigitalContracts[contr]"
                :key="k"
                class="documents__contract-item-action"
              >
                <span
                  class="documents__contract-file color--blue"
                  @click="
                    (event) => getUrlForFile(event, doc.url, doc.documentId)
                  "
                  >{{ doc.descrizione }}</span
                >
              </div>
            </div>
            <div
              v-if="
                contr[0] &&
                contr[0].codProposta &&
                showAfterthoughtButton[contr[0].codProposta]
              "
              class="d-flex align-center"
            >
              <div
                class="documents__afterthought-item mt-2 mr-6"
                @click="
                  openDialogAfterthought(contracts[contr][0], 'commodity')
                "
              >
                <div class="ButtonS color--blue">
                  Ripensamento
                  {{
                    consts.SUPPLY_TYPES[
                      notDigitalContracts[contr][0].commodityType.toLowerCase()
                    ]
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="documents__container-title HeadingsTitleM">
        <span>I tuoi contratti</span>
      </div>
      <p class="BodyL">
        Non vedi il tuo contratto? Consulta la tua casella di posta e verifica i
        documenti inviati a seguito della sottoscrizione.
      </p>
    </template>

    <template v-if="infoDoc">
      <div class="documents__container-title HeadingsTitleM">
        <span>Documenti utili</span>
      </div>
      <div class="documents__list-docs">
        <div
          :key="i"
          v-for="(item, i) in infoDoc"
          class="documents__docs-item mb-6"
          @click="(event) => getDocumentFileUrl(event, item)"
        >
          <div class="ButtonM color--blue">{{ item.name }}</div>
        </div>
      </div>
    </template>

    <template
      v-if="!contractsChanged && contracts && notDigitalContracts && infoDoc"
    >
      <div class="documents__empty">
        <img
          class="mb-8"
          src="../../assets/profile/empty_document.svg"
          width="140"
        />
        <div class="HeadingsSubtitleL mb-4">Nessun contratto o documento</div>
        <div class="BodyM">
          Qui troverai i tuoi contratti e i documenti utili per i servizi
          attivi. Attiva le offerte per i nuovi clienti e scopri quanto puoi
          risparmiare. Più utenze attivi più risparmi!
        </div>
      </div>
    </template>

    <v-progress-circular
      v-if="showContractsLoader"
      class="contracts-loader"
      :size="50"
      indeterminate
    ></v-progress-circular>

    <pdf-viewer
      :showDialog="dialogContractPdf && !cancelDowloadPdf"
      :url="contractDataUrl"
      @closing-pdfViewer="closePdfViewer"
      :attachTo="'body'"
      height="600px"
    />
    <Download
      :dialogDownload="dialogDownload"
      @closeModal="cancelDowload"
    ></Download>

    <PhysiCallDialog
      :physicallObj="physiCall"
      :triggerElement.sync="triggerElement"
    ></PhysiCallDialog>

    <FeedbackDialog
      :triggerElement.sync="triggerElement"
      :physicallObj="physiCall"
    />
    <v-dialog v-model="dialogAfterthought" max-width="576">
      <div class="ripensamento-modal">
        <img
          src="@/assets/ic_chevron_top_black.png"
          alt="close"
          class="modal-close"
          @click="dialogAfterthought = false"
        />
        <div>
          <div class="ripensamento-modal__title">Ripensamento</div>
          <div class="ripensamento-modal__text">
            Questa sezione ti permette di esercitare il diritto di ripensamento
            entro i 14 giorni successivi alla data di perfezionamento del
            Contratto Sorgenia.
          </div>
          <div class="ripensamento-modal__text">
            Codice contratto<br /><strong>{{
              selectedContractType === 'commodity'
                ? selectedContract.cdPropostaContratto ||
                  selectedContract.codProposta
                : selectedContract.cdPropostaContrattoFibra
            }}</strong>
          </div>
          <div class="ripensamento-modal__continue full-bt-group">
            <v-btn
              class="bt-modal--primary"
              text
              @click="
                $router.push({
                  name: 'afterthought',
                  params: {
                    clientDetail: clientDetails,
                    selectedContract: selectedContract,
                    selectedContractType: selectedContractType
                  }
                })
              "
            >
              Prosegui
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { axiosACN, axiosCMS } from '@/js/axiosInstances.js'
import consts from '@/js/constants'
import { b64ToBlob } from '@/js/utils'
import { mapActions, mapGetters, mapState } from 'vuex'

import Download from '@/components/download/Download.vue'
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import TcVars from '@/components/tcTracker/TcVars'

import PhysiCall from '../../js/service/physiCall'
import PhysiCallDialog from '../../components/physicall/PhysiCallDialog.vue'

import _ from 'lodash'

import FeedbackDialog from '../../components/physicall/FeedbackDialog.vue'

export default {
  name: 'documentsProfile',
  data() {
    return {
      contractsChanged: {},
      contracts: {},
      notDigitalContracts: [],
      showAfterthoughtButton: {},
      numContractsFound: null,
      contractDataUrl: '',
      showContractsLoader: false,
      dialogContractPdf: false,
      dialogDownload: false,
      cancelDowloadPdf: false,
      infoDoc: null,
      consts: consts,
      dialogAfterthought: false,
      selectedContract: {},
      selectedContractType: '',
      physiCall: null,
      triggerElement: null
    }
  },
  components: {
    PdfViewer,
    Download,
    TcVars,
    PhysiCallDialog,
    FeedbackDialog
  },
  watch: {
    userProfile(val) {
      this.getDetailForClient(val).then(() => {
        this.init()
      })
    }
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'accountProperties'
    ]),
    ...mapGetters('profile', ['userProfile']),
    ...mapState('session', ['isFeedbackShowing'])
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    this.physiCall.clearTimer()
  },
  methods: {
    ...mapActions('account', [
      'getContractsForClient',
      'getNotDigitalContracts',
      'getChangedProductContract',
      'getRetrieveRipensamento',
      'getDetailForClient',
      ''
    ]),
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('documents', ['getDocsByCategory']),
    async init() {
      if (this.physiCall) {
        this.physiCall.resetAll()
      }
      this.physiCall = new PhysiCall(120000, 120000, 2)
      this.physiCall.setJourneySection(this.consts.JOURNEY.TIMER_DOCUMENTI)
      this.getSupplies()

      if (!this.mainClient.isOnlyFV) this.getAllContract()
      if (this.mainClient.isOnlyFV == true) {
        this.infoDoc = await this.getDocsByCategory('documenti-fotovoltaico')
      } else {
        this.infoDoc = await this.getDocsByCategory('documenti-utili')
        if (
          this.accountProperties?.hasPhotovoltaicActive ||
          this.accountProperties?.hasPhotovoltaicActivating
        ) {
          let dataFV = await this.getDocsByCategory('documenti-fotovoltaico')
          this.infoDoc = this.infoDoc.concat(dataFV)
        }
      }

      // this.infoDoc = !this.mainClient.isOnlyFV
      //   ? await this.getDocsByCategory('documenti-utili')
      //   : await this.getDocsByCategory('documenti-fotovoltaico')
    },
    getAllContract() {
      this.getContractsForClient(this.clientDetails).then((resp) => {
        this.numContractsFound =
          resp.contractDocumentsList && resp.contractDocumentsList.length
        const commodityContracts = _.groupBy(
          resp.contractDocumentsList.filter((contr) => {
            return !!contr.cdPropostaContratto
          }),
          'cdPropostaContratto'
        )
        const fiberOnlyContracts = _.groupBy(
          resp.contractDocumentsList.filter((contr) => {
            return !contr.cdPropostaContratto && contr.cdPropostaContrattoFibra
          }),
          'cdPropostaContrattoFibra'
        )

        this.contracts = Object.assign(commodityContracts, fiberOnlyContracts)

        for (let c in this.contracts) {
          let contract = this.contracts[c][0]
          const cdPropostaContrattoFibra = contract.cdPropostaContrattoFibra
          // let formatPropostaContratto = key.split('/')[0]
          if (contract.cdPropostaContratto && !contract.isChangedProduct) {
            this.showAfterthoughtButton[contract.cdPropostaContratto] = false
            this.checkAfterthought(
              contract.cdPropostaContratto,
              contract.dataFirmaContr
            )
          }
          if (cdPropostaContrattoFibra && !contract.isChangedProduct) {
            this.showAfterthoughtButton[cdPropostaContrattoFibra] = false
            this.checkAfterthought(
              cdPropostaContrattoFibra,
              contract.dataFirmaContr
            )
          }
        }
      })

      this.getNotDigitalContracts(this.clientDetails).then((resp) => {
        this.notDigitalContracts = _.groupBy(
          resp.contractDocumentsList,
          'codProposta'
        )
        if (this.clientDetails.clientType === 'RES') {
          for (let c in this.notDigitalContracts) {
            let contract = this.notDigitalContracts[c][0]
            this.showAfterthoughtButton[contract.codProposta] = false
            const dataCreazione = contract.dataCreazione
              .split('/')
              .reverse()
              .join('-')
            this.checkAfterthought(contract.codProposta, dataCreazione)
          }
        }
      })
      this.getChangedProductContract(this.clientDetails.clientCode).then(
        (listaContratti) => {
          if (listaContratti.length > 0) {
            let contractsChanged = {}
            listaContratti.forEach((c) => {
              let supply = this.supplies.find(
                (s) => s.supplyCode === c.codicePunto
              )
              if (c.linkCondizioniContratto) {
                const contract = {
                  docType: 'pdf',
                  isChangedProduct: true,
                  url: c.linkCondizioniContratto,
                  descFile: 'Condizioni generali di contratto',
                  commodityType: supply.serviceType.toLowerCase(),
                  cdPropostaContratto: c.codiceProposta
                }
                if (contractsChanged[c.codiceProposta]) {
                  contractsChanged[c.codiceProposta].push(contract)
                } else {
                  contractsChanged[c.codiceProposta] = [contract]
                }
              }
              if (c.linkModuloAdesione) {
                const model = {
                  docType: 'pdf',
                  isChangedProduct: true,
                  url: c.linkModuloAdesione,
                  descFile: 'Modulo di Adesione'
                }
                if (contractsChanged[c.codiceProposta]) {
                  contractsChanged[c.codiceProposta].push(model)
                } else {
                  contractsChanged[c.codiceProposta] = [model]
                }
              }
            })
            this.contractsChanged = contractsChanged
          }
        }
      )
    },
    async checkAfterthought(contractId, contractSignatureDate) {
      const isDateExpired = () => {
        return (
          new Date().getTime() - new Date(contractSignatureDate).getTime() >
          14 * 24 * 60 * 60 * 1000
        )
      }

      let afterthoughtRequests =
        JSON.parse(localStorage.getItem('afterthoughtRequests')) || null

      let requestAlreadyPresent = false

      if (afterthoughtRequests && afterthoughtRequests[contractId]) {
        requestAlreadyPresent = true
      } else {
        if (contractId) {
          let resp = await this.getRetrieveRipensamento(contractId)
          requestAlreadyPresent = !!resp?.flagRipensamento
        }
      }

      this.showAfterthoughtButton[contractId] =
        !requestAlreadyPresent && !isDateExpired()
      this.$forceUpdate()
    },
    checkGroupFile(contractId) {
      return (
        this.contracts[contractId] &&
        this.contracts[contractId].some(
          (item) => item.groupFile === 'Nuove condizioni di contratto'
        )
      )
    },
    labelCommodityType(commType, commTypeFibra) {
      const labelComm = this.formatCommodityType(commType)
      const labelFiber = this.formatCommodityFiberType(commTypeFibra)

      return labelComm + (labelComm && labelFiber ? ' E ' : '') + labelFiber
    },
    formatCommodityType(commType) {
      let label = ''
      switch (commType || '') {
        case 'gas':
          label = 'GAS'
          break
        case 'ele':
          label = 'LUCE'
          break
        case 'dual':
          label = 'LUCE E GAS'
          break
        default:
          label = ''
          break
      }
      return label
    },
    formatCommodityFiberType(commTypeFibra) {
      let label = ''
      switch (commTypeFibra || '') {
        case 'FIBRA':
          label = 'FIBRA'
          break
        default:
          label = ''
          break
      }
      return label
    },
    getUrlForFile(event, url, id) {
      this.triggerElement = event
      this.physiCall.setJourneyDoc(this.consts.JOURNEY.DOWNLOAD_DOCUMENTI)
      let isPhysicallShowing = this.physiCall.checkCountClick(id)
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        this.triggerElement = event
        const decriptedUrl = atob(url)
        window.open(decriptedUrl, '_blank').focus()
      }
    },

    getContractFileUrl(event, contract, isDigital = true) {
      this.triggerElement = event
      this.physiCall.setJourneyDoc(this.consts.JOURNEY.DOWNLOAD_DOCUMENTI)
      let isPhysicallShowing = this.physiCall.checkCountClick(contract.id)
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        this.dialogDownload = true
        this.cancelDowloadPdf = false
        const host = axiosACN.defaults.baseURL
        this.trackTapEvent({
          name: 'account_clientContract_tap',
          params: {
            client_code: this.clientDetails.clientCode,
            client_type: this.clientDetails.clientType,
            doc_name: contract.descFile
          }
        })
        let url = ''
        if (contract.isChangedProduct) {
          url = contract.url
        } else if (isDigital) {
          url =
            host +
            '/sorgeniadataclient/retrievePDFContract?iddocumenti=' +
            contract.id +
            '&codiceCliente=' +
            this.clientDetails.clientCode
        } else {
          url = contract.url
          this.showContractsLoader = true
        }
        axiosACN({
          url: url,
          method: 'GET',
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + this.$store.getters['session/token']
          }
        }).then((resp) => {
          let self = this

          var reader = new FileReader()
          reader.readAsDataURL(resp.data)
          reader.onloadend = async function () {
            var base64data = reader.result

            const blob = await b64ToBlob(
              base64data,
              'documento',
              'application/pdf'
            )
            const blobUrl = URL.createObjectURL(blob)

            self.showContractsLoader = false
            self.contractDataUrl = blobUrl // base64data
            self.dialogContractPdf = true
            self.dialogDownload = false
          }
        })
      }
    },
    cancelDowload() {
      this.cancelDowloadPdf = true
      this.dialogDownload = false
      this.showContractsLoader = false
    },
    getDocumentFileUrl(event, file) {
      this.triggerElement = event
      this.physiCall.setJourneyDoc(this.consts.JOURNEY.DOWNLOAD_DOCUMENTI)
      let isPhysicallShowing = this.physiCall.checkCountClick(file.id, true)
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        const host = axiosCMS.defaults.baseURL
        const url = host + file.document
        window.open(url, '_blank')
      }
    },
    openDialogAfterthought(contract, type) {
      this.trackTapEvent({
        name: 'account_contractsRethinking_tap',
        params: {
          client_code: this.clientDetails.clientCode,
          client_type: this.clientDetails.clientType?.toLowerCase(),
          supply_type: type.toLowerCase()
        }
      })
      this.dialogAfterthought = true
      this.selectedContract = contract
      this.selectedContractType =
        type.toLowerCase() === 'fiber' ? 'fiber' : 'commodity'
    },
    closePdfViewer() {
      this.dialogContractPdf = false
      this.physiCall.restartTimerSection()
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.documents {
  width: 815px;
  margin-bottom: 47px;

  &__container-title {
    margin-bottom: 26px;
    letter-spacing: 0.02em;
  }

  &__container-address {
    margin-bottom: 16px;
    h5 {
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../assets/profile/home.svg');
      }
    }
  }

  &__list-contracts {
    margin-bottom: 40px;
  }

  &__contract {
    border: 1px solid $neutri-neutral-30;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  &__contract-item {
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-left: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__contract-info {
    text-transform: uppercase;
  }

  &__contract-item-action {
    display: flex;
    align-items: center;
    margin-left: 32px;
    &:nth-of-type(n + 4) {
      margin-top: 24px;
    }
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      width: 15px;
      background-repeat: no-repeat;
      background-size: contain;
      height: 15px;
      background-image: url('../../assets/profile/download.svg');
    }
  }

  &__contract-file {
    font-weight: bold;
    letter-spacing: 0.8px;
    margin-right: 40px;
    width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__afterthought-item {
    width: 246px;
    height: 44px;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid $neutri-neutral-30;
    padding: 16px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__docs-item {
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid $neutri-neutral-30;
    padding: 16px 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
    .ButtonM {
      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        height: 20px;
        background-image: url('../../assets/profile/pdf.svg');
      }
    }
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      width: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      height: 20px;
      background-image: url('../../assets/profile/download.svg');
    }
  }

  .contracts-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    & > div {
      text-align: center;
      max-width: 656px;
    }
  }
}
</style>
