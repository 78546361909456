<template>
  <v-row class="comment_area mt-6">
    <v-col cols="12">
      <textarea
        :value="question.comment_value"
        @input="onCommentChanged($event.target.value)"
        style="resize: none; border-style: none; border-color: transparent"
        :placeholder="text"
        persistent-placeholder
        :key="question.id"
      >
      </textarea>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'FeedbackTextarea',
  props: {
    question: { type: Object },
    onAnswerChanged: { type: Function },
    text: { type: String },
    onCommentChanged: { type: Function }
  },
  mounted() {
    this.onAnswerChanged()
  }
}
</script>
<style lang="scss" scoped>
.comment_area {
  border-radius: 27px;
  border: 1px solid lightgrey;
  height: 100px;
  padding: 0px 10px;

  textarea {
    width: 100%;
    height: 100%;
  }
}
</style>
