<template>
  <div class="missions">
    <div class="mb-8">
      <h2 class="font-weight-bold mb-2">Fai le missioni</h2>
      <h3 class="mb-3">
        Ogni mese ti proponiamo diverse tipologie di missioni da completare:
      </h3>
      <!-- <p v-if="isModal" class="mb-2">Mettiti alla prova con le <strong>Missioni Greeners</strong>. Raccogli più Green Coins e sali di Livello.</p> -->
      <!-- <p v-if="!isModal" class="mb-2">Mettiti alla prova con le missioni, <strong>scala i livelli</strong> per diventare sempre più esperto del green e raccogli Green Coins. Non farti scappare le <strong>missioni a sorpresa</strong>, sono live per poco tempo!</p> -->
    </div>
    <div class="missions__item">
      <v-img
        width="64"
        height="64"
        src="@/assets/greeners/svg/mission-survey.svg"
      />
      <div>
        <h3><strong>SURVEY</strong></h3>
        <h4>Dicci la tua sulla <strong>sostenibilità</strong></h4>
      </div>
    </div>
    <div class="missions__item">
      <v-img
        width="64"
        height="64"
        src="@/assets/greeners/svg/mission-video.svg"
      />
      <div>
        <h3><strong>VIDEO</strong></h3>
        <h4>Segui <strong>notizie</strong> sull’ambiente</h4>
      </div>
    </div>
    <div class="missions__item">
      <v-img
        width="64"
        height="64"
        src="@/assets/greeners/svg/mission-photo.svg"
      />
      <div>
        <h3><strong>FOTO</strong></h3>
        <h4>Condividi i momenti <strong>Green</strong></h4>
      </div>
    </div>
    <div class="missions__item">
      <v-img
        width="64"
        height="64"
        src="@/assets/greeners/svg/mission-quiz.svg"
      />
      <div>
        <h3><strong>QUIZ</strong></h3>
        <h4>Mettiti alla prova</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissionsComponent',
  props: {
    isModal: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.missions {
  width: 100%;
  padding: 0 12px;
  h3 {
    line-height: 32px;
    font-weight: 500;
    letter-spacing: 0.48px;
  }
  h4 {
    font-size: 20px;
    letter-spacing: 1px;
    color: black;
  }
  p {
    font-size: 20px;
    color: black;
    line-height: 30px;
    letter-spacing: 0.8px;
    flex-grow: 1;
    margin-bottom: 0;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
    .v-image {
      flex-grow: 0;
      margin-right: 32px;
    }
  }
}
</style>
