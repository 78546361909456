<template>
  <div>
    <h1 class="supplies__title">{{ title }}</h1>
    <span class="abstract">{{ abstract }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    abstract: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
.supplies__title {
  font-size: $title-font-size--large;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 52px;
}
.abstract {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
}
</style>
