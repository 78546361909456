<template>
  <div class="std-side-padding upSelling details">
    <v-row>
      <v-col class="col-7 details-desc">
        <h2 v-if="type === 'gas'" class="details-desc__title">Gas!</h2>
        <h2 v-if="type === 'Ele'" class="details-desc__title">Luce!</h2>
        <div class="details-desc__list col-2 offset-10">
          <v-progress-circular
            :size="50"
            indeterminate
            v-if="!isReady"
          ></v-progress-circular>
        </div>
        <div
          v-if="data.conditions && isReady"
          class="details-desc__list"
          v-html="data.conditions"
        />
      </v-col>
      <v-col class="col-5 details-promo">
        <div
          v-if="data.promo_label && data.promo_description"
          class="details-promo__circle d-flex flex-column justify-center align-center"
        >
          <span>{{ data.promo_label }}</span>
          <h3>{{ data.promo_description }}</h3>
        </div>
      </v-col>
      <v-row class="upSelling__button-block">
        <v-col align="center">
          <v-btn
            class="v-btn--shadowed"
            x-large
            color="primary"
            @click="
              $emit('nextStep'),
                trackTapEvent({
                  name: 'commodityUpsell_offer_tap',
                  params: {
                    client_code: requestingClient.clientCode,
                    client_type: requestingClient.clientType
                  }
                })
            "
          >
            Prosegui
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InfoComponent',
  props: {
    type: { type: String },
    data: { type: Object, default: () => ({}) },
    isReady: { type: Boolean, default: false },
    requestingClient: { type: Object, default: null }
  },
  data: () => ({
    activeClient: undefined,
    selectedType: undefined,
    types: [
      { name: 'Residenziale', value: 'RES' },
      { name: 'Business', value: 'BUS' }
    ]
  }),
  beforeMount() {
    this.getClientsForAccount()
    this.trackTapEvent({
      name: 'commodityUpsell_offer_view',
      params: {
        client_code: this.requestingClient.clientCode,
        client_type: this.requestingClient.clientType
      }
    })
  },
  computed: {
    ...mapGetters('account', ['accountClients'])
  },
  methods: {
    ...mapActions('account', ['getClientsForAccount']),
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>

<style lang="scss" scoped>
.details {
  padding-top: 64px;
  &-desc {
    &__title {
      font-size: 42px;
      font-weight: bold;
      margin-bottom: 32px;
    }

    &__list::v-deep ul {
      list-style: none;
      li {
        font-weight: 300;
        margin-bottom: 24px;
        position: relative;
        margin-left: 40px;

        &:before {
          width: 24px;
          content: url('../../assets/icons/ic-check.svg');
          position: absolute;
          left: -40px;
          top: -10px;
        }
      }
    }
  }

  &-promo {
    padding: 30px 0 0 60px;
    text-align: center;

    &__circle {
      width: 285px;
      height: 285px;
      border-radius: 195px;
      background-color: $srg-green;
      color: white;

      span {
        font-weight: bold;
        font-size: 36px;
        margin-bottom: 14px;
      }

      h3 {
        width: 228px;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: bold;

        span {
          margin-bottom: initial;
          font-size: 24px;
          display: block;
        }
      }

      h6 {
        margin-top: 20px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
</style>
